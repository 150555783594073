import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import ReactSelect from 'react-select';
import moment from 'moment';
// import api from 'api/api';
import NoImage from 'assets/images/no-image.png';


// , sendEstimateEmail, sendingEstimate, selectedForSending, disableStatus
const PurchaseOrderModal = ({ openPopup, selectedEstimate, handleClose, handleItems, handleCreatePurchaseOrder }) => {

    const [supplierList, setsupplierList] = useState([]);
    const [warehouseList, setwarehouseList] = useState([]);
    const [invoiceItems, setInvoiceItems] = useState(null);
    const [products, setProducts] = useState([]);
    const [items, setItems] = useState([]);
    const [stockDetails, setStockDetails] = useState([]);
    const [hasAdded, setHasAdded] = useState(false);
    const [currency, setCurrency] = useState('');
    // const totalQuantity = handleItems?.reduce((acc, item) => acc + (parseInt(item.qty) || 0), 0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [total, setTotal] = useState('0.00');
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const total = handleItems?.reduce((acc, item) => acc + (item.total_price || 0), 0);

    const currencies = [
        { label: 'EUR', value: 'eur' },
        { label: 'USD', value: 'usd' },
        { label: 'AED', value: 'aed' }
    ];

    const currentDate = moment().format('YYYY-MM-DD');
    const [alertMessage, setAlertMessage] = useState();

    const [purchaseList, setPurchaseList] = useState({
        warehouse_id: '',
        expected_date: currentDate,
        invoice_number: selectedEstimate?.invoice_number,
        estimation_id: selectedEstimate?.id,
        currency: 'eur'
    });


    useEffect(() => {
        initiateDatas();
    }, []);


    const [loaderStatus, setLoaderStatus] = useState({
        suppliers: false,
        warehouse: false
    });

    const initiateDatas = () => {
        // setInvoiceItems(handleItems)
        setCurrency(selectedEstimate?.currency);
        api.getSelectOptionsList('warehouse').then((res) => {
            const custData = res.data;
            const customerArray = custData.map((item) => ({ id: item.id, label: item.name, value: item.id }));
            setwarehouseList(customerArray);
            setLoaderStatus((oldData) => ({
                ...oldData,
                warehouse: false
            }));
        });
        api.getSelectOptionsList('supplier').then((res) => {
            const custData = res.data;
            const customerArray = custData.map((item) => ({ id: item.id, label: item.name, value: item.id }));
            setsupplierList(customerArray);
            setLoaderStatus((oldData) => ({
                ...oldData,
                suppliers: false
            }));
        });
        getProductAvailability();
        // api.purchaseOrderCheckStockAvailability(handleItems).then((res) => {
        //     console.log(res)
        //     if (res?.status) {
        //         setStockDetails(res?.data);

        //         const populate = res?.data?.map(item => {
        //             let sub_qty = res.data[item.id] || 0;
        //             let computed_quantity = 0;
        //             if (parseInt(item?.available_quantity) > 0) {
        //                 if (parseInt(item?.available_quantity) > parseInt(item?.needed_quantity)) {
        //                     computed_quantity = 0;
        //                 } else {
        //                     computed_quantity = parseInt(item?.needed_quantity) - parseInt(item?.available_quantity);
        //                 }
        //             } else {
        //                 computed_quantity = parseInt(item?.needed_quantity);
        //             }
        //             return {
        //                 name: item.name,
        //                 sku: item.sku,
        //                 qty: computed_quantity,
        //                 qty_needed: computed_quantity,
        //                 processed_quantity: item?.processed_quantity,
        //                 thumbnail_url: item?.thumbnail_url,
        //                 price: '0.00',
        //                 currency: currency,
        //                 total_price: '0.00'
        //             };
        //         });
        //         setItems(populate);

        //         const productArray = handleItems.map((item) => ({ id: item.id, label: item.name, value: item.id }));
        //         setProducts(productArray)
        //         setTotalQuantity(populate?.reduce((acc, item) => acc + (parseInt(item.qty) || 0), 0));
        //     }
        // });
        console.log(handleItems)
    };


    const handlePriceChange = (index, price) => {
        const updatedItems = [...items];
        updatedItems[index].price = price;
        updatedItems[index].total_price = price * updatedItems[index].qty;
        setItems(updatedItems);

        setTotal(updatedItems?.reduce((acc, item) => acc + (parseFloat(item.total_price) || 0), 0));
    };


    const removeRow = index => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);

        setTotalQuantity(updatedItems?.reduce((acc, item) => acc + (parseInt(item.qty) || 0), 0));
        setTotal(updatedItems?.reduce((acc, item) => acc + (parseFloat(item.total_price) || 0), 0));
    };


    const handlePurchaseOrderInfo = (val) => {
        setPurchaseList(val);
    };


    const handleSupplierInfo = (selected) => {
        handlePurchaseOrderInfo({
            ...purchaseList,
            supplier_id: selected?.id
        });
    };

    const handleProductNameChange = (index, product) => {
        const matchingInvoiceItems = invoiceItems.find(item => item.id === product?.id);

        const totalQtyOfOtherItems = items
            .filter(sourceItem => sourceItem.id === matchingInvoiceItems?.id && sourceItem !== items[index])
            .reduce((total, item) => total + item.qty, 0);

        const updatedItems = [...items];
        updatedItems[index].id = matchingInvoiceItems?.id;
        updatedItems[index].name = matchingInvoiceItems?.name;
        updatedItems[index].sku = matchingInvoiceItems?.sku;
        updatedItems[index].base_qty = matchingInvoiceItems?.base_qty;

        if (matchingInvoiceItems?.qty_needed + totalQtyOfOtherItems <= matchingInvoiceItems?.base_qty) {
            updatedItems[index].qty = matchingInvoiceItems?.qty;
            updatedItems[index].qty_needed = matchingInvoiceItems?.qty_needed;
        } else {
            const remainingQty = matchingInvoiceItems?.base_qty - totalQtyOfOtherItems;
            updatedItems[index].qty = remainingQty > 0 ? remainingQty : 0;
            updatedItems[index].qty_needed = remainingQty > 0 ? remainingQty : 0;
        }

        updatedItems[index].processed_quantity = matchingInvoiceItems?.processed_quantity;
        updatedItems[index].thumbnail_url = matchingInvoiceItems?.thumbnail_url;
        updatedItems[index].price = matchingInvoiceItems?.price;
        updatedItems[index].available_quantity = matchingInvoiceItems?.available_quantity;

        setItems(updatedItems);
        setTotalQuantity(updatedItems?.reduce((acc, item) => acc + (parseInt(item.qty) || 0), 0));
    }

    // const handleProductNameChange = (index, product) => {
    //     // console.log('product', product)
    //     // console.log(product?.id)
    //     // console.log('invoiceItems', invoiceItems)
    //     const matchingInvoiceItems = invoiceItems.find(item => item.id === product?.id);
    //     // console.log('matchingInvoiceItems', matchingInvoiceItems);

    //     const limitQty = items
    //         .filter(item => item.id === matchingInvoiceItems?.id)
    //         .map(item => item.qty);

    //     console.log('limitQty', limitQty);
    //     const totalLimitQty = limitQty.reduce((total, qty) => total + qty, 0);
    //     console.log('totalLimitQty', totalLimitQty);


    //     const updatedItems = [...items];
    //     updatedItems[index].id = matchingInvoiceItems?.id;
    //     updatedItems[index].name = matchingInvoiceItems?.name;
    //     updatedItems[index].sku = matchingInvoiceItems?.sku;
    //     updatedItems[index].base_qty = matchingInvoiceItems?.base_qty;
    //     if (matchingInvoiceItems?.qty_needed > totalLimitQty) {
    //         updatedItems[index].qty = matchingInvoiceItems?.qty;
    //         updatedItems[index].qty_needed = matchingInvoiceItems?.qty_needed;
    //     } else {
    //         updatedItems[index].qty = matchingInvoiceItems?.qty_needed - totalLimitQty;
    //         updatedItems[index].qty_needed = matchingInvoiceItems?.qty_needed - totalLimitQty;
    //     }
    //     updatedItems[index].processed_quantity = matchingInvoiceItems?.processed_quantity;
    //     updatedItems[index].thumbnail_url = matchingInvoiceItems?.thumbnail_url;
    //     updatedItems[index].price = matchingInvoiceItems?.price;
    //     updatedItems[index].available_quantity = matchingInvoiceItems?.available_quantity;

    //     console.log('updatedItems', updatedItems);

    //     setItems(updatedItems);
    //     setTotalQuantity(updatedItems?.reduce((acc, item) => acc + (parseInt(item.qty) || 0), 0));
    // }

    const handleQuantityChange = (e, item, index) => {

        const updatedItems = [...items];
        const enteredQty = parseInt(e.target.value);

        const totalQtyOfOtherItems = items
            .filter(sourceItem => sourceItem.id === item?.id && sourceItem !== item)
            .reduce((total, item) => total + item.qty, 0);

        const totalQty = totalQtyOfOtherItems + enteredQty;
        if (totalQty <= item?.base_qty) {
            updatedItems[index].qty = enteredQty;
            updatedItems[index].total_price = (updatedItems[index].qty * updatedItems[index].price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            setItems(updatedItems);
        } else {
            const remainingQty = item?.base_qty - totalQtyOfOtherItems;
            updatedItems[index].qty = remainingQty > 0 ? remainingQty : 0;
            updatedItems[index].total_price = (updatedItems[index].qty * updatedItems[index].price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            setItems(updatedItems);
        }
    }

    const submitCreatePurchaseOrder = () => {
        // console.log('purchaseList', purchaseList)
        // console.log('items', items)
        if (purchaseList?.warehouse_id == '') {
            toast.error('Warehouse is required');
            return false;
        } else if (items.length === 0) {
            toast.error('No item seleteced for purchase order');
            return false;
        } else if (!validateItems()) {
            return false;
        }

        handleCreatePurchaseOrder(purchaseList, items);
        return false;
        const isValid = products.length === 0 ? true : validateFields();
        if (isValid) {
            const data = products.length === 0 ? null : {
                items: items,
                currency: iscurrency.value,
            }
            handleConfirm(show.id, data);
        } else {
            toast.error('Some fields are invalid. Please correct them.');
        }
    };

    const getProductAvailability = () => {

        api.purchaseOrderCheckStockAvailability(handleItems).then((res) => {
            if (res?.status) {
                setStockDetails(res?.data);

                const populate = res?.data?.map(item => {
                    let sub_qty = res.data[item.id] || 0;
                    let computed_quantity = 0;
                    if (parseInt(item?.available_quantity) > 0) {
                        if (parseInt(item?.available_quantity) > parseInt(item?.needed_quantity)) {
                            computed_quantity = 0;
                        } else {
                            computed_quantity = parseInt(item?.needed_quantity) - parseInt(item?.available_quantity);
                        }
                    } else {
                        computed_quantity = parseInt(item?.needed_quantity);
                    }
                    return {
                        id: item.product_id,
                        name: item.name,
                        sku: item.sku,
                        base_qty: item?.needed_quantity,
                        qty: item?.needed_quantity,
                        qty_needed: item?.needed_quantity,
                        processed_quantity: item?.processed_quantity,
                        available_quantity: item?.available_quantity,
                        thumbnail_url: item?.thumbnail_url,
                        price: '0.00',
                        currency: currency,
                        total_price: '0.00',
                        supplier_id: '',
                    };
                });
                setInvoiceItems(populate);

                // const productArray = handleItems.map((item) => ({ id: item.id, label: item.name, value: item.id }));
                // setProducts(productArray)
                // setTotalQuantity(populate?.reduce((acc, item) => acc + (parseInt(item.qty) || 0), 0));
            }
        });
    }

    const addProductItemRow = () => {

        const updatedItems = [...items];
        updatedItems.push({
            id: '',
            name: '',
            sku: '',
            base_qty: 0,
            qty: 0,
            qty_needed: 0,
            supplier_id: '',
            processed_quantity: 0,
            available_quantity: '',
            thumbnail_url: '',
            price: '0.00',
            currency: currency,
            total_price: '0.00'
        });
        setItems(updatedItems);
        setHasAdded(true)
        const productArray = handleItems.map((item) => ({ id: item.id, label: item.name, value: item.id }));
        setProducts(productArray)
        setTotalQuantity(updatedItems?.reduce((acc, item) => acc + (parseInt(item.qty) || 0), 0));
    }
    const validateItems = () => {
        let isValid = true;
        items.forEach((item, index) => {
            if (!item.name) {
                toast.error(`Please select product name for row ${index + 1}`);
                isValid = false;
                return;
            } else if (!item.qty || isNaN(item.qty) || item.qty <= 0) {
                toast.error(`Please provide a valid quantity for ${item.name}`);
                isValid = false;
                return;
            } else if (!item.price || isNaN(item.price) || item.price <= 0) {
                toast.error(`Please provide a valid price for ${item.name}`);
                isValid = false;
                return;
            }
        });
        return isValid
    }









    const [formInfo, setFormInfo] = useState({
        estimationId: '',
    });

    const [sendingType, setSendingType] = useState('auto');
    const handleValue = (val) => {
        setFormInfo({
            ...formInfo,
            estimationId: val?.id
        });
        setFormInfo({ estimationId: val?.value });
    };


    const [emailDetails, setEmailDetails] = useState({
        receiver: '',
        subject: '',
        message: '',
        // id: selectedForSending,
        type: openPopup,
    });



    // useEffect(() => {
    //     const sendType = openPopup == 'estimate' ? 'send_estimate' : 'send_invoice';
    //     api.getEmailTemplate(`${sendType}`).then((res) => {

    //         const salesType = openPopup == 'estimate' ? 'Estimate ' : openPopup == 'invoice' ? 'Invoice' : 'Credit Note ';
    //         if (openPopup == 'estimate') {

    //             let subject = res?.data?.subject?.replace(/{estimate_number}/g, selectedEstimate?.estimation_number);
    //             subject = subject?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
    //             subject = subject?.replace(/{sale_type}/g, salesType);

    //             if (selectedEstimate?.logistic?.name) {
    //                 subject = subject?.replace(/{logistic_name}/g, selectedEstimate?.logistic?.name);
    //             }
    //             let message = res?.data?.template?.replace(/{estimate_number}/g, selectedEstimate?.estimation_number);
    //             message = message?.replace(/{sale_type}/g, salesType);

    //             if (selectedEstimate?.transit_company?.name) {
    //                 message = message?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
    //             }
    //             if (selectedEstimate?.customer?.contact_name) {
    //                 message = message?.replace(/{final_customer_name}/g, selectedEstimate?.customer?.contact_name);
    //             }
    //             if (res?.data?.client_name) {
    //                 message = message?.replace(/{projectmix_user}/g, res?.data?.client_name);
    //             }
    //             setEmailDetails({ ...emailDetails, subject: subject, message: message });
    //         } else {
    //             let hasLogistic = false;

    //             if (selectedEstimate?.logistic) {
    //                 hasLogistic = true;
    //             }

    //             let subject = res?.data?.subject?.replace(/{estimate_number}/g, selectedEstimate?.estimation_number);
    //             subject = subject?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
    //             subject = subject?.replace(/{sale_type}/g, salesType);

    //             if (selectedEstimate?.invoice_number) {
    //                 subject = subject?.replace(/{invoice_number}/g, selectedEstimate?.invoice_number);
    //             }

    //             if (hasLogistic) {
    //                 subject = subject?.replace(/{logistic_name}/g, selectedEstimate?.logistic?.name);
    //             } else {
    //                 subject = subject?.replace(/{logistic_name}/g, selectedEstimate?.transit_company?.name);
    //             }

    //             let message = res?.data?.template?.replace(/{estimate_number}/g, selectedEstimate?.estimation_number);
    //             message = message?.replace(/{invoice_number}/g, selectedEstimate?.invoice_number);
    //             message = message?.replace(/{sale_type}/g, salesType);
    //             if (hasLogistic) {
    //                 if (selectedEstimate?.logistic?.contact_name) {
    //                     message = message?.replace(/{logistic_name}/g, selectedEstimate?.logistic?.contact_name);
    //                 }
    //             } else if (selectedEstimate?.transit_company?.name) {
    //                 message = message?.replace(/{logistic_name}/g, selectedEstimate?.transit_company?.contact_name);
    //             }
    //             if (selectedEstimate?.transit_company?.name) {
    //                 message = message?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
    //             }
    //             if (selectedEstimate?.customer?.contact_name) {
    //                 message = message?.replace(/{final_customer_name}/g, selectedEstimate?.customer?.contact_name);
    //             }
    //             if (res?.data?.client_name) {
    //                 message = message?.replace(/{projectmix_user}/g, res?.data?.client_name);
    //             }
    //             setEmailDetails({ ...emailDetails, subject: subject, message: message });
    //         }
    //     });
    // }, []);

    // const handleSendingType = (type) => {
    //     setSendingType(type)
    //     if (type == 'auto') {
    //         setEmailDetails({ ...emailDetails, receiver: '' });
    //     } else {
    //         // if (openPopup != 'estimate') {
    //         //     if (type == 'manual') {

    //         //         if (selectedEstimate?.logistic) {
    //         //             if (selectedEstimate?.logistic?.name) {
    //         //                 setEmailDetails({ ...emailDetails, receiver: selectedEstimate?.logistic?.email });
    //         //             } else {
    //         //                 toast.error(`No email address assigned to logistics`);
    //         //                 return false;
    //         //             }

    //         //         } else if (selectedEstimate?.transit_company?.name) {
    //         //             if (selectedEstimate?.transit_company?.name) {
    //         //                 setEmailDetails({ ...emailDetails, receiver: selectedEstimate?.transit_company?.email });
    //         //             } else {
    //         //                 toast.error(`No email address assigned to transit company`);
    //         //                 return false;
    //         //             }
    //         //         }
    //         //     }
    //         // } else {
    //         //     if (type == 'manual') {
    //         //         if (selectedEstimate?.customer?.name) {
    //         //             setEmailDetails({ ...emailDetails, receiver: selectedEstimate?.customer?.email });
    //         //         } else {
    //         //             toast.error(`No email address assigned to customer`);
    //         //             return false;
    //         //         }
    //         //     }
    //         // }
    //     }
    // }


    // const sendEmail = () => {
    //     if (sendingType == 'auto') {

    //         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //         if (!emailRegex.test(emailDetails?.receiver)) {
    //             toast.error(`Please enter valid email address`);
    //             return false;
    //         } else if (emailDetails?.subject.trim() == '') {
    //             toast.error(`Please enter email subject`);
    //             return false;
    //         } else {
    //             const data = {
    //                 receiver: emailDetails?.receiver,
    //                 subject: emailDetails?.subject,
    //                 message: emailDetails?.message,
    //                 sending_type: sendingType,
    //                 id: selectedForSending,
    //                 type: openPopup
    //             };
    //             sendEstimateEmail(data);
    //         }
    //     } else {
    //         const data = {
    //             receiver: emailDetails?.receiver,
    //             subject: emailDetails?.subject,
    //             message: emailDetails?.message,
    //             sending_type: sendingType,
    //             id: selectedForSending,
    //             type: openPopup
    //         };
    //         sendEstimateEmail(data);
    //     }
    // }

    const disablepopup = () => {
        handleClose();
    };




    return (
        <>
            <Modal show={openPopup} onHide={disablepopup} centered size="xl">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0" style={{ marginTop: '-20px' }}>

                                <div className="row">
                                    <div className="col-12 text-left">

                                        <h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0" style={{ fontWeight: '600' }}>
                                            Create Purchase Order
                                        </h5>
                                        <br />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    {/* <FaTimes /> */}
                                    <svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                                        <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                                    </svg>


                                </button>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-12 form-group">

                                    {/* <div className="row align-items-top form-group">
                                        <div className="col-lg-3 col-3" style={{ display: 'flex', alignItems: 'center' }} htmlFor="inputName">
                                            Supplier
                                        </div>
                                        <div className="col-lg-9 col-9">

                                            {loaderStatus?.customers ? (
                                                <Skeleton
                                                    style={{
                                                        minHeight: '32px',
                                                        height: '32px',
                                                        transformOrigin: '0 0',
                                                        transform: 'scale(1,1)',
                                                        width: '250px'
                                                    }}
                                                ></Skeleton>
                                            ) : (
                                                <ReactSelect
                                                    className="rs-container"
                                                    classNamePrefix="rs"
                                                    name="customer"
                                                    options={supplierList}
                                                    value={supplierList?.find((c) => c?.value == purchaseList?.supplier_id)}
                                                    onChange={(selected) => handleSupplierInfo(selected)}
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    placeholder="Select"
                                                    style={{ borderRadius: '18px' }}
                                                />
                                            )}
                                            {alertMessage?.name && (
                                                <>
                                                    <span className="error-message text-danger">{alertMessage?.name}</span>
                                                </>
                                            )}
                                        </div>
                                    </div> */}




                                    <div className="row align-items-top form-group">
                                        <div className="col-3" style={{ display: 'flex', alignItems: 'center' }} htmlFor="inputName">
                                            {/* <label htmlFor="inputName" className="est-labels labl-form"> */}
                                            Warehouse
                                            {/* </label> */}
                                        </div>
                                        <div className="col-9">

                                            <ReactSelect
                                                className="rs-container"
                                                classNamePrefix="rs"
                                                name="transit-company"
                                                options={warehouseList}
                                                value={warehouseList?.find((c) => c?.value == purchaseList?.warehouse_id)}
                                                onChange={(e) => {
                                                    handlePurchaseOrderInfo({
                                                        ...purchaseList,
                                                        warehouse_id: e.value
                                                    });
                                                }}
                                                menuPlacement="auto"
                                                menuPosition="fixed"
                                                placeholder="Select"
                                                style={{ borderRadius: '18px' }}
                                            />

                                            {alertMessage?.name && (
                                                <>
                                                    <span className="error-message text-danger">{alertMessage?.name}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row align-items-top form-group">
                                        <div className="col-3" style={{ display: 'flex', alignItems: 'center' }} htmlFor="inputName">
                                            {/* <label htmlFor="inputName" className="est-labels labl-form"> */}
                                            ETA
                                            {/* </label> */}
                                        </div>
                                        <div className="col-4 ">

                                            <input
                                                type="date"
                                                value={purchaseList?.expected_date}
                                                style={{ cursor: 'pointer' }}
                                                className="form-control form-inp"
                                                onClick={(e) => e.target.focus()}
                                                onChange={(e) => {

                                                    handlePurchaseOrderInfo({
                                                        ...purchaseList,
                                                        expected_date: e.target?.value
                                                    });
                                                }}
                                            />

                                            {alertMessage?.name && (
                                                <>
                                                    <span className="error-message text-danger">{alertMessage?.name}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row align-items-top form-group">
                                        <div className="col-3" style={{ display: 'flex', alignItems: 'center' }} htmlFor="inputName">
                                            {/* <label htmlFor="inputName" className="est-labels labl-form"> */}
                                            Currency
                                            {/* </label> */}
                                        </div>
                                        <div className="col-4 ">

                                            <Select
                                                className="rs-container-est-white"
                                                classNamePrefix="rs"
                                                isSearchable={true}
                                                name="customer"
                                                options={currencies}
                                                value={currencies?.find((c) => c?.value == purchaseList?.currency)}
                                                onChange={(e) => {
                                                    handlePurchaseOrderInfo({
                                                        ...purchaseList,
                                                        currency: e.value
                                                    });
                                                    setCurrency(e);
                                                }}
                                            />
                                            {alertMessage?.name && (
                                                <>
                                                    <span className="error-message text-danger">{alertMessage?.name}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>


                                </div>
                                {/* <div className="col-lg-6 col-12 form-group" style={{ marginTop: '-15px' }}>
                             
                                    <div className="row">
                                        <div className="col-12 form-group" style={{ padding: '0px' }}>
                                            <Scroller>
                                                <table className="table  min-width-unset tbl-space pm-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th style={{ width: '90px' }}>In Stocks</th>
                                                            <th style={{ width: '90px' }}>Order Pcs</th>
                                                            <th style={{ width: '90px' }}>Processed</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {stockDetails?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div>
                                                                        {item?.name}
                                                                    </div>
                                                                    <div className="sub-font-color">
                                                                        {item?.sku}
                                                                    </div>

                                                                </td>
                                                                <td>
                                                                    {item?.available_quantity} {(item?.available_quantity > 0) ? 'pcs' : 'pc'}
                                                                </td>
                                                                <td>
                                                                    {item?.needed_quantity} {(item?.needed_quantity > 0) ? 'pcs' : 'pc'}
                                                                </td>
                                                                <td>
                                                                    {item?.processed_quantity} {(item?.processed_quantity > 0) ? 'pcs' : 'pc'}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Scroller>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-12 form-group">
                                    <h3 style={{ fontStyle: 'italic' }} className='sub-font-color font400'>
                                        Draft Purchase Order #{selectedEstimate?.invoice_number}
                                    </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 form-group">
                                    {
                                        !hasAdded ? (
                                            <>
                                                <div className="purchase-order-add-product-div">
                                                    <button
                                                        className='btn btn-main-primary'
                                                        onClick={() => {
                                                            addProductItemRow()
                                                        }}
                                                    >
                                                        Add product
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="estimation-table-area">
                                                    <div className="table-side">
                                                        <div className="table-responsive-xl tableData estimationList">
                                                            {/* <Scroller> */}
                                                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                                                <button
                                                                    className="btn btn-primary btn-outlined-main white-var m-0"
                                                                    onClick={() => {
                                                                        addProductItemRow()
                                                                    }}
                                                                >
                                                                    Add Product
                                                                </button>
                                                            </div>
                                                            <table className="table table-md text-gray-900 formActBtns border-0 table-process">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-left" style={{ padding: '5px 5px 5px 0', width: '30%' }}>Product</th>
                                                                        <th className="align-middle" style={{ textAlign: 'left', width: '30%' }}>Supplier</th>
                                                                        <th className="align-middle" style={{ textAlign: 'left', width: '8%' }}>Qty</th>
                                                                        <th className="align-middle" style={{ textAlign: 'left', width: '9%' }}>Stock</th>
                                                                        <th className="align-middle" style={{ textAlign: 'left', width: '8%' }}>Price</th>
                                                                        <th className="align-middle text-left" style={{ width: '10%' }}>Total</th>
                                                                        <th className="align-middle text-center" style={{ width: '5%' }}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        items?.map((item, index) => (
                                                                            <>

                                                                                <tr key={index}>
                                                                                    <td className="align-middle estimateCName" style={{ padding: '5px 5px 5px 0' }}>
                                                                                        <div className="form-group col-md-12 mb-0">

                                                                                            <div className="row">
                                                                                                <div className="col-12" style={{ padding: '5px 5px 5px 0' }}>
                                                                                                    {/* <div className="d-flex align-items-center">
                                                                                                            <div>
                                                                                                                <img src={item?.thumbnail_url || NoImage} alt={item?.name} style={{ height: '40px' }} />
                                                                                                            </div>
                                                                                                            <div style={{ marginLeft: '10px' }}>
                                                                                                                <div>
                                                                                                                    {item?.name}
                                                                                                                </div>
                                                                                                                <div className="sub-font-color">
                                                                                                                    {item?.sku}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> */}

                                                                                                    <ReactSelect
                                                                                                        className="rs-container"
                                                                                                        classNamePrefix="rs"
                                                                                                        // options={[...models]?.filter((m) => invItems?.every((it) => it.model != m.value) || m.value == item.model)}

                                                                                                        options={products}
                                                                                                        value={item[index]}
                                                                                                        menuPlacement="auto"
                                                                                                        menuPosition="fixed"
                                                                                                        onChange={selectedOption => handleProductNameChange(index, selectedOption)}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div>{item?.name}</div>
                                                                                        <div className="sub-font-color">{item?.sku}</div> */}
                                                                                            {/* <ReactSelect
                                                                                            className="rs-container"
                                                                                            classNamePrefix="rs"
                                                                                            // options={[...models]?.filter((m) => invItems?.every((it) => it.model != m.value) || m.value == item.model)}
            
                                                                                            options={products}
                                                                                            value={products[index]}
                                                                                            menuPlacement="auto"
                                                                                            menuPosition="fixed"
                                                                                            onChange={selectedOption => handleProductNameChange(index, selectedOption)}
                                                                                        /> */}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="align-middle">

                                                                                        <ReactSelect
                                                                                            className="rs-container"
                                                                                            classNamePrefix="rs"
                                                                                            name="customer"
                                                                                            options={supplierList}
                                                                                            value={supplierList?.find((c) => c?.value == purchaseList?.supplier_id)}
                                                                                            // onChange={(selected) => handleSupplierInfo(selected)}
                                                                                            onChange={(selected) => {
                                                                                                const updatedItems = [...items];
                                                                                                updatedItems[index].supplier_id = selected?.value;
                                                                                                setItems(updatedItems);
                                                                                            }}
                                                                                            menuPlacement="auto"
                                                                                            menuPosition="fixed"
                                                                                            placeholder="Select"
                                                                                            style={{ borderRadius: '18px' }}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="align-middle ">
                                                                                        <input
                                                                                            className="numField"
                                                                                            type="number"
                                                                                            value={item?.qty}
                                                                                            placeholder="0"
                                                                                            maxLength={10}
                                                                                            // onChange={e => handleQuantityChange(index, e.target.value)}
                                                                                            onChange={e => handleQuantityChange(e, item, index)}
                                                                                            // onChange={e => {
                                                                                            //     const updatedItems = [...items];
                                                                                            //     const enteredQty = parseInt(e.target.value);

                                                                                            //     const totalQtyOfOtherItems = items
                                                                                            //         .filter(sourceItem => sourceItem.id === item?.id && sourceItem !== item)
                                                                                            //         .reduce((total, item) => total + item.qty, 0);

                                                                                            //     const totalQty = totalQtyOfOtherItems + enteredQty;
                                                                                            //     if (totalQty <= item?.base_qty) {

                                                                                            //         updatedItems[index].qty = enteredQty;
                                                                                            //         updatedItems[index].total_price = updatedItems[index].qty * updatedItems[index].price;
                                                                                            //         setItems(updatedItems);
                                                                                            //     } else {

                                                                                            //         const remainingQty = item?.base_qty - totalQtyOfOtherItems;
                                                                                            //         updatedItems[index].qty = remainingQty > 0 ? remainingQty : 0;
                                                                                            //         updatedItems[index].total_price = updatedItems[index].qty * updatedItems[index].price;
                                                                                            //         setItems(updatedItems);
                                                                                            //     }
                                                                                            // }}
                                                                                            // onChange={e => {


                                                                                            //     const updatedItems = [...items];
                                                                                            //     const enteredQty = parseInt(e.target.value);
                                                                                            //     if (enteredQty > item?.qty_needed) {
                                                                                            //         updatedItems[index].qty = item?.qty_needed;
                                                                                            //     } else {
                                                                                            //         updatedItems[index].qty = enteredQty;
                                                                                            //     }
                                                                                            //     console.log('item', item)
                                                                                            //     // updatedItems[index].qty = enteredQty;
                                                                                            //     updatedItems[index].total_price = updatedItems[index].qty * updatedItems[index].price;
                                                                                            //     setItems(updatedItems);

                                                                                            //     // Calculate total quantity
                                                                                            //     const totalQuantity = updatedItems.reduce((total, item) => parseInt(total) + (parseInt(item?.qty) || 0), 0);
                                                                                            //     setTotalQuantity(totalQuantity); // assuming you have a state setter for totalQuantity
                                                                                            // }}
                                                                                            style={{ paddingInline: '3px' }}
                                                                                            onFocus={(event) => {
                                                                                                event.target.select();
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="align-middle ">
                                                                                        {
                                                                                            (item?.available_quantity != '') ? (
                                                                                                <>
                                                                                                    {
                                                                                                        (item?.available_quantity > 0 && item?.available_quantity <= 10) ? (
                                                                                                            <div><span className="text-warning font600">{item?.available_quantity}</span> {item?.available_quantity > 1 ? 'pcs' : 'pc'}</div>
                                                                                                        ) : (item?.available_quantity > 10) ? (
                                                                                                            <div><span className="text-success font600">{item?.available_quantity}</span> pcs</div>
                                                                                                        ) : (
                                                                                                            <div><span className="text-danger font600">{item?.available_quantity}</span> pc</div>
                                                                                                        )
                                                                                                    }
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <div><span className="text-danger font600">{item?.available_quantity}</span> pc</div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </td>

                                                                                    <td className="align-middle">
                                                                                        <input
                                                                                            className="numField"
                                                                                            type="number"
                                                                                            value={item?.price}
                                                                                            placeholder="0.00"
                                                                                            onChange={e => handlePriceChange(index, e.target.value)}
                                                                                            style={{ width: '100px', paddingInline: '3px' }}
                                                                                            onFocus={(event) => {
                                                                                                event.target.select();
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="align-middle text-left">
                                                                                        {currency.value === 'usd' ? ' $ ' : (currency.value === 'aed' ? ' AED ' : ' € ')}{' '}
                                                                                        {item.total_price ? parseFloat(item.total_price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}
                                                                                    </td>
                                                                                    <td className="align-middle text-center">
                                                                                        <span
                                                                                            className="btnEdit btn-delete-row payment-delete-icon-2"
                                                                                            onClick={() => removeRow(index)}
                                                                                        >
                                                                                            <FaTimes />
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </tbody>

                                                                <tfoot>
                                                                    <tr className='tr-nth'>
                                                                        {/* <td className="align-middle pl-3 estimateCName">
                                                                    <button type="button" onClick={() => addRow()} className="btn btn-primary btn-outlined-main btn-add-row">
                                                                        <FaPlus /> Add new row
                                                                    </button>
                                                                </td> */}
                                                                        <td className="align-middle" colspan={2}>
                                                                            <div className="form-group col-md-12 mb-0 text-center">
                                                                                {/* <label>{totalQuantity}</label> */}
                                                                            </div>
                                                                        </td>
                                                                        <td className='align-middle total-process text-center'>
                                                                            {totalQuantity ? totalQuantity : 0}
                                                                        </td>
                                                                        <td></td>
                                                                        <td className='total-process text-right'>
                                                                            Total
                                                                        </td>
                                                                        <td style={{ textAlign: 'left' }} className='total-process'>
                                                                            {currency.value === 'usd' ? ' $ ' : (currency.value === 'aed' ? ' AED ' : ' € ')}{' '}
                                                                            {total ? parseFloat(total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                            {/* </Scroller> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="btnarea" style={{ justifyContent: 'right' }}>
                                <div className="">

                                    <div className="row">
                                        <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                            <button
                                                className="btn btn-main-primary btn-outlined-main"
                                                style={{ marginRight: '4px' }}
                                                disabled={isSubmitted}
                                                onClick={() => submitCreatePurchaseOrder()}
                                            >
                                                {/* {
                                                    sendingEstimate ? (
                                                        <>
                                                            <FaSpinner className='spinner' /> Sending {openPopup}
                                                        </>
                                                    ) : (
                                                        <> */}
                                                {/* <svg
                                                    id="fi_2983788"
                                                    enable-background="new 0 0 512.005 512.005"
                                                    height="18"
                                                    viewBox="0 0 512.005 512.005"
                                                    width="18"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g>
                                                        <path
                                                            d="m511.658 51.675c2.496-11.619-8.895-21.416-20.007-17.176l-482 184c-5.801 2.215-9.638 7.775-9.65 13.984-.012 6.21 3.803 11.785 9.596 14.022l135.403 52.295v164.713c0 6.948 4.771 12.986 11.531 14.593 6.715 1.597 13.717-1.598 16.865-7.843l56.001-111.128 136.664 101.423c8.313 6.17 20.262 2.246 23.287-7.669 127.599-418.357 122.083-400.163 122.31-401.214zm-118.981 52.718-234.803 167.219-101.028-39.018zm-217.677 191.852 204.668-145.757c-176.114 185.79-166.916 176.011-167.684 177.045-1.141 1.535 1.985-4.448-36.984 72.882zm191.858 127.546-120.296-89.276 217.511-229.462z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </g>
                                                </svg>{' '} */}
                                                {
                                                    isSubmitted ? (
                                                        <>
                                                            <FaSpinner className='spinner' /> Creating purchase order...
                                                        </>
                                                    ) : (
                                                        <>
                                                            Create
                                                        </>
                                                    )
                                                }
                                            </button>
                                            <button onClick={handleClose} className="btn btn-main-primary btn-outlined-main" style={{ marginLeft: '4px' }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PurchaseOrderModal;
