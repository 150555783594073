import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { CommonDateOnlyFormat } from 'helpers/common.helper';


const Logs = ({ logs }) => {

    return (
        <>
            <div>
                {
                    logs?.length > 0 && (
                        <>
                            {
                                logs?.map((log, index) => (
                                    <>
                                        <div className="row" style={{ padding: '0 10px', marginBottom: '10px' }}>
                                            <div className="col-12">
                                                <div className='d-flex'>
                                                    <div>
                                                        <div className="contact-circle" style={{ height: '30px', width: '30px', marginTop: '5px' }}>
                                                            { log?.created_by?.name.split(' ').map((word) => word[0]).join('').toUpperCase() }
                                                        </div>

                                                    </div>
                                                    <div style={{ marginLeft: '10px' }}>

                                                        <div className='textGray' style={{ fontStyle: 'italic' }}>
                                                            <div>
                                                                <span dangerouslySetInnerHTML={{ __html: log?.description }} /> by {log?.created_by?.name}
                                                            </div>
                                                            <div>
                                                                { moment(log?.created_at).format(CommonDateOnlyFormat) }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-1 d-flex" style={{ alignItems: 'center', width: '100%', padding: '0' }}>
                                                <div className="contact-circle" style={{ height: '30px', width: '30px',  marginLeft: '10px' }}>
                                                    { log?.created_by?.name.split(' ').map((word) => word[0]).join('').toUpperCase() }
                                                </div>
                                            </div>
                                            <div className="col-11">
                                    
                                                <div className='textGray' style={{ fontStyle: 'italic' }}>
                                                    <div>
                                                        {log?.description} by {log?.created_by?.name}
                                                    </div>
                                                    <div>
                                                        { moment(log?.created_at).format(CommonDateOnlyFormat) }
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </>
                                ))
                            }
                        </>
                    )
                }
            </div>

        </>
    );
};

export default Logs;
