import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import { Slide, Fade } from 'react-awesome-reveal';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const NotesModal = ({ updateNotes, show, handleClose, selectedImeis }) => {
	const user = useSelector((state) => state.main.user.id);
	const test = useSelector((state) => state.main.user);
	const userInitial = useSelector((state) => state.main.user.first_name.charAt(0));
	const userPhoto = useSelector((state) => state.main.user.profile_photo_url);
	const [createNote, setCreateNote] = useState(false);
	const [wordCount, setWordCount] = useState(0);
	const [inputText, setInputText] = useState('');
	const [notes, setNotes] = useState([]); // Add this line
	const [expandedNotes, setExpandedNotes] = useState([]);

	useEffect(() => {
		setNotes(selectedImeis.notes);
		console.log(test);
	}, [selectedImeis.notes]);

	const handleAddNote = (selected) => {
		const returnsId = selectedImeis.id;
		api.addReturnsNotes(user, inputText, returnsId).then((res) => {
			if (res.status) {
				toast.success(res.message);
				setCreateNote(false);
				updateNotes(res.data); // Update notes in parent component
				setNotes((prevNotes) => [...prevNotes, res.data]); // Append new note to existing notes
			} else {
				toast.error(res.message);
			}
		});
	};

	console.log(notes);
	const createNotes = () => {
		setCreateNote(true);
	};
	const closeCreateNote = () => {
		setCreateNote(false);
	};
	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header>
				<Modal.Title>
					Notes
					<button className="btn modal-close-icon" style={{ float: 'right' }} onClick={handleClose}>
						<svg
							id="Component_127_1"
							height="16"
							width="16"
							data-name="Component 127 – 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 19.295 19.289"
						>
							<path
								id="Icon_ionic-ios-close"
								data-name="Icon ionic-ios-close"
								d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
								transform="translate(-11.285 -11.289)"
							/>
						</svg>
					</button>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ maxHeight: '600px', overflowY: 'auto', paddingBottom: 0 }}>
				{notes &&
					notes.length > 0 &&
					notes
						.sort((a, b) => {
							const dateDiff = new Date(b.created_at) - new Date(a.created_at);
							if (dateDiff !== 0) {
								return dateDiff;
							} else {
								return b.id - a.id;
							}
						})
						.map((note, index) => {
							const isExpanded = expandedNotes.includes(index);
							return (
								<div
									className="row note-row"
									style={{ marginBottom: 5, boxShadow: isExpanded ? '0 0 5px #e1d9d9' : 'none' }}
									onClick={() => {
										if (isExpanded) {
											setExpandedNotes(expandedNotes.filter((noteIndex) => noteIndex !== index));
										} else {
											setExpandedNotes([...expandedNotes, index]);
										}
									}}
								>
									<div className="col-12" style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
										{note.user.image ? (
											<img
												src={note.user.image}
												alt="User"
												style={{
													width: '30px',
													height: '30px',
													borderRadius: '50%',
													marginRight: 10
												}}
											/>
										) : (
											<div
												style={{
													width: '30px',
													height: '30px',
													borderRadius: '50%',
													marginRight: 10,
													backgroundColor: '#ccc',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													color: '#fff',
													fontSize: '15px'
												}}
											>
												{userInitial}
											</div>
										)}
										{note.user && note.created_at && (
											<>
												<a style={{ flex: 1 }}>{note.user.name}</a>
												<a style={{ marginLeft: 'auto', marginRight: '5px' }}>{note.created_at}</a>
											</>
										)}
									</div>
									{isExpanded && (
										<Fade>
											<div className="row" style={{ marginLeft: 5, marginRight: 5 }}>
												<div className={`col-12 notes-body slide-down`} style={{ padding: 20 }}>
													<p dangerouslySetInnerHTML={{ __html: (note.note || note).replace(/\n/g, '<br />') }} />
												</div>
											</div>
										</Fade>
									)}
								</div>
							);
						})}

				{createNote ? (
					<div
						className="create-note"
						style={{
							boxShadow: '0 0 5px #e1d9d9',
							padding: 10,
							borderRadius: '10px',
							position: 'sticky',
							bottom: 0,
							backgroundColor: '#ffffff'
						}}
					>
						<div className="row">
							<div className="col-12">
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{userPhoto ? (
											<img
												src={userPhoto}
												alt="User"
												style={{
													width: '30px',
													height: '30px',
													borderRadius: '50%',
													marginRight: 10
												}}
											/>
										) : (
											<div
												style={{
													width: '30px',
													height: '30px',
													borderRadius: '50%',
													marginRight: 10,
													backgroundColor: '#ccc',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													color: '#fff',
													fontSize: '15px'
												}}
											>
												{userInitial}
											</div>
										)}
										<a>You</a>
									</div>
									<button
										style={{ alignSelf: 'flex-start', position: 'static' }}
										className="btn modal-close-icon"
										onClick={closeCreateNote}
									>
										<FaTimes />
									</button>
								</div>
								<textarea
									className="form-control"
									rows="5"
									placeholder="Add a note..."
									value={inputText}
									onChange={(e) => {
										const words = e.target.value.split(/\s+/).filter((word) => word);
										if (words.length > 30) {
											e.target.value = words.slice(0, 30).join(' ');
										}
										setWordCount(words.length);
										setInputText(e.target.value);
									}}
								/>
								<div style={{ borderRadius: '10px', width: '100%', height: '10px', backgroundColor: '#f3f3f3', marginTop: 10 }}>
									<div
										style={{
											borderRadius: '10px',
											width: `${(wordCount / 30) * 100}%`,
											height: '100%',
											backgroundColor: '#1cbf72',
											transition: 'width 0.5s ease-in-out'
										}}
									></div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button className="btn btn-main" onClick={handleClose}>
					Close
				</Button>

				{createNote ? (
					<Button className="btn btn-main-primary" onClick={handleAddNote} disabled={wordCount === 0}>
						Save Note
					</Button>
				) : (
					<Button className="btn btn-main-primary" onClick={createNotes}>
						Add Note
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default NotesModal;
