import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {

	async getSalesOrdersLists(statusType, filters, search = null, sortByFilter = null) {
		let url = `${base}/api/sales-orders?${statusType ? 'statusType=' + statusType + '&' : ''}`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		if (sortByFilter) {
			url += `&sortBy=${sortByFilter?.field}&sortOrder=${sortByFilter?.direction}`;
		}

		const result = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
		// }, false);
    },
    
	getSalesOrdersFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			const url = `${base}/api/sales-orders/filter`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	saveDraftSalesOrder(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/sales-orders/draft`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateSalesOrder(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/sales-order/${id}`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	deleteSalesOrder(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/sales-order/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	saveSalesOrder(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/sales-order/${id}/save`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// undraftSalesOrder(id) {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.get(`${base}/api/sales-orders/${id}/undraft`, {
	// 				headers: {
	// 					Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
	// 						}`
	// 				}
	// 			})
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				apiResponseErrorHandler(rej);
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },
	async getSalesOrderById(id) {
		// return this.handelAlert((res, rej, loadObj) => {
		// 	;
		// }, false);

		const result = await axios.get(`${base}/api/sales-order/${id}`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
	},

	sendSalesOrderByEmail(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/sales-orders/sendsalesorders`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},


	downloadSalesOrder(est_id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/sales-order/${est_id}/get-sales-order-file`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	addSalesOrderPayment(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/sales-orders/payment`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	addSalesOrderPaymentFile(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/sales-orders/upload-payments`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},


	newSalesOrderNote(est_id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/sales-order/${est_id}/notes`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	removeSalesOrderNote(salesOrderId, noteId) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/sales-order/${salesOrderId}/notes/${noteId}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	cancelSalesOrder(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/sales-order/${id}/cancel`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	closeSalesOrder(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/sales-order/${id}/close`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	loadDetailsForProcessOrder(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/sales-order/${id}/load-process`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	createSalesOrderPurchaseOrder(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/sales-orders/create-purchase-order`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	createSalesOrderPicklist(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/sales-orders/create-picklist`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	createInvoiceFromSalesOrder(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/sales-orders/create-invoice`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	quickSummarySalesOrder(filters, search = null, currency) {
		let url = `${base}/api/sales-orders/sql/quick-stats?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					console.log(response);
					res(response.data);
				})
				.catch((rej) => {
					res(rej?.response?.status);
				});
		}, false);
	},

	downloadListingSalesOrder(status, filters, search = null, currency) {
		let url = `${base}/api/sales-orders/sql/export-results?${status ? 'statusType=' + status + '&' : ''}`;

		Object.keys(filters).forEach((key) => {
			// if (key != 'startDate' && key != 'endDate') {
			filters[key]?.value && (url += `${key}=${filters[key]?.value}&`);
			// } else {
			// 	url += `${key}=${filters[key]}&`;
			// }
		});

		if (search) {
			url += `search=${search}&`;
		}

		if (currency) {
			url += `currency=${currency}&`;
		}
		// if (excel == 1) {
		url += `isExcel=1`;
		// }
		url = url.endsWith('&') ? url.slice(0, -1) : url;
		// const result = axios.post(url,status, {
		// 	headers: {
		// 		Authorization: `Bearer ${
		// 			JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
		// 		}`
		// 	},
		// 	// responseType: 'blob'
		// });

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(url, status, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					console.log(response);
					res(response.data);
				})
				.catch((rej) => {
					res(rej?.response?.status);
				});
		}, false);
	},
}