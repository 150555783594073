import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setStateProductRepairlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Tableheader from 'ui-component/Tableheader';
import { Dropdown, InputGroup } from 'react-bootstrap';
import Pagination from 'ui-component/Pagination';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Scroller from 'ui-component/Scroller';
import NewRepair from './AddRepair';
import downloadIcon from 'assets/images/icons/download.svg';
import { FaDownload, FaPlus, FaSearch } from 'react-icons/fa';
import usePageTitle from 'hooks/usePageTitle';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';

const Repairs = () => {
	const history = useNavigate();
	const [repairList, setRepairList] = useState([]);
	const [show, setShow] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	// const customerList = useSelector((state) => state.main.setcustomerlist);
	const [pagination, setpagination] = useState([]);
	const [newRepair, setNewRepair] = useState(false);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([
		{
			label: 'Status',
			name: 'status',
			options: [
				{ label: 'Active', value: 1 },
				{ label: 'Inactive', value: 0 }
			]
		}
	]);
	const [activeFilters, setActiveFilters] = useState({});

	const handleRepairClose = (data) => {
		if (data == 'reload') {
			getRepairList();
			setNewRepair(false);
		} else {
			setNewRepair(false);
		}
	};

	const calculateProfit = (currency_type, repair_price, price, cost) => {
		let profit = 0;
		if (currency_type === 'AED') {
			const cal = price?.unit_price_aed - (cost?.unit_price_aed + repair_price?.cost_aed);
			profit = Math.round(cal);
		} else if (currency_type === 'EURO') {
			const cal = price?.unit_price_eur - (cost?.unit_price_eur + repair_price?.cost_eur);
			profit = Math.round(cal);
		} else if (currency_type === 'USD') {
			const cal = price?.unit_price_usd - (cost?.unit_price_usd + repair_price?.cost_usd);
			profit = Math.round(cal);
		}
		return price === null ? 0 : profit;
	};

	const calculatePrice = (currency_type, price) => {
		let selling_price = 0;
		if (currency_type === 'AED') {
			selling_price = 'AED ' + Math.round(price?.unit_price_aed);
		} else if (currency_type === 'EURO') {
			selling_price = '€' + Math.round(price?.unit_price_eur);
		} else if (currency_type === 'USD') {
			selling_price = '$ ' + Math.round(price?.unit_price_usd);
		}
		return price === null ? 0 : selling_price;
	};

	const calculateCost = (currency_type, cost) => {
		let purchase_price = 0;
		if (currency_type === 'AED') {
			purchase_price = 'AED ' + Math.round(cost?.unit_price_aed);
		} else if (currency_type === 'EURO') {
			purchase_price = '€' + Math.round(cost?.unit_price_eur);
		} else if (currency_type === 'USD') {
			purchase_price = '$ ' + Math.round(cost?.unit_price_usd);
		}
		return cost === null ? 0 : purchase_price;
	};

	const calculateRepair = (currency_type, repair_price) => {
		let repair = 0;
		if (currency_type === 'AED') {
			repair = 'AED ' + Math.round(repair_price?.cost_aed);
		} else if (currency_type === 'EURO') {
			repair = '€' + Math.round(repair_price?.cost_eur);
		} else if (currency_type === 'USD') {
			repair = '$ ' + Math.round(repair_price?.cost_usd);
		}
		return repair_price === null ? 0 : repair;
	};

	const getRepairList = (internal = false) => {
		setIsLoading(true);
		api.getProductRepairList().then((res) => {
			if (res.status) {
				setRepairList(res.data.data);
				setpagination(res.data);
				setIsLoading(false);
				dispatch(setStateProductRepairlist(res.data));
			}
		});
	};
	const deleteuserData = (data) => {
		setShow(data);
	};

	const handleClose = () => setShow('');

	const handleDelete = (id) => {
		// api.deleteCustomer(id).then((res) => {
		// 	if (res.status) {
		// 		setShow('');
		// 		getRepairList();
		// 		dispatch(storeInLocalStore({ stateAlertmessage: 'Customer Deleted Successfully.' }));
		// 	}
		// });
	};
	const viewUserData = (id) => {
		const currentUser = repairList.filter((item) => item.id === id);
		dispatch(storeInLocalStore({ selectedUser: currentUser[0] }));
		setTimeout(() => {
			history(`/contacts/${id}`);
		}, 1000);
	};
	const handleSearch = (e) => {
		const text = e.target.value;
		if (text.length > 2) {
			setIsLoading(true);
			api.searchProductRepair(text).then((res) => {
				setIsLoading(false);
				setRepairList(res.data.data);
				setpagination(res.data);
			});
		}
		if (text.length == 0) {
			getRepairList(true);
		}
	};
	const updateUser = (id) => {
		const currentUser = repairList.filter((item) => item.id === id);
		dispatch(storeInLocalStore({ selectedUser: currentUser[0] }));
		setTimeout(() => {
			history(`/contacts/edit/${id}`);
		}, 1000);
	};
	const handleData = (data) => {
		setRepairList(data.data);
		setpagination(data);
	};
	useEffect(() => {
		// if (!customerList) {
		getRepairList();
		// } else {
		// 	setRepairList(customerList.data);
		// 	setpagination(customerList);
		// }
	}, []);

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		backgroundColor: '#f2f2f2',
		zIndex: 1
	};
	const handleFilter = (e) => {
		setIsLoading(true);
		const status = e.target.value;
		api.filterCustomer(status).then((res) => {
			setIsLoading(false);
			setRepairList(res.data.data);
		});
	};
	return (
		<>
			<div className="row">
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<h2>Repairs</h2>
						<div className="actions">
							<>
								<Link to={''} className="btn btn-primary btn-outlined-main">
									<FaDownload /> Download
								</Link>
								<Link to="" onClick={() => setNewRepair(true)} className="btn btn-primary btn-outlined-main">
									<FaPlus /> Create
								</Link>
							</>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="table-responsive-xl tableData customersList p-2">
							<Scroller>
								<table className="table ">
									<thead>
										<tr>
											<th className="align-middle">
												<input type="checkbox" />
											</th>
											<th scope="col">Date</th>
											<th scope="col">Model</th>
											<th scope="col">Colour</th>
											<th scope="col">IMEI</th>
											<th scope="col">Supplier</th>
											<th scope="col">Price</th>
											<th scope="col">Cost</th>
											<th scope="col">Repair</th>
											<th scope="col">Profit</th>
											<th scope="col">In Stock</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<>
												{[...Array(5)].map((e, i) => (
													<tr>
														{[...Array(11)].map((e, i) => (
															<td>
																<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
															</td>
														))}
													</tr>
												))}
											</>
										) : (
											<>
												{!isLoading &&
													repairList?.map((row, index) => {
														const profit = calculateProfit(
															row?.currency,
															row?.currency_cost,
															row?.product?.currency_selling_unit_price,
															row?.product?.currency_purchase_unit_price
														);
														const currencySymbol =
															row?.currency === 'USD' ? '$' : row?.currency === 'EURO' ? '€' : 'AED';
														return (
															<>
																<tr key={index}>
																	<td className="text-center pl-2">
																		<input type="checkbox" />
																	</td>
																	<td className="align-middle">{row?.created_at}</td>

																	<td className="align-middle">{row?.product?.name}</td>
																	<td className="align-middle">{row?.product?.color?.name}</td>
																	<td className="align-middle">{row?.product?.imei}</td>
																	<td className="align-middle">{row?.product?.supplier?.name}</td>
																	<td className="align-middle">
																		{calculatePrice(
																			row?.currency,
																			row?.product?.currency_selling_unit_price
																		)}
																	</td>
																	<td className="align-middle">
																		{calculateCost(
																			row?.currency,
																			row?.product?.currency_purchase_unit_price
																		)}
																	</td>
																	<td className="align-middle">
																		{calculateRepair(row?.currency, row?.currency_cost)}
																	</td>
																	<td className="align-middle text-green">
																		{profit >= 0 ? (
																			<>
																				<span className="text-success">
																					{currencySymbol}
																					{' ' + profit}
																				</span>
																			</>
																		) : (
																			<>
																				<span className="text-danger">
																					-{currencySymbol}
																					{' ' + Math.abs(profit)}
																				</span>
																			</>
																		)}
																	</td>
																	<td className="align-middle">{row?.product?.in_stock}</td>
																</tr>
															</>
														);
													})}
												{repairList?.length < 1 && (
													<tr className="no-hover">
														<td colSpan={11}>
															<div className="not-found-container">
																<div className="not-found-icon">
																	<TbDatabaseExclamation />
																</div>
																<h6 className="not-found-text">No Data Available</h6>
															</div>
														</td>
													</tr>
												)}
											</>
										)}
									</tbody>
								</table>
							</Scroller>
						</div>
						<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
					</div>
				</div>
			</div>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Body>
					<Modal.Title>Confirm Delete</Modal.Title>
					<p>Are you sure you want to delete {`${show.name}`}</p>
					<Button variant="primary" className="m-2 " onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="m-2"
						onClick={() => {
							handleDelete(show.id);
						}}
					>
						Delete
					</Button>
				</Modal.Body>
			</Modal>
			<NewRepair newRepair={newRepair} handleRepairClose={handleRepairClose} />
		</>
	);
};

export default Repairs;
function CustomToggle({ children, onClick }) {
	return (
		<>
			<a
				className="dropdown-toggle"
				href="#"
				role="button"
				id="dropdownMenuLink"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				onClick={onClick}
			>
				{children}
			</a>
		</>
	);
}
