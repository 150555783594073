import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import CloseIcon from 'assets/images/close-icon.svg';


const SendEmailModal = ({ openPopup, selectedEstimate, handleClose, sendEstimateEmail, sendingEstimate, selectedForSending, disableStatus }) => {
    const [formInfo, setFormInfo] = useState({
        estimationId: '',
    });

    const [sendingType, setSendingType] = useState('auto');
    const [labelType, setLabelType] = useState('');
    const handleValue = (val) => {
        setFormInfo({
            ...formInfo,
            estimationId: val?.id
        });
        setFormInfo({ estimationId: val?.value });
    };


    const [emailDetails, setEmailDetails] = useState({
        receiver: '',
        subject: '',
        message: '',
        id: selectedForSending,
        type: openPopup,
    });



    useEffect(() => {
        let sendType = '';
        if (openPopup == 'quotation') {
            sendType = 'send_estimate';
            setLabelType('quotation');
        } else if (openPopup == 'sales_order') {
            sendType = 'send_sales_order';
            setLabelType('sales order');
        } else {
            sendType = 'send_invoice';
            setLabelType('invoice');
        }
        // const sendType = openPopup == 'estimate' ? 'send_estimate' : 'send_invoice';
        api.getEmailTemplate(`${sendType}`).then((res) => {
            
            let salesType = '';

            if (openPopup == 'quotation') {
                salesType = 'Quotation';
            } else if (openPopup == 'sales_order') {
                salesType = 'Sales Order';
            } else if (openPopup == 'invoice') {
                salesType = 'Invoice';
            } else {
                salesType = 'Credit Note';
            }

            // const salesType = openPopup == 'estimate' ? 'Estimate ' : openPopup == 'invoice' ? 'Invoice' : 'Credit Note ';
            if (openPopup == 'quotation') {

                let subject = res?.data?.subject?.replace(/{estimate_number}/g, selectedEstimate?.estimation_number);
                subject = subject?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
                subject = subject?.replace(/{sale_type}/g, salesType);

                if (selectedEstimate?.logistic?.name) {
                    subject = subject?.replace(/{logistic_name}/g, selectedEstimate?.logistic?.name);
                }
                let message = res?.data?.template?.replace(/{estimate_number}/g, selectedEstimate?.estimation_number);
                message = message?.replace(/{sale_type}/g, salesType);

                if (selectedEstimate?.transit_company?.name) {
                    message = message?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
                }
                if (selectedEstimate?.customer?.contact_name) {
                    message = message?.replace(/{final_customer_name}/g, selectedEstimate?.customer?.contact_name);
                }
                if (res?.data?.client_name) {
                    message = message?.replace(/{projectmix_user}/g, res?.data?.client_name);
                }

                const email = (selectedEstimate?.transit_company) ? selectedEstimate?.transit_company?.email : selectedEstimate?.customer?.email;
                
                setEmailDetails({ ...emailDetails, subject: subject, message: message, receiver: email });
            } else if (openPopup == 'sales_order') {

                let subject = res?.data?.subject?.replace(/{sales_order_number}/g, selectedEstimate?.sales_order_number);
                subject = subject?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
                subject = subject?.replace(/{sale_type}/g, salesType);

                let message = res?.data?.template?.replace(/{sales_order_number}/g, selectedEstimate?.sales_order_number);
                message = message?.replace(/{sale_type}/g, salesType);

                if (selectedEstimate?.transit_company?.name) {
                    message = message?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
                }
                if (selectedEstimate?.customer?.contact_name) {
                    message = message?.replace(/{final_customer_name}/g, selectedEstimate?.customer?.contact_name);
                }
                if (res?.data?.client_name) {
                    message = message?.replace(/{projectmix_user}/g, res?.data?.client_name);
                }

               const email = (selectedEstimate?.transit_company) ? selectedEstimate?.transit_company?.email : selectedEstimate?.customer?.email;

                setEmailDetails({ ...emailDetails, subject: subject, message: message, receiver: email });
            } else {
                let hasLogistic = false;

                if (selectedEstimate?.logistic) {
                    hasLogistic = true;
                }

                let subject = res?.data?.subject?.replace(/{estimate_number}/g, selectedEstimate?.estimation_number);
                subject = subject?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
                subject = subject?.replace(/{sale_type}/g, salesType);

                if (selectedEstimate?.invoice_number) {
                    subject = subject?.replace(/{invoice_number}/g, selectedEstimate?.invoice_number);
                }

                if (hasLogistic) {
                    subject = subject?.replace(/{logistic_name}/g, selectedEstimate?.logistic?.name);
                } else {
                    subject = subject?.replace(/{logistic_name}/g, selectedEstimate?.transit_company?.name);
                }

                let message = res?.data?.template?.replace(/{estimate_number}/g, selectedEstimate?.estimation_number);
                message = message?.replace(/{invoice_number}/g, selectedEstimate?.invoice_number);
                message = message?.replace(/{sale_type}/g, salesType);
                if (hasLogistic) {
                    if (selectedEstimate?.logistic?.contact_name) {
                        message = message?.replace(/{logistic_name}/g, selectedEstimate?.logistic?.contact_name);
                    }
                } else if (selectedEstimate?.transit_company?.name) {
                    message = message?.replace(/{logistic_name}/g, selectedEstimate?.transit_company?.contact_name);
                }
                if (selectedEstimate?.transit_company?.name) {
                    message = message?.replace(/{transit_company_name}/g, selectedEstimate?.transit_company?.name);
                }
                if (selectedEstimate?.customer?.contact_name) {
                    message = message?.replace(/{final_customer_name}/g, selectedEstimate?.customer?.contact_name);
                }
                if (res?.data?.client_name) {
                    message = message?.replace(/{projectmix_user}/g, res?.data?.client_name);
                }
                
               const email = (selectedEstimate?.transit_company) ? selectedEstimate?.transit_company?.email : selectedEstimate?.customer?.email;

                setEmailDetails({ ...emailDetails, subject: subject, message: message, receiver: email });
            }
        });
    }, []);
    const handleSendingType = (type) => {
        setSendingType(type)
        if (type == 'auto') {
            setEmailDetails({ ...emailDetails, receiver: '' });
        }
    }


    const sendEmail = () => {
        if (sendingType == 'auto') {

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!emailRegex.test(emailDetails?.receiver)) {
                toast.error(`Please enter valid email address`);
                return false;
            } else if (emailDetails?.subject.trim() == '') {
                toast.error(`Please enter email subject`);
                return false;
            } else {
                const data = {
                    receiver: emailDetails?.receiver,
                    subject: emailDetails?.subject,
                    message: emailDetails?.message,
                    sending_type: sendingType,
                    id: selectedForSending,
                    type: openPopup
                };
                sendEstimateEmail(data);
            }
        } else {
            const data = {
                receiver: emailDetails?.receiver,
                subject: emailDetails?.subject,
                message: emailDetails?.message,
                sending_type: sendingType,
                id: selectedForSending,
                type: openPopup
            };
            sendEstimateEmail(data);
        }
    }

    const disablepopup = () => {
        handleClose();
    };




    return (
        <>
            <Modal show={openPopup} onHide={disablepopup} centered size="lg">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-left">

                                        <h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0" style={{ fontWeight: '600' }}>
                                            Send{' '}
                                            {
                                                openPopup == 'quotation' ? (
                                                    'Quotation'
                                                ) : openPopup == 'sales_order' ? (
                                                    'Sales Order'
                                                ) : openPopup == 'invoice' ? (
                                                    'Invoice'
                                                ) : (
                                                    'Credit Note'
                                                )
                                            }
                                        </h5>
                                        <br />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    {/* <FaTimes /> */}
                                    <svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                                        <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                                    </svg>


                                </button>
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12">

                                    <p style={{ color: '#535355' }}>
                                        <input
                                            type="checkbox"
                                            checked={sendingType == 'auto' ? true : false}
                                            onClick={() => handleSendingType('auto')}
                                        />{' '}
                                        <span
                                            style={{ fontWeight: '600', marginLeft: '5px', fontSize: '14px', cursor: 'pointer' }}
                                            onClick={() => handleSendingType('auto')}
                                        >
                                            Send {labelType} by email
                                        </span>{' '}
                                        <span
                                            style={{ fontStyle: 'italic', color: '#989898', cursor: 'pointer' }}
                                            onClick={() => handleSendingType('auto')}
                                        >
                                            (the {labelType} will be added as an attachment to the email message)
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {
                                sendingType == 'auto' && (
                                    <>
                                        <div className="row align-items-top">
                                            <div className="col-md-2 offset-md-1 col-11">
                                                <label className="font500">Send to</label>
                                            </div>
                                            <div className="col-md-9 col-12 form-group">
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    value={emailDetails?.receiver}
                                                    onChange={(e) => setEmailDetails({ ...emailDetails, receiver: e.target.value })}
                                                    placeholder="Enter recipient's email address"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-md-2 offset-md-1 col-11">
                                                <label className="font500">Subject</label>
                                            </div>
                                            <div className="col-md-9 col-12 form-group">
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    value={emailDetails?.subject}
                                                    onChange={(e) => setEmailDetails({ ...emailDetails, subject: e.target.value })}
                                                    placeholder="Enter email subject"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-md-2 offset-md-1 col-11">
                                                <label className="font500">Email message</label>
                                            </div>
                                            <div className="col-md-9 col-12 form-group">
                                                <textarea
                                                    className='form-control'
                                                    rows="10"
                                                    value={emailDetails?.message}
                                                    onChange={(e) => setEmailDetails({ ...emailDetails, message: e.target.value })}
                                                    style={{ resize: 'none' }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                selectedEstimate?.email_sent == 0 && (
                                    <>
                                        <div className="row align-items-top">
                                            <div className="col-12">

                                                <p style={{ color: '#535355' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={sendingType == 'manual' ? true : false}
                                                        onClick={() => handleSendingType('manual')}
                                                    />{' '}
                                                    <span
                                                        style={{ fontWeight: '600', marginLeft: '5px', fontSize: '14px', cursor: 'pointer' }}
                                                        onClick={() => handleSendingType('manual')}
                                                    >
                                                        Send {labelType} manually
                                                    </span>{' '}
                                                    <span
                                                        style={{ fontStyle: 'italic', color: '#989898', cursor: 'pointer' }}
                                                        onClick={() => handleSendingType('manual')}
                                                    >
                                                        (the {labelType} will be marked as sent)
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            <div className="btnarea" style={{ justifyContent: 'right' }}>
                                <div className="">

                                    <div className="row">
                                        <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                            <button
                                                className="btn btn-main-primary btn-outlined-main"
                                                style={{ marginRight: '4px' }}
                                                disabled={!emailDetails?.message.trim() || sendingEstimate}
                                                onClick={() => sendEmail()}
                                            >
                                                {
                                                    sendingEstimate ? (
                                                        <>
                                                            <FaSpinner className='spinner' /> Sending {' '}
                                                            {
                                                                                                                    
                                                                openPopup == 'quotation' ? (
                                                                    'Quotation'
                                                                ) : openPopup == 'sales_order' ? (
                                                                    'Sales Order'
                                                                ) : openPopup == 'invoice' ? (
                                                                    'Invoice'
                                                                ) : (
                                                                    'Credit Note'
                                                                )
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            <svg
                                                                id="fi_2983788"
                                                                enable-background="new 0 0 512.005 512.005"
                                                                height="18"
                                                                viewBox="0 0 512.005 512.005"
                                                                width="18"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g>
                                                                    <path
                                                                        d="m511.658 51.675c2.496-11.619-8.895-21.416-20.007-17.176l-482 184c-5.801 2.215-9.638 7.775-9.65 13.984-.012 6.21 3.803 11.785 9.596 14.022l135.403 52.295v164.713c0 6.948 4.771 12.986 11.531 14.593 6.715 1.597 13.717-1.598 16.865-7.843l56.001-111.128 136.664 101.423c8.313 6.17 20.262 2.246 23.287-7.669 127.599-418.357 122.083-400.163 122.31-401.214zm-118.981 52.718-234.803 167.219-101.028-39.018zm-217.677 191.852 204.668-145.757c-176.114 185.79-166.916 176.011-167.684 177.045-1.141 1.535 1.985-4.448-36.984 72.882zm191.858 127.546-120.296-89.276 217.511-229.462z"
                                                                        fill="currentColor"
                                                                    ></path>
                                                                </g>
                                                            </svg>{' '}
                                                                Send{' '}
                                                                {
                                                                                                                        
                                                                    openPopup == 'quotation' ? (
                                                                        'Quotation'
                                                                    ) : openPopup == 'sales_order' ? (
                                                                        'Sales Order'
                                                                    ) : openPopup == 'invoice' ? (
                                                                        'Invoice'
                                                                    ) : (
                                                                        'Credit Note'
                                                                    )
                                                                }
                                                        </>
                                                    )
                                                }
                                            </button>
                                            <button onClick={handleClose} className="btn btn-main-primary btn-outlined-main" style={{ marginLeft: '4px' }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SendEmailModal;
