import React from 'react';

function DirhamIcon() {
	return (
		<svg height="16" fill="currentColor" viewBox="0 0 512 512" width="16" xmlns="http://www.w3.org/2000/svg" id="fi_9328799">
			<g id="Layer_2" data-name="Layer 2">
				<g id="Dirham">
					<path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm0 477.72c-122.26 0-221.72-99.46-221.72-221.72s99.46-221.72 221.72-221.72 221.72 99.46 221.72 221.72-99.46 221.72-221.72 221.72z"></path>
					<path d="m130.38 326.43c-11.07-.15-20.67-10-20.54-21.14a21.29 21.29 0 0 1 21-20.67 20.91 20.91 0 0 1 -.13 41.82z"></path>
					<path d="m261.65 326.39a20.48 20.48 0 0 1 -14.65-6.15 20.81 20.81 0 0 1 14.5-35.56h.39a20.86 20.86 0 0 1 .1 41.71z"></path>
					<path d="m343.92 299.55c-20.22 0-39.13-12.74-51.88-35a18.57 18.57 0 0 1 16.1-27.81 18.65 18.65 0 0 1 16.13 9.33c5.73 10.08 13.33 16.33 19.73 16.33h.37c6.71-.26 13.61-6.86 18.91-18.09 9.24-19.56-18.89-62-33.14-78.29a18.58 18.58 0 0 1 28-24.45c6.3 7.19 60.93 71.67 38.77 118.6-11.42 24.17-30 38.52-51 39.36-.65 0-1.29 0-1.94 0z"></path>
					<path d="m198.3 376.78a18.56 18.56 0 0 1 -18-13.95c-20.34-79-12.94-101.29-4.23-111.85a23.89 23.89 0 0 1 17.5-9c21.44-1.5 40.41-8.72 42.87-12.88-.13-.94-1.19-3.9-5-8.59-8.26-10.16-16.7-15.59-25.09-16.13-.5 0-1-.05-1.48-.05-11.75 0-21.43 9-21.53 9a18.5 18.5 0 0 1 -26.18-.32 18.62 18.62 0 0 1 .28-26.31c2-2 20.69-19.56 47.38-19.56 1.12 0 2.26 0 3.41.09 19.34 1.09 36.85 11.11 52 29.8 16.52 20.33 14.75 36.59 10.35 46.65-10.12 23.26-43.58 31.32-62.69 34.05a4 4 0 0 0 -3.46 3.85c-.33 10.46 1.44 31.37 11.75 71.53a18.58 18.58 0 0 1 -17.88 23.67z"></path>
				</g>
			</g>
		</svg>
	);
}

export default DirhamIcon;
