import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import ModalComponent from './ModalComponent';
import ColapsibleTable from './components/ColapsibleTable';
import { FaSearch, FaRegFileExcel, FaRegComments } from 'react-icons/fa';
import { LuChevronDown } from 'react-icons/lu';
import './backorder.scss';
import { TfiReload } from 'react-icons/tfi';
import Filter from 'ui-component/filter-menu/Filter';
import { Dropdown } from 'react-bootstrap';
import Scroller from 'ui-component/Scroller';
import Pagination from 'ui-component/Pagination';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import usePageTitle from 'hooks/usePageTitle';
import { ProcessingIcon, ReadyIcon, EstimateIcon } from 'ui-component/custom-icons/icons';
import toast from 'react-hot-toast';
import { BiSolidPurchaseTag } from 'react-icons/bi';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { TbArrowRotaryStraight } from 'react-icons/tb';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';

const Backorders = () => {
	const [setPageTitle] = usePageTitle('Backorders');
	const history = useNavigate();
	const [alignment, setAlignment] = useState('v1');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const [activeComponent, setActiveComponent] = useState('order');
	const [filters, setFilters] = useState([]);
	const [searchField, setSearchField] = useState();
	const [activeFilters, setActiveFilters] = useState({});
	const [pagination, setpagination] = useState(null);
	const [foundOpen, setFoundOpen] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [products, setProducts] = useState([]);
	const [data, setData] = useState(null);
	const [invoiceList, setInvoiceList] = useState([]);
	const [selectedPurchaseList, setPurcahseListId] = useState(null);

	const [showModal, setShowModal] = useState(false);
	const [modelType, setModelType] = useState(null);
	const [modalOrders, setModalOrders] = useState([]);
	const handleComponentChange = (component) => {
		setActiveComponent(component);
		setSearchField('');
		setProducts([]);
	};
	const handleData = (data) => {
		setData(data?.data);
		// setProductList(data.data);
		setpagination(data);
	};

	useEffect(() => {
		api.getbackorders().then((res) => {
			console.log(res);
			setData(res?.data?.data);
			setInvoiceList(res?.data?.invoiceList);
			setpagination(res?.data);
		});
	}, [activeComponent]);

	const organizeDataByOrders = () => {
		const ordersDict = {};
		data?.forEach((order) => {
			const { estimation_id } = order;
			if (!ordersDict[estimation_id]) {
				ordersDict[estimation_id] = {
					estimation: order?.estimation?.invoice_number,
					estimation_id: order?.estimation_id,
					customer_name: order?.estimation?.customer?.name,
					date_created: order.date_created,
					purcahse_List_id: order.purchase_list_id,
					comments: order.notes,
					orders: []
				};
			}
			ordersDict[estimation_id].orders.push({
				product_id: order.product_id,
				product: order?.product?.product_name,
				quantity: order.quantity,
				available_quantity: order?.product?.free_stock_quantity,
				expected_date: order.expected_arrival_date,
				ordered_at: order.date_created
			});
		});
		const sortedOrders = Object.values(ordersDict).sort((a, b) => b.estimation_id - a.estimation_id);
		return sortedOrders;
	};

	const downloadReport = (type) => {
		const sortedOrders = organizeDataByOrders().sort((a, b) => {
			const numA = Number(a.estimation.replace(/[^0-9]/g, ''));
			const numB = Number(b.estimation.replace(/[^0-9]/g, ''));
			return numB - numA;
		});
		console.log(sortedOrders);
		if (type === 1) {
			setShowModal(true);
			setModelType('excel');
			setModalOrders(sortedOrders);
		} else if (type === 0) {
			setShowModal(true);
			setModelType('pdf');
			setModalOrders(sortedOrders);
		}
	};

	const handleClose = () => setShowModal(false);

	const organizeDataByProduct = () => {
		const productsDict = {};
		data?.forEach((order) => {
			const { product_id } = order;
			if (!productsDict[product_id]) {
				productsDict[product_id] = {
					product: order?.product?.product_name,
					product_id: order.product_id,
					purcahse_List_id: order.purchase_list_id,
					comments: false,
					orders: []
				};
			}
			const matchingComments = order?.estimation?.estimation_details
				.filter((detail) => detail.product_id === product_id)
				.map((detail) => detail.comment);
			if (matchingComments?.length > 0) {
				productsDict[product_id].hasComments = true;
			}
			productsDict[product_id].orders.push({
				estimation_id: order?.estimation_id,
				estimation: order?.estimation?.invoice_number,
				customer_name: order?.estimation?.customer?.name,
				date_created: order.date_created,
				quantity: order.quantity,
				available_quantity: order.available_quantity,
				expected_date: order.expected_arrival_date,
				ordered_at: order.date_created
			});
		});
		const sortedProducts = Object.values(productsDict).sort((a, b) => b.estimation_id - a.estimation_id);
		return sortedProducts;
	};

	const organizedOrders = activeComponent === 'order' ? organizeDataByOrders() : organizeDataByProduct();

	const handleSelectRow = (purchase_list_id) => {
		setFoundOpen(true);
		setPurcahseListId(purchase_list_id);
	};
	const closeFound = (data) => {
		if (data == 'reload') {
			setFoundOpen(false);
			setPurcahseListId(null);
			// this.forceUpdate();
		} else {
			setFoundOpen(false);
			setPurcahseListId(null);
		}
	};
	const SearchBackOrders = (search) => {
		setSearchField(search);
		api.getbackorders({ search }).then((res) => {
			setData(res?.data?.data);
			setpagination(res?.data);
		});
	};

	const processBackorders = () => {
		api.processBackorders().then((res) => {
			api.getbackorders().then((res) => {
				if (res?.status) {
					setSearchField('')
					toast.success('Backorders processed successfully');
					setData(res?.data?.data);
					setpagination(res);
				} else {
					toast.error('Failed to process backorders');
				}
			});
		});
	};
	const handleCheckboxUnchecked = (isChecked, group) => {
		if (!isChecked) {
			console.log('Checkbox is unchecked, performing action on group:', group);
			setProducts(
				products.filter((selectedGroup) => {
					if (typeof selectedGroup === 'object') {
						return selectedGroup.product_id !== group.product_id;
					}
					return selectedGroup !== group;
				})
			);
		}
	};
	const addTopurchaseOrder = () => {
		history('/purchaseall/create');
	};

	const dataToPass = { message: 'hello world' };

	const handleSelectAll = () => {
		console.log('Select all');
	};
	const handleCheckboxChecked = (isChecked, group) => {
		let data = group.orders;
		console.log('Checked', group);
		if (group?.product_id) {
			data = {
				product_id: group.product_id,
				product: group.product,
				quantity: group.orders.reduce((accumulator, order) => accumulator + (order.quantity || 0), 0)
			};
		}
		if (isChecked) {
			console.log('Checkbox is checked, performing action on group:', group);
			//    setSelectedRows([...selectedRows, group]);
			setProducts([...products, data]);
		}
	};

	useEffect(() => {
		setPurcahseListId(null);
	}, [activeComponent, activeFilters, searchField]);

	return (
		<div className="row">
			<div className="col-xl-12 col-lg-12">
				<div className="section-head-new" style={{ margin: '10px 0px' }}>
					<ul className="topListCom">
						<li
							className={`${activeComponent === 'order' ? 'active' : ''}`}
							onClick={() => {
								handleComponentChange('order');
							}}
						>
							<span className="topListIcon">
								<EstimateIcon width={14} height={14} />
							</span>
							<span className="topListText">Per order </span>
						</li>
						<li
							className={`${activeComponent === 'product' ? 'active' : ''}`}
							onClick={() => {
								handleComponentChange('product');
							}}
						>
							<span className="topListIcon">
								<ReadyIcon width={14} height={14} />
							</span>
							<span className="topListText">Per product </span>
						</li>
					</ul>
					<div className="actions">
						<PermissionAllowed permissions={['List Estimation (Page)']}>
							<Dropdown className="btnDel iconbtn" align={'end'}>
								<Dropdown.Toggle as="span">
									<Link to={''} className="btn btn-primary btn-outlined-main white-var btn-create-report" style={{ minWidth: 'unset' }}>
										<FaRegFileExcel style={{ fontSize: '1.4em' }} /> Export backorders <LuChevronDown />
									</Link>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => downloadReport(1)}>Excel</Dropdown.Item>
									<Dropdown.Item onClick={() => downloadReport(0)}>Pdf</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</PermissionAllowed>
						{/* <PermissionAllowed permissions={['List Credit Note (Page)']}>
								<button
									className="bulk-action btn-outlined-main"
									onClick={() => {
										history('/credit-note');
									}}
								>
									<span className="menuIcon">
										<TfiReload style={{ fontSize: '1.4em' }} />
									</span>
									<span>Run Autosplit again</span>
								</button>
							</PermissionAllowed> */}
						<PermissionAllowed permissions={['Create Estimation (Button)']}>
							<button className="bulk-action btn-outlined-main" onClick={() => processBackorders()}>
								<span className="menuIcon">
									<TfiReload style={{ fontSize: '1.4em' }} />
								</span>
								<span>Process backorders</span>
							</button>
						</PermissionAllowed>
						{/* <PermissionAllowed permissions={['Create Estimation (Button)']}>
							<Link
								to={`/purchaseall/create?data=${encodeURIComponent(JSON.stringify(products))}`}
								className={`bulk-action  btn-outlined-main-primary ${products.length === 0 ? 'disabled' : ''}`}
								style={{ textDecoration: 'none' }}
								disabled={products.length === 0}
							>
								<span className="menuIcon">
									<BiSolidPurchaseTag style={{ fontSize: '1.4em' }} />
								</span>
								<span>Add to Purchase Order</span>
							</Link>
						</PermissionAllowed> */}
					</div>
				</div>
				<div className="card shadow-sm  bg-white mb-4 table-card">
					<div className="d-flex align-items-center justify-content-between TableSerachBar">
						<div>
							<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
						</div>
						<form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
							<div className="input-group">
								<input
									type="text"
									className="form-control bg-white border"
									placeholder="Search"
									aria-label="Search"
									aria-describedby="basic-addon2"
									value={searchField}
									onChange={(e) => {
										SearchBackOrders(e.target.value);
									}}
									style={{ width: '100%' }}
								/>
								<span className="searchIcon">
									<FaSearch />
								</span>
							</div>
						</form>
					</div>
					<div className="estimation-table-area">
						<div className="table-side">
							<div className="table-responsive-xl tableData estimationList">
								<Scroller extraHeight={55}>
									<table className="table  min-width-unset tbl-space pm-table">
										<thead>
											<tr className="sticky-header">
												<th scope="col-6" width="60%" className="check-head">
													Product and order
												</th>
												<th scope="col" width="4%"></th>
												<th scope="col" width="10%">
													Available
												</th>
												<th scope="col" width="14%">
													Expected
												</th>
												<th scope="col" className="" width="10%">
													Created
												</th>
											</tr>
										</thead>
										<tbody>
											{
												organizedOrders?.length > 0 ? (
													<>
														{
															organizedOrders?.map((group) => (
																<React.Fragment key={group.key}>
																	<tr>
																		{activeComponent === 'order' ? (
																			<td className="check-head">
																				{/* <input
																					type="checkbox"
																					id={`row-${group.key}`}
																					// checked={isChecked}
																					onChange={(e) => {
																						const isChecked = e.target.checked;
																						// console.log('Checkbox state:', isChecked);
																						// console.log('Group ID:', group.purcahse_List_id);
																						if (isChecked) {
																							// console.log('Checkbox checked, calling handleCheckboxChecked');
																							handleCheckboxChecked(isChecked, group);
																						} else {
																							// console.log('Checkbox unchecked, calling handleCheckboxUnchecked');
																							handleCheckboxUnchecked(isChecked, group);
																						}
																					}}
																				/> */}
																				<Link to={`/sales/invoices/${group.estimation_id}`} className="Order-number">
																					{group.estimation}
																				</Link>
																				<Link to={`/sales/invoices/${group.estimation_id}`} className="Order-name">
																					<PrivacyModeContent>{group.customer_name}</PrivacyModeContent>
																				</Link>
																			</td>
																		) : (
																			<td className="check-head">
																				{/* <input
																					type="checkbox"
																					id={`row-${group.key}`}
																					onChange={(e) => {
																						const isChecked = e.target.checked;
																						console.log('Checkbox state:', isChecked);
																						console.log('Group ID:', group.purcahse_List_id);
																						if (isChecked) {
																							console.log('Checkbox checked, calling handleCheckboxChecked');
																							handleCheckboxChecked(isChecked, group);
																						} else {
																							console.log('Checkbox unchecked, calling handleCheckboxUnchecked');
																							handleCheckboxUnchecked(isChecked, group);
																						}
																					}}
																				/> */}
																				<Link to={`/products/${group.product_id}`} className="Order-number">
																					{group.product}
																				</Link>
																			</td>
																		)}
																		{/* <td> <Link to={`/sales/invoices/${order.estimation_id}`}   className='Order-number'>{order.estimation}</Link> {order.customer_name}</td> */}
																		<td className="comments-indication-column">
																			{group.comments && <FaRegComments className="comments-indication-icon" />}
																		</td>
																		<td colSpan="4"></td>
																	</tr>
																	{group?.orders?.map((item, index) => (
																		<tr
																			key={index}
																		//   onClick={() => handleSelectRow(group.purcahse_List_id)}
																		>
																			<td>
																				{activeComponent === 'order' ? (
																					<Link to={`/products/${item.product_id}`} className="table-cell-indented">
																						{item.product}
																					</Link>
																				) : (
																					<>
																						<Link to={`/sales/invoices/${item.estimation_id}`} className="table-cell-indented mr-rem">
																							{item?.estimation}
																						</Link>{' '}
																						<PrivacyModeContent>{item?.customer_name}</PrivacyModeContent>
																					</>
																				)}
																			</td>
																			<td></td>
																			<td>
																				{item?.product?.free_stock_quantity}
																				<span
																					className={item.available_quantity >= item.quantity ? 'backorder-available' : 'backorder-unavailable'}
																				>
																					{item.available_quantity >= item.quantity ? (
																						<>✓ {item.quantity}</>
																					) : (
																						`${item.available_quantity} / ${item.quantity}`
																					)}
																				</span>
																			</td>
																			{item?.expected_date !== null ? (
																				<td style={{ fontWeight: 500 }}>{moment(item.expected_date, 'DD-MM-YYYY').format('DD MMM YYYY')}</td>
																			) : (
																				<td style={{ fontWeight: 500 }}>Not yet purchased</td>
																			)}

																			<td>{moment(item?.ordered_at).format(CommonDateOnlyFormat)}</td>
																		</tr>
																	))}
																</React.Fragment>
															))
														}
													</>
												) : (
													<>
														<tr className="no-hover">
															<td colSpan={5}>
																<div className="not-found-container">
																	<div className="not-found-icon">
																		<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
																	</div>
																	<h6 className="not-found-text">No Data Available</h6>
																</div>
															</td>
														</tr>
													</>
												)
											}
										</tbody>
									</table>
								</Scroller>
							</div>
							<Pagination pagination={pagination} data={handleData} onLoading={(e) => { }} />
						</div>
						{foundOpen && (
							<Modal show={foundOpen} onHide={closeFound} centered className="custom-modal-two">
								<Modal.Body>
									<div className="p-4">
										<ColapsibleTable close={closeFound} purchaseList={selectedPurchaseList} />
									</div>
								</Modal.Body>
							</Modal>
						)}
					</div>
				</div>
			</div>
			<ModalComponent showModal={showModal} handleClose={handleClose} modelType={modelType} modalOrders={modalOrders} />
		</div>
	);
};

export default Backorders;
