import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaSearch, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import useSound from 'use-sound';
import errorSound from '../../../../assets/audio/error.mp3';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiPackageBold, PiTestTubeFill, PiWarningFill } from 'react-icons/pi';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import NoImage from 'assets/images/no-image.png';

const StockProductsModal = ({ openPopup, returnsDetail, handleImeis, handleClose, handleSave, disableStatus }) => {
	const user = useSelector((state) => state.main.user.id);
	const userPhoto = useSelector((state) => state.main.user.profile_photo_url);
	const [alertMessage, setAlertMessage] = useState();
	const [invoiceList, setInvoiceList] = useState([]);
	const [imeiInputValue, setImeiInputValue] = useState('');
	const [modelImeis, setModelImeis] = useState([]);
	const [stockImeis, setStockImeis] = useState([]);
	const [standardRepairTypes, setStandardRepairTypes] = useState([]);
	const imeiInputRef = useRef(null);
	const [errorSoundPlay] = useSound(errorSound);
	const [selectedRepairs, setSelectedRepairs] = useState('');
	const [confirmDisabled, setConfirmDisabled] = useState(true);
	const [otherReason, setOtherReason] = useState('');
	const [isValidCost, setIsValidCost] = useState(false);
	const [wordCount, setWordCount] = useState(0);
	const [inputText, setInputText] = useState('');

	const [notes, setNotes] = useState([]);
	const [addNotes, setAddNotes] = useState(false);
	const [selectedInboundItems, setSelectedInboundItems] = useState([]);

	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const returnReasons = [
		{ label: 'Incorrect Product', value: 1 },
		{ label: 'Defect', value: 2 },
		{ label: 'Others', value: 3 }
	];

	const returnIncorrectProductReason = [
		{ label: 'Quality', value: 1 },
		{ label: 'Storage', value: 2 },
		{ label: 'Color', value: 3 }
	];

	const [formInfo, setFormInfo] = useState({
		estimationId: ''
	});

	const handleIMEIInputKeydown = (event) => {
		if (event.key === 'Enter') {
			addModalImei();
		}
	};
	const handleConfirmImei = () => {
		addModalImei();
	};

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const handleDeleteImei = (index) => {
		const newStockImeis = stockImeis.filter((_, i) => i !== index);
		setStockImeis(newStockImeis);
	};

	const handleReasonForReturn = (selected, index) => {
		// console.log(selected)
		// console.log(index)
		if (selected?.value == 2) {
			const newStockImeis = stockImeis?.map((item, i) => {
				if (i == index) {
					return {
						...item,
						is_repair: true,
						repair_reason: selected?.label,
						repair_reason_value: selected?.value
					};
				} else {
					return item;
				}
			});
			setStockImeis(newStockImeis);
			if (stockImeis[index]?.warranty?.status == 'Valid') {
				setIsValidCost(false);
				console.log(stockImeis);
				console.log(stockImeis[index]?.warranty?.status);
			} else {
				setIsValidCost(false);
				console.log(stockImeis);
				console.log(stockImeis[index]?.warranty?.status);
			}
		} else if (selected?.value == 1) {
			const newStockImeis = stockImeis?.map((item, i) => {
				if (i == index) {
					return {
						...item,
						is_repair: false,
						repair_reason: selected?.label,
						repair_reason_value: selected?.value,
						repairs: []
					};
				} else {
					return item;
				}
			});
			setStockImeis(newStockImeis);
			setIsValidCost(false);
		} else {
			const newStockImeis = stockImeis?.map((item, i) => {
				if (i == index) {
					return {
						...item,
						is_repair: false,
						repair_reason: selected?.label,
						repair_reason_value: selected?.value,
						repairs: []
					};
				} else {
					return item;
				}
			});
			setAddNotes(true);
			setStockImeis(newStockImeis);
			setIsValidCost(true);
		}
	};

	const handleOtherReason = (event) => {
		const input = event.target.value;
		if (input.length > 0) {
			setIsValidCost(true);
		} else {
			setIsValidCost(false);
		}
		setOtherReason(input);
	};

	useEffect(() => {
		console.log(stockImeis);
	}, [stockImeis]);

	const handleNotes = (event) => {
		const input = event.target.value;
		const newStockImeis = stockImeis?.map((item, i) => {
			return { ...item, notes: [input] };
		});
		setNotes(input);
		setStockImeis(newStockImeis);
	};

	const handleAddNote = () => {
		setAddNotes(true);
	};

	const closeNotes = () => {
		setAddNotes(false);
	};

	const closeCreateNote = () => {
		setAddNotes(false);
	};

	const handleSetSelectedRepairs = (selected, index) => {
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, repairs: selected };
			} else {
				return item;
			}
		});
		console.log(repairs);
		console.log('test');
		setStockImeis(repairs);
		// console.log(repairs)
	};

	const handleSetSelectedIncorrect = (selected, index) => {
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, incorrect: selected };
			} else {
				return item;
			}
		});
		setIsValidCost(true);
		console.log(repairs);
		console.log('test');
		setStockImeis(repairs);
		// console.log(repairs)
	};

	const handleRepairCost = (value, index) => {
		const cost = value.target.value;
		console.log(cost);
		if (cost > 0) {
			setIsValidCost(true);
			console.log(isValidCost);
			console.log('it is valid');
		} else {
			setIsValidCost(false);
			console.log(isValidCost);
			console.log('it not is valid');
		}
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, cost: cost };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
	};

	const handleStockPrice = (value, index) => {
		const price = value.target.value;
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, purchase_list: { ...item.purchase_list, price: price } };
			} else {
				return item;
			}
		});

		setStockImeis(repairs);
	};

	const addModalImei = async () => {
		if (!imeiInputValue || !imeiInputValue.trim()) {
			showErrorDialog('Invalid IMEI', `Invalid IMEI Entered`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}

		const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

		if (!alphanumericRegex.test(imeiInputValue)) {
			showErrorDialog('Invalid IMEI', `Please enter a valid IMEI Number`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}
		api.getAvailableReturnsImei(returnsDetail?.estimationId, { imei: imeiInputValue }).then((res) => {
			if (res?.status) {
				const tempImei = res?.data;
				tempImei.is_repair = false;
				// setStockImeis([...stockImeis, tempImei]);
				setStockImeis([tempImei]);
				setImeiInputValue('');
				imeiInputRef.current.value = '';
				if (res?.data?.warranty?.status != 'Valid') {
					setIsValidCost(false);
					console.log('not valid');
				} else {
					setIsValidCost(true);
					console.log('valid');
				}
				//set here
			} else {
				showErrorDialog('Invalid IMEI', `Couldn't find IMEI ${imeiInputValue} from the selected order`);
				setImeiInputValue('');
				imeiInputRef.current.value = '';
				errorSoundPlay();
			}
		});
	};

	const handleSubmitImeis = (action) => {
		handleImeis(stockImeis, action);
		setStockImeis([]);
	};

	const checkIsValidCost = (e, index) => {
		const cost = e.target.value;

		if (cost < 0) {
			setIsValidCost(true);
		} else {
			setIsValidCost(false);
		}
	};

	useEffect(() => {
		api.getUnassignedInvoiceNumberList().then((res) => {
			const tempInvoiceList = res.data.map((item) => ({
				id: item.id,
				label: `${item.invoice_number} - ${item?.customer_name}`,
				value: item.id
			}));
			setInvoiceList(tempInvoiceList);
		});
		api.getReturnsStandardRepairTypes().then((res) => {
			if (res.status) {
				const tempRepairs = res.data.map((item) => ({
					id: item.id,
					label: `${item.name}`,
					value: item.id
				}));
				setStandardRepairTypes(tempRepairs);
			}
		});
		// setFormInfo({
		// 	...formInfo,
		// 	productId: returnsDetails?.id
		// });
	}, []);

	const onSubmit = () => {
		if (formInfo.estimationId == '') {
			setAlertMessage({
				user: 'Please select order.'
			});
		} else {
			const data = {
				...formInfo,
				estimationId: formInfo.estimationId
			};
			handleSave(data);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const disablepopup = () => {
		handleClose();
	};
	return (
		<>
			<Modal show={openPopup} onHide={disablepopup} centered size="md" backdrop="static">
				<Modal.Body>
					<div className="returns-popup-body">
						<div className="foundListPopup">
							<div className="d-flex TableSerachBar text-center align-items-center justify-content-between mb-2 ml-0 pl-0">
								<h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0" style={{ fontWeight: '700 !important' }}>
									Search IMEI
								</h5>
								<button className="btn modal-close-icon" onClick={handleClose}>
									<svg
										id="Component_127_1"
										height="16"
										width="16"
										data-name="Component 127 – 1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 19.295 19.289"
									>
										<path
											id="Icon_ionic-ios-close"
											data-name="Icon ionic-ios-close"
											d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
											transform="translate(-11.285 -11.289)"
										/>
									</svg>
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-12">
									{/* <form className="assign found"> */}
									<div className="row">
										<div className="form-group col-md-12">
											<div className="input-icon">
												<input
													ref={imeiInputRef}
													type="number"
													id="input-example"
													name="input-name"
													className="form-control form-control-sm"
													placeholder="IMEI"
													minLength={15}
													maxLength={16}
													onChange={(e) => {
														setImeiInputValue(event.target.value);
														if (event.target.value.length > 0) {
															setConfirmDisabled(false);
														} else {
															setConfirmDisabled(true);
														}
													}}
													onKeyDown={(e) => {
														handleIMEIInputKeydown(e);
													}}
													style={{ padding: '1.375rem .75rem' }}
													autoComplete={'off'}
													autoFocus
												/>
												<div className="icon">
													<svg
														stroke="currentColor"
														fill="currentColor"
														stroke-width="0"
														viewBox="0 0 512 512"
														height="1em"
														width="1em"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill="none"
															stroke-miterlimit="10"
															stroke-width="32"
															d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
														></path>
														<path
															fill="none"
															stroke-linecap="round"
															stroke-miterlimit="10"
															stroke-width="32"
															d="M338.29 338.29L448 448"
														></path>
													</svg>
												</div>
											</div>
										</div>
									</div>
									{stockImeis?.length > 0 ? (
										<>
											<div className="row">
												<div className="form-group col-md-12">
													<table className="table ">
														<tbody>
															{stockImeis?.map((item, index) => {
																return (
																	<>
																		<tr>
																			<td className="text-center" width="8%">
																				{item?.product?.image == 'default_thumbnail_url' ? (
																					<img style={{ height: '80px' }} src={NoImage} />
																				) : (
																					<img style={{ height: '80px' }} src={item?.product?.thumbnail_url} />
																				)}
																			</td>
																			<td className="font14 v-align-middle">
																				<div className="row">
																					<div className="col-12">
																						<div className="row">
																							<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																								IMEI
																							</div>
																							<div className="col-9" style={{ fontWeight: '500' }}>
																								{item?.imei}
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																								Model
																							</div>
																							<div className="col-9" style={{ fontWeight: '500' }}>
																								{item?.product?.product_name}
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																								Warranty
																							</div>
																							<div className="col-9" style={{ fontWeight: '500' }}>
																								<span className={item?.warranty?.status == 'Valid' ? 'text-success' : 'text-danger'}>
																									{item?.warranty?.message}
																								</span>
																							</div>
																						</div>
																					</div>
																				</div>
																			</td>
																		</tr>

																		<tr>
																			<td colSpan="2">
																				<div className="row" style={{ marginTop: '35px' }}>
																					<div className="col-12">
																						<Select
																							className="rs-container"
																							classNamePrefix="rs"
																							isSearchable={true}
																							name="user"
																							options={returnReasons}
																							placeholder="Select reason for return"
																							onChange={(selected) => handleReasonForReturn(selected, index)}
																						/>
																					</div>
																				</div>
																				{item?.is_repair == true ? (
																					<>
																						<div className="row" style={{ marginTop: '8px' }}>
																							<div className="col-12">
																								{/* <label style={{ fontWeight: '500', color: '#919191' }}>Repairs Needed</label> */}
																								<Select
																									className="rs-container"
																									classNamePrefix="rs"
																									isSearchable={true}
																									name="repairs"
																									isMulti
																									isClearable={false}
																									options={standardRepairTypes}
																									placeholder="Select repairs needed"
																									onChange={(selected) => {
																										handleSetSelectedRepairs(selected, index);
																									}}
																								/>
																							</div>
																						</div>
																						<div className="row" style={{ marginTop: '10px' }}>
																							<div
																								className="col-12 text-center"
																								style={{ marginTop: '15px', paddingTop: '15px', borderTop: '1px solid #cecece' }}
																							>
																								<label style={{ fontWeight: '500', color: '#919191' }}>
																									Estimated Cost (
																									{item?.purchase_list?.currency == 'eur' ? (
																										<>
																											<span>€</span>
																										</>
																									) : item?.purchase_list?.currency == 'usd' ? (
																										<>
																											<span>$</span>
																										</>
																									) : item?.purchase_list?.currency == 'aed' ? (
																										<>
																											<span>د.إ</span>
																										</>
																									) : (
																										<>{item?.purchase_list?.currency}</>
																									)}
																									)
																								</label>
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-12 col-md-6 text-center">
																								<div className="p-inputgroup flex-1">
																									<span className="p-inputgroup-addon">
																										<i className="pi pi-user"></i>
																									</span>
																									<input
																										type="number"
																										name="cost"
																										id="cost"
																										className={'form-control'}
																										value={item.qty}
																										onChange={(e) => {
																											handleRepairCost(e, index);
																										}}
																										onFocus={(event) => {
																											event.target.select();
																										}}
																										required={item?.warranty?.status == 'Valid' ? false : true}
																									/>
																								</div>
																							</div>
																							<div className="col-12 col-md-6 text-center">
																								<button
																									className={
																										item?.warranty?.status == 'Valid'
																											? 'btn btn-outlined-main text-primary btn-block'
																											: 'btn btn-outlined-main btn-block disabled'
																									}
																									disabled={item?.warranty?.status == 'Valid' ? false : true}
																								>
																									Free of charge
																								</button>
																							</div>
																						</div>
																					</>
																				) : (
																					<>
																						{item?.repair_reason_value && (
																							<>
																								{item?.repair_reason_value == '3' ? (
																									<div className="row" style={{ marginTop: '8px' }}></div>
																								) : (
																									<div className="row" style={{ marginTop: '8px' }}>
																										<div className="col-12">
																											<Select
																												className="rs-container"
																												classNamePrefix="rs"
																												isSearchable={true}
																												name="incorrect_reason"
																												isMulti
																												isClearable={false}
																												options={returnIncorrectProductReason}
																												placeholder="Select Reason"
																												onChange={(selected) => {
																													handleSetSelectedIncorrect(selected, index);
																												}}
																											/>
																										</div>
																									</div>
																								)}
																								{addNotes && (
																									<div
																										className="row"
																										style={{
																											marginTop: 20,
																											boxShadow: '0 0 5px #e1d9d9',
																											paddingTop: 10,
																											paddingBottom: 10,
																											borderRadius: '10px'
																										}}
																									>
																										<div className="col-12">
																											<div
																												style={{
																													display: 'flex',
																													alignItems: 'center',
																													justifyContent: 'space-between',
																													marginBottom: '10px'
																												}}
																											>
																												<div style={{ display: 'flex', alignItems: 'center' }}>
																													<img
																														src={userPhoto}
																														alt="User"
																														style={{
																															width: '30px',
																															height: '30px',
																															borderRadius: '50%',
																															marginRight: 10
																														}}
																													/>
																													<a>You</a>
																												</div>
																												<button
																													style={{ alignSelf: 'flex-start', position: 'static' }}
																													className="btn modal-close-icon"
																													onClick={closeCreateNote}
																												>
																													<FaTimes />
																												</button>
																											</div>
																											<textarea
																												className="form-control"
																												rows="5"
																												placeholder="Add a note..."
																												value={notes}
																												onChange={(e) => {
																													const words = e.target.value.split(/\s+/).filter((word) => word);
																													if (words.length > 30) {
																														e.target.value = words.slice(0, 30).join(' ');
																													}
																													setWordCount(words.length);
																													setInputText(e.target.value);
																													handleNotes(e);
																												}}
																											/>
																											<div
																												style={{
																													borderRadius: '10px',
																													width: '100%',
																													height: '10px',
																													backgroundColor: '#f3f3f3',
																													marginTop: 10
																												}}
																											>
																												<div
																													style={{
																														borderRadius: '10px',
																														width: `${(wordCount / 30) * 100}%`,
																														height: '100%',
																														backgroundColor: '#1cbf72',
																														transition: 'width 0.5s ease-in-out'
																													}}
																												></div>
																											</div>
																										</div>
																									</div>
																								)}
																							</>
																						)}
																					</>
																				)}
																			</td>
																		</tr>
																	</>
																);
															})}
														</tbody>
													</table>
												</div>
											</div>
										</>
									) : (
										<>
											{/* <div className="not-found-container" style={{ height: '15vh' }}>
													<div className="not-found-icon">
														<TbDatabaseExclamation />
													</div>
													<h6 className="not-found-text">No Data Available</h6>
												</div> */}
										</>
									)}
									{/* </form> */}
								</div>
							</div>
						</div>
						{/* {
							stockImeis?.length > 0 && (
								<> */}
						{/* <div className="btnarea"> */}

						{stockImeis?.length < 1 ? (
							<>
								<div className="row">
									<div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'center' }}>
										<button
											disabled={confirmDisabled}
											onClick={() => handleConfirmImei()}
											className="btn btn-main btn-with-disabled"
											style={{ margin: '10px' }}
										>
											<span style={{ padding: '0 35px' }}>Confirm</span>
										</button>
									</div>
								</div>
								<div className="row">
									<div className="col-12 text-center" style={{ margnTop: '15px' }}>
										<a href="javascrip:void(0);" onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
											Cancel
										</a>
									</div>
								</div>
							</>
						) : (
							<>
								{isValidCost ? (
									<>
										{!addNotes ? (
											<div className="row">
												<div className="col-12 text-center" style={{ margnTop: '15px' }}>
													<a
														href="javascript:void(0);"
														onClick={handleAddNote}
														style={{
															cursor: isValidCost ? 'pointer' : 'default',
															color: isValidCost ? '#0065ff' : '#f2f2f2',
															textDecoration: 'none'
														}}
														onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'none')}
														onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
													>
														Add Notes
													</a>
												</div>
											</div>
										) : (
											<div className="col-12 text-center" style={{ margnTop: '15px' }}>
												<a
													href="javascript:void(0);"
													onClick={closeNotes}
													style={{
														cursor: isValidCost ? 'pointer' : 'default',
														color: isValidCost ? '#0065ff' : '#f2f2f2',
														textDecoration: 'none'
													}}
													onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'none')}
													onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
												>
													Close Notes
												</a>
											</div>
										)}
										<div className="row">
											<div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'center' }}>
												<button
													onClick={() => handleSubmitImeis('save')}
													className="btn btn-main btn-with-disabled"
													style={{ margin: '10px' }}
												>
													<span style={{ padding: '0 35px' }}>Save</span>
												</button>
											</div>
										</div>
									</>
								) : (
									<>
										<div className="row">
											<div className="col-12 text-center" style={{ margnTop: '15px' }}>
												<a
													href="javascript:void(0);"
													onClick={isValidCost ? () => handleSubmitImeis('empty') : null}
													style={{
														cursor: isValidCost ? 'pointer' : 'default',
														color: isValidCost ? '#0065ff' : '#f2f2f2',
														textDecoration: 'none'
													}}
													onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'none')}
													onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
												>
													Add Notes
												</a>
											</div>
										</div>

										<div className="row">
											<div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'center' }}>
												<button
													onClick={() => handleSubmitImeis('save')}
													className="btn btn-main btn-with-disabled"
													style={{ margin: '10px' }}
													disabled={!isValidCost}
												>
													<span style={{ padding: '0 35px' }}>Save</span>
												</button>
											</div>
										</div>
									</>
								)}
								<div className="row">
									<div className="col-12 text-center" style={{ margnTop: '15px' }}>
										<a
											href="javascript:void(0);"
											onClick={isValidCost ? () => handleSubmitImeis('empty') : null}
											style={{
												cursor: isValidCost ? 'pointer' : 'default',
												color: isValidCost ? '#0065ff' : '#f2f2f2',
												textDecoration: 'none'
											}}
											onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'none')}
											onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
										>
											Save and Add
										</a>
									</div>
								</div>
							</>
						)}
						{/* </div> */}
						{/* </>
							)
						} */}
					</div>
				</Modal.Body>
			</Modal>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}
		</>
	);
};

export default StockProductsModal;
