import React from 'react';
import { ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Area, Bar, ResponsiveContainer } from 'recharts';
import { IconChartLine, IconChartBar } from '@tabler/icons'; // replace with actual import
import { Fade } from 'react-awesome-reveal';

const SalesCard = ({ salesGraphPermission, salesData, graphType, setGraphType, CustomizedAxisTick }) => {
	if (!salesGraphPermission || salesGraphPermission.is_visible !== 1) {
		return null;
	}

	return (
		<div className="col-lg-8 col-md-12" style={{ paddingBottom: '20px' }}>
			<Fade>
				<div className="card" style={{ height: '270px' }}>
					<div
						className="card-header"
						style={{
							backgroundColor: 'white',
							paddingBottom: '5px',
							paddingTop: '20px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}
					>
						<h5 style={{ fontWeight: '600' }}>Sales</h5>
						<div>
							<a className={`graph-selector-line ${graphType === 'area' ? 'active' : ''}`} onClick={() => setGraphType('area')}>
								<IconChartLine size={16} />
							</a>
							<a className={`graph-selector-bar ${graphType === 'bar' ? 'active' : ''}`} onClick={() => setGraphType('bar')}>
								<IconChartBar size={16} />
							</a>
						</div>
					</div>
					<div className="card-body custom-card-body">
						{salesData && salesData.length > 0 ? (
							<>
								<div className="labels" style={{ float: 'right', marginRight: '20px' }}>
									{Object.keys(salesData[0]).map((key, index) => {
										if (key !== 'month') {
											return (
												<a key={index} style={{ marginRight: '5px' }}>
													<span
														style={{
															display: 'inline-block',
															width: '10px',
															height: '10px',
															borderRadius: '3px',
															backgroundColor: index === 1 ? '#4891fd' : '#d1d1d1',
															marginRight: '5px'
														}}
													></span>
													{key}
												</a>
											);
										}
										return null;
									})}
								</div>
								<div style={{ marginTop: '20px', height: '190px' }}>
									<ResponsiveContainer width="100%" height="100%">
										<ComposedChart
											data={salesData}
											margin={{
												top: 10,
												right: 30,
												left: 0,
												bottom: 5
											}}
										>
											<CartesianGrid vertical={false} stroke="#dddddd" strokeDasharray="3 3" />
											<XAxis dataKey="month" axisLine={false} tickLine={false} tick={{ fontSize: 14, fill: '#868686' }} />
											<YAxis orientation="left" axisLine={false} tickLine={false} tick={<CustomizedAxisTick />} />
											<Tooltip
												formatter={(value) =>
													`$ ${new Intl.NumberFormat('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)}`
												}
												labelFormatter={(value) => value.toUpperCase()}
												content={({ payload, label }) => (
													<div style={{ backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '10px', padding: '20px' }}>
														<div style={{ color: '#000', fontWeight: 'bold', fontSize: '16px', paddingBottom: '10px' }}>{label}, 2024</div>
														<div className="graph-label-div">
															{payload.map((item, index) => (
																<div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
																	<div style={{ display: 'flex', alignItems: 'center' }}>
																		<span
																			style={{
																				display: 'inline-block',
																				width: '7px',
																				height: '7px',
																				backgroundColor: item.color,
																				marginRight: '5px',
																				borderRadius: '2px'
																			}}
																		></span>
																		<span style={{ color: item.color, fontSize: '13px', marginRight: '50px' }}>{item.name}</span>
																	</div>
																	<span style={{ fontSize: '13px', color: '#696969' }}>
																		${' '}
																		{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
																			parseFloat(item.value)
																		)}
																	</span>
																</div>
															))}
														</div>
													</div>
												)}
											/>
											{graphType === 'area' ? (
												<>
													{Object.keys(salesData[0]).map((key, index) => {
														if (key !== 'month') {
															return (
																<Area
																	key={index}
																	type="linear"
																	dataKey={key}
																	stackId="1"
																	stroke={index === 2 ? '#e0e0e0' : '#4891fd'}
																	fill={index === 2 ? '#e0e0e0' : '#d0def3'}
																/>
															);
														}
														return null;
													})}
												</>
											) : (
												<>
													{Object.keys(salesData[0]).map((key, index) => {
														if (key !== 'month') {
															return (
																<Bar
																	key={index}
																	dataKey={key}
																	fill={index === 2 ? '#e0e0e0' : '#4891fd'}
																	barSize={20}
																	radius={[5, 5, 5, 5]}
																/>
															);
														}
														return null;
													})}
												</>
											)}
										</ComposedChart>
									</ResponsiveContainer>
								</div>
							</>
						) : (
							<div>Loading...</div>
						)}
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default SalesCard;
