import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import usePermissions from 'hooks/usePermissions';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import moment from 'moment';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import CurrencyValue from 'ui-component/CurrencyValue';
import TransferRightArrowIcon from 'assets/images/icons/transfer-right-arrow.svg';
import {
    CloseIcon,
    CoinIcon
} from 'ui-component/custom-icons/icons';


const PreviewTransactionModal = ({ openPopup, selectedRow, handleClose }) => {

    const [isDisplayLoading, setIsDisplayLoading] = useState(true);
    const [allPermissions, hasEveryPermission] = usePermissions();

    const [transactionDetails, setTransactionDetails] = useState(null);



    useEffect(() => {
        api.getTransactionDetails(selectedRow).then((res) => {
            if (res?.status) {
                setTransactionDetails(res?.data);
                setIsDisplayLoading(false);
            }
        });
    }, []);



    const disablepopup = () => {
        handleClose();
    };




    return (
        <>
            <Modal show={openPopup} onHide={disablepopup} centered size="md">
                <div className="popup-transaction-header"></div>
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center">

                                        <h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0" style={{ fontWeight: '600' }}>
                                            Transaction Details
                                        </h5>
                                        <br />
                                    </div>
                                </div>
                                {/* <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} style={{ background: 'transparent' }} />
                                </button> */}
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12 d-flex justify-content-center">
                                    <CoinIcon height='100' width='100' />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div
                                    className="col-12 d-flex justify-content-center"
                                    style={{
                                        marginTop: '25px', fontSize: '20px', fontWeight: '600'
                                    }}
                                >
                                    <CurrencyValue currencyObject={transactionDetails?.converted_price} />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div
                                    className="col-12 d-flex justify-content-center textGray form-group"
                                >
                                    Payment completed
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ marginTop: '15px' }}>
                                <div
                                    className="col-12 form-group"
                                >
                                    <div className="card bg-white">
                                        <div className="card-body" style={{ padding: '0' }}>
                                            
                                            <div className="preview-transaction-transfer">
                                                <img src={TransferRightArrowIcon} style={{ height: '30px', width: '30px' }} />
                                            </div>
                                            <div className="row">
                                                <div className="col-6" style={{ borderRight: '1px solid #e0e0e0' }}>
                                                    <div style={{ padding: '1.5rem' }}>

                                                        <div className="row">
                                                            <div className="col-12 textGray">
                                                                From
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {
                                                                    transactionDetails?.estimation?.transit_company?.name ? (
                                                                        <>
                                                                            { transactionDetails?.estimation?.transit_company?.name }
                                                                        </>
                                                                    ) : transactionDetails?.estimation?.customer?.name ? (
                                                                        <>
                                                                            { transactionDetails?.estimation?.customer?.name }
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div style={{ padding: '1.5rem' }}>

                                                        <div className="row">
                                                            <div className="col-12 textGray">
                                                                To
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                { transactionDetails?.account_holder }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ marginTop: '15px' }}>
                                <div
                                    className="col-12 form-group"
                                >
                                    <div className="card bg-white">
                                        <div className="card-body" style={{ padding: '0' }}>
                                            
                                            <div className="row">
                                                <div className="col-12 d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="textGray" style={{ padding: '7px 10px', marginLeft: '8px', marginTop: '10px' }}>
                                                        Transaction Status
                                                    </div>
                                                    <div className="text-success" style={{ padding: '7px 10px', marginRight: '8px', marginTop: '10px' }}>Successful</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="textGray" style={{ padding: '7px 10px', marginLeft: '8px' }}>
                                                        Transaction Type
                                                    </div>
                                                    <div style={{ padding: '7px 10px', marginRight: '8px' }}>
                                                        { transactionDetails?.payment_type }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="textGray" style={{ padding: '7px 10px', marginLeft: '8px' }}>
                                                        Date
                                                    </div>
                                                    <div style={{ padding: '7px 10px', marginRight: '8px' }}>
                                                        {moment(transactionDetails?.payment_date).format(CommonDateOnlyFormat)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="textGray" style={{ padding: '7px 10px', marginLeft: '8px' }}>
                                                        Transaction ID
                                                    </div>
                                                    <div style={{ padding: '7px 10px', marginRight: '8px' }}>
                                                        { transactionDetails?.reference_number }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                transactionDetails?.estimation?.invoice_number && (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12 d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <div className="textGray" style={{ padding: '7px 10px', marginLeft: '8px' }}>
                                                                    Invoice
                                                                </div>
                                                                <div style={{ padding: '7px 10px', marginRight: '8px' }}>
                                                                    <div
                                                                        className={hasEveryPermission(['View Estimation Details (Page)']) ? 'cursor-pointer text-primary align-middle font14' : 'align-middle font14'}
                                                                        onClick={() => {
                                                                            if (hasEveryPermission(['View Estimation Details (Page)'])) {
                                                                                if (transactionDetails?.estimation?.invoice_number) {
			                                                                        window.open(`/sales/invoices/${transactionDetails?.estimation?.id}`, '_blank');
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        { transactionDetails?.estimation?.invoice_number }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className="row">
                                                <div className="col-12 d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="textGray" style={{ padding: '7px 10px', marginLeft: '8px', marginBottom: '10px' }}>
                                                        Processed By
                                                    </div>
                                                    <div style={{ padding: '7px 10px', marginRight: '8px', marginBottom: '10px' }}>
                                                        { transactionDetails?.created_by?.name }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                transactionDetails?.attachment && (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12 d-flex" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <div className="textGray" style={{ padding: '7px 10px', marginLeft: '8px', marginBottom: '10px' }}>
                                                                    Attachment
                                                                </div>
                                                                <div
                                                                    className="text-primary"
                                                                    style={{ padding: '7px 10px', marginRight: '8px', marginBottom: '10px', cursor: 'pointer' }}
                                                                    onClick={() => { 
                                                                        window.open(transactionDetails?.attachment?.link, '_blank');
                                                                    }}
                                                                >
                                                                    { transactionDetails?.attachment?.name }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btnarea" style={{ justifyContent: 'right' }}>

                                <div className="row">
                                    <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                        <button onClick={handleClose} className="btn btn-main-primary btn-outlined-main" style={{ marginLeft: '4px' }}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PreviewTransactionModal;
