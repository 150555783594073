import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import FileUpload from 'ui-component/FileUpload';
import { getCurrencySymbol } from 'utils/currency';
import toast from 'react-hot-toast';

const PurchaseInvoicePopup = ({ openpopup, currentRowData, selectedListids, handleClose, handleSave, disableStatus, edit }) => {
	const [rows, setRows] = useState([]);
	const [alertMessage, setAlertMessage] = useState();
	const [userList, setUserList] = useState([]);
	// const [createType, setCreateType] = useState({ label: 'New', value: 'new' });
	const [supplierInvoices, setSupplierInvoices] = useState([]);
	const [file, setFile] = useState();
	const [formInfo, setFormInfo] = useState({
		supplier: '',
		currency: '',
		invoice_number: '',
		date: '',
		transferCharge: '',
		createType: 'new',
		selected_invoice_id: ''
	});

	useEffect(() => {
		setFormInfo({
			supplier: currentRowData.filter((row) => selectedListids.includes(row.id))?.[0]?.supplier,
			currency: currentRowData.filter((row) => selectedListids.includes(row.id))?.[0]?.purchase_currency,
			invoice_number: '',
			date: moment().format('YYYY-MM-DD'),
			transferCharge: 0,
			createType: 'new',
			selected_invoice_id: ''
		});
		setRows(
			currentRowData
				.filter((row) => selectedListids.includes(row.id))
				?.map((r) => ({
					...r,
					item_qty: r?.purchase_qty - (r?.purchase_invoice_items?.reduce((p, c) => c?.item_qty + p, 0) || 0),
					item_qty_max: r?.purchase_qty - (r?.purchase_invoice_items?.reduce((p, c) => c?.item_qty + p, 0) || 0)
				}))
		);
		getPurchaseInvoiceOptionsList(currentRowData.filter((row) => selectedListids.includes(row.id))?.[0]?.supplier?.id);
	}, []);

	const handleFileUpload = (e) => {
		const files = e.target.files;
		setFile(files?.length >= 1 ? files?.[0] : []);
		// setDisablestatus(true);
	};

	const removeRow = (id) => {
		if (rows.length === 1) {
			return;
		}
		const updatedRows = rows.filter((row) => row.id !== id);
		setRows(updatedRows);
	};

	const handleValue = (val) => {
		setFormInfo(val);
	};

	const getPurchaseInvoiceOptionsList = (sup_id) => {
		api.getPurchaseInvoiceOptionsList(sup_id).then((res) => {
			setSupplierInvoices(res?.data);
		});
	};

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		zIndex: 9
	};
	const onSubmit = () => {
		if (formInfo?.createType == 'new' && formInfo.invoice_number == '') {
			toast?.error('Please enter invoice number');
		} else if (formInfo?.createType == 'new' && formInfo.date == '') {
			toast?.error('Please enter invoice number');
		} else if (formInfo?.createType == 'new' && moment(formInfo.date).isAfter(moment())) {
			toast?.error('Selected date is a future date');
		} else if (formInfo?.createType == 'new' && !file) {
			toast?.error('Please upload the purchase invoice');
		} else if (formInfo?.createType == 'existing' && !!!formInfo.selected_invoice_id) {
			toast?.error('Please select invoice');
		} else if (!rows?.every((r) => r.item_qty > 0)) {
			toast?.error('Please enter valid quantities');
		} else {
			let purchaseIds = rows.map((item) => item.id);
			let purchaseQtys = rows.map((item) => item.item_qty);
			const data = {
				invoice_number: formInfo?.createType == 'new' ? formInfo?.invoice_number : null,
				date: formInfo?.createType == 'new' ? formInfo?.date : null,
				additional_charges: formInfo?.createType == 'new' ? formInfo?.transferCharge : null,
				currency: formInfo?.createType == 'new' ? formInfo?.currency : null,
				purchase_list_ids: purchaseIds,
				purchase_list_qtys: purchaseQtys,
				attachment: formInfo?.createType == 'new' ? file : null,
				selected_invoice_id: formInfo?.createType == 'existing' ? formInfo?.selected_invoice_id : null,
				create_type: formInfo?.createType
			};

			handleSave(data);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const disablepopup = () => {
		setRows('');
		handleClose();
	};
	return (
		<>
			<Modal show={openpopup} onHide={disablepopup} centered size="xl">
				<Modal.Body>
					<div className="p-2">
						<div className="foundListPopup">
							<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 ml-0 pl-0">
								<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">Create Purchase Invoice</h5>
								<Select
									className="rs-container"
									classNamePrefix="rs"
									isSearchable={true}
									name="user"
									value={[
										{ label: 'New', value: 'new' },
										{ label: 'Existing', value: 'existing' }
									]?.find((v) => v?.value == formInfo?.createType)}
									options={[
										{ label: 'New', value: 'new' },
										{ label: 'Existing', value: 'existing' }
									]}
									onChange={(e) => {
										setFormInfo((oldData) => ({
											...oldData,
											createType: e.value
										}));
									}}
									placeholder="Select"
								/>
								<button className="btn modal-close-icon" onClick={handleClose}>
									<FaTimes />
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-md-5">
									<form className="assign found">
										{formInfo?.createType == 'existing' ? (
											<>
												<div className="form-group col-md-6 mb-2">
													<label className="">Supplier</label>

													<p className="m-0">{formInfo?.supplier?.name}</p>
												</div>
												<div className="form-group col-md-12 mb-2">
													<label className="">Invoice Number</label>

													<Select
														className="rs-container"
														classNamePrefix="rs"
														isSearchable={true}
														name="user"
														value={supplierInvoices
															?.map((item) => ({
																label: item?.invoice_number,
																value: item?.id,
																...item
															}))
															?.find((t) => t?.value == formInfo?.selected_invoice_id)}
														options={supplierInvoices?.map((item) => ({
															label: item?.invoice_number,
															value: item?.id,
															...item
														}))}
														onChange={(e) => {
															setFormInfo((oldData) => ({
																...oldData,
																selected_invoice_id: e?.value
															}));
														}}
														placeholder="Select"
													/>
												</div>
											</>
										) : (
											<div className="row w-100">
												<div className="form-group col-md-6 mb-2">
													<label className="">Supplier</label>

													<p className="m-0">{formInfo?.supplier?.name}</p>
												</div>
												<div className="form-group col-md-6 mb-2">
													<label className="">Currency</label>

													<p className="m-0">{formInfo?.currency?.toUpperCase()}</p>
												</div>
												<div className="form-group col-md-12 mb-2">
													<label className="">Invoice Number</label>

													<input
														type="text"
														onChange={(e) => {
															handleValue({
																...formInfo,
																invoice_number: e.target.value
															});
														}}
														value={formInfo?.invoice_number}
														className="form-control"
													/>
													{alertMessage?.user && (
														<>
															<span className="error-message text-danger">{alertMessage?.user}</span>
														</>
													)}
												</div>
												<div className="form-group col-md-6">
													<label className="">Date</label>
													<input
														type="date"
														onChange={(e) => {
															handleValue({
																...formInfo,
																date: e.target.value
															});
														}}
														defaultValue={formInfo?.date}
														value={formInfo?.date}
														className="form-control"
													/>
													{alertMessage?.date && (
														<>
															<span className="error-message text-danger">{alertMessage?.date}</span>
														</>
													)}
												</div>
												<div className="form-group col-md-6">
													<label className="">Transfer Charge</label>
													<input
														type="number"
														onChange={(e) => {
															handleValue({
																...formInfo,
																transferCharge: e.target.value
															});
														}}
														value={formInfo?.transferCharge}
														className="form-control"
													/>
													{alertMessage?.time && (
														<>
															<span className="error-message text-danger">{alertMessage?.time}</span>
														</>
													)}
												</div>

												<div className="form-group col-md-12">
													<label>Attachment</label>

													<FileUpload
														accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
														loading={false}
														onChange={(e) => {
															handleFileUpload(e);
														}}
														selectedValue={file?.[0]}
													/>

													{alertMessage?.files && <span className="px-3 down-error text-danger">{alertMessage?.files}</span>}
												</div>
											</div>
										)}
									</form>
								</div>
								<div className="col-md-7 m-0 p-0">
									<div className="collaps found table-responsive-xl">
										<Scroller heightvalue={'100%'}>
											<table className="table table-md  formActBtns border-0">
												<thead style={tableHeaderStyles}>
													<tr>
														<th className="align-middle">Model</th>
														<th className="align-middle">Invoice</th>
														<th className="align-middle">Quantity</th>
														<th className="align-middle">Price</th>
														<th className="align-middle"></th>
													</tr>
												</thead>
												<tbody>
													{rows?.map((row) => (
														<tr key={row.id}>
															<td className="align-middle">{row?.product?.product_name}</td>
															<td className="align-middle">{row?.invoice_number}</td>
															<td className="align-middle">
																<input
																	type="number"
																	className="numField"
																	value={row?.item_qty}
																	max={row?.item_qty_max}
																	min={1}
																	onChange={(e) => {
																		const val = e.target.value;
																		if (val > row?.item_qty_max) {
																			setRows((oldData) =>
																				oldData.map((d) => (d?.id == row.id ? { ...d, item_qty: row?.item_qty_max } : d))
																			);
																		} else if (val < 0) {
																			setRows((oldData) => oldData.map((d) => (d?.id == row.id ? { ...d, item_qty: 0 } : d)));
																		} else {
																			setRows((oldData) => oldData.map((d) => (d?.id == row.id ? { ...d, item_qty: val } : d)));
																		}
																	}}
																/>
															</td>
															<td className="align-middle">
																{getCurrencySymbol(formInfo?.currency)} {row?.converted_currency_purchase_price_unit?.[formInfo?.currency]}
															</td>
															<td className="align-middle text-right">
																<span
																	className={`btnEdit plain-iconbtn ${rows?.length <= 1 ? 'disabled' : ''}`}
																	onClick={() => removeRow(row.id)}
																>
																	<FaTimes />
																</span>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</Scroller>
									</div>
								</div>
							</div>
						</div>
						<div className="btnarea mt-3">
							<button disabled={disableStatus} onClick={disablepopup} className="btn btn-link">
								Cancel
							</button>
							<button disabled={disableStatus} onClick={onSubmit} className="btn btn-main">
								<FaPlus /> Create
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PurchaseInvoicePopup;
