import React, { useState, useEffect } from 'react';
import { Box, TextField, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip, Card } from '@mui/material';
import { useNavigate } from 'react-router';
import { set, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import MultiSelectInput from 'ui-component/MultiSelectInput';
import { FaPlus } from 'react-icons/fa6';
import RolePermissions from './RolePermissions';

const CreateRoles = () => {
	const history = useNavigate();
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm();
	// const { id } = useParams()
	const dispatch = useDispatch();
	const [roles, setRoles] = useState([]);
	const [role, setRole] = useState([]);
	const [mods, setMods] = useState([]);
	const [modsIds, setModsids] = useState([]);
	const [roleIds, setRoleIds] = useState([]);
	const [permissionToIds, setPermissionToIds] = useState({});
	const [mdouleName, setModuleName] = useState([]);
	// const loading = useSelector((state) => state.main.loading)
	const [alertMessage, setAlertmessage] = useState({});

	const getRoleData = async () => {
		const response = await api.getPermissionsList('all');
		setRoles(response.data);
		setModuleName(unquieModuleArray(response.data));
		// console.log(mdouleName, 'adsfsfs')
		const permissionIds = {};
		for (const perm of response.data) {
			permissionIds[perm.name] = perm.id;
		}
		// console.log(permissionIds)
		setPermissionToIds(permissionIds);
	};

	const unquieModuleArray = (row) => {
		// console.log(row)
		return [...new Set(row?.map((el) => el.group_name))];
	};
	const handleChangeMods = (event) => {
		const {
			target: { value }
		} = event;
		// console.log('######## handmole value: ', value)

		const nameArray = typeof value === 'string' ? value.split(',') : value;

		const ids = nameArray.map((name) => nameToId[name]);
		const modeValues = typeof value === 'string' ? value.split(',') : value;
		// console.log(modeValues)
		setMods(modeValues);
		setModsids(ids);
		const selectedRoleToPrmission = modeValues.map((mel) =>
			roles.filter((el) => el.name.toLowerCase().charAt(0) == mel.toLowerCase().charAt(0)).map((mmd) => mmd.name)
		);
		const flatten = (ary) => ary.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);
		const newSelectedRoleToPermission = flatten(selectedRoleToPrmission).filter((sr) => sr.includes(modeValues[modeValues.length - 1]));
		setRole([...role, ...newSelectedRoleToPermission]);
	};

	const onChipDelete = (chip) => {
		let newMods = mods.filter((mo) => !mo.includes(chip));
		let newRole = role.filter((rl) => !rl.includes(chip));
		let isLastItem = role.filter((nrl) => {
			let ch = nrl.split(' ');
			let ch1 = chip.split(' ');
			ch.pop();
			ch1.pop();
			return ch.join(' ').trim() === ch1.join(' ').trim();
		});
		if (isLastItem.length === 1) {
			if (isLastItem[0] === chip) {
				let lastChip = chip.split(' ');
				lastChip.pop();
				lastChip = lastChip.join(' ').trim();
				newMods = newMods.filter((nm) => nm !== lastChip);
			}
		}
		setRole(newRole);
		setMods(newMods);
	};

	const handleChangerole = (event) => {
		const {
			target: { value }
		} = event;
		// console.log('######## handlrole value: ', value)
		const nameArray = typeof value === 'string' ? value.split(',') : value;

		const ids = nameArray.map((name) => nameToId[name]);
		const roleValues = typeof value === 'string' ? value.split(',') : value;
		setRole(roleValues);
		setRoleIds(ids);
		const newMods = [...new Set(roleValues.map((e) => e.split(' ')[0].trim()))];
		setMods(newMods);
		// console.log(roleValues, 'asdfsfdsdffsdfsdfsdasfdsdfs')
		// console.log(ids, 'asdfsfdsdffsdfsdfsd')
	};
	const nameToId = roles.reduce((acc, item) => {
		acc[item.name] = item.id;
		return acc;
	}, {});

	const onSubmit = (data) => {
		// e.preventDefault()
		// console.log(
		// 	data,
		// 	mods,
		// 	role.map((p) => permissionToIds[p]),
		// 	roleIds
		// )
		// return
		// dispatch(setLoading(true))
		api.createRole({
			name: data?.name,
			permission: role.map((p) => permissionToIds[p])
		}).then((res) => {
			// console.log('Responce: ', res)
			if (res.status) {
				// dispatch(setLoading(false))
				setAlertmessage({
					success: true,
					color: 'text-success',
					message: 'Role Created Successfully.'
				});
				setTimeout(() => {
					setAlertmessage({});
					history('/settings/role');
				}, 1500);
			} else {
				// dispatch(setLoading(false))
				setAlertmessage({
					success: false,
					color: 'text-danger',
					message: 'Invalid Error.'
				});
				setTimeout(() => {
					setAlertmessage({});
				}, 2000);
			}
		});
	};

	useEffect(() => {
		getRoleData();
		// console.log(user)
	}, []);

	return (
		<>
			<div className="d-sm-flex align-items-center justify-content-between mb-4">
				<h1 className="h2 mb-0  font-weight-bold">Create Role</h1>
			</div>
			{/* { loading ? <Box sx={{ marginTop: '400px'}}><Loader /></Box>: null } */}
			<Card sx={{ p: 4, borderRadius: '10px' }} variant="outlined">
				<div className="col-lg-12  justify-content-between">
					<div className="p-1">
						<form className="userForm" onSubmit={handleSubmit(onSubmit)}>
							<div className="form-group">
								<label htmlFor="inputName" className="">
									Name
								</label>
								<input
									id="name"
									className="form-control"
									placeholder="Eg: Technician"
									variant="outlined"
									{...register('name', { required: true })}
								/>
								{errors.name && <span className="px-3 down-error text-danger">Name is required</span>}
							</div>

							<RolePermissions />

							<div className="btnarea">
								<button
									type="submit"
									name="wp-submit"
									id="wp-submit"
									className="btn btn-primary btn-outlined-main"
									defaultValue="Login"
								>
									<label>
										<FaPlus /> Create
									</label>
								</button>
							</div>
						</form>
					</div>
					{alertMessage.success && <span className={`px-3 down-error ${alertMessage.color}`}>{alertMessage.message}</span>}
				</div>
			</Card>
		</>
		// </MainWrapper>
	);
};

export default CreateRoles;
