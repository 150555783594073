import React, { useState, useEffect } from 'react';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Pagination from 'ui-component/Pagination';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Loader from 'ui-component/Loader';
import Filter from 'ui-component/filter-menu/Filter';
import { FaSearch } from 'react-icons/fa';
import { Popover, Skeleton, Tooltip } from '@mui/material';
import { TbDatabaseExclamation, TbFileInvoice } from 'react-icons/tb';
import CurrencyValue from 'ui-component/CurrencyValue';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { GiHandTruck } from 'react-icons/gi';
import { CiEdit } from 'react-icons/ci';
import PopupState, { bindHover, bindPopover, bindTrigger } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import SortableHeader from 'ui-component/SortableHeader';
import PLCards from './PLCards';

const AllComponent = ({
	activeComponent,
	tableHeaderStyles,
	purchaseList,
	isLoading,
	selectedIds,
	handleSelectAll,
	handleSelectRow,
	assignInspenction,
	viewEstimateDetails,
	moveStatus,
	editData,
	searchData,
	searchField,
	currnetCurrency,
	activeFilters,
	setActiveFilters,
	filters,
	privacyMode,
	createPurchaseInvoice,
	showErrorDialog,
	updateSortByStatus,
	sortByFilter
}) => {
	const [selectedRowdata, setSelectedRowData] = useState([]);

	useEffect(() => {
		// editSet();
		const selectedRow = purchaseList.filter((row) => selectedIds.includes(row.id));
		setSelectedRowData(selectedRow);
		const firststatus = selectedRow[0]?.status;
		const supllierId = selectedRow[0]?.supplier_id;

		let hasSameStatusId = true;
		if (selectedRow.length > 1) {
			hasSameStatusId = selectedRow.every((item) => item.status === firststatus && item.supplier_id === supllierId);
		}
	}, [selectedIds]);

	const sameStatusandSupcheck = () => {
		const firststatus = selectedRowdata[0]?.status;
		const supllierId = selectedRowdata[0]?.supplier_id;
		const hasSameStatusId = selectedRowdata.every((item) => item.status === firststatus && item.supplier_id === supllierId);
		return hasSameStatusId;
	};
	const supInvoicedCheck = () => {
		const hasInvoicedItems = selectedRowdata.some((item) => {
			const piTotal = item?.purchase_invoice_items?.reduce((p, c) => c?.item_qty + p, 0) || 0;
			return piTotal >= item?.purchase_qty;
		});
		return hasInvoicedItems;
	};
	const createPurchaseInvoiceFunc = () => {
		const hasNeedInspectionStatus = selectedRowdata.some((item) => item.master_status.name == 'Inspected');
		if (selectedIds.length < 1) {
			showErrorDialog('Error', 'Please select models');
		} else if (!sameStatusandSupcheck()) {
			showErrorDialog(
				'Error',
				'Sorry, you cannot perform this action. Make Sure to select Models having same supplier name and same status.'
			);
		} else if (supInvoicedCheck()) {
			showErrorDialog(
				'Error',
				'Sorry, you cannot perform this action. You have selected some items that has already been invoiced by supplier.'
			);
		} else {
			createPurchaseInvoice && createPurchaseInvoice();
		}
	};
	const editoption = () => {
		if (selectedIds.length < 1) {
			showErrorDialog('Error', 'Please select models');
		} else {
			editData();
		}
	};
	return (
		<>
			{/* <div className="d-flex align-items-center justify-content-between p-2 pt-3 TableSerachBar">
				<form className="form-inline navbar-search">
					<div className="input-group">
						<input
							type="text"
							className="form-control bg-white border"
							placeholder="Search Purchase List..."
							aria-label="Search"
							aria-describedby="basic-addon2"
							onChange={(e) => {
								searchData(e.target.value);
							}}
							defaultValue={searchField}
						/>
						<img src={IconSearch} className="sIcon" alt="" />
					</div>
				</form>
			</div> */}
			<div className="d-flex align-items-center justify-content-between TableSerachBar">
				<div>
					<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
				</div>
				<div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
					<div className={`bulk-actions ${selectedIds?.length > 0 ? 'show-mobile' : ''}`}>
						<button
							className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 ? '' : 'disabled'}`}
							style={{ minWidth: '80px' }}
							onClick={() => selectedIds?.length > 0 && editoption()}
						>
							<CiEdit /> Edit
						</button>
						<button
							className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 ? '' : 'disabled'}`}
							style={{ minWidth: '80px' }}
							onClick={() => selectedIds?.length > 0 && createPurchaseInvoiceFunc()}
						>
							<GiHandTruck /> Add Purchase Invoices
						</button>
					</div>
					<form className="form-inline navbar-search">
						<div className="input-group">
							<input
								type="text"
								className="form-control bg-white border"
								placeholder="Search"
								aria-label="Search"
								aria-describedby="basic-addon2"
								onChange={(e) => {
									searchData(e.target.value);
								}}
								value={searchField}
								style={{ width: '100%' }}
							/>
							<span className="searchIcon">
								<FaSearch />
							</span>
						</div>
					</form>
				</div>
			</div>
			<div className="table-responsive-xl tableData estimationList">
				<Scroller extraHeight={44}>
					<table className="table pm-table d-none d-md-table">
						<thead>
							<tr className="sticky-header">
								<th className="align-middle" width={'15'}>
									{purchaseList.length > 0 && (
										<>
											<div className="myTest custom-control custom-checkbox custom-list-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id="groupCheck"
													onChange={handleSelectAll}
													checked={selectedIds.length === purchaseList.length}
												/>
												<label className="custom-control-label" for="groupCheck">
													&nbsp;
												</label>
											</div>
										</>
									)}
								</th>
								<th scope="col" width="">
									<SortableHeader
										name="customer"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('customer');
										}}
									>
										Customer
									</SortableHeader>
								</th>
								{/* <th scope="col" width="">
									Estimate
								</th> */}
								<th scope="col" width="">
									<SortableHeader
										name="invoice_number"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('invoice_number');
										}}
									>
										Invoice
									</SortableHeader>
								</th>
								<th scope="col">
									<SortableHeader
										name="product"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('product');
										}}
									>
										Product
									</SortableHeader>
								</th>
								<th scope="col">Qty</th>
								<th scope="col" width={''}>
									<SortableHeader
										name="supplier"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('supplier');
										}}
									>
										Supplier
									</SortableHeader>
								</th>
								<th scope="col" width={''}>
									Date
								</th>
								<th scope="col" width={''}>
									Selling
								</th>
								<th scope="col" width={''}>
									Purchase
								</th>
								<th scope="col" width={''}>
									Profit
								</th>
								<th scope="col" width={''}>
									<SortableHeader
										name="status"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('status');
										}}
									>
										Status
									</SortableHeader>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{isLoading ? (
								<>
									{[...Array(5)].map((e, i) => (
										<tr>
											<>
												{[...Array(11)].map((e, i) => (
													<td>
														<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
													</td>
												))}
											</>
										</tr>
									))}
								</>
							) : (
								<>
									{purchaseList?.length > 0 &&
										purchaseList.map((item, index) => {
											return (
												<>
													<tr key={index} onClick={() => handleSelectRow(item.id)}>
														<td className="align-middle" width={'15'}>
															<div className="myTest custom-control custom-checkbox custom-list-checkbox">
																<input
																	type="checkbox"
																	className="custom-control-input shadowdisable"
																	id={`row-${index}`}
																	checked={selectedIds.includes(item.id)}
																	// onChange={() => handleSelectRow(item.id)}
																	onClick={(e) => {
																		e.preventDefault();
																	}}
																	disabled
																/>
																<label className="custom-control-label" for={`row-${index}`}>
																	&nbsp;
																</label>
															</div>
														</td>
														<td className="align-middle">
															<PrivacyModeContent>{item?.customer?.name}</PrivacyModeContent>
														</td>
														{/* <td className="align-middle">
															<Link to={`/sales/invoices/${item?.estimation_id}`}>{item?.estimation_number}</Link>
														</td> */}
														<td className="align-middle">
															{item.invoice_number ? <Link to={`/sales/invoices/${item?.estimation_id}`}>{item?.invoice_number}</Link> : '-'}
														</td>
														<td className="align-middle">{item?.product?.product_name}</td>
														<td className="align-middle text-center">
															{item?.master_status?.name?.toLowerCase() == 'sourcing' ||
																item?.master_status?.name?.toLowerCase() == 'cancelled'
																? item?.sold_qty
																: item?.purchase_qty}
														</td>
														<td className="align-middle">
															<PrivacyModeContent>{item?.supplier?.name}</PrivacyModeContent>
														</td>
														<td className="align-middle">{moment(item?.estimation_date).format(CommonDateOnlyFormat)}</td>
														<td className="align-middle">
															<CurrencyValue currencyObject={item?.converted_currency_selling_price_unit} />
														</td>
														<td className="align-middle">
															{item?.supplier ? <CurrencyValue currencyObject={item?.converted_currency_purchase_price_unit} /> : '-'}
														</td>
														<td className="align-middle">
															{item?.supplier ? (
																<>
																	{/* <CurrencyValue
																		currencyObject={Object.keys(
																			item?.converted_currency_selling_price_unit
																		).reduce((acc, key) => {
																			if (item?.converted_currency_purchase_price_unit[key]) {
																				acc[key] =
																					item?.converted_currency_selling_price_unit[key] -
																					item?.converted_currency_purchase_price_unit[key];
																			}
																			return acc;
																		}, {})}
																	/>{' '} */}
																	<CurrencyValue
																		currencyObject={Object.keys(item?.converted_currency_selling_price_unit).reduce((acc, key) => {
																			if (item?.converted_currency_purchase_price_unit[key]) {
																				acc[key] =
																					((item?.converted_currency_selling_price_unit[key] -
																						item?.converted_currency_purchase_price_unit[key]) /
																						item?.converted_currency_purchase_price_unit[key]) *
																					100;
																			}
																			return acc;
																		}, {})}
																		onlyValue={true}
																	/>
																	%
																</>
															) : (
																<>-</>
															)}
														</td>

														<td className="align-middle">{item?.master_status?.name}</td>
														<td>
															{item?.purchase_invoice_items?.length > 0 && (
																<>
																	<PopupState variant="popover" popupId="demo-popup-popover">
																		{(popupState) => (
																			<div>
																				<Link to={`#`} style={{ cursor: 'pointer' }} {...bindHover(popupState)}>
																					<TbFileInvoice />
																				</Link>
																				<HoverPopover
																					{...bindPopover(popupState)}
																					anchorOrigin={{
																						vertical: 'bottom',
																						horizontal: 'center'
																					}}
																					transformOrigin={{
																						vertical: 'top',
																						horizontal: 'center'
																					}}
																				>
																					<ul className="pl-items-list">
																						{item?.purchase_invoice_items?.map((pi, index) => (
																							<li>
																								<b>{pi?.item_qty}</b> Pcs-{' '}
																								<Link to={`/purchase-invoice/${pi?.purchase_invoice?.id}`}>
																									{pi?.purchase_invoice?.invoice_number}
																								</Link>
																							</li>
																						))}
																					</ul>
																				</HoverPopover>
																			</div>
																		)}
																	</PopupState>
																</>
															)}
														</td>
													</tr>
												</>
											);
										})}
									{purchaseList?.length < 1 && (
										<tr className="no-hover">
											<td colSpan={11}>
												<div className="not-found-container">
													<div className="not-found-icon">
														<TbDatabaseExclamation />
													</div>
													<h6 className="not-found-text">No Data Available</h6>
												</div>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</table>
					<PLCards purchaseList={purchaseList} handleSelectRow={handleSelectRow} selectedIds={selectedIds} />
				</Scroller>
			</div>
		</>
	);
};

export default AllComponent;
