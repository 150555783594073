import React,{ useRef,useState }  from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { FaEllipsisV, FaEye, FaEyeSlash, FaPen, FaRegEye, FaTrash } from 'react-icons/fa';

const PdfViewer = ({ src, onPreviewClick }) => {
  const iframeRef = useRef(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);


  const handlePdfLoad = () => {
    console.log('PDF loaded');
  };

  const handlePdfPreviewClick = () => {
    if (onPreviewClick) {
      onPreviewClick();
      console.log('PDF Clicked');
    }
  };
  const handleMouseEnter = () => {
    setOverlayVisible(true);
  };

  const handleMouseLeave = () => {
    setOverlayVisible(false);
  };

  return (
    <div
      style={{ position: 'relative' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <iframe
        title="PDF Viewer"
        src={src}
        width="100%"
        height="200px"
        onLoad={handlePdfLoad}
        onClick={handlePdfPreviewClick}
        ref={iframeRef}
      />
      {isOverlayVisible && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
          onClick={handlePdfPreviewClick}
        >
          <FaEye  className='PdfEye'/>
        </div>
      )}
    </div>
  );
};

const ImageViewer = ({ imagePath, altText,className }) => {

  
  const isPdf = (url) => {
		const lowerCaseUrl = url.toLowerCase();
		return lowerCaseUrl.endsWith('.pdf');
	  };
    const image = {
        src: imagePath,
        alt: altText,
        class : className
      };

      const handlePdfPreviewClick = () => {
     
          console.log('pdf preview Clicked')
          window.open(imagePath, '_blank');
      };
      

  return (
    <div>
      
       <Zoom>
       {isPdf(imagePath) ? (
						
						<PdfViewer src={image.src} onPreviewClick={handlePdfPreviewClick} />
						) : ( 
              <img
                alt={image.alt}
                src={image.src}
                //style={{justifyContent: 'center', alignItems: 'center' , width : '80%', marginLeft: '10%'}}
                className={image.class}
              />
            )}
          
        </Zoom>
     
    </div>
  );
};

export default ImageViewer;
