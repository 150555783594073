import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './AddToPurchaseListConfirmation.scss';
import { FaPlus, FaSave, FaTimes } from 'react-icons/fa';
import { ImWarning } from "react-icons/im";
import Select from 'react-select';
import toast from 'react-hot-toast';
import { filter } from 'assets/scss/fontawesome-free/js/v4-shims';
import ReactSelect from 'react-select';

function AddToPurchaseListConfirmation({ show, handleClose, handleConfirm, title, message }) {
	const [items, setItems] = useState([]);
	const [isDisabled, setIsDisabled] = useState(true);
	const currencies = [
		{ label: 'EUR', value: 'eur' },
		{ label: 'USD', value: 'usd' },
		{ label: 'AED', value: 'aed' },
	];
	const [iscurrency, setCurrency] = useState(currencies[0]);
	const [freeStock, setFreeStock] = useState([]);
	const [products, setProducts] = useState([]);
	const [supplierList, setsupplierList] = useState([]);

	useEffect(() => {
		const productIds = show?.map(item => item?.id);

		if (productIds != undefined) {
			api.freestockByProductIds({ product_ids: productIds }).then((res) => {

				setFreeStock(res?.data)

				const allQuantitiesZero = Object.values(res.data).every(qty => qty === 0);
				setIsDisabled(!allQuantitiesZero);

				const filteredProducts = show?.filter(item => {
					const stockQty = res.data[item.id] || 0;
					return stockQty > 0;
				});

				const customerArray = filteredProducts.map((item) => ({ id: item.id, label: item.name, value: item.id }));
				setProducts(customerArray);
				const populate = filteredProducts?.map(item => {
					let sub_qty = res.data[item.id] || 0;
					return {
						name: item.name,
						qty: sub_qty,
						price: 0,
						currency: '',
						supplier: '',
						total_price: 0
					};
				});
				setItems(populate);

				setIsDisabled(false);

			});
		}
		initiateDatas();

	}, [show]);

	const total = items?.reduce((acc, item) => acc + (item.total_price || 0), 0);
	const totalQuantity = items?.reduce((acc, item) => acc + (parseInt(item.qty) || 0), 0);
	const initiateDatas = () => {
		api.getSelectOptionsList('supplier').then((res) => {
			const custData = res.data;
			const customerArray = custData.map((item) => ({ id: item.id, label: item.name, value: item.id }));
			setsupplierList(customerArray);
		});

	};
	const handlePriceChange = (index, price) => {
		const updatedItems = [...items];
		updatedItems[index].price = price;
		updatedItems[index].total_price = price * updatedItems[index].qty;
		setItems(updatedItems);
	};
	const handleProductNameChange = (index, product) => {
		const updatedItems = [...items];
		updatedItems[index].name = product;
		setItems(updatedItems);
	}
	const handleButtonClick = () => {
		const isValid = products.length === 0 ? true : validateFields();
		if (isValid) {
			const data = products.length === 0 ? null : {
				items: items,
				currency: iscurrency.value,
			}
			handleConfirm(show.id, data);
		} else {
			toast.error('Some fields are invalid. Please correct them.');
		}
	};

	const validateFields = () => {
		let isValid = true;
		const itemNames = items.map(item => item.name?.value);


		const showTotalQuantities = {};
		show.forEach(showItem => {
			showTotalQuantities[showItem.name] = (showTotalQuantities[showItem.name] || 0) +
				freeStock[showItem.id] !== undefined ?
				(freeStock[showItem.id] > showItem?.qty ? showItem?.qty : freeStock[showItem.id])
				:
				0;
		});

		const itemsTotalQuantities = {};
		items.forEach(item => {
			itemsTotalQuantities[item.name] = (itemsTotalQuantities[item.name] || 0) + (parseInt(item.qty));
		});
		Object.keys(showTotalQuantities).forEach(name => {
			const showQty = showTotalQuantities[name];
			const itemsQty = itemsTotalQuantities[name] || 0;
			if (showQty !== itemsQty) {
				toast.error(`Quantity mismatch for product "${name}" between remaining (${showQty}) and inserted (${itemsQty})`);
				isValid = false;
			}
		});


		//   show.forEach((showItem, index) => {
		// 	console.log('showItem',showItem)
		//     if (!itemNames.includes(showItem.name)) {
		//       toast.error(`Product name "${showItem.name}" is missing for some products`);
		//       isValid = false;
		//       return;
		//     }

		//   });

		const totalQtyShow = show.reduce((total, showItem) => {
			const remaining = freeStock[showItem.id] !== undefined ?
				(freeStock[showItem.id] > showItem?.qty ? showItem?.qty : freeStock[showItem.id])
				:
				0;

			return total + remaining;
		}, 0);

		if (totalQuantity !== totalQtyShow) {
			toast.error(`Total quantity inserted (${totalQtyShow}) does not match total quantity in remaining (${totalQuantity})`);
			isValid = false;
			return isValid;
		}

		items.forEach((item, index) => {
			if (!item.name) {
				toast.error(`Please select product name for row ${index + 1}`);
				isValid = false;
				return;
			}

			if (!item.supplier) {
				toast.error(`Please select supplier for row ${index + 1}`);
				isValid = false;
				return;
			}

			if (!item.qty || isNaN(item.qty) || item.qty <= 0) {
				toast.error(`Please provide a valid quantity for row ${index + 1}`);
				isValid = false;
				return;
			}

			if (!item.price || isNaN(item.price) || item.price <= 0) {
				toast.error(`Please provide a valid price for row ${index + 1}`);
				isValid = false;
				return;
			}

			const showItem = show.find(showItem => showItem.name === item?.name);
			if (!showItem) {
				toast.error(`Item ${index + 1} not found`);
				isValid = false;
				return;
			}
		});

		return isValid;
	};


	const handleCurrencyChange = selectedOption => {
		setCurrency(selectedOption);
	};

	const handleSupplierChange = (index, supplier) => {
		const updatedItems = [...items];
		updatedItems[index].supplier = supplier;
		setItems(updatedItems);
	};

	const addRow = () => {
		setItems([...items, { name: '', qty: 0, price: '', currency: '', supplier: '', total_price: '' }]);
	};

	const removeRow = index => {
		const updatedItems = [...items];
		updatedItems.splice(index, 1);
		setItems(updatedItems);
	};

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className='add-to-pl-confirmation-dialog'
		>
			<Modal.Body style={{ paddingBottom: '25px' }}>
				<Modal.Title>
					<span className='warning-icon'><ImWarning /></span>
					<span className='title-process' style={{ fontSize: '16px' }}>{title || 'Process Order'}</span>
				</Modal.Title>

				<div>
					<p className='message textGray' style={{ marginTop: '-5px' }}>
						{message || 'Are you sure you want to Process these items?'}
					</p>


					{products && products.length > 0 ? (
						<div className="productsTable estlist blur-container" style={{ fontSize: '12px' }}>
							<div className="table-responsive-xl tableData">
								<div className="section-head">
									<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>
										Products
									</h2>
									<div className="actions">
										{/* <Select
											className="form-inp"
											classNamePrefix="rs"
											isSearchable={true}
											name="customer"
											menuPosition="fixed"
											options={currencies}
											value={iscurrency}
											onChange={handleCurrencyChange}
										/> */}

										<ReactSelect
											className="rs-container"
											classNamePrefix="rs"
											// options={[...models]?.filter((m) => invItems?.every((it) => it.model != m.value) || m.value == item.model)}

											options={currencies}
											value={iscurrency}
											menuPlacement="auto"
											menuPosition="fixed"
											onChange={handleCurrencyChange}
										/>
									</div>
								</div>
								<table className="table table-md text-gray-900 formActBtns border-0 table-process">
									<thead>
										<tr>
											<th className="text-left pl-3" width={'300'}>Model</th>
											<th className="text-left pl-3" width={'300'}>Supplier</th>
											<th className="align-middle" width={'80'} style={{ textAlign: 'left' }}>Qty</th>
											<th className="align-middle" width={'150'} style={{ textAlign: 'left' }}>Price</th>
											<th className="align-middle text-left" width={'130'}>Total</th>
											<th className="align-middle text-center" width={'30'}></th>
										</tr>
									</thead>
									<tbody>
										{items?.map((item, index) => (
											<>
												<tr key={index}>
													<td className="align-middle estimateCName">
														<div className="form-group col-md-12 mb-0">
															{/* <Select
																className="form-inp"
																classNamePrefix="rs"
																isSearchable={true}
																name="product"
																menuPosition="fixed"
																options={products}
																value={products[index]}
																onChange={selectedOption => handleProductNameChange(index, selectedOption)}

															/> */}

															<ReactSelect
																className="rs-container"
																classNamePrefix="rs"
																// options={[...models]?.filter((m) => invItems?.every((it) => it.model != m.value) || m.value == item.model)}

																options={products}
																value={products[index]}
																menuPlacement="auto"
																menuPosition="fixed"
																onChange={selectedOption => handleProductNameChange(index, selectedOption)}
															/>
														</div>
													</td>
													<td>

														<ReactSelect
															className="rs-container"
															classNamePrefix="rs"
															// options={[...models]?.filter((m) => invItems?.every((it) => it.model != m.value) || m.value == item.model)}

															options={supplierList}
															value={item.supplier}
															menuPlacement="auto"
															menuPosition="fixed"
															onChange={selectedOption => handleSupplierChange(index, selectedOption)}
														/>
													</td>
													<td className="align-middle ">
														<input
															className="numField"
															type="number"
															value={item?.qty}
															placeholder="00"
															maxLength={10}
															onChange={e => {
																const updatedItems = [...items];
																updatedItems[index].qty = e.target.value;
																updatedItems[index].total_price = e.target.value * updatedItems[index].price
																setItems(updatedItems);
															}}
															style={{ width: '60px', paddingInline: '3px' }}
															onFocus={(event) => {
																event.target.select();
															}}
														/>
													</td>
													<td className="align-middle">
														<input
															className="numField"
															type="number"
															value={item?.price}
															placeholder="00"
															onChange={e => handlePriceChange(index, e.target.value)}
															style={{ width: '100px', paddingInline: '3px' }}
															onFocus={(event) => {
																event.target.select();
															}}
														/>
													</td>
													<td className="align-middle text-left">
														{iscurrency.value === 'usd' ? ' $ ' : (iscurrency.value === 'aed' ? ' AED ' : ' € ')}{' '}
														{item.total_price ? item.total_price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}
													</td>
													<td className="align-middle text-center">
														<span
															className="btnEdit btn-delete-row"
															onClick={() => removeRow(index)}
														>
															<FaTimes />
														</span>
													</td>
												</tr>
											</>
										))
										}
									</tbody>

									<tfoot>
										<tr className='tr-nth'>
											{/* <td className="align-middle pl-3 estimateCName">
												<button type="button" onClick={() => addRow()} className="btn btn-primary btn-outlined-main btn-add-row">
													<FaPlus /> Add new row
												</button>
											</td> */}
											<td className="align-middle" colSpan={2}>
												<div className="form-group col-md-12 mb-0 text-center">
													{/* <label>{totalQuantity}</label> */}
												</div>
											</td>
											<td className='align-middle total-process'>
												{totalQuantity ? totalQuantity : 0}
											</td>
											<td className='total-process text-right'>
												Total
											</td>
											<td style={{ textAlign: 'left' }} className='total-process'>
												{iscurrency.value === 'usd' ? ' $ ' : (iscurrency.value === 'aed' ? ' AED ' : ' € ')}{' '}
												{total ? total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}
											</td>
											<td></td>
										</tr>
									</tfoot>
								</table>

							</div>
						</div>
					) : (
						<ul className='products'>
							{show?.map((item, index) => (
								<li key={index}>{item?.name} - {item?.qty}Pcs</li>
							))}
						</ul>
					)}




				</div>
				{products && products.length > 0 ? (
					<div className="modal-actions flex-direction-row">
						<div className='product-section'>
							<label htmlFor="">Product List</label>
							<div className="product-list">
								<table>
									<thead>
										<tr>
											<th className="underline-italic" width={'310'}>Product</th>
											<th className="underline-italic" width={'100'}>Free Stock</th>
											<th className="underline-italic" width={'150'}>Quantity Needed</th>
										</tr>
									</thead>
									<tbody>
										{show?.map((item) => (
											<tr>
												<td>{item?.name}</td>
												<td>

													{freeStock[item.id] !== undefined ? `${freeStock[item.id]} Pcs` : 'Not Available'}
												</td>
												<td>{item?.qty} Pcs</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>


					</div>
				) : (
					<></>
				)}

				<div className='modal-actions flex-direction-row justify-center' style={{ marginTop: '10px' }}>
					<button
						className="btn btn-primary btn-outlined-main btn-confirm"
						disabled={isDisabled}
						onClick={() => {
							handleConfirm && handleButtonClick();
						}}
					>
						Confirm
					</button>
					<button
						className="btn btn-primary btn-outlined-main"
						onClick={(e) => {
							handleClose && handleClose(e);
						}}
					>
						Cancel
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default AddToPurchaseListConfirmation;
