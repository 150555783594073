import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ViewDetailsIcon, DeleteIcon2 } from 'ui-component/custom-icons/icons';
import Pagination from 'ui-component/Pagination';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import Scroller from 'ui-component/Scroller';
// import NewShipments from './AddShipments';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import moment from 'moment';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton, Checkbox } from '@mui/material';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import usePageTitle from 'hooks/usePageTitle';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import StatusBadge from 'ui-component/StatusBadge';
const index = () => {
    const [setPageTitle] = usePageTitle('Picklists');
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);
    const [params, setParams] = useSearchParams();
    const history = useNavigate();
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [showDelete, setShowDelete] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setpagination] = useState([]);
    const dispatch = useDispatch();

    const [searchField, setSearchField] = useState();
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [filters, setFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});
    const [isFiltersloading, setIsFiltersloading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [allChecked, setAllChecked] = useState(false);
    const [picklists, setPicklists] = useState([]);


    const getOperationsLists = () => {
        setIsLoading(true);
        api
            .getSalesOrderTest()
            .then((res) => {
                setIsLoading(false);
                if (res.status) {
                    setPicklists(res?.data?.data);
                    setpagination(res?.data);
                } else {
                    setPicklists([]);
                }
                isFirstLoad && setIsFirstLoad(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setPicklists([]);
                console.error('Failed to fetch returns list:', error);
            });
    };
    const handleSearch = (e) => {
        let text = e.target.value;
        setSearchField(text);

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        let timeout = setTimeout(() => {
            setIsLoading(true);
            if (text.length > 2) {
                api.filterPicklists(activeFilters, text).then((res) => {
                    setIsLoading(false);
                    setPicklists(res.data.data);
                    setpagination(res.data);
                });
            } else if (text.length > 0 && text.length <= 2) {
                setIsLoading(false);
            } else {
                api.filterPicklists(activeFilters, null).then((res) => {
                    setIsLoading(false);
                    setPicklists(res.data.data);
                    setpagination(res.data);
                });
            }
        }, 1000);

        setSearchTimeout(timeout);
    };

    const viewData = (id) => {
        history(`/picklists/${id}`);
    };

    useEffect(() => {
        !isFirstLoad && handleFilter();
    }, [activeFilters]);

    const handleFilter = (activeF = null, searchF = null) => {
        setIsLoading(true);
        api.filterPicklists(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
            setIsLoading(false);
            setPicklists(res.data.data);
            setpagination(res.data);
        });
    };



    const handleClose = () => setShowDelete('');

    const handleData = (data) => {
        setPicklists(data.data);
        setpagination(data);
    };

    useEffect(() => {
        getOperationsLists();
        getFiltersList();
    }, []);

    const getFiltersList = () => {
        setIsFiltersloading(true);
        api.getPicklistFilter().then((res) => {
            setFilters(res.data);
            setIsFiltersloading(false);
        });
    };

    const handleDelete = (id) => {
        toast.promise(
            api.deleteOperation(id).then((res) => {
                if (res.status) {
                    setShowDelete('');
                    getOperationsLists()
                }
                return res?.status ? res : abc;
            }),
            {
                loading: 'Deleting Operations...',
                success: (data) => `${data.message}`,
                error: <b>Failed to delete returns</b>
            }
        );
    };

    const deleteOperation = (data) => {
        setShowDelete(data);
    };

    const handleCheckAllItems = () => {
        const newCheckedState = !allChecked;
        setAllChecked(newCheckedState);
        // setCheckedItems(checkedItems.map(item => ({ ...item, checked: newCheckedState })));
        setPicklists(picklists.map(item => ({ ...item, isChecked: newCheckedState })));
    }

    const handleItemCheck = (index) => {
        // setHasCheckedItems
        const newList = [...picklists];
        newList[index].isChecked = !newList[index].isChecked;
        setPicklists(newList);
    };

    const areAnyItemsChecked = () => {
        return picklists.some(item => item.isChecked);
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    {/* <div className="section-head-new" style={{ marginTop: '10px', paddingLeft: '0' }}>

                        <h2>

                         
                        </h2>
                        <div className="actions">
                            <PermissionAllowed permissions={['Create Operations']}>
                                <button
                                    className="btn btn-primary btn-outlined-main-primary white-var"
                                    onClick={() => {
                                        history('/operations/create');
                                    }}
                                >
                                    <span className="menuIcon">
                                        <FaPlus style={{ fontSize: '13px', marginTop: '-3px' }} />
                                    </span>
                                    <span>New Batch</span>
                                </button>
                            </PermissionAllowed>
                        </div>
                    </div> */}
                    <div className="card shadow-sm bg-white mb-4 mt20">
                        <div className="d-flex align-items-center justify-content-between TableSerachBar">
                            <div>
                                <div className="actions">
                                    <div style={{ margin: '0 5px' }}>
                                        <Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
                                    </div>
                                </div>
                            </div>
                            <form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control bg-white border"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="basic-addon2"
                                        onChange={handleSearch}
                                        style={{ width: '100%' }}
                                    />
                                    <span className="searchIcon">
                                        <FaSearch />
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="table-responsive-xl tableData customersList p-2">
                            <Scroller extraHeight={100}>
                                <table className="table pm-table">
                                    <thead>
                                        <tr className="sticky-header">
                                            <th scope="col" style={{ width: '30px' }}>
                                                <Checkbox
                                                    style={{ padding: '0px' }}
                                                    onChange={() => {
                                                        handleCheckAllItems()
                                                    }}
                                                />
                                            </th>
                                            <th scope="col" >Picklist No</th>
                                            <th scope="col text-left" >Customer</th>
                                            <th scope="col text-left" >Products</th>
                                            <th scope="col text-left" >Status</th>
                                            <th scope="col text-left" >Reference No</th>
                                            <th scope="col text-left" >Created</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <>
                                                {[...Array(7)].map((e, i) => (
                                                    <tr>
                                                        {[...Array(7)].map((e, i) => (
                                                            <td>
                                                                <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {picklists?.map((row, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index} className="hover-row">
                                                                <td className="align-middle">
                                                                    
                                                                    <Checkbox
                                                                        style={{ padding: '5px', paddingLeft: 0 }}
                                                                        checked={row.isChecked || false}
                                                                        onChange={() => handleItemCheck(index)}
                                                                    />
                                                                </td>
                                                                <td
                                                                    className="align-middle font500 text-primary"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Picklists (Button)'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Picklists (Button)']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    {row?.picklist_no}
                                                                </td>
                                                                <td
                                                                    className="align-middle"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Picklists (Button)'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Picklists (Button)']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    {row?.customer?.name}
                                                                </td>
                                                                <td
                                                                    className="align-middle"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Picklists (Button)'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Picklists (Button)']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    {row?.product_qty}
                                                                </td>
                                                                <td
                                                                    className="align-middle"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Picklists (Button)'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Picklists (Button)']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    <div style={{ width: '110px' }}>
                                                                        <StatusBadge itemStatus={row?.status?.name} />
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className="align-middle"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Picklists (Button)'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Picklists (Button)']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    <div style={{ maxWidth: '100px' }}>
                                                                        {row?.reference_no}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className="align-middle"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Picklists (Button)'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Picklists (Button)']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    {moment(row?.created_at).format(CommonDateOnlyFormat)}
                                                                </td>

                                                                <td className="hover-button-container" >
                                                                    <div>
                                                                        <div className="hover-button">
                                                                            
                                                                            <PermissionAllowed permissions={['View Picklists (Button)']}>
                                                                                <div
                                                                                    className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
                                                                                    style={{ marginLeft: '8px' }}
                                                                                    onClick={() => {
                                                                                            viewData(row.id);
                                                                                    }}
                                                                                >
                                                                                    <ViewDetailsIcon height={'16'} width={'16'} /> 
                                                                                </div>
                                                                            </PermissionAllowed>

                                                                            <PermissionAllowed permissions={['Delete Operations']}>
                                                                                <div
                                                                                    className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
                                                                                    style={{ marginLeft: '8px' }}
                                                                                    onClick={() => {
                                                                                        deleteOperation(row);
                                                                                    }}
                                                                                >
                                                                                    <DeleteIcon2 height={'20'} width={'20'} /> 
                                                                                    {/* <CheckCircleIcon height={'20'} width={'20'} /> Complete */}
                                                                                </div>
                                                                            </PermissionAllowed>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                })}
                                                {
                                                    picklists?.length < 1 && (
                                                        <tr className="no-hover">
                                                            <td colSpan={6} className="whitebg">
                                                                <div className="not-found-container">
                                                                    <div className="not-found-icon">
                                                                        <img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
                                                                    </div>
                                                                    <h6 className="not-found-text">No Data Available</h6>

                                                                    <PermissionAllowed permissions={['Create Operation']}>
                                                                        <Link to={'/opearations/create'} className="btn btn-primary btn-outlined-main">
                                                                            Create Operation
                                                                        </Link>
                                                                    </PermissionAllowed>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </Scroller>
                        </div>
                        <Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
                        <div style={{ marginBottom: '8px'}}></div>
                    </div>
                </div>
            </div>
        
            {
                areAnyItemsChecked() && (
                    <>
                        
                        <div className="row">
                            <div className="col-12">
                                <div className="checkbox-action">
                                    <div>
                                        With selected picklists
                                    </div>
                                    <PermissionAllowed permissions={['Operations Item Grading']}>
                                        {/* <Tooltip title={'Identify Grading'} arrow placement="top"> */}
                                            <button
                                                className="btn btn-primary btn-outlined-main white-var btn-icon-only btn-icon-square"
                                                onClick={() => {
                                                    triggerCheckboxAction()
                                                    // setOpenGradingPopup(true)
                                                }}
                                                // disabled={!areAnyItemsChecked()}
                                            >
                                                Action
                                            </button>
                                        {/* </Tooltip> */}
                                    </PermissionAllowed>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            {showDelete && (
                <DeleteConfirmation
                    show={showDelete}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    title="Confirm"
                    message={`Are you sure you want to delete ${showDelete?.reference_number}?`}
                />
            )}

        </>
    );
};

export default index;
