import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { differenceInDays } from 'date-fns';
import index from 'views/estimation';

const TImelineStep = ({ selectedEstimate, startDateParam, endDateParam }) => {


    let showExpiryDetails = false;

    // const startDate = new Date(selectedEstimate?.transaction_type == 'Invoice' ? selectedEstimate?.invoice_date : selectedEstimate?.start_date);
    // const endDate = new Date(selectedEstimate?.transaction_type == 'Invoice' ? selectedEstimate?.invoice_due_date : selectedEstimate?.expires_at);
    const startDate = new Date(startDateParam);
    const endDate = new Date(endDateParam);
    const totalDays = differenceInDays(endDate, startDate);

    const currentDate = new Date(moment().format('YYYY-MM-DD'));
    const consumedDays = differenceInDays(currentDate, startDate);

    const consumedPercentage = (consumedDays / totalDays) * 100;
    const roundedConsumedPercentage = (Math.round(consumedPercentage) <= 100) ? Math.round(consumedPercentage) : 100;


    const numbers = Array.from({ length: totalDays + 1 }, (_, i) => i);
    let groupedNumbers = [];

    for (let i = 0; i < numbers.length; i += 3) {
        groupedNumbers.push(numbers.slice(i, i + 3));
    }
    const lastNumbers = groupedNumbers.map(group => group[group.length - 1]);
    const displayConsumedDays = consumedDays > totalDays ? totalDays : consumedDays;
    if (consumedDays > totalDays) {
        showExpiryDetails = true
    }

    return (
        // <>
        <div style={{ width: '100%' }}>

            {/* <div>
                {roundedConsumedPercentage}% {consumedDays} of {totalDays} days
            </div> */}


            {/* {
                (selectedEstimate && selectedEstimate?.paid_status != 'Paid') && (
                    <> */}
                        <div className="est-detail-item">
                            <label htmlFor="inputName" className="est-labels">
                                Status
                            </label>
                            <div>
                                {
                                    consumedDays <= 1 ? (
                                        <span>{consumedDays} day open</span>
                                    ) : (
                                        <span>{displayConsumedDays} days open</span>
                                    )
                                }
                                {
                                    showExpiryDetails && (
                                        <>
                                            , <span>{parseInt(consumedDays) - parseInt(totalDays)} day{(parseInt(consumedDays) - parseInt(totalDays) > 1) && 's'} expired</span>
                                        </>
                                    )
                                }

                            </div>

                        </div>
                        <div className="">
                            <div class={showExpiryDetails ? 'timeline timeline--danger' : 'timeline timeline--warning'} data-controller="timeline-component">
                                <p class="timeline__text">0</p>
                                {
                                    lastNumbers?.map((number, index) => (
                                        <>
                                            {/* {console.log(lastNumbers.length - index)} */}
                                            <React.Fragment key={index}>
                                                {/* <div className="timeline__step" style={{ flexGrow: lastNumbers.length - index }}></div> */}
                                                <div className="timeline__step" style={{ flexGrow: 1 }}></div>
                                                <p className="timeline__text">{number}</p>
                                            </React.Fragment>
                                        </>
                                    ))
                                }
                                {/* <div class="timeline__step" style={{ flexGrow: '1' }}></div>
                                <p class="timeline__text">5</p> */}


                                {/* <div class="timeline__step" style={{ flexGrow: '1' }}></div>
                                <p class="timeline__text">5</p>
                                <div class="timeline__step" style={{ flexGrow: '2' }}></div>
                                <p class="timeline__text">5</p>
                                <div class="timeline__step" style={{ flexGrow: '2' }}></div>
                                <p class="timeline__text">{totalDays}</p> */}


                                <span class="timeline__active-line timeline__active-line" style={{ width: `${roundedConsumedPercentage}%` }}>
                                    <span class="timeline__active-dot"></span>
                                </span>
                            </div>
                        </div>
                    {/* </>
                )
            } */}
        </div>
        // </>
    )

    // return <div className={`badge ${badgeClass}`}>{itemStatus === 'Credit Note' || itemStatus === 'Unprocessed' ? 'Open' : itemStatus}</div>;
};

export default TImelineStep;
