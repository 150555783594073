import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, storeInLocalStore, setStateModellist } from 'store/reducers/mainSlice';
import { LuChevronDown } from 'react-icons/lu';
import Loader from 'ui-component/Loader';
import { Dropdown } from 'react-bootstrap';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import { FaEye, FaPlus, FaSearch, FaFilter } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { Skeleton, Tooltip } from '@mui/material';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import usePageTitle from 'hooks/usePageTitle';
import { BsFiletypeXls } from 'react-icons/bs';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
// import { FaFilter, } from 'react-icons/fa6';
import toast from 'react-hot-toast';

import NoImage from 'assets/images/no-image.png';

const Index = () => {
	const [setPageTitle] = usePageTitle('Products');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const history = useNavigate();
	const [productList, setProductList] = useState([]);
	const [pagination, setpagination] = useState([]);
	const [show, setShow] = useState('');
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const [allPermissions, hasEveryPermission] = usePermissions();
	const [modelList, setModelList] = useState();
	const dispatch = useDispatch();
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [isProductPageInactive, setIsProductPageInactive] = useState(false);

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isPageChanged, setIsPageChanged] = useState(true);
	const [initializedProductList, setInitializedProductList] = useState(false);
	const [previousUrl, setPreviousUrl] = useState('/products');
	const [indexToDelete, setIndexToDelete] = useState(null);

	const location = useLocation();
	const pathParts = location.pathname;

	const getProductList = () => {
		setIsLoading(true);
		api.productList().then((res) => {
			// console.log(res.data);
			setpagination(res.data);
			setProductList(res.data.data);
			setModelList(res.data);
			dispatch(setLoading(false));
			setIsLoading(false);
			isFirstLoad && setIsFirstLoad(false);
		});
		setIsPageChanged(false);
	};
	const getInactiveProductList = () => {
		setIsLoading(true);
		api.getInactiveProductList().then((res) => {
			// console.log(res.data);
			setpagination(res.data);
			setProductList(res.data.data);
			setModelList(res.data);
			dispatch(setLoading(false));
			setIsLoading(false);
			isFirstLoad && setIsFirstLoad(false);
		});
		setIsPageChanged(false);
	};
	const deleteProductData = (data) => {
		setShow(data);
	};
	const handleClose = () => setShow('');

	const handleDelete = (id) => {
		api.deleteProductDetail(id).then((res) => {
			if (res.status) {
				setShow('');
				const updatedProductList = productList.filter((_, index) => index !== indexToDelete);
				setProductList(updatedProductList);

				toast.success(res.message);
				// getProductList();
			} else {
				setShow('');
				toast.error(res.message);
			}
		});
	};

	const viewData = (id) => {
		history(`/products/${id}`);
	};
	useEffect(() => {
		if (initializedProductList) {
			if (pathParts == '/products') {
				if (previousUrl != pathParts) {
					console.log('asd');
					getProductList();
					setPreviousUrl(pathParts);
					setActiveFilters({});
				}
				setIsProductPageInactive(false);
			} else {
				if (previousUrl != pathParts) {
					getInactiveProductList();
					setPreviousUrl(pathParts);
					setActiveFilters({});
				}
				setIsProductPageInactive(true);
			}
		} else {
			setInitializedProductList(true);
			if (pathParts == '/products') {
				// if (previousUrl != pathParts) {
				console.log('asd');
				getProductList();
				setPreviousUrl(pathParts);
				setActiveFilters({});
				// }
				setIsProductPageInactive(false);
			} else {
				// if (previousUrl != pathParts) {
				getInactiveProductList();
				setPreviousUrl(pathParts);
				setActiveFilters({});
				// }
				setIsProductPageInactive(true);
			}
		}
		setSearchField('');

		getFiltersList();
	}, [location]); //location

	const handleData = (data) => {
		setProductList(data.data);
		setpagination(data);
		// console.log('asd', data.data)

		// setpagination(data);
		// setProductList(data.data);
		// setModelList(data);
	};

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);
		const status = !isProductPageInactive ? 'active' : 'inactive';
		api.filterProduct(activeFilters, searchField?.length > 2 ? searchField : null, status).then((res) => {
			setIsLoading(false);
			setProductList(res.data.data);
			setpagination(res.data);
		});
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getProductFilters().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};
	const ImportProduct = () => {
		history('/products/import');
	};
	const ExportProduct = () => {
		history('/products/export');
	};

	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsLoading(true);
			const status = !isProductPageInactive ? 'active' : 'inactive';
			if (text.length > 2) {
				api.filterProduct(activeFilters, text, status).then((res) => {
					setIsLoading(false);
					setProductList(res.data.data);
					setpagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterProduct(activeFilters, null, status).then((res) => {
					setIsLoading(false);
					setProductList(res.data.data);
					setpagination(res.data);
				});
			}
		}, 1000);

		setSearchTimeout(timeout);
	};

	return (
		<>
			<div className="row">
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head-new mb-1 mar102000">
						<h2>
							<PermissionAllowed permissions={['Create Model (Button)']}>
								<div className="actions mb10">
									<Link to={'/products/create'} className="btn btn-primary btn-outlined-main-primary white-var">
										<FaPlus /> New Product
									</Link>
								</div>
							</PermissionAllowed>
						</h2>
						<div className="actions" style={{ marginBottom: '10px' }}>
							{isProductPageInactive ? (
								<Link to={'/products'} className="btn btn-primary btn-outlined-main white-var">
									<FaFilter /> Active Products
								</Link>
							) : (
								<Link to={'/products/inactive'} className="btn btn-primary btn-outlined-main white-var">
									<FaFilter /> Inactive Products
								</Link>
							)}
							<PermissionAllowed permissions={['Create Model (Button)']}>
								<Dropdown className="btnDel iconbtn" align={'end'}>
									<Dropdown.Toggle as="span">
										<Link to={''} className="btn btn-primary btn-outlined-main white-var btn-create-report" style={{ minWidth: 'unset' }}>
											<BsFiletypeXls style={{ fontSize: '1.4em' }} /> Import/Export <LuChevronDown />
										</Link>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item onClick={() => ImportProduct(1)}>Import products</Dropdown.Item>
										<Dropdown.Item onClick={() => ExportProduct()}>Export products</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
								{/* <Link to={'/products/create'} className="btn btn-primary btn-outlined-main-primary white-var">
									<FaPlus /> New Product
								</Link> */}
							</PermissionAllowed>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4 table-card">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search">
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
										value={searchField}
										style={{ width: '100%' }}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						{/* <div className="table-responsive-xl tableData productListing p-2"> */}

						<div className="estimation-table-area">
							<div className="table-side">
								<div className="table-responsive-xl tableData estimationList">
									<Scroller extraHeight={100}>
										<table className="table pm-table">
											<thead>
												<tr className="sticky-header ">
													<th scope="col" className="" style={{ width: '50px' }}></th>
													<th scope="col" className="" style={{ width: '150px' }}>
														SKU
													</th>
													<th scope="col" className="">
														Product
													</th>
													<th scope="col" className="align-left" style={{ width: '130px' }}>
														Price
													</th>
													<th scope="col" style={{ width: '130px' }}>
														Stock
													</th>
													<PermissionAllowed permissions={['Delete Product (Button)']}>
														<th scope="col" className="text-center" style={{ width: '50px' }}>
															Action
														</th>
													</PermissionAllowed>
												</tr>
											</thead>
											<tbody>
												{isLoading ? (
													<>
														{[...Array(5)].map((e, i) => (
															<tr>
																{[...Array(6)].map((e, i) => (
																	<td>
																		<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																	</td>
																))}
															</tr>
														))}
													</>
												) : (
													<>
														{productList?.map((row, index) => {
															return (
																<tr key={index} style={{ height: '52px' }}>
																	<td
																		className="align-middle"
																		onClick={() => {
																			if (hasEveryPermission(['View Product (Button)'])) {
																				viewData(row.id);
																			}
																		}}
																		style={{
																			cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																		}}
																	>
																		{row?.thumbnail ? (
																			<img src={row?.thumbnail_url} style={{ width: '30px' }} alt="product" />
																		) : (
																			<img src={NoImage} style={{ width: '30px' }} alt="product" />
																		)}
																	</td>
																	<td
																		className="align-middle "
																		onClick={() => {
																			if (hasEveryPermission(['View Product (Button)'])) {
																				viewData(row.id);
																			}
																		}}
																		style={{
																			cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																		}}
																	>
																		{row?.sku}
																	</td>
																	<td
																		className="align-middle "
																		onClick={() => {
																			if (hasEveryPermission(['View Product (Button)'])) {
																				viewData(row.id);
																			}
																		}}
																		style={{
																			cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																		}}
																	>
																		{row?.name}
																	</td>
																	<td
																		className="align-left "
																		onClick={() => {
																			if (hasEveryPermission(['View Product (Button)'])) {
																				viewData(row.id);
																			}
																		}}
																		style={{
																			cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																		}}
																	>
																		$ {row?.price}
																	</td>
																	<td
																		onClick={() => {
																			if (hasEveryPermission(['View Product (Button)'])) {
																				viewData(row.id);
																			}
																		}}
																		style={{
																			cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																		}}
																	>
																		<div>
																			{row?.stocks?.total}
																		</div>
																		<div className="textGray">
																			Free: {row?.stocks?.free}
																		</div>
																	</td>
																
																	<td className="align-middle text-center action-col" style={{ height: '52px' }}>
																		<PermissionAllowed permissions={['Delete Product (Button)']} hasSome>
																			<a
																				href="javascript:void(0);"
																				onClick={() => {
																					if (hasEveryPermission(['Delete Returns (Button)'])) {
																						deleteProductData(row);
																						setIndexToDelete(index);
																					}
																				}}
																				style={{ marginLeft: '3px' }}
																			>
																				<svg
																					id="vuesax_linear_trush-square"
																					data-name="vuesax/linear/trush-square"
																					xmlns="http://www.w3.org/2000/svg"
																					width="28"
																					height="20"
																					viewBox="0 0 31.855 31.855"
																				>
																					<g id="trush-square">
																						<path
																							id="Vector"
																							d="M0,17.255V9.291C0,2.655,2.655,0,9.291,0h7.964c6.637,0,9.291,2.655,9.291,9.291v7.964c0,6.637-2.655,9.291-9.291,9.291H9.291C2.655,26.546,0,23.891,0,17.255Z"
																							transform="translate(2.655 2.655)"
																							fill="none"
																							stroke="#ff342d"
																							stroke-linecap="round"
																							stroke-linejoin="round"
																							stroke-width="2"
																						/>
																						<g id="Group" transform="translate(8.097 8.548)">
																							<path
																								id="Vector-2"
																								data-name="Vector"
																								d="M0,.438,1.779.265A51.533,51.533,0,0,1,6.942,0a86.992,86.992,0,0,1,8.72.438"
																								transform="translate(0 3.026)"
																								fill="none"
																								stroke="#ff342d"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="2"
																							/>
																							<path
																								id="Vector-3"
																								data-name="Vector"
																								d="M0,2.588.186,1.447C.319.624.425,0,1.9,0H4.181C5.654,0,5.76.65,5.893,1.447l.186,1.128"
																								transform="translate(4.792)"
																								fill="none"
																								stroke="#ff342d"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="2"
																							/>
																							<path
																								id="Vector-4"
																								data-name="Vector"
																								d="M11.932,0l-.571,8.76c-.093,1.367-.173,2.429-2.6,2.429H3.172c-2.429,0-2.509-1.062-2.6-2.429L0,0"
																								transform="translate(1.858 3.57)"
																								fill="none"
																								stroke="#ff342d"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="2"
																							/>
																						</g>
																						<path id="Vector-5" data-name="Vector" d="M0,0H31.855V31.855H0Z" fill="none" opacity="0" />
																					</g>
																				</svg>
																			</a>
																		</PermissionAllowed>
																	</td>
																</tr>
															);
														})}
														{
															productList?.length < 1 && (
																<tr className="no-hover">
																	<td colSpan={6}>
																		<div className="not-found-container">
																			<div className="not-found-icon">
																				<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
																			</div>
																			<h6 className="not-found-text">No Data Available</h6>

																			<PermissionAllowed permissions={['Create New Product (Action)']}>
																				<Link to={'/purchasing/create'} className="btn btn-primary btn-outlined-main">
																					Create Product
																				</Link>
																			</PermissionAllowed>
																		</div>
																	</td>
																</tr>
															)
														}
													</>
												)}
											</tbody>
										</table>
									</Scroller>
								</div>
								<Pagination pagination={pagination} data={handleData} style={{ marginBottom: 10 }} />
								{/* {console.log(pagination)} */}
							</div>
						</div>
					</div>
				</div>
			</div>

			{show && (
				<DeleteConfirmation
					show={show}
					handleClose={handleClose}
					handleDelete={handleDelete}
					title="Confirm"
					message={`Are you sure you want to delete ${show.name}?`}
				/>
			)}
		</>
	);
};

export default Index;
