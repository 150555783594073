import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';


const AutosuggestInput = ({ value, setValue, suggestions, placeholder, 
    setSuggestions, getSuggestions,  getSuggestionValue, ismulti}) => {

    const renderSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );

    // const getSuggestionValue = suggestion => suggestion.name;

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = (value ) => {
        setSuggestions(getSuggestions(value));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onBlur = () => {
        // console.log('fous......')
    }

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder,
        value,
        onChange
    };

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onFocus={onBlur}
        />
    );

}

export default AutosuggestInput