import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { IconDotsVertical } from '@tabler/icons';
import 'assets/scss/charts.scss';

function SalesTarget() {
    const [selectedOption, setSelectedOption] = useState('monthly');
    const currentYear = new Date().getFullYear();
    const [salesTarget, setSalesTarget] = useState([]);
    const totalTarget = salesTarget.reduce((total, item) => total + parseFloat(item.target), 0);

    useEffect(() => {
        api.getSalesTarget(currentYear).then((res) => {
            const newSalesTarget = res.sales_target;
            setSalesTarget(newSalesTarget);
        });
    }, []);


    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    }

    const handleYearlySubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const totalTarget = formData.get('target');
        const margin = formData.get('margin');
        const monthlyTarget = totalTarget / 12;
        const result = salesTarget.map((item, index) => ({
            month: item.month,
            target: monthlyTarget,
            margin: margin
        }));

        console.log(result);
        api.updateSalesTarget(result).then((res) => {
            const newSalesTarget = res.sales_target;
            setSalesTarget(newSalesTarget);
        });
    };

    const handleMonthlySubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const targets = formData.getAll('target').map(target => target.replace(/,/g, ''));
        const margins = formData.getAll('margin');
        const result = salesTarget.map((item, index) => ({
            month: item.month,
            target: targets[index],
            margin: margins[index]
        }));
        console.log(result);
        api.updateSalesTarget(result).then((res) => {
            const newSalesTarget = res.sales_target;
            setSalesTarget(newSalesTarget);
        });
    };
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return (
        <>
            <div className="row" style={{ marginTop: '20px', marginBottom: '20px' }}>
                <div className="col-lg=8 col-md-8 col-sm-12" style={{ marginBottom: '20px' }}>
                    <div className="card">
                        <div className="card-header" style={{ backgroundColor: 'white', paddingBottom: 0, display: 'flex', justifyContent: 'space-between' }}>
                            <h5 className="card-title" style={{ fontWeight: 600 }}>Sales Target</h5>
                        </div>
                        <div className="card-body" style={{ paddingTop: '10px' }}>

                            <table className="sales-target-table">
                                <thead>
                                    <tr>
                                        <th className="custom-th">Month</th>
                                        <th className="custom-th revenue">Target</th>
                                        <th className="custom-th profit">Margin</th>
                                        <th className="percentage">12% of Profit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salesTarget.map((item, index) => (
                                        <tr key={index}>
                                            <td className="month-table">{months[item.month - 1]}</td>
                                            <td className="revenue">$ {parseFloat(item.target).toLocaleString()}</td>
                                            <td className="profit">$ {(item.target * item.margin / 100).toLocaleString()}</td>
                                            <td className="percentage">$ {((item.target * item.margin / 100) * .12).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className="custom-th">Total</td>
                                        <td className="custom-th">$ {salesTarget.reduce((total, item) => total + parseFloat(item.target), 0).toLocaleString()}</td>
                                        <td className="custom-th">$ {salesTarget.reduce((total, item) => total + item.target * item.margin / 100, 0).toLocaleString()}</td>
                                        <td className="percentage">$ {salesTarget.reduce((total, item) => total + item.target * item.margin / 100 * 0.12, 0).toLocaleString()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card">
                        <div className="card-header" style={{ backgroundColor: 'white', paddingBottom: 0, display: 'flex', justifyContent: 'space-between' }}>
                            <h5 className="card-title" style={{ fontWeight: 600, paddingTop: '5px' }}>{selectedOption === 'monthly' ? 'Monthly Configuration' : 'Yearly Configuration'}</h5>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className="custom-dropdown-toggle">
                                    <IconDotsVertical size={15} className="icon" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setSelectedOption('monthly')}>By Month</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setSelectedOption('yearly')}>By Year</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="card-body" style={{ paddingTop: '20px' }}>
                            {selectedOption === 'yearly' &&
                                <div>
                                    <div style={{ marginBottom: '30px' }}>
                                        <p><strong>Note:</strong> Using Yearly Configuration will automatically calculate your given target equally throughout the year.</p>
                                    </div>
                                    <form onSubmit={handleYearlySubmit}>
                                        <div className="form-group">
                                            <label htmlFor="target">Target</label>
                                            <input type="text" className="form-control" name="target" id="target" placeholder="Ex. 25000000" defaultValue={totalTarget} required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="year">Profit Margin (%)</label>
                                            <input type="text" className="form-control" name="margin" id="year" placeholder="5" required />
                                        </div>
                                        <button type="submit" className="btn btn-primary float-right">Submit</button>
                                    </form>
                                </div>
                            }
                            {selectedOption === 'monthly' &&
                                <div style={{ marginBottom: '30px' }}>
                                    <form onSubmit={handleMonthlySubmit}>
                                        <div className="row" style={{ marginBottom: '10px' }}>
                                            <div className="col-3 vertical-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <label style={{ fontWeight: 600, fontSize: '13px' }}>Month</label>
                                            </div>
                                            <div className="col-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <label style={{ fontWeight: 600, fontSize: '13px' }}>Target</label>
                                            </div>
                                            <div className="col-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <label style={{ fontWeight: 600, fontSize: '13px' }}>Margin (%)</label>
                                            </div>
                                        </div>
                                        {salesTarget.map((item, index) => (
                                            <div className="row" key={item.month} style={{ marginBottom: '20px' }}>
                                                <div className="col-3 vertical-center" style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                                    <div>
                                                        <p style={{ marginBottom: 0, fontWeight: 600, fontSize: '13px' }}>{months[item.month - 1]}</p>
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <input
                                                        type="text"
                                                        className="form-control input-custom"
                                                        name="target"
                                                        placeholder="Ex. 2500000"
                                                        defaultValue={new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.target)}
                                                        required
                                                        onFocus={(e) => {
                                                            e.target.value = e.target.value.replace(/,/g, '');
                                                        }}
                                                        onBlur={(e) => {
                                                            e.target.value = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(e.target.value.replace(/,/g, '')));
                                                        }}
                                                        style={{ textAlign: 'right' }}
                                                    />
                                                </div>
                                                <div className="col-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <input type="text" className="form-control input-custom" name="margin" placeholder="5" defaultValue={item.margin} required style={{ textAlign: 'center' }} />
                                                </div>
                                            </div>
                                        ))}
                                        <button type="submit" className="btn btn-primary float-right">Submit</button>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalesTarget;