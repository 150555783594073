import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Tableheader from 'ui-component/Tableheader';
import { Dropdown, InputGroup } from 'react-bootstrap';
import Pagination from 'ui-component/Pagination';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Scroller from 'ui-component/Scroller';
// import NewShipments from './AddShipments';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import copyIcon from 'assets/images/icons/copy.svg';
import moment from 'moment';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { Skeleton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import usePageTitle from 'hooks/usePageTitle';

const Index = () => {
	const [setPageTitle] = usePageTitle('Warehouses');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const [params, setParams] = useSearchParams();
	const history = useNavigate();
	const [allPermissions, hasEveryPermission] = usePermissions();
	const [alertMessage, setAlertMessage] = useState('');
	const [warehouseList, setWarehouseList] = useState([]);
	const [show, setShow] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	// const customerList = useSelector((state) => state.main.setcustomerlist);
	const [pagination, setpagination] = useState([]);
	const [newShow, setNewShow] = useState(false);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const handleNewClose = () => setNewShow(false);

	const getWarehouseList = () => {
		setIsLoading(true);
		api.getWarehouseList().then((res) => {
			setIsLoading(false);
			setWarehouseList(res.data.data);
			setpagination(res.data);
			isFirstLoad && setIsFirstLoad(false);
		});
	};
	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsLoading(true);
			if (text.length > 2) {
				api.filterWarehouse(activeFilters, text).then((res) => {
					setIsLoading(false);
					setWarehouseList(res.data.data);
					setpagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterWarehouse(activeFilters, null).then((res) => {
					setIsLoading(false);
					setWarehouseList(res.data.data);
					setpagination(res.data);
				});
			}
		}, 1000);

		setSearchTimeout(timeout);
	};

	const viewData = (id) => {
		history(`/warehouses/${id}`);
	};

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);
		api.filterWarehouse(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsLoading(false);
			setWarehouseList(res.data.data);
			setpagination(res.data);
		});
	};

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};


	const handleClose = () => setShow('');

	const handleData = (data) => {
		setWarehouseList(data.data);
		setpagination(data);
	};

	useEffect(() => {
		getWarehouseList();
		getFiltersList();
	}, []);

	useEffect(() => {
		if (params.get('create') == 'shipment') {
			setParams({});
			setNewShow(true);
		}
	});

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		backgroundColor: '#f2f2f2',
		zIndex: 1
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getWarehouseFilters().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};


	const handleDelete = (id) => {
		toast.promise(
			api.deleteWarehouse(id).then((res) => {
				if (res.status) {
					setShow('');
					api.getWarehouseList().then((res) => {
						setIsLoading(false);
						setWarehouseList(res.data.data);
						setpagination(res.data);
						isFirstLoad && setIsFirstLoad(false);
					});
				}
				return res?.status ? res : abc;
			}),
			{
				loading: 'Deleting Warehouse...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete warehouse</b>
			}
		);
	};

	const deleteWarehouseData = (data) => {
		setShow(data);
	};

	return (
		<>
			<div className="row">
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head-new mb-1 mar102000">
						<div className="actions mb10">
							<PermissionAllowed permissions={['Create Warehouse (Button)']}>
								<Link to={'/warehouses/create'} className="btn btn-primary btn-outlined-main-primary white-var">
									<FaPlus /> New Warehouse
								</Link>
							</PermissionAllowed>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4 table-card">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
										style={{ width: '100%' }}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="table-responsive-xl tableData customersList p-2">
							<Scroller>
								<table className="table pm-table">
									<thead>
										<tr className="sticky-header">
											<th scope="col">Name</th>
											<th scope="col" style={{ width: '160px' }}>Accept Orders</th>
											<th scope="col" style={{ width: '190px' }}>Selected by Default</th>
											<th scope="col" style={{ width: '210px' }}>Counts for general stock</th>
											<th scope="col" style={{ width: '100px' }}>Status</th>
											<th scope="col" style={{ width: '130px' }}>Stock Value</th>

											<PermissionAllowed permissions={['View Warehouse (Button)']}>
												<th scope="col" style={{ width: '50px' }}>
													Action
												</th>
											</PermissionAllowed>

										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<>
												{[...Array(5)].map((e, i) => (
													<tr>
														{[...Array(8)].map((e, i) => (
															<td>
																<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
															</td>
														))}
													</tr>
												))}
											</>
										) : (
											<>
												{warehouseList?.map((row, index) => {
													return (
														<>
															<tr key={index}>
																<td className="align-middle text-primary"
																	onClick={() => {
																		if (hasEveryPermission(['View Warehouse (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Warehouse (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	{row?.name}
																</td>
																<td className="align-middle text-left"
																	onClick={() => {
																		if (hasEveryPermission(['View Warehouse (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Warehouse (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	{(row?.can_accept_orders) ? 'Yes' : 'No'}
																</td>
																<td className="align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['View Warehouse (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Warehouse (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	{(row?.is_default) ? 'Yes' : 'No'}
																</td>
																<td className="align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['View Warehouse (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Warehouse (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	{(row?.can_include_stock) ? 'Yes' : 'No'}
																</td>
																<td className="align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['View Warehouse (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Warehouse (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>

																	<div className={`badge ${(row.status == 1) ? 'badge-success' : 'badge-danger'}`}>
																		{row.status === 1 ? ('Active') : ('Inactive')}
																	</div>
																</td>
																<td className="align-middle text-left"
																	onClick={() => {
																		if (hasEveryPermission(['View Warehouse (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Warehouse (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	{row?.stock_value}
																</td>
																<PermissionAllowed permissions={['Delete Warehouse (Button)', 'Edit Warehouse (Button)']} hasSome>

																	<td className="align-middle action-col" style={{ marginTop: '7px' }}>


																		<Tooltip
																			title={'Edit'}
																			arrow
																			placement="top"
																		>
																			<a href="javascript:void(0);"
																				onClick={() => {
																					if (hasEveryPermission(['View Warehouse (Button)'])) {
																						history(`/warehouses/edit/${row?.id}`);
																					}
																				}}
																				style={{ marginRight: '3px' }}
																			>
																				<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 19.875 21.722">
																					<g id="Group_40056" data-name="Group 40056" transform="translate(-2.982 -1.847)">
																						<path id="Vector" d="M14.452,1.27c2.195,2.076,1.559,3.774.149,5.263l-8.153,8.63a2.851,2.851,0,0,1-1.38.745l-3.2.546A1.517,1.517,0,0,1,.013,14.7l.367-3.218a3.029,3.029,0,0,1,.665-1.42L9.2,1.429c1.41-1.49,3.069-2.215,5.253-.159Z" transform="translate(4.972 2.868)" fill="none" stroke="#06f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
																						<path id="Vector-2" data-name="Vector" d="M5.412,5.114A6.084,6.084,0,0,1,0,0" transform="translate(12.81 5.737)" fill="none" stroke="#06f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
																						<path id="Vector-3" data-name="Vector" d="M0,0H17.875" transform="translate(3.982 22.569)" fill="none" stroke="#06f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
																					</g>
																				</svg>
																			</a>
																		</Tooltip>
																		<Tooltip
																			title={'Delete'}
																			arrow
																			placement="top"
																		>
																			<a href="javascript:void(0);"
																				onClick={() => {
																					if (hasEveryPermission(['Delete Warehouse (Button)'])) {
																						deleteWarehouseData(row)
																					}
																				}}
																				style={{ marginLeft: '3px' }}
																			>
																				<svg id="vuesax_linear_trush-square" data-name="vuesax/linear/trush-square" xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 31.855 31.855">
																					<g id="trush-square">
																						<path id="Vector" d="M0,17.255V9.291C0,2.655,2.655,0,9.291,0h7.964c6.637,0,9.291,2.655,9.291,9.291v7.964c0,6.637-2.655,9.291-9.291,9.291H9.291C2.655,26.546,0,23.891,0,17.255Z" transform="translate(2.655 2.655)" fill="none" stroke="#ff342d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
																						<g id="Group" transform="translate(8.097 8.548)">
																							<path id="Vector-2" data-name="Vector" d="M0,.438,1.779.265A51.533,51.533,0,0,1,6.942,0a86.992,86.992,0,0,1,8.72.438" transform="translate(0 3.026)" fill="none" stroke="#ff342d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
																							<path id="Vector-3" data-name="Vector" d="M0,2.588.186,1.447C.319.624.425,0,1.9,0H4.181C5.654,0,5.76.65,5.893,1.447l.186,1.128" transform="translate(4.792)" fill="none" stroke="#ff342d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
																							<path id="Vector-4" data-name="Vector" d="M11.932,0l-.571,8.76c-.093,1.367-.173,2.429-2.6,2.429H3.172c-2.429,0-2.509-1.062-2.6-2.429L0,0" transform="translate(1.858 3.57)" fill="none" stroke="#ff342d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
																						</g>
																						<path id="Vector-5" data-name="Vector" d="M0,0H31.855V31.855H0Z" fill="none" opacity="0" />
																					</g>
																				</svg>
																			</a>
																		</Tooltip>


																		{/* <Dropdown className="btnDel iconbtn">
																			<Dropdown.Toggle
																				as="span"
																				id={`dropdown-toggle-${index}`}
																			>
																				<i className="fas fa-ellipsis-h "></i>
																			</Dropdown.Toggle>
																			<Dropdown.Menu>
																				<PermissionAllowed
																					permissions={['Edit Warehouse (Button)']}
																				>
																					<Dropdown.Item
																						onClick={() => {
																							history(`/warehouses/edit/${row?.id}`);
																						}}
																					>
																						Edit
																					</Dropdown.Item>
																				</PermissionAllowed>
																				<PermissionAllowed
																					permissions={['Delete Warehouse (Button)']}
																				>
																					<Dropdown.Item
																						onClick={() => deleteWarehouseData(row)}
																					>
																						Delete
																					</Dropdown.Item>
																				</PermissionAllowed>
																			</Dropdown.Menu>
																		</Dropdown> */}
																	</td>
																</PermissionAllowed>

															</tr>
														</>
													);
												})}
												{
													warehouseList?.length < 1 && (
														<tr className="no-hover">
															<td colSpan={7}>
																<div className="not-found-container">
																	<div className="not-found-icon">
																		<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
																	</div>
																	<h6 className="not-found-text">No Data Available</h6>

																	<PermissionAllowed permissions={['Create New Warehouse (Action)']}>
																		<Link to={'/warehouses/create'} className="btn btn-primary btn-outlined-main">
																			Create Warehouse
																		</Link>
																	</PermissionAllowed>
																</div>
															</td>
														</tr>
													)
												}
											</>
										)}
									</tbody>
								</table>
							</Scroller>
						</div>
						<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
					</div>
				</div>
			</div>

			{show && (
				<DeleteConfirmation
					show={show}
					handleClose={handleClose}
					handleDelete={handleDelete}
					title="Confirm"
					message={`Are you sure you want to delete ${show?.name}`}
				/>
			)}
			{/* {newShow && <NewShipments newShow={newShow} handleNewClose={handleNewClose} onSubmit={onAddNewShipment} />} */}
		</>
	);
};

export default Index;
