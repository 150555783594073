import React from 'react';
import { IconArrowUpRight, IconArrowDownRight } from '@tabler/icons';
import { Fade } from 'react-awesome-reveal';

const ProfitCard = ({ soldGrowth, soldQuantity, returns, returnsGrowth }) => (
	<div className="col-lg-3 col-md-6 col-sm-12">
		<Fade>
			<div className="card" style={{ height: 210, marginBottom: '20px' }}>
				<div className="card-body">
					<div className="row" style={{ height: '100%' }}>
						<div className="col-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
							{soldGrowth > 0 ? (
								<div className="icon-container green">
									<IconArrowUpRight size={20} className="icon green" />
								</div>
							) : soldGrowth <= 0 ? (
								<div className="icon-container red">
									<IconArrowDownRight size={20} className="icon red" />
								</div>
							) : null}

							<a style={{ marginTop: 10, fontWeight: 500 }}>Product Sold</a>
							<h5 style={{ padding: 0, margin: 0, fontWeight: 700, marginBottom: '5px' }}>
								{soldQuantity ? `${parseInt(soldQuantity).toLocaleString('en-US')}` : '0'}
							</h5>
							<b
								style={{
									fontSize: 12,
									padding: 0,
									margin: 0,
									fontWeight: 300,
									color: soldGrowth ? (soldGrowth > 0 ? '#6dda52' : '#ff1313') : 'initial'
								}}
							>
								{soldGrowth ? (soldGrowth <= 0 ? `${soldGrowth}%` : `+${soldGrowth}%`) : 'Loading...'} last month
							</b>
						</div>
						<div className="col-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
							{returnsGrowth <= 0 ? (
								<div className="icon-container green">
									<IconArrowUpRight size={20} className="icon green" />
								</div>
							) : returnsGrowth > 0 ? (
								<div className="icon-container red">
									<IconArrowDownRight size={20} className="icon red" />
								</div>
							) : null}

							<a style={{ marginTop: 10, fontWeight: 500 }}>Total Return</a>
							<h5 style={{ padding: 0, margin: 0, fontWeight: 700, marginBottom: '5px' }}>{`${returns}`}</h5>
							<b
								style={{
									fontSize: 12,
									padding: 0,
									margin: 0,
									fontWeight: 300,
									whiteSpace: 'nowrap',
									display: 'block',
									color: returnsGrowth <= 0 ? '#6dda52' : '#ff1313'
								}}
							>
								{returnsGrowth >= 0 ? `+${returnsGrowth}%` : `${returnsGrowth}%`} last month
							</b>
						</div>
					</div>
				</div>
			</div>
		</Fade>
	</div>
);

export default ProfitCard;
