import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

const Pagination = ({ pagination, data, onLoading, reactQuery }) => {
	const [total, setTotal] = useState();
	const [perpage, setPerpage] = useState();
	const [currentPage, setCurrentpage] = useState();
	const clientUser = useSelector((state) => state.main.client);
	const test = useSelector((state) => state.main);

	const handlePageChange = async (url) => {
		if (reactQuery) {
			data(url);
		} else {
			onLoading && onLoading(true);
			if (Object.keys(test.user).length === 0) {
				const response = await api.paginationapiClient(url);
				if (response) {
					setTotal(response.data?.total);
					setPerpage(response.data?.per_page);
					setCurrentpage(response.data?.current_page);
					// console.log(response.data);
					data(response.data);
					onLoading && onLoading(false);
				}
			} else {
				const response = await api.paginationapi(url);
				if (response) {
					setTotal(response.data?.total);
					setPerpage(response.data?.per_page);
					setCurrentpage(response.data?.current_page);
					// console.log(response.data);
					data(response.data);
					onLoading && onLoading(false);
				}
			}
		}
	};
	const renderPageLink = (page) => {
		return (
			<>
				{page.label == '&laquo; Previous' ? (
					<>
						<li
							key={page.label}
							className={`paginate_button page-item ${page.label == '&laquo; Previous' && 'previous'} ${
								page.label == 'Next &raquo;' && 'next'
							} ${page.active ? 'active' : ''}`}
						>
							<Link to={''} onClick={() => handlePageChange(page.url)} className={`page-link ${page.url ? '' : 'disabled'}`}>
								<i className="fas fa-chevron-left"></i>
							</Link>
						</li>
					</>
				) : page.label == 'Next &raquo;' ? (
					<>
						<li
							key={page.label}
							className={`paginate_button page-item ${page.label == '&laquo; Previous' && 'previous'} ${
								page.label == 'Next &raquo;' && 'next'
							} ${page.active ? 'active' : ''}`}
						>
							<Link to={''} onClick={() => handlePageChange(page.url)} className={`page-link ${page.url ? '' : 'disabled'}`}>
								<i className="fas fa-chevron-right"></i>
							</Link>
						</li>
					</>
				) : (
					<></>
				)}
			</>
		);
		// return (
		// 	<li
		// 		key={page.label}
		// 		className={`paginate_button page-item ${page.label == '&laquo; Previous' && 'previous'} ${page.label == 'Next &raquo;' && 'next'} ${page.active ? 'active' : ''
		// 			}`}
		// 	>
		// 		{page.label == '&laquo; Previous' ? (
		// 			<>
		// 				<Link to={''} onClick={() => handlePageChange(page.url)} className={`page-link ${page.url ? '' : 'disabled'}`}>
		// 					<i className="fas fa-chevron-left"></i>
		// 				</Link>
		// 			</>
		// 		) : page.label == 'Next &raquo;' ? (
		// 			<>
		// 				<Link to={''} onClick={() => handlePageChange(page.url)} className={`page-link ${page.url ? '' : 'disabled'}`}>
		// 					<i className="fas fa-chevron-right"></i>
		// 				</Link>
		// 			</>
		// 		) : (
		// 			<>
		// 				<Link to={''} onClick={() => handlePageChange(page.url)} className={`page-link ${page.url ? '' : 'disabled'}`}>
		// 					{page.label}
		// 				</Link>
		// 			</>
		// 		)}
		// 	</li>
		// );
	};
	useEffect(() => {
		setTotal(pagination?.total);
		setPerpage(pagination?.per_page);
		setCurrentpage(pagination?.current_page);
	}, []);
	return (
		<>
			{pagination?.total > 0 ? (
				<div className="d-flex align-items-center justify-content-center paginationcon">
					<div className="col-sm-12 col-md-6">
						{/* <div className="dataTables_info p-2" id="DataTables_Table_0_info" role="status" aria-live="polite">
							{pagination?.from} to {pagination?.to} of {pagination?.total || total}
						</div> */}
					</div>
					<div className="col-sm-12 col-md-6">
						<div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: '-10px' }}>
							<div style={{ marginRight: '5px' }}>
								{/* {pagination?.from} to {pagination?.to} of {pagination?.total || total} */}
								{pagination?.total?.toLocaleString() || total?.toLocaleString()} items &#183; {pagination?.from} to{' '}
								{pagination?.to}
							</div>
							<div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
								<ul className="pagination justify-content-end mb-0">{pagination?.links?.map((page) => renderPageLink(page))}</ul>
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
};

export default Pagination;
