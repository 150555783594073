import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import LogoBlack from 'assets/images/logo-dark.svg';

const NavBarLogin = () => {
	useEffect(() => {
		document.title = 'Projectmix';
	}, []);

	return (
		<nav id="mainNav" className="navbar-expand navbar-light topbar m-0 static-top d-flex justify-content-between">
			<div className="nav-search">
				<img src={LogoBlack} alt="logo" className="img-fluid" width={180} />
			</div>
		</nav>
	);
};

export default NavBarLogin;
