import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './AcceptOrderModal.scss';

import { IoWarningOutline } from 'react-icons/io5';
import { PiWarningCircleFill, PiWarningFill } from 'react-icons/pi';
import { useEffect } from 'react';
import { FaCheckSquare } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im';

function AcceptOrderModal({ show, handleClose, handleConfirm }) {
	const confirmBtnRef = useRef(null);

	const [orderAgreements, setOrderAgreements] = useState([]);
	const [loadingAgreements, setLoadingAgreements] = useState(true);

	useEffect(() => {
		// setTimeout(() => {
		// 	confirmBtnRef?.current?.focus();
		// }, 1);

		api.getOrderAgreementsWithResponse(show.id).then((response) => {
			setOrderAgreements(response?.data);
			setLoadingAgreements(false);
		});
	}, []);

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className="accept-confirmation-dialog"
		>
			<Modal.Body>
				<Modal.Title>
					<span>
						<FaCheckSquare />
					</span>
					Approve Estimate
				</Modal.Title>

				<div>
					<p className="message">Please review below order agreements before approving this estimate</p>
					{
						<div className="order-agreements-detail-section">
							{loadingAgreements ? (
								<p className="p-0 m-0 order-agreements-detail-empty d-flex align-items-center justify-content-center" style={{gap: '5px'}}>
									<ImSpinner2 className="fa-spin" /> Please wait...
								</p>
							) : orderAgreements?.length > 0 ? (
								<>
									{orderAgreements?.map((ag) => (
										<div className="order-agreements-detail">
											<h6>{ag?.title}</h6>
											{ag?.responses?.map((res) => (
												<p>{res?.option?.option}</p>
											))}
										</div>
									))}
								</>
							) : (
								<p className="p-0 m-0 order-agreements-detail-empty">This estimate has no agreements</p>
							)}
						</div>
					}
				</div>

				<div className="modal-actions">
					<button
						ref={confirmBtnRef}
						className={`btn btn-primary btn-outlined-main btn-confirm`}
						onClick={() => {
							handleConfirm && handleConfirm(show.id);
						}}
						disabled={loadingAgreements}
					>
						Confirm
					</button>

					<button
						className="btn btn-primary btn-outlined-main"
						onClick={(e) => {
							handleClose && handleClose(e);
						}}
					>
						Cancel
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default AcceptOrderModal;
