import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPlus, FaSave, FaSpinner } from 'react-icons/fa';
import { CloseIcon, SettingsRepairIcon } from 'ui-component/custom-icons/icons'
import toast from 'react-hot-toast';

import StatusBadge from 'ui-component/StatusBadge';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { set } from 'draft-js/lib/EditorState';

const RepairModal = ({ openPopup, details, handleClose, handleReloadList }) => {

    const [information, setInformation] = useState(null);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isFormProcessing, setIsFormProcessing] = useState(false);

    useEffect(() => {
        setInformation({
            ...information,
            id: details?.id,
            name: details?.name,
        });
        setIsDataLoaded(true);
    }, []);
    


    const handleProcessSubmit = () => {
        setIsFormProcessing(true);
        // handleSubmit(information);
        if (information?.name == null || information?.name == '') {
            toast.error('Repair type name is required');
            setIsFormProcessing(false);
            return false;
        }
        if (information?.id == null || information?.id == '') {
            toast.promise(
                api
                    .settingsCreateRepairType(information)
                    .then((res) => {
                    if (res.status) {
                        handleReloadList()
                    }
    
                    return res?.status == true ? res : null;
                }),
    
                {
                    loading: 'Creating repair type...',
                    success: (data) => `${data.message}`,
                    error: <b>Creating repair type Failed</b>
                }
            );
        } else {
            toast.promise(
                api.settingsUpdateRepairType(information)
                    .then((res) => {
                        if (res.status) {
                            handleReloadList()
                        }

                        return res?.status == true ? res : null;
                    }),

                {
                    loading: 'Updating repair type...',
                    success: (data) => `${data.message}`,
                    error: <b>Updating repair type failed</b>
                }
            );
        }
    }

    const handleProcessEnter = (event) => {
        if (event.key === 'Enter') {
            handleProcessSubmit();
        }
    }
    
    return (
        <>
            <Modal show={openPopup}  centered size="sm">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center color-theme">
                                        <SettingsRepairIcon height={'60px'} width={'60px'} />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Repair Type
                                    </div>
                                    <div className='textGray'>
                                        Manage your repair types information
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    <label htmlFor="inputName" className="form-label">Repair Name</label>
                                </div>
                                <div className="col-12 form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                        value={information?.name}
                                        placeholder="Enter repair name"
                                        onChange={(e) => {
                                            setInformation({
                                                ...information,
                                                name: e.target.value
                                            })
                                        }}
                                        onKeyDown={(e) => {
                                            handleProcessEnter(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top mt10" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    <button
                                        type="button"
                                        className="btn-block btn-primary btn-outlined-main-primary  white-var"
                                        disabled={!isDataLoaded || isFormProcessing}
                                        onClick={() => {
                                            if (!isFormProcessing) {
                                                handleProcessSubmit()
                                            }
                                        }}
                                        style={{ lineHeight: '25px' }}
                                    >
                                        {
                                            isFormProcessing ? (
                                                <>
                                                    <FaSpinner className="fa-spin" /> Saving...
                                                </>
                                            ) : (
                                                <>
                                                    <FaSave /> Save
                                                </>
                                            )
                                        }
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RepairModal;
