import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { FaSave } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import FileUpload from 'ui-component/FileUpload';
import moment from 'moment';
import { useForm } from 'react-hook-form';

const AwbModal = ({ transitShipmentId, handleCloseAwbModal, onFormSubmit, redirectionData }) => {
	const dispatch = useDispatch();
	const history = useNavigate();
    
	const [disableStatus, setDisablestatus] = useState(false);
    const [trackingNo, setTrackingNo] = useState(null);

	const handleForm = () => {
        toast.promise(
            api
                .updateTransitShipmentAwb(transitShipmentId, {
                    awb: trackingNo,
                })
                .then((res) => { 
                    if (res.status) {
                        history(`/operations/transitshipments/${transitShipmentId}`);
                    }
                }),
            {
                loading: 'Updating Tracking...',
                success: <b>Tracking Detail updated!</b>,
                error: <b>Could not Update Tracking Detail.</b>
            }
        );
	};

	const handleOnSubmit = (data) => {
		onFormSubmit &&
			onFormSubmit({
				trackingNo: data?.trackingNo,
			});
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch
	} = useForm();

	useEffect(() => {
	}, [transitShipmentId]);

	return (
		<>
			<Modal show={transitShipmentId} onHide={handleCloseAwbModal} centered  dialogClassName="modal-sm">
				<Modal.Body>
				    <form onSubmit={handleSubmit(handleOnSubmit)}>
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="h6 text-white text-center pb-2">New Tracking</h4>
                                <div className="form-group mb-2">
                                    <label>
                                        Tracking No.
                                    </label>
                                    <input
                                        type="text"
                                        style={{ cursor: 'pointer' }}
                                        className="form-control"
                                        onKeyUp={(e) => (setTrackingNo(e.target?.value))}
                                        {...register('trackingNo', { required: true })}
                                    />
                                    {errors.trackingNo && <p className="mt-1 text-danger">Tracking Number Required</p>}
                                </div>
                                <div className="form-group">
                                    <div className="btnarea w-100">
                                        <button onClick={handleCloseAwbModal} className="btn btn-link">
                                            Cancel
                                        </button>

                                        <button disabled={disableStatus} className="btn btn-primary btn-outlined-main">
                                            <FaSave /> Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default AwbModal;
