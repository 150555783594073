import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { set } from 'react-hook-form';
import { saveAs } from 'file-saver';

const AWBModal = ({ show, handleClose, selectedOrder }) => {
	const [data, setData] = useState([]); // [1]
	const [isLoading, setIsLoading] = useState(false); // [2]
	useEffect(() => {
		if (show) {
			api.GetAvailableAWB(selectedOrder).then((res) => {
				setData(res.data);
				setIsLoading(false);
			});
		}
	}, [show]);

	console.log(data);

	const downloadAWB = (id) => {
		api.downloadAWB(id).then((res) => {
			var newBlob = new Blob([res.data], { type: 'application/pdf' });
			saveAs(newBlob, `Projectmix - AWB.pdf`);
		});
	};

	if (isLoading) {
		return <></>;
	}
	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Body>
				<div className="p-2">
					<div className="mb-2 ml-0 pl-0">
						<div className="row" style={{ marginBottom: 20 }}>
							<div className="col-12 d-flex align-items-center justify-content-between">
								<h4 style={{ marginBottom: 0, fontWeight: 600 }}>Available AWB</h4>
								<button className="btn modal-close-icon" onClick={handleClose} style={{ top: '-9px', right: 0 }}>
									<svg
										id="Component_127_1"
										height="16"
										width="16"
										data-name="Component 127 – 1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 19.295 19.289"
									>
										<path
											id="Icon_ionic-ios-close"
											data-name="Icon ionic-ios-close"
											d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
											transform="translate(-11.285 -11.289)"
										/>
									</svg>
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								{data.map((item, index) => (
									<a
										href={item.awb_attachment_url}
										target="_blank"
										rel="noopener noreferrer"
										style={{ color: 'inherit', textDecoration: 'none' }}
									>
										<div className="card card-awb" style={{ marginBottom: 5 }} key={index}>
											<div className="card-body">
												<div className="row">
													<div className="col-9">{item.awb_attachment_url.substring(item.awb_attachment_url.lastIndexOf('/') + 1)}</div>
													<div className="col-3" style={{ textAlign: 'right' }}>
														{item.created_at}
													</div>
												</div>
											</div>
										</div>
									</a>
								))}
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AWBModal;
