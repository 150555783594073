import React, { useEffect, useRef, useState } from 'react';
import './QuickActions.scss';
import { FaArrowRight, FaCaretRight, FaFileInvoice, FaFileInvoiceDollar, FaPlus, FaShippingFast, FaUser, FaUserTag } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { PiPackageBold } from 'react-icons/pi';
import ClientPortal from './user-portal/clientPortalModal';
import {
	ModelsIcon,
	QAContactIcon,
	QACreditNoteIcon,
	QAEstimateIcon,
	QAInvoiceIcon,
	QAModelIcon,
	QAPackageIcon,
	QAPurchaseInvoiceIcon,
	QAShipmentIcon,
	ReferlinkIcon,
} from 'ui-component/custom-icons/icons';

import { BsPersonFillAdd } from "react-icons/bs";

import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { useHotkeys } from 'react-hotkeys-hook';
import { TbReportMoney } from 'react-icons/tb';
import { HiOutlineDeviceMobile } from 'react-icons/hi';
import Openpopup from 'views/estimation/Openpopup';

function QuickActions() {
	const [newShow, setNewShow] = useState(false);

	const compRef = useRef(null);
	const buttonRef = useRef(null);
	const [showActions, setShowActions] = useState(false);
	const actionRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

	useHotkeys('c', () => {
		setShowActions(!showActions);
	});

	useHotkeys(['1', '2', '3', '4', '5', '6', '7', '8'], (e) => {
		if (showActions) {
			actionRefs?.[e?.key - 1]?.current?.click();
		}
	});
	const handleNewClose = () => {
		setNewShow(false);
	};


	const handleClickOutside = (event) => {
		if (compRef.current && !compRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
			setShowActions(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const toggleShowActions = () => {
		setShowActions(!showActions);
	};


	const onAddNewClient = (data) => {
		toast.promise(
			api
				.createShipments({
					transit_company_id: data?.ship_to,
					shipping_company_id: data?.ship_with,
					tracking_no: data?.tracking_number,
					package_ids: data?.packages
				})
				.then((res) => {
					setNewShow(false);

					if (res?.status) {
						handleFilter();
					}
					return res?.status ? res : null;
				}),
			{
				loading: 'Creating SShipment...',
				success: (data) => `${data.message}`,
				error: <b>Failed to create shipment</b>
			}
		);
	};
	const Openpopup = () => {
		setNewShow(true);
		setShowActions(false)
	}

	return (
		<div className="quick-actions-component">
			<button
				className="btn btn-main-primary btn-rounded d-none d-md-block"
				style={{ minWidth: '85px' }}
				onClick={toggleShowActions}
				ref={buttonRef}
			>
				<FaPlus /> Create
			</button>

			<span className="d-md-none" onClick={toggleShowActions}>
				<FaPlus />
			</span>

			{showActions ? (
				<div className={`quick-action-popover-container`}>
					<div className="quick-action-popover" ref={compRef}>
						<div className="quick-action-item-section">
							<div className="link-list">
								<PermissionAllowed permissions={['Create Estimation (Page)']}>
									<Link to={'/sales/estimates/create'} onClick={() => setShowActions(false)} className="link-list-item" ref={actionRefs[0]}>
										<span className="qa-icon" style={{ color: '#D8578A' }}>
											<QAEstimateIcon width="15" height="15" style={{ fill: '#D8578A' }} />
										</span>
										<p>Quotation</p>
									</Link>
								</PermissionAllowed>

								<PermissionAllowed permissions={['Create Estimation (Page)']}>
									<Link to={'/sales/invoices/create'} onClick={() => setShowActions(false)} className="link-list-item" ref={actionRefs[1]}>
										<span className="qa-icon" style={{ color: '#F39C12' }}>
											<QAInvoiceIcon width="15" height="15" style={{ fill: '#F39C12' }} />
										</span>
										<p>Invoice</p>
									</Link>
								</PermissionAllowed>

								<PermissionAllowed permissions={['Create Estimation (Page)']}>
									<Link
										to={'/sales/credit-note/create'}
										onClick={() => setShowActions(false)}
										className="link-list-item"
										ref={actionRefs[1]}
									>
										<span className="qa-icon" style={{ color: '#795AAA' }}>
											<QACreditNoteIcon width="15" height="15" style={{ fill: '#795AAA' }} />
										</span>
										<p>Credit Note</p>
									</Link>
								</PermissionAllowed>

								{/* <PermissionAllowed permissions={['Display Purchase List (Page)']}>
									<Link to={'/purchase-invoice'} onClick={() => setShowActions(false)} className="link-list-item" ref={actionRefs[2]}>
										<span className="qa-icon">
											<QAPurchaseInvoiceIcon width="15" height="15" />
										</span>
										<p>Purchase Invoice</p>
									</Link>
								</PermissionAllowed> */}

								{/* <Link to={'/settings/model/create'} onClick={() => setShowActions(false)} className="link-list-item" ref={actionRefs[3]}>
									<span className="qa-icon">
										<QAModelIcon width="15" height="15" />
									</span>
									<p>Model</p>
								</Link> */}
								<PermissionAllowed permissions={['Create Contact (Page)']}>
									<Link to={'/contacts/create'} onClick={() => setShowActions(false)} className="link-list-item" ref={actionRefs[4]}>
										<span className="qa-icon" style={{ color: '#555CF3' }}>
											<QAContactIcon width="15" height="15" style={{ fill: '#555CF3 !important' }} />
										</span>
										<p>Contact</p>
									</Link>
								</PermissionAllowed>
								{/* <Link
									to={'/operations/packing?create=packing'}
									onClick={() => setShowActions(false)}
									className="link-list-item"
									ref={actionRefs[5]}
								>
									<span className="qa-icon">
										<QAPackageIcon width="15" height="15" />
									</span>
									<p>Packing</p>
								</Link> */}
								{/* <Link
									to={'/shipments?create=shipment'}
									onClick={() => setShowActions(false)}
									className="link-list-item"
									ref={actionRefs[6]}
								>
									<span className="qa-icon">
										<QAShipmentIcon width="15" height="15" />
									</span>
									<p>Shipment</p>
								</Link> */}
								<a
									onClick={() => Openpopup()}
									className="link-list-item"
									style={{ cursor: 'pointer' }}
									ref={actionRefs[7]}
								>
									<span className="qa-icon">
										<BsPersonFillAdd style={{ fill: '#34C880' }} />
										{/* <ReferlinkIcon width="15" height="15" /> */}
									</span>
									<p>Client Portal</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
			{newShow && <ClientPortal newShow={newShow} handleNewClose={handleNewClose} onSubmit={onAddNewClient} />}
		</div>
	);
}

export default QuickActions;
