import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { FaSpinner, FaTimes, FaPlus, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { WarningIcon, CloseIcon, DeleteIcon2 } from 'ui-component/custom-icons/icons';
import NoImage from 'assets/images/no-image.png';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelect from 'react-select';
import Select from 'react-select';
import CurrencyValue from 'ui-component/CurrencyValue';
import { ConstructionOutlined } from '@mui/icons-material';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import usePermissions from 'hooks/usePermissions';
import StatusBadge from 'ui-component/StatusBadge';


const PicklistComponent = ({ details }) => {

    const [allPermissions, hasEveryPermission] = usePermissions();
	const history = useNavigate();
    
    const handleOpenNewTab = (pageModule, id) => {
        let url = '';
        if (pageModule == 'products') {
            url = `/products/${id}`;
        } else if (pageModule == 'contacts') {
            url = `/contacts/${id}`;
        } else if (pageModule == 'purchase_orders') {
            url = `/purchasing/view/${id}`;
        } else if (pageModule == 'sales_orders') {
            url = `/sales/sales-orders/${id}`;
        } else if (pageModule == 'picklists') {
            url = `/picklists/${id}`;
        }
        if (url) {
            history(url);
            // window.open(url, '_blank');
        }
    };
    return (
        <>
            
            <div style={{ padding: '0px 20px 20px 16px' }}>
			
                <div className="row mb10">
                    <div className="col-12">
                        <h2 className="d-flex font600" style={{ fontSize: '18px' }}>Picklists</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2 font600">Picklist #</div>
                    <div className="col-md-4 font600">Product</div>
                    <div className="col-md-2 font600">Qty</div>
                    <div className="col-md-2 font600">Status</div>
                    <div className="col-md-2 font600">Date</div>
                </div>
                <hr className="mt8 mb8" />
                {
                    details?.map((picklist, index) => (
                        <>
                            {
                                picklist?.items?.map((item, i) => (
                                    <>
                                        <div className="row">
                                            <div className="col-md-2 d-flex align-item-center">
                                                <div
                                                    className={`${hasEveryPermission(['View Picklists Details (Page)']) ? 'text-primary cursor-pointer' : '' }`}
                                                    onClick={() => {
                                                        if (hasEveryPermission(['View Picklists Details (Page)'])) {
                                                            handleOpenNewTab('picklists', picklist?.id)
                                                        }
                                                    }}
                                                >
                                                    {picklist?.picklist_no}
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex align-item-center">
                                                <div
                                                    className={`${hasEveryPermission(['View Product (Button)']) ? 'text-primary cursor-pointer' : '' }`}
                                                    onClick={() => {
                                                        if (hasEveryPermission(['View Product (Button)'])) {
                                                            handleOpenNewTab('products', item?.product?.id)
                                                        }
                                                    }}
                                                >
                                                    {item?.product?.name}
                                                </div>
                                            </div>
                                            <div
                                                className={`${item?.collected_quantity >= item?.quantity ? 'text-success' : ''} col-md-2 d-flex align-item-center`}>
                                                {item?.collected_quantity}/{item?.quantity}
                                            </div>
                                            <div className="col-md-2 d-flex align-item-center">
                                                <div style={{ width: '80px'}}>
                                                    <StatusBadge itemStatus={picklist?.status?.name} />
                                                </div>
                                            </div>
                                            <div className="col-md-2 d-flex align-item-center">
                                                {moment(item?.created_date).format(CommonDateOnlyFormat)}
                                            </div>
                                        </div>
                                        <hr className="mt8 mb8" />
                                    </>
                                ))
                            }
                        </>
                    ))
                }
                {/* {
                    details?.map((order, index) => (
                        <>
                            {
                                order?.items?.map((orderItem, i) => (
                                    <>
                                        <div className="row">
                                            <div className="col-md-2 ">
                                                <div
                                                    className={`${hasEveryPermission(['View Purchase Order']) ? 'text-primary cursor-pointer' : '' }`}
                                                    onClick={() => {
                                                        if (hasEveryPermission(['View Purchase Order'])) {
                                                            NewPageTab('purchase_orders', orderItem?.product?.id)
                                                        }
                                                    }}
                                                >
                                                    {order?.purchase_order_id}
                                                </div>
                                            </div>
                                            <div className="col-md-4 ">
                                                <div
                                                    className={`${hasEveryPermission(['View Product (Button)']) ? 'text-primary cursor-pointer' : '' }`}
                                                    onClick={() => {
                                                        if (hasEveryPermission(['View Product (Button)'])) {
                                                            viewData('products', orderItem?.product?.id)
                                                        }
                                                    }}
                                                >
                                                    {orderItem?.product?.name}
                                                </div>
                                                <div
                                                    style={{ fontSize: '11px' }}
                                                    className={`${hasEveryPermission(['View Contact (Button)']) ? 'textGray cursor-pointer' : 'textGray' }`}
                                                    onClick={() => {
                                                        if (hasEveryPermission(['View Contact (Button)'])) {
                                                            viewData('contacts', orderItem?.supplier?.id)
                                                        }
                                                    }}
                                                
                                                >
                                                    {orderItem?.supplier?.name}
                                                </div>
                                            </div>
                                            <div className="col-md-1">{orderItem?.item_qty}</div>
                                            <div className="col-md-2 "><CurrencyValue currencyObject={orderItem?.unit_price} /></div>
                                            <div className="col-md-3 ">
                                                <div className="row">
                                                    <div className="col-12 d-flex" style={{ gap: '25px' }}>
                                                        <div className="textGray">Total</div>
                                                        <div><CurrencyValue currencyObject={orderItem?.gross_total} /></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 d-flex" style={{ gap: '20px' }}>
                                                        <div className="textGray">Gross</div>
                                                        <div><CurrencyValue currencyObject={orderItem?.gross_profit} /></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 d-flex" style={{ gap: '6px' }}>
                                                        <div className="textGray">Gross %</div>
                                                        <div>(${ orderItem?.gross_profit_percentage }%)</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mt8 mb8" />
                                    </>
                                ))
                            }
                        </>
                    ))
                } */}
            </div>
            
        </>
    );
};

export default PicklistComponent;
