import React from 'react';
import './estimateInvoice.scss';
import mgLogo from 'assets/images/mglogo.svg';
import cmLogo from 'assets/images/cmlogo.svg';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import { Skeleton } from '@mui/material';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Link } from 'react-router-dom';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

function PurchaseInvoiceDetailInvoice({ purchaseInvoice }) {
	return (
		<div className="purchase-inv-container">
			<div className="row mx-0" style={{ alignItems: 'center' }}>
				<div className="col-10 p-0">
					{purchaseInvoice ? <h3 className="est-ref m-0">{purchaseInvoice?.invoice_number}</h3> : <Skeleton variant="rounded" />}
					{purchaseInvoice ? <p className="m-0">{purchaseInvoice?.supplier?.name}</p> : <Skeleton variant="rounded" />}
				</div>
				<div className="col-2 p-0">
					<table className="w-100">
						<tr>
							<td>Date:</td>
							<td className="text-right">{moment(purchaseInvoice?.date).format(CommonDateOnlyFormat)}</td>
						</tr>
					</table>
				</div>
			</div>

			<div className="est-inv-items" style={{ marginTop: '50px' }}>
				<table className="w-100">
					<thead>
						<tr>
							<th>Model</th>
							<th>Comment</th>
							<th className="text-center">Invoice</th>
							<th className="text-right">Unit Price</th>
							<th className="text-right">Total</th>
						</tr>
					</thead>
					<tbody>
						{purchaseInvoice?.purchase_invoice_items?.map((item) => (
							<tr>
								<td>{item?.product?.name}</td>
								<td>
									<Link to={`/sales/invoices/${item?.invoice?.id}`}>{item?.invoice?.invoice_number}</Link>
								</td>
								<td className="text-center">{item?.quantity}</td>
								<td className="text-right">
									<CurrencyValue currencyObject={item?.price} />
								</td>
								<td className="text-right">
									<CurrencyValue
										currencyObject={Object.fromEntries(
											Object.entries(item?.price).map(([currency, amount]) => [
												currency,
												parseFloat(amount) * item?.quantity
											])
										)}
									/>
								</td>
							</tr>
						))}

						<tr>
							<td colSpan={3}></td>
							<td className="text-right">Transfer Fees</td>
							<td className="text-right font-weight-bold">
								<CurrencyValue currencyObject={purchaseInvoice?.converted_currency_additional_charges} />
							</td>
						</tr>

						<tr>
							<td colSpan={3}></td>
							<td className="text-right">Subtotal</td>
							<td className="text-right font-weight-bold">
								<CurrencyValue currencyObject={purchaseInvoice?.converted_currency_subtotal} />
							</td>
						</tr>
						<tr>
							<td colSpan={3}></td>
							<td className="text-right">Total</td>
							<td className="text-right font-weight-bold">
								<CurrencyValue currencyObject={purchaseInvoice?.converted_currency_total_invoiced} />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default PurchaseInvoiceDetailInvoice;
