import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui-component/Pagination';
import StatusBadge from 'ui-component/StatusBadge';
import Scroller from 'ui-component/Scroller';
// import NewShipments from './AddShipments';
import moment from 'moment';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { Skeleton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
//import WarrantyCheckerModal from './components/modals/WarrantyCheckerModal';
//import ReturnsReportPopupModal from './components/modals/ReturnsReportPopupModal';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePageTitle from 'hooks/usePageTitle';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import SearchBar from '../layout/SearchBar';
import { setSearchBar } from 'store/reducers/mainSlice';

const Index = () => {
	const inputRef = useRef(null);

	const [setPageTitle] = usePageTitle('Returns');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const history = useNavigate();
	const [openWarrantyCheckerPopup, setOpenWarrantyCheckerPopup] = useState(false);
	const [openReportPopup, setOpenReportPopup] = useState(false);
	const [returnsList, setReturnsList] = useState([]);
	const [show, setShow] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [pagination, setpagination] = useState([]);
	const [newShow, setNewShow] = useState(false);
	const dispatch = useDispatch();
	const [autoSuggest, setAutoSuggest] = useState([]);
	const searchBar = useSelector((state) => state.main.searchBar);

	const clientUser = useSelector((state) => state.main.client);
	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

	const [showButtons, setShowButtons] = useState([]);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (searchBar && inputRef.current) {
			inputRef.current.focus();
		}
	}, [searchBar]);

	const handleNewClose = () => setNewShow(false);

	const getReturnsList = () => {
		setIsLoading(true);
		api.getClientReturns(clientUser?.data?.contact_id).then((res) => {
			setIsLoading(false);
			setReturnsList(res.data.data);
			setpagination(res.data);
			setAutoSuggest(res?.data?.data.map((item) => item.reference_no).slice(0, 10));

			isFirstLoad && setIsFirstLoad(false);
		});
	};

	const toggleButtons = (id) => {
		const newShowButtons = [...showButtons];
		const index = newShowButtons.indexOf(id);
		if (index !== -1) {
			newShowButtons.splice(index, 1);
		} else {
			newShowButtons.push(id);
		}
		setShowButtons(newShowButtons);
	};
	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsLoading(true);
			if (text.length > 2) {
				api.getClientReturns(clientUser?.data?.contact_id, undefined, text).then((res) => {
					setIsLoading(false);
					setReturnsList(res.data.data);
					setpagination(res.data);
					setAutoSuggest(res?.data?.data.map((item) => item.reference_no).slice(0, 10));
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.getClientReturns(clientUser?.data?.contact_id).then((res) => {
					setIsLoading(false);
					setReturnsList(res.data.data);
					setpagination(res.data);
					setAutoSuggest(res?.data?.data.map((item) => item.reference_no).slice(0, 10));
				});
			}
		}, 1000);

		setSearchTimeout(timeout);
	};

	const viewData = (id) => {
		console.log(id);
		history(`${id}`);
	};

	// useEffect(() => {
	// 	!isFirstLoad && handleFilter();
	// }, [activeFilters]);

	// const handleFilter = (activeF = null, searchF = null) => {
	// 	setIsLoading(true);
	// 	api.filterWarehouse(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
	// 		setIsLoading(false);
	// 		setReturnsList(res.data.data);
	// 		setpagination(res.data);
	// 	});
	// };

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const handleOpenWarrantyCheckerModal = () => {
		setOpenWarrantyCheckerPopup(true);
	};
	const handleOpenReportPopupModal = () => {
		setOpenReportPopup(true);
	};

	const closePopup = () => {
		setOpenWarrantyCheckerPopup(false);
		setOpenReportPopup(false);
	};

	const handleClose = () => setShow('');

	const handleData = (data) => {
		setReturnsList(data.data);
		setpagination(data);
	};

	useEffect(() => {
		getReturnsList();
	}, []);

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		backgroundColor: '#f2f2f2',
		zIndex: 1
	};

	const handleDelete = (id) => {
		toast.promise(
			api.deleteReturns(id).then((res) => {
				if (res.status) {
					setShow('');
					api.getReturnsList().then((res) => {
						setIsLoading(false);
						setReturnsList(res.data.data);
						setpagination(res.data);
						isFirstLoad && setIsFirstLoad(false);
					});
				}
				return res?.status ? res : abc;
			}),
			{
				loading: 'Deleting Warehouse...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete warehouse</b>
			}
		);
	};

	const deleteReturnsData = (data) => {
		setShow(data);
	};

	return (
		<>
			{isMobile && (
				<div className="approved-mobile" style={{ position: 'sticky', top: 0, zIndex: 10 }}>
					<div className="verified-mobile">
						<svg xmlns="http://www.w3.org/2000/svg" width="21.996" height="21.995" viewBox="0 0 21.996 21.995">
							<path
								id="restock_1_"
								data-name="restock (1)"
								d="M22,11A10.994,10.994,0,0,1,1.837,17.074l0,1.256A.917.917,0,0,1,0,18.33V15.588a1.843,1.843,0,0,1,1.84-1.841H4.583a.916.916,0,1,1,0,1.833h-1.5A9.152,9.152,0,0,0,20.163,11,.917.917,0,0,1,22,11ZM21.08,2.749a.916.916,0,0,0-.917.916l0,1.242A11,11,0,0,0,0,11a.917.917,0,0,0,1.833,0A9.168,9.168,0,0,1,18.94,6.415H17.414a.916.916,0,1,0,0,1.833h2.75A1.832,1.832,0,0,0,22,6.415V3.666A.916.916,0,0,0,21.08,2.749ZM9.165,15.58a2.753,2.753,0,0,1-2.75-2.749V9.165a2.753,2.753,0,0,1,2.75-2.749h3.666a2.753,2.753,0,0,1,2.75,2.749v3.666a2.753,2.753,0,0,1-2.75,2.749Zm0-1.833h3.666a.917.917,0,0,0,.917-.916V9.165a.917.917,0,0,0-.917-.916H9.165a.917.917,0,0,0-.917.916v3.666A.917.917,0,0,0,9.165,13.747ZM10.54,11h.917a.916.916,0,1,0,0-1.833H10.54a.916.916,0,1,0,0,1.833Z"
								fill="currentColor"
							/>
						</svg>
						<span style={{ marginLeft: 5, fontSize: 16 }}>Returns</span>
					</div>
				</div>
			)}
			<div className="main-page-client">
				{!isLoading ? (
					<div className="order-client-content">
						<div className={`d-flex align-items-center ${isMobile ? 'justify-content-end' : 'justify-content-between'} TableSerachBar`}>
							{' '}
							<div className="heading-order-client-tbl" style={{ display: 'flex', alignItems: 'center' }}>
								{!isMobile && <h3 style={{ marginBottom: 0, marginRight: 10 }}>Returns</h3>}
								<div>
									<Link to={'/client/returns/create'} className="btn btn-primary btn-outlined-main white-var">
										<FaPlus /> New Returns
									</Link>
								</div>
							</div>
							{!isMobile && (
								<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
									<div className="input-group">
										<input
											ref={inputRef}
											type="text"
											className="form-control bg-white border"
											placeholder="Search"
											aria-label="Search"
											aria-describedby="basic-addon2"
											onChange={handleSearch}
											style={{ width: '100%' }}
											onFocus={() => {
												dispatch(setSearchBar(true));
											}}
											onBlur={() => {
												dispatch(setSearchBar(!searchBar));
											}}
										/>
										<span className="searchIcon" style={{ marginTop: '-37px' }}>
											<FaSearch />
										</span>
									</div>
								</form>
							)}
						</div>
						{!isMobile ? (
							<div className="table-responsive-xl tableData customersList p-2">
								<Scroller style={{ marginBottom: 0 }}>
									<table className="order-client-tb min-width-unset pm-table d-sm-table d-md-table">
										<thead>
											<tr className="sticky-header">
												<th scope="col">Reference</th>
												<th scope="col text-left" style={{ textAlign: 'left' }}>
													Products
												</th>

												<th scope="col text-left" style={{ textAlign: 'left' }}>
													Date
												</th>
												<th scope="col text-left" style={{ textAlign: 'left' }}>
													Status
												</th>
											</tr>
										</thead>
										<tbody>
											{isLoading ? (
												<>
													{[...Array(5)].map((e, i) => (
														<tr>
															{[...Array(6)].map((e, i) => (
																<td>
																	<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																</td>
															))}
														</tr>
													))}
												</>
											) : (
												<>
													{returnsList?.map((row, index) => {
														return (
															<>
																<tr key={index} onClick={() => viewData(row.id)}>
																	<td
																		className="font14"
																		style={{ textAlign: 'left' }}
																		// onClick={() => {
																		// 	if (hasEveryPermission(['View Returns (Button)'])) {
																		// 		viewData(row.id);
																		// 	}
																		// }}
																		// style={{
																		// 	cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																		// }}
																	>
																		<Link to={''} className="ml-1">
																			{row?.reference_no}
																		</Link>
																	</td>
																	<td
																		className=" font14"
																		style={{ textAlign: 'left' }}
																		// onClick={() => {
																		// 	if (hasEveryPermission(['View Returns (Button)'])) {
																		// 		viewData(row.id);
																		// 	}
																		// }}
																		// style={{
																		// 	cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																		// }}
																	>
																		{row?.total_returns}
																	</td>

																	<td
																		className=" font14"
																		style={{ textAlign: 'left' }}
																		// onClick={() => {
																		// 	if (hasEveryPermission(['View Returns (Button)'])) {
																		// 		viewData(row.id);
																		// 	}
																		// }}
																		// style={{
																		// 	cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																		// }}
																	>
																		{moment(row?.created_at).format(CommonDateOnlyFormat)}
																	</td>
																	<td
																		className=" font14"
																		style={{ textAlign: 'left' }}
																		// onClick={() => {
																		// 	if (hasEveryPermission(['View Returns (Button)'])) {
																		// 		viewData(row.id);
																		// 	}
																		// }}
																		// style={{
																		// 	cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																		// }}
																	>
																		<div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
																			<div style={{ width: '60%' }}>
																				<StatusBadge itemStatus={row?.is_approved ? 'Approved' : 'Pending'} />
																			</div>
																		</div>
																	</td>
																</tr>
															</>
														);
													})}
													{returnsList?.length < 1 && (
														<tr className="no-hover">
															<td colSpan={7} className="whitebg">
																<div className="not-found-container ">
																	<div className="not-found-icon">
																		<svg xmlns="http://www.w3.org/2000/svg" width="77.513" height="77.513" viewBox="0 0 77.513 77.513">
																			<g id="Group_40058" data-name="Group 40058" transform="translate(-1056.04 -582)">
																				<path
																					id="Union_56"
																					data-name="Union 56"
																					d="M1464.57,11241.394l-7.437-2.015c-3.039-.82-5.078-3.146-5.078-5.789l.008-9.06a4.216,4.216,0,0,1-2.768-2.223,3.2,3.2,0,0,1,.37-3.3l2.1-3.329a4.27,4.27,0,0,1,2.426-1.582l10.526-2.706a12.559,12.559,0,0,1,6.246,0l10.526,2.7a4.525,4.525,0,0,1,2.311,1.921l2.243,3.024a3.166,3.166,0,0,1,.378,3.273,4.176,4.176,0,0,1-2.779,2.206v9.073c0,2.638-2.045,4.96-5.084,5.781l-7.443,2.017a12.728,12.728,0,0,1-6.551,0Zm4.531-1.893a9.192,9.192,0,0,0,1.275-.251l7.443-2.015a4.046,4.046,0,0,0,3.3-3.64v-8.388l-5.665,1.532a5.351,5.351,0,0,1-1.4.186,4.675,4.675,0,0,1-3.786-1.766l-.011-.021-1.145-1.712Zm-14.519-5.908a4.04,4.04,0,0,0,3.293,3.643l7.44,2.015a9.311,9.311,0,0,0,1.253.248l.008-16.062-1.137,1.7a4.689,4.689,0,0,1-3.8,1.794,5.429,5.429,0,0,1-1.4-.187l-5.646-1.528Zm15.184-13.582,2.638,3.947a2.079,2.079,0,0,0,1.653.728,2.528,2.528,0,0,0,.652-.087l8.19-2.215a1.764,1.764,0,0,0,1.179-.911h0a1.14,1.14,0,0,0-.144-1.19l-2.254-3.042c-.076-.1-.178-.239-.291-.381Zm-15.847-3.146-2.1,3.332a1.2,1.2,0,0,0-.2,1.216l.022.051a1.821,1.821,0,0,0,1.216.937l8.128,2.2a2.485,2.485,0,0,0,.646.087,2.093,2.093,0,0,0,1.67-.75l2.627-3.931-11.926-3.238C1453.973,11216.8,1453.948,11216.831,1453.92,11216.865Zm11.514-3.318-7.527,1.933,9.954,2.7,9.951-2.7-7.55-1.938a9.8,9.8,0,0,0-4.827,0Z"
																					transform="translate(-373.064 -10604.654)"
																				/>
																				<path
																					id="restock_1_"
																					data-name="restock (1)"
																					d="M77.477,40.439A38.761,38.761,0,0,1,3.32,54.466a2.864,2.864,0,0,0-.09.439v11.3a1.615,1.615,0,1,1-3.23,0v-11.3a6.466,6.466,0,0,1,6.459-6.459h11.3a1.615,1.615,0,0,1,0,3.23H6.459a3.167,3.167,0,0,0-.72.145A35.513,35.513,0,0,0,74.248,40.3a1.531,1.531,0,0,1,1.683-1.547A1.623,1.623,0,0,1,77.477,40.439ZM75.9,9.689A1.615,1.615,0,0,0,74.283,11.3v11.3a3.111,3.111,0,0,1-.087.433A38.765,38.765,0,0,0,.036,37.074a1.614,1.614,0,0,0,1.544,1.683H1.65A1.614,1.614,0,0,0,3.262,37.21,35.515,35.515,0,0,1,71.774,25.692a3.167,3.167,0,0,1-.72.145H59.75a1.615,1.615,0,0,0,0,3.23h11.3a6.466,6.466,0,0,0,6.459-6.459V11.3A1.615,1.615,0,0,0,75.9,9.689Z"
																					transform="translate(1056.04 582)"
																				/>
																			</g>
																		</svg>
																	</div>
																	<h6 className="not-found-text" style={{ fontStyle: 'italic', color: '#000', fontSize: '18px' }}>
																		Add your first return and enjoy the convenience!
																	</h6>
																</div>
															</td>
														</tr>
													)}
												</>
											)}
										</tbody>
									</table>
									<div
										style={{
											position: 'sticky',
											bottom: '-1px',
											width: '100%',
											backgroundColor: '#fff',
											textAlign: isMobile ? 'center' : 'right',
											marginTop: 10
										}}
									>
										<Pagination pagination={pagination} data={handleData} />
									</div>
								</Scroller>
							</div>
						) : (
							<>
								{returnsList?.length > 0 ? (
									<>
										<div className="order-list-client">
											<div className="row">
												<div className="col-5" style={{ padding: 10, paddingLeft: 20 }}>
													<span style={{ fontWeight: 600 }}>Reference</span>
												</div>
												<div className="col-5" style={{ padding: 10, display: 'flex', justifyContent: 'start', paddingRight: 20 }}>
													<span style={{ fontWeight: 600 }}>Status</span>
												</div>
											</div>
										</div>
										<div className="order-container">
											{returnsList.map((item) => (
												<div
													className={`row order-item-client ${showButtons.includes(item.id) ? 'active' : ''}`}
													key={item.id}
													onClick={() => viewData(item.id)}
												>
													<div
														className="col-5"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingLeft: 20,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
													>
														<a>{item.reference_no}</a>
													</div>
													<div
														className="col-5"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
													>
														<div style={{ width: 100 }}>
															<StatusBadge itemStatus={item?.is_approved ? 'Approved' : 'Pending'} />
														</div>
													</div>
													<div
														className="col-2"
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21.985 21.985">
															<path
																id="search_1_"
																data-name="search (1)"
																d="M10.649,15.458a.859.859,0,1,1,0,1.718h-1.2a.859.859,0,1,1,0-1.718Zm7.042-4.594A.859.859,0,0,0,16.832,10H9.447a.859.859,0,1,0,0,1.718h7.386A.859.859,0,0,0,17.691,10.864ZM9.447,6.57h7.386a.859.859,0,1,0,0-1.718H9.447a.859.859,0,1,0,0,1.718ZM6.011,15.458H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.453H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.153H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,0,0,0-1.718ZM21.733,20.519a.859.859,0,0,1-1.215,1.215l-1.453-1.453A3.869,3.869,0,1,1,20.31,19.1ZM17.09,19.108a2.147,2.147,0,1,0-2.147-2.147A2.149,2.149,0,0,0,17.09,19.108ZM18.55,0H3.435A3.439,3.439,0,0,0,0,3.435V18.55a3.439,3.439,0,0,0,3.435,3.435h8.845a.859.859,0,1,0,0-1.718H3.435A1.72,1.72,0,0,1,1.718,18.55V3.435A1.72,1.72,0,0,1,3.435,1.718H18.55a1.72,1.72,0,0,1,1.718,1.718v8.5a.859.859,0,0,0,1.718,0v-8.5A3.439,3.439,0,0,0,18.55,0Zm0,0"
															/>
														</svg>
													</div>
												</div>
											))}
										</div>
										<div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
											<Pagination pagination={pagination} data={handleData} />
										</div>
									</>
								) : (
									<>
										<div style={{ width: '100%' }}>
											<div className="no-hover" style={{ width: '100%' }}>
												<div className="whitebg">
													<div className="not-found-container ">
														<svg xmlns="http://www.w3.org/2000/svg" width="77.513" height="77.513" viewBox="0 0 77.513 77.513">
															<g id="Group_40058" data-name="Group 40058" transform="translate(-1056.04 -582)">
																<path
																	id="Union_56"
																	data-name="Union 56"
																	d="M1464.57,11241.394l-7.437-2.015c-3.039-.82-5.078-3.146-5.078-5.789l.008-9.06a4.216,4.216,0,0,1-2.768-2.223,3.2,3.2,0,0,1,.37-3.3l2.1-3.329a4.27,4.27,0,0,1,2.426-1.582l10.526-2.706a12.559,12.559,0,0,1,6.246,0l10.526,2.7a4.525,4.525,0,0,1,2.311,1.921l2.243,3.024a3.166,3.166,0,0,1,.378,3.273,4.176,4.176,0,0,1-2.779,2.206v9.073c0,2.638-2.045,4.96-5.084,5.781l-7.443,2.017a12.728,12.728,0,0,1-6.551,0Zm4.531-1.893a9.192,9.192,0,0,0,1.275-.251l7.443-2.015a4.046,4.046,0,0,0,3.3-3.64v-8.388l-5.665,1.532a5.351,5.351,0,0,1-1.4.186,4.675,4.675,0,0,1-3.786-1.766l-.011-.021-1.145-1.712Zm-14.519-5.908a4.04,4.04,0,0,0,3.293,3.643l7.44,2.015a9.311,9.311,0,0,0,1.253.248l.008-16.062-1.137,1.7a4.689,4.689,0,0,1-3.8,1.794,5.429,5.429,0,0,1-1.4-.187l-5.646-1.528Zm15.184-13.582,2.638,3.947a2.079,2.079,0,0,0,1.653.728,2.528,2.528,0,0,0,.652-.087l8.19-2.215a1.764,1.764,0,0,0,1.179-.911h0a1.14,1.14,0,0,0-.144-1.19l-2.254-3.042c-.076-.1-.178-.239-.291-.381Zm-15.847-3.146-2.1,3.332a1.2,1.2,0,0,0-.2,1.216l.022.051a1.821,1.821,0,0,0,1.216.937l8.128,2.2a2.485,2.485,0,0,0,.646.087,2.093,2.093,0,0,0,1.67-.75l2.627-3.931-11.926-3.238C1453.973,11216.8,1453.948,11216.831,1453.92,11216.865Zm11.514-3.318-7.527,1.933,9.954,2.7,9.951-2.7-7.55-1.938a9.8,9.8,0,0,0-4.827,0Z"
																	transform="translate(-373.064 -10604.654)"
																/>
																<path
																	id="restock_1_"
																	data-name="restock (1)"
																	d="M77.477,40.439A38.761,38.761,0,0,1,3.32,54.466a2.864,2.864,0,0,0-.09.439v11.3a1.615,1.615,0,1,1-3.23,0v-11.3a6.466,6.466,0,0,1,6.459-6.459h11.3a1.615,1.615,0,0,1,0,3.23H6.459a3.167,3.167,0,0,0-.72.145A35.513,35.513,0,0,0,74.248,40.3a1.531,1.531,0,0,1,1.683-1.547A1.623,1.623,0,0,1,77.477,40.439ZM75.9,9.689A1.615,1.615,0,0,0,74.283,11.3v11.3a3.111,3.111,0,0,1-.087.433A38.765,38.765,0,0,0,.036,37.074a1.614,1.614,0,0,0,1.544,1.683H1.65A1.614,1.614,0,0,0,3.262,37.21,35.515,35.515,0,0,1,71.774,25.692a3.167,3.167,0,0,1-.72.145H59.75a1.615,1.615,0,0,0,0,3.23h11.3a6.466,6.466,0,0,0,6.459-6.459V11.3A1.615,1.615,0,0,0,75.9,9.689Z"
																	transform="translate(1056.04 582)"
																/>
															</g>
														</svg>

														<h6
															className="not-found-text"
															style={{ fontStyle: 'italic', color: '#000', fontSize: '18px', marginTop: 10, paddingBottom: 5 }}
														>
															Add your first return and enjoy the convenience!
														</h6>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</>
						)}
						{/* <Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} /> */}
					</div>
				) : (
					<></>
				)}
				<SearchBar searchField={searchField} handleSearch={handleSearch} autoSuggest={autoSuggest} />
			</div>

			{/* {show && (
				<DeleteConfirmation
					show={show}
					handleClose={handleClose}
					handleDelete={handleDelete}
					title="Confirm Delete"
					message={`Are you sure you want to delete ${show?.name}`}
				/>
			)}

            {openWarrantyCheckerPopup && (
				<>
					<WarrantyCheckerModal
						openPopup={openWarrantyCheckerPopup}
						// returnsDetail={returnsDetail}
						// handleReplacementImeis={handleReplacementImeis}
						handleClose={closePopup}
						// handleSave={handleSave}
						disableStatus={isDisable}
					/>
				</>
            )}
            {openReportPopup && (
				<>
					<ReturnsReportPopupModal
						openPopup={openReportPopup}
						// returnsDetail={returnsDetail}
						// handleReplacementImeis={handleReplacementImeis}
						handleClose={closePopup}
						// handleSave={handleSave}
						disableStatus={isDisable}
					/>
				</>
            )} */}
		</>
	);
};

export default Index;
