import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { FaSpinner, FaTimes, FaPlus, FaChevronRight, FaSearch } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { WarningIcon, CloseIcon, DeleteIcon2, ColoredScanIcon } from 'ui-component/custom-icons/icons';
import NoImage from 'assets/images/no-image.png';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelect from 'react-select';
import StatusBadge from 'ui-component/StatusBadge';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import errorSound from '../../../assets/audio/error.mp3';
import useSound from 'use-sound';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import usePermissions from 'hooks/usePermissions';


const PicklistSerialScanModal = ({ openPopup, details, handleClose, selectedProduct, handleReloadData, handleAddScannedSerials }) => {

    const [enteredSerial, setEnteredSerial] = useState('');
    const [scannedSerials, setScannedSerials] = useState([]);
    const [scannedSerialsList, setScannedSerialsList] = useState([]);
    const [errorSoundPlay] = useSound(errorSound);
    const [isScanningDisabled, setIsScanningDisabled] = useState(false);

    // const [serialDetails, setSerialDetails] = useState({
    //     picklist_item_id: selectedProduct?.id,
    //     imei: '',
    //     product_id: '',
    //     product_name: '',
    //     color_id: '',
    //     color_name: '',
    //     is_exists: false,
    //     status: false,
    //     status_name: '',
    // })

            
    // const currencies = [
    //     { label: 'EUR', value: 'eur' },
    //     { label: 'USD', value: 'usd' },
    //     { label: 'AED', value: 'aed' }
    // ];

    // const paymentMethods = [
    //     { label: 'In Credit', value: 'in_credit' },
    //     { label: 'Upfront Payment', value: 'upfront_payment' }
    // ];

    useEffect(() => {

		// api.getSelectOptionsList('product_stocks').then((res) => {
		// 	// option_product_name: "11P256MIXAA+ - iPhone 11 Pro 256GB Mixed AA+ - 0 stock"
		// 	const data = res.data.map((item) => ({
		// 		...item,
		// 		label: `${item.product_name}`,
		// 		value: item.id,
		// 	}));
		// 	setModels(data);
        // });
		// api.getSelectOptionsList('color').then((res) => {
		// 	// option_product_name: "11P256MIXAA+ - iPhone 11 Pro 256GB Mixed AA+ - 0 stock"
		// 	const data = res.data.map((item) => ({
		// 		...item,
		// 		label: `${item.name}`,
		// 		value: item.id,
		// 	}));
		// 	setColors(data);
        // });
        
        populateSerialsList()
    }, []);

    const disablepopup = () => {
        handleClose();
    };

    const populateSerialsList = () => {
        console.log('selectedProduct', selectedProduct)
        const serialsCollection = selectedProduct?.serials?.map((serial) => { 
            const collection = {
                id: serial?.id,
                imei: serial?.imei,
                product: serial?.product,
                color: serial?.color,
                isExists: serial?.is_exists,
                status: serial?.status,
                statusName: serial?.status_name,
            }
            setScannedSerials(prevSerials => [
                ...prevSerials,
                collection
            ]);
            setScannedSerialsList(prevSerials => [
                ...prevSerials,
                collection
            ]);
        });
        selectedProduct?.picklist_item_unused_serials?.map((serial) => { 
            const collection = {
                id: serial?.id,
                imei: serial?.imei,
                product: serial?.product,
                color: serial?.color,
                isExists: serial?.is_exists,
                status: serial?.status,
                statusName: serial?.status_name,
            }
            setScannedSerials(prevSerials => [
                ...prevSerials,
                collection
            ]);
            setScannedSerialsList(prevSerials => [
                ...prevSerials,
                collection
            ]);
        });
    }



    const handleSearch = (value) => {
        if (value.length >= 3) {

            const filtered = scannedSerials.filter(item => 
                (item.imei && item.imei.includes(value)) || 
                (item.productName && item.productName.toLowerCase().includes(value.toLowerCase()))
            );
            setScannedSerialsList(filtered);
        } else {
            setScannedSerialsList(scannedSerials);
        }
    }

	const handleSerialInputKeydown = (event) => {
		if (event.key === 'Enter') {
			handleEnterSerial();
		}
    };
        
    const handleEnterSerial = async () => {
        if (!enteredSerial || !enteredSerial?.trim()) {
            toast.error('Serial Number is required');
            return;
        }
    
        const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;
    
        if (!alphanumericRegex.test(enteredSerial)) {
            toast.error('Please enter a valid Serial Number');
            return;
        }
    
        const serialExists = scannedSerials?.some(product => product.imei === enteredSerial);
    
        if (serialExists) {
            toast.error('Serial Number has been scanned already');
            errorSoundPlay();
            return false;
        }
    
        const data = {
            picklist_item_id: selectedProduct?.id,
            imei: enteredSerial,
        };
        setIsScanningDisabled(true);

		api.addSerialToPicklist(details?.id, data).then((res) => {
			if (res.status) {
                if (res?.data?.status) {
                    const collection = {
                        id: res?.data?.id,
                        imei: res?.data?.imei,
                        product: res?.data?.product,
                        color: res?.data?.color,
                        isExists: res?.data?.is_exists,
                        status: res?.data?.status,
                        statusName: res?.data?.status_name,
                    };
                    setEnteredSerial('');
                    setScannedSerials(prevSerials => [
                        ...prevSerials,
                        collection
                    ]);
                    setScannedSerialsList(prevSerials => [
                        ...prevSerials,
                        collection
                    ]);
                    handleAddScannedSerials(selectedProduct?.id, collection)

                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
                setIsScanningDisabled(false);
            } else {
                toast.error('Failed to process serial');
                setIsScanningDisabled(false);
			}
        });
        
        // toast.promise(
        //     api
        //         .addSerialToPicklist(details?.id, data)
        //         .then(
        //             (res) => {
        //                 if (res.status) {
        //                     if (res?.data?.status) {
        //                         const collection = {
        //                             id: res?.data?.id,
        //                             imei: res?.data?.imei,
        //                             product: res?.data?.product,
        //                             color: res?.data?.color,
        //                             isExists: res?.data?.is_exists,
        //                             status: res?.data?.status,
        //                             statusName: res?.data?.status_name,
        //                         };
        
        //                         // Add the new collection to the scannedSerials array
        //                         setScannedSerials(prevSerials => [
        //                             ...prevSerials,
        //                             collection
        //                         ]);
        //                         setScannedSerialsList(prevSerials => [
        //                             ...prevSerials,
        //                             collection
        //                         ]);
    
        
        //                         setEnteredSerial('');
        //                         handleAddScannedSerials(selectedProduct?.id, collection)
        //                     }
        //                 }
    
        //                 return res?.status ? res : null;
        //             },
        //             () => { }
        //         ),
        //     {
        //         loading: 'Adding serial...',
        //         success: (data) => `${data.message}`,
        //         error: <b>Failed to add repair</b>
        //     }
        // );
    };
    
    const validateSubmitSerial = () => {
        
        handleEnterSerial()
    }
    const handleRemoveSerial = (item, index) => {
        if (item?.id) {

    
            toast.promise(
                api
                    .removeSerialToPicklist(item?.id)
                    .then(
                        (res) => {
        
                            return res?.status ? res : null;
                        },
                        () => { }
                    ),
                {
                    loading: 'Removing serial...',
                    success: (data) => `${data.message}`,
                    error: <b>Failed to remove serial</b>
                }
            );
        }
        
        const updatedSerials = scannedSerials.filter((_, i) => i !== index);
        setScannedSerials(updatedSerials);
        setScannedSerialsList(updatedSerials);

    }

    return (
        <>
            <Modal show={openPopup} onHide={disablepopup} centered size="md">
                <Modal.Body>
                   
                    <div className="p-2 swipe-rtl-animation">
                        <div style={{ padding: '20px 20px 0 20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center">
                                        <ColoredScanIcon height='50px' width='50px' />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Scan Serials
                                    </div>
                                    <div>
                                        Scan the serials of the products to be picked
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0' }}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                                     
                                                    <div className="row align-items-top form-group">
                                                        <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                            {/* <div className="textGray" style={{ width: '150px' }}>Expected Delivery Date</div> */}
                                                            {/* <div style={{ width: '275px', marginLeft: '10px' }}> */}

                                                                <input
                                                                    type="text"
                                                                    id="input-example"
                                                                    name="input-name"
                                                                    className="form-control"
                                                                    placeholder="Scan or enter serial number"
                                                                    minLength={15}
                                                                    maxLength={16}
                                                                    value={enteredSerial}
                                                                    onChange={(e) => {
                                                                        setEnteredSerial(e.target.value)
                                                                        // setItemDetails({
                                                                        //     ...itemDetails,
                                                                        //     imei: e.target.value
                                                                        // });
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        handleSerialInputKeydown(e);
                                                                    }}
                                                                    autoComplete={'off'}
                                                                    // disabled={!itemDetails?.product_id || !itemDetails?.quantity || !itemDetails?.color_name}
                                                                    autoFocus
                                                                />
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row align-items-top mt20">
                                                        <div className="col-12">
                                                            
                                                            {/* <div className="btnarea" style={{ justifyContent: 'right' }}>
                                                                <div className="">

                                                                    <div className="row">
                                                                        <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}> */}
                                                            
                                                                            <button
                                                                                className={`${isScanningDisabled && 'disabled' } btn btn-block btn-main-primary btn-outlined-main-primary`}
                                                                                disabled={isScanningDisabled}
                                                                                onClick={() => {
                                                                                    if (!isScanningDisabled) {
                                                                                        validateSubmitSerial()
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {
                                                                                    isScanningDisabled ? (
                                                                                        <>
                                                                                            <FaSpinner className="fa-spin" /> Processing...
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            Submit Serial
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </button>
                                                                        {/* </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                scannedSerials?.length > 0 && (
                                                    <>
                                                        <div className="card" style={{ marginTop: '35px' }}>
                                                            <div className="card-body">
                                                                <div className="row align-items-top">
                                                                    <div className="col-12 text-left">

                                                                        
                                                                        <div className="d-flex align-items-center justify-content-between TableSerachBar" style={{ padding: '10px 0'}}>
                                                                        <div className="font500" style={{ fontSize: '16px' }}>Scanned Products</div>

                                                                            <form className="form-inline navbar-search" style={{ position: 'absolute', right: '15px' }}>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control bg-white border"
                                                                                        placeholder="Search"
                                                                                        aria-label="Search"
                                                                                        aria-describedby="basic-addon2"
                                                                                        onChange={(e) => {
                                                                                            handleSearch(e.target.value)
                                                                                        }}
                                                                                        // value={searchField}
                                                                                        style={{ width: '100%' }}
                                                                                    />
                                                                                    <span className="searchIcon">
                                                                                        <FaSearch />
                                                                                    </span>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                                <div className="row mt5">
                                                                    <div className="col-md-12 font600 text-left">Products</div>
                                                                </div>
                                                                <hr className="mt5" />
                                                                <div>
                                                                    {
                                                                        scannedSerialsList?.map((item, index) => (
                                                                            <>    
                                                                                <div className="row hover-row">
                                                                                    <div className="col-md-12 text-left">
                                                                                        <div
                                                                                            // className={`${hasEveryPermission(['View Product (Button)']) ? 'text-pimary cursor-pointer' : '' }`}
                                                                                            // onClick={() => {
                                                                                            //     if (hasEveryPermission(['View Product (Button)'])) {
                                                                                            //         viewData('products', item?.product?.id)
                                                                                            //     }
                                                                                            // }}
                                                                                        >

                                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                                    <div className="mt2 font500" style={{ fontSize: '14px' }}>{item?.imei}</div>

                                                                                                    <div className="inline-small-badge" style={{ width: '95px' }}>
                                                                                                        <StatusBadge itemStatus={(item?.id) ? 'Reserved' : 'Available' } />
                                                                                                    </div>
                                                                                                </div>
                                                                                            <div className="textGray">
                                                                                                {item?.product} {item?.color && `(${item?.color})`}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                            
                                                                                    <div className="hover-button-container-lg" style={{marginTop: '0px'}}>
                                                                                        <div>
                                                                                            <div className="hover-button">
                                                                                                
                            
                                                                                                <PermissionAllowed permissions={['Delete Operations']}>
                                                                                                    <div
                                                                                                        className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
                                                                                                        style={{ marginLeft: '8px' }}
                                                                                                        onClick={() => {
                                                                                                            handleRemoveSerial(item, index);
                                                                                                        }}
                                                                                                    >
                                                                                                        <div style={{ margin: 'auto 5px auto 3px' }}>
                                                                                                            <DeleteIcon2 width={'20px'} height={'20px'} />
                                                                                                        </div>
                                                                                                        {/* <CheckCircleIcon height={'20'} width={'20'} /> Complete */}
                                                                                                    </div>
                                                                                                </PermissionAllowed>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <hr />
                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                                    
                                                    


                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PicklistSerialScanModal;
