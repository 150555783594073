import React, { useEffect, useState } from 'react';

import './notifications.scss';
import NotificationTextParser from 'ui-component/NotificationTextParser';
import moment from 'moment';
import { ImSpinner2 } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeHasNewNotification } from 'store/reducers/mainSlice';

function NotificationDropdown({ closeNotificationBox }) {
	const [isLoading, setIsLoading] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [nextLink, setNextLink] = useState(null);
	const [hasNewNotification] = useSelector((state) => [state.main.hasNewNotification]);
	const dispatch = useDispatch();

	const getNotifications = () => {
		setIsLoading(true);
		api.getUserNotifications().then((res) => {
			if (res?.status) {
				// setNextLink(
				// 	res?.data?.links?.[res?.data?.links?.length - 1]?.url ? res?.data?.links?.[res?.data?.links?.length - 1]?.url : null
				// );
				setNotifications((oldNots) => [...oldNots, ...res?.data?.data]);
				dispatch(storeHasNewNotification(res?.data?.hasNotification));
			}
			setIsLoading(false);
		});
	};

	useEffect(() => {
		getNotifications();
	}, []);

	return (
		<div className="notification-dropdown">
			<div className="d-flex justify-content-between align-items-center">
				<h6>Notifications</h6>
				<Link to={'/notifications'} onClick={closeNotificationBox}>
					Show All
				</Link>
			</div>
			<div className="notifications">
				{notifications?.length <= 0 && !isLoading && (
					<div className="no-notifications">
						<p>No Unread Notifications.</p>
					</div>
				)}
				{notifications?.length <= 0 && isLoading && (
					<div className="no-notifications">
						<ImSpinner2 className="fa-spin" /> Loading
					</div>
				)}
				{notifications?.map((notification) => (
					<div className="notification">
						<div className="notification-body">
							<div className="notification-head">
								<p style={{fontSize: '0.75em', color: '#707070'}}>{moment(notification?.date).fromNow()}</p>
							</div>
							<div className="notification-content" style={{fontSize: '0.85em'}}>
								<p>
									<NotificationTextParser inputString={notification?.details} />
								</p>
							</div>
						</div>
					</div>
				))}
				{hasNewNotification && notifications?.length % 5 == 0 && (
					<div className="text-center">
						<button
							className="btn btn-link"
							disabled={isLoading}
							onClick={() => {
								getNotifications();
							}}
						>
							{isLoading ? <ImSpinner2 className="fa-spin" /> : 'Show More'}
						</button>
					</div>
				)}
			</div>
			{/* <div className="text-center mt-2">
				
			</div> */}
		</div>
	);
}

export default NotificationDropdown;
