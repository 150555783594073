import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cirMinusIcon from 'assets/images/icons/minus-circle-line.svg';
import refreshIcon from 'assets/images/icons/refresh.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import cirAddIcon from 'assets/images/icons/add.svg';
import greenIcon from 'assets/images/icons/check-circle-fill.svg';
import redIcon from 'assets/images/icons/exclamation-circle-fill.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import AlertModel from 'views/purchaseList/comonents/AlertModel';
import { useNavigate } from 'react-router-dom';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { element } from 'prop-types';
import { FaCheckCircle, FaMinus, FaMinusCircle, FaPlusCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { MdOutlineFileDownloadDone } from 'react-icons/md';
import { FaCheckDouble, FaTicket } from 'react-icons/fa6';

const Multipleproduct = () => {
	const rowRef = useRef();
	const dispatch = useDispatch();
	const history = useNavigate();
	const popupselectedData = useSelector((state) => state.main.user.popupproductsource);
	const collectedFlag = parseInt(popupselectedData?.source);
	const template = parseInt(popupselectedData?.template);
	const supplierId = popupselectedData?.supplier;
	const [modelOption, setModelOption] = useState([]);
	const [modelList, setModelList] = useState([]);
	const [isLoading, setIsloading] = useState(false);
	const [selectedModel, setSelectedModel] = useState();
	const [curentModelValue, setCurrentModelValue] = useState();
	const [storageOption, setStorageOption] = useState();
	const [gradeOption, setGradeOption] = useState();
	const [colorOption, setColorOption] = useState();
	const [inputValue, setInputValue] = useState('');
	const [imeiList, setImeiList] = useState([]);
	const [varifiedIMEI, setVarifiedIMEI] = useState([]);
	const [currentModelData, setCurrentModelData] = useState();
	const [currentModelimeiLimit, setCurrentModelIMEILimit] = useState();
	const [modelListBlank, setModellistBlank] = useState([]);
	const [colorDisable, setColorDisable] = useState(true);
	const [currentColor, setCurrentColor] = useState('');
	const [alertData, setAlertData] = useState('');
	const [disable, setDisable] = useState(false);
	const [resViewHeight, setResViewHeight] = useState();

	const getallModelListbySupplier = () => {
		if (template > 0) {
			api.getCollectedModels(supplierId)
				.then((res) => {
					setModelList(res.data);
					let tempOption = res.data.map((item) => ({ id: item.master_model.id, label: item.master_model.name }));
					let tempgradeOption = res.data.map((item) => ({ id: item.master_model.grade.id, label: item.master_model.grade.name }));
					let tempstorageoption = res.data.map((item) => ({
						id: item.master_model.storage.id,
						label: item.master_model.storage.name
					}));
					setModelOption(tempOption);
					setStorageOption(tempstorageoption);
					setGradeOption(tempgradeOption);
				})
				.catch((err) => {
					console.log('Error ', err);
				});
		} else {
			api.selectAllmodel()
				.then((res) => {
					const tempList = res.data.map((item) => ({
						id: item.id,
						label: `${item.name}`
					}));
					let tempgradeOption = res.data.map((item) => ({ id: item.grade.id, label: item.grade.name }));
					let tempstorageoption = res.data.map((item) => ({
						id: item.storage.id,
						label: item.storage.name
					}));
					setModelList(res.data);
					setModelOption(tempList);
					setStorageOption(tempstorageoption);
					setGradeOption(tempgradeOption);
				})
				.catch((err) => {
					console.log('Error ', err);
				});
		}
	};
	const getallColorData = () => {
		api.allAttributes()
			.then((res) => {
				if (res.status) {
					let colorTemp = res.data.filter((item) => item.type == 'color').map((item) => ({ id: item.id, label: item.name }));
					setColorOption(colorTemp);
				}
			})
			.catch((err) => {
				console.log('Error ', err);
			});
	};

	const handleImeiRemove = (index) => {
		let list = [...imeiList];
		list.splice(index, 1);

		setImeiList(list);
	};

	const handleModelSelect = (data) => {
		let modelId = '';
		let currentSetModel = '';
		let tempselectedModel = '';
		let tempLimit = '';
		if (data) {
			modelId = data.id;
			if (template > 0) {
				currentSetModel = modelList.filter((item) => item.model == modelId)[0];
				tempselectedModel = modelList.filter((item) => item.model == modelId)[0].master_model;
				tempLimit = currentSetModel?.remaining_qty;
				setCurrentModelIMEILimit(tempLimit);
				if (tempLimit == 0) {
					setAlertData('Model Already Reviewed.');
					tempselectedModel = '';
					currentSetModel = '';
				}
			} else {
				if (modelListBlank && modelListBlank.length > 0 && modelListBlank.some((item) => item.id == modelId)) {
					setAlertData(`Model Already Reviewed.`);
					tempselectedModel = '';
					currentSetModel = '';
				} else {
					currentSetModel = modelList.filter((item) => item.id == modelId)[0];
					tempselectedModel = modelList.filter((item) => item.id == modelId)[0];
				}
			}
		} else {
			tempselectedModel = '';
			currentSetModel = '';
			setCurrentModelIMEILimit();
		}
		setSelectedModel(tempselectedModel);
		let tempData = '';
		if (template > 0) {
			tempData = tempLimit == 0 ? '' : data;
		} else {
			tempData = currentSetModel == '' ? '' : data;
		}
		setCurrentModelValue(tempData);
		setColorDisable(tempData ? false : true);
		setCurrentModelData(currentSetModel);
	};

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const handleInputKeydown = (event) => {
		if (event.key === 'Enter') {
			if (curentModelValue?.id) {
				if (currentColor == '') {
					setAlertData('Select Color');
				}
				const newValue = event.target.value;
				if (/^\d{15}$/.test(newValue)) {
					const checkDuplicate = imeiList?.find((element) => element === newValue);
					if (!checkDuplicate) {
						if (currentModelimeiLimit) {
							if (imeiList.length < currentModelimeiLimit) {
								setImeiList((prevList) => [...prevList, newValue]);
							} else {
								setAlertData(`Reached to Product quantity.`);
							}
						} else {
							setImeiList((prevList) => [...prevList, newValue]);
						}
					} else {
						setAlertData(`Don't enter duplicate IMEI number`);
					}

					setInputValue('');
				} else {
					setAlertData('Invalid IMEI');
				}
			} else {
				setAlertData('Please Select Model');
			}
		}
	};

	const clearData = () => {
		setImeiList([]);
	};
	const uniqimeicheck = () => {
		api.imeiuniqvarification({
			models: [{ imei_numbers: imeiList }]
		})
			.then((res) => {
				setVarifiedIMEI(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const storeDatatomodel = () => {
		if (currentColor == '') {
			setAlertData('Select Color');
		} else {
			let readyImei = varifiedIMEI.filter((item) => item.is_unique == 'yes').map((item) => item.imei);
			let temData = {
				...currentModelData,
				created_imeis: readyImei,
				new_created_qty: readyImei.length,
				color: currentColor
			};
			console.log('Final Data :', temData);
			if (template > 0) {
				handlestore(temData);
			} else {
				handlestoreblank(temData);
			}
		}
	};
	const handlestore = (temData) => {
		let updatedarray = modelList;
		let indexToOverride = updatedarray.findIndex((item) => item.model === temData.model);
		updatedarray[indexToOverride] = temData;
		setModelList(updatedarray);
		handleModelSelect('');
		setVarifiedIMEI([]);
		setImeiList([]);
		setCurrentColor('');
	};
	const handlestoreblank = (temData) => {
		setModellistBlank([...modelListBlank, temData]);
		handleModelSelect('');
		setVarifiedIMEI([]);
		setImeiList([]);
		setCurrentColor('');
	};
	const createProduct = () => {
		let readyModel = [];
		if (template > 0) {
			readyModel = modelList;
		} else {
			readyModel = modelListBlank;
		}
		let modelwithimei = readyModel
			.filter((item) => item.created_imeis?.length > 0)
			.map((item) => ({
				model_id: item.model || item.id,
				color: item.color,
				imei_numbers: item.created_imeis
			}));
		let payload = {
			product_source: collectedFlag,
			supplier: supplierId,
			template: template,
			models: modelwithimei
		};
		if (payload.models?.length > 0) {
			setDisable(true);
			api.createMultipleProduct(payload)
				.then((res) => {
					setDisable(false);
					console.log(res);
					if (res.status) {
						setTimeout(() => {
							dispatch(storeInLocalStore({ stateAlertmessage: 'Product Created Successfully.' }));
							history('/products');
						}, 500);
					}
				})
				.catch((err) => {
					console.log(err);
					setDisable(false);
				});
		} else {
			setAlertData(`Please Enter Product's IMEI Numers.`);
		}
	};
	useEffect(() => {
		getallModelListbySupplier();
		getallColorData();
	}, []);
	const goback = () => {
		history('/products');
	};
	const handlealerclose = () => {
		setAlertData('');
	};

	return (
		<>
			<div className="col-xl-12 col-lg-12 mb-4">
				<div className="card shadow-sm bg-white mb-4 p-2 p-xl-3">
					<div className="multipleView mx-1">
						<form className="userForm">
							<div className="form-row form-card mb-2">
								<div className="form-group multicreate col-md-3">
									<label htmlFor="model" className="">
										Model
									</label>
									<Select
										classNamePrefix="custom-select"
										theme={(theme) => ({
											...theme,
											borderRadius: 0,
											colors: {
												...theme.colors,
												primary: 'trasparent'
											}
										})}
										isSearchable={true}
										name="customer"
										value={curentModelValue}
										options={modelOption}
										placeholder="Select"
										onChange={(selected) => handleModelSelect(selected)}
										isDisabled={isLoading || imeiList.length > 0 ? true : false}
									/>
								</div>
								<div className="form-group col-md-3">
									<label htmlFor="storage" className="">
										Storage
									</label>
									<input
										type="text"
										className="form-control"
										value={storageOption?.find((item) => item.id === selectedModel?.storage_id)?.label}
										readOnly
									/>
								</div>
								<div className="form-group col-md-3">
									<label htmlFor="color" className="">
										Grade
									</label>
									<input
										type="text"
										className="form-control"
										value={gradeOption?.find((item) => item.id === selectedModel?.grade_id)?.label}
										readOnly
									/>
								</div>
								<div className="form-group col-md-3">
									<label htmlFor="color" className="">
										Color
									</label>
									<select
										className="form-control"
										onChange={(e) => setCurrentColor(e.target.value)}
										disabled={colorDisable}
									>
										<option value="">Select</option>
										{colorOption &&
											colorOption.map((item) => {
												return (
													<option key={item.id} value={item.id} selected={item.id == currentColor}>
														{item.label}
													</option>
												);
											})}
									</select>
								</div>
							</div>
							<Scroller
								resHeight={(e) => {
									setResViewHeight(e);
								}}
							>
								{/* <div className="row productRow m-0">
									<div className="col-md-2">
										<div className="inner-col overflow-auto overflow-box">
											<h4>Insert IMEI Here</h4>
											<input
												type="number"
												className="form-control mb-3"
												maxLength="15"
												value={inputValue}
												onChange={handleInputChange}
												placeholder="Insert IMEI here"
											/>
											<ul className="imeiList p-0">
												{imeiList.map((imei, index) => (
													<li key={index}>{imei}</li>
												))}
											</ul>
											<div className="bottombtns d-flex align-items-center justify-content-between">
												<Link to={''} onClick={clearData} className={imeiList.length > 0 ? '' : 'disabled'}>
													<span className="icon text-white mr-1">
														<img src={cirMinusIcon} className="" alt="" />
													</span>
													<span className="text-white">Clear</span>
												</Link>
												<Link to={''} onClick={uniqimeicheck} className={imeiList.length > 0 ? '' : 'disabled'}>
													<span className="icon text-white mr-1">
														<img src={refreshIcon} className="" alt="" />
													</span>
													<span className="text-white">Load</span>
												</Link>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="inner-col">
											<h4>Review</h4>
											<div className="reviewList">
												{varifiedIMEI &&
													varifiedIMEI.map((item, index) => {
														return (
															<ul key={index} className="p-0 d-flex flex-nowrap mb-0">
																<li className="textEllipsis">{selectedModel.name}</li>
																<li className="storage-col">{selectedModel.storage.name}</li>
																<li>{selectedModel.grade.name}</li>
																<li className="textEllipsis imeiNo">{item.imei}</li>
																<li>
																	{item.data ? (
																		<>
																			<img src={redIcon} className="" width={9} alt="" />
																		</>
																	) : (
																		<>
																			<img src={greenIcon} className="" width={9} alt="" />
																		</>
																	)}
																</li>
															</ul>
														);
													})}
											</div>
											<div className="bottombtns d-flex align-items-center justify-content-end">
												<Link
													to={''}
													onClick={storeDatatomodel}
													className={varifiedIMEI.length > 0 ? '' : 'disabled'}
												>
													<span className="icon text-white mr-2">
														<img src={cirAddIcon} className="" alt="" />
													</span>
													<span className="text-white">Add</span>
												</Link>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="inner-col">
											<h4>Models</h4>
											<div className="reviewList overflow-box">
												{template > 0 ? (
													<>
														{modelList &&
															modelList.map((item, index) => {
																let models = item.master_model;
																return (
																	<ul key={index} className="p-0 d-flex flex-nowrap mb-0">
																		<li className="textEllipsis">{models.name}</li>
																		<li>{models.storage.name}</li>
																		<li>{models.grade.name}</li>
																		<li className="">
																			{item.remaining_qty == 0
																				? item?.created_products_qty
																				: item?.new_created_qty || 0}
																			/{item.remaining_qty == 0 ? item.total_qty : item.remaining_qty}
																		</li>
																	</ul>
																);
															})}
													</>
												) : (
													<>
														{modelListBlank &&
															modelListBlank.length > 0 &&
															modelListBlank.map((item, index) => {
																return (
																	<ul key={index} className="p-0 d-flex mb-0">
																		<li className="textEllipsis">{item.name}</li>
																		<li>{item.storage.name}</li>
																		<li>{item.grade.name}</li>
																		<li className="">{item?.new_created_qty || 0}</li>
																	</ul>
																);
															})}
													</>
												)}
											</div>
										</div>
									</div>
								</div> */}

								<div
									className="row"
									style={{
										height: `${resViewHeight || 0}`
									}}
								>
									<div className="col-2">
										<div className="imei-section">
											<div className="top-section">
												<h4>Insert IMEI Here</h4>
												<input
													type="number"
													className="form-control mb-3"
													maxLength="15"
													value={inputValue}
													onChange={handleInputChange}
													onKeyDown={handleInputKeydown}
													placeholder="Insert IMEI here"
												/>
											</div>
											<div
												className="imei-list"
												style={{
													height: `calc(${resViewHeight || 0} - 140px)`
												}}
											>
												{imeiList.map((imei, index) => (
													<div className="imei-list-item" key={index}>
														{imei}{' '}
														<span
															onClick={() => {
																handleImeiRemove(index);
															}}
														>
															<FaTimesCircle />
														</span>
													</div>
												))}
											</div>
											<div className="imei-section-actions">
												<a onClick={clearData}>
													<FaMinusCircle /> Clear
												</a>
												<a onClick={uniqimeicheck}>
													<FaCheckCircle /> Load
												</a>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="review-section">
											<div className="top-section">
												<h4>Review</h4>
											</div>
											<div
												className="review-list"
												style={{
													height: `calc(${resViewHeight || 0} - 100px)`
												}}
											>
												<table className="w-100">
													{varifiedIMEI &&
														varifiedIMEI.map((item, index) => {
															return (
																<tr>
																	<td className="text-left">{selectedModel.name}</td>
																	<td className="text-center">{selectedModel.storage.name}</td>
																	<td className="text-center">{selectedModel.grade.name}</td>
																	<td className="text-center">{item.imei}</td>
																	<td className="text-right">
																		{item.data ? (
																			<>
																				<img src={redIcon} className="" width={9} alt="" />
																			</>
																		) : (
																			<>
																				<img src={greenIcon} className="" width={9} alt="" />
																			</>
																		)}
																	</td>
																</tr>
															);
														})}
												</table>

												{/* {varifiedIMEI &&
													varifiedIMEI.map((item, index) => {
														return (
															<div className="review-list-item" key={index}>
																<div>{selectedModel.name}</div>
																<div>{selectedModel.storage.name}</div>
																<div>{selectedModel.grade.name}</div>
																<div>{item.imei}</div>
																<div>
																	{item.data ? (
																		<>
																			<img src={redIcon} className="" width={9} alt="" />
																		</>
																	) : (
																		<>
																			<img src={greenIcon} className="" width={9} alt="" />
																		</>
																	)}
																</div>
															</div>
														);
													})} */}

												{/* {reviewList.map((review, index) => (
													<div className="review-list-item" key={index}>
														{review}
													</div>
												))} */}
											</div>
											<div className="review-section-actions">
												<a onClick={storeDatatomodel} className={varifiedIMEI.length > 0 ? '' : 'disabled'}>
													<FaPlusCircle /> Add
												</a>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div className="review-section">
											<div className="top-section">
												<h4>Models</h4>
											</div>
											<div
												className="review-list"
												style={{
													height: `calc(${resViewHeight || 0} - 100px)`
												}}
											>
												<table className="w-100">
													{template > 0 ? (
														<>
															{modelList &&
																modelList.map((item, index) => {
																	let models = item.master_model;
																	return (
																		<tr>
																			<td className="text-left">{models.name}</td>
																			<td className="text-center">{models.storage.name}</td>
																			<td className="text-center">{models.grade.name}</td>
																			<td className="text-center">
																				{item.remaining_qty == 0
																					? item?.created_products_qty
																					: item?.new_created_qty || 0}
																				/
																				{item.remaining_qty == 0
																					? item.total_qty
																					: item.remaining_qty}
																			</td>
																		</tr>
																	);
																})}
														</>
													) : (
														<>
															{modelListBlank &&
																modelListBlank.length > 0 &&
																modelListBlank.map((item, index) => {
																	return (
																		<tr>
																			<td className="text-left">{item.name}</td>
																			<td className="text-center">{item.storage.name}</td>
																			<td className="text-center">{item.grade.name}</td>
																			<td className="text-center">{item?.new_created_qty || 0}</td>
																		</tr>
																	);
																})}
														</>
													)}
												</table>

												{/* {varifiedIMEI &&
													varifiedIMEI.map((item, index) => {
														return (
															<div className="review-list-item" key={index}>
																<div>{selectedModel.name}</div>
																<div>{selectedModel.storage.name}</div>
																<div>{selectedModel.grade.name}</div>
																<div>{item.imei}</div>
																<div>
																	{item.data ? (
																		<>
																			<img src={redIcon} className="" width={9} alt="" />
																		</>
																	) : (
																		<>
																			<img src={greenIcon} className="" width={9} alt="" />
																		</>
																	)}
																</div>
															</div>
														);
													})} */}

												{/* {reviewList.map((review, index) => (
													<div className="review-list-item" key={index}>
														{review}
													</div>
												))} */}
											</div>
											<div className="review-section-actions"></div>
										</div>
									</div>
								</div>
							</Scroller>
						</form>
					</div>
					<div className="btnarea">
						<button onClick={goback} disabled={disable} className="btn btn-primary canBtn">
							<img src={minusIcon} className="minusIcon mr-2" alt="" /> Cancel
						</button>
						<button onClick={createProduct} type="submit" disabled={disable} className="btn btn-primary greenBtn">
							<img src={plusIcon} className="plusIcon mr-0" alt="" /> Create
						</button>
					</div>
				</div>
			</div>
			<AlertModel openStatus={alertData} handleclose={handlealerclose} />
		</>
	);
};

export default Multipleproduct;
