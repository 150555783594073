import React, { useRef, useState }  from 'react';
import { FaEye } from 'react-icons/fa6';

const PdfViewer = ({ src, onPreviewClick }) => {
	const iframeRef = useRef(null);
	const [isOverlayVisible, setOverlayVisible] = useState(false);

	const handlePdfLoad = () => {
		console.log('PDF loaded');
	};

	const handlePdfPreviewClick = () => {
		if (onPreviewClick) {
			onPreviewClick();
			console.log('PDF Clicked');
		}
	};
	const handleMouseEnter = () => {
		setOverlayVisible(true);
	};

	const handleMouseLeave = () => {
		setOverlayVisible(false);
	};

	return (
		<div style={{ position: 'relative', width: '100%', paddingTop: '114%' }}>
			<iframe
				title="PDF Viewer"
				src={src}
				width="100%"
				height="100%"
				onLoad={handlePdfLoad}
				// onClick={handlePdfPreviewClick}
				ref={iframeRef}
				style={{position: 'absolute', top: '0', left: '0', bottom: '0', right: '0'}}
			/>
			{isOverlayVisible && (
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(0, 0, 0, 0.5)'
					}}
					onClick={handlePdfPreviewClick}
				>
					<FaEye className="PdfEye" />
				</div>
			)}
		</div>
	);
};


export default PdfViewer;