import React,{useEffect, useRef, useState} from 'react';
import { Link, useNavigate, useParams,useSearchParams } from 'react-router-dom';
import Scroller from 'ui-component/Scroller';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { FaDownload } from 'react-icons/fa';
import { FaCheckCircle, FaPen, FaPlus, FaTimes,FaTimesCircle } from 'react-icons/fa';
import { PiWarningFill, PiInfoFill } from 'react-icons/pi';
import { ImSpinner2 } from 'react-icons/im';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import useSound from 'use-sound';
import errorSound from '../../../../assets/audio/error.mp3';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import toast from 'react-hot-toast';


const InboundComponent = () => {

	const { id } = useParams();
	const history = useNavigate();
	const topSection = useRef(null);
	const imeiInputRef = useRef(null);
	const [errorSoundPlay] = useSound(errorSound);
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedInbound, setSelectedInbound] = useState(null);
	const [selectedInboundItems, setSelectedInboundItems] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedColor, setSelectedColor] = useState(null);
	const [colors, setColors] = useState([]);
	const [modelRepairOptions, setModelRepairOptions] = useState([]);
	const [imeiInputValue, setImeiInputValue] = useState('');
	const [modelImeis, setModelImeis] = useState([]);
	const [isInProgress, setIsInProgress] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [errorDialog, setErrorDialog] = useState({ show: false, message: '', title: '' });
	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});
	const [addRepairDialog, setAddRepairDialog] = useState(false);

		useEffect(() => {
		const data = {
			inboundId: id,
			modelImeis: modelImeis?.filter((m) => !m?.existing)
		};

		if (data?.modelImeis?.length > 0) {
			localStorage.setItem('inboundAutoSave', JSON.stringify(data));
		}
	}, [modelImeis]);

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			// Check if you want to show the confirmation popup
			if (true) {
				const message = 'Are you sure you want to leave?';
				event.returnValue = message; // Standard for most browsers
				return message; // For some older browsers
			}
		};

		// Attach the event listener when the component mounts
		window.addEventListener('beforeunload', handleBeforeUnload);

		// Detach the event listener when the component unmounts
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);


	 useEffect(() => {
		
		
		iniateData();
		loadColorData();
	}, [id]);
	    const totalQuantity = selectedInbound?.receipt_items?.reduce((total, row) => total + row.quantity, 0);

	const iniateData = () =>{
            api.getreceipts(id).then((res) => {
                console.log(res)
                setSelectedInbound(res)
				const itemsList = [];

				res?.receipt_items?.forEach((item)=>{
					itemsList.push({
						id: item?.product_id,
						 product: item?.product,
						qty: item?.quantity,
						remaining_qty: item?.quantity,
					})
			});

			setSelectedInboundItems(itemsList);
			
		});
     }

	 const loadColorData = () => {
		api.getAllColors().then((res) => {
			if (res.status) {
				let typeTemp = res.data.map((item) => ({
					value: item.name?.[0].toUpperCase() + item.name?.substring(1),
					label: item.name?.[0].toUpperCase() + item.name?.substring(1)
				}));
				setColors(typeTemp);
			}
		});
	};

	const removeImeiFromList = (imei) => {
		setModelImeis([...modelImeis].filter((f) => f.imei != imei));
	};

	const handleIMEIInputKeydown = (event) => {
		if (event.key === 'Enter') {
			addModalImei();
		}
	};
	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const addModalImei = async () => {
		if (!imeiInputValue || !imeiInputValue.trim()) {
			showErrorDialog('Invalid IMEI', `Invalid IMEI Entered`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}

		const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

		if (!alphanumericRegex.test(imeiInputValue)) {
			showErrorDialog('Invalid IMEI', `Please enter a valid IMEI Number`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}

		if (
			Number(modelImeis?.filter((model) => model?.product?.id == selectedProduct?.value)?.length) >=
			selectedInboundItems?.find((c) => c?.product?.id == selectedProduct?.value).qty
		) {
			showErrorDialog('Invalid Model', `Maximum quantity for model inbounded`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}

		if (modelImeis.find((m) => m?.imei == imeiInputValue)) {
			showErrorDialog('Duplicate IMEI', `Couldn't add IMEI ${imeiInputValue} since it already exists in the list`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
		} else {
			const theImei = `${imeiInputValue}`;
			setModelImeis((prevModelImeis) => [
				{
					imei: `${imeiInputValue}`,
					repairs: [],
					valid: 'loading',
					invalid_message: 'Loading',
					product: selectedInbound?.receipt_items?.find((c) => c?.product?.id == selectedProduct?.value)?.product,
					color: selectedColor,
					item_id: selectedInbound?.receipt_items?.find((c) => c?.product?.id == selectedProduct?.value)?.id
				},
				...prevModelImeis
			]);
			setImeiInputValue('');
			imeiInputRef.current.value = '';

			try {
				console.log(theImei)
				console.log(modelImeis)
				const response = await api.checkIMEIStatus(theImei);
				console.log('response',response)
				if (response?.status) {
					if (response?.data == 'Available') {
						setModelImeis((prevModelImeis) =>
							prevModelImeis.map((item) =>
								item.imei == theImei ? { ...item, valid: true, invalid_message: 'IMEI Exists in stock' } : item
							
							)
							
						);
					} else if (response?.data == 'Inbounded') {
						setModelImeis((prevModelImeis) =>
							prevModelImeis.map((item) =>
								item.imei == theImei ? { ...item, valid: false, invalid_message: 'IMEI Exists in stock' } : item
							)
						);
					} else if (response?.data == 'Outbounded') {
						setModelImeis((prevModelImeis) =>
							prevModelImeis.map((item) =>
								item.imei == theImei ? { ...item, valid: 'outbounded', invalid_message: 'IMEI was Outbounded' } : item
							)
						);
					}
				} else {
					setModelImeis((prevModelImeis) =>
						prevModelImeis.map((item) =>
							item.imei === theImei ? { ...item, valid: false, invalid_message: 'Something went wrong' } : item
						)
					);
				}
			} catch (error) {
				setModelImeis((prevModelImeis) =>
					prevModelImeis.map((item) =>
						item.imei === theImei ? { ...item, valid: false, invalid_message: 'Something went wrong' } : item
					)
				);
			}
		}
	};
	const onDoneClicked = () => {
		
		localStorage.removeItem('inboundAutoSave');
		const data = {
			receipt_id: id,
			items: modelImeis
				?.filter((m) => !m?.existing)
				?.map((m) => ({
					item_id: m?.item_id,
					product_id: m?.product?.id,
					color: m?.color,
					imei: m?.imei
				}))
		};

			console.log(data)
			api.purchaseInboundingData(data).then((res) => {
				if (res) {
					setIsDisable(false);
					toast.success('Inbounding Completed');
					history('/backorders');
				}
				return res?.status ? res : null;
			})
		
	};
	const goback = () => {
		// history('/operations/inbounding');
	};


  return (
    <>
			 
			
			<div className={`TaskDetails`}>
				
				<div className="row">
					<div className="col-12">
						<div className="card p-4">
							<Scroller>
								<div className="row pb-4" ref={topSection}>
									<div className="col-12">
										<div className="row">
											<div className="col-md-20 mb-2 mb-md-0 detail-item">
												<p className="detail-label">Reference.</p>
												<div className="detail-value"> {selectedInbound?.receipt_number} </div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Supplier</p>
												<div className="detail-value">
													<PrivacyModeContent>{selectedInbound?.purchase_invoice?.supplier?.name}</PrivacyModeContent>
												</div>
											</div>

											<div className="col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label" style={{ fontSize: '11px' }}>
													Quantity
												</p>
												<div className="detail-value">{totalQuantity} Pcs</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row inbounding-imeis">
									<div className="col-xl-12 col-lg-12">
										<div className="row">
											<div className="col-4">
												<div className="card bg-white mb-4">
													<div className="input-form p-0">
														<div className="form-row col-md-12 m-0 p-0">
															<div className="form-group">
																<label htmlFor="">Product</label>
																{JSON.stringify()}
																<Select
																	placeholder="Select Model"
																	className="rs-container"
																	classNamePrefix="rs"
																	options={selectedInboundItems?.map((c) => ({
																		label: c?.product?.product_name,
																		value: c?.product?.id
																	}))}
																	onChange={(e) => {
																		setSelectedProduct(e);
																		setTimeout(() => {
																			if (e.value && selectedColor) {
																				imeiInputRef.current.focus();
																			}
																		}, 1);
																	}}
																/>
															</div>
															<div className="form-group">
																<label htmlFor="">Colour</label>
																<CreatableSelect
																	placeholder="Select Colour"
																	className="rs-container"
																	classNamePrefix="rs"
																	options={colors}
																	isDisabled={!selectedProduct}
																	onChange={(e) => {
																		setSelectedColor(e.value);
																		setTimeout(() => {
																			imeiInputRef.current.focus();
																		}, 1);
																	}}
																/>
															</div>
															<div className="form-group">
																<label htmlFor="">IMEI</label>
																<input
																	ref={imeiInputRef}
																	type="text"
																	id="input-example"
																	name="input-name"
																	className="form-control form-control-sm"
																	placeholder="IMEI"
																	minLength={15}
																	maxLength={16}
																	onChange={(e) => {
																		setImeiInputValue(event.target.value);
																	}}
																	onKeyDown={(e) => {
																		handleIMEIInputKeydown(e);
																	}}
																	disabled={!selectedProduct || !selectedColor}
																	autoComplete={'off'}
																	autoFocus
																/>
															</div>
															<label htmlFor="">Product List</label>
															<div className="product-list">
																<table>
																	<thead>
																		<tr>
																			<th>Product</th>
																			<th>Remaining</th>
																			<th>Total</th>
																		</tr>
																	</thead>
																	<tbody>
																		{selectedInboundItems?.map((item) => (
																			<tr>
																				<td>{item?.product?.product_name}</td>
																				<td>
																					{Number(item?.qty) -
																						(Number(
																							modelImeis?.filter(
																								(model) =>
																									model?.product?.id == item?.product?.id
																							)?.length
																						) || 0)}{' '}
																					Pcs
																				</td>
																				<td>{item?.qty} Pcs</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-8">
												<div className="card bg-white mb-4">
													
													<Scroller extraHeight={topSection?.current?.clientHeight || 0}>
														<table className="table text-gray-800">
															<thead>
																<tr>
																	<th scope="col" width="20%">
																		IMEI
																	</th>
																	<th scope="col" width="20%">
																		Model
																	</th>
																	<th scope="col">Storage</th>
																	<th scope="col">Colour</th>
																	<th scope="col">Grade</th>
																	<th scope="col"></th>
																</tr>
															</thead>
															<tbody>
																{modelImeis.map((item, index) => (
																	<tr>
																		<td className="align-middle">
																			<span
																				className="d-flex align-items-center"
																				style={{ gap: '2px' }}
																			>
																				{item?.existing ? (
																					<Tooltip title="Inbounded" arrow placement="top">
																						<span className="text-info d-flex">
																							<FaCircleStop />
																						</span>
																					</Tooltip>
																				) : item?.valid == 'loading' ? (
																					<Tooltip title="Checking IMEI" arrow placement="top">
																						<span className="d-flex">
																							<ImSpinner2 className="fa-spin" />
																						</span>
																					</Tooltip>
																				) : item?.valid == true ? (
																					<Tooltip title="Valid IMEI" arrow placement="top">
																						<span className="text-success d-flex">
																							<FaCheckCircle />
																						</span>
																					</Tooltip>
																				) : (
																					<Tooltip
																						title={item?.invalid_message || 'Invalid IMEI'}
																						arrow
																						placement="top"
																					>
																						<span className="text-danger d-flex">
																							<FaTimesCircle />
																						</span>
																					</Tooltip>
																				)}{' '}
																				<span>{item?.imei}</span>
																			</span>
																		</td>
																		{console.log(item)}
																		<td className="align-middle">{item?.product?.model?.name}</td>
																		<td className="align-middle">{item?.product?.storage?.name}</td>
																		<td className="align-middle">{item?.color}</td>
																		<td className="align-middle">{item?.product?.grade?.name}</td>
																		<td className="align-middle text-center">
																			{!item?.existing ? (
																				<span
																					className="btnEdit plain-iconbtn"
																					style={{
																						height: '20px',
																						width: '20px',
																						fontSize: '10px',
																						display: 'inline-block'
																					}}
																					onClick={() => {
																						removeImeiFromList(item?.imei);
																					}}
																				>
																					<FaTimes />
																				</span>
																			) : (
																				<Tooltip
																					title="Item already Inbounded"
																					arrow
																					placement="top"
																					key={'remove-' + index}
																				>
																					<span
																						className="btnEdit plain-iconbtn disabled"
																						style={{
																							height: '20px',
																							width: '20px',
																							fontSize: '10px',
																							display: 'inline-block'
																						}}
																						disabled
																					>
																						<FaTimes />
																					</span>
																				</Tooltip>
																			)}
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													</Scroller>
													
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</Scroller>
							<div className="btnarea">
								<span
									onClick={() => {
										goback();
									}}
									className={`btn btn-link ${isDisable ? 'disabled' : ''}`}
								>
									Back
								</span>
								<PermissionAllowed permissions={['Start / End Task Inbounding (Action)']}>

											<span disabled={isDisable}
												onClick={() => {
													if (
														(modelImeis?.filter((m) => !m?.existing)?.length <= 0 &&
															modelImeis?.length != totalQuantity) ||
														modelImeis?.find((m) => m?.valid == false)
													) {
													} else {
														onDoneClicked();
													}
												}}
												className={`btn btn-primary btn-outlined-main  ${
													(modelImeis?.filter((m) => !m?.existing)?.length <= 0 &&
														modelImeis?.length != totalQuantity) ||
													modelImeis?.find((m) => m?.valid == false)
														? 'disabled'
														: modelImeis?.length < totalQuantity ? 'disabled' : ''
												}`}
											>
												{modelImeis?.length >= totalQuantity ? 'Done' : 'Save'}
											</span>
										
								</PermissionAllowed>
							</div>
						</div>
					</div>
				</div>
			</div>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}

			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}

			
		{/*
			<ProcessIndicator isInProgress={isInProgress} processes={taskboardProgresses} currentIndex={4} />
			{console.log(selectedInbound)}
			<div className={`TaskDetails`}>
				<div className="d-flex justify-content-between mb-0">
					<div className="topBtnSign" style={{ '--statusColor': getStatusColor(!selectedInbound?.end_at ? 'blue' : 'green') }}>
						<span>
							{selectedInbound?.start_at && selectedInbound?.end_at ? 'Completed' : isInProgress ? 'Inprogress' : 'Upcoming'}
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card p-4">
							<Scroller>
								<div className="row pb-4" ref={topSection}>
									<div className="col-6 d-flex justify-content-between mb-4">
										<h3 className="font-weight-bold m-0">{selectedInbound?.invoice_number}</h3>
										
									</div>
									<div className="col-6 d-flex download-option mb-4">
									{selectedInbound?.start_at && selectedInbound?.end_at ? (<>
										<button
							    			type="button"
							    			className="btn btn-primary btn-outlined-main white-var"
							    			style={{ minWidth: 'unset' }}
											onClick={() => {downloadInbounding(selectedInbound) }  } 
							  				>
							    			<FaDownload />
							    			Download
							  			</button>
									</>)
									 : (<></>)
									 
									 }
									
										
									</div>
									<div className="col-12">
										<div className="row">
											<div className="col-md-20 mb-2 mb-md-0 detail-item">
												<p className="detail-label">Reference.</p>
												<div className="detail-value"> {selectedInbound?.segregation_detail_reference_no} </div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Collected On</p>
												<div className="detail-value">
													{selectedInbound?.collected_on
														? moment(selectedInbound?.collected_on).format('DD MMM YYYY hh:mm A')
														: 'N/A'}
												</div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Customer</p>
												<div className="detail-value">
													<PrivacyModeContent>{selectedInbound?.customer_name}</PrivacyModeContent>
												</div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Supplier</p>
												<div className="detail-value">
													<PrivacyModeContent>{selectedInbound?.supplier_name}</PrivacyModeContent>
												</div>
											</div>

											<div className="col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label" style={{ fontSize: '11px' }}>
													Quantity
												</p>
												<div className="detail-value">{selectedInbound?.total_qty} Pcs</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row inbounding-imeis">
									<div className="col-xl-12 col-lg-12">
										<div className="row">
											<div className="col-4">
												<div className="card shadow-sm bg-white mb-4">
													<div className="input-form p-0">
														<div className="form-row col-md-12 m-0 p-0">
															<div className="form-group">
																<label htmlFor="">Product</label>
																{JSON.stringify()}
																<Select
																	placeholder="Select Model"
																	className="rs-container"
																	classNamePrefix="rs"
																	options={selectedInboundItems?.map((c) => ({
																		label: c?.product?.product_name,
																		value: c?.product?.id
																	}))}
																	onChange={(e) => {
																		setSelectedProduct(e);
																		setTimeout(() => {
																			if (e.value && selectedColor) {
																				imeiInputRef.current.focus();
																			}
																		}, 1);
																	}}
																	isDisabled={!isInProgress}
																/>
															</div>
															<div className="form-group">
																<label htmlFor="">Colour</label>
																<CreatableSelect
																	placeholder="Select Colour"
																	className="rs-container"
																	classNamePrefix="rs"
																	options={colors}
																	isDisabled={!isInProgress || !selectedProduct}
																	onChange={(e) => {
																		setSelectedColor(e.value);
																		setTimeout(() => {
																			imeiInputRef.current.focus();
																		}, 1);
																	}}
																/>
															</div>
															<div className="form-group">
																<label htmlFor="">IMEI</label>
																<input
																	ref={imeiInputRef}
																	type="text"
																	id="input-example"
																	name="input-name"
																	className="form-control form-control-sm"
																	placeholder="IMEI"
																	minLength={15}
																	maxLength={16}
																	onChange={(e) => {
																		setImeiInputValue(event.target.value);
																	}}
																	onKeyDown={(e) => {
																		handleIMEIInputKeydown(e);
																	}}
																	disabled={!isInProgress || !selectedProduct || !selectedColor}
																	autoComplete={'off'}
																	autoFocus
																/>
															</div>
															<label htmlFor="">Product List</label>
															<div className="product-list">
																<table>
																	<thead>
																		<tr>
																			<th>Product</th>
																			<th>Remaining</th>
																			<th>Total</th>
																		</tr>
																	</thead>
																	<tbody>
																		{selectedInboundItems?.map((item) => (
																			<tr>
																				<td>{item?.product?.product_name}</td>
																				<td>
																					{Number(item?.qty) -
																						(Number(
																							modelImeis?.filter(
																								(model) =>
																									model?.product?.id == item?.product?.id
																							)?.length
																						) || 0)}{' '}
																					Pcs
																				</td>
																				<td>{item?.qty} Pcs</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-8">
												<div className="card shadow-sm bg-white mb-4">
													
													<Scroller extraHeight={topSection?.current?.clientHeight || 0}>
														<table className="table text-gray-800">
															<thead>
																<tr>
																	<th scope="col" width="20%">
																		IMEI
																	</th>
																	<th scope="col" width="20%">
																		Model
																	</th>
																	<th scope="col">Storage</th>
																	<th scope="col">Colour</th>
																	<th scope="col">Grade</th>
																	<th scope="col">Needs Repair</th>
																	<th scope="col"></th>
																</tr>
															</thead>
															<tbody>
																{modelImeis.map((item, index) => (
																	<tr>
																		<td className="align-middle">
																			<span
																				className="d-flex align-items-center"
																				style={{ gap: '2px' }}
																			>
																				{item?.existing ? (
																					<Tooltip title="Inbounded" arrow placement="top">
																						<span className="text-info d-flex">
																							<FaCircleStop />
																						</span>
																					</Tooltip>
																				) : item?.valid == 'loading' ? (
																					<Tooltip title="Checking IMEI" arrow placement="top">
																						<span className="d-flex">
																							<ImSpinner2 className="fa-spin" />
																						</span>
																					</Tooltip>
																				) : item?.valid == true ? (
																					<Tooltip title="Valid IMEI" arrow placement="top">
																						<span className="text-success d-flex">
																							<FaCheckCircle />
																						</span>
																					</Tooltip>
																				) : (
																					<Tooltip
																						title={item?.invalid_message || 'Invalid IMEI'}
																						arrow
																						placement="top"
																					>
																						<span className="text-danger d-flex">
																							<FaTimesCircle />
																						</span>
																					</Tooltip>
																				)}{' '}
																				<span>{item?.imei}</span>
																			</span>
																		</td>
																		<td className="align-middle">{item?.product?.model?.name}</td>
																		<td className="align-middle">{item?.product?.storage?.name}</td>
																		<td className="align-middle">{item?.color}</td>
																		<td className="align-middle">{item?.product?.grade?.name}</td>
																		<td
																			className="align-middle text-left action-col"
																			style={{
																				justifyContent: 'flex-start',
																				alignItems: 'center',
																				gap: '3px'
																			}}
																		>
																			{item?.repairs?.length > 0 ? (
																				<>
																					<span>
																						{item?.repairs?.map(
																							(r, i) =>
																								`${r?.repair_type?.code}${
																									i != item?.repairs?.length - 1
																										? ', '
																										: ''
																								}`
																						)}
																					</span>
																					{!!!selectedInbound?.end_at && (
																						<PermissionAllowed
																							permissions={['Add Item Repair for Inbounding']}
																						>
																							<span
																								style={{
																									fontSize: '10px',
																									display: 'flex',
																									cursor: 'pointer'
																								}}
																								onClick={() => {
																									onRepairClick(item);
																								}}
																							>
																								<FaPen />
																							</span>
																						</PermissionAllowed>
																					)}
																				</>
																			) : !!selectedInbound?.end_at ? (
																				'-'
																			) : (
																				<PermissionAllowed
																					permissions={['Add Item Repair for Inbounding']}
																				>
																					<span
																						className={`btnEdit plain-textbtn`}
																						style={{
																							height: '20px',
																							minWidth: 'unset',
																							fontSize: '10px',
																							width: 'fit-content'
																						}}
																						onClick={() => {
																							onRepairClick(item);
																						}}
																					>
																						<FaPlus /> Add
																					</span>
																				</PermissionAllowed>
																			)}
																		</td>
																		<td className="align-middle text-center">
																			{!item?.existing ? (
																				<span
																					className="btnEdit plain-iconbtn"
																					style={{
																						height: '20px',
																						width: '20px',
																						fontSize: '10px',
																						display: 'inline-block'
																					}}
																					onClick={() => {
																						removeImeiFromList(item?.imei);
																					}}
																				>
																					<FaTimes />
																				</span>
																			) : (
																				<Tooltip
																					title="Item already Inbounded"
																					arrow
																					placement="top"
																					key={'remove-' + index}
																				>
																					<span
																						className="btnEdit plain-iconbtn disabled"
																						style={{
																							height: '20px',
																							width: '20px',
																							fontSize: '10px',
																							display: 'inline-block'
																						}}
																						disabled
																					>
																						<FaTimes />
																					</span>
																				</Tooltip>
																			)}
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													</Scroller>
													
												</div>
											</div>
										</div>
										
									</div>
								</div>
							</Scroller>
							<div className="btnarea">
								<span
									onClick={() => {
										goback();
									}}
									className={`btn btn-link ${isDisable ? 'disabled' : ''}`}
								>
									Back
								</span>
								<PermissionAllowed permissions={['Start / End Task Inbounding (Action)']}>
									{selectedInbound?.status != 'completed' &&
										(isInProgress ? (
											<span
												onClick={() => {
													if (
														isDisable ||
														(modelImeis?.filter((m) => !m?.existing)?.length <= 0 &&
															modelImeis?.length != selectedInbound?.total_qty) ||
														modelImeis?.find((m) => m?.valid == false)
													) {
													} else {
														onDoneClicked();
													}
												}}
												className={`btn btn-primary btn-outlined-main  ${
													isDisable ||
													(modelImeis?.filter((m) => !m?.existing)?.length <= 0 &&
														modelImeis?.length != selectedInbound?.total_qty) ||
													modelImeis?.find((m) => m?.valid == false)
														? 'disabled'
														: ''
												}`}
											>
												{modelImeis?.length >= selectedInbound?.total_qty ? 'Done' : 'Save'}
											</span>
										) : (
											<span
												onClick={() => {
													onStartClick();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Start
											</span>
										))}
								</PermissionAllowed>
							</div>
						</div>
					</div>
				</div>
			</div>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}

			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}

			{addRepairDialog && (
				<AddRepairsModal
					show={addRepairDialog}
					handleClose={handleRepairDialogClose}
					onSubmit={onAddRepairSubmit}
					repairOptions={modelRepairOptions?.find((m) => m?.model_id == addRepairDialog?.product?.model_id)?.options}
				/>
			)} */}
		</>
  );
};

export default InboundComponent;
