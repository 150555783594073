import { textToSentenceCase } from 'helpers/common.helper';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Pagination from 'ui-component/Pagination';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Scroller from 'ui-component/Scroller';
import Notes from 'ui-component/notes';
import { FaUserTie } from 'react-icons/fa6';
import { FaRegFileExcel, FaClock, FaPencilAlt } from 'react-icons/fa';
import { TbFileInvoice } from 'react-icons/tb';
import { LuChevronDown } from 'react-icons/lu';
import { Dropdown } from 'react-bootstrap';
import ShipmentTrackingModal from 'ui-component/confirmation-dialogs/awbModal/ShipmentTrackingModal';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill } from 'react-icons/pi';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import usePageTitle from 'hooks/usePageTitle';

function WarehouseDetails() {
	const [setPageTitle] = usePageTitle('Warehouse');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const { id } = useParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const history = useNavigate();
	const [isLoading, setLoading] = useState(false);
	const [isItemLoading, setItemLoading] = useState(false);
	const [invoices, setInvoices] = useState([]);
	const [transitShipmentItemList, setTransitShipmentItemList] = useState([]);
	const [warehouseDetails, setWarehouseDetails] = useState();
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [pagination, setpagination] = useState([]);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [searchField, setSearchField] = useState();
	const [showTrackingNoModal, setShowTrackingNoModal] = useState(false);

	const [trackingNoDetail, setTrackingNoDetail] = useState(null);

	const topSection = useRef(null);
	const getWarehouseDetails = (callback = () => { }) => {
		setLoading(true);
		api.getWarehouseDetails(id).then((res) => {
			if (res?.status) {
				setLoading(false);
				setWarehouseDetails({
					...res.data,
					// notes: res?.data?.notes?.map((note) => ({
					// 	id: note?.id,
					// 	details: note?.note,
					// 	date: moment(note?.created_at || undefined).format('YYYY-MM-DD'),
					// 	time: moment(note?.created_at || undefined).format('HH:mm'),
					// 	user_name: note?.logged_by_user?.first_name,
					// 	expand: false,
					// 	isDeletable: res?.data?.is_deletable,
					// }))
				});
				// setInvoices(res?.data?.invoices);
				callback(res.data);
			} else {
				history('/shipments');
			}
		});

	};


	const handleTrackingNoModalClose = () => {
		handlePaymentModal(false, null);
		setShowAddPayment && setShowAddPayment(false);
	};

	const handleFormSubmit = (e) => {
		toast.promise(
			api
				.updateShipmentAwb(id, {
					tracking_no: e?.trackingNo,
					shipment_id: showTrackingNoModal,
				})
				.then((res) => {
					if (res.status) {
						getWarehouseDetails()
						handleCloseShipmentTrackingModal();
					}
				}),
			{
				loading: 'Updating Tracking...',
				success: <b>Tracking Detail updated!</b>,
				error: <b>Could not Update Tracking Detail.</b>
			}
		);

	};

	const handleUpdateTransitShipmentStatus = (transit_shipment_invoice_id) => {

		let cnfrmTtl = 'Update Shipment Status';
		let cnfrmMsg = 'Are you sure this package has been received by transit company?';

		showConfirmationDialog(cnfrmTtl, cnfrmMsg, () => {
			toast.promise(
				api
					.updateTransitShipmentInvoiceStatus(id)
					.then(
						(res) => {
							if (res.status) {
								getWarehouseDetails()
							}

							handleCloseConfirmationDialog();

							return res?.status ? res : null;
						},
						() => { }
					),
				{
					loading: 'Updating status...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update shipment status</b>
				}
			);
		});
	};

	// const handleData = (data) => {
	// 	// setTransitShipmentItemList(data.data);
	// 	// setpagination(data);
	// };

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => { },
		handleClose: () => { }
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => { },
			handleClose: () => { }
		});
	};

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => { },
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	useEffect(() => {
		if (id) {
			getWarehouseDetails();
		}
	}, []);


	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, [])

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	}

	const s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
		return buf;
	};

	const handleDownloadImei = (id) => {
		api.downloadShipmentImei(id).then((res) => {
			// Create a workbook
			const wb = XLSX.utils.book_new();
			// Create a worksheet
			const ws = XLSX.utils.json_to_sheet(res?.data);
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

			// Convert the workbook to a binary string
			const wbBinaryString = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

			// Convert the binary string to a Blob
			const blob = new Blob([s2ab(wbBinaryString)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


			saveAs(blob, `${warehouseDetails?.invoice_number} imeis.xlsx`);
		});
	}
	const handleEditAwb = (shipmentId) => {
		setShowTrackingNoModal(shipmentId);
	};
	const handleCloseShipmentTrackingModal = (data) => {
		setShowTrackingNoModal('');
	};
	const redirectFunc = (data) => {
		setTimeout(() => {
			history(`/shipments/${id}`);
		}, 1000);
	};


	const addNote = (noteInputValue) => {
		toast.promise(
			api.newShipmentNote(id, {
				note: noteInputValue
			})
				.then(
					(res) => {
						if (res.status) {
							setWarehouseDetails((oldData) => ({
								...oldData,
								// notes: [
								// 	{
								// 		id: res?.data?.id,
								// 		details: noteInputValue,
								// 		date: moment(res?.data?.created_at || undefined).format('YYYY-MM-DD'),
								// 		time: moment(res?.data?.created_at || undefined).format('HH:mm'),
								// 		user_name: userName,
								// 		expand: false,
								// 		isDeletable: res?.data?.is_deletable,
								// 	},
								// 	...(oldData?.notes || [])
								// ]
							}));
						}

						return res?.status ? res : null;
					},
					() => { }
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const removeNote = (index) => {
		toast.promise(
			api.removeTransitShipmentNote(id, warehouseDetails?.notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setWarehouseDetails((oldData) => ({
							...oldData,
							// notes: oldData?.notes?.filter((note, i) => index != i)
						}));
					}
					return res?.status ? res : null;
				},
				() => { }
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	return (
		<>
			<div className={`TaskDetails`}>
				<div className="row">
					<div className="col-11 offset-1 col-md-8 offset-md-2">
						<div className="row pb10">
							<div className="col-12">
								<div className="section-head">
									<h2
										style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}
									>

									</h2>
									<div className="actions">

										<button
											className="bulk-action btn-outlined-main"
											onClick={() => { handleDownloadImei(id); }}
										>
											<span className="menuIcon">
												<FaRegFileExcel style={{ fontSize: '12px' }} />
											</span>
											<span>Export Stock</span>
										</button>
										<button
											className="bulk-action btn-outlined-main ml8"
											style={{ marginLeft: '8px' }}
											onClick={() => { handleDownloadImei(id); }}
										>
											<span className="menuIcon">
												<FaClock style={{ fontSize: '12px' }} />
											</span>
											<span>Stock History</span>
										</button>
										<button
											className="bulk-action btn-outlined-main ml8"
											style={{ marginLeft: '8px' }}
											onClick={() => {
												history(`/warehouses/edit/${warehouseDetails?.id}`);
											}}
										>
											<span className="menuIcon">
												<FaPencilAlt style={{ fontSize: '12px' }} />
											</span>
											<span>Edit</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-11 offset-1 col-md-8 offset-md-2">
						<div className="card p-2">
							<div style={{ padding: '20px 20px 0px 20px' }}>

								<Scroller>
									<div className="row inbounding-imeis">
										<div className="col-xl-12 col-lg-12">
											<div className="row">
												<div className="col-6">
													<div className="form-group">
														<label className="textGray">Name</label>
														<div className='black mt-5px'>{warehouseDetails?.name}</div>
													</div>
												</div>
												<div className="col-6">
													<div className="form-group">
														<label className="textGray">Accepts orders</label>
														<div className='black mt-5px'>
															{(warehouseDetails?.can_accept_orders) ? 'Yes' : 'No'}
														</div>
													</div>
												</div>
												<div className="col-6">
													<div className="form-group">
														<label className="textGray">Stock value</label>
														<div className='black mt-5px'>{warehouseDetails?.stock_value}</div>
													</div>
												</div>
												<div className="col-6">
													<div className="form-group">
														<label className="textGray">Selected by default</label>
														<div className='black mt-5px'>
															{(warehouseDetails?.is_default) ? 'Yes' : 'No'}
														</div>
													</div>
												</div>
												<div className="col-6">
													<div className="form-group">
														<label className="textGray">Status</label>
														<div className='black mt-5px'>
															{warehouseDetails?.status === 1 ? (
																<span className="activeStatus bg-success">Active</span>
															) : (
																<span className="inactiveStatus bg-danger">
																	Inactive
																</span>
															)}
														</div>
													</div>
												</div>
												<div className="col-6">
													<div className="form-group">
														<label className="textGray">Counts for general stock</label>
														<div className='black mt-5px'>
															{(warehouseDetails?.can_include_stock) ? 'Yes' : 'No'}
														</div>
													</div>
												</div>
											</div>
											{/* <Pagination pagination={pagination} data={handleData} onLoading={(e) => setItemLoading(e)} /> */}
										</div>
									</div>
								</Scroller>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showTrackingNoModal && (
				<ShipmentTrackingModal
					shipmentId={showTrackingNoModal}
					redirectionData={redirectFunc}
					onFormSubmit={handleFormSubmit}
					handleCloseShipmentTrackingModal={handleCloseShipmentTrackingModal}
				/>
			)}
			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}
		</>
	);
}

export default WarehouseDetails;
