import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaPlus, FaSave } from 'react-icons/fa';
import InvoiceModal from './modals/InvoiceModal';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const ReturnsDetailsComponent = ({
	returnsDetail,
	handleSetEstimationId,
	invoiceDetails
	// productDetails,
	// openModalFormWarehouse
}) => {
	// const permission = useSelector((state) => state.main.user.permissionToAction);
	// const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);

	const [openPopup, setOpenPopup] = useState(false);
	const [isDisable, setDisable] = useState(false);
	const [disableStatus, setDisablestatus] = useState(false);
	// const [invoiceDetails, setInvoiceDetails] = useState({
	//     estimationId: '',
	//     invoiceNumber: '',
	//     customerId: '',
	//     customerName: '',
	// });

	const handleSelectInvoice = (type) => {
		setOpenPopup(type);
	};
	const closePopup = () => {
		setOpenPopup(false);
	};

	return (
		<>
			<div className="row mb20">
				<div className="col-12">
					<div className="card card-shadow p-2">
						<div className="row custom-card-padding">
							<div className="col-12" style={{ padding: '15px 25px 15px 25px;' }}>
								<div className="row">
									<div className="col-12">
										<h6 className="mb-3 font14" style={{ fontWeight: '500' }}>
											Details
										</h6>
									</div>
								</div>

								<div className="row">
									<div className="col-12 mb10 ">
										{invoiceDetails?.estimationId && (
											<>
												<div className="row">
													<div className="col-12 font14" style={{ marginBottom: '15px' }}>
														<PrivacyModeContent>{invoiceDetails?.customerName}</PrivacyModeContent>
													</div>
												</div>
												{invoiceDetails?.billingAddress && (
													<>
														<div style={{ marginBottom: '15px' }}>
															<div className="row">
																<div className="col-12">
																	<span className="font14" style={{ fontWeight: '500' }}>
																		Billing Address
																	</span>
																</div>
															</div>
															<div className="row">
																<div className="col-12 font14 textGray">
																	<PrivacyModeContent>
																		{invoiceDetails?.billingAddress && <>{`${invoiceDetails?.billingAddress}`}</>}
																	</PrivacyModeContent>
																</div>
															</div>
															<div className="row">
																<div className="col-12 font14 textGray">
																	<PrivacyModeContent>
																		{invoiceDetails?.billingZipcode && <>{`${invoiceDetails?.billingZipcode}, `}</>}
																	</PrivacyModeContent>
																	<PrivacyModeContent>
																		{invoiceDetails?.billingCity && <>{`${invoiceDetails?.billingCity}, `}</>}
																	</PrivacyModeContent>
																	<PrivacyModeContent>
																		{invoiceDetails?.billingCountry && <>{`${invoiceDetails?.billingCountry}, `}</>}
																	</PrivacyModeContent>
																</div>
															</div>
														</div>
													</>
												)}
												{invoiceDetails?.shippingAddress && (
													<>
														<div style={{ marginBottom: '15px' }}>
															<div className="row">
																<div className="col-12">
																	<span className="font14" style={{ fontWeight: '500' }}>
																		Shipping Address
																	</span>
																</div>
															</div>
															<div className="row">
																<div className="col-12 font14 textGray">
																	<PrivacyModeContent>
																		{invoiceDetails?.shippingAddress && <>{`${invoiceDetails?.shippingAddress}`}</>}
																	</PrivacyModeContent>
																</div>
															</div>
															<div className="row">
																<div className="col-12 font14 textGray">
																	<PrivacyModeContent>
																		{invoiceDetails?.shippingZipcode && <>{`${invoiceDetails?.shippingZipcode}, `}</>}
																	</PrivacyModeContent>
																	<PrivacyModeContent>
																		{invoiceDetails?.shippingCity && <>{`${invoiceDetails?.shippingCity}, `}</>}
																	</PrivacyModeContent>
																	<PrivacyModeContent>
																		{invoiceDetails?.shippingCountry && <>{`${invoiceDetails?.shippingCountry}, `}</>}
																	</PrivacyModeContent>
																</div>
															</div>
														</div>
													</>
												)}
												<div className="row">
													<div className="col-12 font14 textGray">
														Invoice <Link to={`/sales/invoices/${invoiceDetails?.estimationId}`}><PrivacyModeContent>{invoiceDetails?.invoiceNumber}</PrivacyModeContent></Link>
													</div>
												</div>
											</>
										)}
										{/* <label htmlFor="inputName" className="est-labels font14">
                                            Invoice
                                        </label>
                                        {
                                            invoiceDetails?.estimationId ? (
                                                <>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Link to={`/sales/invoices/${invoiceDetails?.estimationId}`}>
                                                                <PrivacyModeContent>{invoiceDetails?.invoiceNumber}</PrivacyModeContent>
                                                            </Link>
                                                            <span className="text-primary"
                                                                style={{ marginLeft: '20px', cursor: 'pointer' }}
                                                                onClick={() => { handleSelectInvoice('Invoice'); }}
                                                            >
                                                                Change
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        className="bulk-action btn-outlined-main"
                                                        onClick={() => { handleSelectInvoice('Invoice'); }}
                                                    >
                                                        <FaPlus style={{ fontSize: '10px' }} /> Select Invoice
                                                    </button>
                                                </>
                                            )
                                        } */}
									</div>
								</div>
								{/* <div className="row">
                                    <div className="col-12">

                                        <label htmlFor="inputName" className="est-labels font14">
                                            Address
                                        </label>

                                    </div>
                                </div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			{openPopup && (
				<InvoiceModal
					openPopup={openPopup}
					returnsDetail={returnsDetail}
					handleClose={closePopup}
					handleSave={handleSave}
					disableStatus={isDisable}
				/>
			)}
		</>
	);
};

export default ReturnsDetailsComponent;
