import { CommonDateOnlyFormat } from 'helpers/common.helper';
import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaDownload, FaMinus, FaSave } from 'react-icons/fa';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';

function PaymentDetailsModal({ showPaymentModal, showPayment, handleClose, estimate }) {
	return (
		<Modal show={showPaymentModal} onHide={handleClose} centered>
			<Modal.Body>
				<h4 className="h6 text-white text-center pb-4">Payment</h4>

				<div className="row payment-view mb-3">
					<div className="col-md-4 mb-3">
						<p>Date</p>
						<h6>{moment(showPayment?.payment_date).format(CommonDateOnlyFormat)}</h6>
					</div>
					<div className="col-md-4 mb-3">
						<p>Payment Method</p>
						<h6>Wired</h6>
					</div>
					<div className="col-md-4 mb-3">
						<p>Amount Paid</p>
						<h6>
							{getCurrencySymbol(estimate?.currency)}
							{numberFormatter.format(showPayment?.amount_paid)}
						</h6>
					</div>
					<div className="col-md-4 mb-3">
						<p>Amount Open</p>
						<h6>
							{getCurrencySymbol(estimate?.currency)} {numberFormatter.format(estimate?.open_balance?.open)}
						</h6>
					</div>
					<div className="col-md-4 mb-3">
						<p>Added By</p>
						<h6>
							{showPayment?.user_created?.first_name} {showPayment?.user_created?.last_name}
						</h6>
					</div>
					<div className="col-md-12">
						<p className="m-0">Attachsadfsment</p>
						<img src={showPayment?.payment_attachment?.payment_attachment_url} alt="" width="100%" />
					</div>
				</div>

				<div className="btnarea w-100 mt-2">
					<button onClick={handleClose} className="btn btn-primary canBtn btn-sm" type="button">
						<FaMinus /> Close
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default PaymentDetailsModal;
