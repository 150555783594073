import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ViewDetailsIcon, ScanIcon, MoreAppIcon, ShipmentLabelIcon } from 'ui-component/custom-icons/icons';
import Pagination from 'ui-component/Pagination';
import NoImage from 'assets/images/no-image.png';
import Scroller from 'ui-component/Scroller';
import { useParams } from 'react-router';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import moment from 'moment';
import { FaSearch, FaPlus, FaSave, FaTimes, FaMinus, FaCheck } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton, Checkbox } from '@mui/material';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import usePageTitle from 'hooks/usePageTitle';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import StatusBadge from 'ui-component/StatusBadge';
import { LuChevronDown, LuSend } from 'react-icons/lu';
import Logs from './components/Logs';
import Breadcrumbs from './components/Breadcrumbs';
import PicklistShipmentComponent from './components/PicklistShipmentComponent';
import PicklistSerialScanModal from './modals/PicklistSerialScanModal';
import PicklistCreateShipmentModal from './modals/PicklistCreateShipmentModal';
import * as XLSX from 'xlsx';

const PicklistDetails = () => {
    const { id } = useParams();
    const [setPageTitle] = usePageTitle('Picklists');
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);

    const history = useNavigate();
    const [showDelete, setShowDelete] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [showScanModal, setShowScanModal] = useState(false);
    const [showCreateShipmentModal, setShowCreateShipmentModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const cancelingPicklistMessage = `Canceling this picklist has the following consequence:
                                        <div class="mt15">
                                            <ul>
                                                <li class="text-left">Products will be re-added to the stock</li>
                                                <li class="text-left">Shipments will be cancelled</li>
                                                <li class="text-left">Products will be converted to backorders</li>
                                            </ul>
                                        </div>`;
    const deletingPicklistMessage = `Deleting this picklist has the following consequence:
                                        <div class="mt15">
                                            <ul>
                                                <li class="text-left">Products will be re-added to the stock</li>
                                                <li class="text-left">Shipments will be cancelled</li>
                                                <li class="text-left">Products will be converted to backorders</li>
                                            </ul>
                                        </div>`;

    const [picklistDetails, setPicklistDetails] = useState({
        id: '',
        picklistNo: '',
        referenceNo: '',
        createdDate: '',
        pickedQty: 0,
        totalCollectedQty: 0,
        totalProducts: 0,
        status: null,
        customer: null,
        items: null,
        shipments: null,
        logs: null
    });

    const [shipmentForm, setShipmentForm] = useState({
        picklist_id: '',
        reference_no: '',
        weight: '',
        weight_unit: 'kg',
        shipping_company_id: '',
        sales_order_id: '',
        items: null,
    });


    useEffect(() => {
        if (id) {
            getPicklistDetails()
        }
    }, []);

    const getPicklistDetails = () => {

        api.getPicklistDetails(id).then((res) => {
            if (res?.status) {
                const data = {
                    id: res?.data?.id,
                    picklistNo: res?.data?.picklist_no,
                    referenceNo: res?.data?.reference_no,
                    createdDate: res?.data?.created_at,
                    pickedQty: res?.data?.picked_qty,
                    totalCollectedQty: res?.data?.total_collected_qty,
                    totalProducts: res?.data?.total_products,
                    status: res?.data?.status,
                    customer: res?.data?.customer,
                    items: res?.data?.items,
                    shipments: res?.data?.shipments,
                    logs: res?.data?.logs,
                }
                setPicklistDetails(data);
                setDataLoaded(true)

                setShipmentForm(shipmentForm => ({
                    ...shipmentForm,
                    picklist_id: res?.data?.id,
                    items: res?.data?.items
                }));
            }
        });
    }

    const viewData = (pageModule, id) => {
        let url = '';
        if (pageModule == 'products') {
            url = `/products/${id}`;
        } else if (pageModule == 'customers') {
            url = `/contacts/${id}`;
        } else if (pageModule == 'sales_orders') {
            url = `/sales/sales-orders//${id}`;
        }
        if (url) {
            window.open(url, '_blank');
        }
    };

    const handleIncrease = (id) => {
        const updatedItems = picklistDetails.items.map(item => {
            if (item.id === id) {
                let currentPickedQuantity = item?.picked_quantity;
                const newCollectedQuantity = currentPickedQuantity + 1
                
                if ((newCollectedQuantity + item?.collected_quantity) <= item?.quantity) {
                    return {
                        ...item,
                        picked_quantity: newCollectedQuantity
                    };
                }
            }
            return item;
        });
    
        setPicklistDetails(prevDetails => ({
            ...prevDetails,
            items: updatedItems,
        }));

        const updateShipmentForm = shipmentForm.items.map(item => { 
            if (item.id === id) {
                let currentPickedQuantity = item?.picked_quantity;
                const newPickedQuantity = currentPickedQuantity + 1
                
                if (newPickedQuantity <= (item?.quantity - item?.collected_quantity)) {
                    return {
                        ...item,
                        picked_quantity: newPickedQuantity
                    };
                }
            }
            return item;
        });
        setShipmentForm(prevDetails => ({
            ...prevDetails,
            items: updateShipmentForm,
        }));
    }
    const handleDecrease = (id) => {
        const updatedItems = picklistDetails.items.map(item => {
            if (item.id === id)
            {
                let currentPickedQuantity = item?.picked_quantity;
                const newPickedQuantity = currentPickedQuantity - 1
                
                console.log('picklistDetails', picklistDetails)
                if (newPickedQuantity >= 0)
                {
                    return {
                        ...item,
                        picked_quantity: newPickedQuantity
                    };
                }
            }
            return item;
        });
    
        setPicklistDetails(prevDetails => ({
            ...prevDetails,
            items: updatedItems
        }));



        const updateShipmentForm = shipmentForm.items.map(item => { 
            if (item.id === id) {
                let currentPickedQuantity = item?.picked_quantity;
                const newPickedQuantity = currentPickedQuantity - 1
                
                if (newPickedQuantity >= 0) {
                    return {
                        ...item,
                        picked_quantity: newPickedQuantity
                    };
                }
            }
            return item;
        });
        setShipmentForm(prevDetails => ({
            ...prevDetails,
            items: updateShipmentForm,
        }));
    }
    
    const handleScanSerials = (item) => {
        console.log('item', item)
        setShowScanModal(true);
        setSelectedProduct(item);
    }

    const handleClose = () => {
        setShowScanModal(false);
        setShowCreateShipmentModal(false);
        setShowCancel(false);
        setShowDelete(false);
    }

    const handleReloadData = () => {
        getPicklistDetails()
        setShowCreateShipmentModal(false);
    }

    const handlePickAll = () => {
        const items = picklistDetails?.items.map(item => ({
            ...item,
            picked_quantity: item?.quantity - item?.collected_quantity
        }))
        const shipmemntItems = shipmentForm?.items.map(item => ({
            ...item,
            picked_quantity: item?.quantity - item?.collected_quantity
        }))
    
        setPicklistDetails(prevDetails => ({
            ...prevDetails,
            // pickedQty: totalQuantity,
            items: items
        }));
        setShipmentForm(prevDetails => ({
            ...prevDetails,
            // pickedQty: totalQuantity,
            items: shipmemntItems,
        }))
    }

    const handlePickAllPerItem = (id) => {

        const updatedItems = picklistDetails.items.map(item => {
            if (item.id === id) {
                let currentCollectedQuantity = item?.collected_quantity;
                const newCollectedQuantity = currentCollectedQuantity + 1
                
                if ((newCollectedQuantity + item?.collected_quantity) <= item?.quantity) {
                    return {
                        ...item,
                        picked_quantity: item?.quantity - item?.collected_quantity
                    };
                }
            }
            return item;
        });
        // const totalQuantity = updatedItems?.map(item => item?.collected_quantity).reduce((a, b) => a + b, 0);
        setPicklistDetails(prevDetails => ({
            ...prevDetails,
            // pickedQty: totalQuantity,
            items: updatedItems,
        }));

        const shipmentItems = shipmentForm.items.map(item => { 
            if (item.id === id) {
                let currentPickedQuantity = item?.picked_quantity;
                const newPickedQuantity = currentPickedQuantity + 1
                
                if (newPickedQuantity <= item?.quantity) {
                    return {
                        ...item,
                        picked_quantity: (item?.quantity - item?.collected_quantity)
                    };
                }
            }
            return item;
        })
        setShipmentForm(prevDetails => ({
            ...prevDetails,
            items: shipmentItems,
        }))
    }

    const handleAddScannedSerials = (picklistItemId, item) => {
        const updatedCollection = shipmentForm?.items?.find(item => item?.id === picklistItemId);
        updatedCollection?.serials.push(item);
        console.log('updatedCollection', updatedCollection)
    }

	const s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	};

    const exportSerials = () => {

		api.downloadPicklistSerials(id).then((res) => {
			// Create a workbook
			const wb = XLSX.utils.book_new();
			// Create a worksheet
			const ws = XLSX.utils.json_to_sheet(res?.data);
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

			// Convert the workbook to a binary string
			const wbBinaryString = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

			// Convert the binary string to a Blob
			const blob = new Blob([s2ab(wbBinaryString)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			saveAs(blob, `Projectmix Picklist ${picklistDetails?.picklistNo} imeis.xlsx`);
        });
        
		// api.downloadPicklistSerials(id).then((res) => {
		// 	// if (res?.status) {

		// 	var newBlob = new Blob([res], { type: 'application/pdf' });
		// 	saveAs(
		// 		newBlob,
		// 		`Projectmix Picklist ${picklistDetails?.picklistNo}.pdf`
		// 	);
		// });
    }


    const handleCreateShipment = () => {
        setShowCreateShipmentModal(true);
        // toast.promise(
        //     api
        //         .createShipmentFromPicklist(picklistDetails?.id, shipmentForm)
        //         .then(
        //             (res) => {
        //                 if (res.status) {
        //                     // const collection = {
        //                     //     id: res?.data?.id,
        //                     //     imei: res?.data?.imei,
        //                     //     product: res?.data?.product,
        //                     //     color: res?.data?.color,
        //                     //     isExists: res?.data?.is_exists,
        //                     //     status: res?.data?.status,
        //                     //     statusName: res?.data?.status_name,
        //                     // };
    
        //                     // // Add the new collection to the scannedSerials array
        //                     // setScannedSerials(prevSerials => [
        //                     //     ...prevSerials,
        //                     //     collection
        //                     // ]);

    
        //                     // setEnteredSerial('');
        //                     // if (res?.data?.status_name !== 'Sold') {
        //                     //     // handleReloadData()
        //                     //     handleAddScannedSerials(selectedProduct?.id, collection)
        //                     // }
        //                 }
    
        //                 return res?.status ? res : null;
        //             },
        //             () => { }
        //         ),
        //     {
        //         loading: 'Creating shipment...',
        //         success: (data) => `${data.message}`,
        //         error: <b>Failed to add shipment</b>
        //     }
        // );
    }

    const handleCancelPicklist = () => {
        
        toast.promise(
            api
                .cancelPicklist(picklistDetails?.id)
                .then(
                    (res) => {
                        if (res.status) {
                            // const collection = {
                            //     id: res?.data?.id,
                            //     imei: res?.data?.imei,
                            //     product: res?.data?.product,
                            //     color: res?.data?.color,
                            //     isExists: res?.data?.is_exists,
                            //     status: res?.data?.status,
                            //     statusName: res?.data?.status_name,
                            // };
    
                            // // Add the new collection to the scannedSerials array
                            // setScannedSerials(prevSerials => [
                            //     ...prevSerials,
                            //     collection
                            // ]);

    
                            // setEnteredSerial('');
                            // if (res?.data?.status_name !== 'Sold') {
                            //     // handleReloadData()
                            //     handleAddScannedSerials(selectedProduct?.id, collection)
                            // }
                        }
    
                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Cancelling picklist...',
                success: (data) => `${data.message}`,
                error: <b>Failed to cancel picklist</b>
            }
        );
    }
    const handleDeletePicklist = () => {
        console.log('cancel picklist')
    }

    const handleCancelShipment = () => {
        console.log('cancel shipment')
    }

    const handleDeleteShipment = () => {
        console.log('delete shipment')
    }
    
    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="section-head-new" style={{ marginTop: '10px', paddingLeft: '0' }}>

                        <h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }} className="col-md-4">
                            {/* P123891 */}
                            {
                                dataLoaded ? ( 
                                    <>
                                        {picklistDetails?.picklistNo}
                                        <div style={{ width: '75px'}}>
                                            <StatusBadge itemStatus={picklistDetails?.status?.name} />
                                        </div>
                                    </>
                                ): (
                                    <>
                                        <Skeleton variant="rounded" width={'90px'} height={20}></Skeleton>
                                        <div style={{ width: '75px'}}>
                                            <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                        </div>
                                    </>
                                )
                            }
                            
                            
                        </h2>
                        <div className="actions">

                            <PermissionAllowed permissions={['View Picklists (Button)']} hasSome>

                                <Dropdown
                                    // className="btnDel btn-export-po iconbtn"
                                    className={ 'btnDel btnPicklistMore' }
                                    align={'end'}
                                >
                                    <Dropdown.Toggle as="span">
                                        <Link to={''}
                                            className={`${!dataLoaded ? 'disabled' : '' } btn btn-primary btn-outlined-main white-var`}
                                            style={{ minWidth: 'unset' }}
                                            disabled={!dataLoaded}
                                        >

                                            <MoreAppIcon width='16px' height='16px' />
                                            More <LuChevronDown />
                                        </Link>
                                    </Dropdown.Toggle>
                                    {
                                        dataLoaded && (
                                            <>
                                                <Dropdown.Menu>
            
                                                    <PermissionAllowed permissions={['View Picklists (Button)']}>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setShowCancel(true)
                                                                
                                                                // downloadPurchaseOrder('pdf');
                                                            }}
                                                        >
                                                            Cancel
                                                        </Dropdown.Item>
                                                    </PermissionAllowed>
                                                    <PermissionAllowed permissions={['View Picklists (Button)']}>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setShowDelete(true)
                                                            }}
                                                        >
                                                            Delete
                                                        </Dropdown.Item>
                                                    </PermissionAllowed>
                                                </Dropdown.Menu>
                                            </>
                                        )
                                    }
                                </Dropdown>
                            </PermissionAllowed>

                            <PermissionAllowed permissions={['View Picklists (Button)']} hasSome>

                                <button
                                    type="button"
                                    onClick={() => exportSerials()}
                                    className="btn btn-primary btn-outlined-main"
                                    disabled={!dataLoaded}
                                >
                                    <svg
                                        id="fi_2767144"
                                        enable-background="new 0 0 128 128"
                                        height="18"
                                        viewBox="0 0 128 128"
                                        width="18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            id="Download"
                                            d="m128 65c0 15.439-12.563 28-28 28h-20c-2.211 0-4-1.791-4-4s1.789-4 4-4h20c11.027 0 20-8.973 20-20s-8.973-20-20-20h-4c-2.211 0-4-1.791-4-4 0-15.439-12.563-28-28-28s-28 12.561-28 28c0 2.209-1.789 4-4 4h-4c-11.027 0-20 8.973-20 20s8.973 20 20 20h20c2.211 0 4 1.791 4 4s-1.789 4-4 4h-20c-15.437 0-28-12.561-28-28s12.563-28 28-28h.223c1.996-17.975 17.277-32 35.777-32s33.781 14.025 35.777 32h.223c15.438 0 28 12.561 28 28zm-50.828 37.172-9.172 9.172v-50.344c0-2.209-1.789-4-4-4s-4 1.791-4 4v50.344l-9.172-9.172c-1.563-1.563-4.094-1.563-5.656 0s-1.563 4.094 0 5.656l16 16c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l16-16c1.563-1.563 1.563-4.094 0-5.656s-4.094-1.563-5.656 0z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                    Export
                                </button>
                            </PermissionAllowed>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-9 form-group">

                            <div className="card shadow-sm bg-white mb-4">
                                <div className="d-flex align-items-center justify-content-between TableSerachBar">
                                    <div>
                                        <div className="actions">
                                            <div style={{ margin: '0 5px' }}>
                                                
                                                <Breadcrumbs
                                                    status={picklistDetails?.status?.name}
                                                    disabled={dataLoaded}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ marginTop: '0px' }} />
                                <div className="text" style={{ padding: '0 15px' }}>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <div className="col-12" style={{ lineHeight: '25px', fontSize: '15px' }}>
                                                    {
                                                        !dataLoaded ? (
                                                            <>
                                                                <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <b>{picklistDetails?.customer?.name}</b>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12" style={{ lineHeight: '20px' }}>
                                                    {
                                                        !dataLoaded ? (
                                                            <>
                                                                <Skeleton variant="rounded" width={'60%'} height={20}></Skeleton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {picklistDetails?.customer?.address?.address}
                                                            </>
                                                        )
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12" style={{ lineHeight: '20px' }}>
                                                    {
                                                        !dataLoaded ? (
                                                            <>
                                                                <Skeleton variant="rounded" width={'60%'} height={20}></Skeleton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {picklistDetails?.customer?.address?.city}, {picklistDetails?.customer?.address?.zipcode}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12" style={{ lineHeight: '20px' }}>
                                                    {
                                                        !dataLoaded ? (
                                                            <>
                                                                <Skeleton variant="rounded" width={'60%'} height={20}></Skeleton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {picklistDetails?.customer?.address?.country}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <div className="col-3 textGray text-right" style={{ lineHeight: '25px' }}>
                                                    Date
                                                </div>
                                                <div className="col-9" style={{ lineHeight: '25px' }}>
                                                    {
                                                        !dataLoaded ? (
                                                            <>
                                                                <Skeleton variant="rounded" width={'100px'} height={20}></Skeleton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {moment(picklistDetails?.createdDate).format(CommonDateOnlyFormat)}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 textGray text-right" style={{ lineHeight: '25px' }}>
                                                    Customer
                                                </div>
                                                <div className="col-9" style={{ lineHeight: '25px' }}>
                                                    {
                                                        !dataLoaded ? (
                                                            <>
                                                                <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {picklistDetails?.customer?.name}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 textGray text-right" style={{ lineHeight: '25px' }}>
                                                    Order
                                                </div>
                                                <div className="col-9" style={{ lineHeight: '25px' }}>

                                                    {
                                                        !dataLoaded ? (
                                                            <>
                                                                <Skeleton variant="rounded" width={'150px'} height={20}></Skeleton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {picklistDetails?.referenceNo} <span className="textGray">Ordered at 24 July 2024</span>
                                                            </>
                                                        )
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 textGray text-right" style={{ lineHeight: '25px' }}>
                                                    Reference
                                                </div>
                                                <div className="col-9" style={{ lineHeight: '25px' }}>
                                                    {
                                                        !dataLoaded ? (
                                                            <>
                                                                <Skeleton variant="rounded" width={'100px'} height={20}></Skeleton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {picklistDetails?.referenceNo}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-2 textGray text-right" style={{ lineHeight: '25px' }}>
                                                    Warehouse
                                                </div>
                                                <div className="col-10" style={{ lineHeight: '25px' }}>
                                                    Dubai Warehouse
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-12 text-right" style={{ lineHeight: '25px' }}>
                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            className="btn btn-primary btn-sm btn-outlined-main white-var btn-icon-only btn-icon-square"
                                                            disabled={!dataLoaded}
                                                            onClick={ () => {
                                                                handlePickAll()
                                                            }}
                                                        >
                                                            Pick All
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        picklistDetails?.items?.length > 0 && (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-3 font600">Amount</div>
                                                    <div className="col-md-2 font600 picklist-sku-col">SKU</div>
                                                    <div className="col-md-5 font600">Name</div>
                                                    <div className="col-md-2 font600">Serials</div>
                                                </div>
                                                <hr />
                                                {
                                                    picklistDetails?.items?.map((item, index) => (
                                                        <>
                                                            
                                                            <div className="row hover-row">
                                                                <div className="col-md-3 d-flex align-items-center d-flex" style={{ gap: '10px'}}>
                                                                    
                                                                    <div className='btn-group'>
                                                                        <button
                                                                            className='btn btn-default btn-sm btn-left'
                                                                            onClick={() => handleDecrease(item?.id)}
                                                                        >
                                                                            <FaMinus />
                                                                        </button>
                                                                        {/* <input type='text' value={rowCounts[index]} className='product-amount-field'
                                                                            onChange={(e) => {
                                                                                const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                                updaterowCounts(index, cleanedValue);
                                                                            }}
                                                                        /> */}
                                                                        <input
                                                                            type='text'
                                                                            // value={item?.quantity}
                                                                            // value={ `${item?.collected_quantity}/${item?.quantity}` }
                                                                            value={ `${(item?.collected_quantity + item?.picked_quantity)}/${item?.quantity}` }
                                                                            className='product-amount-field'
                                                                            // onChange={(e) => {
                                                                            //     const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                            //     updaterowCounts(item?.id, cleanedValue);
                                                                            // }}
                                                                            style={{ width: '65px' }}
                                                                            readOnly={true}
                                                                        />
                                                                        <button
                                                                            className='btn btn-default btn-sm btn-right'
                                                                            onClick={() => handleIncrease(item?.id)}
                                                                        >
                                                                            <FaPlus />
                                                                        </button>
                                                                        <button
                                                                            className='btn btn-default btn-sm btn-right'
                                                                            onClick={() => handlePickAllPerItem(item?.id)}
                                                                        >
                                                                            <FaCheck />
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 d-flex align-items-center text-primary picklist-sku-col">
                                                                    <div
                                                                        className={`${hasEveryPermission(['View Product (Button)']) ? 'text-pimary cursor-pointer' : '' }`}
                                                                        onClick={() => {
                                                                            if (hasEveryPermission(['View Product (Button)'])) {
                                                                                viewData('products', item?.product?.id)
                                                                            }
                                                                        }}
                                                                    >

                                                                        {item?.product?.sku}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5 d-flex align-items-center d-flex" style={{ gap: '8px' }}>
                                                                    {
                                                                        (item?.product?.thumbnail_url) ? (
                                                                            <>
                                                                                <img src={item?.product?.thumbnail_url} className="img-fluid" style={{ height: '40px' }} />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <img src={NoImage} style={{ width: '30px' }} alt="product" />
                                                                            </>
                                                                        )
                                                                    }
                                                                    <div className="">
                                                                        <div className="row">
                                                                            <div className="col-12 font500" style={{ lineHeight: '20px' }}>
                                                                                {item?.product?.name}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    item?.show_scanner && (
                                                                        <>
                                                                            <div className="col-md-2 d-flex align-items-center">
                                                                                {item?.serials?.length + item?.picklist_item_unused_serials?.length}
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    item?.show_scanner && (
                                                                        <>
                                                                            <div className="hover-button-container-lg" style={{ marginTop: '0px' }}>
                                                                                <div>
                                                                                    <div className="hover-button">
                                                                                        

                                                                                        <PermissionAllowed permissions={['Delete Operations']}>
                                                                                            <div
                                                                                                className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
                                                                                                style={{ marginLeft: '8px' }}
                                                                                                onClick={() => {
                                                                                                    handleScanSerials(item);
                                                                                                }}
                                                                                            >
                                                                                                <div style={{ margin: 'auto 5px auto 3px' }}>
                                                                                                    <ScanIcon width={'20px'} height={'20px'} />
                                                                                                </div>
                                                                                                {/* <CheckCircleIcon height={'20'} width={'20'} /> Complete */}
                                                                                            </div>
                                                                                        </PermissionAllowed>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <hr />
                                                        </>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                </div>
                                <div className="row" style={{ padding: '0 15px '}}>
                                    <div className="col-12 d-flex justify-content-between">
                                        <div className="d-flex" style={{ gap: '50px' }}>
                                            <div className="d-flex" style={{ gap: '10px' }}>
                                                Picked Products
                                                <div>{picklistDetails?.items?.reduce((acc, item) => acc + ((parseInt(item.collected_quantity) + parseInt(item.picked_quantity)) || 0), 0)}</div>
                                            </div>
                                            <div className="d-flex" style={{ gap: '10px' }}>
                                                Total Products
                                                <div>{picklistDetails?.totalProducts}</div>
                                            </div>
                                        </div>
                                            
                                        <div className="create-shipment-class justify-content-end mt-8 ">
                                            <button
                                                className="btn btn-primary btn-sm btn-outlined-main white-var btn-icon-only btn-icon-square"
                                                disabled={!dataLoaded || picklistDetails?.items?.reduce((acc, item) => acc + (parseInt(item.picked_quantity) || 0), 0) < 1}
                                                onClick={() => {
                                                    if (picklistDetails?.items?.reduce((acc, item) => acc + (parseInt(item.picked_quantity) || 0), 0) > 0) {
                                                        handleCreateShipment()
                                                    }
                                                }}
                                            >
                                                Create Shipment
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {
                                    picklistDetails?.shipments?.length > 0 && (
                                        <>
                                            <PicklistShipmentComponent 
                                                details={picklistDetails?.shipments}
                                                handleCancelShipment={handleCancelShipment}
                                                handleDeleteShipment={handleDeleteShipment}
                                            />
                                        </>
                                    )
                                }
                            
                                <div style={{ marginBottom: '8px'}}></div>
                            </div>
                        </div>
                        <div className="col-lg-3">

                            <Logs
                                logs={picklistDetails?.logs}
                            />
                        </div>

                    </div>
                </div>
            </div>
        

            {showDelete && (
                <DeleteConfirmation
                    show={showDelete}
                    handleClose={handleClose}
                    handleDelete={handleDeletePicklist}
                    title="Are you sure?"
                    message={deletingPicklistMessage}
                    customDeleteButtonText={'Yes, Delete this picklist'}
                />
            )}
            {showCancel && (
                <DeleteConfirmation
                    show={showCancel}
                    handleClose={handleClose}
                    handleDelete={handleCancelPicklist}
                    title="Are you sure?"
                    message={cancelingPicklistMessage}
                    customDeleteButtonText={'Yes, Cancel this picklist'}
                />
            )}

            {showScanModal && (
                <>
                    <PicklistSerialScanModal
                        openPopup={showScanModal}
                        details={picklistDetails}
                        selectedProduct={selectedProduct}
                        handleClose={handleClose}
                        handleReloadData={handleReloadData}
                        handleAddScannedSerials={handleAddScannedSerials}
                        // disableStatus={isDisable}
                    />
                </>
            )}
            {showCreateShipmentModal && (
                <>
                    <PicklistCreateShipmentModal
                        openPopup={showCreateShipmentModal}
                        details={shipmentForm}
                        handleClose={handleClose}
                        handleReloadData={handleReloadData}
                        // disableStatus={isDisable}
                    />
                </>
            )}
            {/* {openReportPopup && (
                <>
                    <ReturnsReportPopupModal
                        openPopup={openReportPopup}
                        // returnsDetail={returnsDetail}
                        // handleReplacementImeis={handleReplacementImeis}
                        handleClose={closePopup}
                        // handleSave={handleSave}
                        disableStatus={isDisable}
                    />
                </>
            )} */}
        </>
    );
};

export default PicklistDetails;
