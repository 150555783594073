import React, { useState, useEffect, useRef } from 'react';
import Scroller from 'ui-component/Scroller';
import CreatableSelect from 'react-select/creatable';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPlus, FaSearch } from 'react-icons/fa';
import StatusBadge from 'ui-component/StatusBadge';
import { TbDatabaseExclamation } from 'react-icons/tb';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import { IdentifyIcon, RepairsIcon } from 'ui-component/custom-icons/icons';
import RepairModal from '../modals/RepairModal';
import GradingModal from '../modals/GradingModal';
import { toast } from 'react-hot-toast';
import { Checkbox, Tooltip } from '@mui/material';
import ProductDetailModal from '../modals/ProductDetailModal';


const QualityControlList = ({ operationDetails, handleReloadData, handleShowCheckboxAction, handleOpenGradingModal, handleCloseGradingModal }) => {

    const topSection = useRef(null);
    const [gradingModalStatus, setGradingModalStatus] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showRepairPopup, setShowRepairPopup] = useState(false);
    const [showDeleteRepairPopup, setShowDeleteShowRepairPopup] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const [inboundList, setInboundList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredInboundList, setFilteredInboundList] = useState([]);
    const [productIndexRepair, setProductIndexRepair] = useState(null);
    const [deleteIndexRepair, setDeleteIndexRepair] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedRepair, setSelectedRepair] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [openGradingPopup, setOpenGradingPopup] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [disableGrading, setDisableGrading] = useState(true);

    useEffect(() => {
        updateInboundList();
        
    }, [operationDetails]);

    useEffect(() => {
        console.log('dito yan', handleOpenGradingModal)
        setGradingModalStatus(handleOpenGradingModal)
        
    }, [handleOpenGradingModal]);
    
    const updateInboundList = () => {

        // Construct a new summary details array from operationDetails.products
        const collection = operationDetails?.products?.map(product => ({
            id: product?.id,
            oldGradeId: product?.old_grade_id,
            oldGradeName: product?.old_grade_name,
            newGradeId: product?.new_grade_id,
            newGradeName: product?.new_grade_name,
            colorId: product?.color_id,
            colorName: product?.color_name,
            imei: product?.imei,
            productId: product?.product_id,
            productName: product?.product_name,
            quantity: product?.quantity,
            repairs: product?.repairs,
            isChecked: false,
            status: product?.status,
            comment: product?.comment,
            hasComment: product?.has_comment,
            showComment: false
        })) || [];
    
        // Update the summaryDetails state once with the new array
        setInboundList(collection);
    }

    const removeProductFromList = (index) => {
        setShowDelete(true);
        setSelectedProductIndex(index)
    }

    const handleClose = () => {
        setShowDelete(false);
        setShowRepairPopup(false);
        setOpenGradingPopup(false);
        setShowDetails(false);
        handleCloseGradingModal(true)
    }
    const handleCloseDeleteRepair = () => {
        setShowDeleteShowRepairPopup(false)
    }


    // const handleDelete = () => {
    //     handleRemoveProductFromList(selectedProductIndex);
    //     setShowDelete(false);
    //     setSelectedProductIndex(null);
    // }

    const handleShowDetails = (item) => {
        setShowDetails(true);
        setSelectedItem(item);
    }

    const handleSearch = (value) => {
        if (value.length >= 3) {

            const filtered = inboundList.filter(item => 
                (item.imei && item.imei.includes(value)) || 
                (item.productName && item.productName.toLowerCase().includes(value.toLowerCase()))
            );
            setInboundList(filtered);
        } else {
            updateInboundList();
        }
    }

    const addRepair = (index, item) => {
        setShowRepairPopup(true);
        setProductIndexRepair(index)
        setSelectedItem(item);
    }

    const handleAddRepairs = (repairs) => {
        toast.promise(
            api
                .createOperationItemRepair(operationDetails?.id, {
                    operation_item_id: repairs?.operationItemId,
                    assigned_id: repairs?.assignedId,
                    cost: repairs?.repairCost,
                    currency: repairs?.repairCurrency,
                    repair_type_id: repairs?.repairTypeId,
                })
                .then(
                    (res) => {
                        if (res.status) {
                            handleReloadData()
                        }

                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Adding repair...',
                success: (data) => `${data.message}`,
                error: <b>Failed to add repair</b>
            }
        );
    }
    
    const handleDeleteRepair = (index, repair, details) => {
        setShowDeleteShowRepairPopup(true);
        setDeleteIndexRepair(index)
        setSelectedRepair(repair);
        setSelectedProduct(details)
    
}
    const deleteRepair = () => {
        toast.promise(
            api
                .deleteOperationItemRepair(selectedRepair?.id)
                .then(
                    (res) => {
                        if (res.status) {
                            handleReloadData()
                            setShowDeleteShowRepairPopup(false)
                            setDeleteIndexRepair(null)
                            setSelectedRepair(null)
                            setSelectedProduct(null)

                            const updatedRepairs = selectedProduct?.repairs.filter(
                                (r) => r.id !== selectedRepair?.id
                            );
                    
                            setSelectedItem((prevProduct) => ({
                                ...prevProduct,
                                repairs: updatedRepairs,
                            }));
                            // setSelectedItem(item);
                        }

                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Deleting repair...',
                success: (data) => `${data.message}`,
                error: <b>Failed to remove repair</b>
            }
        );
    }

    const handleCheckAllItems = () => {
        const newCheckedState = !allChecked;
        setAllChecked(newCheckedState);
        // setCheckedItems(checkedItems.map(item => ({ ...item, checked: newCheckedState })));
        setInboundList(inboundList.map(item => ({ ...item, isChecked: newCheckedState })));
    }

    const handleItemCheck = (index) => {
        // setHasCheckedItems
        const newList = [...inboundList];
        newList[index].isChecked = !newList[index].isChecked;
        setInboundList(newList);
    };


    const areAnyItemsChecked = () => {
        const bool = inboundList.some(item => item.isChecked);
        
        handleShowCheckboxAction(bool)
        return bool;
    };
    
    const handleShowOpenGradingPopup = () => {

        if (areAnyItemsChecked()) {
            // Show the grading popup
            setOpenGradingPopup(true);
        } else {
            // Show an error message or handle the case where no checkboxes are checked
            toast.error('Please select at least one item to proceed');
        }
    }

    const handleProcessGrading = (items) => {
        toast.promise(
            api
                .setOperationItemGrade(operationDetails?.id, items)
                .then(
                    (res) => {
                        if (res.status) {
                            handleReloadData()
                            // handleClose();

                            handleCloseGradingModal(true)
                        }

                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Adding repair...',
                success: (data) => `${data.message}`,
                error: <b>Failed to add repair</b>
            }
        );
    }

    // const handleOpenGradingModal = (bool) => {
    //     if (handleOpenGradingModal) {
    //         setOpenGradingPopup(true)
    //     }
    // }

    return (
        <>
            
            <div >
                <div className="bg-white pt-0">
                    <div className="d-flex align-items-center justify-content-between TableSerachBar" style={{ marginTop: '10px', marginBottom: '-10px' }}>
                        <div>
                            <h5 >Products</h5>
                        </div>
                        
                    </div>
                    <div className="d-flex align-items-center justify-content-between TableSerachBar">
{/*                         
                        <PermissionAllowed permissions={['Operations Item Grading']}>
                            <Tooltip title={'Identify Grading'} arrow placement="top">
                                <button
                                    style={{ marginBottom: '-5px' }}
                                    className="btn btn-primary btn-outlined-main white-var btn-icon-only btn-icon-square"
                                    onClick={() => {
                                        handleShowOpenGradingPopup()
                                        // setOpenGradingPopup(true)
                                    }}
                                    disabled={!areAnyItemsChecked()}
                                >
                                    <IdentifyIcon width={'16px'} height={'16px'} />
                                </button>
                            </Tooltip>
                        </PermissionAllowed> */}

                        <form className="form-inline navbar-search" style={{ position: 'absolute', right: '15px' }}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control bg-white border"
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                        handleSearch(e.target.value)
                                    }}
                                    // value={searchField}
                                    style={{ width: '100%' }}
                                />
                                <span className="searchIcon">
                                    <FaSearch />
                                </span>
                            </div>
                        </form>
                    </div>

                    <Scroller extraHeight={topSection?.current?.clientHeight || 0}>
                        <table className="table pm-table">
                            <thead>
                                <tr>
                                    {
                                        operationDetails?.isCompleted !== 1 && (
                                            <>
                                                <PermissionAllowed permissions={['Operations Item Grading']}>
                                                    <th scope="col" style={{ width: '30px' }}>
                                                        <Checkbox
                                                            style={{ padding: '5px', paddingLeft: 0 }}
                                                            onChange={() => {
                                                                handleCheckAllItems()
                                                            }}
                                                        />
                                                    </th>
                                                </PermissionAllowed>
                                            </>
                                        )
                                    }
                                    <th scope="col" style={{ width: '160px' }}>IMEI</th>
                                    <th scope="col">Product</th>
                                    <th scope="col" style={{ width: '140px' }}>Grade</th>
                                    <th scope="col" style={{ width: '100px' }}>Status</th>
                                    <th scope="col" style={{ width: '60px' }}>Repairs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    inboundList.length > 0 ? (
                                        <>
                                            {
                                                inboundList?.map((item, index) => (
                                                    <>
                                                        <tr key={index} className="hover-row">
                                                            {
                                                                operationDetails?.isCompleted !== 1 && (
                                                                    <>
                                                                        <PermissionAllowed permissions={['Operations Item Grading']}>
                                                                            <td className="align-middle">
                                                                                
                                                                                <Checkbox
                                                                                    style={{ padding: '5px', paddingLeft: 0 }}
                                                                                    checked={item.isChecked || false}
                                                                                    onChange={() => handleItemCheck(index)}
                                                                                />
                                                                            </td>
                                                                        </PermissionAllowed>
                                                                    </>
                                                                )
                                                            }
                                                            <td
                                                                className="align-middle cursor-pointer"
                                                                onClick={() => {
                                                                    handleShowDetails(item)
                                                                }}
                                                            >
                                                                {
                                                                    item.imei ? (
                                                                        <>
                                                                            <span className="font500">{item?.imei}</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="font500 textGray">No IMEI</span>
                                                                        </>
                                                                    )
                                                                }
                                                            </td>
                                                            <td
                                                                className="align-middle cursor-pointer"
                                                                onClick={() => {
                                                                    handleShowDetails(item)
                                                                }}
                                                            >
                                                                <div>{item.productName}</div>
                                                                <div>
                                                                    {
                                                                        item.colorName ? (
                                                                            <>
                                                                                <span className="font500 textGray">{item?.colorName}</span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className="font500 textGray">No Color</span>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td
                                                                className="align-middle cursor-pointer"
                                                                onClick={() => {
                                                                    handleShowDetails(item)
                                                                }}
                                                            >
                                                                {item?.oldGradeName}
                                                            </td>
                                                            
                                                            <td
                                                                className="align-middle cursor-pointer"
                                                                onClick={() => {
                                                                    handleShowDetails(item)
                                                                }}
                                                            >
                                                                <StatusBadge itemStatus={item?.status} />
                                                            </td>
                                                            {/* <td className="align-middle">{item?.proceedureName}</td> */}
                                                            
                                                            <td
                                                                className="align-middle cursor-pointer"
                                                                onClick={() => {
                                                                    handleShowDetails(item)
                                                                }}
                                                            >
                                                                { item?.repairs?.length }
                                                            </td>
                                                            <PermissionAllowed permissions={['Add Operations Repairs']}>
                                                                <td className="hover-button-container">
                                                                    <div className="hover-button">
                                                                        
                                                                        <div
                                                                            className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
                                                                            onClick={() => addRepair(index, item)}
                                                                        >
                                                                            <RepairsIcon height={'18px'} width={'18px'} />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </PermissionAllowed>
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                            </>
                                        ) : (
                                            <>
                                                <tr>
                                                    <td colSpan={6}>
                                                        <div className="not-found-container cstm-not-found" style={{ height: '385px' }}>
                                                            <div className="not-found-icon">
                                                                <TbDatabaseExclamation />
                                                            </div>
                                                            <h6 className="not-found-text">No Products Inbounded</h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                            </tbody>
                        </table>

                    </Scroller>
                    <PermissionAllowed permissions={['Operations Item Grading']}>
                        <div className="row d-none" style={{ marginBottom: '10px' }}>
                            <div className="col-1">
                                <div className="d-flex justify-content-start">
                                    <Tooltip title={'Identify Grading'} arrow placement="top">
                                        <button
                                            style={{ marginBottom: '-5px' }}
                                            className="btn btn-primary btn-outlined-main white-var btn-icon-only btn-icon-square"
                                            onClick={() => {
                                                handleShowOpenGradingPopup()
                                                // setOpenGradingPopup(true)
                                            }}
                                            disabled={!areAnyItemsChecked()}
                                        >
                                            <IdentifyIcon width={'16px'} height={'16px'} />
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </PermissionAllowed>
                </div>
            </div>


            {showRepairPopup && (
                <RepairModal
                    openPopup={showRepairPopup}
                    selectedItem={selectedItem}
                    index={productIndexRepair}
                    handleClose={handleClose}
                    handleAddRepairs={handleAddRepairs}
                />
            )}
            {gradingModalStatus && (
                <GradingModal
                    openPopup={gradingModalStatus}
                    details={inboundList}
                    handleClose={handleClose}
                    handleProcessGrading={handleProcessGrading}
                />
            )}
            {showDetails && (
                <ProductDetailModal
                    openPopup={showDetails}
                    details={selectedItem}
                    handleClose={handleClose}
                    handleDeleteRepair={handleDeleteRepair}
                />
            )}
			{showDeleteRepairPopup && (
				<DeleteConfirmation
					show={showDeleteRepairPopup}
					handleClose={handleCloseDeleteRepair}
					handleDelete={deleteRepair}
					title="Confirm"
					message={`Are you sure you want to delete ${selectedRepair?.repair_type?.name} from the repair list?`}
				/>
			)}

        </>
    );
};

export default QualityControlList;
