import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import StatusBadge from 'ui-component/StatusBadge';
import { FaPlus, FaTimes } from 'react-icons/fa';

const InvoiceModal = ({ show, handleClose, selectedIdDetails }) => {
	const [data, setData] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1026);
	const windowWidth = window.innerWidth;

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1026);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (selectedIdDetails) {
			// Only run this code if selectedIdDetails is not undefined
			api.getPurchasingDetails(selectedIdDetails).then((res) => {
				setData(res.data.purchase_list);
			});
		}
	}, [selectedIdDetails]);

	if (isMobile) {
		return (
			<Modal show={show} onHide={handleClose} size="md">
				<div className="custom-modal-content" style={{ padding: 20 }}>
					<h4 style={{ marginBottom: 20, fontWeight: 600 }}>Detailed Status</h4>
					<button className="btn modal-close-icon" onClick={handleClose}>
						<svg
							id="Component_127_1"
							height="16"
							width="16"
							data-name="Component 127 – 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 19.295 19.289"
						>
							<path
								id="Icon_ionic-ios-close"
								data-name="Icon ionic-ios-close"
								d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
								transform="translate(-11.285 -11.289)"
							/>
						</svg>
					</button>
					<div className="row" style={{ marginBottom: 10, borderBottom: '1px solid #ededed', paddingBottom: 10 }}>
						<div className="col-2">
							<a style={{ fontWeight: 500 }}>Qty</a>
						</div>
						<div className="col-7">
							<a style={{ fontWeight: 500 }}>Product</a>
						</div>
						<div className="col-3" style={{ paddingLeft: 0 }}>
							<a style={{ fontWeight: 500 }}>Status</a>
						</div>
					</div>
					{data.map((item, index) => (
						<div className="row" key={index} style={{ marginBottom: 10 }}>
							<div className="col-2">
								<a>{item.product.qty}</a>
							</div>
							<div className="col-7">
								<a>{item.product.name}</a>
								<br />
								<a style={{ fontSize: 12, color: '#777' }}>{item.product.sku}</a>
							</div>
							<div className="col-3" style={{ paddingLeft: 0 }}>
								<StatusBadge
									itemStatus={
										item?.product.status === 'Ready To Ship'
											? 'Ready To Ship'
											: item?.product.status === 'Sending to Transit'
											? 'For Transit'
											: item?.product.status === 'Shipped'
											? 'Shipped'
											: 'Processing'
									}
								/>
							</div>
						</div>
					))}
				</div>
			</Modal>
		);
	}

	return (
		<Modal show={show} onHide={handleClose} dialogClassName="custom-modal-2" style={{ marginTop: '35px' }}>
			<div className="custom-modal-content" style={{ padding: 20 }}>
				<h4 style={{ marginBottom: 20, fontWeight: 600 }}>Detailed Status</h4>
				<button className="btn modal-close-icon" onClick={handleClose}>
					<svg
						id="Component_127_1"
						height="16"
						width="16"
						data-name="Component 127 – 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 19.295 19.289"
					>
						<path
							id="Icon_ionic-ios-close"
							data-name="Icon ionic-ios-close"
							d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
							transform="translate(-11.285 -11.289)"
						/>
					</svg>
				</button>
				<div style={{ fontSize: 16, fontWeight: 600, marginBottom: 20 }}></div>
				<table className="borderless-table borderless-table-custom">
					<thead style={{ border: '1px solid #f2f2f2' }}>
						<tr>
							<th>SKU</th>
							<th>Name</th>
							<th style={{ textAlign: 'center' }}>Quantity</th>
							<th style={{ textAlign: 'center' }}>Price</th>
							<th style={{ textAlign: 'center' }}>Status</th>
						</tr>
					</thead>
					<tbody>
						{data.map((item, index) => (
							<tr key={index} style={{ borderBottom: '1px solid #f2f2f2' }}>
								<td>{item.product.sku}</td>
								<td>{item.product.name}</td>
								<td style={{ textAlign: 'center' }}>{item.product.qty}</td>
								<td style={{ textAlign: 'center' }}>€ {item.product.price}</td>
								<td style={{ textAlign: 'center', width: '200px' }}>
									<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
										<div style={{ width: '60%' }}>
											<StatusBadge
												itemStatus={
													item?.product.status === 'Ready To Ship'
														? 'Ready To Ship'
														: item?.product.status === 'Sending to Transit'
														? 'For Transit'
														: item?.product.status === 'Shipped'
														? 'Shipped'
														: 'Processing'
												}
											/>
										</div>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Modal>
	);
};

export default InvoiceModal;
