import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { FaPlus, FaTimes, FaMinus } from 'react-icons/fa';

const ChangeStockModal = ({ openChangestockPopup, productDetails, handleClose, handleSave }) => {
	const [stock, setStock] = useState('');
	const [disable, setDisable] = useState(true);
	const [reason, setReason] = useState(false);
	const [reasonValue, setReasonValue] = useState(null);

	const [warehouse, setWarehouse] = useState([]);
	useEffect(() => {
		if (stock > 0) {
			setDisable(false);
		}
	}, [stock]);

	const handleValue = (val) => {
		setFormInfo(val);
	};
	const handleIncrease = () => {
		const currentStock = (stock == '') ? 0 : stock;
		setStock(parseInt(currentStock) + 1);
	};
	const handleDecrease = () => {
		if (stock > 0) {
			setStock(stock - 1);
		}
	};
	const updaterowCounts = (value) => {
		const intValue = parseInt(value) || 0;
		setStock(intValue);
	};

	useEffect(() => {
		api.getWarehouseProductWise(productDetails?.id).then((res) => {
			console.log(res.data);
			setWarehouse(res?.data);
		});
	}, []);

	useEffect(() => { }, [openChangestockPopup]);
	const onSubmit = () => {
		console.log('submit');
		const data = {
			stock_qty: stock + warehouse?.stock_qty,
			warehouse_id: 1,
			product_id: productDetails?.id,
			reason: reasonValue
		};
		handleSave(data);
	};

	const addreason = () => {
		setReason(true);
	};
	const disablepopup = () => {
		handleClose();
	};
	return (
		<>
			<Modal show={openChangestockPopup} onHide={disablepopup} size="md">
				<Modal.Body>
					<div className="p-2">
						<div className="mb-2 ml-0 pl-0">
							<div className="row">
								<div className="col-12 text-left">
									<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">Stock</h5>
								</div>
							</div>
							<button className="btn modal-close-icon" onClick={handleClose}>
								{/* <FaTimes /> */}
								<svg
									id="Component_127_1"
									height="16"
									width="16"
									data-name="Component 127 – 1"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 19.295 19.289"
								>
									<path
										id="Icon_ionic-ios-close"
										data-name="Icon ionic-ios-close"
										d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
										transform="translate(-11.285 -11.289)"
									/>
								</svg>
							</button>
						</div>
						<div className="foundListPopup">
							<div className="row align-items-top">
								<div className="col-12">
									<div className="row">
										<div className="form-group col-md-12">
											<label className="">Location</label>
											<div className="location">
												<div className="main-location">{warehouse?.warehouse?.name}</div>
												<div className="reserved">
													<label htmlFor="">{warehouse?.stock_qty} pieces</label>
													<label htmlFor="" className="reserved-amount">
														{warehouse?.reserved_qty} reserved
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-12">
											<label className="">Change Stock</label>
											<div className="input-group input-group-lg input-group-plus-minus">
												<span className="input-group-btn" style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
													<button className="btn btn-default btn-sm" style={{ marginTop: '3px' }} onClick={() => handleDecrease()}>
														<FaMinus />
													</button>
												</span>

												<input
													type="number"
													value={stock}
													className="form-cntrl text-center"
													style={{ width: '84.8%', height: '40px' }}
													onChange={(e) => {
														updaterowCounts(e.target.value)
													}}
													onFocus={(event) => {
														if (stock == '0.00' || stock == '0') {

															updaterowCounts('')
														}
														event.target.select();
													}}
												/>

												<span className="input-group-btn" style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
													<button className="btn btn-default btn-sm" style={{ marginTop: '3px' }} onClick={() => handleIncrease()}>
														<FaPlus />
													</button>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="btnarea">
							<button disabled={disable} onClick={onSubmit} className="btn btn-main">
								{stock < 1 ? (
									'No Stock Change'
								) : (
									<>
										Save new stock level of <span>{stock + warehouse?.stock_qty}</span> pieces
									</>
								)}
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ChangeStockModal;
