import { useRef, useState } from 'react';
import { motion } from 'framer-motion';

const CustomGrowInput = ({
	onEnter,
	placeholder = '',
	className,
	value = '',
	onChange,
	readOnly = false,
	readOnlyAuto = false,
	onClick,
	multiLine = false,
	autoFocus = false,
	onFocus,
	onBlur
}) => {
	const [tempValue, setTempValue] = useState(value);
	const inputRef = useRef();

	const adjustHeight = () => {
		if (inputRef.current && multiLine) {
			inputRef.current.style.height = 'auto';
			inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
		}
	};

	const handleInput = (e) => {
		onChange ? onChange(e.target.value) : setTempValue(e.target.value);
		adjustHeight();
	};

	return (
		<motion.div
			layout
			className="task-title-input-container"
			onClick={() => {
				onClick && onClick();
			}}
		>
			{placeholder && !value && !tempValue && <span className="placeholder description-input-placeholder">{placeholder}</span>}
			{multiLine ? (
				<textarea
					ref={inputRef}
					className={`task-title-input ${className}`}
					value={value}
					onChange={handleInput}
					readOnly={readOnlyAuto || readOnly}
					autoFocus={autoFocus}
					onFocus={() => {
						onFocus && onFocus();
					}}
					onBlur={() => {
						onBlur && onBlur();
					}}
					style={{ overflow: 'hidden', resize: 'none', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
				/>
			) : (
				<input
					ref={inputRef}
					className={`task-title-input ${className}`}
					value={value}
					onChange={handleInput}
					readOnly={readOnlyAuto || readOnly}
					autoFocus={autoFocus}
					onFocus={() => {
						onFocus && onFocus();
					}}
					onBlur={() => {
						onBlur && onBlur();
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							inputRef.current.blur();
							console.log('onEnter prop:', onEnter);
							console.log('Before onEnter');
							onEnter && onEnter();
						}
					}}
				/>
			)}
		</motion.div>
	);
};

export default CustomGrowInput;
