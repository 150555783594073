import React from 'react';
import './processindicator.scss';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

function ProcessIndicatorMobile({ processes, isInProgress, currentIndex = 0, estimate }) {
	const clientUser = useSelector((state) => state.main.client);
	const statuses = ['Submitted', 'Accepted', 'Processing', 'Shipped'];
	const statusMessage = [
		'Your order has been submitted',
		'Your order has been accepted',
		'Your order is now being processed. To see detailed status of your order kindly go to Items tab. Updates regarding your order will be seen through the tab.',
		'Your order has been shipped'
	];

	const dates = [estimate.estimation_date, estimate.accepted_on];

	console.log(estimate);

	return (
		<div className="d-flex justify-content-start align-items-start process-status-indicator-container-client" style={{ padding: 5 }}>
			<div className="process-status-indicator-mobile" style={{ flexDirection: 'column' }}>
				{processes.map((item, index) => {
					return (
						<>
							<div
								className={`process ${index < currentIndex ? 'completed' : index == currentIndex ? 'current' : ''} ${
									index == currentIndex && isInProgress ? 'inprogress' : ''
								}`}
							>
								<div className="indicator-check">
									<span className="check-icon">
										{index < currentIndex ? (
											<>
												<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
													<g id="Group_38936" data-name="Group 38936" transform="translate(-540 -118)">
														<g
															id="Ellipse_39"
															data-name="Ellipse 39"
															transform="translate(540 118)"
															fill="#fff"
															stroke="#34C880"
															stroke-width="2"
														>
															<circle cx="13" cy="13" r="13" stroke="none" />
															<circle cx="13" cy="13" r="12" fill="none" />
														</g>
														<path
															id="Icon_metro-spell-check"
															data-name="Icon metro-spell-check"
															d="M23.948,19.28,13.812,30.2,8.355,23.179h0l5.458,2.868L23.948,19.28Z"
															transform="matrix(0.995, -0.105, 0.105, 0.995, 534.352, 108.21)"
															fill="#34C880"
														/>
													</g>
												</svg>
											</>
										) : index == currentIndex ? (
											<>
												{clientUser?.data?.is_approved === 2 ? (
													<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
														<g id="Group_38938" data-name="Group 38938" transform="translate(-694 -118)">
															<g
																id="Ellipse_38"
																data-name="Ellipse 38"
																transform="translate(694 118)"
																fill="#fff"
																stroke="#34C880"
																stroke-width="2"
															>
																<circle cx="13" cy="13" r="13" stroke="none" />
																<circle cx="13" cy="13" r="12" fill="none" />
															</g>
															<path
																id="Icon_metro-spell-check"
																data-name="Icon metro-spell-check"
																d="M23.948,19.28,13.812,30.2,8.355,23.179h0l5.458,2.868L23.948,19.28Z"
																transform="matrix(0.995, -0.105, 0.105, 0.995, 688.352, 108.21)"
																fill="#e2e2e2" //current
															/>
														</g>
													</svg>
												) : (
													<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
														<g id="Group_38939" data-name="Group 38939" transform="translate(-694 -118)">
															<g
																id="Ellipse_38"
																data-name="Ellipse 38"
																transform="translate(694 118)"
																fill="#fff"
																stroke="#e2e2e2"
																stroke-width="2"
															>
																<circle cx="13" cy="13" r="13" stroke="none" />
																<circle cx="13" cy="13" r="12" fill="none" />
															</g>
															<path
																id="Icon_metro-spell-check"
																data-name="Icon metro-spell-check"
																d="M23.948,19.28,13.812,30.2,8.355,23.179h0l5.458,2.868L23.948,19.28Z"
																transform="matrix(0.995, -0.105, 0.105, 0.995, 688.352, 108.21)"
																fill="#e2e2e2"
															/>
														</g>
													</svg>
												)}
											</>
										) : (
											<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
												<g id="Group_38939" data-name="Group 38939" transform="translate(-694 -118)">
													<g
														id="Ellipse_38"
														data-name="Ellipse 38"
														transform="translate(694 118)"
														fill="#fff"
														stroke="#e2e2e2"
														stroke-width="2"
													>
														<circle cx="13" cy="13" r="13" stroke="none" />
														<circle cx="13" cy="13" r="12" fill="none" />
													</g>
													<path
														id="Icon_metro-spell-check"
														data-name="Icon metro-spell-check"
														d="M23.948,19.28,13.812,30.2,8.355,23.179h0l5.458,2.868L23.948,19.28Z"
														transform="matrix(0.995, -0.105, 0.105, 0.995, 688.352, 108.21)"
														fill="#e2e2e2"
													/>
												</g>
											</svg>
										)}
									</span>
								</div>
								<div className="d-flex flex-column">
									<a style={{ fontWeight: 500, color: index <= currentIndex ? '#34C880' : 'e2e2e2' }}>
										{statuses[index]} {dates[index] && <span style={{ fontWeight: 400, color: '#707070' }}>on {dates[index]}</span>}
									</a>
								</div>
								<span className="name-indicator">{item.name}</span>
							</div>
							<div className={`process-detail ${index < currentIndex ? 'completed' : ''}`}>
								{processes?.length - 1 != index && (
									<div className={`process-connector-line ${index < currentIndex ? 'completed' : ''}`}>&nbsp;</div>
								)}
								{index == currentIndex && <a style={{ fontWeight: 400 }}>{statusMessage[index]}</a>}
							</div>
						</>
					);
				})}
			</div>

			{/* <div className="row" style={{ paddingTop: 3 }}>
				{statuses.map((status, index) => (
					<div
						key={index}
						className="col-12 d-flex align-items-left  flex-column"
						style={{ height: 26, marginBottom: index == currentIndex ? 99 : 17 }}
					>
						<span style={{ fontWeight: 500, color: index <= currentIndex ? '#34C880' : '#e2e2e2', marginBottom: 10 }}>
							{status} {index <= currentIndex && <span style={{ color: '#707070', fontWeight: 400 }}>on 20 Jun, 2024</span>}
						</span>
						{index == currentIndex && <div style={{ color: '#707070' }}>{statusMessage[index]}</div>}
					</div>
				))}
			</div> */}
		</div>
	);
}

export default ProcessIndicatorMobile;
