import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';

const WarehouseModal = ({ openWarehousePopup, productDetails, handleClose, handleSave, disableStatus }) => {

	const [alertMessage, setAlertMessage] = useState();
	const [warehouseList, setWarehouseList] = useState([]);

	const [formInfo, setFormInfo] = useState({
		warehouseId: '',
		productId: '',
	});
	const handleValue = (val) => {
		setFormInfo(val);
	};

	useEffect(() => {
		api.getUnassignedWarehouseList(productDetails?.id).then((res) => {
			const tempWarehouse = res.data.map((item) => ({
				id: item.id,
				label: `${item.name}`,
				value: item.id
			}));
			setWarehouseList(tempWarehouse);
		});
		setFormInfo({
			...formInfo,
			productId: productDetails?.id
		});
	}, []);

	useEffect(() => {
	}, [openWarehousePopup]);
	const onSubmit = () => {
		if (formInfo.warehouseId == '') {
			setAlertMessage({
				user: 'Please select warehouse location.'
			});
		} else {
			const data = {
				...formInfo,
				warehouseId: formInfo.warehouseId
			};
			handleSave(data);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const disablepopup = () => {
		handleClose();
	};
	return (
		<>
			<Modal show={openWarehousePopup} onHide={disablepopup} centered size="sm">
				<Modal.Body>
					<div className="p-2">
						<div className="mb-2 ml-0 pl-0">

							<div className="row">
								<div className="col-12 text-left" style={{ fontSize: '18px', fontWeight: '500' }}>
									Add Warehouse Location
									<br />
								</div>
							</div>
							<button className="btn modal-close-icon" onClick={handleClose}>
								{/* <FaTimes /> */}
								<svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
									<path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
								</svg>


							</button>
						</div>
						<div className="foundListPopup">
							<div className="row align-items-top">
								<div className="col-12">
									<form className="assign found">
										<div className="row">
											<div className="form-group col-md-12">
												<label className="">Warehouse</label>

												<Select
													className="rs-container"
													classNamePrefix="rs"
													isSearchable={true}
													name="user"
													options={warehouseList}
													placeholder="Select"
													onChange={(selected) =>
														handleValue({
															...formInfo,
															warehouseId: selected.id
														})
													}
												/>
												{alertMessage?.user && (
													<>
														<span className="error-message text-danger">{alertMessage?.user}</span>
													</>
												)}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="btnarea">
							<button disabled={disableStatus} onClick={onSubmit} className="btn btn-main">
								<FaPlus /> Add Warehouse
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default WarehouseModal;
