import React, { useEffect, useState, useRef } from 'react';
import './estimateInvoice.scss';
import { Link } from 'react-router-dom';
import mgLogo from 'assets/images/mglogo.svg';
import utLogo from 'assets/images/used-trading-logo.png';
import glpLogo from 'assets/images/glplogo.png';
import cmLogo from 'assets/images/cmlogo.svg';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import { Skeleton } from '@mui/material';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import config from '../../config';
import NoImage from 'assets/images/no-image.png';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import ProductCommentModal from './modals/ProductCommentModal';

function EstimateDetailInvoice({ estimate }) {
	const [settingsDetails, setSettingsDetails] = useState();
	const [productComment, setProductComment] = useState('');
	const [productName, setProductName] = useState('');
	const [productImage, setProductImage] = useState('');
	const [commentModal, setCommentModal] = useState(false);

	const totalQty = estimate?.estimation_details?.reduce((total, detail) => total + (detail?.qty || 0), 0);

	const handleCommentModal = (product, image, comment) => {
		setProductComment(comment);
		setProductName(product);
		setProductImage(image);
		setCommentModal(true);
	};
	const handleCloseComment = () => {
		setProductComment('');
		setProductName('');
		setProductImage('');
		setCommentModal(false);
	};

	useEffect(() => {
		api.getSettings().then((res) => {
			setSettingsDetails(res?.data);
		});
	}, []);
	return (
		<>
			<div className="est-inv-container" style={{ padding: '15px', maxWidth: '700px' }}>
				<div className="row m-0">
					{estimate ? (
						<>
							{estimate?.transit_company?.name?.toLowerCase() == 'coolmix' ? (
								<>
									<div className="col-8 p-0">
										<div className="est-detail-logo" style={{ height: '36px' }}>
											<img src={cmLogo} height={'36px'} />
										</div>

										<div className="customer-details" style={{ marginTop: '20px' }}>
											<p className="m-0">
												<b>
													<PrivacyModeContent>{estimate?.customer?.name}</PrivacyModeContent>
												</b>{' '}
												<br />
												<PrivacyModeContent>{estimate?.customer?.address}</PrivacyModeContent> <br />
												<PrivacyModeContent>{estimate?.customer?.city + ', ' + estimate?.customer?.zipcode}</PrivacyModeContent> <br />
												<PrivacyModeContent>{estimate?.customer?.country}</PrivacyModeContent>
												{estimate?.customer?.shipping_address ? (
													<>
														<br />
														<br />
														<b>Ship To: </b>
														<br />
														<PrivacyModeContent>{estimate?.customer?.shipping_address}</PrivacyModeContent> <br />
														<PrivacyModeContent>
															{(estimate?.customer?.city == 'null' ? estimate?.customer?.city + ', ' : '') +
																estimate?.customer?.shipping_zipcode}
														</PrivacyModeContent>{' '}
														<br />
														<PrivacyModeContent>{estimate?.customer?.shipping_country}</PrivacyModeContent>
													</>
												) : (
													<></>
												)}
											</p>
										</div>
									</div>
									<div className="col-4 p-0">
										{/* <div className="company-details text-right">
											<p className="m-0">
												<b>Mix Parts B.V.</b> <br />
												Industrieweg 24 C <br />
												5262 GJ Vught <br />
												The Netherlands <br />
												info@coolmix.eu <br />
												www.coomix.eu
											</p>
										</div> */}
									</div>
								</>
							) : (
								<>
									{/* {config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.app_name == 'glpwireless' ? ( */}

									{estimate?.settings?.client_code == 'glpwireless' ? (
										<div className="col-8 p-0">
											<div className="est-detail-logo" style={{ height: '70px' }}>
												{/* {
													estimate?.master_status?.status_type_id == 2 ? (
														<>
															<h2>{estimate?.transit_company?.name ? estimate?.transit_company?.name : estimate?.customer?.name}</h2>
															
														</>
													) : (
														<><img src={glpLogo} height={'70px'} /></>
													)
												} */}
												<img src={glpLogo} height={'70px'} />
											</div>

											<div className="customer-details" style={{ marginTop: '20px' }}>
												{estimate?.master_status?.status_type_id == 2 ? (
													<>
														<p className="m-0">
															<b>
																<PrivacyModeContent>{estimate?.customer?.name}</PrivacyModeContent>
															</b>{' '}
															<br />
															<PrivacyModeContent>{estimate?.customer?.address}</PrivacyModeContent> <br />
															<PrivacyModeContent>
																{(estimate?.customer?.city == 'null' ? estimate?.customer?.city + ', ' : '') + estimate?.customer?.zipcode}
															</PrivacyModeContent>{' '}
															<br />
															<PrivacyModeContent>{estimate?.customer?.country}</PrivacyModeContent>
															{estimate?.customer?.shipping_address ? (
																<>
																	<br />
																	<br />
																	<b>Ship To: </b>
																	<br />
																	<PrivacyModeContent>{estimate?.customer?.shipping_address}</PrivacyModeContent> <br />
																	<PrivacyModeContent>
																		{estimate?.customer?.shipping_city + ', ' + estimate?.customer?.shipping_zipcode}
																	</PrivacyModeContent>{' '}
																	<br />
																	<PrivacyModeContent>{estimate?.customer?.shipping_country}</PrivacyModeContent>
																</>
															) : (
																<></>
															)}
														</p>
													</>
												) : (
													<>
														<p className="m-0">
															{estimate?.logistic?.name ? (
																<>
																	<b>
																		<PrivacyModeContent notUserRestricted>{estimate?.logistic?.name}</PrivacyModeContent>
																	</b>{' '}
																	<br />
																	<PrivacyModeContent notUserRestricted>{estimate?.logistic?.address}</PrivacyModeContent> <br />
																	{!!estimate?.logistic?.city ? (
																		<PrivacyModeContent notUserRestricted>
																			{`${estimate?.logistic?.city}${!!estimate?.logistic?.zipcode && ','} ${estimate?.logistic?.zipcode}`}
																		</PrivacyModeContent>
																	) : (
																		''
																	)}
																	<br />
																	<PrivacyModeContent notUserRestricted>{estimate?.logistic?.country}</PrivacyModeContent>
																	{estimate?.logistic?.shipping_address ? (
																		<>
																			<br />
																			<br />
																			<b>Ship To: </b>
																			<br />
																			<PrivacyModeContent notUserRestricted>{estimate?.logistic?.shipping_address}</PrivacyModeContent>{' '}
																			<br />
																			<PrivacyModeContent notUserRestricted>
																				{estimate?.logistic?.shipping_city + ', ' + estimate?.logistic?.shipping_zipcode}
																			</PrivacyModeContent>{' '}
																			<br />
																			<PrivacyModeContent notUserRestricted>{estimate?.logistic?.shipping_country}</PrivacyModeContent>
																		</>
																	) : (
																		<></>
																	)}
																</>
															) : estimate?.transit_company?.name ? (
																<>
																	<b>
																		<PrivacyModeContent notUserRestricted>{estimate?.transit_company?.name}</PrivacyModeContent>
																	</b>{' '}
																	<br />
																	<PrivacyModeContent notUserRestricted>{estimate?.transit_company?.address}</PrivacyModeContent> <br />
																	{!!estimate?.transit_company?.city ? (
																		<PrivacyModeContent notUserRestricted>
																			{`${estimate?.transit_company?.city}${!!estimate?.transit_company?.zipcode && ','} ${
																				estimate?.transit_company?.zipcode
																			}`}
																		</PrivacyModeContent>
																	) : (
																		''
																	)}
																	<br />
																	<PrivacyModeContent notUserRestricted>{estimate?.transit_company?.country}</PrivacyModeContent>
																	{estimate?.transit_company?.shipping_address ? (
																		<>
																			<br />
																			<br />
																			<b>Ship To: </b>
																			<br />
																			<PrivacyModeContent notUserRestricted>
																				{estimate?.transit_company?.shipping_address}
																			</PrivacyModeContent>{' '}
																			<br />
																			<PrivacyModeContent notUserRestricted>
																				{estimate?.transit_company?.shipping_city + ', ' + estimate?.transit_company?.shipping_zipcode}
																			</PrivacyModeContent>{' '}
																			<br />
																			<PrivacyModeContent notUserRestricted>
																				{estimate?.transit_company?.shipping_country}
																			</PrivacyModeContent>
																		</>
																	) : (
																		<></>
																	)}
																</>
															) : (
																<>
																	<b>
																		<PrivacyModeContent notUserRestricted>{estimate?.customer?.name}</PrivacyModeContent>
																	</b>{' '}
																	<br />
																	<PrivacyModeContent notUserRestricted>{estimate?.customer?.address}</PrivacyModeContent> <br />
																	{!!estimate?.customer?.city ? (
																		<PrivacyModeContent notUserRestricted>
																			{`${estimate?.customer?.city}${!!estimate?.customer?.zipcode && ','} ${estimate?.customer?.zipcode}`}
																		</PrivacyModeContent>
																	) : (
																		''
																	)}
																	<br />
																	<PrivacyModeContent notUserRestricted>{estimate?.customer?.country}</PrivacyModeContent>
																	{estimate?.customer?.shipping_address ? (
																		<>
																			<br />
																			<br />
																			<b>Ship To: </b>
																			<br />
																			<PrivacyModeContent notUserRestricted>{estimate?.customer?.shipping_address}</PrivacyModeContent>{' '}
																			<br />
																			<PrivacyModeContent notUserRestricted>
																				{estimate?.customer?.shipping_city + ', ' + estimate?.customer?.shipping_zipcode}
																			</PrivacyModeContent>{' '}
																			<br />
																			<PrivacyModeContent notUserRestricted>{estimate?.customer?.shipping_country}</PrivacyModeContent>
																		</>
																	) : (
																		<></>
																	)}
																</>
															)}
														</p>
													</>
												)}
											</div>
										</div>
									) : (
										<div className="col-8 p-0">
											<div className="est-detail-logo" style={{ height: '36px' }}>
												{settingsDetails?.logo_url ? (
													<img src={settingsDetails?.logo_url} height={'36px'} />
												) : (
													<h3>{settingsDetails?.client_full_name}</h3>
												)}
												{/* {
													settingsDetails?.client_code == 'usedtrading' ? (
														<>
															<img src={utLogo} height={'36px'} />
														</>
													) : (
														<>
															<img src={mgLogo} height={'36px'} />
														</>
													)
												} */}
											</div>

											<div className="customer-details" style={{ marginTop: '20px' }}>
												{estimate?.transit_company?.name ? (
													<>
														<p className="m-0">
															<b>
																<PrivacyModeContent>{estimate?.transit_company?.name}</PrivacyModeContent>
															</b>{' '}
															<br />
															<PrivacyModeContent>{estimate?.transit_company?.address}</PrivacyModeContent> <br />
															<PrivacyModeContent>
																{(estimate?.transit_company?.city == 'null' ? estimate?.transit_company?.city + ', ' : '') +
																	estimate?.transit_company?.zipcode}
															</PrivacyModeContent>{' '}
															<br />
															<PrivacyModeContent>{estimate?.transit_company?.country}</PrivacyModeContent>
															{estimate?.transit_company?.shipping_address ? (
																<>
																	<br />
																	<br />
																	<b>Ship To: </b>
																	<br />
																	<PrivacyModeContent>{estimate?.transit_company?.shipping_address}</PrivacyModeContent> <br />
																	<PrivacyModeContent>
																		{estimate?.transit_company?.shipping_city + ', ' + estimate?.transit_company?.shipping_zipcode}
																	</PrivacyModeContent>{' '}
																	<br />
																	<PrivacyModeContent>{estimate?.transit_company?.shipping_country}</PrivacyModeContent>
																</>
															) : (
																<></>
															)}
														</p>
													</>
												) : (
													<>
														<p className="m-0">
															<b>
																<PrivacyModeContent>{estimate?.customer?.name}</PrivacyModeContent>
															</b>{' '}
															<br />
															<PrivacyModeContent>{estimate?.customer?.address}</PrivacyModeContent> <br />
															<PrivacyModeContent>
																{(estimate?.customer?.city == 'null' ? estimate?.customer?.city + ', ' : '') + estimate?.customer?.zipcode}
															</PrivacyModeContent>{' '}
															<br />
															<PrivacyModeContent>{estimate?.customer?.country}</PrivacyModeContent>
															{estimate?.customer?.shipping_address ? (
																<>
																	<br />
																	<br />
																	<b>Ship To: </b>
																	<br />
																	<PrivacyModeContent>{estimate?.customer?.shipping_address}</PrivacyModeContent> <br />
																	<PrivacyModeContent>
																		{estimate?.customer?.shipping_city + ', ' + estimate?.customer?.shipping_zipcode}
																	</PrivacyModeContent>{' '}
																	<br />
																	<PrivacyModeContent>{estimate?.customer?.shipping_country}</PrivacyModeContent>
																</>
															) : (
																<></>
															)}
														</p>
													</>
												)}
											</div>
										</div>
									)}
									{/* {
										estimate?.master_status?.status_type_id == 2 && (
											<>
												<div className="col-4 p-0">
													{estimate?.transit_company?.name && (
														<>
															<div className="company-details" style={{ marginTop: '55px' }}>
																<p className="m-0">
																	<b> {estimate?.transit_company?.name}</b>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: estimate?.transit_company?.address && `${estimate?.transit_company?.address} <br />`
																		}}
																	/>
																	{estimate?.transit_company?.city && `${estimate?.transit_company?.city} `}
																	{estimate?.transit_company?.city && estimate?.transit_company?.zipcode && `, `}
																	{estimate?.transit_company?.zipcode && `${estimate?.transit_company?.zipcode} `}
																	<div
																		dangerouslySetInnerHTML={{
																			__html: estimate?.transit_company?.country && `${estimate?.transit_company?.country}`
																		}}
																	/>
																</p>
															</div>
														</>
													)}
												</div>
											</>
										)
									} */}
								</>
							)}
						</>
					) : (
						<>
							<div className="col-6 p-0">
								<div className="est-detail-logo" style={{ height: '36px' }}>
									<Skeleton sx={{ height: '36px', width: '100px' }} />
								</div>

								<div className="customer-details" style={{ marginTop: '20px' }}>
									<Skeleton variant="text" />
									<Skeleton variant="text" />
									<Skeleton variant="text" />
								</div>
							</div>
							<div className="col-6 p-0">
								<div className="company-details text-right">
									<p className="m-0">
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
									</p>
								</div>
							</div>
						</>
					)}
				</div>

				<div className="row mx-0" style={{ marginTop: '20px', alignItems: 'center' }}>
					<div className="col-6 col-md-8 p-0">
						{estimate ? (
							<h3 className="est-ref m-0">
								{estimate?.master_status?.status_type_id == 2 ? (
									<>
										{
											estimate?.master_status?.name == 'Draft' ? (
												<>
													<span style={{ fontStyle: 'italic', color: '#929598', fontSize: '20px', fontWeight: '400' }}>Quotation {estimate?.estimation_number}</span>
												</>
											) : (
												<>
													Quotation {estimate?.estimation_number}
												</>
											)
										}
										{/* {estimate?.master_status?.name == 'Draft' ? '' : 'Estimate'} {estimate?.estimation_number} */}
									</>
								) : (
									<>
										{estimate?.is_draft == 1 ? (
											<>
												<span style={{ fontStyle: 'italic', color: '#929598', fontSize: '20px', fontWeight: '400' }}>
													{estimate?.master_status?.name == 'Credit Note' ? 'Credit Note' : 'Invoice'} {estimate?.invoice_number}
												</span>
											</>
										) : (
											<>
												{estimate?.master_status?.name == 'Draft' ? (
													<>
														<span style={{ fontStyle: 'italic', color: '#929598', fontSize: '20px', fontWeight: '400' }}>
															{estimate?.invoice_number}
														</span>
													</>
												) : estimate?.master_status?.name == 'Credit Note' ? (
													<>Credit Note {estimate?.invoice_number}</>
												) : (
													<>Invoice {estimate?.invoice_number}</>
												)}
											</>
										)}
									</>
								)}
							</h3>
						) : (
							<Skeleton variant="rounded" />
						)}
					</div>
					<div className="col-6 col-md-4 p-0">
						<table className="w-100">
							{console.log(estimate?.transaction_type)}
							{estimate?.transaction_type == 'Invoice' ? (
								<>
									{estimate?.master_status?.name == 'Credit Note' ? (
										<tr>
											<td>Date:</td>
											<td className="text-right">{moment(estimate?.start_date).format(CommonDateOnlyFormat)}</td>
										</tr>
									) : (
										<tr>
											<td>Date:</td>
											<td className="text-right">{moment(estimate?.invoice_date).format(CommonDateOnlyFormat)}</td>
										</tr>
									)}
								</>
							) : (
								<>
									<tr>
										<td>Estimate Date:</td>
										<td className="text-right">{moment(estimate?.start_date).format(CommonDateOnlyFormat)}</td>
									</tr>
									<tr>
										<td>Due Date: </td>
										<td className="text-right">{moment(estimate?.expires_at).format(CommonDateOnlyFormat)}</td>
									</tr>
								</>
							)}
							{/* {estimate?.transaction_type == 'Estimate' && (
								<>
									<tr>
										<td>Estimate Date:</td>
										<td className="text-right">{moment(estimate?.start_date).format(CommonDateOnlyFormat)}</td>
									</tr>
									<tr>
										<td>Due Date: </td>
										<td className="text-right">{moment(estimate?.expires_at).format(CommonDateOnlyFormat)}</td>
									</tr>
								</>
							)} */}
						</table>
					</div>
				</div>

				<div className="est-inv-items" style={{ marginTop: '20px' }}>
					<table className="w-100">
						<thead>
							<tr>
								{/* {estimate?.master_status?.name == 'Credit Note' && <th className="hide-on-mobile">Invoice</th>} */}
								{estimate?.master_status?.name == 'Credit Note' ? (
									<>
										<th className="hide-on-mobile">Invoice</th>
										<th>Model</th>
									</>
								) : (
									<>
										<th colSpan={2}>Model</th>
									</>
								)}
								{estimate?.master_status?.name != 'Credit Note' && <th className="hide-on-mobile text-center">Comment</th>}
								<th className="text-center">Quantity</th>
								<th className="text-right d-none d-md-block">Price</th>
								<th className="text-right">Total</th>
							</tr>
						</thead>
						<tbody>
							{estimate?.estimation_details?.map((item) => (
								<tr>
									{estimate?.master_status?.name == 'Credit Note' ? (
										<td className="hide-on-mobile">{item?.credited_invoice?.invoice_number}</td>
									) : (
										<td className="hide-on-mobile">
											{item?.product?.thumbnail_url ? (
												<img src={item?.product?.thumbnail_url} style={{ width: '30px' }} alt="product" />
											) : (
												<img src={NoImage} style={{ width: '20px' }} alt="product" />
											)}
										</td>
									)}
									<td>
										<p className="p-0 m-0">{item?.product?.product_name}</p>
										{estimate?.master_status?.name == 'Credit Note' ? (
											<p className="hide-on-non-mobile mobile-prod-comment-ui">{item?.credited_invoice?.invoice_number}</p>
										) : item?.comment ? (
											<p className="hide-on-non-mobile mobile-prod-comment-ui">{item?.comment}</p>
										) : (
											''
										)}
									</td>
									{estimate?.master_status?.name != 'Credit Note' && (
										<td style={{ maxWidth: '80px' }} className="hide-on-mobile text-center">
											<div
												style={{
													cursor: 'pointer'
												}}
												onClick={() => {
													handleCommentModal(item?.product?.product_name, item?.product?.thumbnail_url, item?.comment);
												}}
											>
												{item?.comment ? (
													<>
														<MdOutlineStickyNote2 size={18} />
													</>
												) : (
													<>-</>
												)}
											</div>
											{/* {item?.comment || '-'} */}
										</td>
									)}
									<td className="text-center">{item?.qty}</td>
									<td className="text-right d-none d-md-block">
										<div style={{ marginTop: '5px' }}>
											<CurrencyValue currencyObject={item?.converted_currency_unit_price} />
										</div>
									</td>
									<td className="text-right">
										<CurrencyValue currencyObject={item?.converted_currency_total_price} />
									</td>
								</tr>
							))}
							<tr style={{ height: '10px' }}>
								<td></td>
							</tr>
							{estimate?.shipping_fees > 0 && (
								<tr>
									{estimate?.master_status?.name != 'Credit Note' && <td className="hide-on-mobile"></td>}
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile text-center">{totalQty}</td>
									<td className="d-md-none"></td>
									{/* <td colSpan={2}>Shipping</td> */}
									{/* <td className="text-center"> {estimate?.shipping_qty}</td> */}
									<td className="text-right">Shipping</td>
									{/* <td className="text-right">
									<CurrencyValue currencyObject={estimate?.converted_currency_shipping_fee} />
								</td> */}
									<td className="text-right">
										<CurrencyValue currencyObject={estimate?.converted_currency_total_shipping_fee} />
									</td>
								</tr>
							)}

							{estimate?.total_discount > 0 && (
								<tr>
									{estimate?.master_status?.name != 'Credit Note' && <td className="hide-on-mobile"></td>}
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="d-md-none"></td>
									{/* <td colSpan={2}>Shipping</td> */}
									{/* <td className="text-center"> {estimate?.shipping_qty}</td> */}
									<td className="text-right">Discount {estimate?.discount_type == 'percentage' ? `(${estimate?.discount}%)` : ''}</td>
									{/* <td className="text-right">
									<CurrencyValue currencyObject={estimate?.converted_currency_shipping_fee} />
								</td> */}
									<td className="text-right">
										<CurrencyValue currencyObject={estimate?.converted_total_discount} />
									</td>
								</tr>
							)}

							{estimate?.vat_type == 'Excluding VAT' && (
								<tr>
									{estimate?.master_status?.name != 'Credit Note' && <td className="hide-on-mobile"></td>}
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="d-md-none"></td>
									<td className="text-right">VAT ({estimate?.vat_percent}%)</td>
									<td className="text-right font-weight-bold">
										<CurrencyValue currencyObject={estimate?.converted_currency_vat_amount} />
									</td>
								</tr>
							)}
							<tr>
								{estimate?.master_status?.name != 'Credit Note' && <td className="hide-on-mobile"></td>}
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="d-md-none"></td>
								<td className="text-right">Subtotal</td>
								<td className="text-right font-weight-bold">
									<CurrencyValue currencyObject={estimate?.converted_currency_subtotal} />
								</td>
							</tr>
							{estimate?.transit_fee && (
								<tr>
									{estimate?.master_status?.name != 'Credit Note' && <td className="hide-on-mobile"></td>}
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="d-md-none"></td>
									<td className="text-right">Transit Fee</td>
									<td className="text-right font-weight-bold">
										<CurrencyValue currencyObject={estimate?.converted_currency_total_transit_fee} />
									</td>
								</tr>
							)}
							<tr>
								{estimate?.master_status?.name != 'Credit Note' && <td className="hide-on-mobile"></td>}
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="d-md-none"></td>
								<td className="text-right">Total</td>
								<td className="text-right font-weight-bold">
									<CurrencyValue currencyObject={estimate?.converted_currency_total} />
								</td>
							</tr>
							{(estimate?.open_balance?.open > 0 || estimate?.open_balance?.open < 0) && (
								<>
									<tr>
										{estimate?.master_status?.name != 'Credit Note' && <td className="hide-on-mobile"></td>}
										<td className="hide-on-mobile"></td>
										<td className="hide-on-mobile"></td>
										<td className="hide-on-mobile"></td>
										<td className="d-md-none"></td>
										<td className="text-right">Balance due</td>
										<td className="text-right font-weight-bold">
											<CurrencyValue currencyObject={estimate?.converted_currency_open_balance} />
										</td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</div>
			</div>

			{productComment && (
				<ProductCommentModal
					name={productName}
					image={productImage}
					comment={productComment}
					// show={commentModal}
					handleClose={() => handleCloseComment()}
				/>
			)}
		</>
	);
}

export default EstimateDetailInvoice;
