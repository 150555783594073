import React from 'react';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import CurrencyValue from './CurrencyValue';
import { useSelector } from 'react-redux';

const QuickStatsBar = ({data}) => {
    const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);
	const total = data?.reduce((acc, item) => acc + (item.value?.[selectedCurrency] || 0), 0);

	return (
		<>
			<div
				className="quick-stats-bar"
				style={{ display: 'flex', width: '100%', borderRadius: '2px', overflow: 'hidden', marginTop: '15px' }}
			>
				{data.map((item, index) => (
					<div
						key={index}
                        className='quick-stats-bar-item'
						style={{
                            '--width-item': `${(item.value?.[selectedCurrency] / total) * 100}%`,
							// flex: `${(item.value?.[selectedCurrency] / total) * 100}%`,
							// width: `${(item.value?.[selectedCurrency] / total) * 100}%`,
							// minWidth: `${(item.value?.[selectedCurrency] / total) * 100}%`,
							// maxWidth: `${(item.value?.[selectedCurrency] / total) * 100}%`,
							backgroundColor: item?.color,
							overflow: 'hidden',
							height: '10px',
							position: 'relative'
						}}
					>
                    </div>
				))}
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
				{data.map((item, index) => (
					<div key={index} style={{ display: 'flex', alignItems: 'center'}}>
						<div
							style={{
								width: '10px',
								height: '10px',
								backgroundColor: item?.color,
								marginRight: '10px'
							}}
						></div>
						<div>
							{item?.name} (<CurrencyValue currencyObject={item?.value} />)
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default QuickStatsBar;
