import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import nextIcon from 'assets/images/icons/arrow.svg';
import AsyncSelect from 'react-select/async';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { FaMinus, FaPlus, FaSave } from 'react-icons/fa';

const NoOptionsMessage = (props) => {
	return (
		<div {...props}>
			<button className="btn addNewCus" onClick={inputfieldEnable}>
				<FaPlus /> New Company
			</button>
		</div>
	);
};

const options = [
	{ value: 'IMEI001', label: 'IMEI001' },
	{ value: 'IMEI002', label: 'IMEI002' },
	{ value: 'IMEI003', label: 'IMEI003' }
];

const AddRepair = ({ newRepair, handleRepairClose }) => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const [inputValue, setValue] = useState('');
	const productsRef = useRef();
	const repairTypeRef = useRef();
	const repairLabelRef = useRef();
	const costRef = useRef();
	const currencyRef = useState();
	const [selectedValue, setSelectedValue] = useState(null);
	const [repairTypeList, setRepairTypeList] = useState([]);
	const [repairInfo, setRepairInfo] = useState({
		repair_type: '',
		repair_label: '',
		cost: '',
		currency: 'AED'
	});
	const [alertMessage, setAlertMessage] = useState({});
	const [disable, setDisable] = useState(false);

	const handleInputChange = (value) => {
		setValue(value);
	};

	const handleChange = (value) => {
		setSelectedValue(value);
	};
	const handleClose = () => {
		handleRepairClose();
	};
	const searchApi = async (value) => {
		if (value.length > 5) {
			try {
				const res = await api.searchProductByIMEI(value);
				if (res.status) {
					const data = res.data.map((element) => ({
						value: element.imei,
						label: element.imei,
						name: element.name,
						id: element.id
					}));
					return data;
				}
			} catch (error) {
				console.error('Error fetching IMEI data:', error);
			}
		}
		return [];
	};

	const getRepairType = () => {
		api.allAttributes().then((res) => {
			if (res.status) {
				const data = res?.data?.filter((element) => element.type === 'repair Type');
				setRepairTypeList(data);
			}
		});
	};

	useEffect(() => {
		getRepairType();
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!selectedValue) {
			setAlertMessage({ products: 'Product is required' });
			productsRef.current.focus();
		} else if (repairInfo?.repair_type === '') {
			setAlertMessage({ repair_type: 'Type is required' });
			repairTypeRef.current.focus();
		} else if (repairInfo?.cost === '') {
			setAlertMessage({ cost: 'Cost is required' });
			costRef.current.focus();
		} else {
			setDisable(true);
			api.addProductRepair({
				product_id: selectedValue.id,
				...repairInfo
			}).then((res) => {
				if (res.status) {
					setDisable(false);
					setTimeout(() => {
						setAlertMessage({});
						history('/products/repairs');
						dispatch(storeInLocalStore({ stateAlertmessage: 'Products Repair Created Successfully.' }));
						handleRepairClose('reload');
						setSelectedValue(null);
					}, 500);
				} else {
					// if (res.errors.product_id) {
					// 	setAlertMessage({ products: res.errors.product_id });
					// 	productsRef.current.focus();
					// } else
					// if (res.errors.repair_type) {
					// 	setAlertMessage({ repair_type: res.errors.repair_type });
					// 	repairTypeRef.current.focus();
					// } else if (res.errors.cost) {
					// 	setAlertMessage({ cost: res.errors.cost });
					// 	costRef.current.focus();
					// } else if (res.errors.currency) {
					// 	setAlertMessage({ currency: res.errors.currency });
					// 	currencyRef.current.focus();
					// } else {
					// 	setAlertMessage({
					// 		failed: res.message
					// 	});
					// }
					setTimeout(() => {
						setAlertMessage({});
					}, 2000);
				}
			});
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 3000);
	};

	return (
		<>
			<Modal show={newRepair} onHide={handleClose} centered>
				<div className="p-4">
					<Modal.Title className="text-center text-white mx-auto">Add Repair</Modal.Title>
					<form onSubmit={handleSubmit} className="userForm py-4">
						<div className="form-row form-card">
							<div className="form-group col-md-12 col-xl-12 px-3 py-1">
								<label htmlFor="inputName" className="">
									Products
								</label>
								{/* <Select
									classNamePrefix="custom-select"
									isSearchable={true}
									name="customer"
									components={{
										NoOptionsMessage: NoOptionsMessage
									}}
									options={options}
									placeholder="Select"
									onChange={(selected) => handleCustomerinfo(selected)}
									onInputChange={(e) => {
										handleCustomerName(e);
									}}
								/> */}
								<AsyncSelect
									ref={productsRef}
									classNamePrefix="custom-select"
									cacheOptions
									value={selectedValue}
									defaultOptions
									theme={(theme) => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary: '#1C1C24'
										}
									})}
									className=''
									getOptionLabel={(e) => e.label}
									getOptionValue={(e) => e.value}
									loadOptions={(inputValue, callback) => searchApi(inputValue)}
									onInputChange={handleInputChange}
									onChange={handleChange}
									placeholder="Insert IMEI"
								/>
								{alertMessage.products && (
									<>
										<span className="error-message text-danger">{alertMessage.products}</span>
									</>
								)}
							</div>
						</div>
						<div className="form-row form-card">
							<div className="form-group col-md-12 col-xl-12 px-3 pt-1 pb-3">
								<span className="error-message text-white">{selectedValue && selectedValue?.name}</span>
							</div>
						</div>
						<div className="form-row form-card">
							<div className="form-group col-md-6 col-xl-6 px-3 py-1">
								<label htmlFor="inputName" className="">
									Repair type
								</label>
								<select
									ref={repairTypeRef}
									onChange={(e) => setRepairInfo({ ...repairInfo, repair_type: e.target.value })}
									className="form-control"
								>
									<option value={''}>Select</option>
									{repairTypeList?.map((element) => (
										<option key={element.id} value={element.id}>
											{element.name}
										</option>
									))}
								</select>
								{alertMessage.repair_type && (
									<>
										<span className="error-message text-danger">{alertMessage.repair_type}</span>
									</>
								)}
							</div>
							<div className="form-group col-md-6 col-xl-6 px-3 py-1">
								<label htmlFor="inputName" className="">
									Repair label
								</label>
								<input
									ref={repairLabelRef}
									onChange={(e) => setRepairInfo({ ...repairInfo, repair_label: e.target.value })}
									className="form-control"
									type="text"
									placeholder="Insert"
									maxLength="250"
								/>
								<>
									<span className="error-message text-secondary">Optional</span>
								</>
							</div>
						</div>
						<div className="form-row form-card">
							<div className="form-group col-md-6 col-xl-6 px-3 py-1">
								<label htmlFor="inputName" className="">
									Cost
								</label>
								<input
									ref={costRef}
									onChange={(e) => {
										if (e.target.value < 100000) {
											setRepairInfo({ ...repairInfo, cost: e.target.value });
										}
									}}
									className="form-control"
									type="number"
									placeholder="Insert"
								/>
								{alertMessage.cost && (
									<>
										<span className="error-message text-danger">{alertMessage.cost}</span>
									</>
								)}
							</div>
							<div className="form-group col-md-6 col-xl-6 px-3 py-1">
								<label htmlFor="inputName" className="">
									Currency
								</label>
								<select
									ref={currencyRef}
									onChange={(e) => setRepairInfo({ ...repairInfo, currency: e.target.value })}
									className="form-control"
								>
									<option value={'AED'}>AED</option>
									{['EURO', 'USD'].map((element) => (
										<option key={element} value={element}>
											{element}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className="">
							<div className="btnarea">
								<button onClick={handleClose} className="btn btn-primary canBtn">
									<FaMinus /> Cancel
								</button>
								<button
									type="submit"
									className="btn btn-primary greenBtn"
									disabled={disable}
								>
									<FaSave />
									Save
								</button>
							</div>
							{alertMessage.success && <span className="px-3 down-error text-success">{alertMessage.success}</span>}
							{alertMessage.failed && <span className="px-3 down-error text-danger">{alertMessage.failed}</span>}
						</div>
					</form>
				</div>
			</Modal>
		</>
	);
};

export default AddRepair;
