import React, { useState, useCallback, useEffect } from 'react';
import ProcessIndicator from '../ProcessIndicator';
import { CompanyDetailsProgresses } from 'assets/helperdata/client/company-details-progress';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { MdOutlineFileUpload } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { FaCircleCheck } from 'react-icons/fa6';
import { ImSpinner2 } from 'react-icons/im';
import { FaTimesCircle } from 'react-icons/fa';
import Select from 'react-select';

const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png'];
const allowedExtensionsforLogo = ['jpeg', 'jpg', 'png'];

const validateFileExtension = (fileName) => {
	const extension = fileName.split('.').pop().toLowerCase();
	return allowedExtensions.includes(extension);
};

const validateFileExtensionforLogo = (fileName) => {
	const extension = fileName.split('.').pop().toLowerCase();
	return allowedExtensions.includes(extension);
};

const CompanyDocuments = () => {
	const history = useNavigate();
	const [vatCheckTimer, setVatCheckTimer] = useState();
	const [salesManagers, setSalesManagers] = useState([]);
	const [vatValidationAlert, setVatvalidationlert] = useState();
	const clientUser = useSelector((state) => state.main.client);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [logo, setLogo] = useState('');
	const [registration, setRegistration] = useState('');
	const [vat, setVat] = useState('');
	const [alertMessage, setAlertmessage] = useState({
		vatNumber: ''
	});
	const [isInProgress, setIsInProgress] = useState(false);
	const [data, setData] = useState({
		vatCode: '',
		vatDocument: '',
		registrationNumber: '',
		registrationDocument: '',
		salesManager: '',
		companyLogo: '',
		id: clientUser?.data?.id
	});
	const [userAddress, setUserAddress] = useState(null);
	const [vatValidation, setVatValidation] = useState(false);

	const vatValidationCountries = [
		'Austria',
		'Belgium',
		'Bulgaria',
		'Croatia',
		'Cyprus',
		'Czech Republic',
		'Denmark',
		'Estonia',
		'Finland',
		'France',
		'Germany',
		'Greece',
		'Hungary',
		'Ireland',
		'Italy',
		'Latvia',
		'Lithuania',
		'Luxembourg',
		'Malta',
		'Netherlands',
		'Poland',
		'Portugal',
		'Romania',
		'Slovakia',
		'Slovenia',
		'Spain',
		'Sweden'
	];

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		api.getSelectOptionsListClient('salesperson').then((res) => {
			const tempsalesPerson = res.data.map((item) => ({ id: item.id, name: `${item.first_name} ${item.last_name || ''}` }));
			setSalesManagers(tempsalesPerson);
		});
		if (clientUser?.data?.id) {
			api.getClientInformation(clientUser?.data?.id).then((res) => {

				if (res?.data?.companyDoc) {
					console.log(res.data.companyDoc);
					if (res.data.companyDoc.vat_doc) {
						const vatDocumentPath = res.data.companyDoc.vat_doc;
						const vatDocumentExtension = vatDocumentPath.split('.').pop();
						const vatDocumentName = `VAT.${vatDocumentExtension}`;
					}
					// const vatDocumentPath = res.data.companyDoc.vat_doc;
					// const vatDocumentExtension = vatDocumentPath.split('.').pop();
					// const vatDocumentName = `VAT.${vatDocumentExtension}`;
					const registrationDocumentPath = res.data.companyDoc.registration_doc;
					const registrationDocumentExtension = registrationDocumentPath.split('.').pop();
					const registrationDocumentName = `Registration.${registrationDocumentExtension}`;
					const companyLogoPath = res.data.companyDoc.company_logo;
					const companyLogoExtension = companyLogoPath.split('.').pop();
					const companyLogoName = `CompanyLogo.${companyLogoExtension}`;

					setUserAddress(res.data.companyBillingAddress.country);

					setLogo(companyLogoName);
					setRegistration(registrationDocumentName);
					if (res.data.companyDoc.vat_doc) {
						setVat(vatDocumentName);
					}
					setData({
						vatCode: res.data.companyDoc.vat_code,
						vatDocument: res.data.companyDoc.vat_doc,
						registrationNumber: res.data.companyDoc.registration_number,
						registrationDocument: res.data.companyDoc.registration_doc,
						salesManager: res.data.companyDoc.sales_manager_id,
						companyLogo: res.data.companyDoc.company_logo,
						id: clientUser?.data?.id
					});
				} else {
					setUserAddress(res.data.companyBillingAddress.country);
				}
			});
		}
	}, []);


	useEffect(() => {
		if (vatValidationCountries.includes(userAddress)) {
			setVatValidation(true);
		}
	}, [userAddress]);

	const handlevatValidation = (e) => {
		let inputValue = e.target.value;

		setData((prevData) => ({ ...prevData, vatCode: e.target.value }));
		inputValue = inputValue.trim();
		const words = inputValue.split(' ');
		const capitalizedWords = words.map((word) => word.substr(0).toUpperCase());
		inputValue = capitalizedWords.join(' ');
		e.target.value = inputValue;

		if (vatCheckTimer) {
			clearTimeout(vatCheckTimer);
		}

		let timeout = setTimeout(() => {
			vatNumberValidation(inputValue);
		}, 1000);

		setVatCheckTimer(timeout);
	};

	const onDrop = useCallback((acceptedFiles) => {
		console.log('onDrop triggered', acceptedFiles);
		if (validateFileExtensionforLogo(acceptedFiles[0].name)) {
			setData((prevData) => ({ ...prevData, companyLogo: acceptedFiles[0] }));
			setLogo(acceptedFiles[0].name);
		} else {
			toast.error('Invalid file extension. Please select a JPEG, JPG, PNG, or PDF file.');
		}
	}, []);
	const handleSalesManagerSelect = (selectedOption) => {
		setData({ ...data, salesManager: selectedOption.value });
		const completionPercentage = calculateCompletionPercentage();
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: '.pdf, .jpeg, .jpg, .png',
		multiple: false
	});
	console.log(data);

	const vatNumberValidation = (vatNumbr) => {
		vatNumbr = vatNumbr.trim();
		const words = vatNumbr.split(' ');
		const capitalizedWords = words.map((word) => word.substr(0).toUpperCase());
		vatNumbr = capitalizedWords.join(' ');
		console.log('vatNumbr', vatNumbr);
		if (vatNumbr) {
			setVatvalidationlert('loading');
			api.vatValidation(vatNumbr).then((res) => {
				if (res.errors) {
					setVatvalidationlert(false);
					toast.error('VAT Number is not valid');

					setAlertmessage({
						vatNumber: 'VAT Number is not valid'
					});
				} else {
					if (res.format_valid == false) {
						setVatvalidationlert(false);
						setAlertmessage({
							vatNumber: 'Format is not valid.'
						});
					} else if (res.registration_info?.is_registered == false) {
						setVatvalidationlert(false);
						setAlertmessage({
							vatNumber: 'VAT Number is not registered.'
						});
					} else if (res?.message?.includes('You used all your monthly requests')) {
						setVatvalidationlert(false);
						setAlertmessage({
							vatNumber: 'You used all your monthly requests'
						});
					} else {
						setAlertmessage({
							vatNumber: ''
						});
						setVatvalidationlert(true);
					}
				}
				setData((prevData) => ({ ...prevData, vatCode: vatNumbr }));
			});
		} else {
			setData((prevData) => ({ ...prevData, vatCode: vatNumbr }));
		}
	};

	const onRegistrationDrop = useCallback((acceptedFiles) => {
		console.log('onRegistrationDrop triggered', acceptedFiles);

		if (validateFileExtension(acceptedFiles[0].name)) {
			setData((prevData) => ({ ...prevData, registrationDocument: acceptedFiles[0] }));
			setRegistration(acceptedFiles[0].name);
		} else {
			toast.error('Invalid file extension. Please select a PDF, JPEG, JPG, or PNG file.');
		}
	}, []);

	const { getRootProps: getRegistrationRootProps, getInputProps: getRegistrationInputProps } = useDropzone({ onDrop: onRegistrationDrop });

	const onVATDrop = useCallback((acceptedFiles) => {
		console.log('onVATDrop triggered', acceptedFiles);
		if (validateFileExtension(acceptedFiles[0].name)) {
			setData((prevData) => ({ ...prevData, vatDocument: acceptedFiles[0] }));
			setVat(acceptedFiles[0].name);
		} else {
			toast.error('Invalid file extension. Please select a PDF, JPEG, JPG, or PNG file.');
		}
	}, []);

	const { getRootProps: getVATRootProps, getInputProps: getVATInputProps } = useDropzone({ onDrop: onVATDrop });

	const moveToNext = () => {
		console.log(data);
		if (vatValidation) {
			if (
				data.vatCode === '' ||
				data.vatDocument === '' ||
				data.registrationNumber === '' ||
				data.registrationDocument === '' ||
				data.salesManager === '' ||
				data.companyLogo === ''
			) {
				toast.error('Please fill all the fields');
				setIsInProgress(false);
				return;
			}
		} else {
			if (data.registrationNumber === '' || data.registrationDocument === '' || data.salesManager === '' || data.companyLogo === '') {
				toast.error('Please fill all the fields');
				setIsInProgress(false);
				return;
			}
		}
		if (vatValidationAlert == false) {
			toast.error('Please enter a valid VAT number');
			setIsInProgress(false);
			return;
		}
		setIsInProgress(false);
		const formData = new FormData();
		formData.append('vatCode', data.vatCode);
		formData.append('vatDocument', data.vatDocument);
		formData.append('registrationNumber', data.registrationNumber);
		formData.append('registrationDocument', data.registrationDocument);
		formData.append('salesManager', data.salesManager);
		formData.append('companyLogo', data.companyLogo);

		api
			.updateClientDocuments(data.id, formData)
			.then((res) => {
				if (res.status === true) {
					toast.success('Company documents updated successfully');
					history('../client/company-bank-details');
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const moveToBack = () => {
		setIsInProgress(false);
		history('../client/company-address');
	};

	const calculateCompletionPercentage = () => {
		let fields = [];
		if (vatValidation) {
			fields = ['vatCode', 'vatDocument', 'registrationNumber', 'registrationDocument', 'salesManager', 'companyLogo'];
		} else {
			fields = ['registrationNumber', 'registrationDocument', 'salesManager', 'companyLogo'];
		}
		const filledFields = fields.filter((field) => data[field]);
		return (filledFields.length / fields.length) * 100;
	};

	// Call this function whenever you want to get the completion percentage
	const completionPercentage = calculateCompletionPercentage();

	return (
		<div className={`main-page-client ${isMobile ? 'padding-mobile-client' : ''}`}>
			<div className="actions-company-details-client">
				<ProcessIndicator
					isInProgress={isInProgress}
					processes={CompanyDetailsProgresses}
					currentIndex={3}
					completionPercentage={completionPercentage}
				/>
			</div>
			<div className="company-details-client-content">
				<div className="" style={{ justifyContent: 'space-between' }}>
					<table className="projectmix-table-vertical-client">
						<tbody>
							<tr>
								{!isMobile && <th></th>}

								<td className="fw-600" style={{ textAlign: isMobile ? 'left' : 'left', fontSize: isMobile ? 16 : 14 }}>
									Company Documents
								</td>
							</tr>
							{vatValidation && (
								<>
									<tr>
										{!isMobile && <th>VAT Code</th>}
										<td>
											{isMobile && <label className="onboard-label">VAT Code</label>}
											<input
												type="text"
												className="form-control"
												value={data.vatCode}
												style={{ color: 'black', borderColor: '#0066FF' }}
												onChange={(e) => {
													setData({ ...data, vatCode: e.target.value });
													// handlevatValidation(e);
													// const completionPercentage = calculateCompletionPercentage();
												}}
											/>
											{/* {vatValidationAlert == true && (
										<span className="text-success">
											<FaCircleCheck />
										</span>
									)}
									{vatValidationAlert == false && alertMessage.vatNumber && (
										<span className="text-danger">
											<FaTimesCircle />
										</span>
									)}
									{vatValidationAlert == 'loading' && (
										<span>
											<ImSpinner2 className="fa-spin" />
										</span>
									)} */}
										</td>
									</tr>
									<tr>
										{!isMobile && <th></th>}
										<td {...getVATRootProps()}>
											{isMobile && <label className="onboard-label">VAT Document</label>}
											<div
												className={`dragIconVAT ${data.vatDocument ? 'dragRegistration' : ''}`}
												style={{ position: 'relative', border: '1px solid #0066FF', borderRadius: '12px' }}
											>
												{data.vatDocument ? (
													<>
														<button
															className="btn remove-file-icon"
															onClick={() => setData({ ...data, vatDocument: null })}
															style={{ position: 'absolute', top: 2, right: 2 }}
														>
															<svg
																id="Component_127_1"
																height="12"
																width="12"
																data-name="Component 127 – 1"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 19.295 19.289"
															>
																<path
																	id="Icon_ionic-ios-close"
																	data-name="Icon ionic-ios-close"
																	d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
																	transform="translate(-11.285 -11.289)"
																/>
															</svg>
														</button>
														<p className="dragText">{data.vatDocument.name ? data.vatDocument.name : data.vatDocument.split('/').pop()}</p>
													</>
												) : (
													<>
														<MdOutlineFileUpload className="UploadIcon" />
														<p className="dragText">
															Drop file here or <b>Browse file</b> (5MB)
														</p>
													</>
												)}
											</div>
											<input {...getVATInputProps({ disabled: vatValidationAlert === 'loading' })} style={{ display: 'none' }} />
										</td>
									</tr>
								</>
							)}
							<tr>
								{!isMobile && <th>Registration Nr.</th>}
								<td>
									{isMobile && <label className="onboard-label">Registration Nr.</label>}
									<input
										type="text"
										value={data.registrationNumber}
										className="form-control"
										style={{ color: 'black', borderColor: '#0066FF' }}
										onChange={(e) => {
											setData({ ...data, registrationNumber: e.target.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
									/>
								</td>
							</tr>
							<tr>
								{!isMobile && <th></th>}
								<td {...getRegistrationRootProps()}>
									{isMobile && <label className="onboard-label">Registration Document</label>}
									<div
										className={`dragIconVAT ${registration ? 'dragRegistration' : ''}`}
										style={{ position: 'relative', border: '1px solid #0066FF', borderRadius: '12px' }}
									>
										{registration ? (
											<>
												<button
													className="btn remove-file-icon"
													onClick={() => {
														setData({ ...data, registrationDocument: null });
														setRegistration(null);
													}}
													style={{ position: 'absolute', top: 0, right: 0 }}
												>
													<svg
														id="Component_127_1"
														height="12"
														width="12"
														data-name="Component 127 – 1"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 19.295 19.289"
													>
														<path
															id="Icon_ionic-ios-close"
															data-name="Icon ionic-ios-close"
															d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
															transform="translate(-11.285 -11.289)"
														/>
													</svg>
												</button>
												<p className="dragText">
													{data.registrationDocument.name ? data.registrationDocument.name : data.registrationDocument.split('/').pop()}
												</p>
											</>
										) : (
											<>
												<MdOutlineFileUpload className="UploadIcon" />
												<p className="dragText">
													Drop file here or <b>Browse file</b> (5MB)
												</p>
											</>
										)}
									</div>
									<input {...getRegistrationInputProps({ disabled: vatValidationAlert !== true })} style={{ display: 'none' }} />{' '}
								</td>
							</tr>
							<tr>
								{!isMobile && <th>Sales Manager</th>}
								<td>
									{isMobile && <label className="onboard-label">Sales Manager</label>}
									<Select
										className="rs-container-est-blue-client"
										classNamePrefix="rs"
										isSearchable={true}
										name="customer"
										options={salesManagers?.map((y) => ({ label: y.name, value: y.id }))}
										value={salesManagers?.map((y) => ({ label: y.name, value: y.id }))?.find((y) => y?.value == data.salesManager)}
										onChange={handleSalesManagerSelect}
										menuPlacement="auto"
										menuPosition="fixed"
									/>
								</td>
							</tr>
							<tr>
								{!isMobile && <th>Passport or ID Copy</th>}
								<td {...getRootProps()}>
									{isMobile && <label className="onboard-label">Passport or ID Copy</label>}
									<div
										className={`dragIconVAT ${logo ? 'dragRegistration' : ''}`}
										style={{ position: 'relative', border: '1px solid #0066FF', borderRadius: '12px' }}
									>
										{logo ? (
											<>
												<button
													className="btn remove-file-icon"
													onClick={() => {
														setData({ ...data, companyLogo: null });
														setLogo(null);
													}}
													style={{ position: 'absolute', top: 0, right: 0 }}
												>
													<svg
														id="Component_127_1"
														height="12"
														width="12"
														data-name="Component 127 – 1"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 19.295 19.289"
													>
														<path
															id="Icon_ionic-ios-close"
															data-name="Icon ionic-ios-close"
															d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
															transform="translate(-11.285 -11.289)"
														/>
													</svg>
												</button>
												<p className="dragText">{data.companyLogo.name ? data.companyLogo.name : data.companyLogo.split('/').pop()}</p>
											</>
										) : (
											<>
												<MdOutlineFileUpload className="UploadIcon" />
												<p className="dragText">
													Drop file here or <b>Browse file</b> (5MB)
												</p>
											</>
										)}
									</div>
									<input {...getInputProps({ disabled: vatValidationAlert !== true })} style={{ display: 'none' }} />{' '}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="action-company-dtls-adrs-btn" style={{ marginTop: 10 }}>
				<button
					className="btn btn-cmpny-dtls-action-back"
					onClick={() => {
						setIsInProgress(true);
						moveToBack();
					}}
				>
					<MdKeyboardArrowLeft /> Back
				</button>

				<button
					className="btn btn-cmpny-dtls-action"
					onClick={() => {
						setIsInProgress(true);
						moveToNext();
					}}
				>
					Next <MdKeyboardArrowRight />
				</button>
			</div>
		</div>
	);
};

export default CompanyDocuments;
