import React, { useEffect, useRef, useState } from 'react';
import './CurrencyDropdown.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setStateSelectedCurrency } from 'store/reducers/mainSlice';
import Flag from './Flag';
import { IoIosArrowUp } from 'react-icons/io';

const CURRENCY = {
	usd: (
		<>
			<Flag code="US" height={10} width={18} /> USD
		</>
	),
	eur: (
		<>
			<Flag code="EU" height={10} width={18} />
			EUR
		</>
	),
	aed: (
		<>
			<Flag code="AE" height={10} width={18} />
			AED
		</>
	)
};

const CURRENCY_COLLAPSED = {
	usd: <>USD</>,
	eur: <>EUR</>,
	aed: <>AED</>
};

function CurrencyDropdown({ inputCurrency, onChange, isOpen }) {
	const compRef = useRef(null);
	const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);
	const dispatch = useDispatch();
	const [showCurrencySelector, setShowCurrencySelector] = useState(false);

	const handleClickOutside = (event) => {
		if (compRef.current && !compRef.current.contains(event.target)) {
			setShowCurrencySelector(false);
		}
	};

	const handleSelectCurrency = (currency) => {
		dispatch(setStateSelectedCurrency(currency));
		onChange && onChange(currency);
		setShowCurrencySelector(false);
	};

	const toggleShowSelector = () => {
		setShowCurrencySelector(!showCurrencySelector);
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return (
		<div className={`currency-component ${isOpen ? '' : 'collapsed'}`} ref={compRef}>
			<div className="currencyText" onClick={toggleShowSelector}>
				{isOpen ? (
					<>
						Currency: {selectedCurrency?.toUpperCase()} <IoIosArrowUp />
					</>
				) : (
					<>{selectedCurrency?.toUpperCase()}</>
				)}
			</div>

			{showCurrencySelector ? (
				<div className="currencySelectDropdown">
					{Object.keys(CURRENCY).map((value) => {
						return (
							<div
								key={value}
								className={value == selectedCurrency ? 'currency-item active' : 'currency-item'}
								onClick={() => handleSelectCurrency(value)}
							>
								{isOpen ? CURRENCY[value] : CURRENCY_COLLAPSED[value]}
							</div>
						);
					})}
				</div>
			) : (
				<></>
			)}
		</div>
	);
}

export default CurrencyDropdown;
