import React, { useState, useEffect, useRef } from 'react';
import 'react-phone-number-input/style.css';
import { useNavigate, useParams } from 'react-router';
import Scroller from 'ui-component/Scroller';
import { useDispatch, useSelector } from 'react-redux';
import { FaMinus, FaPlus, FaSave, FaTimesCircle } from 'react-icons/fa';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { setContactlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import Select from 'react-select';

import './CategoryForm.scss';
import toast from 'react-hot-toast';

const yesNoOption = [
	{ label: 'Yes', value: 1 },
	{ label: 'No', value: 0 },
];

const CategoryForm = ({ edit }) => {
	const { id } = useParams();
	const history = useNavigate();
	const dispatch = useDispatch();
	const nameRef = useRef();
	const [alertMessage, setAlertmessage] = useState({});
	const [vatValidationAlert, setVatvalidationlert] = useState('');
	const [categoryInfo, setCategoryInfo] = useState({
		name: '',
		hasGradeVariations: 0,
		hasColorVariations: 0,
		hasStorageVariations: 0,
		hasSkipTesting: 0,
		hasSkipInbounding: 0,
		hasSkipQualityControl: 0,
		hasSkipRepairing: 0,
		hasSkipRetesting: 0,
	});
	
	const handleChange = (val) => {
		setCategoryInfo(val);
	};

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

	const handleSubmit = (e) => {
		if (edit && id) {
			e.preventDefault();
			let sameValue = false;
			if (categoryInfo.name.trim() === '') {
				setAlertmessage({
					name: 'Category Name is required.'
				});
				nameRef.current.focus();
			} else if (sameValue) {
				goback();
			} else {
				setIsSubmitDisabled(true);

				toast.promise(
					api
						.updateCategory(id, {
							name: categoryInfo?.name,
							has_grade_variations: categoryInfo?.hasGradeVariations,
							has_color_variations: categoryInfo?.hasColorVariations,
							has_storage_variations: categoryInfo?.hasStorageVariations,
							has_skip_testing: categoryInfo?.hasSkipTesting,
							has_skip_inbounding: categoryInfo?.hasSkipInbounding,
							has_skip_quality_control: categoryInfo?.hasSkipQualityControl,
							has_skip_repairing: categoryInfo?.hasSkipRepairing,
							has_skip_retesting: categoryInfo?.hasSkipRetesting,
						})
						.then(
							(res) => {
								if (res.status) {
									history('/settings/productcategories');
								} else {
									if (res.errors?.name) {
										setAlertmessage({
											name: res.errors?.name
										});
										nameRef.current.focus();
									} else {
										setAlertmessage({
											failed: res.message
										});
									}
								}

								setIsSubmitDisabled(false);
								return res?.status ? res : null;
							},
							() => {
								setIsSubmitDisabled(false);
							}
						),
					{
						loading: 'Updating Category...',
						success: (data) => `${data.message}`,
						error: <b>Failed to update category</b>
					}
				);
			}
		} else {
			e.preventDefault();
			if (categoryInfo.name.trim() === '') {
				setAlertmessage({
					name: 'Category Name is required.'
				});
				nameRef.current.focus();
			} else {
				setIsSubmitDisabled(true);


				toast.promise(
					api
						.addProductCategories({
							name: categoryInfo.name,
							has_grade_variations: categoryInfo?.hasGradeVariations,
							has_color_variations: categoryInfo?.hasColorVariations,
							has_storage_variations: categoryInfo?.hasStorageVariations,
							has_skip_testing: categoryInfo?.hasSkipTesting,
							has_skip_inbounding: categoryInfo?.hasSkipInbounding,
							has_skip_quality_control: categoryInfo?.hasSkipQualityControl,
							has_skip_repairing: categoryInfo?.hasSkipRepairing,
							has_skip_retesting: categoryInfo?.hasSkipRetesting,
						})
						.then(
							(res) => {
								if (res.status) {
									history('/settings/productcategories');
								} else {
									if (res.errors?.name) {
										setAlertmessage({
											name: res.errors?.name
										});
										nameRef.current.focus();
									} else {
										setAlertmessage({
											failed: res.message
										});
									}
								}
								setIsSubmitDisabled(false);
								return res?.status ? res : null;
							},
							() => {
								setIsSubmitDisabled(false);
							}
						),
					{
						loading: 'Creating Category...',
						success: (data) => `${data.message}`,
						error: <b>Failed to create category</b>
					}
				);
			}
		}
	};
	useEffect(() => {

		if (edit) {
			getSelectedCategory();
		}
	}, []);

	const getSelectedCategory = () => {
		setLoading(true);
		api.getCategoryById(id).then((res) => {
			setLoading(false);
			if (!res.data) {
				setLoading(true);
				history('/settings/productcategories');
			} else {
				const loadData = res?.data;

				let tempdata = {
					name: loadData?.name || '',
					hasGradeVariations: loadData?.has_grade_variations || 0,
					hasColorVariations: loadData?.has_color_variations || 0,
					hasStorageVariations: loadData?.has_storage_variations || 0,
					hasSkipTesting: loadData?.has_skip_testing || 0,
					hasSkipInbounding: loadData?.has_skip_inbounding || 0,
					hasSkipQualityControl: loadData?.has_skip_quality_control || 0,
					hasSkipRepairing: loadData?.has_skip_repairing || 0,
					hasSkipRetesting: loadData?.has_skip_retesting || 0,
				};
				setVatvalidationlert(loadData?.is_vat_verified ? true : false);
				setCategoryInfo(tempdata);
				setLoading(true);
			}
		});
	};
	
	const goback = () => {
		history('/settings/productcategories');
	};
	return (
		<>
			<div className="row m-0 create-contact-container">
				<div className="card w-100 h-100">
					<form action="" className="h-100" onSubmit={handleSubmit}>
						<div className="section-head m-0 p-0">
							<h5 className="m-0 p-0 pb-2" style={{ fontWeight: '500' }}>
								{edit ? 'Edit Category' : 'Create Category'}
							</h5>
						</div>
						<Scroller>

							<div className="form-section" style={{ marginTop: '20px' }}>
								<div className="form-section-head">
									<h4>General Details</h4>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Name</label>
										<div>
											<input
												ref={nameRef}
												value={categoryInfo?.name}
												type="text"
												className="form-control"
												id="inputName"
												onChange={(e) => {
													handleChange({ ...categoryInfo, name: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.name && (
											<>
												<span className="error-message text-danger">{alertMessage.name}</span>
											</>
										)}
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Has Grade?</label>
										<div>
											
											<Select
													menuPosition={'fixed'}
													options={yesNoOption}
													value={yesNoOption?.find((y) => y?.value == categoryInfo?.hasGradeVariations)}
													className="rs-container"
													classNamePrefix="rs"
													placeholder=""
													onChange={(e) => {
														handleChange({ ...categoryInfo, hasGradeVariations: e.value });
													}}
												/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.hasGradeVariations && (
											<>
												<span className="error-message text-danger">{alertMessage.hasGradeVariations}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Has Storage?</label>
										<div>
											
											<Select
													menuPosition={'fixed'}
													options={yesNoOption}
													value={yesNoOption?.find((y) => y?.value == categoryInfo?.hasStorageVariations)}
													className="rs-container"
													classNamePrefix="rs"
													placeholder=""
													onChange={(e) => {
														handleChange({ ...categoryInfo, hasStorageVariations: e.value });
													}}
												/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.hasStorageVariations && (
											<>
												<span className="error-message text-danger">{alertMessage.hasStorageVariations}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Has Color?</label>
										<div>
											
											<Select
													menuPosition={'fixed'}
													options={yesNoOption}
													value={yesNoOption?.find((y) => y?.value == categoryInfo?.hasColorVariations)}
													className="rs-container"
													classNamePrefix="rs"
													placeholder=""
													onChange={(e) => {
														handleChange({ ...categoryInfo, hasColorVariations: e.value });
													}}
												/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.hasColorVariations && (
											<>
												<span className="error-message text-danger">{alertMessage.hasColorVariations}</span>
											</>
										)}
									</div>
								</div>
							</div>



							<div className="form-section" style={{ marginTop: '20px' }}>
								<div className="form-section-head">
									<h4>Operations</h4>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Skip Testing?</label>
										<div>
											
											<Select
													menuPosition={'fixed'}
													options={yesNoOption}
													value={yesNoOption?.find((y) => y?.value == categoryInfo?.hasSkipTesting)}
													className="rs-container"
													classNamePrefix="rs"
													placeholder=""
													onChange={(e) => {
														handleChange({ ...categoryInfo, hasSkipTesting: e.value });
													}}
												/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.hasSkipTesting && (
											<>
												<span className="error-message text-danger">{alertMessage.hasSkipTesting}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Skip Inbounding?</label>
										<div>
											
											<Select
													menuPosition={'fixed'}
													options={yesNoOption}
													value={yesNoOption?.find((y) => y?.value == categoryInfo?.hasSkipInbounding)}
													className="rs-container"
													classNamePrefix="rs"
													placeholder=""
													onChange={(e) => {
														handleChange({ ...categoryInfo, hasSkipInbounding: e.value });
													}}
												/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.hasSkipInbounding && (
											<>
												<span className="error-message text-danger">{alertMessage.hasSkipInbounding}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Skip Quality Control?</label>
										<div>
											
											<Select
													menuPosition={'fixed'}
													options={yesNoOption}
													value={yesNoOption?.find((y) => y?.value == categoryInfo?.hasSkipQualityControl)}
													className="rs-container"
													classNamePrefix="rs"
													placeholder=""
													onChange={(e) => {
														handleChange({ ...categoryInfo, hasSkipQualityControl: e.value });
													}}
												/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.hasSkipQualityControl && (
											<>
												<span className="error-message text-danger">{alertMessage.hasSkipQualityControl}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Skip Repairing?</label>
										<div>
											
											<Select
													menuPosition={'fixed'}
													options={yesNoOption}
													value={yesNoOption?.find((y) => y?.value == categoryInfo?.hasSkipRepairing)}
													className="rs-container"
													classNamePrefix="rs"
													placeholder=""
													onChange={(e) => {
														handleChange({ ...categoryInfo, hasSkipRepairing: e.value });
													}}
												/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.hasSkipRepairing && (
											<>
												<span className="error-message text-danger">{alertMessage.hasSkipRepairing}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Skip Retesting?</label>
										<div>
											
											<Select
													menuPosition={'fixed'}
													options={yesNoOption}
													value={yesNoOption?.find((y) => y?.value == categoryInfo?.hasSkipRetesting)}
													className="rs-container"
													classNamePrefix="rs"
													placeholder=""
													onChange={(e) => {
														handleChange({ ...categoryInfo, hasSkipRetesting: e.value });
													}}
												/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.hasSkipRetesting && (
											<>
												<span className="error-message text-danger">{alertMessage.hasSkipRetesting}</span>
											</>
										)}
									</div>
								</div>


							</div>



						</Scroller>
						<div className="non-scroll-btn-section">
							<div className="btnarea">
								<button onClick={goback} className="btn btn-link">
									Cancel
								</button>
								{edit ? (
									<PermissionAllowed permissions={['Save Contact Details (Action)']}>
										<button
											type="submit"
											className="btn btn-primary btn-outlined-main"
											disabled={isSubmitDisabled || vatValidationAlert == 'loading'}
										>
											<FaSave /> Save Changes
										</button>
									</PermissionAllowed>
								) : (
									<PermissionAllowed permissions={['Create New Contact (Action)']}>
										<button
											type="submit"
											className="btn btn-primary btn-outlined-main"
											disabled={isSubmitDisabled || vatValidationAlert == 'loading'}
										>
											<FaPlus /> Create
										</button>
									</PermissionAllowed>
								)}
							</div>
							{alertMessage.success && <span className="px-3 down-error text-success">{alertMessage.success}</span>}
							{alertMessage.failed && <span className="px-3 down-error text-danger">{alertMessage.failed}</span>}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default CategoryForm;
