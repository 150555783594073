import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';
import { Note } from '@mui/icons-material';

export default {
	// login user
	login(data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/login`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response.data);
				});
		}, false);
	},
	// GET header menu and submenu
	logout() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/logout`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((e) => {
					apiResponseErrorHandler(e);
					rej(e);
				});
		}, false);
	},
	forgotPassword(data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/password/request`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response.data);
				});
		}, false);
	},
	forgotPasswordOtp(data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/password/otp-verify`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response.data);
				});
		}, false);
	},
	forgotPasswordOtpClient(data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/client/password/otp-verify`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response.data);
				});
		}, false);
	},
	passwordReset(data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/password/reset`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response.data);
				});
		}, false);
	},
	passwordResetClient(data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/client/password/reset`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response.data);
				});
		}, false);
	},
	setPassword(data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/password/set`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response.data);
				});
		}, false);
	},
	getUserDetail(userId) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/users/${userId}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getDashboardData() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/dashboard`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getMentionUser(search) {
		let url = `/api/user-mention-search`;
		if (search) {
			url += `?search=${search}`;
		}
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}${url}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getMentionUserById(id) {
		let url = `/api/user-mention-search/${id}`;
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}${url}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getUserTasks() {
		let url = `/api/user-tasks`;
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}${url}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	syncUserTasks(data) {
		let url = `/api/user-tasks-sync`;
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}${url}`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPendingCreditNotes() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/dashboard/get-credit-notes`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPendingOrders() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/dashboard/get-pending-orders`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getCurrentInventory() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/dashboard/get-inventory`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	exportAllBackorders() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(
					`${base}/api/back-orders/create-report-all`,
					{},
					{
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
								}`
						},
						responseType: 'blob'
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	exportBackorder(estimation) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(
					`${base}/api/back-orders/create-report-all/${estimation}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
								}`
						},
						responseType: 'blob'
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	exportAllBackordersPDF() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(
					`${base}/api/back-orders/create-report-all-pdf`,
					{},
					{
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
								}`
						},
						responseType: 'blob'
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	exportBackorderPDF(estimation) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(
					`${base}/api/back-orders/create-report-all-pdf/${estimation}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
								}`
						},
						responseType: 'blob'
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	addReturnsNotes(user, inputText, returnsId) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(
					`${base}/api/returns/add-note`,
					{
						user_id: user,
						returns_id: returnsId,
						notes: inputText
					},
					{
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
								}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// sendEmailSupplier(email, subject, message, id) {
	// 	return this.handelAlert((res, rej, loadobj) => {
	// 		axios
	// 			.post(
	// 				`${base}/api/purchase-order/send-email-to-supplier`,
	// 				{
	// 					email: email,
	// 					subject: subject,
	// 					message: message,
	// 					id: id
	// 				},
	// 				{
	// 					headers: {
	// 						Authorization: `Bearer ${
	// 							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
	// 						}`
	// 					}
	// 				}
	// 			)
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				apiResponseErrorHandler(rej);
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },
	getEstimated() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/dashboard/get-estimated`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getExchangeRate() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/dashboard-exchange`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getDashboardRole() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/dashboard-role`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getDashboardWidgets() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/dashboard-widgets`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getSalesDataMonth(monthNumber) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/sales-data-month/${monthNumber}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getSalesDataQuarter(monthNumber) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/sales-data-quarter/${monthNumber}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getSalesDataYear(monthNumber) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/sales-data-year/${monthNumber}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getUserDataMonth(monthNumber) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/user-data-month/${monthNumber}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getSalesTarget(year) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/sales-target/${year}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateDashboardPermissions(changes) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/dashboard-widgets`, changes, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateSalesTarget(result) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/sales-target`, result, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateUserDetail(userId, data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/users/update/${userId}`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	changePassword(data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/users/change-password`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getRoles() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/get-roles`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getUserList() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/users`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getUserPermissionsList(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/users/${id}/get-permissions`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	updateUserPermissions(user_id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/users/${user_id}/update-permission`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	deleteUser(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/users/${data}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateUser(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/users/${id}`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	createUser(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/users`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	searchuser(name) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/users?search=${name}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterbyRoles(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/users?role=${data?.role || ''}&&status=${data?.status || ''}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	selectallsales() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/users?type=all&status=1&role=salesperson`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	// filterbyStatus(status) {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.get(`${base}/api/users?status=${status}`, {
	// 				headers: {
	// 					Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user'))?.token) || token}`
	// 				}
	// 			})
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },
	searchSalePersonUser(name, status) {
		return this.handelAlert((res, rej, loadObj) => {
			const url = status ? `${base}/api/users?search=${name}&role=2&status=${status}` : `${base}/api/users?search=${name}&role=2`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPermissionsList(type = 'all', token) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/permission-lists?type=${type}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getRoesWithPermissionsList() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/roles`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getModelList(status) {
		return this.handelAlert((res, rej, loadObj) => {
			const url = status ? `${base}/api/models?status=${status}` : `${base}/api/models`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	searchModelList(qery, status) {
		return this.handelAlert((res, rej, loadObj) => {
			const url = `${base}/api/models?search=${qery}&status=${status}`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// Add Role
	createRole(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/roles`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// Update Role
	updateRole(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/roles/${id}`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getRolePermissionsList(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/roles/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateRolePermissions(role_id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/roles/${role_id}/permissions`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// delete Role
	deleteRole(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/roles/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getAllstatus() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/get-status-list`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					// apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	getAllStatusTypes() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/get-status-type-list`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					// apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	getStatusList() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/get-status-list`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	currentConverter(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/get-usd-converted-price`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	paginationapi(url) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${url}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	selectAllUser() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/users?type=all&status=1`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getAppSettings() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/app-settings`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getCurrencyRate(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/get-current-currency-rate`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	uploadAWB(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/invoice/upload-awb`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getReadyToShipPackages() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/shipments/get-ready-to-ship`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getSelectOptionsList(type) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/selections?type=${type}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

    getProductsByCategory(categoryId) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/selections?type=product_by_category&category_id=${categoryId}`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
	getEmailTemplate(type) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/email-template?type=${type}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getPurchaseInvoiceOptionsList(sup_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/selections?type=supplier_invoice&supplier_id=${sup_id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getUserNotificationsList(customLink = null) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(customLink ? customLink : `${base}/api/notification/user` + '?page_length=30', {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getUserNotifications(customLink = null) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(customLink ? customLink : `${base}/api/notification/user` + '?type=unread&action=markasread', {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	userHasUnreadNotification() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/notification/user/has-unread`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	markNotificationAsRead(date) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/notification/user/mark-as-read`,
					{
						date: date
					},
					{
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
								}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getModels() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/selections?type=model`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getRepairTypes() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/selections?type=repair_type`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getSupplierContact(id) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/api/contacts/${id}/contact`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPartsList() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/stocks/parts/lists`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	createParts(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/stocks/parts`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getExportsHistory(moduleName = 'orders') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/get-export-histories?module=${moduleName}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response.data);
				});
		}, false);
	},

	getClientGeneralInformation(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/client/general_information/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	async plainAPIRequest(url) {
		const result = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
	},

};
