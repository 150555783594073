import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
    // GET API


	getOperationLists() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operations`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},

	storeOperationsData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/operations`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	getOperationsDetails(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operations/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	updateOperationsData(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/operations/${id}/inbound`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},

	getSalesOrderPaymentDetails(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/sales-orders/payment/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},


	createOperationItemRepair(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/operations/${id}/add-repair`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},


	verifyImeiStatus(imei) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operations/verify-imei-status/${imei}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response);
				});
		}, false);
	},

	identifySerialAvailability(imei) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operations/identify-serial-availability/${imei}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response);
				});
		}, false);
	},
	getOperationItemRepairs(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operations/${id}/repairs`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	startOperationItemRepairs(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operations/${id}/start-repair`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	pauseOperationItemRepairs(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operations/${id}/pause-repair`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	resumeOperationItemRepairs(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operations/${id}/resume-repair`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	completeOperationItemRepairs(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operations/${id}/complete-repair`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	setOperationItemGrade(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/operations/${id}/set-item-grade`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteOperationItemRepair(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/operations/${id}/delete-repair`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	deleteOperationInboundItem(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/operations/${id}/delete-inbound`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	deleteOperation(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/operations/${id}/delete`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},

    filterOperations(filters, search = null) {
        let url = `${base}/api/operations?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
	},
	
	getOperationsFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/operation/get-filter`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	createPicklistFromOperations(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/operation/create-picklist`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
}