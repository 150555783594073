import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPlus, FaSave, FaSpinner } from 'react-icons/fa';
import { CloseIcon, SettingsBankIcon } from 'ui-component/custom-icons/icons'
import toast from 'react-hot-toast';
import Select from 'react-select';

import StatusBadge from 'ui-component/StatusBadge';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { set } from 'draft-js/lib/EditorState';
import { ConstructionOutlined } from '@mui/icons-material';

const BankModal = ({ openPopup, details, handleClose, handleReloadList }) => {

    const [information, setInformation] = useState(null);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isFormProcessing, setIsFormProcessing] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);

    useEffect(() => {
        initializeData()
    }, []);
    
    const initializeData = () => {
        setInformation({
            ...information,
            id: details?.id,
            currency: details?.currency,
            currencyId: details?.currencyId,
            bankName: details?.bankName,
            beneficiary: details?.beneficiary,
            iban: details?.iban,
            swiftCode: details?.swiftCode,
            branch: details?.branch,
        });
        api.getSelectOptionsList('currency').then((res) => {
            const collection = res?.data?.map((item) => ({ id: item.id, label: item.currency, value: item.id }));
            setCurrencyList(collection);
		});
        setIsDataLoaded(true);
    }

    const handleProcessSubmit = () => {
        setIsFormProcessing(true);
        // handleSubmit(information);
        if (information?.currency == null || information?.currency == '') {
            toast.error('Currency is required');
            setIsFormProcessing(false);
            return false;
        } else if (information?.bankName == null || information?.bankName == '') {
            toast.error('Bank name is required');
            setIsFormProcessing(false);
            return false;
        } else if (information?.beneficiary == null || information?.beneficiary == '') {
            toast.error('Beneficiary is required');
            setIsFormProcessing(false);
            return false;
        } else if (information?.iban == null || information?.iban == '') {
            toast.error('IBAN is required');
            setIsFormProcessing(false);
            return false;
        } else if (information?.swiftCode == null || information?.swiftCode == '') {
            toast.error('Swift code is required');
            setIsFormProcessing(false);
            return false;
        } else if (information?.branch == null || information?.branch == '') {
            toast.error('Branch is required');
            setIsFormProcessing(false);
            return false;
        }
        if (information?.id == null || information?.id == '') {
            toast.promise(
                api
                    .settingsCreateBank(information)
                    .then((res) => {
                    if (res.status) {
                        handleReloadList()
                    }
    
                    return res?.status == true ? res : null;
                }),
    
                {
                    loading: 'Creating bank details...',
                    success: (data) => `${data.message}`,
                    error: <b>Creating bank details Failed</b>
                }
            );
        } else {
            toast.promise(
                api.settingsUpdateBank(information)
                    .then((res) => {
                        if (res.status) {
                            handleReloadList()
                        }

                        return res?.status == true ? res : null;
                    }),

                {
                    loading: 'Updating bank details...',
                    success: (data) => `${data.message}`,
                    error: <b>Updating bank details failed</b>
                }
            );
        }
    }

    const handleProcessEnter = (event) => {
        if (event.key === 'Enter') {
            handleProcessSubmit();
        }
    }
    
    return (
        <>
            <Modal show={openPopup}  centered size="md">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center color-theme">
                                        <SettingsBankIcon height={'60px'} width={'60px'} />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Bank Details
                                    </div>
                                    <div className='textGray'>
                                        This bank details will be shown in every invoices
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    Bank Name
                                </div>
                                <div className="col-12 form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                        value={information?.bankName}
                                        placeholder='Enter bank name'
                                        onChange={(e) => {
                                            setInformation({
                                                ...information,
                                                bankName: e.target.value
                                            })
                                        }}
                                        // onKeyDown={(e) => {
                                        //     handleProcessEnter(e);
                                        // }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    Curency
                                </div>
                                <div className="col-12 form-group">
                                    <Select
                                        options={currencyList}
                                        className="rs-container left-input"
                                        classNamePrefix="rs"
                                        placeholder="Select or type..."
                                        isMulti={false}
                                        onChange={(e) => {
                                            setInformation({
                                                ...information,
                                                currencyId: e?.id,
                                                currency: e?.label
                                            })
                                            // handleChangeOriginCurrency(index, e)
                                        }}
                                        value={currencyList?.find(option => option.id === information?.currencyId) || null}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    Beneficiary
                                </div>
                                <div className="col-12 form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                        value={information?.beneficiary}
                                        placeholder='Enter beneficiary'
                                        onChange={(e) => {
                                            setInformation({
                                                ...information,
                                                beneficiary: e.target.value
                                            })
                                        }}
                                        // onKeyDown={(e) => {
                                        //     handleProcessEnter(e);
                                        // }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    IBAN
                                </div>
                                <div className="col-12 form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                        value={information?.iban}
                                        placeholder='Enter IBAN'
                                        onChange={(e) => {
                                            setInformation({
                                                ...information,
                                                iban: e.target.value
                                            })
                                        }}
                                        // onKeyDown={(e) => {
                                        //     handleProcessEnter(e);
                                        // }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    Swift Code
                                </div>
                                <div className="col-12 form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                        value={information?.swiftCode}
                                        placeholder='Enter swift code'
                                        onChange={(e) => {
                                            setInformation({
                                                ...information,
                                                swiftCode: e.target.value
                                            })
                                        }}
                                        onKeyDown={(e) => {
                                            handleProcessEnter(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    Branch
                                </div>
                                <div className="col-12 form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                        value={information?.branch}
                                        placeholder='Enter bank branch'
                                        onChange={(e) => {
                                            setInformation({
                                                ...information,
                                                branch: e.target.value
                                            })
                                        }}
                                        // onKeyDown={(e) => {
                                        //     handleProcessEnter(e);
                                        // }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top mt10" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    <button
                                        type="button"
                                        className="btn-block btn-primary btn-outlined-main-primary  white-var"
                                        disabled={!isDataLoaded || isFormProcessing}
                                        onClick={() => {
                                            if (!isFormProcessing) {
                                                handleProcessSubmit()
                                            }
                                        }}
                                        style={{ lineHeight: '25px' }}
                                    >
                                        {
                                            isFormProcessing ? (
                                                <>
                                                    <FaSpinner className="fa-spin" /> Saving...
                                                </>
                                            ) : (
                                                <>
                                                    <FaSave /> Save
                                                </>
                                            )
                                        }
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BankModal;
