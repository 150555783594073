import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchBar } from 'store/reducers/mainSlice';

import { FaSearch } from 'react-icons/fa';

const SearchBar = ({ searchField, handleSearch, autoSuggest }) => {
	const path = window.location.pathname;
	const pathSegments = path.split('/');
	const lastSegment = pathSegments[pathSegments.length - 1];
	const searchBar = useSelector((state) => state.main.searchBar);
	const dispatch = useDispatch();
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

	if (!isMobile) {
		return <></>;
	}

	return (
		<div
			style={{
				position: 'fixed', // Change from 'absolute' to 'fixed'
				top: '92px', // Add this
				left: 0, // Add this
				width: '100vw', // Change from '100%' to '100vw'
				height: '100vh', // Change from '100%' to '100vh'
				transform: searchBar === true ? 'translateY(0)' : 'translateY(100%)', // Change from 'translateY(0)' to 'translateY(-100%)'
				transition: 'transform 0.3s ease-in-out',
				backgroundColor: 'white',
				border: 'none',
				padding: 20,
				zIndex: 10
			}}
		>
			<button className="btn modal-close-icon" style={{ top: '-17px' }} onClick={() => dispatch(setSearchBar(!searchBar))}>
				<svg
					id="Component_127_1"
					height="16"
					width="16"
					data-name="Component 127 – 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 19.295 19.289"
				>
					<path
						id="Icon_ionic-ios-close"
						data-name="Icon ionic-ios-close"
						d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
						transform="translate(-11.285 -11.289)"
					/>
				</svg>
			</button>
			<div className="row" style={{ marginTop: 5 }}>
				<div className="col-12">
					<form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
						<div className="input-group" style={{ position: 'relative' }}>
							<input
								type="text"
								className="form-control bg-white border"
								placeholder={lastSegment === 'products' ? 'Search for product SKU or name' : 'Search'}
								aria-label="Search"
								aria-describedby="basic-addon2"
								style={{ paddingRight: '30px', borderRadius: '50px' }} // Add some padding to make room for the search icon\
								value={searchField}
								onChange={(e) => {
									handleSearch(e);
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										dispatch(setSearchBar(!searchBar));
									}
								}}
							/>
							<span className="searchIcon" style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)' }}>
								<FaSearch color={'#888888'} />
							</span>
						</div>
					</form>
				</div>
			</div>
			<div className="row" style={{ marginTop: 10 }}>
				<div className="col-12">
					{autoSuggest.length > 0 && (
						<ul className="list-group" style={{ position: 'absolute', top: '100%', left: 0, width: '100%', zIndex: 10 }}>
							{autoSuggest.map((suggestion, index) => (
								<li
									key={index}
									className="list-group-item mobile-search"
									style={{ cursor: 'pointer', marginRight: 10, marginLeft: 5, border: 'none' }}
									onClick={() => {
										handleSearch({ target: { value: suggestion } });
										dispatch(setSearchBar(!searchBar));
									}}
								>
									{suggestion}
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
};

export default SearchBar;
