const CommentBadge = ({ count }) => (
    <span style={{
        backgroundColor: 'red',
        borderRadius: '50%',
        color: 'white',
        padding: '2px 6px',
        fontSize: '7px',
        marginLeft: '-25px',
        fontWeight: '600',
        marginTop: '-4px',
        position: 'absolute'
    }}>
        {count}
    </span>
);
export default CommentBadge;