import React, { useState, useEffect } from 'react';
import 'assets/scss/charts.scss';

function App() {
    const [permissions, setPermissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [changes, setChanges] = useState({});

    useEffect(() => {
        api.getDashboardWidgets().then((res) => {
            setIsLoading(false);
            setPermissions(res.data);
            setIsLoading(true);
        });
    }, []);

    const handleVisibilityChange = (widget_name, isVisible) => {
        setChanges({
            ...changes,
            [widget_name]: isVisible
        });

        setPermissions(permissions.map(permission =>
            permission.widget_name === widget_name ? { ...permission, is_visible: isVisible } : permission
        ));
    };
    const handleSave = () => {
        api.updateDashboardPermissions(changes).then((res) => {
            console.log(res);
        });
    };

    return (
        <div className="row" style={{ marginTop: '20px' }}>
            <div className="col-12">
                <div className="card">
                    <div className="card-header align-text-center" style={{ backgroundColor: 'white' }}>
                        <h3 style={{ padding: 0, margin: 0, marginTop: '5px' }}>Permission Settings</h3>
                    </div>
                    <div className="card-body">
                        <form>
                            {permissions.map((permission, index) => (
                                <div className='row' key={index} style={{ padding: '10px', backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#fff', borderRadius: '5px' }}>
                                    <div className='col-12 d-flex justify-content-between align-items-center'>
                                        <div style={{ fontSize: '12px', fontWeight: '500' }}>
                                            {permission.widget_name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                        </div>
                                        <div className="switch">
                                            <input
                                                type="checkbox"
                                                checked={permission.is_visible === 1}
                                                onChange={(e) => handleVisibilityChange(permission.widget_name, e.target.checked ? 1 : 0)}
                                            />
                                            <span className="slider round"></span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="text-right" style={{ marginTop: '10px' }}>
                                <button type="button" className="btn btn-sm btn-primary" onClick={handleSave}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;