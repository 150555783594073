import { Outlet } from 'react-router-dom';

// project imports

// ==============================|| MINIMAL LAYOUT ||============================== //

const MainLayout = () => (
	<>
		<Outlet />
		{/* <Customization /> */}
	</>
);

export default MainLayout;
