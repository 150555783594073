import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useEffect } from 'react';
import ScrollButton from 'ui-component/ScrollToTopButton';
import GlobalSearch from 'ui-component/global-search/GlobalSearch';
import { useHotkeys } from 'react-hotkeys-hook';
import toast, { Toaster } from 'react-hot-toast';
import { setDisplayTheme, storeClientDetails } from 'store/reducers/mainSlice';
import { useDispatch, useSelector } from 'react-redux';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { AiOutlinePoweroff } from 'react-icons/ai';
import '../client.scss';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { FaCircleXmark } from 'react-icons/fa6';
import Select from 'react-select';
import NavBar from '../NavBar/NavbarLogin';
import Oval1 from 'assets/images/designs/Oval-1.png';
import Oval2 from 'assets/images/designs/Oval.png';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';

const AuthenticationLayout = () => {
	const history = useNavigate();
	const [displayTheme] = useSelector((state) => [state.main.displayTheme]);
	const logout = useSelector((state) => state.main.logout);
	const dispatch = useDispatch();
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	const clientUser = useSelector((state) => state.main.client);
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [login, setLogin] = useState(true);
	const [countryList, setCountryList] = useState([]);
	const [data, setData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		number: '',
		password: '',
		confirmPassword: '',
		countryCode: '+971',
		companyName: '',
		active: ''
	});
	const [passwordStrength, setPasswordStrength] = useState(0);
	const [passwordChecking, setPasswordChecking] = useState(false);
	const [passCharacter, setPassCharacter] = useState(false);
	const [passCapital, setPassCapital] = useState(false);
	const [passNumber, setPassNumber] = useState(false);
	const [passSpecial, setPassSpecial] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [fullNumber, setFullNumber] = useState('');
	const [selectedCountry, setSelectedCountry] = useState('AE');

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		document.body.id = 'page-top';
		if (!displayTheme) {
			if (localStorage.getItem('displayTheme')) {
				dispatch(setDisplayTheme(localStorage.getItem('displayTheme')));
			} else {
				dispatch(setDisplayTheme('lightMode'));
			}
		}

		if (clientUser?.token) {
			history('/client/orders');
		}
	}, []);

	useEffect(() => {
		if (!data?.password) {
			return;
		}
		if (data?.password) {
			if (data?.password.length >= 8) {
				setPassCharacter(true);
			} else {
				setPassCharacter(false);
			}
			if (/[A-Z]/.test(data?.password)) {
				setPassCapital(true);
			} else {
				setPassCapital(false);
			}
			if (/[0-9]/.test(data?.password)) {
				setPassNumber(true);
			} else {
				setPassNumber(false);
			}
			if (/[!@#$%^&*(),.?":{}|<>]/.test(data?.password)) {
				setPassSpecial(true);
			} else {
				setPassSpecial(false);
			}
		}
	}, [data.password]);

	useEffect(() => {
		api.getCountryList().then((res) => {
			if (res.status === true) {
				setCountryList(res.data);
			}
		});
	}, []);

	// const trigger = () => {
	// 	api.getCountryList().then((res) => {
	// 		console.log(res);
	// 		if (res.status === true) {
	// 			setCountryList(res.data);
	// 		}
	// 	});
	// };

	useEffect(() => {
		const firstNameElement = document.getElementById('firstName');
		if (firstNameElement) {
			firstNameElement.value = '';
		}
	}, []);

	useEffect(() => {
		document.body.className = displayTheme;
	}, [displayTheme]);

	const SignIn = (e) => {
		const signIndata = {
			email: email,
			password: password
		};

		e.preventDefault();
		if (email === '' || password === '') {
			toast.error('All fields are required');
			return;
		}
		api.loginClient(signIndata).then((res) => {
			if (res.message && res.token) {
				dispatch(storeClientDetails({ ...res, stayLoggedIn: true }));
				toast.success('Client login successfully');
				if (res?.data?.is_approved === 2) {
					history('/client/dashboard');
				} else if (res?.data?.is_approved === 0) {
					history('/client/company-details');
				} else {
					history('/client/company-verified');
				}
			} else {
				toast.error('Invalid credentials');
			}
		});
	};
	const Signup = () => {
		if (
			data.firstName === '' ||
			data.lastName === '' ||
			data.email === '' ||
			data.number === '' ||
			data.password === '' ||
			data.confirmPassword === '' ||
			data.companyName === '' ||
			data.countryCode === ''
		) {
			toast.error('All fields are required');
			return;
		}

		if (data.password !== data.confirmPassword) {
			toast.error('Password and Confirm Password should be same');
			return;
		}
		if (data.number.length < 9) {
			toast.error('Phone number should be atleast 9 digits');
			return;
		}
		if (data.password.length < 8) {
			toast.error('Password should be atleast 8 characters');
			return;
		}
		if (data.password.search(/[A-Z]/) < 0) {
			toast.error('Password should contain atleast 1 uppercase letter');
			return;
		}
		if (data.password.search(/[a-z]/) < 0) {
			toast.error('Password should contain atleast 1 lowercase letter');
			return;
		}
		if (data.password.search(/[0-9]/) < 0) {
			toast.error('Password should contain atleast 1 number');
			return;
		}
		if (data.password.search(/[!@#$%^&*]/) < 0) {
			toast.error('Password should contain atleast 1 special character');
			return;
		}
		if (data.password.search(/\s/) >= 0) {
			toast.error('Password should not contain any spaces');
			return;
		}
		if (data.password.search(data.email) >= 0) {
			toast.error('Password should not contain email');
			return;
		}

		api.addClient(data).then((res) => {
			if (res.status === true) {
				toast.success(res.message);
				const client_data = {
					data: res.data,
					token: res.data.api_token
				};
				setLogin(true);
				dispatch(storeClientDetails({ ...client_data, stayLoggedIn: true }));
				history('/client/company-details');
			} else {
				if (res.status != 200) {
					toast.error(res?.data?.message);
				}
			}
		});
	};
	const SignupLink = () => {
		setLogin(false);
	};
	const SignInLink = () => {
		setLogin(true);
	};

	const ForgotPassword = () => {
		history('/client/forgot');
	};
	const countryCodes = countryList.reduce((unique, country) => {
		const isDuplicate = unique.find((item) => item.label === `+${country.phonecode}`);

		if (!isDuplicate) {
			unique.push({
				label: `+${country.phonecode}`,
				value: `+${country.phonecode}`
			});
		}

		return unique;
	}, []);

	const changeNumber = (phone) => {
		setFullNumber(phone?.number);
	};

	const changeFullNumber = () => {
		setData({ ...data, number: fullNumber });
	};

	function checkPasswordStrength(password) {
		// At least 8 characters
		const lengthCheck = password.length >= 8;

		// At least 1 capital letter
		const capitalCheck = /[A-Z]/.test(password);

		// At least 1 number
		const numberCheck = /[0-9]/.test(password);

		// At least 1 special character
		const specialCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password);

		// Count the number of digits
		const digitCount = (password.match(/[0-9]/g) || []).length;

		// Count the number of special characters
		const specialCount = (password.match(/[!@#$%^&*(),.?":{}|<>]/g) || []).length;

		// Calculate strength based on the checks
		let strength = lengthCheck + capitalCheck + numberCheck + specialCheck;

		// Check for 'very strong' password
		if (digitCount >= 5 || specialCount >= 5) {
			strength = 4;
		} else if (strength <= 2) {
			strength = 1;
		} else if (strength <= 3) {
			strength = 2;
		} else {
			strength = 3;
		}

		return strength;
	}
	return (
		<>
			<div id="parent-client-wrapper">
				<div id="wrapper">
					<NavBar />

					<div
						id="new-wrapper"
						style={{
							fontFamily: 'Poppins, sans-serif'
						}}
					>
						<div id="content-wrapper" className="d-flex flex-column" style={{ backgroundColor: 'transparent' }}>
							<div id="content" style={{ position: 'relative' }}>
								<div className="container-fluid d-flex justify-content-center">
									<div style={{ maxWidth: '2200px', width: '100%', height: '90vh' }}>
										<div className={login ? 'main-page-auth' : 'main-page-auth-2'}>
											<div className={login ? 'backdrop-image-purple' : 'backdrop-image-purple-sign'}>
												<img src={Oval1} alt="Oval1" style={{ opacity: '50%' }} />
											</div>
											<div className={login ? 'backdrop-image-pink' : 'backdrop-image-pink-sign'}>
												<img src={Oval2} alt="Oval2" style={{ opacity: '70%' }} />
											</div>
											<div className="invoice-client-content">
												{login ? (
													<div
														className="form-signup"
														style={{ justifyContent: 'space-between' }}
														onKeyPress={(event) => {
															if (event.key === 'Enter') {
																event.preventDefault();
																SignIn(event);
															}
														}}
													>
														<div className="signup-head" style={{ marginBottom: 20 }}>
															<h4 style={{ marginBottom: 5 }}>Sign in to our Portal</h4>
															<p style={{ color: 'black' }}>
																Don't have an account?{' '}
																<span className="sign-link" onClick={() => SignupLink()}>
																	Sign Up
																</span>{' '}
															</p>
														</div>
														<div className="row" style={{ marginBottom: 10 }}>
															{!isMobile && (
																<div className="col-4 d-flex justify-content-left align-items-center">
																	<div style={{ fontSize: 14, fontWeight: 300 }}>Email</div>
																</div>
															)}
															<div className={isMobile ? 'col-12' : 'col-8'}>
																<input
																	type="text"
																	className="form-control"
																	placeholder="Email@gmail.com"
																	style={{ color: 'black', borderColor: '#0066FF' }}
																	autocomplete="nope"
																	onChange={(e) => {
																		setEmail(e.target.value);
																	}}
																/>
															</div>
														</div>
														<div className="row" style={{ marginBottom: 20 }}>
															{!isMobile && (
																<div className="col-4 d-flex justify-content-left align-items-center">
																	<div style={{ fontSize: 14, fontWeight: 300 }}>Password</div>
																</div>
															)}
															<div className={isMobile ? 'col-12' : 'col-8'}>
																<input
																	type="password"
																	className="form-control"
																	placeholder="•••••••••••"
																	style={{
																		color: 'black',
																		borderColor: '#0066FF',
																		textAlign: 'left',
																		padding: '10px 0' // Adjust this value as needed
																	}}
																	autocomplete="new-password"
																	onChange={(e) => {
																		setPassword(e.target.value);
																	}}
																/>
															</div>
														</div>
														<div className="signup-head" style={{ marginBottom: 10 }}>
															<p style={{ color: 'black' }}>
																Forgot password?{' '}
																<span className="sign-link" onClick={() => ForgotPassword()}>
																	Reset here
																</span>{' '}
															</p>
														</div>
														<div className="action-signup">
															<button className="btn btn-cmpny-dtls-action" type="submit" onClick={(e) => SignIn(e)}>
																Sign In
															</button>
														</div>
													</div>
												) : (
													<div
														className="form-signup"
														style={{ justifyContent: 'space-between', width: isMobile ? '100%' : '75%' }}
														onKeyPress={(event) => {
															if (event.key === 'Enter') {
																event.preventDefault();
																Signup();
															}
														}}
													>
														<div className="signup-head" style={{ marginBottom: 20 }}>
															<h4 style={{ marginBottom: 5 }}>Sign up to our Portal</h4>
															<p>
																Already have an account?{' '}
																<span className="sign-link" onClick={() => SignInLink()}>
																	Sign In
																</span>{' '}
															</p>
														</div>
														<table className="projectmix-table-vertical-client">
															<tbody>
																<tr style={{ display: 'none' }}>
																	<td>
																		<input type="text" id="dummyFirstName" name="dummyFirstName" />
																		<input type="text" id="dummyLastName" name="dummyLastName" />
																		<input type="text" id="dummyThirdName" name="dummyThirdName" />
																	</td>
																</tr>

																<tr>
																	{!isMobile && <th>First name</th>}
																	<td style={{ display: 'flex', justifyContent: 'flex-end' }}>
																		<div style={{ width: isMobile ? '100%' : '94%' }}>
																			<input
																				type="text"
																				id="firstName"
																				className="form-control"
																				placeholder="First Name"
																				style={{ borderColor: '#0066FF' }}
																				autocomplete="new-password"
																				onChange={(e) => {
																					setData({ ...data, firstName: e.target.value });
																				}}
																			/>
																		</div>
																	</td>
																</tr>
																<tr>
																	{!isMobile && <th>Last name</th>}
																	<td style={{ display: 'flex', justifyContent: 'flex-end' }}>
																		<div style={{ width: isMobile ? '100%' : '94%' }}>
																			<input
																				type="text"
																				id="lastName"
																				name="lastName"
																				className="form-control"
																				placeholder="Last Name"
																				style={{ borderColor: '#0066FF' }}
																				onChange={(e) => {
																					setData({ ...data, lastName: e.target.value });
																				}}
																			/>
																		</div>
																	</td>
																</tr>
																<tr>
																	{!isMobile && <th>Email</th>}
																	<td style={{ display: 'flex', justifyContent: 'flex-end' }}>
																		<div style={{ width: isMobile ? '100%' : '94%' }}>
																			<input
																				type="text"
																				id="email"
																				className="form-control"
																				placeholder="Email"
																				style={{ borderColor: '#0066FF' }}
																				onChange={(e) => {
																					setData({ ...data, email: e.target.value });
																				}}
																			/>
																		</div>
																	</td>
																</tr>
																<tr>
																	{!isMobile && <th>Company</th>}
																	<td style={{ display: 'flex', justifyContent: 'flex-end' }}>
																		<div style={{ width: isMobile ? '100%' : '94%' }}>
																			<input
																				type="text"
																				id="company"
																				className="form-control"
																				placeholder="Company Name"
																				style={{ borderColor: '#0066FF' }}
																				onChange={(e) => {
																					setData({ ...data, companyName: e.target.value });
																				}}
																			/>
																		</div>
																	</td>
																</tr>
																<tr>
																	{!isMobile && <th>Phone</th>}
																	<td style={{ display: 'flex', justifyContent: 'flex-end' }}>
																		<div style={{ width: isMobile ? '100%' : '94%' }}>
																			<div className="phn-cmpny-dtls">
																				<PhoneInput
																					international
																					defaultCountry={selectedCountry}
																					onCountryChange={(country) => {
																						setData({ ...data, number: '' });
																						setSelectedCountry(country);
																					}}
																					onChange={(phone) => {
																						// Ensure phone is a string before parsing
																						// const phoneString = typeof phone === 'string' ? phone : '';
																						// const phoneNumber = parsePhoneNumberFromString(phoneString);
																						// if (phoneNumber) {
																						// 	const phoneCode = phoneNumber.countryCallingCode;
																						// 	const nationalNumber = phoneNumber.nationalNumber;
																						// 	changeNumber(phoneNumber);
																						// 	setSelectedCountry(phoneCode);
																						// } else {
																						// 	setData({ ...data, number: '' });
																						// }
																						if (typeof phone === 'string') {
																							const phoneNumber = parsePhoneNumberFromString(phone);
																							if (phoneNumber) {
																								const phoneCode = phoneNumber.country;
																								changeNumber(phoneNumber);
																								setSelectedCountry(phoneCode);
																							} else {
																								setData({ ...data, number: '' });
																							}
																						} else {
																							setData({ ...data, number: '' });
																						}
																					}}
																					onBlur={changeFullNumber}
																					style={{ width: '100%', borderColor: '#0066FF' }}
																					className="sign-up-phone-input"
																				/>
																			</div>
																		</div>
																	</td>
																</tr>
																<tr>
																	{!isMobile && <th>Password</th>}
																	<td style={{ display: 'flex', justifyContent: 'flex-end' }}>
																		<OverlayTrigger
																			placement={windowWidth < 650 ? 'bottom' : windowWidth < 800 ? 'left' : 'right'}
																			popperConfig={{
																				modifiers: [
																					{
																						name: 'applyStyles',
																						options: {
																							className: 'white-tooltip'
																						}
																					}
																				]
																			}}
																			overlay={
																				<Tooltip id="password-tooltip" className="white-tooltip">
																					<ul style={{ textAlign: 'left', padding: 0, margin: 0, listStyleType: 'none' }}>
																						<li style={{ color: passCharacter ? '#36c880' : '#f64e60' }}>
																							{passCharacter ? <FaCheckCircle color={'#36c880'} /> : <FaCircleXmark color={'#f64e60'} />} 8
																							Characters
																						</li>
																						<li style={{ color: passCapital ? '#36c880' : '#f64e60' }}>
																							{passCapital ? <FaCheckCircle color={'#36c880'} /> : <FaCircleXmark color={'#f64e60'} />}{' '}
																							Atleast 1 Capital Letter
																						</li>
																						<li style={{ color: passNumber ? '#36c880' : '#f64e60' }}>
																							{passNumber ? <FaCheckCircle color={'#36c880'} /> : <FaCircleXmark color={'#f64e60'} />}{' '}
																							Atleast 1 Number
																						</li>
																						<li style={{ color: passSpecial ? '#36c880' : '#f64e60' }}>
																							{passSpecial ? <FaCheckCircle color={'#36c880'} /> : <FaCircleXmark color={'#f64e60'} />}{' '}
																							Atleast 1 Special Character
																						</li>
																					</ul>
																					{isMobile && (
																						<div style={{ width: '100%', height: '7px', backgroundColor: '#f2f2f2', borderRadius: 10 }}>
																							<div
																								style={{
																									width: `${(passwordStrength / 4) * 100}%`,
																									height: '100%',
																									borderRadius: '10px',
																									backgroundColor:
																										passwordStrength === 1
																											? '#f54e60'
																											: passwordStrength === 2
																											? '#ed751c'
																											: passwordStrength === 3
																											? '#ffba49'
																											: '#36c880',
																									transition: 'width 0.5s ease, background-color 0.5s ease'
																								}}
																							></div>
																							<a
																								style={{
																									color:
																										passwordStrength === 1
																											? '#f54e60'
																											: passwordStrength === 2
																											? '#ed751c'
																											: passwordStrength === 3
																											? '#ffba49'
																											: passwordStrength === 4
																											? '#36c880'
																											: '#888888'
																								}}
																							>
																								{passwordStrength === 1
																									? 'Weak'
																									: passwordStrength === 2
																									? 'Medium'
																									: passwordStrength === 3
																									? 'Strong'
																									: passwordStrength === 4
																									? 'Very Strong'
																									: 'Password Strength'}
																							</a>{' '}
																						</div>
																					)}
																				</Tooltip>
																			}
																		>
																			<div style={{ width: isMobile ? '100%' : '94%' }}>
																				<input
																					type="password"
																					id="password"
																					className="form-control"
																					placeholder="•••••••••••"
																					style={{ borderColor: '#0066FF' }}
																					autocomplete="new-password"
																					onFocus={() => setPasswordChecking(true)}
																					onBlur={() => setPasswordChecking(false)}
																					onChange={(e) => {
																						const password = e.target.value;
																						setData({ ...data, password: password });

																						// Check password strength
																						const strength = checkPasswordStrength(password);
																						setPasswordStrength(strength);
																					}}
																				/>
																			</div>
																		</OverlayTrigger>
																	</td>
																</tr>
																{passwordChecking && !isMobile && (
																	<tr>
																		{!isMobile && <th></th>}
																		<td style={{ paddingLeft: 20, paddingBottom: 20, paddingRight: 7, paddingTop: 10 }}>
																			<div style={{ width: '100%', height: '7px', backgroundColor: '#f2f2f2', borderRadius: 10 }}>
																				<div
																					style={{
																						width: `${(passwordStrength / 4) * 100}%`,
																						height: '100%',
																						borderRadius: '10px',
																						backgroundColor:
																							passwordStrength === 1
																								? '#f54e60'
																								: passwordStrength === 2
																								? '#ed751c'
																								: passwordStrength === 3
																								? '#ffba49'
																								: '#36c880',
																						transition: 'width 0.5s ease, background-color 0.5s ease'
																					}}
																				></div>
																				<a
																					style={{
																						color:
																							passwordStrength === 1
																								? '#f54e60'
																								: passwordStrength === 2
																								? '#ed751c'
																								: passwordStrength === 3
																								? '#ffba49'
																								: passwordStrength === 4
																								? '#36c880'
																								: '#888888'
																					}}
																				>
																					{passwordStrength === 1
																						? 'Weak'
																						: passwordStrength === 2
																						? 'Medium'
																						: passwordStrength === 3
																						? 'Strong'
																						: passwordStrength === 4
																						? 'Very Strong'
																						: 'Password Strength'}
																				</a>{' '}
																			</div>
																		</td>
																	</tr>
																)}
																<tr>
																	{!isMobile && <th>Confirm Password</th>}
																	<td style={{ display: 'flex', justifyContent: 'flex-end' }}>
																		<div style={{ width: isMobile ? '100%' : '94%' }}>
																			<input
																				type="password"
																				id="confirmPassword"
																				className="form-control"
																				placeholder="•••••••••••"
																				style={{ borderColor: '#0066FF' }}
																				autocomplete="new-password"
																				onChange={(e) => {
																					setData({ ...data, confirmPassword: e.target.value });
																				}}
																			/>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
														<div className="action-signup" style={{ marginTop: 10 }}>
															<button className="btn btn-cmpny-dtls-action" type="submit" onClick={() => Signup()}>
																Sign Up
															</button>
														</div>
													</div>
												)}
											</div>
										</div>
										<div
											style={{
												position: 'fixed',
												bottom: 20,
												left: '50%',
												transform: 'translate(-50%, 0)',
												textAlign: 'center'
											}}
										>
											© 2024 Projectmix Ai. <span style={{ display: 'inline-block' }}>All Rights Reserved</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Toaster
				toastOptions={{
					className: 'toaster-styles-2'
				}}
			/>
		</>
	);
};

export default AuthenticationLayout;
