import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-hot-toast';
import { FaCircleCheck } from 'react-icons/fa6';

const CompanyBankSettings = () => {
	const [companyData, setCompanyData] = useState(null);
	const clientUser = useSelector((state) => state.main.client);
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	useEffect(() => {
		if (clientUser?.data?.id) {
			api.getClientInformationBank(clientUser?.data?.id).then((res) => {
				setCompanyData(res.data);
			});
		}
	}, []);
	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 768);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleInputChange = (field, value) => {
		setCompanyData((prevState) => ({
			...prevState,
			companyBank: {
				...prevState.companyBank,
				[field]: value
			}
		}));
		setIsFormChanged(true);
	};

	const SaveCompanydetails = () => {
		api.UpdateClientInformationBank(companyData).then((res) => {
			if (res.status == true) {
				toast.success(res.message);
			} else {
				toast.error(res.message);
			}
			setIsFormChanged(false);
		});
	};

	return (
		<>
			{isMobile && (
				<div className="approved-mobile">
					<div className="verified-mobile">
						<span className="menuIcon">
							<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
								<defs>
									<clipPath id="clip-path">
										<rect
											id="Rectangle_138"
											data-name="Rectangle 138"
											width="22"
											height="22"
											transform="translate(22 557)"
											stroke="#707070"
											stroke-width="1"
										/>
									</clipPath>
								</defs>
								<g id="Mask_Group_5" data-name="Mask Group 5" transform="translate(-22 -557)" clip-path="url(#clip-path)">
									<g id="settings" transform="translate(23.102 557)">
										<path
											id="Path_202"
											data-name="Path 202"
											d="M10.666,7a3.666,3.666,0,1,0,3.666,3.666A3.666,3.666,0,0,0,10.666,7Zm0,5.5A1.833,1.833,0,1,1,12.5,10.666,1.833,1.833,0,0,1,10.666,12.5Z"
											transform="translate(-0.771 0.332)"
											fill="currentColor"
										/>
										<path
											id="Path_203"
											data-name="Path 203"
											d="M19.465,12.739l-.407-.235a8.34,8.34,0,0,0,0-3.015l.407-.235a2.751,2.751,0,1,0-2.749-4.766l-.408.236A8.227,8.227,0,0,0,13.7,3.22v-.47a2.749,2.749,0,1,0-5.5,0v.47a8.227,8.227,0,0,0-2.61,1.508l-.41-.237A2.751,2.751,0,1,0,2.429,9.256l.407.235a8.34,8.34,0,0,0,0,3.015l-.407.235a2.751,2.751,0,0,0,2.749,4.766l.408-.236a8.227,8.227,0,0,0,2.612,1.5v.47a2.749,2.749,0,1,0,5.5,0v-.47a8.227,8.227,0,0,0,2.61-1.509l.41.236a2.751,2.751,0,0,0,2.749-4.766ZM17.13,9.278a6.459,6.459,0,0,1,0,3.437.916.916,0,0,0,.425,1.038l.993.574a.917.917,0,1,1-.916,1.588l-1-.576a.916.916,0,0,0-1.114.151,6.4,6.4,0,0,1-2.972,1.718.916.916,0,0,0-.688.888v1.148a.916.916,0,1,1-1.833,0V18.1a.916.916,0,0,0-.688-.888,6.4,6.4,0,0,1-2.971-1.722.916.916,0,0,0-1.114-.151l-.993.575a.916.916,0,1,1-.916-1.587l.993-.574a.916.916,0,0,0,.425-1.038,6.459,6.459,0,0,1,0-3.437A.916.916,0,0,0,4.34,8.241l-.993-.574a.917.917,0,1,1,.916-1.588l1,.576a.916.916,0,0,0,1.113-.148A6.4,6.4,0,0,1,9.343,4.789a.916.916,0,0,0,.688-.892V2.749a.916.916,0,1,1,1.833,0V3.9a.916.916,0,0,0,.688.888,6.4,6.4,0,0,1,2.972,1.722.916.916,0,0,0,1.114.151l.993-.575a.916.916,0,1,1,.916,1.587l-.993.574a.916.916,0,0,0-.424,1.035Z"
											transform="translate(-1.052 0)"
											fill="currentColor"
										/>
									</g>
								</g>
							</svg>
						</span>
						<span style={{ marginLeft: 5, fontSize: 16 }}>Settings</span>
					</div>
				</div>
			)}
			<div className="main-page-client">
				<div className="order-client-content">
					<div className="heading-order-client-tbl" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h3>Bank Details</h3>
						<h3 style={{ color: '#36c880' }}>
							Submitted <FaCircleCheck />
						</h3>
					</div>
					<div className={`${isMobile ? 'settings-detail-mobile' : ''}`}>
						<div className="row" style={{ marginTop: 15, marginBottom: 10 }}>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Bank Name</label>
								<input
									type="text"
									className="form-control"
									value={companyData?.companyBank.bank_name}
									disabled
									onChange={(e) => handleInputChange('bank_name', e.target.value)}
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Bank Beneficiary</label>
								<input
									type="text"
									className="form-control"
									disabled
									value={companyData?.companyBank.bank_beneficiary_name}
									onChange={(e) => handleInputChange('bank_beneficiary_name', e.target.value)}
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Bank IBAN</label>
								<input
									type="text"
									className="form-control"
									disabled
									value={companyData?.companyBank.bank_IBAN}
									onChange={(e) => handleInputChange('bank_IBAN', e.target.value)}
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Bank SWIFT</label>
								<input
									type="text"
									className="form-control"
									disabled
									value={companyData?.companyBank.bank_SWIFT}
									onChange={(e) => handleInputChange('bank_SWIFT', e.target.value)}
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Address</label>
								<input
									type="text"
									className="form-control"
									disabled
									value={companyData?.companyBank.bank_address}
									onChange={(e) => handleInputChange('bank_address', e.target.value)}
								/>
							</div>

							<div className="col-12" style={{ marginTop: 20 }}>
								<a>This information cannot be edited. If there are any updates you would like to request, kindly contact your provider.</a>
							</div>
						</div>
					</div>
					{/* <div className="row">
						<div className="col-12 d-flex justify-content-end">
							<button className="btn btn-main-primary" disabled={!isFormChanged} onClick={() => SaveCompanydetails()}>
								Update
							</button>
						</div>
					</div> */}
				</div>
			</div>
		</>
	);
};

export default CompanyBankSettings;
