import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { FaSpinner, FaTimes, FaPlus, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { WarningIcon, CloseIcon, DeleteIcon2 } from 'ui-component/custom-icons/icons';
import NoImage from 'assets/images/no-image.png';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelect from 'react-select';
import Select from 'react-select';
import CurrencyValue from 'ui-component/CurrencyValue';
import StatusBadge from 'ui-component/StatusBadge';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import usePermissions from 'hooks/usePermissions';


const InvoiceComponent = ({ details }) => {

	const [allPermissions, hasEveryPermission] = usePermissions();
	const history = useNavigate();
    
    const handleOpenNewTab = (pageModule, id) => {
        let url = '';
        if (pageModule == 'products') {
            url = `/products/${id}`;
        } else if (pageModule == 'contacts') {
            url = `/contacts/${id}`;
        } else if (pageModule == 'purchase_orders') {
            url = `/purchasing/view/${id}`;
        } else if (pageModule == 'sales_orders') {
            url = `/sales/sales-orders/${id}`;
        } else if (pageModule == 'invoices') {
            url = `/sales/invoices/${id}`;
        } else if (pageModule == 'quotations') {
            url = `/sales/quotations/${id}`;
        }
        if (url) {
            history(url);
            // window.open(url, '_blank');
        }
    };

    return (
        <>
            
            <div style={{ padding: '0px 20px 20px 16px' }}>
			
                <div className="row mb10">
                    <div className="col-12">
                        <h2 className="d-flex font600" style={{ fontSize: '18px' }}>Invoice</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2 font600">Invoice #</div>
                    <div className="col-md-4 font600">Customer</div>
                    <div className="col-md-2 font600">Date</div>
                    <div className="col-md-2 font600">Status</div>
                    <div className="col-md-2 font600">Total</div>
                </div>

                <hr className="mt8 mb8" />

                <div className="row">
                    <div className="col-md-2 d-flex align-items-center">
                        <div
                            className={`${hasEveryPermission(['View Estimation Details (Page)']) ? 'text-primary cursor-pointer' : '' }`}
                            onClick={() => {
                                if (hasEveryPermission(['View Estimation Details (Page)'])) {
                                    handleOpenNewTab('invoices', details?.invoice?.id)
                                }
                            }}
                        >
                            {details?.invoice?.invoice_number}
                        </div>
                    </div>
                    <div className="col-md-4 d-flex align-items-center">
                        <div
                            className={`${hasEveryPermission(['View Contact Details (Page)']) ? 'text-primary cursor-pointer' : '' }`}
                            onClick={() => {
                                if (hasEveryPermission(['View Contact Details (Page)'])) {
                                    handleOpenNewTab('contacts', details?.invoice?.customer?.id)
                                }
                            }}
                        >
                            {details?.invoice?.customer?.name}
                        </div>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">{moment(details?.invoice?.date).format(CommonDateOnlyFormat)}</div>
                    <div className="col-md-2 "><div style={{ width: '90px' }}><StatusBadge itemStatus={details?.invoice?.status} /></div></div>
                    <div className="col-md-2 d-flex align-items-center"><CurrencyValue currencyObject={details?.invoice?.converted_currency_total} /></div>
                </div>
                <hr className="mt8 mb8" />
            </div>
            
        </>
    );
};

export default InvoiceComponent;
