import { apiResponseErrorHandler, apiResponseErrorHandlerClient } from 'helpers/common.helper';
import axios from 'axios';

export default {
	forgotPasswordClient(data) {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.post(`${base}/api/client/password/request`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response.data);
				});
		}, false);
	},
	getAllClientProducts() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/api/client/products/list?status=active`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getClientInterest() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/interests`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getCreateReturnsClient(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/returns`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getReturnsImeiClient(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/returns/client/get-stocks-items/imeis`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getClientSpec() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/specifications`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	aprroveAgreement(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/agreement_approve/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},

	getClientInfo(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getClientInformation(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/client_information/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getClientInformationAddress(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/client_information/address/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getClientInformationBank(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/client_information/bank/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getClientInformationSurvey(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/client_information/survey/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	addClientPortal(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/client/add-portal`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	addClient(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/register`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response);
				});
		}, false);
	},
	loginClient(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/login`, data)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response);
				});
		}, false);
	},

	getCountryList(type) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/country-list`)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getSelectOptionsListClient(type) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/client/selections?type=${type}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},
	clientlogout() {
		return this.handelAlert((res, rej, loadobj) => {
			axios
				.get(`${base}/client/logout`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((e) => {
					apiResponseErrorHandlerClient(e);
					rej(e);
				});
		}, false);
	},
	updateClientInfo(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/${id}/company_info`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateClientAddress(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/${id}/company_address`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateClientDocuments(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/${id}/company_documents`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateClientBankDetails(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/${id}/company_bank_details`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},
	addToCart(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/cart/${id}`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getCartItems(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/get-cart-items/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getCartDetails(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/cart-value/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	updateCartItem(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/cart/${id}/update-quantity`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteCartItem(id, data) {
		return new Promise((resolve, reject) => {
			axios
				.delete(`${base}/client/cart/${id}/delete`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					},
					data: data
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	updateClientSurveyDetails(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/${id}/company_survey_details`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateClientInterest(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/${id}/company_interests`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandlerClient(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getClientIvoices(id, page = 1, search = null) {
		let url = `${base}/client/invoices/${id}?page=${page}`;
		if (search) {
			url += `&search=${search}`;
		}
		return new Promise((resolve, reject) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},

	getClientOrders(id, page = 1, search = null) {
		let url = `${base}/client/orders/${id}?page=${page}`;
		if (search) {
			url += `&search=${search}`;
		}
		return new Promise((resolve, reject) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getClientReturns(id, page = 1, search = null) {
		let url = `${base}/client/returns/${id}?page=${page}`;
		if (search) {
			url += `&search=${search}`;
		}
		return new Promise((resolve, reject) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getClientEstimates(id, page = 1, search = null) {
		let url = `${base}/client/estimates/${id}?page=${page}`;
		if (search) {
			url += `&search=${search}`;
		}
		return new Promise((resolve, reject) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	downloadImei(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/imei/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	downloadAWB(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/awb-download/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	GetAvailableAWB(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/awb/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},

	getClientInvoiceNumberList(id, estimationId = null) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/invoice_customer/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getClientInvoiceNumberCustomer(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/invoice_customer_details/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getClientReturnsStandardRepairTypes() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/selections?type=standard_repair_types`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getTurnOvers() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/turnover`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	company_activities() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/company_activity`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},

	getPlatformList() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/platform_list`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getAppClient() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/app_client`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},

	getEmployeeList() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/employee_list`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	getEstimationByQoutationId(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/show_qoutation/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},

	getSalesPerson() {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/sales-information`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},

	getPurchasingDetails(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/get-purchasing-details/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	},
	updateClientGeneralInformation(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/client-information/update`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateClientGeneralInformationAddress(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/client-information/address/update`, data, {
					//check
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	UpdateClientInformationBank(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/client-information/bank/update`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getReturnsDetailsClient(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/client/returns-details/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	addReturnedProductsClient(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/returns/${id}/add-returned-products`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	deleteReturnedProductsClient(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/client/returns/${id}/delete-returned-products`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getAvailableReturnsImeiClient(estimationId, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/returns/${estimationId}/get-avaiable-items`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateReturnedProductsClient(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/client/returns/${id}/update-returned-products`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getAvailableReturnsImeiClient(estimationId, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/client/returns/${estimationId}/get-avaiable-items`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getReturnsStandardRepairTypesClient() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/client/selections?type=standard_repair_types`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getUnassignedInvoiceNumberListClient(estimationId = null) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/client/selections?type=invoice_customer${estimationId ? `&estimation_id=${estimationId}` : ''}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getProductFiltersClient() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/client/products/filter`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterProductClient(filters, search = null, status) {
		// if (status == 'active') {
		let url = `${base}/api/client/products/list?status=${status}&`;
		// } else {
		// 	let url = `${base}/api/products/list?status=inactive`;
		// }

		console.log('filters:', filters);

		Object.keys(filters).forEach((key) => {
			if (Array.isArray(filters[key])) {
				url += `${key}=${filters[key].map((item) => item.value).join(',')}&`;
			} else if (filters[key].value !== undefined) {
				url += `${key}=${filters[key].value}&`;
			}
		});

		console.log('url:', url);

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	paginationapiClient(url) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${url}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getProductDetailsClient(id) {
		return new Promise((resolve, reject) => {
			axios
				.get(`${base}/client/products-details/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('client'))?.token || JSON.parse(sessionStorage.getItem('client')).token || token
						}`
					}
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log(error);
					apiResponseErrorHandlerClient(error);
					reject(error.response);
				});
		});
	}
};
