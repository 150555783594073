import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import moment from 'moment';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton, Tooltip } from '@mui/material';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import usePageTitle from 'hooks/usePageTitle';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import CurrencyValue from 'ui-component/CurrencyValue';
import PreviewTransactionModal from './modals/PreviewTransactionModal';
import ExportTransactionModal from './modals/ExportTransactionModal';
import { FaSearch } from 'react-icons/fa';
import toast from 'react-hot-toast';
import {
    PreviewIcon,
    DeleteIcon,
	NoResultFoundIcon
} from 'ui-component/custom-icons/icons';
import { set } from 'draft-js/lib/EditorState';
const index = () => {
    const [setPageTitle] = usePageTitle('Transactions');
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);
    const history = useNavigate();
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [transactionLists, setTransactionLists] = useState([]);
    const [show, setShow] = useState('');
    const [isLoading, setIsLoading] = useState(false);
	const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedForDelete, setSelectedForDelete] = useState(null);
    
    
    
	const [showExportModal, setShowExportModal] = useState(false);
    const [exportCompIsLoading, setExportCompIsLoading] = useState(false);
    
    const [pagination, setpagination] = useState([]);
    
    const dispatch = useDispatch();

    const [searchField, setSearchField] = useState();
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [filters, setFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});
    const [isFiltersloading, setIsFiltersloading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const handleClose = () => {
        setOpenPreviewModal(false);
        setSelectedRow(null)
        setShowDelete(false);
        setSelectedForDelete(null);
	};



    const getTransactions = () => {
        setIsLoading(true);
        api
            .getTransactionLists()
            .then((res) => {
                setIsLoading(false);
                if (res.status) {
                    setTransactionLists(res?.data?.data);
                    setpagination(res?.data);
                } else {
                    setTransactionLists([]);
                }
                isFirstLoad && setIsFirstLoad(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setTransactionLists([]);
                console.error('Failed to fetch returns list:', error);
            });
    };
    const handleSearch = (e) => {
        let text = e.target.value;
        setSearchField(text);

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        let timeout = setTimeout(() => {
            setIsLoading(true);
            if (text.length > 2) {
                api.filterTransactions(activeFilters, text).then((res) => {
                    setIsLoading(false);
                    setTransactionLists(res.data.data);
                    setpagination(res.data);
                });
            } else if (text.length > 0 && text.length <= 2) {
                setIsLoading(false);
            } else {
                api.filterTransactions(activeFilters, null).then((res) => {
                    setIsLoading(false);
                    setTransactionLists(res.data.data);
                    setpagination(res.data);
                });
            }
        }, 1000);

        setSearchTimeout(timeout);
    };

	const viewData = (type, id) => {
		if (type === 'contacts') {
			window.open(`/contacts/${id}`, '_blank');
		} else if (type === 'invoice') {
			window.open(`/sales/invoices/${id}`, '_blank');
		}
    };

    useEffect(() => {
        !isFirstLoad && handleFilter();
    }, [activeFilters]);

    const handleFilter = (activeF = null, searchF = null) => {
        setIsLoading(true);
        api.filterTransactions(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
            setIsLoading(false);
            setTransactionLists(res.data.data);
            setpagination(res.data);
        });
    };


    const handleData = (data) => {
        setTransactionLists(data.data);
        setpagination(data);
    };

    useEffect(() => {
        getTransactions();
        getFiltersList();
    }, []);

    const getFiltersList = () => {
        setIsFiltersloading(true);
        api.getTransactionFilter().then((res) => {
            setFilters(res.data);
            setIsFiltersloading(false);
        });
    };

    const handlePreviewTransaction = (id) => {
        setOpenPreviewModal(true);
        setSelectedRow(id);
    }


	const exportListSubmitHandler = (selectedValues) => {
        setExportCompIsLoading(true);
        
		api.exportTransaction(selectedValues).then((res) => {
            const filename = `Projectmix - Payment Transaction`;
			if (res != 404) {
				var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				saveAs(newBlob, `${filename}.xlsx`);
				setExportCompIsLoading(false);
				toast.success('Downloaded Completed!');
				setShowExportModal(false);
			} else {
				setExportCompIsLoading(false);
				toast.error(res == 404 ? 'No Records Found!' : 'Download Failed!');
			}
		});
    };
    
    const deleteRecord = (data) => {
        setShowDelete(true);
        setSelectedForDelete(data);
    }

    const handleDelete = () => {
        setShowDelete(false);
        console.log('selectedForDelete', selectedForDelete);
        
        toast.promise(
            api.deleteTransaction(selectedForDelete?.id).then((res) => {
                if (res.status) {
                    getTransactions();
                    setShowDelete(false);
                    setSelectedForDelete(null);
                }
                return res?.status ? res : abc;
            }),
            {
                loading: 'Deleting payment transaction...',
                success: (data) => `${data.message}`,
                error: <b>Failed to delete payment transaction</b>
            }
        );

    }
    
    return (
        <>
            <div className="row">
                {/* Area Chart */}
                <div className="col-xl-12 col-lg-12">
                    <div className="section-head-new" style={{ marginTop: '10px', paddingLeft: '0' }}>

                        <h2>

                         
                        </h2>
                        <div className="actions">
							<PermissionAllowed permissions={['Export Payment Transaction']}>
                                <button
                                    className="btn btn-primary btn-outlined-main white-var btn-create-report"
                                    onClick={() => {
                                        setShowExportModal(true);
                                    }}
                                >
                                    <PiMicrosoftExcelLogoFill style={{ fontSize: '1.4em' }} /> Export
                                </button>
							</PermissionAllowed>
                        </div>
                    </div>
                    <div className="card shadow-sm bg-white mb-4">
                        <div className="d-flex align-items-center justify-content-between TableSerachBar">
                            <div>
                                <div className="actions" style={{ display: 'flex', width: '100%' }}>
                                    <div style={{ margin: '0 5px' }}>
                                        <Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
                                    </div>
                                </div>
                            </div>
                            <form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control bg-white border"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="basic-addon2"
                                        onChange={handleSearch}
                                        style={{ width: '100%' }}
                                    />
                                    <span className="searchIcon">
                                        <FaSearch />
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="table-responsive-xl tableData customersList p-2">
                            <Scroller extraHeight={100}>
                                <table className="table pm-table">
                                    <thead>
                                        <tr className="sticky-header">
                                            <th scope="col">Client</th>
                                            <th scope="col text-left">Invoice</th>
                                            <th scope="col text-left">Transaction Type</th>
                                            <th scope="col text-left">Amount</th>
                                            <th scope="col text-left">Date</th>

                                            <PermissionAllowed permissions={['Delete Payment Transaction']}>
                                                <th scope="col" className="text-center" style={{ width: '5%'}}>
                                                    Action
                                                </th>
                                            </PermissionAllowed>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <>
                                                {[...Array(6)].map((e, i) => (
                                                    <tr>
                                                        {[...Array(7)].map((e, i) => (
                                                            <td>
                                                                <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {transactionLists?.map((row, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index} style={{ height: '35px' }}>
                                                                <td
                                                                    className={hasEveryPermission(['View Contact (Button)']) ? 'cursor-pointer text-primary align-middle font14' : 'align-middle font14'}
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Contact (Button)'])) {
                                                                            if (row?.estimation?.transit_company?.name) {
                                                                                viewData('contacts', row?.estimation?.transit_company?.id);
                                                                            } else if (row?.estimation?.customer?.name ) {
                                                                                viewData('contacts', row?.estimation?.customer?.id);
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        row?.estimation?.transit_company?.name ? (
                                                                            <>
                                                                                <div>
                                                                                    { row?.estimation?.transit_company?.name }
                                                                                </div>
                                                                            </>
                                                                        ) : row?.estimation?.customer?.name ? (
                                                                            <>
                                                                                <div>
                                                                                    { row?.estimation?.customer?.name }
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        ) 
                                                                    }
                                                                </td>
                                                                <td
                                                                    className={hasEveryPermission(['View Estimation Details (Page)']) ? 'cursor-pointer text-primary align-middle font14' : 'align-middle font14'}
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Estimation Details (Page)'])) {
                                                                            if (row?.estimation?.invoice_number) {
                                                                                viewData('invoice', row?.estimation?.id);
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    <div>
                                                                        { row?.estimation?.invoice_number }
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle font14">
                                                                    { row?.payment_type }
                                                                </td>
                                                                <td className="align-middle font14">
												                    <CurrencyValue currencyObject={row?.converted_price} />
                                                                </td>
                                                                <td className="align-middle font14">
                                                                    {moment(row?.payment_date).format(CommonDateOnlyFormat)}
                                                                </td>
                                                                <PermissionAllowed permissions={['Delete Payment Transaction', 'Preview Payment Transaction Details']} hasSome>
                                                                    <td className="align-middle  font14">
                                                                        <div className='action-col'>
                                                                            <PermissionAllowed permissions={['Preview Payment Transaction Details']} hasSome>
                                                                                <Tooltip title={'Preview'} arrow placement="top">
                                                                                    <a
                                                                                        href="javascript:void(0);"
                                                                                        onClick={() => {
                                                                                            if (hasEveryPermission(['Preview Payment Transaction Details'])) {
                                                                                                handlePreviewTransaction(row?.id);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <PreviewIcon height={'18px'} width={'18px'} />
                                                                                    </a>
                                                                                </Tooltip>
                                                                            </PermissionAllowed>
                                                                            <PermissionAllowed permissions={['Delete Payment Transaction']} hasSome>
                                                                                <Tooltip title={'Delete'} arrow placement="top">
                                                                                    <a
                                                                                        href="javascript:void(0);"
                                                                                        onClick={() => {
                                                                                            if (hasEveryPermission(['Delete Payment Transaction'])) {
                                                                                                deleteRecord(row);
                                                                                            }
                                                                                        }}
                                                                                        style={{ marginLeft: '3px' }}
                                                                                    >
                                                                                        <DeleteIcon height={'20px'} width={'20px'} />
                                                                                    </a>
                                                                                </Tooltip>
                                                                            </PermissionAllowed>
                                                                        </div>
                                                                    </td>
                                                                </PermissionAllowed>
                                                            </tr>
                                                        </>
                                                    );
                                                })}
                                                {transactionLists?.length < 1 && (
                                                    <tr className="no-hover">
                                                        <td colSpan={6} className="whitebg">
                                                            <div className="not-found-container ">
                                                                <div className="not-found-icon">
                                                                    
                                                                    <NoResultFoundIcon height={'200px'} width={'200px'} />
                                                                </div>
                                                                <h6 className="not-found-text" style={{ fontStyle: 'italic', color: '#000', fontSize: '18px' }}>
                                                                    No record found
                                                                </h6>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </Scroller>
                        </div>
                        <Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
                        <div style={{ marginBottom: '8px'}}></div>
                    </div>
                </div>
            </div>

            {openPreviewModal && (
                <PreviewTransactionModal
                    openPopup={openPreviewModal}
                    selectedRow={selectedRow}
                    handleClose={handleClose}
                />
            )}


			{showExportModal && (
				<ExportTransactionModal
					openpopup={true}
					disablepopup={() => {
						setShowExportModal(false);
						// dispatch(
						// 	storeHeaderActions({
						// 		showQuickStats: headerActions?.showQuickStats,
						// 		showExport: false
						// 	})
						// );
					}}
					filters={filters}
					moduleName={'Orders'}
					onSubmit={exportListSubmitHandler}
					// historyApi={api.getExportsHistory('orders')}
					isLoading={exportCompIsLoading}
					// exportHistoryPerms={['Show Estimation Export History (Button)']}
				/>
            )}
            
            {showDelete && (
                <DeleteConfirmation
                    show={showDelete}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    title="Confirm"
                    message={`Are you sure you want to delete <br />transaction #${selectedForDelete?.reference_number}?`}
                />
            )}

        </>
    );
};

export default index;
