import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import moment from 'moment';
import { TbDatabaseExclamation } from 'react-icons/tb';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const DetailsTab = ({
    serialDetails,
    repairLists
}) => {

    return (
        <>
            <div className="content mt15">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-12 form-group">
                        <div className="card p-2">
                            <div className="product-card minHeight198 pad10-25">
                                <div className="form-section">
                                    <div className="product-section-head font18">
                                        <b>General</b>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="">Product</label>
                                        <span className="fw600" style={{ marginLeft: '20px', fontSize: '13px' }}> {serialDetails?.product?.name}</span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12" style={{ display: 'flex' }}>
                                        <label htmlFor="">SKU </label>
                                        <div style={{ marginLeft: '49px' }}>
                                            <span className="fw600" > {serialDetails?.product?.sku} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{ display: 'flex' }}>
                                        <label htmlFor="">Category </label>
                                        <div style={{ marginLeft: '14px' }}>
                                            <span className="fw600"> {serialDetails?.product?.category}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{ display: 'flex' }}>
                                        <label htmlFor="">Storage </label>
                                        <div style={{ marginLeft: '24px' }}>
                                            <span className="fw600"> {serialDetails?.storage?.name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{ display: 'flex' }}>
                                        <label htmlFor="">Grade </label>
                                        <div style={{ marginLeft: '35px' }}>

                                            <span className="fw600"> {serialDetails?.grade?.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-12 form-group">
                        <div className="card p-2">
                            <div className="product-card minHeight198 pad10-25">
                                <div className="form-section">
                                    <div className="product-section-head font18">
                                        <b>Invoice</b>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Invoice</label>
                                        <span className="fw600" style={{ marginLeft: '77px' }}> {serialDetails?.estimation?.invoice_number} </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Customer Name </label>
                                        <span className="fw600" style={{ marginLeft: '59px' }}> <PrivacyModeContent>{serialDetails?.customer?.name}</PrivacyModeContent></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Contact Person</label>
                                        <span className="fw600" style={{ marginLeft: '24px' }}> <PrivacyModeContent>{serialDetails?.customer?.contact_name}</PrivacyModeContent></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Supplier</label>
                                        <span className="fw600" style={{ marginLeft: '69px' }}> {serialDetails?.supplier?.name ? (<>{serialDetails?.supplier?.name}</>) : (<>N/A</>)}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Inbounded</label>
                                        <span className="fw600" style={{ marginLeft: '50px' }}> {serialDetails?.inbound_date ? (<>{moment(serialDetails?.inbound_date).format(CommonDateOnlyFormat)}</>) : (<>N/A</>)}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Outbounded</label>
                                        <span className="fw600" style={{ marginLeft: '39px' }}> {serialDetails?.outbound_date ? (<>{moment(serialDetails?.outbound_date).format(CommonDateOnlyFormat)}</>) : (<>N/A</>)}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-12 form-group">
                        <div className="card p-2">
                            <div className="product-card pad10-25" style={{ minHeight: '206px' }}>
                                <div className="form-section">
                                    <div className="product-section-head font18">
                                        <b>Repairs</b>
                                    </div>
                                </div>
                                {repairLists?.length > 0 ? (
                                    <>
                                        {
                                            repairLists?.map((repair) => {
                                                return (
                                                    <div className="row">
                                                        <div className="col-8 font14">
                                                            <label htmlFor="">{repair?.name} </label>
                                                        </div>
                                                        <div className="col-4 font14">
                                                            <span className="fw600" >: $ {repair?.cost}</span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                    </>
                                ) : (
                                    <>
                                        <div className="not-found-container" style={{ height: '10vh' }}>
                                            <div className="not-found-icon">
                                                <TbDatabaseExclamation />
                                            </div>
                                            <h6 className="not-found-text">No Data Available</h6>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailsTab;
