import { PropTypes } from 'prop-types';
import { InputLabel, Select, OutlinedInput, Chip, MenuItem, Box, FormControl } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { createRef, forwardRef } from 'react';

// const FancyChip = forwardRef((props, ref) => {
// 	console.log(props, ref)
// 	return <Chip ref={ref} key={props.value} label={props.value} onDelete={() => props.func(value)} />

// });

const FancyChip = (props) => {
	return (
		<Chip
			key={props.value}
			label={props.hasId ? props.value.split('_')[0] : props.value}
			deleteIcon={<ClearIcon onMouseDown={(event) => event.stopPropagation()} />}
			onDelete={() => props.func(props.value)}
		/>
	);
};

const MultiSelectInput = ({
	labelName,
	name,
	value,
	menuItems,
	handleChange,
	onChipDelete,
	placeholder,
	withSearch,
	hasId,
	onSearchChange
}) => {
	// const ref = createRef();

	return (
		<FormControl sx={{ width: '100% ', mt: 4 }}>
			<Box sx={{ mb: 4, color: 'black' }}>
				<InputLabel className="" id={name} shrink={true} disableAnimation={true} variant="standard" focused={false}>
					{labelName}
				</InputLabel>
			</Box>
			<Select
				labelId={name}
				id={name}
				className="form-control-select"
				multiple
				value={value}
				onChange={handleChange}
				displayEmpty
				input={<OutlinedInput type="text" />}
				renderValue={(selected) => {
					if (selected.length === 0) {
						return <span style={{ color: 'grey' }}>{placeholder ? placeholder : 'Select...'}</span>;
					}
					return (
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: 0.5
							}}
						>
							{selected.map((value) => (
								<FancyChip
									value={value}
									onClick={() => {
										console.log(value);
									}}
									func={onChipDelete}
									hasId={hasId}
								/>
							))}
						</Box>
					);
				}}
			>
				{withSearch ? (
					<MenuItem sx={{ backgroundColor: 'white' }}>
						<OutlinedInput
							type="text"
							inputProps={{ placeholder, onChange: onSearchChange }}
							variant="standard"
							sx={{ backgroundColor: 'white' }}
							onKeyDown={(event) => event.stopPropagation()}
							onKeyUp={(event) => event.stopPropagation()}
						/>
					</MenuItem>
				) : null}
				{menuItems?.map((mod, index) => (
					<MenuItem key={index} value={mod}>
						{hasId ? mod.split('_')[0] : mod}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

// MultiSelectInput.propTypes = {
// 	labelName: PropTypes.string.required,
// 	name: PropTypes.string.required,
// 	value: PropTypes.array.required,
// 	menuItems: PropTypes.array.required,
// 	handleChange: PropTypes.func.required
// }

export default MultiSelectInput;
