import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './Inbounding/inbounding.scss';
import Scroller from 'ui-component/Scroller';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiPackageBold, PiTestTubeFill, PiWarningFill } from 'react-icons/pi';
import { FaSave } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import AddRepairsModal from 'ui-component/confirmation-dialogs/addRepairsModal/AddRepairsModal';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import useSound from 'use-sound';
import usePageTitle from 'hooks/usePageTitle';

import errorSound from '../../assets/audio/error.mp3';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';

import Breadcrumbs from './components/Breadcrumbs';
import InboundingForm from './components/InboundingForm';
import Summary from './components/Summary';
import InboundList from './components/InboundList';
import Logs from './components/Logs';

function OperationDetails({ edit }) {
    const { id } = useParams();
    const [setPageTitle] = usePageTitle('Operations');
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);
    const history = useNavigate();
    const topSection = useRef(null);
    const [errorSoundPlay] = useSound(errorSound);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedInbound, setSelectedInbound] = useState([]);
    const [collectionModals, setCollectionModals] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedColor, setSelectedColor] = useState();
    const [imeiInputValue, setImeiInputValue] = useState('');
    const imeiInputRef = useRef(null);
    const [modelImeis, setModelImeis] = useState([]);
    const [checkModelImeis, setCheckModelImeis] = useState(true);
    const [isInProgress, setIsInProgress] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isDisable, setIsDisable] = useState(false);
    const [colors, setColors] = useState([]);
    const [modelRepairOptions, setModelRepairOptions] = useState([]);
    
    
    const [operationDetails, setOperationDetails] = useState({
        id: '',
        referenceNo: '',
        status: '',
        action: '',
        type: '',
        products: [],
        logs: []
    });


    const [isInternalLoading, setInternalLoading] = useState(false);

    const [errorDialog, setErrorDialog] = useState({
        show: false,
        message: '',
        title: ''
    });

    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
        message: '',
        title: '',
        handleConfirm: () => { },
        handleClose: () => { }
    });

    useEffect(() => {
        const data = {
            inboundId: id,
            modelImeis: modelImeis?.filter((m) => !m?.existing)
        };

        if (data?.modelImeis?.length > 0) {
            localStorage.setItem('inboundAutoSave', JSON.stringify(data));
        }

        console.log(modelImeis);
    }, [modelImeis]);

    const [addRepairDialog, setAddRepairDialog] = useState(null);

    const handleRepairDialogClose = () => {
        setAddRepairDialog(null);
    };

    const onAddRepairSubmit = (data) => {
        const oldModelImed = [...modelImeis];

        setModelImeis(
            modelImeis.map((item) => {
                if (item?.imei == data.imei) {
                    return data;
                }
                return item;
            })
        );

        if (modelImeis.find((item) => item?.imei == data.imei)?.existing) {
            setIsDisable(true);
            toast.promise(
                api
                    .updateStockRepair({
                        stock_id: modelImeis.find((item) => item?.imei == data.imei)?.stock_id,
                        repair_cost_ids: data?.repairs.map((r) => ({ id: r.id }))
                    })
                    .then((res) => {
                        if (res.status) {
                            setIsDisable(false);
                        }
                        return res?.status ? res : null;
                    }),
                {
                    loading: 'Updating Repairs...',
                    success: (data) => `${data.message}`,
                    error: <b>Failed to update repair</b>
                }
            );
        }

        handleRepairDialogClose();
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Check if you want to show the confirmation popup
            if (true) {
                const message = 'Are you sure you want to leave?';
                event.returnValue = message; // Standard for most browsers
                return message; // For some older browsers
            }
        };

        // Attach the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    const handleCloseErrorDialog = () => {
        setErrorDialog({
            show: false,
            message: '',
            title: ''
        });
    };

    const showErrorDialog = (title, message = '') => {
        setErrorDialog({
            show: true,
            message: message,
            title: title
        });
    };


    const addModalImei = async () => {
        if (!imeiInputValue || !imeiInputValue.trim()) {
            showErrorDialog('Invalid IMEI', `Invalid IMEI Entered`);
            setImeiInputValue('');
            imeiInputRef.current.value = '';
            errorSoundPlay();
            return;
        }

        const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

        if (!alphanumericRegex.test(imeiInputValue)) {
            showErrorDialog('Invalid IMEI', `Please enter a valid IMEI Number`);
            setImeiInputValue('');
            imeiInputRef.current.value = '';
            errorSoundPlay();
            return;
        }

        // console.log(
        // 	'Test',
        // 	modelImeis?.filter(
        // 		(model) =>
        // 			model?.product?.id == selectedInbound?.items?.find((c) => c?.id == selectedProduct?.value)?.product?.id &&
        // 			selectedInbound?.items?.find((c) => c?.id == selectedProduct?.value)?.purchase_list_id == model?.purchase_list_id
        // 	)?.length
        // );

        if (
            Number(
                modelImeis?.filter(
                    (model) =>
                        model?.product?.id == selectedInbound?.items?.find((c) => c?.id == selectedProduct?.value)?.product?.id &&
                        selectedInbound?.items?.find((c) => c?.id == selectedProduct?.value)?.purchase_list_id == model?.purchase_list_id
                )?.length
            ) >= selectedInbound?.items?.find((c) => c?.id == selectedProduct?.value)?.qty
        ) {
            showErrorDialog('Invalid Model', `Maximum quantity for model inbounded`);
            setImeiInputValue('');
            imeiInputRef.current.value = '';
            errorSoundPlay();
            return;
        }

        if (modelImeis.find((m) => m?.imei == imeiInputValue)) {
            showErrorDialog('Duplicate IMEI', `Couldn't add IMEI ${imeiInputValue} since it already exists in the list`);
            setImeiInputValue('');
            imeiInputRef.current.value = '';
            errorSoundPlay();
        } else {
            const theImei = `${imeiInputValue}`;
            setModelImeis((prevModelImeis) => [
                {
                    imei: `${imeiInputValue}`,
                    repairs: [],
                    valid: 'loading',
                    invalid_message: 'Loading',
                    product: selectedInbound?.items?.find((c) => c?.id == selectedProduct?.value)?.product,
                    color: selectedColor,
                    item_id: selectedInbound?.items?.find((c) => c?.id == selectedProduct?.value)?.id,
                    purchase_list_id: selectedInbound?.items?.find((c) => c?.id == selectedProduct?.value)?.purchase_list_id
                },
                ...prevModelImeis
            ]);
            setImeiInputValue('');
            imeiInputRef.current.value = '';

            try {
                const response = await api.checkIMEIStatus(theImei);
                if (response?.status) {
                    if (response?.data == 'Available') {
                        setModelImeis((prevModelImeis) =>
                            prevModelImeis.map((item) =>
                                item.imei == theImei ? { ...item, valid: true, invalid_message: 'IMEI Exists in stock' } : item
                            )
                        );
                    } else if (response?.data == 'Inbounded') {
                        setModelImeis((prevModelImeis) =>
                            prevModelImeis.map((item) =>
                                item.imei == theImei ? { ...item, valid: false, invalid_message: 'IMEI Exists in stock' } : item
                            )
                        );
                    } else if (response?.data == 'Outbounded') {
                        setModelImeis((prevModelImeis) =>
                            prevModelImeis.map((item) =>
                                item.imei == theImei ? { ...item, valid: 'outbounded', invalid_message: 'IMEI was Outbounded' } : item
                            )
                        );
                    }
                } else {
                    setModelImeis((prevModelImeis) =>
                        prevModelImeis.map((item) =>
                            item.imei === theImei ? { ...item, valid: false, invalid_message: 'Something went wrong' } : item
                        )
                    );
                }
            } catch (error) {
                setModelImeis((prevModelImeis) =>
                    prevModelImeis.map((item) => (item.imei === theImei ? { ...item, valid: false, invalid_message: 'Something went wrong' } : item))
                );
            }
        }
    };


    const handleCloseConfirmationDialog = () => {
        setConfirmationDialog({
            show: false,
            message: '',
            title: '',
            handleConfirm: () => { },
            handleClose: () => { }
        });
    };

    const showConfirmationDialog = (title, message = '', onConfirm = () => { }, onClose = () => { }) => {
        setConfirmationDialog({
            show: true,
            message: message,
            title: title,
            handleConfirm: onConfirm,
            handleClose: onClose
        });
    };

    useEffect(() => {
        if (id) {
            api.getOperationsDetails(id).then((res) => {
                if (res?.status) {
                    const data = {
                        id: res?.data?.id,
                        referenceNo: res?.data?.reference_number,
                        action: 'view',
                        type: 'inbound',
                        products: res?.data?.items,
                        logs: res?.data?.logs
                    }
                    setOperationDetails(data);
                
                    // setOperationDetails(res?.data);
                }
            });
        }
    }, []);

    const handleStart = () => {
        setIsDisable(true);
        toast.promise(
            api.startInbounding(id).then(
                (res) => {
                    if (res.status) {
                        setIsInProgress(true);
                    }

                    setIsDisable(false);
                    return res?.status ? res : null;
                },
                () => {
                    setIsDisable(false);
                }
            ),
            {
                loading: 'Starting Inbounding...',
                success: (data) => `${data.message}`,
                error: <b>Failed to start Inbounding</b>
            }
        );
    };

    const onDoneClicked = () => {
        if (modelImeis?.length >= selectedInbound?.total_qty) {
            showConfirmationDialog(
                'Complete Inbounding?',
                'Are you sure you want to complete inbounding?',
                () => {
                    handleSubmit();
                    handleCloseConfirmationDialog();
                },
                handleCloseConfirmationDialog
            );
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        setIsDisable(true);
        localStorage.removeItem('inboundAutoSave');
        const data = {
            task_inbounding_id: id,
            items: modelImeis
                ?.filter((m) => !m?.existing)
                ?.map((m) => ({
                    item_id: m?.item_id,
                    product_id: m?.product?.id,
                    color: m?.color,
                    imei: m?.imei,
                    repairs: m?.repairs.map((r) => ({ repair_cost_id: r?.id }))
                }))
        };

        toast.promise(
            api.saveInboundingData(data).then((res) => {
                if (res.status) {
                    setIsDisable(false);
                    history('/operations/inbounding');
                }
                return res?.status ? res : null;
            }),
            {
                loading: 'Adding to stock...',
                success: (data) => `${data.message}`,
                error: (data) => {
                    setIsDisable(false);
                    return <b>Failed to add to stock</b>;
                }
            }
        );
    };
    



    const removeItem = (index) => {
        const updatedProducts = operationDetails?.products ? [...operationDetails.products] : [];
        updatedProducts.splice(index, 1);
        setOperationDetails({
            ...operationDetails,
            products: updatedProducts
        });
    }

    const handleRemoveProductFromList = (index) => {
        if (operationDetails?.products[index]?.id) {
            // setShowDelete(true)
            // setSelectedItem(operationDetails?.products[index])
            // handleDelete()
            toast.promise(
                api
                    .deleteOperationInboundItem(operationDetails?.products[index]?.id)
                    .then(
                        (res) => {
                            if (res.status) {
                                removeItem(index)
                            }

                            return res?.status ? res : null;
                        },
                        () => { }
                    ),
                {
                    loading: 'Adding repair...',
                    success: (data) => `${data.message}`,
                    error: <b>Failed to add repair</b>
                }
            );
        } else {
            removeItem(index)
        }
    }

    const handleErrorDialog = (data) => {
        setErrorDialog({
            ...errorDialog,
            show: data?.show,
            message: data?.message,
            title: data?.title
        })
        errorSoundPlay();

    }

    const handleUpdateOperationDetailsProducts = (details) => { 
        
        const updatedProducts = operationDetails?.products ? [...operationDetails.products] : [];
        
        updatedProducts.push(details);
        setOperationDetails({
            ...operationDetails,
            products: updatedProducts
        });
    }

    const saveOperationDetails = (action) => {
        const collection = {
            id: operationDetails?.id,
            status: operationDetails?.status,
            action: action,
            type: 'create',
            products: operationDetails?.products
        }

        if (edit) {
            
            toast.promise(
                api.updateOperationsData(id, collection).then((res) => {
                    if (res.status) {
                        setIsDisable(false);
                        history(`/operations/view/${res?.data?.id}/qualitycontrol`);
                    }
                    return res?.status ? res : null;
                }),
                {
                    loading: 'Updating operation...',
                    success: (data) => `${data.message}`,
                    error: (data) => {
                        setIsDisable(false);
                        return <b>Failed to update operation</b>;
                    }
                }
            );
        } else {

            api.storeOperationsData(collection).then((res) => {
                if (res?.status) {
                    toast.success(res.message);
                    history(`/operations/view/${res?.data?.id}/qualitycontrol`);
                } else {
                    toast.error(res.message);
                }
            },
            () => { }
        );
            
        }


    }

	return (
        <>
            
            <div className="row m-0 w-100" style={{ marginTop: '20px' }}>
                <div className="col-12 col-md-12">
                    <div className="section-head" style={{ paddingLeft: '0' }}>
                     
                        <h2>
                            <div className="actions" style={{ display: 'flex', width: '100%' }}>

                                <Breadcrumbs 
                                    current={`inbound`} 
                                    activateTabsUsingId={operationDetails?.id}
                                    referenceNumber={operationDetails?.referenceNo}
                                />

                            </div>
                        </h2>
                        <div className="actions">
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-lg-8 p-0 form-group" style={{ marginTop: '5px' }}>
                            <div className="card shadow-sm bg-white table-card p-3" style={{ marginTop: '0' }}>
                                <div className="bg-white pt-0 p-3" style={{  marginTop: '-15px' }}>
                                  
                                    <div className="row">
                                        <div className="col-12 form-group">
                                            
                                            <InboundingForm
                                                operationDetails={operationDetails}
                                                // handleProceedure={handleProceedure}
                                                handleErrorDialog={handleErrorDialog}
                                                handleUpdateOperationDetailsProducts={handleUpdateOperationDetailsProducts}
                                            />
                                        </div>
                                
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            
                                            <InboundList
                                                operationDetails={operationDetails}
                                                handleRemoveProductFromList={handleRemoveProductFromList}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-right">
                                            
                                            {!edit ? (
                                                <>
                                                    <PermissionAllowed permissions={['Create Operations']}>
                                                  
                                                        <button
                                                            type="button"
                                                            onClick={() => saveOperationDetails('to_qualitycontrol')}
                                                            className="btn btn-primary btn-outlined-main"
                                                            disabled={isInternalLoading || operationDetails?.products?.length == 0}
                                                        >
                                                            Save
                                                        </button> 
                                                    </PermissionAllowed>
                                                </>
                                            ) : (
                                                <button
                                                    type="button"
                                                    onClick={() => saveOperationDetails('update')}
                                                    className="btn btn-primary btn-outlined-main"
                                                    disabled={isInternalLoading || operationDetails?.products?.length == 0 || isDisable}
                                                >
                                                    <FaSave /> Update
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>








                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-12 form-group">
                                    <Summary
                                        operationDetails={operationDetails}
                                    />
                                    <Logs
                                        logs={operationDetails?.logs}
                                    />
                                    {/* <RepairSummary /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}

			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}

			{addRepairDialog && (
				<AddRepairsModal
					show={addRepairDialog}
					handleClose={handleRepairDialogClose}
					onSubmit={onAddRepairSubmit}
					repairOptions={modelRepairOptions?.find((m) => m?.model_id == addRepairDialog?.product?.model?.id)?.options}
				/>
			)}
			{/* {showDelete && (
				<DeleteConfirmation
					show={showDelete}
					handleClose={handleClose}
					handleDelete={handleDelete}
					title="Confirm"
					message={`Are you sure you want to delete ${selectedItem?.product_name} ${(selectedItem?.color_name) ? `(${selectedItem?.color_name})` : `` } from the list?`}
				/>
			)} */}
            
		</>
	);
}

export default OperationDetails;
