import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import SelectCreatable from 'react-select/creatable';
import Scroller from 'ui-component/Scroller';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setReturncomponent, setEstimatedata, storeInLocalStore } from 'store/reducers/mainSlice';
import 'react-datepicker/dist/react-datepicker.css';
import { FaSave, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { Skeleton } from '@mui/material';
import Notes from 'ui-component/notes';

const CreditNoteForm = ({ edit }) => {
	const { id } = useParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);

	const [isInternalLoading, setInternalLoading] = useState(false);

	const currencies = [
		{ label: 'EUR', value: 'eur' },
		{ label: 'USD', value: 'usd' },
		{ label: 'AED', value: 'aed' }
	];

	// Customer Create
	const [selectedEstimate, setSelectedEstimate] = useState();

	const dispatch = useDispatch();
	// End Create
	const history = useNavigate();
	const [invoiceList, setInvoiceList] = useState([]);
	const [shippingRow, setShippingrow] = useState({
		quantity: 1,
		unitPrice: 0,
		totalPrice: 0
	});

	const [noteInputValue, setNoteInputValue] = useState('');

	const [vatCalculated, setVatcalculated] = useState();
	const [selectedCustomerinfo, setSelectedCustomerInfo] = useState({});
	const currentDate = moment().format('YYYY-MM-DD');
	const [subTotal, setSubtotal] = useState('00');
	const [grandTotal, setGrandtotal] = useState('00');
	const [alertMessage, setAlertMessage] = useState();

	const [submitDisabled, setSubmitDisabled] = useState();

	const [estimateRow, setEstimaterow] = useState({
		customer_id: '',
		credit_note_number: '',
		sales_manager: '',
		transit_id: '',
		vatStatus: 'Including VAT',
		vatPrice: 21,
		startDate: currentDate,
		expiresIn: 7,
		currency: 'eur',
		notes: [],
		customer: null,
		transit_company: null
	});
	// Row Area
	const [rows, setRows] = useState([
		{
			id: 1,
			invoiceId: '',
			invoiceId: '',
			invoiceNumber: '',
			productId: '',
			modelOptions: [],
			productId: '',
			customValue: null,
			type: '',
			quantity: 0,
			unitPrice: {
				usd: 0,
				aed: 0,
				eur: 0
			}
		}
	]);
	const [nextId, setNextId] = useState(2);

	//   End Row
	/* Shipping Calculator */
	const handleShipping = (val) => {
		const total = (parseInt(val.quantity || 0) * parseFloat(val?.unitPrice || 0)).toFixed(2);
		setShippingrow({
			quantity: val.quantity,
			unitPrice: val?.unitPrice,
			totalPrice: total
		});
	};

	useEffect(() => {
		if (!edit) {
			setEstimaterow((oldData) => ({ ...oldData, sales_manager: selectedCustomerinfo?.sales_manager_id }));
		}
	}, [selectedCustomerinfo]);

	const handleextimatevalue = (val) => {
		// console.log('Val', val);
		setEstimaterow(val);
	};

	useEffect(() => {
		setEstimaterow({
			customer_id: '',
			credit_note_number: '',
			sales_manager: '',
			transit_id: '',
			vatStatus: 'Including VAT',
			vatPrice: 21,
			startDate: currentDate,
			expiresIn: 7,
			currency: 'eur',
			notes: []
		});
		setSubtotal(0);
		setGrandtotal(0);
		setRows([
			{
				id: 1,
				productId: '',
				invoiceId: '',
				invoiceNumber: '',
				productId: '',
				customValue: null,
				type: '',
				quantity: 0,
				unitPrice: {
					aed: 0,
					eur: 0,
					usd: 0
				}
			}
		]);
		initiateDatas();
	}, [edit]);

	const [loaderStatus, setLoaderStatus] = useState({
		est_no: false,
		customers: false,
		transit_companies: false,
		sales_manager: false,
		products: false,
		invoices: false
	});

	const [editDataLoader, setEditDataLoader] = useState(false);

	const initiateDatas = () => {
		if (!edit) {
			setLoaderStatus((oldData) => ({
				...oldData,
				est_no: true
			}));
			api.getCreditNoteNumber().then((res) => {
				handleextimatevalue({
					...estimateRow,
					credit_note_number: res.data?.credit_note_number
				});
				setLoaderStatus((oldData) => ({
					...oldData,
					est_no: false
				}));
			});
		}

		setLoaderStatus((oldData) => ({
			...oldData,
			invoices: true
		}));
		api.getSelectOptionsList('invoice').then((res) => {
			setInvoiceList(res.data);
			setLoaderStatus((oldData) => ({
				...oldData,
				invoices: false
			}));
		});

		if (edit && id) {
			setEditDataLoader(true);
			api.getCreditNoteById(id, true).then((res) => {
				if (!res.data) {
					history('/estimations');
				} else {
					setSelectedEstimate(res?.data);
				}
				setEditDataLoader(false);
			});
		}
	};

	const addNote = (noteInputValue) => {
		if (edit) {
			toast.promise(
				api
					.newCreditNoteNotes(id, {
						details: noteInputValue,
						type: 'public'
					})
					.then(
						(res) => {
							if (res.status) {
								setEstimaterow((oldData) => ({
									...oldData,
									notes: [
										{
											id: res?.data?.id,
											details: noteInputValue,
											date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
											time: moment(res?.data?.created_at).format('HH:mm'),
											user_name: userName,
											expand: false,
											isDeletable: true
										},
										...oldData?.notes
									]
								}));
							}

							return res?.status ? res : null;
						},
						() => {}
					),
				{
					loading: 'Adding Note...',
					success: (data) => `${data.message}`,
					error: <b>Failed to add note</b>
				}
			);
		} else {
			setEstimaterow((oldData) => ({
				...oldData,
				notes: [
					{
						id: null,
						details: noteInputValue,
						date: moment().format('YYYY-MM-DD'),
						time: moment().format('HH:mm'),
						user_name: userName,
						expand: false,
						isDeletable: true
					},
					...oldData?.notes
				]
			}));
		}
	};

	const toggleNoteExpand = (index) => {
		setEstimaterow((oldData) => ({
			...oldData,
			notes: oldData?.notes?.map((note, i) =>
				index == i
					? {
							...note,
							expand: !note?.expand
					  }
					: note
			)
		}));
	};

	const removeNote = (index) => {
		if (edit) {
			toast.promise(
				api.removeCreditNoteNote(id, estimateRow?.notes?.[index]?.id).then(
					(res) => {
						if (res.status) {
							setEstimaterow((oldData) => ({
								...oldData,
								notes: oldData?.notes?.filter((note, i) => index != i)
							}));
						}

						return res?.status ? res : null;
					},
					() => {}
				),
				{
					loading: 'Deleting Note...',
					success: (data) => `${data.message}`,
					error: <b>Failed to delete note</b>
				}
			);
		} else {
			setEstimaterow((oldData) => ({
				...oldData,
				notes: oldData?.notes?.filter((note, i) => index != i)
			}));
		}
	};

	useEffect(() => {
		if (selectedEstimate) {
			// const expDate = moment(selectedEstimate?.expires_at).format('YYYY-MM-DD');
			const expDate = moment().format('YYYY-MM-DD');
			let tempRow = selectedEstimate?.credit_note_details.map((item, index) => {
				return {
					id: index,
					edit_id: item?.id,
					modelId: item?.product_id,
					invoiceNumber: item?.invoice_number,
					productId: item?.product_id,
					customValue: item?.product_id ? null : item?.product_name,
					type: item?.product_name,
					quantity: item?.qty,
					unitPrice: item?.product_id
						? item?.converted_currency_unit_price
						: item?.converted_currency_unit_price?.[selectedEstimate?.currency],
					total: item?.converted_currency_total_price,
					invoiceId: item?.estimation_id,
					modelOptions: item?.model_options
				};
			});

			tempRow = [
				...tempRow,
				{
					id: tempRow.length + 1,
					invoiceId: '',
					modelOptions: [],
					modelId: '',
					productId: '',
					type: '',
					quantity: '',
					unitPrice: {
						aed: 0,
						usd: 0,
						eur: 0
					},
					total: ''
				}
			];

			setRows(tempRow);
			setNextId(tempRow.length + 1);
			setSelectedCustomerInfo(selectedEstimate.customer);
			setSubtotal(selectedEstimate?.subtotal);
			const temEstimate = {
				credit_note_number: selectedEstimate?.credit_note_number,
				customer_id: selectedEstimate?.customer_id,
				customer: selectedEstimate?.customer,
				startDate: moment(selectedEstimate?.date).format('YYYY-MM-DD'),
				expiryDate: expDate,
				transit_id: selectedEstimate?.transit_company?.id,
				sales_manager: selectedEstimate?.sales_person?.id,
				private_note: selectedEstimate?.private_note,
				vatStatus: selectedEstimate?.vat_type,
				vatPrice: selectedEstimate?.vat_percent || 21,
				currency: selectedEstimate?.currency,
				private_note: selectedEstimate?.private_note,
				expiresIn: moment(selectedEstimate?.expires_at).diff(selectedEstimate?.start_date, 'days')
			};

			let notesArray = [];

			selectedEstimate?.notes?.forEach((note) => {
				notesArray = [
					{
						id: note?.id,
						details: note?.details,
						date: moment(note?.created_at).format('YYYY-MM-DD'),
						time: moment(note?.created_at).format('HH:mm'),
						user_name: `${note?.user_created?.first_name} ${note?.user_created?.last_name}`,
						expand: false,
						isDeletable: note?.is_deletable
					},
					...notesArray
				];
			});

			notesArray = _.sortBy(notesArray, (n) => -n.id);

			const tempShipping = {
				unitPrice: selectedEstimate?.shipping_fees,
				quantity: selectedEstimate?.shipping_qty,
				totalPrice: Number(selectedEstimate?.shipping_fees) * Number(selectedEstimate?.shipping_qty)
			};
			setEstimaterow({ ...temEstimate, notes: notesArray });
			setShippingrow(tempShipping);
		}
	}, [selectedEstimate]);

	const removeRow = (id) => {
		if (rows.length === 1) {
			return;
		}
		const updatedRows = rows.filter((row) => row.id !== id);

		if (updatedRows?.length <= 1) {
			setEstimaterow((oldData) => ({ ...oldData, customer: null, transit_company: null }));
		}

		setRows(updatedRows);
	};

	const handleInvoiceSelect = (id, value) => {
		let newRow = null;
		if (!rows.find((row) => row.id == id).invoiceId && value.id) {
			newRow = {
				id: nextId,
				invoiceId: '',
				modelOptions: [],
				modelId: '',
				type: '',
				quantity: '',
				unitPrice: {
					aed: 0,
					usd: 0,
					eur: 0
				},
				total: ''
			};
		}
		if (newRow) {
			setRows((oldData) => [
				...oldData.map((row) => {
					if (row.id === id) {
						const invoiceId = value.id;
						return { ...row, invoiceId, modelOptions: 'loading' };
					}
					return row;
				}),
				newRow
			]);
			setNextId(nextId + 1);
		} else {
			setRows((oldData) =>
				oldData.map((row) => {
					if (row.id === id) {
						const invoiceId = value.id;
						return { ...row, invoiceId, modelOptions: 'loading' };
					}
					return row;
				})
			);
		}

		setEstimaterow((oldData) => ({ ...oldData, customer: value?.customer, transit_company: value?.transit_company }));

		api.getEstimateProducts(value.id).then((res) => {
			setRows((oldData) =>
				oldData.map((row) => {
					if (row.id === id) {
						const invoiceId = value.id;
						return { ...row, invoiceId, modelOptions: res?.data || [] };
					}
					return row;
				})
			);
		});
	};

	const handleModelselect = (id, value) => {
		setRows((oldData) =>
			oldData?.map((row) => {
				if (row.id === id) {
					if (value.id) {
						const modelId = value.product_id;
						const productId = value.product_id;
						const customValue = null;
						return { ...row, modelId, productId, customValue, unitPrice: value?.converted_currency_unit_price };
					}
					const modelId = null;
					const productId = null;
					const customValue = value?.value;

					return { ...row, modelId, productId, customValue, unitPrice: '' };
				}
				return row;
			})
		);

		setNextId(nextId + 1);
	};
	const handleQuantityChange = (id, value) => {
		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				const quantity = parseInt(value) || 0;
				return { ...row, quantity };
			}
			return row;
		});
		setRows(updatedRows);
	};

	const validateRows = () => {
		console.log(rows);
		for (let i = 0; i < rows.length; i++) {
			const { productId, quantity, unitPrice, customValue } = rows[i];
			if (i != rows.length - 1) {
				if ((!productId && !customValue) || !quantity || !unitPrice) {
					return false;
				}
			}
		}
		return true;
	};
	useEffect(() => {
		setSubmitDisabled(
			rows?.some(
				(row) =>
					row?.quantity < 0 ||
					row.quantity > (row?.modelOptions != 'loading' && row?.modelOptions?.find((m) => m?.id == row?.productId)?.qty)
			) ||
				isInternalLoading ||
				!estimateRow?.credit_note_number ||
				rows?.length <= 1 ||
				rows?.slice(0, rows?.length - 1)?.some((r) => !!!r?.invoiceId || (!!!r?.productId && !!!r?.customValue) || !!!r?.quantity)
		);
	}, [rows, shippingRow, estimateRow]);

	const formValidation = () => {
		let isValid = true;
		if (estimateRow?.customer_id == '') {
			isValid = false;
			setAlertMessage({
				customer_id: 'Company Name is Required.'
			});
		} else if (estimateRow?.sales_manager == '') {
			// isValid = false;
			setAlertMessage({
				sales_manager: 'Sales Manager is Required.'
			});
		} else if (rows.length <= 1) {
			isValid = false;
			setAlertMessage({ modelrow: 'Please add at least one product.' });
		} else if (!validateRows()) {
			isValid = false;
			setAlertMessage({ modelrow: 'Please fill in all fields for each product.' });
		}

		return isValid;
	};

	const saveCreditNote = (actionType) => {
		// Validation Condition
		setInternalLoading(true);

		toast.promise(
			api
				.submitCreditNote({
					customer_id: estimateRow?.customer?.id,
					transit_company_id: estimateRow?.transit_company?.id,
					shipping_fees: shippingRow?.quantity * shippingRow.unitPrice,
					currency: estimateRow?.currency,
					date: estimateRow?.startDate,
					items: rows?.slice(0, rows.length - 1)?.map((r) => ({
						product_id: r?.productId,
						estimation_id: r?.invoiceId,
						custom_value: r?.customValue,
						qty: r?.quantity,
						unit_price: r?.customValue ? r?.unitPrice : r?.unitPrice[estimateRow?.currency]
					})),
					notes: estimateRow?.notes?.map((n) => n?.details)
				})
				.then((res) => {
					if (res?.status) {
						if (actionType == 'save') {
							// setShowModal(res.data.estimation_id);
							// setEstimatenumber(res.data.estimation_id);
							dispatch(setReturncomponent('Estimated'));
							history('/estimations');
						} else {
							dispatch(setReturncomponent('Draft'));
							history('/estimations');
						}
					}

					setInternalLoading(false);
					return res?.status ? res : abc;
				}),
			{
				loading: 'Creating Credit Note...',
				success: (res) => res?.message,
				error: <b>Could not Create Credit Note.</b>
			}
		);
	};

	const updateSubmit = () => {
		// Validation Condition
		if (formValidation()) {
			setInternalLoading(true);
			toast.promise(
				api
					.updateCreditNote(selectedEstimate?.id, {
						customer_id: estimateRow?.customer?.id,
						transit_company_id: estimateRow?.transit_company?.id,
						shipping_fees: shippingRow?.quantity * shippingRow.unitPrice,
						currency: estimateRow?.currency,
						date: estimateRow?.startDate,
						items: rows?.slice(0, rows.length - 1)?.map((r) => ({
							edit_id: r?.edit_id || null,
							product_id: r?.productId,
							estimation_id: r?.invoiceId,
							custom_value: r?.customValue,
							qty: r?.quantity,
							unit_price: r?.customValue ? r?.unitPrice : r?.unitPrice[estimateRow?.currency]
						}))
					})
					.then((res) => {
						setInternalLoading(false);
						return res?.status ? res : abc;
					}),
				{
					loading: 'Updating Estimate...',
					success: <b>Estimate Updated!</b>,
					error: <b>Could not Update Estimate.</b>
				}
			);
		}
	};

	return (
		<>
			<div className="row m-0 w-100" style={{ paddingInline: '30px' }}>
				<div className="col-12 col-md-8">
					<div className="section-head">
						<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>
							{loaderStatus?.est_no || editDataLoader ? (
								<Skeleton
									style={{
										// minHeight: '32px',
										// height: '32px',
										// transformOrigin: '0 0',
										// transform: 'scale(1,1)',
										width: '100px'
									}}
								></Skeleton>
							) : (
								estimateRow?.credit_note_number || ''
							)}
							<span className="badge badge-secondary status-badge">
								{edit ? selectedEstimate?.master_status?.name : 'New'}
							</span>
						</h2>
						<div className="actions">
							{/* <button
								className="btn btn-primary btn-outlined-main"
								// style={{justifyContent: 'space-between'}}
								disabled={isInternalLoading || !estimateRow?.credit_note_number}
							>
								Extra <FaChevronDown />
							</button> */}
							{!edit ? (
								<>
									<PermissionAllowed permissions={['Create New Credit Note (Action)']}>
										<button
											type="button"
											onClick={() => saveCreditNote('save')}
											className="btn btn-primary btn-outlined-main"
											disabled={submitDisabled}
										>
											<FaSave /> Save
										</button>
									</PermissionAllowed>
								</>
							) : (
								<PermissionAllowed permissions={['Save Credit Note Details (Action)']}>
									<button
										type="button"
										onClick={() => updateSubmit()}
										className="btn btn-primary btn-outlined-main"
										disabled={submitDisabled}
									>
										<FaSave /> Update
									</button>
								</PermissionAllowed>
							)}
						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-4 p-3 w-100">
							<Scroller>
								<div className="row">
									<div className="col-md-12 col-lg-12">
										<div className="productsTable estlist" style={{ fontSize: '12px' }}>
											<div className="table-responsive-xl tableData">
												<table className="table table-md  formActBtns border-0">
													<thead>
														<tr>
															<th className="align-middle" width={'240'}>
																Invoice
															</th>
															<th className="align-middle" width={'420'}>
																Item
															</th>
															{/* <th className="align-middle text-center" width={'120'}>
															Type
														</th> */}
															<th className="align-middle" width={'60'}>
																Quantity
															</th>
															<th className="align-middle" width={'80'}>
																Unit Price
															</th>
															<th className="align-middle" width={'120'}>
																Total Price
															</th>
															<th className="align-middle text-center" width={'50'}></th>
														</tr>
													</thead>
													<tbody>
														{rows.map((row, index) => (
															<>
																<tr key={row.id}>
																	<td>
																		{loaderStatus?.invoices || editDataLoader ? (
																			<Skeleton
																				style={{
																					minHeight: '32px',
																					height: '32px',
																					transformOrigin: '0 0',
																					transform: 'scale(1,1)',
																					width: '123px'
																				}}
																			></Skeleton>
																		) : (
																			<Select
																				className="rs-container"
																				classNamePrefix="rs"
																				isSearchable={true}
																				name="model"
																				menuPosition={'fixed'}
																				options={[...invoiceList]
																					?.filter((inv) => {
																						if (
																							!!estimateRow?.customer &&
																							!!estimateRow?.transit_company
																						) {
																							return (
																								estimateRow?.customer?.id ==
																									inv.customer?.id &&
																								estimateRow?.transit_company?.id ==
																									inv.transit_company?.id
																							);
																						} else if (!!estimateRow?.customer) {
																							return (
																								estimateRow?.customer?.id ==
																								inv.customer?.id
																							);
																						} else if (!!estimateRow?.transit_company) {
																							return (
																								estimateRow?.transit_company?.id ==
																								inv.transit_company?.id
																							);
																						} else {
																							return true;
																						}
																					})
																					?.map((inv) => ({
																						value: inv?.id,
																						label: inv?.invoice_number,
																						...inv
																					}))}
																				value={[...invoiceList]
																					?.filter((inv) => {
																						if (
																							!!estimateRow?.customer &&
																							!!estimateRow?.transit_company
																						) {
																							return (
																								estimateRow?.customer?.id ==
																									inv.customer?.id &&
																								estimateRow?.transit_company?.id ==
																									inv.transit_company?.id
																							);
																						} else if (!!estimateRow?.customer) {
																							return (
																								estimateRow?.customer?.id ==
																								inv.customer?.id
																							);
																						} else if (!!estimateRow?.transit_company) {
																							return (
																								estimateRow?.transit_company?.id ==
																								inv.transit_company?.id
																							);
																						} else {
																							return true;
																						}
																					})
																					?.map((inv) => ({
																						value: inv?.id,
																						label: inv?.invoice_number,
																						...inv
																					}))
																					?.find((m) => m?.value == row?.invoiceId)}
																				placeholder="Select"
																				onChange={(selected) =>
																					handleInvoiceSelect(row.id, selected)
																				}
																			/>
																		)}
																	</td>
																	<td>
																		{row?.modelOptions == 'loading' || editDataLoader ? (
																			<Skeleton
																				style={{
																					minHeight: '32px',
																					height: '32px',
																					transformOrigin: '0 0',
																					transform: 'scale(1,1)',
																					width: '191px'
																				}}
																			></Skeleton>
																		) : (
																			<SelectCreatable
																				className="rs-container"
																				classNamePrefix="rs"
																				isSearchable={true}
																				name="model"
																				menuPosition={'fixed'}
																				options={row?.modelOptions
																					?.filter((m) =>
																						rows?.every((r) => r.productId != m?.id)
																					)
																					?.map((m) => ({
																						...m,
																						value: m?.id,
																						label: m?.product?.product_name
																					}))}
																				value={
																					row?.productId
																						? row?.modelOptions
																								?.map((m) => ({
																									...m,
																									value: m?.product_id,
																									label: m?.product?.product_name
																								}))
																								?.find((m) => m?.value == row?.productId)
																						: {
																								value: row?.customValue,
																								label: row?.customValue
																						  }
																				}
																				placeholder="Select"
																				onChange={(selected) => handleModelselect(row.id, selected)}
																			/>
																		)}
																	</td>
																	{/* <td className="align-middle text-center">{row.type || '-'}</td> */}
																	<td className="align-middle ">
																		<input
																			className={`numField ${
																				row?.quantity < 0 ||
																				row.quantity >
																					(row?.modelOptions != 'loading' &&
																						row?.modelOptions?.find(
																							(m) => m?.id == row?.productId
																						)?.qty)
																					? 'error-input'
																					: ''
																			}`}
																			type="number"
																			value={row?.quantity}
																			placeholder="00"
																			maxLength={10}
																			onChange={(e) => {
																				if (row.productId || row.customValue) {
																					const newVal = e.target.value;
																					if (newVal.length <= 10) {
																						handleQuantityChange(row.id, newVal);
																					}
																				}
																			}}
																			disabled={!row.productId && !row?.customValue}
																			style={{ width: '60px', paddingInline: '3px' }}
																			onFocus={(event) => {
																				event.target.select();
																			}}
																		/>
																	</td>
																	<td className="align-middle">
																		<span
																			style={{
																				display: 'flex',
																				justifyContent: 'center',
																				alignItems: 'center',
																				gap: '5px'
																			}}
																		>
																			{row?.customValue ? (
																				<>
																					<input
																						type="number"
																						className="numField"
																						value={row?.unitPrice}
																						placeholder="00"
																						maxLength={10}
																						onChange={(e) => {
																							if (row.customValue) {
																								const newVal = e.target.value;
																								setRows((oldData) => {
																									return oldData?.map((o) => {
																										if (o?.id == row?.id) {
																											return {
																												...o,
																												unitPrice: newVal
																											};
																										}
																										return o;
																									});
																								});
																							}
																						}}
																						style={{ width: '60px', paddingInline: '3px' }}
																					/>
																				</>
																			) : (
																				<>
																					{estimateRow?.currency == 'eur'
																						? '€'
																						: estimateRow?.currency == 'usd'
																						? '$'
																						: 'AED'}{' '}
																					{row?.unitPrice?.[estimateRow?.currency] || '00'}
																				</>
																			)}
																		</span>
																	</td>
																	<td className="align-middle">
																		{row?.customValue ? (
																			<>
																				{estimateRow?.currency == 'eur'
																					? '€'
																					: estimateRow?.currency == 'usd'
																					? '$'
																					: 'AED'}{' '}
																				{row?.quantity * row?.unitPrice}
																			</>
																		) : (
																			<>
																				{estimateRow?.currency == 'eur'
																					? '€'
																					: estimateRow?.currency == 'usd'
																					? '$'
																					: 'AED'}{' '}
																				{(
																					row?.unitPrice?.[estimateRow?.currency] * row?.quantity
																				).toFixed(2) || '00'}
																			</>
																		)}
																	</td>
																	<td className="align-middle text-center">
																		{row.invoiceId ? (
																			<span
																				className="btnEdit plain-iconbtn"
																				onClick={() => removeRow(row.id)}
																			>
																				<FaTimes />
																			</span>
																		) : (
																			''
																		)}
																	</td>
																</tr>
															</>
														))}
														{alertMessage?.modelrow && (
															<span className="px-3 down-error text-danger">{alertMessage?.modelrow}</span>
														)}
													</tbody>
													<tfoot>
														{/* <tr className="vatTotal graybg">
															<td className="align-middle p-1"></td>
															<td className="align-middle p-1"></td>
															<td className="align-middle p-1"></td>
															<td className="align-middle p-1 pl-3">SUBTOTAL</td>
															<td className="align-middle p-1">
																{estimateRow?.currency == 'eur'
																	? '€'
																	: estimateRow?.currency == 'usd'
																	? '$'
																	: 'AED'}{' '}
																{[...rows]?.filter((r) => !!r?.invoiceId)?.length > 0
																	? [...rows]
																			?.filter((r) => !!r?.invoiceId)
																			?.reduce(
																				(o, r) =>
																					(isNaN(r?.quantity) ? 0 : r?.quantity) *
																						(isNaN(r?.unitPrice?.[estimateRow?.currency])
																							? 0
																							: r?.unitPrice?.[estimateRow?.currency]) +
																					o,
																				0
																			)
																			?.toFixed(2)
																	: Number(0).toFixed(2)}
															</td>
															<td className="align-middle p-1"></td>
														</tr> */}
														<tr className="vatTotal graybg">
															<td className="align-middle p-1"></td>
															<td className="align-middle p-1"></td>
															<td className="align-middle p-1"></td>
															<td className="align-middle p-1 pl-3">TOTAL</td>
															<td className="align-middle p-1">
																{estimateRow?.currency == 'eur'
																	? '€'
																	: estimateRow?.currency == 'usd'
																	? '$'
																	: 'AED'}{' '}
																{([...rows]?.filter((r) => !!r?.invoiceId)?.length > 0
																	? [...rows]
																			?.filter((r) => !!r?.invoiceId)
																			?.reduce((o, r) => {
																				if (r?.customValue && !!!r?.productId) {
																					console.log(
																						'Test Value',
																						r?.unitPrice,
																						r?.quantity,
																						r?.unitPrice * r?.quantity,
																						o
																					);
																					return o + r?.unitPrice * r?.quantity;
																				} else {
																					return (
																						(isNaN(r?.quantity) ? 0 : r?.quantity) *
																							(isNaN(r?.unitPrice?.[estimateRow?.currency])
																								? 0
																								: r?.unitPrice?.[estimateRow?.currency]) +
																						o
																					);
																				}
																			}, 0)
																	: Number(0)
																).toFixed(2)}
															</td>
															<td className="align-middle p-1"></td>
														</tr>
													</tfoot>
												</table>
											</div>
										</div>
									</div>
									{/* <div className="col-md-3 col-lg-3">
											<div className="form-row form-card createDate">
												<div className="form-group col-md-12">
													<label htmlFor="" className="">
														Transit Company
													</label>
													<Select
														className="rs-container"
														classNamePrefix="rs"
														options={transitCompanyList.map((item) => ({ label: item.name, value: item.id }))}
														onChange={(e) => {
															handleextimatevalue({
																...estimateRow,
																transit_id: e.value
															});
														}}
													/>
													{alertMessage?.transit_id && (
														<span className="px-3 down-error text-danger">{alertMessage?.transit_id}</span>
													)}
												</div>
												<div className="form-group col-md-12">
													<label htmlFor="" className="">
														Starts From:
													</label>
													<input
														type="date"
														value={estimateRow?.startDate}
														style={{ cursor: 'pointer' }}
														className="form-control"
														onClick={(e) => e.target.focus()}
														onChange={(e) => handleStartdate(e)}
													/>
												</div>
												<div className="form-group col-md-12">
													<label htmlFor="" className="">
														Expires on:
													</label>
													<input
														type="date"
														value={estimateRow?.expiryDate}
														min={minExpdate}
														className="form-control"
														onChange={(e) =>
															handleextimatevalue({
																...estimateRow,
																expiryDate: e.target.value
															})
														}
													/>
												</div>
												<div className="form-group col-md-12">
													<label htmlFor="" className="">
														Sales Manager
													</label>
													<Select
														className="rs-container"
														classNamePrefix="rs"
														options={salesPerson?.map((item) => ({ label: item.name, value: item.id }))}
														onChange={(e) => {
															handleextimatevalue({
																...estimateRow,
																sales_manager: e.value
															});
														}}
														disabled={salesId ? true : false}
													/>
													{alertMessage?.sales_manager && (
														<span className="px-3 down-error text-danger">{alertMessage?.sales_manager}</span>
													)}
												</div>
												<div className="form-group col-md-12">
													<label htmlFor="" className="">
														VAT{' '}
													</label>
													<Select
														className="rs-container"
														classNamePrefix="rs"
														options={[
															{ label: 'Exclusive', value: 'exclusive' },
															{ label: 'Inclusive', value: 'inclusive' }
														]}
														onChange={(e) => {
															handleextimatevalue({
																...estimateRow,
																vatStatus: e.value
															});
														}}
													/>
												</div>
												<div className="form-group col-md-12">
													<label htmlFor="" className="">
														Private Note{' '}
													</label>
													<textarea
														ref={privatenoteRef}
														onChange={(e) => {
															handleextimatevalue({
																...estimateRow,
																private_note: e.target.value
															});
														}}
														className="form-control textArea"
														placeholder="notes lorem ipsum …."
													></textarea>
												</div>
											</div>
										</div> */}
								</div>
							</Scroller>

							<div className="">
								{alertMessage?.success && <span className="px-3 down-error text-success">{alertMessage?.success}</span>}
								{alertMessage?.failed && <span className="px-3 down-error text-danger">{alertMessage?.failed}</span>}
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-3">
					<div style={{ marginTop: '65px' }}>
						<div className="est-detail-item mb-2">
							<label htmlFor="inputName" className="est-labels">
								Customer
							</label>
							{estimateRow?.customer?.name ? (
								<Link to={`/contacts/${estimateRow?.customer?.id}`}> {estimateRow?.customer?.name} </Link>
							) : (
								<p className="m-0">N/A</p>
							)}
						</div>
						<div className="est-detail-item mb-2">
							<label htmlFor="inputName" className="est-labels">
								Transit Company
							</label>
							{estimateRow?.customer?.name ? (
								<Link to={`/contacts/${estimateRow?.transit_company?.id}`}> {estimateRow?.transit_company?.name} </Link>
							) : (
								<p className="m-0">N/A</p>
							)}
						</div>
						<div className="form-group mb-2">
							<label htmlFor="inputName" className="est-labels">
								Currency
							</label>
							<Select
								className="rs-container-est-white"
								classNamePrefix="rs"
								isSearchable={true}
								name="customer"
								options={currencies}
								value={currencies?.find((c) => c?.value == estimateRow?.currency)}
								onChange={(e) => {
									handleextimatevalue({
										...estimateRow,
										currency: e.value
									});
								}}
							/>

							{alertMessage?.name && (
								<>
									<span className="error-message text-danger">{alertMessage?.name}</span>
								</>
							)}
						</div>

						<div className="form-group mb-2">
							<label htmlFor="inputName" className="est-labels">
								Date
							</label>

							<input
								type="date"
								value={estimateRow?.startDate}
								style={{ cursor: 'pointer' }}
								className="form-control"
								onClick={(e) => e.target.focus()}
								onChange={(e) => {
									handleextimatevalue({
										...estimateRow,
										startDate: e.target?.value
									});
								}}
							/>

							{alertMessage?.name && (
								<>
									<span className="error-message text-danger">{alertMessage?.name}</span>
								</>
							)}
						</div>

						<div className="form-group mb-2">
							{/* _.groupBy(collection, [iteratee]); */}
							<Notes
								notes={estimateRow?.notes}
								addNote={addNote}
								deleteNotePermission={[]}
								removeNote={removeNote}
								toggleNoteExpand={toggleNoteExpand}
								parentId={1}
								type="creditNote"
							/>
							{/* {estimateRow?.notes?.length > 0 &&
								Object.keys(_.groupBy(estimateRow?.notes, 'date'))?.map((group) => (
									<>
										<h6 className="date-group">{moment(group).format('MMMM DD, YYYY')}</h6>
										{[...estimateRow?.notes]?.map(
											(note, index) =>
												note?.date == group && (
													<div className="estimate-note">
														<div className="note-content">
															<div className="estimate-note-icon">
																<CgNotes />
															</div>
															<div className="estimate-note-value">{note?.details}</div>
															<div className="estimate-note-button">
																<span
																	className={`btnEdit plain-iconbtn ${note?.expand ? 'expanded' : ''}`}
																	style={{
																		height: '22px',
																		width: '22px',
																		minWidth: '22px',
																		background: '#fff',
																		border: 'none'
																	}}
																	onClick={() => {
																		toggleNoteExpand(index);
																	}}
																>
																	<IoIosArrowDown />
																</span>
															</div>
														</div>
														<div className={note?.expand ? 'note-extra-content visible' : 'note-extra-content'}>
															<p>
																At {note?.time} by {note?.user_name}
															</p>
															<Link
																to="#"
																onClick={() => {
																	removeNote(index);
																}}
															>
																Delete Note
															</Link>
														</div>
													</div>
												)
										)}
									</>
								))} */}
						</div>
					</div>
					{/* <div className="estimate-side-info">
						<div className="estimate-info-item">
							<label>Date</label>
							<div className="estimate-info-value">
								<p>{estimateRow?.startDate}</p>
								<span>
									<FaEdit />
								</span>
							</div>
						</div>
						<div className="estimate-info-item">
							<label>Sales Manager</label>
							<div className="estimate-info-value">
								<p>N/A</p>
								<span>
									<FaEdit />
								</span>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</>
	);
};

export default CreditNoteForm;
