// material-ui
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { RotatingLines, RotatingTriangles } from 'react-loader-spinner'

// styles
const LoaderWrapper = styled('div.loader-wrapper')({
	position: 'absolute',
	zIndex: 1301,
	width: '100%',
	height: '100%',
})

// ==============================|| LOADER ||============================== //
const Loader = () => (
	<LoaderWrapper>
		{/* <LinearProgress color="primary" /> */}
		<Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
			<RotatingTriangles
			visible={true}
			height="100"
			width="100"
			ariaLabel="rotating-triangels-loading"
			wrapperStyle={{}}
			wrapperClass="rotating-triangels-wrapper"
			colors={['#795aaa', '#f39c13', '#7cc3e6']}
		/>
		</Box>
		
	</LoaderWrapper>
)

export default Loader
