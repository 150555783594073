import { Avatar } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsCreditCard } from 'react-icons/bs';
import { FaCheck, FaPaperclip, FaTimes } from 'react-icons/fa';
import { FaCircleInfo } from 'react-icons/fa6';
import PropTypes from 'prop-types';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import ImageViewer from './ImageViewer';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

const altText = 'Image Could Not be Loaded';

// paymentPreviewModal
function Payment({ payment, handleDeletePayment }) {
	const [selectedRow, setSelectedRow] = useState(null);

	const handleRowClick = (id) => {
		setSelectedRow(id === selectedRow ? null : id);
	};

	// const openPaymentPreviewModal = (id) => {
	// 	// Open Payment Preview Modal
	// 	paymentPreviewModal(id)
	// }
	const deletePayment = (selectedPaymentDetails) => {
		handleDeletePayment(selectedPaymentDetails);

	}

	return (
		<>
			{/* {console.log('payment', payment)} */}
			<div
				className="task-card"
				style={{ '--statusColor': '#45ad51' }}
			// onClick={() => openPaymentPreviewModal(payment?.id)}
			>
				<PermissionAllowed permissions={['Delete Payment Transaction']}>
					<div
						className="payment-delete-icon"
						onClick={() => deletePayment(payment)}
					>
						<FaTimes />
					</div>
				</PermissionAllowed>
				<div className="task-head">
					<span style={{ color: '#45ad51' }}>
						{payment?.currency} {numberFormatter.format(payment?.amountPaid)}
					</span>

					<span style={{ color: 'grey' }}>{moment(payment?.date).format(CommonDateOnlyFormat)}</span>
				</div>

				<div className="task-body">
					<span style={{ color: '#45ad51' }}>{payment?.bodyContent}</span>
					{selectedRow && <ImageViewer imagePath={payment?.Image} altText={altText} />}
				</div>
				<div className="task-actions">
					<div className="task-qty">
						<BsCreditCard />{' '}
						{
							payment?.payType == 'credit-note' ? (
								'Credit Note'
							) : payment?.payType == 'bank-charges' ? (
								'Bank Charges'
							) : (
								'Wired'
							)
						}
					</div>
					{payment?.Image && (
						<div className="d-flex justify-content-end" style={{ gap: '5px' }}>
							<a
								href={payment?.Image}
								target="_blank"
								// className="btn btn-primary btn-outlined-main"
								className={"btn"}
								style={{ width: '35px', border: '1px solid #cecece' }}
							// onClick={() => handleRowClick(payment?.id)}
							>
								<FaPaperclip /> 
							</a>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default Payment;
