import React, { useEffect, useState } from 'react';

import usePageTitle from 'hooks/usePageTitle';
import 'assets/scss/backorders.scss';

const ExportBackorders = () => {
	// ...rest of your code...

	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="row pb10">
						<div className="col-12">
							<div className="section-head-new"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="content pad15">
							<div className="row m-0 p-0 mb-3">
								<div className="col-md-2 col-sm-3 col-12">
									<h4 className="font18">
										<b>Export</b>
									</h4>
								</div>
							</div>
							<div className="row">
								<div className="col-md-4 col-3 d-flex align-items-center justify-content-end form-group">Select Invoice</div>
								<div className="col-md-4 col-9 form-group">
									<div>
										<select className="form-control" name="selected_invoice" id="selected_invoice">
											<option value="1">INV-010</option>
											<option value="0">INV-168</option>
										</select>
									</div>
								</div>
							</div>
							<div className="row" style={{ marginBottom: '20px', marginTop: '10px' }}>
								<div className="col-6 d-flex justify-content-end">
									<a className="btn btn-main-primary" style={{ width: '100px' }}>
										Export
									</a>
								</div>
								<div className="col-6 d-flex justify-content-start">
									<a className="btn btn-main-primary" style={{ width: '100px' }}>
										Export All
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ExportBackorders;
