import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'; // Import these if they are separate components
import { Fade } from 'react-awesome-reveal';

let COLORS = ['#ebeaea', '#f64e60'];

const getFillColor = (salesPercentage) => {
	if (salesPercentage <= 30) {
		return '#ee4747';
	} else if (salesPercentage <= 70) {
		return '#ffba49';
	} else {
		return '#36c880';
	}
};

const SalesTargetCard = ({ donutData, salesPercentage, salesSold, salesTarget }) => {
	COLORS[1] = getFillColor(salesPercentage);
	const fillColor = getFillColor(salesPercentage);
	return (
		<div className="custom-sales-target col-md-6 col-sm-12">
			<Fade>
				<div className="card" style={{ position: 'relative', width: '100%', height: '230px', maxHeight: '230px', marginBottom: '20px' }}>
					<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: '10px' }}>
						<a style={{ fontWeight: '500' }}>Sales Target Revenue</a>
					</div>
					<div className="card-body" style={{ paddingTop: '5px' }}>
						<div style={{ position: 'absolute', top: 30, left: 40, right: 40, bottom: 0 }}>
							<ResponsiveContainer>
								<PieChart>
									<Pie
										data={donutData}
										cx="50%"
										cy="50%"
										startAngle={0} // Swapped startAngle and endAngle
										endAngle={180} // Swapped startAngle and endAngle
										innerRadius="85%"
										outerRadius="100%"
										cornerRadius={10}
										paddingAngle={salesPercentage === 100 ? 0 : -10}
										fill={fillColor}
										dataKey="value"
									>
										{donutData.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
										))}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</div>
						<div
							className="row"
							style={{
								position: 'absolute',
								bottom: 25,
								left: 10,
								width: '100%',
								backgroundColor: 'transparent',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<div className="col-6" style={{ textAlign: 'center' }}>
								<a style={{ fontWeight: '400' }}>Achieved</a>
								<p style={{ fontWeight: '600', fontSize: '16px' }}>$ {Number(salesSold).toLocaleString()}</p>
							</div>
							<div className="col-6" style={{ textAlign: 'center' }}>
								<a style={{ fontWeight: '400' }}>Target</a>
								<p style={{ fontWeight: '600', fontSize: '16px' }}>$ {Number(salesTarget).toLocaleString()}</p>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default SalesTargetCard;
