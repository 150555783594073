import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import UserProfile from 'assets/images/profile-pic.jpg';
import { setLoading, storeUserDetails } from 'store/reducers/mainSlice';
import Resizer from 'react-image-file-resizer';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Avatar, IconButton } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import PhoneInput from 'react-phone-number-input';
import { FaEdit, FaSave } from 'react-icons/fa';
import { FaPen } from 'react-icons/fa6';
import { BiMessageSquareEdit } from 'react-icons/bi';
import toast from 'react-hot-toast';

const ProfileForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm();
	const user = useSelector((state) => state.main.user);
	const [mobileNumber, setMobileNumber] = useState(user?.mobile || '');
	const [alertMsg, setAlertMsg] = useState({ color: '', msg: '' });
	const dispatch = useDispatch();
	const [userImage, setUserImage] = useState(null);
	const [userImageBas64, setUserImageBase64] = useState(null);

	useEffect(() => {
		reset(
			{
				firstName: user?.first_name,
				lastName: user?.last_name,
				email: user?.email,
				mobile: user?.mobile
			},
			{ keepValues: false }
		);
	}, [reset, alertMsg]);

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				300,
				300,
				'JPEG',
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				'base64'
			);
		});

	const onImageChange = async (event) => {
		const file = event?.target?.files?.[0];
		if (file) {
			const image = file;
			const imageB64 = await resizeFile(file);
			setUserImage(image);
			setUserImageBase64(imageB64);
		}
	};

	const onSubmit = (formData) => {
		const data = {
			first_name: formData?.firstName,
			last_name: formData?.lastName,
			email: formData?.email,
			mobile: mobileNumber
		};

		if (userImage) {
			data.profile_image = userImage;
		}

		dispatch(setLoading(true));

		toast.promise(
			api.updateUserDetail(user?.id, { ...data }).then((res) => {
				if (res && res.status) {
					dispatch(storeUserDetails({ ...user, ...res?.data }));
					return res;
				} else {
					return abx;
				}
				dispatch(setLoading(false));
				setTimeout(() => setAlertMsg({ color: '', msg: '' }), 2000);
			}),
			{
				loading: 'Updating Profile...',
				success: <b>Profile Updated!</b>,
				error: <b>Failed to update profile</b>
			}
		);

		// api.updateUserDetail(user?.id, { ...data }).then((res) => {
		// 	if (res && res.status) {
		// 		dispatch(storeUserDetails({ ...user, ...data }));
		// 	} else {
		// 	}
		// 	dispatch(setLoading(false));
		// 	setTimeout(() => setAlertMsg({ color: '', msg: '' }), 2000);
		// });
	};
	return (
		<div className="row">
			<div className="col-12">
				<div className="profilePic">
					{/* <span className="imgCon shadow-lg">

						<img src={userImage} alt="" width="" height="" />
					</span> */}
					<Avatar
						src={
							userImageBas64
								? userImageBas64
								: user?.profile_photo_url
								? user?.profile_photo_url
								: 'https://cdn.iconscout.com/icon/free/png-256/free-avatar-380-456332.png'
						}
						sx={{ width: 150, height: 150 }}
					/>
					<a href="#" className="rounded-circle uploadpImg">
						<input accept="image/*" id="icon-button-file" type="file" style={{ display: 'none' }} onChange={onImageChange} />
						<label htmlFor="icon-button-file" className="m-0 p-0">
							{/* <IconButton aria-label="upload picture" sx={{ padding: '3px' }} component="span" style={{fontSize: '15px'}} className="btn btn-main">
								<FaPen />
							</IconButton> */}
							<span className="btn btn-main" aria-label="upload picture" style={{ minWidth: '80px', paddingBlock: '5px' }}>
								<FaEdit /> Edit
							</span>
						</label>
					</a>
				</div>
			</div>
			<div className="col-12 d-flex justify-content-center align-items-center mt-4">
				<form className="pt-4" style={{ maxWidth: '600px' }} onSubmit={handleSubmit(onSubmit)}>
					<div className="form-row">
						<div className="form-group-inline p-0 col-md-6 mb-4 pr-4">
							<label htmlFor="inputName" className="text-default">
								First Name
							</label>
							<input type="text" className="form-control" id="inputName" {...register('firstName', { required: true })} />
							{errors.firstName && <p className="mt-1 text-danger">First Name is required</p>}
						</div>
						<div className="form-group-inline p-0 col-md-6 mb-4 pr-4">
							<label htmlFor="inputEmail4" className="text-default">
								Last Name
							</label>
							<input type="text" className="form-control" id="inputEmail4" {...register('lastName', { required: false })} />
						</div>
						<div className="form-group-inline p-0 col-md-6 mb-4 pr-4">
							<label htmlFor="inputEmail4" className="text-default">
								Email
							</label>

							<input
								type="email"
								className="form-control"
								id="inputEmail4"
								{...register('email', { required: true, pattern: /^\S+@\S+$/i })}
							/>
							{errors.email && <p className="ml-1 text-danger">Email is required and must be valid</p>}
						</div>
						<div className="form-group-inline p-0 col-md-6 mb-4 pr-4">
							<label htmlFor="regNumber" className="text-default">
								Mobile no
							</label>
							<PhoneInput
								defaultCountry="AE"
								addInternationalOption={false}
								placeholder="Mobile Number"
								value={mobileNumber}
								onChange={(phone) => {
									setMobileNumber(phone);
								}}
							/>
						</div>
					</div>
					<div className="row w-100 m-0 mt-3">
						<div className="btnarea w-100">
							<button type="submit" className="btn btn-primary btn-outlined-main">
								<FaSave /> Save
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ProfileForm;
