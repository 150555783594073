import React, { useState, useRef, useEffect } from 'react';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import { Modal } from 'react-bootstrap';
import { handleAddress } from 'utils/addressUtils';
import { FaMinus, FaPlus } from 'react-icons/fa';

const CreateSupplier = ({ open, close, selectedSupplier, reloadsup }) => {
    const vatNumberRef = useRef();
    const fullNameRef = useRef();
    const registrationNumberRef = useRef();
    const addressFieldRef = useRef();
    const autoCompleteRef = useRef();
    const [supplierinfo, setsupplierinfo] = useState({
        company_name: '',
        vatNumber: '',
        registrationNumber: '',
        addressField: '',

    });
    const [vatValidationAlert, setVatvalidationlert] = useState('');
    const [alertMessage, setAlertmessage] = useState({});
    const handleChange = (val) => {
        setsupplierinfo(val);
    }
    const namehandle = (e) => {
        let inputValue = e.target.value;
        const words = inputValue.split(' ');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
        inputValue = capitalizedWords.join(' ');
        e.target.value = inputValue;
    };
    const options = {
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['establishment']
    };
    const handlevatValidation = (e) => {
        let inputValue = e.target.value;
        const words = inputValue.split(' ');
        const capitalizedWords = words.map((word) => word.substr(0).toUpperCase());
        inputValue = capitalizedWords.join(' ');
        e.target.value = inputValue;
    };
    const vatNumberValidation = () => {
        const vatNumbr = vatNumberRef.current.value;
        if (vatNumbr) {
            api.vatValidation(vatNumbr).then((res) => {
                if (res.errors) {
                    setVatvalidationlert(false);
                    setAlertmessage({
                        vatNumber: 'Format is not valid.'
                    });
                    vatNumberRef.current.focus();
                } else {
                    if (res.format_valid == false) {
                        setVatvalidationlert(false);
                        setAlertmessage({
                            vatNumber: 'Format is not valid.'
                        });
                        vatNumberRef.current.focus();
                    } else if (res.registration_info?.is_registered == false) {
                        setVatvalidationlert(false);
                        setAlertmessage({
                            vatNumber: 'VAT Number is not registered.'
                        });
                        vatNumberRef.current.focus();
                    } else {
                        setAlertmessage({
                            vatNumber: ''
                        });
                        setVatvalidationlert(true);
                        handleChange({ ...supplierinfo, vatNumber: vatNumbr });
                    }
                }
            });
        } else {
            handleChange({ ...supplierinfo, vatNumber: vatNumbr });
        }
    };
    const onSubmit = () => {
        if (supplierinfo.name.trim() === '') {
            setAlertmessage({
                fullName: 'Company Name is required.'
            });
            fullNameRef.current.focus();
        } else {
            api.addSupplier({
                company_name: supplierinfo.name,
                company_email: '',
                vat_number: supplierinfo.vatNumber,
                registration_number: supplierinfo.registrationNumber
            }).then((res) => {
                if (res.status) {
                    console.log(res.data)
                    selectedSupplier(open, { id: res.data.id, label: res.data.name });
                    reloadsup()
                    close();
                    setAlertmessage('')

                } else {
                    if (res.errors?.vat_number) {
						setAlertmessage({
							vatNumber: res.errors.vat_number
						});
					}
					else if (res.errors?.registration_number) {
						setAlertmessage({
							registrationNumber: res.errors.registration_number
						});
					}
					else {
						setAlertmessage({
							failed: res.message
						});
					}
                    setTimeout(() => {
                        setAlertmessage();
                    }, 2000);
                }
            });
        }
        setTimeout(() => {
            setAlertmessage('')
        }, 1500)
    }
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(addressFieldRef.current, options);
        autoCompleteRef.current.addListener('place_changed', () => {
            const place = autoCompleteRef.current.getPlace();
            const addressDetails = handleAddress(place);
            console.log(addressDetails);
            handleChange((supplierinfo) => ({
                ...supplierinfo,
                addressField: addressFieldRef.current.value,
                zipCode: addressDetails?.zipCode,
                country: addressDetails?.country,
                state: addressDetails?.state,
                city: addressDetails?.city
            }));
        });
    }, [open]);
    return (
        <Modal show={open} onHide={close} centered>
            <Modal.Body>
                <div className="row py-3 px-2">
                    <div className="col-md-12">
                        <h4 className="h6 text-white text-left pb-4">
                            Create Supplier
                        </h4>
                        <div className="custom-control custom-checkbox inputchechbox py-2">
                            <form className="suppliecreatepop form-row">
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label className="">Company Name</label>
                                        <input
                                            ref={fullNameRef}
                                            type='text'
                                            id="input-example"
                                            name="input-name"
                                            className="form-control"
                                            placeholder="Enter Company Name"
                                            onChange={(e) => {
                                                namehandle(e);
                                                handleChange({ ...supplierinfo, company_name: e.target.value });
                                            }}
                                        />
                                        {alertMessage?.fullName && (
                                            <>
                                                <span className="error-message text-danger">{alertMessage?.fullName}</span>
                                            </>
                                        )}
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="">Vat Number</label>
                                        <div className="vatCheck">
                                            <input
                                                ref={vatNumberRef}
                                                type="text"
                                                className="form-control"
                                                id="inputName"
                                                placeholder="VAT Number "
                                                onChange={(e) => {
                                                    handlevatValidation(e);
                                                }}
                                                onBlur={vatNumberValidation}
                                            />
                                            {vatValidationAlert == true && <i className="fas fa-check"></i>}
                                            {vatValidationAlert == false && alertMessage?.vatNumber && (
                                                <i className="fas fa-times"></i>
                                            )}
                                        </div>
                                        {alertMessage?.vatNumber && (
                                            <>
                                                <span className="error-message text-danger">{alertMessage?.vatNumber}</span>
                                            </>
                                        )}

                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="">Registration number</label>
                                        <input
                                            ref={registrationNumberRef}
                                            type='text'
                                            id="input-example"
                                            name="input-name"
                                            className="form-control"
                                            placeholder="Enter Registration Number"
                                            onChange={(e) => {
                                                handleChange({ ...supplierinfo, registrationNumber: e.target.value });
                                            }}
                                        />
                                        {alertMessage?.registrationNumber && (
                                            <>
                                                <span className="error-message text-danger">{alertMessage?.registrationNumber}</span>
                                            </>
                                        )}
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="">Address</label>
                                        <input
                                            ref={addressFieldRef}
                                            type='text'
                                            id="input-example"
                                            name="input-name"
                                            className="form-control"
                                            placeholder="Enter Address"
                                            onChange={(e) => {
                                                handleChange({ ...supplierinfo, addressField: e.target.value });
                                            }}
                                        />
                                        {alertMessage?.addressField && (
                                            <>
                                                <span className="error-message text-danger">{alertMessage?.addressField}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row w-100">
                    <div className="btnarea w-100">
                        <button
                            onClick={close}
                            className="btn btn-link"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onSubmit}
                            className="btn btn-primary btn-outlined-main"
                        >
                            <FaPlus /> Create
                        </button>
                    </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    )
}

export default CreateSupplier
