import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './repairing.scss';
import Scroller from 'ui-component/Scroller';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiPackageBold, PiTestTubeFill, PiWarningFill } from 'react-icons/pi';
import { FaCheck, FaCheckCircle, FaPen, FaPlay, FaPlus, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { ImSpinner2 } from 'react-icons/im';
import ProcessIndicator from '../ProcessIndicator';
import { BsClipboardCheck } from 'react-icons/bs';
import { GiAutoRepair, GiHandTruck, GiMagnifyingGlass } from 'react-icons/gi';
import { BiCategoryAlt } from 'react-icons/bi';
import { IoMdBarcode } from 'react-icons/io';
import { getStatusColor } from '../TaskGrid/Task';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import AddRepairsModal from 'ui-component/confirmation-dialogs/addRepairsModal/AddRepairsModal';
import toast from 'react-hot-toast';
import index from 'views/estimation';
import { AiFillDropboxCircle } from 'react-icons/ai';
import { FaCircleStop } from 'react-icons/fa6';
import LinearProgressWithLabel from 'ui-component/LinearProgressWithLabel';
import CircularProgressWithLabel from 'ui-component/CircularProgressWithLabel';
import ItemRepairsModal from 'ui-component/confirmation-dialogs/ItemRepairsModal/ItemRepairsModal';
import PrintContent from 'ui-component/PrintPreviewDialog/PrintContent';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';
import { taskboardProgresses } from 'assets/helperdata/taskboard-progrss';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

function RepairDetails() {
	const { id } = useParams();
	const history = useNavigate();
	const topSection = useRef(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [repairData, setRepairData] = useState([]);
	const [collectionModals, setCollectionModals] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState();
	const [selectedColor, setSelectedColor] = useState();
	const [imeiInputValue, setImeiInputValue] = useState('');
	const imeiInputRef = useRef(null);
	const [checkModelImeis, setCheckModelImeis] = useState(true);
	const [isInProgress, setIsInProgress] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [colors, setColors] = useState([]);
	const [modelRepairOptions, setModelRepairOptions] = useState([]);
	const repairLabelPrintRef = useRef();
	const handlePrintProcessForm = useReactToPrint({
		bodyClass: 'print-repair-label',
		content: () => repairLabelPrintRef.current
	});

	const onPrintLabelClicked = (e) => {
		setTimeout(() => {
			handlePrintProcessForm();
		}, 1);
	};

	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const [repairModal, setRepairModal] = useState(null);

	const handleRepairDialogClose = () => {
		setRepairModal(null);
	};

	const updateStockRepairState = (stock_id, action) => {
		showConfirmationDialog(
			action == 'start'
				? 'Start Repair'
				: action == 'pause'
				? 'Pause Repair'
				: action == 'resume'
				? 'Resume Repair'
				: action == 'end'
				? 'End Repair'
				: 'Update Repair',
			`Are you sure you want to ${
				action == 'start'
					? 'start repair'
					: action == 'pause'
					? 'pause repair'
					: action == 'resume'
					? 'resume repair'
					: action == 'end'
					? 'end repair'
					: 'update repair'
			}`,
			() => {
				handleCloseConfirmationDialog();
				if (action == 'end') {
					setRepairModal(null);
				}
				toast.promise(
					api.stockRepairState(stock_id, action).then(
						(res) => {
							if (res.status) {
								setRepairData((prevRepData) => ({
									...prevRepData,
									task_repair_details: prevRepData?.task_repair_details?.map((rd) => {
										if (rd?.stock_id == stock_id) {
											return {
												...rd,
												status:
													action == 'start' || action == 'resume'
														? 'inprogress'
														: action == 'pause'
														? 'paused'
														: action == 'end'
														? 'completed'
														: 'upcoming'
											};
										} else {
											return rd;
										}
									})
								}));
								if (action != 'end') {
									setRepairModal((prevRepModal) => ({
										...prevRepModal,
										status:
											action == 'start' || action == 'resume'
												? 'inprogress'
												: action == 'pause'
												? 'paused'
												: action == 'end'
												? 'completed'
												: 'upcoming'
									}));
								}
							}

							setIsDisable(false);
							return res?.status ? res : null;
						},
						() => {
							setIsDisable(false);
						}
					),
					{
						loading:
							action == 'start'
								? 'Starting Repair'
								: action == 'pause'
								? 'Pausing Repair'
								: action == 'resume'
								? 'Resuming Repair'
								: action == 'end'
								? 'Ending Repair'
								: 'Updating Repair',
						success: (data) => `${data.message}`,
						error:
							action == 'start' ? (
								<b>Failed to start repairing</b>
							) : action == 'pause' ? (
								<b>Failed to pause repairing</b>
							) : action == 'resume' ? (
								<b>Failed to resume repairing</b>
							) : action == 'end' ? (
								<b>Failed to end repairing</b>
							) : (
								<b>Failed to update repairing</b>
							)
					}
				);
			},
			handleCloseConfirmationDialog
		);
	};

	const handleRepairItemCheck = (id, status) => {
		if (status == 'pending') {
			showConfirmationDialog(
				'Complete Task',
				'Are you sure you wan to mark this repair as complete?',
				() => {
					handleCloseConfirmationDialog();

					toast.promise(
						api.stockRepairItemComplete(id).then((res) => {
							if (res?.status) {
								setRepairData((prevRepData) => ({
									...prevRepData,
									task_repair_details: prevRepData?.task_repair_details?.map((rd) =>
										rd?.stock?.repairs?.some((rr) => rr?.id == id)
											? {
													...rd,
													repair_progress: {
														...rd?.repair_progress,
														completed: rd?.repair_progress?.completed + 1,
														pending: rd?.repair_progress?.pending - 1
													},
													stock: {
														...rd.stock,
														repairs: rd?.stock?.repairs?.map((rr) =>
															rr?.id == id ? { ...rr, status: 'completed' } : { ...rr }
														)
													}
											  }
											: { ...rd }
									)
								}));

								setRepairModal((rd) => ({
									...rd,
									repair_progress: {
										...rd?.repair_progress,
										completed: rd?.repair_progress?.completed + 1,
										pending: rd?.repair_progress?.pending - 1
									},
									stock: {
										...rd.stock,
										repairs: rd?.stock.repairs?.map((rr) => (rr?.id == id ? { ...rr, status: 'completed' } : { ...rr }))
									}
								}));
							}

							return res?.status ? res : null;
						}),
						{
							loading: 'Updating Task Status...',
							success: (data) => `${data.message}`,
							error: <b>Failed to update task status</b>
						}
					);
				},
				handleCloseConfirmationDialog
			);
		}
	};

	const handleRequestNewRepair = (stock_id, rep, resetNew = () => {}) => {
		showConfirmationDialog(
			'Repair Approval',
			' Are you sure you want to request for approval for this repair',
			() => {
				toast.promise(
					api
						?.newRepairRequest({
							task_repair_id: id,
							stock_id: stock_id,
							repair_cost_id: rep?.id
						})
						.then((res) => {
							if (res?.status) {
								setRepairData((prevRepData) => ({
									...prevRepData,
									task_repair_details: prevRepData?.task_repair_details?.map((st) =>
										st?.stock_id == stock_id
											? {
													...st,
													stock: { ...st?.stock, repairs: [...st?.stock?.repairs, res?.data] },
													repair_progress: { ...st?.repair_progress, total: st?.repair_progress?.total + 1 }
											  }
											: { ...st }
									)
								}));

								setRepairModal((st) => ({
									...st,
									stock: { ...st?.stock, repairs: [...st?.stock?.repairs, res?.data] },
									repair_progress: { ...st?.repair_progress, total: st?.repair_progress?.total + 1 }
								}));

								resetNew();
								handleCloseConfirmationDialog();
							}

							return res?.status ? res : null;
						}),
					{
						loading: 'Saving Repair Request...',
						success: (data) => `${data.message}`,
						error: <b>Failed to save request. Please Try Again!</b>
					}
				);
			},
			handleCloseConfirmationDialog
		);
	};

	const handleCancelRepairRequest = (stock_repair_id, stock_id) => {
		showConfirmationDialog('Cancel Request', 'Are you sure you wan to cancel this repair request?', () => {
			toast.promise(
				api?.cancelRepairRequest(stock_repair_id).then((res) => {
					if (res?.status) {
						setRepairData((prevRepData) => ({
							...prevRepData,
							task_repair_details: prevRepData?.task_repair_details?.map((st) =>
								st?.stock_id == stock_id
									? {
											...st,
											stock: { ...st?.stock, repairs: st?.stock?.repairs.filter((sr) => sr?.id != stock_repair_id) },
											repair_progress: { ...st?.repair_progress, total: st?.repair_progress?.total - 1 }
									  }
									: { ...st }
							)
						}));

						setRepairModal((st) => ({
							...st,
							stock: { ...st?.stock, repairs: st?.stock?.repairs.filter((sr) => sr?.id != stock_repair_id) },
							repair_progress: { ...st?.repair_progress, total: st?.repair_progress?.total - 1 }
						}));

						handleCloseConfirmationDialog();
					}

					return res?.status ? res : null;
				}),
				{
					loading: 'Cancelling Repair Request...',
					success: (data) => `${data.message}`,
					error: <b>Failed to cancel request. Please Try Again!</b>
				}
			);
		});
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const getCollectionData = (callback = () => {}) => {
		setLoading(true);
		setIsDisable(true);
		api.getrepairingdetailsbyid(id).then((res) => {
			setIsDisable(false);
			setLoading(false);
			setRepairData(res?.data);
			callback(res?.data);
		});
	};

	const loadColorData = () => {
		api.getAllColors().then((res) => {
			if (res.status) {
				let typeTemp = res.data.map((item) => ({
					value: item.name?.[0].toUpperCase() + item.name?.substring(1),
					label: item.name?.[0].toUpperCase() + item.name?.substring(1)
				}));
				setColors(typeTemp);
			}
		});
	};

	const handleIMEIInputKeydown = (event) => {
		if (event.key === 'Enter') {
			searchModalByImei(imeiInputValue);
		}
	};

	const [searchImei, setSearchImei] = useState(null);

	const searchModalByImei = (imei) => {
		const stockItem = repairData?.task_repair_details?.find((m) => m?.stock?.imei == imei);
		if (stockItem) {
			const row = document.getElementById(imei);
			row.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			setSearchImei(imei);
			setRepairModal(stockItem);
			setTimeout(() => {
				setSearchImei(null);
			}, 2000);
		} else {
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			showErrorDialog('Invalid IMEI', `The IMEI you entered does not match with any items in this list`);
		}
	};

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => {},
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	
	useEffect(()=> {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, [])
	
	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	}

	const onRepairClick = (item) => {
		if (!modelRepairOptions.find((m) => m?.model_id == item?.stock?.model?.id)) {
			api.getModelRepairOptions(item?.stock?.model?.id).then((res) => {
				if (res?.status) {
					setModelRepairOptions([
						...modelRepairOptions,
						{
							model_id: item?.stock?.model?.id,
							options: res?.data
						}
					]);
				}
			});
		}
		setRepairModal(item);
	};

	useEffect(() => {
		if (id) {
			getCollectionData((data) => {
				setIsInProgress(!!data?.start_at);
				if (!data?.start_at) {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Start QualityControling?',
							'Are you sure you want to start inbounding?',
							() => {
								handleStart();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				} else {
					if (searchParams.get('start')) {
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				}
			});
		}
		loadColorData();
	}, []);

	const onStartClick = () => {
		showConfirmationDialog(
			'Start Repairing?',
			'Are you sure you want to start repairing this batch?',
			() => {
				handleStart();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleStart = () => {
		setIsDisable(true);
		toast.promise(
			api.startRepairing(id).then(
				(res) => {
					if (res.status) {
						setIsInProgress(true);
					}

					setIsDisable(false);
					return res?.status ? res : null;
				},
				() => {
					setIsDisable(false);
				}
			),
			{
				loading: 'Starting Repair...',
				success: (data) => `${data.message}`,
				error: <b>Failed to start repairing</b>
			}
		);
	};

	const handleSubmitClicked = () => {
		showConfirmationDialog(
			'Complete Repair?',
			'Are you sure you want to complete this repair?',
			() => {
				handleSubmit();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleSubmit = () => {
		setIsDisable(true);
		const data = {
			task_repair_id: id
		};

		toast.promise(
			api.saveRepairingData(data).then((res) => {
				if (res.status) {
					setIsDisable(false);
					history('/operations/repairing');
				}
				return res?.status ? res : null;
			}),
			{
				loading: 'Adding to stock...',
				success: (data) => `${data.message}`,
				error: (data) => {
					setIsDisable(false);
					return <b>Failed to add to stock</b>;
				}
			}
		);
	};
	const goback = () => {
		history('/operations/repairing');
	};

	return (
		<>
			<ProcessIndicator isInProgress={isInProgress} processes={taskboardProgresses} currentIndex={6} />

			<div className={`TaskDetails`}>
				<div className="d-flex justify-content-between mb-0">
					<div className="topBtnSign" style={{ '--statusColor': getStatusColor(!repairData?.end_at ? 'blue' : 'green') }}>
						<span>{repairData?.start_at && repairData?.end_at ? 'Completed' : isInProgress ? 'Inprogress' : 'Upcoming'}</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card p-4">
							<Scroller>
								<div className="row pb-4" ref={topSection}>
									<div className="col-12 d-flex justify-content-between mb-4">
										<h3 className="font-weight-bold m-0">{repairData?.estimation?.name}</h3>
									</div>
									<div className="col-12">
										<div className="row">
											<div className="col-md-3 mb-2 mb-md-0 detail-item">
												<p className="detail-label">Reference.</p>
												<div className="detail-value">{repairData?.reference_no}</div>
											</div>

											<div className=" col-md-3 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Customer</p>
												<div className="detail-value">
													<PrivacyModeContent>{repairData?.customer?.name}</PrivacyModeContent>
												</div>
											</div>

											<div className=" col-md-3 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Supplier</p>
												<div className="detail-value">
													<PrivacyModeContent>{repairData?.supplier?.name}</PrivacyModeContent>
												</div>
											</div>

											<div className="col-md-3 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label" style={{ fontSize: '11px' }}>
													Quantity
												</p>
												<div className="detail-value">{repairData?.task_repair_details?.length} Pcs</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row inbounding-imeis">
									<div className="col-xl-12 col-lg-12">
										<div className="row">
											<div className="col-3">
												<div className="card shadow-sm bg-white mb-4">
													<div className="input-form p-0">
														<div className="form-row col-md-12 m-0 p-0">
															<div className="form-group">
																<label htmlFor="">IMEI</label>
																<input
																	ref={imeiInputRef}
																	type="text"
																	id="input-example"
																	name="input-name"
																	className="form-control form-control-sm"
																	placeholder="IMEI"
																	onChange={(e) => {
																		setImeiInputValue(event.target.value);
																	}}
																	onKeyDown={(e) => {
																		handleIMEIInputKeydown(e);
																	}}
																	disabled={!isInProgress || isDisable}
																	autoComplete={'off'}
																	autoFocus
																/>
															</div>
															<label htmlFor="">Progress</label>
															<div className="product-list mb-2">
																<table>
																	<tbody>
																		<tr>
																			<th className="text-center" width="50%">
																				In Progress
																			</th>
																			<th className="text-center">Needs Approval</th>
																		</tr>
																		<tr>
																			<td className="text-center">
																				{
																					repairData?.task_repair_details?.filter(
																						(r2) => r2?.status == 'inprogress'
																					)?.length
																				}
																			</td>
																			<td className="text-center">
																				{repairData?.task_repair_details?.reduce(
																					(prevSize, r) =>
																						prevSize +
																						r?.stock?.repairs?.filter((r2) => !r2?.is_approved)
																							?.length,
																					0
																				)}
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
															<div className="product-list">
																<table>
																	<tbody>
																		<tr>
																			<th>All Repairs</th>
																			<td className="text-right">
																				<small>
																					{repairData?.task_repair_details?.reduce(
																						(prevSize, r) =>
																							prevSize +
																							r?.stock?.repairs?.filter(
																								(r2) => r2?.status == 'completed'
																							)?.length,
																						0
																					)}{' '}
																					/{' '}
																					{repairData?.task_repair_details?.reduce(
																						(prevSize, r) =>
																							prevSize + r?.stock?.repairs?.length,
																						0
																					)}
																				</small>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan={2}>
																				<LinearProgressWithLabel
																					value={
																						(repairData?.task_repair_details?.reduce(
																							(prevSize, r) =>
																								prevSize +
																								r?.stock?.repairs?.filter(
																									(r2) => r2?.status == 'completed'
																								)?.length,
																							0
																						) /
																							repairData?.task_repair_details?.reduce(
																								(prevSize, r) =>
																									prevSize + r?.stock?.repairs?.length,
																								0
																							)) *
																						100
																					}
																				/>
																			</td>
																		</tr>
																		<tr>
																			<th>Items Completed</th>
																			<td className="text-right">
																				<small>
																					{
																						repairData?.task_repair_details?.filter(
																							(r2) => r2?.status == 'completed'
																						)?.length
																					}{' '}
																					/ {repairData?.task_repair_details?.length}
																				</small>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan={2}>
																				<LinearProgressWithLabel
																					value={
																						(repairData?.task_repair_details?.filter(
																							(r2) => r2?.status == 'completed'
																						)?.length /
																							repairData?.task_repair_details?.length) *
																						100
																					}
																				/>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-9">
												<div className="card shadow-sm bg-white mb-4">
													{/* <div className="table-responsive-xl tableData customersList"> */}
													<Scroller extraHeight={topSection?.current?.clientHeight || 0}>
														<table className="table ">
															<thead>
																<tr>
																	<th scope="col" width="20%">
																		IMEI
																	</th>
																	<th scope="col" width="20%">
																		Model
																	</th>
																	<th scope="col">Storage</th>
																	<th scope="col">Colour</th>
																	<th scope="col">Grade</th>
																	<th scope="col">Repairs</th>
																	<th scope="col">Progress</th>
																	<th scope="col"></th>
																</tr>
															</thead>
															<tbody>
																{repairData?.task_repair_details?.map((item, index) => (
																	<>
																		<tr
																			id={item?.stock?.imei}
																			className={item?.stock?.imei == searchImei ? 'found-row' : ''}
																		>
																			<td>{item?.stock?.imei}</td>
																			<td>{item?.stock?.model?.name}</td>
																			<td>{item?.stock?.storage?.name}</td>
																			<td>{item?.stock?.color?.name}</td>
																			<td>{item?.stock?.grade?.name}</td>
																			<td>
																				{item?.stock?.repairs?.map((rp, i) =>
																					i == item?.stock?.repairs?.length - 1
																						? rp?.repair_cost?.repair_type?.code
																						: rp?.repair_cost?.repair_type?.code + ', '
																				)}
																			</td>
																			<td>
																				{item?.repair_progress?.completed}/
																				{item?.repair_progress?.total}
																			</td>
																			<td>
																				{repairData?.status != 'completed' && (
																					<>
																						{item?.status == 'upcoming' && isInProgress && (
																							<span
																								className={`btnEdit plain-textbtn plain-start`}
																								style={{
																									height: '20px',
																									minWidth: '75px',
																									fontSize: '10px',
																									width: 'fit-content'
																								}}
																								onClick={() => {
																									onRepairClick(item);
																								}}
																							>
																								Start
																							</span>
																						)}

																						{item?.status == 'inprogress' && isInProgress && (
																							<span
																								className={`btnEdit plain-textbtn plain-stop`}
																								style={{
																									height: '20px',
																									minWidth: '75px',
																									fontSize: '10px',
																									width: 'fit-content'
																								}}
																								onClick={() => {
																									onRepairClick(item);
																								}}
																							>
																								Inprogress
																							</span>
																						)}

																						{item?.repair_progress?.completed <
																							item?.repair_progress?.total &&
																							item?.status == 'paused' &&
																							isInProgress && (
																								<span
																									className={`btnEdit plain-textbtn plain-continue`}
																									style={{
																										height: '20px',
																										minWidth: '75px',
																										fontSize: '10px',
																										width: 'fit-content'
																									}}
																									onClick={() => {
																										onRepairClick(item);
																									}}
																								>
																									Resume
																								</span>
																							)}

																						{item?.repair_progress?.completed ==
																							item?.repair_progress?.total &&
																							item?.status == 'paused' &&
																							isInProgress && (
																								<span
																									className={`btnEdit plain-textbtn plain-finish`}
																									style={{
																										height: '20px',
																										minWidth: '75px',
																										fontSize: '10px',
																										width: 'fit-content'
																									}}
																									onClick={() => {
																										onRepairClick(item);
																									}}
																								>
																									Finish
																								</span>
																							)}
																					</>
																				)}
																				{((item?.status == 'completed' && isInProgress) ||
																					!isInProgress ||
																					repairData?.status == 'completed') && (
																					<span
																						className={`btnEdit plain-textbtn plain-finish`}
																						style={{
																							height: '20px',
																							minWidth: '75px',
																							fontSize: '10px',
																							width: 'fit-content'
																						}}
																						onClick={() => {
																							onRepairClick(item);
																						}}
																					>
																						View
																					</span>
																				)}
																			</td>
																		</tr>
																	</>
																))}
															</tbody>
														</table>
													</Scroller>
													{/* </div> */}
												</div>
											</div>
										</div>
										{/* <div className="card shadow-sm bg-white mb-4">
						<div className="row">
							<div className="col-4"></div>
							<div className="col-8">
								
							</div>
						</div>
					</div> */}
									</div>
								</div>
							</Scroller>
							<div className="btnarea">
								<span
									onClick={() => {
										goback();
									}}
									className={`btn btn-link ${isDisable ? 'disabled' : ''}`}
								>
									Back
								</span>
								<span className="btn btn-primary btn-outlined-main" onClick={() => onPrintLabelClicked()}>
									Print Label
								</span>
								<PermissionAllowed permissions={['Start / End Task Repairing (Action)']}>
									{repairData?.status != 'completed' &&
										(isInProgress ? (
											<span
												onClick={() => {
													if (
														!isDisable &&
														repairData?.task_repair_details?.filter((r2) => r2?.status == 'completed')?.length >=
															repairData?.task_repair_details?.length
													) {
														handleSubmitClicked();
													}
												}}
												className={`btn btn-primary btn-outlined-main  ${
													isDisable ||
													repairData?.task_repair_details?.filter((r2) => r2?.status == 'completed')?.length <
														repairData?.task_repair_details?.length
														? 'disabled'
														: ''
												}`}
											>
												Done
											</span>
										) : (
											<span
												onClick={() => {
													onStartClick();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Start
											</span>
										))}
								</PermissionAllowed>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="d-none">
				<PrintContent ref={repairLabelPrintRef}>
					<style type="text/css" media="print">
						{
							'\
  @page { size: 100mm 50mm; }\
  .print-repair-label-box-barcode svg {\
	width: 100% }\
'
						}
					</style>
					<div
						className="print-repair-label-box"
						style={{
							textAlign: 'center',
							width: '100vw',
							height: '100dvh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							gap: '2mm'
						}}
					>
						<div
							className="print-repair-label-box-barcode"
							style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
						>
							<Barcode value={repairData?.reference_no} height={'30px'} fontSize={'12px'} />
						</div>
						<h3 style={{ width: '100%', fontSize: '40px', color: 'black', fontWeight: 600 }}>{repairData?.estimation?.name}</h3>
						<h3 style={{ width: '100%', fontSize: '40px', color: 'black', fontWeight: 600 }}>{repairData?.customer?.name}</h3>
						<h3 style={{ width: '100%', fontSize: '40px', color: 'black', fontWeight: 600 }}>
							{repairData?.task_repair_details?.length} Pcs
						</h3>
					</div>
				</PrintContent>
			</div>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}

			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}

			{repairModal && (
				<ItemRepairsModal
					mainTaskCompleted={repairData?.status == 'completed'}
					isInprogress={isInProgress}
					show={repairModal}
					handleClose={handleRepairDialogClose}
					onSubmit={() => {}}
					repairOptions={modelRepairOptions?.find((m) => m?.model_id == repairModal?.stock?.model?.id)?.options}
					handleStockRepairState={(e) => updateStockRepairState(repairModal?.stock?.id, e)}
					handleRepairItemCheck={handleRepairItemCheck}
					handleRequestNewRepair={(e, resetNew) => {
						handleRequestNewRepair(repairModal?.stock?.id, e, resetNew);
					}}
					handleCancelRepairRequest={(e) => {
						handleCancelRepairRequest(e, repairModal?.stock?.id);
					}}
				/>
			)}
		</>
	);
}

export default RepairDetails;
