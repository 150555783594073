import React, { useState, useEffect, useRef } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useNavigate, useParams } from 'react-router';
import { checkObjectMatch, handleAddress } from 'utils/addressUtils';
import CreateHeader from 'ui-component/CreateHeader';
import Scroller from 'ui-component/Scroller';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setContactlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import { FaMinus, FaPlus, FaSave, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { allCountriesList } from 'assets/helperdata/countries-list';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaCircleCheck } from 'react-icons/fa6';
import { ImSpinner2 } from 'react-icons/im';
import Select from 'react-select';
import FileUpload from 'ui-component/FileUpload';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Tooltip } from '@mui/material';

import './contactsforms.scss';
import toast from 'react-hot-toast';

const contactTypes = [
	{ label: 'Customer', value: 'customer' },
	{ label: 'Lead', value: 'lead' },
	{ label: 'Supplier', value: 'supplier' },
	{ label: 'Transit Company', value: 'transit-company' },
	{ label: 'Logistic', value: 'logistic' }
];

const transitCompanyTypes = [
	{ label: 'Child Company', value: 1 },
	{ label: 'External', value: 2 }
];

const ContactForm = ({ edit }) => {
	const { id } = useParams();
	const history = useNavigate();
	// UseRef for validation
	const dispatch = useDispatch();
	const nameRef = useRef();
	const establishmentRef = useRef();
	const companyactivityRef = useRef();
	const turnoverRef = useRef();
	const vatNumberRef = useRef();
	const registrationNumberRef = useRef();
	const addressFieldRef = useRef();
	const shippingAddressRef = useRef();
	const salesContactRef = useRef();
	const [files, setFiles] = useState([]);
	const emailRef = useRef();
	const contactPhoneRef = useRef();
	const [disableStatus, setDisablestatus] = useState(false);
	const [isTransitCompany, setIsTransitCompany] = useState(false);
	const [hasVatFile, setHasVatFile] = useState(false);
	const [vatFile, setVatFile] = useState({
		id: '',
		contact_id: '',
		file_name: '',
		type: '',
		vat_attachment_url: ''
	});
	const [hasRegistrationFile, setHasRegistrationFile] = useState(false);
	const [registrationFile, setRegistrationFile] = useState({
		id: '',
		contact_id: '',
		file_name: '',
		type: '',
		registration_attachment_url: ''
	});

	const [hasCompanyLogo, setHasCompanyLogo] = useState(false);
	const [companyLogo, setCompanyLogo] = useState({
		id: '',
		contact_id: '',
		file_name: '',
		type: '',
		registration_attachment_url: ''
	});

	const saleManagerRef = useRef();
	const startYear = 1950;
	const currentYear = new Date().getFullYear();
	const years = [];
	const [companyactivity, setCompanyactivity] = useState([]);
	const [companyturnover, setCompanyturnover] = useState([]);
	const [alertMessage, setAlertmessage] = useState({});
	const [salesPerson, setSalesperson] = useState([]);
	const [companySpecifications, setCompanySpecifications] = useState([]);
	const [companyInterests, setCompanyInterests] = useState([]);
	const [vatValidationAlert, setVatvalidationlert] = useState('');
	const [contactInfo, setContactInfo] = useState({
		contactType: 'customer',
		name: '',
		establishment: '',
		companyactivity: '',
		turnover: '',
		vatNumber: '',
		registrationNumber: '',
		addressField: '',
		country: '',
		state: '',
		city: '',
		zipCode: '',
		salesContact: '',
		emailIds: '',
		website: '',
		contactPhone: '',
		contactWhatsApp: '',
		salesManager: '',
		interests: [],
		specifications: [],
		status: 1,
		transitType: 2,
		vat_attachment_id: '',
		registration_attachment_id: '',
		logo_attachment_id: '',
		shippingAddress: '',
		shippingZipCode: '',
		shippingCity: '',
		shippingCountry: '',
		transitFee: ''
	});
	const options = {
		fields: ['address_components', 'geometry', 'icon', 'name'],
		types: ['establishment']
	};
	const namehandle = (e) => {
		let inputValue = e.target.value;
		const words = inputValue.split(' ');
		const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.substr(1));
		inputValue = capitalizedWords.join(' ');
		e.target.value = inputValue;
	};
	const handleEmail = (e) => {
		const inputValue = e.target.value;
		const lowercaseValue = inputValue.toLowerCase();
		e.target.value = lowercaseValue;
	};
	const handleSalesContact = (e) => {
		const inputValue = e.target.value;
		const stringWithoutNumbers = inputValue.replace(/\d+/g, '');
		e.target.value = stringWithoutNumbers;
	};

	const [vatCheckTimer, setVatCheckTimer] = useState();

	const handlevatValidation = (e) => {
		let inputValue = e.target.value;
		setContactInfo({ ...contactInfo, vatNumber: inputValue });
		inputValue = inputValue.trim();
		const words = inputValue.split(' ');
		const capitalizedWords = words.map((word) => word.substr(0).toUpperCase());
		inputValue = capitalizedWords.join(' ');
		e.target.value = inputValue;

		if (vatCheckTimer) {
			clearTimeout(vatCheckTimer);
		}

		let timeout = setTimeout(() => {
			vatNumberValidation();
		}, 1000);

		setVatCheckTimer(timeout);
	};
	const handleRegistrationValidation = (e) => {
		let inputValue = e.target.value;
		const words = inputValue.split(' ');
		const capitalizedWords = words.map((word) => word.substr(0).toUpperCase());
		inputValue = capitalizedWords.join(' ');
		e.target.value = inputValue;
	};
	const handleChange = (val) => {
		setContactInfo(val);
	};
	const vatNumberValidation = () => {
		const vatNumbr = vatNumberRef.current.value;
		if (vatNumbr) {
			setVatvalidationlert('loading');
			api.vatValidation(vatNumbr).then((res) => {
				if (res.errors) {
					setVatvalidationlert(false);
					setAlertmessage({
						vatNumber: 'Format is not valid.'
					});
				} else {
					if (res.format_valid == false) {
						setVatvalidationlert(false);
						setAlertmessage({
							vatNumber: 'Format is not valid.'
						});
					} else if (res.registration_info?.is_registered == false) {
						setVatvalidationlert(false);
						setAlertmessage({
							vatNumber: 'VAT Number is not registered.'
						});
					} else if (res?.message?.includes('You used all your monthly requests')) {
						setVatvalidationlert(false);
						setAlertmessage({
							vatNumber: 'You used all your monthly requests'
						});
					} else {
						setAlertmessage({
							vatNumber: ''
						});
						setVatvalidationlert(true);
					}
				}
				handleChange({ ...contactInfo, vatNumber: vatNumbr });
			});
		} else {
			handleChange({ ...contactInfo, vatNumber: vatNumbr });
		}
	};

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

	const handleFileUpload = (e, attachmentType) => {
		const files = e.target.files;
		setFiles(files);
		setDisablestatus(true);
		if (files && files.length > 0) {
			const formData = new FormData();
			for (let i = 0; i < files.length; i++) {
				formData.append('attachment_file', files[i]);
				formData.append('attachment_type', attachmentType);
			}
			if (id) {
				formData.append('contact_id', id);
			}
			api.addContactVatFile(formData).then((res) => {
				setDisablestatus(false);
				if (attachmentType == 'vat') {
					setContactInfo({ ...contactInfo, vat_attachment_id: res?.data?.attachment_id });
				}
				if (attachmentType == 'registration') {
					setContactInfo({ ...contactInfo, registration_attachment_id: res?.data?.attachment_id });
				}
				if (attachmentType == 'companylogo') {
					setContactInfo({ ...contactInfo, logo_attachment_id: res?.data?.attachment_id });
				}
			});
		}
	};

	const handleRemoveAttachment = (e, attachmentType) => {
		console.log(e);
		if (attachmentType == 'vat') {
			setHasVatFile(false);
		} else if (attachmentType == 'registration') {
			setHasRegistrationFile(false);
		} else if (attachmentType == 'companylogo') {
			setHasCompanyLogo(false);
		}
		console.log(attachmentType);
	};

	const handleSubmit = (e) => {
		if (edit && id) {
			e.preventDefault();
			let sameValue = false;
			if (contactInfo.name.trim() === '') {
				setAlertmessage({
					name: 'Company Name is required.'
				});
				nameRef.current.focus();
			} else if (contactInfo.salesManager.length === 0) {
				setAlertmessage({
					salesManager: 'Sales Manager is required.'
				});
				saleManagerRef.current.focus();
			} else if (sameValue) {
				goback();
			} else {
				setIsSubmitDisabled(true);

				let contactPayloadExtra = {};

				// if (contactInfo?.contactType == 'lead' || contactInfo?.contactType == 'customer') {
				contactPayloadExtra = {
					...contactPayloadExtra,
					sales_manager_id: contactInfo?.salesManager || ''
				};
				// }

				if (contactInfo?.contactType == 'transit-company') {
					contactPayloadExtra = {
						...contactPayloadExtra,
						transit_type: contactInfo?.transitType || ''
					};
				}

				toast.promise(
					api
						.updateContact(id, {
							contact_type: contactInfo?.contactType,
							name: contactInfo.name,
							email: contactInfo.emailIds,
							vat_number: contactInfo.vatNumber,
							registration_number: contactInfo.registrationNumber,
							contact_name: contactInfo.salesContact,
							contact_phone: contactInfo.contactPhone,
							contact_whatsapp: contactInfo.contactWhatsApp || '',
							address: contactInfo?.addressField,
							country: contactInfo?.country || '',
							state: contactInfo?.state,
							city: contactInfo?.city || '',
							zipcode: contactInfo?.zipCode || '',
							establishment_year: contactInfo.establishment || '',
							company_activity_id: contactInfo?.companyactivity || '',
							turnover_id: contactInfo?.turnover || '',
							company_website: contactInfo?.website || '',
							status: contactInfo.status,
							is_vat_verified: vatValidationAlert == true ? true : false,
							interests: contactInfo?.interests,
							specifications: contactInfo?.specifications,
							shipping_address: contactInfo?.shippingAddress,
							shipping_zipcode: contactInfo?.shippingZipCode,
							shipping_city: contactInfo?.shippingCity,
							shipping_country: contactInfo?.shippingCountry,
							transit_fee: contactInfo?.transitFee,
							...contactPayloadExtra
						})
						.then(
							(res) => {
								if (res.status) {
									history('/contacts');
								} else {
									if (res.errors?.vat_number) {
										setAlertmessage({
											vatNumber: res.errors.vat_number
										});
										vatNumberRef.current.focus();
									} else if (res.errors?.registration_number) {
										setAlertmessage({
											registrationNumber: res.errors.registration_number
										});
										registrationNumberRef.current.focus();
									} else {
										setAlertmessage({
											failed: res.message
										});
									}
								}

								setIsSubmitDisabled(false);
								return res?.status ? res : null;
							},
							() => {
								setIsSubmitDisabled(false);
							}
						),
					{
						loading: 'Update Contact...',
						success: (data) => `${data.message}`,
						error: <b>Failed to update contact</b>
					}
				);
			}
		} else {
			e.preventDefault();
			if (contactInfo.name.trim() === '') {
				setAlertmessage({
					name: 'Company Name is required.'
				});
				nameRef.current.focus();
			} else if (contactInfo.salesManager.length === 0) {
				setAlertmessage({
					salesManager: 'Sales Manager is required.'
				});
				saleManagerRef.current.focus();
			} else {
				setIsSubmitDisabled(true);

				let contactPayloadExtra = {};

				// if (contactInfo?.contactType == 'lead' || contactInfo?.contactType == 'customer') {
				contactPayloadExtra = {
					...contactPayloadExtra,
					sales_manager_id: contactInfo?.salesManager || ''
				};
				// }

				if (contactInfo?.contactType == 'transit-company') {
					contactPayloadExtra = {
						...contactPayloadExtra,
						transit_type: contactInfo?.transitType || ''
					};
				}

				toast.promise(
					api
						.addContact({
							contact_type: contactInfo?.contactType,
							name: contactInfo.name,
							email: contactInfo.emailIds,
							vat_number: contactInfo.vatNumber,
							registration_number: contactInfo.registrationNumber,
							contact_name: contactInfo.salesContact,
							contact_phone: contactInfo.contactPhone,
							contact_whatsapp: contactInfo.contactWhatsApp || '',
							address: contactInfo?.addressField,
							country: contactInfo?.country || '',
							state: contactInfo?.state,
							city: contactInfo?.city || '',
							zipcode: contactInfo?.zipCode || '',
							establishment_year: contactInfo.establishment || '',
							company_activity_id: contactInfo?.companyactivity || '',
							turnover_id: contactInfo?.turnover || '',
							company_website: contactInfo?.website || '',
							is_vat_verified: vatValidationAlert == true ? true : false,
							interests: contactInfo?.interests,
							specifications: contactInfo?.specifications,
							vat_attachment_id: contactInfo?.vat_attachment_id,
							registration_attachment_id: contactInfo?.registration_attachment_id,
							logo_attachment_id: contactInfo?.logo_attachment_id,
							shipping_address: contactInfo?.shippingAddress,
							shipping_zipcode: contactInfo?.shippingZipCode || '',
							shipping_city: contactInfo?.shippingCity || '',
							shipping_country: contactInfo?.shippingCountry || '',
							transit_fee: contactInfo?.transitFee || 0,
							...contactPayloadExtra
						})
						.then(
							(res) => {
								if (res.status) {
									history('/contacts');
								} else {
									if (res.errors?.vat_number) {
										setAlertmessage({
											vatNumber: res.errors?.vat_number
										});
										vatNumberRef.current.focus();
									} else if (res.errors?.registration_number) {
										setAlertmessage({
											registrationNumber: res.errors.registration_number
										});
										registrationNumberRef.current.focus();
									} else {
										setAlertmessage({
											failed: res.message
										});
									}
								}
								setIsSubmitDisabled(false);
								return res?.status ? res : null;
							},
							() => {
								setIsSubmitDisabled(false);
							}
						),
					{
						loading: 'Creating Contact...',
						success: (data) => `${data.message}`,
						error: <b>Failed to create contact</b>
					}
				);
			}
		}
	};
	const getAttributesList = () => {
		api.getSelectOptionsList('salesperson').then((res) => {
			const tempsalesPerson = res.data.map((item) => ({ id: item.id, name: `${item.first_name} ${item.last_name || ''}` }));
			setSalesperson(tempsalesPerson);
		});

		api.getSelectOptionsList('specification').then((res) => {
			const tempsalesPerson = res.data.map((item) => ({ id: item.id, name: item?.name }));
			setCompanySpecifications(tempsalesPerson);
		});

		api.getSelectOptionsList('interest').then((res) => {
			const tempsalesPerson = res.data.map((item) => ({ id: item.id, name: item?.name }));
			setCompanyInterests(tempsalesPerson);
		});

		api.allAttributes().then((res) => {
			let companyA = res.data.filter((item) => item.type == 'Company Activity').map((item) => ({ id: item.id, name: item.name }));
			let companyT = res.data.filter((item) => item.type == 'Company Turnover').map((item) => ({ id: item.id, name: item.name }));
			setCompanyactivity(companyA);
			setCompanyturnover(companyT);
		});

		if (edit) {
			getSelectedContact();
		}
	};

	useEffect(() => {
		getAttributesList();
	}, []);

	const getSelectedContact = () => {
		setLoading(true);
		api.getContactbyId(id).then((res) => {
			setLoading(false);
			if (!res.data) {
				setLoading(true);
				history('/contacts');
			} else {
				const loadData = res?.data;
				// console.log(res?.data)
				let tempdata = {
					contactType: loadData?.contact_type || '',
					name: loadData?.name || '',
					establishment: loadData?.establishment_year || '',
					companyactivity: loadData?.company_activity?.id || '',
					turnover: loadData?.company_turnover?.id || '',
					vatNumber: loadData?.vat_number || '',
					registrationNumber: loadData?.registration_number || '',
					addressField: loadData?.address?.address || '',
					country: loadData?.address?.country?.name || '',
					state: loadData?.address?.state?.name || '',
					city: loadData?.address?.city?.city || '',
					zipCode: loadData?.address?.zipcode || '',
					salesContact: loadData?.contact_name || '',
					emailIds: loadData?.email || '',
					website: loadData?.company_website || '',
					contactPhone: loadData?.contact_phone || '',
					contactWhatsApp: loadData?.contact_whatsapp || '',
					salesManager: loadData?.sales_manager_id || '',
					interests: loadData?.interests?.map((i) => i?.interest_id),
					specifications: loadData?.specifications?.map((i) => i?.specification_id),
					transitType: loadData?.transit_type || '',
					shippingAddress: loadData?.shipping?.address || '',
					shippingCountry: loadData?.shipping?.country?.name || '',
					shippingCity: loadData?.shipping?.city?.city || '',
					shippingZipCode: loadData?.shipping?.zipcode || '',
					transitFee: loadData?.transit_fee || ''
				};
				console.log(loadData?.contact_type);
				if (loadData?.contact_type == 'transit-company') {
					setIsTransitCompany(true);
				} else {
					setIsTransitCompany(false);
				}
				setVatvalidationlert(loadData?.is_vat_verified ? true : false);
				setAlertmessage((oldData) => ({
					...oldData,
					vatNumber: loadData?.is_vat_verified ? true : 'VAT Not registered'
				}));
				setContactInfo(tempdata);
				loadData?.attachments?.map((i) => {
					if (i?.type == 'vat') {
						setVatFile({
							id: i?.id,
							contact_id: i?.contact_id,
							file_name: i?.file_name,
							type: i?.type,
							vat_attachment_url: i?.vat_attachment_url
						});
						setHasVatFile(true);
					}
					if (i?.type == 'registration') {
						setRegistrationFile({
							id: i?.id,
							contact_id: i?.contact_id,
							file_name: i?.file_name,
							type: i?.type,
							registration_attachment_url: i?.registration_attachment_url
						});
						setHasRegistrationFile(true);
					}
					if (i?.type == 'companylogo') {
						setCompanyLogo({
							id: i?.id,
							contact_id: i?.contact_id,
							file_name: i?.file_name,
							type: i?.type,
							registration_attachment_url: i?.registration_attachment_url
						});
						setHasCompanyLogo(true);
					}
					// console.log(i);
				});
				setLoading(true);
			}
		});
	};

	for (let year = currentYear; year >= startYear; year--) {
		years.push(year);
	}

	const goback = () => {
		history('/contacts');
	};
	return (
		<>
			<div className="row m-0 create-contact-container">
				<div className="card w-100 h-100">
					<form action="" className="h-100" onSubmit={handleSubmit}>
						<div className="section-head m-0 p-0">
							<h5 className="m-0 p-0 pb-2" style={{ fontWeight: '500' }}>
								{edit ? 'Edit Contact' : 'Create Contact'}
							</h5>
						</div>
						<Scroller extraHeight={20}>
							<div className="form-section">
								<div className="form-section-head">
									<h4>General Information</h4>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Company Logo</label>
										<div className="rs-container">
											{hasCompanyLogo ? (
												<>
													<div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
														<a href={companyLogo?.registration_attachment_url} target="_blank">
															<PrivacyModeContent>{companyLogo?.file_name}</PrivacyModeContent>
														</a>
														<Tooltip title="Replace Attachment" arrow placement="top">
															<span
																className="btnEdit plain-iconbtn"
																style={{
																	height: '20px',
																	width: '20px',
																	fontSize: '10px',
																	display: 'inline-block',
																	marginLeft: '20px',
																	paddingLeft: '4px',
																	paddingTop: '1px'
																}}
																onClick={() => {
																	handleRemoveAttachment(companyLogo?.id, 'companylogo');
																}}
															>
																<FaTimes />
															</span>
														</Tooltip>
													</div>
												</>
											) : (
												<></>
											)}
											<div style={{ display: hasCompanyLogo ? 'none' : '' }}>
												<FileUpload
													className="form-control"
													accept=".png,.jpg,.jpeg,.pdf"
													loading={disableStatus}
													onChange={(e) => {
														handleFileUpload(e, 'companylogo');
													}}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Contact Type</label>
										<div>
											<Select
												menuPosition={'fixed'}
												options={contactTypes}
												value={contactTypes?.find((y) => y?.value == contactInfo?.contactType)}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...contactInfo, contactType: e.value });
													if (e.value == 'transit-company') {
														setIsTransitCompany(true);
													} else {
														setIsTransitCompany(false);
													}
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.contactType && (
											<>
												<span className="error-message text-danger">{alertMessage.contactType}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">
											Company <sup className="text-danger">*</sup>
										</label>
										<div>
											<input
												ref={nameRef}
												type="text"
												className="form-control"
												id="inputName"
												maxLength={50}
												value={contactInfo?.name}
												onChange={(e) => {
													namehandle(e);
													handleChange({ ...contactInfo, name: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.name && (
											<>
												<span className="error-message text-danger">{alertMessage.name}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Establishment Year</label>
										<div>
											<Select
												options={years?.map((y) => ({ label: y, value: y }))}
												value={years?.map((y) => ({ label: y, value: y }))?.find((y) => y?.value == contactInfo?.establishment)}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...contactInfo, establishment: e.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.establishment && (
											<>
												<span className="error-message text-danger">{alertMessage.establishment}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Activity</label>
										<div>
											<Select
												options={companyactivity?.map((y) => ({ label: y.name, value: y.id }))}
												value={companyactivity
													?.map((y) => ({ label: y.name, value: y.id }))
													?.find((y) => y?.value == contactInfo?.companyactivity)}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...contactInfo, companyactivity: e.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.companyactivity && (
											<>
												<span className="error-message text-danger">{alertMessage.companyactivity}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Turnover</label>
										<div>
											<Select
												options={companyturnover?.map((y) => ({ label: y.name, value: y.id }))}
												value={companyturnover
													?.map((y) => ({ label: y.name, value: y.id }))
													?.find((y) => y?.value == contactInfo?.turnover)}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...contactInfo, turnover: e.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.turnover && (
											<>
												<span className="error-message text-danger">{alertMessage.turnover}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Interests</label>
										<div>
											<Select
												options={companyInterests?.map((y) => ({ label: y.name, value: y.id }))}
												value={companyInterests
													?.map((y) => ({ label: y.name, value: y.id }))
													?.filter((il) => (contactInfo?.interests?.find((item) => item == il?.value) ? true : false))}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...contactInfo, interests: e?.map((a) => a.value) });
												}}
												isMulti
												isClearable={false}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.turnover && (
											<>
												<span className="error-message text-danger">{alertMessage.turnover}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Specifications</label>
										<div>
											<Select
												options={companySpecifications?.map((y) => ({ label: y.name, value: y.id }))}
												value={companySpecifications
													?.map((y) => ({ label: y.name, value: y.id }))
													?.filter((il) => (contactInfo?.specifications?.find((item) => item == il?.value) ? true : false))}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...contactInfo, specifications: e?.map((a) => a.value) });
												}}
												isMulti
												isClearable={false}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.turnover && (
											<>
												<span className="error-message text-danger">{alertMessage.turnover}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">VAT Number</label>
										<div>
											<div className="vatCheck">
												<input
													ref={vatNumberRef}
													type="text"
													className="form-control"
													value={contactInfo?.vatNumber}
													id="inputName"
													onChange={(e) => {
														handlevatValidation(e);
													}}
													// onBlur={vatNumberValidation}
												/>
												{vatValidationAlert == true && (
													<span className="text-success">
														<FaCircleCheck />
													</span>
												)}
												{vatValidationAlert == false && alertMessage?.vatNumber && (
													<span className="text-danger">
														<FaTimesCircle />
													</span>
												)}
												{vatValidationAlert == 'loading' && (
													<span>
														<ImSpinner2 className="fa-spin" />
													</span>
												)}
											</div>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.vatNumber && (
											<>
												<span className="error-message text-danger">{alertMessage.vatNumber}</span>
											</>
										)}
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">VAT File</label>
										<div className="rs-container">
											{hasVatFile ? (
												<>
													<div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
														<a href={vatFile?.vat_attachment_url} target="_blank">
															<PrivacyModeContent>{vatFile?.file_name}</PrivacyModeContent>
														</a>
														<Tooltip title="Replace Attachment" arrow placement="top">
															<span
																className="btnEdit plain-iconbtn"
																style={{
																	height: '20px',
																	width: '20px',
																	fontSize: '10px',
																	display: 'inline-block',
																	marginLeft: '20px',
																	paddingLeft: '4px',
																	paddingTop: '1px'
																}}
																onClick={() => {
																	handleRemoveAttachment(vatFile?.id, 'vat');
																}}
															>
																<FaTimes />
															</span>
														</Tooltip>
													</div>
												</>
											) : (
												<></>
											)}

											<div style={{ display: hasVatFile ? 'none' : '' }}>
												<FileUpload
													className="form-control"
													accept=".png,.jpg,.jpeg,.pdf"
													loading={disableStatus}
													onChange={(e) => {
														handleFileUpload(e, 'vat');
													}}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Registration number</label>
										<div>
											<input
												ref={registrationNumberRef}
												type="text"
												value={contactInfo?.registrationNumber}
												className="form-control"
												id="inputName"
												onChange={(e) => {
													handleRegistrationValidation(e);
													handleChange({ ...contactInfo, registrationNumber: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.registrationNumber && (
											<>
												<span className="error-message text-danger">{alertMessage.registrationNumber}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Registration File</label>
										<div className="rs-container">
											{hasRegistrationFile ? (
												<>
													<div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
														<a href={registrationFile?.registration_attachment_url} target="_blank">
															<PrivacyModeContent>{registrationFile?.file_name}</PrivacyModeContent>
														</a>
														<Tooltip title="Replace Attachment" arrow placement="top">
															<span
																className="btnEdit plain-iconbtn"
																style={{
																	height: '20px',
																	width: '20px',
																	fontSize: '10px',
																	display: 'inline-block',
																	marginLeft: '20px',
																	paddingLeft: '4px',
																	paddingTop: '1px'
																}}
																onClick={() => {
																	handleRemoveAttachment(registrationFile?.id, 'registration');
																}}
															>
																<FaTimes />
															</span>
														</Tooltip>
													</div>
												</>
											) : (
												<></>
											)}
											<div style={{ display: hasRegistrationFile ? 'none' : '' }}>
												<FileUpload
													className="form-control"
													accept=".png,.jpg,.jpeg,.pdf"
													loading={disableStatus}
													onChange={(e) => {
														handleFileUpload(e, 'registration');
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							{isTransitCompany && (
								<>
									<div className="form-section" style={{ marginTop: '20px' }}>
										<div className="form-section-head">
											<h4>Fees</h4>
										</div>
										<div className="form-group">
											<div className="form-group-body">
												<label htmlFor="">Transit Fee</label>
												<div>
													<input
														value={contactInfo?.transitFee}
														type="text"
														className="form-control"
														id="inputName"
														onChange={(e) => {
															handleChange({ ...contactInfo, transitFee: e.target.value });
														}}
													/>
												</div>
											</div>
											<div className="form-group-error">
												{alertMessage.shippingAddress && (
													<>
														<span className="error-message text-danger">{alertMessage.shippingAddress}</span>
													</>
												)}
											</div>
										</div>
									</div>
								</>
							)}

							<div className="form-section" style={{ marginTop: '20px' }}>
								<div className="form-section-head">
									<h4>Billing Address</h4>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Address</label>
										<div>
											<input
												ref={addressFieldRef}
												value={contactInfo?.addressField}
												type="text"
												className="form-control"
												id="inputName"
												onChange={(e) => {
													handleChange({ ...contactInfo, addressField: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.addressField && (
											<>
												<span className="error-message text-danger">{alertMessage.addressField}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Zip Code</label>
										<div>
											<input
												type="text"
												className="form-control"
												id="inputName"
												value={contactInfo?.zipCode}
												onChange={(e) => {
													handleChange({ ...contactInfo, zipCode: e?.target?.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.zipCode && (
											<>
												<span className="error-message text-danger">{alertMessage.zipCode}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">City</label>
										<div>
											<input
												type="text"
												className="form-control"
												id="inputName"
												value={contactInfo?.city}
												onChange={(e) => {
													handleChange({ ...contactInfo, city: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.city && (
											<>
												<span className="error-message text-danger">{alertMessage.city}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Country</label>
										<div>
											<Select
												menuPosition={'fixed'}
												options={allCountriesList?.map((y) => ({ label: y.name, value: y.name }))}
												value={allCountriesList
													?.map((y) => ({ label: y.name, value: y.name }))
													?.find((y) => y?.value == contactInfo?.country)}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...contactInfo, country: e.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.country && (
											<>
												<span className="error-message text-danger">{alertMessage.country}</span>
											</>
										)}
									</div>
								</div>
							</div>

							<div className="form-section" style={{ marginTop: '20px' }}>
								<div className="form-section-head">
									<h4>Shipping Address</h4>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Address</label>
										<div>
											<input
												ref={shippingAddressRef}
												value={contactInfo?.shippingAddress}
												type="text"
												className="form-control"
												id="inputName"
												onChange={(e) => {
													handleChange({ ...contactInfo, shippingAddress: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.shippingAddress && (
											<>
												<span className="error-message text-danger">{alertMessage.shippingAddress}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Zip Code</label>
										<div>
											<input
												type="text"
												className="form-control"
												id="inputName"
												value={contactInfo?.shippingZipCode}
												onChange={(e) => {
													handleChange({ ...contactInfo, shippingZipCode: e?.target?.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.shippingZipCode && (
											<>
												<span className="error-message text-danger">{alertMessage.shippingZipCode}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">City</label>
										<div>
											<input
												type="text"
												className="form-control"
												id="inputName"
												value={contactInfo?.shippingCity}
												onChange={(e) => {
													handleChange({ ...contactInfo, shippingCity: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.shippingCity && (
											<>
												<span className="error-message text-danger">{alertMessage.shippingCity}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Country</label>
										<div>
											<Select
												menuPosition={'fixed'}
												options={allCountriesList?.map((y) => ({ label: y.name, value: y.name }))}
												value={allCountriesList
													?.map((y) => ({ label: y.name, value: y.name }))
													?.find((y) => y?.value == contactInfo?.shippingCountry)}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...contactInfo, shippingCountry: e.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.shippingCountry && (
											<>
												<span className="error-message text-danger">{alertMessage.shippingCountry}</span>
											</>
										)}
									</div>
								</div>
							</div>

							<div className="form-section" style={{ marginTop: '20px' }}>
								<div className="form-section-head">
									<h4>Contact</h4>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Sales Manager</label>
										<div>
											<Select
												menuPosition={'fixed'}
												ref={saleManagerRef}
												options={salesPerson?.map((y) => ({ label: y.name, value: y.id }))}
												value={salesPerson
													?.map((y) => ({ label: y.name, value: y.id }))
													?.find((y) => y?.value == contactInfo?.salesManager)}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...contactInfo, salesManager: e.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.salesManager && (
											<>
												<span className="error-message text-danger">{alertMessage.salesManager}</span>
											</>
										)}
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Sales Contact Name</label>
										<div>
											<input
												type="text"
												className="form-control"
												id="inputName"
												value={contactInfo?.salesContact}
												maxLength={25}
												onChange={(e) => {
													handleSalesContact(e);
													namehandle(e);
													handleChange({ ...contactInfo, salesContact: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.salesContact && (
											<>
												<span className="error-message text-danger">{alertMessage.salesContact}</span>
											</>
										)}
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Company Email</label>
										<div>
											<input
												ref={emailRef}
												type="email"
												className="form-control"
												value={contactInfo?.emailIds}
												id="inputName"
												onChange={(e) => {
													handleEmail(e);
													handleChange({ ...contactInfo, emailIds: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.email && (
											<>
												<span className="error-message text-danger">{alertMessage.email}</span>
											</>
										)}
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Website</label>
										<div>
											<input
												type="text"
												className="form-control"
												id="inputName"
												value={contactInfo?.website}
												onChange={(e) => {
													handleChange({ ...contactInfo, website: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error"></div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Phone Number</label>
										<div>
											<PhoneInput
												ref={contactPhoneRef}
												defaultCountry="NL"
												addInternationalOption={false}
												value={contactInfo?.contactPhone}
												onChange={(phone) => {
													handleChange({ ...contactInfo, contactPhone: phone });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.contactPhone && (
											<>
												<span className="error-message text-danger">{alertMessage.contactPhone}</span>
											</>
										)}
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Whatsapp</label>
										<div>
											<PhoneInput
												ref={contactPhoneRef}
												defaultCountry="NL"
												addInternationalOption={false}
												value={contactInfo?.contactWhatsApp}
												onChange={(phone) => {
													handleChange({ ...contactInfo, contactWhatsApp: phone });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error"></div>
								</div>

								{contactInfo?.contactType == 'transit-company' && (
									<div className="form-group">
										<div className="form-group-body">
											<label htmlFor="">Transit Company Type</label>
											<div>
												<Select
													menuPosition={'fixed'}
													options={transitCompanyTypes}
													value={transitCompanyTypes?.find((y) => y?.value == contactInfo?.transitType)}
													className="rs-container"
													classNamePrefix="rs"
													placeholder=""
													onChange={(e) => {
														handleChange({ ...contactInfo, transitType: e.value });
													}}
												/>
											</div>
										</div>
										<div className="form-group-error">
											{alertMessage.transitType && (
												<>
													<span className="error-message text-danger">{alertMessage.transitType}</span>
												</>
											)}
										</div>
									</div>
								)}
							</div>
						</Scroller>
						<div className="non-scroll-btn-section">
							<div className="btnarea pt-3 pt-md-0">
								{/* <button onClick={goback} className="btn btn-link">
									Cancel
								</button> */}
								{edit ? (
									<PermissionAllowed permissions={['Save Contact Details (Action)']}>
										<button
											type="submit"
											className="btn btn-primary btn-main"
											disabled={isSubmitDisabled || vatValidationAlert == 'loading'}
										>
											<FaSave /> Save Changes
										</button>
									</PermissionAllowed>
								) : (
									<PermissionAllowed permissions={['Create New Contact (Action)']}>
										<button
											type="submit"
											className="btn btn-primary btn-main"
											disabled={isSubmitDisabled || vatValidationAlert == 'loading'}
										>
											<FaPlus /> Create
										</button>
									</PermissionAllowed>
								)}
							</div>
							{alertMessage.success && <span className="px-3 down-error text-success">{alertMessage.success}</span>}
							{alertMessage.failed && <span className="px-3 down-error text-danger">{alertMessage.failed}</span>}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ContactForm;
