import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import './Inbounding/inbounding.scss';
import moment from 'moment';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import useSound from 'use-sound';
import usePageTitle from 'hooks/usePageTitle';

import errorSound from '../../assets/audio/error.mp3';
import { PicklistIcon2, QualityControlIcon, ShareIcon } from 'ui-component/custom-icons/icons';

import Breadcrumbs from './components/Breadcrumbs';
import InboundingForm from './components/InboundingForm';
import RepairSummary from './components/RepairSummary';
import Logs from './components/Logs';
import RepairsList from './components/RepairsList';

function OperationRepairsView({ edit }) {
    const { id } = useParams();
    const [setPageTitle] = usePageTitle('Operations');
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);
    const history = useNavigate();
    const [errorSoundPlay] = useSound(errorSound);
    
    
    const [operationDetails, setOperationDetails] = useState({
        id: '',
        referenceNo: '',
        status: '',
        action: '',
        type: '',
        isCompleted: false,
        products: [],
        logs: []
    });

    const [repairsLists, setRepairsLists] = useState([]);


    

    // const checkIMEIFromAPI = (imei) => {
    //     api.checkIMEIStatus(imei).then((res) => {
    //         const tempModelImeis = [...modelImeis];
    //         if (res?.status) {
    //             if (res?.data == 'Available') {
    //                 setModelImeis(
    //                     tempModelImeis.map((m) => {
    //                         if (m.imei == imei) {
    //                             return { ...m, valid: true, invalid_message: 'IMEI Exists in stock' };
    //                         } else {
    //                             return m;
    //                         }
    //                     })
    //                 );
    //             } else if (res?.data == 'Inbounded') {
    //                 setModelImeis(
    //                     tempModelImeis.map((m) => {
    //                         if (m.imei == imei) {
    //                             return { ...m, valid: false, invalid_message: 'IMEI Exists in stock' };
    //                         } else {
    //                             return m;
    //                         }
    //                     })
    //                 );
    //             } else if (res?.data == 'Outbounded') {
    //                 setModelImeis(
    //                     tempModelImeis.map((m) => {
    //                         if (m.imei == imei) {
    //                             return { ...m, valid: 'outbounded', invalid_message: 'IMEI was Outbounded' };
    //                         } else {
    //                             return m;
    //                         }
    //                     })
    //                 );
    //             }
    //         } else {
    //             toast.error('Something went wrong while checking IMEI ' + imei);
    //             setModelImeis(
    //                 tempModelImeis.map((m) => {
    //                     if (m.imei == imei) {
    //                         return { ...m, valid: false, invalid_message: 'Something went wrong' };
    //                     } else {
    //                         return m;
    //                     }
    //                 })
    //             );
    //         }
    //     });
    // };

    // const [selectedInboundItems, setSelectedInboundItems] = useState([]);

    // const getCollectionData = (callback = () => { }) => {
    //     setLoading(true);
    //     setIsDisable(true);
    //     api.getinboundingdetailsbyid(id).then((res) => {
    //         setSelectedInbound(res?.data);

    //         const itemsList = [];

    //         res?.data?.items?.forEach((item) => {
    //             const existing = itemsList?.find((l) => l?.id == item?.product_id);
    //             if (existing) {
    //                 existing.qty = existing?.qty + item?.qty;
    //                 existing.remaining_qty = existing?.remaining_qty + item?.remaining_qty;
    //             } else {
    //                 itemsList.push({
    //                     id: item?.product_id,
    //                     product: item?.product,
    //                     qty: item?.qty,
    //                     remaining_qty: item?.remaining_qty
    //                 });
    //             }
    //         });

    //         setSelectedInboundItems(itemsList);

    //         setIsDisable(false);
    //         setLoading(false);
    //         if (res?.data?.stocks?.length > 0) {
    //             setModelImeis(
    //                 res?.data?.stocks?.map((s) => ({
    //                     imei: s?.imei,
    //                     repairs: s?.repairs?.map((sr) => ({
    //                         ...sr?.repair_cost,
    //                         value: sr?.repair_cost?.repair_type_id,
    //                         label: sr?.repair_cost?.repair_type?.name
    //                     })),
    //                     valid: true,
    //                     invalid_message: 'IMEI Exists in stock',
    //                     product: s?.product,
    //                     color: s?.color?.name?.[0].toUpperCase() + s?.color?.name?.substring(1),
    //                     item_id: s?.id,
    //                     stock_id: s?.id,
    //                     existing: true,
    //                     purchase_list_id: s?.purchase_list?.id
    //                 }))
    //             );
    //         }
    //         callback(res?.data);
    //     });
    // };


    useEffect(() => {
        if (id) {
            initializeOperationDetails();
        }
    }, []);

    const initializeOperationDetails = () => {

        // api.getOperationsDetails(id).then((res) => {
        //     if (res?.status) {
        //         const data = {
        //             id: res?.data?.id,
        //             referenceNo: res?.data?.reference_number,
        //             action: 'view',
        //             type: 'inbound',
        //             products: res?.data?.items,
        //             logs: res?.data?.logs
        //         }
        //         setOperationDetails(data);
            
        //         // setOperationDetails(res?.data);
        //     }
        // });
        api.getOperationItemRepairs(id).then((res) => {
            if (res?.status) {
                // const collection = res?.data?.map((item) => { 
                //     return {
                //         id: item?.id,
                //         imei: item?.stock?.imei,
                //         productId: item?.product?.id,
                //         productName: item?.product?.name,
                //         colorId: item?.color?.id,
                //         colorName: item?.color?.name,
                //         repairTypeId: item?.repair_type?.id,
                //         repairTypeName: item?.repair_type?.name,
                //         currency: item?.currency,
                //         cost: item?.cost,
                //         timeSpent: item?.time_spent,
                //         status: item?.status,
                //         assignedToId: item?.assigned_to?.id,
                //         assignedToName: item?.assigned_to?.name,
                //     }
                // })
                setRepairsLists(res?.data);
            }
        });
    }



    const handleRemoveProductFromList = (index) => {
        const updatedProducts = operationDetails?.products ? [...operationDetails.products] : [];

        updatedProducts.splice(index, 1);
        setOperationDetails({
            ...operationDetails,
            products: updatedProducts
        });
    }



    const handleReloadData = () => {
        initializeOperationDetails();
    }

	return (
        <>
            
            <div className="row m-0 w-100" style={{ marginTop: '20px' }}>
                <div className="col-12 col-md-12">
                    <div className="section-head" style={{ paddingLeft: '0' }}>
                     
                        <h2>
                            <div className="actions" style={{ display: 'flex', width: '100%' }}>

                                <Breadcrumbs
                                    current={`repairs`}
                                    activateTabsUsingId={repairsLists?.id}
                                    referenceNumber={repairsLists?.reference_number}
                                    isCompleted={repairsLists?.is_completed ? true : false}
                                />

                            </div>
                        </h2>
                        <div className="actions">
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-lg-9 p-0 form-group" style={{ marginTop: '5px' }}>
                            <div className="card shadow-sm bg-white table-card p-3" style={{ marginTop: '0' }}>
                                <div className="bg-white pt-0" style={{  marginTop: '-15px' }}>
                              
                                    <div className="row">
                                        <div className="col-12">
                                            
                                            <RepairsList
                                                repairsLists={repairsLists}
                                                handleReloadData={handleReloadData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="col-lg-3">
                            <div className="row">
                                <div className="col-12 form-group">
                                    <RepairSummary
                                        repairsLists={repairsLists}
                                    />
                                    <Logs
                                        logs={repairsLists?.logs}
                                    />
                                    {/* <RepairSummary /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</>
	);
}

export default OperationRepairsView;
