import React from 'react';
import { Fade } from 'react-awesome-reveal';

const PerformanceRatingCard = ({ testData }) => {
	const imageUrl = [
		'https://glpapi.projectmix.eu/assets/images/users/ajmal.png',
		'https://glpapi.projectmix.eu/assets/images/users/arian.png',
		'https://glpapi.projectmix.eu/assets/images/users/afzaly.png',
		'https://glpapi.projectmix.eu/assets/images/users/bari.png',
		'https://glpapi.projectmix.eu/assets/images/users/belal.png',
		'https://glpapi.projectmix.eu/assets/images/users/imran.png',
		'https://glpapi.projectmix.eu/assets/images/users/mufeez.png',
		'https://glpapi.projectmix.eu/assets/images/users/vipin.png',
		'https://glpapi.projectmix.eu/assets/images/users/waseem.png'
	];
	return (
		<div className="col-performance col-md-6 col-sm-12">
			<Fade>
				<div className="card" style={{ height: '280px', marginBottom: '20px' }}>
					<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: '10px' }}>
						<a style={{ fontWeight: '500' }}>Performance Rating</a>
					</div>
					<div className="card-body d-flex flex-column" style={{ paddingTop: 10 }}>
						<div className="grid-container-custom flex-grow-1">
							{testData.map((percentage, index) => {
								let color;
								if (percentage >= 85) {
									color = '#4fcd8e';
								} else if (percentage < 85 && percentage >= 70) {
									color = '#7cc3e6';
								} else if (percentage < 70 && percentage >= 50) {
									color = '#ffc669';
								} else {
									color = '#f64e60';
								}

								return (
									<div key={index} className="circle-container">
										<img src={imageUrl[index]} alt="User" className="circle" />
										<svg viewBox="0 0 36 36" className="circular-chart">
											<path
												className="circle-bg"
												d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
											/>
											<path
												className="circle"
												stroke-dasharray={`${percentage}, 100`}
												stroke={color}
												d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
											/>
										</svg>
									</div>
								);
							})}
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '7px', height: '7px', borderRadius: '50%', background: '#4fcd8e', marginRight: '5px' }}></div>
								<a style={{ fontSize: 10, color: '#a5a5a5' }}>Excellent</a>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '7px', height: '7px', borderRadius: '50%', background: '#7cc3e6', marginRight: '5px' }}></div>
								<a style={{ fontSize: 10, color: '#a5a5a5' }}>Above Average</a>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '7px', height: '7px', borderRadius: '50%', background: '#ffc669', marginRight: '5px' }}></div>
								<a style={{ fontSize: 10, color: '#a5a5a5' }}>Average</a>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '7px', height: '7px', borderRadius: '50%', background: '#f64e60', marginRight: '5px' }}></div>
								<a style={{ fontSize: 10, color: '#a5a5a5' }}>Poor</a>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default PerformanceRatingCard;
