import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Loader from 'ui-component/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import editIcon from 'assets/images/icons/ic_edit.svg';
import { FaPen } from 'react-icons/fa6';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';

const Viewuser = () => {
	const { id } = useParams();
	// const isLoading = useSelector((state) => state.main.isLoading);
	const selectedUser = useSelector((state) => state.main.selectedItem);
	const dispatch = useDispatch();
	const history = useNavigate();
	const [userInfo, setUserinfo] = useState();
	const [isLoading, setIsloading] = useState(false);
	const [show, setShow] = useState('');
	const [roles, setRoles] = useState();
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);

	const getUserinfo = async () => {
		setIsloading(true);
		const response = await api.getUserDetail(id);
		if (response && response.status) {
			setUserinfo(response.data);
			setRoles(response.data.roles);
			setIsloading(false);
		}
	};

	const deleteuserData = () => {
		setShow(userInfo);
	};

	const handleClose = () => setShow('');

	const handleDelete = async (item) => {
		api.deleteUser(item).then((res) => {
			if (res.status) {
				setShow('');
				setTimeout(() => {
					history('/settings/contacts/user');
					dispatch(storeInLocalStore({ stateAlertmessage: 'User Deleted Successfully.' }));
				}, 500);
			}
		});
	};

	useEffect(() => {
		getUserinfo();
	}, []);

	return (
		<>
			{isLoading ? (
				<div style={{ position: 'relative', width: '100%', height: '70vh' }}>
					<Loader />
				</div>
			) : (
				<>
					<div className="row mt-4">
						<div className="col-md-5">
							<div className="card shadow-sm rounded bg-white mb-4 p-3 p-md-4  mr-lg-4">
								<h3 className="h4 mb-2  font-weight-bold mb-4">User Details</h3>
								<table className="table-sm  table-borderless mb-3" width="100%">
									<tr>
										<td width="190px" className="align-middle">
											First Name
										</td>
										<td className="align-middle">{userInfo?.first_name}</td>
									</tr>
									<tr>
										<td className="align-middle">Last Name</td>
										<td className="align-middle">{userInfo?.last_name || '-'}</td>
									</tr>
									<tr>
										<td className="align-middle">Mobile Number</td>
										<td className="align-middle">{userInfo?.mobile || '-'}</td>
									</tr>
									<tr>
										<td className="align-middle">Role</td>
										<td className="align-middle">{userInfo?.role?.name}</td>
									</tr>
								</table>
								<div className="btnarea">
									<Link to={`/settings/user`} className="btn btn-link">
										Back
									</Link>

									<PermissionAllowed permissions={['Edit User Details (Action)']}>
										<Link to={`/settings/user/edit/${id}`} className="btn btn-primary btn-outlined-main">
											<FaPen /> Edit
										</Link>
									</PermissionAllowed>
								</div>
							</div>
						</div>
						<div className="col-md-7">
							<div className="card shadow-sm rounded bg-white mb-4 p-3 p-md-4">
								<h3 className="h4 mb-2  font-weight-bold mb-4">Activity History</h3>
								<div className="table-responsive-xl tableData p-2">
									<table className="table  border-bottom mb-0">
										<thead>
											<tr>
												<th scope="col">OPERATION NAME</th>
												<th scope="col">STATUS</th>
												<th scope="col">DATE</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="align-middle">Operation Name</td>
												<td className="align-middle">Succeeded</td>
												<td className="align-middle">Abdulah A</td>
											</tr>
											<tr>
												<td className="align-middle">Operation Name</td>
												<td className="align-middle">Failed</td>
												<td className="align-middle">22-05-2023</td>
											</tr>
											<tr>
												<td className="align-middle">Operation Name</td>
												<td className="align-middle">Succeeded</td>
												<td className="align-middle">Abdulah A</td>
											</tr>
											<tr>
												<td className="align-middle">Operation Name</td>
												<td className="align-middle">Failed</td>
												<td className="align-middle">22-05-2023</td>
											</tr>
											<tr>
												<td className="align-middle">Operation Name</td>
												<td className="align-middle">Succeeded</td>
												<td className="align-middle">Abdulah A</td>
											</tr>
											<tr>
												<td className="align-middle">Operation Name</td>
												<td className="align-middle">Failed</td>
												<td className="align-middle">22-05-2023</td>
											</tr>
											<tr>
												<td className="align-middle">Operation Name</td>
												<td className="align-middle">Succeeded</td>
												<td className="align-middle">Abdulah A</td>
											</tr>
											<tr>
												<td className="align-middle">Operation Name</td>
												<td className="align-middle">Failed</td>
												<td className="align-middle">22-05-2023</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-6">
										<div className="dataTables_info p-2" id="DataTables_Table_0_info" role="status" aria-live="polite">
											Showing 1 to 10 of 57 entries
										</div>
									</div>
									<div className="col-sm-12 col-md-6">
										<div className="dataTables_paginate paging_simple_numbers p-2" id="DataTables_Table_0_paginate">
											<ul className="pagination justify-content-end">
												<li
													className="paginate_button page-item previous disabled"
													id="DataTables_Table_0_previous"
												>
													<a
														href="#"
														aria-controls="DataTables_Table_0"
														data-dt-idx="previous"
														tabindex="0"
														className="page-link"
													>
														<i className="fas fa-chevron-left"></i>
													</a>
												</li>
												<li className="paginate_button page-item active">
													<a
														href="#"
														aria-controls="DataTables_Table_0"
														data-dt-idx="0"
														tabindex="0"
														className="page-link"
													>
														1
													</a>
												</li>
												<li className="paginate_button page-item ">
													<a
														href="#"
														aria-controls="DataTables_Table_0"
														data-dt-idx="1"
														tabindex="0"
														className="page-link"
													>
														2
													</a>
												</li>
												<li className="paginate_button page-item ">
													<a
														href="#"
														aria-controls="DataTables_Table_0"
														data-dt-idx="2"
														tabindex="0"
														className="page-link"
													>
														3
													</a>
												</li>
												<li className="paginate_button page-item ">
													<a
														href="#"
														aria-controls="DataTables_Table_0"
														data-dt-idx="3"
														tabindex="0"
														className="page-link"
													>
														4
													</a>
												</li>
												<li className="paginate_button page-item ">
													<a
														href="#"
														aria-controls="DataTables_Table_0"
														data-dt-idx="4"
														tabindex="0"
														className="page-link"
													>
														5
													</a>
												</li>
												<li className="paginate_button page-item ">
													<a
														href="#"
														aria-controls="DataTables_Table_0"
														data-dt-idx="5"
														tabindex="0"
														className="page-link"
													>
														6
													</a>
												</li>
												<li className="paginate_button page-item next" id="DataTables_Table_0_next">
													<a
														href="#"
														aria-controls="DataTables_Table_0"
														data-dt-idx="next"
														tabindex="0"
														className="page-link"
													>
														<i className="fas fa-chevron-right"></i>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Modal show={show} onHide={handleClose} centered>
						<Modal.Body>
							<Modal.Title>Confirm Delete</Modal.Title>
							<p>Are you sure you want to delete {`${show.first_name} ${show.last_name || ''}`}</p>
							<Button variant="primary" className="m-2 " onClick={handleClose}>
								Cancel
							</Button>
							<Button
								variant="danger"
								className="m-2"
								onClick={(e) => {
									handleDelete(show.id);
								}}
							>
								Delete
							</Button>
						</Modal.Body>
					</Modal>
				</>
			)}
		</>
	);
};

export default Viewuser;
