import React, { useEffect, useState } from 'react';
import usePermissions from 'hooks/usePermissions';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Scroller from 'ui-component/Scroller';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { FaRegClock } from 'react-icons/fa';

const OrderHistoryTab = ({
	serialDetails, 
}) => {
    
	const history = useNavigate();
	const [imeiHistory, setImeiHistory] = useState();
    const [allPermissions, hasEveryPermission] = usePermissions();

    const getSerialsHistory = (id) => {
		api.getSerialsHistory(id).then((res) => {
			if (res.status) {
				setImeiHistory(res?.data)
			}
		})
    };

	useEffect(() => {
		getSerialsHistory(serialDetails?.imei);
	}, []);
	return (
		<>
            <div className="content mt15">
                <div className="row">
                    <div className="col-12 ">
                        
                        {imeiHistory?.length > 0 ? (
                            <>
                            
                                <div className="table-responsive-xl tableData productListing p-2">
                                    <Scroller>
                                        <table className="table pm-table">
                                            <thead>
                                                <tr className='sticky-header '>
                                                    <th scope="col" className="font14">History</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { imeiHistory?.map((row, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                <td className="font14">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div>
                                                                            { row?.description }
                                                                            
                                                                        </div>
                                                                        <div style={{
                                                                            fontSize: '12px',
                                                                            fontStyle: 'italic',
                                                                            color: '#909090'
                                                                        }}>
                                                                            <FaRegClock style={{marginTop: '-3px' }} /> <span>{moment(row?.created_at).format(CommonDateOnlyFormat)}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {/* <td className="text-center font14">
                                                                    {moment(row?.created_at).format(CommonDateOnlyFormat)}
                                                                </td> */}
                                                            </tr>
                                                            {
                                                                row?.imei_repairs_history?.map((repair, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td className="font14">
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div>
                                                                                        { repair?.repair_type_name } has been added for repair with a cost of ${ repair?.cost }
                                                                                        
                                                                                    </div>
                                                                                    <div style={{
                                                                                        fontSize: '12px',
                                                                                        fontStyle: 'italic',
                                                                                        color: '#909090'
                                                                                    }}>
                                                                                        <FaRegClock style={{marginTop: '-3px' }} /> <span>{moment(repair?.created_at).format(CommonDateOnlyFormat)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Scroller>
                                </div>
                            </>
                        ) : (
                            <>
                            
                                <div className="not-found-container">
                                    <div className="not-found-icon">
                                        <TbDatabaseExclamation />
                                    </div>
                                    <h6 className="not-found-text">No Data Available</h6>
                                </div>
                            </>
                        )}



                    </div>
                    
                </div>
            </div>
		</>
	);
};

export default OrderHistoryTab;
