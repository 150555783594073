import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import Scroller from 'ui-component/Scroller';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setReturncomponent } from 'store/reducers/mainSlice';
import 'react-datepicker/dist/react-datepicker.css';
import { FaPlus, FaSave, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';
import { TbDatabaseExclamation } from 'react-icons/tb';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { Skeleton } from '@mui/material';
import { CgNotes } from 'react-icons/cg';
import { IoIosArrowDown } from 'react-icons/io';
import { ImSpinner2 } from 'react-icons/im';
import usePageTitle from 'hooks/usePageTitle';
import ProductList from './comonents/productList';
import { IoIosClose } from 'react-icons/io';
import { SelectionProvider } from './comonents/SelectionContext';
import ReactSelect from 'react-select';

const PurchaseForm = ({ edit }) => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const productData = JSON.parse(decodeURIComponent(params.get('data')));

	const [setPageTitle] = usePageTitle('New Purchase order');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const [isInternalLoading, setInternalLoading] = useState(false);
	const history = useNavigate();
	const currencies = [
		{ label: 'EUR', value: 'eur' },
		{ label: 'USD', value: 'usd' },
		{ label: 'AED', value: 'aed' }
	];

	const [selectedRowsData, setSelectedRowsData] = useState([]);
	const [foundOpen, setFoundOpen] = useState(false);
	const [supplierList, setsupplierList] = useState([]);
	const [warehouseList, setwarehouseList] = useState([]);
	const [totalQuantity, setTotalQuantity] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const currentDate = moment().format('YYYY-MM-DD');
	const [alertMessage, setAlertMessage] = useState();

	const [purchaseList, setPurchaseList] = useState({
		supplier_id: '',
		warehouse_id: '',
		expectedDate: currentDate,
		invoiceNumber: '',
		currency: 'eur'
	});
	// Row Area
	const [rows, setRows] = useState([{ id: 1, modelId: '', type: '', quantity: 0, unitPrice: 0, total: 0, comment: '' }]);

	useEffect(() => {
		if (selectedRowsData.length === 0) {
			if (productData?.length > 0) {
				const allOrders = productData.flatMap((product) => product);
				setSelectedRowsData(allOrders);
			}
		}
	}, [productData]);
	useEffect(() => {
		if (selectedRowsData && selectedRowsData.length > 0) {
			const calculatedTotalQuantity = selectedRowsData.reduce((total, row) => total + row.quantity, 0);
			setTotalQuantity(calculatedTotalQuantity);

			const calculatedTotalPrice = selectedRowsData.reduce((total, row) => total + row.quantity * row.price, 0);
			setTotalPrice(calculatedTotalPrice);
		} else {
			setTotalQuantity(0);
			setTotalPrice(0);
		}
	}, [selectedRowsData]);
	// const totalQuantity = selectedRowsData?.reduce((total, row) => total + row.quantity, 0);
	// const totalPrice = selectedRowsData?.reduce((total, row) => total + (row.quantity * row.price), 0);

	const handleDoneClick = (data) => {
		closeFound(data);
		setSelectedRowsData((prevData) => [...prevData, ...data]);
	};

	const handleextimatevalue = (val) => {
		setPurchaseList(val);
	};

	const handleSelectRow = () => {
		setFoundOpen(true);
		// setPurcahseListId(purchase_list_id);
	};
	const closeFound = (data) => {
		if (data == 'reload') {
			setFoundOpen(false);
			// setPurcahseListId(null);
			// this.forceUpdate();
		} else {
			setFoundOpen(false);
			// setPurcahseListId(null);
		}
	};

	useEffect(() => {
		setPurchaseList({
			supplier_id: '',
			warehouse_id: '',
			expectedDate: currentDate
		});
		setRows([{ id: 1, modelId: '', type: '', quantity: 0, unitPrice: 0, total: 0, comment: '' }]);
		initiateDatas();
	}, [edit]);

	const [loaderStatus, setLoaderStatus] = useState({
		suppliers: false,
		warehouse: false
	});

	const [editDataLoader, setEditDataLoader] = useState(false);

	const initiateDatas = () => {
		api.getSelectOptionsList('warehouse').then((res) => {
			const custData = res.data;
			const customerArray = custData.map((item) => ({ id: item.id, label: item.name, value: item.id }));
			setwarehouseList(customerArray);
			setLoaderStatus((oldData) => ({
				...oldData,
				warehouse: false
			}));
		});
		api.getSelectOptionsList('supplier').then((res) => {
			const custData = res.data;
			const customerArray = custData.map((item) => ({ id: item.id, label: item.name, value: item.id }));
			setsupplierList(customerArray);
			setLoaderStatus((oldData) => ({
				...oldData,
				suppliers: false
			}));
		});
	};

	const handleCustomerinfo = (selected) => {
		handleextimatevalue({
			...purchaseList,
			supplier_id: selected?.id
		});
	};

	const removeRow = (id) => {
		const updatedRows = selectedRowsData.filter((row) => row.product_id !== id);
		setSelectedRowsData(updatedRows);
	};

	const handleQuantityChange = (id, value) => {
		const updatedRows = selectedRowsData.map((row) => {
			if (row.product_id === id) {
				const quantity = parseInt(value) || 0;
				const total = (quantity * row.unitPrice).toFixed(2);
				return { ...row, quantity, total };
			}
			return row;
		});
		setSelectedRowsData(updatedRows);
	};

	const handleUnitPriceChange = (id, value) => {
		const updatedRows = selectedRowsData.map((row) => {
			if (row.product_id === id) {
				const price = Number(value) || 0;
				return { ...row, price };
			}
			return row;
		});
		setSelectedRowsData(updatedRows);
	};

	const validateRows = () => {
		for (let i = 0; i < selectedRowsData.length; i++) {
			console.log(selectedRowsData[i]);
			const { product_id, quantity, price } = selectedRowsData[i];
			if (i != selectedRowsData.length - 1) {
				if (!product_id || !quantity || !price) {
					return false;
				}
			}
		}
		return true;
	};

	const formValidation = () => {
		let isValid = true;
		if (purchaseList?.supplier_id == '') {
			isValid = false;
			toast.error('Supplier is Required');
		} else if (purchaseList?.warehouse_id == '') {
			isValid = false;
			toast.error('Warehouse  is Required');
		} else if (selectedRowsData.length < 1) {
			console.log(selectedRowsData.length);
			isValid = false;
			toast.error('Please add at least one product');
		} else if (!validateRows()) {
			isValid = false;
			toast.error('Please fill in all fields for each product');
		}

		return isValid;
	};

	const updateSubmit = () => { };

	const savePurchaseOrder = () => {
		console.log(purchaseList);
		if (formValidation()) {
			const data = {
				...purchaseList,
				products: selectedRowsData,
				supplier_id: purchaseList?.supplier_id,
				warehouse_id: purchaseList?.warehouse_id,
				expectedDate: purchaseList?.expectedDate,
				currency: purchaseList?.currency ? purchaseList?.currency : 'eur'
				// invoiceNumber: purchaseList?.invoiceNumber,
			};

			api.createPurchaseOrder(data).then(
				(res) => {
					console.log(res);
					if (res.status) {
						toast.success(res.message);
						history(`/purchaseall/view/${res.data.id}`);
					} else {
						toast.error(res.message);
					}
				},
				() => { }
			);
		}
	};

	return (
		<>
			<div className="row m-0 w-100" style={{ paddingInline: '50px' }}>
				{console.log(selectedRowsData)}
				{console.log(productData)}
				<div className="col-12 col-md-12">
					<div className="section-head">
						<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>
							{edit ? (
								<>
									{loaderStatus?.est_no ? (
										<Skeleton
											style={{
												width: '100px'
											}}
										></Skeleton>
									) : (
										<></>
									)}
								</>
							) : (
								<></>
							)}
						</h2>
						<div className="actions">
							{!edit ? (
								<>
									<PermissionAllowed permissions={['Create New Estimation (Action)']}>
										<button
											type="button"
											onClick={() => savePurchaseOrder('save')}
											className="btn btn-primary btn-outlined-main"
											disabled={isInternalLoading}
										>
											<FaSave /> Save
										</button>
									</PermissionAllowed>
								</>
							) : (
								<button
									type="button"
									onClick={() => updateSubmit()}
									className="btn btn-primary btn-outlined-main"
									disabled={isInternalLoading}
								>
									<FaSave /> Update
								</button>
							)}
						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-4 p-3 p-xl-4 w-100">
							<Scroller>
								<div className="row">
									<div className="col-md-12 col-lg-12">
										<div className="" style={{ justifyContent: 'space-between' }}>
											<div className="form-group">
												<div className="row">
													<div className="col-md-6">
														<div className="form-col">
															<label htmlFor="inputName" className="est-labels labl-form">
																Suppliers
															</label>
															{loaderStatus?.customers || editDataLoader ? (
																<Skeleton
																	style={{
																		minHeight: '32px',
																		height: '32px',
																		transformOrigin: '0 0',
																		transform: 'scale(1,1)',
																		width: '250px'
																	}}
																></Skeleton>
															) : (
																// <Select
																// 	className="form-inp"
																// 	classNamePrefix="rs"
																// 	isSearchable={true}
																// 	name="customer"
																// 	menuPosition="fixed"
																// 	options={supplierList}
																// 	placeholder="Select"
																// 	value={supplierList?.find((c) => c?.value == purchaseList?.supplier_id)}
																// 	onChange={(selected) => handleCustomerinfo(selected)}
																// 	/>

																<ReactSelect
																	className="rs-container"
																	classNamePrefix="rs"
																	name="customer"
																	options={supplierList}
																	value={supplierList?.find((c) => c?.value == purchaseList?.supplier_id)}
																	onChange={(selected) => handleCustomerinfo(selected)}
																	menuPlacement="auto"
																	menuPosition="fixed"
																	placeholder="Select"
																	style={{ borderRadius: '18px' }}
																/>
															)}

															{alertMessage?.name && (
																<>
																	<span className="error-message text-danger">{alertMessage?.name}</span>
																</>
															)}
														</div>
														{/* <div className="form-col">
														<label htmlFor="inputName" className="est-labels labl-form">
															Invoice Number
														</label>
																					
														<input
															type="text"
															style={{ cursor: 'pointer' }}
															className="form-control form-inp"
															onClick={(e) => e.target.focus()}
															onChange={(e) => {
																handleextimatevalue({
																	...purchaseList,
																	invoiceNumber: e.target?.value
																});
															}}
														/>
							
														{alertMessage?.name && (
															<>
																<span className="error-message text-danger">{alertMessage?.name}</span>
															</>
														)}
													</div> */}
														<div className="form-col">
															<label htmlFor="inputName" className="est-labels labl-form">
																Expected Date
															</label>

															<input
																type="date"
																value={purchaseList?.expectedDate}
																style={{ cursor: 'pointer' }}
																className="form-control form-inp"
																onClick={(e) => e.target.focus()}
																onChange={(e) => {
																	handleextimatevalue({
																		...purchaseList,
																		expectedDate: e.target?.value
																	});
																}}
															/>

															{alertMessage?.name && (
																<>
																	<span className="error-message text-danger">{alertMessage?.name}</span>
																</>
															)}
														</div>
														<div className="form-col">
															<label htmlFor="inputName" className="est-labels labl-form">
																Warehouse
															</label>
															{loaderStatus?.transit_companies || editDataLoader ? (
																<Skeleton
																	style={{
																		minHeight: '32px',
																		height: '32px',
																		transformOrigin: '0 0',
																		transform: 'scale(1,1)',
																		width: '250px'
																	}}
																></Skeleton>
															) : (
																// <Select
																// 	className="form-inp"
																// 	classNamePrefix="rs"
																// 	isSearchable={true}
																// 	name="transit-company"
																// 	menuPosition="fixed"
																// 	options={warehouseList}
																// 	value={warehouseList?.find((c) => c?.value == purchaseList?.warehouse_id)}
																// 	onChange={(e) => {
																// 		handleextimatevalue({
																// 			...purchaseList,
																// 			warehouse_id: e.value
																// 		});
																// 	}}
																// />

																<ReactSelect
																	className="rs-container"
																	classNamePrefix="rs"
																	name="transit-company"
																	options={warehouseList}
																	value={warehouseList?.find((c) => c?.value == purchaseList?.warehouse_id)}
																	onChange={(e) => {
																		handleextimatevalue({
																			...purchaseList,
																			warehouse_id: e.value
																		});
																	}}
																	menuPlacement="auto"
																	menuPosition="fixed"
																	placeholder="Select"
																	style={{ borderRadius: '18px' }}
																/>
															)}

															{alertMessage?.name && (
																<>
																	<span className="error-message text-danger">{alertMessage?.name}</span>
																</>
															)}
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-col">
															<label htmlFor="inputName" className="est-labels">
																Currency
															</label>
															<Select
																className="rs-container-est-white"
																classNamePrefix="rs"
																isSearchable={true}
																name="customer"
																options={currencies}
																value={currencies?.find((c) => c?.value == purchaseList?.currency)}
																onChange={(e) => {
																	handleextimatevalue({
																		...purchaseList,
																		currency: e.value
																	});
																}}
															/>
															{alertMessage?.name && (
																<>
																	<span className="error-message text-danger">{alertMessage?.name}</span>
																</>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="productsTable estlist" style={{ fontSize: '12px' }}>
											<div className="table-responsive-xl tableData">
												<div className="section-head">
													<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>Products</h2>
												</div>
												<table className="table table-md text-gray-900 formActBtns border-0">
													<thead>
														<tr>
															<th className="align-middle" width={'520'}>
																Model
															</th>
															<th className="align-middle" width={'60'}>
																Quantity
															</th>
															<th className="align-middle" width={'80'}>
																Price
															</th>
															<th className="align-middle" width={'110'}>
																Total
															</th>
															<th className="align-middle text-center" width={'30'}></th>
														</tr>
													</thead>
													<tbody>
														{selectedRowsData?.length > 0 ? (
															selectedRowsData?.map((row, index) => (
																<>
																	<tr key={row?.product_id}>
																		<td className="align-middle estimateCName">
																			<div className="form-group col-md-12 mb-0">{row?.product_name || row.product}</div>
																		</td>
																		<td className="align-middle ">
																			<input
																				className="numField"
																				type="number"
																				value={row?.quantity}
																				// placeholder="00"
																				maxLength={10}
																				onChange={(e) => {
																					if (row.product_id) {
																						const newVal = e.target.value;
																						if (newVal.length <= 10) {
																							handleQuantityChange(row.product_id, newVal);
																						}
																					}
																				}}
																				style={{ width: '60px', paddingInline: '3px' }}
																				onFocus={(event) => {
																					event.target.select();
																				}}
																			/>
																		</td>
																		<td className="align-middle">
																			<input
																				className="numField"
																				type="number"
																				value={row?.price}
																				// placeholder="00"
																				onChange={(e) => {
																					if (row.product_id) {
																						const newVal = e.target.value;
																						if (newVal.length <= 6) {
																							handleUnitPriceChange(row.product_id, newVal);
																						}
																					}
																				}}
																				style={{ width: '60px', paddingInline: '3px' }}
																				onFocus={(event) => {
																					event.target.select();
																				}}
																			/>
																		</td>
																		<td className="align-middle">
																			{purchaseList?.currency === 'usd' ? ' $ ' : purchaseList?.currency === 'aed' ? ' AED ' : ' € '}{' '}
																			{row.quantity * row.price || '0'}
																		</td>
																		<td className="align-middle text-center">
																			{row.product_id ? (
																				<span className="btnEdit plain-iconbtn" onClick={() => removeRow(row.product_id)}>
																					<FaTimes />
																				</span>
																			) : (
																				''
																			)}
																		</td>
																	</tr>
																</>
															))
														) : (
															<tr className="no-hover">
																<td colSpan={9}>
																	<div className="not-found-container cstm-not-found">
																		<div className="not-found-icon">
																			<TbDatabaseExclamation />
																		</div>
																		<h6 className="not-found-text">No Products Selected</h6>
																		<button type="button" className="btn btn-primary btn-outlined-main" onClick={handleSelectRow}>
																			<FaPlus />
																			Add Products
																		</button>
																	</div>
																</td>
															</tr>
														)}
													</tbody>
													{selectedRowsData?.length > 0 ? (
														<tfoot>
															<tr className="tr-nth">
																<td className="align-middle estimateCName"></td>
																<td className="">
																	<div className="form-group col-md-12 mb-0">
																		<label>{totalQuantity} pcs</label>
																	</div>
																</td>
																<td className="align-middle"></td>
																<td className="align-middle">
																	<label>
																		{purchaseList?.currency === 'usd' ? ' $ ' : purchaseList?.currency === 'aed' ? ' AED ' : ' € '}{' '}
																		{totalPrice ? totalPrice?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}
																	</label>
																</td>
																<td className="align-middle text-center"></td>
															</tr>
														</tfoot>
													) : (
														// <div className="section-footer">
														// 	<div className="footer-left"></div>
														// 	<div className="footer-left">
														// 		<div className="produt-count">
														// 			<label htmlFor=""> Products</label>
														// 			<label>{totalQuantity}</label>
														// 		</div>
														// 		<div className="price">
														// 			<label htmlFor=""> Price </label>
														// 			<label>{purchaseList?.currency === 'usd' ? ' $ ' : (purchaseList?.currency === 'aed' ? ' AED ' : ' € ')} {totalPrice ? totalPrice?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</label>
														// 		</div>
														// 	</div>
														// </div>
														<></>
													)}
												</table>
											</div>
										</div>
									</div>
								</div>
							</Scroller>

							<div className="">
								{alertMessage?.success && <span className="px-3 down-error text-success">{alertMessage?.success}</span>}
								{alertMessage?.failed && <span className="px-3 down-error text-danger">{alertMessage?.failed}</span>}
							</div>
						</div>
					</div>
				</div>
			</div>
			{foundOpen && (
				<Modal show={foundOpen} onHide={closeFound} centered className="custom-modal-two">
					<div className="section-head cstm-section-head">
						<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>Add products</h2>
						<IoIosClose className="close-Icon" onClick={closeFound} />
					</div>
					<div>
						<SelectionProvider>
							<ProductList close={handleDoneClick} currency={purchaseList?.currency} />
						</SelectionProvider>
					</div>
				</Modal>
			)}
		</>
	);
};

export default PurchaseForm;
