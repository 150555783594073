import React, { useState, useEffect, useRef } from 'react';
import Scroller from 'ui-component/Scroller';
import CreatableSelect from 'react-select/creatable';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPlus, FaSearch } from 'react-icons/fa';
import StatusBadge from 'ui-component/StatusBadge';
import { TbDatabaseExclamation } from 'react-icons/tb';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import NoItemAvailableIcon from 'assets/images/icons/no-item-available.svg';
import { DeleteIcon2 } from 'ui-component/custom-icons/icons';


const InboundList = ({ operationDetails, handleRemoveProductFromList }) => {

    const topSection = useRef(null);
    const [modelImeis, setModelImeis] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const [inboundList, setInboundList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredInboundList, setFilteredInboundList] = useState([]);
    const statusDisplayedAsInbounded = ['Verified', 'Unverified'];

    useEffect(() => {
        // Log the operationDetails for debugging
        console.log('operationDetails summary', operationDetails);
        updateInboundList();
    }, [operationDetails]);


    const updateInboundList = () => {

        // Construct a new summary details array from operationDetails.products
        const collection = operationDetails?.products?.map(product => ({
            id: product?.id,
            oldGradeId: product?.old_grade_id,
            oldGradeName: product?.old_grade_name,
            newGradeId: product?.new_grade_id,
            newGradeName: product?.new_grade_name,
            colorId: product?.color_id,
            colorName: product?.color_name,
            imei: product?.imei,
            productId: product?.product_id,
            productName: product?.product_name,
            quantity: product?.quantity,
            status: statusDisplayedAsInbounded?.includes(product?.status) ? 'Inbounded' : 'Pending',
        })) || [];
    
        // Update the summaryDetails state once with the new array
        setInboundList(collection);
    }

    const removeProductFromList = (index) => {
        setShowDelete(true);
        setSelectedProductIndex(index)
    }

    const handleClose = () => {
        setShowDelete(false);
    }
    const handleDelete = () => {
        handleRemoveProductFromList(selectedProductIndex);
        setShowDelete(false);
        setSelectedProductIndex(null);
    }

    const handleSearch = (value) => {
        if (value.length >= 3) {
            const filtered = inboundList.filter(item => 
                item.imei.includes(value) || item.productName.toLowerCase().includes(value.toLowerCase())
            );
            setInboundList(filtered);
        } else {
            updateInboundList();
        }
    }

    return (
        <>
            
            
            {/* <div className="card shadow-sm bg-white mb-4 p-4 table-card"></div> */}
            <div >
                <div className="bg-white pt-0">
                    <div className="d-flex align-items-center justify-content-between TableSerachBar">
                        <div>
                            <h5 >Inbounded Products</h5>
                        </div>

                        {
                            inboundList.length > 0 && (

                                <form className="form-inline navbar-search">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control bg-white border"
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) => {
                                                handleSearch(e.target.value)
                                            }}
                                            // value={searchField}
                                            style={{ width: '100%' }}
                                        />
                                        <span className="searchIcon">
                                            <FaSearch />
                                        </span>
                                    </div>
                                </form>
                            )
                        }
                    </div>
                    {/* <div className="table-responsive-xl tableData customersList"> */}

                    {
                        inboundList.length > 0 ? (
                            <>
                                <Scroller extraHeight={topSection?.current?.clientHeight || 0}>
                                    <table className="table pm-table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ width: '160px' }}>IMEI</th>
                                                <th scope="col">Product</th>
                                                {/* <th scope="col" style={{ width: '140px' }}>Color</th> */}
                                                {/* <th scope="col" style={{ width: '60px' }}>Qty</th> */}
                                                <th scope="col" style={{ width: '175px' }}>Status</th>
                                                {/* <th scope="col" style={{ width: '20px' }}></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                inboundList?.map((item, index) => (
                                                    <>
                                                        <tr key={index} className="hover-row">
                                                            <td className="align-middle">
                                                                {
                                                                    item.imei ? (
                                                                        <>
                                                                            <span className="font500">{item?.imei}</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="font500 textGray">No IMEI</span>
                                                                        </>
                                                                    )
                                                                }
                                                            </td>
                                                            <td className="align-middle">
                                                                {item.productName} {' '}

                                                                {
                                                                    item.colorName && (
                                                                        <>
                                                                            <span className="textGray">({item?.colorName})</span>
                                                                        </>
                                                                    )
                                                                }
                                                            </td>
                                                            {/* <td className="align-middle">
                                                                {
                                                                    item.colorName ? (
                                                                        <>
                                                                            <span className="font500">{item?.colorName}</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="font500 textGray">No Color</span>
                                                                        </>
                                                                    )
                                                                }
                                                            </td> */}
                                                            {/* <td className="align-middle">{item?.proceedureName}</td> */}
                                                            {/* <td className="align-middle">{ item?.quantity.toLocaleString() }</td> */}
                                                            <td className="align-middle">
                                                                <div style={{ width: '100px' }}>
                                                                    <StatusBadge itemStatus={item?.status} />
                                                                </div>
                                                            </td>

                                                            <td className="hover-button-container">
                                                                <div className="hover-button">
                                                                    
                                                                    <div
                                                                        className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
                                                                        onClick={() => { removeProductFromList(index) }}
                                                                    >
                                                                        <DeleteIcon2 height={'18px'} width={'18px'} />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {/* <td className="align-middle">
                                                                <div
                                                                    onClick={() => { removeProductFromList(index) }}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <DeleteIcon height={'20px'} width={'20px'} />
                                                                </div>
                                                            </td> */}
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </Scroller>
                            </>
                        ) : (
                            <>
                                <div className="not-found-container cstm-not-found" style={{ height: '300px', marginBottom: '20px' }}>
                                    <div className="not-found-icon">
                                        <img src={NoItemAvailableIcon} style={{ height: '100px', width: '100px' }} />
                                    </div>
                                    <h6 className="not-found-text">No inbounded product  </h6>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>


			{showDelete && (
				<DeleteConfirmation
					show={showDelete}
					handleClose={handleClose}
					handleDelete={handleDelete}
					title="Confirm"
					message={`Are you sure you want to delete ${operationDetails?.products[selectedProductIndex]?.product_name} ${(operationDetails?.products[selectedProductIndex]?.color_name) ? `(${operationDetails?.products[selectedProductIndex]?.color_name})` : `` } from the list?`}
				/>
			)}

        </>
    );
};

export default InboundList;
