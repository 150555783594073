import React from 'react';
import './taskgrid.scss';
import { FaCheck, FaInfoCircle } from 'react-icons/fa';
import { FaCircleInfo } from 'react-icons/fa6';
import { BsPhone } from 'react-icons/bs';
import Avatar from 'ui-component/extended/Avatar';
import Task from './Task';
import { Skeleton } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import PropTypes from 'prop-types';

function TaskGrid({ dataList, isLoading, onAccept, onDetails, customDone, acceptPermission, viewPermission, privacyMode }) {
	return (
		<>
			{isLoading ? (
				<>
					<div className="taskgrid-container">
						{[...Array(8)].map((e, i) => (
							// <div className='task-card'>
							<Skeleton variant="rectangular" width={'100%'} height={100} style={{ borderRadius: '5px' }} key={i}/>
							// </div>
						))}
					</div>
				</>
			) : (
				<>
					{dataList && dataList.length > 0 ? (
						<div className="taskgrid-container">
							{dataList.map((item) => {
								return <Task task={item} key={item.id} onAccept={onAccept} onDetails={onDetails} customDone={customDone} acceptPermission={acceptPermission} viewPermission={viewPermission} privacyMode={privacyMode} />;
							})}
						</div>
					) : (
						<>
							<div className="not-found-container">
								<div className="not-found-icon">
									<TbDatabaseExclamation />
								</div>
								<h6 className="not-found-text">No Data Available</h6>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}

TaskGrid.propTypes = {
	dataList: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			taskName: PropTypes.string,
			date: PropTypes.any,
			priority: PropTypes.string,
			priorityColor: PropTypes.string,
			quantity: PropTypes.number,
			inprogress: PropTypes.bool,
			bodyContent: PropTypes.any
		})
	),
	isLoading: PropTypes.bool,
	onAccept: PropTypes.func,
	onDetails: PropTypes.func
};

export default TaskGrid;
