import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Tableheader from 'ui-component/Tableheader';
import { Dropdown, InputGroup } from 'react-bootstrap';
import Pagination from 'ui-component/Pagination';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Scroller from 'ui-component/Scroller';
import AddTransitShipmentModal from './AddTransitShipmentModal';
import downloadIcon from 'assets/images/icons/download.svg';
import copyIcon from 'assets/images/icons/copy.svg';
import moment from 'moment';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { Skeleton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import { FaEye } from 'react-icons/fa6';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import usePageTitle from 'hooks/usePageTitle';
import usePermissions from 'hooks/usePermissions';

const TransitShipments = () => {
	const [setPageTitle] = usePageTitle('Transit Shipments');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const [params, setParams] = useSearchParams();
	const history = useNavigate();
	const [userList, setUserList] = useState([]);
	const [alertMessage, setAlertMessage] = useState('');
	const [transitShipmentList, setTransitShipmentList] = useState([]);
	const [show, setShow] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	// const customerList = useSelector((state) => state.main.setcustomerlist);
	const [pagination, setpagination] = useState([]);
	const [newShow, setNewShow] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [allPermissions, hasEveryPermission] = usePermissions();


	const handleNewClose = () => setNewShow(false);

	const getTransitShipmentList = () => {
		setIsLoading(true);
		api.getTransitShipmentList().then((res) => {

			setIsLoading(false);
			setTransitShipmentList(res.data.data);
			setpagination(res.data);
			isFirstLoad && setIsFirstLoad(false);
		});
	};

	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsLoading(true);
			if (text.length > 2) {
				api.filterTransitShipments(activeFilters, text).then((res) => {
					console.log(res);
					setIsLoading(false);
					setTransitShipmentList(res.data.data);
					setpagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterTransitShipments(activeFilters, null).then((res) => {
					setIsLoading(false);
					setTransitShipmentList(res.data.data);
					setpagination(res.data);
				});
			}
		}, 500);

		setSearchTimeout(timeout);
	};

	const viewData = (id) => {
		history(`/operations/transitshipments/${id}`);
	};

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);
		api.filterTransitShipments(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsLoading(false);
			setTransitShipmentList(res.data.data);
			setpagination(res.data);
		});
	};

	const deleteuserData = (data) => {
		setShow(data);
	};

	const handleClose = () => setShow('');

	const handleData = (data) => {
		setTransitShipmentList(data.data);
		setpagination(data);
	};

	useEffect(() => {
		getTransitShipmentList();
		getFiltersList();
	}, []);

	useEffect(() => {
		if (params.get('create') == 'shipment') {
			setParams({});
			setNewShow(true);
		}
	});

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		backgroundColor: '#f2f2f2',
		zIndex: 1
	};
	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getTransitShipmentFilters().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	const handleCopyBtn = (link, index) => {
		navigator.clipboard.writeText(link);
		setAlertMessage({ ...alertMessage, copied: { message: 'Copied', index: index } });
		setTimeout(() => {
			setAlertMessage({ alertMessage, copied: '' });
		}, 2000);
	};

	const onAddNewTransitShipment = (data) => {
		toast.promise(
			api
				.createTransitShipments({
					transit_company_id: data?.ship_to,
					shipping_company_id: data?.ship_with,
					tracking_no: data?.tracking_number,
					package_ids: data?.packages
				})
				.then((res) => {
					setNewShow(false);

					if (res?.status) {
						handleFilter();
					}
					return res?.status ? res : null;
				}),
			{
				loading: 'Creating Transit Shipment...',
				success: (data) => `${data.message}`,
				error: <b>Failed to create transit shipment</b>
			}
		);
	};

	// console.log('shipmentList ', shipmentList);

	return (
		<>
			<div className="row">
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<h2></h2>
						<div className="actions">
							<PermissionAllowed permissions={['Create Transit Shipment (Button)']}>
								<Link to="" onClick={() => setNewShow(true)} className="btn btn-primary btn-outlined-main">
									<FaPlus /> Create
								</Link>
							</PermissionAllowed>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
										style={{ width: '100%' }}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="table-responsive-xl tableData customersList p-2">
							<Scroller>
								<table className="table pm-table">
									<thead>
										<tr className='sticky-header'>
											{/* <th className="align-middle pl-2" width={'15'}>
												<input type="checkbox" />
											</th> */}
											<th scope="col">Ref</th>
											<th scope="col">Date</th>
											<th scope="col">Ship To</th>
											<th scope="col">Ship With</th>
											<th scope="col">Invoices</th>
											<th scope="col">Quantity</th>
											<th scope="col">Tracking</th>
											<th scope="col" className="text-center">
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<>
												{[...Array(5)].map((e, i) => (
													<tr>
														{[...Array(8)].map((e, i) => (
															<td>
																<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
															</td>
														))}
													</tr>
												))}
											</>
										) : (
											<>
												{transitShipmentList?.map((row, index) => {
													return (
														<>
															<tr key={index}>
																<td className="align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['View Transit Shipment (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Transit Shipment (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	<b>{row?.reference_no}</b>
																</td>
																<td className="align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['View Transit Shipment (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Transit Shipment (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	{moment(row?.created_at).format(CommonDateOnlyFormat)}
																</td>
																<td className="align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['View Transit Shipment (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Transit Shipment (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	<PrivacyModeContent notUserRestricted>{row?.transit_company?.name}</PrivacyModeContent>
																</td>
																<td className="align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['View Transit Shipment (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Transit Shipment (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	{row?.shipping_company?.company_name}
																</td>
																<td className="align-middle text-primary">
																	{row?.attached_invoice_numbers?.map((n, i) =>
																		i < row?.attached_invoice_numbers?.length - 1 ? (
																			<>
																				<Link to={'#'}>{n}</Link> ,
																			</>
																		) : (
																			<>
																				<Link to={'#'}>{n}</Link>
																			</>
																		)
																	)}
																</td>
																<td className="align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['View Transit Shipment (Button)'])) {
																			viewData(row.id);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['View Transit Shipment (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	{row?.quantity}
																</td>
																<td className="align-middle">
																	<a href={row?.tracking_link} target="_blank">
																		{row?.tracking_no}
																	</a>
																</td>
																<td className="align-middle action-col">
																	<PermissionAllowed permissions={['Delete Shipment (Button)']} hasSome>
																		<Dropdown className="btnDel iconbtn">
																			<Dropdown.Toggle as="span" id={`dropdown-toggle-${index}`}>
																				<i className="fas fa-ellipsis-h "></i>
																			</Dropdown.Toggle>
																			<PermissionAllowed permissions={['Delete Shipment (Button)']}>
																				<Dropdown.Menu>
																					<>
																						<Dropdown.Item>Delete</Dropdown.Item>
																					</>
																				</Dropdown.Menu>
																			</PermissionAllowed>
																		</Dropdown>
																	</PermissionAllowed>
																</td>
															</tr>
														</>
													);
												})}
												{transitShipmentList?.length < 1 && (
													<tr className="no-hover">
														<td colSpan={11}>
															<div className="not-found-container">
																<div className="not-found-icon">
																	<TbDatabaseExclamation />
																</div>
																<h6 className="not-found-text">No Data Available</h6>
															</div>
														</td>
													</tr>
												)}
											</>
										)}
									</tbody>
								</table>
							</Scroller>
						</div>
						<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
					</div>
				</div>
			</div>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Body>
					<Modal.Title>Confirm Delete</Modal.Title>
					<p>Are you sure you want to delete {`${show.name}`}</p>
					<Button variant="primary" className="m-2 " onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="m-2"
						onClick={() => {
							handleDelete(show.id);
						}}
					>
						Delete
					</Button>
				</Modal.Body>
			</Modal>
			{newShow && <AddTransitShipmentModal newShow={newShow} handleNewClose={handleNewClose} onSubmit={onAddNewTransitShipment} />}
		</>
	);
};

export default TransitShipments;
function CustomToggle({ children, onClick }) {
	return (
		<>
			<a
				className="dropdown-toggle"
				href="#"
				role="button"
				id="dropdownMenuLink"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				onClick={onClick}
			>
				{children}
			</a>
		</>
	);
}
