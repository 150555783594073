import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './packing.scss';
import Scroller from 'ui-component/Scroller';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiPackageBold, PiTestTubeFill, PiWarningFill } from 'react-icons/pi';
import { FaCheckCircle, FaPen, FaPlus, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { ImSpinner2 } from 'react-icons/im';
import ProcessIndicator from '../ProcessIndicator';
import { BsClipboardCheck } from 'react-icons/bs';
import { GiAutoRepair, GiHandTruck, GiMagnifyingGlass } from 'react-icons/gi';
import { BiCategoryAlt } from 'react-icons/bi';
import { IoMdBarcode } from 'react-icons/io';
import { getStatusColor } from '../TaskGrid/Task';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import AddRepairsModal from 'ui-component/confirmation-dialogs/addRepairsModal/AddRepairsModal';
import toast from 'react-hot-toast';
import index from 'views/estimation';
import { AiFillDropboxCircle } from 'react-icons/ai';
import { FaCircleStop } from 'react-icons/fa6';
import NewPackingModal from './NewPackingModal';
import { taskboardProgresses } from 'assets/helperdata/taskboard-progrss';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';

function PackingDetails() {
	const { id } = useParams();
	const history = useNavigate();
	const topSection = useRef(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedPackage, setSelectedInbound] = useState([]);
	const [collectionModals, setCollectionModals] = useState([]);
	const [selectedEstimate, setSelectedEstimate] = useState();
	const [selectedColor, setSelectedColor] = useState();
	const [imeiInputValue, setImeiInputValue] = useState('');
	const imeiInputRef = useRef(null);
	const [modelImeis, setModelImeis] = useState([]);
	const [checkModelImeis, setCheckModelImeis] = useState(true);
	const [isInProgress, setIsInProgress] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [colors, setColors] = useState([]);
	const [modelRepairOptions, setModelRepairOptions] = useState([]);

	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const [addRepairDialog, setAddRepairDialog] = useState(null);

	const handleRepairDialogClose = () => {
		setAddRepairDialog(null);
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const removeImeiFromList = (imei) => {
		setModelImeis([...modelImeis].filter((f) => f.imei != imei));
	};

	const addModalImei = async () => {
		if (!imeiInputValue || !imeiInputValue.trim()) {
			showErrorDialog('Invalid IMEI', `Invalid IMEI Entered`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			return;
		}

		const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

		if (!alphanumericRegex.test(imeiInputValue)) {
			showErrorDialog('Invalid IMEI', `Please enter a valid IMEI Number`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			return;
		}

		if (modelImeis.find((m) => m?.imei == imeiInputValue)) {
			showErrorDialog('Duplicate IMEI', `Couldn't add IMEI ${imeiInputValue} since it already exists in the list`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
		} else {
			const theImei = `${imeiInputValue}`;
			const theEstNo = selectedEstimate?.value;
			setModelImeis((prevModelImeis) => [
				{
					imei: `${imeiInputValue}`,
					estimation_id: theEstNo,
					valid: 'loading',
					invalid_message: 'Loading',
					id: null,
					product: null,
					model: null,
					storage: null,
					grade: null,
					color: null
				},
				...prevModelImeis
			]);
			setImeiInputValue('');
			imeiInputRef.current.value = '';

			try {
				const response = await api.getImeiDetails(theImei);
				if (response?.status == true) {
					const templateItem = selectedPackage?.package_details?.find(
						(e) => e?.estimation?.id == theEstNo && e?.product?.id == response?.data?.product?.id
					);
					if (!templateItem) {
						setModelImeis((prevModelImeis) =>
							prevModelImeis.map((item) =>
								item.imei == theImei
									? {
											...item,
											valid: false,
											invalid_message: 'Item does not match the template',
											id: response?.data?.id,
											product: response?.data?.product,
											model: response?.data?.model,
											storage: response?.data?.storage,
											grade: response?.data?.grade,
											color: response?.data?.color
									  }
									: item
							)
						);
					} else if (response?.data?.status == 'inbounded') {
						setModelImeis((prevModelImeis) =>
							prevModelImeis.map((item) =>
								item.imei == theImei
									? {
											...item,
											valid: true,
											invalid_message: 'In Stock',
											id: response?.data?.id,
											product: response?.data?.product,
											model: response?.data?.model,
											storage: response?.data?.storage,
											grade: response?.data?.grade,
											color: response?.data?.color
									  }
									: item
							)
						);
					} else if (response?.data?.status == 'outbounded') {
						setModelImeis((prevModelImeis) =>
							prevModelImeis.map((item) =>
								item.imei == theImei
									? {
											...item,
											valid: false,
											invalid_message: 'This item has been outbounded',
											id: response?.data?.id,
											product: response?.data?.product,
											model: response?.data?.model,
											storage: response?.data?.storage,
											grade: response?.data?.grade,
											color: response?.data?.color
									  }
									: item
							)
						);
					} else if (response?.data?.status == 'under_repair') {
						setModelImeis((prevModelImeis) =>
							prevModelImeis.map((item) =>
								item.imei == theImei
									? {
											...item,
											valid: false,
											invalid_message: 'This item is under repair',
											id: response?.data?.id,
											product: response?.data?.product,
											model: response?.data?.model,
											storage: response?.data?.storage,
											grade: response?.data?.grade,
											color: response?.data?.color
									  }
									: item
							)
						);
					}
				} else {
					setModelImeis((prevModelImeis) =>
						prevModelImeis.map((item) =>
							item.imei === theImei
								? {
										...item,
										valid: false,
										invalid_message: response?.data?.errors?.imei?.[0]
											? 'IMEI does not exist in stock'
											: 'Something went wrong'
								  }
								: item
						)
					);
				}
			} catch (error) {
				setModelImeis((prevModelImeis) =>
					prevModelImeis.map((item) =>
						item.imei === theImei ? { ...item, valid: false, invalid_message: 'Something went wrong' } : item
					)
				);
			}
		}
	};

	const getCollectionData = (callback = () => {}) => {
		setLoading(true);
		setIsDisable(true);
		api.getpackingdetailsbyid(id).then((res) => {
			setSelectedInbound(res?.data);
			setIsDisable(false);
			setLoading(false);
			if (res?.data?.package_details?.length > 0) {
				const modelImeisList = [];

				res?.data?.package_details?.forEach((package_model) => {
					package_model?.package_items?.forEach((package_item) => {
						modelImeisList.push({
							imei: package_item?.imei,
							estimation_id: package_model?.estimation?.id,
							valid: true,
							invalid_message: 'Saved',
							id: package_item?.id,
							product: package_item?.product,
							model: package_item?.model,
							storage: package_item?.storage,
							grade: package_item?.grade,
							color: package_item?.color,
							existing: true
						});
					});
				});

				setModelImeis(modelImeisList);
			}
			callback(res?.data);
		});
	};

	const loadColorData = () => {
		api.getAllColors().then((res) => {
			if (res.status) {
				let typeTemp = res.data.map((item) => ({
					value: item.name?.[0].toUpperCase() + item.name?.substring(1),
					label: item.name?.[0].toUpperCase() + item.name?.substring(1)
				}));
				setColors(typeTemp);
			}
		});
	};

	const handleIMEIInputKeydown = (event) => {
		if (event.key === 'Enter') {
			addModalImei();
		}
	};

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const showConfirmationDialog = (title, message = '', onConfirm = () => {}, onClose = () => {}) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	const onRepairClick = (item) => {
		console.log(item?.product?.model_id);
		if (!modelRepairOptions.find((m) => m?.model_id == item?.product?.model_id)) {
			api.getModelRepairOptions(item?.product?.model_id).then((res) => {
				if (res?.status) {
					setModelRepairOptions([
						...modelRepairOptions,
						{
							model_id: item?.product?.model_id,
							options: res?.data
						}
					]);
				}
			});
		}
		setAddRepairDialog(item);
	};

	useEffect(() => {
		if (id) {
			getCollectionData((data) => {
				setIsInProgress(!!data?.start_at);
				if (!data?.start_at) {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Start Inbounding?',
							'Are you sure you want to start inbounding?',
							() => {
								handleStart();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				} else {
					if (searchParams.get('start')) {
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				}
			});
		}
		loadColorData();
	}, []);

	const onStartClick = () => {
		showConfirmationDialog(
			'Start Packing?',
			'Are you sure you want to start packing?',
			() => {
				handleStart();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleStart = () => {
		setIsDisable(true);
		toast.promise(
			api.startPacking(id).then(
				(res) => {
					if (res.status) {
						setIsInProgress(true);
					}

					setIsDisable(false);
					return res?.status ? res : null;
				},
				() => {
					setIsDisable(false);
				}
			),
			{
				loading: 'Starting Packing...',
				success: (data) => `${data.message}`,
				error: <b>Failed to start Packing</b>
			}
		);
	};

	const onSaveClick = () => {
		showConfirmationDialog(
			'Save Changes',
			'Are you sure you want to save your changes?',
			() => {
				setIsDisable(true);
				const data = {
					package_id: id,
					items: modelImeis?.filter((m) => !m?.existing)?.map((m) => ({ imei: m?.imei, estimation_id: m?.estimation_id }))
				};

				toast.promise(
					api.savePackingData(data).then((res) => {
						if (res.status) {
							setIsDisable(false);
							history('/operations/packing');
						}
						return res?.status ? res : null;
					}),
					{
						loading: 'Saving to packing...',
						success: (data) => `${data.message}`,
						error: (data) => {
							setIsDisable(false);
							return <b>Failed to save packing details</b>;
						}
					}
				);
			},
			handleCloseConfirmationDialog
		);
	};

	const onDoneClick = () => {
		showConfirmationDialog(
			'Complete Packing',
			'Are you sure you want to complete this packing?',
			() => {
				setIsDisable(true);
				const data = {
					package_id: id,
					items: modelImeis?.filter((m) => !m?.existing)?.map((m) => ({ imei: m?.imei, estimation_id: m?.estimation_id })),
					complete: true
				};

				toast.promise(
					api.savePackingData(data).then((res) => {
						if (res.status) {
							setIsDisable(false);
							history('/operations/packing');
						}
						return res?.status ? res : null;
					}),
					{
						loading: 'Saving to packing...',
						success: (data) => `${data.message}`,
						error: (data) => {
							setIsDisable(false);
							return <b>Failed to save packing details</b>;
						}
					}
				);
			},
			handleCloseConfirmationDialog
		);
	};

	const goback = () => {
		history('/operations/packing');
	};

	return (
		<>
			<ProcessIndicator isInProgress={isInProgress} processes={taskboardProgresses} currentIndex={8} />

			<div className={`TaskDetails`}>
				<div className="d-flex justify-content-between mb-0">
					<div className="topBtnSign" style={{ '--statusColor': getStatusColor(!selectedPackage?.end_at ? 'blue' : 'green') }}>
						<span>
							{selectedPackage?.start_at && selectedPackage?.end_at ? 'Completed' : isInProgress ? 'Inprogress' : 'Upcoming'}
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card p-4">
							<Scroller>
								<div className="row pb-4" ref={topSection}>
									<div className="col-12 d-flex justify-content-between mb-4">
										<h3 className="font-weight-bold m-0">{selectedPackage?.transit_company?.name}</h3>
										{/* <div
											className={`d-flex align-items-center justify-content-end datediv mb-4`}
											style={{ '--statusColor': getStatusColor(collection?.priority_status?.color) }}
										>
											<div className="boxDate">{moment(collection?.due_date).format('DD MMM HH:mm')}</div>
										</div> */}
									</div>
									<div className="col-12">
										<div className="row">
											<div className="col-md-20 mb-2 mb-md-0 detail-item">
												<p className="detail-label">Reference.</p>
												<div className="detail-value"> {selectedPackage?.reference_no} </div>
											</div>

											<div className="col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label" style={{ fontSize: '11px' }}>
													Total Packed
												</p>
												<div className="detail-value">{modelImeis?.length} Pcs</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row inbounding-imeis">
									<div className="col-xl-12 col-lg-12">
										<div className="row">
											<div className="col-4">
												<div className="card shadow-sm bg-white mb-4">
													<div className="input-form p-0">
														<div className="form-row col-md-12 m-0 p-0">
															<div className="form-group">
																<label htmlFor="">Invoice</label>
																<Select
																	placeholder="Select Model"
																	className="rs-container"
																	classNamePrefix="rs"
																	options={_.uniqBy(
																		selectedPackage?.package_details,
																		(e) => e?.estimation?.invoice_number
																	)?.map((e) => ({
																		label: e?.estimation?.invoice_number,
																		value: e?.estimation?.id
																	}))}
																	onChange={(e) => {
																		setSelectedEstimate(e);
																		setTimeout(() => {
																			if (e.value && selectedColor) {
																				imeiInputRef.current.focus();
																			}
																		}, 1);
																	}}
																	isDisabled={!isInProgress}
																/>
															</div>
															<div className="form-group">
																<label htmlFor="">IMEI</label>
																<input
																	ref={imeiInputRef}
																	type="text"
																	id="input-example"
																	name="input-name"
																	className="form-control form-control-sm"
																	placeholder="IMEI"
																	minLength={15}
																	maxLength={16}
																	onChange={(e) => {
																		setImeiInputValue(e.target.value);
																	}}
																	onKeyDown={(e) => {
																		handleIMEIInputKeydown(e);
																	}}
																	disabled={!isInProgress || !selectedEstimate}
																	autoComplete={'off'}
																	autoFocus
																/>
															</div>
															<label htmlFor="">Template</label>
															<div className="product-list">
																<table>
																	<thead>
																		<tr>
																			<th>Invoice</th>
																			<th>Product</th>
																			<th className="text-center">Remaining</th>
																		</tr>
																	</thead>
																	<tbody>
																		{selectedPackage?.package_details?.map((item) => (
																			<tr>
																				<td>{item?.estimation?.invoice_number}</td>
																				<td>{item?.product?.name}</td>
																				<td
																					className={`text-center ${
																						item?.item_counts?.saved +
																							[...modelImeis].filter(
																								(m) =>
																									m?.estimation_id ==
																										item?.estimation?.id &&
																									m?.product?.id == item?.product?.id &&
																									!m?.existing
																							)?.length >
																						item?.item_counts?.total
																							? 'text-danger'
																							: ''
																					}`}
																				>
																					{item?.item_counts?.saved +
																						[...modelImeis].filter(
																							(m) =>
																								m?.estimation_id == item?.estimation?.id &&
																								m?.product?.id == item?.product?.id &&
																								!m?.existing
																						)?.length}
																					/{item?.item_counts?.pending + item?.item_counts?.saved }
																				</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-8">
												<div className="card shadow-sm bg-white mb-4">
													<Scroller extraHeight={topSection?.current?.clientHeight || 0}>
														<table className="table ">
															<thead>
																<tr>
																	<th scope="col" width="20%">
																		IMEI
																	</th>
																	<th scope="col" width="20%">
																		Model
																	</th>
																	<th scope="col">Storage</th>
																	<th scope="col">Colour</th>
																	<th scope="col">Grade</th>
																	<th scope="col"></th>
																</tr>
															</thead>
															<tbody>
																{modelImeis.map((item, index) => (
																	<tr>
																		<td className="align-middle">
																			<span
																				className="d-flex align-items-center"
																				style={{ gap: '2px' }}
																			>
																				{item?.existing ? (
																					<Tooltip title="Saved" arrow placement="top">
																						<span className="text-info d-flex">
																							<FaCircleStop />
																						</span>
																					</Tooltip>
																				) : item?.valid == 'loading' ? (
																					<Tooltip title="Checking IMEI" arrow placement="top">
																						<span className="d-flex">
																							<ImSpinner2 className="fa-spin" />
																						</span>
																					</Tooltip>
																				) : item?.valid == true ? (
																					<Tooltip title="Valid IMEI" arrow placement="top">
																						<span className="text-success d-flex">
																							<FaCheckCircle />
																						</span>
																					</Tooltip>
																				) : (
																					<Tooltip
																						title={item?.invalid_message || 'Invalid IMEI'}
																						arrow
																						placement="top"
																					>
																						<span className="text-danger d-flex">
																							<FaTimesCircle />
																						</span>
																					</Tooltip>
																				)}{' '}
																				<span>{item?.imei}</span>
																			</span>
																		</td>
																		<td className="align-middle">{item?.model?.name}</td>
																		<td className="align-middle">{item?.storage?.name}</td>
																		<td className="align-middle">{item?.color?.name}</td>
																		<td className="align-middle">{item?.grade?.name}</td>
																		<td className="align-middle text-center">
																			{!item?.existing ? (
																				<span
																					className="btnEdit plain-iconbtn"
																					style={{
																						height: '20px',
																						width: '20px',
																						fontSize: '10px',
																						display: 'inline-block'
																					}}
																					onClick={() => {
																						removeImeiFromList(item?.imei);
																					}}
																				>
																					<FaTimes />
																				</span>
																			) : (
																				<Tooltip
																					title="Item already Saved"
																					arrow
																					placement="top"
																					key={'remove-' + index}
																				>
																					<span
																						className="btnEdit plain-iconbtn disabled"
																						style={{
																							height: '20px',
																							width: '20px',
																							fontSize: '10px',
																							display: 'inline-block'
																						}}
																						disabled
																					>
																						<FaTimes />
																					</span>
																				</Tooltip>
																			)}
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													</Scroller>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Scroller>
							<div className="btnarea">
								<span
									onClick={() => {
										goback();
									}}
									className={`btn btn-link ${isDisable ? 'disabled' : ''}`}
								>
									Back
								</span>
								<PermissionAllowed permissions={['Start / End Task Packing (Action)']}>
									{selectedPackage?.status != 'completed' &&
										(isInProgress ? (
											<>
												<span
													onClick={() => {
														if (
															isDisable ||
															modelImeis?.filter((m) => !m?.existing)?.length <= 0 ||
															modelImeis?.some((m) => m?.valid != true)
														) {
														} else {
															onSaveClick();
														}
													}}
													className={`btn btn-primary btn-outlined-main  ${
														isDisable ||
														modelImeis?.filter((m) => !m?.existing)?.length <= 0 ||
														modelImeis?.some((m) => m?.valid != true)
															? 'disabled'
															: ''
													}`}
												>
													Save
												</span>
												<span
													onClick={() => {
														if (
															isDisable ||
															modelImeis?.some((m) => m?.valid != true)
														) {
														} else {
															onDoneClick();
														}
													}}
													className={`btn btn-primary btn-outlined-main  ${
														isDisable ||
														modelImeis?.some((m) => m?.valid != true)
															? 'disabled'
															: ''
													}`}
												>
													Done
												</span>
											</>
										) : (
											<span
												onClick={() => {
													onStartClick();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Start
											</span>
										))}
								</PermissionAllowed>
							</div>
						</div>
					</div>
				</div>
			</div>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}

			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}

			{addRepairDialog && (
				<AddRepairsModal
					show={addRepairDialog}
					handleClose={handleRepairDialogClose}
					onSubmit={onAddRepairSubmit}
					repairOptions={modelRepairOptions?.find((m) => m?.model_id == addRepairDialog?.product?.model_id)?.options}
				/>
			)}
		</>
	);
}

export default PackingDetails;
