import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { FaCheck, FaPlus } from 'react-icons/fa6';
import Select from 'react-select';
import Scroller from 'ui-component/Scroller';

function NewPackingModal({ show, handleClose, handleSubmit }) {
	const [transitCompanies, setTransitCompanies] = useState([]);
	const [invoiceList, setInvoiceList] = useState([]);
	const [selectedContact, setSelectedContact] = useState(null);
	const [itemList, setItemList] = useState([
		{
			estimate: null,
			productOptions: [],
			product: null,
			qty: '-'
		}
	]);

	useEffect(() => {
		if (!itemList.find((item) => item?.estimate == null)) {
			setItemList((prevItemList) => [
				...prevItemList,
				{
					estimate: null,
					productOptions: [],
					product: null,
					qty: '-'
				}
			]);
		}

		if (itemList?.length > 0) {
			const contact = itemList?.[0]?.estimate?.transit_company || itemList?.[0]?.estimate?.customer;

			if (contact) {
				setSelectedContact(contact);
			} else {
				setSelectedContact(null);
			}
		} else {
			setSelectedContact(null);
		}
	}, [itemList]);

	const updateItemEstimate = (e, i) => {
		setItemList((prevItemList) => {
			return prevItemList.map((item, index) =>
				index == i
					? {
							estimate: e,
							productOptions: e?.items,
							product: null,
							qty: '-'
					  }
					: item
			);
		});
	};

	const updateItemProduct = (e, i) => {
		setItemList((prevItemList) => {
			return prevItemList.map((item, index) =>
				index == i
					? {
							...item,
							product: e,
							qty: e?.remaining_qty
					  }
					: item
			);
		});
	};

	const removeItemFromList = (i) => {
		setItemList((prevItemList) => {
			return prevItemList.filter((item, index) => index != i);
		});
	};

	const clickCreate = (start = false) => {
		const shortenedList = [...itemList].slice(0, itemList?.length - 1);
		const data = {
			is_start: start ? 1 : 0,
			transit_company_id: selectedContact?.id,
			estimations: shortenedList.map((m) => ({ estimation_item_id: m?.product?.value }))
		};

		handleSubmit && handleSubmit(data);
	};

	useEffect(() => {
		api.getReadyToPackInvoices().then((res) => {
			setInvoiceList(res.data);
		});
	}, []);

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			size="lg"
			centered
		>
			<Modal.Body>
				<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2">
					<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">New Packing</h5>
					<button className="btn modal-close-icon" onClick={handleClose}>
						<FaTimes />
					</button>
				</div>
				<div className="row align-items-top">
					<div className="col-6" style={{ paddingInline: '25px', marginBottom: '10px' }}>
						<label htmlFor="" style={{ fontSize: '11px', color: 'black', fontWeight: 500 }}>
							Package for
						</label>
						<p className="m-0">{selectedContact?.name || '-'}</p>
					</div>
					<div className="col-12">
						<div className="collaps found table-responsive-xl tableData" style={{ overflow: 'visible' }}>
							<table className="table table-md  formActBtns border-0 w-100 pm-table">
								<thead>
									<tr>
										<th>Invoice</th>
										<th width={'330px'}>Product</th>
										<th>Qty</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{itemList?.map((item, index) => (
										<tr
											className={
												[...itemList]?.filter(
													(e) =>
														e.estimate?.value == item?.estimate?.value && e?.product?.value == item?.product?.value && e?.qty == item?.qty
												)?.length > 1
													? 'invalid-row'
													: ''
											}
										>
											<td className="align-middle">
												<Select
													options={invoiceList
														?.filter((e) =>
															!!selectedContact
																? e?.transit_company
																	? e?.transit_company?.id == selectedContact?.id
																	: e?.customer == selectedContact?.id
																: true
														)
														?.filter((e) => !!e?.invoice_number)
														?.filter((e) => e?.items?.some((it) => it?.remaining_qty > 0))
														?.map((ei) => ({
															...ei,
															label: ei?.invoice_number,
															value: ei?.id
														}))}
													className="rs-filter-container"
													classNamePrefix="rs-filter"
													value={item?.estimate}
													onChange={(e) => {
														updateItemEstimate(e, index);
													}}
												/>
											</td>
											<td className="align-middle">
												<Select
													options={item?.productOptions
														?.filter((it) => it.remaining_qty > 0)
														?.map((ii) => ({
															...ii,
															label: ii?.product_name,
															value: ii?.id
														}))}
													className="rs-filter-container"
													classNamePrefix="rs-filter"
													value={item?.product}
													onChange={(e) => {
														updateItemProduct(e, index);
													}}
												/>
											</td>
											<td className="align-middle">{item?.qty}</td>
											<td className="align-middle">
												<Tooltip title="Remove" arrow placement="top">
													<span
														className={`btnEdit plain-iconbtn ${item?.estimate ? '' : 'disabled'}`}
														style={{
															height: '20px',
															width: '20px',
															fontSize: '10px',
															display: 'inline-flex'
														}}
														onClick={() => {
															if (item?.estimate) {
																removeItemFromList(index);
															}
														}}
													>
														<FaTimes />
													</span>
												</Tooltip>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="col-12">
						<div className="btnarea mt-5">
							<button
								className={`btn btn-main ${
									itemList.every((item, index) => (index == itemList?.length - 1 ? true : item?.estimate && item?.product && item?.qty)) &&
									itemList.every(
										(ii) =>
											[...itemList]?.filter(
												(e) => e.estimate?.value == ii?.estimate?.value && e?.product?.value == ii?.product?.value && e?.qty == ii?.qty
											)?.length == 1
									) &&
									itemList?.length > 1 &&
									selectedContact
										? ''
										: 'disabled'
								}`}
								onClick={() => {
									if (
										itemList.every((item, index) =>
											index == itemList?.length - 1 ? true : item?.estimate && item?.product && item?.qty
										) &&
										itemList.every(
											(ii) =>
												[...itemList]?.filter(
													(e) => e.estimate?.value == ii?.estimate?.value && e?.product?.value == ii?.product?.value && e?.qty == ii?.qty
												)?.length == 1
										) &&
										itemList?.length > 1 &&
										selectedContact
									) {
										clickCreate(true);
									}
								}}
							>
								<FaCheck /> Create & Start
							</button>
							<button
								className={`btn btn-main ${
									itemList.every((item, index) => (index == itemList?.length - 1 ? true : item?.estimate && item?.product && item?.qty)) &&
									itemList.every(
										(ii) =>
											[...itemList]?.filter(
												(e) => e.estimate?.value == ii?.estimate?.value && e?.product?.value == ii?.product?.value && e?.qty == ii?.qty
											)?.length == 1
									) &&
									itemList?.length > 1 &&
									selectedContact
										? ''
										: 'disabled'
								}`}
								onClick={() => {
									if (
										itemList.every((item, index) =>
											index == itemList?.length - 1 ? true : item?.estimate && item?.product && item?.qty
										) &&
										itemList.every(
											(ii) =>
												[...itemList]?.filter(
													(e) => e.estimate?.value == ii?.estimate?.value && e?.product?.value == ii?.product?.value && e?.qty == ii?.qty
												)?.length == 1
										) &&
										itemList?.length > 1 &&
										selectedContact
									) {
										clickCreate();
									}
								}}
							>
								<FaPlus /> Create
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default NewPackingModal;
