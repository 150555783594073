import React, { useState, useEffect, useContext } from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import { FaSearch, FaTimesCircle, FaPlus, FaSave, FaTimes,FaMinus, FaCheck } from 'react-icons/fa';
import Scroller from 'ui-component/Scroller';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { CiMobile3 } from "react-icons/ci";
import Pagination from 'ui-component/Pagination';
import { useSelection } from './SelectionContext';
import { toast } from 'react-hot-toast';




const ProductList = ({
	close,
    isLoading,
	currency
}) => {	
	  const { selectedRows, addSelectedRow, removeSelectedRow, clearSelectedRows, updateSelectedRow } = useSelection();
		const [productList, setProductList] = useState([]);
		const [isAddList, setIsAddList] = useState([]);
		const [rowCounts, setRowCounts] = useState([]);
		const [pagination, setpagination] = useState(null);
		const [searchField, setSearchField] = useState('');
		const [priceInputs, setPriceInputs] = useState([]);
		

		useEffect(() => {
		api.getAllproducts().then((res) => {
			console.log(res.data)
			setpagination(res?.data?.pagedResults);
			setProductList(res?.data?.data);
			setIsAddList(Array(res.data?.data.length).fill(false));
      		setRowCounts(Array(res.data?.data.length).fill(0));
        });
	}, []);
	const totalQuantity = selectedRows.reduce((total, row) => total + row.quantity, 0);
	const totalPrice = selectedRows.reduce((total, row) => total + (row.quantity * row.price), 0);

  useEffect(() => {
    setIsAddList(Array(productList?.length).fill(false));
    setRowCounts(Array(productList?.length).fill(0));
  }, [productList]);

  		useEffect(() => {
  		  if (selectedRows?.length > 0) {
  		    const updatedIsAddList = Array(productList?.length).fill(false);
  		    const updatedRowCounts = Array(productList?.length).fill(0);
  		    const updatedPriceInputs = Array(productList?.length).fill('');

  		    selectedRows.forEach((selectedRow) => {
  		      const { product_id, quantity, price } = selectedRow;
  		      const index = productList.findIndex((item) => item.id === product_id);
  		      if (index !== -1) {
  		        updatedIsAddList[index] = true;
  		        updatedRowCounts[index] = quantity;
  		        updatedPriceInputs[index] = price;
  		      }
  		    });
  		    setIsAddList(updatedIsAddList);
  		    setRowCounts(updatedRowCounts);
  		    setPriceInputs(updatedPriceInputs);
  		  }
  		}, [productList,selectedRows]);

		const updatePriceInput = (index, value) => {
			console.log(value);
		    const updatedPrices = [...priceInputs];
		    updatedPrices[index] = value;
		    setPriceInputs(updatedPrices);
		  };

  		const handleIncrease = (index) => {
			  const updatedCounts = [...rowCounts];
    		  updatedCounts[index] += 1;
    		  setRowCounts(updatedCounts);
			  const product_id = productList[index].id;
			  const updatedRowData = {
				    product_id: product_id,
				    product_name: productList[index].product_name,
				    quantity: updatedCounts[index],
				    price: priceInputs[index],
				  };
			  updateSelectedRow(updatedRowData);
  		};
	
  		const handleDecrease = (index) => {
			const product_id = productList[index].id;
			const currentCount = rowCounts[index];
			const updatedCounts = [...rowCounts];
  			updatedCounts[index] = Math.max(currentCount - 1, 0);
  			setRowCounts(updatedCounts);
		  if(currentCount - 1 < 1){
			const updatedIsAddList = [...isAddList];
    		updatedIsAddList[index] = false;
			setIsAddList(updatedIsAddList);
    		removeSelectedRow(product_id);
    		
			priceInputs[index] = '';

		  }else{
				const updatedRowData = {
				    product_id: product_id,
				    product_name: productList[index].product_name,
				    quantity: updatedCounts[index],
				    price: priceInputs[index],
				  };
				updateSelectedRow(updatedRowData);
		  }
  		};

		const handleData = (data) => {
		
		 setProductList(data?.data);
		 setpagination(data?.pagedResults);
		};

		useEffect(() => {
			if (searchField?.length > 2) {
				api.getAllproducts(searchField).then((res) => {
					setpagination(res?.data?.pagedResults);
					setProductList(res?.data?.data);
				});
			}
		}, [searchField]);

		const searchData = (e) => {
			e.preventDefault();
		  setSearchField(e.target.value);
		};
		const updaterowCounts = (index, value) => {
			const product_id = productList[index].id;
			let v = parseInt(value, 10);
			if(v > 0){
				const updatedRowData = {
					product_id: product_id,
					product_name: productList[index].product_name,
					quantity: parseInt(value, 10),
					price: priceInputs[index],
				};
				 updateSelectedRow(updatedRowData);
			}
			else{
				const updatedIsAddList = [...isAddList];
    			updatedIsAddList[index] = false;
				setIsAddList(updatedIsAddList);
    			removeSelectedRow(product_id);
				
				priceInputs[index] = '';
			}

		}


		const add = (index) => {
			if(!priceInputs[index]){
				toast.error('Please enter the price before adding');
				return false;
			}

		  const updatedCounts = [...rowCounts];
    	  updatedCounts[index] += 1;
    	  setRowCounts(updatedCounts);
		  const updatedIsAddList = [...isAddList];
		  updatedIsAddList[index] = true;
		  setIsAddList(updatedIsAddList);
			const product_id = productList[index].id;
		  const rowData = {
    		  product_id: product_id,
    		  product_name: productList[index].product_name,
    		  quantity: updatedCounts[index],
    		  price: priceInputs[index], 
    		};
		  addSelectedRow(rowData);

		};
  return (
    <div>
       <div className="modal-body">
      		<div className="card shadow-sm bg-white mb-4 table-card">
               
            <div className="align-items-center justify-content-between TableSerachBar">
				
				<div className="justify-content-between align-items-center" style={{ gap: '10px' }}>
					<form className="navbar-search">
						<div className="input-group">
							<input
								type="text"
								className="form-control bg-white border"
								placeholder="Search"
								aria-label="Search"
								aria-describedby="basic-addon2"
								onChange={(e) => {
									searchData(e);
								}}
								value={searchField}
							/>
							<span className="searchIcon">
								<FaSearch />
							</span>
						</div>
					</form>
				</div>
			</div>
			<div className="table-responsive-xl tableData estimationList p-2">
				<Scroller extraHeight={44}>
					<table className="table">
						<thead>
							<tr className="sticky-header">
								<th className="align-middle" width={'5%'}>
									
								</th>
								<th scope="col" width={'40%'}>
									Name
								</th>
								<th scope="col" width={'15%'} className='text-center'>
									Free stock
								</th>
								<th scope="col" width={'15%'} className='text-center'>Sold Last 28 days</th>
								<th scope="col" width={'15%'} className='text-center'>Price 
								{currency === 'usd' ? ' ($)' : (currency === 'eur' ? ' (€)' : (currency === 'aed' ? ' (AED)' : ''))}</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							{isLoading ? (
								<>
									{[...Array(5)].map((e, i) => (
										<tr>
											<>
												{[...Array(9)].map((e, i) => (
													<td>
														<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
													</td>
												))}
											</>
										</tr>
									))}
								</>
							) : (
								<>
									
									{productList?.length > 0 &&
										productList?.map((item, index) => {
											return (
												<>
													<tr key={item?.id}>
														<td> <CiMobile3 /> </td>
														<td>{item?.product_name}</td>
														<td className='text-center'>{item?.FreeStock}</td>
														<td className='text-center'> {item?.Sold_last_28_days} </td>
														<td className='text-center cstm-flex'>
															<label htmlFor="" style={{margin: 'auto 0 auto 0'}}>{currency === 'usd' ? ' $' : (currency === 'eur' ? ' €' : (currency === 'aed' ? ' AED' : ''))}</label> 
														 <input type="text" 
															className="form-control bg-white border"
															style={{ maxWidth: '100px' , alignItems: 'left'}}
															placeholder={`${currency === 'usd' ? '$' : (currency === 'eur' ? '€' : (currency === 'aed' ? 'AED' : ''))} 55,230`}
															value={priceInputs[index] ? priceInputs[index] : item?.price ? item.price : ''}
															onChange={(e) => {
    														  const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
    														  updatePriceInput(index, cleanedValue);
    														}}
														/> </td>
														<td> 
															{isAddList[index] ? (
																<>
																 	<div className='btn-group'>
																		<button className='btn btn-default btn-sm btn-left' onClick={() => handleDecrease(index)}>
																			<FaMinus />
																		</button>
    																 		<input type='text' value={rowCounts[index]} className='product-amount-field'
																			onChange={(e) => {
																			    const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
																			    updaterowCounts(index,cleanedValue);
																			  }}
																			>
    																 		</input>
																	 	<button className='btn btn-default btn-sm btn-right' onClick={() => handleIncrease(index)}>
																			<FaPlus />
																		</button>
    											    
    																</div>
																</>
															): 
															(
																<>
																	<button className='btn btn-primary btn-outlined-main' onClick={() => add(index)}> <FaPlus /> Add</button>
																</>
															
															)}
														
														</td>
													</tr>
												</>
											);
										})}
									{isLoading && (
										<tr className="no-hover">
											<td colSpan={9}>
												<div className="not-found-container">
													<div className="not-found-icon">
														<TbDatabaseExclamation />
													</div>
													<h6 className="not-found-text">No Data Available</h6>
												</div>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</table>
				</Scroller>
				<Pagination pagination={pagination} data={handleData} onLoading={(e) => {}} />
			</div>
			
	  		</div>
	  </div>
	  <div className="section-footer">
			<div className="footer-left">
				<div className="produt-count">
					<label htmlFor=""> Products</label>
					<label>{totalQuantity}</label>
				</div>
				<div className="price">
					<label htmlFor=""> Price </label>
					<label>{currency === 'usd' ? ' $ ' : (currency === 'eur' ? ' € ' : (currency === 'aed' ? ' AED ' : ''))} {totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</label>
				</div>
			</div>
			<div className="footer-right">
				<button className="btn btn-primary btn-outlined-main" onClick={() => close(selectedRows)}>
					<FaCheck /> Done
				</button>
			</div>
		</div>
    </div>
  );
};

export default ProductList;
