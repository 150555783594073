import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import './Inbounding/inbounding.scss';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import useSound from 'use-sound';
import usePageTitle from 'hooks/usePageTitle';

import errorSound from '../../assets/audio/error.mp3';
import { CloseIcon, IdentifyIcon, ShareIcon } from 'ui-component/custom-icons/icons';

import Breadcrumbs from './components/Breadcrumbs';
import InboundingForm from './components/InboundingForm';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import Summary from './components/Summary';
import Logs from './components/Logs';
import QualityControlList from './components/QualityControlList';
import MoveToPicklistModal from './modals/MoveToPicklistModal';

function OperationQualityControlView({ edit }) {
    const { id } = useParams();
    const [setPageTitle] = usePageTitle('Operations');
    const [toggleCheckboxActionStatus, setToggleCheckboxActionStatus] = useState(false);
    const [openGradingModal, setOpenGradingModal] = useState(false)
    const [showMoveToPicklistModal, setShowMoveToPicklistModal] = useState(false);

    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);
    const history = useNavigate();
    const [errorSoundPlay] = useSound(errorSound);
    
    
    const [operationDetails, setOperationDetails] = useState({
        id: '',
        referenceNo: '',
        status: '',
        action: '',
        type: '',
        isCompleted: '',
        products: [],
        logs: []
    });

    const toggleCheckboxAction = (bool) => {
        setToggleCheckboxActionStatus(bool)
    }

    useEffect(() => {
        if (id) {
            initializeOperationDetails();
        }
    }, []);

    const initializeOperationDetails = () => {

        api.getOperationsDetails(id).then((res) => {
            if (res?.status) {
                const data = {
                    id: res?.data?.id,
                    referenceNo: res?.data?.reference_number,
                    action: 'view',
                    type: 'inbound',
                    isCompleted: res?.data?.is_completed,
                    products: res?.data?.items,
                    logs: res?.data?.logs
                }
                setOperationDetails(data);
            }
        });
    }




    const handleReloadData = () => {
        initializeOperationDetails();
    }
    const handleReloadDataFromPicklist = () => {
        initializeOperationDetails();
        setShowMoveToPicklistModal(false)
    }
    const triggerCheckboxAction = () => {
        setOpenGradingModal(true)
    }
    const closeGradingModal = () => {
        setOpenGradingModal(false)
    }

    const handleClose = () => {
        setShowMoveToPicklistModal(false)
    }

    const uncheckAllItems = () => {
        let items = operationDetails?.products.map((item) => {
            item.isChecked = false;
            return item;
        });
        setOperationDetails({
            ...operationDetails,
            products: items
        })
        toggleCheckboxAction(false)
    }

	return (
        <>
            
            <div className="row m-0 w-100" style={{ marginTop: '20px' }}>
                <div className="col-12 col-md-12">
                    <div className="section-head" style={{ paddingLeft: '0' }}>
                     
                        <h2>
                            <div className="actions" style={{ display: 'flex', width: '100%' }}>

                                <Breadcrumbs
                                    current={`qualitycontrol`}
                                    activateTabsUsingId={operationDetails?.id}
                                    referenceNumber={operationDetails?.referenceNo}
                                    isCompleted={operationDetails?.isCompleted ? true : false}
                                />

                            </div>
                        </h2>
                        <div className="actions">
                            {edit && (
                                <>
                                    {
                                        operationDetails?.isCompleted === 0 ? (
                                            <>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setShowMoveToPicklistModal(true)
                                                    }}
                                                    className="btn btn-primary btn-outlined-main"
                                                >
                                                    <ShareIcon width={'15px'} height={'15px'} />
                                                    Move to Picklist
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    type="button"
                                                    disabled={true}
                                                    className="btn btn-primary btn-outlined-main disabled"
                                                >
                                                    <ShareIcon width={'15px'} height={'15px'} />
                                                    Moved to Picklist
                                                </button>
                                            </>
                                        )
                                    }
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-lg-8 p-0 form-group" style={{ marginTop: '5px' }}>
                            <div className="card shadow-sm bg-white table-card p-3" style={{ marginTop: '0' }}>
                                <div className="bg-white pt-0" style={{  marginTop: '-15px' }}>
                              
                                    <div className="row">
                                        <div className="col-12">
                                            
                                            <QualityControlList
                                                operationDetails={operationDetails}
                                                handleReloadData={handleReloadData}
                                                handleShowCheckboxAction={toggleCheckboxAction}
                                                handleOpenGradingModal={openGradingModal}
                                                handleCloseGradingModal={closeGradingModal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-12 form-group">
                                    <Summary
                                        operationDetails={operationDetails}
                                    />
                                    <Logs
                                        logs={operationDetails?.logs}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                toggleCheckboxActionStatus && (
                    <>
                        <div className="custom-footer-popup"></div>
                        <div className="row">
                            <div className="col-12 checkbox-action-container">
                                <div className="checkbox-action" style={{width: '98%', position: 'absolute' }}>
                                    <div>
                                        Specify the grade of the output items
                                    </div>
                                    <PermissionAllowed permissions={['Operations Item Grading']}>
                                        {/* <Tooltip title={'Identify Grading'} arrow placement="top"> */}
                                            <button
                                                className="btn btn-primary btn-outlined-main white-var btn-icon-only btn-icon-square"
                                                onClick={() => {
                                                    triggerCheckboxAction()
                                                    // setOpenGradingPopup(true)
                                                }}
                                                // disabled={!areAnyItemsChecked()}
                                            >
                                                <IdentifyIcon width={'16px'} height={'16px'} /> Set Grade 
                                            </button>
                                        {/* </Tooltip> */}
                                    </PermissionAllowed>
                                    <div 
                                        style={{ position: 'absolute', right: '16px' }}
                                        onClick={() => { 
                                            uncheckAllItems()
                                        }}
                                        className="cursor-pointer"
                                    >
                                            <CloseIcon height={'16px'} width={'16px'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            {showMoveToPicklistModal && (
                <>
                    <MoveToPicklistModal
                        openPopup={showMoveToPicklistModal}
                        details={operationDetails}
                        // selectedProduct={selectedProduct}
                        handleClose={handleClose}
                        handleReloadDataFromPicklist={handleReloadDataFromPicklist}
                        // handleAddScannedSerials={handleAddScannedSerials}
                        // disableStatus={isDisable}
                    />
                </>
            )}
		</>
	);
}

export default OperationQualityControlView;
