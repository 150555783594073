import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import useSound from 'use-sound';
import errorSound from '../../../../assets/audio/error.mp3';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiPackageBold, PiTestTubeFill, PiWarningFill } from 'react-icons/pi';
import { BsCalendar2Date } from 'react-icons/bs';
import { DateRangePicker } from 'react-date-range';

const ReturnsReportPopupModal = ({ openPopup, handleClose, disableStatus }) => {

	const [alertMessage, setAlertMessage] = useState();
	const columnRef = useRef(null);
	const dateRef = useRef(null);
	const [imeiReplacementInputValue, setImeiReplacementInputValue] = useState('');
	const [modelImeis, setModelImeis] = useState([]);
	const [stockImeis, setStockImeis] = useState([]);
	const [isDateSelected, setIsDateSelected] = useState(false);
	const imeiInputRef = useRef(null);
	const [errorSoundPlay] = useSound(errorSound);
	const [selectedRepairs, setSelectedRepairs] = useState('');
	const [confirmDisabled, setConfirmDisabled] = useState(true);
	const [showPicker, setShowPicker] = useState(false);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [focusedInput, setFocusedInput] = useState(null);

	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const reportTypes = [
		{ label: 'Most returned items', value: 1 },
		{ label: 'Most returns from the supplier', value: 2 },
		{ label: 'Most return from a customer', value: 3 },
	];

	const returnReasons = [
		{ label: 'Wrong product', value: 1 },
		{ label: 'Defective', value: 2 },
		{ label: 'Unknown', value: 3 },
		{ label: 'Wrong size', value: 4 },
	];

	const [reportResult, setReportResult] = useState([]);

	const [reportFilterDetails, setReportFilterDetails] = useState({
		reportType: null,
		startDate: null,
		endDate: null,
	});

	const [formInfo, setFormInfo] = useState({
		estimationId: '',
	});


	const handleSubmitReport = () => {
		// setReportResult([]);
		getReportResult();
	};



	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const handleDeleteImei = (index) => {
		const newStockImeis = stockImeis.filter((_, i) => i !== index);
		setStockImeis(newStockImeis);
	}

	const handleSetSelectedRepairs = (selected, index) => {
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, repairs: selected };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
		// console.log(repairs)
	}

	const handleRepairCost = (value, index) => {
		const cost = value.target.value;
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, cost: cost };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
	}

	const getReportResult = async () => {
		api.getReportResult(reportFilterDetails).then((res) => {
			if (res?.status) {
				setReportResult(res?.data);
			}
			// 	const tempImei = res?.data;
			// 	tempImei.is_repair = false;
			// 	// setStockImeis([...stockImeis, tempImei]);
			// 	setStockImeis([tempImei]);
			// 	setImeiReplacementInputValue('');
			// 	imeiInputRef.current.value = '';
			// } else {
			// 	showErrorDialog('Invalid IMEI', `Couldn't find IMEI ${imeiReplacementInputValue} from the selected order`);
			// 	setImeiReplacementInputValue('');
			// 	imeiInputRef.current.value = '';
			// 	errorSoundPlay();
			// }
		});
		return false;
		// if (!imeiReplacementInputValue || !imeiReplacementInputValue.trim()) {
		// 	showErrorDialog('Invalid IMEI', `Invalid IMEI Entered`);
		// 	setImeiReplacementInputValue('');
		// 	imeiInputRef.current.value = '';
		// 	errorSoundPlay();
		// 	return;
		// }

		// const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

		// if (!alphanumericRegex.test(imeiReplacementInputValue)) {
		// 	showErrorDialog('Invalid IMEI', `Please enter a valid IMEI Number`);
		// 	setImeiReplacementInputValue('');
		// 	imeiInputRef.current.value = '';
		// 	errorSoundPlay();
		// 	return;
		// }
		// api.getReturnsImei({
		// 	imei: imeiReplacementInputValue
		// }).then((res) => {
		// 	if (res?.status) {
		// 		const tempImei = res?.data;
		// 		tempImei.is_repair = false;
		// 		// setStockImeis([...stockImeis, tempImei]);
		// 		setStockImeis([tempImei]);
		// 		setImeiReplacementInputValue('');
		// 		imeiInputRef.current.value = '';
		// 	} else {
		// 		showErrorDialog('Invalid IMEI', `Couldn't find IMEI ${imeiReplacementInputValue} from the selected order`);
		// 		setImeiReplacementInputValue('');
		// 		imeiInputRef.current.value = '';
		// 		errorSoundPlay();
		// 	}
		// });
	};


	const handleReplacementPrice = (value, index) => {
		const price = value.target.value;
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				// return { ...item, product?.price: price };
				return { ...item, product: { ...item.product, price: price } };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
	}

	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);

	const handleDatesChange = (ranges) => {
		// console.log(ranges.selection)
		// // setDateRange([ranges.selection]);
		setStartDate(ranges.selection?.startDate);
		setEndDate(ranges.selection?.endDate);
		setReportFilterDetails({ ...reportFilterDetails, startDate: ranges.selection?.startDate, endDate: ranges.selection?.endDate })
		setDateRange([ranges.selection]);
		setIsDateSelected(true)

		// // If both dates are selected, close the date picker
		if (ranges.selection?.startDate && ranges.selection?.endDate) {
			setFocusedInput(null);
		}

	};
	// const handleDatesChange = (ranges) => {
	// 	setDateRange([ranges.selection]);
	// };


	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dateRef.current && !dateRef.current.contains(event.target)) {
				setShowPicker(false);
			}
			// if (columnRef.current && !columnRef.current.contains(event.target)) {
			// 	setShowColumns(false);
			// }
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);
	const handleDateRangeClick = () => {
		setShowPicker(!showPicker);
	};

	const disablepopup = () => {
		handleClose();
	};
	return (
		<>
			<Modal show={openPopup} onHide={disablepopup} centered size="md" backdrop="static" >
				<Modal.Body>
					<div className="returns-popup-body">
						<div className="foundListPopup">
							<div className="mb-2 ml-0 pl-0">

								<div className="row">
									<div className="col-12 text-center" >
										<svg xmlns="http://www.w3.org/2000/svg" width="55.632" height="60.689" viewBox="0 0 55.632 60.689">
											<path id="file-medical-alt" d="M16.437,40.46H2.529a2.529,2.529,0,1,1,0-5.057H15.084l4.306-6.461a2.5,2.5,0,0,1,2.261-1.12,2.532,2.532,0,0,1,2.106,1.393L29.394,40.49l2.64-3.96a2.532,2.532,0,0,1,2.1-1.125H45.517a2.529,2.529,0,1,1,0,5.057H35.491l-4.306,6.461a2.529,2.529,0,0,1-2.1,1.125c-.053,0-.106,0-.157-.005a2.532,2.532,0,0,1-2.106-1.393L21.181,35.374l-2.64,3.96a2.532,2.532,0,0,1-2.1,1.125Zm39.2-13.946V48.046A12.658,12.658,0,0,1,42.988,60.689H17.7A12.658,12.658,0,0,1,5.057,48.046a2.529,2.529,0,1,1,5.057,0A7.6,7.6,0,0,0,17.7,55.632H42.988a7.6,7.6,0,0,0,7.586-7.586V26.514c0-.412-.02-.822-.058-1.226H37.931A7.6,7.6,0,0,1,30.345,17.7V5.116c-.4-.038-.814-.058-1.226-.058H17.7a7.6,7.6,0,0,0-7.586,7.586V27.816a2.529,2.529,0,0,1-5.057,0V12.644A12.658,12.658,0,0,1,17.7,0H29.118A17.59,17.59,0,0,1,41.635,5.184L50.448,14a17.586,17.586,0,0,1,5.184,12.517ZM48.908,20.23a12.658,12.658,0,0,0-2.036-2.655L38.06,8.762A12.723,12.723,0,0,0,35.4,6.726V17.7a2.533,2.533,0,0,0,2.529,2.529H48.911Z" />
										</svg>



									</div>
								</div>
								<div className="row">
									<div className="col-12 text-center" style={{ margin: '15px 0' }}>
										<h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0 font22">
											Return reports
										</h5>
									</div>
								</div>
								<button className="btn modal-close-icon" onClick={handleClose}>

									<svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
										<path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
									</svg>
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-12">
									<div className="row">
										<div className="form-group col-md-12">

											<Select
												menuPosition={'fixed'}
												options={reportTypes}
												// value={contactTypes?.find((y) => y?.value == contactInfo?.contactType)}
												className="rs-container"
												classNamePrefix="rs"
												placeholder="Select Report Type"
												onChange={(e) => {
													setReportResult([]);
													setReportFilterDetails({ ...reportFilterDetails, reportType: e.value });
												}}
											/>
											{/* <div className="input-icon">
												<input
													ref={imeiInputRef}
													type="text"
													id="input-example"
													name="input-name"
													className="form-control"
													minLength={15}
													maxLength={16}
                                                    placeholder={"Enter device serial number"}
													onChange={(e) => {
														setImeiReplacementInputValue(event.target.value);
														if (event.target.value.length > 0) {
															setConfirmDisabled(false);
														} else {
															setConfirmDisabled(true);
														}
													}}
													onKeyDown={(e) => {
														handleIMEIInputKeydown(e);
													}}
													style={{ padding: '1.375rem .75rem' }}
													autoComplete={'off'}
													autoFocus
												/>
												<div className="icon">
													<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-miterlimit="10" stroke-width="32" d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"></path><path fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448"></path></svg>
												</div>
											</div> */}
										</div>
									</div>
									<div className="row">
										<div className="col-12 form-group">
											{/* <DateRangePicker ranges={dateRange} onChange={handleDatesChange} className='date-range-picker'/> */}
											{/* <DateRangePicker placeholder="Select Date Range" /> */}
											<div style={{ display: 'flex' }}>
												<div style={{ width: '100%' }}>

													<button className="btn btn-primary btn-outlined-main white-var btn-date" onClick={handleDateRangeClick} style={{ width: '100%', padding: '8px' }}>
														{
															isDateSelected ? (
																<>
																	{dateRange[0].startDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })} -{' '}
																	{dateRange[0].endDate ? dateRange[0].endDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : 'Select an end date'}
																	{/* <BsCalendar2Date /> */}
																	<div className="" style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
																		<svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
																			<path id="Path_1936" data-name="Path 1936" d="M19,2H18V1a1,1,0,1,0-2,0V2H8V1A1,1,0,1,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z" fill="currentColor" />
																			<circle id="Ellipse_49" data-name="Ellipse 49" cx="1.5" cy="1.5" r="1.5" transform="translate(10.5 13.5)" fill="currentColor" />
																			<circle id="Ellipse_50" data-name="Ellipse 50" cx="1.5" cy="1.5" r="1.5" transform="translate(5.5 13.5)" fill="currentColor" />
																			<circle id="Ellipse_51" data-name="Ellipse 51" cx="1.5" cy="1.5" r="1.5" transform="translate(15.5 13.5)" fill="currentColor" />
																		</svg>

																	</div>
																</>
															) : (
																<>
																	<div style={{ display: 'flex', justifyContent: 'left', width: '100%', color: '#b8b8b8' }}>
																		Select Period
																	</div>
																</>
															)
														}
													</button>
												</div>
												{/* <div className="">

																<button className="btn btn-main"
																	onClick={ () => {
																	setShowPicker(false)
																}}>
																	Select Date
																</button>
												</div> */}
											</div>

											{showPicker && (
												<>
													<div ref={dateRef} className="returns-daterangepicker-container">
														{/* <DateRangePicker 
															ranges={dateRange} 
															onChange={handleDatesChange} 
															className="date-range-picker" 
														/> */}
														<DateRangePicker
															ranges={dateRange}
															// onDatesChange={handleDatesChange}
															onChange={handleDatesChange}
														// focusedInput={focusedInput}
														// onFocusChange={focusedInput => setFocusedInput(focusedInput)}
														/>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							{
								stockImeis?.length < 1 ? (
									<>
										<div className="row">
											<div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'center' }}>

												<button
													disabled={(reportFilterDetails?.reportType && reportFilterDetails?.startDate && reportFilterDetails?.endDate) ? false : true}
													onClick={() => handleSubmitReport()}
													className="btn btn-main btn-with-disabled"
													style={{ margin: '10px' }}>
													<span style={{ padding: '0 35px' }}>Submit</span>
												</button>
											</div>
										</div>
									</>
								) : (
									<>
										<div className="row">
											<div className="col-12 text-center" style={{ margnTop: '15px' }}>

												<button onClick={() => handleClose()} className="btn btn-main btn-with-disabled">
													Back to returns
												</button>
											</div>
										</div>
									</>
								)
							}
						</div>
						{
							reportResult?.length > 0 && (
								<>
									<div className="row">
										<div className="col-12">
											<hr />
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-12">
											{
												reportFilterDetails?.reportType == 1 ? (
													<>
														<table className="table pm-table">
															<thead>
																<tr>
																	<th width="70%">Product</th>
																	<th width="30%">Return counts</th>
																</tr>
															</thead>
															<tbody>
																{
																	reportResult?.map((item, index) => {
																		return (
																			<tr>
																				<td>
																					<Link to={`/products/${item.product_id}`} target='_blank'>
																						{item.product_name}
																					</Link>
																				</td>
																				<td className="text-center">{item?.count}</td>
																			</tr>
																		);
																	})
																}
															</tbody>
														</table>
													</>
												) : reportFilterDetails?.reportType == 2 ? (
													<>
														<div className="row">
															<div className="col-5" style={{ fontSize: '13px', fontWeight: '700' }}>Supplier</div>
															<div className="col-5" style={{ fontSize: '13px', fontWeight: '700' }}>Product</div>
															<div className="col-2" style={{ fontSize: '13px', fontWeight: '700' }}>Counts</div>
														</div>
														<hr />
														<div style={{ marginTop: '-10px' }}>
															{

																reportResult?.map((returnItem, index) => {
																	return (
																		<>
																			<div className="row" style={{ borderBottom: '1px solid #0000001a', padding: '10px 0px' }}>
																				<div className="col-5" >

																					<Link to={`/contacts/${returnItem.supplier_id}`} target='_blank'>
																						{returnItem.supplier_name}
																					</Link>

																				</div>
																				<div className="col-7">
																					{
																						returnItem?.items?.map((item, indexItem) => {
																							return (
																								<>
																									<div className="row">
																										<div className="col-8">

																											<Link to={`/products/${item.product_id}`} target='_blank'>
																												{item.product_name}
																											</Link>
																										</div>
																										<div className="col-4 text-center">

																											{item.count}
																										</div>
																									</div>
																								</>
																							)
																						})
																					}
																				</div>
																			</div>
																		</>
																	);
																})
															}
														</div>
													</>
												) : (
													<>
														<div className="row">
															<div className="col-5" style={{ fontSize: '13px', fontWeight: '700' }}>Customer</div>
															<div className="col-5" style={{ fontSize: '13px', fontWeight: '700' }}>Product</div>
															<div className="col-2" style={{ fontSize: '13px', fontWeight: '700' }}>Counts</div>
														</div>
														<hr />
														<div style={{ marginTop: '-10px' }}>
															{

																reportResult?.map((returnItem, index) => {
																	return (
																		<>
																			<div className="row" style={{ borderBottom: '1px solid #0000001a', padding: '10px 0px' }}>
																				<div className="col-5" >

																					<Link to={`/contacts/${returnItem.customer_id}`} target='_blank'>
																						{returnItem.customer_name}
																					</Link>

																				</div>
																				<div className="col-7">
																					{
																						returnItem?.items?.map((item, indexItem) => {
																							return (
																								<>
																									<div className="row">
																										<div className="col-8">

																											<Link to={`/products/${item.product_id}`} target='_blank'>
																												{item.product_name}
																											</Link>
																										</div>
																										<div className="col-4 text-center">

																											{item.count}
																										</div>
																									</div>
																								</>
																							)
																						})
																					}
																				</div>
																			</div>
																		</>
																	);
																})
															}
														</div>
													</>
												)

											}
										</div>
									</div>
								</>
							)
						}
					</div>
				</Modal.Body>
			</Modal>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}
		</>
	);
};

export default ReturnsReportPopupModal;
