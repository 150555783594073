import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IoSearchOutline } from 'react-icons/io5';
import images from './iphone-15-finish-select-202309-6-7inch-green.png';
import { toast } from 'react-hot-toast';
import NoImage from 'assets/images/no-image.png';

const CheckWarranty = () => {
	const history = useNavigate();
	const [coverages, setCoverages] = useState(null);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	const clientUser = useSelector((state) => state.main.client);
	const [selectedImei, setSelectedImei] = useState('');
	const [data, setData] = useState(null);
	const searchImei = () => {
		setCoverages(true);
	};
	const backToOrders = () => {
		history('orders');
	};
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const checkCoverage = async () => {
		api
			.getReturnsImeiClient({
				client_id: clientUser.data.contact_id,
				imei: selectedImei
			})
			.then((res) => {
				if (res.status == true) {
					setData(res.data);
				} else {
					toast.error('No data found');
				}
			});
	};

	console.log(data);

	console.log(selectedImei);
	return (
		<div
			className={`main-page-client ${isMobile ? 'padding-mobile-client' : ''}`}
			style={{ paddingBottom: 10, height: isMobile ? '100%' : '100%' }}
		>
			{isMobile && (
				<div className="approved-mobile">
					<div className="verified-mobile">
						<svg xmlns="http://www.w3.org/2000/svg" width="21.996" height="21.995" viewBox="0 0 59.992 61.271">
							<path
								id="features-alt"
								d="M55.261,14.148l-8.9-8.9A17.746,17.746,0,0,0,33.727.015H22.2A12.766,12.766,0,0,0,9.852,9.543a2.552,2.552,0,1,0,4.937,1.292A7.664,7.664,0,0,1,22.2,5.121H33.727c.421,0,.825.082,1.238.12V17.886a7.668,7.668,0,0,0,7.659,7.659h12.64c.041.414.125.822.125,1.238V48.521A7.668,7.668,0,0,1,47.73,56.18H22.2A7.593,7.593,0,0,1,17.413,54.5a2.553,2.553,0,1,0-3.194,3.983,12.8,12.8,0,0,0,7.978,2.8h25.53A12.779,12.779,0,0,0,60.492,48.521V26.783a17.753,17.753,0,0,0-5.234-12.635ZM42.624,20.439a2.556,2.556,0,0,1-2.553-2.553V6.865a12.723,12.723,0,0,1,2.681,1.994l8.9,8.9a12.624,12.624,0,0,1,1.981,2.681H42.621ZM24.753,40.824H45.177a2.553,2.553,0,1,1,0,5.106H24.753a2.553,2.553,0,1,1,0-5.106Zm-7.659,7.268V35.966A10.191,10.191,0,1,0,.5,28.059a10.155,10.155,0,0,0,3.829,7.907V48.092A1.663,1.663,0,0,0,7.314,49.1l3.4-4.45,3.4,4.45A1.663,1.663,0,0,0,17.094,48.092ZM10.712,22.953a5.106,5.106,0,1,1-5.106,5.106A5.111,5.111,0,0,1,10.712,22.953ZM27.306,35.718a2.553,2.553,0,1,1,0-5.106H45.177a2.553,2.553,0,1,1,0,5.106Z"
								transform="translate(-0.5 -0.015)"
							/>
						</svg>
						<span style={{ marginLeft: 5, fontSize: 16 }}>Check Warranty</span>
					</div>
				</div>
			)}
			<div
				className={`${isMobile ? 'mobile-model-client-width' : 'company-details-client-content-3'}`}
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: isMobile ? '90%' : 'fit-content'
				}}
			>
				<div className="search-imei-div">
					<svg xmlns="http://www.w3.org/2000/svg" width="59.992" height="61.271" viewBox="0 0 59.992 61.271">
						<path
							id="features-alt"
							d="M55.261,14.148l-8.9-8.9A17.746,17.746,0,0,0,33.727.015H22.2A12.766,12.766,0,0,0,9.852,9.543a2.552,2.552,0,1,0,4.937,1.292A7.664,7.664,0,0,1,22.2,5.121H33.727c.421,0,.825.082,1.238.12V17.886a7.668,7.668,0,0,0,7.659,7.659h12.64c.041.414.125.822.125,1.238V48.521A7.668,7.668,0,0,1,47.73,56.18H22.2A7.593,7.593,0,0,1,17.413,54.5a2.553,2.553,0,1,0-3.194,3.983,12.8,12.8,0,0,0,7.978,2.8h25.53A12.779,12.779,0,0,0,60.492,48.521V26.783a17.753,17.753,0,0,0-5.234-12.635ZM42.624,20.439a2.556,2.556,0,0,1-2.553-2.553V6.865a12.723,12.723,0,0,1,2.681,1.994l8.9,8.9a12.624,12.624,0,0,1,1.981,2.681H42.621ZM24.753,40.824H45.177a2.553,2.553,0,1,1,0,5.106H24.753a2.553,2.553,0,1,1,0-5.106Zm-7.659,7.268V35.966A10.191,10.191,0,1,0,.5,28.059a10.155,10.155,0,0,0,3.829,7.907V48.092A1.663,1.663,0,0,0,7.314,49.1l3.4-4.45,3.4,4.45A1.663,1.663,0,0,0,17.094,48.092ZM10.712,22.953a5.106,5.106,0,1,1-5.106,5.106A5.111,5.111,0,0,1,10.712,22.953ZM27.306,35.718a2.553,2.553,0,1,1,0-5.106H45.177a2.553,2.553,0,1,1,0,5.106Z"
							transform="translate(-0.5 -0.015)"
						/>
					</svg>
					<h3 style={{ marginBottom: 10 }}>Check your device’s coverage</h3>
					<div className="search-imei">
						<div className="input-search">
							<input
								type="text"
								className="form-control"
								placeholder="Enter IMEI number"
								onChange={(e) => {
									setSelectedImei(e.target.value);
								}}
							/>
							<IoSearchOutline />
						</div>
						{data && (
							<div className="row">
								<div className="form-group col-md-12" style={{ marginBottom: 0 }}>
									<table className="table " style={{ marginBottom: 0 }}>
										<tbody>
											<tr>
												<td className="text-center" width="8%">
													{data?.product?.image == 'default_thumbnail_url' ? (
														<img style={{ height: '80px' }} src={NoImage} />
													) : (
														<img style={{ height: '80px' }} src={data?.product?.data} />
													)}
												</td>
												<td className="font14 d-flex justify-content-center align-items-center">
													<div className="row">
														<div className="col-12">
															<div className="row">
																<div className="col-3" style={{ fontWeight: '500', color: '#919191', textAlign: 'left', paddingLeft: 0 }}>
																	IMEI
																</div>

																<div className="col-9" style={{ fontWeight: '500', textAlign: 'left' }}>
																	{data?.imei}
																</div>
															</div>
															<div className="row">
																<div className="col-3" style={{ fontWeight: '500', color: '#919191', textAlign: 'left', paddingLeft: 0 }}>
																	Model
																</div>
																<div className="col-9" style={{ fontWeight: '500', textAlign: 'left', whiteSpace: 'nowrap' }}>
																	{data?.product.product_name}
																</div>
															</div>
															<div className="row">
																<div className="col-3" style={{ fontWeight: '500', color: '#919191', textAlign: 'left', paddingLeft: 0 }}>
																	Warranty
																</div>
																<div className="col-9" style={{ fontWeight: '500', textAlign: 'left' }}>
																	<span className={data?.warranty?.status == 'Valid' ? 'text-success' : 'text-danger'}>
																		{data?.warranty?.message}
																	</span>
																</div>
															</div>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						)}
						<button onClick={checkCoverage}> {coverages == null ? 'Search' : 'Back to order'} </button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckWarranty;
