import React, { useEffect, useState } from 'react';
import assignbyIcon from 'assets/images/icons/assign-by.svg';
import calendarIcon from 'assets/images/icons/feather-calendar.svg';
import rejectIcon from 'assets/images/icons/reject.svg';
import inspactIcon from 'assets/images/icons/inspact.svg';
import checkIcon from 'assets/images/icons/check-green.svg';
import closetIcon from 'assets/images/icons/close-red.svg';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { storeInLocalStore, setstateCollectionList, setMessageData } from 'store/reducers/mainSlice';
import Scroller from 'ui-component/Scroller';
import { getStatusColor } from 'views/Operations/TaskGrid/Task';
import { Avatar } from '@mui/material';
import { CgPlayListCheck } from 'react-icons/cg';
import toast from 'react-hot-toast';
import ProcessIndicator from 'views/Operations/ProcessIndicator';

import { useSearchParams } from 'react-router-dom';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { taskboardProgresses } from 'assets/helperdata/taskboard-progrss';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import moment from 'moment';
import { PiInfoFill, PiArchiveBoxFill, PiCheckBold } from 'react-icons/pi';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Tooltip } from '@mui/material';

const CollectionDetails = () => {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const selectedId = useSelector((state) => state.main.user.selectedtaskid);
	const [isLoading, setLoading] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [inspectInfo, setInspectInfo] = useState();
	const [dueDate, setDueDate] = useState();
	const [startDate, setStartDate] = useState();
	const [isInProgress, setIsInProgress] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});
	const [collectionItemRow, setCollectionItemRow] = useState({
		task_collection_details: []
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const showConfirmationDialog = (title, message = '', onConfirm = () => {}, onClose = () => {}) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	const getCollection_details = (callback = () => {}) => {
		setLoading(true);
		setIsDisable(true);
		api.getcollectiondetailsbyid(id).then((res) => {
			setLoading(false);
			setIsDisable(false);
			setInspectInfo(res.data);
			let duedate = moment(res.data.due_date).format('DD MMM HH:mm');
			let startdate = moment(res.data.created_at).format('DD MMM HH:mm');
			setDueDate(duedate);
			setStartDate(startdate);
			callback(res.data);
		});
	};

	useEffect(() => {
		if (id) {
			getCollection_details((data) => {
				setIsInProgress(!!data?.start_at);
				if (!data?.start_at) {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Start Collection?',
							'Are you sure you want to start collection without looking at its details?',
							() => {
								handleStart();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				} else {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Complete Collection?',
							'Are you sure you want to complete collection without looking at its details?',
							() => {
								handleSubmit();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const onStartClick = () => {
		showConfirmationDialog(
			'Start Collection?',
			'Are you sure you want to start this Collection?',
			() => {
				handleStart();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleStart = () => {
		setIsDisable(true);
		toast.promise(
			api.startCollection(id).then(
				(res) => {
					if (res.status) {
						setIsInProgress(true);
					}

					setIsDisable(false);
					return res?.status ? res : null;
				},
				() => {
					setIsDisable(false);
				}
			),
			{
				loading: 'Starting Collection...',
				success: (data) => `${data.message}`,
				error: <b>Failed to start collection</b>
			}
		);
	};

	const onSubmitClick = () => {
		showConfirmationDialog(
			'Complete Collection?',
			'Are you sure you want to complete this Collection?',
			() => {
				handleSubmit();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleItem = (itemId) => {
		showConfirmationDialog(
			'Collect Item?',
			'Are you sure you want to select this item to collect?',
			() => {
				handleInboundItemSubmit(itemId);
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleInboundItemSubmit = (itemId) => {
		setIsDisable(true);
		toast.promise(
			api
				.collectItem(id, {
					item_id: itemId
				})
				.then(
					(res) => {
						if (res.status) {
							setLoading(true);
							setIsDisable(true);
							api.getcollectiondetailsbyid(id).then((res) => {
								setLoading(false);
								setIsDisable(false);
								setInspectInfo(res.data);
								let duedate = moment(res.data.due_date).format('DD MMM HH:mm');
								let startdate = moment(res.data.created_at).format('DD MMM HH:mm');
								setDueDate(duedate);
								setStartDate(startdate);
							});
						}
						return res?.status ? res : null;
					},
					() => {
						setIsDisable(false);
					}
				),
			{
				loading: 'Collecting...',
				success: (data) => `${data.message}`,
				error: <b>Failed to collect item</b>
			}
		);
	};

	const handleSubmit = () => {
		setIsDisable(true);
		const data = { task_collection_id: id, action_type: 'submit' };
		toast.promise(
			api.savecollectionData(data).then((res) => {
				if (res.status) {
					setIsDisable(false);
					history('/operations/collection');
				}
				return res?.status ? res : null;
			}),
			{
				loading: 'Saving Collection...',
				success: (data) => `${data.message}`,
				error: <b>Failed to save collection</b>
			}
		);
	};
	const goback = () => {
		history('/operations/collection');
	};
	return (
		<>
			<ProcessIndicator isInProgress={isInProgress} processes={taskboardProgresses} currentIndex={1} />
			<div className={`TaskDetails`}>
				<div className="d-flex justify-content-between mb-0">
					<div className="topBtnSign" style={{ '--statusColor': getStatusColor(inspectInfo?.priority_status?.color) }}>
						<span>{inspectInfo?.priority_status?.label}</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card p-4">
							<Scroller>
								<div className="row mb-5">
									<div className="col-12 d-flex justify-content-between">
										<h3 className="font-weight-bold mb-4">{inspectInfo?.supplier_name}</h3>
										<div
											className={`d-flex align-items-center justify-content-end datediv mb-4`}
											style={{ '--statusColor': getStatusColor(inspectInfo?.priority_status?.color) }}
										>
											<div className="boxDate">{moment(inspectInfo?.due_date).format('DD MMM HH:mm')}</div>
										</div>
									</div>
									<div className="col-md-6 mb-2 mb-md-0">
										<div className="row">
											<div className="col-md-6 mb-2 mb-md-0 detail-item">
												<p className="detail-label">Assigned To</p>
												<div className="detail-value">
													<div
														className="task-assignee"
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '5px',
															fontSize: '12px'
														}}
													>
														<Avatar
															sx={{ width: 25, height: 25 }}
															style={{ fontSize: 11 }}
															// src={getRandomImage()}
														>
															{inspectInfo?.assigned_to_user?.split(' ')?.[0]?.slice(0, 1)}
															{inspectInfo?.assigned_to_user?.split(' ')?.[1]?.slice(0, 1)}
														</Avatar>
														<span>{inspectInfo?.assigned_to_user}</span>
													</div>
												</div>
											</div>

											<div className=" col-md-6 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Assigned By</p>
												<div className="detail-value">
													<div
														className="task-assignee"
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '5px',
															fontSize: '12px'
														}}
													>
														<Avatar
															sx={{ width: 25, height: 25 }}
															style={{ fontSize: 11 }}
															// src={getRandomImage()}
														>
															{inspectInfo?.assigned_by_user?.split(' ')?.[0]?.slice(0, 1)}
															{inspectInfo?.assigned_by_user?.split(' ')?.[1]?.slice(0, 1)}
														</Avatar>
														<span>{inspectInfo?.assigned_by_user}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6 mb-2 mb-md-0">
										<div className="row">
											<div className="col-md-6 mb-2 mb-md-0 detail-item">
												<p className="detail-label" style={{ fontSize: '11px' }}>
													Assigned On
												</p>
												<div className="detail-value">
													{moment(inspectInfo?.created_at).format('DD MMM YYYY hh:mm A')}
												</div>
											</div>

											<div className="col-md-6 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label" style={{ fontSize: '11px' }}>
													Quantity
												</p>
												<div className="detail-value">{inspectInfo?.total_qty} Pcs</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<h5 className="font-weight-bold mb-4">
											<CgPlayListCheck fontSize={30} /> To Collect
										</h5>
										<div className="product-list-box">
											<table className="table-sm table-borderless" width="100%">
												<thead>
													<tr>
														<th>Invoice</th>
														<th>Estimation</th>
														<th>Customer</th>
														<th>Product</th>
														<th>Quantity</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{inspectInfo?.collection_details.length > 0 &&
														inspectInfo?.collection_details.map((item) => {
															return (
																<>
																	<tr>
																		<td className="align-middle font14">
																			{item?.invoice_number || '-'}
																		</td>
																		<td className="align-middle font14">
																			{item?.estimation_number || '-'}
																		</td>
																		<td className="align-middle font14">
																			<PrivacyModeContent>
																				{item?.customer_name || '-'}
																			</PrivacyModeContent>
																		</td>
																		<td className="align-middle font14">{item?.product_name}</td>
																		<td className="align-middle font14">{item?.collect_qty} Pcs</td>
																		<td style={{ width: '15px' }}>
																			{item?.is_collected == 0 &&
																			inspectInfo?.priority_status?.value != 'completed' ? (
																				<Tooltip title="Collect Item" arrow placement="top">
																					<span
																						onClick={() => {
																							if (isInProgress) {
																								handleItem(item?.id);
																							}
																						}}
																						className="btnEdit plain-iconbtn"
																					>
																						<PiCheckBold />
																					</span>
																				</Tooltip>
																			) : (
																				<Tooltip title="Collected" arrow placement="top">
																					<span
																						className="btnEdit plain-iconbtn"
																						style={{ background: '#cecece' }}
																					>
																						<PiCheckBold />
																					</span>
																				</Tooltip>
																			)}
																		</td>
																	</tr>
																</>
															);
														})}
												</tbody>
											</table>
										</div>
									</div>
									{inspectInfo?.has_rejected_models > 0 && (
										<div className="col-md-6">
											<h5 className="font-weight-bold mb-4">
												<img src={rejectIcon} className="mr-1" alt="" /> Rejected
											</h5>
											<div className="p-3 rounded border">
												<table className="table-sm table-borderless" width="100%">
													<tbody>
														{inspectInfo?.collection_details.length > 0 &&
															inspectInfo?.collection_details.map((item) => {
																let model_name =
																	item?.master_model?.name.length > 8
																		? item?.master_model?.name.slice(0, 16) + '...'
																		: item?.master_model?.name;
																return (
																	<>
																		<tr>
																			<td width="30px" className="align-middle">
																				<img src={checkIcon} className="mr-2" alt="" />
																			</td>
																			<td width="210px" className="align-middle">
																				EST-20232023
																			</td>
																			<td width="210px" className="align-middle">
																				{model_name}
																			</td>
																			<td className="align-middle">
																				{item?.master_model?.storage?.name}
																			</td>
																			<td className="align-middle">
																				{item?.master_model?.grade?.name}
																			</td>
																			<td className="align-middle">{item?.rejected_qty}</td>
																		</tr>
																	</>
																);
															})}
													</tbody>
												</table>
											</div>
										</div>
									)}
								</div>
							</Scroller>
							<div className="btnarea">
								<span
									onClick={() => {
										goback();
									}}
									className={`btn btn-link ${isDisable ? 'disabled' : ''}`}
								>
									Back
								</span>
								<PermissionAllowed permissions={['Start / End Task Collection (Action)']}>
									{inspectInfo?.priority_status?.value != 'completed' &&
										(isInProgress ? (
											<span
												onClick={() => {
													onSubmitClick();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Done
											</span>
										) : (
											<span
												onClick={() => {
													onStartClick();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Start
											</span>
										))}
								</PermissionAllowed>
							</div>
						</div>
					</div>
				</div>
			</div>
			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}
		</>
	);
};

export default CollectionDetails;
