import React from 'react';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Pagination from 'ui-component/Pagination';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Loader from 'ui-component/Loader';
import { useSelector } from 'react-redux';
import { FaSearch, FaTimesCircle } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { CgSearchFound } from 'react-icons/cg';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Skeleton, Tooltip } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { calculateRateValue, numberFormatter } from 'utils/currency';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { Link } from 'react-router-dom';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import moment from 'moment';
import { LiaCommentSolid } from 'react-icons/lia';
import SortableHeader from 'ui-component/SortableHeader';
import PLCards from './PLCards';

const SearchingTable = ({
	tableHeaderStyles,
	purchaseList,
	isLoading,
	selectedIds,
	handleSelectAll,
	handleSelectRow,
	onFoundData,
	viewEstimateDetails,
	searchData,
	searchField,
	activeFilters,
	setActiveFilters,
	filters,
	onCancelData,
	privacyMode,
	sortByFilter,
	updateSortByStatus
}) => {
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);

	return (
		<>
			<div className="d-flex align-items-center justify-content-between TableSerachBar">
				<div>
					<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
				</div>
				<div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
					{/* {selectedIds?.length > 0 ? (
						<> */}
					<div className={`bulk-actions ${selectedIds?.length > 0 ? 'show-mobile' : ''}`}>
						<PermissionAllowed permissions={['Move Purchase List to Cancel (Button)']}>
							<button
								className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 ? '' : 'disabled'}`}
								style={{ minWidth: '80px' }}
								onClick={() => selectedIds?.length > 0 && onCancelData()}
							>
								<FaTimesCircle /> Cancel
							</button>
						</PermissionAllowed>
						<PermissionAllowed permissions={['Move Purchase List to Found (Button)']}>
							<button
								className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 ? '' : 'disabled'}`}
								style={{ minWidth: '80px' }}
								onClick={() => selectedIds?.length > 0 && onFoundData()}
							>
								<BsCheckCircleFill /> Found
							</button>
						</PermissionAllowed>
					</div>
					<form className="form-inline navbar-search">
						<div className="input-group">
							<input
								type="text"
								className="form-control bg-white border"
								placeholder="Search"
								aria-label="Search"
								aria-describedby="basic-addon2"
								onChange={(e) => {
									searchData(e.target.value);
								}}
								value={searchField}
								style={{ width: '100%' }}

							/>
							<span className="searchIcon">
								<FaSearch />
							</span>
						</div>
					</form>
				</div>
			</div>
			<div className="table-responsive-xl tableData estimationList">
				<Scroller extraHeight={44}>
					<table className="table pm-table d-none d-md-table">
						<thead>
							<tr className="sticky-header">
								<th className="align-middle" width={'15'}>
									{purchaseList.length > 0 && (
										<>
											<div className="myTest custom-control custom-checkbox custom-list-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id="groupCheck"
													onChange={handleSelectAll}
													checked={selectedIds.length === purchaseList.length}
												/>
												<label className="custom-control-label" for="groupCheck">
													&nbsp;
												</label>
											</div>
										</>
									)}
								</th>
								<th scope="col" width="">
									<SortableHeader
										name="customer"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('customer');
										}}
									>
										Customer
									</SortableHeader>
								</th>
								<th scope="col" width="">
									<SortableHeader
										name="invoice_number"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('invoice_number');
										}}
									>
										Invoice
									</SortableHeader>
								</th>
								<th scope="col">
									<SortableHeader
										name="product"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('product');
										}}
									>
										Product
									</SortableHeader>
								</th>
								{/* <th scope="col">Comment</th> */}
								<th scope="col">
									<SortableHeader
										name="accepted_date"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('accepted_date');
										}}
									>
										Accepted Date
									</SortableHeader>
								</th>
								<th scope="col">Qty</th>
								<th scope="col" width={''}>
									Price ($)
								</th>
								<th scope="col" width={''}>
									Price (€)
								</th>
								<th scope="col">Status</th>
							</tr>
						</thead>
						<tbody>
							{isLoading ? (
								<>
									{[...Array(5)].map((e, i) => (
										<tr>
											<>
												{[...Array(9)].map((e, i) => (
													<td>
														<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
													</td>
												))}
											</>
										</tr>
									))}
								</>
							) : (
								<>
									{purchaseList.length > 0 &&
										purchaseList.map((item, index) => {
											return (
												<>
													<tr key={index} onClick={() => handleSelectRow(item.id)}>
														<td className="align-middle" width={'15'}>
															<div className="myTest custom-control custom-checkbox custom-list-checkbox">
																<input
																	type="checkbox"
																	className="custom-control-input shadowdisable"
																	id={`row-${index}`}
																	checked={selectedIds.includes(item.id)}
																	// onChange={() => handleSelectRow(item.id)}
																	onClick={(e) => {
																		e.preventDefault();
																	}}
																	disabled
																/>
																<label className="custom-control-label" for={`row-${index}`}>
																	&nbsp;
																</label>
															</div>
														</td>
														<td className="align-middle">
															<PrivacyModeContent>{item?.customer?.name}</PrivacyModeContent>
														</td>
														{/* <td className="align-middle">
															<Link to={`/sales/invoices/${item?.estimation_id}`}>{item?.estimation_number}</Link>
														</td> */}
														<td className="align-middle">
															{item.invoice_number ? <Link to={`/sales/invoices/${item?.estimation_id}`}>{item?.invoice_number}</Link> : '-'}
														</td>
														<td className="align-middle" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
															{item?.product?.product_name}

															{item?.comment ? (
																<Tooltip title={item?.comment} arrow placement="top">
																	<span style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
																		<LiaCommentSolid />
																	</span>
																</Tooltip>
															) : (
																''
															)}
														</td>
														{/* <td className="align-middle">{item?.comment || '-'}</td> */}
														<td className="align-middle">{moment(item?.accepted_on).format(CommonDateOnlyFormat)}</td>
														<td className="align-middle">{item?.sold_qty}</td>
														<td className="align-middle">$ {numberFormatter.format(item?.converted_currency_selling_price_unit?.usd)}</td>
														<td className="align-middle">€ {numberFormatter.format(item?.converted_currency_selling_price_unit?.eur)}</td>
														<td className="align-middle">{item?.master_status?.name}</td>
													</tr>
												</>
											);
										})}
									{purchaseList?.length < 1 && !isLoading && (
										<tr className="no-hover">
											<td colSpan={9}>
												<div className="not-found-container">
													<div className="not-found-icon">
														<TbDatabaseExclamation />
													</div>
													<h6 className="not-found-text">No Data Available</h6>
												</div>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</table>
					<PLCards purchaseList={purchaseList} handleSelectRow={handleSelectRow} selectedIds={selectedIds} />
				</Scroller>
			</div>
		</>
	);
};

export default SearchingTable;
