import React, { useEffect, useRef } from 'react';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineDarkMode } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import CustomMUISwitch from './CustomMUISwitch';
import { PiNotificationBold } from 'react-icons/pi';
import { useNavigate } from 'react-router';
import { Avatar } from '@mui/material';
import { TbEyeCancel, TbLogout } from 'react-icons/tb';
import { setLogout, storePrivacyMode, setDisplayTheme } from 'store/reducers/mainSlice';

const ProfileMenuBlock = ({ onCloseMenu, mainIconRef, mobile }) => {
	const [userFName, userLName, userEmail, profilePhoto] = useSelector((state) => [
		state.main?.user?.first_name,
		state.main?.user?.last_name,
		state.main?.user?.email,
		state.main?.user?.profile_photo_url
	]);
	const [privacyMode, displayTheme] = useSelector((state) => [state.main.privacyMode, state.main.displayTheme]);
	// const darkMode = useSelector((state) => state.main.darkMode);

	const history = useNavigate();
	const dispatch = useDispatch();

	const menuRef = useRef(null);

	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target) && mainIconRef.current && !mainIconRef.current.contains(event.target)) {
			onCloseMenu();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const handlePrivacyMode = () => {
		dispatch(storePrivacyMode(!privacyMode));
	};
	const handleDarkMode = () => {
		dispatch(storeDarkMode(!darkMode));
	};
	const handleThemeToggle = () => {
		dispatch(setDisplayTheme(displayTheme == 'darkMode' ? 'lightMode' : 'darkMode'));
	};

	return (
		<div>

			<div
				className={`profile-details-dropdown bg ${mobile ? 'profile-details-dropdown-mobile' : ''}`}
				ref={menuRef}
				style={{
				// 	filter: 'blur(2px)',
				// 	background: '#161819',
				// 	opacity: '0.95',
				// 	border: '1px solid #fff',
					height: '262px'
				}}
			>
			</div>

			<div
				className={`profile-details-dropdown pdb-container ${mobile ? 'profile-details-dropdown-mobile' : ''}`}
				ref={menuRef}
			>

				<div className="profile-details-dropdown-header">
					<Avatar
						sx={{ width: 35, height: 35 }}
						style={{ fontSize: 8 }}
						src={profilePhoto ? profilePhoto : 'https://cdn.iconscout.com/icon/free/png-256/free-avatar-380-456332.png'}
					>
						{userFName ? userFName?.[0] : ''}
						{userLName ? userLName?.[0] : ''}
					</Avatar>
					<div className="profile-details-dropdown-header-name">
						<h6>
							{userFName} {userLName}
						</h6>
						<p>{userEmail}</p>
					</div>
				</div>
				<div className="profile-details-dropdown-menu-items">
					<div
						className="profile-details-dropdown-menu-item"
						onClick={() => {
							history('user/profile');
							onCloseMenu();
						}}
					>
						<span className="pddmi-icon">
							<IoSettingsOutline />
						</span>
						<span className="pddmi-text">Account Settings</span>
						<span className="pddmi-action"></span>
					</div>
					<div
						className="profile-details-dropdown-menu-item"
						onClick={(e) => {
							handleThemeToggle();
							// handleDarkMode();
						}}
					>
						<span className="pddmi-icon">
							<MdOutlineDarkMode />
						</span>
						<span className="pddmi-text">Dark Mode</span>
						<span className="pddmi-action">
							<CustomMUISwitch checked={displayTheme == 'darkMode'} />
						</span>
					</div>
					<div
						className="profile-details-dropdown-menu-item"
						onClick={(e) => {
							handlePrivacyMode();
						}}
					>
						<span className="pddmi-icon">
							<TbEyeCancel />
						</span>
						<span className="pddmi-text">Privacy Mode</span>
						<span className="pddmi-action">
							<CustomMUISwitch checked={privacyMode} />
						</span>
					</div>
					<div
						className="profile-details-dropdown-menu-item"
						onClick={() => {
							history('/notifications');
							onCloseMenu();
						}}
					>
						<span className="pddmi-icon">
							<PiNotificationBold />
						</span>
						<span className="pddmi-text">All Notifications</span>
						<span className="pddmi-action"></span>
					</div>
				</div>
				<div className="profile-details-dropdown-footer">
					<div className="profile-details-dropdown-menu-items">
						<div
							className="profile-details-dropdown-menu-item"
							onClick={() => {
								dispatch(setLogout(true));
								onCloseMenu();
							}}
						>
							<span className="pddmi-icon">
								<TbLogout />
							</span>
							<span className="pddmi-text">Logout</span>
							<span className="pddmi-action"></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileMenuBlock;
