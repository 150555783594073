import React, { useState, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useEffect } from 'react';
import ScrollButton from 'ui-component/ScrollToTopButton';
import GlobalSearch from 'ui-component/global-search/GlobalSearch';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { setDisplayTheme, storeClientDetails } from 'store/reducers/mainSlice';
import { useDispatch, useSelector } from 'react-redux';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { AiOutlinePoweroff } from 'react-icons/ai';
import '../client.scss';
import Select from 'react-select';
import NavBar from '../NavBar/NavbarLogin';
import { FaCheckCircle } from 'react-icons/fa';
import { FaCircleXmark } from 'react-icons/fa6';

import Oval1 from 'assets/images/designs/Oval-1.png';
import Oval2 from 'assets/images/designs/Oval.png';

const NewPassword = () => {
	const history = useNavigate();
	const navigate = useNavigate();
	const [time, setTime] = useState(180); // Initial time in seconds
	const location = useLocation();
	const tokenObject = location.state || {};
	const token = tokenObject.token;

	const [isClicked, setIsClicked] = useState(false);
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	let validToken = useSelector((state) => state.main?.user?.token);
	const [login, setLogin] = useState(true);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [passwordStrength, setPasswordStrength] = useState(0);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const [passwordChecking, setPasswordChecking] = useState(false);
	const [passCharacter, setPassCharacter] = useState(false);
	const [passCapital, setPassCapital] = useState(false);
	const [passNumber, setPassNumber] = useState(false);
	const [passSpecial, setPassSpecial] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (password) {
			if (password.length >= 8) {
				setPassCharacter(true);
			} else {
				setPassCharacter(false);
			}
			if (/[A-Z]/.test(password)) {
				setPassCapital(true);
			} else {
				setPassCapital(false);
			}
			if (/[0-9]/.test(password)) {
				setPassNumber(true);
			} else {
				setPassNumber(false);
			}
			if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
				setPassSpecial(true);
			} else {
				setPassSpecial(false);
			}
		}
	});

	const onSubmit = () => {
		if (password !== confirmPassword) {
			setConfirmPasswordError(true);
			toast.error('Passwords do not match');
			return;
		}

		if (passCharacter === false || passCapital === false || passNumber === false || passSpecial === false) {
			toast.error('Password does not meet the requirements');
			return;
		}

		setIsSubmitting(true);
		const data = {
			password: password,
			token: token
		};

		toast.promise(api.passwordResetClient(data), {
			loading: 'Submitting',
			success: (res) => {
				if (res.status) {
					navigate('/client/login');
				} else {
					setIsSubmitting(false);
				}
				return res.status ? 'Password reset successfully' : res.message;
			},
			error: (err) => `Failed to reset password: ${err.message}`
		});
	};

	// const resendOTP = (event) => {
	// 	const data = {
	// 		email: email
	// 	};

	// 	api.forgotPasswordClient(data).then((res) => {
	// 		const {
	// 			data: { email, token }
	// 		} = res;
	// 		if (res.status === true) {
	// 			navigate('/client/forgot-password-otp', { state: { email, token } });
	// 			window.location.reload(); // Add this line to reload the page
	// 		} else {
	// 			setEmsg(res.message);
	// 			setTimeout(() => setEmsg(''), 3000);
	// 		}
	// 	});
	// };
	function checkPasswordStrength(password) {
		// At least 8 characters
		const lengthCheck = password.length >= 8;

		// At least 1 capital letter
		const capitalCheck = /[A-Z]/.test(password);

		// At least 1 number
		const numberCheck = /[0-9]/.test(password);

		// At least 1 special character
		const specialCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password);

		// Count the number of digits
		const digitCount = (password.match(/[0-9]/g) || []).length;

		// Count the number of special characters
		const specialCount = (password.match(/[!@#$%^&*(),.?":{}|<>]/g) || []).length;

		// Calculate strength based on the checks
		let strength = lengthCheck + capitalCheck + numberCheck + specialCheck;

		// Check for 'very strong' password
		if (digitCount >= 5 || specialCount >= 5) {
			strength = 4;
		} else if (strength <= 2) {
			strength = 1;
		} else if (strength <= 3) {
			strength = 2;
		} else {
			strength = 3;
		}

		return strength;
	}
	return (
		<>
			<div id="parent-client-wrapper">
				<div id="wrapper">
					<NavBar />

					<div
						id="new-wrapper"
						style={{
							fontFamily: 'Poppins, sans-serif'
						}}
					>
						<div id="content-wrapper" className="d-flex flex-column" style={{ backgroundColor: 'transparent' }}>
							<div id="content" style={{ position: 'relative' }}>
								<div className="container-fluid d-flex justify-content-center">
									<div style={{ maxWidth: '2200px', width: '100%', height: '90vh' }}>
										<div className={login ? 'main-page-auth-3' : 'main-page-auth-2'}>
											<div className={login ? 'backdrop-image-purple' : 'backdrop-image-purple-sign'}>
												<img src={Oval1} alt="Oval1" style={{ opacity: '50%' }} />
											</div>
											<div className={login ? 'backdrop-image-pink' : 'backdrop-image-pink-sign'}>
												<img src={Oval2} alt="Oval2" style={{ opacity: '70%' }} />
											</div>
											<div className="invoice-client-content">
												<div className="form-signup" style={{ justifyContent: 'space-between', width: '100%' }}>
													<div className="signup-head" style={{ marginBottom: 15 }}>
														<h4 style={{ marginBottom: 5, fontWeight: 500 }}>Password Reset</h4>
													</div>

													<table style={{ width: '100%', marginBottom: 20 }}>
														<tr>
															{!isMobile && (
																<th style={{ textAlign: 'left', paddingBottom: 10, fontWeight: 300, fontSize: 14 }}>New Password</th>
															)}
															<td style={{ width: '64%', paddingBottom: 10 }}>
																<OverlayTrigger
																	placement={windowWidth < 800 ? 'top' : 'right'}
																	popperConfig={{
																		modifiers: [
																			{
																				name: 'applyStyles',
																				options: {
																					className: 'white-tooltip'
																				}
																			}
																		]
																	}}
																	overlay={
																		<Tooltip id="password-tooltip" className="white-tooltip">
																			<ul style={{ textAlign: 'left', padding: 0, margin: 0, listStyleType: 'none' }}>
																				<li style={{ color: passCharacter ? '#36c880' : '#f64e60' }}>
																					{passCharacter ? <FaCheckCircle color={'#36c880'} /> : <FaCircleXmark color={'#f64e60'} />} 8
																					Characters
																				</li>
																				<li style={{ color: passCapital ? '#36c880' : '#f64e60' }}>
																					{passCapital ? <FaCheckCircle color={'#36c880'} /> : <FaCircleXmark color={'#f64e60'} />} Atleast
																					1 Capital Letter
																				</li>
																				<li style={{ color: passNumber ? '#36c880' : '#f64e60' }}>
																					{passNumber ? <FaCheckCircle color={'#36c880'} /> : <FaCircleXmark color={'#f64e60'} />} Atleast 1
																					Number
																				</li>
																				<li style={{ color: passSpecial ? '#36c880' : '#f64e60' }}>
																					{passSpecial ? <FaCheckCircle color={'#36c880'} /> : <FaCircleXmark color={'#f64e60'} />} Atleast
																					1 Special Character
																				</li>
																			</ul>
																		</Tooltip>
																	}
																>
																	<input
																		type="password"
																		className="form-control"
																		style={{ color: 'black', borderColor: '#0066FF' }}
																		placeholder="Enter new password"
																		name="password"
																		value={password}
																		onFocus={() => setPasswordChecking(true)}
																		onBlur={() => setPasswordChecking(false)}
																		onChange={(e) => {
																			setPassword(e.target.value);
																			setPasswordStrength(checkPasswordStrength(e.target.value));
																		}}
																		autoComplete="off"
																	/>
																</OverlayTrigger>
															</td>
														</tr>
														{passwordChecking && (
															<tr>
																{!isMobile && <th></th>}
																<td style={{ paddingBottom: 30, paddingRight: 7, paddingTop: 5 }}>
																	<div style={{ width: '100%', height: '7px', backgroundColor: '#f2f2f2', borderRadius: 10 }}>
																		<div
																			style={{
																				width: `${(passwordStrength / 4) * 100}%`,
																				height: '100%',
																				borderRadius: '10px',
																				backgroundColor:
																					passwordStrength === 1
																						? '#f54e60'
																						: passwordStrength === 2
																						? '#ed751c'
																						: passwordStrength === 3
																						? '#ffba49'
																						: '#36c880',
																				transition: 'width 0.5s ease, background-color 0.5s ease'
																			}}
																		></div>
																		<a
																			style={{
																				color:
																					passwordStrength === 1
																						? '#f54e60'
																						: passwordStrength === 2
																						? '#ed751c'
																						: passwordStrength === 3
																						? '#ffba49'
																						: passwordStrength === 4
																						? '#36c880'
																						: '#888888'
																			}}
																		>
																			{passwordStrength === 1
																				? 'Weak'
																				: passwordStrength === 2
																				? 'Medium'
																				: passwordStrength === 3
																				? 'Strong'
																				: passwordStrength === 4
																				? 'Very Strong'
																				: 'Password Strength'}
																		</a>{' '}
																	</div>
																</td>
															</tr>
														)}
														<tr>
															{!isMobile && <th style={{ textAlign: 'left', fontWeight: 300, fontSize: 14 }}>Confirm Password</th>}
															<td>
																<input
																	type="password"
																	className="form-control"
																	style={{ color: 'black', borderColor: '#0066FF' }}
																	placeholder="Confirm password"
																	name="confirmPassword"
																	onChange={(e) => setConfirmPassword(e.target.value)}
																/>
															</td>
														</tr>
													</table>

													<div className="action-signup">
														<button
															className="btn btn-cmpny-dtls-action"
															type="submit"
															onClick={() => onSubmit()}
															disabled={isSubmitting} // Disable the button when isSubmitting is true
														>
															Confirm
														</button>
													</div>
												</div>
											</div>
										</div>
										<div
											style={{
												position: 'fixed',
												bottom: 20,
												left: '50%',
												transform: 'translate(-50%, 0)',
												textAlign: 'center'
											}}
										>
											© 2024 Projectmix Ai. <span style={{ display: 'inline-block' }}>All Rights Reserved</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Toaster
				toastOptions={{
					className: 'toaster-styles-2'
				}}
			/>
		</>
	);
};

export default NewPassword;
