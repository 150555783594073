import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { FaSpinner, FaTimes, FaPlus, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { ColoredDeliveryIcon, CloseIcon, DeleteIcon2, ColoredScanIcon } from 'ui-component/custom-icons/icons';
import NoImage from 'assets/images/no-image.png';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelect from 'react-select';
import StatusBadge from 'ui-component/StatusBadge';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import errorSound from '../../../assets/audio/error.mp3';
import useSound from 'use-sound';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import Select from 'react-select';
import usePermissions from 'hooks/usePermissions';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';


const PicklistCreateShipmentModal = ({ openPopup, details, handleClose, handleReloadData }) => {

    const [processingPurchaseOrderButton, setProcessingPurchaseOrderButton] = useState(false);
    const [collectionDetails, setCollectionDetails] = useState(null);
    const [disabledPurchaseOrderButton, setDisabledPurchaseOrderButton] = useState(true);
    const [disabledPicklistButton, setDisabledPicklistButton] = useState(true);
    const [disabledOperationsButton, setDisabledOperationsButton] = useState(true);
    const [processOrderStep, setProcessOrderStep] = useState(1);
    const [supplierList, setSupplierList] = useState([]);
    const [warehouseList, setWarehouseList] = useState([]);
    const currentDate = moment().format('YYYY-MM-DD');
    const [defaultCurrency, setDefaultCurrency] = useState('usd');
    const [availableProductLists, setAvailableProductLists] = useState([]);
    // const [selectedProducts, setSelectedProducts] = useState({});
    const [proceeedToCreatePurchaseOrder, setProceedToCreatePurchaseOrder] = useState(false);
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [imeiExistsInInventory, setImeiExistsInInventory] = useState(true);
    const [enteredSerial, setEnteredSerial] = useState('');
    const [scannedSerials, setScannedSerials] = useState([]);
    const [errorSoundPlay] = useSound(errorSound);
    const [shippingCompanies, setShippingCompanies] = useState([]);
    const [shipmentReferenceNo, setShipmentReferenceNo] = useState([]);
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);
    const [defaultWeightUnit, setDefaultWeightUnit] = useState('');
    const [shipmentDetails, setShipmentDetails] = useState(null);

    const weightUnits = [
        { label: 'Kilogram', value: 'kg' },
        { label: 'Gram', value: 'g' },
    ];

    useEffect(() => {

        api.getNextReferenceNumber('shipment').then((res) => {
            if (res?.status) {
                setShipmentReferenceNo(res?.data?.next_reference_no);
            }
        });
		api.getSelectOptionsList('shipping_company').then((res) => {
			// option_product_name: "11P256MIXAA+ - iPhone 11 Pro 256GB Mixed AA+ - 0 stock"
			const data = res.data.map((item) => ({
				...item,
				label: `${item.name}`,
				value: item.id,
			}));
			setShippingCompanies(data);
        });
        setDefaultWeightUnit('Kilogram');
        setShipmentDetails(details)
    }, []);

    const disablepopup = () => {
        handleClose();
    };

    const handleChangeWeight = (e) => { 
        
        const weightValue = parseFloat(e.target.value);

        if (!isNaN(weightValue)) {
            setShipmentDetails({
                ...shipmentDetails,
                weight: weightValue
            })
        } else {
            setShipmentDetails({
                ...shipmentDetails,
                weight: 0
            })
        }
    }
    const submitShipmentForm = () => {
        if (!shipmentDetails?.picklist_id) {
            toast.error('Picklist is required');
            return;
        } else if (!shipmentDetails?.shipping_company_id) {
            toast.error('Shipping Company is required');
            return;
        } else if (!shipmentDetails?.weight) {
            toast.error('Weight is required');
            return;
        }
        toast.promise(
            api
                .createShipmentFromPicklist(details?.id, shipmentDetails)
                .then(
                    (res) => {
                        if (res.status) {
                            handleReloadData()
                        }
    
                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Creating shipment...',
                success: (data) => `${data.message}`,
                error: <b>Failed to add shipment</b>
            }
        );
    }


    // const removeProductFromList = (index) => {
    //     setPurchaseOrderItems(purchaseOrderItems.filter((_, i) => i !== index));
    // };
    

	// const handleSerialInputKeydown = (event) => {
	// 	if (event.key === 'Enter') {
	// 		handleEnterSerial();
	// 	}
    // };
        
    // const handleEnterSerial = async () => {
    //     if (!enteredSerial || !enteredSerial?.trim()) {
    //         toast.error('Serial Number is required');
    //         return;
    //     }
    
    //     const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;
    
    //     if (!alphanumericRegex.test(enteredSerial)) {
    //         toast.error('Please enter a valid Serial Number');
    //         return;
    //     }
    
    //     const serialExists = scannedSerials?.some(product => product.imei === enteredSerial);
    
    //     if (serialExists) {
    //         toast.error('Serial Number has been scanned already');
    //         errorSoundPlay();
    //         return false;
    //     }
    
    //     const data = {
    //         picklist_item_id: selectedProduct?.id,
    //         imei: enteredSerial,
    //     };
    
    //     toast.promise(
    //         api
    //             .addSerialToPicklist(details?.id, data)
    //             .then(
    //                 (res) => {
    //                     if (res.status) {
    //                         const collection = {
    //                             id: res?.data?.id,
    //                             imei: res?.data?.imei,
    //                             product: res?.data?.product,
    //                             color: res?.data?.color,
    //                             isExists: res?.data?.is_exists,
    //                             status: res?.data?.status,
    //                             statusName: res?.data?.status_name,
    //                         };
    
    //                         // Add the new collection to the scannedSerials array
    //                         setScannedSerials(prevSerials => [
    //                             ...prevSerials,
    //                             collection
    //                         ]);

    
    //                         setEnteredSerial('');
    //                         if (res?.data?.status_name !== 'Sold') {
    //                             // handleReloadData()
    //                             handleAddScannedSerials(selectedProduct?.id, collection)
    //                         }
    //                     }
    
    //                     return res?.status ? res : null;
    //                 },
    //                 () => { }
    //             ),
    //         {
    //             loading: 'Adding serial...',
    //             success: (data) => `${data.message}`,
    //             error: <b>Failed to add repair</b>
    //         }
    //     );
    // };
    
    // const validateSubmitSerial = () => {
        
    //     handleEnterSerial()
    // }
    // const handleRemoveSerial = (item, index) => {
    //     if (item?.id) {

    
    //         toast.promise(
    //             api
    //                 .removeSerialToPicklist(item?.id)
    //                 .then(
    //                     (res) => {
    //                         // if (res.status) {
    //                         //     const collection = {
    //                         //         id: res?.data?.id,
    //                         //         imei: res?.data?.imei,
    //                         //         product: res?.data?.product,
    //                         //         color: res?.data?.color,
    //                         //         isExists: res?.data?.is_exists,
    //                         //         status: res?.data?.status,
    //                         //         statusName: res?.data?.status_name,
    //                         //     };
        
    //                         //     // Add the new collection to the scannedSerials array
    //                         //     setScannedSerials(prevSerials => [
    //                         //         ...prevSerials,
    //                         //         collection
    //                         //     ]);
        
    //                         //     setEnteredSerial('');
    //                         // }
        
    //                         return res?.status ? res : null;
    //                     },
    //                     () => { }
    //                 ),
    //             {
    //                 loading: 'Removing serial...',
    //                 success: (data) => `${data.message}`,
    //                 error: <b>Failed to remove serial</b>
    //             }
    //         );
    //     }
        
    //     const updatedSerials = scannedSerials.filter((_, i) => i !== index);
    //     setScannedSerials(updatedSerials);
    // }

    return (
        <>
            <Modal show={openPopup} onHide={disablepopup} centered size="md">
                <Modal.Body>
                   
                    <div className="p-2 swipe-rtl-animation">
                        <div style={{ padding: '20px 20px 0 20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center">
                                        <ColoredDeliveryIcon height='60px' width='60px' />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Create Shipment
                                    </div>
                                    <div>
                                        Fill up the information needed in creating shipment
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0' }}>
                                    <label>Reference No.</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={shipmentReferenceNo}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0' }}>
                                    <label>Shipping Provider</label>
                                    <Select
                                        options={shippingCompanies}
                                        className="rs-container"
                                        classNamePrefix="rs"
                                        placeholder="Select or type..."
                                        isMulti={false}
                                        onChange={(e) => {
                                            setShipmentDetails({
                                                ...shipmentDetails,
                                                shipping_company_id: e.value
                                            })
                                        }}
                                        // value={selectedRepairType}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0 0 0' }}>
                                    <label>Weight</label>
                                    <div className="custom-input-group">

                                        <InputGroup className="mb-3 d-flex">
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title={defaultWeightUnit}
                                                id="input-group-dropdown-1"
                                            >
                                                {
                                                    weightUnits.map((unit) => { 
                                                        return (
                                                            <Dropdown.Item
                                                                href="#"
                                                                key={unit.value}
                                                                onClick={() => { 
                                                                    // handleChangeCurrency(unit)
                                                                    setShipmentDetails({
                                                                        ...shipmentDetails,
                                                                        weight_unit: unit.value
                                                                    })
                                                                    // setSelectedRepairs({
                                                                    //     ...selectedRepairs,
                                                                    //     repairCurrency: currency.value
                                                                    // })
                                                                    setDefaultWeightUnit(unit.label);
                                                                }}
                                                            >
                                                                {unit.label}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </DropdownButton>
                                            <input
                                                type="text"
                                                id="input-example"
                                                name="input-name"
                                                className="form-control"
                                                placeholder="0"
                                                onChange={(e) => {
                                                    handleChangeWeight(e)
                                                }}
                                                value={shipmentDetails?.weight}
                                                autoComplete={'off'}
                                                autoFocus
                                            />
                                        </InputGroup>
                                    </div>

                                </div>
                            </div>
                                
                            {/* <div className="btnarea" style={{ justifyContent: 'center' }}> */}

                                <div className="row align-items-top">
                                    <div className="col-12 text-left " style={{ padding: '10px 0 0 0' }}>
                                        <button
                                            className="btn btn-main-primary btn-outlined-main-primary btn-block"
                                            style={{ marginRight: '12px' }}
                                            onClick={() => { submitShipmentForm() }}
                                        >
                                            Create Shipment
                                        </button>
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PicklistCreateShipmentModal;
