export const numberFormatter = new Intl.NumberFormat('en-US', {
	style: 'decimal',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
});

export const getCurrencySymbol = (currency) => {
	return currency == 'usd' ? '$' : currency == 'aed' ? 'AED' : '€';
};

export const calculateRateValue = (baseValue, baseRate, toCurrencyRate) => {
	return numberFormatter.format((Number(baseValue) / Number(baseRate)) * Number(toCurrencyRate));
};

export const currencyObject = [
	{ label: 'EUR', value: 'eur' },
	{ label: 'USD', value: 'usd' },
	{ label: 'AED', value: 'aed' }
];
