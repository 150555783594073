import axios from 'axios';
import config from '../config.js';

window.axios = axios;
const token = JSON.parse(localStorage.getItem('user'))?.token;
const api_url = config.apps.find(c=>c?.app_urls.includes(window.location.origin))?.api_url || 'abcd';

window.base = api_url;
window.token = token || null;

if (!token && window.token) {
  localStorage.setItem('token', window.token)
}

const defaultText = '';
export default {
	handelAlert: (func, loaderSet = true, message = '') => {
		return new Promise((res, rej) => {
			let loadObj = { close: () => {} };
			if (loaderSet && window.loader) {
				loadObj = loader(message || defaultText);
			}
			const customRej = (...arg) => {
				loadObj.close();
				const [error] = arg;
				if (error && error.response && error.response.data) {
					const errorMessages = error.response.data.errorMessages || [];
					console.log({ rejected: errorMessages.map((o) => o.errorMessage) });
					alert(errorMessages.map((o) => o.errorMessage).join('<br/>'));
				}
				rej(...arg);
			};
			const customResolve = (...arg) => {
				const unauth = arg[0]?.message;
				if (unauth == 'Unauthenticated.') {
					//localStorage.removeItem('user');
				}
				loadObj.close(), res(...arg);
			};
			func(customResolve, customRej, loadObj);
		});
	}
};
