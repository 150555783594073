import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import usePermissions from 'hooks/usePermissions';
import moment from 'moment';
import Scroller from 'ui-component/Scroller';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { TbDatabaseExclamation } from 'react-icons/tb';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import StatusBadge from 'ui-component/StatusBadge';
import Pagination from 'ui-component/Pagination';

const PurchaseOrderTab = ({ productDetails }) => {

    const history = useNavigate();
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setpagination] = useState([]);

    const getPurchaseOrders = () => {
        setIsLoading(true);
        api
            .getPurchaseOrdersByProductId(productDetails?.id)
            .then((res) => {
                setIsLoading(false);
                if (res.status) {
                    setPurchaseOrders(res.data.data);
                    setpagination(res?.data);
                } else {
                    setPurchaseOrders([]);
                }
                // isFirstLoad && setIsFirstLoad(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setPurchaseOrders([]);
                console.error('Failed to fetch purchase orders:', error);
            });
    };



    const viewPurchaseOrderData = (id) => {
        history(`/purchaseall/view/${id}`);
    };
    const viewSupplierData = (id) => {
        history(`/contacts/${id}`);
    };
    const handleData = (data) => {
        setPurchaseOrders(data.data);
        setpagination(data);
    };

    useEffect(() => {
        getPurchaseOrders(productDetails?.id);
    }, []);
    return (
        <>
            <div className="content ">
                <div className="row">
                    <div className="col-12 ">
                        {purchaseOrders?.length > 0 ? (
                            <>
                                <div className="table-responsive-xl tableData customersList p-2">
                                    <Scroller>
                                        <table className="table pm-table">
                                            <thead>
                                                <tr className="sticky-header ">
                                                    <th scope="col" style={{ width: '25%' }}>Purchase Order</th>
                                                    <th scope="col" style={{ width: '45%' }}>Supplier</th>
                                                    <th scope="col" style={{ width: '15%' }}>Qty</th>
                                                    <th scope="col" style={{ width: '115px' }}>Status</th>
                                                    <th scope="col" style={{ width: '15%' }}>ETA</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {purchaseOrders?.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <a href="javacript:void(0);"

                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Purchase Invoice (Button)'])) {
                                                                            viewPurchaseOrderData(row.purchase_invoice_id);
                                                                        }
                                                                    }}
                                                                >
                                                                    {row?.reference_no}
                                                                </a>
                                                            </td>
                                                            <td >
                                                                <a href="javascript:void(0);"

                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Contact (Button)'])) {
                                                                            viewSupplierData(row?.supplier?.id);
                                                                        }
                                                                    }}
                                                                >

                                                                    <PrivacyModeContent>{row?.supplier?.name}</PrivacyModeContent>
                                                                </a>
                                                            </td>
                                                            <td>{row?.quantity}</td>
                                                            <td><StatusBadge itemStatus={row?.status?.name} /></td>
                                                            <td>{moment(row?.expected_delivery_date).format(CommonDateOnlyFormat)}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Scroller>
                                </div>
                                <Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
                            </>
                        ) : (
                            <>
                                <div className="not-found-container">
                                    <div className="not-found-icon">
                                        <TbDatabaseExclamation />
                                    </div>
                                    <h6 className="not-found-text">No Data Available</h6>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div >
        </>
    );
};

export default PurchaseOrderTab;
