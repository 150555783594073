import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setEstimatedata, storeHeaderActions } from 'store/reducers/mainSlice';
import { Dropdown } from 'react-bootstrap';
import Pagination from 'ui-component/Pagination';
import ColapsibleTable from './comonents/ColapsibleTable';
import FoundPopup from './comonents/FoundPopup';
import SearchingTable from './comonents/SearchingTable';
import CancelledTable from './comonents/CancelledTable';
import FoundTable from './comonents/FoundTable';
import EditColapsible from './comonents/EditColapsible';
import AllComponent from './comonents/AllComponent';
import toast from 'react-hot-toast';
import { FaPlus } from 'react-icons/fa';
import { TbReportMoney } from 'react-icons/tb';
import EstimationDetails from 'views/estimation/EstimationDetails';
import PurchaseInvoicePopup from './comonents/PurchaseInvoicePopup';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiWarningFill, PiInfoFill, PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { RiBillLine } from 'react-icons/ri';
import { LuChevronDown } from 'react-icons/lu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { CommonDateOnlyFormat, getLocalStorageCacheItem, setLocalStorageCacheItem } from 'helpers/common.helper';
import md5 from 'md5-hash';
import moment from 'moment';
import usePageTitle from 'hooks/usePageTitle';
import { AllIcon, CancelledIcon, ProcessingIcon, ReadyIcon, ShippedIcon, SourcedIcon, SourcingIcon } from 'ui-component/custom-icons/icons';
import ExportListItemsModal from 'ui-component/ExportListItemsModal';
import CurrencyValue from 'ui-component/CurrencyValue';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { FaUserTie } from 'react-icons/fa6';

const PurchaseList = () => {
	const [setPageTitle] = usePageTitle('Purchasing');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const dispatch = useDispatch();
	const masterStatuses = useSelector((state) => state.main.masterStatuses);
	const statusList = masterStatuses?.filter((item) => item.type == 'Purchase List').map((item) => ({ id: item.id, name: item.name }));
	const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);
	const [activeComponent, setActiveComponent] = useState('Sourcing');
	const [isDisable, setDisable] = useState(false);
	const [purchaseListOld, setPurchaseList] = useState([]);
	const [openDetails, setOpenDetails] = useState();
	const [paginationOld, setpagination] = useState([]);
	const [selectedIds, setSelectedIds] = useState([]);
	const [foundOpen, setFoundOpen] = useState(false);
	const [cancelConfirmation, setCancelConfirmation] = useState(false);
	const [isOldLoading, setIsLoading] = useState(false);
	const [openpopup, setOpenPopup] = useState(false);
	const [purchaseInvoicePopup, setPurchaseInvoicePopup] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [allModelList, setAllModelList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [openEdit, setOpenEdit] = useState(false);
	const [currentCurrency, setCurrentcurrency] = useState('usd');
	const history = useNavigate();

	const [searchField, setSearchField] = useState('');
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [statusCountsOld, setStatusCounts] = useState(null);
	const [paginationUrl, setPaginationUrl] = useState();

	const [sortByFilter, setSortByFilter] = useState();
	const headerActions = useSelector((state) => state.main.headerActions);

	const updateSortByStatus = (name) => {
		if (sortByFilter?.field == name) {
			setSortByFilter((e) => ({
				...e,
				direction: e?.direction == 'asc' ? 'desc' : 'asc'
			}));
		} else {
			setSortByFilter((e) => ({
				field: name,
				direction: 'asc'
			}));
		}
	};

	const queryClient = useQueryClient();

	const {
		data: listReqData,
		error,
		isSuccess,
		isLoading,
		isFetching
	} = useQuery(
		['purchase-list', activeComponent, activeFilters, searchField, paginationUrl, sortByFilter],
		() => {
			return paginationUrl
				? api.plainAPIRequest(paginationUrl)
				: api.filterPurchaseList(
						activeComponent == 'All' ? '' : statusList?.find((item) => item?.name == activeComponent)?.id,
						activeFilters,
						searchField?.length > 2 ? searchField : null,
						sortByFilter
				  );
		},
		{
			// keepPreviousData: true,
			enabled: !!statusList,
			initialData: (e) => {
				let item = null;
				if (paginationUrl) {
					item = getLocalStorageCacheItem(md5(paginationUrl));
				} else {
					item = getLocalStorageCacheItem(
						md5(
							api.filterPurchaseListApiUrl(
								activeComponent == 'All' ? '' : statusList?.find((item) => item?.name == activeComponent)?.id,
								activeFilters,
								searchField?.length > 2 ? searchField : null,
								sortByFilter
							)
						)
					);
				}
				return item ? item : undefined;
			},
			onSuccess: (e) => {
				setLocalStorageCacheItem(md5(e?.config?.url), e, moment().add(3, 'days').format('YYYY-MM-DD'));
			}
		}
	);

	const { data: counterReqData } = useQuery(
		['purchase-list-counter'],
		() => {
			return api.getPagePurchaseCounter();
		},
		{
			keepPreviousData: true,
			enabled: true,
			initialData: (e) => {
				const item = getLocalStorageCacheItem(md5('purchase-list-counter'));

				return item ? item : undefined;
			},
			onSuccess: (e) => {
				setLocalStorageCacheItem(md5('purchase-list-counter'), e, moment().add(3, 'days').format('YYYY-MM-DD'));
			}
		}
	);

	const invalidateListingQuery = () => {
		queryClient.invalidateQueries({
			queryKey: ['purchase-list', activeComponent, activeFilters, searchField, paginationUrl, sortByFilter]
		});
		queryClient.invalidateQueries({ queryKey: ['purchase-list-counter'] });
	};

	const initialRender = () => {
		// api.getAllstatus().then((res) => {
		// 	const tempStatus = res.data.filter((item) => item.type == 'Purchase List').map((item) => ({ id: item.id, name: item.name }));
		// 	setStatusList(tempStatus);
		// });
	};

	const downloadReport = (excel) => {
		api
			.downloadfilterpurchaselist(
				activeComponent == 'All' ? '' : statusList?.find((item) => item?.name == activeComponent)?.id,
				activeFilters,
				searchField?.length > 2 ? searchField : null,
				selectedCurrency,
				excel
			)
			.then((res) => {
				const newFileName = `${activeComponent != 'All' ? 'Purchasing -' + activeComponent : 'Purchasing'}`;
				if (excel > 0) {
					var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					saveAs(newBlob, `${newFileName}.xlsx`);
				} else {
					var newBlob = new Blob([res], { type: 'application/pdf' });
					saveAs(newBlob, `${newFileName}.pdf`);
				}
			});
	};

	useEffect(() => {
		initialRender();
	}, []);

	useEffect(() => {
		setSelectedIds([]);
	}, [activeComponent]);

	const purchaseList = listReqData?.data?.data?.data || [];
	const pagination = listReqData?.data?.data || [];
	const statusCounts = counterReqData?.data?.data;

	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	// const handleCloseCancellationDialog = () => {
	// 	setCancellationDialog({
	// 		show: false,
	// 		message: '',
	// 		title: ''
	// 	});
	// };

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	useEffect(() => {
		setPaginationUrl(null);
	}, [activeComponent, activeFilters, searchField, sortByFilter]);

	const getPurchaseListFilter = (component) => {
		if (activeComponent && statusList?.length > 0) {
			const statusId = getIdByName(component);
			const stid = component == 'All' ? '' : statusId;
			api.purchaseListFilter(stid).then((res) => {
				setFilters(res.data);
			});
		}
	};

	function getIdByName(name) {
		const status = statusList?.find((obj) => obj.name === name);
		return status ? status.id : null;
	}
	const handleComponentChange = (component) => {
		dispatch(setEstimatedata(''));
		setSearchField('');
		setSortByFilter(null);
		setActiveComponent(component);
	};
	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		zIndex: 9
	};

	useEffect(() => {
		queryClient.invalidateQueries({ queryKey: ['purchase-list-counter'] });
		getPurchaseListFilter(activeComponent);
	}, [activeComponent]);

	const viewEstimateDetails = (id) => {
		setOpenDetails(id);
	};

	const closeDetails = () => {
		setOpenDetails('');
	};
	const handleData = (data) => {
		setPaginationUrl(data);
	};
	const handleSelectAll = () => {
		if (selectedIds.length === purchaseList.length) {
			setSelectedIds([]);
		} else {
			const allIds = purchaseList.map((item) => item.id);
			setSelectedIds(allIds);
		}
	};

	const handleSelectRow = (id) => {
		if (selectedIds.includes(id)) {
			const updatedIds = selectedIds.filter((selectedId) => selectedId !== id);
			setSelectedIds(updatedIds);
		} else {
			setSelectedIds([...selectedIds, id]);
		}
	};

	const onFoundData = () => {
		setFoundOpen(true);
	};

	const showConfirmationDialog = (title, message = '', onConfirm = () => {}, onClose = () => {}) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};
	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const handleCancelSourcing = () => {
		toast.promise(
			api
				.cancelPurchaselistSourcing({
					purchase_list_ids: selectedIds
				})
				.then(
					(res) => {
						if (res.status) {
							invalidateListingQuery();
						}

						return res?.status ? res : null;
					},
					() => {}
				),
			{
				loading: 'Start Cancelling...',
				success: (data) => `${data.message}`,
				error: <b>Failed to cancel sourcing</b>
			}
		);
	};

	const onCancelData = () => {
		showConfirmationDialog(
			'Cancel Invoices?',
			'Are you sure you want to cancel selected invoices?',
			() => {
				handleCancelSourcing();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};
	const closeFound = (data) => {
		if (data == 'reload') {
			invalidateListingQuery();
			setFoundOpen(false);
			setSelectedIds([]);
		} else {
			setFoundOpen(false);
			setSelectedIds([]);
		}
	};
	const assignInspection = () => {
		setOpenPopup(true);
	};

	const closePopup = () => {
		setOpenPopup(false);
		setSelectedIds([]);
	};

	const savePurchaseInvoice = (data) => {
		toast.promise(
			api.createPurchaseInvoice(data).then((res) => {
				if (res.status) {
					setPurchaseInvoicePopup(false);
					invalidateListingQuery();
				}
				return res.status ? res : abc;
			}),
			{
				loading: 'Creating Purchase Invoice...',
				success: <b>Purchase Invoice Created!</b>,
				error: <b>Could not create Purchase Invoice</b>
			}
		);
	};

	const handleSave = (data) => {
		if (data.currentStatus == 'Sourced') {
			setDisable(true);
			toast.promise(
				api
					.assignInspection({
						user_id: data.userId,
						date: data.date,
						time: data.time,
						purchase_list_ids: data.purchase_list_ids,
						supplier_id: data.supplier_id
					})
					.then((res) => {
						setDisable(false);
						if (res.status) {
							setOpenPopup(false);
							invalidateListingQuery();
						}
						return res?.status ? res : null;
					}),
				{
					loading: 'Assigning Inspection...',
					success: (data) => `${data.message}`,
					error: <b>Failed to assign inspection</b>
				}
			);
		} else if (data.currentStatus == 'Inspected') {
			setDisable(true);
			toast.promise(
				api
					.assignCollect({
						user_id: data.userId,
						date: data.date,
						time: data.time,
						purchase_list_ids: data.purchase_list_ids,
						supplier_id: data.supplier_id
					})
					.then((res) => {
						setDisable(false);
						if (res.status) {
							setOpenPopup(false);
							invalidateListingQuery();
						}
						return res?.status ? res : null;
					}),
				{
					loading: 'Assigning Collection...',
					success: (data) => `${data.message}`,
					error: <b>Failed to assign collection</b>
				}
			);
		}
	};
	const moveStatus = (data) => {
		toast.promise(
			api.purchaseStatuschange(data).then(
				(res) => {
					if (res.status) {
						setOpenPopup(false);
						invalidateListingQuery();
					}
					return res?.status ? res : null;
				},
				{
					loading: 'Updating model status...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update modal status</b>
				}
			)
		);
	};
	const editData = () => {
		setOpenEdit(true);
	};
	const closeEditData = (data) => {
		if (data == 'reload') {
			invalidateListingQuery();
			setOpenEdit(false);
			setSelectedIds([]);
		} else {
			setOpenEdit(false);
			setSelectedIds([]);
		}
	};

	const handleSearch = (e) => {
		setSearchField(e);
	};

	const [exportCompIsLoading, setExportCompIsLoading] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);

	const exportListSubmitHandler = (selectedValues) => {
		setExportCompIsLoading(true);
		api.downloadfilterpurchaselist('', selectedValues, '', selectedCurrency).then((res) => {
			const newFileName = `Purchasing`;
			if (res != 404) {
				var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				saveAs(newBlob, `${newFileName}.xlsx`);
				// toast.dismiss(downloading);
				setExportCompIsLoading(false);
				toast.success('Downloaded Completed!');
				setShowExportModal(false);
				dispatch(
					storeHeaderActions({
						showQuickStats: headerActions?.showQuickStats,
						showExport: false
					})
				);
			} else {
				// toast.dismiss(downloading);
				setExportCompIsLoading(false);
				toast.error(res == 404 ? 'No Records Found!' : 'Download Failed!');
			}
		});
	};

	useEffect(() => {
		if (headerActions?.showExport) {
			setShowExportModal(true);
		} else {
			setShowExportModal(false);
		}
	}, [headerActions]);

	return (
		<div className="row">
			<div className="col-xl-12 col-lg-12">
				<div className="section-head-new" style={{ margin: '10px 0px' }}>
					<ul className="topListCom">
						<li
							className={`${activeComponent === 'Sourcing' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
							onClick={() => {
								!isLoading && handleComponentChange('Sourcing');
							}}
						>
							<span className="topListIcon">
								<SourcingIcon width={14} height={14} />
							</span>
							<span className="topListText">Sourcing</span>

							{!isNaN(statusCounts?.Sourcing) && <span className="topListCounter">({statusCounts?.Sourcing})</span>}
						</li>
						<li
							className={`${activeComponent === 'Sourced' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
							onClick={() => {
								!isLoading && handleComponentChange('Sourced');
							}}
						>
							<span className="topListIcon">
								<SourcedIcon width={14} height={14} />
							</span>
							<span className="topListText">Sourced</span>

							{!isNaN(statusCounts?.Sourced) && <span className="topListCounter">({statusCounts?.Sourced})</span>}
						</li>
						<li
							className={`${activeComponent === 'Under Process' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
							onClick={() => {
								!isLoading && handleComponentChange('Under Process');
							}}
						>
							<span className="topListIcon">
								<ProcessingIcon width={14} height={14} />
							</span>
							<span className="topListText">Processing</span>

							{!isNaN(statusCounts?.['Under Process']) && <span className="topListCounter">({statusCounts?.['Under Process']})</span>}
						</li>
						{/* <li
							className={`${activeComponent === 'Ready To Ship' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
							onClick={() => {
								!isLoading && handleComponentChange('Ready To Ship');
							}}
						>
							<span className="topListIcon">
								<ReadyIcon width={14} height={14} />
							</span>
							<span className="topListText">Ready</span>

							{!isNaN(statusCounts?.['Ready To Ship']) && <span className="topListCounter">({statusCounts?.['Ready To Ship']})</span>}
						</li> */}
						<li
							className={`${activeComponent === 'Shipped' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
							onClick={() => {
								!isLoading && handleComponentChange('Shipped');
							}}
						>
							<span className="topListIcon">
								<ShippedIcon width={14} height={14} />
							</span>
							<span className="topListText">Shipped</span>

							{!isNaN(statusCounts?.Shipped) && <span className="topListCounter">({statusCounts?.Shipped})</span>}
						</li>
						<li
							className={`${activeComponent === 'All' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
							onClick={() => {
								!isLoading && handleComponentChange('All');
							}}
						>
							<span className="topListIcon">
								<AllIcon width={15} height={15} />
							</span>
							<span className="topListText">All</span>

							{!isNaN(statusCounts?.All) && <span className="topListCounter">({statusCounts?.All})</span>}
						</li>
						<Link
							className="d-md-none"
							to={'#'}
							onClick={() => {
								handleComponentChange('Cancelled');
							}}
							style={{ textWrap: 'nowrap' }}
						>
							{!isNaN(statusCounts?.Cancelled) && statusCounts?.Cancelled > 0 && <span>Cancelled&nbsp;({statusCounts?.Cancelled})</span>}
						</Link>
					</ul>
					<div className="actions d-none d-md-flex">
						<Link
							className=""
							to={'#'}
							onClick={() => {
								handleComponentChange('Cancelled');
							}}
						>
							{!isNaN(statusCounts?.Cancelled) && statusCounts?.Cancelled > 0 && <span>Cancelled ({statusCounts?.Cancelled})</span>}
						</Link>
						<PermissionAllowed permissions={['List Purchase Invoice (Page)']}>
							<button
								className="bulk-action btn-outlined-main white-var"
								onClick={() => {
									history('/purchaseorder');
								}}
							>
								<span className="menuIcon">
									<RiBillLine style={{ fontSize: '1.3em' }} />
								</span>
								<span>Purchase Order</span>
							</button>
						</PermissionAllowed>
						<PermissionAllowed permissions={['List Purchase Invoice (Page)']}>
							<button
								className="bulk-action btn-outlined-main white-var"
								onClick={() => {
									history('/purchase-invoice');
								}}
							>
								<span className="menuIcon">
									<RiBillLine style={{ fontSize: '1.3em' }} />
								</span>
								<span>Purchase Invoices</span>
							</button>
						</PermissionAllowed>
						<PermissionAllowed permissions={['Export Purchase List (Button)']}>
							<button
								className="btn btn-primary btn-outlined-main white-var btn-create-report"
								onClick={() => {
									setShowExportModal(true);
								}}
							>
								<PiMicrosoftExcelLogoFill style={{ fontSize: '1.4em' }} /> Export
							</button>
						</PermissionAllowed>

						{/* <Dropdown className="btnDel iconbtn" align={'end'}>
							<Dropdown.Toggle as="span">
								<Link to={''} className="btn btn-primary btn-outlined-main white-var btn-create-report" style={{ minWidth: 'unset' }}>
									<PiMicrosoftExcelLogoFill style={{ fontSize: '1.4em' }} /> Export <LuChevronDown />
								</Link>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => downloadReport(1)}>Excel</Dropdown.Item>
								<Dropdown.Item onClick={() => downloadReport(0)}>Pdf</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown> */}
					</div>
				</div>

				<div className="card shadow-sm bg-white mb-4 table-card">
					{activeComponent == 'Sourcing' ? (
						<>
							<SearchingTable
								tableHeaderStyles={tableHeaderStyles}
								purchaseList={purchaseList}
								isLoading={isLoading}
								selectedIds={selectedIds}
								handleSelectAll={handleSelectAll}
								handleSelectRow={handleSelectRow}
								onFoundData={onFoundData}
								onCancelData={onCancelData}
								viewEstimateDetails={viewEstimateDetails}
								searchData={handleSearch}
								searchField={searchField}
								activeFilters={activeFilters}
								setActiveFilters={setActiveFilters}
								filters={filters}
								privacyMode={privacyMode}
								setSortByFilter={setSortByFilter}
								sortByFilter={sortByFilter}
								updateSortByStatus={updateSortByStatus}
							/>
						</>
					) : activeComponent == 'Cancelled' ? (
						<>
							<CancelledTable
								tableHeaderStyles={tableHeaderStyles}
								purchaseList={purchaseList}
								isLoading={isLoading}
								selectedIds={selectedIds}
								handleSelectAll={handleSelectAll}
								handleSelectRow={handleSelectRow}
								onFoundData={onFoundData}
								onCancelData={onCancelData}
								viewEstimateDetails={viewEstimateDetails}
								searchData={handleSearch}
								searchField={searchField}
								activeFilters={activeFilters}
								setActiveFilters={setActiveFilters}
								filters={filters}
								privacyMode={privacyMode}
								setSortByFilter={setSortByFilter}
								sortByFilter={sortByFilter}
								updateSortByStatus={updateSortByStatus}
							/>
						</>
					) : activeComponent == 'All' ? (
						<>
							<AllComponent
								activeComponent={activeComponent}
								tableHeaderStyles={tableHeaderStyles}
								purchaseList={purchaseList}
								isLoading={isLoading}
								selectedIds={selectedIds}
								handleSelectAll={handleSelectAll}
								handleSelectRow={handleSelectRow}
								assignInspenction={assignInspection}
								viewEstimateDetails={viewEstimateDetails}
								moveStatus={moveStatus}
								editData={editData}
								searchData={handleSearch}
								searchField={searchField}
								currnetCurrency={currentCurrency}
								activeFilters={activeFilters}
								setActiveFilters={setActiveFilters}
								filters={filters}
								privacyMode={privacyMode}
								createPurchaseInvoice={() => {
									setPurchaseInvoicePopup(true);
								}}
								showErrorDialog={showErrorDialog}
								setSortByFilter={setSortByFilter}
								sortByFilter={sortByFilter}
								updateSortByStatus={updateSortByStatus}
							/>
						</>
					) : (
						<>
							<FoundTable
								activeComponent={activeComponent}
								tableHeaderStyles={tableHeaderStyles}
								purchaseList={purchaseList}
								isLoading={isLoading}
								selectedIds={selectedIds}
								handleSelectAll={handleSelectAll}
								handleSelectRow={handleSelectRow}
								assignInspenction={assignInspection}
								viewEstimateDetails={viewEstimateDetails}
								moveStatus={moveStatus}
								editData={editData}
								searchData={handleSearch}
								searchField={searchField}
								currnetCurrency={currentCurrency}
								activeFilters={activeFilters}
								setActiveFilters={setActiveFilters}
								filters={filters}
								privacyMode={privacyMode}
								createPurchaseInvoice={() => {
									setPurchaseInvoicePopup(true);
								}}
								showErrorDialog={showErrorDialog}
								setSortByFilter={setSortByFilter}
								sortByFilter={sortByFilter}
								updateSortByStatus={updateSortByStatus}
							/>
						</>
					)}

					<Pagination pagination={pagination} data={handleData} onLoading={(e) => {}} reactQuery />
					<Modal show={openDetails} onHide={closeDetails} centered className="custom-modal">
						<Modal.Body>
							<EstimationDetails modelId={openDetails} />
						</Modal.Body>
						<Modal.Footer>
							<button
								onClick={() => {
									closeDetails();
								}}
								className="btn rounded-pill btn-primary py-2 px-4 pl-3 pr-4"
							>
								Ok
							</button>
						</Modal.Footer>
					</Modal>
					{foundOpen && (
						<Modal show={foundOpen} onHide={closeFound} centered className="custom-modal-two">
							<Modal.Body>
								<div className="p-2">
									<ColapsibleTable
										currentRowData={purchaseList}
										close={closeFound}
										selectedListids={selectedIds}
										allModelList={allModelList}
									/>
								</div>
							</Modal.Body>
						</Modal>
					)}

					{openEdit && (
						<EditColapsible
							openEdit={openEdit}
							currentRowData={purchaseList}
							close={closeEditData}
							selectedListids={selectedIds}
							allModelList={allModelList}
						/>
					)}
					{openpopup && (
						<FoundPopup
							openpopup={openpopup}
							currentRowData={purchaseList}
							selectedListids={selectedIds}
							handleClose={closePopup}
							handleSave={handleSave}
							userList={userList}
							disableStatus={isDisable}
						/>
					)}

					{purchaseInvoicePopup && (
						<PurchaseInvoicePopup
							openpopup={purchaseInvoicePopup}
							currentRowData={purchaseList}
							selectedListids={selectedIds}
							handleClose={() => {
								setPurchaseInvoicePopup(false);
							}}
							handleSave={savePurchaseInvoice}
							disableStatus={isDisable}
						/>
					)}

					{errorDialog?.show && (
						<ActionConfirmationModal
							show={errorDialog?.show}
							message={errorDialog?.message}
							title={errorDialog?.title}
							noCancel
							customConfirmText="Okay"
							customIcon={<PiWarningFill />}
							handleConfirm={() => {
								handleCloseErrorDialog();
							}}
							handleClose={() => {
								handleCloseErrorDialog();
							}}
						/>
					)}
					{/* {cancellationDialog?.show && (
						<ActionConfirmationModal
							show={cancellationDialog?.show}
							message={cancellationDialog?.message}
							title={cancellationDialog?.title}
							noCancel
							customConfirmText="Okay"
							customIcon={<PiWarningFill />}
							handleConfirm={() => {
								handleCloseCancellationDialog();
							}}
							handleClose={() => {
								handleCloseCancellationDialog();
							}}
						/>
					)} */}
					{confirmationDialog?.show && (
						<ActionConfirmationModal
							show={confirmationDialog?.show}
							message={confirmationDialog?.message}
							title={confirmationDialog?.title}
							customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
							customCancelText={confirmationDialog?.customCancel || 'Cancel'}
							customIcon={<PiInfoFill />}
							handleConfirm={confirmationDialog?.handleConfirm}
							handleClose={confirmationDialog?.handleClose}
						/>
					)}

					{showExportModal && (
						<ExportListItemsModal
							openpopup={true}
							disablepopup={() => {
								setShowExportModal(false);
								dispatch(
									storeHeaderActions({
										showQuickStats: headerActions?.showQuickStats,
										showExport: false
									})
								);
							}}
							filters={filters}
							moduleName={'Purchasing'}
							onSubmit={exportListSubmitHandler}
							historyApi={api.getExportsHistory('purchasing')}
							isLoading={exportCompIsLoading}
							exportHistoryPerms={['Show Purchase List Export History (Button)']}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default PurchaseList;
