import { configureStore } from '@reduxjs/toolkit'
import mainReducer from './reducers/mainSlice'

const store = configureStore({
	reducer: {
		main: mainReducer
	}
})

export default store
