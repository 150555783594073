import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useRef } from 'react';

const CustomDateInput = () => {
	const ref = useRef();
	const [value, setValue] = React.useState(moment().format('YYYY-MM-DDTHH:mm'));

	return (
		<div
			onClick={() => {
				ref.current.click();
			}}
		>
			{moment(value).format('EEE, d MMM, HH:mm')}
			<input
				ref={ref}
				type="datetime-local"
				value={moment(value).toDate()}
				onChange={(e) => {
					setValue(moment(e.target.value).format('YYYY-MM-DDTHH:mm'));
				}}
				hidden
			/>
		</div>
	);
};

export default CustomDateInput;
