import moment from 'moment';

export const textToSentenceCase = (text) => {
	return `${text?.length > 0 && text?.[0].toUpperCase()}${text?.length > 1 && text.slice(1, text?.length)}`;
};

export const apiResponseErrorHandler = (res) => {
	if (res?.response?.status == 401) {
		localStorage.removeItem('user');
		sessionStorage.removeItem('user');
		window.location.replace('/login');
	}
};

export const apiResponseErrorHandlerClient = (res) => {
	if (res?.response?.status == 401) {
		localStorage.removeItem('client');
		sessionStorage.removeItem('client');
		window.location.replace('/client');
	}
};

export const notificationTextToPlainText = (notiText) => {
	const parseString = (str) => {
		const blockRegex = /<(\w+)([^>]*)>(.*?)<\/\1>/g;

		let match;
		let result = '';
		let lastIndex = 0;

		while ((match = blockRegex.exec(str)) !== null) {
			const [fullMatch, tagName, attributes, innerText] = match;

			// Extract plain text content from the block
			const plainText = innerText;

			// Concatenate the text before the current block and the plain text content
			result += str.slice(lastIndex, match.index) + plainText;

			lastIndex = match.index + fullMatch.length;
		}

		// Add the remaining text after the last match
		result += str.slice(lastIndex);

		return result;
	};
	const parsedElements = parseString(notiText);

	return parsedElements;
};

export const CommonDateOnlyFormat = 'DD MMM YYYY';
// export const CommonDatetTimeFormat = 'DD MMM YYYY ';

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const setLocalStorageCacheItem = (key, value, expiry = 'infinity') => {
	try {
		localStorage.setItem(
			key,
			JSON.stringify({
				value,
				type: 'cache',
				expiry
			})
		);
	} catch (e) {
		if (e == QUOTA_EXCEEDED_ERR) {
			localStorage.clear();
			setLocalStorageCacheItem(key, value, expiry);
		}
	}
};

export const getLocalStorageCacheItem = (key) => {
	const value = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;

	if (value) {
		if (value.expiry == 'infinity') {
			return value?.value;
		} else {
			if (moment(value?.expiry).diff(moment(), 'days') > 0) {
				return value?.value;
			} else {
				localStorage.removeItem(key);
				return undefined;
			}
		}
	} else {
		return undefined;
	}
};

export const localStorageCacheCleaner = () => {
	Object.keys(localStorage).forEach((key) => {
		let jsonObj = null;

		try {
			jsonObj = JSON.parse(localStorage.getItem(key));
		} catch (error) { }

		if (
			jsonObj?.type == 'cache' &&
			!!jsonObj?.expiry &&
			jsonObj?.expiry != 'infinity' &&
			moment(jsonObj?.expiry).diff(moment(), 'days') < 0
		) {
			localStorage.removeItem(key);
		}
	});
};
