import { GridView, ViewList } from '@mui/icons-material';
import { Avatar, Skeleton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaEllipsisH, FaEye, FaPlus, FaSearch } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import TaskGrid from '../TaskGrid';
import Filter from 'ui-component/filter-menu/Filter';
import Scroller from 'ui-component/Scroller';
import Pagination from 'ui-component/Pagination';
import { Dropdown } from 'react-bootstrap';
import { TbDatabaseExclamation } from 'react-icons/tb';
import moment from 'moment';
import { getStatusColor } from '../TaskGrid/Task';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { AllIcon, CompletedIcon, InprogressIcon, UpcomingIcon } from 'ui-component/custom-icons/icons';
import usePageTitle from 'hooks/usePageTitle';

function Segregation() {
	const [setPageTitle] = usePageTitle('Segregation');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const history = useNavigate();
	const [viewLayout, setViewLayout] = useState('grid');
	const [activeStatus, setActiveStatus] = useState('upcoming');
	const [dataList, setDataList] = useState([]);
	const [pagination, setpagination] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [privacyMode, setPrivacyMode] = useState(false);

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [statusCounts, setStatusCounts] = useState(null);

	const handleViewLayout = (event, value) => {
		if (value !== null) {
			setViewLayout(value);
		}
	};

	const handleData = (data) => {
		setDataList(data.data);
		setpagination(data);
	};

	const handleStatusChange = (value) => {
		setActiveStatus(value);
	};

	const getDataList = () => {
		setIsLoading(true);
		api.getSegregationlistapi(activeStatus).then((res) => {
			setIsLoading(false);
			setDataList(res.data.data);
			setpagination(res.data);
			// setStatusCounts(res?.data?.status_counts);
			isFirstLoad && setIsFirstLoad(false);
		});
		getPageCounter();
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getSegregationFilters().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	const getPageCounter = () => {
		api.getPageSegregationCounter().then(
			(res) => {
				if (res.status) {
					setStatusCounts(res?.data);
				}
				return res?.status ? res : null;
			},
			() => {}
		);
	};

	const handleStart = (id) => {
		history(`/operations/segregation/${id}?start=true`);
	};

	const handleDetails = (id) => {
		history(`/operations/segregation/${id}`);
	};

	useEffect(() => {
		getFiltersList();
	}, []);

	useEffect(() => {
		isFirstLoad ? getDataList() : handleFilter();
	}, [activeStatus]);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsLoading(true);
			if (text.length > 2) {
				api.filterSegregations(activeStatus, activeFilters, text).then((res) => {
					setIsLoading(false);
					setDataList(res.data.data);
					setpagination(res.data);
				});
				getPageCounter();
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterSegregations(activeStatus, activeFilters, null).then((res) => {
					setIsLoading(false);
					setDataList(res.data.data);
					setpagination(res.data);
				});
				getPageCounter();
			}
		}, 500);

		setSearchTimeout(timeout);
	};

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);
		api.filterSegregations(activeStatus, activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsLoading(false);
			setDataList(res.data.data);
			setpagination(res.data);
		});
		getPageCounter();
	};

	return (
		<>
			<div className="row">
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<ul className="topListCom">
							<li
								className={`${activeStatus === 'upcoming' ? 'active' : ''}  ${isLoading && 'not-allowed-pointer'}`}
								onClick={() => {
									!isLoading && handleStatusChange('upcoming');
								}}
							>
								<span className="topListIcon">
									<UpcomingIcon width={15} height={15} />
								</span>
								<span className="topListText">Upcoming</span>
								{!isNaN(statusCounts?.['Upcoming']) && <span className="topListCounter">({statusCounts?.['Upcoming']})</span>}
							</li>
							<li
								className={`${activeStatus === 'inprogress' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
								onClick={() => {
									!isLoading && handleStatusChange('inprogress');
								}}
							>
								<span className="topListIcon">
									<InprogressIcon width={19} height={18} />
								</span>
								<span className="topListText">In Progress</span>
								{!isNaN(statusCounts?.['In Progress']) && <span className="topListCounter">({statusCounts?.['In Progress']})</span>}
							</li>
							<li
								className={`${activeStatus === 'completed' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
								onClick={() => {
									!isLoading && handleStatusChange('completed');
								}}
							>
								<span className="topListIcon">
									<CompletedIcon width={15} height={15} />
								</span>
								<span className="topListText">Completed</span>

								{!isNaN(statusCounts?.['Completed']) && <span className="topListCounter">({statusCounts?.['Completed']})</span>}
							</li>
							<li
								className={`${activeStatus === '' ? 'active' : ''} ${isLoading && 'not-allowed-pointer'}`}
								onClick={() => {
									!isLoading && handleStatusChange('');
								}}
							>
								<span className="topListIcon">
									<AllIcon width={15} height={15} />
								</span>
								<span className="topListText">All</span>

								{!isNaN(statusCounts?.['All']) && <span className="topListCounter">({statusCounts?.['All']})</span>}
							</li>
						</ul>
						<div className="actions d-none d-md-flex">
							<ToggleButtonGroup size="small" value={viewLayout} exclusive onChange={handleViewLayout} className="view-toggle">
								<ToggleButton value="grid" key="grid">
									<GridView />
								</ToggleButton>
								<ToggleButton value="list" key="list">
									<ViewList />
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="table-responsive-xl tableData customersList">
							<Scroller>
								{viewLayout == 'grid' ? (
									<TaskGrid
										dataList={dataList.map((task) => {
											return {
												id: task?.id,
												taskName: task?.supplier,
												date: task?.collected_on,
												priority: !task?.end_at ? '' : 'completed',
												priorityColor: !task?.end_at ? 'blue' : 'green',
												quantity: task?.total_qty,
												inprogress: !!task?.start_at,
												bodyContent: (
													<>
														<div>
															<p style={{ fontSize: '12px' }}>Ref: {task?.reference_no}</p>
														</div>
														{task?.customers?.length > 0 && (
															<p style={{ fontSize: '12px', color: 'grey', marginBlock: '10px' }}>
																{task?.customers?.length > 1 ? (
																	<>
																		<PrivacyModeContent>{task?.customers?.[0]?.name + ' '}</PrivacyModeContent>
																		<Tooltip
																			title={task?.customers?.map((cus) => (
																				<p className="m-0">{cus?.name}</p>
																			))}
																		>
																			<b>
																				<span>+{task?.customers?.length - 1}</span>
																			</b>
																		</Tooltip>
																	</>
																) : (
																	<>
																		<PrivacyModeContent>{task?.customers?.[0]?.name + ' '}</PrivacyModeContent>
																	</>
																)}
															</p>
														)}
													</>
												)
											};
										})}
										isLoading={isLoading}
										onAccept={handleStart}
										onDetails={handleDetails}
										viewPermission={['View Task Segregation Details (Button)']}
										acceptPermission={['Start / End Task Segregation (Button)']}
										privacyMode={privacyMode}
									/>
								) : (
									<>
										<table className="table pm-table">
											<thead>
												<tr className="sticky-header">
													<th scope="col" width="10%">
														Collected On
													</th>
													<th scope="col" width="10%">
														Ref
													</th>
													<th scope="col" width="20%">
														Supplier
													</th>
													<th scope="col" width="10%">
														Quantity
													</th>
													<th scope="col" width="40%">
														Customers
													</th>
													<th scope="col" className="text-center" width="10%">
														Action
													</th>
												</tr>
											</thead>
											<tbody>
												{isLoading ? (
													<>
														{[...Array(5)].map((e, i) => (
															<tr>
																{[...Array(7)].map((e, i) => (
																	<td>
																		<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																	</td>
																))}
															</tr>
														))}
													</>
												) : (
													<>
														{dataList?.map((row, index) => {
															const maxLength = 40;
															const supplier_name =
																row.supplier?.length > maxLength ? row.supplier.slice(0, maxLength) + '...' : row.supplier;
															return (
																<tr key={index}>
																	<td className="align-middle task-date-col">
																		<div
																			className="priority-line"
																			style={{
																				backgroundColor: getStatusColor(!row?.end_at ? 'blue' : 'green')
																			}}
																		>
																			&nbsp;
																		</div>
																		{moment(row?.collected_on).format(CommonDateOnlyFormat)}
																		{/* {moment(row?.collected_on).format('YYYY-MM-DD hh:mmA')} */}
																	</td>
																	<td className="align-middle">{row?.reference_no}</td>
																	<td className="align-middle">
																		<PrivacyModeContent>{supplier_name}</PrivacyModeContent>
																	</td>
																	<td className="align-middle"> {row?.total_qty} </td>
																	<td className="align-middle">{row?.customers?.map((obj) => obj['name']).join(', ')}</td>
																	<td className="align-middle text-center action-col">
																		<PermissionAllowed permissions={['View Task Segregation Details (Button)']}>
																			<span className="btnEdit plain-iconbtn" onClick={() => handleDetails(row?.id)}>
																				<FaEye />
																			</span>
																		</PermissionAllowed>
																		<PermissionAllowed permissions={['Start / End Task Segregation (Button)']}>
																			{row?.priority_status?.value == 'completed' ? (
																				<></>
																			) : (
																				<Dropdown className="btnDel iconbtn plain-iconbtn">
																					<Dropdown.Toggle as="span" id={`dropdown-toggle-${index}`}>
																						<FaEllipsisH />
																					</Dropdown.Toggle>

																					<Dropdown.Menu>
																						{!!row?.start_at ? (
																							<>
																								<Dropdown.Item
																									onClick={() => {
																										handleStart(row.id);
																									}}
																								>
																									Done
																								</Dropdown.Item>
																							</>
																						) : (
																							<>
																								<Dropdown.Item
																									onClick={() => {
																										handleStart(row.id);
																									}}
																								>
																									Start
																								</Dropdown.Item>
																							</>
																						)}
																					</Dropdown.Menu>
																				</Dropdown>
																			)}
																		</PermissionAllowed>
																	</td>
																</tr>
															);
														})}
														{dataList?.length < 1 && (
															<tr className="no-hover">
																<td colSpan={7}>
																	<div className="not-found-container">
																		<div className="not-found-icon">
																			<TbDatabaseExclamation />
																		</div>
																		<h6 className="not-found-text">No Data Available</h6>
																	</div>
																</td>
															</tr>
														)}
													</>
												)}
											</tbody>
										</table>
									</>
								)}
							</Scroller>
						</div>
						<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
					</div>
				</div>
			</div>
		</>
	);
}

export default Segregation;
