import React, { useState } from 'react';
import { GridView, ViewList } from '@mui/icons-material';
import moment from 'moment';
import Scroller from 'ui-component/Scroller';
import NewShipmentItem from './NewShipmentItem';
import { FaCircleInfo } from 'react-icons/fa6';
import { Avatar, Skeleton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

function NewShipmentComponent({ shipmentData, setShipmentData, estimateDetails }) {
	const [viewLayout, setViewLayout] = useState('grid');

	const handleViewLayout = (event, value) => {
		if (value !== null) {
			setViewLayout(value);
		}
	};

	const pStyle = {
		textAlign: 'center'
	};
	console.log(estimateDetails);
	return (
		<>
			<div className="estimate-option-card mb-2">
				<div className="estimate-option-card-head">
					<h5>Shipment Details</h5>
					<div className="actions">
						{/* <ToggleButtonGroup
								size="small"
								value={viewLayout}
								exclusive
								onChange={handleViewLayout}
								className="view-toggle"
							>
								<ToggleButton value="grid" key="grid">
									<GridView />
								</ToggleButton>
								<ToggleButton value="list" key="list">
									<ViewList />
								</ToggleButton>
							</ToggleButtonGroup> */}
					</div>
				</div>
				<div className="estimate-option-items w-100">
					{estimateDetails?.length == 0 && <div className="no-records">No shipment recorded</div>}

					<Scroller>
						<div className="payment-taskgrid-container w-100">
							{estimateDetails?.awb.map((item, index) => (
								<div key={index} className="task-card" style={{ '--statusColor': '#45ad51' }}>
									<div className="task-head">
										<span style={{ fontWeight: 600 }}>Shipped</span>
										<span style={{ color: 'grey' }}>{moment(item?.created_at).format(CommonDateOnlyFormat)}</span>
									</div>

									<div className="task-body">
										AWB:{''}
										<span style={{ color: 'black' }}> {item?.file_name.replace(/\.pdf/g, '')}</span>
										{/* {selectedRow && <ImageViewer imagePath={payment?.Image} altText={altText} />} */}
									</div>
									<div className="task-actions">
										<div className="task-qty">{/* <BsCreditCard /> {payment?.payType === 'credit-note' ? 'Credit Note' : 'Wired'} */}</div>
										{item?.awb_attachment_url && (
											<div className="d-flex justify-content-end" style={{ gap: '5px' }}>
												<a
													href={item?.awb_attachment_url}
													target="_blank"
													className="btn btn-primary btn-outlined-main"
													// onClick={() => handleRowClick(payment?.id)}
												>
													<FaCircleInfo /> Attachments
												</a>
											</div>
										)}
									</div>
								</div>
							))}
						</div>
					</Scroller>
				</div>
			</div>
		</>
	);
}

export default NewShipmentComponent;
