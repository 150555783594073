import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

import { useDispatch, useSelector } from 'react-redux';
import usePageTitle from 'hooks/usePageTitle';
import { storePageTitle } from 'store/reducers/mainSlice';
import { IoCartOutline } from 'react-icons/io5';
import { toast } from 'react-hot-toast';
import { setCartValue } from 'store/reducers/mainSlice';

import { useQuery } from 'react-query';

const Cart = () => {
	const inputRef = useRef(null);
	const clientUser = useSelector((state) => state.main.client);
	const searchBar = useSelector((state) => state.main.searchBar);
	const dispatch = useDispatch();
	const history = useNavigate();
	const [setPageTitle] = usePageTitle('cart');
	const [searchField, setSearchField] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	const [data, setData] = useState([]);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (searchBar && inputRef.current) {
			inputRef.current.focus();
		}
	}, [searchBar]);

	// Rename variables to avoid conflict
	const {
		data: queryData,
		isLoading: queryIsLoading,
		error
	} = useQuery('cartItems', () => api.getCartItems(clientUser?.data?.id), {
		onSuccess: (queryData) => {
			setData(queryData.data); // Use the renamed variable here
		},
		onError: (error) => {
			console.error(error);
		},
		// This ensures the query does not run until `clientUser?.data?.id` is available
		enabled: !!clientUser?.data?.id
	});

	const deleteCartItem = (id) => {
		let data = {
			id: clientUser?.data?.id,
			cart_id: id
		};
		api.deleteCartItem(data?.id, data).then((res) => {
			api.getCartItems(clientUser?.data?.id).then((res) => {
				setData(res.data);
			});
			api.getCartDetails(clientUser?.data?.id).then((res) => {
				dispatch(setCartValue(res.data));
			});
			toast.success('Item removed from cart successfully');
		});
	};

	const updateQuantity = (id, quantity) => {
		let data = {
			id: clientUser?.data?.id,
			cart_id: id,
			quantity: quantity
		};
		api.updateCartItem(data?.id, data).then((res) => {
			api.getCartItems(clientUser?.data?.id).then((res) => {
				setData(res.data);
			});
			toast.success('Quantity updated successfully');
			api.getCartDetails(clientUser?.data?.id).then((res) => {
				dispatch(setCartValue(res.data));
			});
		});
	};

	return (
		<>
			<div className="main-page-client" style={{ padding: 30 }}>
				<div className="order-client-content">
					{!isMobile && (
						<div className="d-flex align-items-center TableSerachBar" style={{ justifyContent: isMobile ? 'flex-end' : 'space-between' }}>
							<div className="heading-order-client-tbl">{!isMobile && <h3>Your Cart</h3>}</div>
							{/* <form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										ref={inputRef}
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										value={searchField}
									/>
									<span className="searchIcon">
										<FaSearch />

									</span>
								</div>
							</form> */}
							<button className="btn  btn-confirm-agreement">Proceed to Checkout</button>
						</div>
					)}
					{!isMobile && (
						<>
							<div className="estimation-table-area" style={{ height: isMobile ? 'auto' : '93%' }}>
								<div className="table-side">
									<div
										className="table-responsive-xl tableData estimationList"
										style={{ height: isMobile ? 'auto' : '100%', overflowY: isMobile ? 'none' : 'auto' }}
									>
										{data.length > 0 ? (
											<>
												<table className="order-client-tb min-width-unset pm-table d-sm-table d-md-table">
													<thead
														style={{
															position: 'sticky',
															top: '-1px',
															zIndex: 1,
															backgroundColor: '#fff',
															borderBottom: '1px solid #cacaca'
														}}
													>
														<tr>
															<th scope="col" className="text-left"></th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Product
															</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Quantity
															</th>

															<th scope="col" style={{ textAlign: 'left' }}>
																Price
															</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Total
															</th>
															<th scope="col" className="text-center"></th>
														</tr>
													</thead>
													<tbody>
														{data.map((item, index) => (
															<tr key={index}>
																<td className="text-left ml-5">
																	<img src={item?.product?.thumbnail_url} alt="product" style={{ width: 50, height: 50 }} />
																</td>
																<td style={{ textAlign: 'left' }}>
																	<a>{item?.product?.name}</a>
																</td>
																<td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
																	<input
																		type="number"
																		className="qty-inpt-cart-mobile"
																		defaultValue={item.quantity}
																		onBlur={(e) => updateQuantity(item.id, e.target.value)}
																		onKeyDown={(e) => {
																			if (e.key === 'Enter') {
																				e.target.blur(); // This triggers the onBlur event handler
																			}
																		}}
																	/>
																</td>
																<td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
																	{item.currency === 'EUR' ? '€' : item.currency === 'USD' ? '$' : 'AED'}{' '}
																	{Number(item.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</td>

																<td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
																	{item.currency === 'EUR' ? '€' : item.currency === 'USD' ? '$' : 'AED'}{' '}
																	{Number(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</td>
																<td style={{ textAlign: 'center' }}>
																	<div onClick={() => deleteCartItem(item.id)}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25.639 24.276">
																			<g id="Group" transform="translate(1 1)" opacity="0.5">
																				<path
																					id="Vector"
																					d="M23.64,1.661a1.011,1.011,0,0,1-.1-.005A130.606,130.606,0,0,0,10.477,1c-2.5,0-5.088.133-7.692.4L.1,1.656A1,1,0,0,1-1,.758,1,1,0,0,1-.1-.334l2.684-.26C5.255-.864,7.911-1,10.477-1a132.616,132.616,0,0,1,13.262.666,1,1,0,0,1-.1,2Z"
																					transform="translate(0 4.567)"
																					fill="#ff342d"
																				/>
																				<path
																					id="Vector-2"
																					data-name="Vector"
																					d="M0,4.907a1.007,1.007,0,0,1-.162-.013A1,1,0,0,1-.987,3.746l.28-1.723C-.5.748-.219-1,2.865-1H6.31a3.465,3.465,0,0,1,2.8.983A3.888,3.888,0,0,1,9.88,2.014l.282,1.71a1,1,0,0,1-1.973.325L7.907,2.343a2.545,2.545,0,0,0-.3-1.047A1.911,1.911,0,0,0,6.31,1H2.865a1.955,1.955,0,0,0-1.3.286,2.566,2.566,0,0,0-.3,1.057L.987,4.067A1,1,0,0,1,0,4.907Z"
																					transform="translate(7.232)"
																					fill="#ff342d"
																				/>
																				<path
																					id="Vector-3"
																					data-name="Vector"
																					d="M13.222,17.888H4.788c-2.023,0-3.319-.461-4.078-1.45a5.577,5.577,0,0,1-.846-3.148L-1,.065A1,1,0,0,1-.065-1,1,1,0,0,1,1-.065l.861,13.222A4.177,4.177,0,0,0,2.3,15.219c.341.443,1.179.668,2.492.668h8.434c1.313,0,2.152-.225,2.492-.668a4.183,4.183,0,0,0,.436-2.065L17.011-.065A1,1,0,0,1,18.074-1,1,1,0,0,1,19.007.065l-.861,13.222a5.582,5.582,0,0,1-.846,3.151C16.54,17.427,15.244,17.888,13.222,17.888Z"
																					transform="translate(2.805 5.389)"
																					fill="#ff342d"
																				/>
																			</g>
																		</svg>
																	</div>
																</td>
															</tr>
														))}
													</tbody>
													<tfoot
														style={{
															position: 'sticky',
															bottom: '-1px',
															zIndex: 1,
															backgroundColor: '#fff',
															borderTop: '1px solid #cacaca'
														}}
													>
														<tr>
															<td colSpan={3}></td>
															<td>
																<b>Total</b>
															</td>
															<td>
																{data[0].currency === 'EUR' ? '€' : data[0].currency === 'USD' ? '$' : 'AED'}{' '}
																{data
																	.reduce((acc, item) => acc + item.total, 0)
																	.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
															</td>
															<td></td>
														</tr>
													</tfoot>
												</table>
												{/* <div
													style={{
														position: 'sticky',
														bottom: '-1px',
														width: '100%',
														backgroundColor: '#fff',
														textAlign: isMobile ? 'center' : 'right',
														marginTop: 10
													}}
												>
													<Pagination pagination={pagination} data={handleData} />
												</div> */}
											</>
										) : (
											<div style={{ width: '100%' }}>
												<div className="no-hover" style={{ width: '100%' }}>
													<div className="whitebg">
														<div className="not-found-container ">
															<IoCartOutline size={120} color="black" />

															<h6
																className="not-found-text"
																style={{ fontStyle: 'italic', color: '#000', fontSize: '18px', marginTop: 10 }}
															>
																Add your first product and enjoy the convenience!
															</h6>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default Cart;
