import React from 'react';
import './processindicator.scss';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

function ProcessIndicator({ processes, isInProgress, currentIndex = 0 }) {
	const clientUser = useSelector((state) => state.main.client);
	return (
		<div className="d-flex justify-content-center align-items-center process-status-indicator-container-client">
			<div className="process-status-indicator mt-3">
				{processes.map((item, index) => {
					return (
						<>
							<div
								className={`process ${index < currentIndex ? 'completed' : index == currentIndex ? 'current' : ''} ${
									index == currentIndex && isInProgress ? 'inprogress' : ''
								}`}
							>
								<div className="indicator-check">
									<span className="check-icon">
										{index < currentIndex ? (
											<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
												<g id="Group_38936" data-name="Group 38936" transform="translate(-540 -118)">
													<g
														id="Ellipse_39"
														data-name="Ellipse 39"
														transform="translate(540 118)"
														fill="#fff"
														stroke="#34c880"
														stroke-width="2"
													>
														<circle cx="13" cy="13" r="13" stroke="none" />
														<circle cx="13" cy="13" r="12" fill="none" />
													</g>
													<path
														id="Icon_metro-spell-check"
														data-name="Icon metro-spell-check"
														d="M23.948,19.28,13.812,30.2,8.355,23.179h0l5.458,2.868L23.948,19.28Z"
														transform="matrix(0.995, -0.105, 0.105, 0.995, 534.352, 108.21)"
														fill="#34c880"
													/>
												</g>
											</svg>
										) : index == currentIndex ? (
											<>
												{clientUser?.data?.is_approved === 2 ? (
													<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
														<g id="Group_38938" data-name="Group 38938" transform="translate(-694 -118)">
															<g
																id="Ellipse_38"
																data-name="Ellipse 38"
																transform="translate(694 118)"
																fill="#fff"
																stroke="#34c880"
																stroke-width="2"
															>
																<circle cx="13" cy="13" r="13" stroke="none" />
																<circle cx="13" cy="13" r="12" fill="none" />
															</g>
															<path
																id="Icon_metro-spell-check"
																data-name="Icon metro-spell-check"
																d="M23.948,19.28,13.812,30.2,8.355,23.179h0l5.458,2.868L23.948,19.28Z"
																transform="matrix(0.995, -0.105, 0.105, 0.995, 688.352, 108.21)"
																fill="#e2e2e2"
															/>
														</g>
													</svg>
												) : (
													<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
														<g id="Group_38939" data-name="Group 38939" transform="translate(-694 -118)">
															<g
																id="Ellipse_38"
																data-name="Ellipse 38"
																transform="translate(694 118)"
																fill="#fff"
																stroke="#e2e2e2"
																stroke-width="2"
															>
																<circle cx="13" cy="13" r="13" stroke="none" />
																<circle cx="13" cy="13" r="12" fill="none" />
															</g>
															<path
																id="Icon_metro-spell-check"
																data-name="Icon metro-spell-check"
																d="M23.948,19.28,13.812,30.2,8.355,23.179h0l5.458,2.868L23.948,19.28Z"
																transform="matrix(0.995, -0.105, 0.105, 0.995, 688.352, 108.21)"
																fill="#e2e2e2"
															/>
														</g>
													</svg>
												)}
											</>
										) : (
											<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
												<g id="Group_38939" data-name="Group 38939" transform="translate(-694 -118)">
													<g
														id="Ellipse_38"
														data-name="Ellipse 38"
														transform="translate(694 118)"
														fill="#fff"
														stroke="#e2e2e2"
														stroke-width="2"
													>
														<circle cx="13" cy="13" r="13" stroke="none" />
														<circle cx="13" cy="13" r="12" fill="none" />
													</g>
													<path
														id="Icon_metro-spell-check"
														data-name="Icon metro-spell-check"
														d="M23.948,19.28,13.812,30.2,8.355,23.179h0l5.458,2.868L23.948,19.28Z"
														transform="matrix(0.995, -0.105, 0.105, 0.995, 688.352, 108.21)"
														fill="#e2e2e2"
													/>
												</g>
											</svg>
										)}
									</span>
								</div>
								<span className="name-indicator">{item.name}</span>
							</div>
							{processes?.length - 1 != index && <div className="process-connector-line">&nbsp;</div>}
						</>
					);
				})}
			</div>
		</div>
	);
}

export default ProcessIndicator;
