import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { setLoading, storePermissions, storeUserDetails } from 'store/reducers/mainSlice';
import Logo from 'assets/images/logo-light.svg';
import CarouselUi from 'ui-component/CarouselUi';

import { Alert } from '@mui/material';
import './NewLogin.scss'; // Import your CSS file

function Copyright(props) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			<Link color="inherit" href="https://projectmix.ai/">
				Projectmix
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
	palette: {
		main: {
			primary: '#192138'
		}
	}
});

export default function SetPassword() {
	const history = useNavigate();
	const navigate = useNavigate();
	const location = useLocation();
	const emailObject = location.state || {};
	const email = emailObject.email;
	let validToken = useSelector((state) => state.main?.user?.token);
	const [showPassword, setShowPassword] = useState('fa-eye');

	const matches = useMediaQuery('(max-width:900px)');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handlePasswordChange = (event) => {
		const password = event.target.value;
		setPassword(password);

		const hasUpperCase = /[A-Z]/.test(password);
		const hasNumber = /\d/.test(password);
		const hasSymbol = /\W/.test(password);
		const hasMinLength = password.length >= 8;

		if (!hasUpperCase || !hasNumber || !hasSymbol || !hasMinLength) {
			setEmsgNew('Password must contain at least one uppercase letter, one number, and one symbol');
		} else {
			setEmsgNew(false);
		}
	};

	const handleConfirmPasswordChange = (e) => {
		setConfirmPassword(e.target.value);
		if (password !== e.target.value) {
			setEmsgCurrent('Password is not matched!');
		} else {
			setEmsgCurrent(false);
		}
	};

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const [emsg, setEmsg] = useState('');
	const [emsgNew, setEmsgNew] = useState('');
	const [emsgCurrent, setEmsgCurrent] = useState('');
	const [rememberPwd, setRememberPwd] = useState(true);
	const isLoading = useSelector((state) => state.main.loading);
	const dispatch = useDispatch();

	// const onSubmit = (event) => {
	// 	event.preventDefault();
	// 	const data = new FormData(event.currentTarget);
	// 	console.log({
	// 		email: data.get('email'),
	// 		password: data..get('email')
	// 	});
	// };

	const [showOtpInput, setShowOtpInput] = useState(false);

	const handleResetPasswordClick = () => {
		setShowOtpInput(true);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		setIsSubmitting(true);
		const formData = new FormData(event.currentTarget);

		const data = {
			email: email,
			password: formData?.get('new-password')
		};
		console.log(data);

		dispatch(setLoading(true));

		api.setPassword(data).then((res) => {
			if (res.status) {
				history('/login');
			} else {
				setEmsg(res.message);
				setTimeout(() => setEmsg(''), 3000);
			}
			dispatch(setLoading(false));
		});
	};

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid container component="main" sx={{ height: '100dvh' }}>
				<Grid
					item
					xs={false}
					sm={false}
					md={6}
					// hidden={{ xs: true, sm: false, md: false }}
					sx={{
						// backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
						backgroundRepeat: 'no-repeat',
						// backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[900] : t.palette.grey[900]),
						background: 'radial-gradient(circle at left,#36214A,#161618 40%)',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						borderRight: '2px solid #373843'
					}}
					className="hidden-sm-grid-item"
				>
					<img src={Logo} style={{ width: '40%' }}></img>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					component={Paper}
					square
					sx={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#181823'
					}}
					container
					spacing={2}
				>
					<Box
						sx={{
							my: 8,
							mx: 12,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: '100%',
							justifyContent: 'center'
						}}
					>
						{matches && <img src={Logo} style={{ width: '290px', marginBottom: '40px' }} alt="Projectmix.ai" />}
						{/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} className='hidden-sm-grid-item'>
							<LockOutlinedIcon />
						</Avatar> */}
						{/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} > */}
						{/* </Avatar> */}
						<Typography component="h1" variant="h3" className="login-text-gray" style={{ fontSize: '24px' }}>
							<span>
								<strong>Hi!</strong>
								{email}
							</span>
						</Typography>
						<Typography component="h1" variant="h5" className="login-text-gray ">
							<p className="divider-text">
								<span>Set your new password here</span>
								<div className="line"></div>
							</p>
						</Typography>
						<Box
							component="form"
							noValidate
							onSubmit={onSubmit}
							sx={{ mt: 1 }}
							display="flex"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
						>
							{emsgNew && (
								<Alert
									severity="error"
									sx={{
										marginBottom: '5px',
										width: '309.15px'
									}}
								>
									{emsgNew}
								</Alert>
							)}
							<TextField
								margin="normal"
								required
								fullWidth
								name="new-password"
								label="New Password"
								type="password"
								id="new-password"
								className="login-form-field"
								style={{ WebkitTextFillColor: '#fff !important', width: '309.15px' }}
								value={password}
								onChange={handlePasswordChange}
								error={!!passwordError}
							/>
							{emsgCurrent && (
								<Alert
									severity="error"
									sx={{
										marginBottom: '5px',
										width: '309.15px'
									}}
								>
									{emsgCurrent}
								</Alert>
							)}
							<TextField
								margin="normal"
								required
								fullWidth
								name="confirm-password"
								label="Confirm Password"
								type="password"
								id="confirm-password"
								className="login-form-field"
								style={{ WebkitTextFillColor: '#fff !important', width: '309.15px' }}
								value={confirmPassword}
								onChange={handleConfirmPasswordChange}
								error={confirmPasswordError}
							/>

							{isSubmitting ? (
								<Button
									type="submit"
									className="signin-button"
									fullWidth
									variant="contained"
									style={{ width: '309.15px' }}
									sx={{ mt: 3, mb: 2, '&&': { color: 'white', backgroundColor: 'black' }, pointerEvents: 'none' }}
									disabled
								>
									Sending Confirmation <CircularProgress size={24} sx={{ ml: 1 }} />
								</Button>
							) : (
								<Button
									type="submit"
									className="signin-button"
									fullWidth
									variant="contained"
									style={{ Width: '309.15px' }}
									sx={{ mt: 3, mb: 2 }}
									disabled={!(password && confirmPassword && password === confirmPassword && !passwordError && !confirmPasswordError)}
								>
									Reset Password
								</Button>
							)}
							<Typography sx={{ mt: 10 }} className="login-text-gray" style={{ textAlign: 'center', fontSize: '12px' }}>
								Copyright ©{' '}
								<Link href="#" variant="body2">
									Projectmix
								</Link>{' '}
								2024.
							</Typography>
							{/* <Copyright sx={{ mt: 5 }} className='login-text-gray' /> */}
						</Box>
					</Box>
				</Grid>
			</Grid>
			{/* <Grid container component="main" sx={{ height: '100vh' }}>
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						// backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
						backgroundRepeat: 'no-repeat',
						// backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[900] : t.palette.grey[900]),
						background: 'radial-gradient(circle at left,#36214A,#161618 40%)',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column'
					}}
				>
					<img src={Logo} style={{ width: '40%' }}></img>
				</Grid>
				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					square
					sx={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#181823',
					}}
				>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: '100%'
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1 }}>
							{emsg && (
								<Alert
									severity="error"
									sx={{
										marginBottom: '5px'
									}}
								>
									{emsg}
								</Alert>
							)}
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								type="email"
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
							/>
							
							<FormControlLabel
								control={
									<Checkbox
										value="remember"
										color="primary"
										checked={rememberPwd}
										onChange={() => {
											setRememberPwd(!rememberPwd);
										}}
									/>
								}
								label="Remember me"
							/>
							<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
								Sign In
							</Button>
							<Grid container>
								<Grid item>
								</Grid>
								<Grid item xs>
									<Link href="#" variant="body2">
										Forgot password?
									</Link>
								</Grid>
							</Grid>
							<Copyright sx={{ mt: 5 }} />
						</Box>
					</Box>
				</Grid>
			</Grid> */}
		</ThemeProvider>
	);
}
