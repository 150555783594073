import React, { useEffect, useRef, useState } from 'react';
import { FaCheckCircle, FaFileUpload } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im';


function FileUpload({ onChange, multiple, accept, selectedValue, loading }) {
	const [file, setFile] = useState(null);
	const inputRef = useRef();

	const handleClick = () => {
		inputRef.current.click();
	};

	const handleFileChange = (e) => {
		if (e.target.files) {
			setFile(e.target.files[0]);
			onChange && onChange(e);
		}
	};

	useEffect(()=>{
		if (selectedValue) {
			setFile({name: selectedValue.file_name})
		}
	}, []);

	return (
		<>
			<div className="custom-file-upload-input" onClick={handleClick}>
				<div className="icon">{loading ? <ImSpinner2 className='fa-spin'/> : file ? <FaCheckCircle /> : <FaFileUpload />}</div>
				<div className="cfu-name">{file ? file?.name : 'Choose File'}</div>
			</div>
			<input type="file" hidden ref={inputRef} onChange={handleFileChange} multiple={multiple} accept={accept} />
		</>
	);
}

export default FileUpload;
