import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setContactlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import { FaEllipsisH, FaEye, FaPlus, FaSearch, FaTimes } from 'react-icons/fa';
import usePageTitle from 'hooks/usePageTitle';
import Filter from 'ui-component/filter-menu/Filter';
import { TbDatabaseExclamation } from 'react-icons/tb';
import toast from 'react-hot-toast';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import Select from 'react-select';
import { FaCheck } from 'react-icons/fa6';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { FaCircleCheck } from 'react-icons/fa6';
import { Skeleton, Tooltip } from '@mui/material';
import { BiBookAdd } from "react-icons/bi";


const Contacts = () => {
	const [setPageTitle] = usePageTitle('Contacts');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const history = useNavigate();
	const [userList, setUserList] = useState([]);
	const [show, setShow] = useState('');
	const [isLoading, setIsloading] = useState(false);
	const [isFiltersLoading, setIsFiltersloading] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [allPermissions, hasEveryPermission] = usePermissions();
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const [customerList, setContactlist] = useState();
	const [pagination, setpagination] = useState([]);
	const [salesPerson, setSalesperson] = useState([]);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});

	const getContactList = () => {
		setIsloading(true);
		api.getContact().then((res) => {
			setUserList(res?.data?.data);
			setpagination(res?.data);
			dispatch(setLoading(false));
			setIsloading(false);
			setContactlist(res?.data);
			isFirstLoad && setIsFirstLoad(false);
		});
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getContactFilter().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	useEffect(() => {
		getFiltersList();
	}, []);

	const deleteuserData = (data) => {
		setShow(data);
	};

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const handleClose = () => setShow('');

	const handleDelete = (id) => {
		api.deleteContact(id).then((res) => {
			if (res.status) {
				setShow('');
				getContactList();
				dispatch(storeInLocalStore({ stateAlertmessage: 'Contact Deleted Successfully.' }));
			}
		});
	};
	const viewUserData = (id) => {
		const currentUser = userList.filter((item) => item.id === id);
		history(`/contacts/${id}`);
	};

	const updateUserStatus = (id, status) => {
		toast.promise(
			api.updateContactStatus(id, status).then((res) => {
				const copy = [...userList];

				setUserList(
					copy.map((item) => {
						if (item.id == id) {
							return { ...item, status: status ? 1 : 0 };
						} else {
							return item;
						}
					})
				);

				return res?.status ? res : null;
			}),
			{
				loading: 'Updating Status...',
				success: (data) => `${data.message}`,
				error: <b>Failed to update status</b>
			}
		);
	};

	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsloading(true);
			if (text.length > 2) {
				api.filterContact(activeFilters, text).then((res) => {
					setIsloading(false);
					setUserList(res.data.data);
					setpagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsloading(false);
			} else {
				api.filterContact(activeFilters, null).then((res) => {
					setIsloading(false);
					setUserList(res.data.data);
					setpagination(res.data);
				});
			}
		}, 500);

		setSearchTimeout(timeout);
	};

	const updateUser = (id) => {
		const currentUser = userList.filter((item) => item.id === id);
		dispatch(storeInLocalStore({ selectedUser: currentUser[0] }));
		setTimeout(() => {
			history(`/contacts/edit/${id}`);
		}, 1000);
	};
	const handleData = (data) => {
		setUserList(data.data);
		setpagination(data);
	};
	useEffect(() => {
		api.getSelectOptionsList('salesperson').then((res) => {
			const tempsalesPerson = res.data;
			setSalesperson(tempsalesPerson);
		});
		getContactList();
	}, []);

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		backgroundColor: '#f2f2f2',
		zIndex: 1
	};
	const handleFilter = (activeF = null, searchF = null) => {
		setIsloading(true);
		api.filterContact(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsloading(false);
			setUserList(res.data.data);
			setpagination(res.data);
		});
	};
	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const addSalesManagerToggle = (index, e) => {
		setUserList((oldData) =>
			oldData?.map((item, i) => {
				if (index == i) {
					return {
						...item,
						addSalesManagerValue: e?.value
					};
				} else {
					return item;
				}
			})
		);
	};

	const clearSalesManagerSelect = (index) => {
		setUserList((oldData) =>
			oldData?.map((item, i) => {
				if (index == i) {
					return {
						...item,
						addSalesManagerValue: null
					};
				} else {
					return item;
				}
			})
		);
	};

	const assignSalesManager = (index) => {
		const cus_id = userList?.[index]?.id;
		const sm_id = userList?.[index]?.addSalesManagerValue;

		toast.promise(
			api
				.assignContactSalesPerson(cus_id, {
					sales_manager_id: sm_id
				})
				.then((res) => {
					if (res.status) {
						setUserList((oldData) =>
							oldData?.map((item, i) => {
								if (index == i) {
									return {
										...item,
										sales_manager: salesPerson?.find((s) => s.id == sm_id),
										addSalesManagerValue: null
									};
								} else {
									return item;
								}
							})
						);
					}

					return res?.status ? res : abc;
				}),
			{
				loading: 'Updating Sales Manger...',
				success: (data) => `${data.message}`,
				error: <b>Failed to update sales manager</b>
			}
		);
	};

	return (
		<>
			<div className="row justify-content-center m-0">
				{/* Area Chart */}
				<div className="medium-sized-container">
					<div className="col-xl-12 col-lg-12 p-0">
						<div className="section-head hide-on-mobile mb-1">
							<h2></h2>
							<div className="actions">
								<Link to={'/contacts/portal'} className="btn btn-primary btn-outlined-main">
									<BiBookAdd /> Portal Approval
								</Link>
								<PermissionAllowed permissions={['Create Contact (Button)']}>
									<Link to={'/contacts/create'} className="btn btn-primary btn-outlined-main-primary">
										<FaPlus /> New Contact
									</Link>
								</PermissionAllowed>
								{/* {console.log(filters)} */}
							</div>
						</div>
						<div className="card shadow-sm bg-white mb-4 mt-4 mt-md-0 table-card">
							<div className="d-flex align-items-center justify-content-between TableSerachBar">
								<div>
									<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
								</div>
								<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
									<div className="input-group">
										<input
											type="text"
											className="form-control bg-white border"
											placeholder="Search"
											aria-label="Search"
											aria-describedby="basic-addon2"
											onChange={handleSearch}
											style={{ width: '100%' }}
										/>
										<span className="searchIcon">
											<FaSearch />
										</span>
									</div>
								</form>
							</div>
							<div className="table-responsive-xl tableData customersList p-2">
								<Scroller extraHeight={57}>
									<table className="table pm-table">
										<tbody>
											{isLoading ? (
												<>
													{[...Array(5)].map((e, i) => (
														<tr>
															{[...Array(2)].map((e, i) => (
																<td>
																	<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																</td>
															))}
														</tr>
													))}
												</>
											) : (
												<>
													{userList?.map((row, index) => {
														const company_name = row?.name?.length <= 40 ? row.name : row.name?.slice(0, 40) + '...';
														let sales_manager = `${row?.sales_manager?.first_name || '-'} ${row?.sales_manager?.last_name || '-'}`;
														sales_manager =
															sales_manager?.length <= 20
																? sales_manager
																: `${row?.sales_manager?.first_name.slice(0, 10) || '-'} ${row?.sales_manager?.last_name.slice(0, 10) || '-'
																}...`;
														const email = !row?.email ? ' - ' : row?.email?.length <= 30 ? row?.email : row?.email?.slice(0, 30) + '...';

														return (
															<>
																<tr key={index}>
																	<td
																		className="align-middle task-date-col"
																		onClick={() => {
																			if (hasEveryPermission(['View Contact (Button)'])) {
																				viewUserData(row.id);
																			}
																		}}
																		style={{
																			cursor: hasEveryPermission(['View Contact (Button)']) ? 'pointer' : 'not-allowed'
																		}}
																	>
																		{/* <div
																			className="priority-line"
																			style={{
																				backgroundColor: 'red'
																			}}
																		>
																			&nbsp;
																		</div>  */}

																		{row?.verification_level?.percentage == '100' ? (
																			<Tooltip
																				title={`Verification percentage: ${row?.verification_level?.percentage}%`}
																				arrow
																				placement="top"
																			>
																				<span style={{ width: '25px' }} className="text-success">
																					<FaCircleCheck />
																				</span>
																			</Tooltip>
																		) : (
																			<span style={{ width: '25px' }}></span>
																		)}
																		<PrivacyModeContent>{company_name || '-'}</PrivacyModeContent>
																	</td>
																</tr>
															</>
														);
													})}
													{
														userList?.length < 1 && (
															<tr className="no-hover">
																<td>
																	<div className="not-found-container">
																		<div className="not-found-icon">
																			<img src={NoRecordFoundIcon} style={{ height: '180px', width: '180px' }} />
																		</div>
																		<h6 className="not-found-text">No Data Available</h6>

																		<PermissionAllowed permissions={['Create New Contact (Action)']}>
																			<Link to={'/contacts/create'} className="btn btn-primary btn-outlined-main">
																				Create Contact
																			</Link>
																		</PermissionAllowed>
																	</div>
																</td>
															</tr>
														)
													}
												</>
											)}
										</tbody>
									</table>
								</Scroller>
							</div>
							<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsloading(e)} />
						</div>
					</div>
				</div>
			</div>
			<DeleteConfirmation
				show={show}
				handleClose={handleClose}
				handleDelete={handleDelete}
				title="Confirm"
				message={`Are you sure you want to delete ${show.name}`}
			/>
		</>
	);
};

export default Contacts;
function CustomToggle({ children, onClick }) {
	return (
		<>
			<a
				className="dropdown-toggle"
				href="#"
				role="button"
				id="dropdownMenuLink"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				onClick={onClick}
			>
				{children}
			</a>
		</>
	);
}
