import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaSave } from 'react-icons/fa';
import { ImCancelCircle } from "react-icons/im";
import '../contactdetails.scss';
import Select from 'react-select';
import { fontSize, style } from '@mui/system';


function ClientPortalModel({
	show,
	handleClose,
	handleConfirm
}) {

	const confirmBtnRef = useRef(null);
    const activeOptions = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' }
    ];
    const [data,setData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        active: ''
    });

    const isValidate = (data) => {
        if(data.firstName === '' || data.lastName === '' || data.email === '' || data.password === '' || data.confirmPassword === '' || data.active === ''){
            return true;
        }
        if(data.password !== data.confirmPassword){
            return true;
        }

        return false;

    }

    const handleSubmit = () => {
        if(isValidate(data)){
            return;
        }
        handleConfirm(data);
    }
	useEffect(()=>{
		setTimeout(() => {
			confirmBtnRef?.current?.focus();
		}, 1);
	}, [])

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className=""
		>
			<Modal.Body>

                        <span
							className="modal-close"
							onClick={(e) => {
								handleClose && handleClose(e);
							}}
						>
							<ImCancelCircle />
						</span>
                <div className="main-content-client-modal">
                    <div className='heading-client-modal'>
                        <span style={{fontSize:'14px'}}>New user portal</span>
					    <p className="message-client">your customers can login at <Link to={'https://abc.projectmix.com/portal'} 
                        style={{color: '#0066FF', textDecoration: 'none' }}>
                            https://abc.projectmix.com/portal</Link></p>
				    </div>

                     <div className="" style={{ justifyContent: 'space-between' }}>
                         <table className='projectmix-table-vertical-client'>
                             <tbody>
                                 <tr>
                                     <th>First name</th>
                                     <td> 
                                        <input 
                                        type="text"
                                        className='form-control'
                                        placeholder='first name'
                                        onChange={(e) => {
                                            setData({...data,firstName: e.target.value});
                                        }
                                        }
                                        />
                                        </td>
                                 </tr>
                                 <tr>
                                     <th>Last name</th>
                                     <td><input type="text" className='form-control' placeholder='last name'
                                     onChange={(e) => {
                                        setData({...data,lastName: e.target.value});

                                     }}
                                     /> </td>
                                 </tr>
                                 <tr>
                                     <th>Email</th>
                                     <td><input type="email" className='form-control' placeholder='your email'
                                     onChange={(e) => {
                                            setData({...data,email: e.target.value});
                                     }}
                                     /> </td>
                                 </tr>
                                 <tr>
                                     <th>New password</th>
                                     <td> 
                                        <input 
                                        type="password" 
                                        className='form-control' 
                                        placeholder='generate new password'
                                        onChange={
                                           (e) => {
                                               setData({...data,password: e.target.value});
                                           }
                                        }/>
                                     </td>
                                 </tr>
                                  <tr>
                                     <th>Confirm password</th>
                                     <td> <input type="password" className='form-control' placeholder='confirm your password'
                                     onChange={
                                        (e) => {
                                            setData({...data,confirmPassword: e.target.value});
                                        }
                                     }
                                     /></td>
                                 </tr>
                                 <tr>
                                     <th>Active</th>
                                     <td> 
                                        <Select
											className="rs-container-est-white"
											classNamePrefix="rs"
											isSearchable={true}
											name="customer"
											options={activeOptions}
											onChange={(e) => {
												setData({...data,active: e.value});
											}}
										/>
                                     </td>
                                 </tr>
                             </tbody>
                         </table>
					</div>

                    <div className="modal-actions modal-client">
					
						<button
							ref={confirmBtnRef}
							className={`btn btn-primary btn-outlined-main`}
							onClick={() => {
                                handleSubmit()
							}}
						>
						<FaSave />	Save
						</button>
				    </div>

                </div>
				

				
			</Modal.Body>
		</Modal>
	);
}

export default ClientPortalModel;
