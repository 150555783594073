import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setReturncomponent, setEstimatedata, storeInLocalStore } from 'store/reducers/mainSlice';
import 'react-datepicker/dist/react-datepicker.css';
import { FaArrowRight, FaEdit, FaMinus, FaPlus, FaRegSave, FaSave, FaTimes } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaCheck, FaDownload, FaPen } from 'react-icons/fa6';
import { LuChevronDown } from 'react-icons/lu';
import { Dropdown } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Notes from 'ui-component/notes';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill } from 'react-icons/pi';
import { getCurrencySymbol } from 'utils/currency';
import config from 'config.js';
import { useQuery, useQueryClient } from 'react-query';
import { getLocalStorageCacheItem, setLocalStorageCacheItem } from 'helpers/common.helper';
import md5 from 'md5-hash';
import AcceptOrderModal from 'ui-component/confirmation-dialogs/acceptOrderModal/AcceptOrderModal';
import agreementImage from 'assets/images/agreement.svg';
import EstimateDetailInvoice from './EstimationDetailnvoice';

const EstimationDetails = ({ id, openAgreementPopup, handleClose, handleSave, pending, type }) => {
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const userEmail = useSelector((state) => state.main.user?.email);

	const [isInternalLoading, setInternalLoading] = useState(false);

	// Customer Create
	const [selectedEstimate, setSelectedEstimate] = useState();

	const dispatch = useDispatch();

	// End Create
	const history = useNavigate();
	const [modalPrice, setModalprice] = useState('00');

	const [showModal, setShowModal] = useState(false);
	const [shippingRow, setShippingrow] = useState({
		quantity: 1,
		unitPrice: 0,
		totalPrice: 0
	});

	const app_name = config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.app_name || 'abcd';
	const client_name = config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.client_name || 'abcd';

	const [selectedCustomerinfo, setSelectedCustomerInfo] = useState({});
	const currentDate = moment().format('YYYY-MM-DD');
	const [subTotal, setSubtotal] = useState('00');
	const [grandTotal, setGrandtotal] = useState('00');

	const [orderType, setOrderType] = useState('');

	const queryClient = useQueryClient();
	const params = useParams();
	const location = useLocation();

	const invalidateEstQuery = () => {
		queryClient.invalidateQueries({ queryKey: ['estimation-details', id] });
	};

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => {},
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	const [estimateRow, setEstimaterow] = useState({
		customer_id: '',
		estimate_number: '',
		sales_manager: '',
		transit_id: '',
		vatStatus: 'Including VAT',
		vatPrice: 21,
		startDate: currentDate,
		expiresIn: 7,
		currency: 'eur',
		notes: []
	});
	// Row Area
	const [rows, setRows] = useState([{ id: 1, modelId: '', type: '', quantity: 0, unitPrice: 0, total: 0 }]);
	const [nextId, setNextId] = useState(2);

	useEffect(() => {
		setEstimaterow((oldData) => ({ ...oldData, sales_manager: selectedCustomerinfo?.sales_manager_id }));
	}, [selectedCustomerinfo]);

	const [currentAppName, setCurrentAppName] = useState();
	const [currentClientName, setCurrentClientName] = useState();

	useEffect(() => {
		initiateDatas();
		setCurrentAppName(app_name);
		setCurrentClientName(client_name);
	}, []);

	useEffect(() => {
		if (pending) {
			api.getQoutationById(id).then((res) => {
				setSelectedEstimate(res?.data?.data);
			});
		} else {
			api.getEstimationByQoutationId(id).then((res) => {
				console.log(res);
				setSelectedEstimate(res?.data);
			});
		}

		if (selectedEstimate?.invoice_number == '' && selectedEstimate?.estimation_number != '') {
			setOrderType('selectedEstimate');
		} else if (selectedEstimate?.invoice_number != '' && selectedEstimate?.estimation_number != '') {
			setOrderType('invoice');
		} else {
			setOrderType('credit-note');
		}
	}, [id]);

	const initiateDatas = () => {
		if (id) {
		} else {
			history('/order');
		}
	};

	useEffect(() => {
		if (selectedEstimate) {
			const expDate = moment(selectedEstimate?.expires_at).format('YYYY-MM-DD');
			let tempRow = selectedEstimate?.estimation_details.map((item, index) => {
				return {
					id: index,
					modelId: item?.product_id,
					type: item?.product?.product_name,
					quantity: item?.qty,
					unitPrice: Number(item?.unit_price),
					total: item?.total_price
				};
			});

			tempRow = [
				...tempRow,
				{
					id: tempRow.length + 1,
					modelId: '',
					type: '',
					quantity: '',
					unitPrice: '',
					total: ''
				}
			];

			setRows(tempRow);
			setNextId(tempRow.length + 1);
			setSelectedCustomerInfo(selectedEstimate.customer);
			setSubtotal(selectedEstimate?.subtotal);

			const temEstimate = {
				estimate_number: selectedEstimate?.estimation_number,
				customer_id: selectedEstimate?.customer_id,
				startDate: selectedEstimate?.start_date,
				expiryDate: expDate,
				transit_id: selectedEstimate?.transit_company?.id,
				sales_manager: selectedEstimate?.sales_person?.id,
				private_note: selectedEstimate?.private_note,
				vatStatus: selectedEstimate?.vat_type,
				vatPrice: selectedEstimate?.vat_percent || 21,
				currency: selectedEstimate?.currency,
				private_note: selectedEstimate?.private_note,
				expiresIn: moment(selectedEstimate?.expires_at).diff(selectedEstimate?.start_date, 'days')
			};

			let notesArray = [];

			selectedEstimate?.notes?.forEach((note) => {
				notesArray = [
					{
						id: note?.id,
						details: note?.details,
						date: moment(note?.created_at).format('YYYY-MM-DD'),
						time: moment(note?.created_at).format('HH:mm'),
						user_name: `${note?.user_created?.first_name} ${note?.user_created?.last_name}`,
						expand: false,
						isDeletable: note?.is_deletable
					},
					...notesArray
				];
			});

			notesArray = _.sortBy(notesArray, (n) => -n.id);

			const tempShipping = {
				unitPrice: selectedEstimate?.shipping_fees,
				quantity: selectedEstimate?.shipping_qty,
				totalPrice: Number(selectedEstimate?.shipping_fees) * Number(selectedEstimate?.shipping_qty)
			};
			setEstimaterow({ ...temEstimate, notes: notesArray });
		}
	}, [selectedEstimate]);

	const calculateTotal = () => {
		let total = 0;
		let grandTotal = 0;
		for (let i = 0; i < rows.length; i++) {
			const { quantity, unitPrice } = rows[i];
			total += parseFloat(quantity || 0) * parseFloat(unitPrice || 0);
		}

		if (shippingRow?.totalPrice > 0) {
			const shippingTotal = parseFloat(shippingRow.totalPrice);
			grandTotal = total + shippingTotal;
		} else {
			grandTotal = total;
		}

		if (isNaN(grandTotal)) {
			return { subtotal: '00', modelTotal: '00' };
		}
		return { subtotal: grandTotal, modelTotal: total };
	};

	useEffect(() => {
		let tempsubtotal = calculateTotal();
		const subTotals = parseFloat(tempsubtotal?.subtotal).toFixed(2);
		const modaltotal = parseFloat(tempsubtotal?.modelTotal).toFixed(2);
		setSubtotal(subTotals);
		setModalprice(modaltotal);
	}, [rows, shippingRow, estimateRow]);

	useEffect(() => {
		if (estimateRow?.vatStatus == 'Excluding VAT' && estimateRow?.vatPrice > 0) {
			let tempvat = (modalPrice * (estimateRow?.vatPrice / 100)).toFixed(2);
			let temTotal = (parseFloat(subTotal) + parseFloat(tempvat)).toFixed(2);
			setGrandtotal(temTotal);
		} else {
			setGrandtotal(subTotal);
		}
	}, [subTotal, modalPrice, estimateRow]);

	const downloadEstimate = (template, to) => {
		// toast.promise(
		api.downloadEstimate(id, { template, to }).then((res) => {
			// if (res?.status) {

			var newBlob = new Blob([res], { type: 'application/pdf' });
			saveAs(
				newBlob,
				`${selectedEstimate?.transaction_type == 'Invoice' ? selectedEstimate?.invoice_number : selectedEstimate?.estimation_number}.pdf`
			);
		});
	};

	// Create Customer

	const changeStatus = (data) => {
		const id = data.id;
		const status = data.status;
		showConfirmationDialog('Update Status', 'Are You sure you want to update the status of this order?', () => {
			api.changeestimateStatus({ estimation_id: id, status: status }).then((res) => {
				if (res.status) {
					initiateDatas();
				}
				handleCloseConfirmationDialog();
				invalidateEstQuery();
				return res?.status ? res : abc;
			}),
				{
					loading: 'Updating Invoice Status...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update status</b>
				};
		});
	};

	const redirectFunc = (data) => {
		if (data?.bankDetails == true && data?.ttCopies == true && data?.vatRegistration == true) {
			dispatch(setReturncomponent('Accepted'));
			setTimeout(() => {
				history('/order');
			}, 1000);
		} else {
			dispatch(setReturncomponent('Estimated'));
			setTimeout(() => {
				history('/order');
			}, 1000);
		}
	};
	const disablepopup = () => {
		handleClose();
	};

	const [showAcceptPopup, setShowAcceptPopup] = useState(null);

	const acceptPopup = () => {
		setShowAcceptPopup({
			id: selectedEstimate?.id
		});
	};

	const handleAcceptConfirm = () => {
		toast.promise(
			api
				.acceptedEstimate({
					estimation_id: selectedEstimate?.id
				})
				.then((res) => {
					if (res.status) {
						setShowAcceptPopup(null);
						invalidateEstQuery();
					}
				}),
			{
				loading: 'Accepting Estimate...',
				success: <b>Estimate Accepted!</b>,
				error: <b>Could not Accept Estimate.</b>
			}
		);
	};
	const confirm = () => {
		handleSave(id);
	};

	return (
		<>
			<Modal show={openAgreementPopup} handleClose={disablepopup} onHide={disablepopup} size="lg" className="bg-transparent">
				<div className="row m-0 w-100 est-nv-container">
					<div className="col-12 p-0 p-md-2">
						<div className="section-head-new pl-0 mb-1 mt-2">
							<div className="actions" style={{ gap: '5px', width: 'auto' }}>
								{selectedEstimate?.master_status?.status_type_id == 2 ? (
									<></>
								) : (
									<>
										{/* <button
												type="button"
												className="btn btn-primary btn-outlined-main white-var m-0"
												style={{ minWidth: 'unset', margin: '0 !important' }}
												disabled={isInternalLoading || !estimateRow?.estimate_number}
												onClick={() => {
													if (selectedEstimate?.logistic?.name) {
														!!selectedEstimate?.transit_company
															? downloadEstimate('logistic', 'customer')
															: downloadEstimate('mixglobal', 'customer');
													} else if (selectedEstimate?.transit_company?.name) {
														!!selectedEstimate?.transit_company
															? downloadEstimate('transitcompany', 'customer')
															: downloadEstimate('mixglobal', 'customer');
													}
												}}
											>
												<FaDownload />
												Downloads
											</button> */}
									</>
								)}
							</div>
							<div className="actions" style={{ gap: '5px' }}></div>
						</div>
						<div className="row m-0">
							<div className="card shadow-sm rounded bg-white mb-3 p-3 p-xl-4 w-100">
								<EstimateDetailInvoice estimate={selectedEstimate} handleClose={handleClose} type={type} />
								{selectedEstimate?.master_status?.name === 'Pending' && selectedEstimate?.order_agreements?.length < 1 && (
									<div className="action-confirm">
										<button className="btn btn-primary btn-confirm-agreement" onClick={() => confirm(id)}>
											Confirm
										</button>
										<button className="btn btn-cancel" onClick={() => disablepopup()}>
											Cancel
										</button>
									</div>
								)}
							</div>
							{selectedEstimate?.order_agreements?.length > 0 && (
								<div className="card shadow-sm rounded bg-white mb-3 p-3 p-xl-4 w-100">
									<div className="row">
										<div className="col-12">
											{orderType != 'credit-note' && (
												<>
													{selectedEstimate?.order_agreements?.length > 0 && (
														<>
															<div className="row">
																<div className="col-12">
																	<div className="text-center mb-3 pt-2">
																		<img src={agreementImage} alt="" height="70px" className="agreement-img" />
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<h4 className="text-center mb-3">SALES AGREEMENT</h4>
																</div>
															</div>
															<div className="row">
																<div className="col-md-10 offset-md-1">
																	<p className="text-center mb-3">
																		This agreement is made on{' '}
																		<Link to="#">{moment(selectedEstimate?.invoiceDate).format('DD MMMM YYYY')}</Link> between{' '}
																		<a className="block_a" target="_blank">
																			<PrivacyModeContent>{selectedEstimate?.customer?.name}</PrivacyModeContent>
																		</a>
																		,{' '}
																		{selectedEstimate?.customer?.address && (
																			<>
																				located at{' '}
																				<a className="block_a">
																					<PrivacyModeContent>
																						{selectedEstimate?.customer?.address ? `${selectedEstimate?.customer?.address}, ` : ''}
																						{selectedEstimate?.customer?.zipcode ? `${selectedEstimate?.customer?.zipcode}, ` : ''}
																						{selectedEstimate?.customer?.city ? `${selectedEstimate?.customer?.city}, ` : ''}
																						{selectedEstimate?.customer?.country ? `${selectedEstimate?.customer?.country}` : ''}
																					</PrivacyModeContent>
																				</a>{' '}
																			</>
																		)}
																		and{' '}
																		{selectedEstimate?.transit_company?.name && (
																			<>
																				<a className="block_a" target="_blank">
																					{selectedEstimate?.transit_company?.name}
																				</a>{' '}
																				{selectedEstimate?.transit_company?.address && (
																					<>
																						located at{' '}
																						<a className="block_a">
																							{selectedEstimate?.transit_company?.address
																								? `${selectedEstimate?.transit_company?.address}, `
																								: ''}
																							{selectedEstimate?.transit_company?.zipcode
																								? `${selectedEstimate?.transit_company?.zipcode}, `
																								: ''}
																							{selectedEstimate?.transit_company?.city
																								? `${selectedEstimate?.transit_company?.city}, `
																								: ''}
																							{selectedEstimate?.transit_company?.country
																								? `${selectedEstimate?.transit_company?.country}`
																								: ''}
																						</a>
																						.
																					</>
																				)}
																			</>
																		)}
																	</p>
																</div>
															</div>

															<div className="row">
																<div className="col-md-10 offset-md-1">
																	<p className="text-center mb-3">
																		Both parties confirm that they have mutually agreed upon the following conditions, as negotiated between{' '}
																		<b>
																			{selectedEstimate?.sales_person?.first_name} {selectedEstimate?.sales_person?.last_name}
																		</b>
																		,{' '}
																		{selectedEstimate?.transit_company?.name && (
																			<> representing {selectedEstimate?.transit_company?.name}</>
																		)}
																		, and{' '}
																		{selectedEstimate?.customer?.contact_name ? (
																			<>
																				<Link to={`contact/${selectedEstimate?.customer?.id}`} target="_blank">
																					<PrivacyModeContent>{selectedEstimate?.customer?.name}</PrivacyModeContent>
																				</Link>
																				, the representative of{' '}
																			</>
																		) : (
																			<></>
																		)}{' '}
																		the representative of{' '}
																		<a className="block_a" target="_blank">
																			<PrivacyModeContent>{selectedEstimate?.customer?.name}</PrivacyModeContent>
																		</a>
																		.
																	</p>
																</div>
															</div>
															{selectedEstimate?.order_agreements?.length > 0 && (
																<div className="order-agreements-detail-section">
																	<>
																		{selectedEstimate?.order_agreements?.map((ag) => (
																			<div className="order-agreements-detail">
																				<h6>{ag?.title}</h6>
																				{ag?.type == 'multiselect' ? (
																					ag?.responses?.map((res) => <p>{res?.option?.option}</p>)
																				) : (
																					<p>{ag?.response?.label}</p>
																				)}
																			</div>
																		))}
																		<div className="row">
																			<div className="col-12">
																				<p
																					className="d-flex justify-content-center align-items-center text-center mb-4 pt-4"
																					style={{ gap: '10px', color: '#838383' }}
																				>
																					i have read and agreed to the terms of the agrrement and to the terms of condition
																				</p>
																				{selectedEstimate?.master_status?.name === 'Pending' && (
																					<div className="action-confirm">
																						<button className="btn btn-primary btn-confirm-agreement" onClick={() => confirm(id)}>
																							Confirm
																						</button>
																						<button className="btn btn-cancel" onClick={() => disablepopup()}>
																							Cancel
																						</button>
																					</div>
																				)}
																			</div>
																		</div>
																	</>
																</div>
															)}
														</>
													)}
												</>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default EstimationDetails;
