// TopProducts.js
import React from 'react';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

const TopProducts = ({ topProducts, isAdmin }) => {
	return (
		topProducts.length > 0 && (
			<div className="col-lg-3 col-md-6 col-sm-12">
				<Fade>
					<div
						className="card"
						style={{ height: isAdmin == true ? '280px' : '270px', padding: '10px', paddingTop: '5px', marginBottom: '20px' }}
					>
						<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: '10px' }}>
							<a style={{ fontWeight: '500' }}>Top 10 Products</a>
						</div>
						<div
							className="card-body custom-no-scroll"
							style={{ paddingTop: 5, paddingBottom: 0, paddingRight: 15, overflowY: 'auto', maxHeight: '200px' }}
						>
							{topProducts.map((item, index) => (
								<Link to={`/products/${item.id}`} style={{ color: 'black', textDecoration: 'none' }}>
									<div className="row hover-effect" key={index} style={{ paddingBottom: '4px', paddingTop: '3px' }}>
										<div className="col-9 top-clients">
											<a style={{ whiteSpace: 'nowrap', fontWeight: 100 }}>
												<PrivacyModeContent>{item.product_name}</PrivacyModeContent>
											</a>
										</div>
										<div className="col-3 top-clients" style={{ textAlign: 'right' }}>
											<a style={{ fontWeight: 600 }}>
												<PrivacyModeContent>{item.total_quantity_sold}</PrivacyModeContent>
											</a>
										</div>
									</div>
								</Link>
							))}
						</div>
					</div>
				</Fade>
			</div>
		)
	);
};

export default TopProducts;
