import React, { useEffect, useRef, useState } from 'react';
import assignbyIcon from 'assets/images/icons/assign-by.svg';
import calendarIcon from 'assets/images/icons/feather-calendar.svg';
import rejectIcon from 'assets/images/icons/reject.svg';
import inspactIcon from 'assets/images/icons/inspact.svg';
import checkIcon from 'assets/images/icons/check-green.svg';
import closetIcon from 'assets/images/icons/close-red.svg';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { storeInLocalStore, setstateCollectionList, setMessageData } from 'store/reducers/mainSlice';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Loader from 'ui-component/Loader';
import moment from 'moment';
import Scroller from 'ui-component/Scroller';
import { getStatusColor } from 'views/Operations/TaskGrid/Task';
import { Avatar, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';

import '../processindicator.scss';
import { FaCheck, FaPrint } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im';
import Barcode from 'react-barcode';
import { BsClipboardCheck } from 'react-icons/bs';
import { GrTest } from 'react-icons/gr';
import { FaBoxesStacked } from 'react-icons/fa6';
import { GiAutoRepair, GiHandTruck, GiMagnifyingGlass } from 'react-icons/gi';
import { BiCategoryAlt } from 'react-icons/bi';
import { PiInfoFill, PiPackageBold, PiTestTubeFill } from 'react-icons/pi';
import ProcessIndicator from '../ProcessIndicator';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { useSearchParams } from 'react-router-dom';
import PrintPreviewDialog from 'ui-component/PrintPreviewDialog';
import { width } from '@mui/system';
import mgLogo from 'assets/images/mglogo.svg';
import './segregation-pp.scss';
import PrintContent from 'ui-component/PrintPreviewDialog/PrintContent';
import { useReactToPrint } from 'react-to-print';
import { IoMdBarcode } from 'react-icons/io';
import { taskboardProgresses } from 'assets/helperdata/taskboard-progrss';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

const SegregationDetails = () => {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const processFormPrintRef = useRef();
	const handlePrintProcessForm = useReactToPrint({
		bodyClass: 'print-processing-form',
		content: () => processFormPrintRef.current
	});
	const [selectedPrintData, setSelectedPrintData] = useState(null);
	const history = useNavigate();
	const selectedId = useSelector((state) => state.main.user.selectedtaskid);
	const [isLoading, setLoading] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [inspectInfo, setInspectInfo] = useState();
	const [dueDate, setDueDate] = useState();
	const [startDate, setStartDate] = useState();
	const [isInProgress, setIsInProgress] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const onBoxPrintClick = (e) => {
		setSelectedPrintData(e);

		setTimeout(() => {
			handlePrintProcessForm();
		}, 1);
	};

	const onClickPrintProcessForm = () => {};

	const showConfirmationDialog = (title, message = '', onConfirm = () => {}, onClose = () => {}) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	const getSegregationDetails = (callback = () => {}) => {
		setLoading(true);
		setIsDisable(true);
		const data = {
			selectedId: selectedId,
			type: 'accept'
		};
		api.getsegregationdetailsbyid(id).then((res) => {
			setLoading(false);
			setIsDisable(false);
			setInspectInfo(res.data);
			callback(res.data);
		});
	};

	useEffect(() => {
		if (id) {
			getSegregationDetails((data) => {
				setIsInProgress(!!data?.start_at);
				if (!data?.start_at) {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Start Segregation?',
							'Are you sure you want to start segregation without looking at its details?',
							() => {
								handleStart();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				} else {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Complete Segregation?',
							'Are you sure you want to complete segregation without looking at its details?',
							() => {
								handleSubmit();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const onStartClick = () => {
		showConfirmationDialog(
			'Start Segregation?',
			'Are you sure you want to start segregating?',
			() => {
				handleStart();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleStart = () => {
		setIsDisable(true);
		toast.promise(
			api.startSegregation(id).then(
				(res) => {
					if (res.status) {
						setIsInProgress(true);
					}

					setIsDisable(false);
					return res?.status ? res : null;
				},
				() => {
					setIsDisable(false);
				}
			),
			{
				loading: 'Starting Segregation...',
				success: (data) => `${data.message}`,
				error: <b>Failed to start Segregation</b>
			}
		);
	};

	const onDoneClicked = () => {
		showConfirmationDialog(
			'Complete Segregation?',
			'Are you sure you want to complete segregation?',
			() => {
				handleSubmit();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleSubmit = () => {
		setIsDisable(true);
		const data = { task_segregation_id: id, action_type: 'submit' };
		toast.promise(
			api.saveSegregationData(data).then(
				(res) => {
					if (res.status) {
						setIsDisable(false);
						history('/operations/segregation');
					}
					return res?.status ? res : null;
				},
				() => {
					setIsDisable(false);
				}
			),
			{
				loading: 'Saving Collection...',
				success: (data) => `${data.message}`,
				error: <b>Failed to save collection</b>
			}
		);
	};
	const goback = () => {
		history('/operations/segregation');
	};
	return (
		<>
			<ProcessIndicator isInProgress={isInProgress} processes={taskboardProgresses} currentIndex={2} />

			<div className={`TaskDetails`}>
				<div className="d-flex justify-content-between mb-0">
					<div className="topBtnSign" style={{ '--statusColor': getStatusColor(!inspectInfo?.end_at ? 'blue' : 'green') }}>
						<span>{inspectInfo?.start_at && inspectInfo?.end_at ? 'Completed' : isInProgress ? 'Inprogress' : 'Upcoming'}</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card p-4">
							<Scroller>
								<div className="row mb-5">
									<div className="col-12 d-flex justify-content-between mb-4">
										<h3 className="font-weight-bold m-0">{inspectInfo?.task_collection?.supplier?.name}</h3>
										{/* <div
											className={`d-flex align-items-center justify-content-end datediv mb-4`}
											style={{ '--statusColor': getStatusColor(inspectInfo?.priority_status?.color) }}
										>
											<div className="boxDate">{moment(inspectInfo?.due_date).format('DD MMM HH:mm')}</div>
										</div> */}
										{isInProgress && (
											<button className="btn btn-primary btn-outlined-main">
												{' '}
												<FaPrint /> Print
											</button>
										)}
									</div>
									<div className="col-12">
										<div className="row">
											<div className="col-md-4 mb-2 mb-md-0 detail-item">
												<p className="detail-label">Reference.</p>
												<div className="detail-value"> {inspectInfo?.reference_no} </div>
											</div>

											<div className=" col-md-4 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Collected On</p>
												<div className="detail-value">
													{moment(inspectInfo?.collected_on).format('DD MMM YYYY hh:mm A')}
												</div>
											</div>

											<div className="col-md-4 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label" style={{ fontSize: '11px' }}>
													Quantity
												</p>
												<div className="detail-value">{inspectInfo?.task_collection?.total_qty} Pcs</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									{inspectInfo?.task_collection?.items?.map((sbox) => (
										<div className="col-md-4 mb-2">
											<div className="estimate-product-list-box">
												<div className="epi-header">
													<h6>{sbox?.invoice_number}</h6>

													<p>
														<PrivacyModeContent>{sbox?.customer?.name}</PrivacyModeContent>
													</p>
												</div>
												<table className="table-sm table-borderless" width="100%">
													<tbody>
														{sbox?.items?.map((sboxItem) => (
															<tr>
																<td className="align-middle">{sboxItem?.product}</td>
																<td className="align-middle">{sboxItem?.collect_qty}Pcs</td>
															</tr>
														))}
													</tbody>
												</table>
												{isInProgress && (
													<div className="epi-footer">
														<div className="w-100">
															<div className="barcode">
																<Barcode value={sbox?.reference_no} height={30} fontSize={12} />
															</div>
															<button
																className="btn btn-primary btn-outlined-main w-100"
																style={{ padding: '5px' }}
																onClick={() => {
																	onBoxPrintClick(sbox);
																}}
															>
																<FaPrint /> Print
															</button>
														</div>
													</div>
												)}
											</div>
										</div>
									))}
								</div>
							</Scroller>
							<div className="btnarea">
								<span
									onClick={() => {
										goback();
									}}
									className={`btn btn-link ${isDisable ? 'disabled' : ''}`}
								>
									Back
								</span>
								<PermissionAllowed permissions={['Start / End Task Segregation (Action)']}>
									{inspectInfo?.status != 'completed' &&
										(isInProgress ? (
											<span
												onClick={() => {
													onDoneClicked();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Done
											</span>
										) : (
											<span
												onClick={() => {
													onStartClick();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Start
											</span>
										))}
								</PermissionAllowed>
							</div>
						</div>
					</div>
				</div>
			</div>
			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}

			<div className="d-none">
				<PrintContent ref={processFormPrintRef}>
					<div className="segregation-print-preview-content">
						<h6 className="main-title">Processing Form</h6>
						<table className="detail-table mb-5">
							<tr>
								<td>
									<table>
										<tr>
										<div className="est-detail-logo" style={{ height: '36px' }}>
											<img src={mgLogo} height={'36px'} />
										</div>
										</tr>
									</table>
								</td>
							</tr>
						</table>
						<table className="detail-table">
							<tr>
								<td>
									<table>
										<tr>
											<td>Date of Arrival:</td>
											<td>{moment(inspectInfo?.collected_on).format(CommonDateOnlyFormat)}</td>
										</tr>
										<tr>
											<td>Customer:</td>
											<td><PrivacyModeContent>{selectedPrintData?.customer?.name}</PrivacyModeContent></td>
										</tr>
										<tr>
											<td>Estimate No:</td>
											<td>{selectedPrintData?.invoice_number}</td>
										</tr>
										<tr>
											<td>Supplier:</td>
											<td>{inspectInfo?.task_collection?.supplier?.name}</td>
										</tr>
										<tr>
											<td>Date of Departure: </td>
											<td>&nbsp;</td>
										</tr>
									</table>
								</td>
								<td>
									<div
										style={{
											width: '100%',
											height: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										}}
										className="barcode-div"
									>
										<Barcode value={selectedPrintData?.reference_no} width={'2'} height={'100%'} fontSize={12} />
									</div>
								</td>
							</tr>
						</table>

						<table border={1} className="actual-order-table">
							<thead>
								<tr>
									<th colSpan={3} style={{ textAlign: 'center' }}>
										Actual full Order
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Model</th>
									<th>Qty</th>
									<th>Shipped</th>
								</tr>
								{inspectInfo?.estimation_items
									?.filter((i) => i?.invoice_number == selectedPrintData?.invoice_number)
									?.map((el) => (
										<tr>
											<td>{el?.product}</td>
											<td>{el?.total_qty}</td>
											<td>&nbsp; &nbsp;</td>
										</tr>
									))}
							</tbody>
						</table>

						<table width={'100%'} border={1} className="status-table">
							<thead>
								<tr>
									<th colSpan={9} style={{ textAlign: 'center' }}>
										Status
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Model</th>
									<th>Qty</th>
									<th>Ready Qty</th>
									<th>Housing</th>
									<th>Polish</th>
									<th>Side Polish</th>
									<th>Glass Polish & Housing</th>
									<th>Glass Change & Side Polish</th>
									<th>Repair Qty</th>
								</tr>

								{selectedPrintData?.items?.map((item) => (
									<tr>
										<td>{item?.product}</td>
										<td>{item?.collect_qty}</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
									</tr>
								))}
							</tbody>
						</table>

						<table width={'100%'} border={1} className="notes-table">
							<thead>
								<tr>
									<th style={{ textAlign: 'center' }}>Notes</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td rowSpan={5}>
										&nbsp; <br />
										&nbsp; <br />
										&nbsp; <br />
										&nbsp; <br />
										&nbsp; <br />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</PrintContent>
			</div>
		</>
	);
};

export default SegregationDetails;
