import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const InvoiceModal = ({ openPopup, returnsDetail, handleClose, handleSave, disableStatus }) => {
	const [alertMessage, setAlertMessage] = useState();
	const [invoiceList, setInvoiceList] = useState([]);

	const [formInfo, setFormInfo] = useState({
		estimationId: ''
	});
	const handleValue = (val) => {
		setFormInfo({
			...formInfo,
			estimationId: val?.id
		});
		setFormInfo({ estimationId: val?.value });
	};

	useEffect(() => {
		api.getUnassignedInvoiceNumberList().then((res) => {
			const tempInvoiceList = res.data.map((item) => ({
				id: item.id,
				label: `${item.invoice_number}`,
				value: item.id
			}));
			setInvoiceList(tempInvoiceList);
		});
		// setFormInfo({
		// 	...formInfo,
		// 	productId: returnsDetails?.id
		// });
		console.log(invoiceList);
	}, []);

	const onSubmit = () => {
		if (formInfo.estimationId == '') {
			setAlertMessage({
				user: 'Please select order.'
			});
		} else {
			const data = {
				...formInfo,
				estimationId: formInfo.estimationId
			};
			handleSave(data);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const disablepopup = () => {
		handleClose();
	};
	return (
		<>
			<Modal show={openPopup} onHide={disablepopup} centered size="sm">
				<Modal.Body>
					<div className="p-2">
						<div className="foundListPopup">
							<div className="mb-2 ml-0 pl-0">
								<div className="row">
									<div className="col-12 text-center">
										<svg xmlns="http://www.w3.org/2000/svg" width="41.06" height="51.271" viewBox="0 0 51.06 61.271">
											<path
												id="Union_55"
												data-name="Union 55"
												d="M10329.825,13997.133l-8.9-8.9a17.754,17.754,0,0,0-12.635-5.233h-11.526a12.781,12.781,0,0,0-12.765,12.765v35.741a12.781,12.781,0,0,0,12.765,12.766h25.529a12.781,12.781,0,0,0,12.766-12.766v-21.738A17.752,17.752,0,0,0,10329.825,13997.133Zm-3.609,3.61a12.84,12.84,0,0,1,1.994,2.681h-11.021a2.553,2.553,0,0,1-2.553-2.553v-11.021a12.806,12.806,0,0,1,2.681,1.994Zm3.737,30.763a7.659,7.659,0,0,1-7.659,7.659h-25.529a7.659,7.659,0,0,1-7.659-7.659v-35.741a7.66,7.66,0,0,1,7.659-7.659h11.526c.422,0,.825.082,1.238.12v12.646a7.659,7.659,0,0,0,7.659,7.658h12.645c.038.414.12.817.12,1.238Z"
												transform="translate(-10284 -13983)"
											/>
										</svg>
									</div>
								</div>
								<div className="row">
									<div className="col-12 text-center" style={{ margin: '15px 0' }}>
										<h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0">Select {openPopup}</h5>
									</div>
								</div>
								<button className="btn modal-close-icon" onClick={handleClose}>
									<svg
										id="Component_127_1"
										height="16"
										width="16"
										data-name="Component 127 – 1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 19.295 19.289"
									>
										<path
											id="Icon_ionic-ios-close"
											data-name="Icon ionic-ios-close"
											d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
											transform="translate(-11.285 -11.289)"
										/>
									</svg>
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-12">
									<form className="assign found">
										<div className="row">
											<div className="form-group col-md-12">
												{/* <label className="text-gray-900">{ openPopup }</label> */}

												<Select
													className="rs-container"
													classNamePrefix="rs"
													isSearchable={true}
													name="user"
													options={invoiceList}
													placeholder="Select"
													onChange={(selected) => handleValue(selected)}
													style={{ padding: '1.175rem .75rem' }}
												/>
												{alertMessage?.user && (
													<>
														<span className="error-message text-danger">{alertMessage?.user}</span>
													</>
												)}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="btnarea">
							<div className="">
								<div className="row">
									<div className="col-12 text-center">
										<button disabled={disableStatus} onClick={onSubmit} className="btn btn-primary btn-outlined-main">
											<FaPlus /> Confirm
										</button>
									</div>
								</div>
								<div className="row" style={{ marginTop: '10px' }}>
									<div className="col-12 text-center">
										<a href="javascript:void(0);" onClick={handleClose}>
											Cancel
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default InvoiceModal;
