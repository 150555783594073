import React from 'react';
import { LiaSortDownSolid, LiaSortSolid, LiaSortUpSolid } from 'react-icons/lia';

const SortableHeader = ({ children, currentSortBy, name, onClick }) => {
	return (
		<span style={{ display: 'flex', gap: '2px', cursor: 'pointer' }} onClick={onClick}>
			<span>{children}</span>
			<span>{currentSortBy?.field == name ? currentSortBy?.direction == 'asc' ? <LiaSortUpSolid /> : <LiaSortDownSolid />  : <LiaSortSolid />}</span>
		</span>
	);
};

export default SortableHeader;
