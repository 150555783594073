import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { storePageTitle } from 'store/reducers/mainSlice';

// ==============================|| ELEMENT REFERENCE HOOKS  ||============================== //

const usePageTitle = (title = '') => {
	// const pageTitle = useSelector((state) => state.main.pageTitle);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(storePageTitle(title));
	}, []);

	return [
		(val) => {
			dispatch(storePageTitle(val));
		}
	];
};

export default usePageTitle;
