import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const InvoiceModalProduct = ({ openPopup, returnsDetail, handleClose, handleSave, disableStatus }) => {
	const [alertMessage, setAlertMessage] = useState();
	const [invoiceList, setInvoiceList] = useState([]);
	const [imei, setImei] = useState('');

	const [formInfo, setFormInfo] = useState({
		estimationId: ''
	});
	const handleValue = (val) => {
		setFormInfo({
			...formInfo,
			estimationId: val?.id
		});
		setFormInfo({ estimationId: val?.value });
	};

	const onSubmit = () => {
		api.getEstimationIdByItemId(imei).then((res) => {
			if (res.status == true) {
				const data = {
					...formInfo,
					estimationId: res.data ? res.data.estimationId : '',
					invoiceNumber: res.data ? res.data.invoiceNumber : ''
				};
				handleSave(data);
				handleClose();
			} else {
				setAlertMessage({
					user: 'Please enter a valid IMEI.'
				});
			}
		});
	};
	const disablepopup = () => {
		handleClose();
	};
	return (
		<>
			<Modal show={openPopup} onHide={disablepopup} centered size="sm">
				<Modal.Body>
					<div className="p-2">
						<div className="foundListPopup">
							<div className="mb-2 ml-0 pl-0">
								<div className="row">
									<div className="col-12 text-center" style={{ margin: '15px 0' }}>
										<h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0">Search IMEI</h5>
									</div>
								</div>
								<button className="btn modal-close-icon" onClick={handleClose}>
									<svg
										id="Component_127_1"
										height="16"
										width="16"
										data-name="Component 127 – 1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 19.295 19.289"
									>
										<path
											id="Icon_ionic-ios-close"
											data-name="Icon ionic-ios-close"
											d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
											transform="translate(-11.285 -11.289)"
										/>
									</svg>
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-12">
									<form className="assign found" onSubmit={(e) => e.preventDefault()}>
										<div className="row">
											<div className="form-group col-md-12">
												{/* <label className="text-gray-900">{ openPopup }</label> */}

												<input
													type="number"
													className="form-control"
													placeholder="Search IMEI"
													value={imei}
													onChange={(e) => setImei(e.target.value)}
												/>
												{alertMessage?.user && (
													<>
														<span className="error-message text-danger">{alertMessage?.user}</span>
													</>
												)}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="btnarea">
							<div className="">
								<div className="row">
									<div className="col-12 text-center">
										<button disabled={disableStatus} onClick={onSubmit} className="btn btn-primary btn-outlined-main">
											<FaPlus /> Confirm
										</button>
									</div>
								</div>
								<div className="row" style={{ marginTop: '10px' }}>
									<div className="col-12 text-center">
										<a href="javascript:void(0);" onClick={handleClose}>
											Cancel
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default InvoiceModalProduct;
