import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { PropTypes } from 'prop-types';
import { Switch } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import { storeInLocalStore, setStateUserList } from 'store/reducers/mainSlice';
import { useDispatch } from 'react-redux';
import Scroller from 'ui-component/Scroller';
import { FaMinus, FaPlus, FaSave } from 'react-icons/fa';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';

const UserForm = ({ edit }) => {
	const { id } = useParams();

	const history = useNavigate();
	const dispatch = useDispatch();
	const firstnameRef = useRef();
	const lastnameRef = useRef();
	const emailRef = useRef();
	const confirmPassowrdRef = useRef();
	const roleRef = useRef();
	const mobileRef = useRef();
	const [validpass, setValidPass] = useState();
	const [userInfo, setUserinfo] = useState({
		fname: '',
		lastname: '',
		emailid: '',
		mobile: '',
		role: '',
		status: 1,
		is_salesperson: null
	});

	const [alertMessage, setAlertmessage] = useState({});
	const [allRoles, setAllroles] = useState({});
	const [roles, setRoles] = useState([]);
	const [selectedRole, setSelectedRole] = useState([]);
	const [rolesId, setRoleid] = useState([]);
	const [notvalidPhone, setnotvalidPhone] = useState(true);
	const getRoleData = () => {
		api.getRoles().then((res) => {
			const transformedOptions = res.data.map((item) => ({ label: item.name, value: item.id }));
			setRoles(transformedOptions);
			setAllroles(res.data);
		});
	};
	const handleChange = (val) => {
		setUserinfo(val);
	};

	useEffect(() => {
		if (edit && id) {
			// setIsloading(true);
			api.getUserDetail(id).then((res) => {
				if (res && res.status) {
					setUserinfo({
						fname: res?.data?.first_name,
						lastname: res?.data?.last_name,
						emailid: res?.data?.email,
						mobile: res?.data?.mobile,
						role: res?.data?.role?.id,

						status: 1,
						is_salesperson: res?.data?.is_salesperson ? true : false
					});
					setSelectedRole({ label: res?.data?.role?.name, value: res?.data?.role?.id });
					// setIsloading(false);
				} else {
					history('/settings/user');
				}
			});
		}
	}, []);

	useEffect(() => {
		setUserinfo((olddata) => ({ ...olddata, role: selectedRole?.value || '' }));
	}, [selectedRole]);

	const onChipDelete = (chip) => {
		console.log('Chip: ', chip);
		let newRole = selectedRole.filter((item) => item != chip);
		console.log(newRole);
		setSelectedRole(newRole);
	};
	
	
	const fnamehandle = (e) => {
		let inputValue = e.target.value;
		const onlyAlphabets = /^[A-Za-z ]+$/;
		if (onlyAlphabets.test(inputValue) || inputValue === '') {
			const words = inputValue.split(' ');
			const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
			inputValue = capitalizedWords.join(' ');
			handleChange({ ...userInfo, fname: inputValue });
		}
	};
	const lnamehandle = (e) => {
		let inputValue = e.target.value;
		const onlyAlphabets = /^[A-Za-z ]+$/;
		if (onlyAlphabets.test(inputValue) || inputValue === '') {
			const words = inputValue.split(' ');
			const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
			inputValue = capitalizedWords.join(' ')?.trim();
			handleChange({ ...userInfo, lastname: inputValue });
		}
	};

	const handleEmail = (e) => {
		const inputValue = e.target.value;
		const lowercaseValue = inputValue.toLowerCase();
		e.target.value = lowercaseValue;
	};
	function findIdsByNames(array, names) {
		const filteredArray = array.filter((obj) => names.includes(obj.name));
		return filteredArray.map((obj) => obj.id);
	}
	const handleRole = (event) => {
		setSelectedRole(event);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (userInfo.fname?.trim() == '') {
			setAlertmessage({
				firstname: true,
				message: 'First Name is required.'
			});
			firstnameRef.current.focus();
		} else if (userInfo.lastname?.trim() == '') {
			setAlertmessage({
				lastname: true,
				message: 'Last Name is required.'
			});
			lastnameRef.current.focus();
		} else if (userInfo.emailid == '') {
			setAlertmessage({
				emailid: true,
				message: 'Email is required and must be valid.'
			});
			emailRef.current.focus();
		} else if (!notvalidPhone) {
			setAlertmessage({
				mobile: true,
				message: 'Phone Number is not in valid format.'
			});
		} else if (!userInfo?.role) {
			setAlertmessage({
				roleis: true,
				message: 'Role is Required.'
			});
			roleRef.current.focus();
		} else {
			if (edit) {
				toast.promise(
					api
						.updateUser(id, {
							first_name: userInfo.fname,
							last_name: userInfo?.lastname || '',
							email: userInfo?.emailid,
							mobile: userInfo?.mobile || '',
							role_id: userInfo?.role,
							status: userInfo?.status,
							is_salesperson: userInfo?.is_salesperson
						})
						.then((res) => {
							if (res.status) {
								setTimeout(() => {
									setAlertmessage({});
									history('/settings/user');
								}, 500);
							} else {
								setAlertmessage({
									failed: true,
									message: res.message
								});
								if (res.errors.first_name) {
									setAlertmessage({
										firstname: true,
										message: res.errors.first_name[0]
									});
									firstnameRef.current.focus();
								} else if (res.errors.email) {
									setAlertmessage({
										emailid: true,
										message: res.errors.email[0]
									});
									emailRef.current.focus();
								}
								setTimeout(() => {
									setAlertmessage({});
								}, 2000);
							}
							return res?.status ? res : null;
						}),
					{
						loading: 'Updating User...',
						success: (data) => `${data.message}`,
						error: <b>Failed to update user.</b>
					}
				);
			} else {
				toast.promise(
					api
						.createUser({
							first_name: userInfo.fname,
							last_name: userInfo?.lastname || '',
							email: userInfo?.emailid,
							mobile: userInfo?.mobile || '',
							role_id: userInfo?.role,
							status: userInfo?.status
						})
						.then((res) => {
							if (res.status) {
								setTimeout(() => {
									setAlertmessage({});
									history('/settings/user');
								}, 500);
							} else {
								setAlertmessage({
									failed: true,
									message: res.message
								});
								if (res.errors.first_name) {
									setAlertmessage({
										firstname: true,
										message: res.errors.first_name[0]
									});
									firstnameRef.current.focus();
								} else if (res.errors.email) {
									setAlertmessage({
										emailid: true,
										message: res.errors.email[0]
									});
									emailRef.current.focus();
								}
								setTimeout(() => {
									setAlertmessage({});
								}, 2000);
							}
							return res?.status ? res : null;
						}),
					{
						loading: 'Creating User...',
						success: (data) => `${data.message}`,
						error: <b>Failed to create user.</b>
					}
				);
			}
		}
		setTimeout(() => {
			setAlertmessage('');
		}, 3000);
	};
	useEffect(() => {
		getRoleData();
	}, []);
	const goback = () => {
		history('/settings/user');
	};

	return (
		<>
			<div className="row m-0 create-contact-container">
				<div className="card w-100 h-100">
					<form action="" className="h-100" onSubmit={handleSubmit}>
						<div className="section-head m-0 p-0">
							<h5 className="m-0 p-0 pb-2" style={{ fontWeight: '500' }}>
								{edit ? 'Edit User' : 'Create User'}
							</h5>
						</div>
						<Scroller>
							<div className="form-section">
								{/* <div className="form-section-head">
									<h4>General Information</h4>
								</div> */}

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">
											First Name <sup className="text-danger">*</sup>
										</label>
										<div>
											<input
												ref={firstnameRef}
												type="text"
												className="form-control"
												id="inputName"
												value={userInfo.fname}
												maxLength={15}
												onChange={(e) => {
													fnamehandle(e);
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.firstname && (
											<>
												<span className="error-message text-danger">{alertMessage.message}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">
											Last Name <sup className="text-danger">*</sup>
										</label>
										<div>
											<input
												ref={lastnameRef}
												type="text"
												className="form-control"
												id="inputName"
												value={userInfo.lastname}
												maxLength={15}
												onChange={(e) => {
													lnamehandle(e);
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.lastname && (
											<>
												<span className="error-message text-danger">{alertMessage.message}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">
											Email <sup className="text-danger">*</sup>
										</label>
										<div>
											<input
												ref={emailRef}
												type="email"
												className="form-control"
												id="inputName"
												value={userInfo.emailid}
												autoComplete="off"
												onChange={(e) => {
													handleEmail(e);
													handleChange({ ...userInfo, emailid: e.target.value });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.emailid && (
											<>
												<span className="error-message text-danger">{alertMessage.message}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Phone Number</label>
										<div>
											<PhoneInput
												value={userInfo?.mobile}
												defaultCountry="AE"
												addInternationalOption={false}
												onChange={(phone) => {
													if (phone && !isValidPhoneNumber(phone)) {
														setnotvalidPhone(false);
														console.log('Invalid');
													} else {
														setnotvalidPhone(true);
														console.log('valid');
													}
													handleChange({ ...userInfo, mobile: phone });
												}}
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.mobile && (
											<>
												<span className="error-message text-danger">{alertMessage.contactPhone}</span>
											</>
										)}
									</div>
								</div>

								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">
											Roles <sup className="text-danger">*</sup>
										</label>
										<div>
											<Select
												className="rs-container"
												classNamePrefix="rs"
												labelName={'Role'}
												name={'role'}
												value={selectedRole}
												options={roles}
												onChange={handleRole}
												menuPosition="fixed"
												placeholder=""
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage.roleis && (
											<>
												<span className="error-message text-danger">{alertMessage.message}</span>
											</>
										)}
									</div>
								</div>

								{edit && (
									<>
										<div className="form-group">
											<div className="form-group-body">
												<label htmlFor="">
													Does Sales? <sup className="text-danger">*</sup>
												</label>
												<div>
													<Select
														className="rs-container"
														classNamePrefix="rs"
														labelName={'Role'}
														name={'role'}
														value={[
															{ label: 'Yes', value: true },
															{ label: 'No', value: false }
														]?.find((a) => a.value == userInfo?.is_salesperson)}
														options={[
															{ label: 'Yes', value: true },
															{ label: 'No', value: false }
														]}
														menuPosition="fixed"
														placeholder=""
														onChange={(e) => {
															setUserinfo((oldData) => ({
																...oldData,
																is_salesperson: e.value
															}));
														}}
													/>
												</div>
											</div>
											<div className="form-group-error">
												{/* {alertMessage.roleis && (
													<>
														<span className="error-message text-danger">{alertMessage.message}</span>
													</>
												)} */}
											</div>
										</div>
									</>
								)}
							</div>
						</Scroller>
						<div className="non-scroll-btn-section">
							<div className="btnarea">
								<button onClick={goback} className="btn btn-link">
									Cancel
								</button>
								{edit ? (
									<PermissionAllowed permissions={['Save User Details (Action)']}>
										<button
											type="submit"
											className="btn btn-primary btn-outlined-main"
											// disabled={isSubmitDisabled}
										>
											<FaSave /> Save Changes
										</button>
									</PermissionAllowed>
								) : (
									<PermissionAllowed permissions={['Create New User (Action)']}>
										<button
											type="submit"
											className="btn btn-primary btn-outlined-main"
											// disabled={isSubmitDisabled}
										>
											<FaPlus /> Create
										</button>
									</PermissionAllowed>
								)}
							</div>
							{alertMessage.success && <span className="px-3 down-error text-success">{alertMessage.success}</span>}
							{alertMessage.failed && <span className="px-3 down-error text-danger">{alertMessage.failed}</span>}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default UserForm;
