import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useMemo, useCallback } from 'react';
import usePageTitle from 'hooks/usePageTitle';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import 'assets/scss/charts.scss';
import { Slide, Fade } from 'react-awesome-reveal';
import { IconCalendar } from '@tabler/icons';
import wotm from 'assets/images/dbDummy/afzaly.png';
import us from 'assets/images/dbDummy/us.png';
import eu from 'assets/images/dbDummy/eu.png';
import gbp from 'assets/images/dbDummy/ae.png';
import ProfitCard from './ProfitCard';
import ExchangeCard from './ExchangeCard';
import PendingOrdersCard from './PendingOrders';
import PendingCreditNotesCard from './PendingCreditNotes';
import TopSellingProductsCard from './TopSellingProducts';
import TopCustomers from './TopCustomerAdmin';
import TopProducts from './TopProductsAdmin';
import EstimatedCommissionCard from './EstimatedCommission';
import SalesCard from './SalesGraph';
import PerformanceCard from './PerformanceCard';
import SalesTeamCard from './SalesPerformance';
import SalesTargetCard from './SalesTargetCard';
import WarriorCard from './WarriorCard';

const currentYear = new Date().getFullYear();
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const scrollMonths = months.map((month) => `${month} ${currentYear}`);

const areadata = [
	{ name: 'Jan', uv: 0 },
	{ name: 'Feb', uv: 1200 },
	{ name: 'Mar', uv: 2000 },
	{ name: 'Apr', uv: 1800 },
	{ name: 'May', uv: 2000 },
	{ name: 'Jun', uv: 2390 },
	{ name: 'Jul', uv: 3490 }
];

const estimateddata = [
	{ name: 'Jan', uv: 4000 },
	{ name: 'Feb', uv: 3000 },
	{ name: 'Mar', uv: 2000 },
	{ name: 'Apr', uv: 2780 },
	{ name: 'May', uv: 1890 },
	{ name: 'Jun', uv: 800 },
	{ name: 'Jul', uv: 0 }
];

const CustomizedAxisTick = (props) => {
	const { x, y, payload } = props;

	const formatK = (num) => {
		if (Math.abs(num) > 999 && Math.abs(num) <= 999999) {
			return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k';
		} else if (Math.abs(num) > 999999) {
			return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'M';
		} else {
			return Math.sign(num) * Math.abs(num);
		}
	};

	return (
		<g transform={`translate(${x - 40},${y - 10})`}>
			<text x={0} y={0} dy={16} textAnchor="start" fill="#838383" fontSize="14">
				$ {formatK(payload.value)}
			</text>
		</g>
	);
};

const isGoingUp1 = areadata[areadata.length - 1].uv > areadata[areadata.length - 2].uv;
const gradientId1 = isGoingUp1 ? 'greenGradient1' : 'redGradient1';
const stopColor1 = isGoingUp1 ? '#8cdaa0' : '#f75868';

const isGoingUp2 = estimateddata[estimateddata.length - 1].uv > estimateddata[estimateddata.length - 2].uv;
const gradientId2 = isGoingUp2 ? 'greenGradient2' : 'redGradient2';
const stopColor2 = isGoingUp2 ? '#51d651' : '#f75868';

const halfdata = [
	{ name: 'Group A', value: 400 },
	{ name: 'Group B', value: 600 }
];

const COLORS = ['#e2e2e2', '#f64e60'];

const SalesDashboard = () => {
	const date = new Date();
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	const [currentMonth, setCurrentMonth] = useState(monthNames[date.getMonth()]);
	const [currentYear, setCurrentYear] = useState(date.getFullYear());
	const [activeMonth, setActiveMonth] = useState(`${currentMonth} ${currentYear}`);
	const [previousMonth, setPreviousMonth] = useState(monthNames[date.getMonth() - 1]);
	const [month, year] = activeMonth.split(' ');
	const [graphType, setGraphType] = useState('area');
	const [isFlipped, setIsFlipped] = React.useState(false);
	const [setPageTitle] = usePageTitle('dashboard');
	const [currentDate, setDate] = useState(new Date());
	const [data, setData] = useState([]);
	const [dataWeek, setDataWeek] = useState([]);
	const [dataMonth, setDataMonth] = useState([]);
	const [dataYear, setDataYear] = useState([]);
	const [user, setUser] = useState([]);
	const [userImage, setUserImage] = useState([]);
	const [wotmData, setWotm] = useState([]);
	const [wotmName, setWotmName] = useState([]);
	const [wotmImage, setWotmImage] = useState([]);
	const [wotmImageSmall, setWotmImageSmall] = useState([]);
	const [wotmSold, setWotmSold] = useState([]);
	const [wotmProfitMargin, setWotmProfitMargin] = useState([]);
	const [wotmContact, setWotmContact] = useState([]);
	const [sold, setSold] = useState([]);
	const [target, setTarget] = useState([]);
	const [salesTargetPercentage, setSalesTargetPercentage] = useState([]);
	const [soldQuantity, setSoldQuantity] = useState([]);
	const [soldGrowth, setSoldGrowth] = useState([]);
	const [targetWeek, setTargetWeek] = useState([]);
	const [targetMonth, setTargetMonth] = useState([]);
	const [targetYear, setTargetYear] = useState([]);
	const [salesData, setSalesData] = useState([]);
	const [profitMargin, setProfitMargin] = useState([]);
	const [invoiced, setInvoiced] = useState([]);
	const [opened, setOpened] = useState(false);
	const [contactCount, setContactCount] = useState([]);
	const [estimateddata, setEstimatedData] = useState([]);
	const [targetPeriod, setTargetPeriod] = useState('month');
	const [isOpen, setIsOpen] = useState(false);
	const [amount, setAmount] = useState(1.0);
	const [fromCurrency, setFromCurrency] = useState('USD');
	const [toCurrency, setToCurrency] = useState('EUR');
	const [permissions, setPermission] = useState(false);
	const [newCalCommission, setNewCalCommission] = useState([]);
	const [result, setResult] = useState(0);
	const [salesPerformance, setSalesPerformance] = useState([]);
	const [salesPerformanceData, setSalesPerformanceData] = useState([]);
	const [hasLoadedData, setHasLoadedData] = useState(false);

	const [returns, setReturns] = useState([]);
	const [returnsGrowth, setReturnsGrowth] = useState([]);
	const [topCustomer, setTopCustomer] = useState([]);
	const [topProducts, setTopProducts] = useState([]);
	const activeDate = new Date(`${month} 1, ${year}`);
	const lastMonth = new Date(activeDate.setMonth(activeDate.getMonth() - 1));
	const lastLastMonth = new Date(activeDate.setMonth(activeDate.getMonth() - 1));

	const [prevMonth, setPrevMonth] = useState(`${lastMonth.toLocaleString('default', { month: 'long' })} ${lastMonth.getFullYear()}`);
	const [prevPrevMonth, setPrevPrevMonth] = useState(
		`${lastLastMonth.toLocaleString('default', { month: 'long' })} ${lastLastMonth.getFullYear()}`
	);

	const [isLoading, setIsLoading] = useState(false);

	let transformedData;
	if (newCalCommission) {
		transformedData = Object.keys(newCalCommission).map((key) => ({
			month: key,
			commission_solo: newCalCommission[key].commission_solo
		}));
	}

	const adjustedMonth = activeDate.getMonth() + 3 > 11 ? activeDate.getMonth() + 3 - 12 : activeDate.getMonth() + 3;
	const monthData = transformedData.filter((data) => Number(data.month) === adjustedMonth);

	useEffect(() => {
		setIsLoading(true);

		const date = new Date();
		const currentMonth = date.getMonth();
		const currentYear = date.getFullYear();

		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];

		for (let month = currentMonth; month >= 0; month--) {
			const fullMonthName = monthNames[month];

			const cachedData = localStorage.getItem(`userData_${fullMonthName}_${currentYear}`);
			const cachedTime = localStorage.getItem(`timestamp_${fullMonthName}_${currentYear}`);
			const currentTime = new Date().getTime();
			const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds

			if (cachedData && cachedTime && currentTime - cachedTime < fiveMinutes) {
				if (month === currentMonth) {
					processData(JSON.parse(cachedData));
					setIsLoading(false);
				}
			} else {
				if (month === currentMonth) {
					api.getDashboardData(fullMonthName, currentYear).then((res) => {
						localStorage.setItem(`userData_${fullMonthName}_${currentYear}`, JSON.stringify(res));
						localStorage.setItem(`timestamp_${fullMonthName}_${currentYear}`, currentTime.toString());
						processData(res);
						setIsLoading(false);
					});
				} else {
					api.getUserDataMonth(`${fullMonthName} ${currentYear}`).then((res) => {
						localStorage.setItem(`userData_${fullMonthName}_${currentYear}`, JSON.stringify(res));
						localStorage.setItem(`timestamp_${fullMonthName}_${currentYear}`, currentTime.toString());
					});
				}
			}
		}
	}, []);

	// ...
	const handleMonth = (month) => {
		setActiveMonth(month);
		const [monthName, year] = month.split(' ');

		const activeDate = new Date(`${monthName} 1, ${year}`);
		const fullMonthName = activeDate.toLocaleString('default', { month: 'long' });

		const lastMonth = new Date(activeDate.getFullYear(), activeDate.getMonth() - 1, 1);
		const lastLastMonth = new Date(activeDate.getFullYear(), activeDate.getMonth() - 2, 1);

		setCurrentMonth(monthNames[activeDate.getMonth()]);
		setCurrentYear(activeDate.getFullYear());
		setPreviousMonth(monthNames[lastMonth.getMonth()]);

		setPrevMonth(`${lastMonth.toLocaleString('default', { month: 'long' })} ${lastMonth.getFullYear()}`);
		setPrevPrevMonth(`${lastLastMonth.toLocaleString('default', { month: 'long' })} ${lastLastMonth.getFullYear()}`);

		// Check if data for this month is in localStorage
		const cachedData = localStorage.getItem(`userData_${fullMonthName}_${year}`);

		if (cachedData) {
			// Parse and use the cached data
			const res = JSON.parse(cachedData);
			processData(res);
		} else {
			console.log('No cached data for this month');
		}
	};

	const processData = (res) => {
		console.log('res', res)
		const chartData = [
			{ name: 'Remaining', value: parseFloat(res.Remaining.toFixed(2)) },
			{ name: 'Credit', value: parseFloat(res.credit_notes.toFixed(2)) },
			{ name: 'Sold', value: parseFloat(res.Sold.toFixed(2)) }
		];
		setData(chartData);

		const chartDataMonth = [
			{ name: 'Remaining', value: parseFloat(res.Remaining.toFixed(2)) },
			{ name: 'Credit', value: parseFloat(res.credit_notes.toFixed(2)) },
			{ name: 'Sold', value: parseFloat(res.Sold.toFixed(2)) }
		];
		setDataMonth(chartDataMonth);

		const chartDataWeek = [
			{ name: 'Remaining', value: parseFloat(res.Remaining_week.toFixed(2)) },
			{ name: 'Credit', value: parseFloat(res.credit_notes.toFixed(2)) },
			{ name: 'Sold', value: parseFloat(res.sold_week.toFixed(2)) }
		];
		setDataWeek(chartDataWeek);

		const chartDataYear = [
			{ name: 'Remaining', value: parseFloat(res.Remaining_year.toFixed(2)) },
			{ name: 'Credit', value: parseFloat(res.credit_notes.toFixed(2)) },
			{ name: 'Sold', value: parseFloat(res.sold_year.toFixed(2)) }
		];
		setDataYear(chartDataYear);

		if (!hasLoadedData) {
			const newSalesData = res.combined_sales;
			setSalesData(newSalesData);
			setHasLoadedData(true);
		}

		// Update estimateddata state variable
		setEstimatedData(transformedData);
		setUserImage(res.user_image);
		setSold(res.Sold);
		setSalesTargetPercentage(res.sales_target_percent);
		setTarget(res.target);
		setTargetWeek(res.target_week);
		setTargetMonth(res.target);
		setTargetYear(res.target_year);
		setReturns(res.returns);
		setReturnsGrowth(res.returns_growth);
		setProfitMargin(res.profit_margin);
		setSoldQuantity(res.sold_qty);
		setSoldGrowth(parseFloat(res.sold_qty_growth).toFixed(2));

		setInvoiced(parseFloat(res.invoiced).toFixed(2));
		setOpened(res.opened);

		setContactCount(res.contacts_count);

		const newSalesPerformance = res.sales_performance;
		setSalesPerformance(newSalesPerformance);

		const newWotm = [
			{ name: 'wotm_sold', value: res.WOTM_Sold },
			{ name: 'wotm_credit', value: res.WOTM_Credit },
			{ name: 'wotm_remaining', value: res.WOTM_Remaining },
			{ name: 'wotm_target', value: res.target }
		];
		setWotm(newWotm);
		setWotmSold(res.WOTM_sold_qty);
		setWotmProfitMargin(res.WOTM_profit_margin);

		const userName = [
			{ name: 'first', value: res.first_name },
			{ name: 'last', value: res.last_name }
		];
		setUser(userName);

		const newWotmName = [
			{ name: 'wotm_first', value: res.wotm_first_name },
			{ name: 'wotm_last', value: res.wotm_last_name }
		];
		setWotmName(newWotmName);
		setWotmImage(res.wotm_image);
		setWotmImageSmall(res.wotm_image_small);
		setWotmContact(res.wotm_contact_count);

		const CalCommission = res.team_commission;
		setNewCalCommission(CalCommission);

		const newPermission = res.permissions;
		setPermission(newPermission);

		const newPerformanceData = res.sales_performance_data;
		setSalesPerformanceData(newPerformanceData);

		const newTopCustomer = res.top_customers;
		setTopCustomer(newTopCustomer);

		const newTopProduct = res.top_products;
		setTopProducts(newTopProduct);

		setIsLoading(false);
	};

	let totalCommission = 0;

	if (newCalCommission) {
		totalCommission = Object.values(newCalCommission).reduce((total, data) => total + (Number(data.commission_solo) || 0), 0);
	}

	useEffect(() => {
		const timer = setInterval(() => {
			setDate(new Date());
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const currencyOptions = [
		{ value: 'USD', label: 'USD', image: us },
		{ value: 'EUR', label: 'EUR', image: eu },
		{ value: 'AED', label: 'AED', image: gbp }
	];

	const [exchangeRate, setExchangeRate] = useState([]);

	useEffect(() => {
		const today = new Date().toISOString().split('T')[0];
		const cachedExchangeRate = localStorage.getItem('exchangeRate');
		const cacheDate = localStorage.getItem('cacheDate');

		if (cachedExchangeRate && cacheDate === today) {
			setExchangeRate(JSON.parse(cachedExchangeRate));
		} else {
			api.getExchangeRate().then((res) => {
				const newExchangeRate = res;
				setExchangeRate(newExchangeRate);
				localStorage.setItem('exchangeRate', JSON.stringify(newExchangeRate));
				localStorage.setItem('cacheDate', today);
			});
		}
	}, []);

	useEffect(() => {
		// Convert the exchangeRate array into an object
		const exchangeRateObj = exchangeRate.reduce((acc, curr) => {
			acc[curr.name] = curr.rate;
			return acc;
		}, {});

		const baseCurrencyRate = exchangeRateObj[fromCurrency];
		const conversionCurrencyRate = exchangeRateObj[toCurrency];
		const convertedAmount = (amount / baseCurrencyRate) * conversionCurrencyRate;
		setResult(convertedAmount);
	}, [amount, fromCurrency, toCurrency, exchangeRate]);

	const handleAmountChange = (event) => {
		setAmount(event.target.value);
	};

	const handleFromCurrencyChange = (selectedOption) => {
		setFromCurrency(selectedOption);
	};

	const handleToCurrencyChange = (selectedOption) => {
		setToCurrency(selectedOption);
	};

	const handleIconClick = () => {
		const temp = fromCurrency;
		setFromCurrency(toCurrency);
		setToCurrency(temp);
	};

	React.useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const percentage = ((invoiced / (invoiced + opened)) * 100).toFixed(0);
	let color;

	if (percentage < 30) {
		color = '#e25151';
	} else if (percentage < 70) {
		color = '#f0cd34';
	} else {
		color = '#6dda52';
	}

	const contactColors = [
		{ background: '#bbf3ae', color: '#63b950' },
		{ background: '#caa4ec', color: '#8049b1' },
		{ background: '#f1e29b', color: '#ebb030' },
		{ background: '#df8d8d', color: '#b85252' }
	];

	const salesTargetData = useMemo(
		() => [
			{ name: 'Group A', value: 100 - Number(salesTargetPercentage) },
			{ name: 'Group B', value: Number(salesTargetPercentage) }
		],
		[salesTargetPercentage]
	);

	const handleSelect = useCallback(
		(value) => {
			setTargetPeriod(value);
			setIsOpen(false);

			switch (value) {
				case 'week':
					setData(dataWeek);
					setTarget(targetWeek);
					break;
				case 'month':
					setData(dataMonth);
					setTarget(targetMonth);
					break;
				case 'year':
					setData(dataYear);
					setTarget(targetYear);
					break;
				default:
					setData(dataMonth);
					setTarget(targetMonth);
			}
		},
		[setData, setTarget, setTargetPeriod, setIsOpen, dataWeek, targetWeek, dataMonth, targetMonth, dataYear, targetYear]
	);

	const chartData = Object.keys(salesPerformanceData || {}).map((key) => {
		const monthName = monthNames[parseInt(key, 10) - 1];
		return { name: monthName, value: parseFloat((salesPerformanceData[key] || 0).toFixed(2)) };
	});

	//permissions
	const warriorPermission = Object.values(permissions).find((permission) => permission.widget_name === 'warrior_of_the_month');
	const targetPermission = Object.values(permissions).find((permission) => permission.widget_name === 'target');
	const performancePermission = Object.values(permissions).find((permission) => permission.widget_name === 'performance');
	const estimatedCommissionPermission = Object.values(permissions).find((permission) => permission.widget_name === 'estimated_commission');
	const salesGraphPermission = Object.values(permissions).find((permission) => permission.widget_name === 'sales_graph');
	const exchangePermission = Object.values(permissions).find((permission) => permission.widget_name === 'exchange');

	return (
		<>
			<div className="global-time row m-0 mt-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
				<a>
					{new Date().toLocaleString('en-US', {
						month: 'short',
						day: 'numeric',
						year: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						hour12: true
					})}
				</a>
				<div style={{ marginRight: '20px' }}>
					<a className="multiple-times">
						New York{' '}
						{currentDate.toLocaleString('en-US', { timeZone: 'America/New_York', hour: 'numeric', minute: 'numeric', hour12: false })}
					</a>
					<b className="multiple-times">|</b>
					<a className="multiple-times">
						London {currentDate.toLocaleString('en-US', { timeZone: 'Europe/London', hour: 'numeric', minute: 'numeric', hour12: false })}
					</a>
					<b className="multiple-times">|</b>
					<a className="multiple-times">
						Amsterdam{' '}
						{currentDate.toLocaleString('en-US', { timeZone: 'Europe/Amsterdam', hour: 'numeric', minute: 'numeric', hour12: false })}
					</a>
					<b className="multiple-times">|</b>
					<a className="multiple-times">
						Hong Kong{' '}
						{currentDate.toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong', hour: 'numeric', minute: 'numeric', hour12: false })}
					</a>
				</div>
			</div>
			<div className="row sticky-row" style={{ paddingBottom: '20px', paddingTop: '20px' }}>
				<div className="col-12">
					<div
						className="scroll-container"
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							overflowX: 'auto',
							paddingLeft: '10px',
							paddingRight: '10px',
							marginTop: ''
						}}
					>
						<div className="stats-date stats-date-custom">
							<button onClick={() => scroll(-100)} disabled>
								&lt;
							</button>
							<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
								{scrollMonths.map((month, index) => {
									const currentMonth = new Date().getMonth();
									const isDisabled = index > currentMonth || (index === 0 && month.includes('Jan'));

									return (
										<div
											key={index}
											className={`stats-date__item ${month === activeMonth ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
											onClick={() => !isDisabled && handleMonth(month)}
											style={{ minWidth: '100px', opacity: isDisabled ? 0.5 : 1 }} // replace 100px with the desired width
										>
											{month}
										</div>
									);
								})}
							</div>
							<button onClick={() => scroll(100)} disabled>
								&gt;
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="row m-0  p-0">
				{!isLoading && wotmName[0] && wotmName[0].value !== 'No' && wotmName[1] && wotmName[1].value !== 'Data' && (
					<div className="custom-col-lg-4 col-md-8" style={{ paddingBottom: '20px' }}>
						<Fade>
							<div
								className={`card custom-card ${isFlipped ? 'card-flipped' : ''}`}
								style={{ height: '270px' }}
								onClick={() => setIsFlipped(!isFlipped)}
							>
								<WarriorCard
									warriorPermission={warriorPermission}
									isFlipped={isFlipped}
									setIsFlipped={setIsFlipped}
									previousMonth={previousMonth}
									currentYear={currentYear}
									wotm={wotm}
									wotmName={wotmName}
									wotmData={wotmData}
									wotmSold={wotmSold}
									wotmProfitMargin={wotmProfitMargin}
									wotmContact={wotmContact}
									currentMonth={currentMonth}
									wotmImage={wotmImage}
									wotmImageSmall={wotmImageSmall}
									userImage={userImage}
									user={user}
									data={data}
									target={target}
									isAdmin={false}
									soldQuantity={soldQuantity}
									profitMargin={profitMargin}
									contactCount={contactCount}
								/>
							</div>
						</Fade>
					</div>
				)}
				{!isLoading && (
					<SalesTargetCard
						className="order-desktop-1 order-tablet-2"
						targetPermission={targetPermission}
						targetPeriod={targetPeriod}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						handleSelect={handleSelect}
						data={data}
						COLORS={COLORS}
						sold={sold}
						targetMonth={targetMonth}
						salesTargetData={salesTargetData}
					/>
				)}

				{!isLoading && <SalesTeamCard salesPerformance={salesPerformance} />}

				{!isLoading && (
					<PerformanceCard
						className="order-desktop-2 order-tablet-1"
						performancePermission={performancePermission}
						chartData={chartData}
						gradientId1={gradientId1}
						stopColor1={stopColor1}
					/>
				)}
			</div>
			<div className="row m-0 p-0">
				{!isLoading && (
					<SalesCard
						salesGraphPermission={salesGraphPermission}
						salesData={salesData}
						graphType={graphType}
						setGraphType={setGraphType}
						CustomizedAxisTick={CustomizedAxisTick}
					/>
				)}

				{!isLoading && (
					<EstimatedCommissionCard
						estimatedCommissionPermission={estimatedCommissionPermission}
						transformedData={transformedData}
						totalCommission={totalCommission}
						month={month}
						monthData={monthData}
						gradientId2={gradientId2}
						stopColor2={stopColor2}
						monthNames={monthNames}
					/>
				)}

				{!isLoading && <TopCustomers topCustomer={topCustomer} isAdmin={false} />}

				{!isLoading && <TopProducts topProducts={topProducts} isAdmin={false} />}

				<PendingCreditNotesCard />

				<PendingOrdersCard />

				{!isLoading && (
					<ExchangeCard
						exchangePermission={exchangePermission}
						amount={amount}
						handleAmountChange={handleAmountChange}
						result={result}
						toCurrency={toCurrency}
						fromCurrency={fromCurrency}
						handleFromCurrencyChange={handleFromCurrencyChange}
						currencyOptions={currencyOptions}
						handleIconClick={handleIconClick}
						handleToCurrencyChange={handleToCurrencyChange}
					/>
				)}

				{!isLoading && <ProfitCard soldGrowth={soldGrowth} soldQuantity={soldQuantity} returns={returns} returnsGrowth={returnsGrowth} />}
			</div>
		</>
	);
};

export default SalesDashboard;
