import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { PropTypes } from 'prop-types';
import { Switch } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import { storeInLocalStore, setStateUserList } from 'store/reducers/mainSlice';
import { useDispatch } from 'react-redux';
import Scroller from 'ui-component/Scroller';
import { FaMinus, FaPlus, FaSave } from 'react-icons/fa';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';

const CreateParts = ({ edit }) => {
	const { id } = useParams();

	const history = useNavigate();
	const dispatch = useDispatch();
	const nameRef = useRef();
	const qtyRef = useRef();
	const modelRef = useRef();
	const repairTypeRef = useRef();
	const [validpass, setValidPass] = useState();
	const [partsInfo, setPartsInfo] = useState({
		name: '',
		qty: 1,
		model_id: '',
		repair_type_id: '',
	});

	const [alertMessage, setAlertmessage] = useState({});
	const [allRoles, setAllroles] = useState({});
	const [allModels, setAllModels] = useState({});
	const [allRepairTypes, setAllRepairTypes] = useState({});
	const [roles, setRoles] = useState([]);
	const [models, setModels] = useState([]);
	const [repairTypes, setRepairTypes] = useState([]);
	const [selectedRole, setSelectedRole] = useState([]);
	const [selectedModel, setSelectedModel] = useState([]);
	const [selectedRepairType, setSelectedRepairType] = useState([]);
	const [rolesId, setRoleid] = useState([]);
	const [notvalidPhone, setnotvalidPhone] = useState(true);
	const getRoleData = () => {
		api.getRoles().then((res) => {
			const transformedOptions = res.data.map((item) => ({ label: item.name, value: item.id }));
			setRoles(transformedOptions);
			setAllroles(res.data);
		});
	};
	const getModelData = () => {
		api.getModels().then((res) => {
			const transformedOptions = res.data.map((item) => ({ label: item.name, value: item.id }));
			setModels(transformedOptions);
			setAllModels(res.data);
		});
	};
	const getRepairTypeData = () => {
		api.getRepairTypes().then((res) => {
			const transformedOptions = res.data.map((item) => ({ label: item.name, value: item.id }));
			setRepairTypes(transformedOptions);
			setAllRepairTypes(res.data);
		});
	};
	const handleChange = (val) => {
		setPartsInfo(val);
	};

	useEffect(() => {
		if (edit && id) {
			// setIsloading(true);
			api.getUserDetail(id).then((res) => {
				if (res && res.status) {
					setPartsInfo({
						name: res?.data?.name,
						// lastname: res?.data?.last_name,
						// emailid: res?.data?.email,
						// mobile: res?.data?.mobile,
						// role: res?.data?.role?.id,
						// password: '',
						// confirmPassword: '',
						// status: 1,
						// is_salesperson: res?.data?.is_salesperson ? true : false
					});
					setSelectedRole({ label: res?.data?.role?.name, value: res?.data?.role?.id });
					// setIsloading(false);
				} else {
					history('/inventory/parts');
				}
			});
		}
	}, []);

	useEffect(() => {
		setPartsInfo((olddata) => ({ ...olddata, role: selectedRole?.value || '' }));
	}, [selectedRole]);

	const onChipDelete = (chip) => {
		console.log('Chip: ', chip);
		let newRole = selectedRole.filter((item) => item != chip);
		console.log(newRole);
		setSelectedRole(newRole);
	};
	const passwordValidation = (e) => {
		const password = e.target.value;
		if (password.length < 7) {
			setAlertmessage({
				password: true,
				message: 'Password must be at least 7 characters'
			});
			setValidPass(false);
		} else {
			setAlertmessage('');
			setValidPass(true);
		}
		setTimeout(() => {
			setAlertmessage('');
		}, 1500);
	};
	const confirmpasswordhandle = (e) => {
		const contfirm_password = e.target.value;
		if (contfirm_password !== partsInfo.password) {
			setAlertmessage({
				confirmPassword: true,
				message: 'Confirm Password is not matched'
			});
		} else {
			setAlertmessage('');
		}
	};
	const namehandle = (e) => {
		handleChange({ ...partsInfo, name: e.target.value });
	};
	const qtyhandle = (e) => {
		handleChange({ ...partsInfo, qty: e.target.value });
	};

	const handleEmail = (e) => {
		const inputValue = e.target.value;
		const lowercaseValue = inputValue.toLowerCase();
		e.target.value = lowercaseValue;
	};
	function findIdsByNames(array, names) {
		const filteredArray = array.filter((obj) => names.includes(obj.name));
		return filteredArray.map((obj) => obj.id);
	}
	const handleRole = (event) => {
		setSelectedRole(event);
	};
	const handleModel = (event) => {
		handleChange({ ...partsInfo, model_id: event.value });
		setSelectedModel(event);
	};
	const handleRepairType = (event) => {
		handleChange({ ...partsInfo, repair_type_id: event.value });
		setSelectedRepairType(event);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (partsInfo.name == '') {
			setAlertmessage({
				name: true,
				message: 'Parts Name is required.'
			});
			nameRef.current.focus();
		} else if (partsInfo.qty == '') {
			setAlertmessage({
				qty: true,
				message: 'qty is required.'
			});
		} else if (!partsInfo?.model_id) {
			setAlertmessage({
				model_id: true,
				message: 'Model is Required.'
			});
		} else if (!partsInfo?.repair_type_id) {
			setAlertmessage({
				repair_type_id: true,
				message: 'Repair Type is Required.'
			});
		} else {
			if (edit) {
				toast.promise(
					api
						.updateUser(id, {
							first_name: partsInfo.fname,
							last_name: partsInfo?.lastname || '',
							email: partsInfo?.emailid,
							mobile: partsInfo?.mobile || '',
							role_id: partsInfo?.role,
							status: partsInfo?.status,
							is_salesperson: partsInfo?.is_salesperson
						})
						.then((res) => {
							if (res.status) {
								setTimeout(() => {
									setAlertmessage({});
									history('/settings/user');
								}, 500);
							} else {
								setAlertmessage({
									failed: true,
									message: res.message
								});
								if (res.errors.name) {
									setAlertmessage({
										name: true,
										message: res.errors.name[0]
									});
									nameRef.current.focus();
								} else if (res.errors.qty) {
									setAlertmessage({
										qty: true,
										message: res.errors.qty[0]
									});
									qtyRef.current.focus();
								}
								setTimeout(() => {
									setAlertmessage({});
								}, 2000);
							}
							return res?.status ? res : null;
						}),
					{
						loading: 'Updating User...',
						success: (data) => `${data.message}`,
						error: <b>Failed to update parts.</b>
					}
				);
			} else {
				toast.promise(
					api
						.createParts({
							name: partsInfo.name,
							qty: partsInfo?.qty || 0,
							model_id: partsInfo?.model_id,
							repair_type_id: partsInfo?.repair_type_id,
						})
						.then((res) => {
							if (res.status) {
								setTimeout(() => {
									setAlertmessage({});
									history('/inventory/parts');
								}, 500);
							} else {
								setAlertmessage({
									failed: true,
									message: res.message
								});
								if (res.errors.name) {
									setAlertmessage({
										name: true,
										message: res.errors.name[0]
									});
									nameRef.current.focus();
								} else if (res.errors.model_id) {
									setAlertmessage({
										model_id: true,
										message: res.errors.model_id[0]
									});
									modelRef.current.focus();
								} else if (res.errors.qty) {
									setAlertmessage({
										qty: true,
										message: res.errors.qty[0]
									});
									qtyRef.current.focus();
								} else if (res.errors.repair_type_id) {
									setAlertmessage({
										repair_type_id: true,
										message: res.errors.repair_type_id[0]
									});
									repairTypeRef.current.focus();
								}
								setTimeout(() => {
									setAlertmessage({});
								}, 2000);
							}
							return res?.status ? res : null;
						}),
					{
						loading: 'Creating User...',
						success: (data) => `${data.message}`,
						error: <b>Failed to create parts.</b>
					}
				);
			}
		}
		setTimeout(() => {
			setAlertmessage('');
		}, 3000);
	};
	useEffect(() => {
		getRoleData();
		getModelData();
		getRepairTypeData();
	}, []);
	const goback = () => {
		history('/inventory/parts');
	};
	return (
		<>
			<div className="row mt-3">
				<div className="col-8 offset-2">
					<div className="col-md-12 my-3">
						<h1 className="h4 mb-0  font-weight-bold">{edit ? 'Update Parts' : 'Create Parts'}</h1>
					</div>
					<div className="card shadow-sm bg-white mb-4 p-2 p-xl-3">
						<form autoComplete="off" className="partsForm" onSubmit={handleSubmit}>
							<div className="row  px-3 py-1">
								<div className="col-3 text-right">
									<label htmlFor="inputName" className="">
										Part Name
									</label>
								</div>
								<div className="col-7">
									<input
										ref={nameRef}
										type="text"
										className="form-control form-control-sm"
										id="inputName"
										value={partsInfo.name}
										maxLength={15}
										onChange={(e) => {
											namehandle(e);
										}}
									/>
								</div>
								<div className="col-2">
									{alertMessage.name && (
										<>
											<span className="px-3 error-message text-danger">{alertMessage.message}</span>
										</>
									)}
								</div>
							</div>
							<div className="row px-3 py-1">
								<div className="col-3 text-right">
									<label htmlFor="inputName" className="">
										Model
									</label>
								</div>
								<div className="col-7">
									
									<Select
										ref={modelRef}
										className="rs-container"
										classNamePrefix="rs"
										labelName={'Model'}
										name={'model'}
										value={selectedModel}
										options={models}
										onChange={handleModel}
									/>
								</div>
								<div className="col-2">
									{alertMessage.model_id && (
										<div>
											<span className="px-3 error-message text-danger">{alertMessage.message}</span>
										</div>
									)}
								</div>
							</div>
							<div className="row  px-3 py-1">
								<div className="col-3 text-right">
									<label htmlFor="inputName" className="">
										Quantity
									</label>
								</div>
								<div className="col-3">
									<input
										ref={qtyRef}
										type="number"
										className="form-control form-control-sm"
										id="inputName"
										value={partsInfo.qty}
										onChange={qtyhandle}
										// maxLength={15}
									/>
								</div>
								<div className="col-2 offset-4">
									{alertMessage.qty && (
										<>
											<span className="px-3 error-message text-danger">{alertMessage.message}</span>
										</>
									)}
								</div>
							</div>
							<div className="row px-3 py-1">
								<div className="col-3 text-right">
									<label htmlFor="inputName" className="">
										Repair Type
									</label>
								</div>
								<div className="col-7">
									
									<Select
										ref={repairTypeRef}
										className="rs-container"
										classNamePrefix="rs"
										labelName={'Repair Type'}
										name={'repairType'}
										value={selectedRepairType}
										options={repairTypes}
										onChange={handleRepairType}
									/>
								</div>
								<div className="col-2">
									{alertMessage.repair_type_id && (
										<div>
											<span className="px-3 error-message text-danger">{alertMessage.message}</span>
										</div>
									)}
								</div>
							</div>
							<div className="row px-3 py-1">
								<div className="col-8 offset-2">

									<div className="btnarea">
										<button onClick={goback} className="btn btn-link">
											Cancel
										</button>

										{edit ? (
											<PermissionAllowed>
												<button type="submit" className="btn btn-primary btn-outlined-main">
													<FaSave /> Save Changes
												</button>
											</PermissionAllowed>
										) : (
											<PermissionAllowed>
												<button type="submit" className="btn btn-primary btn-outlined-main">
													<FaPlus /> Create
												</button>
											</PermissionAllowed>
										)}
									</div>
									{alertMessage.success && <span className="px-3 down-error text-success">{alertMessage.message}</span>}
									{alertMessage.failed && <span className="px-3 down-error text-danger">{alertMessage.message}</span>}
								</div>
							</div>
						</form>
					</div>
				</div>

			</div>
		</>
	);
};

export default CreateParts;
