import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import toast from 'react-hot-toast';
import { GrHistory } from 'react-icons/gr';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { TbDatabaseExclamation } from 'react-icons/tb';
import StatusModal from './modals/StatusModal';

const ReturnsHistoryComponent = ({ returnsDetail, handleLoadRecord }) => {
	const [openPopup, setOpenPopup] = useState(false);
	const [isDisable, setDisable] = useState(false);

	const handleUpdateStatus = () => {
		setOpenPopup(true);
	};
	const closePopup = () => {
		setOpenPopup(false);
	};

	const handleSave = (data) => {
		console.log(data);
		console.log(returnsDetail);

		toast.promise(
			api.updateReturnsStatus(returnsDetail?.id, data).then((res) => {
				if (res.status) {
					setOpenPopup(false);
					// setShowDelete('');
					// setSelectedReplacementProduct('')
					handleLoadRecord();
				}
				return res?.status ? res : null;
			}),
			{
				loading: 'Updating Returns Details...',
				success: (data) => `${data.message}`,
				error: <b>Failed to update returns status</b>
			}
		);
		// handleSetEstimationId(data?.estimationId);

		// api.getInvoiceNumberCustomer(data?.estimationId).then((res) => {
		//     setInvoiceDetails({
		//         estimationId: res.data?.id,
		//         invoiceNumber: res.data?.invoice_number,
		//         customerId: res.data?.customer_id,
		//         customerName: res.data?.customer_name,
		//     });
		// });

		// if (returnsDetail?.id) {
		//     api.updateReturnsEstimateDetails(returnsDetail?.id, {
		//         estimation_id: data?.estimationId
		//     }).then((res) => {});
		// }
		// setOpenPopup(false);
	};
	return (
		<>
			<div className="row mb20">
				<div className="col-12">
					<div className="card card-shadow p-2">
						<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: 0 }}>
							<div className="font18">
								History
								{/* <Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} /> */}
							</div>
						</div>
						<div className="row custom-card-padding">
							<div className="col-12" style={{ maxHeight: 429, overflowY: 'auto' }}>
								{returnsDetail?.histories?.length > 0 ? (
									<>
										{returnsDetail?.histories?.map((history, index) => {
											return (
												<>
													<div className="row mb10 ">
														<div className="col-12" style={{ padding: '15px 25px 15px 25px;' }}>
															<div className="row">
																<div className="col-12" style={{ display: 'flex' }}>
																	<div className="">
																		<GrHistory />
																	</div>
																	<div className="" style={{ marginLeft: '5px' }}>

																		<div className="estimate-note-value" dangerouslySetInnerHTML={{ __html: history?.description }} />
																	</div>
																</div>
															</div>
															{/* <div className="row">
                                                                        <div className="col-1">
                                                                            <GrHistory />
                                                                        </div>
                                                                        <div className="col-11">
                                                                            { history?.description }
                                                                        </div>
                                                                    </div> */}

															<div className="row">
																<div className="col-11 offset-1 textGray font12" style={{ fontStyle: 'italic' }}>
																	{moment(history?.created_at).format(CommonDateOnlyFormat)} Created by: {history?.created_by_user?.name}
																</div>
															</div>
														</div>
													</div>
												</>
											);
										})}
									</>
								) : (
									<>
										<div className="not-found-container" style={{ height: '15vh' }}>
											<div className="not-found-icon">
												<TbDatabaseExclamation />
											</div>
											<h6 className="not-found-text">No Data Available</h6>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{openPopup && (
				<StatusModal
					openPopup={openPopup}
					returnsDetail={returnsDetail}
					selectedCurrentStatus={returnsDetail?.status}
					handleClose={closePopup}
					handleSave={handleSave}
					disableStatus={isDisable}
				/>
			)}
		</>
	);
};

export default ReturnsHistoryComponent;
