import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HiOutlineRefresh } from 'react-icons/hi';
import { MdOutlineModeEdit } from 'react-icons/md';

const DetailsTab = ({ productDetails, openModalFormWarehouse }) => {
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);

	const onSubmit = () => {
		if (formInfo.userId == '') {
			setAlertMessage({
				user: 'Please select user.'
			});
		} else if (formInfo.date == '') {
			setAlertMessage({
				date: 'Please Select Date.'
			});
		} else if (formInfo.time == '') {
			setAlertMessage({
				time: 'Please Select Time.'
			});
		} else {
			let purchaseId = rows.map((item) => item.purchase_id);
			const data = {
				...formInfo,
				purchase_list_ids: purchaseId
			};
			handleSave(data);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	// const percentageMargin = Math.round(((parseFloat(productDetails?.price) - parseFloat(productDetails?.purchase_price)) / parseFloat(productDetails?.price)) * 100)
	return (
		<>
			<div className="content mt15">
				<div className="row">
					<div className="col-md-4 col-sm-12 col-12 form-group">
						<div className="card p-2">
							<div className="product-card minHeight230 pad10-25">
								<div className="form-section">
									<div className="product-section-head font18">
										<b>General</b>
									</div>
								</div>

								<div className="row">
									<div className="col-12 font14" style={{ display: 'flex' }}>
										<label htmlFor="">Product</label>
										<div style={{ marginLeft: '44px' }}>
											<span className="fw600">{productDetails?.product_name}</span>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-12 font14" style={{ display: 'flex' }}>
										<label htmlFor="">Category</label>
										<div style={{ marginLeft: '35px' }}>
											<span className="fw600">{productDetails?.category?.name}</span>
										</div>
									</div>
								</div>
								{
									productDetails?.storage?.name && (
										<>
											<div className="row">
												<div className="col-12 font14" style={{ display: 'flex' }}>
													<label htmlFor="">Storage </label>
													<div style={{ marginLeft: '44px' }}>
														<span className="fw600">{productDetails?.storage?.name}</span>
													</div>
												</div>
											</div>
										</>
									)
								}
								{
									productDetails?.grade?.name && (
										<>
											<div className="row">
												<div className="col-12 font14" style={{ display: 'flex' }}>
													<label htmlFor="">Grade </label>
													<div style={{ marginLeft: '55px' }}>
														<span className="fw600">{productDetails?.grade?.name}</span>
													</div>
												</div>
											</div>
										</>
									)
								}
								<div className="row">
									<div className="col-12 font14" style={{ display: 'flex' }}>
										<label htmlFor="">Status </label>
										<div style={{ marginLeft: '54px' }}>
											{
												productDetails?.id && (
													<>
														{
															productDetails?.status == 1 ? (
																<span className="fw600 text-success">Active</span>
															) : (
																<span className="fw600 text-danger">Inactive</span>
															)
														}
													</>
												)
											}
											{/* <span className="fw600">{productDetails?.status == 1 ? 'Published' : 'Inactive'} </span> */}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 font14" style={{ display: 'flex' }}>
										<label htmlFor="">Client Portal </label>
										<div style={{ marginLeft: '20px' }}>
											{
												productDetails?.id && (
													<>
														{

															productDetails?.in_portal == 1 ? (
																<span className="fw600 text-success">Active</span>
															) : (
																<span className="fw600 text-danger">Inactive</span>
															)
														}
													</>
												)
											}
											{/* <span className="fw600">{productDetails?.in_portal == 1 ? 'Published' : 'Unpublished'} </span> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-sm-12 col-12 form-group">
						<div className="card p-2">
							<div className="product-card minHeight230 pad10-25">
								<div className="form-section">
									<div className="product-section-head font18">
										<b>Price</b>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className='card-container' style={{ display: 'flex' }}>
											<div className="">
												<div className="row">
													<div className="col-12">
														<label htmlFor="">Price</label>
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<label htmlFor="">Purchase Price </label>
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<label htmlFor="">Margin Percentage </label>
													</div>
												</div>
											</div>
											<div className="" style={{ marginLeft: '15px' }}>
												<div className="row">
													<div className="col-12">
														<label htmlFor=""><span className="fw600">$ {productDetails?.price} </span></label>
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<label htmlFor=""><span className="fw600">$ {productDetails?.purchase_price}</span></label>
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<label htmlFor="">
															<span className="fw600">
																{
																	productDetails?.id && (
																		<>
																			{
																				productDetails?.price != '0.00' || productDetails?.purchase_price != '0.00' ? (
																					<>{100 - Math.round(((parseFloat(productDetails?.price) - parseFloat(productDetails?.purchase_price)) / parseFloat(productDetails?.price)) * 100)}%</>
																				) : (
																					<>0%</>
																				)
																			}
																		</>
																	)
																}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-sm-12 col-12 form-group">
						<div className="card p-2">
							<div className="product-card minHeight230 pad10-25">
								<div className="form-section">
									<div className="product-section-head font18">
										<b>Supplier</b>
									</div>
								</div>
								<div className="row">
									<div className="col-12 font14" style={{ display: 'flex' }}>
										<div style={{ minWidth: '175px' }}>Supplier Name</div>
										<div>
											<label className="fw600">{productDetails?.supplier?.name ? (<>{productDetails?.supplier?.name}</>) : (<>N/A</>)}</label>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 font14" style={{ display: 'flex' }}>
										<div style={{ minWidth: '175px' }}>Product code Supplier</div>
										<div>
											<label className="fw600">{productDetails?.code_supplier ? (<>{productDetails?.code_supplier}</>) : (<>N/A</>)}</label>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 font14" style={{ display: 'flex' }}>
										<div style={{ minWidth: '175px' }}>Delivery Time </div>
										<div>
											<label className="fw600">{(productDetails?.delivery_time == 0 || productDetails?.delivery_time == null) ? ('Now') : productDetails?.delivery_time == 1 ? ('1 day') : (<>{productDetails?.delivery_time} days</>)}</label>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 font14" style={{ display: 'flex' }}>
										<div style={{ minWidth: '175px' }}>Minimum amount to order </div>
										<div>
											<label className="fw600">{productDetails?.minimum_order}</label>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 font14" style={{ display: 'flex' }}>
										<div style={{ minWidth: '175px' }}>Order in quantities of </div>
										<div>
											<label className="fw600">{productDetails?.order_quantity}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="content mt15">
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="product-card minHeight171 pad25">
								<div className="row">
									<div className="col-12">
										<div className="form-section">
											<div className="product-section-head font18" style={{ fontSize: '1.5rem' }}>
												<b>Stock</b>
											</div>
										</div>
										<div className="row">
											<div className="col-12 ">
												<span>
													Stock: <span className="fw600">{productDetails?.stocks?.total}</span>
												</span>
												<span style={{ marginLeft: '25px' }}>
													Free: <span className="fw600">{productDetails?.stocks?.free}</span>
												</span>
												<span style={{ marginLeft: '25px' }}>
													Reserved: <span className="fw600">{productDetails?.stocks?.reserved}</span>
												</span>
												<span style={{ marginLeft: '25px' }}>
													Stock value: <span className="fw600">{productDetails?.stocks?.stock_value}</span>
												</span>
											</div>
										</div>
										<hr style={{ marginBottom: '0px' }} />
									</div>
								</div>
								<div className="row mt20">
									<div className="col-12">
										<div className="form-section">
											<div className="location-section-head font18" style={{ fontSize: '1.5rem' }}>
												<div className="row">
													<div className="col-3">
														<b>Locations</b>
													</div>
													<div className="col-9 text-right">
														<span className="span-action">Show Location History </span>
														<span style={{ marginLeft: '8px', marginRight: '10px' }}>|</span>
														<span className="span-action" onClick={() => openModalFormWarehouse()}>
															Add Location
														</span>
														{/* <button className="bulk-action btn-outlined-main ml8"  onClick={() => edit()} >
                                                        <HiOutlineRefresh />  Add Stock
                                                    </button> */}
													</div>
												</div>
											</div>
										</div>

										{productDetails?.warehouses?.map((warehouse, index) => {
											return (
												<div className="product-warehouse-card" key={warehouse?.id}>
													<div className="row">
														<div className="col-md-4 col-12 " style={{ verticalAlign: 'middle' }}>
															<div className="mt6 fw600">
																<Link to={`/warehouses/${warehouse?.id}`} className="text-primary">
																	{warehouse?.name}
																</Link>
															</div>
														</div>
														<div className="col-md-4 col-sm-12 col-12 ">
															<div className="row">
																<div className="col-md-6 col-4 mt6">
																	Free Stock: {warehouse?.free_stocks}
																</div>
																<div className="col-md-6 col-4 mt6">
																	Reserved Stock: {warehouse?.reserved_stocks}
																</div>
															</div>
														</div>
														<div className="col-md-4 col-12 ">
															<div className="product-warehouses mt6">
																<div className="actions">
																	<button className="bulk-action btn-outlined-main ml8" onClick={() => edit()}>
																		<HiOutlineRefresh /> Move Stock
																	</button>
																	<button className="bulk-action btn-outlined-main ml8">
																		<MdOutlineModeEdit /> Change Stock
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
								{/* <div className="row mt20">
                                    <div className="col-12">
                                        <div className="form-section">
                                            <div className="product-section-head font18" style={{fontSize:'1.5rem'}}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <span className="font12 text-primary fw500">Show all warehouses </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DetailsTab;
