import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Tableheader from 'ui-component/Tableheader';
import { Dropdown, InputGroup } from 'react-bootstrap';
import Pagination from 'ui-component/Pagination';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Scroller from 'ui-component/Scroller';
import downloadIcon from 'assets/images/icons/download.svg';
import moment from 'moment';
import { FaDownload, FaPlus, FaSearch } from 'react-icons/fa';
import usePageTitle from 'hooks/usePageTitle';
import { Skeleton } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import Filter from 'ui-component/filter-menu/Filter';
import { textToSentenceCase } from 'helpers/common.helper';

const Outbound = () => {
	const [setPageTitle] = usePageTitle('Inventory - Outbound');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const history = useNavigate();
	const [productList, setProductList] = useState([]);
	const [show, setShow] = useState('');
	const [createShow, setCreateShow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const customerList = useSelector((state) => state.main.setcustomerlist);
	const [pagination, setpagination] = useState([]);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const getStockList = (internal = false) => {
		setIsLoading(true);
		api.getAllStock('outbound')
			.then((res) => {
				setProductList(res.data.data);
				setpagination(res.data);
				dispatch(setLoading(false));
				setIsLoading(false);
				isFirstLoad && setIsFirstLoad(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const deleteuserData = (data) => {
		setShow(data);
	};

	const handleClose = () => setShow('');

	const handleCreateClose = () => setCreateShow(false);

	const handleDelete = (id) => {
		// api.deleteCustomer(id).then((res) => {
		// 	if (res.status) {
		// 		setShow('');
		// 		getCustomerList();
		// 		dispatch(storeInLocalStore({ stateAlertmessage: 'Customer Deleted Successfully.' }));
		// 	}
		// });
	};
	const viewUserData = (id) => {
		const currentUser = productList.filter((item) => item.id === id);
		dispatch(storeInLocalStore({ selectedUser: currentUser[0] }));
		setTimeout(() => {
			history(`/contacts/${id}`);
		}, 1000);
	};
	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsLoading(true);
			if (text.length > 2) {
				api.filterStock('outbound', activeFilters, text).then((res) => {
					setIsLoading(false);
					setProductList(res.data.data);
					setpagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterStock('outbound', activeFilters, null).then((res) => {
					setIsLoading(false);
					setProductList(res.data.data);
					setpagination(res.data);
				});
			}
		}, 1000);

		setSearchTimeout(timeout);
	};
	const updateUser = (id) => {
		const currentUser = productList.filter((item) => item.id === id);
		dispatch(storeInLocalStore({ selectedUser: currentUser[0] }));
		setTimeout(() => {
			history(`/contacts/edit/${id}`);
		}, 1000);
	};
	const handleData = (data) => {
		setProductList(data.data);
		setpagination(data);
	};

	useEffect(() => {
		getStockList();
		getFiltersList();
	}, []);

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);
		api.filterStock('outbound', activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsLoading(false);
			setProductList(res.data.data);
			setpagination(res.data);
		});
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getStockFilters('outbound').then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};
	return (
		<>
			<div className="row">
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<h2></h2>
						<div className="actions">
							<>
								<Link to={''} className="btn btn-primary btn-outlined-main">
									<FaDownload /> Download
								</Link>
								{/* <Link to={'/inventory/create'} className="btn btn-primary btn-outlined-main">
										<FaPlus /> Create
									</Link> */}
							</>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4">
						{/* <div className="d-flex align-items-center justify-content-between p-2 pt-3 TableSerachBar">
							<form className="form-inline navbar-search">
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border w-8/12"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
									/>
									<img src={IconSearch} className="sIcon" alt="" />
								</div>
							</form>
						</div> */}
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="table-responsive-xl tableData customersList p-2">
							<Scroller>
								<table className="table pm-table">
									<thead>
										<tr className='sticky-header'>
											<th className="align-middle" width={'15'}>
												<input type="checkbox" />
											</th>
											<th scope="col">Date</th>
											<th scope="col">Model</th>
											<th scope="col">Colour</th>
											<th scope="col">Invoice</th>
											<th scope="col">IMEI</th>
											<th scope="col">Supplier</th>

											{/* <th scope="col">COMMENT</th> */}
											<th className="align-middle" width={'15'}></th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<>
												<>
													{[...Array(5)].map((e, i) => (
														<tr>
															{[...Array(12)].map((e, i) => (
																<td>
																	<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																</td>
															))}
														</tr>
													))}
												</>
											</>
										) : (
											<>
												{productList &&
													productList.length > 0 &&
													productList?.map((row, index) => {
														let cdate = row.collected_on || row.created_at;
														let readyDate = moment(cdate).format('DD MMM');
														let maxLength = 15;
														let customerName = row.customer?.name || '';

														let repairPrice = row?.repaires?.length > 0 ? row.repaires[0] : 0;
														let repairreadyprice = repairPrice?.currency_cost?.cost_usd || 0;
														let profitePrice = (
															(row?.currency_selling_unit_price?.unit_price_usd || 0) -
															((row?.currency_purchase_unit_price?.unit_price_usd || 0) + repairreadyprice)
														).toFixed(2);

														return (
															<>
																<tr key={index}>
																	<td className="text-center pl-2">
																		<input type="checkbox" />
																	</td>
																	<td className="align-middle">{readyDate}</td>

																	<td className="align-middle">{row?.product?.name}</td>
																	<td className="align-middle">{textToSentenceCase(row?.color?.name)}</td>
																	<td className="align-middle">{row?.estimation?.invoice_number}</td>
																	<td className="align-middle">{row?.imei}</td>
																	<td className="align-middle">{row?.supplier?.name}</td>

																	<td className="align-middle text-center">
																		<Dropdown className="btnDel iconbtn">
																			<Dropdown.Toggle as="span" id={`dropdown-toggle-${index}`}>
																				<i className="fas fa-ellipsis-h "></i>
																			</Dropdown.Toggle>
																			<Dropdown.Menu>
																				<Dropdown.Item>Details</Dropdown.Item>
																			</Dropdown.Menu>
																		</Dropdown>
																	</td>
																</tr>
															</>
														);
													})}
												{productList?.length < 1 && (
													<tr className="no-hover">
														<td colSpan={12}>
															<div className="not-found-container">
																<div className="not-found-icon">
																	<TbDatabaseExclamation />
																</div>
																<h6 className="not-found-text">No Data Available</h6>
															</div>
														</td>
													</tr>
												)}
											</>
										)}
									</tbody>
								</table>
							</Scroller>
						</div>
						<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
					</div>
				</div>
			</div>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Body>
					<Modal.Title>Confirm Delete</Modal.Title>
					<p>Are you sure you want to delete {`${show.name}`}</p>
					<Button variant="primary" className="m-2 " onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="m-2"
						onClick={() => {
							handleDelete(show.id);
						}}
					>
						Delete
					</Button>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Outbound;
function CustomToggle({ children, onClick }) {
	return (
		<>
			<a
				className="dropdown-toggle"
				href="#"
				role="button"
				id="dropdownMenuLink"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				onClick={onClick}
			>
				{children}
			</a>
		</>
	);
}
