import React from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Area, ResponsiveContainer } from 'recharts';
import { Fade } from 'react-awesome-reveal';

const EstimatedCommissionCard = ({
	estimatedCommissionPermission,
	transformedData,
	totalCommission,
	month,
	monthData,
	gradientId2,
	stopColor2,
	monthNames
}) => {
	if (
		!estimatedCommissionPermission ||
		estimatedCommissionPermission.is_visible !== 1 ||
		!transformedData ||
		transformedData.length === 0
	) {
		return null;
	}

	return (
		<div className="col-lg-4 col-md-12 col-sm-12" style={{ paddingBottom: '20px', width: '100%' }}>
			<Fade>
				<div className="card" style={{ height: '270px', width: '100%' }}>
					<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: 0, width: '100%' }}>
						<h5 style={{ fontWeight: '600' }}>Estimated Commission</h5>
					</div>
					<div className="card-body" style={{ width: '100%' }}>
						<div className="estimated-commission" style={{ width: '100%' }}>
							<h2>
								${' '}
								{Number(monthData[monthData.length - 1].commission_solo).toLocaleString('en-US', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								})}
							</h2>
							<a>
								Total Commission: ${' '}
								{Number(totalCommission).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
							</a>
						</div>
						<div className="estimated-commission-chart" style={{ height: '70%', width: '100%' }}>
							<ResponsiveContainer width="100%" height="100%">
								<AreaChart data={transformedData} margin={{ top: 10, right: 0, left: 0, bottom: 2 }}>
									<defs>
										<linearGradient id={gradientId2} x1="0" y1="0" x2="0" y2="1">
											<stop offset="0%" stopColor={stopColor2} stopOpacity={1} />
											<stop offset="100%" stopColor={stopColor2} stopOpacity={0} />
										</linearGradient>
									</defs>
									<XAxis dataKey="month" hide={true} />
									<YAxis hide={true} />
									<Tooltip
										content={({ payload }) => {
											if (payload.length > 0) {
												return (
													<div
														style={{
															backgroundColor: '#fff',
															padding: '15px',
															border: '1px solid #ccc',
															borderRadius: '10px',
															display: 'flex',
															flexDirection: 'column',
															width: '200px'
														}}
													>
														<span style={{ fontWeight: '500', padding: 0, paddingBottom: 10 }}>{`${
															monthNames[payload[0].payload.month - 1]
														}, 2024`}</span>
														<div style={{ display: 'flex', justifyContent: 'space-between' }}>
															<span>
																<strong>Commission:</strong>
															</span>
															<span>
																$
																{Number(payload[0].payload.commission_solo).toLocaleString('en-US', {
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2
																})}
															</span>
														</div>
													</div>
												);
											}
											return null;
										}}
									/>
									<Area type="monotone" dataKey="commission_solo" stroke={stopColor2} fill={`url(#${gradientId2})`} />
								</AreaChart>
							</ResponsiveContainer>
						</div>
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default EstimatedCommissionCard;
