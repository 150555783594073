import { textToSentenceCase } from 'helpers/common.helper';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Pagination from 'ui-component/Pagination';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Scroller from 'ui-component/Scroller';
import Notes from 'ui-component/notes';
import { FaUserTie } from 'react-icons/fa6';
import { FaMapMarkedAlt, FaPencilAlt } from 'react-icons/fa';
import { TbFileInvoice } from 'react-icons/tb';
import InvoiceModal from './components/modals/InvoiceModal';

import ShipmentTrackingModal from 'ui-component/confirmation-dialogs/awbModal/ShipmentTrackingModal';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiWarningFill } from 'react-icons/pi';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import usePageTitle from 'hooks/usePageTitle';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { HiOutlineRefresh } from 'react-icons/hi';
import { FaPlus, FaSave } from 'react-icons/fa';
import { MdOutlineModeEdit } from 'react-icons/md';
import ReturnsDetailsComponent from './components/ReturnsDetailsComponent';
import ReturnsHistoryComponent from './components/ReturnsHistoryComponent';
import ReturnsReturnedProductsComponent from './components/ReturnsReturnedProductsComponent';
import ReturnsReplacementComponent from './components/ReturnsReplacementComponent';
import InvoiceModalProduct from './components/modals/InvoiceModalProduct';
import StatusBadge from 'ui-component/StatusBadge';

function ReturnsDetails({ edit }) {
	const [setPageTitle] = usePageTitle('Returns');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const { id } = useParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const history = useNavigate();
	const [isLoading, setLoading] = useState(false);
	const [shipmentInvoiceDetails, setShipmentInvoiceDetails] = useState();
	const [shippingCompanies, setShippingCompanies] = useState();
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [disableProductBtn, setDisableProductBtn] = useState(true);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [showTrackingNoModal, setShowTrackingNoModal] = useState(false);
	const [isDisable, setDisable] = useState(false);
	const [invoiceProduct, setInvoiceProduct] = useState(false);
	const [invoiceDetails, setInvoiceDetails] = useState({
		estimationId: '',
		invoiceNumber: '',
		customerId: '',
		customerName: '',
		vatNumber: '',
		registrationNumber: '',
		billingAddress: '',
		billingZipcode: '',
		billingCity: '',
		billingCountry: '',
		shippingAddress: '',
		shippingZipcode: '',
		shippingCity: '',
		shippingCountry: ''
	});

	const [openPopup, setOpenPopup] = useState(false);

	const handleSelectInvoice = (type) => {
		setOpenPopup(type);
	};
	const closePopup = () => {
		setOpenPopup(false);
		setInvoiceProduct(false);
	};
	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const handleSave = (data) => {
		handleSetEstimationId(data?.estimationId);

		api.getInvoiceNumberCustomer(data?.estimationId).then((res) => {
			setInvoiceDetails({
				estimationId: res.data?.id,
				invoiceNumber: res.data?.invoice_number,
				customerId: res.data?.customer?.id,
				customerName: res.data?.customer?.name,
				vatNumber: res.data?.customer?.vat_number,
				registrationNumber: res.data?.customer?.registration_number,
				billingAddress: res.data?.customer?.billing_address?.address,
				billingZipcode: res.data?.customer?.billing_address?.zipcode,
				billingCity: res.data?.customer?.billing_address?.city,
				billingCountry: res.data?.customer?.billing_address?.country,
				shippingAddress: res.data?.customer?.shipping_address?.address,
				shippingZipcode: res.data?.customer?.shipping_address?.zipcode,
				shippingCity: res.data?.customer?.shipping_address?.city,
				shippingCountry: res.data?.customer?.shipping_address?.country
			});
			setReturnsDetail({
				estimationId: res.data?.id,
				invoiceNumber: res.data?.invoice_number,
				customerName: res.data?.customer_name
			});
		});

		if (returnsDetail?.id) {
			api
				.updateReturnsEstimateDetails(returnsDetail?.id, {
					estimation_id: data?.estimationId
				})
				.then((res) => {});
		}
		setOpenPopup(false);
	};

	const [returnsDetail, setReturnsDetail] = useState({
		id: '',
		referenceNo: '',
		estimationId: '',
		is_approved: '',
		invoiceNumber: '',
		customerId: '',
		customerName: '',
		vatNumber: '',
		registrationNumber: '',
		billingAddress: '',
		billingZipcode: '',
		billingCity: '',
		billingCountry: '',
		shippingAddress: '',
		shippingZipcode: '',
		shippingCity: '',
		shippingCountry: '',
		totalReturns: 0,
		totalReplacements: 0,
		returns: [],
		replacements: [],
		histories: [],
		returnValue: 0,
		replacementValue: 0,
		repairValue: 0,
		differenceValue: 0
	});

	const topSection = useRef(null);

	const getShipmentInvoiceDetails = (callback = () => {}) => {
		setLoading(true);
		api.getShipmentDetails(id).then((res) => {
			if (res?.status) {
				setLoading(false);
				setShipmentInvoiceDetails({
					...res.data
					// notes: res?.data?.notes?.map((note) => ({
					// 	id: note?.id,
					// 	details: note?.note,
					// 	date: moment(note?.created_at || undefined).format('YYYY-MM-DD'),
					// 	time: moment(note?.created_at || undefined).format('HH:mm'),
					// 	user_name: note?.logged_by_user?.first_name,
					// 	expand: false,
					// 	isDeletable: res?.data?.is_deletable,
					// }))
				});
				console.log(res?.data);
				// setInvoices(res?.data?.invoices);
				callback(res.data);
			} else {
				history('/returns');
			}
		});
	};
	const getReturnsDetails = (callback = () => {}) => {
		setLoading(true);
		api.getReturnsDetails(id).then((res) => {
			if (res?.status) {
				setLoading(false);

				setInvoiceDetails({
					estimationId: res.data?.estimation?.id,
					invoiceNumber: res.data?.estimation?.invoice_number,
					customerId: res.data?.customer?.id,
					customerName: res.data?.customer?.name,
					vatNumber: '',
					registrationNumber: '',
					billingAddress: res.data?.customer?.billing_address?.address,
					billingZipcode: res.data?.customer?.billing_address?.zipcode,
					billingCity: res.data?.customer?.billing_address?.city,
					billingCountry: res.data?.customer?.billing_address?.country,
					shippingAddress: res.data?.customer?.shipping_address?.address,
					shippingZipcode: res.data?.customer?.shipping_address?.zipcode,
					shippingCity: res.data?.customer?.shipping_address?.city,
					shippingCountry: res.data?.customer?.shipping_address?.country
				});
				setReturnsDetail({
					...res.data,
					id: res.data?.id,
					referenceNo: res.data?.reference_no,
					estimationId: res.data?.estimation?.id,
					is_approved: res.data?.is_approved,
					invoiceNumber: res.data?.estimation?.invoice_number,
					statusId: res.data?.status?.id,
					status: res.data?.status?.name,
					customerName: res.data?.customer?.name,
					totalReturns: res.data?.returns?.length,
					totalReplacements: res.data?.replacements?.length,
					returns: res.data?.returns,
					replacements: res.data?.replacements,
					histories: res.data?.histories,
					returnValue: res.data?.returning_value,
					replacementValue: res.data?.replacement_value,
					repairValue: res.data?.repair_value,
					differenceValue: res.data?.difference_value,
					currency: res.data?.estimation?.currency
				});
				callback(res.data);
			} else {
				history('/returns');
			}
		});
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};
	const handleTrackingNoModalClose = () => {
		handlePaymentModal(false, null);
		setShowAddPayment && setShowAddPayment(false);
	};

	const handleFormSubmit = (e) => {
		toast.promise(
			api
				.updateShipmentAwb(id, {
					tracking_no: e?.trackingNo,
					shipping_company_id: e?.shippingCompanyId,
					shipment_id: showTrackingNoModal
				})
				.then((res) => {
					if (res.status) {
						getShipmentInvoiceDetails();
						handleCloseShipmentTrackingModal();
					}
				}),
			{
				loading: 'Updating Tracking...',
				success: <b>Tracking Detail updated!</b>,
				error: <b>Could not Update Tracking Detail.</b>
			}
		);
	};

	const handleSetEstimationId = (value) => {
		setReturnsDetail({
			...returnsDetail,
			estimationId: value
		});
		setDisableProductBtn(false);
	};
	const handleSetReplacements = (value) => {
		console.log('Replacement Value', value);
		setReturnsDetail({
			...returnsDetail,
			replacements: value
		});
	};

	const newReturnedDetails = (data) => {
		setReturnsDetail({
			...returnsDetail,
			returns: data
		});
	};
	const handleSetImeis = (value) => {
		setReturnsDetail({
			...returnsDetail,
			returns: value
		});
	};

	const handleSelectInvoiceProduct = () => {
		console.log('clicked');
		setInvoiceProduct(true);
	};

	const handleLoadRecord = (value) => {
		// console.log("load Imeis", value);
		getReturnsDetails();
	};

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const handleSubmit = () => {
		if (edit && id) {
			console.log(id);
			return false;
		} else {
			if (!returnsDetail?.estimationId || returnsDetail?.estimationId == '') {
				showErrorDialog('Invalid Invoice', `Please Select Invoice`);
				return;
			}
			if (returnsDetail?.returns.length == 0) {
				showErrorDialog('Invalid Product', `Please add returning products`);
				return;
			}
			toast.promise(
				api
					.getCreateReturns({
						estimation_id: returnsDetail?.estimationId,
						replacements: returnsDetail?.replacements,
						returns: returnsDetail?.returns
					})
					.then((res) => {
						if (res?.status) {
							history(`/returns`);
							// history(`/returns/${res?.data?.returns_id}`);
						}
					}),
				{
					loading: 'Creating Returns...',
					success: <b>Returns Created!</b>,
					error: <b>Could not Create Returns.</b>
				}
			);
		}
	};

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => {},
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	useEffect(() => {
		if (id) {
			setDisableProductBtn(false);
			getReturnsDetails();
			// getShipmentInvoiceDetails();
			// getShippingCompanies();
		}
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const handleCloseShipmentTrackingModal = (data) => {
		setShowTrackingNoModal('');
	};
	const redirectFunc = (data) => {
		setTimeout(() => {
			history(`/returns/${id}`);
		}, 1000);
	};

	const addNote = (noteInputValue) => {
		toast.promise(
			api
				.newShipmentNote(id, {
					note: noteInputValue
				})
				.then(
					(res) => {
						if (res.status) {
							setShipmentInvoiceDetails((oldData) => ({
								...oldData,
								notes: [
									{
										id: res?.data?.id,
										details: noteInputValue,
										date: moment(res?.data?.created_at || undefined).format('YYYY-MM-DD'),
										time: moment(res?.data?.created_at || undefined).format('HH:mm'),
										user_name: userName,
										expand: false,
										isDeletable: res?.data?.is_deletable
									},
									...(oldData?.notes || [])
								]
							}));
						}

						return res?.status ? res : null;
					},
					() => {}
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const removeNote = (index) => {
		toast.promise(
			api.removeTransitShipmentNote(id, shipmentInvoiceDetails?.notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setShipmentInvoiceDetails((oldData) => ({
							...oldData,
							notes: oldData?.notes?.filter((note, i) => index != i)
						}));
					}
					return res?.status ? res : null;
				},
				() => {}
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	const toggleNoteExpand = (index) => {
		setShipmentInvoiceDetails((oldData) => ({
			...oldData,
			notes: oldData?.notes?.map((note, i) =>
				index == i
					? {
							...note,
							expand: !note?.expand
					  }
					: note
			)
		}));
	};

	return (
		<>
			<div className={`TaskDetails`}>
				<div className="row">
					<div className="col-12">
						<div className="row pb10">
							<div className="col-9">
								<div className="section-head" style={{ paddingTop: '0px', display: 'flex', justifyContent: 'space-between' }}>
									<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>
										<span style={{ whiteSpace: 'nowrap', fontSize: 18 }}>{returnsDetail?.referenceNo}</span>
									</h2>
									<div>
										<StatusBadge itemStatus={returnsDetail?.status === 'For Approval' ? 'Pending' : returnsDetail?.status} />{' '}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-9 col-6">
						<div className="row">
							<div className="col-12 mb20">
								<div className="card card-shadow p-2 card-box-shadow">
									<div className="row inbounding-imeis">
										<div className="col-xl-12 col-lg-12">
											<div className="row">
												<div className="col-12">
													<div className="d-flex align-items-center justify-content-between TableSerachBar">
														<div className="font18">
															<span className={invoiceDetails?.invoiceNumber ? 'returns-section-header' : 'returns-section-header gray'}>
																Invoices
															</span>
															{invoiceDetails?.invoiceNumber && (
																<>
																	:{' '}
																	<span className="font18" style={{ fontWeight: '600' }}>
																		<Link to={`/sales/invoices/${invoiceDetails?.estimationId}`}>
																			<PrivacyModeContent>{invoiceDetails?.invoiceNumber}</PrivacyModeContent>
																		</Link>
																	</span>
																</>
															)}
															{/* <Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} /> */}
														</div>
														<div className="form-inline navbar-search">
															{invoiceDetails?.estimationId ? (
																<>
																	<div className="row">
																		<div className="col-12">
																			<span
																				className="text-primary"
																				style={{ marginLeft: '20px', cursor: 'pointer' }}
																				onClick={() => {
																					handleSelectInvoice('Invoice');
																				}}
																			>
																				Edit &nbsp;
																				<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 19.875 21.722">
																					<g id="Group_40056" data-name="Group 40056" transform="translate(-2.982 -1.847)">
																						<path
																							id="Vector"
																							d="M14.452,1.27c2.195,2.076,1.559,3.774.149,5.263l-8.153,8.63a2.851,2.851,0,0,1-1.38.745l-3.2.546A1.517,1.517,0,0,1,.013,14.7l.367-3.218a3.029,3.029,0,0,1,.665-1.42L9.2,1.429c1.41-1.49,3.069-2.215,5.253-.159Z"
																							transform="translate(4.972 2.868)"
																							fill="none"
																							stroke="#06f"
																							stroke-linecap="round"
																							stroke-linejoin="round"
																							stroke-width="2"
																						/>
																						<path
																							id="Vector-2"
																							data-name="Vector"
																							d="M5.412,5.114A6.084,6.084,0,0,1,0,0"
																							transform="translate(12.81 5.737)"
																							fill="none"
																							stroke="#06f"
																							stroke-linecap="round"
																							stroke-linejoin="round"
																							stroke-width="2"
																						/>
																						<path
																							id="Vector-3"
																							data-name="Vector"
																							d="M0,0H17.875"
																							transform="translate(3.982 22.569)"
																							fill="none"
																							stroke="#06f"
																							stroke-linecap="round"
																							stroke-linejoin="round"
																							stroke-width="2"
																						/>
																					</g>
																				</svg>
																			</span>
																		</div>
																	</div>
																</>
															) : (
																<>
																	<button
																		className="bulk-action btn-outlined-main"
																		style={{ marginRight: 10 }}
																		onClick={() => {
																			handleSelectInvoice('Invoice');
																		}}
																	>
																		<FaPlus style={{ fontSize: '10px' }} /> Search Invoice
																	</button>
																	<button
																		className="bulk-action btn-outlined-main"
																		onClick={() => {
																			handleSelectInvoiceProduct();
																		}}
																	>
																		<FaPlus style={{ fontSize: '10px' }} /> Search Product
																	</button>
																</>
															)}
															{/* <button
																className="bulk-action btn-outlined-main"
																onClick={() => { handleSelectInvoice('Invoice'); }}
															>
																<FaPlus style={{ fontSize: '10px' }} /> Select Invoice
															</button> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 mb20">
								<ReturnsReturnedProductsComponent
									returnsDetail={returnsDetail}
									disableProductBtn={disableProductBtn}
									handleSetImeis={handleSetImeis}
									handleLoadRecord={handleLoadRecord}
									newReturnedDetails={newReturnedDetails}
								/>
							</div>
							<div className="col-12 mb20">
								<ReturnsReplacementComponent
									returnsDetail={returnsDetail}
									disableProductBtn={disableProductBtn}
									handleSetReplacements={handleSetReplacements}
									handleLoadRecord={handleLoadRecord}
								/>
							</div>

							<div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'center' }}>
								{!edit && (
									<>
										<PermissionAllowed permissions={['Create Returns (Button)']}>
											<button className="bulk-action btn-outlined-main ml8" onClick={() => handleSubmit()}>
												<FaPlus /> Save
											</button>
										</PermissionAllowed>
									</>
								)}
							</div>
						</div>
					</div>
					<div className="col-md-3 col-6">
						<ReturnsDetailsComponent
							returnsDetail={returnsDetail}
							// disableProductBtn={disableProductBtn}
							handleSetEstimationId={handleSetEstimationId}
							invoiceDetails={invoiceDetails}
						/>

						{edit && (
							<>
								<div className="row">
									<div className="col-12">
										<div className="card card-shadow p-2">
											<Scroller>
												<div className="row inbounding-imeis">
													<div className="col-xl-12 col-lg-12">
														<div className="row">
															<div className="col-12">
																<div className="d-flex align-items-center justify-content-between TableSerachBar">
																	<div className="font14">Totals</div>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-12" style={{ margin: 'auto 10px' }}>
																<div className="row" style={{ marginBottom: '5px' }}>
																	<div className="col-5 font14 fw400 textGray">Returning</div>
																	<div className="col-7 font14">{returnsDetail?.totalReturns} products</div>
																</div>
																<div className="row" style={{ marginBottom: '5px' }}>
																	<div className="col-5 font14 fw400 textGray">Replacing</div>
																	<div className="col-7 font14">{returnsDetail?.totalReplacements} products</div>
																</div>
																<div className="row" style={{ marginBottom: '5px' }}>
																	<div className="col-5 font14 fw400 textGray">Value Returning</div>
																	<div className="col-7 font14">
																		{returnsDetail?.currency == 'eur'
																			? `€ ${returnsDetail?.returnValue}`
																			: returnsDetail?.currency == 'usd'
																			? `$ ${returnsDetail?.returnValue}`
																			: returnsDetail?.currency == 'aed'
																			? `د.إ ${returnsDetail?.returnValue}`
																			: `${returnsDetail?.currency} ${returnsDetail?.returnValue}`}
																	</div>
																</div>
																<div className="row" style={{ marginBottom: '5px' }}>
																	<div className="col-5 font14 fw400 textGray">Value Replacing</div>
																	<div className="col-7 font14">
																		{returnsDetail?.currency == 'eur'
																			? `€ ${returnsDetail?.replacementValue}`
																			: returnsDetail?.currency == 'usd'
																			? `$ ${returnsDetail?.replacementValue}`
																			: returnsDetail?.currency == 'aed'
																			? `د.إ ${returnsDetail?.replacementValue}`
																			: `${returnsDetail?.currency} ${returnsDetail?.replacementValue}`}
																	</div>
																</div>
																<div className="row" style={{ marginBottom: '5px' }}>
																	<div className="col-5 font14 fw400 textGray">Value Difference</div>
																	<div className="col-7 font14">
																		{returnsDetail?.currency == 'eur'
																			? `€ ${returnsDetail?.differenceValue}`
																			: returnsDetail?.currency == 'usd'
																			? `$ ${returnsDetail?.differenceValue}`
																			: returnsDetail?.currency == 'aed'
																			? `د.إ ${returnsDetail?.differenceValue}`
																			: `${returnsDetail?.currency} ${returnsDetail?.differenceValue}`}
																	</div>
																</div>
																<div className="row">
																	<div className="col-5 font14 fw400 textGray">Total Repair Cost</div>
																	<div className="col-7 font14">
																		{returnsDetail?.currency == 'eur'
																			? `€ ${returnsDetail?.repairValue}`
																			: returnsDetail?.currency == 'usd'
																			? `$ ${returnsDetail?.repairValue}`
																			: returnsDetail?.currency == 'aed'
																			? `د.إ ${returnsDetail?.repairValue}`
																			: `${returnsDetail?.currency} ${returnsDetail?.repairValue}`}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Scroller>
										</div>
									</div>
								</div>

								<div style={{ marginTop: '20px' }}>
									<ReturnsHistoryComponent returnsDetail={returnsDetail} handleLoadRecord={handleLoadRecord} />
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{showTrackingNoModal && (
				<ShipmentTrackingModal
					shipmentId={showTrackingNoModal}
					shippingCompanies={shippingCompanies}
					redirectionData={redirectFunc}
					onFormSubmit={handleFormSubmit}
					handleCloseShipmentTrackingModal={handleCloseShipmentTrackingModal}
				/>
			)}
			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}
			{openPopup && (
				<InvoiceModal
					openPopup={openPopup}
					returnsDetail={returnsDetail}
					handleClose={closePopup}
					handleSave={handleSave}
					disableStatus={isDisable}
				/>
			)}
			{invoiceProduct && (
				<InvoiceModalProduct
					openPopup={invoiceProduct}
					returnsDetail={returnsDetail}
					handleClose={closePopup}
					handleSave={handleSave}
					disableStatus={isDisable}
				/>
			)}
		</>
	);
}

export default ReturnsDetails;
