import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import './UserPermissions.scss';
import Scroller from 'ui-component/Scroller';
import toast from 'react-hot-toast';
import usePermissions from 'hooks/usePermissions';

const UserPermissions = ({ onClose, data }) => {
	const history = useNavigate();
	const [allPerms, hasPerms] = usePermissions();
	const dispatch = useDispatch();

	const [userPermissionsList, setUserPermissionsList] = useState([]);

	// const userPermissions = ;

	useEffect(() => {
		// const copy = [...userPermissions.modules];
		// copy.sort((a, b) => b?.permissions?.length - a?.permissions?.length);
		setUserPermissionsList(data?.permissions);
	}, [data]);

	const handlePermissionEventCheck = (event_id, perm_id, group_id) => {
		const newValue = [...userPermissionsList]
			?.find((module) => module?.id == group_id)
			?.permissions?.find((perm) => perm?.id == perm_id)
			?.page_events?.find((e) => e?.id == event_id)?.has_permission
			? 0
			: 1;

		setUserPermissionsList((oldData) => {
			const copy = [...oldData];
			const permEvent = copy
				?.find((module) => module?.id == group_id)
				?.permissions?.find((perm) => perm?.id == perm_id)
				?.page_events?.find((e) => e?.id == event_id);
			permEvent.has_permission = newValue;
			return copy;
		});

		//Get API Response and repeat above 3 lines along with the last line only if success

		if(data?.role) {
			toast.promise(
				api
					.updateRolePermissions(data?.role?.id, {
						is_checked: newValue,
						page_event_ids: [event_id]
					})
					.then((res) => {
						if (res?.status) {
						} else {
							setUserPermissionsList((oldData) => {
								const copy = [...oldData];
								const permEvent = copy
									?.find((module) => module?.id == group_id)
									?.permissions?.find((perm) => perm?.id == perm_id)
									?.page_events?.find((e) => e?.id == event_id);
								permEvent.has_permission = newValue ? 0 : 1;
								return copy;
							});
						}
	
						return res?.status ? res : null;
					})
					.catch(() => {
						setUserPermissionsList((oldData) => {
							const copy = [...oldData];
							const permEvent = copy
								?.find((module) => module?.id == group_id)
								?.permissions?.find((perm) => perm?.id == perm_id)
								?.page_events?.find((e) => e?.id == event_id);
							permEvent.has_permission = newValue ? 0 : 1;
							return copy;
						});
					}),
				{
					loading: 'Updating Permission...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update permission.</b>
				}
			);
		} else {
			toast.promise(
				api
					.updateUserPermissions(data?.user?.id, {
						is_checked: newValue,
						page_event_ids: [event_id]
					})
					.then((res) => {
						if (res?.status) {
						} else {
							setUserPermissionsList((oldData) => {
								const copy = [...oldData];
								const permEvent = copy
									?.find((module) => module?.id == group_id)
									?.permissions?.find((perm) => perm?.id == perm_id)
									?.page_events?.find((e) => e?.id == event_id);
								permEvent.has_permission = newValue ? 0 : 1;
								return copy;
							});
						}
	
						return res?.status ? res : null;
					})
					.catch(() => {
						setUserPermissionsList((oldData) => {
							const copy = [...oldData];
							const permEvent = copy
								?.find((module) => module?.id == group_id)
								?.permissions?.find((perm) => perm?.id == perm_id)
								?.page_events?.find((e) => e?.id == event_id);
							permEvent.has_permission = newValue ? 0 : 1;
							return copy;
						});
					}),
				{
					loading: 'Updating Permission...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update permission.</b>
				}
			);
		}

		

		// setUserPermissionsList(copy);
	};

	return (
		<Scroller>
			<div className="user-permissions-box">
				{userPermissionsList?.map((module) => {
					return module?.permissions?.length > 0 ? (
						<>
							<div className="user-permission-group">
								<div>
									{/* <div className="myTest custom-control custom-checkbox">
										<input type="checkbox" className="custom-control-input" id="groupTest" indeterminate/>
										<label className="custom-control-label" for="groupTest" style={{ fontSize1: '12px' }}></label>
									</div> */}
									<h4>{module?.name}</h4>
								</div>

								{module?.permissions?.map((permission) => {
									return (
										<>
											<h6 className="permission-group-title">{permission?.name}</h6>
											<div className="permission-selectors">
												{permission?.page_events?.map((pEvent) => {
													return (
														<div className="pEvent-selector">
															{/* <div className="form-check">
													<input className="form-check-input" type="checkbox" value="" id={pEvent.name} checked={pEvent?.has_pEvent}/>
													<label
														className="form-check-label"
														for={pEvent.name}
														style={{ fontSize1: '12px' }}
													>
														{pEvent.name}
													</label>
												</div> */}

															<div className="myTest custom-control custom-checkbox">
																<input
																	type="checkbox"
																	className="custom-control-input"
																	id={pEvent.name}
																	checked={pEvent?.has_permission}
																	onChange={(e) =>
																		handlePermissionEventCheck(pEvent.id, permission?.id, module?.id)
																	}
																	disabled={!hasPerms(['Update User Permissions (Checkbox)'])}
																/>
																<label
																	className="custom-control-label"
																	for={pEvent.name}
																	style={{ fontSize1: '12px' }}
																>
																	{pEvent?.name}
																</label>
															</div>
														</div>
													);
												})}
											</div>
										</>
									);
								})}
							</div>
						</>
					) : (
						<></>
					);
				})}
			</div>
		</Scroller>
	);
};

export default UserPermissions;
