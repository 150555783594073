import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import useSound from 'use-sound';
import errorSound from '../../../../assets/audio/error.mp3';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiPackageBold, PiTestTubeFill, PiWarningFill } from 'react-icons/pi';
import { TbDatabaseExclamation } from 'react-icons/tb';
import NoImage from 'assets/images/no-image.png';

const ReplacementModal = ({ openPopup, returnsDetail, handleReplacementImeis, handleClose, handleSave, disableStatus }) => {
	const [alertMessage, setAlertMessage] = useState();
	const [invoiceList, setInvoiceList] = useState([]);
	const [imeiReplacementInputValue, setImeiReplacementInputValue] = useState('');
	const [modelImeis, setModelImeis] = useState([]);
	const [stockImeis, setStockImeis] = useState([]);
	const [standardRepairTypes, setStandardRepairTypes] = useState([]);
	const imeiInputRef = useRef(null);
	const [errorSoundPlay] = useSound(errorSound);
	const [selectedRepairs, setSelectedRepairs] = useState('');
	const [confirmDisabled, setConfirmDisabled] = useState(true);

	const [selectedInboundItems, setSelectedInboundItems] = useState([]);

	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const returnReasons = [
		{ label: 'Wrong product', value: 1 },
		{ label: 'Defective', value: 2 },
		{ label: 'Unknown', value: 3 },
		{ label: 'Wrong size', value: 4 }
	];

	const [formInfo, setFormInfo] = useState({
		estimationId: ''
	});

	const handleIMEIInputKeydown = (event) => {
		if (event.key === 'Enter') {
			addModalImei();
		}
	};

	const handleConfirmImei = () => {
		addModalImei();
	};

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const handleDeleteImei = (index) => {
		const newStockImeis = stockImeis.filter((_, i) => i !== index);
		setStockImeis(newStockImeis);
	};

	const handleSetSelectedRepairs = (selected, index) => {
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, repairs: selected };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
		// console.log(repairs)
	};

	const handleRepairCost = (value, index) => {
		const cost = value.target.value;
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, cost: cost };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
	};

	const addModalImei = async () => {
		if (!imeiReplacementInputValue || !imeiReplacementInputValue.trim()) {
			showErrorDialog('Invalid IMEI', `Invalid IMEI Entered`);
			setImeiReplacementInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}

		const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

		if (!alphanumericRegex.test(imeiReplacementInputValue)) {
			showErrorDialog('Invalid IMEI', `Please enter a valid IMEI Number`);
			setImeiReplacementInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}
		api
			.getReturnsImei({
				imei: imeiReplacementInputValue
			})
			.then((res) => {
				if (res?.status) {
					const tempImei = res?.data;
					tempImei.is_repair = false;
					// setStockImeis([...stockImeis, tempImei]);
					setStockImeis([tempImei]);
					setImeiReplacementInputValue('');
					imeiInputRef.current.value = '';
				} else {
					showErrorDialog('Invalid IMEI', `Couldn't find IMEI ${imeiReplacementInputValue} from the selected order`);
					setImeiReplacementInputValue('');
					imeiInputRef.current.value = '';
					errorSoundPlay();
				}
			});
	};

	const handleSubmitImeis = (action) => {
		handleReplacementImeis(stockImeis, action);
		setStockImeis([]);
	};

	const handleReplacementPrice = (value, index) => {
		const price = value.target.value;
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				// return { ...item, product?.price: price };
				return { ...item, product: { ...item.product, price: price } };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
	};

	const onSubmit = () => {
		if (formInfo.estimationId == '') {
			setAlertMessage({
				user: 'Please select order.'
			});
		} else {
			const data = {
				...formInfo,
				estimationId: formInfo.estimationId
			};
			handleSave(data);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const disablepopup = () => {
		handleClose();
	};
	return (
		<>
			<Modal show={openPopup} onHide={disablepopup} centered size="md" backdrop="static">
				<Modal.Body>
					<div className="returns-popup-body">
						<div className="foundListPopup">
							<div className="d-flex TableSerachBar text-center align-items-center justify-content-between mb-2 ml-0 pl-0">
								<h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0">Search IMEI</h5>
								<button className="btn modal-close-icon" onClick={handleClose}>
									<svg
										id="Component_127_1"
										height="16"
										width="16"
										data-name="Component 127 – 1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 19.295 19.289"
									>
										<path
											id="Icon_ionic-ios-close"
											data-name="Icon ionic-ios-close"
											d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
											transform="translate(-11.285 -11.289)"
										/>
									</svg>
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-12">
									<div className="row">
										<div className="form-group col-md-12">
											<div className="input-icon">
												<input
													ref={imeiInputRef}
													type="text"
													id="input-example"
													name="input-name"
													className="form-control form-control-sm"
													placeholder="IMEI"
													minLength={15}
													maxLength={16}
													onChange={(e) => {
														setImeiReplacementInputValue(event.target.value);
														if (event.target.value.length > 0) {
															setConfirmDisabled(false);
														} else {
															setConfirmDisabled(true);
														}
													}}
													onKeyDown={(e) => {
														handleIMEIInputKeydown(e);
													}}
													style={{ padding: '1.375rem .75rem' }}
													autoComplete={'off'}
													autoFocus
												/>
												<div className="icon">
													<svg
														stroke="currentColor"
														fill="currentColor"
														stroke-width="0"
														viewBox="0 0 512 512"
														height="1em"
														width="1em"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill="none"
															stroke-miterlimit="10"
															stroke-width="32"
															d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
														></path>
														<path
															fill="none"
															stroke-linecap="round"
															stroke-miterlimit="10"
															stroke-width="32"
															d="M338.29 338.29L448 448"
														></path>
													</svg>
												</div>
											</div>
										</div>
									</div>
									{stockImeis?.length > 0 && (
										<>
											<div className="row">
												<div className="form-group col-md-12">
													<table className="table ">
														<tbody>
															{stockImeis?.map((item, index) => {
																return (
																	<>
																		<tr>
																			<td className="text-center" width="8%">
																				{
																					item?.product?.thumbnail_url ? (
																						<>
																							<img style={{ height: '80px' }} src={item?.product?.thumbnail_url} />
																						</>
																					) : (
																						<>
																							<img style={{ height: '80px' }} src={NoImage} />
																						</>
																					)
																				}
																			</td>
																			<td className="font14 v-align-middle">
																				<div className="row">
																					<div className="col-12">
																						<div className="row">
																							<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																								IMEI
																							</div>
																							<div className="col-9" style={{ fontWeight: '500' }}>
																								{item?.imei}
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																								Model
																							</div>
																							<div className="col-9" style={{ fontWeight: '500' }}>
																								{item?.product?.product_name}
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																								Warranty
																							</div>
																							<div className="col-9" style={{ fontWeight: '500' }}>
																								<span className={item?.warranty?.status == 'Valid' ? 'text-success' : 'text-danger'}>
																									{item?.warranty?.message}
																								</span>
																							</div>
																						</div>
																					</div>
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan={2}>
																				<div className="row" style={{ marginTop: '8px' }}>
																					<div className="col-12">
																						<div className="p-inputgroup flex-1">
																							<span className="p-inputgroup-addon">
																								<i className="pi pi-user"></i>
																							</span>
																							<input
																								type="number"
																								className="form-control"
																								value={item.product?.price}
																								placeholder="Replacement Price"
																								onChange={(e) => {
																									handleReplacementPrice(e, index);
																								}}
																								onFocus={(event) => {
																									event.target.select();
																								}}
																							/>
																						</div>
																					</div>
																				</div>
																			</td>
																		</tr>
																	</>
																);
															})}
														</tbody>
													</table>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
						{stockImeis?.length < 1 ? (
							<>
								<div className="row">
									<div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'center' }}>
										<button
											disabled={confirmDisabled}
											onClick={() => handleConfirmImei()}
											className="btn btn-main btn-with-disabled"
											style={{ margin: '10px' }}
										>
											{/* <button disabled={ confirmDisabled } onClick={() => handleConfirmImei()} className="btn btn-main btn-with-disabled" style={{ margin: '10px'}}> */}
											<span style={{ padding: '0 35px' }}>Confirm</span>
										</button>
									</div>
								</div>
								<div className="row">
									<div className="col-12 text-center" style={{ margnTop: '15px' }}>
										<a href="javascrip:void(0);" onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
											Cancel
										</a>
									</div>
								</div>
							</>
						) : (
							<>
								{/* <div className="btnarea">
										<button disabled={disableStatus} onClick={() => handleSubmitImeis()} className="btn btn-main">
											<FaPlus /> Add Products
										</button>
									</div> */}

								<div className="row">
									<div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'center' }}>
										<button onClick={() => handleSubmitImeis('save')} className="btn btn-main btn-with-disabled" style={{ margin: '10px' }}>
											<span style={{ padding: '0 35px' }}>Save</span>
										</button>
									</div>
								</div>
								<div className="row">
									<div className="col-12 text-center" style={{ margnTop: '15px' }}>
										<a href="javascript:void(0);" onClick={() => handleSubmitImeis('empty')} style={{ cursor: 'pointer' }}>
											Add Replacement
										</a>
									</div>
								</div>
							</>
						)}
					</div>
				</Modal.Body>
			</Modal>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}
		</>
	);
};

export default ReplacementModal;
