import React, { useEffect, useState, useCallback } from 'react';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Pagination from 'ui-component/Pagination';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Loader from 'ui-component/Loader';
import { useSelector } from 'react-redux';
import { FaSearch, FaTimesCircle } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { CgSearchFound } from 'react-icons/cg';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Skeleton, Tooltip } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { calculateRateValue, numberFormatter } from 'utils/currency';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { Link } from 'react-router-dom';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import moment from 'moment';
import { LiaCommentSolid } from 'react-icons/lia';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';
import { SlCloudUpload } from 'react-icons/sl';
import FileUpload from 'ui-component/FileUpload';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import { useDropzone } from 'react-dropzone';

const ImagesTab = ({
    productDetails,
    handleFileUpload,
    deleteThumbnail,
    handleSetDefaultThumbnail
}) => {
    const [disableStatus, setDisablestatus] = useState(false);
    const [show, setShow] = useState('');

    const onDrop = useCallback(
        (acceptedFiles) => {
            handleFileUpload(acceptedFiles)
        },
        []
    );
    const handleClose = () => setShow('');

    const handleDelete = () => {
        deleteThumbnail(show);
        setShow('')
    };

    const handleDeleteThumbnail = useCallback((thumbnailId) => {
        // deleteThumbnail(thumbnailId);
        setShow(thumbnailId);
    });

    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <>
            <div className="form-group ">
                <div className="row mt20">
                    <div className="col-md-12">
                        <div className="">
                            <div className="image-upload">
                                {
                                    productDetails?.thumbnails?.map((thumbnail, index) => (

                                        <span className="thumbnail-display">
                                            <img src={thumbnail?.thumbnail_url} style={{ width: '100px', minHeight: '100px', minWidth: '100px' }} alt="product" />
                                            {thumbnail?.is_default == 1 ? (
                                                <>
                                                    <button className="thumbnail-btn thumbnail-btn-active">
                                                        <span>Default</span>
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button className="thumbnail-btn" onClick={() => handleSetDefaultThumbnail(thumbnail?.id)}>
                                                        <span>Set as default</span>
                                                    </button>
                                                </>
                                            )}
                                            {
                                                thumbnail?.is_default == 0 && (
                                                    <button className="thumbnail-close" onClick={() => handleDeleteThumbnail(thumbnail?.id)}>
                                                        <FaTimes />
                                                    </button>
                                                )
                                            }
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {/* { productDetails?.thumbnail !== null && (
                        <div className="col-md-12">
                            <div className="">
                                <div className="image-upload">
                                    <label htmlFor="file-input" className="thumbnail-display">
                                        <img src={ productDetails?.thumbnail_url } style={{ width: '100px', minHeight: '100px', minWidth: '100px' }} alt="product" />
                                        <div className="actions">
                                            <button className="btn thumbnail-btn ">Default</button>
                                        </div>
                                        <button className="thumbnail-close">
                                            <FaTimes />
                                        </button>
                                    </label>
                                </div>
                            </div>
                        </div>
                    
                    )} */}
                    <div className="col-12 mt30"  {...getRootProps()}>

                        <div className="product-container-dragdrop">

                            <input {...getInputProps()} style={{ display: 'none' }} />
                            <div style={{ padding: 'padding: 0px 0px 15px 0px;' }}>
                                <div style={{ marginLeft: '30px', marginBottom: '12px' }}>
                                    <button className="btn btn-default btn-sm drag-drop-btn">Add Images</button>
                                    <span className="drag-drop-text ">or drag them here</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {!!show && <DeleteConfirmation
                show={show}
                handleClose={handleClose}
                handleDelete={handleDelete}
                title="Confirm"
                message={`Are you sure you want to delete ${show?.name}`}
            />}
        </>
    );
};

export default ImagesTab;
