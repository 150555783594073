import { BiCategoryAlt } from "react-icons/bi";
import { BsClipboardCheck } from "react-icons/bs";
import { FaBoxesStacked } from "react-icons/fa6";
import { GiAutoRepair, GiHandTruck, GiMagnifyingGlass } from "react-icons/gi";
import { PiPackageBold, PiTestTubeFill } from "react-icons/pi";
import { LuTestTubes } from "react-icons/lu";

export const taskboardProgresses = [
    { name: 'Inspection', icon: <BsClipboardCheck />, status: '', link: '' },
    { name: 'Collection', icon: <GiHandTruck />, status: '', link: '' },
    { name: 'Segregation', icon: <BiCategoryAlt />, status: '', link: '' },
    { name: 'Testing', icon: <PiTestTubeFill />, status: '', link: '' },
    { name: 'Inbounding', icon: <FaBoxesStacked />, status: '', link: 'operations/inbounding/31' },
    { name: 'Quality Control', icon: <GiMagnifyingGlass />, status: '', link: '' },
    { name: 'Repairing', icon: <GiAutoRepair />, status: '', link: '' },
    { name: 'Retesting', icon: <LuTestTubes />, status: '', link: '' },
    { name: 'Packing', icon: <PiPackageBold />, status: '', link: '' }
]