import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * A react hook that allows to get all permissions for logged in user or permissions,
 * or permissions for a specific module, along with a function to check if user is allowed to
 * use specified permissions.
 *
 * @memberof module:React
 * @param {number} module_id (Optional) if specified only permissions related to specified module will return
 *
 * The array that returns has all or module specified permissions for current user.
 * The First callback is a function used to check if user has specified permissions. Params: array of permissions (required). It will return a boolean based on if the specified permissions specified are allowed to the user.
 * The Second callback is a function used to check if user has any of specified permissions. Params: array of permissions (required). It will return a boolean based on if the any of specified permissions specified are allowed to the user.
 * @returns {[Array, callback, callback]}
 */

const usePermissions = () => {
	const permissions = useSelector((state) => state.main.permissions);
	const dispatch = useDispatch();

	return [
		permissions,
		(perms = []) => {
			return perms?.every((perm) => permissions?.some((p) => p == perm));
		},
		(perms = []) => {
			return perms?.some((perm) => permissions?.some((p) => p == perm));
		}
	];
};

export default usePermissions;
