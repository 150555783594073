import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './RoleFormModal.scss';

import { IoWarningOutline } from 'react-icons/io5';
import { PiWarningCircleFill, PiWarningFill } from 'react-icons/pi';
import { useEffect } from 'react';
import { BiBarcodeReader } from 'react-icons/bi';
// import BarcodeScannerComponent from 'react-qr-barcode-scanner-18';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { FaPlus, FaPlusCircle, FaSave, FaTimes } from 'react-icons/fa';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';

function RoleFormModal({ show, handleClose, onSubmit, title, message, customIcon, confirmDanger, isLoading }) {
	const confirmBtnRef = useRef(null);
	const roleInputFocus = useRef(null);
	const isMobile = useCheckMobileScreen();

	const [role, setRole] = useState('');

	useEffect(() => {
		if (show?.edit) {
			console.log(show);
			setRole(show?.edit?.name);
		}
	}, [show]);

	const handleSubmit = (event) => {
		event.preventDefault();
		onSubmit &&
			onSubmit({
				edit: show?.edit ? show?.edit : false,
				role: role
			});
	};

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className="role-modal-dialog"
		>
			<Modal.Body>
				<Modal.Title>
					<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">{show?.edit ? 'Update Role' : 'Create Role'}</h5>
					<button className="btn modal-close-icon" onClick={handleClose}>
						<FaTimes />
					</button>
				</Modal.Title>

				<form onSubmit={handleSubmit}>
					<div>
						{/* <p className="message">{message || 'Please enter or scan barcode to continue.'}</p> */}

						{/* {isMobile && (
							<BarcodeScannerComponent
								onUpdate={(err, result) => {
									if (result) setScanBc(result.text);
									else setScanBc('');
								}}
							/>
						)} */}

						<div className="form-group">
							<label htmlFor="" style={{ width: '100%', textAlign: 'left' }}>
								Role
							</label>
							<input
								ref={roleInputFocus}
								type="text"
								className="form-control"
								autoFocus
								value={role}
								onChange={(e) => {
									setRole(e.target.value);
								}}
								required
							/>
						</div>
					</div>

					<div className="modal-actions">
						{/* <button
							className="btn btn-link"
							type="button"
							onClick={(e) => {
								handleClose && handleClose(e);
							}}
						>
							Cancel
						</button> */}
						{show?.edit ? (
							<PermissionAllowed permissions={['Update Role Permission (Button)']}>
								<button
									ref={confirmBtnRef}
									type="submit"
									className={`btn btn-main`}
									disabled={isLoading || role?.length < 1}
								>
									<FaSave /> Update
								</button>
							</PermissionAllowed>
						) : (
							<PermissionAllowed permissions={['Create New Role (Action)']}>
								<button
									ref={confirmBtnRef}
									type="submit"
									className={`btn btn-main`}
									disabled={isLoading || role?.length < 1}
								>
									<FaPlus /> Create
								</button>
							</PermissionAllowed>
						)}
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}

export default RoleFormModal;
