
import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-hot-toast';
import { FaDownload } from 'react-icons/fa6';
import CreditNoteDetailInvoice from './CreditNoteDetailInvoice';
import { LuChevronDown } from 'react-icons/lu';
import { Dropdown } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Notes from 'ui-component/notes';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const CreditNoteDetails = () => {
	const { id } = useParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);

	const [selectedCreditNote, setSelectedCreditNote] = useState();

	const [privacyMode, setPrivacyMode] = useState(false);

	const history = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const [creditNoteRow, setCreditNoteRow] = useState({
		notes: []
	});

	useEffect(() => {
		initiateDatas();
	}, []);

	const initiateDatas = () => {
		if (id) {
			api.getCreditNoteById(id).then((res) => {
				if (!res.data) {
					history('/credit-note');
				} else {
					setSelectedCreditNote(res?.data);
				}
			});
		} else {
			history('/credit-note');
		}
	};

	const addNote = (noteInputValue) => {
		toast.promise(
			api
				.newCreditNoteNotes(id, {
					details: noteInputValue,
					type: 'public'
				})
				.then(
					(res) => {
						if (res.status) {
							setCreditNoteRow((oldData) => ({
								...oldData,
								notes: [
									{
										id: res?.data?.id,
										details: noteInputValue,
										date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
										time: moment(res?.data?.created_at).format('HH:mm'),
										user_name: userName,
										expand: false,
										isDeletable: true
									},
									...oldData?.notes
								]
							}));
						}

						return res?.status ? res : null;
					},
					() => {}
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const toggleNoteExpand = (index) => {
		setCreditNoteRow((oldData) => ({
			...oldData,
			notes: oldData?.notes?.map((note, i) =>
				index == i
					? {
							...note,
							expand: !note?.expand
					  }
					: note
			)
		}));
	};

	const removeNote = (index) => {
		toast.promise(
			api.removeCreditNoteNote(id, creditNoteRow?.notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setCreditNoteRow((oldData) => ({
							...oldData,
							notes: oldData?.notes?.filter((note, i) => index != i)
						}));
					}

					return res?.status ? res : null;
				},
				() => {}
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	useEffect(() => {
		if (selectedCreditNote) {
			const expDate = moment(selectedCreditNote?.created_at).format('YYYY-MM-DD');
			let tempRow = selectedCreditNote?.credit_note_details.map((item, index) => {
				return {
					id: index,
					modelId: item?.estimation_detail?.product_id,
					type: item?.estimation_detail?.product?.product_name,
					quantity: item?.estimation_detail?.qty,
					unitPrice: Number(item?.estimation_detail?.unit_price),
					total: item?.estimation_detail?.total_price
				};
			});
			tempRow = [
				...tempRow,
				{
					id: tempRow.length + 1,
					modelId: '',
					type: '',
					quantity: '',
					unitPrice: '',
					total: ''
				}
			];

			const tempNotes = {};

			let notesArray = [];

			selectedCreditNote?.notes?.forEach((note) => {
				notesArray = [
					{
						id: note?.id,
						details: note?.details,
						date: moment(note?.created_at).format('YYYY-MM-DD'),
						time: moment(note?.created_at).format('HH:mm'),
						user_name: `${note?.user_created?.first_name} ${note?.user_created?.last_name}`,
						expand: false,
						isDeletable: note?.is_deletable
					},
					...notesArray
				];
			});

			notesArray = _.sortBy(notesArray, (n) => -n.id);
			setCreditNoteRow({ ...tempNotes, notes: notesArray });
		}
	}, [selectedCreditNote]);

	const downloadFile = (blob, fileName = 'creditnote.pdf') => {
		var newBlob = new Blob([blob], { type: 'application/pdf' });
		saveAs(newBlob);
	};

	const downloadEstimate = (from = 'coolmix', to = 'customer') => {
		// toast.promise(
		api
			.downloadCreditNote(id, {
				template: from,
				to
			})
			.then((res) => {
				// if (res?.status) {

				var newBlob = new Blob([res], { type: 'application/pdf' });
				saveAs(newBlob, `${selectedCreditNote?.credit_note_number}.pdf`);
			});
	};

	return (
		<>
			<div className="row m-0 w-100" style={{ paddingInline: '50px' }}>
				<div className="col-12 col-md-8">
					<div className="section-head">
						<div className="actions" style={{ gap: '5px' }}>
							<Dropdown className="btnDel iconbtn">
								<Dropdown.Toggle as="span">
									<button type="button" className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: 'unset' }}>
										<FaDownload />
										Download
									</button>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{!!selectedCreditNote?.transit_company ? (
										<Dropdown.Item
											onClick={() => {
												downloadEstimate('mixglobal', 'transitcompany');
											}}
										>
											Mix Global to {selectedCreditNote?.transit_company?.name}
										</Dropdown.Item>
									) : (
										<Dropdown.Item
											onClick={() => {
												downloadEstimate('mixglobal', 'customer');
											}}
										>
											Mix Global to {selectedCreditNote?.customer?.name}
										</Dropdown.Item>
									)}

									<Dropdown.Item
										onClick={() => {
											downloadEstimate('coolmix', 'customer');
										}}
									>
										Coolmix to Customer
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div className="actions" style={{ gap: '5px' }}>
							<PermissionAllowed permissions={['Edit Credit Note (Button)']} hasSome>
								<Dropdown className="btnDel iconbtn" align={'end'}>
									<Dropdown.Toggle as="span">
										<Link to={''} className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: 'unset' }}>
											Extra <LuChevronDown />
										</Link>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<PermissionAllowed permissions={['Edit Credit Note (Button)']}>
											<Dropdown.Item
												onClick={() => {
													history(`/credit-note/edit/${id}`);
												}}
											>
												Edit
											</Dropdown.Item>
										</PermissionAllowed>
									</Dropdown.Menu>
								</Dropdown>
							</PermissionAllowed>
						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-3 p-3 p-xl-4 w-100">
							<CreditNoteDetailInvoice creditnotes={selectedCreditNote} privacyMode={privacyMode} />
						</div>
					</div>
				</div>
				<div className="col-12 col-md-3">
					<div style={{ marginTop: '65px' }}>
						<div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Currency
							</label>

							<p>{selectedCreditNote?.currency?.toUpperCase()}</p>
						</div>
						<div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Customer
							</label>

							<Link to={`/contacts/${selectedCreditNote?.customer?.id}`}>
								<PrivacyModeContent>{selectedCreditNote?.customer?.name}</PrivacyModeContent>
							</Link>
						</div>

						<div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Transit Company
							</label>

							<Link to={`/contacts/${selectedCreditNote?.transit_company?.id}`}>
								<PrivacyModeContent>{selectedCreditNote?.transit_company?.name}</PrivacyModeContent>
							</Link>
						</div>

						<Notes
							notes={creditNoteRow?.notes}
							addNote={addNote}
							deleteNotePermission={[]}
							removeNote={removeNote}
							toggleNoteExpand={toggleNoteExpand}
							parentId={1}
							type="creditNote"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreditNoteDetails;
