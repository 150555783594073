import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';

const FoundPopup = ({ openpopup, currentRowData, selectedListids: selectedListIds, handleClose, handleSave, disableStatus }) => {
	const [rows, setRows] = useState([{ id: 1, purchase_id: '', productId: '', quantity: '' }]);
	const [alertMessage, setAlertMessage] = useState();
	const [userList, setUserList] = useState([]);

	const [formInfo, setFormInfo] = useState({
		userId: '',
		date: '',
		time: '',
		currentStatus: ''
	});
	const removeRow = (id) => {
		if (rows.length === 1) {
			return;
		}
		const updatedRows = rows.filter((row) => row.id !== id);
		setRows(updatedRows);
	};

	const handleValue = (val) => {
		setFormInfo(val);
	};

	useEffect(() => {
		api.getSelectOptionsList('user').then((res) => {
			const tempUser = res.data.map((item) => ({
				id: item.id,
				label: `${item.first_name} ${item.last_name || ''}`,
				value: item.id
			}));
			setUserList(tempUser);
		});
		setFormInfo({
			...formInfo,
			date: new Date().toISOString().substring(0, 10),
			time: moment().format()
		});
	}, []);

	useEffect(() => {
		const selectedRow = currentRowData.filter((row) => selectedListIds.includes(row.id));

		handleValue({
			...formInfo,
			currentStatus: selectedRow?.[0]?.master_status?.name,
			supplier_id: selectedRow?.[0]?.supplier_id
		});
		if (selectedRow.length > 0) {
			const tempData = selectedRow.map((item, index) => {
				return { id: index + 1, purchase_id: item.id, productId: item.id, product: item?.product, quantity: item.purchase_qty };
			});
			setRows(tempData);
		}
	}, [openpopup]);
	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		zIndex: 9
	};
	const onSubmit = () => {
		if (formInfo.userId == '') {
			setAlertMessage({
				user: 'Please select user.'
			});
		} else if (formInfo.date == '') {
			setAlertMessage({
				date: 'Please Select Date.'
			});
		} else if (formInfo.time == '') {
			setAlertMessage({
				time: 'Please Select Time.'
			});
		} else {
			let purchaseId = rows.map((item) => item.purchase_id);
			const data = {
				...formInfo,
				purchase_list_ids: purchaseId
			};
			handleSave(data);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const disablepopup = () => {
		setRows('');
		handleClose();
	};
	return (
		<>
			<Modal show={openpopup} onHide={disablepopup} centered size="lg">
				<Modal.Body>
					<div className="p-2">
						<div className="foundListPopup">
							<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 ml-0 pl-0">
								<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">
									{formInfo?.currentStatus == 'Inspected' ? 'Need to Collect' : 'Assign Inspection'}
								</h5>
								<button className="btn modal-close-icon" onClick={handleClose}>
									<FaTimes />
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-md-6">
									<form className="assign found">
										<div className="row w-100">
											<div className="form-group col-md-12">
												<label className="">User</label>

												<Select
													className="rs-container"
													classNamePrefix="rs"
													isSearchable={true}
													name="user"
													options={userList}
													placeholder="Select"
													onChange={(selected) =>
														handleValue({
															...formInfo,
															userId: selected.id
														})
													}
												/>
												{alertMessage?.user && (
													<>
														<span className="error-message text-danger">{alertMessage?.user}</span>
													</>
												)}
											</div>
											<div className="form-group col-md-6">
												<label className="">Date</label>
												<input
													type="date"
													onChange={(e) => {
														handleValue({
															...formInfo,
															date: e.target.value
														});
													}}
													defaultValue={formInfo?.date}
													value={formInfo?.date}
													className="form-control"
												/>
												{alertMessage?.date && (
													<>
														<span className="error-message text-danger">{alertMessage?.date}</span>
													</>
												)}
											</div>
											<div className="form-group col-md-6">
												<label className="">Time</label>
												<input
													type={'time'}
													className="form-control"
													value={formInfo?.time}
													onChange={(e) => {
														handleValue({
															...formInfo,
															time: e.target.value
														});
													}}
												/>
												{alertMessage?.time && (
													<>
														<span className="error-message text-danger">{alertMessage?.time}</span>
													</>
												)}
											</div>
										</div>
									</form>
								</div>
								<div className="col-md-6">
									<div className="collaps found table-responsive-xl">
										<Scroller heightvalue={'20vh'}>
											<table className="table table-md  formActBtns border-0">
												<thead style={tableHeaderStyles}>
													<tr>
														<th className="align-middle" width={'100px'}>
															Model
														</th>

														<th className="align-middle" width={'50px'}>
															Quantity
														</th>
														<th className="align-middle" width={'50px'}></th>
													</tr>
												</thead>
												<tbody>
													{rows.map((row, index) => (
														<>
															<tr key={row.id}>
																<td className="align-middle">{row.product?.product_name}</td>
																<td className="align-middle">{row.quantity}</td>
																<td className="align-middle text-right">
																	<span className="btnEdit plain-iconbtn" onClick={() => removeRow(row.id)}>
																		<FaTimes />
																	</span>
																</td>
															</tr>
														</>
													))}
												</tbody>
											</table>
										</Scroller>
									</div>
								</div>
							</div>
						</div>
						<div className="btnarea mt-5">
							<button disabled={disableStatus} onClick={onSubmit} className="btn btn-main">
								<FaPlus /> Assign
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default FoundPopup;
