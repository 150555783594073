import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { FaSave } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import FileUpload from 'ui-component/FileUpload';
import moment from 'moment';
import { useForm } from 'react-hook-form';

const ShipmentTrackingModal = ({ shipmentId, shippingCompanies, handleCloseShipmentTrackingModal, onFormSubmit, redirectionData }) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [disableStatus, setDisablestatus] = useState(false);
    
	const [formInfo, setFormInfo] = useState({
		trackingNo: '',
		shippingCompanyId: '',
	});

	const handleValue = (val) => {
		setFormInfo(val);
	};
	const handleOnSubmit = (data) => {
		onFormSubmit &&
			onFormSubmit({
				trackingNo: formInfo?.trackingNo,
				shippingCompanyId: formInfo?.shippingCompanyId,
			});
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch
	} = useForm();

	useEffect(() => {
	}, [shipmentId]);

	return (
		<>
			<Modal show={shipmentId} onHide={handleCloseShipmentTrackingModal} centered  dialogClassName="modal-sm">
				<Modal.Body>
				    <form onSubmit={handleSubmit(handleOnSubmit)}>
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="h6 text-white text-center pb-2">Tracking # for End Customer</h4>
                                <div className="form-group mb-2">
                                    <label>
                                        Shipping Company
                                    </label>
                                    
                                    <Select
                                        className="rs-container-est-white"
                                        classNamePrefix="rs"
                                        isSearchable={true}
                                        name="shipping-company"
                                        placeholder="Choose"
                                        options={shippingCompanies?.map((item) => ({ label: item?.name, value: item?.id }))}
                                        onChange={(e) => { 
                                            handleValue({
                                                ...formInfo,
                                                shippingCompanyId: e.value
                                            })
                                        }}
                                    />
                                    {errors.shippingCompanyId && <p className="mt-1 text-danger">Shipping Comapny is Required</p>}
                                </div>
                                <div className="form-group mb-2">
                                    <label>
                                        Tracking No.
                                    </label>
                                    <input
                                        type="text"
                                        style={{ cursor: 'pointer' }}
                                        className="form-control"
                                        onKeyUp={(e) => 
                                            handleValue({
                                                ...formInfo,
                                                trackingNo: e.target?.value
                                            })
                                        }
                                        {...register('trackingNo', { required: true })}
                                    />
                                    {errors.trackingNo && <p className="mt-1 text-danger">Tracking Number Required</p>}
                                </div>
                                <div className="">
                                    <div className="btnarea w-100">
                                        <button onClick={handleCloseShipmentTrackingModal} className="btn btn-link">
                                            Cancel
                                        </button>

                                        <button disabled={disableStatus} className="btn btn-primary btn-outlined-main">
                                            <FaSave /> Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ShipmentTrackingModal;
