import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import ReactSelect from 'react-select';


const EmailPurchaseOrderModal = ({ openPopup, details, items, handleClose }) => {
    const [formInfo, setFormInfo] = useState({
        estimationId: '',
    });

    const [sendingEmail, setSendingEmail] = useState(false);
    const [supplierList, setSupplierList] = useState([]);
    const [defaultEmailMessage, setDefaultEmailMessage] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [supplierItems, setSupplierItems] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({
        id: '',
        name: ''
    });
    const handleValue = (val) => {
        setFormInfo({
            ...formInfo,
            estimationId: val?.id
        });
        setFormInfo({ estimationId: val?.value });
    };



    const [emailDetails, setEmailDetails] = useState({
        id: details?.id,
        supplierId: details?.supplier?.id,
        supplierName: details?.supplier?.name,
        representative: '',
        receiver: '',
        subject: '',
        message: '',
        // id: selectedForSending,
        type: openPopup,
    });

    const handleSupplierChange = (supplier) => {
        const thisSupplier = suppliers?.find((sup) => sup?.id == supplier?.value);

        setSupplierItems(items?.filter((item) => item.supplier_id == thisSupplier?.id))

        if (details?.contact) {
            const message = defaultEmailMessage?.replace(/{supplier_representative}/g, details?.contact);
            setEmailDetails({ ...emailDetails, supplierId: res?.data?.id, supplierName: res?.data?.name, receiver: res?.data?.email ?? '', message: message });
        } else {
            setEmailDetails({ ...emailDetails, supplierId: res?.data?.id, supplierName: res?.data?.name, receiver: res?.data?.email ?? '', message: defaultEmailMessage });
        }
        // api.getSupplierContact(thisSupplier?.id).then((res) => {
        //     if (res?.status) {
        //         if (res?.data?.contact_name) {
        //             const message = defaultEmailMessage?.replace(/{supplier_representative}/g, res?.data?.contact_name);
        //             setEmailDetails({ ...emailDetails, supplierId: res?.data?.id, supplierName: res?.data?.name, receiver: res?.data?.email ?? '', message: message });
        //         } else {
        //             setEmailDetails({ ...emailDetails, supplierId: res?.data?.id, supplierName: res?.data?.name, receiver: res?.data?.email ?? '', message: defaultEmailMessage });
        //         }


        //     }
        // });

    }

    useEffect(() => {
        const currentSuppliers = details?.purchase_invoice_items?.map((sup) => ({ id: sup?.supplier?.id, name: sup?.supplier?.name, email: sup?.supplier?.email }));

        setSuppliers(currentSuppliers);

        const customerArray = details?.purchase_invoice_items?.reduce((acc, item) => {
            // Check if the item is already in the accumulator
            const found = acc.find((a) => a.id === item?.supplier?.id);

            // If not found, add the item to the accumulator
            if (!found) {
                acc.push({ id: item?.supplier?.id, label: item?.supplier?.name, value: item?.supplier?.id });
            }

            return acc;
        }, []);
        setSupplierList(customerArray);

        api.getEmailTemplate(`send_purchase_order`).then((res) => {
            if (res?.status) {
                let defaultMsg = res?.data?.template?.replace(/{purchase_order_reference_no}/g, details?.purchase_order_id);
                setDefaultEmailMessage(defaultMsg)
                let subject = res?.data?.subject?.replace(/{purchase_order_reference_no}/g, details?.purchase_order_id);

                let message = res?.data?.template?.replace(/{purchase_order_reference_no}/g, details?.purchase_order_id);
                // if (selectedSupplier?.name) {
                if (details?.supplier?.contact) {
                    message = message?.replace(/{supplier_representative}/g, details?.supplier?.contact);
                }
                // }
                setEmailDetails({ ...emailDetails, subject: subject, message: message, receiver: details?.supplier?.email });
            }
        });
        // setEmailDetails({ ...emailDetails, receiver: details?.supplier?.email ?? ''})


    }, []);


    const sendEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        console.log('emailDetails', emailDetails)
        if (emailDetails?.supplierName.trim() == '') {
            toast.error(`Please select supplier`);
            return false;
        } else if (!emailRegex.test(emailDetails?.receiver)) {
            toast.error(`Please enter valid email address`);
            return false;
        } else if (emailDetails?.subject.trim() == '') {
            toast.error(`Please enter email subject`);
            return false;
        } else {
            // sendEstimateEmail(data);
            setSendingEmail(true);
            api.sendPurchaseOrderEmailToSupplier(emailDetails).then((res) => {
                if (res?.status) {
                    setSendingEmail(false);
                    toast.success(res?.message);
                    handleClose();
                } else {
                    setSendingEmail(false);
                    toast.error(res?.message);
                }
            });
        }
    }

    const disablepopup = () => {
        handleClose();
    };




    return (
        <>
            <Modal show={openPopup} onHide={disablepopup} centered size="md">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-left">

                                        <h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0" style={{ fontWeight: '600' }}>
                                            Send Purchase Order {details?.purchase_order_id} to {details?.supplier?.name ?? 'the supplier'}
                                        </h5>
                                        <br />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    {/* <FaTimes /> */}
                                    <svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                                        <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                                    </svg>


                                </button>
                            </div>
                            {/* <div className="row align-items-top">
                                <div className="col-12">
                                    <label className="font500">Supplier</label>
                                </div>
                                <div className="col-12 form-group">

                                    <ReactSelect
                                        className="rs-container"
                                        classNamePrefix="rs"
                                        name="customer"
                                        options={supplierList}
                                        // value={supplierList?.find((c) => c?.value == item?.supplier_id)}
                                        // onChange={(selected) => handleSupplierInfo(selected)}
                                        onChange={(selected) => {
                                            handleSupplierChange(selected);
                                            // setEmailDetails({ ...emailDetails, supplierId: selected?.value });
                                            // setEmailDetails({ ...emailDetails, supplierName: selected?.label });
                                        }}
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        placeholder="Select or type..."
                                        style={{ borderRadius: '18px' }}
                                    />
                                </div>
                            </div> */}
                            {/* {
                                supplierItems?.length > 0 && (
                                    <>
                                        <div className="row align-items-top">
                                            <div className="col-12">
                                                <div>Products to purchase from {emailDetails?.supplierName}</div>
                                                <div className="send-to-products-purchase">
                                                    {

                                                        supplierItems.map((supplierItem, index) => (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-10">{supplierItem?.name}</div>
                                                                    <div className="col-2 form-group">{supplierItem?.qty} pcs</div>
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            } */}
                            <div className="row align-items-top">
                                <div className="col-12">
                                    <label className="font500">Send to</label>
                                </div>
                                <div className="col-12 form-group">
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={emailDetails?.receiver}
                                        onChange={(e) => setEmailDetails({ ...emailDetails, receiver: e.target.value })}
                                        placeholder="Enter recipient's email address"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12">
                                    <label className="font500">Subject</label>
                                </div>
                                <div className="col-12 form-group">
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={emailDetails?.subject}
                                        onChange={(e) => setEmailDetails({ ...emailDetails, subject: e.target.value })}
                                        placeholder="Enter email subject"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12">
                                    <label className="font500">Email message</label>
                                </div>
                                <div className="col-12 form-group">
                                    <textarea
                                        className='form-control'
                                        rows="10"
                                        value={emailDetails?.message}
                                        onChange={(e) => setEmailDetails({ ...emailDetails, message: e.target.value })}
                                        style={{ resize: 'none' }}
                                    />
                                </div>
                            </div>
                            <div className="btnarea" style={{ justifyContent: 'right' }}>
                                <div className="">

                                    <div className="row">
                                        <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>

                                            <button onClick={handleClose} className="btn btn-main-danger btn-outlined-danger" style={{ marginRight: '4px' }}>
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-main-primary btn-outlined-main"
                                                style={{ marginLeft: '4px' }}
                                                disabled={!emailDetails?.message.trim() || sendingEmail}
                                                onClick={() => sendEmail()}
                                            >
                                                {
                                                    sendingEmail ? (
                                                        <>
                                                            <FaSpinner className='spinner' /> Sending {openPopup}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <svg
                                                                id="fi_2983788"
                                                                enable-background="new 0 0 512.005 512.005"
                                                                height="18"
                                                                viewBox="0 0 512.005 512.005"
                                                                width="18"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g>
                                                                    <path
                                                                        d="m511.658 51.675c2.496-11.619-8.895-21.416-20.007-17.176l-482 184c-5.801 2.215-9.638 7.775-9.65 13.984-.012 6.21 3.803 11.785 9.596 14.022l135.403 52.295v164.713c0 6.948 4.771 12.986 11.531 14.593 6.715 1.597 13.717-1.598 16.865-7.843l56.001-111.128 136.664 101.423c8.313 6.17 20.262 2.246 23.287-7.669 127.599-418.357 122.083-400.163 122.31-401.214zm-118.981 52.718-234.803 167.219-101.028-39.018zm-217.677 191.852 204.668-145.757c-176.114 185.79-166.916 176.011-167.684 177.045-1.141 1.535 1.985-4.448-36.984 72.882zm191.858 127.546-120.296-89.276 217.511-229.462z"
                                                                        fill="currentColor"
                                                                    ></path>
                                                                </g>
                                                            </svg>{' '}
                                                            Send {openPopup}
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EmailPurchaseOrderModal;
