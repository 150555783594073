import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

const ReturnsPerSupplierCard = ({ returns }) => {
	return (
		<div className="row" style={{ marginBottom: '20px' }}>
			<div className="col-12">
				<Fade>
					<div className="card" style={{ height: '160px' }}>
						<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: '7px' }}>
							<a style={{ fontWeight: '500' }}>Returns per supplier</a>
						</div>
						<div
							className="card-body custom-no-scroll"
							style={{ paddingTop: 5, paddingBottom: 0, paddingRight: 20, overflowY: 'auto', maxHeight: '200px' }}
						>
							{Object.entries(returns).map(([key, value], index) => (
								<Link to={`/contacts/${value.id}`} style={{ color: 'black', textDecoration: 'none' }}>
									<div className="row hover-effect" key={index} style={{ paddingBottom: '4px', paddingTop: '3px' }}>
										<div className="col-6 top-clients">
											<a style={{ whiteSpace: 'nowrap', fontWeight: 100 }}>{value.name}</a>
										</div>
										<div className="col-6 top-clients" style={{ textAlign: 'right' }}>
											<a style={{ fontWeight: 600 }}>{value.count}</a>
										</div>
									</div>
								</Link>
							))}
						</div>
					</div>
				</Fade>
			</div>
		</div>
	);
};

export default ReturnsPerSupplierCard;
