import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setContactlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import { FaEllipsisH, FaEye, FaPlus, FaSearch, FaTimes } from 'react-icons/fa';
import usePageTitle from 'hooks/usePageTitle';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton, Tooltip } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import toast from 'react-hot-toast';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import Select from 'react-select';
import { FaCheck } from 'react-icons/fa6';
import { LiaTimesSolid } from 'react-icons/lia';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

const CreditNotes = () => {
	const [setPageTitle] = usePageTitle('Credit Notes');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const history = useNavigate();
	const [dataList, setDataList] = useState([]);
	const [show, setShow] = useState('');
	const [isLoading, setIsloading] = useState(false);
	const [isFiltersLoading, setIsFiltersloading] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [allPermissions, hasEveryPermission] = usePermissions();
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const [customerList, setContactlist] = useState();
	const [pagination, setpagination] = useState([]);
	const [salesPerson, setSalesperson] = useState([]);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});

	const getContactList = () => {
		setIsloading(true);
		api.getCreditNotes().then((res) => {
			setDataList(res?.data?.data);
			setpagination(res?.data);
			dispatch(setLoading(false));
			setIsloading(false);
			isFirstLoad && setIsFirstLoad(false);
		});
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getCreditNoteFilter().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	useEffect(() => {
		getFiltersList();
	}, []);

	const deleteuserData = (data) => {
		setShow(data);
	};

	const handleClose = () => setShow('');

	const handleDelete = (id) => {
		api.deleteContact(id).then((res) => {
			if (res.status) {
				setShow('');
				getContactList();
				dispatch(storeInLocalStore({ stateAlertmessage: 'Contact Deleted Successfully.' }));
			}
		});
	};
	const viewUserData = (id) => {
		const currentUser = dataList.filter((item) => item.id === id);
		history(`/credit-note/${id}`);
	};

	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}
		let timeout = setTimeout(() => {
			setIsloading(true);
			if (text.length > 2) {
				api.filterCreditNote(activeFilters, text).then((res) => {
					setIsloading(false);
					setDataList(res.data.data);
					setpagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsloading(false);
			} else {
				api.filterCreditNote(activeFilters, null).then((res) => {
					setIsloading(false);
					setDataList(res.data.data);
					setpagination(res.data);
				});
			}
		}, 1000);
		setSearchTimeout(timeout);
	};

	const handleData = (data) => {
		setDataList(data.data);
		setpagination(data);
	};
	useEffect(() => {
		getContactList();
	}, []);

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		backgroundColor: '#f2f2f2',
		zIndex: 1
	};
	const handleFilter = (activeF = null, searchF = null) => {
		setIsloading(true);
		api.filterCreditNote(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsloading(false);
			setDataList(res.data.data);
			setpagination(res.data);
		});
	};
	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const addSalesManagerToggle = (index, e) => {
		setDataList((oldData) =>
			oldData?.map((item, i) => {
				if (index == i) {
					return {
						...item,
						addSalesManagerValue: e?.value
					};
				} else {
					return item;
				}
			})
		);
	};

	const clearSalesManagerSelect = (index) => {
		setDataList((oldData) =>
			oldData?.map((item, i) => {
				if (index == i) {
					return {
						...item,
						addSalesManagerValue: null
					};
				} else {
					return item;
				}
			})
		);
	};

	const assignSalesManager = (index) => {
		const cus_id = dataList?.[index]?.id;
		const sm_id = dataList?.[index]?.addSalesManagerValue;

		toast.promise(
			api
				.assignContactSalesPerson(cus_id, {
					sales_manager_id: sm_id
				})
				.then((res) => {
					if (res.status) {
						setDataList((oldData) =>
							oldData?.map((item, i) => {
								if (index == i) {
									return {
										...item,
										sales_manager: salesPerson?.find((s) => s.id == sm_id),
										addSalesManagerValue: null
									};
								} else {
									return item;
								}
							})
						);
					}

					return res?.status ? res : abc;
				}),
			{
				loading: 'Updating Sales Manger...',
				success: (data) => `${data.message}`,
				error: <b>Failed to update sales manager</b>
			}
		);
	};

	return (
		<>
			<div className="row justify-content-center m-0 w-100">
				{/* Area Chart */}
				<div className="w-100">
					<div className="col-xl-12 col-lg-12 p-0">
						<div className="section-head">
							<h2></h2>
							<div className="actions">
								<PermissionAllowed permissions={['Create Credit Note (Button)']}>
									<Link to={'/credit-note/create'} className="btn btn-primary btn-outlined-main white-var">
										<FaPlus /> New Credit Note
									</Link>
								</PermissionAllowed>
							</div>
						</div>
						<div className="card shadow-sm bg-white mb-4">
							<div className="d-flex align-items-center justify-content-between TableSerachBar">
								<div>
									<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
								</div>
								<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
									<div className="input-group">
										<input
											type="text"
											className="form-control bg-white border"
											placeholder="Search"
											aria-label="Search"
											aria-describedby="basic-addon2"
											onChange={handleSearch}
										/>
										<span className="searchIcon">
											<FaSearch />
										</span>
									</div>
								</form>
							</div>
							<div className="table-responsive-xl tableData customersList p-2">
								<Scroller>
									<table className="table pm-table">
										<thead>
											<tr>
												<th scope="col" width="20%">
													Credit Note
												</th>
												<th scope="col">Customer</th>
												<th scope="col">Date</th>
												<th scope="col">Total</th>
												<th scope="col" className="text-center" width={'180'}>
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{isLoading ? (
												<>
													{[...Array(5)].map((e, i) => (
														<tr>
															{[...Array(6)].map((e, i) => (
																<td>
																	<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																</td>
															))}
														</tr>
													))}
												</>
											) : (
												<>
													{dataList?.map((row, index) => {
														return (
															<>
																<tr key={index}>
																	<td className="align-middle task-date-col">
																		{/* <div
																			className="priority-line"
																			style={{
																				backgroundColor: 'red'
																			}}
																		>
																			&nbsp;
																		</div>  */}
																		{row?.credit_note_number}
																	</td>
																	<td className="align-middle">{row?.customer?.name}</td>
																	<td className="align-middle">
																		{row?.date ? moment(row?.date).format(CommonDateOnlyFormat) : '-'}
																	</td>
																	<td className="align-middle">
																		<CurrencyValue currencyObject={row?.converted_currency_total} />
																	</td>
																	<td className="align-middle action-col">
																		<PermissionAllowed permissions={['View Credit Note (Button)']}>
																			<Tooltip title="View" arrow placement="top">
																				<span
																					className="btnEdit plain-iconbtn"
																					onClick={() => {
																						history(`/credit-note/${row?.id}`)
																					}}
																				>
																					<FaEye />
																				</span>
																			</Tooltip>
																		</PermissionAllowed>
																	</td>
																</tr>
															</>
														);
													})}
													{dataList?.length < 1 && (
														<tr className="no-hover">
															<td colSpan={7}>
																<div className="not-found-container">
																	<div className="not-found-icon">
																		<TbDatabaseExclamation />
																	</div>
																	<h6 className="not-found-text">No Data Available</h6>
																</div>
															</td>
														</tr>
													)}
												</>
											)}
										</tbody>
									</table>
								</Scroller>
							</div>
							<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsloading(e)} />
						</div>
					</div>
				</div>
			</div>
			<DeleteConfirmation
				show={show}
				handleClose={handleClose}
				handleDelete={handleDelete}
				title="Confirm"
				message={`Are you sure you want to delete ${show.name}`}
			/>
		</>
	);
};

export default CreditNotes;
