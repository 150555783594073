import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import CreateSupplier from './CreateSupplier';
import { useDispatch, useSelector } from 'react-redux';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { FaMinus, FaPlus, FaTimes } from 'react-icons/fa';
import { currencyObject, getCurrencySymbol } from 'utils/currency';
import toast from 'react-hot-toast';
import { ImSpinner2 } from 'react-icons/im';

const ColapsibleTable = ({ currentRowData, close, selectedListids, allModelList }) => {
	console.log(currentRowData);
	const dispatch = useDispatch();
	const [alertMessage, setAlertMessage] = useState();
	const [rows, setRows] = useState([
		{
			id: 1,
			product: null,
			purchase_listid: '',
			editmodal: false,
			estimateId: '',
			estimatenumber: '',
			quantity: '',
			unitPrice: '',
			supplierId: '',
			sellingprice: '',
			profit: '',
			profitPercent: '',
			totalprofit: '',
			purchaseNum: ''
		}
	]);
	const [filtermodelList, setFiltermodellist] = useState(allModelList?.map((item) => ({ id: item.id, label: item?.model?.name })));
	const [modelList, setModellist] = useState(allModelList?.map((item) => ({ id: item.id, label: item?.model?.name })));
	const [supplierList, setSupplierList] = useState([]);
	const [nextId, setNextId] = useState(2);
	const [createSupplier, setCreateSupplier] = useState();
	const [submitLoading, setSubmitLoading] = useState(false);
	const addRow = () => {
		const newRow = {
			id: nextId,
			product: null,
			purchase_listid: '',
			editmodal: false,
			estimateId: '',
			estimatenumber: '',
			quantity: '',
			unitPrice: '',
			supplierId: '',
			sellingprice: null,
			profit: '',
			profitPercent: '',
			totalprofit: '',
			purchaseNum: ''
		};
		setRows([...rows, newRow]);
		setNextId(nextId + 1);
	};
	const [currentCurrency, setCurrentCurrency] = useState('usd');

	const updateCallSup = () => {
		api.getSelectOptionsList('supplier').then((res) => {
			const tempList = res.data.map((item) => ({
				id: item.id,
				label: `${item.name}`,
				value: item.id
			}));
			setSupplierList(tempList);
		});
	};
	const removeRow = (id) => {
		if (rows.length === 1) {
			return;
		}
		const updatedRows = rows.filter((row) => row.id !== id);
		setRows(updatedRows);
	};
	const handleModelselect = (id, value) => {

		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				const listData = currentRowData.filter((item) => item.id == value.id)[0];
				const modelId = listData.model_id;
				const purchase_listid = listData.id;
				const estimatenumber = listData.estimation_number;
				const estimateId = listData.estimation_id;
				const quantity = listData.model_sold_qty;
				const sellingprice_eur = listData.currency_unit_price.unit_price_eur;
				const sellingprice_usd = listData.currency_unit_price.unit_price_usd;
				const sellingprice_aed = listData.currency_unit_price.unit_price_aed;
				const type = value.label;
				const purchaseNum = listData.id;
				return {
					...row,
					modelId,
					purchase_listid,
					estimatenumber,
					estimateId,
					quantity,
					sellingprice_eur,
					sellingprice_usd,
					sellingprice_aed,
					purchaseNum
				};
			}
			return row;
		});
		setRows(updatedRows);
	};
	const handleQuantityChange = (id, value, pl_id) => {
		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				const maxQuantity = currentRowData?.find((c) => c?.id == pl_id)?.sold_qty;
				const inputVal = parseInt(value) || 0;
				const quantity = inputVal > maxQuantity ? maxQuantity : inputVal < 0 ? 0 : inputVal;
				const unitPrice = row.unitPrice || 0;
				let sellingPrice = row.sellingprice?.[currentCurrency] || 0;
				const profit = unitPrice > 0 ? (sellingPrice - unitPrice).toFixed(2) : 0;
				const profitPercent = unitPrice > 0 ? ((profit * 100) / unitPrice).toFixed(2) : 0;
				const totalprofit = unitPrice > 0 ? (quantity * profit).toFixed(2) : 0;

				return { ...row, quantity, unitPrice, profit, totalprofit, profitPercent };
			}
			return row;
		});
		setRows(updatedRows);
	};

	const handleUnitPriceChange = (id, value, currency) => {
		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				const unitPrice = parseFloat(value) || 0;
				let sellingPrice = row.sellingprice?.[currentCurrency] || 0;
				const profit = unitPrice > 0 ? (sellingPrice - unitPrice).toFixed(2) : 0;
				const profitPercent = unitPrice > 0 ? ((profit * 100) / unitPrice).toFixed(2) : 0;
				const totalprofit = unitPrice > 0 ? (row.quantity * profit).toFixed(2) : 0;

				return { ...row, unitPrice, profit, totalprofit, profitPercent };
			}
			return row;
		});
		setRows(updatedRows);
	};

	const [firstSupplierSelect, setFirstSupplierSelect] = useState(true);

	const handleSupplierList = (id, value) => {
		const updatedRows = rows.map((row) => {
			if (firstSupplierSelect) {
				setFirstSupplierSelect(false);
				const supplierId = value.id;
				return { ...row, supplierId };
			} else {
				if (row.id === id) {
					const supplierId = value.id;
					return { ...row, supplierId };
				}
				return row;
			}
		});
		setRows(updatedRows);
	};
	const valueValidation = (e, purchaseId) => {
		let inputValue = e.target.value;

		if (inputValue > maxQuantity) {
			inputValue = maxQuantity;
		}
	};
	const handleAmount = (e, purchaseId) => {
		let inputValue = e.target.value;
		const currentdata = currentRowData.filter((row) => row.id == purchaseId)[0]?.currency_unit_price;
		let maxQuantity =
			currentCurrency == 'eur'
				? currentdata?.unit_price_eur
				: currentCurrency == 'usd'
				? currentdata?.unit_price_usd
				: currentdata?.unit_price_aed;
		if (inputValue > maxQuantity) {
			inputValue = maxQuantity;
		}
		e.target.value = inputValue;
	};

	useEffect(() => {
		updateCallSup();
		const selectedRow = currentRowData.filter((row) => selectedListids.includes(row.id));
		if (selectedRow.length > 0) {
			const tempData = selectedRow.map((item, index) => {
				return {
					id: index + 1,
					product: item.product,
					purchase_listid: item.id,
					editmodal: true,
					estimateId: item?.estimation_id,
					estimatenumber: item?.invoice_number || item?.estimation_number,
					quantity: item.sold_qty,
					unitPrice: '',
					supplierId: '',
					sellingprice: item?.converted_currency_selling_price_unit,
					profit: '',
					profitPercent: '',
					totalprofit: '',
					purchaseNum: item.id
				};
			});
			setRows(tempData);
			setNextId(tempData.length + 1);
		}

		console.log('rows', rows);
	}, []);
	// Customer Create
	const NoOptionsMessage = (props) => {
		return (
			<div {...props}>
				<button className="btn addNewCus" onClick={() => inputfieldEnable(props.rowId)}>
					<FaPlus /> New Supplier
				</button>
			</div>
		);
	};
	const inputfieldEnable = (rowId) => {
		setCreateSupplier(rowId);
	};
	const closeCreateSupplier = () => {
		setCreateSupplier('');
	};
	const validateRows = () => {
		// for (let i = 0; i < rows.length; i++) {
		// 	const { modelId, supplierId, quantity, unitPrice } = rows[i];
		// 	if (!modelId || !quantity || !unitPrice || !supplierId) {
		// 		return false;
		// 	}
		// }

		if (rows.filter((r) => !r?.quantity)?.length > 0) {
			toast.error('Quantity is required for all items');
			return false;
		}

		if (rows.filter((r) => !r?.unitPrice)?.length > 0) {
			toast.error('Unit Price is required for all items');
			return false;
		}

		return true;
	};

	const saveData = () => {
		if (!validateRows()) {
			// setAlertMessage({ modelrow: 'All fields are mandatory for each product' });
		} else {
			setSubmitLoading(true);
			const data = {
				currency: currentCurrency,
				details: rows.map((obj) => ({
					purchase_unit_price: obj.unitPrice,
					purchase_qty: obj.quantity,
					supplier_id: obj.supplierId,
					purchase_list_id: obj.purchase_listid
				}))
			};
			toast.promise(
				api.movetoFound(data).then(
					(res) => {
						if (res.status) {
							close('reload');
						}

						setSubmitLoading(false);

						return res?.status ? res : null;
					},
					() => {
						setSubmitLoading(false);
					}
				),
				{
					loading: 'Moving to found...',
					success: (data) => `${data.message}`,
					error: <b>Failed to move to found</b>
				}
			);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const handlecurrency = (e) => {
		setCurrentCurrency(e);
		const updatedRows = rows.map((row) => {
			const unitPrice = row.unitPrice || 0;
			let sellingPrice = row.sellingprice?.[e] || 0;
			const profit = unitPrice > 0 ? (sellingPrice - unitPrice).toFixed(2) : 0;
			const profitPercent = unitPrice > 0 ? ((profit * 100) / unitPrice).toFixed(2) : 0;
			const totalprofit = unitPrice > 0 ? (row.quantity * profit).toFixed(2) : 0;

			return { ...row, unitPrice, profit, totalprofit, profitPercent };
		});
		setRows(updatedRows);
	};

	useEffect(() => {
		console.log(rows);
		const modelId = rows?.map((item) => item.purchase_listid);
		const updatedModal = filtermodelList.filter((row) => !modelId.includes(row.id));
		setModellist(updatedModal);
	}, [rows]);
	return (
		<>
			<div className="foundListPopup">
				<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 pl-0">
					<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">Sourced Products</h5>

					<div className="CurrencySel d-flex align-items-center">
						<Select
							className="rs-container"
							classNamePrefix="rs"
							value={currencyObject.find((item) => item.value == currentCurrency)}
							options={currencyObject}
							onChange={(e) => {
								handlecurrency(e.value);
							}}
						/>
					</div>
					<button className="btn modal-close-icon" onClick={close}>
						<FaTimes />
					</button>
				</div>
				<div className="table-responsive-xl productsTable">
					<table className="table pm-table collaps sourced-table table-md formActBtns border-0 hide-on-mobile">
						<thead>
							<tr>
								<th className="align-middle" width={'250'}>
									Model
								</th>
								<th className="align-middle">Estimate</th>
								<th className="align-middle">Quantity</th>
								<th className="align-middle">Unit Price</th>
								<th className="align-middle" width={'240'}>
									Supplier
								</th>
								<th className="align-middle hide-on-mobile">Selling Price</th>
								<th className="align-middle hide-on-mobile">Gross Profit</th>
								<th className="align-middle hide-on-mobile">Total Profit</th>
								<th className="align-middle text-center" width={'50'}></th>
							</tr>
						</thead>
						<tbody>
							{rows.map((row, index) => (
								<>
									<tr key={row.id}>
										<td className="align-middle form-row estimateCName">{row?.product?.product_name}</td>
										<td className="align-middle">{row.estimatenumber}</td>
										<td className="align-middle">
											<input
												className="numField"
												type="number"
												value={row.quantity}
												placeholder="00"
												onChange={(e) => {
													// valueValidation(e, row.purchase_listid);
													handleQuantityChange(row.id, e.target.value, row.purchase_listid);
												}}
												min={0}
												max={currentRowData?.find((c) => c?.id == row?.purchase_listid)?.sold_qty}
											/>
										</td>

										<td className="align-middle">
											<input
												className="numField"
												type="number"
												defaultValue="{'00'}"
												placeholder="00"
												onChange={(e) => {
													handleAmount(e, row.purchaseNum);
													handleUnitPriceChange(row.id, e.target.value);
												}}
											/>
										</td>
										<td className="align-middle form-row estimateCName">
											<div className="form-group col-md-12 mb-0">
												<Select
													className="rs-container"
													classNamePrefix="rs"
													isSearchable={true}
													name="customer"
													components={{
														NoOptionsMessage: () => <NoOptionsMessage rowId={row.id} />
													}}
													options={supplierList}
													value={supplierList.find((sup) => sup.id == row.supplierId)}
													placeholder="Select"
													onChange={(selected) => handleSupplierList(row.id, selected)}
												/>
											</div>
										</td>
										<td className="align-middle hide-on-mobile">
											{getCurrencySymbol(currentCurrency)}
											{row?.sellingprice?.[currentCurrency]}
										</td>
										<td className="align-middle hide-on-mobile">
											{getCurrencySymbol(currentCurrency)}
											{row.profit || '00'} {`(${row.profitPercent || '00'}%)`}
										</td>
										<td className="align-middle hide-on-mobile">
											{getCurrencySymbol(currentCurrency)}
											{row.totalprofit || '00'}
										</td>
										<td className="align-middle text-center">
											<span className="btnEdit plain-iconbtn" onClick={() => removeRow(row.id)}>
												<FaTimes />
											</span>
										</td>
									</tr>
								</>
							))}
							{alertMessage?.modelrow && <span className="px-3 down-error text-danger">{alertMessage?.modelrow}</span>}
						</tbody>
					</table>

					<div className="sourcing-popup-list">
						{rows?.map((row, index) => (
							<div className="sourcing-popup-item">
								<div className="sourcing-popup-item-header">
									<h6 className="sourcing-popup-item-title p-0 m-0">{row?.product?.product_name}</h6>
									<p className="p-0 m-0">{row.estimatenumber}</p>
								</div>
								<div className="sourcing-popup-item-body row m-0 p-0 mt-2">
									<div className="col-6 row m-0 p-0">
										<div className="col-6 m-0 p-0 pr-1">
											<div className="form-group m-0 p-0">
												<label htmlFor="">Qty</label>
												<input
													className="form-control"
													type="number"
													value={row.quantity}
													placeholder="00"
													onChange={(e) => {
														// valueValidation(e, row.purchase_listid);
														handleQuantityChange(row.id, e.target.value, row.purchase_listid);
													}}
													min={0}
													max={currentRowData?.find((c) => c?.id == row?.purchase_listid)?.sold_qty}
												/>
											</div>
										</div>
										<div className="col-6 m-0 p-0 pl-1">
											<div className="form-group m-0 p-0">
												<label htmlFor="">Price</label>
												<input
													className="form-control"
													type="number"
													defaultValue="{'00'}"
													placeholder="00"
													onChange={(e) => {
														handleAmount(e, row.purchaseNum);
														handleUnitPriceChange(row.id, e.target.value);
													}}
												/>
											</div>
										</div>
										<div className="col-12 m-0 p-0 mt-2">
											<div className="form-group m-0 p-0">
												<label htmlFor="">Supplier</label>
												<Select
													className="rs-container"
													classNamePrefix="rs"
													isSearchable={true}
													name="customer"
													components={{
														NoOptionsMessage: () => <NoOptionsMessage rowId={row.id} />
													}}
													options={supplierList}
													value={supplierList.find((sup) => sup.id == row.supplierId)}
													placeholder="Select"
													onChange={(selected) => handleSupplierList(row.id, selected)}
													menuPosition="fixed"
												/>
											</div>
										</div>
									</div>
									<div className="col-6 m-0 p-0 pl-1 d-flex justify-content-center align-items-center">
										<table>
											<tr>
												<th className="p-1">Selling Price:</th>
												<td className="p-1">
													{getCurrencySymbol(currentCurrency)}
													{row?.sellingprice?.[currentCurrency]}
												</td>
											</tr>
											<tr>
												<th className="p-1">Gross Profit:</th>
												<td className="p-1">
													{getCurrencySymbol(currentCurrency)}
													{row.profit || '00.00'} {`(${row.profitPercent || '00'}%)`}
												</td>
											</tr>
											<tr>
												<th className="p-1">Total Profit:</th>
												<td className="p-1">
													{getCurrencySymbol(currentCurrency)}
													{row.totalprofit || '00.00'}
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="btnarea mt-3 mt-md-5">
				<button onClick={() => saveData()} disabled={submitLoading} className="btn btn-main">
					<FaPlus /> Move to sourced
				</button>
			</div>
			<CreateSupplier open={createSupplier} close={closeCreateSupplier} selectedSupplier={handleSupplierList} reloadsup={updateCallSup} />
		</>
	);
};

export default ColapsibleTable;
