import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { setLoading, storePermissions, storeUserDetails } from 'store/reducers/mainSlice';
import Logo from 'assets/images/logo-light.svg';
import CarouselUi from 'ui-component/CarouselUi';
import { Alert } from '@mui/material';
import './NewLogin.scss'; // Import your CSS file

function Copyright(props) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			<Link color="inherit" href="https://projectmix.ai/">
				Projectmix
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
	palette: {
		main: {
			primary: '#192138'
		}
	}
});

export default function ForgotPasswordOTP() {
	const history = useNavigate();
	const navigate = useNavigate();
	const location = useLocation();
	const matches = useMediaQuery('(max-width:900px)');
	const { state } = useLocation();
	const { email, token } = state || {};
	const isMobile = useMediaQuery('(max-width:900px)');
	useEffect(() => {
		document.title = 'Projectmix - Login';
		if (!state || !email || !token) {
			navigate('/login');
		}
	}, []);

	let validToken = useSelector((state) => state.main?.user?.token);
	const [showPassword, setShowPassword] = useState('fa-eye');
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const [emsg, setEmsg] = useState('');
	const [rememberPwd, setRememberPwd] = useState(true);
	const isLoading = useSelector((state) => state.main.loading);
	const dispatch = useDispatch();
	const [timeLeft, setTimeLeft] = useState(180);

	const [showOtpInput, setShowOtpInput] = useState(false);

	const handleResetPasswordClick = () => {
		setShowOtpInput(true);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);

		const data = {
			token: token,
			otp: `${formData?.get('otp-0')}${formData?.get('otp-1')}${formData?.get('otp-2')}${formData?.get('otp-3')}`
		};

		dispatch(setLoading(true));

		api.forgotPasswordOtp(data).then((res) => {
			const token = res.data[0];
			if (res.status) {
				navigate('/new-password', { state: { token } });
			} else {
				setEmsg(res.message);
				setTimeout(() => setEmsg(''), 3000);
			}
			dispatch(setLoading(false));
		});
	};

	const resubmitOtp = (event) => {
		event.preventDefault();
		setTimeLeft(180);
		const data = {
			email: email
		};

		dispatch(setLoading(true));

		api.forgotPassword(data).then((res) => {
			const {
				data: { email, token }
			} = res;
			if (res.status) {
				navigate('/forgot-password-otp', { state: { email, token } });
			} else {
				setEmsg(res.message);
				setTimeout(() => setEmsg(''), 3000);
			}
			dispatch(setLoading(false));
		});
	};

	useEffect(() => {
		document.title = 'Projectmix - Login';

		if (!timeLeft) return;

		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [timeLeft]);

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid container component="main" sx={{ height: '100dvh' }}>
				<Grid
					item
					xs={false}
					sm={false}
					md={6}
					// hidden={{ xs: true, sm: false, md: false }}
					sx={{
						// backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
						backgroundRepeat: 'no-repeat',
						// backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[900] : t.palette.grey[900]),
						background: 'radial-gradient(circle at left,#36214A,#161618 40%)',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						borderRight: '2px solid #373843'
					}}
					className="hidden-sm-grid-item"
				>
					<img src={Logo} style={{ width: '40%' }}></img>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					component={Paper}
					square
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
						backgroundColor: '#181823',
						flex: '1' // make the Grid fill the remaining space
					}}
					container
					spacing={isMobile ? 0 : 2}
				>
					{matches && <img src={Logo} style={{ width: '290px', marginTop: '20%' }} alt="Projectmix.ai" />}
					{/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} className='hidden-sm-grid-item'>
							<LockOutlinedIcon />
						</Avatar> */}
					{/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} > */}

					{/* </Avatar> */}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							flex: '1', // make the Box take up all available space
							marginTop: isMobile ? '0' : '20%'
						}}
					>
						<Typography component="h1" variant="h5" className="login-text-gray ">
							{timeLeft > 0 ? (
								<h5 style={{ paddingTop: '10px' }}>
									Time left: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
								</h5>
							) : (
								<h5 style={{ paddingTop: '10px' }}>OTP expired!</h5>
							)}
						</Typography>
						<Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1 }}>
							{emsg && (
								<Alert
									severity="error"
									sx={{
										marginBottom: '5px'
									}}
								>
									{emsg}
								</Alert>
							)}
							<div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: 300 }}>
								{[0, 1, 2, 3].map((_, index) => (
									<TextField
										key={index}
										margin="normal"
										required
										fullWidth
										id={`otp-${index}`}
										name={`otp-${index}`}
										autoComplete="off"
										autoFocus={index === 0} // autofocus only on the first input
										type="text"
										className="login-form-field form-control-md font12 otp-input"
										style={{
											WebkitTextFillColor: '#fff !important',
											marginRight: index < 3 ? '10px' : '0'
											// fontSize: '24px !important',
										}}
										inputProps={{
											maxLength: 1,
											style: {
												// apply styles directly to the input field
												// fontSize: '24px !important', // set font size to 16px
												textAlign: 'center', // center the text
												fontWeight: 'bold'
											}
										}}
										onChange={(e) => {
											if (e.target.value.length === 1) {
												document.getElementById(`otp-${index + 1}`)?.focus();
											}
										}}
										onPaste={(e) => {
											e.preventDefault();
											const pastedData = e.clipboardData.getData('text');
											pastedData.split('').forEach((char, i) => {
												const input = document.getElementById(`otp-${i}`);
												if (input) input.value = char;
											});
										}}
									/>
								))}
							</div>
							<Button type="submit" className="signin-button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
								Submit OTP
							</Button>
							{timeLeft === 0 && (
								<Typography className="login-text-gray" style={{ textAlign: 'center', fontSize: '12px' }}>
									<Link
										to="#"
										variant="body2"
										onClick={(event) => {
											event.preventDefault();
											resubmitOtp(event);
										}}
									>
										Resend OTP
									</Link>
								</Typography>
							)}

							{/* <Copyright sx={{ mt: 5 }} className='login-text-gray' /> */}
						</Box>
					</Box>
					<Typography className="login-text-gray" style={{ textAlign: 'center', fontSize: '12px', width: '100%', marginBottom: '15%' }}>
						Copyright ©{' '}
						<Link href="#" variant="body2">
							Projectmix
						</Link>{' '}
						2024.
					</Typography>
				</Grid>
			</Grid>
			{/* <Grid container component="main" sx={{ height: '100vh' }}>
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						// backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
						backgroundRepeat: 'no-repeat',
						// backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[900] : t.palette.grey[900]),
						background: 'radial-gradient(circle at left,#36214A,#161618 40%)',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column'
					}}
				>
					<img src={Logo} style={{ width: '40%' }}></img>
				</Grid>
				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					square
					sx={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#181823',
					}}
				>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: '100%'
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1 }}>
							{emsg && (
								<Alert
									severity="error"
									sx={{
										marginBottom: '5px'
									}}
								>
									{emsg}
								</Alert>
							)}
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								type="email"
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
							/>
							
							<FormControlLabel
								control={
									<Checkbox
										value="remember"
										color="primary"
										checked={rememberPwd}
										onChange={() => {
											setRememberPwd(!rememberPwd);
										}}
									/>
								}
								label="Remember me"
							/>
							<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
								Sign In
							</Button>
							<Grid container>
								<Grid item>
								</Grid>
								<Grid item xs>
									<Link href="#" variant="body2">
										Forgot password?
									</Link>
								</Grid>
							</Grid>
							<Copyright sx={{ mt: 5 }} />
						</Box>
					</Box>
				</Grid>
			</Grid> */}
		</ThemeProvider>
	);
}
