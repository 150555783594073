import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import AutosuggestInput from 'ui-component/AutoSuggestInput';
import Scroller from 'ui-component/Scroller';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import refreshIcon from 'assets/images/icons/refresh.svg';
import { FaMinus, FaPlus } from 'react-icons/fa';

const currencyData = ['aed', 'eur', 'usd'];

const Productadd = () => {
	const history = useNavigate();
	const productNameRef = useRef();
	const selectModelRef = useRef();
	const imeiRef = useRef();
	const colorRef = useRef();
	const purchasePriceRef = useRef();
	const sellingPriceRef = useRef();
	const supplierRef = useRef();
	const customerRef = useRef();
	const [allstatus, setStatus] = useState([]);
	const [modelvalue, setModelvalue] = useState('');
	const [modelsuggestions, setModelsuggestions] = useState([]);
	const [storage, setStorage] = useState([]);
	const [grade, setGrade] = useState([]);
	const [color, setColor] = useState([]);
	const [imeiNumber, setImeiNumber] = useState();
	const [currentstorage, setCurrentstorage] = useState('');
	const [sellingInvoice, setSellingInvoice] = useState([]);
	const [currentGrade, setCurrentgrade] = useState('');
	const [alertMessage, setAlertMessage] = useState();
	const [productValue, setproductValue] = useState({
		modelName: '',
		color: '',
		imei: '',
		storage: '',
		grade: '',
		purchase_price: '',
		selling_price: '',
		currency: '',
		supplier: '',
		purchase_invoice: '',
		customer: '',
		selling_invoice: null
	});

	const [currencyValue, setCurrencyValue] = useState('');
	const [currencyRates, setCurrencyRates] = useState([]);
	const [supplierData, setSupplierData] = useState([]);
	const [customerData, setCustomerData] = useState([]);

	const getSupplierAPI = () => {
		api.selectAllSuppller()
			.then((res) => {
				if (res.status) {
					setSupplierData(res?.data);
				}
			})
			.catch((err) => {
				console.log('Err ', err);
			});
	};

	const getCustomerAPI = () => {
		api.selectAllCustomer(1)
			.then((res) => {
				if (res.status) {
					setCustomerData(res?.data);
				}
			})
			.catch((err) => {
				console.log('Err ', err);
			});
	};

	const getCurrencyRates = (data) => {
		api.getCurrencyRate(data)
			.then((res) => {
				if (res?.status) {
					const _data = res?.data;
					if (_data.price_eur === 1) {
						setCurrencyRates([`Dirham ${_data.price_aed}`, `$ ${_data.price_usd}`]);
					} else if (_data.price_aed === 1) {
						setCurrencyRates([`€ ${_data.price_eur}`, `$ ${_data.price_usd}`]);
					} else if (_data.price_usd === 1) {
						setCurrencyRates([`Dirham ${_data.price_aed}`, `€ ${_data.price_eur}`]);
					}
				}
			})
			.catch((err) => {
				console.log('Err ', err);
			});
	};

	const validateLength = (e, maxLength) => {
		let inputValue = e.target.value;
		if (inputValue.length > maxLength) {
			inputValue = inputValue.slice(0, maxLength);
		}
		e.target.value = inputValue;
	};

	const handleCheckIMEI = () => {
		if (imeiNumber !== '') {
			setAlertMessage({
				verifyingImei: 'Please wait...'
			});
			api.imeiuniqvarification({
				models: [{ imei_numbers: [imeiNumber] }]
			})
				.then((res) => {
					if (res.status) {
						const data = res.data[0];
						if (data.is_unique === 'yes') {
							handleChange({ ...productValue, imei: data.imei });
						}
						setAlertMessage({
							imeiVerify: 'Verified'
						});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
		setTimeout(() => {
			setAlertMessage({});
		}, 1500);
	};

	const getAllattributes = () => {
		api.getAllstatus().then((res) => {
			let tempstatus = res.data?.filter((item) => item.type == 'Product').map((item) => ({ id: item.id, name: item.name }));
			setStatus(tempstatus);
		});

		api.allAttributes().then((res) => {
			if (res.status) {
				let storageTemp = res?.data?.filter((item) => item.type == 'storage').map((item) => ({ id: item.id, name: item.name }));
				let gradeTemp = res?.data?.filter((item) => item.type == 'grade').map((item) => ({ id: item.id, name: item.name }));
				let colorTemp = res?.data?.filter((item) => item.type == 'color').map((item) => ({ id: item.id, name: item.name }));
				setStorage(storageTemp);
				setGrade(gradeTemp);
				setColor(colorTemp);
			} else {
			}
		});
	};
	
	const searchCustomer = async (inputValue) => {
		const response = await api.searchModel(inputValue, 1);
		if (response.status) {
			setModelsuggestions(
				response.data.data.map((model) => {
					return { name: model.name, value: model.id };
				})
			);
		}
		return [];
	};
	const getModelsuggestion = (value) => {
		const inputValue = value.value?.trim()?.toLowerCase();
		const inputLength = inputValue.length;
		if (inputLength !== 0) {
			searchCustomer(inputValue);
			return ['searching...'];
		}
		return [];
	};
	const getModelsuggestValue = (sugg) => {
		api.getSinglemodel(sugg.value).then((res) => {
			setCurrentstorage(res.data.storage);
			setCurrentgrade(res.data.grade);
			handleChange({
				...productValue,
				modelName: sugg.value,
				storage: res.data.storage_id,
				grade: res.data.grade_id
			});
		});

		return sugg?.name;
	};
	const imeiValidation = (e) => {
		const numericValue = e.target.value.replace(/\D/g, '');
		if (numericValue.length < 15) {
			setAlertMessage({
				imei: 'IMEI should be exactly 15 digits.'
			});
		} else {
			setAlertMessage();
		}
		e.target.value = numericValue;
	};
	const handlePrice = (e) => {
		const input = e.target.value;
		const formattedInput = input.replace(/[^0-9.]/g, '');
		e.target.value = formattedInput;
	};
	const convertUsd = () => {
		const price = productValue.purchase_cost_usd;
		api.currentConverter({ price_usd: price }).then((res) => {
			handleChange({
				...productValue,
				purchase_cost_eur: res.data.price_eur,
				purchase_cost_aed: res.data.price_aed
			});
		});
	};
	const convertUsdsale = () => {
		const price = productValue.sold_price_usd;
		api.currentConverter({ price_usd: price }).then((res) => {
			handleChange({
				...productValue,
				sold_price_eur: res.data.price_eur,
				sold_price_aed: res.data.price_aed
			});
		});
	};
	const handleChange = (val) => {
		setproductValue(val);
	};
	const goback = () => {
		history('/inventory/inbound');
	};

	const getSellingInvoiceAPI = (id) => {
		api.getSellingInvoice(id)
			.then((res) => {
				if (res?.status) {
					setSellingInvoice(res?.data);
				}
			})
			.catch((err) => {
				console.log('Error ', err);
			});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (productValue.modelName === '') {
			setAlertMessage({
				modelName: 'Model is Required.'
			});
			selectModelRef.current.scrollIntoView({ behavior: 'smooth' });
		} else if (productValue.color === '') {
			setAlertMessage({
				color: 'Color is Required.'
			});
			colorRef.current.focus();
		} else if (productValue.imei === '') {
			if (imeiNumber === '') {
				setAlertMessage({
					imei: 'IMEI Number is Required'
				});
			} else {
				setAlertMessage({
					imei: 'Please verify the IMEI Number'
				});
			}

			imeiRef.current.focus();
		} else if (productValue.purchase_price === '') {
			setAlertMessage({
				purchase_price: 'Purchase Price is Required.'
			});
			purchasePriceRef.current.focus();
		} else if (productValue.selling_price === '') {
			setAlertMessage({
				selling_price: 'Selling Price is Required.'
			});
			sellingPriceRef.current.focus();
		} else if (productValue.supplier === '') {
			setAlertMessage({
				supplier: 'Supplier is Required.'
			});
			supplierRef.current.focus();
		} else {
			api.addProduct({
				model_id: productValue.modelName,
				color_id: productValue.color,
				supplier_id: productValue.supplier,
				customer_id: productValue.customer,
				imei: productValue.imei,
				currency: productValue.currency,
				purchase_invoice: productValue.purchase_invoice,
				selling_invoice: productValue.selling_invoice,
				purchase_price: productValue.purchase_price,
				selling_price: productValue.selling_price
			}).then((res) => {
				if (res.status) {
					setAlertMessage({
						success: 'Product Created Successfully.'
					});
					setTimeout(() => {
						setAlertMessage({});
						history('/inventory/inbound');
					}, 1500);
				} else {
					setAlertMessage({
						failed: res.message
					});
					setTimeout(() => {
						setAlertMessage({});
					}, 2000);
				}
			});
		}

		setTimeout(() => {
			setAlertMessage({});
		}, 1500);
	};
	useEffect(() => {
		getAllattributes();
	}, []);
	useEffect(() => {
		if (modelvalue == '') {
			setCurrentstorage('');
			setCurrentgrade('');
		}
	}, [modelvalue]);

	useEffect(() => {
		getSupplierAPI();
		getCustomerAPI();
		if (currencyValue) {
			const data = { currency: currencyValue };
			getCurrencyRates(data);
		}
		if (productValue?.customer) {
			getSellingInvoiceAPI(productValue?.customer);
		}
	}, [currencyValue, productValue?.customer]);

	console.log('productValue ', productValue);

	return (
		<>
			<div className="row">
				<div className="col-xl-12 col-lg-12">
					<div className="card shadow-sm bg-white mb-4 p-2 p-xl-3">
						<div className="col-md-12 my-4">
							<h1 className="h4 mb-0  font-weight-bold">Create Product</h1>
						</div>
						<form className="userForm" onSubmit={handleSubmit}>
							<Scroller>
								<div className="row">
									<div className="col-xl-12 col-lg-12">
										<div className="card shadow-sm rounded bg-white mb-4 p-3 p-xl-4">
											<div className="m-sm-2">
												<form className="userForm" onSubmit={handleSubmit}>
													<div className="form-row">
														<div className="col-md-12 mt-0 p-3">
															<h6 className="h5 ">Product Information</h6>
														</div>
														<div className="form-group col-md-4 px-3 py-1">
															<label ref={selectModelRef} htmlFor="inputName" className="">
																Model*
															</label>
															<AutosuggestInput
																placeholder={'Select'}
																className={'form-control'}
																value={modelvalue}
																setValue={setModelvalue}
																suggestions={modelsuggestions}
																setSuggestions={setModelsuggestions}
																getSuggestionValue={getModelsuggestValue}
																getSuggestions={getModelsuggestion}
															/>
															{alertMessage?.modelName && (
																<>
																	<span className="px-3 error-message text-danger">
																		{alertMessage.modelName}
																	</span>
																</>
															)}
														</div>
														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Product Color
															</label>
															<select
																ref={colorRef}
																className="form-control select-option"
																onChange={(e) => {
																	handleChange({ ...productValue, color: e.target.value });
																}}
															>
																<option>Select</option>
																{color &&
																	color.map((item, index) => (
																		<option key={index} value={item.id}>
																			{item.name}
																		</option>
																	))}
															</select>
															{alertMessage?.color && (
																<>
																	<span className="px-3 error-message text-danger">
																		{alertMessage.color}
																	</span>
																</>
															)}
														</div>
														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																IMEI *
															</label>
															<div style={{ position: 'relative' }}>
																<input
																	ref={imeiRef}
																	type="text"
																	className="form-control"
																	id="inputName"
																	placeholder="IMEI *"
																	maxLength={15}
																	pattern="[0-9]*"
																	onChange={(e) => {
																		imeiValidation(e);
																		setImeiNumber(e.target.value);
																	}}
																/>
																<img
																	style={{
																		position: 'absolute',
																		top: '20px',
																		right: '20px',
																		cursor: 'pointer'
																	}}
																	onClick={handleCheckIMEI}
																	width="20px"
																	weight="20px"
																	src={refreshIcon}
																	alt="error"
																/>
															</div>
															{alertMessage?.imei && (
																<>
																	<span className="px-3 error-message text-danger">
																		{alertMessage.imei}
																	</span>
																</>
															)}
															{alertMessage?.verifyingImei && (
																<>
																	<span className="px-3 error-message text-info">
																		{alertMessage.verifyingImei}
																	</span>
																</>
															)}
															{alertMessage?.imeiVerify && (
																<>
																	<span className="px-3 error-message text-success">
																		{alertMessage.imeiVerify}
																	</span>
																</>
															)}
														</div>

														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Storage
															</label>
															<select
																className="form-control select-option"
																onChange={(e) => {
																	handleChange({ ...productValue, storage: e.target.value });
																}}
																disabled={currentstorage ? 'disabled' : ''}
															>
																{currentstorage != '' ? (
																	<>
																		<option value={currentstorage.id}> {currentstorage.name} </option>
																	</>
																) : (
																	<>
																		<option> Select </option>
																	</>
																)}

																{storage &&
																	storage.map((item, index) => (
																		<option key={index} value={item.id}>
																			{item.name}
																		</option>
																	))}
															</select>
														</div>
														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Grade
															</label>
															<select
																className="form-control select-option"
																onChange={(e) => {
																	handleChange({ ...productValue, grade: e.target.value });
																}}
																disabled={currentGrade ? 'disabled' : ''}
															>
																{currentGrade != '' ? (
																	<>
																		<option value={currentGrade.id}> {currentGrade.name} </option>
																	</>
																) : (
																	<>
																		<option> Select </option>
																	</>
																)}

																{grade &&
																	grade.map((item, index) => (
																		<option key={index} value={item.id}>
																			{item.name}
																		</option>
																	))}
															</select>
														</div>
														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Purchase Price
															</label>
															<input
																ref={purchasePriceRef}
																type="text"
																className="form-control"
																id="inputName"
																placeholder="Purchase Price"
																onChange={(e) => {
																	validateLength(e, 6);
																	handleChange({ ...productValue, purchase_price: e.target.value });
																}}
															/>
															{alertMessage?.purchase_price && (
																<>
																	<span className="px-3 error-message text-danger">
																		{alertMessage.purchase_price}
																	</span>
																</>
															)}
														</div>
														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Selling Price
															</label>
															<input
																ref={sellingPriceRef}
																type="text"
																className="form-control"
																id="inputName"
																placeholder="Selling Price"
																onChange={(e) => {
																	validateLength(e, 6);
																	handleChange({ ...productValue, selling_price: e.target.value });
																}}
															/>
															{alertMessage?.selling_price && (
																<>
																	<span className="px-3 error-message text-danger">
																		{alertMessage.selling_price}
																	</span>
																</>
															)}
														</div>
														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Currency
															</label>
															<select
																className="form-control select-option"
																onChange={(e) => {
																	setCurrencyValue(e.target.value);
																	handleChange({ ...productValue, currency: e.target.value });
																}}
															>
																<option> Select </option>
																{currencyData?.map((item, index) => (
																	<option key={index} value={item}>
																		{item}
																	</option>
																))}
															</select>
														</div>
														<div className="form-group col-md-2 px-3 py-1">
															<label htmlFor="inputName" className="">
																Currency Rate
															</label>
															<input
																type="text"
																className="form-control"
																id="inputName"
																// placeholder="Euro"
																value={currencyRates[0]}
																disabled
															/>
														</div>
														<div className="form-group col-md-2 px-3 mt-5">
															<input
																type="text"
																className="form-control"
																id="inputName"
																// placeholder="Dirham"
																value={currencyRates[1]}
																// onChange={(e) => {
																// 	handleChange({ ...productValue, selling_price: e.target.value });
																// }}
																disabled
															/>
														</div>
														<div className="col-md-12 mt-0 p-3">
															<h6 className="h5 ">Company Address</h6>
														</div>
														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Supplier
															</label>
															<select
																className="form-control select-option"
																onChange={(e) => {
																	handleChange({ ...productValue, supplier: e.target.value });
																}}
															>
																<option> Select </option>
																{supplierData?.map((item, index) => (
																	<option key={index} value={item.id}>
																		{item.name}
																	</option>
																))}
															</select>
															{alertMessage?.supplier && (
																<>
																	<span className="px-3 error-message text-danger">
																		{alertMessage.supplier}
																	</span>
																</>
															)}
														</div>

														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Purchase INV
															</label>
															<select
																className="form-control select-option"
																// onChange={(e) => {
																// 	handleChange({ ...productValue, purchase_invoice: e.target.value });
																// }}
															>
																<option> Select </option>
																{[].map((item, index) => (
																	<option key={index} value={item}>
																		{item}
																	</option>
																))}
															</select>
														</div>
														<div className="form-group col-md-4 px-3 py-1"></div>
														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Customer
															</label>
															<select
																className="form-control select-option"
																onChange={(e) => {
																	handleChange({ ...productValue, customer: e.target.value });
																}}
															>
																<option> Select </option>
																{customerData?.map((item, index) => (
																	<option key={index} value={item.id}>
																		{item.name}
																	</option>
																))}
															</select>
														</div>
														<div className="form-group col-md-4 px-3 py-1">
															<label htmlFor="inputName" className="">
																Selling INV
															</label>
															<select
																className="form-control select-option"
																onChange={(e) => {
																	handleChange({ ...productValue, selling_invoice: e.target.value });
																}}
															>
																<option> Select </option>
																{sellingInvoice?.map((item, index) => (
																	<option key={index} value={item?.id}>
																		{item?.invoice_number}
																	</option>
																))}
															</select>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</Scroller>
							<div className="">
								<div className="btnarea">
									<button onClick={goback} className="btn btn-primary canBtn">
										<FaMinus /> Cancel
									</button>
									<button type="submit" className="btn btn-primary greenBtn">
										<FaPlus /> Create
									</button>
								</div>
								{alertMessage?.success && <span className="px-3 down-error text-success">{alertMessage?.success}</span>}
								{alertMessage?.failed && <span className="px-3 down-error text-danger">{alertMessage?.failed}</span>}
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Productadd;
