import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { GiCancel } from "react-icons/gi";
import { FaDownload } from "react-icons/fa6";



const ImageModel = (
    { 
        open, close , type
    }
    ) => {
    

    const handleClose = () => {
      console.log('clicked close')
        close()
    };
   

    const onDownload = (item) => {

        console.log('rendering',item)
        // let fullpath = `${base}/${item?.directory_path}/${item?.name}`;
        // let modifiedUrl = fullpath.replace('/public/', '/storage/');
        
        const link = document.createElement('a');
        link.href = item?.url;
        link.download = item?.name || 'downloaded_file';
        link.target ='_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    }
      
   
    

    return (
        <Modal show={open} onHide={handleClose} centered>
          
                <div className="top-image">
                 
                    
                    <p className='imageName'>{open?.name}</p>
                    
                </div>
                <div className="col-md-12 imagewidth">
                    
                        {type != 'img' ? (
                              <iframe
                              src={open?.url}
                              className='modalImage'
                                />
                        ) :(
                            
                            <img src={open?.url} className='image-model'/>
                            
                        )
                        }
                        <div className="btnDownload">
                        <button
                            onClick={() => onDownload(open)}
                            className="btn btn-primary btn-outlined-main"
                        >   
                            <FaDownload />

                            Download
                            
                        </button>
                        </div>
                        
                </div>        
                    
                    

                
          
        </Modal>

    )
}

export default ImageModel
