import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProfileForm from './ProfileForm';
import PasswordForm from './PasswordForm';
import { useNavigate } from 'react-router';
import { EstimateIcon, ProfilePasswordIcon, ProfileUserIcon } from 'ui-component/custom-icons/icons';
import usePageTitle from 'hooks/usePageTitle';

const Settings = () => {

	const [setPageTitle] = usePageTitle('User Settings');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const user = useSelector((state) => state.main.user);
	const [active, setActive] = React.useState(1);
	const history = useNavigate();

	useEffect(() => {
		if (user === null) {
			history('/');
		}
	}, []);

	return (
		<>
			<div className="row">
				<div className="section-head">
					<ul className="topListCom">
						<li
							className={`${active === 1 ? 'active' : ''}`}
							onClick={() => setActive(1)}

						>
							<span className="topListIcon">
								<ProfileUserIcon width={15} height={15} />
							</span>
							<span className="topListText">My Profile</span>
						</li>
						<li
							className={`${active === 0 ? 'active' : ''}`}
							onClick={() => setActive(0)}

						>
							<span className="topListIcon">
								<ProfilePasswordIcon width={15} height={15} />
							</span>
							<span className="topListText">Password</span>
						</li>
					</ul>
				</div>
				<div className="p-0 w-100">
					<div className="card shadow-sm rounded-xl bg-white mb-4 p-3 p-xl-4 settingsPage">
						<div className="tab-content" id="myTabContent">
							<div
								className={`tab-pane fade ${active === 1 ? 'show active' : ''}`}
								id="profile"
								role="tabpanel"
								aria-labelledby="profile-tab"
							>
								<ProfileForm />
							</div>
							<div
								className={`tab-pane fade ${active !== 1 ? 'show active' : ''}`}
								id="password"
								role="tabpanel"
								aria-labelledby="password-tab"
							>
								<PasswordForm />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Settings;
