import { LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 31 }}>
				<Typography variant="body2" color="text.secondary" fontSize={12} textAlign={'right'}>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

export default LinearProgressWithLabel;
