import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { CloseIcon, DeleteIcon2, ArrowRightIcon, CommentInfoIcon, QouteStart, QouteEnd } from 'ui-component/custom-icons/icons'

import StatusBadge from 'ui-component/StatusBadge';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';

const ProductDetailModal = ({ openPopup, details, handleClose, handleDeleteRepair }) => {

    const [selectedRepairType, setSelectedRepairType] = useState(null);
    const [gradeList, setGradeList] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    

    useEffect(() => {
    }, []);
    

    const initializeItems = () => {
        const checkedItems = details?.filter((item) => item.isChecked);
        setSelectedItems(checkedItems);
    }

    const handleChangeGrade = (e, itemId) => {
        const updatedItems = selectedItems.map((item) => {
            if (item.id === itemId) {
                return { ...item, oldGradeId: e.id, oldGradeName: e?.label };
            }
            return item;
        });
        setSelectedItems(updatedItems);
    }
    

    return (
        <>
            <Modal show={openPopup}  centered size="md">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="80px" height="80px" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: 'new 0 0 512 512' }} ><g><g data-name="Layer 2"><circle cx="12" cy="12" r="10.75" fill="#0066ff" opacity="1" data-original="#4294ff"></circle><g fill="#eef3ff"><circle cx="12" cy="7.5" r="1.5" fill="#eef3ff" opacity="1" data-original="#eef3ff"></circle><rect width="3" height="8" x="10.5" y="10.5" rx="1" fill="#eef3ff" opacity="1" data-original="#eef3ff"></rect></g></g></g></svg>
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Item Information
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-md-3 col-4 text-left textGray font500">
                                    IMEI
                                </div>
                                <div className="col-md-9 col-8 text-left ">
                                    {details?.imei}
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-md-3 col-4 text-left textGray font500">
                                    Product
                                </div>
                                <div className="col-md-9 col-8 text-left ">
                                    {details?.productName}
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-md-3 col-4 text-left textGray font500">
                                    Color
                                </div>
                                <div className="col-md-9 col-8 text-left ">
                                    {details?.colorName}
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-md-3 col-4 text-left textGray font500">
                                    Status
                                </div>
                                <div className="col-md-9 col-8 text-left ">
                                    <div style={{ width: '75px' }}>
                                        <StatusBadge itemStatus={details?.status} />
                                    </div>
                                </div>
                            </div>
                            {
                                details?.old_grade_name && (
                                    <>
                                        <div className="row align-items-top">
                                            <div className="col-md-3 col-4 text-left textGray font500">
                                                Grade
                                            </div>
                                            <div className="col-md-9 col-8 text-left ">
                                                {details?.oldGradeName}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                details?.hasComment && (
                                    <>
                                        <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                            <div className="col-md-3 col-4 text-left textGray font500">
                                                Comment
                                            </div>
                                            <div className="col-md-9 col-8 text-left ">
                                                <div dangerouslySetInnerHTML={{ __html: details?.comment }} />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                details?.repairs?.length > 0 && (
                                    <>
                                                    
                                        <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div
                                                        className="col-12"
                                                        style={{ fontSize: '15px', marginTop: '15px', fontWeight: '600' }}
                                                    >
                                                        Repairs
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="">
                                                        <table className="table pm-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className='padLeft0'>Type</th>
                                                                    <th>Cost</th>
                                                                    <th>Status</th>
                                                                    <th colSpan={2}>Assigned to</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    details?.repairs?.map((repair, index) => (
                                                                        <>
                                                                            <tr className="hover-row">
                                                                                <td className='padLeft0'>{repair?.repair_type?.name}</td>
                                                                                <td>{getCurrencySymbol(repair?.currency)} {parseFloat(repair?.cost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                                                                <td>
                                                                                    <div style={{ width: '80px' }}>
                                                                                        <StatusBadge itemStatus={repair?.status} />
                                                                                    </div>
                                                                                </td>
                                                                                <td>{repair?.assigned_to?.name}</td>
                                                                                <PermissionAllowed permissions={['Delete Operations Repairs']}>
                                                                                    <td className="hover-button-container">
                                                                                        <div className="hover-button">
                                                                                            
                                                                                            <div
                                                                                                className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
                                                                                                onClick={() => handleDeleteRepair(index, repair, details)}
                                                                                            >
                                                                                                <DeleteIcon2 height={'15px'} width={'15px'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </PermissionAllowed>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProductDetailModal;
