import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import Sidebar from './Sidebar';
import NavBar from './NavBar';
import Footer from './Footer';
import LogOutDialogModel from './LogoutDialogModel';
import { useEffect } from 'react';
import ScrollButton from 'ui-component/ScrollToTopButton';
import GlobalSearch from 'ui-component/global-search/GlobalSearch';
import { useHotkeys } from 'react-hotkeys-hook';
import toast, { Toaster } from 'react-hot-toast';
import { setDisplayTheme, setLogout, storePermissions, storeUserDetails } from 'store/reducers/mainSlice';
import { useDispatch, useSelector } from 'react-redux';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { AiOutlinePoweroff } from 'react-icons/ai';
import HeaderMobile from './HeaderMobile';
import NavMobile from './NavMobile';

const MainLayout = ({ onGlobalizePrivacyMode }) => {
	const history = useNavigate();
	const [showSearch, setShowSearch] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [displayTheme] = useSelector((state) => [state.main.displayTheme]);
	const logout = useSelector((state) => state.main.logout);
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.main.user);
	const currentUserRole = useSelector((state) => state.main.user?.role?.name);

	useHotkeys('/', () => handleSearchToggle());

	const LogOutHandler = () => {
		dispatch(setLogout(false));
		// toast.promise(
		api.logout().then();
		// 	{
		// 		loading: 'Logging Out'
		// 	}
		// );
		window.localStorage?.removeItem('user');
		window.sessionStorage?.removeItem('user');
		// window.location.replace('/');
		dispatch(storeUserDetails(null));
		dispatch(storePermissions(null));
		history('/login');
	};

	useEffect(() => {
		document.body.id = 'page-top';
		if (!displayTheme) {
			if (localStorage.getItem('displayTheme')) {
				dispatch(setDisplayTheme(localStorage.getItem('displayTheme')));
			} else {
				dispatch(setDisplayTheme('lightMode'));
			}
		}

		if (!currentUser) {
			history('/login');
		}
	}, []);

	useEffect(() => {
		document.body.className = displayTheme;
	}, [displayTheme]);

	const handleSearchToggle = () => {
		setShowSearch(!showSearch);
	};

	return (
		<>
			<div id="parent-wrapper">
				{/* Sidebar */}
				<Sidebar />
				<div id="wrapper">
					<NavBar onSearchToggle={handleSearchToggle} />
					<HeaderMobile />
					{/* Content Wrapper */}
					<div id="content-wrapper" className="d-flex flex-column">
						{/* Main Content */}
						<div id="content" style={{position: 'relative'}}>
							{/* Topbar */}

							<div className="container-fluid d-flex justify-content-center">
								{/* Page Content */}
								<div style={{ maxWidth: '2200px', width: '100%' }}>
									<Outlet />
								</div>
							</div>
						</div>
						{/* Footer */}
						<NavMobile /> 
					</div>

				</div>
			</div>

			{/* Scroll to Top Button */}
			<ScrollButton />

			{logout && (
				<ActionConfirmationModal
					show={logout}
					title={'Ready to Leave?'}
					message={'Select "Logout" below if you are ready to end your current session.'}
					customIcon={<AiOutlinePoweroff />}
					customConfirmText={'Logout'}
					handleClose={() => dispatch(setLogout(false))}
					handleConfirm={LogOutHandler}
					confirmDanger
				/>
			)}
			<GlobalSearch show={showSearch} onForceClose={() => setShowSearch(false)} />
			<Toaster
				toastOptions={{
					className: 'toaster-styles'
				}}
			/>
		</>
	);
};

export default MainLayout;
