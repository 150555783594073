import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, InputGroup } from 'react-bootstrap';
import Pagination from 'ui-component/Pagination';
import { RiBillLine } from 'react-icons/ri';
import Scroller from 'ui-component/Scroller';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { Skeleton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import { FaEye } from 'react-icons/fa6';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import usePageTitle from 'hooks/usePageTitle';

const PurchaseOrders = () => {
	const [setPageTitle] = usePageTitle('Purchase Orders');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const [params, setParams] = useSearchParams();
	const history = useNavigate();
	const [userList, setUserList] = useState([]);
	const [alertMessage, setAlertMessage] = useState('');
	const [purchaseInvoiceList, setPurchaseInvoiceList] = useState([]);
	const [showDelete, setShowDelete] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	// const customerList = useSelector((state) => state.main.setcustomerlist);
	const [pagination, setPagination] = useState([]);
	const [newShow, setNewShow] = useState(false);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const handleNewClose = () => setNewShow(false);

	const getPurchaseInvoices = () => {
		setIsLoading(true);
		api.getPurchaseOrders().then((res) => {
			setIsLoading(false);
			setPurchaseInvoiceList(res.data.data);
			setPagination(res.data);
			isFirstLoad && setIsFirstLoad(false);
		});
	};

	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}
		let timeout = setTimeout(() => {
			setIsLoading(true);
			if (text.length > 2) {
				api.filterPurchaseOrders(activeFilters, text).then((res) => {
					setIsLoading(false);
					setPurchaseInvoiceList(res.data.data);
					setPagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterPurchaseOrders(activeFilters, null).then((res) => {
					setIsLoading(false);
					setPurchaseInvoiceList(res.data.data);
					setPagination(res.data);
				});
			}
		}, 1000);
		setSearchTimeout(timeout);
	};

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);
		api.filterPurchaseOrders(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsLoading(false);
			setPurchaseInvoiceList(res.data.data);
			setPagination(res.data);
		});
	};

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const handleData = (data) => {
		setPurchaseInvoiceList(data.data);
		setPagination(data);
	};

	const handleDelete = (id) => {
		toast.promise(
			api.deletePurchaseInvoice(id).then((res) => {
				setShowDelete(false);

				if (res?.status) {
					handleFilter();
				}
				return res?.status ? res : null;
			}),
			{
				loading: 'Deleting Purchase Invoice...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete Purchase Invoice</b>
			}
		);
	};

	useEffect(() => {
		getPurchaseInvoices();
		getFiltersList();
	}, []);

	useEffect(() => {
		if (params.get('create') == 'PurchaseBill') {
			setParams({});
			setNewShow(true);
		}
	});

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getPurchaseInvoiceFilter().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};
	const editPurchaseOrder = (id) => {
		history(`/purchaseall/view/${id}`);
	};

	return (
		<>
			<div className="row">
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<h2></h2>
					</div>
					<div className="card shadow-sm bg-white mb-4">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="table-responsive-xl tableData customersList p-28u">
							<Scroller>
								<table className="table pm-table">
									<thead>
										<tr className="sticky-header">
											<th scope="col" width="120">ID</th>
											<th scope="col" width="500">Supplier</th>
											<th scope="col" className="text-center-custom">Products</th>
											<th scope="col" className="text-center-custom">ETA</th>
											<th scope="col" className="text-center-custom">Status</th>
											<th scope="col" className="text-center-custom">Created</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<>
												{[...Array(5)].map((e, i) => (
													<tr>
														{[...Array(8)].map((e, i) => (
															<td>
																<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
															</td>
														))}
													</tr>
												))}
											</>
										) : (
											<>
												{purchaseInvoiceList?.map((row, index) => {
													return (
														<>
															<tr
																key={index}
																onClick={() => row.attached_invoice_number?.length < 1 && editPurchaseOrder(row.id)}
																style={{ cursor: 'pointer' }}
															>
																<td style={{ fontWeight: 500 }}>{row?.purchaseId}</td>
																<td className="align-middle">
																	<b>
																		<PrivacyModeContent>{row?.supplier?.name}</PrivacyModeContent>
																	</b>
																</td>
																<td className="text-center-custom">{row?.products}</td>
																<td className="text-center-custom">{moment(row?.date).format(CommonDateOnlyFormat)}</td>
																<td className="text-center-custom">
																	<span
																		className={`purchase-order-status-label ${row?.status?.name === 'Received'
																			? 'purchaseorder-status-label-received'
																			: row?.status?.name === 'Purchased'
																				? 'purchaseorder-status-label-purchased'
																				: 'purchaseorder-status-label-concept'
																			}`}
																	>
																		{row?.status?.name}
																	</span>
																</td>
																<td className="text-center-custom">{moment(row?.created_at).fromNow()}</td>
															</tr>
														</>
													);
												})}
												{purchaseInvoiceList?.length < 1 && (
													<tr className="no-hover">
														<td colSpan={11}>
															<div className="not-found-container">
																<div className="not-found-icon">
																	<TbDatabaseExclamation />
																</div>
																<h6 className="not-found-text">No Data Available</h6>
															</div>
														</td>
													</tr>
												)}
											</>
										)}
									</tbody>
								</table>
							</Scroller>
						</div>
						<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
					</div>
				</div>
			</div>

			{showDelete && (
				<DeleteConfirmation
					show={showDelete}
					handleClose={() => {
						setShowDelete(null);
					}}
					handleDelete={handleDelete}
					title="Confirm"
					message={`Are you sure you want to delete this purchase invoice?`}
				/>
			)}
		</>
	);
};
export default PurchaseOrders;
