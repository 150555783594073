import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './BarcodeScanModal.scss';

import { IoWarningOutline } from 'react-icons/io5';
import { PiWarningCircleFill, PiWarningFill } from 'react-icons/pi';
import { useEffect } from 'react';
import { BiBarcodeReader } from 'react-icons/bi';
// import BarcodeScannerComponent from 'react-qr-barcode-scanner-18';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';

function BarcodeScanModal({ show, handleClose, onSubmit, title, message, customIcon, confirmDanger, isLoading }) {
	const confirmBtnRef = useRef(null);
	const barcodeInputFocus = useRef(null);
	const [scanBC, setScanBc] = useState('');
	const isMobile = useCheckMobileScreen();

	const [barcode, setBarcode] = useState('');

	useEffect(() => {
		if (!!scanBC) {
			setBarcode(scanBC);
		}
	}, [scanBC]);

	useEffect(() => {
		setTimeout(() => {
			barcodeInputFocus?.current?.focus();
		}, 1);
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		onSubmit && onSubmit(barcode);
	};

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className="barcode-modal-dialog"
		>
			<Modal.Body>
				<Modal.Title>
					<span> {customIcon ? customIcon : <BiBarcodeReader />}</span>
					{title || 'Scan Barcode'}
				</Modal.Title>

				<form onSubmit={handleSubmit}>
					<div>
						<p className="message">{message || 'Please enter or scan barcode to continue.'}</p>

						{/* {isMobile && (
							<BarcodeScannerComponent
								onUpdate={(err, result) => {
									if (result) setScanBc(result.text);
									else setScanBc('');
								}}
							/>
						)} */}

						<div className="form-group">
							<input
								ref={barcodeInputFocus}
								type="text"
								className="form-control"
								autoFocus
								value={barcode}
								onChange={(e) => {
									setBarcode(e.target.value);
								}}
								required
							/>
						</div>
					</div>

					<div className="modal-actions">
						<button
							ref={confirmBtnRef}
							type="submit"
							className={`btn btn-primary btn-outlined-main btn-confirm ${confirmDanger ? 'danger' : ''}`}
							disabled={isLoading}
						>
							Continue
						</button>

						<button
							className="btn btn-primary btn-outlined-main"
							type="button"
							onClick={(e) => {
								handleClose && handleClose(e);
							}}
						>
							Cancel
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}

export default BarcodeScanModal;
