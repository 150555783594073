import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { FaCheck, FaMinus, FaInfoCircle, FaBackward } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import FileUpload from 'ui-component/FileUpload';
import moment from 'moment';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

const ActionInspectionRollbackModal = ({ inspectionId, handleCloseRollbackModal, redirectionData }) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [checkbox, setCheckbox] = useState({
		vatRegistration: false,
		bankDetails: false,
		ttCopies: false
	});
	const [disableStatus, setDisablestatus] = useState(false);
	const [alertMessage, setAlertMessage] = useState();
	const [imageIds, setImageids] = useState([]);
	const [files, setFiles] = useState([]);
	const [paymentDetails, setPaymentDetails] = useState({
		date: moment().format(CommonDateOnlyFormat),
		amount: 0,
	});
    const [rollbackComment, setRollbackComment] = useState(null);

	const handleCheckboxChange = (e) => {
		const { id, checked } = e.target;
		setCheckbox((prevCheckbox) => ({
			...prevCheckbox,
			[id]: checked
		}));
	};

	const handleFileUpload = (e) => {
		const files = e.target.files;
		setFiles(files);
		setDisablestatus(true);
		if (files && files.length > 0) {
			const formData = new FormData();
			for (let i = 0; i < files.length; i++) {
				formData.append('tt_files[]', files[i]);
			}

			formData.append('estimation_id', inspectionId || 23);
			api.uploadTTfile(formData).then((res) => {
				setDisablestatus(false);
				// setImageids(res.data);
			});
		}
	};

	const handleRollback = () => {
        toast.promise(
            api
                .rollbackInspection({
                    inspection_id: inspectionId,
                    comment: rollbackComment,
                })
                .then((res) => {
                    if (res.status) {
                        history('/operations/inspection');
                    }
                }),
            {
                loading: 'Accepting Estimate...',
                success: <b>Inspection Rolled back!</b>,
                error: <b>Could not Rollback Inspection.</b>
            }
        );
	};
	useEffect(() => {
	}, [inspectionId]);

	return (
		<>
			<Modal show={inspectionId} onHide={handleCloseRollbackModal} centered>
				<Modal.Body>
					<div className="row py-3 px-2">
						<div className="col-md-12">
							<h4 className="h6 text-white text-center pb-2">
                                <FaInfoCircle style={{fontSize: '34px'}} />
                                <br />
                                <br />
								<h4>Rollback Inspection</h4>
							</h4>
                            <div className="form-group mb-2">
                                <label htmlFor="rollbackComment">
                                    Reason for rollback
                                </label>
                                <textarea
                                    className="form-control"
                                    id="rollbackComment"
                                    onKeyUp={(e)=>( setRollbackComment(e.target?.value))}
                                />
                            </div>
						</div>
						<div className="btnarea w-100 mt-2">
							<button onClick={handleCloseRollbackModal} className="btn btn-link">
								Cancel
							</button>

							<button disabled={disableStatus} onClick={handleRollback} className="btn btn-primary btn-outlined-main">
                                <FaBackward /> Rollback
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ActionInspectionRollbackModal;
