import React from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselUi = () => {
	return (
		<>
			<Carousel indicators={true} controls={false}>
				<Carousel.Item>
					<h4 className="h3 mb-4 font-weight-bold">The easiest way to manage your orders.</h4>
					<p className="h5">
						View all the analytics and grow your <br />
						Business from anywhere!
					</p>
				</Carousel.Item>
				<Carousel.Item>
					<h4 className="h3 mb-4 font-weight-bold">There are many variations of Lorem Ipsum.</h4>
					<p className="h5">
						Simply random text. It has roots in a piece of <br />
						classical Latin literature
					</p>
				</Carousel.Item>
				<Carousel.Item>
					<h4 className="h3 mb-4 font-weight-bold">Established fact that a reader will be distracted.</h4>
					<p className="h5">
						View all the analytics and grow your <br />
						Business from anywhere!
					</p>
				</Carousel.Item>
			</Carousel>
		</>
	)
}

export default CarouselUi
