import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaCheck, FaEdit, FaTrash, FaFileInvoice } from 'react-icons/fa';
import { LuSend } from 'react-icons/lu';
import usePageTitle from 'hooks/usePageTitle';
import Scroller from 'ui-component/Scroller';
import { TbDatabaseExclamation } from 'react-icons/tb';
import toast from 'react-hot-toast';
import { FaPlus, FaSave, FaPencilAlt, FaCloudDownloadAlt, FaSpinner } from 'react-icons/fa';
import { RiTruckLine } from 'react-icons/ri';
import moment from 'moment';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import FileUpload from 'ui-component/FileUpload';
import NoImage from 'assets/images/no-image.png';

const PurchaseDetails = () => {
	const { id } = useParams();
	const history = useNavigate();
	const [purchaseInvoice, setPurchaseInvoice] = useState(null);
	const [uploadInvoice, setUploadInvoice] = useState(false);
	const [file, setFile] = useState();
	const [receipts, setReceipts] = useState([]);
	const [setPageTitle] = usePageTitle('Purchasingw');

	const [email, setEmail] = useState(purchaseInvoice?.supplier?.email || '');
	const [subject, setSubject] = useState(`Purchase Order - ${purchaseInvoice && purchaseInvoice.purchase_order_id}`);
	const [message, setMessage] = useState(
		`Dear ${purchaseInvoice?.supplier?.name},\n\nWe are hereby sending you an order, under our reference ${purchaseInvoice?.purchase_order_id}. The order is attached as a PDF and as an Excel file.\n\nKind regards,\nMix Global FZCO`
	);
	const [isSending, setIsSending] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const currentYear = new Date().getFullYear();

	useEffect(() => {
		iniateData();
	}, [id]);

	const iniateData = () => {
		api.getPurchaseInvoiceView(id).then((res) => {
			console.log(res);
			setPurchaseInvoice(res?.data);
			setReceipts(res?.data?.receipts);

			// Set initial values of email, subject, and message
			setEmail(res?.data?.supplier?.email || '');
			setSubject(`Purchase Order - ${res?.data?.purchase_order_id}`);
			setMessage(
				`Dear ${res?.data?.supplier?.name},\n\nWe are hereby sending you an order, under our reference ${res?.data?.purchase_order_id}. The order is attached as a PDF and as an Excel file.\n\nKind regards,\nMix Global FZCO`
			);
		});
	};
	const totalQuantity = purchaseInvoice?.purchase_invoice_items?.reduce((total, row) => total + row.quantity, 0);
	const totalrecieved = purchaseInvoice?.receipts?.reduce(
		(total, row) => total + row.receipt_items?.reduce((total, item) => total + (item.quantity || 0), 0),
		0
	);
	const totalPrice = purchaseInvoice?.purchase_invoice_items?.reduce((total, row) => total + row.quantity * row.price, 0);

	const deletePurchase = () => {
		api.deletePurchaseInvoice(id).then((res) => {
			console.log(res);
			if (res.status) {
				toast.success(res.message);
				history('/purchase-invoice');
			} else {
				toast.error(res.message);
			}
		});
	};

	const handleFileUpload = (e) => {
		const files = e.target.files;
		setFile(files?.length >= 1 ? files?.[0] : []);
	};

	const editPurchase = () => {
		history(`/purchaseall/${id}`);
	};
	const markPurchased = () => {
		api.markPurchased(id, purchaseInvoice).then((res) => {
			console.log(res);
			if (res.status) {
				toast.success(res.message);
				iniateData();
			} else {
				toast.error(res.message);
			}
		});
	};

	const emailSupplier = () => {
		setIsModalOpen(true);
		setIsSending(false);
	};

	const sendEmail = () => {
		setIsSending(true);
		api.sendEmailSupplier(email, subject, message, id).then((res) => {
			toast.success(res.message);
			setIsSending(false);
			handleClose2();
		});
	};

	const registerReceipt = () => {
		history(`/purchase-receipt/create/${id}`);
	};
	const uplodInvoice = () => {
		setUploadInvoice(true);
	};
	const uploadFile = () => {
		const data = {
			id: id,
			attachment: file
		};
		console.log(data);
		api.uploadInvoiceAttachment(id, data).then((res) => {
			console.log(res);
			if (res.status) {
				toast.success(res.message);
				iniateData();
				setUploadInvoice(false);
			} else {
				toast.error(res.message);
			}
		});
	};
	const handleClose = () => setUploadInvoice(false);
	const handleClose2 = () => setIsModalOpen(false);

	return (
		<>
			<div className="row">
				<div className="col-12 col-md-12">
					<div className="section-head row">
						<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }} className="col-md-6">
							{purchaseInvoice && purchaseInvoice.purchase_order_id}
						</h2>
						<div className="actions col-md-6">
							<button type="button" onClick={() => emailSupplier()} className="btn btn-primary btn-outlined-main">
								<LuSend />
								Email Supplier
							</button>
							<button type="button" onClick={() => uplodInvoice()} className="btn btn-primary btn-outlined-main">
								<FaFileInvoice />
								upload Invoice
							</button>
							<button type="button" onClick={() => deletePurchase()} className="btn btn-primary btn-outlined-main">
								<FaTrash /> Delete
							</button>
						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-4 p-3 p-xl-4 w-100">
							<div className="parent-group">
								<div className="btn-group">
									<button
										className={`btn btn-default btn-sm btn-left events-none ${purchaseInvoice?.master_status?.name === 'Concept' ? 'concept' : ''
											}`}
									>
										Concept
									</button>
									<button
										className={`btn btn-default btn-sm btn-middle events-none ${purchaseInvoice?.master_status?.name === 'Purchased' ? 'purchased' : ''
											}`}
									>
										Purchased
									</button>
									<button
										className={`btn btn-default btn-sm btn-right events-none ${purchaseInvoice?.master_status?.name === 'Received' ? 'received' : ''
											}`}
									>
										Received
									</button>
								</div>
								<div className="btn-group-actions">
									{

										purchaseInvoice?.master_status?.name === 'Concept' ? (
											<>
												<button className="btn btn-default btn-sm btn-purchase-main" onClick={() => markPurchased()}>
													<FaCheck /> Mark as purchased
												</button>
											</>
										) : (
											<>
												<button
													className="btn btn-default btn-sm btn-purchase-main"
													disabled={totalQuantity == totalrecieved}
													onClick={() => registerReceipt()}
												>
													<RiTruckLine /> Register receipt
												</button>
											</>
										)}
								</div>
							</div>

							<hr />
							<Scroller>
								<div className="row">
									<div className="col-md-12 col-lg-12">
										<div className="" style={{ justifyContent: 'space-between' }}>
											<table className="projectmix-table-vertical">
												<tbody>
													<tr>
														<th>Purchase order</th>
														<td> {purchaseInvoice && purchaseInvoice.purchase_order_id}</td>
													</tr>
													<tr>
														<th>Supplier</th>
														<td> {purchaseInvoice?.supplier?.name}</td>
													</tr>
													<tr>
														<th>ETA</th>
														<td> {moment(purchaseInvoice?.date).format('DD MMM YYYY')}</td>
													</tr>
													<tr>
														<th>Warehouse</th>
														<td> {purchaseInvoice?.warehouse?.name}</td>
													</tr>
													<tr>
														<th>Currency</th>
														<td className="upper-case"> {purchaseInvoice?.currency}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div className="productsTable estlist" style={{ fontSize: '12px' }}>
											<div className="section-head">
												<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>Products</h2>
											</div>

											<div className="estimation-table-area">
												<div className="table-side">
													<div className="table-responsive-xl tableData estimationList">
														<Scroller extraHeight={80}>
															{/* <table className="table table-md text-gray-900 formActBtns border-0"> */}
															<table className="table pm-table">
																<thead>
																	<tr className="sticky-header ">
																		<th scope="col" className="align-middle" style={{ width: '50px' }}></th>
																		<th scope="col" className="align-middle" style={{ width: '125px' }}>SKU</th>
																		<th scope="col" className="align-middle">Product</th>
																		<th scope="col" className="align-middle" style={{ width: '100px' }}>Ordered</th>
																		<th scope="col" className="align-middle" style={{ width: '120px' }}>Price</th>
																		<th scope="col" className="align-middle" style={{ width: '120px' }}>Total</th>
																		{/* <th className="align-middle text-center" width={'30'}></th> */}
																	</tr>
																</thead>
																<tbody>
																	{purchaseInvoice?.purchase_invoice_items?.length > 0 ? (
																		purchaseInvoice?.purchase_invoice_items.map((row, index) => (
																			<>
																				<tr key={row?.id}>
																					<td>

																						{row?.product?.thumbnail_url ? (
																							<img src={row?.product?.thumbnail_url} style={{ width: '30px' }} alt="product" />
																						) : (
																							<img src={NoImage} style={{ width: '30px' }} alt="product" />
																						)}
																					</td>
																					<td className="align-middle estimateCName">
																						{row?.product?.sku}
																					</td>
																					<td className="align-middle estimateCName">
																						{row?.product?.name}
																					</td>
																					<td className="align-middle ">
																						{row?.quantity}
																					</td>
																					<td className="align-middle">
																						<div className="">
																							{purchaseInvoice?.currency === 'usd'
																								? ' $ '
																								: purchaseInvoice?.currency === 'eur'
																									? ' € '
																									: purchaseInvoice?.currency === 'aed'
																										? ' AED '
																										: ''}
																							{row?.price}
																						</div>
																					</td>
																					<td className="align-middle">
																						<div className="">
																							{purchaseInvoice?.currency === 'usd'
																								? ' $ '
																								: purchaseInvoice?.currency === 'eur'
																									? ' € '
																									: purchaseInvoice?.currency === 'aed'
																										? ' AED '
																										: ''}
																							{row?.total}
																						</div>
																					</td>
																					{/* <td className="align-middle text-center">
																			{row.product_id ? (
																			
																			<span
																				className="btnEdit plain-iconbtn"
																				onClick={() => removeRow(row.product_id)}
																			>
																				<FaTimes />
																			</span>
																		) : (
																			''
																		)}
																		</td> */}
																				</tr>
																			</>
																		))
																	) : (
																		<tr className="no-hover">
																			<td colSpan={9}>
																				<div className="not-found-container cstm-not-found">
																					<div className="not-found-icon">
																						<TbDatabaseExclamation />
																					</div>
																					<h6 className="not-found-text">Products not found</h6>
																				</div>
																			</td>
																		</tr>
																	)}
																</tbody>
																{purchaseInvoice?.purchase_invoice_items?.length > 0 ? (
																	<tfoot>
																		<tr className="tr-nth">
																			<td className="align-middle" colSpan={3}></td>
																			<td className="align-middle">
																				<div className="form-group col-md-12 mb-0">{totalQuantity}</div>
																			</td>
																			<td className="align-middle"></td>
																			<td className="align-middle">
																				<label>
																					{purchaseInvoice?.currency === 'usd'
																						? ' $ '
																						: purchaseInvoice?.currency === 'eur'
																							? ' € '
																							: purchaseInvoice?.currency === 'aed'
																								? ' AED '
																								: ''}
																					{totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
																				</label>
																			</td>
																		</tr>
																	</tfoot>
																) : (
																	<></>
																)}
															</table>
														</Scroller>
													</div>
												</div>
											</div>
										</div>

										<br />
										<br />
										{purchaseInvoice?.master_status?.name === 'Received' && receipts?.length > 0 ? (
											<>
												<div className="productsTable estlist" style={{ fontSize: '12px' }}>
													<div className="table-responsive-xl tableData">
														<div className="section-head">
															<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>Receipts</h2>
														</div>
														<table className="table table-md text-gray-900 formActBtns border-0">
															<thead>
																<tr>
																	<th className="align-middle" width={'120'}></th>
																	<th className="align-middle" width={'120'}>
																		Received By
																	</th>
																	<th className="align-middle " width={'120'}>
																		Status
																	</th>
																	<th className="align-middle text-right" width={'110'}>
																		Products
																	</th>
																	<th className="align-middle text-center" width={'30'}>
																		Value
																	</th>
																	<th className="align-middle text-center" width={'120'}>
																		Completed at
																	</th>
																	<th className="align-middle text-center" width={'30'}></th>
																</tr>
															</thead>
															<tbody>
																{receipts?.length > 0 ? (
																	receipts.map((row, index) => (
																		<>
																			<tr key={row?.id}>
																				<td className="align-middle estimateCName">
																					<div className="form-group col-md-12 mb-0">{row?.receipt_number}</div>
																				</td>
																				<td className="align-middle ">
																					<div className="form-group col-md-12 mb-0">{row?.user?.name}</div>
																				</td>
																				<td className="align-middle ">
																					<span className="status-complete">Completed</span>
																				</td>
																				<td className="align-middle text-right">
																					<div className="mr-3">
																						{row?.receipt_items?.reduce((total, item) => total + (item.quantity || 0), 0)}
																					</div>
																				</td>
																				<td className="align-middle text-center">
																					{purchaseInvoice?.currency === 'usd'
																						? ' $ '
																						: purchaseInvoice?.currency === 'eur'
																							? ' € '
																							: purchaseInvoice?.currency === 'aed'
																								? ' AED '
																								: ''}
																					{row?.receipt_items?.reduce((total, item) => total + (parseFloat(item.total) || 0), 0).toFixed(2)}
																				</td>
																				<td className="align-middle text-center">
																					{moment(row?.created_at).format('DD MMM YYYY hh:mm A')}
																				</td>
																				<td></td>
																			</tr>
																		</>
																	))
																) : (
																	<tr className="no-hover">
																		<td colSpan={9}>
																			<div className="not-found-container cstm-not-found">
																				<div className="not-found-icon">
																					<TbDatabaseExclamation />
																				</div>
																				<h6 className="not-found-text">Products not found</h6>
																			</div>
																		</td>
																	</tr>
																)}
															</tbody>
														</table>
													</div>
												</div>
											</>
										) : (
											<></>
										)}
									</div>
								</div>
							</Scroller>
							{purchaseInvoice?.attachment_url && (
								<>
									{purchaseInvoice.attachment_url.toLowerCase().endsWith('.pdf') ? (
										<embed src={purchaseInvoice.attachment_url} type="application/pdf" width="100%" height="600px" />
									) : (
										<img src={purchaseInvoice.attachment_url} alt="" />
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<Modal show={uploadInvoice} onHide={handleClose} centered>
				<Modal.Body>
					<Modal.Title>
						{' '}
						<div className="text-center">Upload Invoice</div>
					</Modal.Title>
					<div className="form-group col-md-12">
						<label>Attachment</label>
						<FileUpload
							accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
							loading={false}
							onChange={(e) => {
								handleFileUpload(e);
							}}
							selectedValue={file?.[0]}
						/>
					</div>
					<div className="flex-attachment">
						<Button variant="primary" className="m-2 " onClick={handleClose}>
							Cancel
						</Button>
						<button className="btn btn-primary btn-outlined-main" onClick={() => uploadFile()}>
							Upload
						</button>
					</div>
				</Modal.Body>
			</Modal>

			{isModalOpen && (
				<Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
					<Modal.Header>
						<Modal.Title>Send email to supplier</Modal.Title>
						<Button variant="link" onClick={handleClose2} style={{ marginLeft: 'auto' }}>
							X
						</Button>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="form-group">
								<label htmlFor="email">Email</label>
								<input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
							</div>
							<div className="form-group">
								<label htmlFor="subject">Subject</label>
								<input type="text" className="form-control" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
							</div>
							<div className="form-group">
								<label htmlFor="message">Message</label>
								<textarea
									type="text"
									className="form-control"
									id="message"
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									rows="8"
								/>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<Button className="btn btn-main" onClick={() => setIsModalOpen(false)}>
							Close
						</Button>
						{isSending ? (
							<button className="btn btn-main-primary disabled" disabled>
								<FaSpinner style={{ animation: 'spin 2s linear infinite' }} /> Sending...
							</button>
						) : (
							<Button className="btn btn-main-primary" onClick={() => sendEmail(email, subject, message)}>
								Send
								<LuSend />
							</Button>
						)}
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
};

export default PurchaseDetails;
