import React, { useEffect }  from 'react'
import usePageTitle from 'hooks/usePageTitle';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { BsCalendar2Date } from "react-icons/bs";
import { VscReferences } from "react-icons/vsc";
import { MdOutlineManageAccounts, MdOutlineDisplaySettings } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import {
    SettingsSubscriptionIcon,
    SettingsPaymentMethodIcon,
    SettingsEditSettingsIcon,
    SettingsEmailTemplateIcon,
    SettingsDownloadIcon,
    SettingsUsersIcon,
    SettingsRolesIcon,
    SettingsWebsiteIcon,
    SettingsModelIcon,
    SettingsColorIcon,
    SettingsCategoryIcon,
    SettingsGradeIcon,
    SettingsRepairIcon,
    SettingsShippingIcon,
    SettingsSpecificationIcon,
    SettingsCurrencyIcon,
    SettingsBankIcon,
    SettingsInterestIcon
} from 'ui-component/custom-icons/icons';





const Main = () => {
    const [setPageTitle] = usePageTitle('Settings');
    useEffect(() => {
        return () => {
            setPageTitle('Settings');
        };
    }, []);
    const history = useNavigate()

    return (
        <>
            <div className="row settings-container mt30 mb50">
                <div className="col-12 col-md-12 p-0 p-md-2 inv-preview">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/company-details')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 45.609 45.609" className='icon-set'>
                                                    <path id="file-circle-info" d="M32.306,19a13.3,13.3,0,1,0,13.3,13.3A13.317,13.317,0,0,0,32.306,19Zm0,22.8a9.5,9.5,0,1,1,9.5-9.5A9.505,9.505,0,0,1,32.306,41.809Zm2.851-14.253a2.851,2.851,0,1,1-2.851-2.851A2.847,2.847,0,0,1,35.157,27.556Zm-.95,6.651v3.8a1.9,1.9,0,1,1-3.8,0v-3.8a1.9,1.9,0,1,1,3.8,0Zm-17.1,5.7a1.906,1.906,0,0,1-1.9,1.9H9.5a9.505,9.505,0,0,1-9.5-9.5V9.5A9.505,9.505,0,0,1,9.5,0H20.448a7.724,7.724,0,0,1,5.378,2.223l6.157,6.157a7.647,7.647,0,0,1,2.2,4.751,1.924,1.924,0,0,1-1.748,2.052h-.152a1.918,1.918,0,0,1-1.9-1.748v-.152H24.724a3.812,3.812,0,0,1-3.8-3.8V3.839c-.152,0-.3-.038-.456-.038H9.5A5.718,5.718,0,0,0,3.8,9.5v22.8a5.718,5.718,0,0,0,5.7,5.7h5.7A1.906,1.906,0,0,1,17.1,39.908Z" fill="currentColor" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Company details</div>
                                            <div className="textGray settings-card-description">Details about your company like name and address. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="card shadow-sm bg-white mb-4 cursor-pointer card-effect">
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsSubscriptionIcon height={'30px'} width={'30px'} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Your Subscription</div>
                                            <div className="textGray settings-card-description">Your subscription and other terms we made. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="card shadow-sm bg-white mb-4 cursor-pointer card-effect">
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">

                                                <SettingsPaymentMethodIcon height={'30px'} width={'30px'} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Payment Method</div>
                                            <div className="textGray settings-card-description">Change your payment method. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                    </div>   
                    
                    <div className="row">
                        <div className="col-12 settings-feature-headers">
                            General Settings
                            <hr />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/general-settings')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsEditSettingsIcon height={'30px'} width={'30px'} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">General Settings</div>
                                            <div className="textGray settings-card-description">Manage system-wide configurations to optimize functionality that fit your business needs. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="card shadow-sm bg-white mb-4 cursor-pointer card-effect">
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsEmailTemplateIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Email Templates</div>
                                            <div className="textGray settings-card-description">Manager email templates and maintain a library to streamline messaging and boost engagement. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="card shadow-sm bg-white mb-4 cursor-pointer card-effect">
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsDownloadIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Download Templates</div>
                                            <div className="textGray settings-card-description">Efficiently organize and update your downloadable templates for streamlined access and use. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                    </div>   
                    
                    <div className="row">
                        <div className="col-12 settings-feature-headers">
                            Access
                            <hr />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/user')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsUsersIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Users</div>
                                            <div className="textGray settings-card-description">Create or manager the accounts of all your co-workers. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/role')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsRolesIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Roles</div>
                                            <div className="textGray settings-card-description">Allows to define and manage your user's access within the system. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="card shadow-sm bg-white mb-4 cursor-pointer card-effect">
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsWebsiteIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Client Portal</div>
                                            <div className="textGray settings-card-description">General settings for your client portal where your clients can login. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                    </div>  
                    
                    
                    <div className="row">
                        <div className="col-12 settings-feature-headers">
                            Categories
                            <hr />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="card shadow-sm bg-white mb-4 cursor-pointer card-effect">
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsModelIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Models</div>
                                            <div className="textGray settings-card-description">Create or manager the accounts of all your co-workers. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/colors')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsColorIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Colors</div>
                                            <div className="textGray settings-card-description">Manage and customize color options for seamless branding and product differentiation. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/productcategories')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsCategoryIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Categories</div>
                                            <div className="textGray settings-card-description">Organize and categorize your products for easy navigation and streamlined inventory management. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/grades')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsGradeIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Grades</div>
                                            <div className="textGray settings-card-description">Define and manage product grades to ensure quality control and standardization across your offerings. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/repairs')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsRepairIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Repairs</div>
                                            <div className="textGray settings-card-description">Manage different repair types to streamline service operations and improve workflow efficiency. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/shipping-companies')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsShippingIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Shipping companies</div>
                                            <div className="textGray settings-card-description">Easily manage and update your shipping company information for seamless logistics operations. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/specifications')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsSpecificationIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Product Specifications</div>
                                            <div className="textGray settings-card-description">Streamline and adjust product details to maintain accuracy and meet market demands. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/interests')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsInterestIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Customer Interests</div>
                                            <div className="textGray settings-card-description">Track and update customer interests to align offerings with preferences and market trends. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                        <div className="col-md-4 col-sm-6 col-12">
                            <div
                                className="card shadow-sm bg-white mb-4 cursor-pointer card-effect"
                                onClick={() => history('/settings/banks')}
                            >
                                <div className="card-body card-body-settings-container">
                                    <div className="d-flex" style={{ gap: '10px' }}>
                                        <div className="settings-icon-set color-theme">
                                            <div className="settings-icon-bg-container">
                                                <SettingsBankIcon height={'30px'} width={'30px'} /> 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="settings-card-title color-theme">Bank</div>
                                            <div className="textGray settings-card-description">Provide and manage your banking information for secure and efficient transactions. </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        </div>
                    </div>  


                </div>
            </div>
        </>

    )
}

export default Main
