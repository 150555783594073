import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Loader from 'ui-component/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'store/reducers/mainSlice';
import usePageTitle from 'hooks/usePageTitle';
import _ from 'lodash';
import { FaDownload, FaFile, FaShippingFast, FaTools } from 'react-icons/fa';
import { BsClipboardCheck, BsFillFileEarmarkImageFill, BsFillFileEarmarkPdfFill } from 'react-icons/bs';
import { GiHandTruck } from 'react-icons/gi';

const ProductsView = () => {
	const { id } = useParams();
	// const isLoading = useSelector((state) => state.main.isLoading);
	const selectedUser = useSelector((state) => state.main.user.selectedUser);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const dispatch = useDispatch();
	const history = useNavigate();
	const [modelvalue, setmodelvalue] = useState();
	const [purchaseDate, setPurchasedate] = useState();
	const [saleDate, setSaledate] = useState();
	const [isLoading, setIsloading] = useState(false);
	const [show, setShow] = useState('');
	const [calculatedCosts, setCalculatedCosts] = useState({
		repair: {
			usd: 0,
			eur: 0,
			aed: 0
		},
		profit: {
			usd: 0,
			eur: 0,
			aed: 0
		}
	});
	// const [roles, setRoles] = useState();

	const getmodelvalue = () => {
		setIsloading(true);
		api.getproductbyId(id).then((res) => {
			setIsloading(false);
			if (res.status) {
				console.log(res);
				setmodelvalue(res.data);
				calculateCosts(res.data);
			}
		});
	};

	const calculateCosts = (model) => {
		let repair = {
			usd: _.sumBy(model?.repaires, (e) => e?.currency_cost?.cost_usd),
			eur: _.sumBy(model?.repaires, (e) => e?.currency_cost?.cost_eur),
			aed: _.sumBy(model?.repaires, (e) => e?.currency_cost?.cost_usd)
		};

		let profit = {
			usd: (
				Number(model?.currency_selling_unit_price?.unit_price_usd || 0) -
				(Number(model?.currency_purchase_unit_price?.unit_price_usd || 0) + Number(repair.usd || 0))
			).toFixed(2),
			eur: (
				Number(model?.currency_selling_unit_price?.unit_price_eur || 0) -
				(Number(model?.currency_purchase_unit_price?.unit_price_eur || 0) + Number(repair.eur || 0))
			).toFixed(2),
			aed: (
				Number(model?.currency_selling_unit_price?.unit_price_aed || 0) -
				(Number(model?.currency_purchase_unit_price?.unit_price_aed || 0) + Number(repair.aed || 0))
			).toFixed(2)
		};

		setCalculatedCosts({ repair: { ...repair }, profit: { ...profit } });
	};

	useEffect(() => {
		getmodelvalue();
	}, []);

	// console.log('modelvalue ', modelvalue);

	return (
		<>
			<div className="row">
				<div className="col-6">
					<div className="card shadow-sm rounded-xl bg-white p-4 mb-4 text-white">
						<h5 className="mb-">Product</h5>
						<div className="row">
							<div className="col-3">
								<img src="https://coolmix.eu/store/images/models/iphone-14m-back.webp" alt="" className="w-100" />
							</div>
							<div className="col-9">
								<div className="model-title">
									<h6 className="mb-2">{`${modelvalue?.product?.name}`}</h6>
									{/* <p>
												<small>{`${modelvalue?.grade?.name}`}</small> &nbsp; &nbsp;{' '}
												<small>{`${modelvalue?.storage?.name}`}</small>
											</p> */}
								</div>

								<table>
									<tr>
										<td width={130}>IMEI: </td>
										<td>{`${modelvalue?.imei || '-'}`}</td>
									</tr>
									<tr>
										<td>Grade: </td>
										<td>{`${modelvalue?.grade?.name}`}</td>
									</tr>
									<tr>
										<td>Storage: </td>
										<td>{`${modelvalue?.storage?.name}`}</td>
									</tr>
									<tr>
										<td>Color: </td>
										<td>{`${modelvalue?.color?.name || '-'}`}</td>
									</tr>
									<tr>
										<td>Supplier: </td>
										<td>{`${modelvalue?.supplier?.name || '-'}`}</td>
									</tr>
									<tr>
										<td>Customer: </td>
										<td>{`${modelvalue?.customer?.name || '-'}`}</td>
									</tr>
									<tr>
										<td>Invoice: </td>
										<td>{`${modelvalue?.estimation?.invoice_number || '-'}`}</td>
									</tr>
									{/* <tr>
										<td>Purchase Order: </td>
										<td>---TO DO---</td>
									</tr> */}
								</table>
							</div>
						</div>
					</div>

					<div className="card shadow-sm rounded-xl bg-white p-4 mb-4 text-white">
						<h5 className="mb-4">Attachments</h5>
						<div className="product-attachments-list">
							<div className="product-attachment">
								<div>
									<BsFillFileEarmarkPdfFill /> Invoice
								</div>
								<div>
									<button className="btn btn-primary btn-main">
										<FaDownload />
									</button>
								</div>
							</div>
							<div className="product-attachment">
								<div>
									<BsFillFileEarmarkPdfFill /> Purchase Order
								</div>
								<div>
									<button className="btn btn-primary btn-main">
										<FaDownload />
									</button>
								</div>
							</div>
							<div className="product-attachment">
								<div>
									<BsFillFileEarmarkImageFill /> Payment Proof 1
								</div>
								<div>
									<button className="btn btn-primary btn-main">
										<FaDownload />
									</button>
								</div>
							</div>
							<div className="product-attachment">
								<div>
									<BsFillFileEarmarkImageFill /> Payment Proof 2
								</div>
								<div>
									<button className="btn btn-primary btn-main">
										<FaDownload />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6">
					<div className="card shadow-sm rounded-xl bg-white p-4 mb-4 text-white">
						<h5 className="mb-4">Profit</h5>
						<div className="row" style={{ rowGap: '10px' }}>
							{/* <div className="cost-box col-12">
										<div className="row">
											<div className="col-3 font-weight-bold"></div>
											<div className="col-3 border-bottom"><small>USD</small></div
											<div className="col-3">EURO</div>
											<div className="col-3">AED</div>
										</div>
									</div> */}
							<div className="cost-box col-12">
								<div className="row">
									<div className="col-3 font-weight-bold">Purchase Cost</div>
									<div className="col-3">
										$ {`${modelvalue?.currency_purchase_unit_price?.unit_price_usd.toFixed(2)}`}
									</div>
									<div className="col-3">
										€ {`${modelvalue?.currency_purchase_unit_price?.unit_price_eur.toFixed(2)}`}
									</div>
									<div className="col-3">
										AED {`${modelvalue?.currency_purchase_unit_price?.unit_price_aed.toFixed(2)}`}
									</div>
								</div>
							</div>
							{modelvalue?.repaires?.length > 0 && (
								<div className="cost-box col-12">
									<div className="row">
										<div className="col-3 font-weight-bold">Repair Cost</div>
										<div className="col-3">$ {calculatedCosts.repair.usd.toFixed(2)}</div>
										<div className="col-3">€ {calculatedCosts.repair.eur.toFixed(2)}</div>
										<div className="col-3">AED {calculatedCosts.repair.aed.toFixed(2)}</div>
									</div>
								</div>
							)}
							<div className="cost-box col-12">
								<div className="row">
									<div className="col-3 font-weight-bold"> Selling Price</div>
									<div className="col-3">$ {`${modelvalue?.currency_selling_unit_price?.unit_price_usd.toFixed(2)}`}</div>
									<div className="col-3">€ {`${modelvalue?.currency_selling_unit_price?.unit_price_eur.toFixed(2)}`}</div>
									<div className="col-3">
										AED {`${modelvalue?.currency_selling_unit_price?.unit_price_aed.toFixed(2)}`}
									</div>
								</div>
							</div>
							<div className="cost-box col-12">
								<div className="row">
									<div
										className={
											calculatedCosts.profit.usd > 0
												? 'col-3 font-weight-bold text-success'
												: 'col-3 font-weight-bold text-danger'
										}
									>
										Profit
									</div>
									<div className={calculatedCosts.profit.usd > 0 ? 'col-3 text-success' : 'col-3 text-danger'}>
										$ {calculatedCosts.profit.usd}
									</div>
									<div className={calculatedCosts.profit.usd > 0 ? 'col-3 text-success' : 'col-3 text-danger'}>
										€ {calculatedCosts.profit.eur}
									</div>
									<div className={calculatedCosts.profit.usd > 0 ? 'col-3 text-success' : 'col-3 text-danger'}>
										AED {calculatedCosts.profit.aed}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="card shadow-sm rounded-xl bg-white p-4 mb-4 text-white">
						<h5 className="mb-4">History</h5>
						<div className="product-history-list">
							<div className="product-history">
								<div>
									<BsClipboardCheck /> Inspected by Umar Uzman
								</div>
								<div>2023-05-12</div>
							</div>
							<div className="product-history">
								<div>
									<GiHandTruck /> Collected by Umar Uzman
								</div>
								<div>2023-05-13</div>
							</div>
							<div className="product-history">
								<div>
									<FaTools /> Repaired by Ben Tennyson
								</div>
								<div>2023-05-14</div>
							</div>
							<div className="product-history">
								<div>
									<FaShippingFast /> Product Shipped
								</div>
								<div>2023-05-15</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductsView;
