// All countries
// length 252
export const allCountriesList = [
    {name: "Afghanistan",code: "AF",symbol: "؋",currency: "AFN",alpha_3: "AFG"},
    {name: "Aland Islands",code: "AX",symbol: "€",currency: "EUR",alpha_3: "ALA"},
    {name: "Albania",code: "AL",symbol: "Lek",currency: "ALL",alpha_3: "ALB"},
    {name: "Algeria",code: "DZ",symbol: "دج",currency: "DZD",alpha_3: "DZA"},
    {name: "American Samoa",code: "AS",symbol: "$",currency: "USD",alpha_3: "ASM"},
    {name: "Andorra",code: "AD",symbol: "€",currency: "EUR",alpha_3: "AND"},
    {name: "Angola",code: "AO",symbol: "Kz",currency: "AOA",alpha_3: "AGO"},
    {name: "Anguilla",code: "AI",symbol: "$",currency: "XCD",alpha_3: "AIA"},
    {name: "Antarctica",code: "AQ",symbol: "$",currency: "AAD",alpha_3: "ATA"},
    {name: "Antigua and Barbuda",code: "AG",symbol: "$",currency: "XCD",alpha_3: "ATG"},
    {name: "Argentina",code: "AR",symbol: "$",currency: "ARS",alpha_3: "ARG"},
    {name: "Armenia",code: "AM",symbol: "֏",currency: "AMD",alpha_3: "ARM"},
    {name: "Aruba",code: "AW",symbol: "ƒ",currency: "AWG",alpha_3: "ABW"},
    {name: "Australia",code: "AU",symbol: "$",currency: "AUD",alpha_3: "AUS"},
    {name: "Austria",code: "AT",symbol: "€",currency: "EUR",alpha_3: "AUT"},
    {name: "Azerbaijan",code: "AZ",symbol: "m",currency: "AZN",alpha_3: "AZE"},
    {name: "Bahamas",code: "BS",symbol: "B$",currency: "BSD",alpha_3: "BHS"},
    {name: "Bahrain",code: "BH",symbol: ".د.ب",currency: "BHD",alpha_3: "BHR"},
    {name: "Bangladesh",code: "BD",symbol: "৳",currency: "BDT",alpha_3: "BGD"},
    {name: "Barbados",code: "BB",symbol: "Bds$",currency: "BBD",alpha_3: "BRB"},
    {name: "Belarus",code: "BY",symbol: "Br",currency: "BYN",alpha_3: "BLR"},
    {name: "Belgium",code: "BE",symbol: "€",currency: "EUR",alpha_3: "BEL"},
    {name: "Belize",code: "BZ",symbol: "$",currency: "BZD",alpha_3: "BLZ"},
    {name: "Benin",code: "BJ",symbol: "CFA",currency: "XOF",alpha_3: "BEN"},
    {name: "Bermuda",code: "BM",symbol: "$",currency: "BMD",alpha_3: "BMU"},
    {name: "Bhutan",code: "BT",symbol: "Nu.",currency: "BTN",alpha_3: "BTN"},
    {name: "Bolivia",code: "BO",symbol: "Bs.",currency: "BOB",alpha_3: "BOL"},
    {name: "Bonaire, Sint Eustatius and Saba",code: "BQ",symbol: "$",currency: "USD",alpha_3: "BES"},
    {name: "Bosnia and Herzegovina",code: "BA",symbol: "KM",currency: "BAM",alpha_3: "BIH"},
    {name: "Botswana",code: "BW",symbol: "P",currency: "BWP",alpha_3: "BWA"},
    {name: "Bouvet Island",code: "BV",symbol: "kr",currency: "NOK",alpha_3: "BVT"},
    {name: "Brazil",code: "BR",symbol: "R$",currency: "BRL",alpha_3: "BRA"},
    {name: "British Indian Ocean Territory",code: "IO",symbol: "$",currency: "USD",alpha_3: "IOT"},
    {name: "Brunei Darussalam",code: "BN",symbol: "B$",currency: "BND",alpha_3: "BRN"},
    {name: "Bulgaria",code: "BG",symbol: "Лв.",currency: "BGN",alpha_3: "BGR"},
    {name: "Burkina Faso",code: "BF",symbol: "CFA",currency: "XOF",alpha_3: "BFA"},
    {name: "Burundi",code: "BI",symbol: "FBu",currency: "BIF",alpha_3: "BDI"},
    {name: "Cambodia",code: "KH",symbol: "KHR",currency: "KHR",alpha_3: "KHM"},
    {name: "Cameroon",code: "CM",symbol: "FCFA",currency: "XAF",alpha_3: "CMR"},
    {name: "Canada",code: "CA",symbol: "$",currency: "CAD",alpha_3: "CAN"},
    {name: "Cape Verde",code: "CV",symbol: "$",currency: "CVE",alpha_3: "CPV"},
    {name: "Cayman Islands",code: "KY",symbol: "$",currency: "KYD",alpha_3: "CYM"},
    {name: "Central African Republic",code: "CF",symbol: "FCFA",currency: "XAF",alpha_3: "CAF"},
    {name: "Chad",code: "TD",symbol: "FCFA",currency: "XAF",alpha_3: "TCD"},
    {name: "Chile",code: "CL",symbol: "$",currency: "CLP",alpha_3: "CHL"},
    {name: "China",code: "CN",symbol: "¥",currency: "CNY",alpha_3: "CHN"},
    {name: "Christmas Island",code: "CX",symbol: "$",currency: "AUD",alpha_3: "CXR"},
    {name: "Cocos (Keeling) Islands",code: "CC",symbol: "$",currency: "AUD",alpha_3: "CCK"},
    {name: "Colombia",code: "CO",symbol: "$",currency: "COP",alpha_3: "COL"},
    {name: "Comoros",code: "KM",symbol: "CF",currency: "KMF",alpha_3: "COM"},
    {name: "Congo",code: "CG",symbol: "FC",currency: "XAF",alpha_3: "COG"},
    {name: "Congo, Democratic Republic of the Congo",code: "CD",symbol: "FC",currency: "CDF",alpha_3: "COD"},
    {name: "Cook Islands",code: "CK",symbol: "$",currency: "NZD",alpha_3: "COK"},
    {name: "Costa Rica",code: "CR",symbol: "₡",currency: "CRC",alpha_3: "CRI"},
    {name: "Cote D'Ivoire",code: "CI",symbol: "CFA",currency: "XOF",alpha_3: "CIV"},
    {name: "Croatia",code: "HR",symbol: "kn",currency: "HRK",alpha_3: "HRV"},
    {name: "Cuba",code: "CU",symbol: "$",currency: "CUP",alpha_3: "CUB"},
    {name: "Curacao",code: "CW",symbol: "ƒ",currency: "ANG",alpha_3: "CUW"},
    {name: "Cyprus",code: "CY",symbol: "€",currency: "EUR",alpha_3: "CYP"},
    {name: "Czech Republic",code: "CZ",symbol: "Kč",currency: "CZK",alpha_3: "CZE"},
    {name: "Denmark",code: "DK",symbol: "Kr.",currency: "DKK",alpha_3: "DNK"},
    {name: "Djibouti",code: "DJ",symbol: "Fdj",currency: "DJF",alpha_3: "DJI"},
    {name: "Dominica",code: "DM",symbol: "$",currency: "XCD",alpha_3: "DMA"},
    {name: "Dominican Republic",code: "DO",symbol: "$",currency: "DOP",alpha_3: "DOM"},
    {name: "Ecuador",code: "EC",symbol: "$",currency: "USD",alpha_3: "ECU"},
    {name: "Egypt",code: "EG",symbol: "ج.م",currency: "EGP",alpha_3: "EGY"},
    {name: "El Salvador",code: "SV",symbol: "$",currency: "USD",alpha_3: "SLV"},
    {name: "Equatorial Guinea",code: "GQ",symbol: "FCFA",currency: "XAF",alpha_3: "GNQ"},
    {name: "Eritrea",code: "ER",symbol: "Nfk",currency: "ERN",alpha_3: "ERI"},
    {name: "Estonia",code: "EE",symbol: "€",currency: "EUR",alpha_3: "EST"},
    {name: "Ethiopia",code: "ET",symbol: "Nkf",currency: "ETB",alpha_3: "ETH"},
    {name: "Falkland Islands (Malvinas)",code: "FK",symbol: "£",currency: "FKP",alpha_3: "FLK"},
    {name: "Faroe Islands",code: "FO",symbol: "Kr.",currency: "DKK",alpha_3: "FRO"},
    {name: "Fiji",code: "FJ",symbol: "FJ$",currency: "FJD",alpha_3: "FJI"},
    {name: "Finland",code: "FI",symbol: "€",currency: "EUR",alpha_3: "FIN"},
    {name: "France",code: "FR",symbol: "€",currency: "EUR",alpha_3: "FRA"},
    {name: "French Guiana",code: "GF",symbol: "€",currency: "EUR",alpha_3: "GUF"},
    {name: "French Polynesia",code: "PF",symbol: "₣",currency: "XPF",alpha_3: "PYF"},
    {name: "French Southern Territories",code: "TF",symbol: "€",currency: "EUR",alpha_3: "ATF"},
    {name: "Gabon",code: "GA",symbol: "FCFA",currency: "XAF",alpha_3: "GAB"},
    {name: "Gambia",code: "GM",symbol: "D",currency: "GMD",alpha_3: "GMB"},
    {name: "Georgia",code: "GE",symbol: "ლ",currency: "GEL",alpha_3: "GEO"},
    {name: "Germany",code: "DE",symbol: "€",currency: "EUR",alpha_3: "DEU"},
    {name: "Ghana",code: "GH",symbol: "GH₵",currency: "GHS",alpha_3: "GHA"},
    {name: "Gibraltar",code: "GI",symbol: "£",currency: "GIP",alpha_3: "GIB"},
    {name: "Greece",code: "GR",symbol: "€",currency: "EUR",alpha_3: "GRC"},
    {name: "Greenland",code: "GL",symbol: "Kr.",currency: "DKK",alpha_3: "GRL"},
    {name: "Grenada",code: "GD",symbol: "$",currency: "XCD",alpha_3: "GRD"},
    {name: "Guadeloupe",code: "GP",symbol: "€",currency: "EUR",alpha_3: "GLP"},
    {name: "Guam",code: "GU",symbol: "$",currency: "USD",alpha_3: "GUM"},
    {name: "Guatemala",code: "GT",symbol: "Q",currency: "GTQ",alpha_3: "GTM"},
    {name: "Guernsey",code: "GG",symbol: "£",currency: "GBP",alpha_3: "GGY"},
    {name: "Guinea",code: "GN",symbol: "FG",currency: "GNF",alpha_3: "GIN"},
    {name: "Guinea-Bissau",code: "GW",symbol: "CFA",currency: "XOF",alpha_3: "GNB"},
    {name: "Guyana",code: "GY",symbol: "$",currency: "GYD",alpha_3: "GUY"},
    {name: "Haiti",code: "HT",symbol: "G",currency: "HTG",alpha_3: "HTI"},
    {name: "Heard Island and McDonald Islands",code: "HM",symbol: "$",currency: "AUD",alpha_3: "HMD"},
    {name: "Holy See (Vatican City State)",code: "VA",symbol: "€",currency: "EUR",alpha_3: "VAT"},
    {name: "Honduras",code: "HN",symbol: "L",currency: "HNL",alpha_3: "HND"},
    {name: "Hong Kong",code: "HK",symbol: "$",currency: "HKD",alpha_3: "HKG"},
    {name: "Hungary",code: "HU",symbol: "Ft",currency: "HUF",alpha_3: "HUN"},
    {name: "Iceland",code: "IS",symbol: "kr",currency: "ISK",alpha_3: "ISL"},
    {name: "India",code: "IN",symbol: "₹",currency: "INR",alpha_3: "IND"},
    {name: "Indonesia",code: "ID",symbol: "Rp",currency: "IDR",alpha_3: "IDN"},
    {name: "Iran, Islamic Republic of",code: "IR",symbol: "﷼",currency: "IRR",alpha_3: "IRN"},
    {name: "Iraq",code: "IQ",symbol: "د.ع",currency: "IQD",alpha_3: "IRQ"},
    {name: "Ireland",code: "IE",symbol: "€",currency: "EUR",alpha_3: "IRL"},
    {name: "Isle of Man",code: "IM",symbol: "£",currency: "GBP",alpha_3: "IMN"},
    {name: "Israel",code: "IL",symbol: "₪",currency: "ILS",alpha_3: "ISR"},
    {name: "Italy",code: "IT",symbol: "€",currency: "EUR",alpha_3: "ITA"},
    {name: "Jamaica",code: "JM",symbol: "J$",currency: "JMD",alpha_3: "JAM"},
    {name: "Japan",code: "JP",symbol: "¥",currency: "JPY",alpha_3: "JPN"},
    {name: "Jersey",code: "JE",symbol: "£",currency: "GBP",alpha_3: "JEY"},
    {name: "Jordan",code: "JO",symbol: "ا.د",currency: "JOD",alpha_3: "JOR"},
    {name: "Kazakhstan",code: "KZ",symbol: "лв",currency: "KZT",alpha_3: "KAZ"},
    {name: "Kenya",code: "KE",symbol: "KSh",currency: "KES",alpha_3: "KEN"},
    {name: "Kiribati",code: "KI",symbol: "$",currency: "AUD",alpha_3: "KIR"},
    {name: "Korea, Democratic People's Republic of",code: "KP",symbol: "₩",currency: "KPW",alpha_3: "PRK"},
    {name: "Korea, Republic of",code: "KR",symbol: "₩",currency: "KRW",alpha_3: "KOR"},
    {name: "Kosovo",code: "XK",symbol: "€",currency: "EUR",alpha_3: "XKX"},
    {name: "Kuwait",code: "KW",symbol: "ك.د",currency: "KWD",alpha_3: "KWT"},
    {name: "Kyrgyzstan",code: "KG",symbol: "лв",currency: "KGS",alpha_3: "KGZ"},
    {name: "Lao People's Democratic Republic",code: "LA",symbol: "₭",currency: "LAK",alpha_3: "LAO"},
    {name: "Latvia",code: "LV",symbol: "€",currency: "EUR",alpha_3: "LVA"},
    {name: "Lebanon",code: "LB",symbol: "£",currency: "LBP",alpha_3: "LBN"},
    {name: "Lesotho",code: "LS",symbol: "L",currency: "LSL",alpha_3: "LSO"},
    {name: "Liberia",code: "LR",symbol: "$",currency: "LRD",alpha_3: "LBR"},
    {name: "Libyan Arab Jamahiriya",code: "LY",symbol: "د.ل",currency: "LYD",alpha_3: "LBY"},
    {name: "Liechtenstein",code: "LI",symbol: "CHf",currency: "CHF",alpha_3: "LIE"},
    {name: "Lithuania",code: "LT",symbol: "€",currency: "EUR",alpha_3: "LTU"},
    {name: "Luxembourg",code: "LU",symbol: "€",currency: "EUR",alpha_3: "LUX"},
    {name: "Macao",code: "MO",symbol: "$",currency: "MOP",alpha_3: "MAC"},
    {name: "Macedonia, the Former Yugoslav Republic of",code: "MK",symbol: "ден",currency: "MKD",alpha_3: "MKD"},
    {name: "Madagascar",code: "MG",symbol: "Ar",currency: "MGA",alpha_3: "MDG"},
    {name: "Malawi",code: "MW",symbol: "MK",currency: "MWK",alpha_3: "MWI"},
    {name: "Malaysia",code: "MY",symbol: "RM",currency: "MYR",alpha_3: "MYS"},
    {name: "Maldives",code: "MV",symbol: "Rf",currency: "MVR",alpha_3: "MDV"},
    {name: "Mali",code: "ML",symbol: "CFA",currency: "XOF",alpha_3: "MLI"},
    {name: "Malta",code: "MT",symbol: "€",currency: "EUR",alpha_3: "MLT"},
    {name: "Marshall Islands",code: "MH",symbol: "$",currency: "USD",alpha_3: "MHL"},
    {name: "Martinique",code: "MQ",symbol: "€",currency: "EUR",alpha_3: "MTQ"},
    {name: "Mauritania",code: "MR",symbol: "MRU",currency: "MRO",alpha_3: "MRT"},
    {name: "Mauritius",code: "MU",symbol: "₨",currency: "MUR",alpha_3: "MUS"},
    {name: "Mayotte",code: "YT",symbol: "€",currency: "EUR",alpha_3: "MYT"},
    {name: "Mexico",code: "MX",symbol: "$",currency: "MXN",alpha_3: "MEX"},
    {name: "Micronesia, Federated States of",code: "FM",symbol: "$",currency: "USD",alpha_3: "FSM"},
    {name: "Moldova, Republic of",code: "MD",symbol: "L",currency: "MDL",alpha_3: "MDA"},
    {name: "Monaco",code: "MC",symbol: "€",currency: "EUR",alpha_3: "MCO"},
    {name: "Mongolia",code: "MN",symbol: "₮",currency: "MNT",alpha_3: "MNG"},
    {name: "Montenegro",code: "ME",symbol: "€",currency: "EUR",alpha_3: "MNE"},
    {name: "Montserrat",code: "MS",symbol: "$",currency: "XCD",alpha_3: "MSR"},
    {name: "Morocco",code: "MA",symbol: "DH",currency: "MAD",alpha_3: "MAR"},
    {name: "Mozambique",code: "MZ",symbol: "MT",currency: "MZN",alpha_3: "MOZ"},
    {name: "Myanmar",code: "MM",symbol: "K",currency: "MMK",alpha_3: "MMR"},
    {name: "Namibia",code: "NA",symbol: "$",currency: "NAD",alpha_3: "NAM"},
    {name: "Nauru",code: "NR",symbol: "$",currency: "AUD",alpha_3: "NRU"},
    {name: "Nepal",code: "NP",symbol: "₨",currency: "NPR",alpha_3: "NPL"},
    {name: "Netherlands",code: "NL",symbol: "€",currency: "EUR",alpha_3: "NLD"},
    {name: "Netherlands Antilles",code: "AN",symbol: "NAf",currency: "ANG",alpha_3: "ANT"},
    {name: "New Caledonia",code: "NC",symbol: "₣",currency: "XPF",alpha_3: "NCL"},
    {name: "New Zealand",code: "NZ",symbol: "$",currency: "NZD",alpha_3: "NZL"},
    {name: "Nicaragua",code: "NI",symbol: "C$",currency: "NIO",alpha_3: "NIC"},
    {name: "Niger",code: "NE",symbol: "CFA",currency: "XOF",alpha_3: "NER"},
    {name: "Nigeria",code: "NG",symbol: "₦",currency: "NGN",alpha_3: "NGA"},
    {name: "Niue",code: "NU",symbol: "$",currency: "NZD",alpha_3: "NIU"},
    {name: "Norfolk Island",code: "NF",symbol: "$",currency: "AUD",alpha_3: "NFK"},
    {name: "Northern Mariana Islands",code: "MP",symbol: "$",currency: "USD",alpha_3: "MNP"},
    {name: "Norway",code: "NO",symbol: "kr",currency: "NOK",alpha_3: "NOR"},
    {name: "Oman",code: "OM",symbol: ".ع.ر",currency: "OMR",alpha_3: "OMN"},
    {name: "Pakistan",code: "PK",symbol: "₨",currency: "PKR",alpha_3: "PAK"},
    {name: "Palau",code: "PW",symbol: "$",currency: "USD",alpha_3: "PLW"},
    {name: "Palestinian Territory, Occupied",code: "PS",symbol: "₪",currency: "ILS",alpha_3: "PSE"},
    {name: "Panama",code: "PA",symbol: "B/.",currency: "PAB",alpha_3: "PAN"},
    {name: "Papua New Guinea",code: "PG",symbol: "K",currency: "PGK",alpha_3: "PNG"},
    {name: "Paraguay",code: "PY",symbol: "₲",currency: "PYG",alpha_3: "PRY"},
    {name: "Peru",code: "PE",symbol: "S/.",currency: "PEN",alpha_3: "PER"},
    {name: "Philippines",code: "PH",symbol: "₱",currency: "PHP",alpha_3: "PHL"},
    {name: "Pitcairn",code: "PN",symbol: "$",currency: "NZD",alpha_3: "PCN"},
    {name: "Poland",code: "PL",symbol: "zł",currency: "PLN",alpha_3: "POL"},
    {name: "Portugal",code: "PT",symbol: "€",currency: "EUR",alpha_3: "PRT"},
    {name: "Puerto Rico",code: "PR",symbol: "$",currency: "USD",alpha_3: "PRI"},
    {name: "Qatar",code: "QA",symbol: "ق.ر",currency: "QAR",alpha_3: "QAT"},
    {name: "Reunion",code: "RE",symbol: "€",currency: "EUR",alpha_3: "REU"},
    {name: "Romania",code: "RO",symbol: "lei",currency: "RON",alpha_3: "ROM"},
    {name: "Russian Federation",code: "RU",symbol: "₽",currency: "RUB",alpha_3: "RUS"},
    {name: "Rwanda",code: "RW",symbol: "FRw",currency: "RWF",alpha_3: "RWA"},
    {name: "Saint Barthelemy",code: "BL",symbol: "€",currency: "EUR",alpha_3: "BLM"},
    {name: "Saint Helena",code: "SH",symbol: "£",currency: "SHP",alpha_3: "SHN"},
    {name: "Saint Kitts and Nevis",code: "KN",symbol: "$",currency: "XCD",alpha_3: "KNA"},
    {name: "Saint Lucia",code: "LC",symbol: "$",currency: "XCD",alpha_3: "LCA"},
    {name: "Saint Martin",code: "MF",symbol: "€",currency: "EUR",alpha_3: "MAF"},
    {name: "Saint Pierre and Miquelon",code: "PM",symbol: "€",currency: "EUR",alpha_3: "SPM"},
    {name: "Saint Vincent and the Grenadines",code: "VC",symbol: "$",currency: "XCD",alpha_3: "VCT"},
    {name: "Samoa",code: "WS",symbol: "SAT",currency: "WST",alpha_3: "WSM"},
    {name: "San Marino",code: "SM",symbol: "€",currency: "EUR",alpha_3: "SMR"},
    {name: "Sao Tome and Principe",code: "ST",symbol: "Db",currency: "STD",alpha_3: "STP"},
    {name: "Saudi Arabia",code: "SA",symbol: "﷼",currency: "SAR",alpha_3: "SAU"},
    {name: "Senegal",code: "SN",symbol: "CFA",currency: "XOF",alpha_3: "SEN"},
    {name: "Serbia",code: "RS",symbol: "din",currency: "RSD",alpha_3: "SRB"},
    {name: "Serbia and Montenegro",code: "CS",symbol: "din",currency: "RSD",alpha_3: "SCG"},
    {name: "Seychelles",code: "SC",symbol: "SRe",currency: "SCR",alpha_3: "SYC"},
    {name: "Sierra Leone",code: "SL",symbol: "Le",currency: "SLL",alpha_3: "SLE"},
    {name: "Singapore",code: "SG",symbol: "$",currency: "SGD",alpha_3: "SGP"},
    {name: "St Martin",code: "SX",symbol: "ƒ",currency: "ANG",alpha_3: "SXM"},
    {name: "Slovakia",code: "SK",symbol: "€",currency: "EUR",alpha_3: "SVK"},
    {name: "Slovenia",code: "SI",symbol: "€",currency: "EUR",alpha_3: "SVN"},
    {name: "Solomon Islands",code: "SB",symbol: "Si$",currency: "SBD",alpha_3: "SLB"},
    {name: "Somalia",code: "SO",symbol: "Sh.so.",currency: "SOS",alpha_3: "SOM"},
    {name: "South Africa",code: "ZA",symbol: "R",currency: "ZAR",alpha_3: "ZAF"},
    {name: "South Georgia and the South Sandwich Islands",code: "GS",symbol: "£",currency: "GBP",alpha_3: "SGS"},
    {name: "South Sudan",code: "SS",symbol: "£",currency: "SSP",alpha_3: "SSD"},
    {name: "Spain",code: "ES",symbol: "€",currency: "EUR",alpha_3: "ESP"},
    {name: "Sri Lanka",code: "LK",symbol: "Rs",currency: "LKR",alpha_3: "LKA"},
    {name: "Sudan",code: "SD",symbol: ".س.ج",currency: "SDG",alpha_3: "SDN"},
    {name: "Suriname",code: "SR",symbol: "$",currency: "SRD",alpha_3: "SUR"},
    {name: "Svalbard and Jan Mayen",code: "SJ",symbol: "kr",currency: "NOK",alpha_3: "SJM"},
    {name: "Swaziland",code: "SZ",symbol: "E",currency: "SZL",alpha_3: "SWZ"},
    {name: "Sweden",code: "SE",symbol: "kr",currency: "SEK",alpha_3: "SWE"},
    {name: "Switzerland",code: "CH",symbol: "CHf",currency: "CHF",alpha_3: "CHE"},
    {name: "Syrian Arab Republic",code: "SY",symbol: "LS",currency: "SYP",alpha_3: "SYR"},
    {name: "Taiwan, Province of China",code: "TW",symbol: "$",currency: "TWD",alpha_3: "TWN"},
    {name: "Tajikistan",code: "TJ",symbol: "SM",currency: "TJS",alpha_3: "TJK"},
    {name: "Tanzania, United Republic of",code: "TZ",symbol: "TSh",currency: "TZS",alpha_3: "TZA"},
    {name: "Thailand",code: "TH",symbol: "฿",currency: "THB",alpha_3: "THA"},
    {name: "Timor-Leste",code: "TL",symbol: "$",currency: "USD",alpha_3: "TLS"},
    {name: "Togo",code: "TG",symbol: "CFA",currency: "XOF",alpha_3: "TGO"},
    {name: "Tokelau",code: "TK",symbol: "$",currency: "NZD",alpha_3: "TKL"},
    {name: "Tonga",code: "TO",symbol: "$",currency: "TOP",alpha_3: "TON"},
    {name: "Trinidad and Tobago",code: "TT",symbol: "$",currency: "TTD",alpha_3: "TTO"},
    {name: "Tunisia",code: "TN",symbol: "ت.د",currency: "TND",alpha_3: "TUN"},
    {name: "Turkey",code: "TR",symbol: "₺",currency: "TRY",alpha_3: "TUR"},
    {name: "Turkmenistan",code: "TM",symbol: "T",currency: "TMT",alpha_3: "TKM"},
    {name: "Turks and Caicos Islands",code: "TC",symbol: "$",currency: "USD",alpha_3: "TCA"},
    {name: "Tuvalu",code: "TV",symbol: "$",currency: "AUD",alpha_3: "TUV"},
    {name: "Uganda",code: "UG",symbol: "USh",currency: "UGX",alpha_3: "UGA"},
    {name: "Ukraine",code: "UA",symbol: "₴",currency: "UAH",alpha_3: "UKR"},
    {name: "United Arab Emirates",code: "AE",symbol: "إ.د",currency: "AED",alpha_3: "ARE"},
    {name: "United Kingdom",code: "GB",symbol: "£",currency: "GBP",alpha_3: "GBR"},
    {name: "United States",code: "US",symbol: "$",currency: "USD",alpha_3: "USA"},
    {name: "United States Minor Outlying Islands",code: "UM",symbol: "$",currency: "USD",alpha_3: "UMI"},
    {name: "Uruguay",code: "UY",symbol: "$",currency: "UYU",alpha_3: "URY"},
    {name: "Uzbekistan",code: "UZ",symbol: "лв",currency: "UZS",alpha_3: "UZB"},
    {name: "Vanuatu",code: "VU",symbol: "VT",currency: "VUV",alpha_3: "VUT"},
    {name: "Venezuela",code: "VE",symbol: "Bs",currency: "VEF",alpha_3: "VEN"},
    {name: "Viet Nam",code: "VN",symbol: "₫",currency: "VND",alpha_3: "VNM"},
    {name: "Virgin Islands, British",code: "VG",symbol: "$",currency: "USD",alpha_3: "VGB"},
    {name: "Virgin Islands, U.s.",code: "VI",symbol: "$",currency: "USD",alpha_3: "VIR"},
    {name: "Wallis and Futuna",code: "WF",symbol: "₣",currency: "XPF",alpha_3: "WLF"},
    {name: "Western Sahara",code: "EH",symbol: "MAD",currency: "MAD",alpha_3: "ESH"},
    {name: "Yemen",code: "YE",symbol: "﷼",currency: "YER",alpha_3: "YEM"},
    {name: "Zambia",code: "ZM",symbol: "ZK",currency: "ZMW",alpha_3: "ZMB"},
    {name: "Zimbabwe",code: "ZW",symbol: "$",currency: "ZWL",alpha_3: "ZWE"}
];