import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ScanIcon, RepairsIcon, QualityControlIcon2 } from 'ui-component/custom-icons/icons';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';


const Breadcrumbs = ({ current, activateTabsUsingId, referenceNumber, isCompleted = false }) => {

    const history = useNavigate();

    return (
        <>
            
            <nav>
                <ol class="cd-breadcrumb custom-separator" style={{ padding: '0', margin: '0', background: 'transparent' }}>
                    {
                        referenceNumber && (
                            <>
                                <li style={{ margin: '0' }}>
                                    <a style={{ textDecoration: 'none' }}>
                                        {referenceNumber}
                                    </a>
                                </li>
                            </>
                        )
                    }
                    <PermissionAllowed 
                        permissions={[
                            'Create Operations',
                            'View Operations Details',
                        ]}
                    >
                        <li style={{ margin: '0' }}>
                            <a
                                style={{ textDecoration: 'none' }}
                                onClick={() => {
                                    if (!isCompleted) {
                                        if (activateTabsUsingId) {
                                            history(`/operations/edit/${activateTabsUsingId}/inbound`);
                                        }
                                    }
                                    // history(`/operations/view/${activateTabsUsingId}/inbound`);
                                }}
                            >
                                <button
                                    className={`btn btn-primary ${(current === 'inbound') ? 'btn-outlined-main-primary' : 'btn-outlined-main'} white-var ${isCompleted ? 'disabled' : ''}`}
                                >
                                    <ScanIcon height={'15px'} width={'15px'} />
                                    Inbounding
                                </button>
                            </a>
                        </li>
                    </PermissionAllowed>
                    <PermissionAllowed 
						permissions={[
							'List Quality Control',
							'View Operations Details',
						]}
                        hasSome={true}
                    >
                        <li style={{ margin: '0' }}>
                            <a
                                style={{ textDecoration: 'none' }}
                                onClick={() => {
                                    history(`/operations/view/${activateTabsUsingId}/qualitycontrol`);
                                }}
                            >
                                <button
                                    disabled={activateTabsUsingId ? "" : "true"}
                                    className={`btn btn-primary ${(current === 'qualitycontrol') ? 'btn-outlined-main-primary' : 'btn-outlined-main'} white-var`}
                                >
                                    <QualityControlIcon2 height={'15px'} width={'15px'} />
                                    Quality Control
                                </button>
                            </a>
                        </li>
                    </PermissionAllowed>
                    <PermissionAllowed 
						permissions={[
							'List Operation Repairs',
						]}
                        hasSome={true}
                    >
                        <li style={{ margin: '0' }}>
                            <a
                                style={{ textDecoration: 'none' }}
                                onClick={() => {
                                    history(`/operations/view/${activateTabsUsingId}/repairs`);
                                }}
                            >
                                <button
                                    disabled={activateTabsUsingId ? "" : "true"}
                                    className={`btn btn-primary ${(current === 'repairs') ? 'btn-outlined-main-primary' : 'btn-outlined-main'} white-var`}
                                >
                                    <RepairsIcon height={'18px'} width={'18px'} />
                                    Repairs
                                </button>
                            </a>
                        </li>
                    </PermissionAllowed>
                </ol>
            </nav>
        </>
    );
};

export default Breadcrumbs;
