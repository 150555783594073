import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconOpen from 'assets/images/icons/back-2.svg';
import IconClose from 'assets/images/icons/menu-toggle.png';
import IconProfile from 'assets/images/undraw_profile.svg';
import IconSearch from 'assets/images/magnifying-glass.svg';
import { setDisplayTheme, setLogout, setOpened, storeHasNewNotification, storePrivacyMode } from 'store/reducers/mainSlice';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import Alert from './CustomAlert';
import { Avatar } from '@mui/material';
import { GrNotification } from 'react-icons/gr';
import { FaBell, FaSearch, FaEye, FaEyeSlash } from 'react-icons/fa';
import CurrencyDropdown from 'ui-component/currency-dropdown/CurrencyDropdown';
import { useHotkeys } from 'react-hotkeys-hook';
import { MdClose, MdDarkMode, MdOutlineDarkMode } from 'react-icons/md';
import Logo from 'assets/images/logo-light.svg';
import LogoBlack from 'assets/images/logo-dark.svg';
import Logo1 from 'assets/images/project-mix-icon.png';
import NotificationDropdown from 'ui-component/NotificationDropdown/NotificationDropdown';

import Echo from 'laravel-echo';
import toast from 'react-hot-toast';
import NotificationTextParser from 'ui-component/NotificationTextParser';
import config from '../../../../config';
import { notificationTextToPlainText } from 'helpers/common.helper';
import { BiSolidBell } from 'react-icons/bi';
import { ImSearch } from 'react-icons/im';
import { NotificationIcon, PrivacyModeHideIcon, PrivacyModeIcon } from 'ui-component/custom-icons/icons';
import { IoIosArrowDown } from 'react-icons/io';
import { VscBell } from 'react-icons/vsc';
import MaterialUISwitch from './MUISwitch';
import { IoSearchOutline, IoSettingsOutline } from 'react-icons/io5';
import { HiOutlineExclamationCircle } from 'react-icons/hi2';
import { GoVerified } from 'react-icons/go';
import { FaRegCheckCircle } from 'react-icons/fa';
import api from 'api/api';

window.Pusher = require('pusher-js');

const NavBar = ({ onSearchToggle }) => {
	const clientUser = useSelector((state) => state.main.client);

	const [displayTheme] = useSelector((state) => [state.main.displayTheme]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	const history = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleClickOutside = (event) => {};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	useEffect(() => {
		document.title = 'Projectmix';
	}, []);

	if (isMobile) {
		return (
			<nav
				id="mainNav"
				className="navbar-expand navbar-light topbar m-0 static-top d-flex justify-content-between"
				style={{ paddingLeft: 15, paddingRight: 5 }}
			>
				<>
					<div id="nav-page-title" className="ml-3 client-dashboard-titile">
						<h5>{clientUser?.data?.company_name}</h5>{' '}
						{clientUser?.data?.is_approved !== 2 ? (
							<button
								className={`btn btn-primary d-flex align-items-center ${
									clientUser?.data?.is_approved === 1 ? 'btn-not-verified' : 'btn-not-verified-incomplete'
								}`}
								style={{ pointerEvents: 'none' }}
							>
								<svg height="20" viewBox="0 0 93.5 93.5" width="20" xmlns="http://www.w3.org/2000/svg" id="fi_3889548" fill="#ffba49">
									<g id="Layer_5" data-name="Layer 5">
										<circle cx="46.95" cy="51.4" r="1.25"></circle>
										<circle cx="46.95" cy="56.34" r="1.25"></circle>
										<path d="m55.72 43.81 5.4-4.93 2.59-2.35c4.68-4.22 7-9.94 7.09-17.51v-6.36h3.92a1.25 1.25 0 0 0 1.28-1.25v-5.41a1.25 1.25 0 0 0 -1.25-1.25h-55.58a1.25 1.25 0 0 0 -1.25 1.25v5.41a1.25 1.25 0 0 0 1.25 1.25h3.92v6.34c.09 7.55 2.42 13.27 7.1 17.48l3 2.74 5 4.56a4.57 4.57 0 0 1 1.81 3.31 4.76 4.76 0 0 1 -1.93 3.39l-7.9 7.22c-4.68 4.21-7 9.93-7.1 17.5v5.52h-3.9a1.25 1.25 0 0 0 -1.25 1.28v5.41a1.25 1.25 0 0 0 1.25 1.25h55.55a1.25 1.25 0 0 0 1.28-1.28v-5.38a1.25 1.25 0 0 0 -1.25-1.25h-3.95v-5.57c-.09-7.55-2.41-13.27-7.09-17.48l-7.91-7.23a4.71 4.71 0 0 1 -1.91-3.36 4.61 4.61 0 0 1 1.83-3.3zm-4.33 3.31a7.07 7.07 0 0 0 2.73 5.21l7.88 7.22c4.2 3.78 6.19 8.76 6.27 15.65v5.52h-42.68v-5.51c.09-6.9 2.08-11.88 6.28-15.66l7.9-7.21a7.13 7.13 0 0 0 2.75-5.25 6.88 6.88 0 0 0 -2.66-5.09l-5-4.56-3-2.74c-4.19-3.78-6.18-8.76-6.27-15.65v-6.39h42.71v6.34c-.08 6.9-2.07 11.88-6.27 15.66-.87.78-1.73 1.57-2.6 2.37l-5.43 4.97a7 7 0 0 0 -2.61 5.12z"></path>
										<path d="m63.41 29a1.24 1.24 0 0 0 -.18-1.48 1.22 1.22 0 0 0 -1.47-.25c-4.93 2.51-6.78 3.45-14.43 1a20 20 0 0 0 -14.88 1 1.25 1.25 0 0 0 -.45 1.9c.48.57 11.36 11 11.46 11a5.78 5.78 0 0 1 1.4 3.31 2 2 0 0 0 2.14 1.74 1.83 1.83 0 0 0 2-1.66 6.42 6.42 0 0 1 2.2-4.11c.17-.15.87-.82 1.82-1.73 2.34-2.24 6.24-6 7.31-6.94a15.26 15.26 0 0 0 3.08-3.78z"></path>
										<path d="m29.73 76.72a1.25 1.25 0 0 0 1.27 1.28h31.91a1.25 1.25 0 0 0 1.25-1.25 17.22 17.22 0 0 0 -34.43 0z"></path>
									</g>
								</svg>
							</button>
						) : (
							<button
								className={`btn btn-primary d-flex align-items-center btn-verified`}
								style={{ pointerEvents: 'none', paddingRight: 5, marginLeft: '-7px', marginTop: 1 }}
							>
								<svg height="22" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg" id="fi_7641727">
									<g id="Layer_2" data-name="Layer 2">
										<path
											d="m21.187 10.007a3.457 3.457 0 0 1 -.864-.712 3.378 3.378 0 0 1 .277-1.141c.291-.821.62-1.751.092-2.474s-1.525-.7-2.4-.68a3.422 3.422 0 0 1 -1.155-.078 3.369 3.369 0 0 1 -.425-1.063c-.248-.845-.531-1.8-1.4-2.086-.838-.27-1.614.324-2.3.846a3.285 3.285 0 0 1 -1.012.631 3.285 3.285 0 0 1 -1.023-.631c-.684-.519-1.457-1.119-2.299-.845-.867.282-1.15 1.24-1.4 2.085a3.418 3.418 0 0 1 -.421 1.061 3.482 3.482 0 0 1 -1.157.08c-.878-.024-1.867-.05-2.4.68s-.2 1.653.092 2.473a3.336 3.336 0 0 1 .281 1.141 3.449 3.449 0 0 1 -.863.713c-.732.5-1.563 1.069-1.563 1.993s.831 1.491 1.563 1.993a3.449 3.449 0 0 1 .863.712 3.335 3.335 0 0 1 -.273 1.142c-.29.82-.618 1.75-.091 2.473s1.521.7 2.4.68a3.426 3.426 0 0 1 1.156.078 3.4 3.4 0 0 1 .424 1.063c.248.845.531 1.8 1.4 2.086a1.424 1.424 0 0 0 .431.068 3.382 3.382 0 0 0 1.868-.914 3.285 3.285 0 0 1 1.012-.631 3.285 3.285 0 0 1 1.023.631c.685.523 1.461 1.12 2.3.845.867-.282 1.15-1.24 1.4-2.084a3.388 3.388 0 0 1 .424-1.062 3.425 3.425 0 0 1 1.153-.08c.878.021 1.867.05 2.4-.68s.2-1.653-.092-2.474a3.38 3.38 0 0 1 -.281-1.139 3.436 3.436 0 0 1 .864-.713c.732-.5 1.563-1.07 1.563-1.994s-.834-1.492-1.567-1.993z"
											fill="#0066FF"
										></path>
										<path
											d="m11 14.75a.745.745 0 0 1 -.53-.22l-2-2a.75.75 0 0 1 1.06-1.06l1.54 1.54 3.48-2.61a.75.75 0 0 1 .9 1.2l-4 3a.751.751 0 0 1 -.45.15z"
											fill="#fff"
										></path>
									</g>
								</svg>
							</button>
						)}
					</div>
				</>

				<div className="nav-actions">
					<div style={{ position: 'relative' }}>
						<div className="nav-brand-logo">
							<Link className="sidebar-brand d-flex align-items-center justify-content-center" to={'/client/dashboard'}>
								<div className="sidebar-brand-text pr-4" onClick={() => history('/client/dashboard')}>
									<img src={`${process.env.PUBLIC_URL}/img/logo-icon.png`} alt="logo" className="img-fluid" width={40} height={'80%'} />
								</div>
							</Link>
						</div>
					</div>
				</div>
			</nav>
		);
	} else {
		return (
			<nav
				id="mainNav"
				className="navbar-expand navbar-light topbar m-0 static-top d-flex justify-content-between"
				style={{ paddingLeft: 15, paddingRight: 5 }}
			>
				{!isMobile && (
					<>
						<div id="nav-page-title" className="ml-3 client-dashboard-titile">
							<h5>{clientUser?.data?.company_name}</h5>
							{clientUser?.data?.is_approved !== 2 ? (
								<button
									className={`btn btn-primary d-flex align-items-center ${
										clientUser?.data?.is_approved === 1 ? 'btn-not-verified' : 'btn-not-verified-incomplete'
									}`}
									style={{ pointerEvents: 'none', marginLeft: '-7px', marginTop: 1 }}
								>
									{clientUser?.data?.is_approved == 1 ? (
										<svg height="16" viewBox="0 0 93.5 93.5" width="16" xmlns="http://www.w3.org/2000/svg" id="fi_3889548" fill="#F39C12">
											<g id="Layer_5" data-name="Layer 5">
												<circle cx="46.95" cy="51.4" r="1.25"></circle>
												<circle cx="46.95" cy="56.34" r="1.25"></circle>
												<path d="m55.72 43.81 5.4-4.93 2.59-2.35c4.68-4.22 7-9.94 7.09-17.51v-6.36h3.92a1.25 1.25 0 0 0 1.28-1.25v-5.41a1.25 1.25 0 0 0 -1.25-1.25h-55.58a1.25 1.25 0 0 0 -1.25 1.25v5.41a1.25 1.25 0 0 0 1.25 1.25h3.92v6.34c.09 7.55 2.42 13.27 7.1 17.48l3 2.74 5 4.56a4.57 4.57 0 0 1 1.81 3.31 4.76 4.76 0 0 1 -1.93 3.39l-7.9 7.22c-4.68 4.21-7 9.93-7.1 17.5v5.52h-3.9a1.25 1.25 0 0 0 -1.25 1.28v5.41a1.25 1.25 0 0 0 1.25 1.25h55.55a1.25 1.25 0 0 0 1.28-1.28v-5.38a1.25 1.25 0 0 0 -1.25-1.25h-3.95v-5.57c-.09-7.55-2.41-13.27-7.09-17.48l-7.91-7.23a4.71 4.71 0 0 1 -1.91-3.36 4.61 4.61 0 0 1 1.83-3.3zm-4.33 3.31a7.07 7.07 0 0 0 2.73 5.21l7.88 7.22c4.2 3.78 6.19 8.76 6.27 15.65v5.52h-42.68v-5.51c.09-6.9 2.08-11.88 6.28-15.66l7.9-7.21a7.13 7.13 0 0 0 2.75-5.25 6.88 6.88 0 0 0 -2.66-5.09l-5-4.56-3-2.74c-4.19-3.78-6.18-8.76-6.27-15.65v-6.39h42.71v6.34c-.08 6.9-2.07 11.88-6.27 15.66-.87.78-1.73 1.57-2.6 2.37l-5.43 4.97a7 7 0 0 0 -2.61 5.12z"></path>
												<path d="m63.41 29a1.24 1.24 0 0 0 -.18-1.48 1.22 1.22 0 0 0 -1.47-.25c-4.93 2.51-6.78 3.45-14.43 1a20 20 0 0 0 -14.88 1 1.25 1.25 0 0 0 -.45 1.9c.48.57 11.36 11 11.46 11a5.78 5.78 0 0 1 1.4 3.31 2 2 0 0 0 2.14 1.74 1.83 1.83 0 0 0 2-1.66 6.42 6.42 0 0 1 2.2-4.11c.17-.15.87-.82 1.82-1.73 2.34-2.24 6.24-6 7.31-6.94a15.26 15.26 0 0 0 3.08-3.78z"></path>
												<path d="m29.73 76.72a1.25 1.25 0 0 0 1.27 1.28h31.91a1.25 1.25 0 0 0 1.25-1.25 17.22 17.22 0 0 0 -34.43 0z"></path>
											</g>
										</svg>
									) : (
										<FaRegCheckCircle size={18} color={'#E74C3C'} />
									)}
									{clientUser?.data?.is_approved == 1 ? (
										<span style={{ marginLeft: 5, color: '#F39C12' }}>Waiting for approval</span>
									) : (
										<span style={{ marginLeft: 5, color: '#E74C3C' }}>Incomplete details</span>
									)}
								</button>
							) : (
								<button
									className={`btn btn-primary d-flex align-items-center btn-verified`}
									style={{ pointerEvents: 'none', paddingRight: 5, marginLeft: '-7px', marginTop: 1 }}
								>
									<svg height="22" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg" id="fi_7641727">
										<g id="Layer_2" data-name="Layer 2">
											<path
												d="m21.187 10.007a3.457 3.457 0 0 1 -.864-.712 3.378 3.378 0 0 1 .277-1.141c.291-.821.62-1.751.092-2.474s-1.525-.7-2.4-.68a3.422 3.422 0 0 1 -1.155-.078 3.369 3.369 0 0 1 -.425-1.063c-.248-.845-.531-1.8-1.4-2.086-.838-.27-1.614.324-2.3.846a3.285 3.285 0 0 1 -1.012.631 3.285 3.285 0 0 1 -1.023-.631c-.684-.519-1.457-1.119-2.299-.845-.867.282-1.15 1.24-1.4 2.085a3.418 3.418 0 0 1 -.421 1.061 3.482 3.482 0 0 1 -1.157.08c-.878-.024-1.867-.05-2.4.68s-.2 1.653.092 2.473a3.336 3.336 0 0 1 .281 1.141 3.449 3.449 0 0 1 -.863.713c-.732.5-1.563 1.069-1.563 1.993s.831 1.491 1.563 1.993a3.449 3.449 0 0 1 .863.712 3.335 3.335 0 0 1 -.273 1.142c-.29.82-.618 1.75-.091 2.473s1.521.7 2.4.68a3.426 3.426 0 0 1 1.156.078 3.4 3.4 0 0 1 .424 1.063c.248.845.531 1.8 1.4 2.086a1.424 1.424 0 0 0 .431.068 3.382 3.382 0 0 0 1.868-.914 3.285 3.285 0 0 1 1.012-.631 3.285 3.285 0 0 1 1.023.631c.685.523 1.461 1.12 2.3.845.867-.282 1.15-1.24 1.4-2.084a3.388 3.388 0 0 1 .424-1.062 3.425 3.425 0 0 1 1.153-.08c.878.021 1.867.05 2.4-.68s.2-1.653-.092-2.474a3.38 3.38 0 0 1 -.281-1.139 3.436 3.436 0 0 1 .864-.713c.732-.5 1.563-1.07 1.563-1.994s-.834-1.492-1.567-1.993z"
												fill="#0066FF"
											></path>
											<path
												d="m11 14.75a.745.745 0 0 1 -.53-.22l-2-2a.75.75 0 0 1 1.06-1.06l1.54 1.54 3.48-2.61a.75.75 0 0 1 .9 1.2l-4 3a.751.751 0 0 1 -.45.15z"
												fill="#fff"
											></path>
										</g>
									</svg>
								</button>
							)}
						</div>
					</>
				)}

				{!isMobile && (
					<div className="nav-actions">
						<div style={{ position: 'relative' }}>
							<div className="nav-brand-logo">
								<Link className="sidebar-brand d-flex align-items-center justify-content-center" to={'/client/dashboard'}>
									<div className="sidebar-brand-text pr-4" onClick={() => history('/client/dashboard')}>
										<img src="https://projectmix.ai/images/logo.png" alt="logo" className="img-fluid" width={150} height={'80%'} />
									</div>
								</Link>
							</div>
						</div>
					</div>
				)}
			</nav>
		);
	}
};

export default NavBar;
