import React from 'react';
import './Payment.scss';
import Payment from './Payment';
import { TbDatabaseExclamation } from 'react-icons/tb';

function PaymentGrid({ dataList, isLoading, onAccept, onDetails, customDone, acceptPermission, viewPermission, processSelectedPayment }) {
	// const paymentPreviewModal = (id) => {
	// 	handlePaymentPreviewModal(id)
	// }
	const handleDeletePayment = (selectedPaymentDetails) => {
		// handleDeletePayment(selectedPaymentDetails);
		processSelectedPayment(selectedPaymentDetails);
	};
	return (
		<>
			{dataList && dataList.length > 0 ? (
				<div className="payment-taskgrid-container w-100">
					{dataList.map((item) => {
						return (
							<Payment
								payment={item}
								key={item.id}
								onAccept={onAccept}
								onDetails={onDetails}
								customDone={customDone}
								acceptPermission={acceptPermission}
								viewPermission={viewPermission}
								handleDeletePayment={handleDeletePayment}
							// paymentPreviewModal={paymentPreviewModal}
							/>
						);
					})}
				</div>
			) : (
				<>
					<div className="not-found-container">
						<div className="not-found-icon">
							<TbDatabaseExclamation />
						</div>
						<h6 className="not-found-text">No Data Available</h6>
					</div>
				</>
			)}
		</>
	);
}

export default PaymentGrid;
