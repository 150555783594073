import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { useQuery } from 'react-query';
import moment from 'moment';
import {
	FaArrowRight,
	FaCheck,
	FaInfoCircle,
	FaPen,
	FaPlus,
	FaSave,
	FaSearch,
	FaTrash,
	FaRegFileExcel,
	FaRegComments
} from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';

import { LuChevronDown } from 'react-icons/lu';
import { TfiReload } from 'react-icons/tfi';
import Filter from 'ui-component/filter-menu/Filter';
import { Dropdown } from 'react-bootstrap';
import Scroller from 'ui-component/Scroller';
import Pagination from 'ui-component/Pagination';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import usePageTitle from 'hooks/usePageTitle';
import { ProcessingIcon, ReadyIcon, EstimateIcon } from 'ui-component/custom-icons/icons';
import toast from 'react-hot-toast';
import { BiSolidPurchaseTag } from 'react-icons/bi';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Contacts } from '@mui/icons-material';
import { TbDashboard } from 'react-icons/tb';
import StatusBadge from 'ui-component/StatusBadge';
import DetailModal from './DetailModal';

const contactsApprove = () => {
	const [setPageTitle] = usePageTitle('Contacts Approval');
	const history = useNavigate();
	const [alignment, setAlignment] = useState('v1');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const [activeComponent, setActiveComponent] = useState('order');
	const [filters, setFilters] = useState([]);
	const [searchField, setSearchField] = useState();
	const [activeFilters, setActiveFilters] = useState({});
	const [pagination, setpagination] = useState(null);
	const [foundOpen, setFoundOpen] = useState(false);
	const [products, setProducts] = useState([]);
	// const [data, setData] = useState(null);
	const [selectedPurchaseList, setPurcahseListId] = useState(null);
	const [showDetailModal, setShowDetailModal] = useState(false);
	const [selectedId, setSelectedId] = useState(null);
	const [isApproved, setIsApproved] = useState(null);
	const handleComponentChange = (component) => {
		setActiveComponent(component);
		setSearchField('');
		setProducts([]);
	};
	const handleData = (data) => {
		setData(data?.data);
		setpagination(data);
	};

	const { data, error, isLoading, refetch } = useQuery('clients', () =>
		api.getallClients().then((res) => {
			console.log(res);
			return res?.data;
		})
	);

	useEffect(() => {
		refetch();
	}, [showDetailModal]);





	const openDetailModal = (id, is_approved) => {
		console.log(id);
		setSelectedId(id);
		setIsApproved(is_approved);
		setShowDetailModal(true);
	};
	const closeDetailModal = () => {
		setShowDetailModal(false);
	};

	return (
		<div className="row" style={{ padding: '20px' }}>
			<div className="col-xl-12 col-lg-12">
				<div className="card shadow-sm  bg-white mb-4 table-card" style={{ marginTop: 10 }}>
					<div className="d-flex align-items-center justify-content-between TableSerachBar">
						{/* <form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
							<div className="input-group">
								<input
									type="text"
									className="form-control bg-white border"
									placeholder="Search"
									aria-label="Search"
									aria-describedby="basic-addon2"
									value={searchField}
									onChange={(e) => {
										SearchBackOrders(e.target.value);
									}}
								/>
								<span className="searchIcon">
									<FaSearch />
								</span>
							</div>
						</form> */}
					</div>
					<div className="estimation-table-area">
						<div className="table-side">
							<div className="table-responsive-xl tableData estimationList">
								<Scroller>
									<table className="table  min-width-unset tbl-space pm-table">
										<thead>
											<tr className="sticky-header">
												<th scope="col">company name</th>
												<th scope="col">First name</th>
												<th scope="col" class="">
													Last name
												</th>
												<th scope="col">Email</th>
												<th scope="col">Number</th>
												<th scope="col">Status</th>
												<th scope="col" style={{ width: '8%' }}></th>
											</tr>
										</thead>
										<tbody>
											{data
												?.sort((a, b) => {
													const sortOrder = { 1: 1, 0: 2, 2: 3 }; // Define custom sort order
													return sortOrder[a.is_approved] - sortOrder[b.is_approved];
												})
												.map((group) => (
													<React.Fragment key={group.key}>
														<tr onClick={() => openDetailModal(group.id, group.is_approved)} style={{ cursor: 'pointer' }}>
															<td>
																<PrivacyModeContent>{group.company_name}</PrivacyModeContent>
															</td>
															<td className="">{group.first_name}</td>
															<td colSpan="">{group.last_name}</td>
															<td colSpan="">{group.email}</td>

															<td>{group.number}</td>
															<td>
																<StatusBadge
																	itemStatus={group.is_approved == 2 ? 'Approved' : group.is_approved == 1 ? 'Pending' : 'Incomplete'}
																/>
															</td>
															<td>
																<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
																	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21.985 21.985">
																		<path
																			id="search_1_"
																			data-name="search (1)"
																			d="M10.649,15.458a.859.859,0,1,1,0,1.718h-1.2a.859.859,0,1,1,0-1.718Zm7.042-4.594A.859.859,0,0,0,16.832,10H9.447a.859.859,0,1,0,0,1.718h7.386A.859.859,0,0,0,17.691,10.864ZM9.447,6.57h7.386a.859.859,0,1,0,0-1.718H9.447a.859.859,0,1,0,0,1.718ZM6.011,15.458H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.453H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.153H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,0,0,0-1.718ZM21.733,20.519a.859.859,0,0,1-1.215,1.215l-1.453-1.453A3.869,3.869,0,1,1,20.31,19.1ZM17.09,19.108a2.147,2.147,0,1,0-2.147-2.147A2.149,2.149,0,0,0,17.09,19.108ZM18.55,0H3.435A3.439,3.439,0,0,0,0,3.435V18.55a3.439,3.439,0,0,0,3.435,3.435h8.845a.859.859,0,1,0,0-1.718H3.435A1.72,1.72,0,0,1,1.718,18.55V3.435A1.72,1.72,0,0,1,3.435,1.718H18.55a1.72,1.72,0,0,1,1.718,1.718v8.5a.859.859,0,0,0,1.718,0v-8.5A3.439,3.439,0,0,0,18.55,0Zm0,0"
																		/>
																	</svg>
																</div>
															</td>
														</tr>
													</React.Fragment>
												))}
											{data?.length < 1 && (
												<tr className="no-hover">
													<td colSpan={7} className="whitebg">
														<div className="not-found-container ">
															<div className="not-found-icon">
																<svg xmlns="http://www.w3.org/2000/svg" width="77.513" height="77.513" viewBox="0 0 77.513 77.513">
																	<g id="Group_40058" data-name="Group 40058" transform="translate(-1056.04 -582)">
																		<path
																			id="Union_56"
																			data-name="Union 56"
																			d="M1464.57,11241.394l-7.437-2.015c-3.039-.82-5.078-3.146-5.078-5.789l.008-9.06a4.216,4.216,0,0,1-2.768-2.223,3.2,3.2,0,0,1,.37-3.3l2.1-3.329a4.27,4.27,0,0,1,2.426-1.582l10.526-2.706a12.559,12.559,0,0,1,6.246,0l10.526,2.7a4.525,4.525,0,0,1,2.311,1.921l2.243,3.024a3.166,3.166,0,0,1,.378,3.273,4.176,4.176,0,0,1-2.779,2.206v9.073c0,2.638-2.045,4.96-5.084,5.781l-7.443,2.017a12.728,12.728,0,0,1-6.551,0Zm4.531-1.893a9.192,9.192,0,0,0,1.275-.251l7.443-2.015a4.046,4.046,0,0,0,3.3-3.64v-8.388l-5.665,1.532a5.351,5.351,0,0,1-1.4.186,4.675,4.675,0,0,1-3.786-1.766l-.011-.021-1.145-1.712Zm-14.519-5.908a4.04,4.04,0,0,0,3.293,3.643l7.44,2.015a9.311,9.311,0,0,0,1.253.248l.008-16.062-1.137,1.7a4.689,4.689,0,0,1-3.8,1.794,5.429,5.429,0,0,1-1.4-.187l-5.646-1.528Zm15.184-13.582,2.638,3.947a2.079,2.079,0,0,0,1.653.728,2.528,2.528,0,0,0,.652-.087l8.19-2.215a1.764,1.764,0,0,0,1.179-.911h0a1.14,1.14,0,0,0-.144-1.19l-2.254-3.042c-.076-.1-.178-.239-.291-.381Zm-15.847-3.146-2.1,3.332a1.2,1.2,0,0,0-.2,1.216l.022.051a1.821,1.821,0,0,0,1.216.937l8.128,2.2a2.485,2.485,0,0,0,.646.087,2.093,2.093,0,0,0,1.67-.75l2.627-3.931-11.926-3.238C1453.973,11216.8,1453.948,11216.831,1453.92,11216.865Zm11.514-3.318-7.527,1.933,9.954,2.7,9.951-2.7-7.55-1.938a9.8,9.8,0,0,0-4.827,0Z"
																			transform="translate(-373.064 -10604.654)"
																		/>
																		<path
																			id="restock_1_"
																			data-name="restock (1)"
																			d="M77.477,40.439A38.761,38.761,0,0,1,3.32,54.466a2.864,2.864,0,0,0-.09.439v11.3a1.615,1.615,0,1,1-3.23,0v-11.3a6.466,6.466,0,0,1,6.459-6.459h11.3a1.615,1.615,0,0,1,0,3.23H6.459a3.167,3.167,0,0,0-.72.145A35.513,35.513,0,0,0,74.248,40.3a1.531,1.531,0,0,1,1.683-1.547A1.623,1.623,0,0,1,77.477,40.439ZM75.9,9.689A1.615,1.615,0,0,0,74.283,11.3v11.3a3.111,3.111,0,0,1-.087.433A38.765,38.765,0,0,0,.036,37.074a1.614,1.614,0,0,0,1.544,1.683H1.65A1.614,1.614,0,0,0,3.262,37.21,35.515,35.515,0,0,1,71.774,25.692a3.167,3.167,0,0,1-.72.145H59.75a1.615,1.615,0,0,0,0,3.23h11.3a6.466,6.466,0,0,0,6.459-6.459V11.3A1.615,1.615,0,0,0,75.9,9.689Z"
																			transform="translate(1056.04 582)"
																		/>
																	</g>
																</svg>
															</div>
															<h6 className="not-found-text" style={{ fontStyle: 'italic', color: '#000', fontSize: '18px' }}>
																Add your first client and enjoy the convenience!
															</h6>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</Scroller>
							</div>
							<Pagination pagination={pagination} data={handleData} onLoading={(e) => { }} />
						</div>
					</div>
				</div>
			</div>
			{showDetailModal && (
				<DetailModal isOpen={showDetailModal} onClose={closeDetailModal} selectedId={selectedId} isApproved={isApproved} />
			)}
		</div>
	);
};

export default contactsApprove;
