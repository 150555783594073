import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { ImNewTab } from 'react-icons/im';
import { RxCrossCircled } from 'react-icons/rx';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import toast from 'react-hot-toast';

const DetailModal = ({ isOpen, onClose, selectedId, isApproved }) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isMissing, setIsMissing] = useState(true);

	useEffect(() => {
		api.getClientGeneralInformation(selectedId).then((res) => {
			setData(res.data);
			setIsLoading(false);
			if (
				res.data.client === null ||
				// res.data.companyBank === null ||
				res.data.companyBillingAddress === null ||
				res.data.companyContact === null ||
				res.data.companyDoc === null ||
				res.data.companyInfo === null ||
				res.data.companyShippingAddress === null
			) {
				setIsMissing(true);
			} else {
				setIsMissing(false);
			}
		});
	}, [selectedId]);

	const companyActivity = data?.dropdwons?.find((dropdown) => dropdown.type === 'Company Activity')?.name;
	const companyTurnover = data?.dropdwons?.find((dropdown) => dropdown.type === 'Company Turnover')?.name;

	const approveContact = (id) => {
		console.log(id);
		api
			.approveClients(id)
			.then((res) => {
				console.log(res);
				toast.success('Contact Approved Successfully');
				api.getallClients().then((res) => {
					console.log(res);
					onClose();
				});
			})
			.catch((err) => {
				console.log(err);
				toast.error('Failed to Approve Contact');
			});
	};

	const rejectContact = (id) => {
		api
			.rejectClients(id)
			.then((res) => {
				console.log(res);
				toast.success('Contact Rejected Successfully');
				api.getallClients().then((res) => {
					console.log(res);
					onClose();
				});
			})
			.catch((err) => {
				console.log(err);
				toast.error('Failed to Reject Contact');
			});
	};
	if (isLoading) {
		return null; // or return a loading spinner
	}
	return (
		<Modal show={isOpen} onHide={onClose} centered size="lg">
			<Modal.Body>
				<div className="p-2">
					<div className="mb-2 ml-0 pl-0">
						<div className="row" style={{ marginBottom: 20 }}>
							<div className="col-12">
								<h4 style={{ marginBottom: 0, fontWeight: 600 }}>Client Details</h4>
								<button className="btn modal-close-icon" style={{ top: '-5px' }} onClick={onClose}>
									<svg
										id="Component_127_1"
										height="16"
										width="16"
										data-name="Component 127 – 1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 19.295 19.289"
									>
										<path
											id="Icon_ionic-ios-close"
											data-name="Icon ionic-ios-close"
											d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
											transform="translate(-11.285 -11.289)"
										/>
									</svg>
								</button>
							</div>
						</div>
						<div className="row" style={{ marginBottom: 20 }}>
							<div className="col-12" style={{ marginBottom: 20 }}>
								<h5 style={{ marginBottom: 0, fontWeight: 600 }}>Company Info</h5>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>First Name</label>
								<input type="text" className="form-control" value={data?.client?.first_name} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Last Name</label>
								<input type="text" className="form-control" value={data?.client?.last_name} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Phone Number</label>
								<input type="text" className="form-control" value={data?.client?.number} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Company Name</label>
								<input type="text" className="form-control" value={data?.client?.company_name} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Establishment Year</label>
								<input type="text" className="form-control" value={data?.companyInfo?.establishment_year} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Activities</label>
								<input type="text" className="form-control" value={companyActivity} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Annual Turnover</label>
								<input type="text" className="form-control" value={companyTurnover} disabled></input>
							</div>
						</div>
						<div className="row" style={{ marginBottom: 20 }}>
							<div className="col-12" style={{ marginBottom: 20 }}>
								<h5 style={{ marginBottom: 0, fontWeight: 600 }}>Contacts</h5>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Sales Contact</label>
								<input type="text" className="form-control" value={data?.companyContact?.contact_person} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Company Email</label>
								<input type="text" className="form-control" value={data?.companyContact?.email} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Website</label>
								<input type="text" className="form-control" value={data?.companyContact?.website} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Phone Number</label>
								<input
									type="text"
									className="form-control"
									value={`${data?.companyContact?.phone_code}${data?.companyContact?.phone_number}`}
									disabled
								></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>WhatsApp Number</label>
								<input
									type="text"
									className="form-control"
									value={`${data?.companyContact?.whatsapp_code}${data?.companyContact?.whatsapp}`}
									disabled
								></input>
							</div>
						</div>
						<div className="row" style={{ marginBottom: 20 }}>
							<div className="col-12" style={{ marginBottom: 20 }}>
								<h5 style={{ marginBottom: 0, fontWeight: 600 }}>Documents</h5>
							</div>
							{data?.companyDoc?.vat_code && (
								<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
									<label style={{ color: '#888888' }}>VAT code</label>
									<input type="text" className="form-control" value={data?.companyDoc?.vat_code} disabled></input>
								</div>
							)}
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Registration Number</label>
								<input type="text" className="form-control" value={data?.companyDoc?.registration_number} disabled></input>
							</div>
							<div className="col-12" style={{ marginBottom: 20, marginTop: 20 }}>
								<h5 style={{ marginBottom: 0, fontWeight: 600 }}>Attachment</h5>
							</div>
							{data?.companyDoc?.vat_doc_url && (
								<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
									<label style={{ color: '#888888' }}>VAT File</label>
									<a
										href={data?.companyDoc?.vat_doc_url || '#'}
										target={data?.companyDoc?.vat_doc_url ? '_blank' : '_self'}
										rel="noopener noreferrer"
										style={{ color: 'inherit', textDecoration: 'none' }}
									>
										<div className="attachments-file-client">
											{`${data?.companyDoc?.vat_doc.split('/').pop().substring(0, 25)}`}
											<ImNewTab size={20} />
										</div>
									</a>
								</div>
							)}
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Registration File</label>
								<a
									href={data?.companyDoc?.registration_doc_url || '#'}
									target={data?.companyDoc?.registration_doc_url ? '_blank' : '_self'}
									rel="noopener noreferrer"
									style={{ color: 'inherit', textDecoration: 'none' }}
								>
									<div className="attachments-file-client">
										{`${data?.companyDoc?.registration_doc.split('/').pop().substring(0, 25)}`}
										<ImNewTab size={20} />
									</div>
								</a>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Passport or Valid ID</label>
								<a
									href={data?.companyDoc?.company_logo_url || '#'}
									target={data?.companyDoc?.company_logo_url ? '_blank' : '_self'}
									rel="noopener noreferrer"
									style={{ color: 'inherit', textDecoration: 'none' }}
								>
									<div className="attachments-file-client">
										{`${data?.companyDoc?.company_logo.split('/').pop().substring(0, 25)}`}
										<ImNewTab size={20} />
									</div>
								</a>
							</div>
						</div>
						<div className="row" style={{ marginBottom: 20 }}>
							<div className="col-12" style={{ marginBottom: 20 }}>
								<h5 style={{ marginBottom: 0, fontWeight: 600 }}>Shipping Address</h5>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Address</label>
								<input type="text" className="form-control" value={data?.companyShippingAddress?.address} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>City</label>
								<input type="text" className="form-control" value={data?.companyShippingAddress?.city} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Zip Code</label>
								<input type="text" className="form-control" value={data?.companyShippingAddress?.zip} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Country</label>
								<input type="text" className="form-control" value={data?.companyShippingAddress?.country} disabled></input>
							</div>
						</div>
						<div className="row" style={{ marginBottom: 20 }}>
							<div className="col-12" style={{ marginBottom: 20 }}>
								<h5 style={{ marginBottom: 0, fontWeight: 600 }}>Billing Address</h5>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Address</label>
								<input type="text" className="form-control" value={data?.companyBillingAddress?.address} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>City</label>
								<input type="text" className="form-control" value={data?.companyBillingAddress?.city} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Zip Code</label>
								<input type="text" className="form-control" value={data?.companyBillingAddress?.zip} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Country</label>
								<input type="text" className="form-control" value={data?.companyBillingAddress?.country} disabled></input>
							</div>
						</div>
						<div className="row" style={{ marginBottom: 20 }}>
							<div className="col-12" style={{ marginBottom: 20 }}>
								<h5 style={{ marginBottom: 0, fontWeight: 600 }}>Bank Details</h5>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Bank Name</label>
								<input type="text" className="form-control" value={data?.companyBank?.bank_name} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Bank Beneficiary</label>
								<input type="text" className="form-control" value={data?.companyBank?.bank_beneficiary_name} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Bank IBAN</label>
								<input type="text" className="form-control" value={data?.companyBank?.bank_IBAN} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Bank SWIFT</label>
								<input type="text" className="form-control" value={data?.companyBank?.bank_SWIFT} disabled></input>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
								<label style={{ color: '#888888' }}>Address</label>
								<input type="text" className="form-control" value={data?.companyBank?.bank_address} disabled></input>
							</div>
						</div>
						{data?.companySurvey && (
							<div className="row" style={{ marginBottom: 20 }}>
								<div className="col-12" style={{ marginBottom: 20 }}>
									<h5 style={{ marginBottom: 0, fontWeight: 600 }}>Survey Answers</h5>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
									<label style={{ color: '#888888' }}>Platform</label>
									<input
										type="text"
										className="form-control"
										value={`${data?.companySurvey?.platform_name || ''} - ${data?.companySurvey?.platform_notes || ''}`}
										disabled
									></input>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
									<label style={{ color: '#888888' }}>Job Title</label>
									<input type="text" className="form-control" value={data?.companySurvey?.job_title} disabled></input>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: 5 }}>
									<label style={{ color: '#888888' }}>Employees</label>
									<input type="text" className="form-control" value={data?.companySurvey?.employee_name} disabled></input>
								</div>
							</div>
						)}
						{isApproved != 2 && (
							<div className="row" style={{ marginBottom: 20, marginTop: 35 }}>
								<div className="col-12">
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
										<button className="btn btn-primary btn-outlined-reject" onClick={() => rejectContact(data?.client.id)}>
											Reject
											<RxCrossCircled size={20} />
										</button>
										<button
											className="btn btn-primary btn-outlined-main"
											disabled={isMissing}
											onClick={() => approveContact(data?.client.id)}
										>
											Approve
											<IoIosCheckmarkCircleOutline size={20} />
										</button>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default DetailModal;
