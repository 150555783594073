import React, { useEffect, useState, useRef } from 'react';
//import './estimateInvoice.scss';
import { Link } from 'react-router-dom';
import mgLogo from 'assets/images/mglogo.svg';
import glpLogo from 'assets/images/glplogo.png';
import cmLogo from 'assets/images/cmlogo.svg';
import ProcessIndicator from '../orders/ProcessIndicator';
import ProcessIndicatorMobile from '../orders/ProcessIndicatorMobile';
import { taskboardProgresses } from 'assets/helperdata/client/order-client-progress';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import { Skeleton } from '@mui/material';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import config from '../../../config';
import { FaPlus, FaTimes } from 'react-icons/fa';

function EstimateDetailInvoice({ estimate, handleClose, type }) {
	const [isInProgress, setIsInProgress] = useState(true);
	const [cleanUrl, setCleanUrl] = useState(null);
	const [status, setStatus] = useState(null);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1028);
	const [showTracking, setShowTracking] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1028);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (estimate?.transit_company) {
			estimate?.transit_company?.attachments.map((attachment) => {
				if (attachment?.type == 'companylogo') {
					const cleaned_Url = decodeURI(attachment?.logo_attachment_url);
					setCleanUrl(cleaned_Url);
				}
			});
		}
	}, [estimate]);
	console.log(estimate);

	useEffect(() => {
		if (estimate) {
			if (estimate?.status_id == 30) {
				setStatus('3');
			} else if (estimate?.status_id == 133) {
				setStatus('1');
			} else if (estimate?.status_id == 132) {
				setStatus('0');
			} else {
				setStatus('2');
			}
		}
	}, [estimate]);

	console.log(estimate);

	if (isMobile) {
		return (
			<>
				<button className="btn modal-close-icon" onClick={handleClose} style={{ zIndex: 10 }}>
					<svg
						id="Component_127_1"
						height="16"
						width="16"
						data-name="Component 127 – 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 19.295 19.289"
					>
						<path
							id="Icon_ionic-ios-close"
							data-name="Icon ionic-ios-close"
							d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
							transform="translate(-11.285 -11.289)"
						/>
					</svg>
				</button>
				<div className="row" style={{ marginBottom: 20 }}>
					<div className="col-12">
						<label style={{ marginBottom: 0, fontWeight: 600 }}>{estimate?.transit_company?.name}</label>
						<br />
						<a>
							{estimate?.transit_company?.address}, {estimate?.transit_company.city}
						</a>{' '}
						<br />
						<a>
							{estimate?.transit_company?.zipcode}, {estimate?.transit_company?.country}
						</a>
					</div>
				</div>
				<div className="row" style={{ marginBottom: 20 }}>
					<div className="col-12">
						<label style={{ marginBottom: 0, fontWeight: 600 }}>{estimate?.customer?.name}</label>
						<br />
						<a>
							{estimate?.customer?.address}, {estimate?.customer.city}
						</a>{' '}
						<br />
						<a>
							{estimate?.customer?.zipcode}, {estimate?.customer?.country}
						</a>
					</div>
				</div>
				{estimate?.customer?.shipping_address && (
					<div className="row" style={{ marginBottom: 20 }}>
						<div className="col-12">
							<label style={{ marginBottom: 0, fontWeight: 600 }}>Ship To:</label>
							<br />
							<a>
								{estimate?.customer?.shipping_address}, {estimate?.customer.shipping_city}
							</a>{' '}
							<br />
							<a>
								{estimate?.customer?.shipping_zipcode}, {estimate?.customer?.shipping_country}
							</a>
						</div>
					</div>
				)}
				<div className="row" style={{ marginBottom: 20 }}>
					<div className="col-6">
						<label style={{ marginBottom: 0, fontWeight: 600 }}>Estimate Date:</label>
						<br />
						<a>{moment(estimate?.start_date).format(CommonDateOnlyFormat)}</a>
					</div>
					<div className="col-6">
						<label style={{ marginBottom: 0, fontWeight: 600 }}>Due Date:</label>
						<br />
						<a>{moment(estimate?.expires_at).format(CommonDateOnlyFormat)}</a>
					</div>
				</div>
				<div className="row" style={{ marginBottom: 20 }}>
					<div className="col-12 d-flex justify-content-center align-items-center flex-column">
						<button
							className="btn btn-primary btn-confirm-agreement"
							onClick={() => setShowTracking(!showTracking)}
							style={{ marginBottom: 10 }}
						>
							Order status
						</button>
						{showTracking && (
							<>
								<ProcessIndicatorMobile
									isInProgress={isInProgress}
									processes={taskboardProgresses}
									currentIndex={status}
									estimate={estimate}
								/>
							</>
						)}
					</div>
				</div>

				<div className="row" style={{ marginBottom: 20 }}>
					<div className="col-12">
						<h3 className="est-ref m-0" style={{ fontWeight: 600 }}>
							{estimate?.master_status?.name == 'Credit Note' ? 'Credit Note' : ''}{' '}
							{type !== 'estimation' ? 'Invoice ' + estimate?.invoice_number : 'Estimate ' + estimate?.estimation_number}
						</h3>
					</div>
				</div>

				<div className="row" style={{ marginBottom: 10, borderBottom: '1px solid #ededed', paddingBottom: 10 }}>
					<div className="col-2">
						<a style={{ fontWeight: 500 }}>Qty</a>
					</div>
					<div className="col-7">
						<a style={{ fontWeight: 500 }}>Product</a>
					</div>
					<div className="col-3" style={{ paddingLeft: 0 }}>
						<a style={{ fontWeight: 500 }}>Total</a>
					</div>
				</div>
				{estimate?.estimation_details?.map((item) => (
					<div className="row" style={{ marginBottom: 20 }}>
						<div className="col-2">
							<a>{item?.qty}</a>
						</div>
						<div className="col-7">
							<a>{item?.product?.product_name}</a>
							<br />
							<span style={{ color: '#818181', fontStyle: 'italic' }}>({item?.product?.sku})</span>
						</div>
						<div className="col-3" style={{ paddingLeft: 0 }}>
							<a style={{ fontWeight: 600 }}>
								<span style={{ whiteSpace: 'nowrap' }}>
									<CurrencyValue currencyObject={item?.converted_currency_total_price} />
								</span>
							</a>
						</div>
					</div>
				))}
				{estimate?.shipping_fees > 0 && (
					<div className="row">
						<div className="col-9 d-flex justify-content-end">
							<a>Shipping:</a>
						</div>
						<div className="col-3" style={{ paddingLeft: 0 }}>
							<a style={{ fontWeight: 600 }}>
								<span style={{ whiteSpace: 'nowrap', fontWeight: 400 }}>
									<CurrencyValue currencyObject={estimate?.converted_currency_total_shipping_fee} />
								</span>
							</a>
						</div>
					</div>
				)}
				{estimate?.vat_type == 'Excluding VAT' && (
					<div className="row">
						<div className="col-9 d-flex justify-content-end">
							<a>VAT</a>
						</div>
						<div className="col-3" style={{ paddingLeft: 0 }}>
							<a style={{ fontWeight: 600 }}>
								<span style={{ whiteSpace: 'nowrap', fontWeight: 400 }}>
									<CurrencyValue currencyObject={estimate?.converted_currency_vat_amount} />
								</span>
							</a>
						</div>
					</div>
				)}
				<div className="row">
					<div className="col-9 d-flex justify-content-end">
						<a>Subtotal</a>
					</div>
					<div className="col-3" style={{ paddingLeft: 0 }}>
						<a style={{ fontWeight: 600 }}>
							<span style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
								<CurrencyValue currencyObject={estimate?.converted_currency_subtotal} />
							</span>
						</a>
					</div>
				</div>
				<div className="row" style={{ marginBottom: 20 }}>
					<div className="col-9 d-flex justify-content-end">
						<a>Total</a>
					</div>
					<div className="col-3" style={{ paddingLeft: 0 }}>
						<a style={{ fontWeight: 600 }}>
							<span style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
								<CurrencyValue currencyObject={estimate?.converted_currency_total} />
							</span>
						</a>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="est-inv-container">
				<button className="btn modal-close-icon" onClick={handleClose}>
					<svg
						id="Component_127_1"
						height="16"
						width="16"
						data-name="Component 127 – 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 19.295 19.289"
					>
						<path
							id="Icon_ionic-ios-close"
							data-name="Icon ionic-ios-close"
							d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
							transform="translate(-11.285 -11.289)"
						/>
					</svg>
				</button>
				<div className="row m-0">
					{type !== 'estimation' && type !== 'invoice' ? (
						<ProcessIndicator isInProgress={isInProgress} processes={taskboardProgresses} currentIndex={status} />
					) : null}
					{estimate ? (
						<>
							{estimate?.transit_company?.name?.toLowerCase() == 'coolmix' ? (
								<>
									<div className="col-6 p-0">
										<div className="est-detail-logo" style={{ height: '36px' }}>
											<img src={cmLogo} height={'36px'} />
										</div>

										<div className="customer-details" style={{ marginTop: '20px' }}>
											<p className="m-0">
												<b style={{ fontSize: 18 }}>
													<PrivacyModeContent>{estimate?.customer?.name}</PrivacyModeContent>
												</b>{' '}
												<br />
												<PrivacyModeContent>{estimate?.customer?.address}</PrivacyModeContent> <br />
												<PrivacyModeContent>{estimate?.customer?.city + ', ' + estimate?.customer?.zipcode}</PrivacyModeContent> <br />
												<PrivacyModeContent>{estimate?.customer?.country}</PrivacyModeContent>
												{estimate?.customer?.shipping_address ? (
													<>
														<br />
														<br />
														<b>Ship To: </b>
														<br />
														<PrivacyModeContent>{estimate?.customer?.shipping_address}</PrivacyModeContent> <br />
														<PrivacyModeContent>
															{(estimate?.customer?.city == 'null' ? estimate?.customer?.city + ', ' : '') +
																estimate?.customer?.shipping_zipcode}
														</PrivacyModeContent>{' '}
														<br />
														<PrivacyModeContent>{estimate?.customer?.shipping_country}</PrivacyModeContent>
													</>
												) : (
													<></>
												)}
											</p>
										</div>
									</div>
									<div className="col-6 p-0">
										{/* <div className="company-details text-right">
											<p className="m-0">
												<b>Mix Parts B.V.</b> <br />
												Industrieweg 24 C <br />
												5262 GJ Vught <br />
												The Netherlands <br />
												info@coolmix.eu <br />
												www.coomix.eu
											</p>
										</div> */}
									</div>
								</>
							) : (
								<>
									{config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.app_name == 'glpwireless' ? (
										<div className="col-6 p-0">
											<div className="est-detail-logo" style={{ height: '70px' }}>
												<label htmlFor="" style={{ marginBottom: 0, fontWeight: 600 }}>
													{estimate?.transit_company?.name}
												</label>
											</div>

											<div className="customer-details" style={{ marginTop: '20px' }}>
												<p className="m-0">
													<b>
														<PrivacyModeContent notUserRestricted>{estimate?.transit_company?.name}</PrivacyModeContent>
													</b>{' '}
													<br />
													<PrivacyModeContent notUserRestricted>{estimate?.transit_company?.address}</PrivacyModeContent> <br />
													{!!estimate?.transit_company?.city ? (
														<PrivacyModeContent notUserRestricted>
															{`${estimate?.transit_company?.city}${!!estimate?.transit_company?.zipcode && ','} ${
																estimate?.transit_company?.zipcode
															}`}
														</PrivacyModeContent>
													) : (
														''
													)}
													<br />
													<PrivacyModeContent notUserRestricted>{estimate?.transit_company?.country}</PrivacyModeContent>
													{estimate?.transit_company?.shipping_address ? (
														<>
															<br />
															<br />
															<b>Ship To: </b>
															<br />
															<PrivacyModeContent notUserRestricted>{estimate?.transit_company?.shipping_address}</PrivacyModeContent>{' '}
															<br />
															<PrivacyModeContent notUserRestricted>
																{estimate?.transit_company?.shipping_city + ', ' + estimate?.transit_company?.shipping_zipcode}
															</PrivacyModeContent>{' '}
															<br />
															<PrivacyModeContent notUserRestricted>{estimate?.transit_company?.shipping_country}</PrivacyModeContent>
														</>
													) : (
														<></>
													)}
												</p>
											</div>
										</div>
									) : (
										<div className="col-12 p-0">
											<div className="customer-details" style={{ marginTop: '20px' }}>
												<div className="est-detail-logo" style={{ height: '36px', float: 'right', textAlign: 'right' }}>
													<label htmlFor="" style={{ marginBottom: 0, fontWeight: 600 }}>
														{estimate?.transit_company?.name}
													</label>
													<br />
													<a>{estimate?.transit_company?.address}</a>
													<br />
													<a>{estimate?.transit_company?.city}</a>
													<br />
													<a>
														{estimate?.transit_company?.zipcode}, {estimate?.transit_company?.country}
													</a>
												</div>
												<p className="m-0">
													<b style={{ fontSize: 18 }}>
														<PrivacyModeContent>{estimate?.customer?.name}</PrivacyModeContent>
													</b>{' '}
													<br />
													<PrivacyModeContent>{estimate?.customer?.address}</PrivacyModeContent> <br />
													<PrivacyModeContent>
														{(estimate?.customer?.shipping_city !== 'null' ? estimate?.customer?.shipping_city + ', ' : '') +
															estimate?.customer?.zipcode}
													</PrivacyModeContent>{' '}
													<br />
													<PrivacyModeContent>{estimate?.customer?.country}</PrivacyModeContent>
													{estimate?.customer?.shipping_address ? (
														<>
															<br />
															<br />
															<b>Ship To: </b>
															<br />
															<PrivacyModeContent>{estimate?.customer?.shipping_address}</PrivacyModeContent> <br />
															<PrivacyModeContent>
																{estimate?.customer?.shipping_city + ', ' + estimate?.customer?.shipping_zipcode}
															</PrivacyModeContent>{' '}
															<br />
															<PrivacyModeContent>{estimate?.customer?.shipping_country}</PrivacyModeContent>
														</>
													) : (
														<></>
													)}
												</p>
											</div>
										</div>
									)}

									<div className="col-6 p-0">
										<div className="company-details text-right">
											{/* <p className="m-0">
												<b> Mix Global FZCO</b> <br />
												Dubai Airport Freezone <br />
												4W Block B, Office 636, Dubai <br />
												finance@trademixglobal.com <br />
												www.trademixglobal.com <br />
											</p> */}
										</div>
									</div>
								</>
							)}
						</>
					) : (
						<>
							<div className="col-6 p-0">
								<div className="est-detail-logo" style={{ height: '36px' }}>
									<Skeleton sx={{ height: '36px', width: '100px' }} />
								</div>

								<div className="customer-details" style={{ marginTop: '20px' }}>
									<Skeleton variant="text" />
									<Skeleton variant="text" />
									<Skeleton variant="text" />
								</div>
							</div>
							<div className="col-6 p-0">
								<div className="company-details text-right">
									<p className="m-0">
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
									</p>
								</div>
							</div>
						</>
					)}
				</div>

				<div className="row mx-0 d-flex align-items-center" style={{ marginTop: '20px' }}>
					<div className="col-6 col-md-8 p-0">
						{estimate ? (
							<h3 className="est-ref m-0">
								{estimate?.master_status?.name == 'Credit Note' ? 'Credit Note' : ''}{' '}
								{type !== 'estimation' ? 'Invoice ' + estimate?.invoice_number : 'Estimate ' + estimate?.estimation_number}
							</h3>
						) : (
							<Skeleton variant="rounded" />
						)}
					</div>
					<div className="col-6 col-md-4 p-0 d-flex align-items-center justify-content-center">
						<table className="w-100 table table-condensed" style={{ marginBottom: 0 }}>
							{estimate?.transaction_type == 'Invoice' &&
								(estimate?.master_status?.name == 'Credit Note' ? (
									<tr>
										<td style={{ fontWeight: 600 }}>Date:</td>
										<td className="text-right">{moment(estimate?.start_date).format(CommonDateOnlyFormat)}</td>
									</tr>
								) : (
									<tr>
										<td style={{ fontWeight: 600 }}>Date:</td>
										<td className="text-right">{moment(estimate?.invoice_date).format(CommonDateOnlyFormat)}</td>
									</tr>
								))}

							<>
								<tr>
									<td style={{ whiteSpace: 'nowrap', padding: 0 }}>Estimate Date:</td>
									<td className="text-right" style={{ whiteSpace: 'nowrap', padding: 0 }}>
										{moment(estimate?.start_date).format(CommonDateOnlyFormat)}
									</td>
								</tr>
								<tr>
									<td style={{ padding: 0 }}>Due Date: </td>
									<td className="text-right" style={{ padding: 0 }}>
										{moment(estimate?.expires_at).format(CommonDateOnlyFormat)}
									</td>
								</tr>
							</>
						</table>
					</div>
				</div>

				<div className="est-inv-items" style={{ marginTop: '20px' }}>
					<table className="w-100 table table-condensed">
						<thead>
							<tr>
								{estimate?.master_status?.name == 'Credit Note' && <th className="hide-on-mobile">Invoice</th>}
								<th>SKU</th>
								<th>Product</th>
								<th className="text-center">QTY</th>
								<th className="text-right d-none d-md-block">Price</th>
								<th className="text-right">Total</th>
							</tr>
						</thead>
						<tbody>
							{estimate?.estimation_details?.map((item) => (
								<tr>
									{estimate?.master_status?.name == 'Credit Note' && (
										<td className="hide-on-mobile">{item?.credited_invoice?.invoice_number}</td>
									)}
									<td className="text-left">{item?.product?.sku}</td>
									<td>
										<p className="p-0 m-0">{item?.product?.product_name}</p>
										{estimate?.master_status?.name == 'Credit Note' ? (
											<p className="hide-on-non-mobile mobile-prod-comment-ui">{item?.credited_invoice?.invoice_number}</p>
										) : item?.comment ? (
											<p className="hide-on-non-mobile mobile-prod-comment-ui">{item?.comment}</p>
										) : (
											''
										)}
									</td>

									<td className="text-center">{item?.qty}</td>
									<td className="text-right d-none d-md-block">
										<CurrencyValue currencyObject={item?.converted_currency_unit_price} />
									</td>
									<td className="text-right">
										<CurrencyValue currencyObject={item?.converted_currency_total_price} />
									</td>
								</tr>
							))}

							{estimate?.shipping_fees > 0 && (
								<tr>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="d-md-none"></td>
									{/* <td colSpan={2}>Shipping</td> */}
									{/* <td className="text-center"> {estimate?.shipping_qty}</td> */}
									<td className="text-right">Shipping</td>
									{/* <td className="text-right">
									<CurrencyValue currencyObject={estimate?.converted_currency_shipping_fee} />
								</td> */}
									<td className="text-right">
										<CurrencyValue currencyObject={estimate?.converted_currency_total_shipping_fee} />
									</td>
								</tr>
							)}
							{estimate?.vat_type == 'Excluding VAT' && (
								<tr>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="d-md-none"></td>
									<td className="text-right">VAT ({estimate?.vat_percent}%)</td>
									<td className="text-right font-weight-bold">
										<CurrencyValue currencyObject={estimate?.converted_currency_vat_amount} />
									</td>
								</tr>
							)}
							<tr>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="d-md-none"></td>
								<td className="text-right" style={{ fontWeight: 600 }}>
									Subtotal
								</td>
								<td className="text-right font-weight-bold" style={{ fontWeight: 600 }}>
									<CurrencyValue currencyObject={estimate?.converted_currency_subtotal} />
								</td>
							</tr>
							<tr>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="d-md-none"></td>
								<td className="text-right" style={{ fontWeight: 600 }}>
									Total
								</td>
								<td className="text-right font-weight-bold" style={{ fontWeight: 600 }}>
									<CurrencyValue currencyObject={estimate?.converted_currency_total} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}

export default EstimateDetailInvoice;
