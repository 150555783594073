import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import nextIcon from 'assets/images/icons/arrow.svg';
import Select from 'react-select';
import { useNavigate } from 'react-router';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { useDispatch } from 'react-redux';
import { element } from 'prop-types';
import { FaArrowRight, FaMinus, FaPlus } from 'react-icons/fa';
import Scroller from 'ui-component/Scroller';
import toast from 'react-hot-toast';
import '../QuickActions.scss';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';

const ClientPortal = ({ newShow, handleNewClose, onSubmit }) => {
	const [email, setEmail] = useState('');

	const [transitCompanies, setTransitCompanies] = useState([]);
	const [shippingPartners, setShippingPartners] = useState([]);
	const [packagesList, setPackagesList] = useState([]);
	const [isDisabled, setIsDisabled] = useState(false);

	const [summaryList, setSummaryList] = useState([]);
	const [sendingEmail, setSendingEmail] = useState(false);

	const [shipToValue, setShipToValue] = useState(null);
	const [shipmentData, setShipmentData] = useState({
		packages: [],
		ship_with: '',
		tracking_number: null,
		ship_to: null
	});
	const [alertMessage, setAlertMessage] = useState({});

	const getInitialData = () => {
		api.getReadyToShip().then((res) => {
			setPackagesList(res?.data?.packages);
			setShippingPartners(res?.data?.shipping_companies);
		});

		api.getSelectOptionsList('transit_company').then((res) => {
			setTransitCompanies(res?.data);
		});
	};

	useEffect(() => {
		if (packagesList?.filter((p) => shipmentData?.packages?.includes(p?.id))?.length > 0) {
			setShipToValue(packagesList?.filter((p) => shipmentData?.packages?.includes(p?.id))?.[0]?.transit_company);
		} else {
			setShipToValue(null);
		}
	}, [shipmentData]);

	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (shipmentData?.packages?.length > 0) {
			const packages = [...packagesList?.filter((p) => shipmentData?.packages?.includes(p.id))];
			let summList = [];
			packages?.forEach((pkg) => {
				pkg?.items?.forEach((item) => {
					const existing = summList?.find((s) => s.estimation_id == item.estimation_id && s.product_id == item.product_id);
					const existingIndex = summList?.findIndex((s) => s.estimation_id == item.estimation_id && s.product_id == item.product_id);
					if (existing) {
						const newItem = { ...existing, qty: Number(existing?.qty) + Number(item?.qty) };
						summList[existingIndex] = newItem;
						// summList?.map((s) => (s.estimation_id == item.estimation_id && s.product_id == item.product_id ? newItem : s));
					} else {
						summList.push(item);
					}
				});
			});
			setSummaryList();
			setSummaryList(summList);
		} else {
			setSummaryList([]);
		}
	}, [shipmentData]);

	const handleSubmit = (e) => {
		e.preventDefault();
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!(email?.length > 0) || !emailRegex.test(email)) {
			toast.error('Please enter a valid email');
			return;
		} else {
			const data = {
				email: email
			};
			setSendingEmail(true);
			setIsDisabled(true);

			toast.promise(api.sendClientLink(data), {
				loading: 'Sending...',
				success: (res) => {
					if (res?.status) {
						setIsDisabled(false);
						handleNewClose();
					}
					return res?.status ? 'Client link sent successfully' : 'Failed to send email';
				},
				error: (err) => `Failed to send email: ${err.message}`
			});
		}
	};

	return (
		<>
			<Modal show={newShow} onHide={handleNewClose} centered size="sm">
				<div className="p-4">
					<Modal.Title
						className="text-center mx-auto cstm-prtl-titl"
						style={{ marginBottom: 15, marginTop: 10, fontWeight: 500, fontFamily: 'poppins', fontSize: 16 }}
					>
						New user portal
					</Modal.Title>
					<form onSubmit={handleSubmit} className="userForm">
						<div className="row">
							<div className="col-12 p-2 refer-link">
								<input
									type="email"
									name=""
									id=""
									placeholder="Enter client email"
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									className="form-control"
									style={{ width: '75%', textAlign: 'center', fontFamily: 'poppins' }}
								/>
							</div>
							<div className="col-12 d-flex justify-content-center">
								<button
									style={{ marginTop: 20, marginBottom: 10, fontFamily: 'poppins' }}
									type="submit"
									className="btn btn-cmpny-dtls-action"
									disabled={isDisabled}
								>
									Send
								</button>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		</>
	);
};

export default ClientPortal;
