import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';

const CurrentInventoryCard = ({ inventoryValue, productCount }) => {
	const [currentInventory, setCurrentInventory] = useState([]);
	const [currentInventoryQuantity, setCurrentInventoryQuantity] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		api.getCurrentInventory().then((res) => {
			setCurrentInventory(res.total_value);
			setCurrentInventoryQuantity(res.total_qty);
			setIsLoading(false);
		});
	}, []);

	return isLoading ? null : (
		<div className="row" style={{ marginBottom: '20px' }}>
			<div className="col-12">
				<Fade>
					<div className="card" style={{ height: '100px' }}>
						<div className="card-body current-inventory text-center" style={{ paddingTop: '15px' }}>
							<a style={{ fontWeight: 500 }}>Current Inventory Value</a>
							<b style={{ fontSize: '20px', fontWeight: 700 }}>$ {currentInventory}</b>
							<c style={{ color: '#4acd8a' }}>{currentInventoryQuantity} products</c>
						</div>
					</div>
				</Fade>
			</div>
		</div>
	);
};

export default CurrentInventoryCard;
