import React, { useEffect, useRef, useState } from 'react';
import LogoSingle from 'assets/images/pmix-logo-single.svg';
import {
	MenuIcon,
	NotificationIcon,
	QAContactIcon,
	QACreditNoteIcon,
	QAEstimateIcon,
	QAInvoiceIcon,
	QAModelIcon,
	QAPackageIcon,
	QAPurchaseInvoiceIcon,
	QAShipmentIcon,
	TasksIcon
} from 'ui-component/custom-icons/icons';
import { FaFileInvoice, FaFileInvoiceDollar, FaPlus, FaUser } from 'react-icons/fa6';
import { Avatar, Menu, MenuItem } from '@mui/material';
import Sidebar from '../Sidebar';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout, storeHasNewNotification } from 'store/reducers/mainSlice';
import { Link } from 'react-router-dom';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { TbReportMoney } from 'react-icons/tb';
import { HiOutlineDeviceMobile } from 'react-icons/hi';
import { PiPackageBold } from 'react-icons/pi';
import { FaShippingFast } from 'react-icons/fa';
import NotificationDropdown from 'ui-component/NotificationDropdown/NotificationDropdown';
import ProfileMenuBlock from 'ui-component/ProfileMenuBlock';

const NavMobile = () => {
	const userIconRef = useRef(null);
	const [showMenu, setShowMenu] = useState(false);
	const [showActions, setShowActions] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);

	const hasNewNotification = useSelector((state) => state.main?.hasNewNotification);
	const profilePhoto = useSelector((state) => state.main?.user?.profile_photo_url);

	const history = useNavigate();

	const dispatch = useDispatch();

	const [showProfileMenu, setShowProfileMenu] = useState(false);

	const handleMenuButton = () => {
		setShowActions(false);
		setShowNotifications(false);
		setShowMenu(!showMenu);
	};

	const handleQuickActionButton = () => {
		setShowMenu(false);
		setShowNotifications(false);
		setShowActions(!showActions);
	};

	const handleCloseMenuButton = () => {
		setShowMenu(false);
	};

	useEffect(() => {
		api.userHasUnreadNotification().then((res) => {
			if (res.status) {
				dispatch(storeHasNewNotification(res?.data?.has_unread));
			}
		});
	}, []);

	const handleShowNotification = () => {
		setShowMenu(false);
		setShowActions(false);
		setShowNotifications(!showNotifications);
	};

	return (
		<>
			<div className={`navMobileMenu ${showMenu ? 'show-mobile' : ''}`}>
				<Sidebar mobile mobileShowMenu={showMenu} closeMobileMenu={handleCloseMenuButton} />
			</div>
			<div className="navMobile">
				<div className="action-item">
					<span onClick={handleMenuButton}>
						<MenuIcon width={20} height={20} />
					</span>
				</div>
				<div className="action-item">
					<span className="icon-noti" onClick={handleShowNotification}>
						<NotificationIcon width={20} height={20} />
						{hasNewNotification && <span className="badge badge-danger badge-counter noti-badge">&nbsp;</span>}
					</span>
					{showNotifications && (
						<div className="mobile-noti-view">
							<NotificationDropdown
								closeNotificationBox={() => {
									setShowNotifications(false);
								}}
							/>
						</div>
					)}
				</div>
				{/* <div className="action-item quick-action">
					<div className="icon-circle">
						<span onClick={handleQuickActionButton}>
							<FaPlus />
						</span>
					</div>
					<div className={`mobile-quick-action-popover-container ${showActions ? 'show' : ''}`}>
						<div className="quick-action-popover">
							<div className="quick-action-item-section">
								<div className="link-list">
									<PermissionAllowed permissions={['Create Estimation (Page)']}>
										<Link to={'/sales/invoices/create'} onClick={() => setShowActions(false)} className="link-list-item">
											<span className="qa-icon">
												<QAEstimateIcon width="15" height="15" />
											</span>
											<p>Estimate</p>
										</Link>
									</PermissionAllowed>

									<PermissionAllowed permissions={['Create Estimation (Page)']}>
										<Link to={'/sales/invoices/create/invoice'} onClick={() => setShowActions(false)} className="link-list-item">
											<span className="qa-icon">
												<QAInvoiceIcon width="15" height="15" />
											</span>
											<p>Invoice</p>
										</Link>
									</PermissionAllowed>

									<PermissionAllowed permissions={['Create Estimation (Page)']}>
										<Link to={'/sales/invoices/create/credit-note'} onClick={() => setShowActions(false)} className="link-list-item">
											<span className="qa-icon">
												<QACreditNoteIcon width="15" height="15" />
											</span>
											<p>Credit Note</p>
										</Link>
									</PermissionAllowed>

									<PermissionAllowed permissions={['Display Purchase List (Page)']}>
										<Link to={'/purchase-invoice'} onClick={() => setShowActions(false)} className="link-list-item">
											<span className="qa-icon">
												<QAPurchaseInvoiceIcon width="15" height="15" />
											</span>
											<p>Purchase Invoice</p>
										</Link>
									</PermissionAllowed>
								</div>
							</div>
							<div className="quick-action-item-section">
								<div className="link-list">
									<Link to={'/settings/model/create'} onClick={() => setShowActions(false)} className="link-list-item">
										<span className="qa-icon">
											<QAModelIcon width="15" height="15" />
										</span>
										<p>Model</p>
									</Link>
									<PermissionAllowed permissions={['Create Contact (Page)']}>
										<Link to={'/contacts/create'} onClick={() => setShowActions(false)} className="link-list-item">
											<span className="qa-icon">
												<QAContactIcon width="15" height="15" />
											</span>
											<p>Contact</p>
										</Link>
									</PermissionAllowed>
									<Link to={'/operations/packing?create=packing'} onClick={() => setShowActions(false)} className="link-list-item">
										<span className="qa-icon">
											<QAPackageIcon width="15" height="15" />
										</span>
										<p>Packing</p>
									</Link>
									<Link to={'/shipments?create=shipment'} onClick={() => setShowActions(false)} className="link-list-item">
										<span className="qa-icon">
											<QAShipmentIcon width="15" height="15" />
										</span>
										<p>Shipment</p>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				<div className="action-item">
					<TasksIcon width={20} height={20} />
				</div>
				<div className="action-item" style={{ position: 'relative' }}>
					<div
						className="user-icon-item"
						onClick={() => {
							setShowProfileMenu(!showProfileMenu);
						}}
						ref={userIconRef}
					>
						<Avatar
							sx={{ width: 30, height: 30 }}
							src={profilePhoto ? profilePhoto : 'https://cdn.iconscout.com/icon/free/png-256/free-avatar-380-456332.png'}
						/>
					</div>
					{showProfileMenu && (
						<ProfileMenuBlock
							onCloseMenu={() => {
								setShowProfileMenu(false);
							}}
							mainIconRef={userIconRef}
							mobile
						/>
					)}
					{/* <PopupState variant="popover" popupId="demo-popup-menu">
						{(popupState) => (
							<React.Fragment>
								
								<Menu
									{...bindMenu(popupState)}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right'
									}}
									transformOrigin={{
										vertical: 'bottom',
										horizontal: 'left'
									}}
								>
									<MenuItem
										onClick={(e) => {
											popupState.close(e);
											history('user/profile');
										}}
									>
										Profile
									</MenuItem>
									<MenuItem
										onClick={(e) => {
											popupState.close(e);
											dispatch(setLogout(true));
										}}
									>
										Logout
									</MenuItem>
								</Menu>
							</React.Fragment>
						)}
					</PopupState> */}

					{/* <Dropdown style={{ padding: 0 }}>
						<Dropdown.Toggle as={CustomToggle} style={{ padding: 0 }}>
							<div className="user-icon-item">
								<Avatar sx={{ width: 30, height: 30 }} src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-380-456332.png" />
							</div>
						</Dropdown.Toggle>

						<Dropdown.Menu className="dropdown-menu dropdown-menu-right shadow animated--grow-in">
							<Dropdown.Item className="dropdown-item" href="#" onClick={() => history('user/profile')}>
								<i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" /> Profile
							</Dropdown.Item>
							<div className="dropdown-divider" />
							<Dropdown.Item className="dropdown-item" href="#" onClick={() => }>
								<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" /> Logout
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown> */}
				</div>
			</div>
		</>
	);
};

export default NavMobile;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
	return (
		<>
			<Link
				className="nav-link dropdown-toggle "
				to="#"
				id="userDropdown"
				role="button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				onClick={onClick}
			>
				{children}
			</Link>
		</>
	);
});
