import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaSpinner, FaTimes, FaArrowRight } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { CloseIcon, IdenifyIcon, ArrowRightIcon, CommentInfoIcon, QouteStart, QouteEnd } from 'ui-component/custom-icons/icons'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CommentBadge from '../components/CommentBadge';
import { ConstructionOutlined } from '@mui/icons-material';

const GradingModal = ({ openPopup, details, handleClose, handleProcessGrading }) => {

    const [selectedRepairType, setSelectedRepairType] = useState(null);
    const [gradeList, setGradeList] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    

    useEffect(() => {

        api.getSelectOptionsList('grade').then((res) => {
            if (res?.data) {
                const grades = res?.data?.map((item) => { 
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                setGradeList(grades);
            }
        });
        initializeItems()
    }, []);
    

    const initializeItems = () => {
        // console.log('details', details)
        const checkedItems = details?.filter((item) => item.isChecked);
        // // Do something with checkedItems
        // console.log(checkedItems);
        setSelectedItems(checkedItems);
    }

    const handleChangeGrade = (e, itemId) => {
        const updatedItems = selectedItems.map((item) => {
            if (item.id === itemId) {
                return { ...item, oldGradeId: e.id, oldGradeName: e?.label };
            }
            return item;
        });
        setSelectedItems(updatedItems);
    }
    // const handleChangeCurrency = (currency) => {
    //     setSelectedRepairs({
    //         ...selectedRepairs,
    //         repairCurrency: currency.value
    //     })
    //     setDefaultCurrency(currency.label);
    // }

    // const handleChangeCost = (e) => {
    //     const cost = parseFloat(e.target.value);

    //     if (!isNaN(cost)) {
    //         setSelectedRepairs({
    //             ...selectedRepairs,
    //             repairCost: cost
    //         })
    //     } else {
    //         setSelectedRepairs({
    //             ...selectedRepairs,
    //             repairCost: parseFloat('0')
    //         })
    //     }
    // };

    // const handleChangeAssignedPerson = (e) => {
    //     setSelectedRepairs({
    //         ...selectedRepairs,
    //         'assignedId': e?.id,
    //         'assignedName': e?.label,
    //     })
    //     setSelectedAssignedPerson(e)
    // }
    
    const showCommentSection = (index) => {
        setSelectedItems(prevItems => 
            prevItems.map((item, i) => 
                i === index ? { ...item, showComment: true } : item
            )
        );
    }
    
    const closeComment = (index) => {
        setSelectedItems(prevItems => 
            prevItems.map((item, i) => 
                i === index ? { ...item, showComment: false, comment: '' } : item
            )
        );
    }
    const updateCommentField = (e, index) => {
        setSelectedItems(prevItems => 
            prevItems.map((item, i) => 
                i === index ? { ...item, comment: e?.target?.value } : item
            )
        );
    }

    const saveComment = (index) => {
        setSelectedItems(prevItems => 
            prevItems.map((item, i) => 
                i === index ? { ...item, showComment: false } : item
            )
        );
    }

    const submitForm = () => {
        handleProcessGrading(selectedItems);
        // if (selectedRepairs?.repairTypeId == '') {
        //     toast.error('Please select a repair type');
        //     return;
        // }
        // if (selectedRepairs?.repairCost === undefined ) {
        //     toast.error('Please enter the repair cost');
        //     return;
        // }
        // if (selectedRepairs?.assignedId == '') {
        //     toast.error('Please select an assigned person');
        //     return;
        // }
        // if (type == 'save') {
        //     handleAddRepairs(selectedRepairs);
        //     handleClose();
        // } else {
        //     handleAddRepairs(selectedRepairs);
            
        //     setSelectedRepairType(null);
        //     setSelectedAssignedPerson(null);
        //     setSelectedRepairs({
        //         'operationItemId': selectedItem?.id,
        //         'repairTypeId': '',
        //         'repairName': '',
        //         'repairCurrency': currencies?.[0]?.value,
        //         'repairCost': '',
        //         'assignedId': '',
        //         'assignedName': '',
        //     })
        // }
    }
    

    return (
        <>
            <Modal show={openPopup}  centered size="md">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center">
                                        <IdenifyIcon height={'80px'} width={'80px'} />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Identify Grading
                                    </div>
                                    <div>
                                        Select new grade for selected IMEIs
                                    </div>
                                </div>
                            </div>
                            {console.log('selectedItems to', selectedItems)}
                            {
                                selectedItems?.length > 0 && (
                                    <>
                                        {
                                            selectedItems?.map((item, index) => (
                                                <>
                                                    <div className="row align-items-top">
                                                        <div className="col-12 text-left " style={{ padding: '10px 0' }}>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    {
                                                                        item?.showComment == true ? (
                                                                            <>
                                                                                <div className="comment-section" style={{ marginTop: '-15px', marginBottom: '-10px' }}>
                                                                                                                                                     
                                                                                    <div className="section-head row" style={{ paddingLeft: '0', marginBottom: '5px' }}>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '13px' }} className="col-md-4">
                                                                                            Comment
                                                                                        </div>
                                                                                        <div className="actions col-md-8">
                                                                                            <div 
                                                                                                className='cursor-pointer'
                                                                                                onClick={() => closeComment(index)}
                                                                                            >
                                                                                                <CloseIcon height={'12px'} width={'12px'} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        (item?.hasComment) ? (
                                                                                            <>
                                                                                                <div className="row" style={{ marginBottom: '10px' }}>
                                                                                                    <div className="col-12 d-flex">
                                                                                                        <div style={{marginTop: '-5px' }}><QouteStart /></div>
                                                                                                        {item?.comment}
                                                                                                        <div style={{marginTop: '-5px' }}><QouteEnd /></div>
                                                                                                    </div>
                                                                                                </div>         
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                    
                                                                                                <div className="row">
                                                                                                    <div className="col-12">
                                                                                                        <textarea
                                                                                                            className='form-control'
                                                                                                            rows="2"
                                                                                                            value={item?.comment}
                                                                                                            onChange={(e) => {
                                                                                                                updateCommentField(e, index);
                                                                                                            }}
                                                                                                            style={{ resize: 'none' }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>                                                          
                                                                                                <div className="section-head row" style={{ paddingLeft: '0 !important'}}>
                                                                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '13px' }} className="col-md-4">
                                                                                                        
                                                                                                    </div>
                                                                                                    <div className="actions col-md-8">
                                                                                                        <button type="button" onClick={() => saveComment(index)} className="btn btn-primary btn-outlined-main">
                                                                                                            Save Comment 
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                    
                                                                                <div className="row detail-section">
                                                                                    <div className="col-12 d-flex justify-content-between">
                                                                                        <div className="font15">
                                                                                                {item?.imei}
                                                                                        </div>
                                                                                        <div>
                                                                                            <div
                                                                                                className="cursor-pointer"
                                                                                                // className="btn btn-main-primary btn-outlined-main"
                                                                                                style={{ marginRight: '8px' }}
                                                                                                // disabled={!selectedRepairs}
                                                                                                onClick={() => {
                                                                                                    showCommentSection(index);
                                                                                                }}
                                                                                                >
                                                                                                    {console.log('itemcomment', item)}
                                                                                                    <CommentInfoIcon height='20' width='20' />
                                                                                                    {
                                                                                                        (item?.hasComment || (item?.comment != '' && item?.comment != null && item?.comment !== undefined)) && (
                                                                                                            <>
                                                                                                                <CommentBadge count="1" />
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12 textGray" style={{ margin: '5px 0' }}>
                                                                                        {item?.productName} {item?.colorName ? `${item?.colorName}` : `` }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-5">
                                                                                        <input type="text" value={item?.newGradeName} disabled className='form-control' />
                                                                                    </div>
                                                                                    <div className="col-2 d-flex justify-content-center align-items-center">
                                                                                        <ArrowRightIcon height='28px' width='28px' />
                                                                                    </div>
                                                                                    <div className="col-5">
                                                                                        <Select
                                                                                            options={gradeList}
                                                                                            className="rs-container"
                                                                                            classNamePrefix="rs"
                                                                                            placeholder="Select or type..."
                                                                                            isMulti={false}
                                                                                            onChange={(e) => {
                                                                                                handleChangeGrade(e, item?.id)
                                                                                            }}
                                                                                            value={gradeList?.find(option => option.id === item.oldGradeId) || null}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </>
                                )
                            }
                            {/* <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0 0 0' }}>
                                    <label>Repair Cost</label>
                                    <div className="custom-input-group">

                                        <InputGroup className="mb-3 d-flex">
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title={defaultCurrency}
                                                id="input-group-dropdown-1"
                                            >
                                                {
                                                    currencies.map((currency) => { 
                                                        return (
                                                            <Dropdown.Item
                                                                href="#"
                                                                key={currency.value}
                                                                onClick={() => { 
                                                                    handleChangeCurrency(currency)
                                                                }}
                                                            >
                                                                {currency.label}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </DropdownButton>
                                            <input
                                                type="text"
                                                id="input-example"
                                                name="input-name"
                                                className="form-control"
                                                placeholder="0.00"
                                                onChange={(e) => {
                                                    handleChangeCost(e)
                                                }}
                                                // value={selectedRepairs?.repairCost}
                                                autoComplete={'off'}
                                                autoFocus
                                            />
                                        </InputGroup>
                                    </div>

                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0' }}>
                                    <label>Assign to</label>
                                    <Select
                                        options={users}
                                        className="rs-container"
                                        placeholder="Select or type..."
                                        classNamePrefix="rs"
                                        isMulti={false}
                                        isClearable={false}
                                        onChange={(e) => {
                                            handleChangeAssignedPerson(e)
                                            
                                        }}
                                        value={selectedAssginedPerson}
                                    />
                                </div>
                            </div> */}
                            <div className="btnarea" style={{ justifyContent: 'center', marginTop: '20px' }}>

                                    <div className="row">
                                        <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                            <button
                                                className="btn btn-main-primary btn-outlined-main"
                                                style={{ marginRight: '8px' }}
                                                // disabled={!selectedRepairs}
                                                onClick={() => {
                                                    submitForm();
                                                }}
                                            >
                                                Save 
                                            </button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default GradingModal;
