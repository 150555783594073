import React, { useEffect, useState } from 'react';
import ProcessIndicator from '../ProcessIndicator';
import { CompanyDetailsProgresses } from 'assets/helperdata/client/company-details-progress';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import config from '../../../../config';

import Select from 'react-select';

const VerificationCompleted = () => {
	const history = useNavigate();
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div className="main-page-client" style={{ padding: isMobile ? 0 : 10, height: '100%' }}>
			<div
				className="company-details-client-content-2"
				style={{ height: isMobile ? '100%' : 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<div className="verify-div">
					<svg
						clip-rule="evenodd"
						fill-rule="evenodd"
						height="60"
						image-rendering="optimizeQuality"
						shape-rendering="geometricPrecision"
						text-rendering="geometricPrecision"
						viewBox="0 0 512 512"
						width="60"
						xmlns="http://www.w3.org/2000/svg"
						id="fi_7490520"
						fill="#34C880"
					>
						<g id="Layer_x0020_1">
							<path d="m360 512h-312c-3 0-6-3-6-6v-165c0-52 26-100 69-129 2-2 5-1 7 1 45 51 126 51 170 0 2-2 6-3 8-1 22 15 40 35 52 58l11-2 88 20c2 1 3 1 4 3 13 22 19 47 19 74 0 68-45 129-110 147zm-42-74c-7 0-13-3-16-9l-23-39c-8-16 7-32 21-27 4 1 9 5 11 9l13 23 87-50c21-13 40 20 19 31l-103 60c-3 2-6 2-9 2zm-29-54 23 39c2 4 7 4 9 3l103-60c7-6-1-16-7-11l-92 53c-3 2-7 1-8-2l-16-28c-6-8-15-1-12 6zm-235 116h62v-134c0-8 12-8 12 0v134h198c-50-26-81-79-81-135 0-26 7-51 20-74 1-2 2-3 4-3l67-15c-11-19-25-36-42-48-49 51-132 51-181 0-37 27-59 70-59 116zm304 0c87-26 124-127 84-201l-84-19-84 19c-16 29-16 46-17 75 4 59 44 110 101 126zm-155-286c-58 0-106-49-106-107 0-59 48-107 106-107 59 0 107 48 107 107s-48 107-107 107zm0-202c-52 0-94 43-94 95s42 95 94 95 95-43 95-95-43-95-95-95z"></path>
						</g>
					</svg>
					<h3 style={{ fontSize: 22, marginTop: 15 }}>Congratulations!</h3>
					<p style={{ fontSize: 18 }}>You completed the verification process</p>
					<span style={{ fontSize: 14 }}>Please wait approval from {config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.client_name}</span>
				</div>
			</div>
		</div>
	);
};

export default VerificationCompleted;
