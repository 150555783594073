import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPlus } from 'react-icons/fa';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';


const Summary = ({ operationDetails }) => {
    const [summaryDetails, setSummaryDetails] = useState([]);

    useEffect(() => {
    
        const summaryMap = {};
    
        operationDetails?.products?.forEach(product => {
            const key = `${product.product_name}-${product.color_name}`;
            if (summaryMap[key]) {
                summaryMap[key].quantity += product.quantity;
            } else {
                summaryMap[key] = {
                    name: product.product_name,
                    color: product.color_name,
                    quantity: product.quantity
                };
            }
        });
        
        // console.log('summaryMap', summaryMap);
        const newSummaryDetails = Object.values(summaryMap);
        console.log('newSummaryDetails', newSummaryDetails);
    
        setSummaryDetails(newSummaryDetails);
    }, [operationDetails]);
    
    return (
        <>
            
            
            <div className="">
                                                
                <div className="form-group">
                    <h5 style={{ marginLeft: '10px', marginTop: '10px' }}>Summary</h5>
                </div>
                <div className="row">
                    <div className="col-12">


                        {
                            summaryDetails?.length > 0 ? (
                                <>
									<table className="table pm-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Product</th>
                                                <th scope="col" style={{ width: '80px' }}>Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                summaryDetails?.map((product, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div>{product.name}</div>
                                                            {
                                                                product?.color && (
                                                                    <>
                                                                        
                                                                        <div className="textGray">{product.color}</div>
                                                                    </>
                                                                )
                                                            }
                                                        </td>
                                                        <td>{ parseInt(product.quantity).toLocaleString() }</td>
                                                    </tr>
                                                ))    
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td className='font600'>
                                                    Total
                                                </td>
                                                <td className='font600'>
                                                    { parseInt(summaryDetails?.reduce((acc, product) => acc + parseInt(product.quantity), 0)).toLocaleString() } pcs
                                                </td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </>
                            ) : (
                                <>
                                    <div className="not-found-container cstm-not-found" style={{ height: '300px' }}>
                                        <div className="not-found-icon">
                                            <img src={NoRecordFoundIcon} style={{ height: '100px', width: '100px' }} />
                                        </div>
                                        <h6 className="not-found-text">Summary unavailable</h6>
                                    </div>
                                </>
                            )
                        }



                    </div>
                </div>

                


            </div>


        </>
    );
};

export default Summary;
