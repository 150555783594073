import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { CloseIcon, ColoredMoveIcon, ArrowRightIcon, CommentInfoIcon, QouteStart, QouteEnd } from 'ui-component/custom-icons/icons'

import Select from 'react-select';
import StatusBadge from 'ui-component/StatusBadge';
import { toast } from 'react-hot-toast';
import { FaSpinner } from 'react-icons/fa';

const MoveToPicklistModal = ({ openPopup, details, handleClose, handleReloadDataFromPicklist }) => {

    const [selectedRepairType, setSelectedRepairType] = useState(null);
    const [gradeList, setGradeList] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [salesOrderList, setSalesOrderList] = useState([]);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    
    const [formDetails, setFormDetails] = useState({
        operation_id: null,
        sales_order_id: null,
    });

    useEffect(() => {
        initializeItems()
    }, []);
    

    const initializeItems = () => {
        
        api.getSelectOptionsList('sales_order_with_invoice').then((res) => {
            if (res?.data) {
                const salesOrders = res?.data?.map((item) => { 
                    item.label = item.sales_order_number;
                    item.value = item.id;
                    return item;
                });
                setSalesOrderList(salesOrders);
            }
        });

        setFormDetails({
            ...formDetails,
            operation_id: details?.id,
        })

    }

    const handeChangeSalesOrder = (value) => {
        setFormDetails({
            ...formDetails,
            sales_order_id: value,
        })
    }
    
    const submitForm = () => {
        if (!formDetails.sales_order_id) {
            toast.error('Please select a sales order');
            return;
        } else if (!formDetails.operation_id) {
            toast.error('No operation selected');
            return;
        }
        setIsFormDisabled(true)
        toast.promise(
            api
                .createPicklistFromOperations(formDetails)
                .then(
                    (res) => {
                        setIsFormDisabled(false)
                        if (res.status) {
                            handleReloadDataFromPicklist()
                        }
    
                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Creating Picklist...',
                success: (data) => `${data.message}`,
                error: <b>Failed to create picklist</b>
            }
        );
    }


    return (
        <>
            <Modal show={openPopup}  centered size="sm">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center">
                                        <ColoredMoveIcon height={'60px'} width={'60px'} />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 10px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Move to Picklist
                                    </div>
                                    <div>
                                        Select which sales order to move <br />the selected items to.
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0' }}>
                                    <label>Sales Order</label>
                                    
                                    <Select
                                        options={salesOrderList}
                                        className="rs-container"
                                        classNamePrefix="rs"
                                        placeholder="Select or type..."
                                        isMulti={false}
                                        formatOptionLabel={({ label, optionAttribute }) => (
                                            <div dangerouslySetInnerHTML={{ __html: label }}></div>
                                        )}
                                        onChange={(e) => {
                                            handeChangeSalesOrder(e?.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '20px 0px 0px 0px' }}>
                                    
                                    <button
                                        className={`${isFormDisabled && 'disabled' } btn btn-block btn-main-primary btn-outlined-main-primary`}
                                        disabled={isFormDisabled}
                                        onClick={() => {
                                            if (!isFormDisabled) {
                                                submitForm()
                                            }
                                        }}
                                    >
                                        {
                                            isFormDisabled ? (
                                                <>
                                                    <FaSpinner className="fa-spin" /> Processing...
                                                </>
                                            ) : (
                                                <>
                                                    Create Picklist
                                                </>
                                            )
                                        }
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default MoveToPicklistModal;
