import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {


    getPurchaseInvoices() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/purchasing`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    getPurchaseInvoice(id) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/purchase-invoice/${id}`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    getPurchaseInvoiceView(id) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/purchase-invoice/view/${id}`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    getPurchaseOrderDetails(id) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/purchasing/view/${id}`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

	submitPurchaseOrderReceipt(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchasing/receipts`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},

    markPurchased(id, fmData) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .put(`${base}/api/purchase-invoice/view/${id}`, fmData, {
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    markAsPurchased(id, fmData) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .put(`${base}/api/purchasing/${id}/mark-purchased`, fmData, {
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    assignPurchaseInvoiceNumber(id, fmData) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .put(`${base}/api/purchasing/${id}/assign-purchase-invoice-numberpurchased`, fmData, {
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    updatePurchaseOrderInvoice(id, fmData) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .put(`${base}/api/purchasing/${id}/update-purchase-invoice`, fmData, {
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

	postPurchaseOrder(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchasing/create`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updatePurchaseOrder(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/purchasing/${id}/update`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	uploadPurchaseOrderAttachment(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchasing/${id}/upload`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
	addPurchaseOrderPayment(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchasing/${id}/payment`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    deletePurchaseOrderAttachment(id) { 
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/purchasing/${id}/delete-attachment`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    deletePurchaseOrderPayment(id) { 
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/purchasing/${id}/delete-payment`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    downloadPurchaseOrderExcel(id) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/purchasing/${id}/download-excel`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    downloadPurchaseOrderPdf(id) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/purchasing/${id}/download-pdf`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    deletePurchaseInvoice(id) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .delete(`${base}/api/purchase-invoice/${id}`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    getPurchaseOrders() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/purchase-invoice/purchase-order`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    filterPurchaseOrders(filters, search = null) {
        let url = `${base}/api/purchasing?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    filterPurchaseInvoice(filters, search = null) {
        let url = `${base}/api/purchase-invoice?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    getPurchaseInvoiceFilter() {
        return this.handelAlert((res, rej, loadObj) => {
            const url = `${base}/api/purchase-invoice/filter/get`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    createPurchaseInvoice(fmData) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .post(`${base}/api/purchase-invoice/create`, fmData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    uploadInvoiceAttachment(id, data) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .post(`${base}/api/uploads/purchase-invoice/attachments`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    res(rej.response.data);
                });
        }, false);
    },

    updatePurchaseInvoice(id, fmData) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .put(`${base}/api/purchase-invoice/${id}`, fmData, {
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    newPurchaseInvoiceNote(pi_id, data) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .post(`${base}/api/purchase-invoice/${pi_id}/note`, data, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    removePurchaseInvoiceNote(pi_id, note_id) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .delete(`${base}/api/purchase-invoice/${pi_id}/note/${note_id}`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    addPurchaseInvoicePayment(pi_id, data) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .post(`${base}/api/purchase-invoice/${pi_id}/add-payment`, data, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    getAllproducts(search = null) {
        return this.handelAlert((res, rej, loadObj) => {
            const url = search ? `${base}/api/purchasing/product-list?search=${search}` : `${base}/api/purchasing/product-list`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    getReceiptDetails(id) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/purchasing/${id}/receipt`, {
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
	getPurchaseOrderFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchasing/filter`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	downloadPurchaseOrderAttachment(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchasing/${id}/download-attachment`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
};
