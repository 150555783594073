import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';

export default function MentionDisplay({ state }) {
	const ref = useRef(null);
	const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));

	const { plugins } = useMemo(() => {
		const mentionPlugin = createMentionPlugin({
			mentionComponent: ({ className, mention, children }) => {
				return <span className={`${className} mentioned-user`}>{children}</span>;
			}
		});
		// eslint-disable-next-line no-shadow
		const { MentionSuggestions } = mentionPlugin;
		// eslint-disable-next-line no-shadow
		const plugins = [mentionPlugin];
		return { plugins, MentionSuggestions };
	}, []);

	return <Editor editorKey={'editor'} editorState={editorState} onChange={setEditorState} plugins={plugins} ref={ref} readOnly />;
}
