import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CgNotes } from 'react-icons/cg';
import { FaHistory } from 'react-icons/fa';
import { FaCheck, FaPlus } from 'react-icons/fa6';
import { GrHistory } from 'react-icons/gr';
import { IoIosArrowDown } from 'react-icons/io';
import { LuPaperclip } from 'react-icons/lu';
import { MdHistory } from 'react-icons/md';
import { Link } from 'react-router-dom';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import MentionInput from 'ui-component/MentionInput/MentionInput';
import { isJson } from 'helpers/common.helper';
import { convertFromRaw } from 'draft-js';
import MentionDisplay from 'ui-component/MentionInput/MentionDisplay';
import { v4 as uuidv4 } from 'uuid';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import ReactSelect from 'react-select';

const noteType = [
	{ label: 'Note', value: 'note' },
	// { label: 'Todo', value: 'todo' }
];

function Notes({ notes, addNote, deleteNotePermission, removeNote, toggleNoteExpand, type = '', parentId = null }) {
	const fileSelectorRef = useRef(null);
	const selectRef = useRef(null);
	const saveRef = useRef(null);
	const inputRef = useRef(null);
	const [noteInputValue, setNoteInputValue] = useState('');
	const [noteInputPlain, setNoteInputPlain] = useState('');
	const [noteMentions, setNoteMentions] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedNoteType, setSelectedNoteType] = useState('note');

	const [inputFocus, setInputFocus] = useState(false);

	useEffect(() => {
		console.log('Attachments', attachments);
	}, [attachments]);

	const handleFileSelectorChange = (e) => {
		let theApi = null;
		let formData = null;

		switch (type) {
			case 'quotation':
				formData = new FormData();
				for (let i = 0; i < files.length; i++) {
					formData.append('note_file', files[i]);
				}
				theApi = api.uploadEstimateNoteAttachment;
				break;

			case 'shipment':
				formData = new FormData();
				for (let i = 0; i < files.length; i++) {
					formData.append('note_file', files[i]);
				}
				theApi = api.uploadEstimateNoteAttachment;
				break;

			default:
				break;
		}

		theApi && theApi(parentId, formData);

		// console.log(e?.target?.files?.map);
	};

	const [suggestionMenu, setSuggestionMenu] = useState([]);

	useEffect(() => {
		// if (inputRef.current) {
		// 	const range = document.createRange();
		// 	range.selectNodeContents(inputRef.current);
		// 	range.collapse(false);
		// 	const sel = window.getSelection();
		// 	sel?.removeAllRanges();
		// 	sel?.addRange(range);
		// }
		// console.log('Note Input Value', noteInputValue);
		// const lastWord = noteInputValue?.split(' ')?.[noteInputValue?.split(' ')?.length - 1];
		// if (lastWord.includes('@') && lastWord.replace('@', '')?.length > 0) {
		// 	api.getMentionUser(lastWord.replace('@', '')).then((res) => {
		// 		setSuggestionMenu(res?.data);
		// 	});
		// } else {
		// 	setSuggestionMenu([]);
		// }
	}, [noteInputValue]);

	const [clearInput, setClearInput] = useState(false);

	const clearInputField = () => {
		setClearInput(true);
		setTimeout(() => {
			setClearInput(false);
		}, 1);
	};

	return (
		<>
			<div className="form-group mb-2 mt-3">
				<label htmlFor="inputName" className="est-labels">
					Add Note
				</label>

				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'end' }}>
					<div style={{ width: '100%', position: 'relative' }}>
						<MentionInput
							onContentChange={(e, x, y) => {
								setNoteInputValue(JSON.stringify(e));
								setNoteInputPlain(x);
								setNoteMentions(y);
							}}
							clear={clearInput}
							inputFocus={inputFocus}
							setInputFocus={(e) => {
								setInputFocus(e);
							}}
							triggerSave={() => {
								if(noteInputPlain.length > 0) {
									addNote && addNote(noteInputValue, selectedNoteType, noteMentions, noteInputPlain);
									clearInputField();
								}
							}}
						/>
						{/* <div
							className="form-control"
							style={{
								height: 'auto',
								flex: 1,
								flexGrow: 0,
								flexShrink: 0
							}}
							ref={inputRef}
							contentEditable
							onInput={(e) => setNoteInputValue(e.currentTarget.innerHTML || '')}
							onBlur={(e) => setNoteInputValue(e.currentTarget.innerHTML || '')}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
							dangerouslySetInnerHTML={{
								__html: noteInputValue.replace(/user:(\d+):\((.+?)\)/g, '<span class="mention" data-id="$1">@$2</span>')
							}}
						>

						</div> */}
						{/* 
						<Mentions>
							<Option value="light">Light</Option>
							<Option value="bamboo">Bamboo</Option>
							<Option value="cat">Cat</Option>
						</Mentions> */}
						{/* 
						<MentionsInput
							value={noteInputValue}
							// onChange={(e) => {
							// 	setNoteInputValue(e);
							// }}
						>
							<Mention trigger="@" data={suggestionMenu} renderSuggestion={getSuggestions} />
						</MentionsInput> */}

						{suggestionMenu?.length > 0 && (
							<div
								style={{
									position: 'absolute',
									top: 'calc(100% + 5px)',
									background: '#fff',
									border: '1px solid #ccc',
									width: '100%'
								}}
							>
								{suggestionMenu?.map((user, index) => (
									<div key={index} style={{ padding: '5px', cursor: 'pointer' }}>
										{user?.first_name} {user?.last_name}
									</div>
								))}
							</div>
						)}
					</div>
					<input type="file" hidden className="d-none" ref={fileSelectorRef} onChange={handleFileSelectorChange} />

					<div
						className="d-flex w-100 justify-content-between align-items-center overflow-hidden"
						style={{ maxHeight: !!noteInputPlain ? '200px' : '0px', transition: 'all 0.3s ease-in-out' }}
					>
						<ReactSelect
							ref={selectRef}
							menuPosition={'fixed'}
							options={noteType}
							value={noteType?.find((item) => item?.value == selectedNoteType)}
							onChange={(e) => {
								setSelectedNoteType(e?.value);
							}}
							className="rs-container-est-white"
							classNamePrefix="rs"
							placeholder=""
							styles={{
								container: (provided) => ({ ...provided, width: '100px' })
							}}
						/>
						<button
							ref={saveRef}
							onClick={() => {
								addNote && addNote(noteInputValue, selectedNoteType, noteMentions, noteInputPlain);
								clearInputField();
							}}
							className="btn btn-sm btn-main-primary py-0 align-self-stretch"
						>
							<FaCheck /> Save
						</button>
					</div>
				</div>
			</div>

			<div className="estimate-notes mt-3">
				{notes?.length > 0 &&
					Object.keys(_.groupBy(notes, 'date'))?.map((group) => (
						<>
							<h6 className="date-group">{moment(group).format('MMMM DD, YYYY')}</h6>
							{[...notes]?.map((note, index) =>
									note?.date == group && (
										<div className="estimate-note" key={uuidv4()}>
											<div className="note-content">
												<div className="estimate-note-icon">{note?.isDeletable == 1 ? <CgNotes /> : <GrHistory />}</div>
												{isJson(note?.details) && JSON.parse(note?.details)?.blocks ? (
													<div className="estimate-note-value">
														<MentionDisplay state={convertFromRaw(JSON.parse(note?.details))} />
													</div>
												) : (
													<div
														className="estimate-note-value"
														dangerouslySetInnerHTML={{
															__html: note?.details
														}}
													/>
												)}

												<div className="estimate-note-button">
													<span
														className={`btnEdit plain-iconbtn ${note?.expand ? 'expanded' : ''}`}
														style={{
															height: '22px',
															width: '22px',
															minWidth: '22px',
															background: '#fff',
															border: 'none'
														}}
														onClick={() => {
															toggleNoteExpand(index);
														}}
													>
														<IoIosArrowDown />
													</span>
												</div>
											</div>
											<div className={note?.expand ? 'note-extra-content visible' : 'note-extra-content'}>
												<p>
													At {note?.time} by {note?.user_name}
												</p>
												{note?.isDeletable == 1 ? (
													<PermissionAllowed permissions={[...deleteNotePermission]}>
														<Link
															to="#"
															onClick={() => {
																removeNote && removeNote(index);
															}}
														>
															Delete Note
														</Link>
													</PermissionAllowed>
												) : (
													''
												)}
											</div>
										</div>
									)
							)}
						</>
					))}
			</div>
		</>
	);
}

export default Notes;
