import React, { useEffect, useState } from 'react';
import editIcon from 'assets/images/icons/ic_edit.svg';
import Loader from 'ui-component/Loader';
import filterIcon from 'assets/images/icons/filter.svg';
import Scroller from 'ui-component/Scroller';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, storeInLocalStore, setIndexOpen } from 'store/reducers/mainSlice';
import { FaCheck, FaEdit, FaEye, FaPen, FaPlus, FaSave } from 'react-icons/fa';
import { Skeleton, Tooltip } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import CurrencyValue from 'ui-component/CurrencyValue';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import StatusBadge from 'ui-component/StatusBadge';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { NoItemAvailableIcon } from 'ui-component/custom-icons/icons';

import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';


function getStatusBorderColorClass(paidStatus) {
	if (paidStatus === 'Paid') {
		return 'bd-left-success';
	} else if (paidStatus === 'Unpaid') {
		return 'bd-left-danger';
	} else if (paidStatus === 'Partial') {
		return 'bd-left-warning';
	} else {
		return 'bd-left';
	}
}

import { CommonDateOnlyFormat } from 'helpers/common.helper';

const OrderItemsTable = ({
	row,
	isLoading,
	currentStatus,
	deleteDraft,
	deleteEstimate,
	acceptpopup,
	changeStatus,
	addtopurchagelist,
	addrp,
	toggleShowDetails,
	selectedEstimate,
	setSelectedEstimate,
	viewDetails,
	updateEstimate
}) => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const mainStatus = currentStatus;
	const iniopen = useSelector((state) => state.main.user?.iniopen);
	const handleToggle = (estimate) => {
		if (selectedEstimate?.id === estimate?.id) {
			setSelectedEstimate(null);
			toggleShowDetails(null);
		} else {
			setSelectedEstimate(estimate);
			toggleShowDetails(estimate);
		}
		dispatch(storeInLocalStore({ iniopen: '' }));
	};
	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		zIndex: 1
	};
	const tableFooterStyles = {
		position: 'sticky',
		bottom: 0,
		zIndex: 1
	};

	const location = useLocation();
	const pathParts = location.pathname.split('/');
	const currentPath = pathParts[pathParts.length - 1];


	useEffect(() => {
		setSelectedEstimate(iniopen);
		// console.log(currentPath)
	}, [iniopen, location]);

	return (
		<>
			<tbody>
				{isLoading ? (
					<>
						{[...Array(10)].map((e, i) => (
							<tr>
								{selectedEstimate ? (
									<>
										{[...Array(5)].map((e, i) => (
											<td>
												<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
											</td>
										))}
									</>
								) : (
									<>
										{[...Array(7)].map((e, i) => (
											<td>
												<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
											</td>
										))}
									</>
								)}
							</tr>
						))}
					</>
				) : (
					<>
						{row &&
							row.length > 0 &&
							row.map((item, index) => {
								const exp_date = `Due ${moment().to(item?.expires_at)}`;
								const start_dates = `${moment(item?.start_date).format(CommonDateOnlyFormat)}`;
								const maxCharacters = 20;
								let currencyRate = 0;
								let activeStatus = mainStatus == 'All' ? item.master_status?.name : mainStatus;
								let itemStatus = item?.is_draft == 1 ? 'Draft' : item.master_status?.name;
								const salesPersonFullName = `${item?.sales_person?.first_name || ''} ${item?.sales_person?.last_name || ''}`;
								const truncatedSalesPersonFullName =
									salesPersonFullName && salesPersonFullName.length > maxCharacters
										? salesPersonFullName.substring(0, maxCharacters) + '...'
										: salesPersonFullName;
								let currencySymbol = '';
								currencyRate = 1;
								currencySymbol = item.currency == 'eur' ? '€' : item.currency == 'usd' ? '$' : 'AED';
								return (
									<>
										<tr
											key={index}
											className={`${selectedEstimate?.id === item?.id ? 'selected-row' : ''} ${
												// itemStatus == 'Canceled' ? 'strike-through-row' : ''
												itemStatus == 'Canceled' ? '' : ''
												}`}
										>
											{
												currentStatus == 'Quotation' ? (
													<>
														<td
															onClick={(e) => {
																history(`/sales/${currentPath}/${item?.id}`);
															}}
															className={`align-middle cursor-pointer`}
															style={{ widhth: '10%' }}
														>
															{
																itemStatus == 'Draft' ? (
																	<>
																		<span style={{ fontStyle: 'italic', color: '#929598' }}>{item?.estimation_number}</span>
																	</>
																) : (
																	<>
																		{item?.estimation_number}
																	</>
																)
															}
														</td>
													</>
												) : (
													<>
														<td
															onClick={(e) => {
																history(`/sales/${currentPath}/${item?.id}`);
															}}
															className={`align-middle cursor-pointer`}
															style={{ widhth: '10%' }}
														>
															{
																itemStatus == 'Draft' ? (
																	<>
																		<span style={{ fontStyle: 'italic', color: '#929598' }}>{item?.invoice_number}</span>
																	</>
																) : (
																	<>
																		{item?.invoice_number}
																	</>
																)
															}
														</td>
													</>
												)}

											<td
												onClick={(e) => {
													// handleToggle(item);
													history(`/sales/${currentPath}/${item?.id}`);
												}}
												className="align-middle cursor-pointer"
											>
												{
													currentPath == 'quotations' ? (
														<>
															<PrivacyModeContent>{item?.customer?.name}</PrivacyModeContent>
														</>
													) : (
														<>
															{
																item?.transit_company?.name ? (
																	<>
																		<PrivacyModeContent>{item?.transit_company?.name}</PrivacyModeContent>
																	</>
																) : (
																	<>
																		<PrivacyModeContent>{item?.customer?.name}</PrivacyModeContent>
																	</>
																)
															}
														</>
													)
												}

											</td>
											<td
												onClick={(e) => {
													// handleToggle(item);
													history(`/sales/${currentPath}/${item?.id}`);
												}}
												className="align-middle cursor-pointer"
											>
												{item?.sales_person?.first_name ? truncatedSalesPersonFullName : '-'}
											</td>
											<td
												onClick={(e) => {
													// handleToggle(item);
													history(`/sales/${currentPath}/${item?.id}`);
												}}
												className="align-middle cursor-pointer"
											>
												<p className="m-0">{start_dates}</p>
											</td>


											{/* {selectedEstimate ? (
												''
											) : (
												<>

													{currentStatus == 'Estimation' && (
														<td
															onClick={(e) => {
																// handleToggle(item);
																history(`/sales/${currentPath}/${item?.id}`);
															}}
														>
															{moment(item?.expires_at).format(CommonDateOnlyFormat)}
														</td>
													)}
												</>
											)} */}

											{
												currentPath == 'quotations' && (
													<>

														<td
															onClick={(e) => {
																// handleToggle(item);
																history(`/sales/${currentPath}/${item?.id}`);
															}}
															className="align-middle cursor-pointer"
														>
															<PrivacyModeContent notUserRestricted>
																{item?.transit_company?.name
																	? item?.transit_company?.name && item.transit_company?.name.length > 20
																		? item.transit_company?.name.substring(0, 20) + '...'
																		: item?.transit_company?.name
																	: '-'}
															</PrivacyModeContent>
														</td>
													</>
												)
											}
											<td
												onClick={(e) => {
													// handleToggle(item);
													history(`/sales/${currentPath}/${item?.id}`);
												}}
												className="align-middle cursor-pointer"
											>
												{item?.item_qty}
											</td>
											<td
												onClick={(e) => {
													// handleToggle(item);
													history(`/sales/${currentPath}/${item?.id}`);
												}}
												className="align-middle cursor-pointer"
											>
												<CurrencyValue currencyObject={item?.converted_currency_total} />
											</td>
											{selectedEstimate ? (
												''
											) : (
												<>

													{currentStatus == 'Draft' ? (
														<></>
													) : (
														<>
															<td
																onClick={(e) => {
																	// handleToggle(item);
																	history(`/sales/${currentPath}/${item?.id}`);
																}}
																className="align-middle cursor-pointer"
																style={{ width: '100px' }}
															>


																{
																	item?.master_status?.name == 'Credit Note' ? (
																		<>
																			{
																				item?.paid_status == 'Paid' ? (
																					<>
																						<StatusBadge itemStatus="Paid" />
																					</>
																				) : (
																					<>
																						<StatusBadge itemStatus={itemStatus} />
																					</>
																				)

																			}
																		</>
																	) : (
																		<>
																			<StatusBadge itemStatus={itemStatus} />
																		</>
																	)
																}

															</td>
														</>
													)}
												</>
											)}
										</tr>
									</>
								);
							})}
						{row?.length < 1 && (
							<tr className="no-hover">
								<td colSpan={selectedEstimate ? 3 : currentStatus == 'Quotation' ? 8 : 9}>
									<div className="not-found-container">
										<div className="not-found-icon">
											<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
										</div>
										<h6 className="not-found-text">No Data Available</h6>
										{
											currentPath == 'quotations' ? (
												<>
													<Link to={'/sales/quotations/create'} className="btn btn-primary btn-outlined-main">
														Create new {currentPath} ?
													</Link>
												</>
											) : (
												<>

													<Link to={'/sales/invoices/create'} className="btn btn-primary btn-outlined-main">
														Create new {currentPath} ?
													</Link>
												</>
											)
										}
									</div>
								</td>
							</tr>
						)}
					</>
				)}
			</tbody>
		</>
	);
};

export default OrderItemsTable;
