import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';
import { Note } from '@mui/icons-material';

export default {

	getSettings() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getNextReferenceNumber(type) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/${type}/get-next-reference`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getCompanyDetails() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/company-details`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getSettingsGeneralSettings() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/general-settings`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	updateCompanyDetails(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/settings/company-details`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
    },
    
	uploadCompanyDetailsLogo(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/settings/upload-company-details-logo`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },

	updateGeneralSettingsDetails(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/settings/general-settings`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
    },
	deleteCurrencyConversion(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/settings/${id}/delete-currency-conversion`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
    },



    /*
    |--------------------------------------------------------------------------
    | Start: Grades
    |--------------------------------------------------------------------------
    */
	getSettingsGrades() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/grades`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    


    filterGrades(filters, search = null) {
        let url = `${base}/api/settings/grades?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
	},
    
	settingsCreateGrade(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/settings/create-grade`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    
	settingsUpdateGrade(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/settings/update-grade`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
	settingsDeleteGrade(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/settings/grade/${id}/delete-grade`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },



    /*
    |--------------------------------------------------------------------------
    | Start: Colors
    |--------------------------------------------------------------------------
    */
	getSettingsColors() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/colors`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    


    filterColors(filters, search = null) {
        let url = `${base}/api/settings/colors?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
	},
    
	settingsCreateColor(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/settings/create-color`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    
	settingsUpdateColor(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/settings/update-color`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
	settingsDeleteColor(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/settings/color/${id}/delete-color`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },


    /*
    |--------------------------------------------------------------------------
    | Start: Banks
    |--------------------------------------------------------------------------
    */
	getSettingsBanks() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/banks`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    


    filterBanks(filters, search = null) {
        let url = `${base}/api/settings/banks?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
	},
    
	settingsCreateBank(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/settings/create-bank`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    
	settingsUpdateBank(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/settings/update-bank`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
	settingsDeleteBank(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/settings/bank/${id}/delete-bank`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },


    /*
    |--------------------------------------------------------------------------
    | Start: Shipping Company
    |--------------------------------------------------------------------------
    */
	getSettingsShippingCompanies() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/shipping-companies`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    


    filterShippingCompanies(filters, search = null) {
        let url = `${base}/api/settings/shipping-companies?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
	},
    
	settingsCreateShippingCompany(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/settings/create-shipping-company`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    
	settingsUpdateShippingCompany(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/settings/update-shipping-company`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
	settingsDeleteShippingCompany(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/settings/shipping-company/${id}/delete-shipping-company`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },


    /*
    |--------------------------------------------------------------------------
    | Start: Repair Type
    |--------------------------------------------------------------------------
    */
	getSettingsRepairTypes() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/repair-types`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    


    filterRepairTypes(filters, search = null) {
        let url = `${base}/api/settings/repair-types?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
	},
    
	settingsCreateRepairType(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/settings/create-repair-type`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    
	settingsUpdateRepairType(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/settings/update-repair-type`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
	settingsDeleteRepairType(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/settings/repair-type/${id}/delete-repair-type`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },


    /*
    |--------------------------------------------------------------------------
    | Start: Specifications
    |--------------------------------------------------------------------------
    */
	getSettingsSpecifications() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/specifications`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    


    filterSpecifications(filters, search = null) {
        let url = `${base}/api/settings/specifications?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
	},
    
	settingsCreateSpecification(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/settings/create-specification`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    
	settingsUpdateSpecification(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/settings/update-specification`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
	settingsDeleteSpecification(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/settings/specification/${id}/delete-specification`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },


    /*
    |--------------------------------------------------------------------------
    | Start: Interests
    |--------------------------------------------------------------------------
    */
	getSettingsInterests() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/settings/interests`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    


    filterInterests(filters, search = null) {
        let url = `${base}/api/settings/interest?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
	},
    
	settingsCreateInterest(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/settings/create-interest`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    
	settingsUpdateInterest(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/settings/update-interest`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
	settingsDeleteInterest(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/settings/interest/${id}/delete-interest`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
};
