import React, { useState, useEffect, useCallback } from 'react';
import { FaRegFolder } from 'react-icons/fa';
import { AiFillFileText } from 'react-icons/ai';
import { FaRegFilePdf } from 'react-icons/fa';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { Dropdown } from 'react-bootstrap';
import { HiEllipsisVertical } from 'react-icons/hi2';
import { GoImage } from 'react-icons/go';
import { BsFileBreak } from 'react-icons/bs';
import { FiFolderPlus } from 'react-icons/fi';
import moment from 'moment';
import CreateDirectoryComponent from './CreateDirectory';
import ImageModel from './ImageModel';
import { SlCloudUpload } from 'react-icons/sl';
import { useDropzone } from 'react-dropzone';
import { IoPerson } from 'react-icons/io5';
import { SiMicrosoftexcel, SiMicrosoftword } from 'react-icons/si';
import { BsFiletypeCsv } from 'react-icons/bs';
import fileDownload from 'js-file-download';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

const DirectoryTree = ({ id }) => {
	const [fileUploadData, setFileUploadData] = useState();
	const [folder, setFolder] = useState(true);
	const [parentData, setParentData] = useState(null);
	const [show, setShow] = useState('');
	const [fileType, setFileType] = useState(null);
	const [directoryData, setDirectoryData] = useState(null);
	const [selectedData, setSelectedData] = useState(null);
	const [CreateDirectory, setCreateDirectory] = useState();
	const [imageModel, setImageModel] = useState();
	const [currentPath, setCurrentPath] = useState(null);
	const [pathArray, setPathArray] = useState([]);
	const [apiData, setApiData] = useState([]);

	const getcustomerDirectories = () => {
		api.getDirectoriesByContactId(id).then((res) => {
			if (!res.error) {
				setParentData(res);
			}
			if (!res.error && res.length > 0) {
				setApiData(res);
				let result = res.reduce((a, o) => ((a[o.parent_id] ??= []).push({ ...o, children: (a[o.id] ??= []) }), a), {})['0'];
				result = result.find((a) => a.parent_id == 0);
				setDirectoryData(result);
				setFileUploadData(result);
				setSelectedData(result?.children);
				setCurrentPath({
					id: result?.id,
					name: ''
				});
			}
		});
	};

	const onDrop = useCallback(
		(acceptedFiles) => {
			let parent = apiData?.find((a) => a.id == currentPath.id);

			console.log('parent', parent);
			api
				.addDirectory({
					directory_path: parent?.directory_path,
					directory_name: 'fileName',
					parent_id: parent?.id,
					contact_id: parent?.contact_id,
					isFile: 1,
					file: acceptedFiles[0]
				})
				.then((res) => {
					if (res.message) {
						closeCreateDirectory(res.message);
					} else {
						setTimeout(() => { }, 2000);
					}
				});
		},
		[currentPath]
	);

	useEffect(() => {
		getcustomerDirectories();
	}, [id]);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	const handleDirectoryClick = (directory, index, event) => {
		event.stopPropagation();
		setCurrentPath({
			id: selectedData[index]?.id,
			name: selectedData[index]?.name
		});
		setPathArray((prevPathArray) => [...prevPathArray, { id: selectedData[index]?.id, name: selectedData[index]?.name }]);
		setSelectedData(directory);
	};

	const handlePathClick = (path) => {
		if (path != '') {
			setCurrentPath(path);
			const indexToRemove = pathArray.findIndex((item) => item.id === path.id);
			if (indexToRemove !== -1) {
				setPathArray((prevPathArray) => prevPathArray.slice(0, indexToRemove + 1));
				const result = getHierarchy(apiData);
				const directory = searchDataById(result, path.id);
				setSelectedData(directory?.children);
			}
		} else {
			setCurrentPath(directoryData?.name);
			setPathArray([]);
			setSelectedData(directoryData?.children);
		}
	};

	const renderBreadcrumbs = () => {
		return (
			<div className="BreadCrumbs">
				{pathArray?.map((directory, index) => {
					return (
						<span key={index}>
							<a onClick={() => handlePathClick(directory)}>{directory?.name}</a>
							{index < pathArray.length - 1 && ' / '}
						</span>
					);
				})}
			</div>
		);
	};

	const inputfieldEnable = (type) => {
		setFolder(type);
		let parent = apiData?.find((a) => a.id == currentPath.id);
		setCreateDirectory(parent);
	};

	const searchDataById = (data, targetId) => {
		for (const item of data) {
			if (item.id === targetId) {
				return item;
			}
			if (item.children && item.children.length > 0) {
				const resultInChildren = searchDataById(item.children, targetId);
				if (resultInChildren) {
					return resultInChildren;
				}
			}
			if (item.subchildren && item.subchildren.length > 0) {
				const resultInSubchildren = searchDataById(item.subchildren, targetId);
				if (resultInSubchildren) {
					return resultInSubchildren;
				}
			}
		}
		return null;
	};

	const getHierarchy = (data) => {
		return data.reduce((a, o) => ((a[o.parent_id] ??= []).push({ ...o, children: (a[o.id] ??= []) }), a), {})['0'];
	};

	const closeCreateDirectory = (data) => {
		if (data != undefined && data != 'close') {
			setApiData(data);
			const result = getHierarchy(data);
			const directory = searchDataById(result, currentPath?.id);
			setParentData(result.find((a) => a.parent_id == 0));
			setDirectoryData(result.find((a) => a.parent_id == 0));
			setSelectedData(directory?.children != null ? directory?.children : parentData?.children);
		}
		setCreateDirectory('');
	};

	const handleClose = () => setShow('');

	const handleDelete = (id) => {
		api.deleteDirectory(id).then((res) => {
			setShow('');
			if (res.message) {
				let result = res.message.reduce((a, o) => ((a[o.parent_id] ??= []).push({ ...o, children: (a[o.id] ??= []) }), a), {})['0'];
				const directory = searchDataById(result, currentPath?.id);
				result = result.find((a) => a.parent_id == 0);
				setDirectoryData(result);
				setSelectedData(directory?.children != null ? directory?.children : result?.children);
			}
		});
	};

	const deleteuserData = (data) => {
		setShow(data);
	};
	const rename = (row) => {
		setFolder('rename');
		setCreateDirectory(row);
	};

	const openImage = (path, name, type, event) => {
		setFileType(type);
		let fullpath = `${base}/${path}/${name}`;
		let modifiedUrl = fullpath.replace('/public/', '/storage/');
		setImageModel({
			url: modifiedUrl,
			name: name
		});
	};
	const imageClose = () => {
		setImageModel('');
	};

	const Download = (item) => {
		let fullpath = `${base}/${item?.directory_path}/${item?.name}`;
		let modifiedUrl = fullpath.replace('/public/', '/storage/');

		const link = document.createElement('a');
		link.href = modifiedUrl;
		link.download = item?.name || 'downloaded_file';
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	return (
		<div className="Directorytree">
			<DeleteConfirmation
				show={show}
				handleClose={handleClose}
				handleDelete={handleDelete}
				title="Confirm"
				message={`Are you sure you want to delete`}
			/>

			<CreateDirectoryComponent open={CreateDirectory} close={closeCreateDirectory} path={currentPath} type={folder} />

			<ImageModel open={imageModel} close={imageClose} type={fileType} />

			<div>
				<div className=" documentTopBar">
					<div className="left">
						<FaRegFolder onClick={() => handlePathClick('')} /> <p className="text">{renderBreadcrumbs()}</p>
					</div>
					<div className="right">
						<div className="Iconfill">
							<Dropdown className="btnDel iconbtn dropdownDocuments">
								<Dropdown.Toggle as="span">
									<FiFolderPlus className="addFolderIcon" />
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => inputfieldEnable('folder')}>New Folder</Dropdown.Item>
									<Dropdown.Item onClick={() => inputfieldEnable('file')}>New File</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</div>
			</div>
			<div className={`${selectedData && selectedData.length < 1 ? ' DragDropAvailable' : 'DragDrop'}`} {...getRootProps()}>
				{selectedData && selectedData.length > 0 ? (
					<table className="table pm-table DocumentDetailsTable">
						<thead className="DocumentDetailsTableHead">
							<tr>
								<th scope="col" width="40%" className="mr-5">
									Name
								</th>
								<th scope="col" width="20%" className="center">
									Created
								</th>
								<th scope="col" width="20%" className="center">
									Owner
								</th>
								<th scope="col" width="20%" className="center">
									Last Modified
								</th>
								<th scope="col" width="10%" className="center"></th>
							</tr>
						</thead>
						<tbody className="DocumentDetailsTableBody">
							{selectedData &&
								selectedData.length > 0 &&
								selectedData.map((item, index) => (
									<>
										<tr key={index} onClick={(event) => stopPropagation(event)}>
											<td
												className="NameClass"
												onClick={
													item.isFile === 0
														? (event) => handleDirectoryClick(item.children, index, event)
														: (item.isFile == 1 && item.file_extension == 'png') ||
															item.file_extension == 'jpg' ||
															item.file_extension == 'jpeg'
															? (event) => openImage(item.directory_path, item?.name, 'img', event)
															: item.file_extension == 'pdf'
																? (event) => openImage(item.directory_path, item?.name, 'pdf', event)
																: undefined
												}
											>
												{item.isFile == 0 ? (
													<div className="IconFolder folder">
														<FaRegFolder className="folderIcon" />
													</div>
												) : item.file_extension == 'png' || item.file_extension == 'jpg' || item.file_extension == 'jpeg' ? (
													<div className="IconFolder image">
														<GoImage className="folderIcon" />
													</div>
												) : item.file_extension == 'pdf' ? (
													<div className="IconFolder pdf">
														<FaRegFilePdf className="folderIcon" />
													</div>
												) : item.file_extension == 'xls' ? (
													<div className="IconFolder excel">
														<SiMicrosoftexcel className="folderIcon" />
													</div>
												) : item.file_extension == 'csv' ? (
													<div className="IconFolder csv">
														<BsFiletypeCsv className="folderIcon" />
													</div>
												) : item.file_extension == 'docx' ? (
													<div className="IconFolder word">
														<SiMicrosoftword className="folderIcon" />
													</div>
												) : (
													<div className="IconFolder broken">
														<BsFileBreak className="folderIcon" />
													</div>
												)}

												<div className="NameFolder">{item?.name}</div>
											</td>
											<td className="center colorgrey"> {moment(item?.created_at).format(CommonDateOnlyFormat)}</td>
											<td className="center colorgrey">
												<p className="centerText">
													<IoPerson />
													&nbsp;
													{item?.users?.first_name}
												</p>
											</td>
											<td className="center colorgrey"> {moment(item?.updated_at).fromNow()}</td>
											<td>
												<Dropdown className="btnDel iconbtn dropdownDocuments">
													<Dropdown.Toggle as="span" id={`dropdown-toggle-${index}`}>
														<HiEllipsisVertical className="VerticalHam" />
													</Dropdown.Toggle>

													<Dropdown.Menu>
														{item?.isFile === 1 && <Dropdown.Item onClick={() => Download(item)}>Download</Dropdown.Item>}

														<PermissionAllowed permissions={['Handle Directory']}>
															<Dropdown.Item onClick={() => rename(item)}>Rename</Dropdown.Item>

															<Dropdown.Item onClick={() => deleteuserData(item)}>Delete</Dropdown.Item>
														</PermissionAllowed>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									</>
								))}
						</tbody>
					</table>
				) : (
					<></>
				)}
				{fileUploadData != undefined && selectedData && selectedData.length < 1 ? (
					<div className="dragIconText">
						<SlCloudUpload className="CloudIcon" />
						<p className="dragText">Drop here to upload</p>
					</div>
				) : (
					<></>
				)}
				<input {...getInputProps()} style={{ display: 'none' }} />
			</div>
		</div>
	);
};

export default DirectoryTree;
