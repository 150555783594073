import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import './Inbounding/inbounding.scss';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import useSound from 'use-sound';
import usePageTitle from 'hooks/usePageTitle';

import errorSound from '../../assets/audio/error.mp3';
import { PicklistIcon2, QualityControlIcon, ShareIcon } from 'ui-component/custom-icons/icons';

import Breadcrumbs from './components/Breadcrumbs';
import InboundingForm from './components/InboundingForm';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import Summary from './components/Summary';
import Logs from './components/Logs';
import InboundView from './components/QualityControlList';

function OperationInboundView({ edit }) {
    const { id } = useParams();
    const [setPageTitle] = usePageTitle('Operations');
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);
    const history = useNavigate();
    const [errorSoundPlay] = useSound(errorSound);
    
    
    const [operationDetails, setOperationDetails] = useState({
        id: '',
        referenceNo: '',
        status: '',
        action: '',
        type: '',
        products: [],
        logs: []
    });


    useEffect(() => {
        if (id) {
            initializeOperationDetails();
        }
    }, []);

    const initializeOperationDetails = () => {

        api.getOperationsDetails(id).then((res) => {
            if (res?.status) {
                const data = {
                    id: res?.data?.id,
                    referenceNo: res?.data?.reference_number,
                    action: 'view',
                    type: 'inbound',
                    products: res?.data?.items,
                    logs: res?.data?.logs
                }
                setOperationDetails(data);
            
                // setOperationDetails(res?.data);
            }
        });
    }



    const handleRemoveProductFromList = (index) => {
        const updatedProducts = operationDetails?.products ? [...operationDetails.products] : [];

        updatedProducts.splice(index, 1);
        setOperationDetails({
            ...operationDetails,
            products: updatedProducts
        });
    }


    const saveOperationDetails = (action) => {
        const collection = {
            id: operationDetails?.id,
            status: operationDetails?.status,
            action: action,
            type: 'create',
            products: operationDetails?.products
        }
        console.log('collection', collection)



        api.storeOperationsData(collection).then((res) => {
                if (res?.status) {
                    toast.success(res.message);
                    history(`/operations/view/${res?.data?.id}/inbound`);
                } else {
                    toast.error(res.message);
                }
            },
            () => { }
        );

    }

    const handleReloadData = () => {
        initializeOperationDetails();
    }

	return (
        <>
            
            <div className="row m-0 w-100" style={{ marginTop: '20px' }}>
                <div className="col-12 col-md-12">
                    <div className="section-head" style={{ paddingLeft: '0' }}>
                     
                        <h2>
                            <div className="actions" style={{ display: 'flex', width: '100%' }}>

                                <Breadcrumbs
                                    current={`inbound`}
                                    activateTabsUsingId={operationDetails?.id}
                                    referenceNumber={operationDetails?.referenceNo}
                                />

                            </div>
                        </h2>
                        <div className="actions">
                            {!edit ? (
                                <>
                                    <PermissionAllowed permissions={['Move to Picklist']}>
                                        <button
                                            type="button"
                                            onClick={() => saveOperationDetails('to_picklist')}
                                            className="btn btn-primary btn-outlined-main"
                                            disabled={operationDetails?.products?.length == 0}
                                        >
                                            <ShareIcon width={'15px'} height={'15px'} />
                                            Move to Picklist
                                        </button>
                                    </PermissionAllowed>
                                </>
                            ) : (
                                <>
                                    <PermissionAllowed permissions={['Move to Picklist']}>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                history('/operations/edit/' + operationDetails?.id);    
                                            }}
                                            className="btn btn-primary btn-outlined-main"
                                        >
                                            <ShareIcon width={'15px'} height={'15px'} />
                                            Move to Picklist
                                        </button>
                                    </PermissionAllowed>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            history('/operations/edit/' + operationDetails?.id);    
                                        }}
                                        className="btn btn-primary btn-outlined-main"
                                    >
                                        <svg
                                            id="fi_2356780"
                                            style={{ marginTop: '-3px' }}
                                            enable-background="new 0 0 511.984 511.984"
                                            height="16"
                                            viewBox="0 0 511.984 511.984"
                                            width="16"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g>
                                                <path
                                                    d="m415 221.984c-8.284 0-15 6.716-15 15v220c0 13.785-11.215 25-25 25h-320c-13.785 0-25-11.215-25-25v-320c0-13.785 11.215-25 25-25h220c8.284 0 15-6.716 15-15s-6.716-15-15-15h-220c-30.327 0-55 24.673-55 55v320c0 30.327 24.673 55 55 55h320c30.327 0 55-24.673 55-55v-220c0-8.284-6.716-15-15-15z"
                                                    fill="currentColor"
                                                ></path>
                                                <path
                                                    d="m501.749 38.52-28.285-28.285c-13.645-13.646-35.849-13.646-49.497 0l-226.273 226.274c-2.094 2.094-3.521 4.761-4.103 7.665l-14.143 70.711c-.983 4.918.556 10.002 4.103 13.548 2.841 2.841 6.668 4.394 10.606 4.394.979 0 1.963-.096 2.941-.291l70.711-14.143c2.904-.581 5.571-2.009 7.665-4.103l226.275-226.273s.001 0 .001-.001c13.645-13.645 13.645-35.849-.001-49.496zm-244.276 251.346-44.194 8.84 8.84-44.194 184.17-184.173 35.356 35.356zm223.063-223.062-17.678 17.678-35.356-35.356 17.677-17.677c1.95-1.95 5.122-1.951 7.072-.001l28.284 28.285c1.951 1.949 1.951 5.122.001 7.071z"
                                                    fill="currentColor"
                                                ></path>
                                            </g>
                                        </svg>
                                        Edit
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-lg-8 p-0 form-group" style={{ marginTop: '5px' }}>
                            <div className="card shadow-sm bg-white table-card p-3" style={{ marginTop: '0' }}>
                                <div className="bg-white pt-0" style={{  marginTop: '-15px' }}>
                              
                                    <div className="row">
                                        <div className="col-12">
                                            
                                            <InboundView
                                                operationDetails={operationDetails}
                                                handleRemoveProductFromList={handleRemoveProductFromList}
                                                handleReloadData={handleReloadData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-12 form-group">
                                    <Summary
                                        operationDetails={operationDetails}
                                    />
                                    <Logs
                                        logs={operationDetails?.logs}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</>
	);
}

export default OperationInboundView;
