import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FaArrowDown, FaArrowUp, FaCaretRight, FaTrashCan } from 'react-icons/fa6';
import { HiDotsVertical } from 'react-icons/hi';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { MdAddTask } from 'react-icons/md';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { v4 as uuidv4 } from 'uuid';
import CustomGrowInput from './CustomGrowInput';
import { Reorder, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { setTasksList } from 'store/reducers/mainSlice';
import CustomDateInput from './CustomDateInput';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import pmloaderimg from 'assets/images/projectmix-preloader.gif';

const taskTypes = [
	{
		name: 'All Tasks',
		value: 'all'
	},
	{
		name: 'Assigned to me',
		value: 'assigned'
	},
	{
		name: 'Self Assigned',
		value: 'self'
	}
];

const emptyTask = {
	id: null,
	title: '',
	description: '',
	assignedBy: null,
	dueOn: null,
	complete: false,
	parentId: null
};

const TaskPanel = ({ onClose, tasks, setTasks, deletedTasks, setDeletedTasks, loaded, setLoaded }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [contextAnchorEl, setContextAnchorEl] = useState(null);
	const [contextItemId, setContextItemId] = useState(null);
	const taskItemsRef = useRef(null);
	const [currentTaskType, setCurrentTaskType] = useState(taskTypes[0].value); // taskTypes[0].name = 'All Tasks' [default value]
	const currentTaskTypeObject = taskTypes.find((taskType) => taskType.value == currentTaskType);
	const open = Boolean(anchorEl);
	const contextOpen = Boolean(contextAnchorEl) || Boolean(contextItemId);
	const handleTaskTypeMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleItemContextClick = (event, id) => {
		setContextAnchorEl(event.currentTarget);
		setContextItemId(id);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleContextClose = () => {
		setContextAnchorEl(null);
		setContextItemId(null);
	};

	const selectTaskType = (type) => {
		setAnchorEl(null);
		setCurrentTaskType(type);
	};

	// const [tasksList] = useSelector((state) => [state.main.tasksList]);

	const dispatch = useDispatch();
	const [selectedTask, setSelectedTask] = useState(null);
	const [showCompleted, setShowCompleted] = useState(false);

	const getAllTasks = () => {
		if (!loaded) {
			api.getUserTasks().then((data) => {
				const icTasks = data?.data?.incomplete?.map((task) => {
					return {
						id: task?.id,
						title: task?.title,
						description: task?.description,
						complete: false,
						order: task?.zOrder
					};
				});
				const cTasks = data?.data?.complete?.map((task) => {
					return {
						id: task?.id,
						title: task?.title,
						description: task?.description,
						complete: true,
						order: task?.zOrder
					};
				});
				setTasks([...icTasks, ...cTasks]);
				setLoaded(true);
			});
		}
	};

	useEffect(() => {
		getAllTasks();

		return () => {
			updateTasks();
		};
	}, []);

	useEffect(() => {
		dispatch(setTasksList(tasks));
		const timeout = setTimeout(() => {
			updateTasks();
		}, 300);

		return () => {
			clearTimeout(timeout);
		};
	}, [tasks]);

	const updateTasks = () => {
		const updatedAtIndex = tasks.findIndex((t) => t.updated);
		const newDeletedTasks = [...deletedTasks.filter((task) => !task.deleted)];
		if (updatedAtIndex > -1 || newDeletedTasks.length > 0) {
			const newTasks = [...tasks]
				.filter((task, i) => i <= updatedAtIndex + 1)
				.map((task, i) => ({ ...task, zOrder: tasks.length - i, updated: true }));
			if (newTasks.length > 0 || newDeletedTasks.length > 0) {
				api
					.syncUserTasks({
						tasks: newTasks,
						deletedTasks: deletedTasks.map((task) => task.id)
					})
					.then((data) => {
						setDeletedTasks((old) => old.map((task) => ({ ...task, deleted: true })));
						setTasks((old) => old.map((task) => ({ ...task, updated: false })));
					});
			}
		}
	};

	// useEffect(() => {
	// 	if (!selectedTask) {
	// 		updateTasks();
	// 	}
	// }, [selectedTask]);

	const addNewTask = (addAfterIndex = 0) => {
		// if (tasks[0]?.title !== '' || tasks[0]?.description !== '') {
		// 	const id = uuidv4();
		// 	setTasks((old) => [{ ...emptyTask, id }, ...old]);
		// 	setSelectedTask(id);
		// } else {
		// 	setSelectedTask(null);
		// 	setTimeout(() => {
		// 		setSelectedTask(tasks?.[0]?.id);
		// 	}, 1);
		// }

		if (tasks[addAfterIndex]?.title !== '' || tasks[addAfterIndex]?.description !== '') {
			const id = uuidv4();
			setTasks((old) => [...old.slice(0, addAfterIndex + 1), { ...emptyTask, id }, ...old.slice(addAfterIndex + 1)]);
			setSelectedTask(id);
		} else {
			setSelectedTask(null);
			setTimeout(() => {
				setSelectedTask(tasks?.[addAfterIndex]?.id);
			}, 1);
		}
	};

	const completeTask = (id) => {
		const task = tasks.find((task) => task.id === id);
		if (!!task?.title || !!task?.description) {
			setTasks(
				tasks.map((task) => {
					if (task.id === id) {
						return { ...task, complete: true, updated: true };
					}
					return task;
				})
			);
		}
	};

	const unCompleteTask = (id) => {
		setTasks(
			tasks.map((task) => {
				if (task.id === id) {
					return { ...task, complete: false, updated: true };
				}
				return task;
			})
		);
	};

	const removeTask = (id) => {
		const task = tasks.find((task) => task.id === id);
		if (task) {
			setDeletedTasks([...deletedTasks, { ...task, deleted: false }]);
			setTasks(tasks.filter((task) => task.id !== id));
		}
	};

	const updateTask = (id, key, value) => {
		setTasks(
			tasks.map((task) => {
				if (task.id === id) {
					return { ...task, [key]: value, updated: true };
				}
				return task;
			})
		);
	};

	// On click outside other than taskItem ref items
	useEffect(() => {
		const handleClick = (e) => {
			if (!taskItemsRef.current.contains(e.target)) {
				setSelectedTask(null);
			}
		};

		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [taskItemsRef]);

	// useEffect(() => {
	// 	console.log('selectedTask', selectedTask);
	// }, [selectedTask]);

	const handleMoveToTop = () => {
		const task = tasks.find((task) => task.id === contextItemId);
		if (task) {
			setTasks((old) => [{ ...task, updated: true }, ...old.filter((t) => t.id !== contextItemId)]);
		}
	};

	const handleMoveToEnd = () => {
		const task = tasks.find((task) => task.id === contextItemId);
		if (task) {
			setTasks((old) => [...old.filter((t) => t.id !== contextItemId), { ...task, updated: true }]);
		}
	};

	return (
		<div className="tasks-panel">
			<>
				<div className={`tasks-panel-head`}>
					<div>
						<p className="color-secondary m-0">Tasks</p>
						<Button
							id="basic-button"
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							// onClick={handleTaskTypeMenuClick}
							style={{
								padding: '0',
								textTransform: 'none',
								fontSize: '18px',
								color: '#000000',
								lineHeight: '1'
							}}
						>
							{currentTaskTypeObject?.name}
						</Button>
						<Menu
							id="main-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button'
							}}
						>
							{[...taskTypes]
								?.filter((t) => t.value != currentTaskType)
								?.map((taskType, index) => (
									<MenuItem onClick={() => selectTaskType(taskType.value)} key={taskType.value}>
										{taskType.name}
									</MenuItem>
								))}
						</Menu>
					</div>
					<button
						className="btn modal-close-icon"
						style={{
							position: 'initial'
						}}
						onClick={() => {
							onClose && onClose();
						}}
					>
						<svg
							id="Component_127_1"
							height="16"
							width="16"
							data-name="Component 127 – 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 19.295 19.289"
						>
							<path
								id="Icon_ionic-ios-close"
								data-name="Icon ionic-ios-close"
								d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
								transform="translate(-11.285 -11.289)"
							/>
						</svg>
					</button>
				</div>
				<div className={`task-panel-body ${!loaded ? 'd-none' : ''}`}>
					<Menu
						id="context-menu"
						anchorEl={contextAnchorEl}
						open={contextOpen}
						onClose={handleContextClose}
						MenuListProps={{
							'aria-labelledby': 'context-button'
						}}
					>
						<MenuItem
							onClick={() => {
								handleMoveToTop();
								handleContextClose();
							}}
						>
							<div className="d-flex" style={{ gap: '10px', alignItems: 'center' }}>
								<FaArrowUp />
								Move to top
							</div>
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleMoveToEnd();
								handleContextClose();
							}}
						>
							<div className="d-flex" style={{ gap: '10px', alignItems: 'center' }}>
								<FaArrowDown />
								Move to bottom
							</div>
						</MenuItem>
						<MenuItem
							onClick={() => {
								removeTask(contextItemId);
								handleContextClose();
							}}
						>
							<div className="d-flex" style={{ gap: '10px', alignItems: 'center' }}>
								<FaTrashCan />
								Delete
							</div>
						</MenuItem>
					</Menu>
					<div className="task-item add-task" style={{ display: 'flex', paddingBlock: '10px' }}>
						<div className="cursor-pointer" style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '15px' }} onClick={addNewTask}>
							<div className="flex align-items-center" style={{ fontSize: '18px' }}>
								<MdAddTask />
							</div>
							<p className="m-0" style={{ fontSize: '14px' }}>
								Add Task
							</p>
						</div>
						{/* <div className="flex align-items-center" style={{ color: 'black' }}>
						<HiDotsVertical />
					</div> */}
					</div>
					<div ref={taskItemsRef}>
						<Reorder.Group
							axis="y"
							values={tasks}
							onReorder={(items, index) => {
								setTasks(items?.map((i) => ({ ...i, updated: true })));
							}}
							className="p-0"
						>
							{[...tasks]
								?.filter((t) => !t.complete)
								.map((task, index) => (
									<Reorder.Item
										key={task?.id}
										value={task}
										className="list-unstyled p-0"
										initial={{
											opacity: 0
										}}
										animate={{
											opacity: 1
										}}
									>
										<motion.div layout key={task?.id} className="task-item">
											<motion.div layout className="check-section">
												<div className="check-item">&nbsp;</div>
												<div
													className="check-check-item"
													onClick={() => {
														completeTask(task.id);
													}}
												>
													<IoCheckmarkSharp />
												</div>
											</motion.div>
											<motion.div
												layout
												className="d-flex flex-grow-1"
												style={{
													alignSelf: 'stretch',
													flexDirection: 'column'
												}}
											>
												<CustomGrowInput
													placeholder="Title"
													className=""
													value={task?.title}
													onChange={(v) => {
														updateTask(task.id, 'title', v);
													}}
													onClick={() => {
														setSelectedTask(task?.id);
													}}
													readOnly={selectedTask !== task.id}
													autoFocus
													onEnter={() => {
														setSelectedTask(null);
														addNewTask(index);
													}}
												/>
												{(selectedTask === task.id || task?.description) && (
													<CustomGrowInput
														placeholder="Details"
														className="description-input"
														value={task?.description}
														onChange={(v) => {
															updateTask(task.id, 'description', v);
														}}
														onClick={() => {
															setSelectedTask(task?.id);
														}}
														// readOnlyAuto
														multiLine
													/>
												)}
											</motion.div>
											<motion.div layout className="task-action">
												<button
													// id="context-button
													style={{
														background: 'none',
														border: 'none',
														cursor: 'pointer',
														outline: 'none'
													}}
													aria-controls={contextOpen ? 'context-menu' : undefined}
													aria-haspopup="true"
													aria-expanded={contextOpen ? 'true' : undefined}
													onClick={(e) => {
														handleItemContextClick(e, task.id);
													}}
												>
													<HiDotsVertical />
												</button>
											</motion.div>
										</motion.div>
									</Reorder.Item>
								))}
						</Reorder.Group>
					</div>
				</div>
				<div className={`task-panel-footer ${!loaded ? 'd-none' : ''}`}>
					<div
						onClick={() => {
							setShowCompleted((old) => !old);
						}}
						style={{
							paddingInline: '10px',
							cursor: 'pointer'
						}}
					>
						<FaCaretRight /> Completed ({[...tasks].filter((t) => t.complete)?.length})
					</div>
					<motion.div
						style={{ display: 'flex', flexDirection: 'column', gap: '5px', overflow: 'hidden' }}
						animate={{
							height: showCompleted ? 'auto' : 0,
							transition: {
								duration: 0.1,
								ease: 'easeInOut'
							}
						}}
					>
						{[...tasks]
							.filter((t) => t.complete)
							.map((task) => (
								<div key={task?.id} className="task-item task-item-complete">
									<div className="check-section">
										<div className="check-check-item" onClick={() => unCompleteTask(task.id)}>
											<IoCheckmarkSharp />
										</div>
									</div>
									<div className="content flex flex-grow-1">
										<div
											className="d-flex flex-grow-1"
											style={{
												alignSelf: 'stretch',
												flexDirection: 'column'
											}}
										>
											<CustomGrowInput
												placeholder="Title"
												className=""
												value={task?.title}
												onChange={(v) => {
													updateTask(task.id, 'title', v);
												}}
												onClick={() => {
													setSelectedTask(task?.id);
												}}
												readOnly={selectedTask !== task.id}
												onEnter={() => {
													console.log('Enter');
													setSelectedTask(null);
												}}
												autoFocus
											/>
											{task?.description && (
												<CustomGrowInput
													placeholder="Details"
													className="description-input"
													value={task?.description}
													onChange={(v) => {
														updateTask(task.id, 'description', v);
													}}
													onClick={() => {
														setSelectedTask(task?.id);
													}}
													// readOnlyAuto
													multiLine
												/>
											)}
											{/* <CustomGrowInput /> */}
											{/* <CustomDateInput /> */}
										</div>
									</div>
									<div className="task-action">
										<div
											className="cursor-pointer"
											onClick={() => {
												removeTask(task.id);
											}}
										>
											<FaTrashCan />
										</div>
									</div>
								</div>
							))}
					</motion.div>
				</div>
			</>
			<div
				style={{
					height: 'auto',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flex: 1
				}}
				className={`tasks-panel-preloader ${loaded ? 'd-none' : ''}`}
			>
				<img
					src={pmloaderimg}
					style={{
						height: '150px',
						width: '150px'
					}}
					alt=""
				></img>
			</div>
		</div>
	);
};

export default TaskPanel;
