import React, { useRef, useState, useEffect } from 'react';

import Scroller from 'ui-component/Scroller';
import { FaSearch } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AgreementModel from './EstimationDetails';
import moment from 'moment';
import { IoCartOutline } from 'react-icons/io5';
import StatusBadge from 'ui-component/StatusBadge';
import Pagination from 'ui-component/Pagination';
import SearchBar from '../layout/SearchBar';
import { setSearchBar } from 'store/reducers/mainSlice';

const Estimates = () => {
	const inputRef = useRef(null);

	const history = useNavigate();
	const [isInProgress, setIsInProgress] = useState(false);
	const [searchField, setSearchField] = useState();
	const [pending, setPending] = useState(false);
	const clientUser = useSelector((state) => state.main.client);
	const [id, setId] = useState();
	const type = 'estimation';
	const [agreementPopup, setAgreemmentPopup] = useState(false);
	const [data, setData] = useState([]);
	const [autoSuggest, setAutoSuggest] = useState([]);
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(true);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	const searchBar = useSelector((state) => state.main.searchBar);

	const [showButtons, setShowButtons] = useState([]);
	const [pagination, setpagination] = useState();

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (searchBar && inputRef.current) {
			inputRef.current.focus();
		}
	}, [searchBar]);
	// const data = [
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Processing', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Pending', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Completed', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Completed', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Processing', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Completed', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Pending', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	// ]

	useEffect(() => {
		api
			.getClientEstimates(clientUser?.data?.contact_id)
			.then((res) => {
				console.log(res);
				setData(res?.data?.data);
				setpagination(res.data);
				setAutoSuggest(res?.data?.data.map((item) => item.estimation_number).slice(0, 10));

				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	// useEffect(() => {
	// 	api
	// 		.getClientEstimates(clientUser?.data?.contact_id)
	// 		.then((res) => {
	// 			console.log(res);
	// 			setData(res.data);
	// 			setIsLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// }, []);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const toggleButtons = (id) => {
		const newShowButtons = [...showButtons];
		const index = newShowButtons.indexOf(id);
		if (index !== -1) {
			newShowButtons.splice(index, 1);
		} else {
			newShowButtons.push(id);
		}
		setShowButtons(newShowButtons);
	};
	const closeagreementPopup = () => {
		setAgreemmentPopup(false);
	};
	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);
		if (text.length > 2) {
			api.getClientEstimates(clientUser?.data?.contact_id, undefined, text).then((res) => {
				setData(res.data.data);
				setAutoSuggest(res?.data?.data.map((item) => item.estimation_number).slice(0, 10));
				setpagination(res.data);
			});
		} else if (text.length > 0 && text.length <= 2) {
		} else {
			api.getClientEstimates(clientUser?.data?.contact_id).then((res) => {
				setData(res.data.data);
				setpagination(res.data);
				setAutoSuggest(res?.data?.data.map((item) => item.estimation_number).slice(0, 10));
			});
		}
	};
	const handleData = (data) => {
		setData(data.data);
		setpagination(data);
	};

	const EstimationDetails = (id, status) => {
		setAgreemmentPopup(true);
		setId(id);
		setPending(status === 'Pending Customer' ? true : false);
	};

	const submiAgreement = (id) => {
		api
			.aprroveAgreement(id)
			.then((res) => {
				setAgreemmentPopup(false);
				setData(res.data);
			})
			.catch((err) => {});
	};

	return (
		<>
			{isMobile && (
				<div className="approved-mobile" style={{ position: 'sticky', top: 0, zIndex: 10 }}>
					<div className="verified-mobile">
						<svg xmlns="http://www.w3.org/2000/svg" width="21.986" height="21.985" viewBox="0 0 21.986 21.985">
							<path
								id="to-do"
								d="M15.115,16.489h-.458V4.122A4.127,4.127,0,0,0,10.535,0H4.122A4.127,4.127,0,0,0,0,4.122V18.779a3.209,3.209,0,0,0,3.2,3.206H14.657a2.752,2.752,0,0,0,2.748-2.748v-.458A2.292,2.292,0,0,0,15.115,16.489ZM4.58,18.779a1.374,1.374,0,0,1-2.748,0V4.122a2.292,2.292,0,0,1,2.29-2.29h6.413a2.292,2.292,0,0,1,2.29,2.29V16.489H6.871A2.292,2.292,0,0,0,4.58,18.779Zm10.993.458a.916.916,0,0,1-.916.916H6.1a3.184,3.184,0,0,0,.31-1.374.459.459,0,0,1,.458-.458h8.245a.459.459,0,0,1,.458.458ZM19.238,0a2.752,2.752,0,0,0-2.748,2.748v9.475a4.552,4.552,0,0,0,1.342,3.239l.759.758a.916.916,0,0,0,1.3,0l.759-.758a4.552,4.552,0,0,0,1.342-3.239V2.748A2.752,2.752,0,0,0,19.238,0Zm.916,12.223a2.732,2.732,0,0,1-.805,1.944l-.111.111-.111-.111a2.732,2.732,0,0,1-.805-1.944V2.748a.916.916,0,0,1,1.832,0ZM3.017,6.5a.916.916,0,0,1,1.3-1.3l1.18,1.181a.1.1,0,0,0,.127,0l2-2a.916.916,0,0,1,1.3,1.3l-2,2a1.924,1.924,0,0,1-2.717,0L3.018,6.5Zm3.9,6.677a1.924,1.924,0,0,1-2.717,0L3.018,12a.916.916,0,0,1,1.3-1.3l1.18,1.181a.1.1,0,0,0,.127,0l2-2a.916.916,0,0,1,1.3,1.3l-2,2Z"
								fill="currentColor"
							/>
						</svg>
						<span style={{ marginLeft: 5, fontSize: 16 }}>Estimates & Agreements</span>
					</div>
				</div>
			)}
			<div className="main-page-client">
				{!isLoading ? (
					<div className="order-client-content">
						{!isMobile && (
							<div
								className="d-flex align-items-center TableSerachBar"
								style={{
									justifyContent: isMobile ? 'flex-end' : 'space-between' // Add this line
								}}
							>
								<div className="heading-order-client-tbl">{!isMobile && <h3>Estimates & Agreements</h3>}</div>
								<form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
									<div className="input-group">
										<input
											ref={inputRef}
											type="text"
											className="form-control bg-white border"
											placeholder="Search"
											aria-label="Search"
											aria-describedby="basic-addon2"
											value={searchField}
											onChange={(e) => {
												handleSearch(e);
											}}
											onFocus={() => {
												dispatch(setSearchBar(true));
											}}
											onBlur={() => {
												dispatch(setSearchBar(!searchBar));
											}}
										/>
										<span className="searchIcon">
											<FaSearch />
										</span>
									</div>
								</form>
							</div>
						)}
						{!isMobile ? (
							<div className="estimation-table-area" style={{ height: isMobile ? 'auto' : '93%' }}>
								<div className="table-side">
									<div
										className="table-responsive-xl tableData estimationList"
										style={{ height: isMobile ? 'auto' : '100%', overflowY: isMobile ? 'none' : 'auto' }}
									>
										{data.length > 0 ? (
											<>
												<table className="order-client-tb min-width-unset pm-table d-sm-table d-md-table">
													<thead
														style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff', borderBottom: '1px solid #cacaca' }}
													>
														<tr>
															{/* <th width="3%"></th> */}
															<th scope="col" className="text-left">
																Reference
															</th>
															<th scope="col" className="text-left">
																Total
															</th>

															<th scope="col" className="text-left">
																Manager
															</th>
															<th scope="col" className="text-left">
																Date
															</th>
															<th scope="col" className="text-left">
																Status
															</th>
														</tr>
													</thead>
													<tbody>
														{data.map((item, index) => {
															return (
																<tr key={index} onClick={() => EstimationDetails(item.id, item?.master_status?.name)}>
																	{/* <td>
															{item?.master_status?.name === 'Pending Customer' ? (
																<div className="tooltipclient">
																	<svg xmlns="http://www.w3.org/2000/svg" width="28.037" height="28.037" viewBox="0 0 28.037 28.037">
																		<g id="Group_40124" data-name="Group 40124" transform="translate(-1.88 -1.955)">
																			<g id="Icon" transform="translate(2 2)" opacity="0.604">
																				<rect id="Bg" width="28" height="28" rx="14" transform="translate(-0.119 -0.045)" fill="#fae4e1" />
																			</g>
																			<g id="Group_40122" data-name="Group 40122" transform="translate(16.196 1.955) rotate(45)">
																				<g id="Group_40121" data-name="Group 40121">
																					<g id="Up_Icon" data-name="Up Icon">
																						<path id="Shape" d="M0,20.247H19.4V0H0Z" fill="none" />
																						<path
																							id="Combined_Shape"
																							data-name="Combined Shape"
																							d="M1.381,9.442a.844.844,0,0,1,0-1.687H6.289L.237,1.44a.87.87,0,0,1,0-1.193.786.786,0,0,1,1.144,0l6.132,6.4L7.718,1.7a.81.81,0,1,1,1.616.073l-.286,6.86a.824.824,0,0,1-.808.807Z"
																							transform="translate(5.177 4.981)"
																							fill="#f64e60"
																						/>
																					</g>
																				</g>
																			</g>
																		</g>
																	</svg>
																	<span className="tooltiptext">Waiting for approval</span>
																</div>
															) : (
																<div className="tooltipclient">
																	<svg xmlns="http://www.w3.org/2000/svg" width="28.037" height="29" viewBox="0 0 28.037 29">
																		<g id="Group_40123" data-name="Group 40123" transform="translate(-2 -0.955)">
																			<g id="Icon" transform="translate(2 2)" opacity="0.604">
																				<rect id="Bg" width="28" height="28" rx="14" transform="translate(0 -0.045)" fill="#d9f2ec" />
																			</g>
																			<g id="Group_40122" data-name="Group 40122" transform="translate(2 14.675) rotate(-45)">
																				<g id="Group_40121" data-name="Group 40121">
																					<g id="Up_Icon" data-name="Up Icon">
																						<path id="Shape" d="M0,0H19.4V20.247H0Z" fill="none" />
																						<path
																							id="Combined_Shape"
																							data-name="Combined Shape"
																							d="M.237,9.195A.87.87,0,0,1,.237,8L6.288,1.687H1.38A.826.826,0,0,1,.572.843.826.826,0,0,1,1.38,0H8.24a.825.825,0,0,1,.808.807l.286,6.86a.809.809,0,1,1-1.615.073L7.512,2.8,1.38,9.195a.784.784,0,0,1-1.143,0Z"
																							transform="translate(5.177 5.824)"
																							fill="#34c880"
																						/>
																					</g>
																				</g>
																			</g>
																		</g>
																	</svg>
																	<span className="tooltiptext">approved by client</span>
																</div>
															)}
														</td> */}
																	<td className="text-left ml-5">
																		<Link to={''} className="ml-1">
																			{item?.estimation_number}
																		</Link>
																	</td>
																	<td className="text-left" style={{ whiteSpace: 'nowrap' }}>
																		{item?.currency === 'eur' ? '€' : item?.currency === 'usd' ? '$' : 'AED'}{' '}
																		{Number(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																	</td>

																	<td className="text-left">{item?.sales_person?.first_name}</td>
																	<td className="text-left">{moment(item?.estimation_date, 'DD-MM-YYYY').format('DD MMMM YYYY')}</td>
																	<td className="text-left">
																		<div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
																			<div style={{ width: (windowWidth >= 650) & (windowWidth < 800) ? '100%' : '80%' }}>
																				{' '}
																				<StatusBadge itemStatus={item?.master_status?.name === 'Pending' ? 'Pending' : 'Accepted'} />
																			</div>
																		</div>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</table>
												<div
													style={{
														position: 'sticky',
														bottom: '-1px',
														width: '100%',
														backgroundColor: '#fff',
														textAlign: isMobile ? 'center' : 'right',
														marginTop: 10
													}}
												>
													<Pagination pagination={pagination} data={handleData} />
												</div>
											</>
										) : (
											<div style={{ width: '100%' }}>
												<div className="no-hover" style={{ width: '100%' }}>
													<div className="whitebg">
														<div className="not-found-container ">
															<IoCartOutline size={120} color="black" />

															<h6
																className="not-found-text"
																style={{ fontStyle: 'italic', color: '#000', fontSize: '18px', marginTop: 10 }}
															>
																Add your first order and enjoy the convenience!
															</h6>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
									{/* <Pagination pagination={paginationData} data={handleData} onLoading={(e) => {}} reactQuery /> */}
								</div>
							</div>
						) : (
							<>
								{data.length > 0 ? (
									<>
										<div className="order-list-client">
											<div className="row">
												<div className="col-5" style={{ padding: 10, paddingLeft: 20 }}>
													<span style={{ fontWeight: 600 }}>Reference</span>
												</div>
												<div className="col-5" style={{ padding: 10, display: 'flex', justifyContent: 'start', paddingRight: 20 }}>
													<span style={{ fontWeight: 600 }}>Status</span>
												</div>
											</div>
										</div>
										<div className="order-container">
											{data.map((item) => (
												<div
													className={`row order-item-client ${showButtons.includes(item.id) ? 'active' : ''}`}
													key={item.id}
													onClick={() => EstimationDetails(item.id, item?.master_status?.name)}
												>
													<div
														className="col-5"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingLeft: 20,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
													>
														<a>{item.estimation_number}</a>
													</div>
													<div
														className="col-5"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
													>
														<div style={{ width: 100 }}>
															<StatusBadge itemStatus={item?.master_status?.name === 'Pending' ? 'Pending' : 'Accepted'} />
														</div>
													</div>
													<div
														className="col-2"
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
														onClick={(event) => {
															event.stopPropagation();
															EstimationDetails(item.id, item?.master_status?.name);
														}}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21.985 21.985">
															<path
																id="search_1_"
																data-name="search (1)"
																d="M10.649,15.458a.859.859,0,1,1,0,1.718h-1.2a.859.859,0,1,1,0-1.718Zm7.042-4.594A.859.859,0,0,0,16.832,10H9.447a.859.859,0,1,0,0,1.718h7.386A.859.859,0,0,0,17.691,10.864ZM9.447,6.57h7.386a.859.859,0,1,0,0-1.718H9.447a.859.859,0,1,0,0,1.718ZM6.011,15.458H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.453H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.153H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,0,0,0-1.718ZM21.733,20.519a.859.859,0,0,1-1.215,1.215l-1.453-1.453A3.869,3.869,0,1,1,20.31,19.1ZM17.09,19.108a2.147,2.147,0,1,0-2.147-2.147A2.149,2.149,0,0,0,17.09,19.108ZM18.55,0H3.435A3.439,3.439,0,0,0,0,3.435V18.55a3.439,3.439,0,0,0,3.435,3.435h8.845a.859.859,0,1,0,0-1.718H3.435A1.72,1.72,0,0,1,1.718,18.55V3.435A1.72,1.72,0,0,1,3.435,1.718H18.55a1.72,1.72,0,0,1,1.718,1.718v8.5a.859.859,0,0,0,1.718,0v-8.5A3.439,3.439,0,0,0,18.55,0Zm0,0"
															/>
														</svg>
													</div>
												</div>
											))}
										</div>
										<div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
											<Pagination pagination={pagination} data={handleData} />
										</div>
									</>
								) : (
									<>
										<div style={{ width: '100%' }}>
											<div className="no-hover" style={{ width: '100%' }}>
												<div className="whitebg">
													<div className="not-found-container ">
														<IoCartOutline size={120} color="black" />

														<h6 className="not-found-text" style={{ fontStyle: 'italic', color: '#000', fontSize: '18px', marginTop: 10 }}>
															Add your first order and enjoy the convenience!
														</h6>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</>
						)}
					</div>
				) : (
					<></>
				)}
				{agreementPopup && (
					<AgreementModel
						id={id}
						openAgreementPopup={agreementPopup}
						pending={pending}
						handleClose={closeagreementPopup}
						handleSave={submiAgreement}
						type={type}
					/>
				)}
				<SearchBar searchField={searchField} handleSearch={handleSearch} autoSuggest={autoSuggest} />
			</div>
		</>
	);
};

export default Estimates;
