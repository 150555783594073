import React, { useState, useEffect } from 'react';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Pagination from 'ui-component/Pagination';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Loader from 'ui-component/Loader';
import { useSelector } from 'react-redux';
import AlertModel from './AlertModel';
import { BsCheckCircleFill, BsClipboardCheck } from 'react-icons/bs';
import { FaPen, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { GiHandTruck } from 'react-icons/gi';
import { RiLoader3Line } from 'react-icons/ri';
import { CiEdit } from 'react-icons/ci';
import { AiOutlineFileDone } from 'react-icons/ai';
import { FaShippingFast } from 'react-icons/fa';
import { LiaShippingFastSolid } from 'react-icons/lia';
import { TbDatabaseExclamation, TbFileInvoice } from 'react-icons/tb';
import { Popover, Skeleton, Tooltip } from '@mui/material';
import CurrencyValue from 'ui-component/CurrencyValue';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import PopupState, { bindHover, bindPopover, bindTrigger } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { LiaCommentSolid } from 'react-icons/lia';
import SortableHeader from 'ui-component/SortableHeader';
import PLCards from './PLCards';

const FoundTable = ({
	activeComponent,
	tableHeaderStyles,
	purchaseList,
	isLoading,
	selectedIds,
	handleSelectAll,
	handleSelectRow,
	assignInspenction,
	viewEstimateDetails,
	moveStatus,
	editData,
	searchData,
	searchField,
	currnetCurrency,
	activeFilters,
	setActiveFilters,
	filters,
	privacyMode,
	createPurchaseInvoice,
	showErrorDialog,
	sortByFilter,
	updateSortByStatus
}) => {
	const [assignbtn, setAssignbtn] = useState(true);
	const [needCollect, setNeedCollect] = useState(true);
	const [underProcess, setUnderProcess] = useState(true);
	const [enableEdit, setEnableEdit] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const [selectedRowdata, setSelectedRowData] = useState([]);
	const [openAlert, setAlert] = useState('');
	const history = useNavigate();

	const editSet = () => {
		const selectedRow = purchaseList?.filter((row) => selectedIds.includes(row.id));
		let ttt = false;
		if (selectedRow.length > 1) {
			ttt = selectedRow.every((item) => item.master_status.name === 'Sourced' || item.master_status.name === 'Inspection');
			if (ttt) {
				setEnableEdit(true);
			} else {
				setEnableEdit(false);
			}
		} else {
			if (selectedRow[0]?.master_status.name == 'Sourced' || selectedRow[0]?.master_status.name == 'Inspection') {
				setEnableEdit(true);
			} else {
				setEnableEdit(false);
			}
		}
	};

	useEffect(() => {
		editSet();
		const selectedRow = purchaseList.filter((row) => selectedIds.includes(row.id));
		setSelectedRowData(selectedRow);
		const firststatus = selectedRow[0]?.status;
		const supllierId = selectedRow[0]?.supplier_id;

		let hasSameStatusId = true;
		if (selectedRow.length > 1) {
			hasSameStatusId = selectedRow.every((item) => item.status === firststatus && item.supplier_id === supllierId);
		}
		if (hasSameStatusId && selectedRow[0]?.master_status.name == 'Sourced') {
			setAssignbtn(true);
		} else if (hasSameStatusId && selectedRow[0]?.master_status.name == 'Inspected') {
			setNeedCollect(true);
		} else if (hasSameStatusId && selectedRow[0]?.master_status.name == 'Collected') {
			setUnderProcess(true);
		} else {
			setAssignbtn(false);
			setNeedCollect(false);
			setUnderProcess(false);
		}
	}, [selectedIds]);
	const movetostatus = (data) => {
		const statusName = data;
		if (selectedIds.length < 1) {
			showErrorDialog('Error', 'Please select models');
		} else {
			moveStatus({
				status: statusName,
				purchase_list_ids: selectedIds
			});
		}
	};

	const handlealerclose = () => {
		setAlert('');
	};
	const checkrequiredstatus = (statusName) => {
		const hasNeedInspectionStatus = selectedRowdata.every((item) => item.master_status.name == statusName);
		return hasNeedInspectionStatus;
	};
	const sameStatusandSupcheck = () => {
		const firststatus = selectedRowdata[0]?.status;
		const supllierId = selectedRowdata[0]?.supplier_id;
		const hasSameStatusId = selectedRowdata.every((item) => item.status === firststatus && item.supplier_id === supllierId);
		return hasSameStatusId;
	};
	const supInvoicedCheck = () => {
		const hasInvoicedItems = selectedRowdata.some((item) => {
			const piTotal = item?.purchase_invoice_items?.reduce((p, c) => c?.item_qty + p, 0) || 0;
			return piTotal >= item?.purchase_qty;
		});
		return hasInvoicedItems;
	};
	const editoption = () => {
		if (selectedIds.length < 1) {
			showErrorDialog('Error', 'Please select models');
			// } else if (!enableEdit) {
			// 	showErrorDialog(
			// 		'Error',
			// 		'Sorry, you cannot perform this action. Make Sure to select Models having same supplier name and same status.'
			// 	);
		} else {
			editData();
		}
	};
	const assignInspenctionfunc = () => {
		const hasNeedInspectionStatus = selectedRowdata.some((item) => item.master_status.name == 'Sourced');
		if (selectedIds.length < 1) {
			showErrorDialog('Error', 'Please select models');
		} else if (!sameStatusandSupcheck()) {
			showErrorDialog(
				'Error',
				'Sorry, you cannot perform this action. Make Sure to select Models having same supplier name and same status.'
			);
		} else if (!checkrequiredstatus('Sourced')) {
			showErrorDialog('Error', 'The Action you selected is inappropriate as per the models selection.');
		} else {
			assignInspenction();
		}
	};
	const collectFunc = () => {
		const hasNeedInspectionStatus = selectedRowdata.some((item) => item.master_status.name == 'Inspected');
		if (selectedIds.length < 1) {
			showErrorDialog('Error', 'Please select models');
		} else if (!sameStatusandSupcheck()) {
			showErrorDialog(
				'Error',
				'Sorry, you cannot perform this action. Make Sure to select Models having same supplier name and same status.'
			);
		} else if (!checkrequiredstatus('Inspected')) {
			showErrorDialog('Error', 'The Action you selected is inappropriate as per the models selection.');
		} else {
			assignInspenction();
		}
	};

	const createPurchaseInvoiceFunc = () => {
		const hasNeedInspectionStatus = selectedRowdata.some((item) => item.master_status.name == 'Inspected');
		if (selectedIds.length < 1) {
			showErrorDialog('Error', 'Please select models');
		} else if (!sameStatusandSupcheck()) {
			showErrorDialog(
				'Error',
				'Sorry, you cannot perform this action. Make Sure to select Models having same supplier name and same status.'
			);
		} else if (supInvoicedCheck()) {
			showErrorDialog(
				'Error',
				'Sorry, you cannot perform this action. You have selected some items that has already been invoiced by supplier.'
			);
		} else {
			createPurchaseInvoice && createPurchaseInvoice();
		}
	};

	const underprocessfunc = () => {
		const hasNeedInspectionStatus = selectedRowdata.some((item) => item.master_status.name == 'Collected');
		if (selectedIds.length < 1) {
			showErrorDialog('Error', 'Please select models');
		} else if (!sameStatusandSupcheck()) {
			showErrorDialog(
				'Error',
				'Sorry, you cannot perform this action. Make Sure to select Models having same supplier name and same status.'
			);
		} else if (!checkrequiredstatus('Collected')) {
			showErrorDialog('Error', 'The Action you selected is inappropriate as per the models selection.');
		} else {
			movetostatus('Under Process');
		}
	};
	return (
		<>
			<div className="d-flex align-items-center justify-content-between TableSerachBar">
				<div>
					<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
				</div>
				<div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
					<div className={`bulk-actions ${selectedIds?.length > 0 ? 'show-mobile' : ''}`}>
						<>
							{activeComponent == 'Sourced' && (
								<>
									<PermissionAllowed permissions={['Assign Inspection (Button)']}>
										<button
											className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 && checkrequiredstatus('Sourced') ? '' : 'disabled'
												}`}
											style={{ minWidth: '80px' }}
											onClick={() => selectedIds?.length > 0 && checkrequiredstatus('Sourced') && assignInspenctionfunc()}
										>
											<BsClipboardCheck /> Assign Inspection
										</button>
									</PermissionAllowed>

									<PermissionAllowed permissions={['Assign Collection (Button)']}>
										<button
											className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 && checkrequiredstatus('Inspected') ? '' : 'disabled'
												}`}
											style={{ minWidth: '80px' }}
											onClick={() => selectedIds?.length > 0 && checkrequiredstatus('Inspected') && collectFunc()}
										>
											<GiHandTruck /> Need to Collect
										</button>
									</PermissionAllowed>

									<>
										{/* <button
												className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 ? '' : 'disabled'}`}
												style={{ minWidth: '80px' }}
												onClick={() => selectedIds?.length > 0 && underprocessfunc()}
											>
												<RiLoader3Line /> Under Process
											</button> */}
									</>
									{/* <>
										<button
											className={`bulk-action btn-outlined-main ${
												selectedIds?.length > 0  ? '' : 'disabled'
											}`}
											style={{ minWidth: '80px' }}
											onClick={() => selectedIds?.length > 0  && editoption()}
										>
											<CiEdit /> Edit
										</button>
									</> */}
								</>
							)}

							<button
								className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 ? '' : 'disabled'}`}
								style={{ minWidth: '80px' }}
								onClick={() => selectedIds?.length > 0 && editoption()}
							>
								<CiEdit /> Edit
							</button>
							<button
								className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 ? '' : 'disabled'}`}
								style={{ minWidth: '80px' }}
								onClick={() => selectedIds?.length > 0 && createPurchaseInvoiceFunc()}
							>
								<GiHandTruck /> Add Purchase Invoices
							</button>

							<>
								{/* {activeComponent == 'Under Process' && (
									<>
										<button
											className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 ? '' : 'disabled'}`}
											style={{ minWidth: '80px' }}
											onClick={() => selectedIds?.length > 0 && movetostatus('Ready To Ship')}
										>
											<AiOutlineFileDone /> Ready To Ship
										</button>
									</>
								)} */}
								{/* {activeComponent == 'Ready To Ship' && (
									<>
										<button
											className={`bulk-action btn-outlined-main ${selectedIds?.length > 0 ? '' : 'disabled'}`}
											style={{ minWidth: '80px' }}
											onClick={() => selectedIds?.length > 0 && movetostatus('Shipped')}
										>
											<LiaShippingFastSolid /> Shipped
										</button>
									</>
								)} */}
							</>
						</>
						{/* ) : (
							''
						)} */}
					</div>
					<form className="form-inline navbar-search">
						<div className="input-group">
							<input
								type="text"
								className="form-control bg-white border"
								placeholder="Search"
								aria-label="Search"
								aria-describedby="basic-addon2"
								onChange={(e) => {
									searchData(e.target.value);
								}}
								value={searchField}
								style={{ width: '100%' }}
							/>
							<span className="searchIcon">
								<FaSearch />
							</span>
						</div>
					</form>
				</div>
			</div>
			<div className="table-responsive-xl tableData estimationList">
				<Scroller extraHeight={44}>
					<table className="table  pm-table d-none d-md-table">
						<thead>
							<tr className="sticky-header">
								<th className="align-middle" width={'15'}>
									{purchaseList?.length > 0 && (
										<>
											<div className="myTest custom-control custom-checkbox custom-list-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id="groupCheck"
													onChange={handleSelectAll}
													checked={selectedIds.length === purchaseList.length}
												/>
												<label className="custom-control-label" for="groupCheck">
													&nbsp;
												</label>
											</div>
										</>
									)}
								</th>
								<th scope="col" width="">
									<SortableHeader
										name="customer"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('customer');
										}}
									>
										Customer
									</SortableHeader>
								</th>
								{/* <th scope="col" width="">
									Estimate
								</th> */}
								<th scope="col" width="">
									<SortableHeader
										name="invoice_number"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('invoice_number');
										}}
									>
										Invoice
									</SortableHeader>
								</th>
								<th scope="col">
									<SortableHeader
										name="product"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('product');
										}}
									>
										Product
									</SortableHeader>
								</th>
								<th className="" scope="col">
									Qty
								</th>
								<th scope="col" width={''}>
									<SortableHeader
										name="supplier"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('supplier');
										}}
									>
										Supplier
									</SortableHeader>
								</th>
								<th scope="col" width={''}>
									Date
								</th>
								<th scope="col" width={''}>
									Selling
								</th>
								<th scope="col" width={''}>
									Purchase
								</th>
								<th scope="col" width={''}>
									Profit
								</th>
								<th scope="col" width={''}>
									<SortableHeader
										name="status"
										currentSortBy={sortByFilter}
										onClick={() => {
											updateSortByStatus('status');
										}}
									>
										Status
									</SortableHeader>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{isLoading ? (
								<>
									{[...Array(5)].map((e, i) => (
										<tr>
											<>
												{[...Array(11)].map((e, i) => (
													<td>
														<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
													</td>
												))}
											</>
										</tr>
									))}
								</>
							) : (
								<>
									{purchaseList.length > 0 &&
										purchaseList.map((item, index) => {
											return (
												<>
													<tr key={index} onClick={() => handleSelectRow(item.id)}>
														<td className="align-middle" width={'15'}>
															<div className="myTest custom-control custom-checkbox custom-list-checkbox">
																<input
																	type="checkbox"
																	className="custom-control-input shadowdisable"
																	id={`row-${index}`}
																	checked={selectedIds.includes(item.id)}
																	// onChange={() => handleSelectRow(item.id)}
																	onClick={(e) => {
																		e.preventDefault();
																	}}
																	disabled
																/>
																<label className="custom-control-label" for={`row-${index}`}>
																	&nbsp;
																</label>
															</div>
														</td>
														<td className="align-middle">
															<PrivacyModeContent>{item?.customer?.name}</PrivacyModeContent>
														</td>
														{/* <td className="align-middle">
															<Link to={`/sales/invoices/${item?.estimation_id}`}>{item?.estimation_number}</Link>
														</td> */}
														<td className="align-middle">
															{item.invoice_number ? <Link to={`/sales/invoices/${item?.estimation_id}`}>{item?.invoice_number}</Link> : '-'}
														</td>
														<td className="align-middle" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
															{item?.product?.product_name}

															{item?.comment ? (
																<Tooltip title={item?.comment} arrow placement="top">
																	<span style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
																		<LiaCommentSolid />
																	</span>
																</Tooltip>
															) : (
																''
															)}
														</td>
														<td className="align-middle">{item?.purchase_qty}</td>
														<td className="align-middle">
															<PrivacyModeContent>{item?.supplier?.name}</PrivacyModeContent>
														</td>
														<td className="align-middle">{moment(item?.estimation_date).format(CommonDateOnlyFormat)}</td>
														<td className="align-middle">
															<CurrencyValue currencyObject={item?.converted_currency_selling_price_unit} />
														</td>
														<td className="align-middle">
															<CurrencyValue currencyObject={item?.converted_currency_purchase_price_unit} />
														</td>
														<td className="align-middle">
															{/* <CurrencyValue
																currencyObject={Object.keys(
																	item?.converted_currency_selling_price_unit
																).reduce((acc, key) => {
																	if (item?.converted_currency_purchase_price_unit[key]) {
																		acc[key] =
																			item?.converted_currency_selling_price_unit[key] -
																			item?.converted_currency_purchase_price_unit[key];
																	}
																	return acc;
																}, {})}
															/>{' '} */}
															<CurrencyValue
																currencyObject={Object.keys(item?.converted_currency_selling_price_unit).reduce((acc, key) => {
																	if (item?.converted_currency_purchase_price_unit[key]) {
																		acc[key] =
																			((item?.converted_currency_selling_price_unit[key] -
																				item?.converted_currency_purchase_price_unit[key]) /
																				item?.converted_currency_purchase_price_unit[key]) *
																			100;
																	}
																	return acc;
																}, {})}
																onlyValue={true}
															/>
															%
														</td>
														<td className="align-middle">{item?.master_status?.name}</td>
														<td>
															{item?.purchase_invoice_items?.length > 0 && (
																<>
																	<PopupState variant="popover" popupId="demo-popup-popover">
																		{(popupState) => (
																			<div>
																				<Link to={`#`} style={{ cursor: 'pointer' }} {...bindHover(popupState)}>
																					<TbFileInvoice />
																				</Link>
																				<HoverPopover
																					{...bindPopover(popupState)}
																					anchorOrigin={{
																						vertical: 'bottom',
																						horizontal: 'center'
																					}}
																					transformOrigin={{
																						vertical: 'top',
																						horizontal: 'center'
																					}}
																				>
																					<ul className="pl-items-list">
																						{item?.purchase_invoice_items?.map((pi, index) => (
																							<li>
																								<b>{pi?.item_qty}</b> Pcs-{' '}
																								<Link to={`/purchase-invoice/${pi?.purchase_invoice?.id}`}>
																									{pi?.purchase_invoice?.invoice_number}
																								</Link>
																							</li>
																						))}
																					</ul>
																				</HoverPopover>
																			</div>
																		)}
																	</PopupState>
																</>
															)}
														</td>
													</tr>
												</>
											);
										})}
									{purchaseList?.length < 1 && !isLoading && (
										<tr className="no-hover">
											<td colSpan={11}>
												<div className="not-found-container">
													<div className="not-found-icon">
														<TbDatabaseExclamation />
													</div>
													<h6 className="not-found-text">No Data Available</h6>
												</div>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</table>
					<PLCards purchaseList={purchaseList} handleSelectRow={handleSelectRow} selectedIds={selectedIds} />
				</Scroller>
				<AlertModel openStatus={openAlert} handleclose={handlealerclose} />
			</div>
		</>
	);
};

export default FoundTable;
