import React, { useEffect, useRef, useState } from 'react';
import LogoSingle from 'assets/images/pmix-logo-single.svg';
import { IoSearchOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import QuickActions from 'ui-component/quick-actions/QuickActions';
import { FaSearch } from 'react-icons/fa';
import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import { FaChartBar } from 'react-icons/fa6';
import { storeHeaderActions } from 'store/reducers/mainSlice';
import config from '../../../config';

const HeaderMobile = () => {
	const searchInputRef = useRef();
	const [pageTitle] = useSelector((state) => [state.main.pageTitle]);
	const dispatch = useDispatch();
	const headerActions = useSelector((state) => state.main.headerActions);
	const [searchExpand, setSearchExpand] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const handleSearchExpand = () => {
		if (!searchExpand) {
			setSearchExpand(true);
			setTimeout(() => {
				// searchInputRef.current.focus();
			}, 1);
		} else {
			setSearchExpand(false);
		}
	};

	useEffect(() => {
		dispatch(
			storeHeaderActions({
				showQuickStats: false,
				showExport: false
			})
		);
	}, []);

	const toggleQuickStats = () => {
		dispatch(
			storeHeaderActions({
				showQuickStats: !headerActions.showQuickStats,
				showExport: headerActions.showExport
			})
		);
	};

	const toggleExport = () => {
		dispatch(
			storeHeaderActions({
				showQuickStats: headerActions.showQuickStats,
				showExport: !headerActions.showExport
			})
		);
	};

	const handleSearchSubmit = (e) => {
		e.preventDefault();
		console.log('searchValue', searchValue);
	};

	const appName = config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.app_name;

	return (
		<>
			<header className="mobileHead">
				<h4 className="p-0 m-0 font-weight-bold d-flex justify-content-center align-items-center" style={{ lineHeight: '1' }}>
					{appName == 'glpwireless' ? 'GLP Wireless' : 'Mix Global FZCO'}
				</h4>

				<div className="d-flex justify-content-center align-items-center" style={{ gap: '20px' }}>
					<div style={{ fontSize: '18px', lineHeight: '1' }}>
						<span onClick={handleSearchExpand}>
							<FaSearch />
						</span>
					</div>
					{pageTitle == 'Orders' && (
						<div style={{ fontSize: '19px', lineHeight: '1' }}>
							<span onClick={toggleQuickStats}>
								<FaChartBar />
							</span>
						</div>
					)}
					{/* {(pageTitle == 'Orders' || pageTitle == 'Purchasing') && (
						<div style={{ fontSize: '21px', lineHeight: '1' }}>
							<span onClick={toggleExport}>
								<PiMicrosoftExcelLogoFill />
							</span>
						</div>
					)} */}
					<div style={{ fontSize: '18px', lineHeight: '1' }}>
						<QuickActions isOpened={true} />
					</div>
				</div>
			</header>
			<div className={`mobile-search-container ${searchExpand ? 'show' : ''}`}>
				<form onSubmit={handleSearchSubmit}>
					<div className="form-group m-0 mb-1" style={{ position: 'relative', paddingInline: '20px' }}>
						<input
							type="search"
							className="form-control"
							placeholder="Search..."
							ref={searchInputRef}
							value={searchValue}
							onChange={(e) => {
								setSearchValue(e.target.value);
							}}
						/>
						<span style={{ position: 'absolute', right: '30px', top: '50%', transform: 'translateY(-50%)', color: '#b9b9b9' }}>
							<FaSearch />
						</span>
					</div>
				</form>
			</div>
		</>
	);
};

export default HeaderMobile;
