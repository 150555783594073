import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Scroller from 'ui-component/Scroller';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import ProductVariations from './ProductVariations'
import { Skeleton, Tooltip } from '@mui/material';
import { AiOutlineDelete } from 'react-icons/ai';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import toast from 'react-hot-toast';
import { FaPlus, FaSave, FaPencilAlt } from 'react-icons/fa';
import { MdOutlineModeEdit } from "react-icons/md";
import Select from 'react-select';
import { HiOutlineRefresh } from 'react-icons/hi';
import ComingSoonTab from './components/ComingSoonTab';
import DetailsTab from './components/DetailsTab';
import ImagesTab from './components/ImagesTab';
import PurchaseOrderTab from './components/PurchaseOrderTab';
import BackordersTab from './components/BackordersTab';
import ProductOrderHistoryTab from './components/ProductOrderHistoryTab';
import ProductHistory from './components/ProductHistory';
import WarehouseModal from './components/WarehouseModal';
import ChangeStockModal from './components/ChangeStockModal';
import NoImage from 'assets/images/no-image.png';
import usePageTitle from 'hooks/usePageTitle';
import { Dropdown } from 'react-bootstrap';
import { LuChevronDown } from 'react-icons/lu';



const ProductView = () => {
	const [setPageTitle] = usePageTitle('Products');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const { id } = useParams();
	const [activeComponent, setActiveComponent] = useState('Details');
	const selectedUser = useSelector((state) => state.main.user.selectedUser);
	const [isDisable, setDisable] = useState(false);
	const [openWarehousePopup, setOpenWarehousePopup] = useState(false);
	const [changeStockPopup, setChangeStockPopup] = useState(false);
	const dispatch = useDispatch();
	const history = useNavigate();
	const [productDetails, setProductDetails] = useState();
	const [backorders, setBackorders] = useState();
	const [defaultThumbnail, setDefaultThumbnail] = useState('');
	const [isEdit, setisEdit] = useState(false);
	const [isChecked, setisChecked] = useState(null);
	const [associateproduct, setAssociateproduct] = useState([]);
	const [isLoading, setIsloading] = useState(false);
	const [disableStatus, setDisablestatus] = useState(false);
	const [imageId, setImageId] = useState(null);
	const [files, setFiles] = useState([]);
	const [isDisabled, setIsDisabled] = useState(true);
	const [currentStatus, setCurrentStatus] = useState(false);
	const [currentPortalStatus, setCurrentPortalStatus] = useState(false);

	const [show, setShow] = useState('');


	const getProductDetails = () => {
		setIsloading(true);
		api.getProductDetails(id).then((res) => {
			setIsloading(false);
			setIsDisabled(false)
			setProductDetails(res.data);
			setisChecked(res?.data?.status == 1 ? true : false)
			setAssociateproduct(res.data.products);
			res?.data?.thumbnails?.map((thumbnail) => {
				if (thumbnail?.is_default == 1) {
					setDefaultThumbnail(thumbnail?.thumbnail_url);
					// } else {
					// 	setDefaultThumbnail('https://t3.ftcdn.net/jpg/05/88/70/78/360_F_588707867_pjpsqF5zUNMV1I2g8a3tQAYqinAxFkQp.jpg');
				}
			});
			if (res?.data?.status == 1) {
				setCurrentStatus(true);
			} else {
				setCurrentStatus(false);
			}
			if (res?.data?.in_portal == 1) {
				setCurrentPortalStatus(true);
			} else {
				setCurrentPortalStatus(false);
			}
		});
	};
	// const getProductBackorders = (id) => {
	// 	api.getProductBackorders(id).then((res) => {
	// 		if (res.status) {
	// 			setBackorders(res?.data)
	// 		}
	// 	})
	// };

	const edit = () => {
		setisEdit(!isEdit)
	}
	const handleClose = () => setShow('');

	const handleDelete = (item) => {

		toast.promise(api.deleteModelProduct(item).then((res) => {
			if (res.status) {
				setProductDetails(oldData => ({ ...oldData, products: oldData?.products?.filter(p => p?.id != item) }));
			}

			setShow(null);

			return res?.status ? res : abc;
		}), {
			loading: 'Deleting Product...',
			success: (data) => `${data.message}`,
			error: <b>Failed to delete product</b>
		})
	};

	const handleComponentChange = (component) => {
		setActiveComponent(component);
	};

	const openModalFormWarehouse = () => {
		setOpenWarehousePopup(true);
	}

	const closePopup = () => {
		setOpenWarehousePopup(false);
	};
	const closechagePopup = () => {
		setChangeStockPopup(false);
	}

	const handleSave = (data) => {
		toast.promise(
			api
				.assignProductWarehouse({
					product_id: data.productId,
					warehouse_id: data.warehouseId,
				})
				.then((res) => {
					setDisable(false);
					if (res.status) {
						setOpenWarehousePopup(false);
						getProductDetails();
					}
					return res?.status ? res : null;
				}),
			{
				loading: 'Assigning Warehouse...',
				success: (data) => `${data.message}`,
				error: <b>Failed to assign product warehouse</b>
			}
		);
	}
	const changeStocks = (data) => {

		toast.promise(
			api
				.changeStock(data)
				.then((res) => {
					closechagePopup();
					getProductDetails();

					return res?.status ? res : null;
				}),
			{
				loading: 'Assigning Warehouse...',
				success: (data) => `${data.message}`,
				error: <b>Failed to change stock</b>
			}
		);
	}

	const handleFileUpload = (e) => {
		const files = e;
		setFiles(files);
		setDisablestatus(true);
		if (files && files.length > 0) {
			const formData = new FormData();
			for (let i = 0; i < files.length; i++) {
				formData.append('thumbnail_files[]', files[i]);
			}
			formData.append('product_id', productDetails?.id);

			api.uploadProductImage(productDetails?.id, formData).then((res) => {
				setProductDetails(oldData => ({ ...oldData, thumbnails: res.data?.thumbnails }));
				setDisablestatus(false);
				setImageId(res.data);

				res?.data?.thumbnails?.map((thumbnail) => {
					if (thumbnail?.is_default == 1) {
						setDefaultThumbnail(thumbnail?.thumbnail_url);
					}
				});
			});
		}
	};

	const deleteThumbnail = (thumbnailId) => {
		toast.promise(
			api
				.deleteProductThumbnail(thumbnailId)
				.then(
					(res) => {
						if (res.status) {
							setProductDetails(oldData => ({ ...oldData, thumbnails: res.data }));

							res?.data?.map((thumbnail) => {
								if (thumbnail?.is_default == 1) {
									setDefaultThumbnail(thumbnail?.thumbnail_url);
								}
							});
							// history(`/products/${res.data.id}`);
						}

						// setIsSubmitDisabled(false);
						return res?.status ? res : null;
					},
					() => {
						// setIsSubmitDisabled(false);
					}
				),
			{
				loading: 'Updating Products...',
				success: (data) => `${data.message}`,
				error: <b>Failed to update products</b>
			}
		);
	}

	const handleDeleteThumbnail = () => {
		const thumbnailId = show;
		toast.promise(
			api
				.deleteProductThumbnail(thumbnailId)
				.then(
					(res) => {
						if (res.status) {
							setProductDetails(oldData => ({ ...oldData, thumbnails: res.data }));

							res?.data?.map((thumbnail) => {
								if (thumbnail?.is_default == 1) {
									setDefaultThumbnail(thumbnail?.thumbnail_url);
								}
							});
							// history(`/products/${res.data.id}`);
						}
						setShow('');

						// setIsSubmitDisabled(false);
						return res?.status ? res : null;
					},
					() => {
						// setIsSubmitDisabled(false);
					}
				),
			{
				loading: 'Updating Products...',
				success: (data) => `${data.message}`,
				error: <b>Failed to update products</b>
			}
		);
	}

	const handleSetDefaultThumbnail = (thumbnailId) => {

		let thumbnailFormData = {
			product_id: productDetails?.id,
			thumbnail_id: thumbnailId
		};

		toast.promise(
			api
				.setProductDefaultThumbnail(id, thumbnailFormData)
				.then(
					(res) => {
						if (res.status) {
							setProductDetails(oldData => ({ ...oldData, thumbnails: res.data }));

							res?.data?.map((thumbnail) => {
								if (thumbnail?.is_default == 1) {
									setDefaultThumbnail(thumbnail?.thumbnail_url);
								}
							});
							// history(`/products/${res.data.id}`);
						}

						// setIsSubmitDisabled(false);
						return res?.status ? res : null;
					},
					() => {
						// setIsSubmitDisabled(false);
					}
				),
			{
				loading: 'Updating Products...',
				success: (data) => `${data.message}`,
				error: <b>Failed to update products</b>
			}
		);

	}
	const changeStock = () => {
		setChangeStockPopup(true);
	}

	const handleChangeType = (type) => {

		// 1 => Normal; 2 => Unlimited stock; 3 => Virtual composition; 4 => Composition with own stock

		let paramValue = '';
		let paramType = 'product-type';
		let msg = 'product type';
		if (type == 'status') {
			paramValue = productDetails?.status ? 0 : 1;
			paramType = 'status'
			msg = 'status'
		} else if (type == 'portal') {
			paramValue = productDetails?.in_portal ? 0 : 1;
			paramType = 'portal'
			msg = 'portal status'
		} else if (type == 'unlimited-stock') {
			paramValue = 2;
		} else if (type == 'virtual-composition') {
			paramValue = 3;
		} else if (type == 'virtual-composition-stock') {
			paramValue = 4;
		} else {
			return false;
		}
		toast.promise(
			api
				.changeProductType({
					product_id: productDetails?.id,
					type: paramType,
					param: paramValue
				})
				.then((res) => {
					getProductDetails();

					return res?.status ? res : null;
				}),
			{
				loading: `Changing ${msg}...`,
				success: (data) => `${data.message}`,
				error: <b>Failed to change {msg}</b>
			}
		);
	}
	useEffect(() => {
		getProductDetails();
		// getProductBackorders(id)
	}, []);

	return (
		<>


			<div className="row">
				<div className="col-12">
					<div className="row pb10">
						<div className="col-12">
							<div className="section-head">
								<h2
									style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}
								>
									{productDetails?.sku}
								</h2>
								<div className="actions">
									<PermissionAllowed permissions={['Edit Model Details (Action)']}>

										<div>

											<Dropdown className="product-view-change-type iconbtn" align={'end'}>
												<Dropdown.Toggle as="span">
													<Link to={''}
														className={isDisabled ? 'btn btn-primary btn-outlined-main white-var disabled' : 'btn btn-primary btn-outlined-main white-var'}
														disabled={isDisabled}
														style={{ minWidth: 'unset' }}>
														Change Type <LuChevronDown />
													</Link>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item
														onClick={() => {
															handleChangeType('status');
														}}
													>
														Make product {currentStatus ? 'inactive' : 'active'}
													</Dropdown.Item>
													<Dropdown.Item
														onClick={() => {
															handleChangeType('portal');
														}}
													>
														Make product  {currentPortalStatus ? 'inactive' : 'active'} to portal
													</Dropdown.Item>
													{/* <Dropdown.Item
														onClick={() => {
															handleChangeType('unlimited-stock');
														}}
													>
														Change type to unlimited stock
													</Dropdown.Item>
													<Dropdown.Item
														onClick={() => {
															handleChangeType('virtual-composition');
														}}
													>
														Change type to virtual composition
													</Dropdown.Item>
													<Dropdown.Item
														onClick={() => {
															handleChangeType('virtual-composition-stock');
														}}
													>
														Change type to virtual composition with stock
													</Dropdown.Item> */}
												</Dropdown.Menu>
											</Dropdown>
										</div>

										<button
											className={isDisabled ? 'bulk-action btn-outlined-main ml8 disabled' : 'bulk-action btn-outlined-main ml8'}
											disabled={isDisabled}
											onClick={() => changeStock()}
										>
											<HiOutlineRefresh />  Change Stock
										</button>
										<button
											className={isDisabled ? 'bulk-action btn-outlined-main ml8 disabled' : 'bulk-action btn-outlined-main ml8'}
											disabled={isDisabled}
											onClick={() => history(`/products/edit/${productDetails?.id}`)}
										>
											<MdOutlineModeEdit />  Edit
										</button>

									</PermissionAllowed>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="content pad15">
							<div className="row m-0 p-0 mb-3">
								<div className="col-md-2 col-sm-3 col-12">
									{
										defaultThumbnail ? (
											<img src={defaultThumbnail} className='product-thumbnail' alt="product" />
										) : (
											<img src={NoImage} alt="product" style={{ width: '70px', height: '100px' }} />
										)
									}
								</div>
								<div className="col-md-8 col-sm-9 col-12 product-header-summary">
									<div className="row">
										<div className="col-12">


											<div className="row form-group">
												<div className="col-12">
													<div className="form-section">
														<div className=" font16">
															<h4 className='font18'><b>{productDetails?.product_name}</b></h4>
														</div>
													</div>
													<div className="row product-preview-stock-labels">
														<div className="col-12 font13 ">
															<div className="">
																<div
																	className={`badge2 badge-light-gray2`}
																	style={{ fontWeight: '500', color: '#000' }}
																>
																	{productDetails?.sku}
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-12 product-stock-summary">
															<div className="stock-list">
																<div className="row">
																	<div className="col-md-12 font13">
																		<span className="textGray">Free Stock</span>
																		<span className="font600 text-primary">: {productDetails?.stocks?.free || 0}</span>
																	</div>
																</div>
															</div>
															<div className="stock-list">

																<div className="row">
																	<div className="col-md-12 font13">
																		<span className="textGray">Reserved</span>
																		<span className="font600 text-primary">: {productDetails?.stocks?.reserved}</span>
																	</div>
																</div>
															</div>
															<div className="stock-list">

																<div className="row">
																	<div className="col-md-12 font13">
																		<span className="textGray">Total Stock</span>
																		<span className="font600 text-primary">: {productDetails?.stocks?.total}</span>
																	</div>
																</div>
															</div>
															<div className="stock-list">
																<div className="row">
																	<div className="col-md-12 font13">
																		<span className="textGray">To Receive</span>
																		<span className="font600 text-primary">: {productDetails?.stocks?.to_receive}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													{/* <div className="row">
														<div className="col-md-3 col-sm-6 col-12 font13">

															<div className="row form-group product-preview-stock-labels">
																<div className="col-12">
																	<div className="row">
																		<div className="col-md-12 font13">
																			<span className="textGray">Reserved</span>
																			<span className="ml17 text-primary">: {productDetails?.stocks?.reserved}</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-md-3 col-sm-6 col-12 font13">
															<div className="row form-group product-preview-stock-labels">
																<div className="col-12">
																	<div className="row">
																		<div className="col-md-12 font13">
																			<span className="textGray">Total Stock</span>
																			<span className="ml6 text-primary">: {productDetails?.stocks?.total}</span>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-md-12 font13">
																			<span className="textGray">To Receive</span>
																			<span className="ml8 text-primary">: {productDetails?.stocks?.to_receive}</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div> */}
												</div>
											</div>


										</div>
									</div>


								</div>
							</div>



							<div className="row mt20">
								<div className="col-12">
									<ul className="topListCom">
										<li
											className={`${activeComponent === 'Details' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('Details');
											}}
										>
											Details
										</li>
										<li
											className={`${activeComponent === 'StockHistory' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('StockHistory');
											}}
										>
											Stock history
										</li>
										<li
											className={`${activeComponent === 'PurchaseOrders' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('PurchaseOrders');
											}}
										>
											Purchase orders
										</li>
										<li
											className={`${activeComponent === 'OrderHistory' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('OrderHistory');
											}}
										>
											Order history
										</li>
										<li
											className={`${activeComponent === 'Images' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('Images');
											}}
										>
											Images
										</li>
										<li
											className={`${activeComponent === 'Backorders' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('Backorders');
											}}
										>
											Backorders
										</li>
										<li
											className={`${activeComponent === 'Picklists' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('Picklists');
											}}
										>
											Pick-lists
										</li>
										<li
											className={`${activeComponent === 'Log' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('Log');
											}}
										>
											Log
										</li>
									</ul>

									<hr style={{ marginTop: '-1px' }} />
								</div>
							</div>

							{activeComponent === 'Details' ? (
								<>
									<DetailsTab
										productDetails={productDetails}
										openModalFormWarehouse={openModalFormWarehouse}
									/>
								</>
							) : activeComponent == 'StockHistory' ? (
								<>
									<ProductHistory
										productDetails={productDetails}
									/>
								</>
							) : activeComponent == 'PurchaseOrders' ? (
								<>
									{/* <ComingSoonTab /> */}
									<PurchaseOrderTab
										productDetails={productDetails}
									/>
								</>
							) : activeComponent == 'OrderHistory' ? (
								<>
									<ProductOrderHistoryTab
										productDetails={productDetails}
									/>
								</>
							) : activeComponent == 'Images' ? (
								<>
									<ImagesTab
										productDetails={productDetails}
										handleFileUpload={handleFileUpload}
										deleteThumbnail={deleteThumbnail}
										handleSetDefaultThumbnail={handleSetDefaultThumbnail}
									/>
								</>
							) : activeComponent == 'Backorders' ? (
								<>
									<BackordersTab
										productDetails={productDetails}
									// backorders={backorders}
									/>
								</>
							) : activeComponent == 'Picklists' ? (
								<>
									<ComingSoonTab />
								</>
							) : activeComponent == 'Log' ? (
								<>
									<ComingSoonTab />
								</>
							) : (
								<></>
							)}


						</div>
					</div>
				</div>
			</div>
			{!!show && <DeleteConfirmation
				show={show}
				handleClose={handleClose}
				handleDelete={handleDelete}
				title="Confirm"
				message={`Are you sure you want to delete ${show?.name}`}
			/>}
			{openWarehousePopup && (
				<WarehouseModal
					openWarehousePopup={openWarehousePopup}
					productDetails={productDetails}
					handleClose={closePopup}
					handleSave={handleSave}
					disableStatus={isDisable}
				/>
			)}

			{changeStockPopup && (
				<ChangeStockModal
					openChangestockPopup={changeStockPopup}
					productDetails={productDetails}
					handleClose={closechagePopup}
					handleSave={changeStocks}
					disableStatus={isDisable}
				/>
			)}
		</>
	);
};

export default ProductView;
