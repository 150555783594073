import React from 'react';
import Select from 'react-select'; // replace with actual import
import { IconArrowsExchange } from '@tabler/icons'; // replace with actual import
import { Fade } from 'react-awesome-reveal';

const ExchangeCard = ({
	exchangePermission,
	amount,
	handleAmountChange,
	result,
	toCurrency,
	fromCurrency,
	handleFromCurrencyChange,
	currencyOptions,
	handleIconClick,
	handleToCurrencyChange
}) => (
	<div className="col-lg-3 col-md-6 col-sm-12" style={{ height: 210, marginBottom: '20px' }}>
		{exchangePermission && exchangePermission.is_visible === 1 && (
			<div className="row">
				<div className="col-12">
					<Fade>
						<div className="card" style={{ height: '210px', marginBottom: '15px', padding: '10px' }}>
							<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: 0, paddingLeft: '10px' }}>
								<h5 style={{ fontWeight: '500', fontSize: '13px' }}>Exchange</h5>
							</div>
							<div className="card-body d-flex flex-column align-items-center" style={{ padding: '10px', paddingTop: 0 }}>
								<form onSubmit={(event) => event.preventDefault()} style={{ width: '100%', height: '100%' }}>
									<div className="form-group" style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
										<div>
											<label htmlFor="exampleFormControlSelect1">Amount</label>
											<input
												type="number"
												step="0.01"
												className="form-control"
												defaultValue={amount}
												onChange={handleAmountChange}
												style={{ width: 120 }}
											/>
										</div>
										<a style={{ fontSize: '13px', marginTop: '20px', marginLeft: '10px' }}>
											={' '}
											<strong>
												{result.toFixed(2)} {toCurrency}
											</strong>
										</a>
									</div>
									<div className="form-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div>
											<label htmlFor="fromCurrency">From</label>
											<Select
												className="custom-form custom-form-1"
												id="fromCurrency"
												value={currencyOptions.find((option) => option.value === fromCurrency)}
												onChange={(selectedOption) => handleFromCurrencyChange(selectedOption.value)}
												options={currencyOptions}
												formatOptionLabel={({ image, label }) => (
													<div>
														<img src={image} height="15px" width="15px" style={{ borderRadius: '50%', marginRight: 5 }} />
														{label}
													</div>
												)}
												styles={{
													control: (provided) => ({
														...provided
													}),
													dropdownIndicator: (provided) => ({
														...provided,
														backgroundColor: 'transparent',
														width: '10px' // Adjust this value as needed
													}),
													indicatorSeparator: () => ({
														display: 'none'
													})
												}}
											/>
										</div>
										<div style={{ paddingTop: '35px' }}>
											<IconArrowsExchange size={20} onClick={handleIconClick} />
										</div>
										<div>
											<label htmlFor="toCurrency">To</label>
											<Select
												className="custom-form custom-form-1"
												id="toCurrency"
												value={currencyOptions.find((option) => option.value === toCurrency)}
												onChange={(selectedOption) => handleToCurrencyChange(selectedOption.value)}
												options={currencyOptions}
												formatOptionLabel={({ image, label }) => (
													<div>
														<img src={image} height="15px" width="15px" style={{ borderRadius: '50%', marginRight: 5 }} />
														{label}
													</div>
												)}
												styles={{
													control: (provided) => ({
														...provided
													}),
													dropdownIndicator: (provided) => ({
														...provided,
														backgroundColor: 'transparent',
														width: '10px' // Adjust this value as needed
													}),
													indicatorSeparator: () => ({
														display: 'none'
													})
												}}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</Fade>
				</div>
			</div>
		)}
	</div>
);

export default ExchangeCard;
