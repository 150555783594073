import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { setLoading, storePermissions, storeUserDetails } from 'store/reducers/mainSlice';
import Logo from 'assets/images/logo-light.svg';
import CarouselUi from 'ui-component/CarouselUi';
import { Alert } from '@mui/material';
import './NewLogin.scss'; // Import your CSS file

function Copyright(props) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			<Link color="inherit" href="https://projectmix.ai/">
				Projectmix
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
	palette: {
		main: {
			primary: '#192138'
		}
	}
});

export default function NewLogin() {
	const history = useNavigate();
	let validToken = useSelector((state) => state.main?.user?.token);
	const [showPassword, setShowPassword] = useState('fa-eye');
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const [emsg, setEmsg] = useState('');
	const [rememberPwd, setRememberPwd] = useState(true);
	const isLoading = useSelector((state) => state.main.loading);
	const dispatch = useDispatch();

	// const onSubmit = (event) => {
	// 	event.preventDefault();
	// 	const data = new FormData(event.currentTarget);
	// 	console.log({
	// 		email: data.get('email'),
	// 		password: data..get('email')
	// 	});
	// };

	const onSubmit = (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);

		const data = {
			username: formData?.get('email'),
			password: formData?.get('password')
		};

		dispatch(setLoading(true));

		api.login(data).then((res) => {
			console.log(res);
			const data = res.data[0];

			if (res.status && data.token) {
				const email = data.email;
				const setPassword = data.set_password;
				dispatch(storeUserDetails({ ...data, stayLoggedIn: rememberPwd }));
				dispatch(storePermissions(data?.permissions));
				if (setPassword === false) {
					history('/dashboard');
				} else {
					history('/setPassword', { state: { email } });
				}
			} else {
				setEmsg(res.message);
				setTimeout(() => setEmsg(''), 3000);
			}
			dispatch(setLoading(false));
		});
	};

	useEffect(() => {
		document.title = 'Projectmix - Login';
		if (validToken) {
			history('/dashboard');
		}
	}, []);

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid container component="main" sx={{ height: '100dvh' }}>
				<Grid
					item
					xs={false}
					sm={false}
					md={6}
					// hidden={{ xs: true, sm: false, md: false }}
					sx={{
						// backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
						backgroundRepeat: 'no-repeat',
						// backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[900] : t.palette.grey[900]),
						background: 'radial-gradient(circle at left,#36214A,#161618 40%)',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						borderRight: '2px solid #373843'
					}}
					className="hidden-sm-grid-item"
				>
					<img src={Logo} style={{ width: '40%' }}></img>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					component={Paper}
					square
					sx={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#181823'
					}}
					container
					spacing={2}
				>
					<Box
						sx={{
							my: 8,
							mx: 12,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: '100%',
							justifyContent: 'center'
						}}
					>
						<div style={{ marginLeft: '-15px', textAlign: 'center' }}>
							{/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} className='hidden-sm-grid-item'>
								<LockOutlinedIcon />
							</Avatar> */}
							{/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} > */}
							<button type="button" className="login-with-google-btn">
								Sign in with Google
							</button>
							{/* </Avatar> */}
							<Typography component="h1" variant="h5" className="login-text-gray ">
								<h5 className="divider-text">
									<span>Or with email</span>
									<div className="line" style={{ width: '90%' }}></div>
								</h5>
							</Typography>
							<Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1, width: '100%', maxWidth: '450px' }}>
								{emsg && (
									<Alert
										severity="error"
										sx={{
											marginBottom: '5px'
										}}
									>
										{emsg}
									</Alert>
								)}
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									autoFocus
									type="email"
									className="login-form-field form-control-md font12"
									style={{ WebkitTextFillColor: '#fff !important' }}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									autoComplete="current-password"
									className="login-form-field"
									style={{ WebkitTextFillColor: '#fff !important' }}
								/>
								<FormControlLabel
									control={
										<Checkbox
											value="remember"
											color="primary"
											checked={rememberPwd}
											onChange={() => {
												setRememberPwd(!rememberPwd);
											}}
										/>
									}
									label="Remember me"
									className="login-text-gray font12"
								/>
								<Button type="submit" className="signin-button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
									Sign In
								</Button>
								<Grid container>
									<Grid item></Grid>
									<Grid item xs>
										<Link to={`/forgot`} variant="body2" className="font12">
											Forgot password?
										</Link>
									</Grid>
								</Grid>
								<Typography sx={{ mt: 10 }} className="login-text-gray" style={{ textAlign: 'center', fontSize: '12px' }}>
									Copyright ©{' '}
									<Link to="#" variant="body2">
										Projectmix
									</Link>{' '}
									2024.
								</Typography>
								{/* <Copyright sx={{ mt: 5 }} className='login-text-gray' /> */}
							</Box>
						</div>
					</Box>
				</Grid>
			</Grid>
			{/* <Grid container component="main" sx={{ height: '100vh' }}>
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						// backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
						backgroundRepeat: 'no-repeat',
						// backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[900] : t.palette.grey[900]),
						background: 'radial-gradient(circle at left,#36214A,#161618 40%)',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column'
					}}
				>
					<img src={Logo} style={{ width: '40%' }}></img>
				</Grid>
				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					square
					sx={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#181823',
					}}
				>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: '100%'
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1 }}>
							{emsg && (
								<Alert
									severity="error"
									sx={{
										marginBottom: '5px'
									}}
								>
									{emsg}
								</Alert>
							)}
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								type="email"
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
							/>
							<FormControlLabel
								control={
									<Checkbox
										value="remember"
										color="primary"
										checked={rememberPwd}
										onChange={() => {
											setRememberPwd(!rememberPwd);
										}}
									/>
								}
								label="Remember me"
							/>
							<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
								Sign In
							</Button>
							<Grid container>
								<Grid item>
								</Grid>
								<Grid item xs>
									<Link href="#" variant="body2">
										Forgot password?
									</Link>
								</Grid>
							</Grid>
							<Copyright sx={{ mt: 5 }} />
						</Box>
					</Box>
				</Grid>
			</Grid> */}
		</ThemeProvider>
	);
}
