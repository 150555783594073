import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import { FaCheckCircle, FaSave, FaTimes } from 'react-icons/fa';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';

import agreementImage from 'assets/images/agreement.svg';
import moment from 'moment';
import config from '../../../config';
import agreementBg from 'assets/images/agreement-bg.svg';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const OrderAgreementsPopup = ({ onClose, onSubmit, preLoadedData, contactData, skipAgreement }) => {
	const compRef = useRef(null);

	// ## To close the popup ## //
	const handleClose = () => {
		onClose && onClose();
	};

	const [isAgreementChecked, setIsAgreementChecked] = useState(false);

	// ## To close the popup when clicked outside ## //
	const handleClickOutside = (event) => {
		if (compRef.current && !compRef.current.contains(event.target)) {
			handleClose();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const [allOrderAgreements, setAllOrderAgreements] = useState([]);
	const [invalidData, setInvalidData] = useState(false);
	const [invalidDataMessage, setInvalidDataMessage] = useState('');
	const [selectedAgreements, setSelectedAgreements] = useState([]);

	// const handleAgreementSelect = (agreement_id, response_id, multi = false) => {

	// };
	const handleAgreementSingleSelect = (agreement, response) => {
		const newSelectedAgreements = [...selectedAgreements];
		const existingIndex = newSelectedAgreements.findIndex((item) => item?.agreement?.id === agreement?.id);
		if (existingIndex > -1) {
			if (response === null) {
				delete newSelectedAgreements[existingIndex];
			} else {
				newSelectedAgreements[existingIndex] = { agreement: agreement, response: response };
			}
		} else {
			newSelectedAgreements.push({ agreement: agreement, response: response });
		}
		setSelectedAgreements(newSelectedAgreements?.filter((item) => !!item));
	};

	const handleAgreementMultiSelect = (agreement, response) => {
		const newSelectedAgreements = [...selectedAgreements];
		const existingIndex = newSelectedAgreements.findIndex((item) => item?.agreement?.id === agreement?.id);
		if (existingIndex > -1) {
			if (response === null) {
				delete newSelectedAgreements[existingIndex];
			} else {
				const responseExist = newSelectedAgreements[existingIndex]?.response?.find((item) => item?.id === response?.id);
				newSelectedAgreements[existingIndex] = {
					agreement: agreement,
					response: responseExist
						? newSelectedAgreements[existingIndex]?.response?.filter((r) => r?.id != responseExist?.id)
						: [...newSelectedAgreements[existingIndex]?.response, response]
				};

				if (newSelectedAgreements[existingIndex]?.response?.length <= 0) {
					delete newSelectedAgreements[existingIndex];
				}
			}
		} else {
			newSelectedAgreements.push({ agreement: agreement, response: [response] });
		}
		setSelectedAgreements(newSelectedAgreements?.filter((item) => !!item));
	};

	const getSelectOptions = () => {
		// Fetch Models
		api.getOrderAgreements().then((res) => {
			setAllOrderAgreements(res?.data);
		});
	};

	const handleCheckboxChange = () => {
		setIsAgreementChecked(!isAgreementChecked); // Toggle the state of isChecked
		// You can also call other functions or perform additional logic here
	};

	useEffect(() => {
		if (preLoadedData) {
			console.log('preLoadedData', preLoadedData);
			if (preLoadedData?.selections) {
				setAllOrderAgreements(preLoadedData?.selections?.allOrderAgreements);
			} else {
				getSelectOptions();
			}
			setSelectedAgreements(preLoadedData?.selectedAgreements || []);
			if (preLoadedData?.selectedAgreements?.length > 0) {
				setIsAgreementChecked(true);
			}
		} else {
			getSelectOptions();
		}
	}, []);

	const handleSubmit = () => {
		console.log('selectedAgreements', selectedAgreements);
		onSubmit &&
			onSubmit({
				selectedAgreements: selectedAgreements?.filter(
					(item) => !!item && (item?.agreement?.type == 'multiselect' ? item?.response?.length > 0 : !!item?.response)
				),
				selections: {
					allOrderAgreements
				}
			});
	};
	const handleSkipAgreement = () => {
		skipAgreement(true);
	};

	useEffect(() => {
		const handleEscKey = (event) => {
			if (event.keyCode === 27) {
				handleClose();
			}
		};

		document.addEventListener('keydown', handleEscKey, false);

		return () => {
			document.removeEventListener('keydown', handleEscKey, false);
		};
	}, []); // Empty array ensures effect is only run on mount and unmount

	const appName = config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.app_name;

	return (
		<div
			style={{ position: 'fixed', width: '100dvw', height: '100vh', top: '0', left: '0', zIndex: '1000', overflow: 'auto' }}
			className="d-flex justify-content-center align-items-center order-agreements-bg"
		>
			<div
				style={{
					// backgroundImage: `url(${agreementBg})`,
					width: 'fit-content',
					padding: '63px 116px 71px 101px',
					// padding: '150px 78px 140px 76px',
					backgroundRepeat: 'no-repeat',
					backgroundSize: '100% 100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '200px'
				}}
			>
				<div className="popup agreement-popup" style={{ maxWidth: '50rem' }} ref={compRef}>
					<button className="btn modal-close-icon" onClick={handleClose}>
						<svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
							<path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
						</svg>
					</button>

					<div className="text-center mb-3 pt-2">
						<img src={agreementImage} alt="" className="agreement-img" />
					</div>

					<h4 className="text-center mb-3">SALES AGREEMENT</h4>

					<p className="text-center mb-3">
						This agreement is made on <Link to="#">{moment(contactData?.invoiceDate).format('DD MMMM YYYY')}</Link> between{' '}
						<Link to={`/contacts/${contactData?.customer?.id}`} target="_blank">
							<PrivacyModeContent>{contactData?.customer?.label}</PrivacyModeContent>
						</Link>
						,{' '}
						{contactData?.customer?.address?.address && (
							<>
								located at{' '}
								<Link to="#">
									<PrivacyModeContent>
										{contactData?.customer?.address?.address ? `${contactData?.customer?.address?.address}, ` : ''}
										{contactData?.customer?.address?.zipcode ? `${contactData?.customer?.address?.zipcode}, ` : ''}
										{contactData?.customer?.address?.city?.city ? `${contactData?.customer?.address?.city?.city}, ` : ''}
										{contactData?.customer?.address?.country?.name ? `${contactData?.customer?.address?.country?.name}` : ''}
									</PrivacyModeContent>
								</Link> (The Client){' '}
							</>
						)}
						{contactData?.transitCompany?.name && (
							<>
								<Link to={`/contacts/${contactData?.transitCompany?.id}`} target="_blank">
									{contactData?.transitCompany?.name}
								</Link>{' '}
								{contactData?.transitCompany?.address?.address ? (
									<>
										located at{' '}
										<Link to="#">
											{contactData?.transitCompany?.address?.address ? `${contactData?.transitCompany?.address?.address}, ` : ''}
											{contactData?.transitCompany?.address?.zipcode ? `${contactData?.transitCompany?.address?.zipcode} ` : ''}
											{contactData?.transitCompany?.address?.city ? `${contactData?.transitCompany?.address?.city}, ` : ''}
											{contactData?.transitCompany?.address?.country ? `${contactData?.transitCompany?.address?.country}` : ''}
										</Link>
										.
									</>
								) : contactData?.transitCompany?.address ? (
									<>
										located at{' '}
										<Link to="#">
											{contactData?.transitCompany?.address ? `${contactData?.transitCompany?.address}, ` : ''}
											{contactData?.transitCompany?.zipcode ? `${contactData?.transitCompany?.zipcode}, ` : ''}
											{contactData?.transitCompany?.city ? `${contactData?.transitCompany?.city}, ` : ''}
											{contactData?.transitCompany?.country ? `${contactData?.transitCompany?.country}` : ''}
										</Link>{' '}
									</>
								) : (
									<></>
								)}
								(The Supplier).
							</>
						)}
					</p>

					<p className="text-center mb-3">
						Both parties confirm that they have mutually agreed upon the following conditions, as negotiated between{' '}
						<b>{contactData?.salesManager?.label}</b>,{' '}
						{contactData?.transitCompany?.label && <>representing {contactData?.transitCompany?.label}</>}, and{' '}
						{contactData?.customer?.contact_name ? (
							<>
								<Link to="#">
									<PrivacyModeContent>{contactData?.customer?.contact_name}</PrivacyModeContent>
								</Link>{' '}
								the representative of{' '}
							</>
						) : (
							<></>
						)}
						<Link to={`/contacts/${contactData?.customer?.id}`} target="_blank">
							<PrivacyModeContent>{contactData?.customer?.label}</PrivacyModeContent>
						</Link>
						.
					</p>

					<div className="m-0 p-0 d-flex flex-column align-items-center pt-1">
						{allOrderAgreements?.map((agreementItem) => (
							<div style={{ maxWidth: '500px', width: '100%' }}>
								<div className="form-group text-center">
									<label style={{ fontWeight: '500' }}>{agreementItem?.title}</label>
									<ReactSelect
										key={`select-item-${agreementItem?.id}`}
										className="rs-container"
										classNamePrefix="rs"
										options={agreementItem?.options?.map((item) => ({ ...item, value: item?.id, label: item?.option }))}
										onChange={(selectedOption) => {
											handleAgreementSingleSelect(agreementItem, selectedOption);
										}}
										value={selectedAgreements?.find((item) => item?.agreement?.id === agreementItem?.id)?.response}
										placeholder={`No ${agreementItem?.title}`}
										isSearchable
										isClearable
										// menuPlacement="auto"
										menuPosition="fixed"
										isMulti={agreementItem?.type == 'multiselect'}
									/>
								</div>
							</div>
						))}
					</div>

					<p
						className="d-flex justify-content-center align-items-center text-center mb-4 pt-4"
						style={{ gap: '10px', color: '#838383', fontStyle: 'italic' }}
					>
						Note: <br />
						Please be aware that a 2% charge will be applied to the entire order amount if payment is not made on time, {' '}
						{/* <br /> */}
						due to fluctuations in USD and EUR currency values.
					</p>
					<p className="" style={{ gap: '10px' }}>
						<p className="d-flex justify-content-center align-items-center mb-4" style={{ gap: '10px' }}>
							<span style={{ color: 'green', fontSize: '20px', lineHeight: '1' }}>
								<div className="round">
									<input type="checkbox" id="checkbox" checked={isAgreementChecked} onChange={handleCheckboxChange} />
									<label for="checkbox"></label>
								</div>
							</span>
							<span style={{ lineHeight: '1' }}>
								<Link to={'#'}>Terms of conditions</Link> are accepted and shared with the customer.
							</span>
						</p>

						<div className="btnarea mt-3 text-center">
							<Tooltip title={invalidData ? invalidDataMessage : null} arrow placement="top">
								<button className="btn btn-main" onClick={handleSubmit} disabled={!isAgreementChecked}>
									<FaSave /> Save
								</button>
								<div style={{ marginTop: '15px' }}>
									<a href="javascript:void(0);" onClick={handleSkipAgreement}>
										Skip
									</a>
								</div>
							</Tooltip>
						</div>
					</p>
				</div>
			</div>
		</div>
	);
};

export default OrderAgreementsPopup;
