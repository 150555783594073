import React, { useState, useEffect } from 'react'
import { Box, TextField, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip, Card } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading, storeInLocalStore } from 'store/reducers/mainSlice'
import MultiSelectInput from 'ui-component/MultiSelectInput'
import Loader from 'ui-component/Loader'

const EditRoles = () => {
	const history = useNavigate()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm()
	const selectedRole = useSelector((state) => state.main.selectedItem)
	// const { id } = useParams()
	const dispatch = useDispatch()
	const [roles, setRoles] = useState([])
	const [role, setRole] = useState([])
	const [mods, setMods] = useState([])
	const [modsIds, setModsids] = useState([])
	const [roleIds, setRoleIds] = useState([])
	const [permissionToIds, setPermissionToIds] = useState({})
	const [mdouleName, setModuleName] = useState([])
	// const loading = useSelector((state) => state.main.loading)
	const [alertMessage, setAlertmessage] = useState({})

	const getRoleData = async () => {
		const response = await api.getPermissionsList('all')
		setRoles(response.data)
		setModuleName(unquieModuleArray(response.data))
		// console.log(mdouleName, 'adsfsfs')
		const permissionIds = {}
		for (const perm of response.data) {
			permissionIds[perm.name] = perm.id
		}
		// console.log(permissionIds)
		setPermissionToIds(permissionIds)
	}

	const onChipDelete = (chip) => {
		let newMods = mods.filter(mo => !mo.includes(chip))
		let newRole = role.filter(rl => !rl.includes(chip))
		let isLastItem = role.filter(nrl => {
			let ch = nrl.split(" ")
			let ch1 = chip.split(" ")
			ch.pop()
			ch1.pop()
			return ch.join(" ").trim() === ch1.join(" ").trim()
		})
		if (isLastItem.length === 1){
			if (isLastItem[0] === chip){
				let lastChip = chip.split(" ")
				lastChip.pop()
				lastChip = lastChip.join(" ").trim()
				newMods = newMods.filter(nm => nm !== lastChip)

			}
		}
		setRole(newRole)
		setMods(newMods)
		
	}

	const unquieModuleArray = (row) => {
		// console.log(row)
		return [...new Set(row?.map((el) => el.group_name))]
	}
	const handleChangeMods = (event) => {
		const {
			target: { value }
		} = event
		// console.log('######## handmole value: ', value)

		const nameArray = typeof value === 'string' ? value.split(',') : value

		const ids = nameArray.map((name) => nameToId[name])
		const modeValues = typeof value === 'string' ? value.split(',') : value
		// console.log(modeValues)
		setMods(modeValues)
		setModsids(ids)
		const selectedRoleToPrmission = modeValues.map((mel) =>
			roles.filter((el) => el.name.toLowerCase().charAt(0) == mel.toLowerCase().charAt(0)).map((mmd) => mmd.name)
		)
		const flatten = (ary) => ary.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), [])
		const newSelectedRoleToPermission = flatten(selectedRoleToPrmission).filter(sr => sr.includes(modeValues[modeValues.length -1]))
		setRole([...role, ...newSelectedRoleToPermission])
	}

	const handleChangerole = (event) => {
		const {
			target: { value }
		} = event
		// console.log('######## handlrole value: ', value)
		const nameArray = typeof value === 'string' ? value.split(',') : value

		const ids = nameArray.map((name) => nameToId[name])
		const roleValues = typeof value === 'string' ? value.split(',') : value
		setRole(roleValues)
		setRoleIds(ids)
		const newMods = [...new Set(roleValues.map((e) => e.split(' ')[0].trim()))]
		setMods(newMods)
		// console.log(roleValues, 'asdfsfdsdffsdfsdfsdasfdsdfs')
		// console.log(ids, 'asdfsfdsdffsdfsdfsd')
	}
	const nameToId = roles.reduce((acc, item) => {
		acc[item.name] = item.id
		return acc
	}, {})

	const onSubmit = (data) => {
		// e.preventDefault()
		// console.log(
		// 	data,
		// 	mods,
		// 	role.map((p) => permissionToIds[p]),
		// 	roleIds
		// )
		// return
		// dispatch(setLoading(true))
		api.updateRole(id, {
			name: data?.name,
			permission: role.map((p) => permissionToIds[p])
		}).then((res) => {
			// console.log('Responce: ', res)
			if (res.status) {
				// dispatch(setLoading(false))
				setAlertmessage({
					success: true,
					color: 'text-success',
					message: 'Role updated Successfully.'
				})
				setTimeout(() => {
					setAlertmessage({})
					history('/settings/role')
				}, 1500)
			} else {
				// dispatch(setLoading(false))
				setAlertmessage({
					success: false,
					color: 'text-danger',
					message: 'Invalid Error.'
				})
				setTimeout(() => {
					setAlertmessage({})
				}, 2000)
			}
		})
	}

	useEffect(() => {
		getRoleData()
		// console.log(selectedRole, 'asfdsdfsdfsdfsdfsfdsfdsdfsdf')
		reset({
			name: selectedrole[0]?.name
		})
		const mods = [...new Set(selectedRole?.permissions?.map((el) => el.permission_group))]
		const roles = selectedRole?.permissions?.map((el) => el.name)
		setMods(mods)
		setRole(roles)
	}, [])

	return (
		<>
			<div className="d-sm-flex align-items-center justify-content-between mb-4">
				<h1 className="h2 mb-0  font-weight-bold">Edit Role</h1>
			</div>
			{/* { loading ? <Box sx={{ marginTop: '400px'}}><Loader /></Box>: null } */}
			<div className="card shadow-sm bg-white mb-4 p-2 p-xl-3">
				<div className="col-lg-12 justify-content-between">
					<div className="p-1">
						<form className="userForm" onSubmit={handleSubmit(onSubmit)}>
							<div className="form-group">
								<FormControl sx={{ width: '100% ', mt: 2 }} variant="">
									<Box sx={{ mb: 4, color: 'black' }}>
										<InputLabel id="name" shrink={true} disableAnimation={true} variant="standard" focused={false}>
                                            Name *
										</InputLabel>
									</Box>
									<OutlinedInput
										id="name"
										disabled={selectedRole?.id === 2}
										className="form-control"
										placeholder="Name *"
										variant="outlined"
										sx={{ color: 'black' }}
										{...register('name', { required: true })}
									/>
								</FormControl>
								{errors.name && <p className="ml-2 text-danger">Name is required</p>}
							</div>
							<div className="form-group col-md-12 px-3 py-1">	
							<MultiSelectInput
								labelName={'Module Access'}
								name={'module-access'}
								value={mods}
								menuItems={mdouleName}
								handleChange={handleChangeMods}
								onChipDelete={onChipDelete}
							/>
							</div>
							<div className="form-group col-md-12 px-3 py-1">	
							<MultiSelectInput
								labelName={'Permissions'}
								name={'permissions'}
								value={role}
								menuItems={roles?.map((rl) => rl.name)}
								handleChange={handleChangerole}
								onChipDelete={onChipDelete}
							/>
							</div>
							<Box sx={{ mt: 0 }}>
							<div className="form-group col-md-12 formActBtns px-3 py-1 text-right">
							<button className="btn rounded-pill btn-primary px-4 canBtn">Cancel</button>
									<button
										type="submit"
										name="wp-submit"
										id="wp-submit"
										className="btn rounded-pill btn-primary px-4 greenBtn ml-5 "
										defaultValue="Login">
										Update
 									</button>
								</div>
							</Box>
						</form>
						{alertMessage.success && <span className={`px-3 down-error ${alertMessage.color}`}>{alertMessage.message}</span>}
					</div>
				</div>
			</div>
		</>
	// </MainWrapper>
	)
}

export default EditRoles
