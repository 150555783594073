import { CommonDateOnlyFormat } from 'helpers/common.helper';
import moment from 'moment';
import React from 'react';
import { TbDatabaseExclamation } from 'react-icons/tb';
import CurrencyValue from 'ui-component/CurrencyValue';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const PLCards = ({ purchaseList, handleSelectRow, selectedIds }) => {
	return (
		<div className="inv-cards-list d-md-none">
			{purchaseList?.map((item, index) => (
				<div className={`order-list-card ${selectedIds.includes(item.id) ? 'selected' : ''}`} onClick={() => handleSelectRow(item.id)}>
					<div className="order-list-card-header">
						<h6 className="order-no">{item?.product?.product_name}</h6>
						<p className="order-date">{moment(item?.estimation_date).format(CommonDateOnlyFormat)}</p>
					</div>

					<div className="order-list-card-body">
						<div className="order-contacts">
							<p className="order-customer">{item.invoice_number ? item?.invoice_number : '-'}</p>
							{item?.customer && (
								<p className="order-transit">
									for <PrivacyModeContent>{item?.customer?.name}</PrivacyModeContent>
								</p>
							)}
						</div>
						<div className="order-contacts">
							<p className="total-val text-right">
								<CurrencyValue currencyObject={item?.converted_currency_selling_price_unit} />
							</p>
							{item?.master_status?.name?.toLowerCase() != 'sourcing' && (
								<p className="order-transit text-right">
									Sourced for <CurrencyValue currencyObject={item?.converted_currency_purchase_price_unit} />
								</p>
							)}
						</div>
					</div>

					<div className="order-list-card-footer">
						<div className="order-icon-items" style={{ gap: '5px' }}>
							<div className="order-bordered-status">
								{item?.master_status?.name?.toLowerCase() == 'sourcing' || item?.master_status?.name?.toLowerCase() == 'cancelled'
									? item?.sold_qty
									: item?.purchase_qty}{' '}
								Pcs
							</div>
							{item?.supplier && <div className="order-bordered-status">{item?.supplier?.name}</div>}
						</div>

						<div className={`order-status order-status-${item?.master_status?.name?.toLowerCase()?.replaceAll(' ', '-')}`}>
							{item?.master_status?.name}
						</div>
					</div>
				</div>
			))}
			{purchaseList?.length < 1 && (
				<div className="w-100">
					<div className="not-found-container">
						<div className="not-found-icon">
							<TbDatabaseExclamation />
						</div>
						<h6 className="not-found-text">No Data Available</h6>
					</div>
				</div>
			)}
		</div>
	);
};

export default PLCards;
