import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { DeliveryIcon, FlagIcon, CheckCircleGreenIcon, AddCartIcon } from 'ui-component/custom-icons/icons';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import StatusBadge from 'ui-component/StatusBadge';


const Breadcrumbs = ({ status, disabled }) => {

    
    return (
        <>
            <div className='card-breadcrumbs'>

                <nav>
                    <ol class="cd-breadcrumb custom-separator" style={{ padding: '0', margin: '0', background: 'transparent' }}>
                        {/* {
                            referenceNumber && (
                                <>
                                    <li style={{ margin: '0' }}>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <a style={{ textDecoration: 'none' }}>
                                                {referenceNumber}
                                            </a>
                                            <div style={{ width: '75px'}}>
                                                <StatusBadge itemStatus={'Open'} />
                                            </div>
                                        </div>
                                    </li>
                                </>
                            )
                        } */}
                        <li style={{ margin: '0' }}>
                            <a style={{ textDecoration: 'none' }}>
                                <div>

                                    <button
                                        className={`btn btn-primary btn-outlined-main white-var`}
                                        // disabled={!disabled || (status === 'Closed') ? true : false}
                                    >
                                        <AddCartIcon width='18px' height='18px' />
                                        Pick Products
                                    </button>
                                    {
                                        status === 'Closed' && (
                                            <>
                                                <div style={{ position: 'absolute', top: '-6px' }}>
                                                    <CheckCircleGreenIcon width={'15px'} height={'15px'} />
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </a>
                        </li>
                        <li style={{ margin: '0' }}>
                            <a style={{ textDecoration: 'none' }}>
                                <div>
                                    <button
                                        className={`btn btn-primary btn-outlined-main white-var`}
                                        // disabled={!disabled || (status === 'Closed') ? true : false}
                                    >
                                        <DeliveryIcon width='18px' height='18px' />
                                        Create Shipment
                                    </button>
                                    {
                                        status === 'Closed' && (
                                            <>
                                                <div style={{ position: 'absolute', top: '-6px' }}>
                                                    <CheckCircleGreenIcon width={'15px'} height={'15px'} />
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </a>
                        </li>
                        <li style={{ margin: '0' }}>
                            <a style={{ textDecoration: 'none' }}>
                                <div>
                                    <button
                                        className={`btn btn-primary btn-outlined-main white-var`}
                                        // disabled={!disabled || (status === 'Closed') ? true : false}
                                    >
                                        <FlagIcon width='12px' height='12px' />
                                        Close Picklist
                                    </button>
                                    {
                                        status === 'Closed' && (
                                            <>
                                                <div style={{ position: 'absolute', top: '-6px' }}>
                                                    <CheckCircleGreenIcon width={'15px'} height={'15px'} />
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </a>
                        </li>
                        {/* <PermissionAllowed 
                            permissions={[
                                'List Operation Repairs',
                            ]}
                            hasSome={true}
                        >
                            <li style={{ margin: '0' }}>
                                <a
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => {
                                        // history(`/operations/view/${activateTabsUsingId}/repairs`);
                                    }}
                                >
                                    <button
                                        disabled={!disabled}
                                        className={`btn btn-primary ${(current === 'repairs') ? 'btn-outlined-main-primary' : 'btn-outlined-main'} white-var`}
                                    >
                                        <ChecklistIcon width='14px' height='14px' />
                                        Next Picklist in Batch
                                    </button>
                                </a>
                            </li>
                        </PermissionAllowed> */}
                    </ol>
                </nav>
            </div>
        </>
    );
};

export default Breadcrumbs;
