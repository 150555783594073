import React, { useState, useRef, useEffect } from 'react';
import './reports.scss';
import Scroller from 'ui-component/Scroller';
import { Link } from 'react-router-dom';
import { LuChevronDown } from 'react-icons/lu';
import { Dropdown } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { FaFilter } from 'react-icons/fa6';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BsCalendar2Date } from 'react-icons/bs';
import Pagination from 'ui-component/Pagination';
import { IoEyeOutline } from 'react-icons/io5';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import usePageTitle from 'hooks/usePageTitle';

const Report = () => {
	const [setPageTitle] = usePageTitle('Reports');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const { id } = useParams();
	const dateRef = useRef(null);
	const columnRef = useRef(null);
	const [activeFilters, setActiveFilters] = useState({});
	const [filters, setFilters] = useState([]);
	const [reportData, setReportData] = useState(null);
	const [columnsData, setColumns] = useState(null);
	const [reportName, setReportName] = useState(null);
	const [showPicker, setShowPicker] = useState(false);
	const [isLoading, setIsloading] = useState(false);
	const [pagination, setpagination] = useState(null);
	const [isButtonClicked, setIsButtonClicked] = useState(false);
	const [ShowColumns, setShowColumns] = useState(false);
	const [isChecked, setChecked] = useState([]);

	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: null,
			key: 'selection'
		}
	]);

	useEffect(() => {
		setPageTitle(`${reportName ? `Report - ${reportName}` : 'Report'}`);
	}, [reportName]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dateRef.current && !dateRef.current.contains(event.target)) {
				setShowPicker(false);
			}
			if (columnRef.current && !columnRef.current.contains(event.target)) {
				setShowColumns(false);
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	useEffect(() => {
		initiateDatas();
	}, []);

	const initiateDatas = () => {
		if (id) {
			api.getReportbyId(id).then((res) => {
				console.log(res);
				setReportName(res?.data?.report_type);
			});

			api.getReportbyTypeId(id, null, null).then((res) => {
				// console.log(res)
				// setReportData(res?.data?.data)
				// if (res?.data?.data?.length > 0) {
				//     console.log(res?.data?.data[0])
				//     const firstRow = res?.data?.data[0];
				//     const columnNames = Object.keys(firstRow);
				//     console.log(columnNames)
				//     setColumns(columnNames)
				//     setpagination(res?.data?.pagedResults)
				//   }
				setFilters(res?.data?.filters);
			});
		} else {
			history('/report');
		}
	};

	const handleData = (data) => {
		console.log(data);
		setReportData(data?.data);
		setpagination(data?.pagedResults);
	};

	const fetchData = (filters, date, columns) => {
		setIsloading(true);
		api.getReportbyTypeId(id, filters, date, columns).then((res) => {
			setIsloading(false);
			console.log(res);
			setpagination(res?.data?.pagedResults);
			setReportData(res?.data?.data);
			if (res?.data?.data?.length > 0) {
				const firstRow = res?.data?.data[0];
				if (columnsData == null) {
					const columnNames = Object.keys(firstRow);
					const initialColumnsData = columnNames?.map((name) => ({ name, isVisible: true }));
					setColumns(initialColumnsData);
					setChecked(Array(columnNames?.length).fill(true));
				}
			}
		});
	};

	const downloadReport = (excel) => {
		let date = null;
		const visibleColumns = columnsData?.filter((column) => column.isVisible);
		const selectedNames = visibleColumns?.map((column) => column.name);
		if (dateRange[0].startDate != null && dateRange[0].endDate != null) {
			const startdate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
			const enddate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
			console.log(startdate, enddate);

			date = {
				startDate: startdate,
				endDate: enddate
			};
		}
		api.downloadReports(id, date, activeFilters, date, excel, selectedNames).then((res) => {
			if (excel > 0) {
				var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				saveAs(newBlob, `${reportName}.xlsx`);
			} else {
				var newBlob = new Blob([res], { type: 'application/pdf' });
				saveAs(newBlob, `${reportName}.pdf`);
			}
		});
	};

	const handleSelect = (ranges) => {
		setDateRange([ranges.selection]);
	};

	const handleDateRangeClick = () => {
		setShowPicker(!showPicker);
	};

	const preview = () => {
		const visibleColumns = columnsData?.filter((column) => column.isVisible);
		const selectedNames = visibleColumns?.map((column) => column.name);
		let date = null;
		if (dateRange[0].startDate != null && dateRange[0].endDate != null) {
			const startdate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
			const enddate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
			console.log(startdate, enddate);

			date = {
				startDate: startdate,
				endDate: enddate
			};
		}
		fetchData(activeFilters, date, selectedNames);
	};

	const handleDisplayAllClick = () => {
		setColumns((prevColumnsData) => {
			const updatedColumnsData = prevColumnsData?.map((column) => ({
				...column,
				isVisible: true
			}));
			const updatedChecked = updatedColumnsData?.map((column) => column.isVisible);
			setChecked(updatedChecked);

			return updatedColumnsData;
		});
		setIsButtonClicked(!isButtonClicked);
	};

	const handleColumns = () => {
		setShowColumns(!ShowColumns);
	};

	const handlecolumnhideShow = (index) => {
		if (isButtonClicked != true) {
			setColumns((prevColumnsData) => {
				const updatedColumnsData = [...prevColumnsData];
				updatedColumnsData[index].isVisible = !updatedColumnsData[index].isVisible;
				const updatedChecked = updatedColumnsData.map((column) => column.isVisible);
				setChecked(updatedChecked);
				return updatedColumnsData;
			});
		}
	};

	const buttonStyle = {
		backgroundColor: isButtonClicked ? '#192138' : 'transparent',
		color: isButtonClicked ? 'white' : '#192138'
	};

	return (
		<div className="row " style={{ height: '100%' }}>
			<div className="col-xl-12 col-lg-12">
				<div className="section-head">{/* <h2>{reportName}</h2> */}</div>
				<div className="card shadow-sm bg-white mb-4">
					<div className="">
						<div className="row">
							<div className="col-md-5"></div>
							<div className="col-md-5"></div>
							<div className="col-md-2">
								<div className="btn-group btn-group-cols">
									<button style={buttonStyle} className="btn btn-primary btn-filled-main filter-btn" onClick={handleDisplayAllClick}>
										Display all
									</button>
									<button className="btn btn-primary btn-outlined-main filter-btn" onClick={handleColumns}>
										<FaFilter />
									</button>
								</div>
								{ShowColumns && (
									<>
										<div className="filter-cols" ref={columnRef}>
											<ul>
												{columnsData?.map((column, index) => (
													<li className="checkbox-row" key={index}>
														<input type="checkbox" id={index} checked={isChecked[index]} onChange={() => handlecolumnhideShow(index)} />
														<label for={index} className="cutomCheckbox"></label>
														<label htmlFor={index} onClick={() => handlecolumnhideShow(index)}>
															{column.name}
														</label>
													</li>
												))}
											</ul>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="d-flex align-items-center justify-content-between">
							<div className="col-md-12">
								<div className="row">
									<div className="col-md-7">
										<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} multi="false" />
									</div>
									<div className="col-md-5 btn-area">
										<button className="btn btn-primary btn-outlined-main white-var btn-date" onClick={handleDateRangeClick}>
											{dateRange[0].startDate.toDateString()} -{' '}
											{dateRange[0].endDate ? dateRange[0].endDate.toDateString() : 'Select an end date'} <BsCalendar2Date />
										</button>

										<button
											className="btn btn-primary btn-outlined-main white-var btn-create-report"
											style={{ minWidth: 'unset' }}
											onClick={() => preview()}
										>
											<IoEyeOutline /> Preview
										</button>
										<Dropdown className="btnDel iconbtn" align={'end'}>
											<Dropdown.Toggle as="span">
												<Link
													to={''}
													className="btn btn-primary btn-outlined-main white-var btn-create-report"
													style={{ minWidth: 'unset' }}
												>
													<FaPlus /> Create Report <LuChevronDown />
												</Link>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() => downloadReport(1)}>Excel</Dropdown.Item>
												<Dropdown.Item onClick={() => downloadReport(0)}>Pdf</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
										{/* <button className="" style={{ minWidth: 'unset' }}  >
								                      		
								                      		</button>
                                          */}
										{showPicker && (
											<div ref={dateRef}>
												<DateRangePicker ranges={dateRange} onChange={handleSelect} className="date-range-picker" />
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="estimation-table-area">
							<div className="table-side">
								<div className="table-responsive-xl estimationList p-2">
									<Scroller>
										<table className="table min-width-unset tbl-space pm-table">
											<thead>
												<tr className="sticky-header">
													{columnsData
														?.filter((column) => column.isVisible)
														.map((visibleColumn, index) => (
															<th key={index}>{visibleColumn?.name}</th>
														))}
												</tr>
											</thead>
											{reportData ? (
												<>
													{reportData?.map((row, rowIndex) => (
														<tr key={rowIndex}>
															{columnsData?.map((column, colIndex) => column.isVisible && <td key={colIndex}>{row[column.name]}</td>)}
														</tr>
													))}
												</>
											) : (
												<>
													<div className="Filter-to-access">
														<HiOutlineDocumentReport className="generate-report-icon" />
														<p className="generate-report-text">Use filters to generate {reportName} report</p>
													</div>
												</>
											)}
										</table>
									</Scroller>
								</div>
								<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsloading(e)} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Report;
