import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './quality-control.scss';
import Scroller from 'ui-component/Scroller';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiPackageBold, PiTestTubeFill, PiWarningFill } from 'react-icons/pi';
import { FaCheckCircle, FaPen, FaPlus, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { ImSpinner2 } from 'react-icons/im';
import ProcessIndicator from '../ProcessIndicator';
import { BsClipboardCheck } from 'react-icons/bs';
import { GiAutoRepair, GiHandTruck, GiMagnifyingGlass } from 'react-icons/gi';
import { BiCategoryAlt } from 'react-icons/bi';
import { IoMdBarcode } from 'react-icons/io';
import { getStatusColor } from '../TaskGrid/Task';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import AddRepairsModal from 'ui-component/confirmation-dialogs/addRepairsModal/AddRepairsModal';
import toast from 'react-hot-toast';
import index from 'views/estimation';
import { AiFillDropboxCircle } from 'react-icons/ai';
import { FaCircleStop } from 'react-icons/fa6';
import { taskboardProgresses } from 'assets/helperdata/taskboard-progrss';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

function QualityControlDetails() {
	const { id } = useParams();
	const history = useNavigate();
	const topSection = useRef(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedQualityControl, setSelectedQualityControl] = useState([]);
	const [collectionModals, setCollectionModals] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState();
	const [selectedColor, setSelectedColor] = useState();
	const [imeiInputValue, setImeiInputValue] = useState('');
	const imeiInputRef = useRef(null);
	const [modelImeis, setModelImeis] = useState([]);
	const [checkModelImeis, setCheckModelImeis] = useState(true);
	const [isInProgress, setIsInProgress] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [colors, setColors] = useState([]);
	const [modelRepairOptions, setModelRepairOptions] = useState([]);

	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const [addRepairDialog, setAddRepairDialog] = useState(null);

	const handleRepairDialogClose = () => {
		setAddRepairDialog(null);
	};

	const onAddRepairSubmit = (data) => {
		const oldModelImed = [...modelImeis];

		setModelImeis(
			modelImeis.map((item) => {
				if (item?.imei == data.imei) {
					return data;
				}
				return item;
			})
		);

		if (modelImeis.find((item) => item?.imei == data.imei)?.existing) {
			setIsDisable(true);
			toast.promise(
				api
					.updateStockRepair({
						stock_id: modelImeis.find((item) => item?.imei == data.imei)?.stock_id,
						repair_cost_ids: data?.repairs.map((r) => ({ id: r.id }))
					})
					.then((res) => {
						if (res.status) {
							setIsDisable(false);
						}
						return res?.status ? res : null;
					}),
				{
					loading: 'Updating Repairs...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update repair</b>
				}
			);
		}

		handleRepairDialogClose();
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const removeImeiFromList = (imei) => {
		setModelImeis([...modelImeis].filter((f) => f.imei != imei));
	};

	const getCollectionData = (callback = () => {}) => {
		setLoading(true);
		setIsDisable(true);
		api.getqualitycontroldetailsbyid(id).then((res) => {
			setSelectedQualityControl(res?.data);
			setIsDisable(false);
			setLoading(false);
			if (res?.data?.stocks?.length > 0) {
				setModelImeis(
					res?.data?.stocks?.map((s) => ({
						imei: s?.imei,
						repairs: s?.repairs?.map((sr) => ({
							...sr?.repair_cost,
							value: sr?.repair_cost?.repair_type_id,
							label: sr?.repair_cost?.repair_type?.name
						})),
						valid: true,
						invalid_message: 'IMEI Exists in stock',
						product: s?.product,
						color: s?.color?.name?.[0].toUpperCase() + s?.color?.name?.substring(1),
						item_id: s?.id,
						stock_id: s?.id,
						existing: true
					}))
				);
			}
			callback(res?.data);
		});
	};

	const loadColorData = () => {
		api.getAllColors().then((res) => {
			if (res.status) {
				let typeTemp = res.data.map((item) => ({
					value: item.name?.[0].toUpperCase() + item.name?.substring(1),
					label: item.name?.[0].toUpperCase() + item.name?.substring(1)
				}));
				setColors(typeTemp);
			}
		});
	};

	const handleIMEIInputKeydown = (event) => {
		if (event.key === 'Enter') {
			searchModalByImei(imeiInputValue);
		}
	};

	const [searchImei, setSearchImei] = useState(null);

	const searchModalByImei = (imei) => {
		const stockItem = modelImeis.find((m) => m?.imei == imei);
		if (stockItem) {
			const row = document.getElementById(imei);
			row.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			setSearchImei(imei);
			setTimeout(() => {
				setSearchImei(null);
			}, 2000);
		} else {
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			showErrorDialog('Invalid IMEI', `The IMEI you entered does not match with any items in this list`);
		}
	};

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const showConfirmationDialog = (title, message = '', onConfirm = () => {}, onClose = () => {}) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	// useEffect(() => {
	// 	if (id) {
	// 		getCollectionData();
	// 	}
	// }, []);

	useEffect(()=> {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, [])
	
	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	}

	const onRepairClick = (item) => {
		// if (!modelRepairOptions.find((m) => m?.model_id == item?.product?.model?.id)) {
			api.getModelRepairOptions(item?.product?.model?.id).then((res) => {
				if (res?.status) {
					setModelRepairOptions([
						...modelRepairOptions,
						{
							model_id: item?.product?.model?.id,
							options: res?.data
						}
					]);
				}
			});
		// }
		setAddRepairDialog(item);
	};

	useEffect(() => {
		if (id) {
			getCollectionData((data) => {
				setIsInProgress(!!data?.start_at);
				if (!data?.start_at) {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Start QualityControling?',
							'Are you sure you want to start inbounding?',
							() => {
								handleStart();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				} else {
					if (searchParams.get('start')) {
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				}
			});
		}
		loadColorData();
	}, []);

	const onStartClick = () => {
		showConfirmationDialog(
			'Start QualityControling?',
			'Are you sure you want to start inbounding?',
			() => {
				handleStart();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleStart = () => {
		setIsDisable(true);
		toast.promise(
			api.startQualityControl(id).then(
				(res) => {
					if (res.status) {
						setIsInProgress(true);
					}

					setIsDisable(false);
					return res?.status ? res : null;
				},
				() => {
					setIsDisable(false);
				}
			),
			{
				loading: 'Starting QualityControling...',
				success: (data) => `${data.message}`,
				error: <b>Failed to start QualityControling</b>
			}
		);
	};

	const onDoneClicked = () => {
		showConfirmationDialog(
			'Complete Quality Control?',
			'Are you sure you want to complete this quality control?',
			() => {
				handleSubmit();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleSubmit = () => {
		setIsDisable(true);
		const data = {
			task_inbounding_id: id
		};

		toast.promise(
			api.saveQualityControlData(data).then((res) => {
				if (res.status) {
					setIsDisable(false);
					history('/operations/quality-control');
				}
				return res?.status ? res : null;
			}),
			{
				loading: 'Adding to stock...',
				success: (data) => `${data.message}`,
				error: (data) => {
					setIsDisable(false);
					return <b>Failed to add to stock</b>;
				}
			}
		);
	};
	const goback = () => {
		history('/operations/quality-control');
	};

	return (
		<>
			<ProcessIndicator isInProgress={isInProgress} processes={taskboardProgresses} currentIndex={5} />
		{console.log(taskboardProgresses)}
			<div className={`TaskDetails`}>
				<div className="d-flex justify-content-between mb-0">
					<div
						className="topBtnSign"
						style={{ '--statusColor': getStatusColor(!selectedQualityControl?.end_at ? 'blue' : 'green') }}
					>
						<span>
							{selectedQualityControl?.start_at && selectedQualityControl?.end_at
								? 'Completed'
								: isInProgress
								? 'Inprogress'
								: 'Upcoming'}
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card p-4">
							<Scroller>
								<div className="row pb-4" ref={topSection}>
									<div className="col-12 d-flex justify-content-between mb-4">
										<h3 className="font-weight-bold m-0">{selectedQualityControl?.invoice_number}</h3>
										{/* <div
											className={`d-flex align-items-center justify-content-end datediv mb-4`}
											style={{ '--statusColor': getStatusColor(collection?.priority_status?.color) }}
										>
											<div className="boxDate">{moment(collection?.due_date).format('DD MMM HH:mm')}</div>
										</div> */}
									</div>
									<div className="col-12">
										<div className="row">
											<div className="col-md-20 mb-2 mb-md-0 detail-item">
												<p className="detail-label">Reference.</p>
												<div className="detail-value">
													{' '}
													{selectedQualityControl?.segregation_detail_reference_no}{' '}
												</div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Collected On</p>
												<div className="detail-value">
													{selectedQualityControl?.collected_on
														? moment(selectedQualityControl?.collected_on).format('DD MMM YYYY hh:mm A')
														: 'N/A'}
												</div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Customer</p>
												<div className="detail-value">
													<PrivacyModeContent>{selectedQualityControl?.customer_name}</PrivacyModeContent>
												</div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Supplier</p>
												<div className="detail-value">
													<PrivacyModeContent>{selectedQualityControl?.supplier_name}</PrivacyModeContent>
												</div>
											</div>

											<div className="col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label" style={{ fontSize: '11px' }}>
													Quantity
												</p>
												<div className="detail-value">{selectedQualityControl?.total_qty} Pcs</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row inbounding-imeis">
									<div className="col-xl-12 col-lg-12">
										<div className="row">
											<div className="col-4">
												<div className="card shadow-sm bg-white mb-4">
													<div className="input-form p-0">
														<div className="form-row col-md-12 m-0 p-0">
															<div className="form-group">
																<label htmlFor="">IMEI</label>
																<input
																	ref={imeiInputRef}
																	type="text"
																	id="input-example"
																	name="input-name"
																	className="form-control form-control-sm"
																	placeholder="IMEI"
																	onChange={(e) => {
																		setImeiInputValue(event.target.value);
																	}}
																	onKeyDown={(e) => {
																		handleIMEIInputKeydown(e);
																	}}
																	disabled={!isInProgress || isDisable}
																	autoComplete={'off'}
																	autoFocus
																/>
															</div>
															<label htmlFor="">Product List</label>
															<div className="product-list">
																<table>
																	<thead>
																		<tr>
																			<th>Product</th>
																			<th>Total</th>
																		</tr>
																	</thead>
																	<tbody>
																		{selectedQualityControl?.items?.map((item) => (
																			<tr>
																				<td>{item?.product?.product_name}</td>
																				{/* <td>
																					{Number(item?.qty) -
																						(Number(
																							modelImeis?.filter(
																								(model) =>
																									model?.product?.id == item?.product?.id
																							)?.length
																						) || 0)}{' '}
																					Pcs
																				</td> */}
																				<td>{item?.qty} Pcs</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-8">
												<div className="card shadow-sm bg-white mb-4">
													{/* <div className="table-responsive-xl tableData customersList"> */}
													<Scroller extraHeight={topSection?.current?.clientHeight || 0}>
														<table className="table ">
															<thead>
																<tr>
																	<th scope="col" width="20%">
																		IMEI
																	</th>
																	<th scope="col" width="20%">
																		Model
																	</th>
																	<th scope="col">Storage</th>
																	<th scope="col">Colour</th>
																	<th scope="col">Grade</th>
																	<th scope="col">Needs Repair</th>
																	{/* <th scope="col"></th> */}
																</tr>
															</thead>
															<tbody>
																{modelImeis.map((item, index) => (
																	<tr
																		key={index}
																		id={item?.imei}
																		className={item?.imei == searchImei ? 'found-row' : ''}
																	>
																		<td className="align-middle">
																			<span
																				className="d-flex align-items-center"
																				style={{ gap: '2px' }}
																			>
																				{item?.existing ? (
																					<Tooltip title="QualityControled" arrow placement="top">
																						<span className="text-info d-flex">
																							<FaCircleStop />
																						</span>
																					</Tooltip>
																				) : item?.valid == 'loading' ? (
																					<Tooltip title="Checking IMEI" arrow placement="top">
																						<span className="d-flex">
																							<ImSpinner2 className="fa-spin" />
																						</span>
																					</Tooltip>
																				) : item?.valid == true ? (
																					<Tooltip title="Valid IMEI" arrow placement="top">
																						<span className="text-success d-flex">
																							<FaCheckCircle />
																						</span>
																					</Tooltip>
																				) : (
																					<Tooltip
																						title={item?.invalid_message || 'Invalid IMEI'}
																						arrow
																						placement="top"
																					>
																						<span className="text-danger d-flex">
																							<FaTimesCircle />
																						</span>
																					</Tooltip>
																				)}{' '}
																				<span>{item?.imei}</span>
																			</span>
																		</td>
																		<td className="align-middle">{item?.product?.model?.name}</td>
																		<td className="align-middle">{item?.product?.storage?.name}</td>
																		<td className="align-middle">{item?.color}</td>
																		<td className="align-middle">{item?.product?.grade?.name}</td>
																		<td
																			className="align-middle text-left action-col"
																			style={{
																				justifyContent: 'flex-start',
																				alignItems: 'center',
																				gap: '3px'
																			}}
																		>
																			{item?.repairs?.length > 0 ? (
																				<>
																					<span>
																						{item?.repairs?.map(
																							(r, i) =>
																								`${r?.repair_type?.code}${
																									i != item?.repairs?.length - 1
																										? ', '
																										: ''
																								}`
																						)}
																					</span>
																					{!!!selectedQualityControl?.end_at && isInProgress && (
																						<PermissionAllowed permissions={['Add Item Repair for Quality Control']}>
																							<span
																								style={{
																									fontSize: '10px',
																									display: 'flex',
																									cursor: 'pointer'
																								}}
																								onClick={() => {
																									onRepairClick(item);
																								}}
																							>
																								<FaPen />
																							</span>
																						</PermissionAllowed>
																					)}
																				</>
																			) : !!selectedQualityControl?.end_at || !isInProgress ? (
																				'-'
																			) : (
																				<PermissionAllowed permissions={['Add Item Repair for Quality Control']}>
																					<span
																						className={`btnEdit plain-textbtn`}
																						style={{
																							height: '20px',
																							minWidth: 'unset',
																							fontSize: '10px',
																							width: 'fit-content'
																						}}
																						onClick={() => {
																							onRepairClick(item);
																						}}
																					>
																						<FaPlus /> Add
																					</span>
																				</PermissionAllowed>
																			)}
																		</td>
																		{/* <td className="align-middle text-center">
																			{!item?.existing ? (
																				<span
																					className="btnEdit plain-iconbtn"
																					style={{
																						height: '20px',
																						width: '20px',
																						fontSize: '10px',
																						display: 'inline-block'
																					}}
																					onClick={() => {
																						removeImeiFromList(item?.imei);
																					}}
																				>
																					<FaTimes />
																				</span>
																			) : (
																				<Tooltip
																					title="Item already QualityControled"
																					arrow
																					placement="top"
																					key={'remove-' + index}
																				>
																					<span
																						className="btnEdit plain-iconbtn disabled"
																						style={{
																							height: '20px',
																							width: '20px',
																							fontSize: '10px',
																							display: 'inline-block'
																						}}
																						disabled
																					>
																						<FaTimes />
																					</span>
																				</Tooltip>
																			)}
																		</td> */}
																	</tr>
																))}
															</tbody>
														</table>
													</Scroller>
													{/* </div> */}
												</div>
											</div>
										</div>
										{/* <div className="card shadow-sm bg-white mb-4">
						<div className="row">
							<div className="col-4"></div>
							<div className="col-8">
								
							</div>
						</div>
					</div> */}
									</div>
								</div>
							</Scroller>
							<div className="btnarea">
								<span
									onClick={() => {
										goback();
									}}
									className={`btn btn-link ${isDisable ? 'disabled' : ''}`}
								>
									Back
								</span>
								<PermissionAllowed permissions={['Start / End Task Quality Control (Action)']}>
									{selectedQualityControl?.status != 'completed' &&
										(isInProgress ? (
											<span
												onClick={() => {
													if (!isDisable) {
														onDoneClicked();
													}
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Done
											</span>
										) : (
											<span
												onClick={() => {
													onStartClick();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Start
											</span>
										))}
								</PermissionAllowed>
							</div>
						</div>
					</div>
				</div>
			</div>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}

			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}

			{addRepairDialog && (
				<AddRepairsModal
					show={addRepairDialog}
					handleClose={handleRepairDialogClose}
					onSubmit={onAddRepairSubmit}
					repairOptions={modelRepairOptions?.find((m) => m?.model_id == addRepairDialog?.product?.model?.id)?.options}
				/>
			)}
		</>
	);
}

export default QualityControlDetails;
