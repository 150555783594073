import React, { useEffect } from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { IconCalendar } from '@tabler/icons';
import { Fade } from 'react-awesome-reveal';

function SalesTargetCard({ targetPermission, targetPeriod, isOpen, setIsOpen, handleSelect, COLORS, sold, targetMonth, salesTargetData }) {
	return (
		targetPermission &&
		targetPermission.is_visible === 1 && (
			<div className="custom-col-lg-3 col-md-4 col-sm-6" style={{ paddingBottom: '20px' }}>
				<Fade>
					<div className="card" style={{ height: '270px' }}>
						<div
							className="card-header"
							style={{
								backgroundColor: 'white',
								paddingBottom: 0,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								position: 'relative'
							}}
						>
							<h5 style={{ fontWeight: '600' }}>Sales Target</h5>
							<div style={{ position: 'relative' }}>
								<a className="target-selector" onClick={() => setIsOpen(!isOpen)} style={{ textDecoration: 'none' }}>
									<IconCalendar size={16} />
									This {targetPeriod}
								</a>
								{isOpen && (
									<div
										className="targetDropdown"
										style={{
											position: 'absolute',
											right: 0,
											backgroundColor: 'white',
											boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
											zIndex: 1
										}}
									>
										<a onClick={() => handleSelect('week')}>Weekly</a>
										<br />
										<a onClick={() => handleSelect('month')}>Monthly</a>
										<br />
										<a onClick={() => handleSelect('year')}>Yearly</a>
									</div>
								)}
							</div>
						</div>
						<div className="pie-chart-custom" style={{ position: 'relative' }}>
							{salesTargetData && salesTargetData.length > 0 ? (
								<ResponsiveContainer width="80%">
									<PieChart>
										<Pie
											data={salesTargetData}
											cx="50%"
											cy="50%"
											startAngle={0} // Swapped startAngle and endAngle
											endAngle={180} // Swapped startAngle and endAngle
											innerRadius="85%"
											outerRadius="100%"
											cornerRadius={10}
											paddingAngle={salesTargetData[0].value == 0 || salesTargetData[1].value <= 10 ? 0 : -10}
											fill="#8884d8"
											dataKey="value"
											animationDuration={1000}
											animationEasing="linear"
										>
											{salesTargetData.map((entry, index) => (
												<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
											))}
										</Pie>
									</PieChart>
								</ResponsiveContainer>
							) : null}
							<div
								className="chart-labels"
								style={{ position: 'absolute', bottom: 10, left: 0, zIndex: 1, width: '100%', height: '100px' }}
							>
								<div className="row" style={{ width: '100%' }}>
									<div className="col-6 target-custom" style={{ textAlign: 'center', paddingRight: '20px' }}>
										<a>Achieved</a>
										<b style={{ whiteSpace: 'nowrap', display: 'block' }}>
											$ {Number(sold).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
										</b>
									</div>
									<div className="col-6 target-custom" style={{ textAlign: 'center', paddingLeft: '20px' }}>
										<a style={{ whiteSpace: 'nowrap', display: 'block' }}>Expect Target</a>
										<b style={{ whiteSpace: 'nowrap', display: 'block' }}>
											$ {Number(targetMonth).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
										</b>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fade>
			</div>
		)
	);
}

export default SalesTargetCard;
