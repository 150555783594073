import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { TrackingPointIcon, FlagIcon, ChecklistIcon, AddCartIcon } from 'ui-component/custom-icons/icons';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import StatusBadge from 'ui-component/StatusBadge';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { ShipmentLabelIcon } from 'ui-component/custom-icons/icons';
import { FaPaperclip, FaPlus, FaSave, FaTimes, FaMinus, FaCheck } from 'react-icons/fa';
import moment from 'moment';


const PicklistShipmentComponent = ({ details, handleCancelShipment, handleDeleteShipment }) => {

    // const history = useNavigate();

    return (
        <>
            
            <div className="productsTable estlist" style={{ padding: '20px 6px' }}>
                <div className="table-responsive-xl tableData">
                    <div>
                        <h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px', fontWeight: '600' }}>
                            Shipments
                        </h2>
                    </div>
                    <div className="row mt10">
                        <div className="col-lg-3 font500">Shipment Provider</div>
                        <div className="col-lg-3 font500">Reference No</div>
                        <div className="col-lg-2 font500">Qty</div>
                        <div className="col-lg-2 font500">Status</div>
                        <div className="col-lg-2 font500">Shipment Date</div>
                    </div>
                    <hr className="mt5" />

                    {
                        details?.map((item, index) => (
                            <>
                                <div className="row hover-row">
                                    <div className="col-lg-3 align-middle mt3">
                                        {item?.shipping_company?.name}
                                    </div>
                                    <div className="col-lg-3 align-middle mt3">
                                        {item?.reference_no}
                                    </div>
                                    <div className="col-lg-2 align-middle mt3">
                                        {item?.total_quantity}
                                    </div>
                                    <div className="col-lg-2 align-middle">
                                        <div style={{ width: '90px' }}>
                                            <StatusBadge itemStatus={item?.status?.name}  />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 align-middle mt3">
                                        {moment(item?.created_at).format(CommonDateOnlyFormat)}
                                    </div>
                                    <div className="hover-button-container-lg" style={{ marginTop: '0px' }}>
                                        <div>
                                            <div className="hover-button">
                                                
                                                {
                                                    item?.tracking_no && (
                                                        <>
                                                            <div
                                                                className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
                                                                style={{ marginLeft: '8px' }}
                                                                onClick={() => {
                                                                    handleScanSerials(item);
                                                                }}
                                                            >
                                                                <div>
                                                                    <TrackingPointIcon width={'19px'} height={'19px'} /> 
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                {
                                                    item?.attachment && (
                                                        <>
                                                            <div
                                                                className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
                                                                style={{ marginLeft: '8px' }}
                                                                onClick={() => {
                                                                    handleScanSerials(item);
                                                                }}
                                                            >
                                                                <div style={{ margin: 'auto 5px auto 3px' }}>
                                                                    <FaPaperclip /> 
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                <PermissionAllowed permissions={['Delete Operations']}>
                                                    <div
                                                        className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
                                                        style={{ marginLeft: '8px' }}
                                                        onClick={() => {
                                                            handleScanSerials(item);
                                                        }}
                                                    >
                                                        <div style={{ margin: 'auto 5px auto 3px' }}>
                                                            <FaTimes style={{ marginTop: '-1px', marginRight: '3px' }} /> 
                                                            Cancel
                                                        </div>
                                                        {/* <CheckCircleIcon height={'20'} width={'20'} /> Complete */}
                                                    </div>
                                                </PermissionAllowed>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </>
                        ))
                    }

                    {/* <table className="table pm-table">
                        <thead>
                            <tr className="sticky-header">
                                <th className="align-middle">Shipment Provider</th>
                                <th className="align-middle">AWB</th>
                                <th className="align-middle">Attachment</th>
                                <th className="align-middle">Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="hover-row" style={{ lineHeight: '30px' }}>
                                <td className="align-middle">DHL</td>
                                <td className="align-middle">12345ewsdfdfs</td>
                                <td className="align-middle">
                                    <ShipmentLabelIcon height="20px" width="20px" />
                                </td>
                                <td>
                                    20 Aug 2024
                                </td>

                                <td className="hover-button-container" >
                                    <div>
                                        <div className="hover-button">
                                            

                                            <PermissionAllowed permissions={['Delete Operations']}>
                                                <div
                                                    className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
                                                    style={{ marginLeft: '8px' }}
                                                    onClick={() => {
                                                        deleteOperation(row);
                                                    }}
                                                >
                                                    <div style={{ margin: 'auto 5px auto 3px' }}>
                                                        <FaTimes style={{ marginTop: '-1px', marginRight: '3px' }} /> 
                                                        Cancel
                                                    </div>
                                                </div>
                                            </PermissionAllowed>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="hover-row" style={{ lineHeight: '30px' }}>
                                <td className="align-middle">DHL</td>
                                <td className="align-middle">12345ewsdfdfs</td>
                                <td className="align-middle">
                                    <ShipmentLabelIcon height="20px" width="20px" />
                                </td>
                                <td>
                                    20 Aug 2024
                                </td>

                                <td className="hover-button-container" >
                                    <div>
                                        <div className="hover-button">
                                            

                                            <PermissionAllowed permissions={['Delete Operations']}>
                                                <div
                                                    className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
                                                    style={{ marginLeft: '8px' }}
                                                    onClick={() => {
                                                        deleteOperation(row);
                                                    }}
                                                >
                                                    <div style={{ margin: 'auto 5px auto 3px' }}>
                                                        <FaTimes style={{ marginTop: '-1px', marginRight: '3px' }} /> 
                                                        Cancel
                                                    </div>
                                                </div>
                                            </PermissionAllowed>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>
            </div>
        </>
    );
};

export default PicklistShipmentComponent;
