import { CommonDateOnlyFormat } from 'helpers/common.helper';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';
import { FaDownload, FaPlus } from 'react-icons/fa6';
import { ImSpinner2 } from 'react-icons/im';
import { IoIosArrowDropdown } from 'react-icons/io';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import PermissionAllowed from './permissions/PermissionAllowed';

const ExportListItemsModal = ({ openpopup, disablepopup, filters, moduleName, onSubmit, historyApi, isLoading, exportHistoryPerms }) => {
	const [disableStatus, setDisableStatus] = useState(false);
	const [selectedValues, setSelectedValues] = useState({});
	const [exportsHistory, setExportsHistory] = useState([]);
	const [showHistory, setShowHistory] = useState(false);

	const handleFilterSelect = (name, value) => {
		if (value?.value == '' || value?.value == null) {
			setSelectedValues((oldVal) => {
				return { ...oldVal, [name]: undefined };
			});
		} else {
			setSelectedValues((oldVal) => ({ ...oldVal, [name]: value }));
		}
	};

	const handleSelectDate = (ranges) => {
		const rangesData = [ranges?.selection];
		setSelectedValues((oldData) => ({
			...oldData,
			...(rangesData?.[0]?.startDate
				? { startDate: { value: moment(rangesData?.[0]?.startDate).startOf('day').format('YYYY-MM-DD HH:mm') } }
				: {}),
			...(rangesData?.[0]?.endDate ? { endDate: { value: moment(rangesData?.[0]?.endDate).endOf('day').format('YYYY-MM-DD HH:mm') } } : {})
		}));
	};

	useEffect(() => {
		loadExportHistory();
	}, []);

	const loadExportHistory = () => {
		historyApi.then((res) => {
			res?.data && setExportsHistory(res?.data);
		});
	};

	// const onSubmit = () => {
	// 	// const downloading = toast.loading('Downloading...', { duration: 1000000 });
	// 	setIsLoading(true);
	// 	downloadApi('', selectedValues, '', selectedCurrency).then((res) => {
	// 		const newFileName = `Orders`;
	// 		if (res != 404) {
	// 			var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	// 			saveAs(newBlob, `${newFileName}.xlsx`);
	// 			// toast.dismiss(downloading);
	// 			setIsLoading(false);
	// 			toast.success('Downloaded Completed!');
	// 		} else {
	// 			// toast.dismiss(downloading);
	// 			setIsLoading(false);
	// 			toast.error(res == 404 ? 'No Records Found!' : 'Download Failed!');
	// 		}
	// 		loadExportHistory();
	// 	});
	// };

	const handleSubmit = () => {
		onSubmit(selectedValues);
	};

	return (
		<>
			<Modal show={openpopup} onHide={disablepopup} centered size="lg" dialogClassName="modal790">
				<Modal.Body>
					<div className="p-2">
						<div className="foundListPopup">
							<div className="d-flex TableSerachBar align-items-center justify-content-between mb-4 ml-0 pl-0 p-0">
								<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">Export {moduleName ? `- ${moduleName}` : ''}</h5>
								<button className="btn modal-close-icon" onClick={disablepopup}>
									{/* <FaTimes /> */}
									<svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
										<path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
									</svg>

								</button>
							</div>
							<div className="row align-items-top" style={{ display: 'flex' }}>
								<div className="" style={{ flex: 'auto' }}>
									{filters?.map((filter) => {
										return (
											filter?.name != 'date_range' && (
												<div className="form-group col-12" key={filter.name}>
													<label htmlFor="">{filter.label}</label>
													<ReactSelect
														// isMulti={true}
														className="rs-filter-container"
														classNamePrefix="rs-filter"
														options={[{ label: 'All', value: '' }, ...filter.options]}
														defaultValue={{ label: 'All', value: '' }}
														// isClearable={true}
														onChange={(e) => {
															handleFilterSelect(filter.name, e);
														}}
													/>
												</div>
											)
										);
									})}
								</div>
								<div className="d-flex justify-content-center w-100">
									{filters?.find((filter) => filter?.name == 'date_range') && (
										<>
											<DateRangePicker
												rangeColors={['#0066FF']}
												ranges={[
													{
														startDate: selectedValues?.startDate?.value ? moment(selectedValues?.startDate?.value).toDate() : null,
														endDate: selectedValues?.endDate?.value ? moment(selectedValues?.endDate?.value).toDate() : null,
														key: 'selection'
													}
												]}
												onChange={handleSelectDate}
												staticRanges={[
													{
														label: 'All',
														range: () => ({
															startDate: null,
															endDate: null
														}),
														isSelected: (e) => {
															return !selectedValues?.startDate?.value && !selectedValues?.endDate?.value;
														}
													},
													{
														label: 'Today',
														range: () => ({
															startDate: new Date(),
															endDate: new Date()
														}),
														isSelected: (e) => {
															return moment(e.startDate).isSame(moment(), 'day') && moment(e.endDate).isSame(moment(), 'day');
														}
													},
													{
														label: 'This Week',
														range: () => ({
															startDate: moment().startOf('week').toDate(),
															endDate: moment().endOf('week').toDate()
														}),
														isSelected: (e) => {
															return (
																moment(e.startDate).isSame(moment().startOf('week').toDate(), 'day') &&
																moment(e.endDate).isSame(moment().endOf('week').toDate(), 'day')
															);
														}
													},
													{
														label: 'This Month',
														range: () => ({
															startDate: moment().startOf('month').toDate(),
															endDate: moment().toDate()
														}),
														isSelected: (e) => {
															return (
																moment(e.startDate).isSame(moment().startOf('month').toDate(), 'day') &&
																moment(e.endDate).isSame(moment().toDate(), 'day')
															);
														}
													},
													{
														label: 'Last Month',
														range: () => ({
															startDate: moment().subtract(1, 'month').startOf('month').toDate(),
															endDate: moment().subtract(1, 'month').endOf('month').toDate()
														}),
														isSelected: (e) => {
															return (
																moment(e.startDate).isSame(moment().subtract(1, 'month').startOf('month').toDate(), 'day') &&
																moment(e.endDate).isSame(moment().subtract(1, 'month').endOf('month').toDate(), 'day')
															);
														}
													},
													{
														label: 'This Year',
														range: () => ({
															startDate: moment().startOf('year').toDate(),
															endDate: moment().toDate()
														}),
														isSelected: (e) => {
															return (
																moment(e.startDate).isSame(moment().startOf('year').toDate(), 'day') &&
																moment(e.endDate).isSame(moment().toDate(), 'day')
															);
														}
													},
													{
														label: 'Last Year',
														range: () => ({
															startDate: moment().subtract(1, 'year').startOf('year').toDate(),
															endDate: moment().subtract(1, 'year').endOf('year').toDate()
														}),
														isSelected: (e) => {
															return (
																moment(e.startDate).isSame(moment().subtract(1, 'year').startOf('year').toDate(), 'day') &&
																moment(e.endDate).isSame(moment().subtract(1, 'year').endOf('year').toDate(), 'day')
															);
														}
													}
												]}
											/>
										</>
									)}
								</div>
							</div>
						</div>
						<div className="btnarea mt-3">
							<button
								disabled={disableStatus}
								onClick={() => {
									!isLoading && handleSubmit();
								}}
								className="btn btn-main"
							>
								{isLoading ? (
									<>
										<ImSpinner2 className="fa-spin" /> Downloading
									</>
								) : (
									<>
										<FaDownload /> Download
									</>
								)}
							</button>
						</div>

						{/* <hr /> */}

						<PermissionAllowed permissions={exportHistoryPerms}>
							<div className={`export-history-box ${showHistory ? 'expanded' : ''}`}>
								<div className="d-flex justify-content-between align-items-center" style={{ lineHeight: 1 }}>
									<p style={{ fontWeight: '500', fontSize: '13px', margin: 0 }}>History</p>
									<div style={{ fontSize: '18px' }}>
										<span
											className="export-expand-icon"
											onClick={() => {
												setShowHistory(!showHistory);
											}}
										>
											<IoIosArrowDropdown />
										</span>
									</div>
								</div>
								<table className="table pm-table" style={{ fontSize: '12px' }}>
									<tr>
										<th>Exported on</th>
										<th>Exported by</th>
									</tr>

									{exportsHistory?.map((item, index) => (
										<tr key={index}>
											<td>{moment(item?.created_at).format(CommonDateOnlyFormat + ' hh:mm A')}</td>
											<td>
												{item?.user?.first_name} {item?.user?.last_name}
											</td>
										</tr>
									))}
								</table>
								{/* <div className="exportHistory">
									<div className="d-flex justify-content-between">
										<p>Exported on 2021-03-18</p>
										<p>Exported by: Admin</p>
									</div>
									<div className="d-flex justify-content-between">
										<p>Exported on 2021-03-18</p>
										<p>Exported by: Admin</p>
									</div>
								</div> */}
							</div>
						</PermissionAllowed>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ExportListItemsModal;
