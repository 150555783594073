import React from 'react';

const CompanyPreferenceSettings = () => {
	return (
		<>
			<div className="main-page-client" style={{ width: '80%', margin: '0 auto' }}>
				<div className="order-client-content">
					<div className="heading-order-client-tbl">
						<h3>Bank Details</h3>
					</div>
					<div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
						<div className="col-lg-4">
							<input type="text" className="form-control" />
							<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Bank Name</label>
						</div>
						<div className="col-lg-4">
							<input type="text" className="form-control" />
							<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Bank Beneficiary</label>
						</div>
						<div className="col-lg-4">
							<input type="text" className="form-control" />
							<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Bank IBAN</label>
						</div>
						<div className="col-lg-4">
							<input type="text" className="form-control" />
							<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Bank SWIFT</label>
						</div>
						<div className="col-lg-4">
							<input type="text" className="form-control" />
							<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Address</label>
						</div>
					</div>
					<div className="row">
						<div className="col-12 d-flex justify-content-end">
							<button className="btn btn-main-primary">Update</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CompanyPreferenceSettings;
