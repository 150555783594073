import React, { useEffect, useState } from 'react';

import ProcessIndicator from '../company-details/ProcessIndicator';
import { CompanyDetailsProgresses } from 'assets/helperdata/client/company-details-progress';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import Select from 'react-select';

const Settings = () => {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const currentYear = new Date().getFullYear();
	const establishmentYearOptions = [];

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	for (let year = currentYear; year >= currentYear - 100; year--) {
		establishmentYearOptions.push({
			value: year.toString(),
			label: year.toString()
		});
	}

	return (
		<>
			{isMobile && (
				<div className="approved-mobile">
					<div className="verified-mobile">
						<span className="menuIcon">
							<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
								<defs>
									<clipPath id="clip-path">
										<rect
											id="Rectangle_138"
											data-name="Rectangle 138"
											width="22"
											height="22"
											transform="translate(22 557)"
											stroke="#707070"
											stroke-width="1"
										/>
									</clipPath>
								</defs>
								<g id="Mask_Group_5" data-name="Mask Group 5" transform="translate(-22 -557)" clip-path="url(#clip-path)">
									<g id="settings" transform="translate(23.102 557)">
										<path
											id="Path_202"
											data-name="Path 202"
											d="M10.666,7a3.666,3.666,0,1,0,3.666,3.666A3.666,3.666,0,0,0,10.666,7Zm0,5.5A1.833,1.833,0,1,1,12.5,10.666,1.833,1.833,0,0,1,10.666,12.5Z"
											transform="translate(-0.771 0.332)"
											fill="currentColor"
										/>
										<path
											id="Path_203"
											data-name="Path 203"
											d="M19.465,12.739l-.407-.235a8.34,8.34,0,0,0,0-3.015l.407-.235a2.751,2.751,0,1,0-2.749-4.766l-.408.236A8.227,8.227,0,0,0,13.7,3.22v-.47a2.749,2.749,0,1,0-5.5,0v.47a8.227,8.227,0,0,0-2.61,1.508l-.41-.237A2.751,2.751,0,1,0,2.429,9.256l.407.235a8.34,8.34,0,0,0,0,3.015l-.407.235a2.751,2.751,0,0,0,2.749,4.766l.408-.236a8.227,8.227,0,0,0,2.612,1.5v.47a2.749,2.749,0,1,0,5.5,0v-.47a8.227,8.227,0,0,0,2.61-1.509l.41.236a2.751,2.751,0,0,0,2.749-4.766ZM17.13,9.278a6.459,6.459,0,0,1,0,3.437.916.916,0,0,0,.425,1.038l.993.574a.917.917,0,1,1-.916,1.588l-1-.576a.916.916,0,0,0-1.114.151,6.4,6.4,0,0,1-2.972,1.718.916.916,0,0,0-.688.888v1.148a.916.916,0,1,1-1.833,0V18.1a.916.916,0,0,0-.688-.888,6.4,6.4,0,0,1-2.971-1.722.916.916,0,0,0-1.114-.151l-.993.575a.916.916,0,1,1-.916-1.587l.993-.574a.916.916,0,0,0,.425-1.038,6.459,6.459,0,0,1,0-3.437A.916.916,0,0,0,4.34,8.241l-.993-.574a.917.917,0,1,1,.916-1.588l1,.576a.916.916,0,0,0,1.113-.148A6.4,6.4,0,0,1,9.343,4.789a.916.916,0,0,0,.688-.892V2.749a.916.916,0,1,1,1.833,0V3.9a.916.916,0,0,0,.688.888,6.4,6.4,0,0,1,2.972,1.722.916.916,0,0,0,1.114.151l.993-.575a.916.916,0,1,1,.916,1.587l-.993.574a.916.916,0,0,0-.424,1.035Z"
											transform="translate(-1.052 0)"
											fill="currentColor"
										/>
									</g>
								</g>
							</svg>
						</span>
						<span style={{ marginLeft: 5, fontSize: 16 }}>Settings</span>
					</div>
				</div>
			)}
			<div className={`main-page-client`} style={{ width: '100%', padding: 20 }}>
				<div className="heading-inner-settig">
					<span>General settings</span>
					<hr />
				</div>
				<div className="row g-3 d-flex align-items-stretch" style={{ marginTop: '-15px' }}>
					<div className="col-xl-4 col-lg-6 col-md-6" style={{ marginBottom: 20 }}>
						<Link to="./company-info" className="no-underline-link" style={{ color: 'black' }}>
							<div className="card flex-fill d-flex hover-card" style={{ minHeight: 100, cursor: 'pointer' }}>
								<div className="card-body" style={{ display: 'flex', alignItems: 'center' }}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="38"
										height="38"
										viewBox="0 0 45.609 45.609"
										style={{ marginRight: 20, marginLeft: 5, minWidth: 45 }}
									>
										<path
											id="file-circle-info"
											d="M32.306,19a13.3,13.3,0,1,0,13.3,13.3A13.317,13.317,0,0,0,32.306,19Zm0,22.8a9.5,9.5,0,1,1,9.5-9.5A9.505,9.505,0,0,1,32.306,41.809Zm2.851-14.253a2.851,2.851,0,1,1-2.851-2.851A2.847,2.847,0,0,1,35.157,27.556Zm-.95,6.651v3.8a1.9,1.9,0,1,1-3.8,0v-3.8a1.9,1.9,0,1,1,3.8,0Zm-17.1,5.7a1.906,1.906,0,0,1-1.9,1.9H9.5a9.505,9.505,0,0,1-9.5-9.5V9.5A9.505,9.505,0,0,1,9.5,0H20.448a7.724,7.724,0,0,1,5.378,2.223l6.157,6.157a7.647,7.647,0,0,1,2.2,4.751,1.924,1.924,0,0,1-1.748,2.052h-.152a1.918,1.918,0,0,1-1.9-1.748v-.152H24.724a3.812,3.812,0,0,1-3.8-3.8V3.839c-.152,0-.3-.038-.456-.038H9.5A5.718,5.718,0,0,0,3.8,9.5v22.8a5.718,5.718,0,0,0,5.7,5.7h5.7A1.906,1.906,0,0,1,17.1,39.908Z"
										/>
									</svg>
									<div>
										<a style={{ fontWeight: 600, fontSize: 16 }}>Company Info</a>
										<br />
										<a style={{ lineHeight: '1.3', marginTop: 5, color: '#888888' }}>General information about your company</a>
									</div>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-xl-4 col-lg-6 col-md-6" style={{ marginBottom: 20 }}>
						<Link to="./company-address" className="no-underline-link" style={{ color: 'black' }}>
							<div className="card flex-fill d-flex hover-card" style={{ minHeight: 100, cursor: 'pointer' }}>
								<div className="card-body" style={{ display: 'flex', alignItems: 'center' }}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="38"
										height="38"
										viewBox="0 0 45 44.998"
										style={{ marginRight: 20, marginLeft: 5, minWidth: 45 }}
									>
										<path
											id="house-building"
											d="M35.625,28.124H33.75a1.875,1.875,0,1,1,0-3.75h1.875a1.875,1.875,0,1,1,0,3.75ZM37.5,33.749a1.873,1.873,0,0,0-1.875-1.875H33.75a1.875,1.875,0,1,0,0,3.75h1.875A1.873,1.873,0,0,0,37.5,33.749ZM30,11.25a1.873,1.873,0,0,0-1.875-1.875H26.25a1.875,1.875,0,1,0,0,3.75h1.875A1.873,1.873,0,0,0,30,11.25Zm7.5,0a1.873,1.873,0,0,0-1.875-1.875H33.75a1.875,1.875,0,1,0,0,3.75h1.875A1.873,1.873,0,0,0,37.5,11.25Zm0,7.5a1.873,1.873,0,0,0-1.875-1.875H33.75a1.875,1.875,0,1,0,0,3.75h1.875A1.873,1.873,0,0,0,37.5,18.749ZM45,35.624V9.375A9.385,9.385,0,0,0,35.625,0H26.25a9.385,9.385,0,0,0-9.375,9.375,1.875,1.875,0,0,0,3.75,0A5.632,5.632,0,0,1,26.25,3.75h9.375A5.632,5.632,0,0,1,41.25,9.375V35.624a5.632,5.632,0,0,1-5.625,5.625H33.75a1.875,1.875,0,1,0,0,3.75h1.875A9.385,9.385,0,0,0,45,35.624Zm-15,.937V28.777a9.321,9.321,0,0,0-3.6-7.384l-5.625-4.4a9.363,9.363,0,0,0-11.554,0L3.6,21.391A9.318,9.318,0,0,0,0,28.775v7.785A8.448,8.448,0,0,0,8.438,45H21.563A8.448,8.448,0,0,0,30,36.559ZM18.467,19.944l5.625,4.4a5.6,5.6,0,0,1,2.158,4.43v7.785a4.692,4.692,0,0,1-4.688,4.687H8.438A4.692,4.692,0,0,1,3.75,36.561V28.777a5.6,5.6,0,0,1,2.158-4.43l5.625-4.4a5.619,5.619,0,0,1,6.934,0Zm.283,13.805V30a1.876,1.876,0,0,0-1.875-1.875h-3.75A1.876,1.876,0,0,0,11.25,30v3.75a1.876,1.876,0,0,0,1.875,1.875h3.75A1.876,1.876,0,0,0,18.75,33.749Z"
										/>
									</svg>

									<div>
										<a style={{ fontWeight: 600, fontSize: 16 }}>Company Address</a>
										<br />
										<a style={{ lineHeight: '1.3', marginTop: 5, color: '#888888' }}>View address</a>
									</div>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-xl-4 col-lg-6 col-md-6" style={{ marginBottom: 20 }}>
						<Link to="./company-bank" className="no-underline-link" style={{ color: 'black' }}>
							<div className="card flex-fill d-flex hover-card" style={{ minHeight: 100, cursor: 'pointer' }}>
								<div className="card-body" style={{ display: 'flex', alignItems: 'center' }}>
									<svg
										id="credit-card"
										xmlns="http://www.w3.org/2000/svg"
										width="30"
										height="30"
										viewBox="0 0 45.609 34.207"
										style={{ marginRight: 20, marginLeft: 5, minWidth: 45 }}
									>
										<ellipse
											id="Ellipse_32"
											data-name="Ellipse 32"
											cx="2.827"
											cy="2.827"
											rx="2.827"
											ry="2.827"
											transform="translate(7.539 20.688)"
										/>
										<path
											id="Path_823"
											data-name="Path 823"
											d="M36.107,3H9.5A9.513,9.513,0,0,0,0,12.5v15.2a9.513,9.513,0,0,0,9.5,9.5h26.6a9.513,9.513,0,0,0,9.5-9.5V12.5a9.513,9.513,0,0,0-9.5-9.5ZM9.5,6.8h26.6a5.7,5.7,0,0,1,5.7,5.7H3.8A5.7,5.7,0,0,1,9.5,6.8Zm26.6,26.605H9.5a5.7,5.7,0,0,1-5.7-5.7V16.3H41.808v11.4A5.7,5.7,0,0,1,36.107,33.406Z"
											transform="translate(0 -2.999)"
										/>
									</svg>
									<div>
										<a style={{ fontWeight: 600, fontSize: 16 }}>Company Bank</a>
										<br />
										<a style={{ lineHeight: '1.3', marginTop: 5, color: '#888888' }}>View bank details</a>
									</div>
								</div>
							</div>
						</Link>
					</div>
					{/* <div className="col-lg-4 col-md-6" style={{ marginBottom: 20 }}>
						<Link to="./company-preference" className="no-underline-link" style={{ color: 'black' }}>
							<div className="card flex-fill d-flex hover-card" style={{ minHeight: 100, cursor: 'pointer' }}>
								<div className="card-body" style={{ display: 'flex', alignItems: 'center' }}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="38"
										height="38"
										viewBox="0 0 37.5 44.998"
										style={{ marginRight: 20, marginLeft: 5, minWidth: 45 }}
									>
										<path
											id="ballot-check"
											d="M20.18,21.971l-3.491,3.384a3.075,3.075,0,0,1-2.181.9,3.135,3.135,0,0,1-2.218-.917l-1.277-1.226a1.875,1.875,0,1,1,2.6-2.7l.887.853L17.57,19.28a1.875,1.875,0,1,1,2.61,2.692Zm8.07,9.9H22.625a1.875,1.875,0,1,0,0,3.75H28.25a1.875,1.875,0,1,0,0-3.75Zm0-22.5H22.625a1.875,1.875,0,1,0,0,3.75H28.25a1.875,1.875,0,1,0,0-3.75Zm0,11.25H24.5a1.875,1.875,0,1,0,0,3.75h3.75a1.875,1.875,0,1,0,0-3.75ZM14.188,30.937A2.812,2.812,0,1,0,17,33.749,2.813,2.813,0,0,0,14.188,30.937Zm0-16.874a2.812,2.812,0,1,0-2.813-2.812A2.813,2.813,0,0,0,14.188,14.062ZM39.5,9.375V35.624A9.385,9.385,0,0,1,30.125,45H11.375A9.385,9.385,0,0,1,2,35.624V9.375A9.385,9.385,0,0,1,11.375,0h18.75A9.385,9.385,0,0,1,39.5,9.375Zm-3.75,0A5.632,5.632,0,0,0,30.125,3.75H11.375A5.632,5.632,0,0,0,5.75,9.375V35.624a5.632,5.632,0,0,0,5.625,5.625h18.75a5.632,5.632,0,0,0,5.625-5.625Z"
											transform="translate(-2)"
										/>
									</svg>

									<div>
										<a style={{ fontWeight: 600, fontSize: 16 }}>Company Preference</a>
										<br />
										<a style={{ lineHeight: '1.3', marginTop: 5, color: '#888888' }}>Modify your company preference</a>
									</div>
								</div>
							</div>
						</Link>
					</div> */}
				</div>
			</div>
		</>
	);
};

export default Settings;
