import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {


	getSalesOrderTest(filters, search = null, sortByFilter = null) {
		let url = `${base}/api/testing?`;

		if (search) {
			url += `search=${search}`;
		}

		if (sortByFilter) {
			url += `&sortBy=${sortByFilter?.field}&sortOrder=${sortByFilter?.direction}`;
		}

		const result = axios.get(url, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
					}`
			}
		});

		return result;
		// }, false);
    },
};
