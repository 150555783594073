import React, { useState, useEffect } from 'react';
import './reports.scss';
import { useNavigate } from 'react-router-dom';
import usePageTitle from 'hooks/usePageTitle';

const Reports = () => {
	const [setPageTitle] = usePageTitle('Reports');
    const [alignment, setAlignment] = useState('v1');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const history = useNavigate();
	const [selectedData, setSelectedData] = useState(null);

	const getReportsList = () => {
		api.getAllReports().then((res) => {
			setSelectedData(res.data);
		});
	};

    const getReport = (item) =>{
            history(`/report/${item?.id}`);
    }
     const handleChange = () => {
        setAlignment(alignment == 'v1' ? 'v2' : 'v1');
    }



	useEffect(() => {
		getReportsList();
	}, []);

	return (
			<div className="row " style={{ height: '100%' }}>
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<h2>Reports</h2>
					</div>
					<div className="card shadow-sm bg-white mb-4">
                        {/* <div className="toggles">
                            <ToggleButtonGroup
                              color="primary"
                              value={alignment}
                              exclusive
                              onChange={handleChange}
                              aria-label="Platform"
                            >
                              <ToggleButton value="v1" className='toggle-value'>v1.0</ToggleButton>
                              <ToggleButton value="v2" className='toggle-value'>v2.0</ToggleButton>
                            </ToggleButtonGroup>
                        </div> */}
                        <div className="container-fluid">
                            {alignment == 'v1' ? (<>
                                <div className="row">
			                    {  selectedData && selectedData.length > 0 && selectedData.map((item,index) => (	
               
                                    <div className="col-md-4"  key={index} onClick={() => getReport(item)}>
                                        <div className="card">
                                           <p className='heading'>{item?.name}</p> 
                                           <p className='para'>{item?.txt_inline}</p> 
                                           <p className='neon'>{item?.btn_txt}</p>
                                        </div>
                                    </div>
                
			                    ))}
                            </div>
                            </>) : 
                            (
                                <>
                                    <div className="container body-v2">
                                    <div className="page-content">
			                            {/* {  selectedData && selectedData.length > 0 && selectedData.map((item,index) => (	 */}
                                            
                                            
                                                <div className="card-v2">
                                                    <div className="card-section">
                                                        <div className="reports-index">
                                                            <div className="report-card">
                                                                    <h3 className='heading-v2'>
                                                                    <Link to="/report/today"> <CgToday className='icon'/> Today</Link> </h3> 
                                                                    <p className='para-v2'>How is your team performing today ?</p> 
                                                               
                                                                
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href=""> <MdOutlineShoppingCart className='icon'/>
                                                                            Best selling Products
                                                                    </a> 
                                                                </h3> 
                                                                <p className='para-v2'>All sold products in a period, sorted by amount sold.</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">

                                                                     <MdProductionQuantityLimits className='icon'/>
                                                                    Most returned products</a> 
                                                                </h3> 
                                                                <p className='para-v2'>Aggregates of the most returned products within a specific period.</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">
                                                                    <AiOutlineStock className='icon'/>
                                                                        Old stock</a> 
                                                                </h3> 
                                                                <p className='para-v2'>Orders revenue and costs</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">Orders revenue and costs</a> 
                                                                </h3> 
                                                                <p className='para-v2'>How is your team performing today ?</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">Picklists processed
</a> 
                                                                </h3>  
                                                                <p className='para-v2'>How is your team performing today ?</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">Product stock counts</a> 
                                                                </h3>  
                                                                <p className='para-v2'>How is your team performing today ?</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">Products never sold</a> 
                                                                </h3>  
                                                                <p className='para-v2'>How is your team performing today ?</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">Products on unshipped picklists</a> 
                                                                </h3>  
                                                                <p className='para-v2'>How is your team performing today ?</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">Products received via purchase orders</a> 
                                                                </h3>  
                                                                <p className='para-v2'>How is your team performing today ?</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">Receipts processed</a> 
                                                                </h3> 
                                                                <p className='para-v2'>How is your team performing today ?</p> 
                                                            </div>
                                                            <div className="report-card">
                                                                <h3 className='heading-v2'>
                                                                   <a href="">Returns processed</a> 
                                                                </h3> 
                                                                <p className='para-v2'>How is your team performing today ?</p> 
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                 
                                                
                                                
                                            </div>
                                            

			                            {/*  ))} */}
                                    </div>
                                    </div>
                                    <br />
                                </>
                            )}
                            
                        </div>
                   

						

                </div>
            </div>
        </div>
	);
};

export default Reports;
