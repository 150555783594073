import { concat } from 'assets/scss/fontawesome-free/js/v4-shims';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaPlus, FaTimes } from 'react-icons/fa';
import toast from 'react-hot-toast';
import Select from 'react-select';

function UpdateStatusModal({ show, handleClose, selectedIds, handleLoadRecord, clearSelectedIds }) {
	const [selectedStatus, setSelectedStatus] = useState('123');

	const handleStatusChange = (e) => {
		setSelectedStatus(e.target.value);
	};

	const [status, setStatus] = useState([]);

	const loadReturnsStatus = () => {
		api.getReturnsStatusList().then((res) => {
			if (res.status) {
				let typeTemp = res.data.map((item) => ({ value: item.id, label: item.name }));
				setStatus(typeTemp);
			} else {
			}
		});
	};

	const handleUpdateStatusSelected = () => {
		const data = {
			selectedIds: selectedIds,
			selectedStatus: selectedStatus
		};

		api.updateStatusSelected(data).then((res) => {
			if (res.status == true) {
				toast.success(res.message);
				handleLoadRecord();
				clearSelectedIds();
				handleClose();
			} else {
				toast.error(res.message);
			}
		});
	};
	useEffect(() => {

		loadReturnsStatus();
	}, []);

	return (
		<Modal show={show} onClose={handleClose} centered>
			<Modal.Body>
				<div className="p-2">
					<div className="foundListPopup">
						<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 ml-0 pl-0">
							<h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0">Update Status</h5>
							<button className="btn modal-close-icon" onClick={handleClose}>
								<svg
									id="Component_127_1"
									height="16"
									width="16"
									data-name="Component 127 – 1"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 19.295 19.289"
								>
									<path
										id="Icon_ionic-ios-close"
										data-name="Icon ionic-ios-close"
										d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
										transform="translate(-11.285 -11.289)"
									/>
								</svg>
							</button>
						</div>
						<div className="row align-items-top">
							<div className="col-12">
								<div className="row">
									<div className="form-group col-md-12">
										<label className="text-gray-900">Select Status</label>
										{/* <select
											className="form-control"
											id="statusSelect"
											name="statusSelect"
											value={selectedStatus}
											onChange={handleStatusChange}
										>
											<option value="123">Received</option>
											<option value="124">In Repair</option>
											<option value="125">Completed</option>
											<option value="127">For Credit Note</option>
										</select> */}
										<Select
											className="rs-container"
											classNamePrefix="rs"
											name="ca"
											menuPosition="fixed"
											options={status.map((item) => ({ label: item.label, value: item.value }))}
											value={
												status.map((item) => ({
													label: item.label, value: item.value
												}))?.find((c) => c?.value == selectedStatus)
											}
											onChange={(e) => {
												setSelectedStatus(e?.value);
											}}
										// onChange={(e) => {
										// 	handleChange({ ...productDetail, storageId: e.value });
										// }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="btnarea">
						<button className="btn btn-main" onClick={handleUpdateStatusSelected}>
							<FaPlus /> Update status
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default UpdateStatusModal;
