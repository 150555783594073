import React, { useEffect, useRef, useState } from 'react';
import './GlobalSearch.scss';
import { CgSearch } from 'react-icons/cg';
import { FaFileInvoice, FaFileInvoiceDollar, FaUserTag } from 'react-icons/fa';
import { RxCaretRight } from 'react-icons/rx';
import { FaUserGroup } from 'react-icons/fa6';

function GlobalSearch({ show, onForceClose }) {
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
		if (containerRef.current && !containerRef.current.contains(event.target)) {
      onForceClose && onForceClose();
		}
	};

  const escFunction = (event) => {
    if (event.key === "Escape") {
      onForceClose && onForceClose();
    }
  };

  useEffect(() => {
	setTimeout(()=>{
		inputRef.current?.focus();
	}, 1)

  }, [show])

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);


	return (
		<>
			{show && (
				<div className="global-search-component">
					<div className="g-search-container" ref={containerRef}>
						<form>
							<div className="gs-input-group">
								<div className="icon">
									<CgSearch />
								</div>
								<input type="text" placeholder="Search by Customer, Supplier, estimate etc…" ref={inputRef}/>
							</div>
						</form>
						<div className="search-results">
							<div className="search-result-group">
								<p className="sr-title">
									<FaFileInvoiceDollar /> Estimates
								</p>
								<div className="srg-items">
									<a className="srg-item">
										EST-123456 <RxCaretRight />
									</a>
									<a className="srg-item">
										EST-123456 <RxCaretRight />
									</a>
									<a className="srg-item">
										EST-123456 <RxCaretRight />
									</a>
									<a className="srg-item">
										EST-123456 <RxCaretRight />
									</a>
								</div>
							</div>
							<div className="search-result-group">
								<p className="sr-title">
									<FaFileInvoice /> Invoices
								</p>
								<div className="srg-items">
									<a className="srg-item">
										INV-123456 <RxCaretRight />
									</a>
									<a className="srg-item">
										INV-123456 <RxCaretRight />
									</a>
									<a className="srg-item">
										INV-123456 <RxCaretRight />
									</a>
								</div>
							</div>
							<div className="search-result-group">
								<p className="sr-title">
									<FaUserGroup /> Customers
								</p>
								<div className="srg-items">
									<a className="srg-item">
										ABC Private Limited <RxCaretRight />
									</a>
									<a className="srg-item">
										QWERTY and Co <RxCaretRight />
									</a>
									<a className="srg-item">
										Apple INC <RxCaretRight />
									</a>
									<a className="srg-item">
										Coolmix BV <RxCaretRight />
									</a>
								</div>
							</div>
							<div className="search-result-group">
								<p className="sr-title">
									<FaUserTag /> Suppliers
								</p>
								<div className="srg-items">
									<a className="srg-item">
										ABC Private Limited <RxCaretRight />
									</a>
									<a className="srg-item">
										QWERTY and Co <RxCaretRight />
									</a>
									<a className="srg-item">
										Apple INC <RxCaretRight />
									</a>
									<a className="srg-item">
										Coolmix BV <RxCaretRight />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default GlobalSearch;
