import React from 'react';
import './processindicator.scss';
import { Tooltip } from '@mui/material';

function ProcessIndicator({ processes, isInProgress, currentIndex = 0 }) {
	return (
		<div className="d-flex justify-content-center align-items-center process-status-indicator-container">
			<div className="process-status-indicator mt-3">
				{processes.map((item, index) => {
					return (
						<>
							<div
								className={`process ${index < currentIndex ? 'completed' : index == currentIndex ? 'current' : ''} ${
									index == currentIndex && isInProgress ? 'inprogress' : ''
								}`}
							>
								<Tooltip title={item?.name}>
									<div className="indicator-check">
										<span className="check-icon">{item?.icon}</span>
									</div>
								</Tooltip>
							</div>
							{processes?.length - 1 != index && <div className="process-connector-line">&nbsp;</div>}
						</>
					);
				})}
			</div>
		</div>
	);
}

export default ProcessIndicator;
