import React, { useState, useEffect } from 'react';
import ProcessIndicator from '../ProcessIndicator';
import { CompanyDetailsProgresses } from 'assets/helperdata/client/company-details-progress';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { storeClientDetails } from 'store/reducers/mainSlice';

const EndDetails = () => {
	const history = useNavigate();
	const clientUser = useSelector((state) => state.main.client);
	const [isInProgress, setIsInProgress] = useState(false);
	const [interests, setInterests] = useState([]);
	const [specifications, setSpecifications] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const dispatch = useDispatch();
	const [data, setData] = useState({
		interests: '',
		specifications: '',
		isAgreed: false,
		isAcknowledged: false,
		id: clientUser?.data?.id
	});
	const [user, setUser] = useState({});
	const [userInfo, setUserInfo] = useState({});

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	console.log(clientUser);
	const moveToNext = () => {
		if (userInfo?.company_activity?.name === 'Mobiles') {
			if (data.interests == '' || data.specifications == '' || !data.isAgreed || !data.isAcknowledged) {
				setIsInProgress(false);
				toast.error('Please fill all fields');
				return;
			}
		} else {
			if (data.specifications == '' || !data.isAgreed || !data.isAcknowledged) {
				setIsInProgress(false);
				toast.error('Please fill all fields');
				return;
			}
		}
		setIsInProgress(false);
		api
			.updateClientInterest(data.id, data)
			.then((res) => {
				console.log(res.data);
				if (res.status === true) {
					toast.success('Successfully updated');
					const client_data = {
						data: res.data,
						token: res.data.api_token
					};
					dispatch(storeClientDetails({ ...client_data, stayLoggedIn: true }));
					history('../client/company-verified');
				}
			})
			.catch((err) => {
				toast.error('Failed to update');
				console.log(err);
			});
	};
	const moveToBack = () => {
		setIsInProgress(false);
		history('../client/company-survey-details');
	};

	useEffect(() => {
		api
			.getClientInterest()
			.then((res) => {
				setInterests(res.data);
			})
			.catch((err) => {
				console.log(err);
			});

		api
			.getClientSpec()
			.then((res) => {
				setSpecifications(res.data);
			})
			.catch((err) => {
				console.log(err);
			});

		api
			.getClientInfo(clientUser?.data?.id)
			.then((res) => {
				setUser(res.client);
				setUserInfo(res.companyInfo);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleCheckboxChange = (value) => {
		setData((prevData) => {
			const interests = prevData.interests.includes(value)
				? prevData.interests.filter((interest) => interest !== value)
				: [...prevData.interests, value];

			return { ...prevData, interests };
		});
	};

	const handleSpecChange = (value) => {
		setData((prevData) => {
			let specifications = prevData.specifications.includes(value)
				? prevData.specifications.filter((spec) => spec !== value)
				: [...prevData.specifications, value];

			// Check if specifications is empty, and if so, set it to an empty string
			specifications = specifications.length === 0 ? '' : specifications;

			return { ...prevData, specifications };
		});
	};

	console.log(data);
	const calculateCompletionPercentage = () => {
		let fields = [];
		if (userInfo?.company_activity?.name === 'Mobiles') {
			fields = ['interests', 'specifications', 'isAgreed', 'isAcknowledged'];
		} else {
			fields = ['specifications', 'isAgreed', 'isAcknowledged'];
		}

		const filledOrTrueFields = fields.filter((field) => {
			if (field === 'isAgreed' || field === 'isAcknowledged') {
				return data[field] !== false;
			} else {
				return data[field] !== undefined && data[field] !== null && data[field] !== '';
			}
		});
		return (filledOrTrueFields.length / fields.length) * 100;
	};

	// Call this function whenever you want to get the completion percentage
	const completionPercentage = calculateCompletionPercentage();

	return (
		<div className={`main-page-client ${isMobile ? 'padding-mobile-client' : ''}`}>
			<div className="actions-company-details-client">
				<ProcessIndicator
					isInProgress={isInProgress}
					processes={CompanyDetailsProgresses}
					currentIndex={6}
					completionPercentage={completionPercentage}
				/>
			</div>
			<div className="company-details-client-content">
				<div className="" style={{ justifyContent: 'space-between' }}>
					<table className="projectmix-table-vertical-client">
						<tbody>
							{userInfo?.company_activity?.name === 'Mobiles' && (
								<>
									{isMobile && (
										<tr>
											<td className="fw-600" style={{ textAlign: isMobile ? 'left' : 'left', fontSize: isMobile ? 16 : 14 }} colspan={2}>
												Interests
											</td>
										</tr>
									)}
									<br />
									<tr>
										{!isMobile && <th>Interests</th>}
										{interests.slice(0, 2).map((interest) => (
											<td>
												<div className="group-chk-cmpny-dtls">
													<input type="checkbox" id={`checkbox-${interest.id}`} onClick={() => handleCheckboxChange(interest.id)} />
													<label htmlFor={`checkbox-${interest.id}`}>{interest.name}</label>
												</div>
											</td>
										))}
									</tr>
									<tr>
										{!isMobile && <th></th>}
										{interests.slice(2, 4).map((interest) => (
											<td>
												<div className="group-chk-cmpny-dtls">
													<input type="checkbox" id={`checkbox-${interest.id}`} onClick={() => handleCheckboxChange(interest.id)} />
													<label htmlFor={`checkbox-${interest.id}`}>{interest.name}</label>
												</div>
											</td>
										))}
									</tr>
									<tr>
										{!isMobile && <th></th>}
										{interests.slice(4, 6).map((interest) => (
											<td>
												<div className="group-chk-cmpny-dtls">
													<input type="checkbox" id={`checkbox-${interest.id}`} onClick={() => handleCheckboxChange(interest.id)} />
													<label htmlFor={`checkbox-${interest.id}`}>{interest.name}</label>
												</div>
											</td>
										))}
									</tr>
									<tr>
										{!isMobile && <th></th>}
										{interests.slice(6, 8).map((interest) => (
											<td>
												<div className="group-chk-cmpny-dtls">
													<input type="checkbox" id={`checkbox-${interest.id}`} onClick={() => handleCheckboxChange(interest.id)} />
													<label htmlFor={`checkbox-${interest.id}`}>{interest.name}</label>
												</div>
											</td>
										))}
									</tr>
									<br />
								</>
							)}

							{isMobile && (
								<tr>
									<td className="fw-600" style={{ textAlign: isMobile ? 'left' : 'left', fontSize: isMobile ? 16 : 14 }} colspan={2}>
										Specifications
									</td>
								</tr>
							)}
							<br />
							<tr>
								{!isMobile && <th>Specifications</th>}
								{specifications.slice(0, 2).map((spec) => (
									<td>
										<div className="group-chk-cmpny-dtls">
											<input type="checkbox" id={`checkbox-${spec.id}`} onClick={() => handleSpecChange(spec.id)} />
											<label htmlFor={`checkbox-${spec.id}`}>{spec.name}</label>
										</div>
									</td>
								))}
							</tr>
							<tr>
								{!isMobile && <th></th>}
								{specifications.slice(2, 4).map((spec) => (
									<td>
										<div className="group-chk-cmpny-dtls">
											<input type="checkbox" id={`checkbox-${spec.id}`} onClick={() => handleSpecChange(spec.id)} />
											<label htmlFor={`checkbox-${spec.id}`}>{spec.name}</label>
										</div>
									</td>
								))}
							</tr>
							<tr>
								{!isMobile && <th></th>}
								{specifications.slice(4, 6).map((spec) => (
									<td>
										<div className="group-chk-cmpny-dtls">
											<input type="checkbox" id={`checkbox-${spec.id}`} onClick={() => handleSpecChange(spec.id)} />
											<label htmlFor={`checkbox-${spec.id}`}>{spec.name}</label>
										</div>
									</td>
								))}
							</tr>
							<tr>
								{!isMobile && <th></th>}
								{specifications.slice(6, 8).map((spec) => (
									<td>
										<div className="group-chk-cmpny-dtls">
											<input type="checkbox" id={`checkbox-${spec.id}`} onClick={() => handleSpecChange(spec.id)} />
											<label htmlFor={`checkbox-${spec.id}`}>{spec.name}</label>
										</div>
									</td>
								))}
							</tr>
							<tr>
								{!isMobile && <th></th>}
								{specifications.slice(8, 10).map((spec) => (
									<td>
										<div className="group-chk-cmpny-dtls">
											<input type="checkbox" id={`checkbox-${spec.id}`} onClick={() => handleSpecChange(spec.id)} />
											<label htmlFor={`checkbox-${spec.id}`}>{spec.name}</label>
										</div>
									</td>
								))}
							</tr>
							<tr>
								{!isMobile && <th></th>}
								{specifications.slice(10, 11).map((spec) => (
									<td>
										<div className="group-chk-cmpny-dtls">
											<input type="checkbox" id={`checkbox-${spec.id}`} onClick={() => handleSpecChange(spec.id)} />
											<label htmlFor={`checkbox-${spec.id}`}>{spec.name}</label>
										</div>
									</td>
								))}
								<td></td>
							</tr>
						</tbody>
					</table>
					<div className="group-chk-trms-dtls">
						<input type="checkbox" id="terms-checkbox" onClick={() => setData({ ...data, isAgreed: !data.isAgreed })} />
						<label htmlFor="terms-checkbox">
							I agree to the{' '}
							<a href="https://projectmix.ai/terms-of-use" target="_blank">
								terms and conditions.
							</a>{' '}
						</label>
					</div>
					<div className="group-chk-trms-dtls">
						<input
							type="checkbox"
							name=""
							id="acknowledge-checkbox"
							style={{ marginBottom: isMobile ? 20 : 0 }}
							onClick={() => setData({ ...data, isAcknowledged: !data.isAcknowledged })}
						/>
						<label htmlFor="acknowledge-checkbox">I acknowledge that the provided information is accurate.</label>
					</div>
				</div>
			</div>
			<div className="action-company-dtls-adrs-btn" style={{ marginTop: 10 }}>
				<button
					className="btn btn-cmpny-dtls-action-back"
					onClick={() => {
						setIsInProgress(true);
						moveToBack();
					}}
				>
					<MdKeyboardArrowLeft /> Back
				</button>

				<button
					className="btn btn-cmpny-dtls-action"
					onClick={() => {
						setIsInProgress(true);
						moveToNext();
					}}
				>
					Next <MdKeyboardArrowRight />
				</button>
			</div>
		</div>
	);
};

export default EndDetails;
