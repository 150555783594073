import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import Loader from 'ui-component/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import editIcon from 'assets/images/icons/ic_edit.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import { FaArrowLeft, FaEdit, FaEllipsisH, FaEye, FaTimesCircle, FaFileInvoice, FaPlus } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { TbDatabaseExclamation } from 'react-icons/tb';
import Status from 'views/contacts/statusComponents/Estimate';
import DirectoryTree from 'views/contacts/statusComponents/DirectoryTree';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import './contactdetails.scss';
import { FaCircleCheck, FaUserTie, FaWhatsapp, FaRegTrashCan } from 'react-icons/fa6';
import { LuChevronDown, LuPhone, LuTrash2, LuBan, LuCheck } from 'react-icons/lu';
import { MdOutlineMailOutline } from 'react-icons/md';
import { ImLocation2 } from 'react-icons/im';
import { BsGlobe2 } from 'react-icons/bs';
import { textToSentenceCase } from 'helpers/common.helper';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Tooltip } from '@mui/material';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import ClientPortalModel from './statusComponents/ClientPortalModal';
import { PiInfoFill, PiArchiveBoxFill, PiCheckBold } from 'react-icons/pi';
import toast from 'react-hot-toast';

const ContactDetails = () => {
	const { id } = useParams();
	// const isLoading = useSelector((state) => state.main.isLoading);
	const selectedUser = useSelector((state) => state.main.user.selectedUser);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const dispatch = useDispatch();
	const history = useNavigate();
	const [contactInfo, setContactInfo] = useState();
	const [isLoading, setIsloading] = useState(false);
	const [activeStatus, setActiveStatus] = useState('stats');
	const [show, setShow] = useState('');
	const [showPortal, setShowPortal] = useState('');
	const [contactStatus, setContactStatus] = useState(0);
	const [contactNewStatus, setContactNewStatus] = useState('');
	// const [roles, setRoles] = useState();
	const [tableData, setTableData] = useState();

	const [hasVatFile, setHasVatFile] = useState(false);
	const [vatFile, setVatFile] = useState({
		id: '',
		contact_id: '',
		file_name: '',
		type: '',
		vat_attachment_url: ''
	});
	const [hasRegistrationFile, setHasRegistrationFile] = useState(false);
	const [registrationFile, setRegistrationFile] = useState({
		id: '',
		contact_id: '',
		file_name: '',
		type: '',
		registration_attachment_url: ''
	});

	const getSalesManagerName = () => {
		let sales_manager = `${contactInfo?.sales_manager?.first_name} ${contactInfo?.sales_manager?.last_name || ''}`;
		sales_manager =
			sales_manager?.length <= 25
				? sales_manager
				: `${contactInfo?.sales_manager?.first_name.slice(0, 10)} ${contactInfo?.sales_manager?.last_name?.slice(0, 10) + '...' || ''}`;
		return sales_manager;
	};

	const handleStatusChange = (value) => {
		setActiveStatus(value);
	};

	const getFullName = () => {
		let name = contactInfo?.contact_name || '-';
		name = name.length <= 30 ? name : contactInfo?.contact_name?.slice(0, 25) + '...' || '-';
		return name;
	};
	const viewDetails = (id) => {
		history(`/sales/invoices/${id}`);
	};

	const getcustomerInfo = () => {
		const loading = [true, true];
		setIsloading(true);

		api.getContactbyId(id).then((res) => {
			loading[0] = false;
			setIsloading(loading[0] || loading[1]);

			setContactInfo(res.data);
			setContactStatus(res?.data?.status);
			if (res?.data?.status == 1) {
				setContactNewStatus('Suspend');
			} else {
				setContactNewStatus('Activate');
			}

			if (!res?.data?.client) {
				res?.data?.attachments?.map((i) => {
					if (i?.type == 'vat') {
						setVatFile({
							id: i?.id,
							contact_id: i?.contact_id,
							file_name: i?.file_name,
							type: i?.type,
							vat_attachment_url: i?.vat_attachment_url
						});
						setHasVatFile(true);
					}
					if (i?.type == 'registration') {
						setRegistrationFile({
							id: i?.id,
							contact_id: i?.contact_id,
							file_name: i?.file_name,
							type: i?.type,
							registration_attachment_url: i?.registration_attachment_url
						});
						setHasRegistrationFile(true);
					}
					// console.log(i);
				});
			} else {
				const client = res?.data?.client;
				if (client) {
					if (client.vat_code) {
						const fileName = client.vat_doc.split('/').pop(); // Extracts the filename and extension
						setVatFile({
							id: client.id,
							contact_id: '',
							file_name: fileName, // Use the extracted filename
							type: 'vat',
							vat_attachment_url: client.vat_doc_url
						});
						setHasVatFile(true);
					}
					const fileNameReg = client.registration_doc_url.split('/').pop();
					setRegistrationFile({
						id: client.id,
						contact_id: '',
						file_name: fileNameReg,
						type: 'registration',
						registration_attachment_url: client.registration_doc_url
					});
					setHasRegistrationFile(true);
				}
			}
		});

		api.getContactEstimates(id).then((res) => {
			loading[1] = false;
			setIsloading(loading[0] || loading[1]);
			setTableData(res?.data?.data);
			// setContactInfo(res.data);
		});
	};
	const deleteuserData = () => {
		setShow(contactInfo);
	};

	const handleClientPortalSubmit = (data) => {
		console.log(data);
		toast.promise(
			api.addClientPortal(data).then((res) => {
				console.log(res);
				if (res.message) {
					history('/contacts');
				}
			}),
			{
				loading: 'Creating User Portal...',
				success: (data) => `${data.message}`,
				error: <b>Failed to create user portal</b>
			}
		);
	};

	const showPortalData = () => {
		setShowPortal('something');
	};
	const handleClose = () => setShow('');
	const handleClosePortal = () => setShowPortal('');

	const handleDelete = (id) => {
		showConfirmationDialog(
			`Delete Contact?`,
			`Are you sure you want to delete ${contactInfo?.name}?`,
			() => {
				handleDeleteSubmit(id);
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleDeleteSubmit = (id) => {
		toast.promise(
			api.deleteContact(id).then((res) => {
				if (res.status) {
					history('/contacts');
				}

				// setIsDisable(false);
				return res?.status ? res : null;
			}),
			{
				loading: 'Updating Status...',
				success: (data) => `${data.message}`,
				error: <b>Failed to update status</b>
			}
		);
	};

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const showConfirmationDialog = (title, message = '', onConfirm = () => {}, onClose = () => {}) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	console.log(contactInfo);

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const handleUpdateStatusSubmit = () => {
		toast.promise(
			api.updateContactStatus(id, contactStatus == 1 ? false : true).then((res) => {
				if (res.status) {
					// setIsInProgress(true);

					setContactStatus(res?.data?.status);
					if (res?.data == 'activated') {
						setContactNewStatus('Suspend');
						setContactStatus(1);
					} else {
						setContactNewStatus('Activate');
						setContactStatus(0);
					}
				}

				// setIsDisable(false);
				return res?.status ? res : null;
			}),
			{
				loading: 'Updating Status...',
				success: (data) => `${data.message}`,
				error: <b>Failed to update status</b>
			}
		);
	};

	const handleUpdateStatus = (id) => {
		showConfirmationDialog(
			`${contactNewStatus} Contact?`,
			`Are you sure you want to ${contactNewStatus.toLowerCase()} ${contactInfo?.name}?`,
			() => {
				handleUpdateStatusSubmit();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	useEffect(() => {
		getcustomerInfo();
	}, [activeStatus]);

	return (
		<>
			<>
				<div className="row m-0 p-0 mt-4 contact-details-section">
					<div className="col-3 m-0 p-0">
						<div className="card shadow-sm rounded bg-white p-3 contact-detail-side">
							<div className="contact-detail-head">
								<h2 className="contact-name">
									<PrivacyModeContent>{contactInfo?.name}</PrivacyModeContent>
								</h2>
								<p className="contact-sm">
									{contactInfo?.contact_type == 'customer' || contactInfo?.contact_type == 'lead' ? (
										<>
											<FaUserTie /> {contactInfo?.sales_manager?.first_name} {contactInfo?.sales_manager?.last_name}
										</>
									) : contactInfo?.contact_type == 'transit-company' ? (
										contactInfo?.transit_type == 1 ? (
											'Child Company'
										) : (
											'External'
										)
									) : (
										''
									)}
								</p>
								<div className="contact-head-btns">
									<a href={contactInfo?.contact_phone ? 'tel:' + contactInfo?.contact_phone : '#'} target="_blank">
										<Tooltip title="Call" arrow placement="top">
											<span className="btnEdit plain-iconbtn">
												<LuPhone />
											</span>
										</Tooltip>
									</a>

									<a href={contactInfo?.email ? 'mailto:' + contactInfo?.email : '#'} target="_blank">
										<Tooltip title="Email" arrow placement="top">
											<span className="btnEdit plain-iconbtn">
												<MdOutlineMailOutline />
											</span>
										</Tooltip>
									</a>
									<a href={contactInfo?.company_website || '#'} target="_blank">
										<Tooltip title="Go to Contact Website" arrow placement="top">
											<span className="btnEdit plain-iconbtn">
												<BsGlobe2 />
											</span>
										</Tooltip>
									</a>
									<PermissionAllowed permissions={['Suspend/Activate Contact (Button)']}>
										<Tooltip title={`${contactNewStatus} Contact`} arrow placement="top">
											<span
												className="btnEdit plain-iconbtn"
												onClick={() => {
													handleUpdateStatus(contactInfo?.id);
												}}
											>
												{contactStatus == 1 ? <LuBan /> : <LuCheck />}
											</span>
										</Tooltip>
									</PermissionAllowed>
									<PermissionAllowed permissions={['Delete Contact (Action)']}>
										<Tooltip title="Delete Contact" arrow placement="top">
											<span
												className="btnEdit plain-iconbtn"
												onClick={() => {
													handleDelete(contactInfo?.id);
												}}
											>
												<LuTrash2 />
											</span>
										</Tooltip>
									</PermissionAllowed>
								</div>
							</div>
							<div className="contact-detail-item">
								<label>Type</label>
								{textToSentenceCase(contactInfo?.contact_type) != 'falsefalse' ? (
									<>
										<p>{textToSentenceCase(contactInfo?.contact_type)?.replace('-', ' ') || '-'}</p>
									</>
								) : (
									<>
										<p>-</p>
									</>
								)}
							</div>
							<div className="contact-detail-item">
								<label>VAT Number</label>
								<p className="vat">
									<PrivacyModeContent>{contactInfo?.vat_number || '-'}</PrivacyModeContent>

									{contactInfo?.vat_number && (
										<>
											{contactInfo?.is_vat_verified ? (
												<span className="text-success">
													<FaCircleCheck />
												</span>
											) : (
												<span className="text-danger">
													<FaTimesCircle />
												</span>
											)}
										</>
									)}
								</p>
							</div>
							<div className="contact-detail-item">
								<label>VAT File</label>
								<p className="vat">
									{vatFile?.file_name ? (
										<a href={vatFile?.vat_attachment_url} target="_blank">
											<PrivacyModeContent>{vatFile?.file_name}</PrivacyModeContent>
										</a>
									) : (
										<>-</>
									)}
								</p>
							</div>
							<div className="contact-detail-item">
								<label>Registration Number</label>
								<p>
									<PrivacyModeContent>{contactInfo?.registration_number || '-'}</PrivacyModeContent>
								</p>
							</div>
							<div className="contact-detail-item">
								<label>Registration File</label>
								<p className="vat">
									{registrationFile?.file_name ? (
										<a href={registrationFile?.registration_attachment_url} target="_blank">
											<PrivacyModeContent>{registrationFile?.file_name}</PrivacyModeContent>
										</a>
									) : (
										<>-</>
									)}
								</p>
							</div>
							<div className="contact-detail-item">
								<label>Email address</label>
								<p>
									<PrivacyModeContent>{contactInfo?.email || '-'}</PrivacyModeContent>
								</p>
							</div>
							<div className="contact-detail-item">
								<label>Establishment Year </label>
								<p>{contactInfo?.establishment_year || '-'}</p>
							</div>
							<div className="contact-detail-item">
								<label>Turnover </label>
								<p>{contactInfo?.company_turnover?.name || '-'}</p>
							</div>
							<div className="contact-detail-item">
								<label>Company Activity </label>
								<p>{contactInfo?.company_activity?.name || '-'}</p>
							</div>
							<div className="contact-detail-item">
								<label>Interest </label>
								<p>
									{contactInfo?.interests?.length > 0
										? contactInfo?.interests?.map((int, ind) =>
												ind == contactInfo?.interests?.length - 1 ? int?.interest?.name : int?.interest?.name + ', '
										  )
										: '-'}
								</p>
							</div>
							<div className="contact-detail-item">
								<label>Specifications </label>
								<p>
									{contactInfo?.specifications?.length > 0
										? contactInfo?.specifications?.map((int, ind) =>
												ind == contactInfo?.specifications?.length - 1 ? int?.specification?.name : int?.specification?.name + ', '
										  )
										: '-'}
								</p>
							</div>
							<div className="contact-detail-item">
								<label>Shipping Address </label>
								<p>
									{contactInfo?.shipping?.address}, {contactInfo?.shipping?.city?.city}, {contactInfo?.shipping?.zipcode} <br />
									{contactInfo?.shipping?.country?.name}
								</p>
							</div>
							<div className="contact-detail-item">
								<label>Billing Address </label>
								<p>
									{contactInfo?.address?.address}, {contactInfo?.address?.city?.city}, {contactInfo?.address?.zipcode} <br />
									{contactInfo?.address?.country?.name}
								</p>
							</div>

							<h6 className="sub-head">Contact Details</h6>

							<div className="contact-cps">
								<div className="contact-cp">
									<label className="cp-key">Contact 01</label>
									<p className="cp-name">
										<PrivacyModeContent>{contactInfo?.contact_name || '-'}</PrivacyModeContent>
									</p>
									<p className="cp-email">
										<PrivacyModeContent>{contactInfo?.email || '-'}</PrivacyModeContent>
									</p>
									<p className="cp-mobile">
										<PrivacyModeContent>{contactInfo?.contact_phone || '-'}</PrivacyModeContent>
									</p>

									<div className="contact-cp-actions">
										<a href={contactInfo?.contact_phone ? 'tel:' + contactInfo?.contact_phone : '#'} target="_blank">
											<span className="btnEdit plain-iconbtn" style={{ fontSize: '10px', width: '20px', height: '20px' }}>
												<LuPhone />
											</span>
										</a>
										<a href={contactInfo?.contact_whatsapp ? 'https://wa.me/' + contactInfo?.contact_whatsapp?.replace('+', '') : '#'}>
											<span className="btnEdit plain-iconbtn" style={{ fontSize: '10px', width: '20px', height: '20px' }}>
												<FaWhatsapp />
											</span>
										</a>
										<a href={contactInfo?.email ? 'mailto:' + contactInfo?.email : '#'} target="_blank">
											<span className="btnEdit plain-iconbtn" style={{ fontSize: '10px', width: '20px', height: '20px' }}>
												<MdOutlineMailOutline />
											</span>
										</a>
										{/* <span className="btnEdit plain-iconbtn" style={{ fontSize: '10px', width: '20px', height: '20px' }}>
											<FiEdit />
										</span> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-9 m-0 p-0">
						<div className="contact-actions-area">
							{/* <button className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: '65px' }} 
							onClick={() => showPortalData()}>
								<FaPlus /> Add user portal
							</button> */}
							{/* <button className="btn btn-primary btn-outlined-main white-var white-var-success" style={{ minWidth: '65px' }}>
								Approve
							</button> */}
							<PermissionAllowed permissions={['Edit Contact Details (Action)']}>
								<Link className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: '65px' }} to={`/contacts/edit/${id}`}>
									<FaEdit /> Edit
								</Link>
							</PermissionAllowed>
							<PermissionAllowed permissions={['Delete Contact (Button)']} hasSome>
								<Dropdown className="btnDel iconbtn" align={'end'}>
									<Dropdown.Toggle as="span">
										<Link to={''} className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: 'unset' }}>
											Extra <LuChevronDown />
										</Link>
									</Dropdown.Toggle>
									<PermissionAllowed permissions={['Delete Contact (Button)']}>
										<Dropdown.Menu>
											<Dropdown.Item>Delete</Dropdown.Item>
										</Dropdown.Menu>
									</PermissionAllowed>
								</Dropdown>
							</PermissionAllowed>
						</div>
						<ul className="topListCom mini" style={{ paddingInline: '10px', paddingBottom: '5px' }}>
							<li
								className={`${activeStatus === 'stats' ? 'active' : ''}`}
								onClick={() => {
									handleStatusChange('stats');
								}}
							>
								Stats
							</li>
							<li
								className={`${activeStatus === 'Estimates' ? 'active' : ''}`}
								onClick={() => {
									handleStatusChange('Estimates');
								}}
							>
								Estimates
							</li>
							<li
								className={`${activeStatus === 'Invoices' ? 'active' : ''}`}
								onClick={() => {
									handleStatusChange('Invoices');
								}}
							>
								Invoices
							</li>
							<li
								className={`${activeStatus === 'Credit' ? 'active' : ''}`}
								onClick={() => {
									handleStatusChange('Credit');
								}}
							>
								Credit
							</li>
							<li
								className={`${activeStatus === 'Documents' ? 'active' : ''}`}
								onClick={() => {
									handleStatusChange('Documents');
								}}
							>
								Documents
							</li>
							<li
								className={`${activeStatus === 'History' ? 'active' : ''}`}
								onClick={() => {
									handleStatusChange('History');
								}}
							>
								History
							</li>
							<li
								className={`${activeStatus === 'More Details' ? 'active' : ''}`}
								onClick={() => {
									handleStatusChange('More Details');
								}}
							>
								More Details
							</li>
						</ul>
						{/* <div className="card shadow-sm  bg-white ml-3 mt-2">
							
						</div> */}
						<div className="table-responsive-xl tableData customersList">
							{activeStatus == 'Estimates' ? (
								<>
									<div className="card shadow-sm  bg-white backgroundData table-card">
										<div className="estimate-option-card mb-2">
											{tableData?.length > 0 ? (
												<Status row={tableData} status={activeStatus} viewDetails={viewDetails} />
											) : (
												<div className="not-found-container">
													<div className="not-found-icon">
														<TbDatabaseExclamation />
													</div>
													<h6 className="not-found-text">No Data Available</h6>
												</div>
											)}
										</div>
									</div>
								</>
							) : activeStatus == 'Invoices' ? (
								<>
									<div className="card shadow-sm  bg-white backgroundData">
										<div className="estimate-option-card mb-2">
											{tableData?.length > 0 ? (
												<Status row={tableData} status={activeStatus} viewDetails={viewDetails} />
											) : (
												<div className="not-found-container">
													<div className="not-found-icon">
														<TbDatabaseExclamation />
													</div>
													<h6 className="not-found-text">No Data Available</h6>
												</div>
											)}
										</div>
									</div>
								</>
							) : activeStatus == 'Credit' ? (
								<>
									<div className="card shadow-sm  bg-white backgroundData">
										<div className="estimate-option-card mb-2">
											{tableData?.length > 0 ? (
												<Status row={tableData} status={activeStatus} viewDetails={viewDetails} />
											) : (
												<div className="not-found-container">
													<div className="not-found-icon">
														<TbDatabaseExclamation />
													</div>
													<h6 className="not-found-text">No Data Available</h6>
												</div>
											)}
										</div>
									</div>
								</>
							) : activeStatus == 'Documents' ? (
								<>
									<div className="card shadow-sm  bg-white">
										<div>
											<DirectoryTree id={id} />
										</div>
									</div>
								</>
							) : (
								<>
									<div className="card shadow-sm  bg-white backgroundData">
										<div className="estimate-option-card mb-2">
											<div className="not-found-container">
												<div className="not-found-icon">
													<TbDatabaseExclamation />
												</div>
												<h6 className="not-found-text">No Data Available</h6>
											</div>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
				<Modal show={show} onHide={handleClose} centered>
					<Modal.Body>
						<Modal.Title>Confirm Delete</Modal.Title>
						<p>Are you sure you want to delete {`${show.name}`}</p>
						<Button variant="primary" className="m-2 " onClick={handleClose}>
							Cancel
						</Button>
						<Button
							variant="danger"
							className="m-2"
							onClick={(e) => {
								handleDelete(show.id);
							}}
						>
							Delete
						</Button>
					</Modal.Body>
				</Modal>
				{showPortal && <ClientPortalModel show={showPortal} handleConfirm={handleClientPortalSubmit} handleClose={handleClosePortal} />}
				{confirmationDialog?.show && (
					<ActionConfirmationModal
						show={confirmationDialog?.show}
						message={confirmationDialog?.message}
						title={confirmationDialog?.title}
						customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
						customCancelText={confirmationDialog?.customCancel || 'Cancel'}
						customIcon={<PiInfoFill />}
						handleConfirm={confirmationDialog?.handleConfirm}
						handleClose={confirmationDialog?.handleClose}
					/>
				)}
			</>
		</>
	);
};

export default ContactDetails;
