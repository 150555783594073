import React, { useEffect, useState } from 'react';
import { IconArrowUpRight } from '@tabler/icons'; // Import this if it's a separate component
import { Fade } from 'react-awesome-reveal';

const EstimatedCard = ({ estimatedValue, percentageChange }) => {
	const [estimated, setEstimated] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		api.getEstimated().then((res) => {
			setEstimated(res);
			setIsLoading(false);
		});
	}, []);

	if (isLoading) {
		return null; // or return a loading spinner
	}

	return (
		<div className="row" style={{ marginBottom: '20px' }}>
			<div className="col-12">
				<Fade>
					<div className="card" style={{ height: '100px' }}>
						<div className="card-body estimated-card" style={{ paddingTop: 15 }}>
							<div className="row">
								<div className="col-4 d-flex justify-content-center align-items-center">
									<div
										style={{
											borderRadius: '50%',
											background: '#e8f7f4',
											display: 'inline-flex',
											justifyContent: 'center',
											alignItems: 'center',
											width: '40px',
											height: '40px'
										}}
									>
										<IconArrowUpRight size={24} className="icon green" />
									</div>
								</div>
								<div className="col-8">
									<a style={{ fontWeight: 500 }}>New Estimations</a>
									<b style={{ fontSize: 20, fontWeight: 600 }}>$ {Math.floor(Number(estimated)).toLocaleString()}</b>
									<c style={{}}>{new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}</c>
								</div>
							</div>
						</div>
					</div>
				</Fade>
			</div>
		</div>
	);
};

export default EstimatedCard;
