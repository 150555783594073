import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
	getinspectionlistapi(status = '') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-inspections?status=${status}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	newInspectionNote(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-inspections/notes`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	removeInspectionNote(note_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/task-inspections/notes/${note_id}/delete`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	filterInspections(status, filters, search = null) {
		let url = `${base}/api/purchase-list/task-inspections?`;
		if (status) {
			url += `status=${status}&`;
		}

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getinspectiondetailsbyid(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-inspections/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getInspectionFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-inspections/filter`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	saveinspectionData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-inspections/save-details`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	approveInspection(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-inspections/approval/${id}`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	startInspection(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-inspections/start/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	rollbackInspection(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-inspections/rollback`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// Collection
	getcollectionlistapi(status = '') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-collections?status=${status}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getCollectionFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-collections/filter`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterCollections(status, filters, search = null) {
		let url = `${base}/api/purchase-list/task-collections?`;

		if (status) {
			url += `status=${status}&`;
		}

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getcollectiondetailsbyid(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-collections/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	startCollection(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-collections/start/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	savecollectionData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-collections/save-details`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// Create Inspection
	getsupplierListtaskbox(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/suppliers?status=${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	//getModel list
	getmodellistfortaskbox(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/models`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	createinspection(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-inspections/add`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	// Segregation
	createcollection(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-collections/add`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getSegregationlistapi(status = '') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-segregations?status=${status}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getsegregationdetailsbyid(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-segregations/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	startSegregation(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-segregations/start/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	saveSegregationData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-segregations/complete`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getSegregationFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-segregation/filter`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterSegregations(status, filters, search = null) {
		let url = `${base}/api/purchase-list/task-segregations?`;

		if (status) {
			url += `status=${status}&`;
		}

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	// Segregation

	getTestinglistapi(status = '') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-testings?status=${status}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	gettestingdetailsbyid(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-testings/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	startTesting(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-testings/start/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	saveTestingData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-testings/complete`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getTestingFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-testings/filter`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterTestings(status, filters, search = null) {
		let url = `${base}/api/purchase-list/task-testings?`;

		if (status) {
			url += `status=${status}&`;
		}

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getTestingIdFromRef(ref) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/purchase-list/task-testings/scan`,
					{ reference_no: ref },
					{
						headers: {
							Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	// Inbounding

	getInboundinglistapi(status = '') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-inbounding?status=${status}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	downloadInboundReport(inbound_id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/task-inbounding/${inbound_id}/get-inbound-file`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					},
					 responseType: 'blob'
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	getinboundingdetailsbyid(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-inbounding/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	startInbounding(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-inbounding/start/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	saveInboundingData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-inbounding/save-details`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getInboundingFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-inbounding/filter`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterInboundings(status, filters, search = null) {
		let url = `${base}/api/purchase-list/task-inbounding?`;

		if (status) {
			url += `status=${status}&`;
		}

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getInboundingIdFromRef(ref) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/purchase-list/task-inbounding/scan`,
					{ reference_no: ref },
					{
						headers: {
							Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getModelRepairOptions(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/repair/cost/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	checkIMEIStatus(imei) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-inbounding/get-imei-status/${imei}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response);
				});
		}, false);
	},

	updateStockRepair(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/stocks/repair`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	// QualityControl

	getQualityControllistapi(status = '') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-quality-control?status=${status}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getqualitycontroldetailsbyid(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-quality-control/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	startQualityControl(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-quality-control/start/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	saveQualityControlData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-quality-control/complete`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getQualityControlFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-quality-control/filter`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterQualityControls(status, filters, search = null) {
		let url = `${base}/api/purchase-list/task-quality-control?`;

		if (status) {
			url += `status=${status}&`;
		}

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getQualityControlIdFromRef(ref) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/purchase-list/task-quality-control/scan`,
					{ reference_no: ref },
					{
						headers: {
							Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	// General

	getEstimationDropdownList(transit_company) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/estimations/get-all/list?transit_company_id=${transit_company}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response);
				});
		}, false);
	},

	// Packing

	createPacking(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/task-package`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response);
				});
		}, false);
	},

	getPackinglistapi(status = '') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-packages?status=${status}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getpackingdetailsbyid(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-packages/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	startPacking(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-package/start/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	savePackingData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-package/update-task`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getPackingFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-packages/filter`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterPackings(status, filters, search = null) {
		let url = `${base}/api/purchase-list/task-packages?`;

		if (status) {
			url += `status=${status}&`;
		}

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getPackingIdFromRef(ref) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/purchase-list/task-package/scan`,
					{ reference_no: ref },
					{
						headers: {
							Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getImeiDetails(imei) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/imei/details`,
					{ imei },
					{
						headers: {
							Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response);
				});
		}, false);
	},

	getReadyToPackInvoices() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-package/get-ready-for-packing-invoice`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	// Repairing

	createRepairing(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/purchase-list/task-repairing`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					res(rej.response);
				});
		}, false);
	},

	getRepairinglistapi(status = '') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-repairing?status=${status}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getrepairingdetailsbyid(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-repairing/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	startRepairing(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-repairing/start/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	saveRepairingData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-repairing/complete`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getRepairingFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-repairing/filter`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterRepairings(status, filters, search = null) {
		let url = `${base}/api/purchase-list/task-repairing?`;

		if (status) {
			url += `status=${status}&`;
		}

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getRepairingIdFromRef(ref) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/purchase-list/task-repairing/scan`,
					{ reference_no: ref },
					{
						headers: {
							Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	newRepairRequest(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-repairing/stock-repairing/new-request`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	cancelRepairRequest(stock_repair_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-repairing/stock-repairing/${stock_repair_id}/cancel-request`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	stockRepairState(stock_id, action) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/task-repairing/stock-repairing`,
					{
						stock_id,
						action
					},
					{
						headers: {
							Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	stockRepairItemComplete(stock_repair_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(
					`${base}/api/task-repairing/${stock_repair_id}/complete-repair`,
					{
						headers: {
							Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	// Retesting

	getRetestinglistapi(status = '') {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-retestings?status=${status}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getretestingdetailsbyid(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-retestings/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	startRetesting(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/task-retestings/start/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	saveRetestingData(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-retestings/complete`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getRetestingFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/purchase-list/task-retestings/filter`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterRetestings(status, filters, search = null) {
		let url = `${base}/api/purchase-list/task-retestings?`;

		if (status) {
			url += `status=${status}&`;
		}

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getRetestingIdFromRef(ref) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/purchase-list/task-retesting/scan`,
					{ reference_no: ref },
					{
						headers: {
							Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPageInspectionCounter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/counters?page=inspection`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPageCollectionCounter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/counters?page=collection`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPageSegregationCounter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/counters?page=segregation`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPageTestingCounter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/counters?page=testing`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPageInboundingCounter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/counters?page=inbounding`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPageQualityControlCounter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/counters?page=quality_control`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPageRepairCounter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/counters?page=repair`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPageRetestCounter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/counters?page=retest`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getPagePackingCounter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/counters?page=packing`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	collectItem(collectionId, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/task-collections/collect/${collectionId}`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

};
