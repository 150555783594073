import React from 'react';
import aeFlag from '../../assets/images/flags/ae.svg';
import euFlag from '../../assets/images/flags/eu.svg';
import usFlag from '../../assets/images/flags/us.svg';

const FlagMap = {
	AE: aeFlag,
	US: usFlag,
	EU: euFlag
};

function Flag({ code, height = 10, width = 18 }) {
	return <img src={FlagMap?.[code]} alt="Image note found" width={width} height={height} />;
}

export default Flag;
