import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaSave } from 'react-icons/fa';
import { FiShoppingCart } from 'react-icons/fi';
import NoImage from 'assets/images/no-image.png';
import { toast } from 'react-hot-toast';
import { setCartValue } from 'store/reducers/mainSlice';

import Select from 'react-select';
import Images from '../../check-warranty/iphone-15-finish-select-202309-6-7inch-green.png';

function ProductDetailsModel({ show, handleClose, handleConfirm, selectedId, selectedCurrency }) {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	const [selectedProduct, setSelectedProduct] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const clientUser = useSelector((state) => state.main.client);
	const [quantity, setQuantity] = useState(50);
	const dispatch = useDispatch();
	const confirmBtnRef = useRef(null);
	const activeOptions = [
		{ value: 'yes', label: 'Yes' },
		{ value: 'no', label: 'No' }
	];
	const [data, setData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
		active: ''
	});

	useEffect(() => {
		if (selectedId > 0) {
			api.getProductDetailsClient(selectedId).then((res) => {
				setSelectedProduct(res.data);
				setIsLoading(false);
			});
		}
	}, [selectedId]);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	if (isLoading) {
		return <></>;
	}
	const currencySymbols = {
		USD: '$',
		EUR: '€',
		AED: 'Dhs'
	};

	const handleQuantityChange = (event) => {
		setQuantity(event.target.value); // Update state on change
	};

	const addToCart = () => {
		let data = {
			product_id: selectedProduct.id,
			quantity: quantity,
			price: selectedProduct.converted_currency_price[selectedCurrency.toLowerCase()],
			client_id: clientUser.data.id,
			currency: selectedCurrency
		};

		api
			.addToCart(data.client_id, data)
			.then((res) => {
				toast.success('Item added to cart successfully');
				handleClose();

				api.getCartDetails(clientUser?.data?.id).then((res) => {
					dispatch(setCartValue(res.data));
				});
			})
			.catch((error) => {
				toast.error('Failed to add item to cart');
				console.error(error);
			});
	};

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className=""
			dialogClassName="custom-modal"
		>
			{!isMobile ? (
				<Modal.Body style={{ margin: 20 }}>
					<div className=" client-prdct-details">
						<div className="heading-client-modal">
							{selectedProduct?.thumbnail ? (
								<img src={selectedProduct?.thumbnail_url} alt="product" />
							) : (
								<img src={NoImage} alt="product" />
							)}
						</div>

						<div className="prdct-dtls-modal" style={{ justifyContent: 'space-between' }}>
							<h3>
								{selectedProduct.model_name}
								{selectedProduct.storage_name && selectedProduct.grade_name
									? ` - ${selectedProduct.storage_name} - ${selectedProduct.grade_name}`
									: selectedProduct.storage_name || selectedProduct.grade_name}
							</h3>
							<table className="projectmix-table-vertical-client">
								<tbody>
									<tr>
										<th style={{ width: 'fit-content' }}>Product code </th>
										<td className="text-left pad-check-warrant">
											<span>{selectedProduct.sku}</span>
										</td>
									</tr>
									<tr>
										<th style={{ width: 'fit-content', justifyContent: 'start', display: 'flex' }}>Name</th>
										<td className="text-left pad-check-warrant">
											<span>
												{selectedProduct.model_name} {selectedProduct.storage_name} {selectedProduct.grade_name}
											</span>
										</td>
									</tr>

									<tr>
										<th> Price</th>
										<td className="text-left pad-check-warrant">
											<span className="">
												{currencySymbols[selectedCurrency]}{' '}
												{parseFloat(selectedProduct.converted_currency_price[selectedCurrency.toLowerCase()]).toFixed(2)}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
							<div className="prdc-dtls-action">
								<input
									type="number"
									className="qty-inpt-prdct"
									value={quantity} // Bind input value to state
									onChange={handleQuantityChange} // Handle changes
								/>
								<button className="prdc-dtls-add-cart-btn" onClick={addToCart}>
									Add to Cart <FiShoppingCart />
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			) : (
				<Modal.Body style={{ padding: 20 }}>
					<div className="row" style={{ fontSize: 14 }}>
						<div className="col-12">
							<h3 style={{ fontWeight: 600 }}>
								{selectedProduct.model_name} - {selectedProduct.storage_name} - {selectedProduct.grade_name}
							</h3>
						</div>
						<div className="col-12" style={{ marginBottom: 20 }}>
							{selectedProduct?.thumbnail ? (
								<img src={selectedProduct?.thumbnail_url} style={{ width: '50%' }} alt="product" />
							) : (
								<img src={NoImage} style={{ width: '50%' }} alt="product" />
							)}
						</div>
						<div className="col-4">
							<a style={{ color: '#535353' }}>SKU</a>
						</div>
						<div className="col-8">
							<a style={{ fontWeight: '500' }}>{selectedProduct?.sku}</a>
						</div>
						<div className="col-4">
							<a style={{ color: '#535353' }}>Product</a>
						</div>
						<div className="col-8">
							<a style={{ fontWeight: '500' }}>
								{selectedProduct.model_name} {selectedProduct.storage_name} {selectedProduct.grade_name}
							</a>
						</div>
						<div className="col-4">
							<a style={{ color: '#535353' }}>Price</a>
						</div>
						<div className="col-8">
							<a style={{ fontWeight: '500' }}>
								{currencySymbols[selectedCurrency]}{' '}
								{parseFloat(selectedProduct.converted_currency_price[selectedCurrency.toLowerCase()]).toFixed(2)}
							</a>
						</div>
						<div className="col-12" style={{ marginTop: 10 }}>
							<div className="prdc-dtls-action">
								<input type="number" className="qty-inpt-prdct-mobile" value="50" />
								<button className="prdc-dtls-add-cart-btn-mobile" style={{ marginLeft: 10 }} onClick={addToCart}>
									Add to Cart <FiShoppingCart />
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			)}
		</Modal>
	);
}

export default ProductDetailsModel;
