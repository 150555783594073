import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, storeInLocalStore, setStateModellist } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import { Dropdown } from 'react-bootstrap';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import { FaEye, FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import usePageTitle from 'hooks/usePageTitle';

const ProductCategories = () => {
	const [setPageTitle] = usePageTitle('Categories');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const history = useNavigate();
	const [listData, setListData] = useState([]);
	const [pagination, setpagination] = useState([]);
	const [show, setShow] = useState('');
	const [isLoading, setIsloading] = useState(false);
	const [isInternalLoading, setIsInternalLoading] = useState(false);
	const dispatch = useDispatch();

	const [filters, setFilters] = useState([
		{
			label: 'Category',
			name: 'category',
			options: [
				{ label: 'iPhone', value: 'iphone' },
				{ label: 'iPad', value: 'ipad' },
				{ label: 'Cable', value: 'cable' },
				{ label: 'Charger', value: 'charger' }
			]
		}
	]);
	const [activeFilters, setActiveFilters] = useState({});

	useEffect(() => {
		getListData();
	}, []);

	const getListData = (internal = false) => {
		internal ? setIsInternalLoading(true) : setIsloading(true);
		api
			.getAllProductCategories()
			.then((res) => {
				setListData(res.data.data);
				setpagination(res.data);
				dispatch(setLoading(false));
				internal ? setIsInternalLoading(false) : setIsloading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDelete = (id) => {
		api.deleteCategory(id).then((res) => {
			if (res.status) {
				setShow('');
				getListData();
				dispatch(storeInLocalStore({ stateAlertmessage: 'Category Deleted Successfully.' }));
			}
		});
	};

	const handleClose = () => setShow('');

	return (
		<>
			<div className="row">
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<h2></h2>
						<div className="actions">
							<Link to={'/settings/productcategories/create'} className="btn btn-primary btn-outlined-main white-var">
								<FaPlus /> Add New
							</Link>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter
									activeFilters={activeFilters}
									// onSubmit={setActiveFilters}
									filters={filters}
								/>
							</div>
							<form className="form-inline navbar-search">
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										// onChange={handleSearch}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="table-responsive-xl tableData UserListing p-2">
							<Scroller>
								<table className="table pm-table">
									<thead>
										<tr className="sticky-header">
											<th width="40%" scope="col">
												Category
											</th>
											<th width="12%" scope="col" className="text-center">
												Has Grades?
											</th>
											<th width="12%" scope="col" className="text-center">
												Has Storage?
											</th>
											<th width="12%" scope="col" className="text-center">
												Has Colors?
											</th>
											<th width="12%" scope="col" className="text-center">
												Has Skip Operations?
											</th>
											<th width="12%" scope="col" className="text-center">
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<>
												{[...Array(5)].map((e, i) => (
													<tr>
														{[...Array(5)].map((e, i) => (
															<td>
																<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
															</td>
														))}
													</tr>
												))}
											</>
										) : (
											<>
												{listData?.map((row, index) => {
													return (
														<tr key={index}>
															<td className="align-middle">{row.name}</td>
															<td className="align-middle text-center">
																{row?.has_grade_variations ? (
																	<span className="activeStatus bg-success">Yes</span>
																) : (
																	<span className="inactiveStatus bg-danger">No</span>
																)}
															</td>
															<td className="align-middle text-center">
																{row?.has_storage_variations ? (
																	<span className="activeStatus bg-success">Yes</span>
																) : (
																	<span className="inactiveStatus bg-danger">No</span>
																)}
															</td>
															<td className="align-middle text-center">
																{row?.has_color_variations ? (
																	<span className="activeStatus bg-success">Yes</span>
																) : (
																	<span className="inactiveStatus bg-danger">No</span>
																)}
															</td>
															<td className="align-middle text-center">
																{row?.has_skip_inbounding ||
																row?.has_skip_quality_control ||
																row?.has_skip_repairing ||
																row?.has_skip_retesting ||
																row?.has_skip_testing ? (
																	<span className="activeStatus bg-success">Yes</span>
																) : (
																	<span className="inactiveStatus bg-danger">No</span>
																)}
															</td>
															<td className="align-middle text-center action-col">
																<Dropdown className="btnDel iconbtn">
																	<Dropdown.Toggle as="span" id={`dropdown-toggle-${index}`}>
																		<i className="fas fa-ellipsis-h "></i>
																	</Dropdown.Toggle>
																	<Dropdown.Menu>
																		<Dropdown.Item
																			onClick={() => {
																				history(`/settings/productcategories/${row?.id}`);
																			}}
																		>
																			Edit
																		</Dropdown.Item>
																		<Dropdown.Item onClick={() => handleDelete(row?.id)}>Delete</Dropdown.Item>
																	</Dropdown.Menu>
																</Dropdown>
															</td>
														</tr>
													);
												})}
												{listData?.length < 1 && (
													<tr className="no-hover">
														<td colSpan={5}>
															<div className="not-found-container">
																<div className="not-found-icon">
																	<TbDatabaseExclamation />
																</div>
																<h6 className="not-found-text">No Data Available</h6>
															</div>
														</td>
													</tr>
												)}
											</>
										)}
									</tbody>
								</table>
							</Scroller>
						</div>
					</div>
				</div>
			</div>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Body>
					<Modal.Title>Confirm Delete</Modal.Title>
					<p>Are you sure you want to delete {`${show.name}`}</p>
					<Button variant="primary" className="m-2 " onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="m-2"
						onClick={() => {
							handleDelete(show.id);
						}}
					>
						Delete
					</Button>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ProductCategories;
