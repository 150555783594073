import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setSelectedItem, setStateUserList, storeInLocalStore, setStateUserRole } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import IconSearch from 'assets/images/magnifying-glass.svg';
import { Dropdown } from 'react-bootstrap';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import { FaEye, FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { LiaTimesSolid } from 'react-icons/lia';
import UserPermissions from 'views/settings/UserPermissions';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import { RiShieldUserLine } from 'react-icons/ri';
import { ImSpinner2 } from 'react-icons/im';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import RoleFormModal from 'ui-component/confirmation-dialogs/RoleFormModal/RoleFormModal';
import toast from 'react-hot-toast';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import usePageTitle from 'hooks/usePageTitle';

const RolesList = () => {
	const [setPageTitle] = usePageTitle('Roles');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const history = useNavigate();
	const [roleList, setRoleList] = useState([]);
	const [show, setShow] = useState('');
	const [roleslist, setRoleslist] = useState([]);
	const [pagination, setpagination] = useState([]);
	const [isLoading, setIsloading] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const [stateUserlist, setStateUserlist] = useState();
	const staterole = useSelector((state) => state.main.setstateuserrole);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([
		{
			label: 'Role',
			name: 'role',
			options: [
				{ label: 'Administrator', value: 1 },
				{ label: 'Technician', value: 0 },
				{ label: 'Logistics', value: 0 }
			]
		}
	]);
	const [activeFilters, setActiveFilters] = useState({});

	const [rolePermissions, setRolePermissions] = useState();

	const getRolelist = async () => {
		setIsloading(true);
		const response = await api.getRoesWithPermissionsList();
		setRoleList(response.data);
		setpagination(response.data);
		setIsloading(false);
	};
	const getAllrole = () => { };
	const deleteroleData = (data) => {
		setShow(data);
	};
	const handleSearch = (e) => {
		const name = e.target.value;
		if (name.length > 2) {
			setTimeout(() => {
				setIsloading(true);
				api.searchuser(name).then((res) => {
					setIsloading(false);
					setRoleList(res.data.data);
					setpagination(res.data);
				});
			}, 500);
		}
		if (name.length == 0) {
			getRolelist();
		}
	};
	const handleClose = () => setShow('');

	const [loadingPermissionId, setLoadingPermissionId] = useState();

	const [showRoleModal, setShowRoleModal] = useState(false);

	const handleRoleFormSubmit = (e) => {
		if (e?.edit) {
			setShowRoleModal(false);
			toast.promise(
				api
					.updateRole(e?.edit?.id, {
						name: e?.role
					})
					.then((res) => {
						getRolelist();
						return res?.status ? res : null;
					}),
				{
					loading: 'Updating Role...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update role</b>
				}
			);
		} else {
			setShowRoleModal(false);
			toast.promise(
				api
					.createRole({
						name: e?.role
					})
					.then((res) => {
						getRolelist();
						return res?.status ? res : null;
					}),
				{
					loading: 'Creating Role...',
					success: (data) => `${data.message}`,
					error: <b>Failed to create role</b>
				}
			);
		}
	};

	const openPermissions = (id) => {
		setLoadingPermissionId(id);
		api.getRolePermissionsList(id).then((res) => {
			if (res?.status) {
				setRolePermissions({
					role: roleList?.find((u) => u?.id == id),
					permissions: res?.data?.permissions
				});
			}
			setLoadingPermissionId(null);
		});
	};

	const handleDelete = (id) => {
		toast.promise(
			api.deleteRole(id).then((res) => {
				if (res.status) {
					getRolelist();
					setShow(null);
					return res?.status ? res : abc;
				}
			}),
			{
				loading: 'Deleting Role...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete role</b>
			}
		);
	};
	const viewUserData = (id) => {
		const currentUser = roleList.filter((item) => item.id === id);
		dispatch(setSelectedItem(currentUser[0]));
		history(`/settings/user/${id}`);
	};
	const updateUser = (id) => {
		const currentUser = roleList.filter((item) => item.id === id);
		dispatch(setSelectedItem(currentUser[0]));
		history(`/settings/user/edit/${id}`);
	};
	useEffect(() => {
		console.log(staterole);
		if (!staterole) {
			getAllrole();
		} else {
			setRoleslist(staterole);
		}
	}, [staterole]);
	useEffect(() => {
		getRolelist();
	}, []);
	const roleFilter = (e) => {
		setIsloading(true);
		api.filterbyRoles({
			role: e.target.value
		}).then((res) => {
			setIsloading(false);
			setRoleList(res.data.data);
			setpagination(res.data);
		});
	};
	const handleData = (data) => {
		setRoleList(data.data);
		setpagination(data);
	};
	return (
		<>
			<div className="row " style={{ height: '100%' }}>
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<h2></h2>
						<div className="actions">
							<PermissionAllowed permissions={['Create Role (Button)']}>
								<Link
									to={'#'}
									onClick={() => {
										setShowRoleModal(true);
									}}
									className="btn btn-primary btn-outlined-main white-var"
								>
									<FaPlus /> New Role
								</Link>
							</PermissionAllowed>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="estimation-table-area">
							<div className="table-side">
								<div className="table-responsive-xl tableData UserListing p-2">
									<Scroller>
										<table className="table pm-table min-width-unset">
											<thead>
												<tr className='sticky-header'>
													<th scope="col" width="">
														Role
													</th>
													<th scope="col" width="">
														# of users
													</th>
													<th scope="col" className="text-center" width={'180'}>
														Action
													</th>
												</tr>
											</thead>
											<tbody>
												{isLoading ? (
													<>
														{[...Array(5)].map((e, i) => (
															<tr>
																{[...Array(3)].map((e, i) => (
																	<td>
																		<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																	</td>
																))}
															</tr>
														))}
													</>
												) : (
													<>
														{roleList?.map((row, index) => {
															return (
																<tr
																	key={index}
																	className={rolePermissions?.user?.id == row?.id ? 'selected-row' : ''}
																>
																	<td className="align-middle"> {row?.name}</td>
																	<td className="align-middle"> {row?.total_users}</td>

																	<td className="align-middle text-center action-col">
																		<PermissionAllowed
																			permissions={['Update Role Permission (Button)']}
																		>
																			<span
																				className="btnEdit plain-iconbtn"
																				onClick={() => openPermissions(row.id)}
																			>
																				{loadingPermissionId == row.id ? (
																					<ImSpinner2 className="fa-spin" />
																				) : (
																					<RiShieldUserLine />
																				)}
																			</span>
																		</PermissionAllowed>

																		<PermissionAllowed permissions={['Delete Role (Button)']} hasSome>
																			<Dropdown className="btnDel iconbtn">
																				<Dropdown.Toggle as="span" id={`dropdown-toggle-${index}`}>
																					<i className="fas fa-ellipsis-h "></i>
																				</Dropdown.Toggle>
																				<Dropdown.Menu>
																					{/* <PermissionAllowed permissions={['Edit User (Button)']}> */}
																					<Dropdown.Item
																						onClick={() => {
																							setShowRoleModal({
																								edit: row
																							});
																						}}
																					>
																						Edit
																					</Dropdown.Item>
																					{/* </PermissionAllowed> */}
																					{row?.total_users <= 0 && (
																						<PermissionAllowed
																							permissions={['Delete Role (Button)']}
																						>
																							<Dropdown.Item
																								onClick={() => deleteroleData(row)}
																							>
																								Delete
																							</Dropdown.Item>
																						</PermissionAllowed>
																					)}
																				</Dropdown.Menu>
																			</Dropdown>
																		</PermissionAllowed>
																	</td>
																</tr>
															);
														})}
														{roleList?.length < 1 && (
															<tr className="no-hover">
																<td colSpan={7}>
																	<div className="not-found-container">
																		<div className="not-found-icon">
																			<TbDatabaseExclamation />
																		</div>
																		<h6 className="not-found-text">No Data Available</h6>
																	</div>
																</td>
															</tr>
														)}
													</>
												)}
											</tbody>
										</table>
									</Scroller>
								</div>
								<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsloading(e)} />
							</div>

							{rolePermissions && (
								<div className="estimate-detail-container">
									<div className="row m-0 p-2">
										<div className="col-12">
											<div className="d-flex justify-content-between align-items-center">
												<h6 className="est-title">Permissions - {rolePermissions?.role?.name}</h6>
												<LiaTimesSolid style={{ cursor: 'pointer' }} onClick={() => setRolePermissions(null)} />
											</div>
											<UserPermissions onClose={() => setRolePermissions(null)} data={rolePermissions} rolePerms />
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			{showRoleModal && (
				<RoleFormModal
					show={showRoleModal}
					handleClose={() => {
						setShowRoleModal(false);
					}}
					onSubmit={handleRoleFormSubmit}
				/>
			)}

			{show && (
				<DeleteConfirmation
					show={show}
					handleClose={handleClose}
					handleDelete={handleDelete}
					title="Confirm"
					message={`Are you sure you want to delete Role`}
				/>
			)}
		</>
	);
};

export default RolesList;

function CustomToggle({ children, onClick }) {
	return (
		<>
			<a
				className="dropdown-toggle"
				href="#"
				role="button"
				id="dropdownMenuLink"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				onClick={onClick}
			>
				{children}
			</a>
		</>
	);
}
