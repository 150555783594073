import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import useSound from 'use-sound';
import errorSound from '../../../../assets/audio/error.mp3';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiPackageBold, PiTestTubeFill, PiWarningFill } from 'react-icons/pi';
import { TbDatabaseExclamation } from 'react-icons/tb';

const WarrantyCheckerModal = ({ openPopup, handleClose, disableStatus }) => {
	const [alertMessage, setAlertMessage] = useState();
	const [invoiceList, setInvoiceList] = useState([]);
	const [imeiReplacementInputValue, setImeiReplacementInputValue] = useState('');
	const [modelImeis, setModelImeis] = useState([]);
	const [stockImeis, setStockImeis] = useState([]);
	const [standardRepairTypes, setStandardRepairTypes] = useState([]);
	const imeiInputRef = useRef(null);
	const [errorSoundPlay] = useSound(errorSound);
	const [selectedRepairs, setSelectedRepairs] = useState('');
	const [confirmDisabled, setConfirmDisabled] = useState(true);

	const [selectedInboundItems, setSelectedInboundItems] = useState([]);

	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const returnReasons = [
		{ label: 'Wrong product', value: 1 },
		{ label: 'Defective', value: 2 },
		{ label: 'Unknown', value: 3 },
		{ label: 'Wrong size', value: 4 }
	];

	const [formInfo, setFormInfo] = useState({
		estimationId: ''
	});

	const handleIMEIInputKeydown = (event) => {
		if (event.key === 'Enter') {
			addModalImei();
		}
	};

	const handleSearchImeiWarranty = () => {
		addModalImei();
	};

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const handleDeleteImei = (index) => {
		const newStockImeis = stockImeis.filter((_, i) => i !== index);
		setStockImeis(newStockImeis);
	};

	const handleSetSelectedRepairs = (selected, index) => {
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, repairs: selected };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
		// console.log(repairs)
	};

	const handleRepairCost = (value, index) => {
		const cost = value.target.value;
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				return { ...item, cost: cost };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
	};

	const addModalImei = async () => {
		if (!imeiReplacementInputValue || !imeiReplacementInputValue.trim()) {
			showErrorDialog('Invalid IMEI', `Invalid IMEI Entered`);
			setImeiReplacementInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}

		const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

		if (!alphanumericRegex.test(imeiReplacementInputValue)) {
			showErrorDialog('Invalid IMEI', `Please enter a valid IMEI Number`);
			setImeiReplacementInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}
		api
			.getReturnsImei({
				imei: imeiReplacementInputValue
			})
			.then((res) => {
				if (res?.status) {
					const tempImei = res?.data;
					tempImei.is_repair = false;
					// setStockImeis([...stockImeis, tempImei]);
					setStockImeis([tempImei]);
					setImeiReplacementInputValue('');
					imeiInputRef.current.value = '';
				} else {
					showErrorDialog('Invalid IMEI', `Couldn't find IMEI ${imeiReplacementInputValue} from the selected order`);
					setImeiReplacementInputValue('');
					imeiInputRef.current.value = '';
					errorSoundPlay();
				}
			});
	};

	const handleSubmitImeis = (action) => {
		// handleReplacementImeis(stockImeis, action);
		// setStockImeis([]);
	};

	const handleReplacementPrice = (value, index) => {
		const price = value.target.value;
		const repairs = stockImeis?.map((item, i) => {
			if (i == index) {
				// return { ...item, product?.price: price };
				return { ...item, product: { ...item.product, price: price } };
			} else {
				return item;
			}
		});
		setStockImeis(repairs);
	};

	// const onSubmit = () => {
	// 	if (formInfo.estimationId == '') {
	// 		setAlertMessage({
	// 			user: 'Please select order.'
	// 		});
	// 	} else {
	// 		const data = {
	// 			...formInfo,
	// 			estimationId: formInfo.estimationId
	// 		};
	// 		handleSave(data);
	// 	}
	// 	setTimeout(() => {
	// 		setAlertMessage('');
	// 	}, 1500);
	// };
	const disablepopup = () => {
		handleClose();
	};
	return (
		<>
			<Modal show={openPopup} onHide={disablepopup} centered size="md" backdrop="static">
				<Modal.Body>
					<div className="returns-popup-body">
						<div className="foundListPopup">
							<div className="mb-2 ml-0 pl-0">
								<div className="row">
									<div className="col-12 text-center">
										<svg xmlns="http://www.w3.org/2000/svg" width="59.992" height="61.271" viewBox="0 0 59.992 61.271">
											<path
												id="features-alt"
												d="M55.261,14.148l-8.9-8.9A17.746,17.746,0,0,0,33.727.015H22.2A12.766,12.766,0,0,0,9.852,9.543a2.552,2.552,0,1,0,4.937,1.292A7.664,7.664,0,0,1,22.2,5.121H33.727c.421,0,.825.082,1.238.12V17.886a7.668,7.668,0,0,0,7.659,7.659h12.64c.041.414.125.822.125,1.238V48.521A7.668,7.668,0,0,1,47.73,56.18H22.2A7.593,7.593,0,0,1,17.413,54.5a2.553,2.553,0,1,0-3.194,3.983,12.8,12.8,0,0,0,7.978,2.8h25.53A12.779,12.779,0,0,0,60.492,48.521V26.783a17.753,17.753,0,0,0-5.234-12.635ZM42.624,20.439a2.556,2.556,0,0,1-2.553-2.553V6.865a12.723,12.723,0,0,1,2.681,1.994l8.9,8.9a12.624,12.624,0,0,1,1.981,2.681H42.621ZM24.753,40.824H45.177a2.553,2.553,0,1,1,0,5.106H24.753a2.553,2.553,0,1,1,0-5.106Zm-7.659,7.268V35.966A10.191,10.191,0,1,0,.5,28.059a10.155,10.155,0,0,0,3.829,7.907V48.092A1.663,1.663,0,0,0,7.314,49.1l3.4-4.45,3.4,4.45A1.663,1.663,0,0,0,17.094,48.092ZM10.712,22.953a5.106,5.106,0,1,1-5.106,5.106A5.111,5.111,0,0,1,10.712,22.953ZM27.306,35.718a2.553,2.553,0,1,1,0-5.106H45.177a2.553,2.553,0,1,1,0,5.106Z"
												transform="translate(-0.5 -0.015)"
											/>
										</svg>
									</div>
								</div>
								<div className="row">
									<div className="col-12 text-center" style={{ margin: '15px 0' }}>
										<h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0 font22">Check your device’s coverage</h5>
									</div>
								</div>
								<button className="btn modal-close-icon" onClick={handleClose}>
									<svg
										id="Component_127_1"
										height="16"
										width="16"
										data-name="Component 127 – 1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 19.295 19.289"
									>
										<path
											id="Icon_ionic-ios-close"
											data-name="Icon ionic-ios-close"
											d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
											transform="translate(-11.285 -11.289)"
										/>
									</svg>
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-12">
									<div className="row">
										<div className="form-group col-md-12">
											<div className="input-icon">
												<input
													ref={imeiInputRef}
													type="text"
													id="input-example"
													name="input-name"
													className="form-control"
													minLength={15}
													maxLength={16}
													placeholder={'Enter device serial number'}
													onChange={(e) => {
														setImeiReplacementInputValue(event.target.value);
														if (event.target.value.length > 0) {
															setConfirmDisabled(false);
														} else {
															setConfirmDisabled(true);
														}
													}}
													onKeyDown={(e) => {
														handleIMEIInputKeydown(e);
													}}
													style={{ padding: '1.375rem .75rem' }}
													autoComplete={'off'}
													autoFocus
												/>
												<div className="icon">
													<svg
														stroke="currentColor"
														fill="currentColor"
														stroke-width="0"
														viewBox="0 0 512 512"
														height="1em"
														width="1em"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill="none"
															stroke-miterlimit="10"
															stroke-width="32"
															d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
														></path>
														<path
															fill="none"
															stroke-linecap="round"
															stroke-miterlimit="10"
															stroke-width="32"
															d="M338.29 338.29L448 448"
														></path>
													</svg>
												</div>
											</div>
										</div>
									</div>
									{stockImeis?.length > 0 && (
										<>
											<div className="row">
												<div className="form-group col-md-12">
													<table className="table ">
														<tbody>
															{stockImeis?.map((item, index) => {
																return (
																	<>
																		<tr>
																			<td className="text-center" width="8%">
																				<img
																					style={{ height: '80px' }}
																					src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwWIVyAdRMA4aS5YN8LJb3JE6OatJn71YZfpZM0ck55rJXBZluV0Fc0BC7QepmxY4RAQY&usqp=CAU"
																				/>
																			</td>
																			<td className="font14 v-align-middle">
																				<div className="row">
																					<div className="col-12">
																						<div className="row">
																							<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																								IMEI
																							</div>
																							<div className="col-9" style={{ fontWeight: '500' }}>
																								{item?.imei}
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																								Model
																							</div>
																							<div className="col-9" style={{ fontWeight: '500' }}>
																								{item?.product?.product_name}
																							</div>
																						</div>
																						<div className="row">
																							<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																								Warranty
																							</div>
																							<div className="col-9" style={{ fontWeight: '500' }}>
																								<span className={item?.warranty?.status == 'Valid' ? 'text-success' : 'text-danger'}>
																									{item?.warranty?.message}
																								</span>
																							</div>
																						</div>
																					</div>
																				</div>
																			</td>
																		</tr>
																	</>
																);
															})}
														</tbody>
													</table>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							{stockImeis?.length < 1 ? (
								<>
									<div className="row">
										<div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'center' }}>
											<button
												disabled={confirmDisabled}
												onClick={() => handleSearchImeiWarranty()}
												className="btn btn-main btn-with-disabled"
												style={{ margin: '10px' }}
											>
												<span style={{ padding: '0 35px' }}>Search</span>
											</button>
										</div>
									</div>
								</>
							) : (
								<>
									<div className="row">
										<div className="col-12 text-center" style={{ margnTop: '15px' }}>
											<button onClick={() => handleClose()} className="btn btn-main btn-with-disabled">
												Back to returns
											</button>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}
		</>
	);
};

export default WarrantyCheckerModal;
