import usePermissions from 'hooks/usePermissions';
import React from 'react';

function PermissionAllowed({ permissions = [], children, hasSome = false, contentOnNotAllowed = <></> }) {
	const [allPermissions, hasEveryPermission, hasSomePermission] = usePermissions();

	return (hasSome && hasSomePermission(permissions)) || (!hasSome && hasEveryPermission(permissions)) ? (
		<>{children}</>
	) : (
		contentOnNotAllowed
	);
}

export default PermissionAllowed;
