import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
	// get Invoice list
	getWarehouseList() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/warehouses`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getWarehouseDetails(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/warehouse/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getWarehouseProductWise(product_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/warehouse/product/${product_id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateWarehouse(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/warehouse/${id}`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	addWarehouse(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/warehouse`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	deleteWarehouse(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/warehouse/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	filterWarehouse(filters, search = null) {
		let url = `${base}/api/warehouses?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getWarehouseFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/warehouses/filter`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	

	getUnassignedWarehouseList(product_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/selections?type=warehouse&product_id=${product_id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
};
