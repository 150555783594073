import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import Logo from 'assets/images/logo.png';
import LogoBlack from 'assets/images/logo_black.png';
import LogoSingle from 'assets/images/pmix-logo-single.svg';
import Logo1 from 'assets/images/project-mix-icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { div } from '@mui/icons-material';
import { TbFileInvoice } from 'react-icons/tb';

import { FaChevronLeft, FaChevronRight, FaRegClock } from 'react-icons/fa6';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Index = ({ clientLogout, mobileNav, setMobileNav, setIsBlur, setBurgerActive }) => {
	const { pathname } = useLocation();
	const [show, setShow] = useState('');
	const clientUser = useSelector((state) => state.main.client);
	const [isOpened, setIsOpened] = useState(true);
	const compRef = useRef(null);

	useEffect(() => {
		setShow('');
	}, [pathname, isOpened]);

	useEffect(() => {
		if (window.innerWidth <= 1150 && isOpened) {
			setIsOpened(false);
		}
	}, []);
	const clientLogoutside = () => {
		clientLogout();
	};

	console.log(mobileNav);

	const handleResize = () => {
		if (window.innerWidth <= 1150 && isOpened) {
			setIsOpened(false);
		} else if (window.innerWidth > 1150) {
			setIsOpened(true);
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (compRef.current && !compRef.current.contains(event.target)) {
			setShow('');
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return (
		<>
			{show && <div className="nav-opacity-bg">&nbsp;</div>}

			<div
				className={`new-nav-bar-client navbar-client-mobile ${isOpened || mobileNav ? 'expanded' : 'mobile-collapsed'}`}
				style={{ height: '100%' }}
			>
				<ul className="navbar-nav-items" ref={compRef}>
					<li className={`nav-item ${pathname.includes('/client/orders') ? 'active' : ''}`}>
						<Link
							to={'/client/orders'}
							className={`nav-link ${clientUser?.data?.is_approved !== 2 ? 'disabled-link' : ''}`}
							onClick={() => {
								setMobileNav(false);
								setIsBlur(false);
								setBurgerActive(false);
							}}
						>
							{/* <span className="menuIcon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.996" height="20.162" viewBox="0 0 21.996 20.162">
									<path
										id="rectangle-list"
										d="M17.414,1H4.583A4.588,4.588,0,0,0,0,5.582v11a4.588,4.588,0,0,0,4.583,4.582H17.414A4.588,4.588,0,0,0,22,16.58v-11A4.588,4.588,0,0,0,17.414,1Zm2.75,15.58a2.753,2.753,0,0,1-2.75,2.749H4.583a2.753,2.753,0,0,1-2.75-2.749v-11a2.753,2.753,0,0,1,2.75-2.749H17.414a2.753,2.753,0,0,1,2.75,2.749ZM17.414,6.5a.917.917,0,0,1-.917.916H10.082a.916.916,0,1,1,0-1.833H16.5A.917.917,0,0,1,17.414,6.5ZM7.332,6.5A1.375,1.375,0,1,1,5.957,5.124,1.375,1.375,0,0,1,7.332,6.5Zm10.082,4.582A.917.917,0,0,1,16.5,12H10.082a.916.916,0,1,1,0-1.833H16.5A.917.917,0,0,1,17.414,11.081Zm-10.082,0A1.375,1.375,0,1,1,5.957,9.707,1.375,1.375,0,0,1,7.332,11.081Zm10.082,4.582a.917.917,0,0,1-.917.916H10.082a.916.916,0,1,1,0-1.833H16.5A.917.917,0,0,1,17.414,15.664Zm-10.082,0a1.375,1.375,0,1,1-1.375-1.375A1.375,1.375,0,0,1,7.332,15.664Z"
										transform="translate(0 -1)"
									/>
								</svg>
							</span> */}
							<span style={{ fontSize: 28 }}>Orders</span>
						</Link>
					</li>
					<li className={`nav-item ${pathname.includes('/client/estimates') ? 'active' : ''}`}>
						<Link
							to={'/client/estimates'}
							className={`nav-link ${clientUser?.data?.is_approved !== 2 ? 'disabled-link' : ''}`}
							onClick={() => {
								setMobileNav(false);
								setIsBlur(false);
								setBurgerActive(false);
							}}
						>
							{/* <span className="menuIcon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.986" height="21.985" viewBox="0 0 21.986 21.985">
									<path
										id="to-do"
										d="M15.115,16.489h-.458V4.122A4.127,4.127,0,0,0,10.535,0H4.122A4.127,4.127,0,0,0,0,4.122V18.779a3.209,3.209,0,0,0,3.2,3.206H14.657a2.752,2.752,0,0,0,2.748-2.748v-.458A2.292,2.292,0,0,0,15.115,16.489ZM4.58,18.779a1.374,1.374,0,0,1-2.748,0V4.122a2.292,2.292,0,0,1,2.29-2.29h6.413a2.292,2.292,0,0,1,2.29,2.29V16.489H6.871A2.292,2.292,0,0,0,4.58,18.779Zm10.993.458a.916.916,0,0,1-.916.916H6.1a3.184,3.184,0,0,0,.31-1.374.459.459,0,0,1,.458-.458h8.245a.459.459,0,0,1,.458.458ZM19.238,0a2.752,2.752,0,0,0-2.748,2.748v9.475a4.552,4.552,0,0,0,1.342,3.239l.759.758a.916.916,0,0,0,1.3,0l.759-.758a4.552,4.552,0,0,0,1.342-3.239V2.748A2.752,2.752,0,0,0,19.238,0Zm.916,12.223a2.732,2.732,0,0,1-.805,1.944l-.111.111-.111-.111a2.732,2.732,0,0,1-.805-1.944V2.748a.916.916,0,0,1,1.832,0ZM3.017,6.5a.916.916,0,0,1,1.3-1.3l1.18,1.181a.1.1,0,0,0,.127,0l2-2a.916.916,0,0,1,1.3,1.3l-2,2a1.924,1.924,0,0,1-2.717,0L3.018,6.5Zm3.9,6.677a1.924,1.924,0,0,1-2.717,0L3.018,12a.916.916,0,0,1,1.3-1.3l1.18,1.181a.1.1,0,0,0,.127,0l2-2a.916.916,0,0,1,1.3,1.3l-2,2Z"
									/>
								</svg>
							</span> */}
							<span style={{ fontSize: 28 }}>Estimates</span>
						</Link>
					</li>
					<li className={`nav-item ${pathname.includes('/client/invoices') ? 'active' : ''}`}>
						<Link
							to={'/client/invoices'}
							className={`nav-link ${clientUser?.data?.is_approved !== 2 ? 'disabled-link' : ''}`}
							onClick={() => {
								setMobileNav(false);
								setIsBlur(false);
								setBurgerActive(false);
							}}
						>
							{/* <span className="menuIcon">
								<svg xmlns="http://www.w3.org/2000/svg" width="18.329" height="21.985" viewBox="0 0 18.329 21.985">
									<path
										id="template"
										d="M18.45,5.078,15.261,1.889A6.378,6.378,0,0,0,10.724.01H6.582A4.57,4.57,0,0,0,2,4.583v12.83a4.584,4.584,0,0,0,4.582,4.582h9.164a4.584,4.584,0,0,0,4.582-4.582v-7.8A6.378,6.378,0,0,0,18.45,5.078ZM17.158,6.37a4.658,4.658,0,0,1,.742.962H13.923a.919.919,0,0,1-.916-.916V2.438a4.658,4.658,0,0,1,.962.742ZM18.5,17.413a2.757,2.757,0,0,1-2.749,2.749H6.582a2.757,2.757,0,0,1-2.749-2.749V4.583A2.757,2.757,0,0,1,6.582,1.834h4.133a3.68,3.68,0,0,1,.449.018V6.416a2.757,2.757,0,0,0,2.749,2.749h4.564a3.605,3.605,0,0,1,.018.449ZM14.372,11H7.957a2.292,2.292,0,0,0-2.291,2.291v2.749a2.292,2.292,0,0,0,2.291,2.291h6.415a2.292,2.292,0,0,0,2.291-2.291V13.289A2.292,2.292,0,0,0,14.372,11Zm.458,5.04a.454.454,0,0,1-.458.458H7.957a.454.454,0,0,1-.458-.458V13.289a.454.454,0,0,1,.458-.458h6.415a.454.454,0,0,1,.458.458ZM5.666,8.249a.919.919,0,0,1,.916-.916H8.415a.916.916,0,1,1,0,1.833H6.582A.919.919,0,0,1,5.666,8.249Z"
										transform="translate(-2 -0.01)"
									/>
								</svg>
							</span> */}
							<span style={{ fontSize: 28 }}>Invoices</span>
						</Link>
					</li>
					<li className={`nav-item ${pathname.includes('/client/products') ? 'active' : ''}`}>
						<Link
							to={'/client/products'}
							className={`nav-link ${clientUser?.data?.is_approved !== 2 ? 'disabled-link' : ''}`}
							onClick={() => {
								setMobileNav(false);
								setIsBlur(false);
								setBurgerActive(false);
							}}
						>
							{/* <span className="menuIcon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.994" height="21.991" viewBox="0 0 21.994 21.991">
									<path
										id="box-open-full"
										d="M21.639,9.931,20.064,6.639a.916.916,0,0,0-1.117-.474L10.994,8.819,3.031,6.166a.918.918,0,0,0-1.117.475L.4,9.824A2.773,2.773,0,0,0,1.824,13.86l-.005,2.122a4.579,4.579,0,0,0,3.134,4.351l4,1.334a6.464,6.464,0,0,0,4.058,0l4-1.334a4.576,4.576,0,0,0,3.134-4.342l.006-2.128a2.729,2.729,0,0,0,1.667-1.639,2.682,2.682,0,0,0-.181-2.291Zm-19.628.761L3.219,8.16l6.475,2.157L8.209,13.3a.913.913,0,0,1-1.076.4L2.476,12.147a.936.936,0,0,1-.586-.566,1.006,1.006,0,0,1,.119-.889Zm3.521,7.9a2.747,2.747,0,0,1-1.881-2.61l0-1.512,2.9.967a2.753,2.753,0,0,0,3.262-1.257l.254-.509,0,6.4a4.663,4.663,0,0,1-.533-.141l-4-1.333Zm10.9,0-4,1.334a4.637,4.637,0,0,1-.532.142l0-6.411.292.587a2.732,2.732,0,0,0,2.348,1.338,2.777,2.777,0,0,0,.88-.144l2.9-.965,0,1.513a2.745,2.745,0,0,1-1.881,2.606ZM20.1,11.586a.912.912,0,0,1-.567.551L14.843,13.7a.916.916,0,0,1-1.041-.335l-1.516-3.045L18.76,8.16,20.027,10.8a.91.91,0,0,1,.074.787ZM4.779,5.4a.915.915,0,0,1,0-1.3L8.35.537a1.836,1.836,0,0,1,2.592,0L12.4,2a1.885,1.885,0,0,1,2.157.316l2.067,2.067a.916.916,0,0,1-1.3,1.3L13.266,3.612,10.378,6.5a.916.916,0,0,1-1.3-1.3l1.968-1.968-1.4-1.4L6.076,5.4a.916.916,0,0,1-1.3,0Z"
										transform="translate(0 -0.002)"
									/>
								</svg>
							</span> */}
							<span style={{ fontSize: 28 }}>Products</span>
						</Link>
					</li>
					<li className={`nav-item ${pathname.includes('/client/returns') ? 'active' : ''}`}>
						<Link
							to={'/client/returns'}
							className={`nav-link ${clientUser?.data?.is_approved !== 2 ? 'disabled-link' : ''}`}
							onClick={() => {
								setMobileNav(false);
								setIsBlur(false);
								setBurgerActive(false);
							}}
						>
							{/* <span className="menuIcon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.996" height="21.995" viewBox="0 0 21.996 21.995">
									<path
										id="restock_1_"
										data-name="restock (1)"
										d="M22,11A10.994,10.994,0,0,1,1.837,17.074l0,1.256A.917.917,0,0,1,0,18.33V15.588a1.843,1.843,0,0,1,1.84-1.841H4.583a.916.916,0,1,1,0,1.833h-1.5A9.152,9.152,0,0,0,20.163,11,.917.917,0,0,1,22,11ZM21.08,2.749a.916.916,0,0,0-.917.916l0,1.242A11,11,0,0,0,0,11a.917.917,0,0,0,1.833,0A9.168,9.168,0,0,1,18.94,6.415H17.414a.916.916,0,1,0,0,1.833h2.75A1.832,1.832,0,0,0,22,6.415V3.666A.916.916,0,0,0,21.08,2.749ZM9.165,15.58a2.753,2.753,0,0,1-2.75-2.749V9.165a2.753,2.753,0,0,1,2.75-2.749h3.666a2.753,2.753,0,0,1,2.75,2.749v3.666a2.753,2.753,0,0,1-2.75,2.749Zm0-1.833h3.666a.917.917,0,0,0,.917-.916V9.165a.917.917,0,0,0-.917-.916H9.165a.917.917,0,0,0-.917.916v3.666A.917.917,0,0,0,9.165,13.747ZM10.54,11h.917a.916.916,0,1,0,0-1.833H10.54a.916.916,0,1,0,0,1.833Z"
									/>
								</svg>
							</span> */}
							<span style={{ fontSize: 28 }}> Returns</span>
						</Link>
					</li>
					<li className={`nav-item ${pathname.includes('/client/check-warranty') ? 'active' : ''}`}>
						<Link
							to={'/client/check-warranty'}
							className={`nav-link ${clientUser?.data?.is_approved !== 2 ? 'disabled-link' : ''}`}
							onClick={() => {
								setMobileNav(false);
								setIsBlur(false);
								setBurgerActive(false);
							}}
						>
							{/* <span className="menuIcon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.536" height="21.995" viewBox="0 0 21.536 21.995">
									<path
										id="features-alt"
										d="M20.158,5.088,16.964,1.895A6.37,6.37,0,0,0,12.428.015H8.29a4.583,4.583,0,0,0-4.433,3.42A.916.916,0,1,0,5.629,3.9,2.751,2.751,0,0,1,8.29,1.848h4.138c.151,0,.3.029.444.043V6.43a2.753,2.753,0,0,0,2.749,2.749h4.537c.015.148.045.3.045.444v7.8a2.753,2.753,0,0,1-2.749,2.749H8.29a2.726,2.726,0,0,1-1.718-.6A.916.916,0,1,0,5.425,21,4.6,4.6,0,0,0,8.289,22.01h9.164a4.587,4.587,0,0,0,4.582-4.582v-7.8a6.373,6.373,0,0,0-1.879-4.536ZM15.621,7.347A.917.917,0,0,1,14.7,6.43V2.474a4.567,4.567,0,0,1,.962.716l3.194,3.194a4.532,4.532,0,0,1,.711.962H15.62ZM9.206,14.664h7.332a.916.916,0,1,1,0,1.833H9.206a.916.916,0,1,1,0-1.833ZM6.457,17.274V12.92a3.666,3.666,0,1,0-4.582,0v4.353a.6.6,0,0,0,1.071.362l1.22-1.6,1.22,1.6A.6.6,0,0,0,6.457,17.274ZM4.166,8.249a1.833,1.833,0,1,1-1.833,1.833A1.835,1.835,0,0,1,4.166,8.249Zm5.957,4.582a.916.916,0,1,1,0-1.833h6.415a.916.916,0,0,1,0,1.833Z"
										transform="translate(-0.5 -0.015)"
									/>
								</svg>
							</span> */}
							<span style={{ fontSize: 28 }}>Check warranty</span>
						</Link>
					</li>
					{clientUser?.data?.is_approved != 2 && (
						<li className={`nav-item company-details-link ${pathname.includes('/client') ? 'active' : ''}`}>
							<Link
								to={'/client/company-details'}
								className={`nav-link `}
								onClick={() => {
									setMobileNav(false);
									setIsBlur(false);
									setBurgerActive(false);
								}}
							>
								{/* <span className="menuIcon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.996" height="21.995" viewBox="0 0 21.996 21.995">
										<path
											id="house-building"
											d="M17.414,13.747H16.5a.916.916,0,1,1,0-1.833h.917a.916.916,0,1,1,0,1.833ZM18.33,16.5a.916.916,0,0,0-.917-.916H16.5a.916.916,0,1,0,0,1.833h.917A.916.916,0,0,0,18.33,16.5Zm-3.666-11a.916.916,0,0,0-.917-.916h-.917a.916.916,0,1,0,0,1.833h.917A.916.916,0,0,0,14.664,5.5Zm3.666,0a.916.916,0,0,0-.917-.916H16.5a.916.916,0,1,0,0,1.833h.917A.916.916,0,0,0,18.33,5.5Zm0,3.666a.916.916,0,0,0-.917-.916H16.5a.916.916,0,1,0,0,1.833h.917A.916.916,0,0,0,18.33,9.165ZM22,17.413V4.582A4.588,4.588,0,0,0,17.414,0H12.831A4.588,4.588,0,0,0,8.249,4.582a.917.917,0,0,0,1.833,0,2.753,2.753,0,0,1,2.75-2.749h4.583a2.753,2.753,0,0,1,2.75,2.749V17.413a2.753,2.753,0,0,1-2.75,2.749H16.5A.916.916,0,1,0,16.5,22h.917A4.588,4.588,0,0,0,22,17.413Zm-7.332.458V14.066a4.556,4.556,0,0,0-1.759-3.609L10.156,8.3a4.576,4.576,0,0,0-5.647,0l-2.75,2.152A4.555,4.555,0,0,0,0,14.065V17.87a4.129,4.129,0,0,0,4.124,4.124H10.54a4.129,4.129,0,0,0,4.124-4.124ZM9.027,9.749l2.75,2.152a2.737,2.737,0,0,1,1.055,2.166v3.805a2.293,2.293,0,0,1-2.291,2.291H4.124a2.293,2.293,0,0,1-2.291-2.291V14.066A2.735,2.735,0,0,1,2.888,11.9l2.75-2.151a2.746,2.746,0,0,1,3.389,0ZM9.165,16.5V14.664a.917.917,0,0,0-.917-.916H6.416a.917.917,0,0,0-.917.916V16.5a.917.917,0,0,0,.917.916H8.249A.917.917,0,0,0,9.165,16.5Z"
										/>
									</svg>
								</span> */}
								{isOpened ? (
									<div className={` cmpny-dtls ${clientUser?.data?.is_approved === 2 ? 'cmpny-dtls-vrfied' : ''}`}>
										<span>Company Info</span>

										{clientUser?.data?.is_approved != 2 && (
											<span className="action-required">
												{' '}
												{clientUser?.data?.is_approved === 0
													? 'Action needed'
													: clientUser?.data?.is_approved === 1
													? 'waiting for approval'
													: 'Approved'}
											</span>
										)}
									</div>
								) : (
									<span style={{ fontSize: 28 }}>Company Info</span>
								)}
							</Link>
						</li>
					)}
				</ul>
			</div>
		</>
	);
};

export default Index;
