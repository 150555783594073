import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ViewDetailsIcon, DeleteIcon2 } from 'ui-component/custom-icons/icons';
import Pagination from 'ui-component/Pagination';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import Scroller from 'ui-component/Scroller';
// import NewShipments from './AddShipments';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import moment from 'moment';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton, Checkbox } from '@mui/material';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import usePageTitle from 'hooks/usePageTitle';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import StatusBadge from 'ui-component/StatusBadge';
import GradeModal from './modals/GradeModal';


const SettingsGrades = () => {
    const [setPageTitle] = usePageTitle('Settings');
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);
    const [params, setParams] = useSearchParams();
    const history = useNavigate();
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [showDelete, setShowDelete] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setpagination] = useState([]);
    const dispatch = useDispatch();

    const [searchField, setSearchField] = useState();
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [activeFilters, setActiveFilters] = useState({});
    const [isFiltersloading, setIsFiltersloading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({
        'id': '',
        'name': '',
    });


    const getGradesLists = () => {
        setIsLoading(true);
        api
            .getSettingsGrades()
            .then((res) => {
                setIsLoading(false);
                if (res.status) {
                    console.log('res?.data?.data', res?.data?.data)
                    setGrades(res?.data?.data);
                    setpagination(res?.data);
                } else {
                    setGrades([]);
                }
                isFirstLoad && setIsFirstLoad(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setGrades([]);
                console.error('Failed to fetch returns list:', error);
            });
    };
    const handleSearch = (e) => {
        let text = e.target.value;
        setSearchField(text);

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        let timeout = setTimeout(() => {
            setIsLoading(true);
            if (text.length > 2) {
                api.filterGrades(activeFilters, text).then((res) => {
                    setIsLoading(false);
                    setGrades(res.data.data);
                    setpagination(res.data);
                });
            } else if (text.length > 0 && text.length <= 2) {
                setIsLoading(false);
            } else {
                api.filterGrades(activeFilters, null).then((res) => {
                    setIsLoading(false);
                    setGrades(res.data.data);
                    setpagination(res.data);
                });
            }
        }, 1000);

        setSearchTimeout(timeout);
    };

    useEffect(() => {
        !isFirstLoad && handleFilter();
    }, [activeFilters]);

    const handleFilter = (activeF = null, searchF = null) => {
        setIsLoading(true);
        api.filterGrades(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
            setIsLoading(false);
            setGrades(res.data.data);
            setpagination(res.data);
        });
    };



    const handleClose = () => { 
        setShowModal(false)
        setShowDelete(null);
    }
    const handleReloadList = () => { 
        setShowModal(false)
        setSelectedGrade({
            ...selectedGrade,
            id: '',
            name: '',
        })
        getGradesLists();
    }

    const handleData = (data) => {
        setGrades(data.data);
        setpagination(data);
    };

    useEffect(() => {
        getGradesLists();
    }, []);
    
    const handleCreateGrade = () => {

        setShowModal(true);
        setSelectedGrade({
            ...selectedGrade,
            id: '',
            name: '',
        })
    }

    const handleDelete = (id) => {
        toast.promise(
            api.settingsDeleteGrade(showDelete?.id).then((res) => {
                if (res.status) {
                    handleClose();
                    getGradesLists()
                }
                return res?.status ? res : abc;
            }),
            {
                loading: 'Deleting grade...',
                success: (data) => `${data.message}`,
                error: <b>Failed to delete grade</b>
            }
        );
    };

    const deleteGrade = (data) => {
        setShowDelete(data);
    };


    useEffect(() => {
        if (showModal) {
          window.addEventListener('keydown', handleKeyDown);
        } else {
          window.removeEventListener('keydown', handleKeyDown);
        }
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [showModal]);

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          setShowModal(false);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12 offset-lg-3 offset-md-3">
                    <div className="section-head-new" style={{ margin:'10px 20px 0px 1px'}}>

                        <div className="actions">
                            <PermissionAllowed permissions={['List Purchase Order']}>
                                <button
                                    className="bulk-action btn-primary btn-outlined-main-primary  white-var"
                                    onClick={() => {
                                        handleCreateGrade();
                                    }}
                                >
                                    <span className="menuIcon">
                                        <FaPlus style={{ fontSize: '13px', marginTop: '-3px' }} />
                                    </span>
                                    <span>New</span>
                                </button>
                            </PermissionAllowed>
                        </div>
                    </div>
                    <div className="card shadow-sm bg-white mb-4">
                        <div className="d-flex align-items-center justify-content-between TableSerachBar">
                            <div>
                                <div className="actions">
                                    <div style={{ margin: '0 5px' }}>
                                        <h5>Grades</h5>
                                    </div>
                                </div>
                            </div>
                            <form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control bg-white border"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="basic-addon2"
                                        onChange={handleSearch}
                                        style={{ width: '100%' }}
                                    />
                                    <span className="searchIcon">
                                        <FaSearch />
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="table-responsive-xl tableData customersList p-2">
                                <table className="table pm-table">
                                    <thead>
                                        <tr className="sticky-header">
                                            <th scope="col" className="font500" colSpan="2">Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <>
                                                {[...Array(10)].map((e, i) => (
                                                    <tr>
                                                        {[...Array(1)].map((e, i) => (
                                                            <td>
                                                                <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {grades?.map((row, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index} className="hover-row">
                                                                <td
                                                                    className="align-middle cursor-pointer"
                                                                    onClick={() => {
                                                                        setShowModal(true);
                                                                        setSelectedGrade({
                                                                            ...selectedGrade,
                                                                            id: row.id,
                                                                            name: row.name,
                                                                        })
                                                                    }}
                                                                >
                                                                    {row?.name}
                                                                </td>


                                                                <td className="hover-button-container">
                                                                    <div>
                                                                        <div className="hover-button">
                                                                            
                                                                            {/* <PermissionAllowed permissions={['View Operations Details']}>
                                                                                <div
                                                                                    className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
                                                                                    style={{ marginLeft: '8px' }}
                                                                                    onClick={() => {
                                                                                            viewData(row.id);
                                                                                    }}
                                                                                >
                                                                                    <ViewDetailsIcon height={'16'} width={'16'} /> 
                                                                                </div>
                                                                            </PermissionAllowed> */}

                                                                            <PermissionAllowed permissions={['Delete Operations']}>
                                                                                <div
                                                                                    className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
                                                                                    style={{ marginLeft: '8px' }}
                                                                                    onClick={() => {
                                                                                        deleteGrade(row);
                                                                                    }}
                                                                                >
                                                                                    <DeleteIcon2 height={'20'} width={'20'} /> 
                                                                                </div>
                                                                            </PermissionAllowed>
                                                                        </div>
                                                                    </div>
                                                                </td> 
                                                            </tr>
                                                        </>
                                                    );
                                                })}
                                                {
                                                    grades?.length < 1 && (
                                                        <tr className="no-hover">
                                                            <td className="whitebg">
                                                                <div className="not-found-container">
                                                                    <div className="not-found-icon">
                                                                        <img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
                                                                    </div>
                                                                    <h6 className="not-found-text">No Data Available</h6>
                                                                        <div
                                                                            className="cursor-pointer btn btn-primary btn-outlined-main"
                                                                            onClick={() => { 
                                                                                handleCreateGrade();
                                                                            }}
                                                                        >
                                                                            Create Grade
                                                                        </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )}
                                    </tbody>
                                </table>
                        </div>
                        <Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
                        <div style={{ marginBottom: '8px'}}></div>
                    </div>
                </div>
            </div>
        

            {showDelete && (
                <DeleteConfirmation
                    show={showDelete}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    title="Confirm"
                    message={`Are you sure you want to delete ${showDelete?.name}?`}
                    customDeleteButtonText={'Yes, Delete this grade'}
                />
            )}


                {showModal && (
					<GradeModal
						openPopup={showModal}
						details={selectedGrade}
						handleClose={handleClose}
						handleReloadList={handleReloadList}
					/>
				)}

        </>
    );
};

export default SettingsGrades;
