import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
	// Add Customer
	getTransitCompany() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-companies`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	updateTransitCompanyStatus(id, status) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/transit-companies/update-status/${id}`,
					{ status: status ? 'activate' : 'suspend' },
					{
						headers: {
							Authorization: `Bearer ${
								JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getTransitCompanyFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-companies/filter/get`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// add Customer
	addTransitCompany(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/transit-companies`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	// search Transit
	searchTransitCompany(query, status) {
		return this.handelAlert((res, rej, loadObj) => {
			const url = status ? `${base}/api/transit-companies?${query}&status=${status}` : `${base}/api/transit-companies?${query}`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	searchTransitCompanyBytext(query) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-companies?search=${query}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	filterTransitCompany(filters, search = null) {
		let url = `${base}/api/transit-companies?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					if (rej?.response?.status == 401) {
						localStorage.removeItem('user');
						sessionStorage.removeItem('user');
						window.location.replace('/login');
					}
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getTransitCompanybyId(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-companies/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteTransitCompany(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/transit-companies/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateTransitCompany(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/transit-companies/${id}`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	selectedTransitCompany() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-companies?type=all&status=1`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
};
