import React, { useEffect, useState } from 'react';
import usePermissions from 'hooks/usePermissions';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Scroller from 'ui-component/Scroller';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { TbDatabaseExclamation } from 'react-icons/tb';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import StatusBadge from 'ui-component/StatusBadge';
import Pagination from 'ui-component/Pagination';

const ProductOrderHistoryTab = ({
    productDetails,
}) => {

    const history = useNavigate();
    const [productOrderHistory, setProductOrderHistory] = useState([]);
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [pagination, setpagination] = useState([]);

    const getProductOrderHistory = (id) => {
        api.getProductOrderHistory(id).then((res) => {
            // if (res.status) {
            //     setProductOrderHistory(res?.data)
            // }
            if (res.status) {
                setProductOrderHistory(res.data.data);
                setpagination(res?.data);
            } else {
                setProductOrderHistory([]);
            }
        })
    };

    const handleData = (data) => {
        setProductOrderHistory(data.data);
        setpagination(data);
    };

    useEffect(() => {
        getProductOrderHistory(productDetails?.id);
    }, []);
    return (
        <>
            <div className="content">
                <div className="row">
                    <div className="col-12 ">

                        {productOrderHistory?.length > 0 ? (
                            <>

                                <div className="table-responsive-xl tableData customersList p-2">
                                    <Scroller>
                                        <table className="table pm-table">
                                            <thead>
                                                <tr className='sticky-header '>
                                                    <th scope="col" style={{ width: '110px' }}>Reference</th>
                                                    <th scope="col" width="">Customer</th>
                                                    <th scope="col" style={{ width: '90px' }}>Amount</th>
                                                    <th scope="col" >Description</th>
                                                    <th scope="col" style={{ width: '115px' }}>Status</th>
                                                    <th scope="col" style={{ width: '105px' }}>Ordered At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productOrderHistory?.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="align-middle">
                                                                <a onClick={() => {
                                                                    if (hasEveryPermission(['View Estimation (Button)'])) {
                                                                        history(`/sales/invoices/${row?.estimation?.id}`);
                                                                    }
                                                                }}
                                                                    target="_blank"
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="link-list-item"
                                                                >
                                                                    {row?.estimation?.invoice_number}
                                                                </a>
                                                            </td>
                                                            <td className="align-middle">
                                                                <a onClick={() => {
                                                                    if (hasEveryPermission(['View Contact (Button)'])) {
                                                                        history(`/contacts/${row?.customer?.id}`);
                                                                    }
                                                                }}
                                                                    target="_blank"
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="link-list-item"
                                                                >
                                                                    <PrivacyModeContent>{row?.customer?.name}</PrivacyModeContent>
                                                                </a>
                                                            </td>
                                                            <td className="">
                                                                {row?.estimation?.qty}
                                                            </td>
                                                            <td className="font14">
                                                                {row?.description}
                                                            </td>
                                                            <td className="font14">
                                                                <StatusBadge itemStatus={row?.status?.name} />
                                                            </td>
                                                            <td className="">
                                                                {moment(row?.created_at).format(CommonDateOnlyFormat)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Scroller>
                                </div>
                                <Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
                            </>
                        ) : (
                            <>

                                <div className="not-found-container">
                                    <div className="not-found-icon">
                                        <TbDatabaseExclamation />
                                    </div>
                                    <h6 className="not-found-text">No Data Available</h6>
                                </div>
                            </>
                        )}



                    </div>

                </div>
            </div>
        </>
    );
};

export default ProductOrderHistoryTab;
