import React, { useEffect, useRef, useState } from 'react';

const StatusBadge = ({ itemStatus }) => {
	let badgeClass = '';
	switch (itemStatus) {
		case 'Draft':
		case 'Concept':
		case 'Paused':
		case 'Sold':
			badgeClass = 'badge-secondary';
			break;
		case 'Closed':
			badgeClass = 'badge-black';
			break;
		case 'Estimated':
		case 'Invoiced':
		case 'Sourced':
		case 'Inspected':
		case 'Ready for Testing':
		case 'Ready for Inbounding':
		case 'Ready for Packing':
		case 'Ready To Ship':
		case 'Ready for Repair':
		case 'Processing':
		// case 'Purchased':
		// 	badgeClass = 'badge-info';
		// 	break;
		case 'Canceled':
			badgeClass = 'badge-dark-gray';
			break;
		case 'Pending Manager':
			badgeClass = 'badge-warning disabled';
			break;
		case 'Pending Customer':
		case 'Pending':
		case 'In Repair':
		case 'Sourcing':
		case 'Repairing':
			badgeClass = 'badge-warning';
			break;
		case 'Credit Note':
			badgeClass = 'badge-orange';
			break;
		case 'Open':
		case 'Purchased':
		case 'Reserved':
			badgeClass = 'badge-orange';
			break;
		case 'Unprocessed':
			badgeClass = 'badge-orange';
			break;
		case 'Pending':
		case 'Inspection':
		case 'Need Collection':
			badgeClass = 'badge-dark-warning';
			break;
		case 'Completed':
		case 'For Transit':
		case 'Shipped':
		case 'Submitted':
		case 'Paid':
		case 'Approved':
		case 'Accepted':
		case 'Verified':
		case 'Available':
			badgeClass = 'badge-success';
			break;
		case 'For Credit Note':
		case 'Inspection Approval':
		case 'Collected':
		case 'Inbounded':
		case 'Received':
		case 'In Progress':
			badgeClass = 'badge-primary';
			break;
		case 'For Approval':
		case 'Unrepairable':
		case 'Incomplete':
		case 'Unverified':
		case 'Not Registered':
			badgeClass = 'badge-danger';
			break;

		// case 'Concept':
		// 	badgeClass = 'badge-light-orange';
		// 	break;
		default:
			badgeClass = '';
	}

	return (
		<div className={`badge ${badgeClass}`}>
			{itemStatus === 'Credit Note' || itemStatus === 'Unprocessed'
				? 'Open'
				: itemStatus === 'Need Collection'
					? 'For Collection'
					: itemStatus}
		</div>
	);
};

export default StatusBadge;
