import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconOpen from 'assets/images/icons/back-2.svg';
import IconClose from 'assets/images/icons/menu-toggle.png';
import IconProfile from 'assets/images/undraw_profile.svg';
import IconSearch from 'assets/images/magnifying-glass.svg';
import { setDisplayTheme, setLogout, setOpened, storeHasNewNotification, storePrivacyMode } from 'store/reducers/mainSlice';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import Alert from './CustomAlert';
import { Avatar, Drawer } from '@mui/material';
import { GrNotification } from 'react-icons/gr';
import { FaBell, FaSearch, FaEye, FaEyeSlash } from 'react-icons/fa';
import CurrencyDropdown from 'ui-component/currency-dropdown/CurrencyDropdown';
import { useHotkeys } from 'react-hotkeys-hook';
import { MdClose, MdDarkMode, MdOutlineDarkMode, MdOutlineTaskAlt, MdTaskAlt } from 'react-icons/md';
import Logo from 'assets/images/logo-light.svg';
import LogoBlack from 'assets/images/logo-dark.svg';
import Logo1 from 'assets/images/project-mix-icon.png';
import NotificationDropdown from 'ui-component/NotificationDropdown/NotificationDropdown';

import Echo from 'laravel-echo';
import toast from 'react-hot-toast';
import NotificationTextParser from 'ui-component/NotificationTextParser';
import config from '../../../config';
import { notificationTextToPlainText } from 'helpers/common.helper';
import { BiSolidBell } from 'react-icons/bi';
import { ImSearch } from 'react-icons/im';
import { NotificationIcon, PrivacyModeHideIcon, PrivacyModeIcon } from 'ui-component/custom-icons/icons';
import { IoIosArrowDown } from 'react-icons/io';
import { VscBell } from 'react-icons/vsc';
import MaterialUISwitch from './MUISwitch';
import { IoSearchOutline, IoSettingsOutline } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa6';
import QuickActions from 'ui-component/quick-actions/QuickActions';
import ProfileMenuBlock from 'ui-component/ProfileMenuBlock';
import { SiTask } from 'react-icons/si';
import TaskPanel from 'ui-component/TaskPanel';
import TaskSection from 'ui-component/TaskSection';

window.Pusher = require('pusher-js');

// window.Echo = new Echo({
// 	broadcaster: 'pusher',
// 	key: config.pusher.key,
// 	cluster: 'ap2',
// 	forceTLS: true,
// 	logToConsole: true // Add this line for debugging
// });

const NavBar = ({ onSearchToggle }) => {
	const notiRef = useRef(null);
	const profileIconRef = useRef(null);
	const notiBtnRef = useRef(null);
	const [pageTitle] = useSelector((state) => [state.main.pageTitle]);
	const [userName, userEmail] = useSelector((state) => [state.main.user.first_name, state.main.user.email]);
	const [taskPanelOpened, setTaskPanelOpened] = useState(false);
	const [userFName, userLName, userID, hasNewNotification, profilePhoto] = useSelector((state) => [
		state.main?.user?.first_name,
		state.main?.user?.last_name,
		state.main?.user?.id,
		state.main?.hasNewNotification,
		state.main?.user?.profile_photo_url
	]);

	const [showNotifications, setShowNotifications] = useState(false);
	const privacyMode = useSelector((state) => state.main.privacyMode);
	const [userRole] = useSelector((state) => [state.main.user?.role?.name]);
	const validToken = useSelector((state) => state?.main?.user?.token);
	const [displayTheme] = useSelector((state) => [state.main.displayTheme]);
	const history = useNavigate();
	const dispatch = useDispatch();

	const [showProfileMenu, setShowProfileMenu] = useState(false);

	const getAllStatus = () => {
		api.getAllstatus().then((res) => {
			if (res.status) {
				const tempStatus = res.data;
				dispatch(storeInLocalStore({ allstatuses: tempStatus }));
			} else {
				if (res.message == 'Unauthenticated.') {
					localStorage.removeItem('user');
					window.location.reload();
				}
			}
		});
	};

	const handlePrivacyMode = () => {
		dispatch(storePrivacyMode(!privacyMode));
	};
	useEffect(() => {
		if (!('Notification' in window)) {
			console.log('Browser does not support desktop notification');
		} else {
			Notification.requestPermission();
		}
	}, []);

	useEffect(() => {
		api.userHasUnreadNotification().then((res) => {
			if (res.status) {
				dispatch(storeHasNewNotification(res?.data?.has_unread));
			}
		});
	}, []);

	const markAsRead = (date) => {
		api.markNotificationAsRead(date).then((res) => {
			if (res.status) {
				dispatch(storeHasNewNotification(res?.data?.has_unread));
			}
		});
	};

	useEffect(() => {
		const pusher_config = config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.pusher || {};

		const pusher = new Pusher(pusher_config.key, {
			cluster: 'ap2'
		});

		const channel = pusher.subscribe('Projectmix-channel');

		// Bind to 'YourEvent' on the channel
		channel.bind(`notification-event-${userID}`, function (data) {
			// toast(
			// 	(t) => (
			// 		<div className="notification">
			// 			<div className="notification-body">
			// 				<div className="notification-head d-flex align-content-center justify-content-between">
			// 					<p>Just Now</p>{' '}
			// 					<span
			// 						onClick={() => {
			// 							toast.dismiss(t.id);
			// 							markAsRead(data?.message?.date)
			// 						}}
			// 						style={{ cursor: 'pointer' }}
			// 					>
			// 						<MdClose />
			// 					</span>
			// 				</div>
			// 				<div className="notification-content">
			// 					<p>
			// 						<NotificationTextParser inputString={data?.message?.details} />
			// 					</p>
			// 				</div>
			// 			</div>
			// 		</div>
			// 	),
			// 	{
			// 		duration: '1000000'
			// 	}
			// );

			const pushNoti = new Notification('Project Mix', {
				body: notificationTextToPlainText(data?.message?.details),
				icon: 'http://localhost:3000/img/logo-icon.png'
			});
			pushNoti.close();

			dispatch(storeHasNewNotification(true));
		});

		return () => {
			channel.unsubscribe();
		};
	}, []);

	const handleClickOutside = (event) => {
		if (notiRef.current && !notiRef.current.contains(event.target) && notiBtnRef.current && !notiBtnRef.current.contains(event.target)) {
			setShowNotifications(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const handleSearchToggle = () => {
		onSearchToggle && onSearchToggle();
	};

	const handleThemeToggle = () => {
		if (displayTheme == 'darkMode') {
			dispatch(setDisplayTheme('lightMode'));
		} else {
			dispatch(setDisplayTheme('darkMode'));
		}
	};

	useEffect(() => {
		// if (!validToken) {
		// 	history('/');
		// }
		document.title = 'Projectmix';
		// if (isOpend) {
		// 	document.body.classList.remove('sidebar-toggled')
		// } else {
		// 	document.body.classList.add('sidebar-toggled')
		// }
	}, []);
	useEffect(() => {
		// if (validToken) {
		// 	getAllStatus();
		// }
	}, []);

	return (
		<nav id="mainNav" className="navbar navbar-expand navbar-light topbar m-0 static-top d-flex justify-content-between">
			<div id="nav-page-title" className="m-0">
				{!!pageTitle && (
					<>
						{pageTitle == 'dashboard' ? (
							<>
								<h5>Welcome back, {userName}</h5>
							</>
						) : (
							<h5 className="ml23">{pageTitle}</h5>
						)}
					</>
				)}
			</div>

			<div className="nav-search">
				<div className="nav-search-input">
					<input type="text" placeholder="Search" />
					<span className="nav-search-input-icon">
						<IoSearchOutline />
					</span>
				</div>
			</div>

			<div className="nav-actions">
				{/* <div className="" onClick={() => handlePrivacyMode()}>
					{privacyMode ? <PrivacyModeHideIcon width={18} height={18} /> : <PrivacyModeIcon width={18} height={18} />}
				</div> */}
				<div>
					<TaskSection taskPanelOpened={taskPanelOpened} setTaskPanelOpened={setTaskPanelOpened} />
				</div>
				<div>
					<a
						className="nav-link dropdown-toggle"
						href="#"
						id="alertsDropdown"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						onClick={() => setShowNotifications(!showNotifications)}
						ref={notiBtnRef}
					>
						<NotificationIcon width={15} height={15} />

						{hasNewNotification && <span className="badge badge-danger badge-counter noti-badge">&nbsp;</span>}
					</a>
					<div className="notification-box" ref={notiRef}>
						{showNotifications && (
							<NotificationDropdown
								closeNotificationBox={() => {
									setShowNotifications(false);
								}}
							/>
						)}
					</div>
				</div>
				<div>
					<QuickActions isOpened={true} />
				</div>
				<div style={{ position: 'relative' }}>
					{/* <Dropdown style={{ padding: 0 }}>
						<Dropdown.Toggle as={CustomToggle} style={{ padding: 0 }}> */}
					<div
						className="user-icon-item"
						onClick={() => {
							setShowProfileMenu(!showProfileMenu);
						}}
						ref={profileIconRef}
					>
						<Avatar
							sx={{ width: 25, height: 25 }}
							style={{ fontSize: 8 }}
							src={profilePhoto ? profilePhoto : 'https://cdn.iconscout.com/icon/free/png-256/free-avatar-380-456332.png'}
						>
							{userFName ? userFName?.[0] : ''}
							{userLName ? userLName?.[0] : ''}
						</Avatar>
						{/* <span>{userName}</span> */}
						<IoIosArrowDown />
					</div>
					{showProfileMenu && <ProfileMenuBlock mainIconRef={profileIconRef} onCloseMenu={() => setShowProfileMenu(false)} />}

					{/* </Dropdown.Toggle>

						<Dropdown.Menu className="dropdown-menu dropdown-menu-right shadow animated--grow-in">
							<Dropdown.Item className="dropdown-item" href="#" onClick={() => history('user/profile')}>
								<i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" /> Profile
							</Dropdown.Item>
							<div className="dropdown-divider" />
							<Dropdown.Item className="dropdown-item" href="#" onClick={() => dispatch(setLogout(true))}>
								<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" /> Logout
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown> */}
				</div>
			</div>

			{/* <ul className="navbar-nav">

				<li className="item dropdown no-arrow search-icon" onClick={handleSearchToggle} style={{ marginRight: '3px' }}>
					<ImSearch style={{ fontSize: '0.75em' }} />
				</li>

				<li className="nav-item dropdown no-arrow mx-1" style={{ marginRight: '5px' }}>
					<a
						className="nav-link dropdown-toggle"
						href="#"
						id="alertsDropdown"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						onClick={() => setShowNotifications(!showNotifications)}
						ref={notiBtnRef}
					>
						<BiSolidBell style={{ fontSize: '1.3em' }} />
						{hasNewNotification && <span className="badge badge-danger badge-counter">&nbsp;</span>}
					</a>
					<div className="notification-box" ref={notiRef}>
						{showNotifications && (
							<NotificationDropdown
								closeNotificationBox={() => {
									setShowNotifications(false);
								}}
							/>
						)}
					</div>
				</li>

				<li className="item dropdown no-arrow search-icon" style={{ marginRight: '10px' }} onClick={() => handlePrivacyMode()}>
					{privacyMode ? <FaEyeSlash style={{ fontSize: '0.9em' }} /> : <FaEye style={{ fontSize: '0.9em' }} />}
				</li>

				<li className="nav-item dropdown no-arrow ">
					<Dropdown>
						<Dropdown.Toggle as={CustomToggle}>
							<Avatar sx={{ width: 30, height: 30 }} style={{ fontSize: 12 }}>
								{userFName ? userFName?.[0] : ''}
								{userLName ? userLName?.[0] : ''}
							</Avatar>
							<span className="ml-2 d-none d-lg-inline userName pl-1 mr-0">
								{userName}
							</span>
						</Dropdown.Toggle>

						<Dropdown.Menu className="dropdown-menu dropdown-menu-right shadow animated--grow-in">
							<Dropdown.Item className="dropdown-item" href="#" onClick={() => history('user/profile')}>
								<i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" /> Profile
							</Dropdown.Item>
							<div className="dropdown-divider" />
							<Dropdown.Item className="dropdown-item" href="#" onClick={() => dispatch(setLogout(true))}>
								<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" /> Logout
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</li>
			</ul> */}
		</nav>
	);
};

export default NavBar;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
	return (
		<>
			<Link
				className="nav-link dropdown-toggle "
				to="#"
				id="userDropdown"
				role="button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				onClick={onClick}
			>
				{children}
			</Link>
		</>
	);
});
