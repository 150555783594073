import React from 'react'

export const DashboardIcon = () => {
  return (
	<>
   <svg xmlns="http://www.w3.org/2000/svg" id="ic_Dashboard" width="20" height="20" viewBox="0 0 20 20">
  <path id="Shape" d="M13,20H1a1,1,0,0,1-1-1V6.5a2,2,0,0,1,2-2H6V2A2,2,0,0,1,8,0h4a2,2,0,0,1,2,2V9h4a2,2,0,0,1,2,2v8a1,1,0,0,1-1,1Zm5-2V11H14v7ZM8,18h4V11q0-.03,0-.059V2H8ZM2,18H6V6.5H2Z" fill="#59626f"/>
  </svg>
  </>
  )
}

export const Outbound = () => {
  return (
	<>
   <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="20" viewBox="0 0 22.5 20">
    <path id="Icon_awesome-boxes" data-name="Icon awesome-boxes" d="M21.875,11.25H18.75V15l-1.25-.832L16.25,15V11.25H13.125a.627.627,0,0,0-.625.625v7.5a.627.627,0,0,0,.625.625h8.75a.627.627,0,0,0,.625-.625v-7.5A.627.627,0,0,0,21.875,11.25Zm-15-2.5h8.75a.627.627,0,0,0,.625-.625V.625A.627.627,0,0,0,15.625,0H12.5V3.75l-1.25-.832L10,3.75V0H6.875A.627.627,0,0,0,6.25.625v7.5A.627.627,0,0,0,6.875,8.75Zm2.5,2.5H6.25V15L5,14.168,3.75,15V11.25H.625A.627.627,0,0,0,0,11.875v7.5A.627.627,0,0,0,.625,20h8.75A.627.627,0,0,0,10,19.375v-7.5A.627.627,0,0,0,9.375,11.25Z" fill="#59626f"/>
  </svg>
  </>
  )
}

export const PurchaseIcon = () => {
  return (
	<>
  <svg xmlns="http://www.w3.org/2000/svg" id="Icon_feather-shopping-cart" data-name="Icon feather-shopping-cart" width="20" height="19.167" viewBox="0 0 20 19.167">
  <path id="Path_32" data-name="Path 32" d="M8.333,17.5a.833.833,0,1,1-.833-.833A.833.833,0,0,1,8.333,17.5Z" fill="none" stroke="#59626f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.667"/>
  <path id="Path_33" data-name="Path 33" d="M17.5,17.5a.833.833,0,1,1-.833-.833A.833.833,0,0,1,17.5,17.5Z" fill="none" stroke="#59626f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.667"/>
  <path id="Path_34" data-name="Path 34" d="M.833.833H4.167L6.4,11.992a1.667,1.667,0,0,0,1.667,1.342h8.1a1.667,1.667,0,0,0,1.667-1.342L19.167,5H5" fill="none" stroke="#59626f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.667"/>
  </svg>
  </>
  )
}

export const ProductIcon = () => {
  return (
	<>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="20" viewBox="0 0 22.5 20">
  <path id="Icon_awesome-boxes" data-name="Icon awesome-boxes" d="M21.875,11.25H18.75V15l-1.25-.832L16.25,15V11.25H13.125a.627.627,0,0,0-.625.625v7.5a.627.627,0,0,0,.625.625h8.75a.627.627,0,0,0,.625-.625v-7.5A.627.627,0,0,0,21.875,11.25Zm-15-2.5h8.75a.627.627,0,0,0,.625-.625V.625A.627.627,0,0,0,15.625,0H12.5V3.75l-1.25-.832L10,3.75V0H6.875A.627.627,0,0,0,6.25.625v7.5A.627.627,0,0,0,6.875,8.75Zm2.5,2.5H6.25V15L5,14.168,3.75,15V11.25H.625A.627.627,0,0,0,0,11.875v7.5A.627.627,0,0,0,.625,20h8.75A.627.627,0,0,0,10,19.375v-7.5A.627.627,0,0,0,9.375,11.25Z" fill="#59626f"/>
</svg>
  </>
  )
}

export const PlusIcon = () => {
  return (
	<>
  <svg xmlns="http://www.w3.org/2000/svg" width="13.563" height="13.563" viewBox="0 0 13.563 13.563">
  <path id="Icon_awesome-plus-circle" data-name="Icon awesome-plus-circle" d="M7,.219A6.781,6.781,0,1,0,13.781,7,6.78,6.78,0,0,0,7,.219Zm3.938,7.547a.329.329,0,0,1-.328.328H8.094v2.516a.329.329,0,0,1-.328.328H6.234a.329.329,0,0,1-.328-.328V8.094H3.391a.329.329,0,0,1-.328-.328V6.234a.329.329,0,0,1,.328-.328H5.906V3.391a.329.329,0,0,1,.328-.328H7.766a.329.329,0,0,1,.328.328V5.906h2.516a.329.329,0,0,1,.328.328Z" transform="translate(-0.219 -0.219)" fill="#fff"/>
</svg>
  </>
  )
}

export const MinusIcon = () => {
  return (
	<>
  <svg xmlns="http://www.w3.org/2000/svg" width="13.563" height="13.563" viewBox="0 0 13.563 13.563">
  <path id="Icon_awesome-minus-circle" data-name="Icon awesome-minus-circle" d="M7,.219A6.781,6.781,0,1,0,13.781,7,6.78,6.78,0,0,0,7,.219ZM3.391,8.094a.329.329,0,0,1-.328-.328V6.234a.329.329,0,0,1,.328-.328h7.219a.329.329,0,0,1,.328.328V7.766a.329.329,0,0,1-.328.328Z" transform="translate(-0.219 -0.219)" fill="#fff"/>
</svg>
  </>
  )
}

export const SwitchNextIcon = () => {
  return (
	<>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.025" height="10.827" viewBox="0 0 22.025 10.827">
  <path id="Icon_awesome-long-arrow-alt-right" data-name="Icon awesome-long-arrow-alt-right" d="M15.435,7.436H.59a.59.59,0,0,0-.59.59v2.753a.59.59,0,0,0,.59.59H15.435v2.264a1.18,1.18,0,0,0,2.014.834l4.231-4.231a1.18,1.18,0,0,0,0-1.669L17.449,4.337a1.18,1.18,0,0,0-2.014.834Z" transform="translate(0 -3.989)" fill="#59626F"/>
</svg>
  </>
  )
}

export const SwitchprvIcon = () => {
  return (
	<>
<svg xmlns="http://www.w3.org/2000/svg" width="22.025" height="10.826" viewBox="0 0 22.025 10.826">
  <path id="Icon_awesome-long-arrow-alt-left" data-name="Icon awesome-long-arrow-alt-left" d="M6.591,11.369H21.435a.59.59,0,0,0,.59-.59V8.026a.59.59,0,0,0-.59-.59H6.591V5.172a1.18,1.18,0,0,0-2.014-.834L.346,8.568a1.18,1.18,0,0,0,0,1.669l4.231,4.231a1.18,1.18,0,0,0,2.014-.834Z" transform="translate(0 -3.989)" fill="#59626F"/>
</svg>
  </>
  )
}

export const ModalIcon = () => {
  return (
	<>
   <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="16.375" viewBox="0 0 16.875 16.375">
  <g id="ic_Dashboard" transform="translate(-0.354 2.25)">
    <g id="Group_160" data-name="Group 160" transform="translate(-17 -4)">
      <g id="Icon_feather-users" data-name="Icon feather-users" transform="translate(-0.833 -2.5)">
        <path id="Icon_awesome-inbox" data-name="Icon awesome-inbox" d="M16.639,7.146,13.542,2.5a1.406,1.406,0,0,0-1.17-.626H4.5a1.406,1.406,0,0,0-1.17.626L.236,7.146A1.406,1.406,0,0,0,0,7.926v3.793a1.406,1.406,0,0,0,1.406,1.406H15.469a1.406,1.406,0,0,0,1.406-1.406V7.926a1.406,1.406,0,0,0-.236-.78ZM4.753,3.75,5.3,1.875h6.17l.651,1.875,2.5,3.75H11.016l-.937,1.875H6.8L5.859,7.5H2.253Z" transform="translate(18.188 7.5)" fill="#59626f"/>
      </g>
      <path id="Icon_material-phone-iphone" data-name="Icon material-phone-iphone" d="M9.688.625h-5A1.563,1.563,0,0,0,3.125,2.188V12.813a1.563,1.563,0,0,0,1.563,1.563h5a1.563,1.563,0,0,0,1.563-1.562V2.188A1.563,1.563,0,0,0,9.688.625Zm.583,12.743H4.049V1.422H10.27Z" transform="translate(18.604 1.125)" fill="#59626f"/>
    </g>
  </g>
</svg>
  </>
  )
}
