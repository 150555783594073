import React from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Area, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Fade } from 'react-awesome-reveal';

const PerformanceCard = ({ performancePermission, chartData, gradientId1, stopColor1 }) => {
	if (!performancePermission || performancePermission.is_visible !== 1) {
		return null;
	}

	return (
		<div className="custom-col-lg-2 col-md-6 col-sm-12" style={{ paddingBottom: '20px' }}>
			<Fade>
				<div className="card" style={{ height: '270px' }}>
					<div className="card-header card-header-custom" style={{ backgroundColor: 'white' }}>
						<h5 style={{ fontWeight: '600' }}>Performance</h5>
					</div>
					<div className="card-body card-body-custom">
						<div className="chart-area" style={{ width: '100%', height: '35%' }}>
							<ResponsiveContainer width="100%" height="100%">
								<AreaChart
									data={chartData}
									margin={{
										top: 0,
										right: 0,
										left: 0,
										bottom: 0
									}}
								>
									<defs>
										<linearGradient id={gradientId1} x1="0" y1="0" x2="0" y2="1">
											<stop offset="0%" stopColor={stopColor1} stopOpacity={1} />
											<stop offset="100%" stopColor={stopColor1} stopOpacity={0} />
										</linearGradient>
									</defs>
									<XAxis dataKey="name" hide={true} />
									<YAxis hide={true} />
									<Tooltip
										content={({ payload }) => {
											if (payload.length > 0) {
												return (
													<div
														style={{
															backgroundColor: '#fff',
															padding: '15px',
															border: '1px solid #ccc',
															borderRadius: '10px',
															display: 'flex',
															flexDirection: 'column'
														}}
													>
														<span style={{ fontWeight: '500', padding: 0 }}>{`${payload[0].payload.name}, 2024`}</span>
														<span>
															<strong>Performance:</strong> {`${payload[0].payload.value.toFixed(2)}`}%
														</span>
													</div>
												);
											}
											return null;
										}}
									/>
									<Area type="monotone" dataKey="value" stroke={stopColor1} fill={`url(#${gradientId1})`} />
								</AreaChart>
							</ResponsiveContainer>
						</div>
						<div className="chart-list-one" style={{ paddingTop: '20px' }}>
							{chartData &&
								[...chartData]
									.reverse()
									.slice(0, 3)
									.map((data, index) => (
										<div className="row" key={index}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													paddingBottom: '5px',
													marginLeft: '10px'
												}}
											>
												<a>{data.name}</a>
											</div>
											<div style={{ flexGrow: 1 }}></div>
											<div
												style={{
													width: '38px',
													height: '38px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													marginRight: '7px'
												}}
											>
												<ResponsiveContainer>
													<PieChart>
														<Pie
															data={[
																{ name: 'Percentage', value: Math.min(data.value, 100) },
																{ name: 'Remaining', value: Math.max(0, 100 - data.value) }
															]}
															cx="50%"
															cy="50%"
															startAngle={225}
															endAngle={-45}
															innerRadius="55%"
															outerRadius="85%"
															fill="#8884d8"
															paddingAngle={0}
															cornerRadius={10}
															dataKey="value"
														>
															<Cell
																key={`cell-${index}-1`}
																fill={data.value <= 30 ? '#ee4747' : data.value <= 70 ? '#ffba49' : '#6dda52'}
															/>
															<Cell key={`cell-${index}-2`} fill="#c5c5c5" />
														</Pie>
													</PieChart>
												</ResponsiveContainer>
											</div>
										</div>
									))}
						</div>
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default PerformanceCard;
