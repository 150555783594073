import React, { useState, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useEffect } from 'react';
import ScrollButton from 'ui-component/ScrollToTopButton';
import GlobalSearch from 'ui-component/global-search/GlobalSearch';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';

import toast, { Toaster } from 'react-hot-toast';
import { setDisplayTheme, storeClientDetails } from 'store/reducers/mainSlice';
import { useDispatch, useSelector } from 'react-redux';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { AiOutlinePoweroff } from 'react-icons/ai';
import '../client.scss';
import Select from 'react-select';
import NavBar from '../NavBar/NavbarLogin';
import Oval1 from 'assets/images/designs/Oval-1.png';
import Oval2 from 'assets/images/designs/Oval.png';

const ForgotPasswordOTP = () => {
	const history = useNavigate();
	const navigate = useNavigate();
	const [time, setTime] = useState(180); // Initial time in seconds
	const { state } = useLocation();
	const { email, token } = state || {};
	const [isClicked, setIsClicked] = useState(false);
	const inputRefs = useRef([]);
	inputRefs.current = [0, 1, 2, 3].map((_, i) => inputRefs.current[i] ?? React.createRef());
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	let validToken = useSelector((state) => state.main?.user?.token);
	const [login, setLogin] = useState(true);
	const [otp, setOtp] = useState([null, null, null, null]);
	const [responseData, setResponseData] = useState(null);
	const [resent, setResent] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		// Only start the countdown if time is not 0
		if (time > 0) {
			// Create a timeout to decrease the time
			const timer = setTimeout(() => {
				setTime(time - 1);
			}, 1000);

			// Clear the timeout when the component is unmounted or time is updated
			return () => clearTimeout(timer);
		}
	}, [time]); // Add time as a dependency to the useEffect hook

	const minutes = Math.floor(time / 60);
	const seconds = time % 60;

	const onSubmit = (event) => {
		let otpData = otp.join('');
		// console.log(otpData);
		// console.log(token);
		let data;

		setIsSubmitting(true);
		if (resent === false) {
			data = {
				token: token,
				otp: otpData
			};
		} else {
			data = {
				token: responseData.token,
				otp: otpData
			};
		}
		toast.promise(api.forgotPasswordOtpClient(data), {
			loading: 'Validating OTP...',
			success: (res) => {
				if (res.status) {
					const token = res.data[0];
					navigate('/client/new-password', { state: { email, token } });
					return 'OTP validated successfully';
				} else {
					setTimeout(() => setEmsg(''), 3000);
					return res.message;
				}
			},
			error: (err) => `Failed to process request: ${err.message}`
		});
	};

	const resendOTP = (event) => {
		const data = {
			email: email
		};

		toast.promise(api.forgotPasswordClient(data), {
			loading: 'Resending OTP',
			success: (res) => {
				if (res.status === true) {
					const { email, token } = res.data;
					setResponseData({ email, token });
					setTime(180);
					setResent(true);
					setOtp(['', '', '', '']); // Clear the OTP fields
					return 'OTP sent successfully';
				} else {
					return res.message;
				}
			},
			error: (err) => `Failed to process request: ${err.message}`
		});
	};

	return (
		<>
			<div id="parent-client-wrapper">
				<div id="wrapper">
					<NavBar />

					<div
						id="new-wrapper"
						style={{
							fontFamily: 'Poppins, sans-serif'
						}}
					>
						<div id="content-wrapper" className="d-flex flex-column" style={{ backgroundColor: 'transparent' }}>
							<div id="content" style={{ position: 'relative' }}>
								<div className="container-fluid d-flex justify-content-center">
									<div style={{ maxWidth: '2200px', width: '100%', height: '90vh' }}>
										<div className={login ? 'main-page-auth-3' : 'main-page-auth-2'}>
											<div className={login ? 'backdrop-image-purple' : 'backdrop-image-purple-sign'}>
												<img src={Oval1} alt="Oval1" style={{ opacity: '50%' }} />
											</div>
											<div className={login ? 'backdrop-image-pink' : 'backdrop-image-pink-sign'}>
												<img src={Oval2} alt="Oval2" style={{ opacity: '70%' }} />
											</div>
											<div className="invoice-client-content">
												<div
													className="form-signup"
													style={{ justifyContent: 'space-between' }}
													onKeyPress={(event) => {
														if (event.key === 'Enter') {
															event.preventDefault();
														}
													}}
												>
													<div className="signup-head" style={{ marginBottom: 15 }}>
														<h4 style={{ marginBottom: 5, fontWeight: 500 }}>Password Reset</h4>
													</div>
													<div className="row" style={{ marginBottom: 10 }}>
														<div className="col-12 d-flex justify-content-center align-items-center">
															<h5 style={{ fontSize: 20 }}>
																{time > 0 ? `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}` : 'OTP expired!'}
															</h5>
														</div>
													</div>
													<div className="row" style={{ marginBottom: 20 }}>
														<div className={isMobile ? 'col-12' : 'col-12'} style={{ display: 'flex', justifyContent: 'space-between' }}>
															{[0, 1, 2, 3].map((_, index) => (
																<input
																	key={index}
																	id={`otp-${index}`}
																	ref={inputRefs.current[index]}
																	type="tel"
																	pattern="[0-9]*"
																	className="form-control "
																	style={{
																		color: 'black',
																		textAlign: 'center',
																		width: '58px',
																		fontSize: 30,
																		borderColor: '#0066FF',
																		marginRight: index < 3 ? '10px' : '0'
																	}}
																	autoComplete="off"
																	maxLength={1}
																	autoFocus={index === 0}
																	onChange={(e) => {
																		const value = e.target.value;
																		if (value !== '' && !Number.isNaN(Number(value))) {
																			setOtp((prevOtp) => {
																				const newOtp = [...prevOtp];
																				newOtp[index] = Number(value);
																				return newOtp;
																			});
																			if (value.length === 1) {
																				document.getElementById(`otp-${index + 1}`)?.focus();
																			}
																		}
																	}}
																	onPaste={(e) => {
																		e.preventDefault();
																		const pastedData = e.clipboardData.getData('text');
																		let newOtp = [...otp]; // Copy the current otp state
																		pastedData.split('').forEach((char, i) => {
																			const input = document.getElementById(`otp-${i}`);
																			if (input) {
																				input.value = char;
																				newOtp[i] = Number(char); // Update the otp state
																			}
																		});
																		setOtp(newOtp); // Set the new otp state
																	}}
																	onKeyDown={(e) => {
																		if (e.key === 'Backspace' && e.target.value === '') {
																			if (index > 0) {
																				document.getElementById(`otp-${index - 1}`)?.focus();
																			}
																		}
																	}}
																/>
															))}
														</div>
													</div>
													<div className="action-signup">
														{time > 0 ? (
															<button
																className="btn btn-cmpny-dtls-action"
																type="submit"
																onClick={() => onSubmit()}
																disabled={isSubmitting}
															>
																Send OTP
															</button>
														) : (
															<button className="btn btn-cmpny-dtls-action" type="submit" onClick={(event) => resendOTP()}>
																Resend OTP
															</button>
														)}
													</div>
												</div>
											</div>
										</div>
										<div
											style={{
												position: 'fixed',
												bottom: 20,
												left: '50%',
												transform: 'translate(-50%, 0)',
												textAlign: 'center'
											}}
										>
											© 2024 Projectmix Ai. <span style={{ display: 'inline-block' }}>All Rights Reserved</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Toaster
				toastOptions={{
					className: 'toaster-styles-2'
				}}
			/>
		</>
	);
};

export default ForgotPasswordOTP;
