import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import NoImage from 'assets/images/no-image.png';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import CloseIcon from 'assets/images/close-icon.svg';


const ReceiptModal = ({ openPopup, purchaseInvoice, handleClose, submitReceiptForm, selectedItems }) => {
    const [formInfo, setFormInfo] = useState({
        estimationId: '',
    });

    const [sendingType, setSendingType] = useState('auto');
    const handleValue = (val) => {
        setFormInfo({
            ...formInfo,
            estimationId: val?.id
        });
        setFormInfo({ estimationId: val?.value });
    };

    const [processingReceipt, setProcessingReceipt] = useState(false);

    const [emailDetails, setEmailDetails] = useState({
        receiver: '',
        subject: '',
        message: '',
        // id: selectedForSending,
        type: openPopup,
    });



    useEffect(() => {
        
    }, []);


    const sendEmail = () => {
        if (sendingType == 'auto') {

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!emailRegex.test(emailDetails?.receiver)) {
                toast.error(`Please enter valid email address`);
                return false;
            } else if (emailDetails?.subject.trim() == '') {
                toast.error(`Please enter email subject`);
                return false;
            } else {
                const data = {
                    receiver: emailDetails?.receiver,
                    subject: emailDetails?.subject,
                    message: emailDetails?.message,
                    sending_type: sendingType,
                    // id: selectedForSending,
                    type: openPopup
                };
                sendEstimateEmail(data);
            }
        } else {
            const data = {
                receiver: emailDetails?.receiver,
                subject: emailDetails?.subject,
                message: emailDetails?.message,
                sending_type: sendingType,
                // id: selectedForSending,
                type: openPopup
            };
            sendEstimateEmail(data);
        }
    }

    const disablepopup = () => {
        handleClose();
    };




    return (
        <>
            <Modal show={openPopup} onHide={disablepopup} centered size="md">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-left">

                                        <h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0" style={{ fontWeight: '600' }}>
                                            Add to stock
                                        </h5>
                                        <br />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                                        <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                                    </svg>
                                </button>
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12">
                                    <p className="text-center font500" style={{ fontSize: '16px', marginTop: '-10px', marginBottom: '20px'}}>Upcoming stock items</p>
                                    <div className="send-to-products-purchase" style={{ paddingBottom: '1px'}}>
                                        {
                                            selectedItems.map((item, index) => (
                                                <>
                                                    <div className="row" style={{ marginBottom: '12px'}}>
                                                        <div className="col-10">
                                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                                                <div>
                                                                    {item?.thumbnail_url ? (
                                                                        <img src={item.thumbnail_url} style={{ width: '30px' }} alt="product" />
                                                                    ) : (
                                                                        <img src={NoImage} style={{ width: '30px' }} alt="product" />
                                                                    )}
                                                                </div>
                                                                <div style={{ marginLeft: '8px'}}>{item?.product_name}</div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="col-2 form-group" style={{ marginTop: '4px'}}>{item?.receiving_quantity} pcs</div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="btnarea" style={{ justifyContent: 'right' }}>
                                <div className="">

                                    <div className="row">
                                        <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                            <button
                                                className="btn btn-main-primary btn-outlined-main"
                                                style={{ marginRight: '4px' }}
                                                disabled={true}
                                                onClick={() => { submitReceiptForm('with_imei') }}
                                            >
                                                {
                                                    processingReceipt ? (
                                                        <>
                                                            <FaSpinner className='spinner' /> Processing...
                                                        </>
                                                    ) : (
                                                        <>
                                                            With IMEI
                                                        </>
                                                    )
                                                }
                                            </button>
                                            <button
                                                onClick={() => { submitReceiptForm('without_imei') }}
                                                className="btn btn-main-primary btn-outlined-main"
                                                disabled={processingReceipt}
                                                style={{ marginLeft: '4px' }}
                                            >
                                            {
                                                processingReceipt ? (
                                                    <>
                                                        <FaSpinner className='spinner' /> Processing...
                                                    </>
                                                ) : (
                                                    <>
                                                        Without IMEI
                                                    </>
                                                )
                                            }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ReceiptModal;
