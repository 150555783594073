import React from 'react';
import { Fade } from 'react-awesome-reveal';

function SalesTeamCard({ salesPerformance }) {
	return (
		<div className="custom-col-lg-3-5 col-md-6 col-sm-6" style={{ paddingBottom: '20px' }}>
			<Fade>
				<div className="card" style={{ height: '270px' }}>
					<div className="card-header" style={{ backgroundColor: 'white' }}>
						<h5 style={{ fontWeight: '600' }}>Sales Team</h5>
					</div>
					<div className="card-body" style={{ paddingTop: '10px' }}>
						<div className="chart-list">
							{salesPerformance &&
								Object.values(salesPerformance)
									.sort((a, b) => b.percentage - a.percentage)
									.map((salesPerson, index) => (
										<div className="row" key={index} style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
											<div style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
												<div
													style={{
														width: '50px',
														height: '50px',
														borderRadius: '50%',
														backgroundImage: `url(${salesPerson.image})`,
														backgroundSize: 'cover'
													}}
												></div>
											</div>
											<div
												style={{ width: '52%', marginRight: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
											>
												<a>{salesPerson.name}</a>
												<div style={{ flexGrow: 1 }}>
													<div
														style={{
															height: '7px',
															backgroundColor: '#F3F6F9',
															borderRadius: '10px',
															overflow: 'hidden',
															marginRight: '12px',
															marginTop: '5px',
															marginBottom: '10px'
														}}
													>
														<div
															style={{
																width: `${Math.min(salesPerson.percentage, 100)}%`,
																height: '100%',
																borderRadius: '50px',
																backgroundColor:
																	salesPerson.percentage <= 30 ? '#ee4747' : salesPerson.percentage <= 70 ? '#ffba49' : '#36c880',
																transition: 'width 1.5s ease-in-out, background-color 1.5s ease-in-out'
															}}
														></div>
													</div>
												</div>
											</div>
											<div
												style={{
													width: '5%',
													height: '38px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													paddingTop: '12px',
													color: salesPerson.percentage <= 30 ? '#ee4747' : salesPerson.percentage <= 70 ? '#ffba49' : '#36c880'
												}}
											>
												<span>{salesPerson.percentage.toFixed(2)}%</span>
											</div>
										</div>
									))}
						</div>
					</div>
				</div>
			</Fade>
		</div>
	);
}

export default SalesTeamCard;
