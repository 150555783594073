import { Drawer } from '@mui/material';
import React, { useState } from 'react';
import { MdTaskAlt } from 'react-icons/md';
import TaskPanel from './TaskPanel';

const TaskSection = ({
    taskPanelOpened,
    setTaskPanelOpened
}) => {
    const [tasks, setTasks] = useState([]);
    const [loaded, setLoaded] = useState(false);
	const [deletedTasks, setDeletedTasks] = useState([]);
    
	return (
		<>
			<span
				style={{ fontSize: '20px', cursor: 'pointer' }}
				onClick={() => {
					setTaskPanelOpened(true);
				}}
			>
				<MdTaskAlt />
			</span>
			<Drawer
				anchor={'right'}
				open={taskPanelOpened}
				onClose={() => {
					setTaskPanelOpened(false);
				}}
			>
				<TaskPanel
					onClose={() => {
						setTaskPanelOpened(false);
                    }}
                    tasks={tasks}
                    setTasks={setTasks}
                    deletedTasks={deletedTasks}
                    setDeletedTasks={setDeletedTasks}
                    loaded={loaded}
                    setLoaded={setLoaded}
				/>
			</Drawer>
		</>
	);
};

export default TaskSection;
