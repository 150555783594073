import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
	
    
	getAllReports() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/reports/get-allreports`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getReportbyId(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/reports/get-reports/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
    getReportbyTypeId(id,filters,date,columns) {
		let url = `${base}/api/reports/get-reports?${id ? 'id=' + id + '&' : ''}`;
		if (filters) {
			Object.keys(filters).forEach((key) => {
				const values = filters[key];
		
				if (Array.isArray(values) && values.length > 0) {
					values.forEach((valueObj) => {
						const { label, value } = valueObj;
						url += `${key}[]=${encodeURIComponent(value)}&`;
					});
				} else if (values && values.value !== undefined && values.value !== null) {
					url += `${key}=${encodeURIComponent(values.value)}&`;
				}
			});
			
		}
		if (columns && columns.length > 0) {
        	columns.forEach((columnName) => {
        	    url += `columns[]=${encodeURIComponent(columnName)}&`;
        	});
   		 }

		if (date?.startDate && date?.endDate) {
			console.log('inside date')
			url += `startDate=${encodeURIComponent(date.startDate)}&`;
			url += `endDate=${encodeURIComponent(date.endDate)}&`;
		}
		url = url.endsWith('&') ? url.slice(0, -1) : url;
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	downloadReports(type_id, data, filters, date,excel,columns) {
		let url = `${base}/api/reports/${type_id}/create-report`;
	
		if (filters) {
			let queryParams = Object.keys(filters).map((key) => {
				const values = filters[key];
	
				if (Array.isArray(values) && values.length > 0) {
					return values.map((valueObj) => `${key}[]=${encodeURIComponent(valueObj.value)}`).join('&');
				} else if (values && values.value !== undefined && values.value !== null) {
					return `${key}=${encodeURIComponent(values.value)}`;
				}
				return '';
			});
			queryParams = queryParams.filter((param) => param !== ''); 
        	url += queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

		}
	
		if (date?.startDate && date?.endDate) {
			url += `${url.includes('?') ? '&' : '?'}startDate=${encodeURIComponent(date.startDate)}`;
			url += `&endDate=${encodeURIComponent(date.endDate)}`;
		}
		if(excel == 1){
			url += `${url.includes('?') ? '&' : '?'}isExcel=1`;
		}
		if (columns && columns.length > 0) {
        	columns.forEach((columnName) => {
        	    url += `${url.includes('?') ? '&' : '?'}columns[]=${encodeURIComponent(columnName)}`;
        	});
   		 }
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(url, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token ||
							JSON.parse(sessionStorage.getItem('user')).token ||
							token
						}`,
					},
					responseType: 'blob',
				})
				.then((response) => {
					console.log(response)
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},


};