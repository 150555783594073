import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
    // get Models
	getCollectedModels(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/get-collected-models/${id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	}
};
