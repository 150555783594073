import React from 'react';
import { Dropdown } from 'react-bootstrap'; // Import this if it's a separate component
import { IconDots } from '@tabler/icons'; // Import this if it's a separate component
import { Fade } from 'react-awesome-reveal';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const SalesTeamCard = ({ salesPerson, setDisplayMode, displayMode }) => {
	return (
		<div className="col-lg-3 col-md-6 col-sm-12">
			<Fade>
				<div className="card" style={{ height: '230px', marginBottom: '20px', zIndex: 10 }}>
					<div
						className="card-header d-flex justify-content-between align-items-center"
						style={{ backgroundColor: 'white', paddingBottom: '7px' }}
					>
						<a style={{ fontWeight: '500' }}>Sales Team</a>
						<div>
							<Dropdown className="high-z-index">
								<Dropdown.Toggle variant="primary" id="dropdown-basic" className="small-dropdown">
									<IconDots size={20}></IconDots>
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-menu-left custom-inset high-z-index">
									<Dropdown.Item onClick={() => setDisplayMode('percentage')}>Show Percentage</Dropdown.Item>
									<Dropdown.Item onClick={() => setDisplayMode('turnover')}>Show Turnover</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
					<div
						className="card-body sales-person"
						style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto', paddingTop: 0 }}
					>
						{salesPerson
							.sort((a, b) => b.value - a.value)
							.map((item, index) => (
								<div className="row" style={{ marginBottom: '15px' }} key={index}>
									<div className="col-3">
										{item.photo ? (
											<img src={item.photo} alt="Salesman" style={{ height: '45px', width: '45px', borderRadius: '50%' }} />
										) : (
											<div
												style={{
													height: '45px',
													width: '45px',
													borderRadius: '50%',
													backgroundColor: '#ccc',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													color: '#fff',
													fontWeight: 'bold',
													fontSize: '20px'
												}}
											>
												{item.name.charAt(0).toUpperCase()}
											</div>
										)}
									</div>
									<div className="col-9" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
										<a style={{ fontWeight: '500' }}>{item.name}</a>
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
											{displayMode === 'profit' ? (
												<div style={{ fontSize: 16, fontWeight: 600 }}>
													<PrivacyModeContent>$ {parseFloat(item.amount).toLocaleString()}</PrivacyModeContent>
												</div>
											) : displayMode === 'turnover' ? (
												<div style={{ fontSize: 16, fontWeight: 600 }}>
													<PrivacyModeContent>$ {parseFloat(item.turnover).toLocaleString()}</PrivacyModeContent>
												</div>
											) : (
												<div style={{ height: '6px', width: '70%', backgroundColor: '#F3F6F9', borderRadius: '50px', overflow: 'hidden' }}>
													<div
														style={{
															height: '100%',
															width: `${Math.min(item.value, 100)}%`,
															backgroundColor: item.value <= 30 ? '#f64e60' : item.value <= 70 ? '#ffba49' : '#36c880',
															borderRadius: '50px',
															transition: 'width 2s ease-in-out' // Add transition
														}}
													></div>
												</div>
											)}
											<div style={{ color: item.value <= 30 ? '#f64e60' : item.value <= 70 ? '#ffba49' : '#36c880' }}>
												{item.value.toFixed(2)}%
											</div>
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default SalesTeamCard;
