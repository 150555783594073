import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import CreateSupplier from './CreateSupplier';
import CurrencyInput from 'react-currency-input-field';
import { useDispatch } from 'react-redux';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { FaPlus, FaSave, FaTimes } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { getCurrencySymbol } from 'utils/currency';

const EditColapsible = ({ openEdit, currentRowData, close, selectedListids }) => {
	const dispatch = useDispatch();
	const [alertMessage, setAlertMessage] = useState();
	const [rows, setRows] = useState([
		{
			id: 1,
			currentCurrency: '',
			modelId: '',
			product: '',
			purchase_listid: '',
			editmodal: false,
			estimateId: '',
			estimatenumber: '',
			invoicenumber: '',
			quantity: '',
			unitPrice: '',
			unitPrice_eur: '',
			unitPrice_usd: '',
			unitPrice_aed: '',
			supplierId: '',
			sellingprice_eur: '',
			sellingprice_usd: '',
			sellingprice_aed: '',
			selling_price: '',
			profit: '',
			profitPercent: '',
			totalprofit: '',
			purchaseNum: ''
		}
	]);
	const [modelList, setModellist] = useState();
	const [supplierList, setSupplierList] = useState([]);
	const [nextId, setNextId] = useState(2);
	const [createSupplier, setCreateSupplier] = useState();
	const [currentCurrency, setCurrentCurrency] = useState('eur');
	const [submitLoading, setSubmitLoading] = useState(false);

	const updateCallSup = () => {
		api.getSelectOptionsList('supplier').then((res) => {
			const tempList = res.data.map((item) => ({
				id: item.id,
				label: `${item.name}`,
				value: item.id
			}));
			setSupplierList(tempList);
		});
	};

	const removeRow = (id) => {
		if (rows.length === 1) {
			return;
		}
		const updatedRows = rows.filter((row) => row.id !== id);
		setRows(updatedRows);
	};

	const handleModelselect = (id, value) => {
		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				const listData = currentRowData.filter((item) => item.id == value.id)[0];
				const modelId = listData.model_id;
				const purchase_listid = listData.id;
				const estimatenumber = listData.estimation_number;
				const invoicenumber = listData.invoice_number;
				const estimateId = listData.estimation_id;
				const quantity = listData.model_sold_qty;
				const sellingprice_eur = listData?.currency_unit_price?.unit_price_eur;
				const sellingprice_usd = listData?.currency_unit_price?.unit_price_usd;
				const sellingprice_aed = listData?.currency_unit_price?.unit_price_aed;
				const type = value.label;
				return {
					...row,
					modelId,
					purchase_listid,
					estimatenumber,
					invoicenumber,
					estimateId,
					quantity,
					sellingprice_eur,
					sellingprice_usd,
					sellingprice_aed
				};
			}
			return row;
		});
		setRows(updatedRows);
	};
	// const handleQuantityChange = (id, value) => {
	// 	const updatedRows = rows.map((row) => {
	// 		if (row.id === id) {
	// 			const quantity = parseInt(value) || 0;
	// 			const totalprofit = quantity * row.profit;
	// 			return { ...row, quantity, totalprofit };
	// 		}
	// 		return row;
	// 	});
	// 	setRows(updatedRows);
	// };

	// const handleUnitPriceChange = (id, value, currency) => {
	// 	const updatedRows = rows.map((row) => {
	// 		if (row.id === id) {
	// 			const unitPrice = parseFloat(value) || 0;
	// 			let sellingPrice = row.selling_price?.[row.currentCurrency];
	// 			const profit = unitPrice > 0 ? (sellingPrice - unitPrice).toFixed(2) : 0;
	// 			const profitPercent = unitPrice > 0 ? ((profit * 100) / unitPrice).toFixed(2) : 0;
	// 			const totalprofit = unitPrice > 0 ? (row.quantity * profit).toFixed(2) : 0;
	// 			return { ...row, unitPrice, profit, totalprofit, profitPercent };
	// 		}
	// 		return row;
	// 	});
	// 	setRows(updatedRows);
	// };

	const handleQuantityChange = (id, value) => {
		const selectedRow = currentRowData.filter((row) => selectedListids.includes(row.id));
		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				let quantity = 0;
				if (parseInt(value) > selectedRow?.find((r) => r.id == row?.purchase_listid)?.purchase_qty) {
					quantity = selectedRow?.find((r) => r.id == row?.purchase_listid)?.purchase_qty;
				} else if (parseInt(value) < 0) {
					quantity = 0;
				} else {
					quantity = parseInt(value) || 0;
				}
				const unitPrice = row.unitPrice || 0;
				let sellingPrice = row.selling_price || 0;
				const profit = unitPrice > 0 ? (sellingPrice - unitPrice).toFixed(2) : 0;
				const profitPercent = unitPrice > 0 ? ((profit * 100) / unitPrice).toFixed(2) : 0;
				const totalprofit = unitPrice > 0 ? (row.quantity * profit).toFixed(2) : 0;

				return { ...row, quantity, unitPrice, profit, totalprofit, profitPercent };
			}
			return row;
		});
		setRows(updatedRows);
	};

	const handleUnitPriceChange = (id, value, currency) => {
		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				const unitPrice = parseFloat(value) || 0;
				let sellingPrice = row.selling_price || 0;
				const profit = unitPrice > 0 ? (sellingPrice - unitPrice).toFixed(2) : 0;
				const profitPercent = unitPrice > 0 ? ((profit * 100) / unitPrice).toFixed(2) : 0;
				const totalprofit = unitPrice > 0 ? (row.quantity * profit).toFixed(2) : 0;

				return { ...row, unitPrice, profit, totalprofit, profitPercent };
			}
			return row;
		});
		setRows(updatedRows);
	};

	const handleSupplierList = (id, value) => {
		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				const supplierId = value.id;
				return { ...row, supplierId };
			}
			return row;
		});
		setRows(updatedRows);
	};
	const valueValidation = (e, purchaseId) => {
		let inputValue = e.target.value;
		const maxQuantity = currentRowData.filter((row) => row.id == purchaseId)[0]?.model_purchase_qty;
		if (inputValue > maxQuantity) {
			inputValue = maxQuantity;
		}
		e.target.value = inputValue;
	};
	const handleAmount = (e, purchaseId) => {
		let inputValue = e.target.value;
		const currentdata = currentRowData.filter((row) => row.id == purchaseId)[0];

		let maxQuantity = currentdata?.selling_price;

		if (inputValue > maxQuantity) {
			inputValue = maxQuantity;
		}
		e.target.value = inputValue;
	};
	useEffect(() => {
		updateCallSup();
		const selectedRow = currentRowData.filter((row) => selectedListids.includes(row.id));
		const tempmodellist = currentRowData.map((item) => ({ id: item.id, label: item?.master_model?.name }));
		setModellist(tempmodellist);
		if (selectedRow.length > 0) {
			const tempData = selectedRow.map((item, index) => {
				return {
					id: index + 1,
					currentCurrency: item.purchase_currency,
					modelId: item.model,
					product: item.product,
					purchase_listid: item.id,
					editmodal: true,
					estimateId: item?.estimation_id,
					estimatenumber: item?.estimation_number,
					invoicenumber: item?.invoice_number,
					quantity: item.purchase_qty,
					unitPrice_eur: item?.currency_purchase_unit_price?.unit_price_eur,
					unitPrice_usd: item?.currency_purchase_unit_price?.unit_price_usd,
					unitPrice_aed: item?.currency_purchase_unit_price?.unit_price_aed,
					unitPrice: item?.purchase_price_unit,
					supplierId: item.supplier_id,
					selling_price: item?.converted_currency_selling_price_unit?.[item?.purchase_currency],
					profit: item?.converted_currency_selling_price_unit?.[item?.purchase_currency] - item?.purchase_price_unit,
					profitPercent:
						((item?.converted_currency_selling_price_unit?.[item?.purchase_currency] - item?.purchase_price_unit) /
							item?.converted_currency_selling_price_unit?.[item?.purchase_currency]) *
						100,
					totalprofit:
						(item?.converted_currency_selling_price_unit?.[item?.purchase_currency] - item?.purchase_price_unit) * item.purchase_qty,
					purchaseNum: item.id
				};
			});
			setRows(tempData);
		}
	}, [openEdit, selectedListids]);

	useEffect(() => {}, []);

	// Customer Create
	const NoOptionsMessage = (props) => {
		return (
			<div {...props}>
				<button className="btn addNewCus" onClick={() => inputfieldEnable(props.rowId)}>
					<FaPlus /> New Supplier
				</button>
			</div>
		);
	};
	const inputfieldEnable = (rowId) => {
		setCreateSupplier(rowId);
	};
	const closeCreateSupplier = () => {
		setCreateSupplier('');
	};
	const validateRows = () => {
		for (let i = 0; i < rows.length; i++) {
			const { supplierId, quantity, unitPrice } = rows[i];
			if (!unitPrice || !supplierId) {
				return false;
			}
		}
		return true;
	};
	const saveData = () => {
		if (!validateRows()) {
			setAlertMessage({ modelrow: 'Please fill in all fields for each product.' });
		} else {
			setSubmitLoading(true);
			const data = {
				details: rows.map((obj) => ({
					purchase_id: obj.purchase_listid,
					quantity: obj.quantity,
					unit_price: obj.unitPrice,
					supplier: obj.supplierId
				}))
			};

			toast.promise(
				api.editpurchasemodel(data).then(
					(res) => {
						if (res.status) {
							setRows([]);
							close('reload');
						}
						setSubmitLoading(false);
						return res?.status ? res : null;
					},
					() => {
						setSubmitLoading(false);
					}
				),
				{
					loading: 'Saving Changes...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update changes</b>
				}
			);
		}
	};
	return (
		<>
			<Modal show={openEdit} onHide={close} centered className="custom-modal-two">
				<Modal.Body>
					<div className="p-2">
						<div className="foundListPopup">
							<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 pl-0">
								<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">Edit Products</h5>
								<button className="btn modal-close-icon" onClick={close}>
									<FaTimes />
								</button>
							</div>
							<div className="table-responsive-xl productsTable">
								<table className="table collaps sourced-table table-md  formActBtns border-0 hide-on-mobile">
									<thead>
										<tr>
											<th className="align-middle" width={'250'}>
												Model
											</th>
											<th className="align-middle">Invoice No.</th>
											<th className="align-middle">Quantity</th>
											<th className="align-middle">Unit Price</th>
											<th className="align-middle" width={'240'}>
												Supplier
											</th>
											<th className="align-middle">Selling Price</th>
											<th className="align-middle">Profit</th>
											<th className="align-middle">Total Profit</th>
											<th className="align-middle text-center" width={'50'}></th>
										</tr>
									</thead>
									<tbody>
										{rows.map((row, index) => (
											<>
												<tr key={row.id}>
													<td className="align-middle form-row estimateCName">{row?.product?.product_name}</td>
													<td className="align-middle">{row.invoicenumber}</td>
													<td className="align-middle">
														<input
															className="numField"
															type="number"
															placeholder="00"
															value={row.quantity}
															onChange={(e) => {
																valueValidation(e, row.purchaseNum);
																handleQuantityChange(row.id, e.target.value);
															}}
														/>
													</td>

													<td className="align-middle">
														{row.currentCurrency == 'eur' ? '€ ' : row.currentCurrency == 'usd' ? '$ ' : 'AED '}
														<input
															className="numField"
															type="number"
															value={row?.unitPrice}
															placeholder="00"
															onChange={(e) => {
																handleAmount(e, row.purchaseNum);
																handleUnitPriceChange(row.id, e.target.value);
															}}
														/>
													</td>
													<td className="align-middle form-row estimateCName">
														<div className="form-group col-md-12 mb-0">
															<Select
																className="rs-filter-container"
																classNamePrefix="rs-filter"
																isSearchable={true}
																name="customer"
																components={{
																	NoOptionsMessage: () => <NoOptionsMessage rowId={row.id} />
																}}
																options={supplierList}
																value={supplierList.find((sup) => sup.id == row.supplierId)}
																placeholder="Select"
																onChange={(selected) => handleSupplierList(row.id, selected)}
															/>
														</div>
													</td>
													<td className="align-middle">
														{row.currentCurrency == 'eur' ? '€ ' : row.currentCurrency == 'usd' ? '$ ' : 'AED '}
														{Number(row?.selling_price)?.toFixed(2)}
													</td>
													<td className="align-middle">
														{' '}
														{row.currentCurrency == 'eur' ? '€ ' : row.currentCurrency == 'usd' ? '$ ' : 'AED '}
														{Number(row.profit).toFixed(2) || '00'} {`(${Number(row.profitPercent).toFixed(2) || '00'}%)`}
													</td>
													<td className="align-middle">
														{' '}
														{row.currentCurrency == 'eur' ? '€ ' : row.currentCurrency == 'usd' ? '$ ' : 'AED '}
														{Number(row.totalprofit).toFixed(2) || '00'}
													</td>
													<td className="align-middle text-center">
														<span className="btnEdit plain-iconbtn" onClick={() => removeRow(row.id)}>
															<FaTimes />
														</span>
													</td>
												</tr>
											</>
										))}
										{alertMessage?.modelrow && <span className="px-3 down-error text-danger">{alertMessage?.modelrow}</span>}
									</tbody>
								</table>
								<div className="sourcing-popup-list">
									{rows?.map((row, index) => (
										<div className="sourcing-popup-item">
											<div className="sourcing-popup-item-header">
												<h6 className="sourcing-popup-item-title p-0 m-0">{row?.product?.product_name}</h6>
												<p className="p-0 m-0">{row.invoicenumber}</p>
											</div>
											<div className="sourcing-popup-item-body row m-0 p-0 mt-2">
												<div className="col-6 row m-0 p-0">
													<div className="col-6 m-0 p-0 pr-1">
														<div className="form-group m-0 p-0">
															<label htmlFor="">Qty</label>
															<input
																className="form-control"
																type="number"
																value={row.quantity}
																onChange={(e) => {
																	valueValidation(e, row.purchaseNum);
																	handleQuantityChange(row.id, e.target.value);
																}}
																min={0}
																max={currentRowData?.find((c) => c?.id == row?.purchase_listid)?.sold_qty}
															/>
														</div>
													</div>
													<div className="col-6 m-0 p-0 pl-1 pr-1">
														<div className="form-group m-0 p-0">
															<label htmlFor="">Price</label>
															<input
																className="form-control"
																type="number"
																value={row?.unitPrice}
																placeholder="00"
																onChange={(e) => {
																	handleAmount(e, row.purchaseNum);
																	handleUnitPriceChange(row.id, e.target.value);
																}}
															/>
														</div>
													</div>
													<div className="col-12 m-0 p-0 mt-2">
														<div className="form-group m-0 p-0">
															<label htmlFor="">Supplier</label>
															<Select
																className="rs-container"
																classNamePrefix="rs"
																isSearchable={true}
																name="customer"
																components={{
																	NoOptionsMessage: () => <NoOptionsMessage rowId={row.id} />
																}}
																options={supplierList}
																value={supplierList.find((sup) => sup.id == row.supplierId)}
																placeholder="Select"
																onChange={(selected) => handleSupplierList(row.id, selected)}
																menuPosition="fixed"
															/>
														</div>
													</div>
												</div>
												<div className="col-6 m-0 p-0 pl-1">
													<table>
														<tr>
															<th className="p-1">Selling Price:</th>
															<td className="p-1">
																{getCurrencySymbol(currentCurrency)}
																{Number(row?.selling_price)?.toFixed(2)}
															</td>
														</tr>
														<tr>
															<th className="p-1">Gross Profit:</th>
															<td className="p-1">
																{getCurrencySymbol(currentCurrency)}
																{Number(row.profit).toFixed(2) || '00'} {`(${Number(row.profitPercent).toFixed(2) || '00'}%)`}
															</td>
														</tr>
														<tr>
															<th className="p-1">Total Profit:</th>
															<td className="p-1">
																{getCurrencySymbol(currentCurrency)}
																{Number(row.totalprofit).toFixed(2) || '00'}
															</td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="btnarea mt-5">
							<button onClick={() => saveData()} className="btn btn-main" disabled={submitLoading}>
								<FaSave /> Save
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<CreateSupplier open={createSupplier} close={closeCreateSupplier} selectedSupplier={handleSupplierList} reloadsup={updateCallSup} />
		</>
	);
};

export default EditColapsible;
