import { BiCategoryAlt } from 'react-icons/bi';
import { BsClipboardCheck } from 'react-icons/bs';
import { FaBoxesStacked } from 'react-icons/fa6';
import { GiAutoRepair, GiHandTruck, GiMagnifyingGlass } from 'react-icons/gi';
import { PiPackageBold, PiTestTubeFill } from 'react-icons/pi';
import { LuTestTubes } from 'react-icons/lu';

export const taskboardProgresses = [
	{ name: 'Submitted', icon: <PiTestTubeFill />, status: '', link: '' },
	{ name: 'Accepted', icon: <GiMagnifyingGlass />, status: '', link: '' },
	{ name: 'Processing', icon: <GiAutoRepair />, status: '', link: '' },
	{ name: 'Shipped', icon: <LuTestTubes />, status: '', link: '' }
];
