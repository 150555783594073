import React, { useState, useEffect } from 'react';

const Chatbox = ({ chatBoxOpen, setChatBoxOpen, activeChatMenu, setActiveChatMenu, isMobile, salesManager }) => {
	const [delayedActive, setDelayedActive] = useState(false);

	useEffect(() => {
		// Reset delayedActive whenever activeChatMenu changes
		setDelayedActive(false);
		const timer = setTimeout(() => {
			// Apply delayed active state if activeChatMenu is 'messages'
			if (activeChatMenu === 'messages') {
				setDelayedActive(true);
			}
		}, 1000); // Adjust delay as needed

		return () => clearTimeout(timer);
	}, [activeChatMenu]);
	return (
		<>
			<div className="chat-supp-container">
				<div className={`chat-container ${chatBoxOpen ? '' : 'closed'}`}>
					{activeChatMenu == 'menu' ? (
						<div className={`chat-body-menu`}>
							<>
								{isMobile && (
									<>
										<button
											className="btn chat-close-icon"
											style={{ top: '16px', backgroundColor: 'black' }}
											onClick={() => setChatBoxOpen(false)}
										>
											<svg
												id="Component_127_1"
												height="16"
												width="16"
												data-name="Component 127 – 1"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 19.295 19.289"
											>
												<path
													id="Icon_ionic-ios-close"
													data-name="Icon ionic-ios-close"
													d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
													transform="translate(-11.285 -11.289)"
												/>
											</svg>
										</button>
									</>
								)}
								<div className="row" style={{ padding: 10, marginTop: isMobile ? '50px' : '' }}>
									<div className={`col-12 d-flex align-items-center ${isMobile ? 'justify-content-end' : 'justify-content-end'}`}>
										{' '}
										<div
											style={{
												borderRadius: '50%',
												backgroundColor: 'white',
												height: 45,
												width: 45,
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												border: '1px solid white' // add this
											}}
										>
											{salesManager && <img src={salesManager.profile_photo_url} height={40} width={40} style={{ borderRadius: '50%' }} />}
										</div>
									</div>
								</div>
								<div className="row" style={{ height: 80 }}>
									<div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
										<img src="https://projectmix.ai/images/logo-dark.svg" alt="logo" className="img-fluid" width={150} height={'80%'} />
									</div>
								</div>
								<div className="row" style={{ height: 200, backgroundColor: 'white', borderRadius: 16, marginRight: 10, marginLeft: 10 }}>
									<div className="col-12 d-flex justify-content-center align-items-center">
										<a style={{ fontSize: 24 }}>Coming Soon</a>
									</div>
								</div>
							</>
						</div>
					) : activeChatMenu == 'messages' ? (
						<div className="chat-body-message">
							<>
								{isMobile && (
									<>
										<button
											className="btn chat-close-icon"
											style={{ top: '16px', backgroundColor: 'black', zIndex: 11 }}
											onClick={() => setChatBoxOpen(false)}
										>
											<svg
												id="Component_127_1"
												height="16"
												width="16"
												data-name="Component 127 – 1"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 19.295 19.289"
											>
												<path
													id="Icon_ionic-ios-close"
													data-name="Icon ionic-ios-close"
													d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
													transform="translate(-11.285 -11.289)"
												/>
											</svg>
										</button>
									</>
								)}
								<div className="message-banner">Messages</div>
								<div className="chat-popup-body active">
									<div className="chat-header">Unread message</div>
									<div className="row" style={{ padding: 10, width: '100%' }}>
										<div className="col-12 d-flex justify-content-start align-items-center">
											<div
												style={{
													borderRadius: '50%',
													backgroundColor: 'white',
													height: 45,
													width: 45,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													border: '1px solid white' // add this
												}}
											>
												{salesManager && (
													<img src={salesManager.profile_photo_url} height={40} width={40} style={{ borderRadius: '50%' }} />
												)}
											</div>
											<div className="d-flex flex-column">
												<div className="chat-popup-reply-name">{salesManager ? <a>{salesManager.first_name}</a> : <a>Sales</a>}</div>
												<div className="chat-popup-reply">
													<a>Coming soon</a>
												</div>
											</div>
											<div className="d-flex flex-column">
												<div style={{ opacity: 0 }}>pusher</div>
												<div className="chat-timestamp">
													<span>Oct, 2024 10:00 AM</span>
												</div>
											</div>
										</div>
									</div>
									<div className="chat-input-field d-flex align-items-center" style={{ padding: 10, cursor: 'pointer' }}>
										<input type="text" className="form-control" placeholder="Type a message" />
										<span className="send-icon" style={{ marginLeft: 10, marginRight: 5 }}>
											<svg
												id="fi_3024593"
												enable-background="new 0 0 176.368 176.368"
												height="18"
												viewBox="0 0 176.368 176.368"
												width="18"
												xmlns="http://www.w3.org/2000/svg"
												fill="currentColor"
											>
												<g>
													<g>
														<path
															clip-rule="evenodd"
															d="m175.865 12.489c2.469-7.408-4.578-14.456-11.986-11.987l-157.399 52.467c-8.839 2.946-8.565 15.542.393 18.101l76.552 21.873 21.872 76.552c2.56 8.959 15.155 9.233 18.101.393zm-12.34 7.055-49.116 147.348-21.83-76.403zm-6.701-6.701-70.945 70.945-76.402-21.829z"
															fill-rule="evenodd"
														></path>
													</g>
												</g>
											</svg>
										</span>
									</div>
								</div>
							</>
						</div>
					) : activeChatMenu == 'help' ? (
						<div className={`chat-body-help ${activeChatMenu === 'help' ? 'active' : ''}`}>
							<>
								{isMobile && (
									<>
										<button
											className="btn chat-close-icon"
											style={{ top: '16px', backgroundColor: 'black', zIndex: 11 }}
											onClick={() => setChatBoxOpen(false)}
										>
											<svg
												id="Component_127_1"
												height="16"
												width="16"
												data-name="Component 127 – 1"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 19.295 19.289"
											>
												<path
													id="Icon_ionic-ios-close"
													data-name="Icon ionic-ios-close"
													d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
													transform="translate(-11.285 -11.289)"
												/>
											</svg>
										</button>
									</>
								)}
								<div className="message-banner">Need Help?</div>
								<div className="help-body" style={{ padding: 20 }}>
									<div className="row">
										<div className="col-6 fadeInItem" style={{ marginBottom: 20 }}>
											<div className="card-help">
												<span className="help-icon">
													<div className="svg-background" style={{ backgroundColor: '#D8678A' }}>
														<svg
															enable-background="new 0 0 512 512"
															viewBox="0 0 512 512"
															height="40"
															width="40"
															xmlns="http://www.w3.org/2000/svg"
															id="fi_11781564"
															fill="currentColor"
														>
															<g id="Clock"></g>
															<g id="Chemistry"></g>
															<g id="Classroom"></g>
															<g id="Coffee"></g>
															<g id="Graduate"></g>
															<g id="Pass"></g>
															<g id="Clipboard"></g>
															<g id="Certificate"></g>
															<g id="Tutorial">
																<g>
																	<path d="m457 105.934h-402c-30.327 0-55 24.673-55 55v190.133c0 30.327 24.673 55 55 55h402c30.327 0 55-24.673 55-55v-190.133c0-30.328-24.673-55-55-55zm35 245.132c0 19.299-15.701 35-35 35h-402c-19.299 0-35-15.701-35-35v-190.132c0-19.299 15.701-35 35-35h402c19.299 0 35 15.701 35 35z"></path>
																	<path d="m502 451.3h-382.276c-4.162-12.088-15.641-20.8-29.124-20.8s-24.962 8.712-29.125 20.8h-51.475c-5.523 0-10 4.478-10 10s4.477 10 10 10h51.475c4.162 12.088 15.641 20.8 29.125 20.8 13.483 0 24.962-8.712 29.124-20.8h382.276c5.523 0 10-4.478 10-10s-4.477-10-10-10zm-411.4 20.8c-5.955 0-10.8-4.845-10.8-10.8s4.845-10.8 10.8-10.8 10.8 4.845 10.8 10.8-4.845 10.8-10.8 10.8z"></path>
																	<path d="m227.229 207.149c-3.094 1.786-5 5.088-5 8.66v80.381c0 3.572 1.906 6.874 5 8.66 1.547.894 3.273 1.34 5 1.34s3.453-.446 5-1.34l69.612-40.19c3.094-1.786 5-5.088 5-8.66s-1.906-6.874-5-8.66l-69.612-40.19c-3.094-1.788-6.906-1.788-10-.001zm15 25.981 39.612 22.87-39.612 22.87z"></path>
																	<path d="m256 166.6c-49.295 0-89.4 40.104-89.4 89.4s40.104 89.4 89.4 89.4 89.4-40.104 89.4-89.4-40.105-89.4-89.4-89.4zm0 158.8c-38.267 0-69.4-31.133-69.4-69.4s31.133-69.4 69.4-69.4 69.4 31.133 69.4 69.4-31.133 69.4-69.4 69.4z"></path>
																</g>
															</g>
															<g id="Backpack"></g>
															<g id="Chair"></g>
															<g id="Calender"></g>
															<g id="Bus"></g>
															<g id="Folder"></g>
															<g id="Bed"></g>
															<g id="Hostel"></g>
															<g id="Calvulator"></g>
															<g id="Exam"></g>
															<g id="Blackboard"></g>
															<g id="Suitcase"></g>
															<g id="Bag"></g>
															<g id="Library"></g>
															<g id="Qyestion"></g>
															<g id="Medical_Kit"></g>
															<g id="Teacher"></g>
															<g id="Glasses"></g>
															<g id="Website"></g>
															<g id="Basket_ball"></g>
															<g id="Degree"></g>
															<g id="ID_card"></g>
															<g id="Medal"></g>
															<g id="Credit_card"></g>
															<g id="Concert_ticket"></g>
															<g id="Sports_shorts"></g>
															<g id="Idea"></g>
															<g id="University"></g>
															<g id="Mortarboard"></g>
															<g id="Route"></g>
															<g id="Compass"></g>
															<g id="Table_lamp"></g>
															<g id="Notebook"></g>
															<g id="Directional_sign"></g>
															<g id="Cruise_ship"></g>
															<g id="Lockers"></g>
															<g id="Money_00000052791285769839527850000011059614501927807388_"></g>
															<g id="Map"></g>
															<g id="Landing"></g>
															<g id="Books"></g>
															<g id="Science"></g>
															<g id="Chat"></g>
														</svg>
													</div>
												</span>
												<a style={{ marginTop: 10, fontWeight: 500 }}>Tutorial</a>
											</div>
										</div>
										<div className="col-6" style={{ marginBottom: 20 }}>
											<div className=" card-help">
												<span className="help-icon">
													<div className="svg-background" style={{ backgroundColor: '#795AAA' }}>
														<svg
															height="40"
															viewBox="-77 1 511 511.99901"
															width="40"
															xmlns="http://www.w3.org/2000/svg"
															id="fi_1705243"
															fill="currentColor"
														>
															<path d="m357.207031 371.574219v-352.910157c0-10.289062-8.375-18.664062-18.667969-18.664062h-289.15625c-26.953124 0-48.882812 21.929688-48.882812 48.882812v372.753907c0 26.953125 21.925781 48.878906 48.882812 48.878906h.109376v33.972656c0 2.828125 1.589843 5.417969 4.109374 6.699219 2.523438 1.277344 5.554688 1.03125 7.835938-.636719l20.589844-15.058593 20.59375 15.058593c1.3125.960938 2.867187 1.449219 4.433594 1.449219 1.160156 0 2.324218-.269531 3.398437-.8125 2.523437-1.28125 4.113281-3.871094 4.113281-6.699219v-33.972656h223.972656c10.292969 0 18.664063-8.371094 18.664063-18.664063v-43.886718c0-4.148438-3.363281-7.511719-7.511719-7.511719-4.148437 0-7.511718 3.363281-7.511718 7.511719v43.886718c0 2.011719-1.632813 3.644532-3.644532 3.644532h-223.96875v-18.804688h193.816406c4.148438 0 7.511719-3.363281 7.511719-7.507812 0-4.148438-3.363281-7.511719-7.511719-7.511719h-271.429687c-4.148437 0-7.511719 3.363281-7.511719 7.511719 0 4.144531 3.363282 7.507812 7.511719 7.507812h12.539063v18.804688h-.109376c-18.671874 0-33.863281-15.191406-33.863281-33.859375v-8.691407c0-18.671874 15.191407-33.859374 33.863281-33.859374h300.3125c4.148438 0 7.511719-3.363282 7.511719-7.511719zm-257.660156 65.117187v53l-13.082031-9.566406c-1.320313-.964844-2.878906-1.449219-4.433594-1.449219-1.558594 0-3.117188.484375-4.4375 1.449219l-13.082031 9.566406v-53zm-84.023437-58.949218v-328.859376c0-18.671874 15.1875-33.863281 33.859374-33.863281h289.15625c2.011719 0 3.644532 1.636719 3.644532 3.644531v345.398438h-292.800782c-13.136718 0-25.070312 5.21875-33.859374 13.679688zm0 0"></path>
															<path d="m57.988281 392.40625c-4.148437 0-7.511719 3.363281-7.511719 7.511719 0 4.148437 3.363282 7.511719 7.511719 7.511719h156.289063c4.148437 0 7.511718-3.363282 7.511718-7.511719 0-4.148438-3.363281-7.511719-7.511718-7.511719zm0 0"></path>
															<path d="m249.566406 392.40625c-4.148437 0-7.511718 3.363281-7.511718 7.511719 0 4.148437 3.363281 7.511719 7.511718 7.511719h58.816406c4.148438 0 7.511719-3.363282 7.511719-7.511719 0-4.148438-3.363281-7.511719-7.511719-7.511719zm0 0"></path>
															<path d="m100 252.0625h-13.320312c-2.902344 0-3.75 2.625-3.75 5.25s.75 5.34375 3.75 5.34375h7.222656c2.15625 0 2.71875.75 2.625 3.09375-.1875 4.503906-1.59375 12.753906-12.375 12.753906-12.003906 0-13.320313-14.628906-13.320313-22.882812 0-9.84375 1.597657-22.785156 13.320313-22.785156 6.28125 0 9.09375 3 10.5 8.34375.65625 2.4375 2.25 3.472656 7.503906 3.472656 4.78125 0 6-2.628906 5.4375-5.628906-2.160156-11.0625-10.316406-17.160157-23.445312-17.160157-19.128907 1.03125-26.160157 14.628907-26.160157 33.757813 0 18.757812 7.40625 33.855468 26.628907 33.855468 18.285156 0 24.757812-13.222656 24.101562-29.355468-.1875-5.058594-1.875-8.058594-8.71875-8.058594zm0 0"></path>
															<path d="m160.289062 222.519531c-3.09375 0-6.1875.941407-6.1875 4.316407v39.195312c0 7.128906-4.035156 12.285156-11.347656 12.285156s-11.253906-5.15625-11.253906-12.285156v-39.195312c0-3.375-3.09375-4.316407-6.191406-4.316407-3.09375 0-6.1875.941407-6.1875 4.316407v38.070312c0 17.441406 9.847656 24.664062 23.632812 24.664062 13.875 0 23.722656-7.222656 23.722656-24.664062v-38.070312c0-3.375-3.09375-4.316407-6.1875-4.316407zm0 0"></path>
															<path d="m184.851562 222.519531c-3.09375 0-6.1875.941407-6.1875 4.316407v58.140624c0 3.46875 3.09375 4.40625 6.1875 4.40625s6.28125-.9375 6.28125-4.40625v-58.140624c0-3.375-3.1875-4.316407-6.28125-4.316407zm0 0"></path>
															<path d="m225.453125 222.894531h-12.941406c-4.78125 0-9.1875 1.21875-9.1875 8.253907v49.042968c0 7.128906 4.40625 8.347656 9.1875 8.347656h12.941406c18.660156 0 25.972656-15.378906 25.972656-32.820312 0-17.445312-7.5-32.824219-25.972656-32.824219zm0 54.296875h-8.160156c-1.03125 0-1.59375-.375-1.59375-1.59375v-39.855468c0-1.21875.5625-1.59375 1.59375-1.59375h8.160156c10.21875 0 13.222656 12.378906 13.222656 21.570312 0 9.1875-2.722656 21.472656-13.222656 21.472656zm0 0"></path>
															<path d="m274.867188 234.148438h20.910156c3.191406 0 3.941406-2.8125 3.941406-5.625s-.84375-5.625-3.941406-5.625h-25.785156c-4.691407 0-9.191407 1.21875-9.191407 8.25v49.136718c0 7.035156 4.5 8.253906 9.191407 8.253906h25.785156c3.09375 0 3.941406-2.8125 3.941406-5.625s-.75-5.628906-3.941406-5.628906h-20.910156c-1.125 0-1.59375-.375-1.59375-1.59375v-15.472656h19.035156c3.09375 0 3.9375-2.8125 3.9375-5.625s-.84375-5.625-3.9375-5.625h-19.035156v-13.222656c0-1.222656.46875-1.597656 1.59375-1.597656zm0 0"></path>
															<path d="m255.121094 38.066406c-37.265625 0-67.585938 30.320313-67.585938 67.585938 0 37.269531 30.320313 67.589844 67.585938 67.589844 37.269531 0 67.589844-30.320313 67.589844-67.589844 0-37.265625-30.320313-67.585938-67.589844-67.585938zm0 120.15625c-28.984375 0-52.566406-23.582031-52.566406-52.566406 0-28.988281 23.582031-52.570312 52.566406-52.570312 28.988281 0 52.570312 23.582031 52.570312 52.570312 0 28.984375-23.582031 52.566406-52.570312 52.566406zm0 0"></path>
															<path d="m162.039062 55.300781-6.996093 7c-8.628907-10.328125-20.183594-17.890625-33.269531-21.632812-17.359376-4.960938-35.613282-2.867188-51.394532 5.898437-15.785156 8.765625-27.207031 23.152344-32.167968 40.511719-10.242188 35.832031 10.574218 73.320313 46.410156 83.5625 6.183594 1.769531 12.414062 2.609375 18.550781 2.609375 29.40625 0 56.535156-19.371094 65.011719-49.019531 1.316406-4.609375 2.144531-9.347657 2.464844-14.085938.074218-1.089843.113281-2.683593.113281-4.492187 0-4.144532-3.359375-7.507813-7.511719-7.507813-4.148438 0-7.507812 3.363281-7.507812 7.507813 0 1.4375-.03125 2.734375-.082032 3.480468-.25 3.6875-.894531 7.378907-1.921875 10.96875-7.964843 27.871094-37.121093 44.0625-64.992187 36.097657-27.867188-7.96875-44.0625-37.125-36.09375-64.992188 3.855468-13.5 12.742187-24.691406 25.019531-31.507812 12.273437-6.816407 26.46875-8.449219 39.96875-4.589844 10.617187 3.035156 19.929687 9.300781 26.722656 17.867187l-43.242187 43.242188-20.5625-20.558594c-2.933594-2.933594-7.6875-2.933594-10.621094 0s-2.933594 7.6875 0 10.621094l25.871094 25.871094c1.46875 1.464844 3.390625 2.199218 5.3125 2.199218s3.84375-.730468 5.308594-2.199218l66.230468-66.230469c2.933594-2.933594 2.933594-7.6875 0-10.621094-2.933594-2.933593-7.6875-2.933593-10.621094 0zm0 0"></path>
															<path d="m287.285156 74.886719c-2.9375-2.933594-7.691406-2.933594-10.625 0l-21.460937 21.464843-21.464844-21.464843c-2.933594-2.933594-7.6875-2.933594-10.621094 0-2.933593 2.933593-2.933593 7.6875 0 10.621093l21.464844 21.464844-21.464844 21.464844c-2.933593 2.929688-2.933593 7.6875 0 10.621094 1.46875 1.464844 3.390625 2.199218 5.3125 2.199218s3.84375-.734374 5.308594-2.199218l21.464844-21.464844 21.460937 21.464844c1.46875 1.464844 3.390625 2.199218 5.3125 2.199218s3.84375-.734374 5.3125-2.199218c2.929688-2.933594 2.929688-7.691406 0-10.621094l-21.464844-21.464844 21.464844-21.464844c2.929688-2.933593 2.929688-7.6875 0-10.621093zm0 0"></path>
														</svg>
													</div>
												</span>
												<a style={{ marginTop: 10, fontWeight: 500 }}>User guide</a>
											</div>
										</div>
										<div className="col-6" style={{ marginBottom: 20 }}>
											<div className=" card-help">
												<span className="help-icon">
													<div className="svg-background" style={{ backgroundColor: '#F39C12' }}>
														<svg
															height="40"
															viewBox="0 0 64 64"
															width="40"
															xmlns="http://www.w3.org/2000/svg"
															id="fi_5196188"
															fill="currentColor"
														>
															<g id="question" fill="currentColor">
																<path d="m56 30h-52a1 1 0 0 1 -1-1v-17a1 1 0 0 1 1-1h52a1 1 0 0 1 1 1v17a1 1 0 0 1 -1 1zm-51-2h50v-15h-50z"></path>
																<path d="m14 26a6 6 0 1 1 6-6 6 6 0 0 1 -6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0 -4-4z"></path>
																<path d="m19 27a1 1 0 0 1 -.78-.38l-4-5a1 1 0 0 1 1.56-1.24l4 5a1 1 0 0 1 -.15 1.4 1 1 0 0 1 -.63.22z"></path>
																<path d="m24 30h-20a1 1 0 0 1 -1-1v-17a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v17a1 1 0 0 1 -1 1zm-19-2h18v-15h-18z"></path>
																<path d="m60 53h-52a1 1 0 0 1 -1-1v-17a1 1 0 0 1 1-1h52a1 1 0 0 1 1 1v17a1 1 0 0 1 -1 1zm-51-2h50v-15h-50z"></path>
																<path d="m60 53h-20a1 1 0 0 1 -1-1v-17a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v17a1 1 0 0 1 -1 1zm-19-2h18v-15h-18z"></path>
																<path d="m37 17h-9a1 1 0 0 1 0-2h9a1 1 0 0 1 0 2z"></path>
																<path d="m52 21h-24a1 1 0 0 1 0-2h24a1 1 0 0 1 0 2z"></path>
																<path d="m52 25h-24a1 1 0 0 1 0-2h24a1 1 0 0 1 0 2z"></path>
																<path d="m25 40h-13a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2z"></path>
																<path d="m36 44h-24a1 1 0 0 1 0-2h24a1 1 0 0 1 0 2z"></path>
																<path d="m36 48h-24a1 1 0 0 1 0-2h24a1 1 0 0 1 0 2z"></path>
																<path d="m55 50a1 1 0 0 1 -.91-.59l-4.09-9-4.09 9a1 1 0 1 1 -1.82-.82l5-11a1 1 0 0 1 1.82 0l5 11a1 1 0 0 1 -.5 1.32 1 1 0 0 1 -.41.09z"></path>
																<path d="m52.73 45h-5.46a1 1 0 0 1 0-2h5.46a1 1 0 0 1 0 2z"></path>
															</g>
														</svg>
													</div>
												</span>
												<a style={{ marginTop: 10, fontWeight: 500 }}>FAQs</a>
											</div>
										</div>
										<div className="col-6" style={{ marginBottom: 20 }}>
											<div className=" card-help">
												<span className="help-icon">
													<div className="svg-background" style={{ backgroundColor: '#555CF3' }}>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="40"
															height="40"
															viewBox="0 0 32 32"
															id="fi_3059408"
															fill="currentColor"
														>
															<g id="Layer_3" data-name="Layer 3">
																<path d="M30.035,22.594c-.053-.044-6.049-4.316-7.668-4.049-.781.138-1.227.671-2.122,1.737-.144.172-.491.583-.759.876a12.458,12.458,0,0,1-1.651-.672,13.7,13.7,0,0,1-6.321-6.321,12.458,12.458,0,0,1-.672-1.651c.294-.269.706-.616.882-.764,1.061-.89,1.593-1.337,1.731-2.119.283-1.619-4.005-7.613-4.049-7.667A2.289,2.289,0,0,0,7.7,1C5.962,1,1,7.436,1,8.521c0,.063.091,6.467,7.988,14.5C17.012,30.909,23.416,31,23.479,31,24.563,31,31,26.038,31,24.3A2.291,2.291,0,0,0,30.035,22.594Zm-6.667,6.4C22.5,28.92,17.12,28.211,10.4,21.61,3.767,14.857,3.076,9.468,3.007,8.633A27.054,27.054,0,0,1,7.713,3.072c.04.04.093.1.161.178a35.391,35.391,0,0,1,3.574,6.063,11.886,11.886,0,0,1-1.016.911A10.033,10.033,0,0,0,8.92,11.646a1,1,0,0,0-.171.751,11.418,11.418,0,0,0,.965,2.641,15.71,15.71,0,0,0,7.248,7.247,11.389,11.389,0,0,0,2.641.966,1,1,0,0,0,.751-.171,10.075,10.075,0,0,0,1.427-1.518c.314-.374.733-.873.892-1.014a35.146,35.146,0,0,1,6.076,3.578c.083.07.142.124.181.159A27.036,27.036,0,0,1,23.368,28.992Z"></path>
																<path d="M17,9a6.006,6.006,0,0,1,6,6,1,1,0,0,0,2,0,8.009,8.009,0,0,0-8-8,1,1,0,0,0,0,2Z"></path>
																<path d="M17,4A11.013,11.013,0,0,1,28,15a1,1,0,0,0,2,0A13.015,13.015,0,0,0,17,2a1,1,0,0,0,0,2Z"></path>
															</g>
														</svg>
													</div>
												</span>
												<a style={{ marginTop: 10, fontWeight: 500 }}>Contact us</a>
											</div>
										</div>
										<div className="col-12">
											<a>To view Terms and Condition </a>
											<a href="#">Click Here</a>
										</div>
									</div>
								</div>
							</>
						</div>
					) : (
						<div className={`chat-body-news ${activeChatMenu === 'news' ? 'active' : ''}`}>
							<>
								{isMobile && (
									<>
										<button
											className="btn chat-close-icon"
											style={{ top: '16px', backgroundColor: 'black' }}
											onClick={() => setChatBoxOpen(false)}
										>
											<svg
												id="Component_127_1"
												height="16"
												width="16"
												data-name="Component 127 – 1"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 19.295 19.289"
											>
												<path
													id="Icon_ionic-ios-close"
													data-name="Icon ionic-ios-close"
													d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
													transform="translate(-11.285 -11.289)"
												/>
											</svg>
										</button>
									</>
								)}
								<div className="row" style={{ height: 80 }}>
									<div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
										<img src="https://projectmix.ai/images/logo-dark.svg" alt="logo" className="img-fluid" width={150} height={'80%'} />
									</div>
								</div>
								<div className="row" style={{ height: 200, backgroundColor: 'white', borderRadius: 16, marginRight: 10, marginLeft: 10 }}>
									<div className="col-12 d-flex justify-content-center align-items-center">
										<a style={{ fontSize: 24 }}>Coming Soon</a>
									</div>
								</div>
							</>
						</div>
					)}
					<div className="chat-footer">
						<div className="row" style={{ height: '100%', padding: 10 }}>
							<div
								className={`col-3 d-flex flex-column justify-content-center align-items-center chat-menu ${
									activeChatMenu === 'menu' ? 'active' : ''
								}`}
								onClick={() => setActiveChatMenu('menu')}
							>
								<svg
									height="20"
									viewBox="0 1 511 511.999"
									width="20"
									xmlns="http://www.w3.org/2000/svg"
									id="fi_1946488"
									fill="currentColor"
								>
									<path d="m498.699219 222.695312c-.015625-.011718-.027344-.027343-.039063-.039062l-208.855468-208.847656c-8.902344-8.90625-20.738282-13.808594-33.328126-13.808594-12.589843 0-24.425781 4.902344-33.332031 13.808594l-208.746093 208.742187c-.070313.070313-.144532.144531-.210938.214844-18.28125 18.386719-18.25 48.21875.089844 66.558594 8.378906 8.382812 19.441406 13.234375 31.273437 13.746093.484375.046876.96875.070313 1.457031.070313h8.320313v153.695313c0 30.417968 24.75 55.164062 55.167969 55.164062h81.710937c8.285157 0 15-6.71875 15-15v-120.5c0-13.878906 11.292969-25.167969 25.171875-25.167969h48.195313c13.878906 0 25.167969 11.289063 25.167969 25.167969v120.5c0 8.28125 6.714843 15 15 15h81.710937c30.421875 0 55.167969-24.746094 55.167969-55.164062v-153.695313h7.71875c12.585937 0 24.421875-4.902344 33.332031-13.8125 18.359375-18.367187 18.367187-48.253906.027344-66.632813zm-21.242188 45.421876c-3.238281 3.238281-7.542969 5.023437-12.117187 5.023437h-22.71875c-8.285156 0-15 6.714844-15 15v168.695313c0 13.875-11.289063 25.164062-25.167969 25.164062h-66.710937v-105.5c0-30.417969-24.746094-55.167969-55.167969-55.167969h-48.195313c-30.421875 0-55.171875 24.75-55.171875 55.167969v105.5h-66.710937c-13.875 0-25.167969-11.289062-25.167969-25.164062v-168.695313c0-8.285156-6.714844-15-15-15h-22.328125c-.234375-.015625-.464844-.027344-.703125-.03125-4.46875-.078125-8.660156-1.851563-11.800781-4.996094-6.679688-6.679687-6.679688-17.550781 0-24.234375.003906 0 .003906-.003906.007812-.007812l.011719-.011719 208.847656-208.839844c3.234375-3.238281 7.535157-5.019531 12.113281-5.019531 4.574219 0 8.875 1.78125 12.113282 5.019531l208.800781 208.796875c.03125.03125.066406.0625.097656.09375 6.644531 6.691406 6.632813 17.539063-.03125 24.207032zm0 0"></path>
								</svg>
								Home
							</div>
							<div
								className={`col-3 d-flex flex-column justify-content-center align-items-center chat-menu ${
									activeChatMenu === 'messages' ? 'active' : ''
								}`}
								onClick={() => setActiveChatMenu('messages')}
							>
								<span>
									<svg
										id="fi_3721935"
										enable-background="new 0 0 512 512"
										height="20"
										viewBox="0 0 512 512"
										width="20"
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
									>
										<g>
											<path d="m0 349.022c0 12.187 13.808 19.277 23.711 12.211l82.471-58.832c6.917-4.934 15.065-7.542 23.563-7.542h181.382c31.928 0 57.902-25.975 57.902-57.902v-190.331c0-8.284-6.716-15-15-15h-296.127c-31.927 0-57.902 25.974-57.902 57.902zm30-259.494c0-15.386 12.517-27.902 27.902-27.902h281.126v175.331c0 15.386-12.517 27.902-27.902 27.902h-181.382c-14.78 0-28.952 4.537-40.984 13.12l-58.76 41.917z"></path>
											<path d="m512 205.876c0-31.932-25.974-57.91-57.9-57.91h-40.07c-8.284 0-15 6.716-15 15s6.716 15 15 15h40.07c15.385 0 27.9 12.521 27.9 27.91v230.364l-58.759-41.915c-12.031-8.583-26.202-13.119-40.981-13.119h-181.39c-15.385 0-27.9-12.521-27.9-27.91v-13.439c0-8.284-6.716-15-15-15s-15 6.716-15 15v13.439c0 31.932 25.974 57.91 57.9 57.91h181.39c8.497 0 16.644 2.607 23.56 7.541l82.47 58.83c9.853 7.031 23.711.015 23.711-12.211v-259.49z"></path>
											<path d="m107.862 143.339h173.304c8.284 0 15-6.716 15-15s-6.716-15-15-15h-173.304c-8.284 0-15 6.716-15 15s6.716 15 15 15z"></path>
											<path d="m107.862 213.339h173.304c8.284 0 15-6.716 15-15s-6.716-15-15-15h-173.304c-8.284 0-15 6.716-15 15s6.716 15 15 15z"></path>
										</g>
									</svg>
								</span>
								Messages
							</div>
							<div
								className={`col-3 d-flex flex-column justify-content-center align-items-center chat-menu ${
									activeChatMenu === 'help' ? 'active' : ''
								}`}
								onClick={() => setActiveChatMenu('help')}
							>
								<span>
									<svg
										id="fi_2984971"
										enable-background="new 0 0 64 64"
										height="20"
										viewBox="0 0 64 64"
										width="20"
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
									>
										<g>
											<g id="LWPOLYLINE">
												<g>
													<path d="m31.321 38.904c-.006 0-.012 0-.018 0-1.104-.01-1.992-.913-1.982-2.018l.009-1c0-.057.003-.115.009-.172.277-2.909 2.208-4.692 3.761-6.125.526-.486 1.024-.945 1.448-1.424.517-.583 1.267-1.777.478-3.218-.909-1.664-3.129-2.135-4.854-1.74-1.802.412-2.466 1.951-2.702 2.831-.286 1.067-1.383 1.7-2.45 1.415-1.067-.286-1.7-1.383-1.415-2.45.797-2.977 2.865-5.052 5.673-5.695 3.779-.863 7.585.664 9.256 3.719 1.391 2.544 1.01 5.529-.995 7.791-.557.628-1.152 1.178-1.728 1.709-1.435 1.325-2.332 2.211-2.484 3.49l-.008.903c-.008 1.101-.902 1.984-1.998 1.984z"></path>
												</g>
											</g>
											<g id="POINT">
												<g>
													<path d="m31.32 44.9c-.52 0-1.04-.21-1.41-.58-.38-.37-.59-.89-.59-1.42 0-.52.21-1.04.59-1.41.74-.74 2.08-.74 2.82 0 .38.37.59.89.59 1.41 0 .53-.21 1.04-.58 1.42-.38.37-.89.58-1.42.58z"></path>
												</g>
											</g>
											<g id="CIRCLE_2_">
												<g>
													<path d="m31.923 57.997c-2.275 0-4.53-.296-6.703-.881-9.364-2.519-16.696-10.276-18.679-19.763-2.021-9.665 1.709-19.825 9.504-25.884 4.506-3.503 10.189-5.433 16.003-5.433 4.025 0 8.022.936 11.56 2.705 8.737 4.371 14.382 13.503 14.382 23.266 0 7.966-3.759 15.644-10.055 20.538-4.522 3.516-10.209 5.452-16.012 5.452zm.126-47.961c-4.929 0-9.741 1.63-13.548 4.591-6.597 5.128-9.755 13.727-8.044 21.907 1.678 8.025 7.881 14.588 15.803 16.719 1.834.493 3.74.744 5.663.744h.001c4.918 0 9.732-1.637 13.557-4.61 5.329-4.142 8.51-10.639 8.51-17.38 0-8.261-4.777-15.989-12.171-19.688-2.986-1.493-6.364-2.283-9.771-2.283z"></path>
												</g>
											</g>
										</g>
									</svg>
								</span>
								Help
							</div>
							<div
								className={`col-3 d-flex flex-column justify-content-center align-items-center chat-menu ${
									activeChatMenu === 'news' ? 'active' : ''
								}`}
								onClick={() => setActiveChatMenu('news')}
							>
								<span>
									<svg
										id="fi_2692815"
										enable-background="new 0 0 32 32"
										height="20"
										viewBox="0 0 32 32"
										width="20"
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
									>
										<path d="m29.7 10.5c-2-5.4-5.6-9.2-8.8-9.2-.5 0-.9.1-1.3.2-.9.3-1.7 1-2.2 1.9-6 6.8-14.3 9.9-14.4 9.9-2 .7-2.6 3.9-1.3 7.4 1.1 3 3.1 4.9 4.9 4.9.2 0 .5 0 .7-.1s1.3-.3 3.3-.6l2 4.2c.1.2.3.4.6.5l3.2 1.1c.1 0 .2.1.3.1.2 0 .4-.1.6-.2.3-.2.4-.6.4-.9l-.7-5.7c2.5-.2 5.3-.3 8.1-.1h.2c.4.1.8.2 1.2.2.5 0 .9-.1 1.3-.2 1.7-.6 2.9-2.5 3.2-5.2.2-2.4-.2-5.4-1.3-8.2zm-15.5 17.3-1.5-3.3 2.3-.3.5 4zm7.2-9.6c-.7-1.1-1.4-2.4-2-4-.5-1.5-.9-2.9-1-4.3.3 0 .7.3 1.1.6.7.7 1.4 1.8 1.8 3 .8 2.3.6 4.1.1 4.7zm-14.6 5.4c-.1 0-.1 0-.1 0-.7.2-2.2-1.1-3.2-3.6-.9-2.5-.5-4.6.1-4.8.3-.1 6.8-2.5 12.7-7.9-.1 2.3.3 5 1.2 7.6 1.1 2.9 2.6 5.3 4.3 7-7.8-.1-14.7 1.6-15 1.7zm22.1-5.1c-.2 1.9-.9 3.1-1.9 3.5-.2.1-.4.1-.6.1-1.1 0-2.4-.8-3.8-2.4 1.4-1.2 1.7-3.9.6-6.9-.6-1.5-1.4-2.8-2.3-3.7-.8-.8-1.7-1.2-2.6-1.2 0-.4 0-.7.1-1 .2-1.9.9-3.1 1.9-3.5.2-.1.4-.1.6-.1 2 0 5.1 3 6.9 7.9 1 2.5 1.4 5.2 1.1 7.3z"></path>
									</svg>
								</span>
								News
							</div>
						</div>
					</div>
				</div>
				<div className={`chat-toggle ${chatBoxOpen ? 'open' : ''}`} onClick={() => setChatBoxOpen(!chatBoxOpen)}>
					<span>
						<svg
							id="fi_10541409"
							height="30"
							width="30"
							enable-background="new 0 0 512 512"
							viewBox="0 0 512 512"
							xmlns="http://www.w3.org/2000/svg"
							fill={'white'}
						>
							<g>
								<g>
									<path d="m489.57 208.07c-6.21-104.54-93.22-187.67-199.29-187.67h-68.56c-106.07 0-193.08 83.13-199.29 187.67-13.18 5.68-22.43 18.81-22.43 34.05v77.33c0 20.44 16.63 37.07 37.07 37.07h30v-151.47h-14.34c7.62-86.54 80.5-154.65 168.99-154.65h68.56c88.49 0 161.37 68.11 168.99 154.65h-14.34v151.47h30c20.44 0 37.07-16.63 37.07-37.07v-77.33c0-15.24-9.25-28.37-22.43-34.05z"></path>
									<path d="m373.87 158.1h-235.74c-22.64 0-41.06 18.42-41.06 41.06v186.88c0 22.64 18.42 41.06 41.06 41.06h51.41l50 59.18c2.85 3.37 7.04 5.32 11.46 5.32s8.61-1.95 11.46-5.32l50-59.18h61.41c22.64 0 41.06-18.42 41.06-41.06v-186.88c0-22.64-18.42-41.06-41.06-41.06zm-63.05 75.12c8.28 0 15 6.71 15 15 0 8.28-6.72 15-15 15s-15-6.72-15-15c0-8.29 6.72-15 15-15zm-115.99 0c8.28 0 15 6.71 15 15 0 8.28-6.72 15-15 15s-15-6.72-15-15c0-8.29 6.72-15 15-15zm135.94 91c-23.06 20.45-48.92 30.68-74.77 30.68-25.86 0-51.71-10.22-74.77-30.68-6.2-5.49-6.77-14.97-1.27-21.17s14.98-6.77 21.18-1.27c17.43 15.46 36.14 23.19 54.86 23.19s37.44-7.73 54.87-23.19c6.19-5.5 15.67-4.93 21.17 1.27s4.93 15.68-1.27 21.17z"></path>
								</g>
							</g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
						</svg>
					</span>
					<span>
						<svg height="22" viewBox="0 0 329.26933 329" width="22" xmlns="http://www.w3.org/2000/svg" id="fi_1828778" fill={'white'}>
							<path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path>
						</svg>
					</span>
				</div>
			</div>
		</>
	);
};
export default Chatbox;
