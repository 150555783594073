import React, { useEffect, useRef, useState } from 'react';
import './notifications.scss';
import NotificationTextParser from 'ui-component/NotificationTextParser';
import moment from 'moment';
import { ImSpinner2 } from 'react-icons/im';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { GoBellSlash } from "react-icons/go";

function Notifications() {
	const [isLoading, setIsLoading] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [nextLink, setNextLink] = useState(null);

	const getNotifications = () => {
		setIsLoading(true);
		api.getUserNotificationsList(nextLink).then((res) => {
			if (res?.status) {
				setNextLink(
					res?.data?.links?.[res?.data?.links?.length - 1]?.url ? res?.data?.links?.[res?.data?.links?.length - 1]?.url : null
				);
				// console.log(res?.data?.data);
				setNotifications((oldNots) => [...oldNots, ...res?.data?.data]);
			}
			setIsLoading(false);
		});
	};

	useEffect(() => {
		getNotifications();
	}, []);

	return (
		<div className="row notifications-page">
			<div className="col-12">
				<div className="section-head">
					<h2>Notifications</h2>
					<div className="actions">
						{/* <Link to={'/contacts/supplier/create'} className="btn btn-primary btn-outlined-main">
							<FaPlus /> New Supplier
						</Link> */}
					</div>
				</div>
				<div className="card shadow-sm bg-white p-2">
					<div className="notifications">
						{notifications?.length > 0 ? (
							Object.keys(_.groupBy(notifications, (n) => moment(n?.date).format('YYYY-MM-DD')))?.map((group) => (
								<>
									<h6 className="date-group m-0">{moment(group).format('MMMM DD, YYYY')}</h6>
									{notifications.map(
										(noti) =>
											moment(noti.date).format('YYYY-MM-DD') == group && (
												<div className="notification">
													<div className="notification-body">
														<div className="notification-head">
															<p>{moment(noti.date).format('h:mm a')}</p>
														</div>
														<div className="notification-content">
															<NotificationTextParser inputString={noti?.details} />
														</div>
													</div>
												</div>
											)
									)}
								</>
							))
						) : (
							<div className="not-found-container">
								<div className="not-found-icon">
									<GoBellSlash />
								</div>
								<h6 className="not-found-text">You have no notifications at the moment</h6>
							</div>
						)}
						{!!nextLink && (
							<div className="text-center">
								<button
									className="btn btn-link"
									disabled={isLoading}
									onClick={() => {
										getNotifications();
									}}
								>
									{isLoading ? <ImSpinner2 className="fa-spin" /> : 'Show More'}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Notifications;
