import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import toast from 'react-hot-toast';
import useSound from 'use-sound';
import errorSound from '../../../../assets/audio/error.mp3';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiPackageBold, PiTestTubeFill, PiWarningFill } from 'react-icons/pi';
import { TbDatabaseExclamation } from 'react-icons/tb';
import NoImage from 'assets/images/no-image.png';

const UpdateReplacementProductModal = ({
	openProductReplacementPopup,
	returnsDetail,
	handleProduct,
	productDetail,
	handleUpdateRecord,
	handleClose,
	handleSave,
	disableStatus
}) => {
	const [alertMessage, setAlertMessage] = useState();
	const [invoiceList, setInvoiceList] = useState([]);
	const [imeiInputValue, setImeiInputValue] = useState('');
	const [showRepairs, setShowRepairs] = useState(false);
	const [stockImeis, setStockImeis] = useState([]);
	const [currentProductDetails, setCurrentProductDetails] = useState([]);
	// const [standardRepairTypes, setStandardRepairTypes] = useState([]);
	const imeiInputRef = useRef(null);
	const [errorSoundPlay] = useSound(errorSound);
	const [selectedRepairs, setSelectedRepairs] = useState('');

	const [selectedInboundItems, setSelectedInboundItems] = useState([]);

	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

	const returnReasons = [
		{ label: 'Wrong product', value: 1 },
		{ label: 'Defective', value: 2 },
		{ label: 'Unknown', value: 3 },
		{ label: 'Wrong size', value: 4 }
	];

	const [formInfo, setFormInfo] = useState({
		estimationId: ''
	});

	const handleIMEIInputKeydown = (event) => {
		if (event.key === 'Enter') {
			addModalImei();
		}
	};

	// const selectedRepairsNeededOptions = standardRepairTypes.filter(option => productDetail?.repairs.includes(option.label));

	const showErrorDialog = (title, message = '') => {
		setErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			show: false,
			message: '',
			title: ''
		});
	};

	const handleDeleteImei = (index) => {
		const newStockImeis = stockImeis.filter((_, i) => i !== index);
		setStockImeis(newStockImeis);
	};

	const handleReasonForReturn = (selected, index) => {
		setCurrentProductDetails((prevDetail) => ({ ...prevDetail, repair_reason: selected.label }));
		if (selected?.value == 2) {
			setShowRepairs(true);
		} else {
			setShowRepairs(false);
		}
	};

	const handleSetSelectedRepairs = (selected) => {
		setCurrentProductDetails({ ...currentProductDetails, repairs: selected });
	};

	const handleRepairCost = (value) => {
		setCurrentProductDetails({ ...currentProductDetails, cost: value });
	};

	const handleStockPrice = (value) => {
		const price = value.target.value;

		setCurrentProductDetails({ ...currentProductDetails, product: { ...currentProductDetails.product, price } });
	};

	const addModalImei = async () => {
		if (!imeiInputValue || !imeiInputValue.trim()) {
			showErrorDialog('Invalid IMEI', `Invalid IMEI Entered`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}

		const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

		if (!alphanumericRegex.test(imeiInputValue)) {
			showErrorDialog('Invalid IMEI', `Please enter a valid IMEI Number`);
			setImeiInputValue('');
			imeiInputRef.current.value = '';
			errorSoundPlay();
			return;
		}
		api.getAvailableReturnsImei(returnsDetail?.estimationId, { imei: imeiInputValue }).then((res) => {
			if (res?.status) {
				const tempImei = res?.data;
				tempImei.is_repair = false;
				setStockImeis([...stockImeis, tempImei]);
				setImeiInputValue('');
				imeiInputRef.current.value = '';
			} else {
				showErrorDialog('Invalid IMEI', `Couldn't find IMEI ${imeiInputValue} from the selected order`);
				setImeiInputValue('');
				imeiInputRef.current.value = '';
				errorSoundPlay();
			}
		});
	};

	const handleSubmit = () => {
		if (returnsDetail?.id) {
			console.log('with id');

			toast.promise(
				api.updateReplacementProducts(returnsDetail?.id, currentProductDetails).then((res) => {
					// setDisable(false);
					if (res.status) {
						handleUpdateRecord();
					}
					return res?.status ? res : null;
				}),
				{
					loading: 'Saving Details...',
					success: (data) => `${data.message}`,
					error: <b>Failed to save details</b>
				}
			);
			// api.updateReturnedProducts(returnsDetail?.id, currentProductDetails)
			//     .then((res) => {
			//         handleUpdateRecord();
			//     });
		} else {
			//     setSelectedImeis(prevImeis => {
			//         const newData = [...prevImeis, ...data];
			//         // console.log("New Data:", newData); // logs the new data
			//         handleSetImeis(newData); // passes the new data
			//         return newData;
			//     });
		}

		// handleProduct(stockImeis);
	};

	useEffect(() => {
		api.getUnassignedInvoiceNumberList().then((res) => {
			const tempInvoiceList = res.data.map((item) => ({
				id: item.id,
				label: `${item.invoice_number} - ${item?.customer_name}`,
				value: item.id
			}));
			setInvoiceList(tempInvoiceList);
		});
		// api.getReturnsStandardRepairTypes().then((res) => {
		// 	if (res.status) {
		// 		const tempRepairs = res.data.map((item) => ({
		// 			id: item.id,
		// 			label: `${item.name}`,
		// 			value: item.id
		// 		}));
		// 		setStandardRepairTypes(tempRepairs);
		// 	}
		// });
		// setFormInfo({
		// 	...formInfo,
		// 	productId: returnsDetails?.id
		// });
		setCurrentProductDetails(productDetail);
		// const selectedOptions = standardRepairTypes.filter(option => productDetail?.repairs.includes(option.name));
		// console.log(productDetail?.repairs.includes(option.label));
		// console.log(productDetail)
		if (productDetail?.repair_reason == 'Defective') {
			setShowRepairs(true);
		} else {
			setShowRepairs(false);
		}
	}, [productDetail]);

	const onSubmit = () => {
		if (formInfo.estimationId == '') {
			setAlertMessage({
				user: 'Please select order.'
			});
		} else {
			const data = {
				...formInfo,
				estimationId: formInfo.estimationId
			};
			handleSave(data);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const disablepopup = () => {
		handleClose();
	};
	return (
		<>
			<Modal show={openProductReplacementPopup} onHide={disablepopup} centered size="md" backdrop="static">
				<Modal.Body>
					<div className="returns-popup-body">
						<div className="foundListPopup">
							<div className="d-flex align-items-center text-center justify-content-between mb-2 ml-0 pl-0">
								<h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0">Replacement Details</h5>
								<button className="btn modal-close-icon" onClick={handleClose}>
									<svg
										id="Component_127_1"
										height="16"
										width="16"
										data-name="Component 127 – 1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 19.295 19.289"
									>
										<path
											id="Icon_ionic-ios-close"
											data-name="Icon ionic-ios-close"
											d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
											transform="translate(-11.285 -11.289)"
										/>
									</svg>
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-12">
									{/* <form className="assign found"> */}
									<div className="row">
										<div className="col-md-12">
											<table className="table ">
												<tbody>
													<tr>
														<td className="text-center" width="8%">
															{
																productDetail?.product?.thumbnail_url ? (
																	<>
																		<img style={{ height: '80px' }} src={productDetail?.product?.thumbnail_url} />
																	</>
																) : (
																	<>
																		<img style={{ height: '80px' }} src={NoImage} />
																	</>
																)
															}
														</td>
														<td className="font14 v-align-middle">
															<div className="row">
																<div className="col-12">
																	<div className="row">
																		<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																			IMEI
																		</div>
																		<div className="col-9" style={{ fontWeight: '500' }}>
																			{productDetail?.imei}
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																			Model
																		</div>
																		<div className="col-9" style={{ fontWeight: '500' }}>
																			{productDetail?.product?.product_name}
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-3" style={{ fontWeight: '500', color: '#919191' }}>
																			Warranty
																		</div>
																		<div className="col-9" style={{ fontWeight: '500' }}>
																			<span className={productDetail?.warranty?.status == 'Valid' ? 'text-success' : 'text-danger'}>
																				{productDetail?.warranty?.message}
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
													<tr>
														<td colSpan={2}>
															<div className="row" style={{ marginTop: '5px' }}>
																<div className="col-12">
																	<div className="p-inputgroup flex-1">
																		<span className="p-inputgroup-addon">
																			<i className="pi pi-user"></i>
																		</span>
																		<input
																			type="number"
																			className="form-control"
																			value={currentProductDetails?.product?.price.replace(/,/g, '')}
																			onChange={(e) => {
																				handleStockPrice(e);
																			}}
																			onFocus={(event) => {
																				event.target.select();
																			}}
																		/>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="btnarea">
												<button disabled={disableStatus} onClick={() => handleSubmit()} className="btn btn-main">
													<FaPlus /> Save
												</button>
											</div>
										</div>
									</div>
									{/* </form> */}
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{errorDialog?.show && (
				<ActionConfirmationModal
					show={errorDialog?.show}
					message={errorDialog?.message}
					title={errorDialog?.title}
					noCancel
					customConfirmText="Okay"
					customIcon={<PiWarningFill />}
					handleConfirm={() => {
						handleCloseErrorDialog();
					}}
					handleClose={() => {
						handleCloseErrorDialog();
					}}
				/>
			)}
		</>
	);
};

export default UpdateReplacementProductModal;
