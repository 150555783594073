import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { FaPlus, FaSave } from 'react-icons/fa';
import Scroller from 'ui-component/Scroller';
import { Dropdown } from 'react-bootstrap';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import ReplacementModal from './modals/ReplacementModal';
import { TbDatabaseExclamation } from 'react-icons/tb';
import UpdateReplacementProductModal from './modals/UpdateReplacementProductModal';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import usePermissions from 'hooks/usePermissions';
import { Tooltip } from '@mui/material';
import NoImage from 'assets/images/no-image.png';

const ReturnsReplacementComponent = ({
	returnsDetail,
	disableProductBtn,
	handleSetReplacements,
	handleLoadRecord
	// productDetails,
	// openModalFormWarehouse
}) => {
	const topSection = useRef(null);
	const [allPermissions, hasEveryPermission] = usePermissions();
	const [openPopup, setOpenPopup] = useState(false);
	const [isDisable, setDisable] = useState(false);
	const [imeiLists, setImeiLists] = useState([]);
	const [showDelete, setShowDelete] = useState('');
	const [selectedImeis, setSelectedImeis] = useState([]);
	const [disableReceiveBtn, setDisableReceiveBtn] = useState(true);
	const [openProductReplacementPopup, setOpenProductReplacementPopup] = useState(false);
	const [productReplacementDetail, setProductReplacementDetail] = useState([]);
	const [selectedReplacementProduct, setSelectedReplacementProduct] = useState('');
	const [showCreationDelete, setShowCreationDelete] = useState('');
	const [showCreationDeleteIndex, setShowCreationDeleteIndex] = useState(0);
	const [stocks, setStocksDetails] = useState([]);

	const handleEditReplacementProductPopup = (item) => {
		setOpenProductReplacementPopup(true);
		setProductReplacementDetail(item);
		console.log(item);
		// console.log("Item:", item)
		// console.log("returnedId:", returnedId)
	};

	const handleOpenModal = (estimationId) => {
		setOpenPopup(true);
		// api.getReturnsEstimationItems(estimationId).then((res) => {
		//     // console.log(res)
		//     res.data.map((item) => {
		//         item?.stocks?.map((stock) => {
		//             setImeiLists([...imeiLists, stock]);
		//             console.log(stock);
		//         });
		//     });
		// 	// const tempInvoiceList = res.data.map((item) => ({
		// 	// 	id: item.id,
		// 	// 	label: `${item.invoice_number} - ${item?.customer_name}`,
		// 	// 	value: item.id
		// 	// }));
		// 	// setInvoiceList(tempInvoiceList);
		// });
	};

	const closePopup = () => {
		setOpenPopup(false);
		setOpenProductReplacementPopup(false);
		setShowDelete(false);
		setShowCreationDelete(false);
	};

	const handleSave = (data) => {
		// console.log(data);
		api.getInvoiceNumberCustomer(data?.estimationId).then((res) => {
			setInvoiceDetails({
				estimationId: res.data?.id,
				invoiceNumber: res.data?.invoice_number,
				customerId: res.data?.customer_id,
				customerName: res.data?.customer_name
			});
			handleSetEstimationId(res.data?.id);

			setReturnsDetail({
				...returnsDetail,
				estimationId: res.data?.id,
				invoiceNumber: res.data?.invoice_number,
				customerId: res.data?.customer_id,
				customerName: res.data?.customer_name
			});
		});
		setOpenPopup(false);
		// toast.promise(
		// 	api
		// 		.assignProductWarehouse({
		// 			product_id: data.productId,
		// 			warehouse_id: data.warehouseId,
		// 		})
		// 		.then((res) => {
		// 			setDisable(false);
		// 			if (res.status) {
		// 				setOpenWarehousePopup(false);
		// 				getProductDetails();
		// 			}
		// 			return res?.status ? res : null;
		// 		}),
		// 	{
		// 		loading: 'Assigning Warehouse...',
		// 		success: (data) => `${data.message}`,
		// 		error: <b>Failed to assign product warehouse</b>
		// 	}
		// );
	};

	const handleReplacementImeis = (data, action) => {
		setDisableReceiveBtn(false);

		if (returnsDetail?.id) {
			console.log('with id');

			api.addReplacementProducts(returnsDetail?.id, data).then((res) => {
				handleLoadRecord();
			});
		} else {
			setSelectedImeis((prevImeis = []) => {
				const newData = [...prevImeis, ...data];
				// console.log("New Data:", newData); // logs the new data
				handleSetReplacements(newData); // passes the new data
				return data;
			});
			// setSelectedImeis(prevImeis => {
			//     const newData = [...prevImeis, ...data];
			//     // console.log("New Data:", newData); // logs the new data
			//     handleSetReplacements(newData); // passes the new data
			//     return newData;
			// });
		}

		if (action == 'empty') {
			setOpenPopup(true);
		} else {
			setOpenPopup(false);
		}
	};

	const handleImeis = (data) => {
		setDisableReceiveBtn(false);

		if (returnsDetail?.id) {
			console.log('with id');

			api.addReturnedProducts(returnsDetail?.id, data).then((res) => {
				handleLoadRecord();
			});
		} else {
			setSelectedImeis((prevImeis) => {
				const newData = [...prevImeis, ...data];
				// console.log("New Data:", newData); // logs the new data
				handleSetImeis(newData); // passes the new data
				return newData;
			});
		}
		setOpenPopup(false);
	};

	const handleUpdateRecord = () => {
		handleLoadRecord();
		setOpenProductReplacementPopup(false);
	};

	const handleDeleteProduct = (item, index) => {
		if (returnsDetail?.id) {
			setShowDelete(item);
			setSelectedReplacementProduct(item?.id);
		} else {
			setShowCreationDelete(item);
			setShowCreationDeleteIndex(index);
		}
		// setShowDelete(item);

		// setSelectedReplacementProduct(item?.id);
	};

	const handleCreationDelete = () => {
		const newSelectedImeis = [...selectedImeis]; // Create a copy of the array
		newSelectedImeis.splice(showCreationDeleteIndex, 1); // Remove the record at the specified index
		setSelectedImeis(newSelectedImeis); // Update the state
		setShowCreationDelete('');
		setShowCreationDeleteIndex(0);
	};

	const handleDelete = () => {
		toast.promise(
			api.deleteReplacementProducts(selectedReplacementProduct).then((res) => {
				if (res.status) {
					setShowDelete('');
					setSelectedReplacementProduct('');
					handleLoadRecord();
				}
				return res?.status ? res : null;
			}),
			{
				loading: 'Deleting Product Returns...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete product returns</b>
			}
		);
	};
	useEffect(() => {
		if (returnsDetail?.estimationId) {
			setDisableReceiveBtn(false);
			setSelectedImeis(returnsDetail?.replacements);
		}
	}, [returnsDetail]);

	return (
		<>
			<div className="card card-shadow p-2 card-box-shadow">
				<div className="row inbounding-imeis">
					<div className="col-xl-12 col-lg-12">
						<div className="row">
							<div className="col-12">
								<div className="d-flex align-items-center justify-content-between TableSerachBar">
									<div className={selectedImeis?.length > 0 ? 'returns-section-header' : 'returns-section-header gray'}>
										Replacement Products
										{/* <Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} /> */}
									</div>
									<div className="form-inline navbar-search">
										<button
											className="bulk-action btn-outlined-main"
											onClick={() => {
												handleOpenModal(returnsDetail?.estimationId);
											}}
											disabled={disableProductBtn}
										>
											<FaPlus style={{ fontSize: '10px' }} /> Add Product
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								{selectedImeis?.length > 0 && (
									<>
										<Scroller extraHeight={topSection?.current?.clientHeight || 0}>
											<table className="table text-gray-800">
												<thead>
													<tr>
														<th scope="col" width="8%"></th>
														<th scope="col" width="60%" className="font14">
															Product
														</th>
														<th scope="col" width="15%" className="font14">
															Price
														</th>
														<th scope="col" width="5%" className="font14"></th>
														{/* <th scope="col"></th> */}
													</tr>
												</thead>
												<tbody>
													{selectedImeis?.map((item, index) => {
														return (
															<tr>
																<td
																	className="text-center"
																	onClick={() => {
																		if (hasEveryPermission(['Edit Returns (Button)'])) {
																			handleEditReplacementProductPopup(item);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['Edit Returns (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	{
																		item?.product?.thumbnail_url ? (
																			<>
																			<img style={{ height: '50px' }} src={item?.product?.thumbnail_url} />
																			</>
																		): (
																			<>
																				<img style={{ height: '50px' }} src={NoImage} />
																			</>
																		)
																	}
																</td>
																<td
																	className="font14 v-align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['Edit Returns (Button)'])) {
																			handleEditReplacementProductPopup(item);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['Edit Returns (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	<label>{item?.imei}</label>
																	<div style={{ marginTop: '-8px' }} className="textGray">
																		{item?.product?.product_name}
																	</div>
																</td>
																<td
																	className="font14 v-align-middle"
																	onClick={() => {
																		if (hasEveryPermission(['Edit Returns (Button)'])) {
																			handleEditReplacementProductPopup(item);
																		}
																	}}
																	style={{
																		cursor: hasEveryPermission(['Edit Returns (Button)']) ? 'pointer' : 'not-allowed'
																	}}
																>
																	$ {item?.product?.price}
																</td>
																<td className="font14 v-align-middle text-center">
																	<PermissionAllowed permissions={['Delete Returns (Button)']}>
																		<a
																			href="javascript:void(0);"
																			onClick={() => {
																				// history(`/returnss/edit/${row?.id}`);
																				handleDeleteProduct(item, index);
																			}}
																		>
																			<Tooltip title={`Remove ${item?.imei}`} arrow placement="top">
																				<svg
																					id="vuesax_linear_trush-square"
																					data-name="vuesax/linear/trush-square"
																					xmlns="http://www.w3.org/2000/svg"
																					width="20"
																					height="20"
																					viewBox="0 0 31.855 31.855"
																				>
																					<g id="trush-square">
																						<path
																							id="Vector"
																							d="M0,17.255V9.291C0,2.655,2.655,0,9.291,0h7.964c6.637,0,9.291,2.655,9.291,9.291v7.964c0,6.637-2.655,9.291-9.291,9.291H9.291C2.655,26.546,0,23.891,0,17.255Z"
																							transform="translate(2.655 2.655)"
																							fill="none"
																							stroke="#ff342d"
																							stroke-linecap="round"
																							stroke-linejoin="round"
																							stroke-width="2"
																						/>
																						<g id="Group" transform="translate(8.097 8.548)">
																							<path
																								id="Vector-2"
																								data-name="Vector"
																								d="M0,.438,1.779.265A51.533,51.533,0,0,1,6.942,0a86.992,86.992,0,0,1,8.72.438"
																								transform="translate(0 3.026)"
																								fill="none"
																								stroke="#ff342d"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="2"
																							/>
																							<path
																								id="Vector-3"
																								data-name="Vector"
																								d="M0,2.588.186,1.447C.319.624.425,0,1.9,0H4.181C5.654,0,5.76.65,5.893,1.447l.186,1.128"
																								transform="translate(4.792)"
																								fill="none"
																								stroke="#ff342d"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="2"
																							/>
																							<path
																								id="Vector-4"
																								data-name="Vector"
																								d="M11.932,0l-.571,8.76c-.093,1.367-.173,2.429-2.6,2.429H3.172c-2.429,0-2.509-1.062-2.6-2.429L0,0"
																								transform="translate(1.858 3.57)"
																								fill="none"
																								stroke="#ff342d"
																								stroke-linecap="round"
																								stroke-linejoin="round"
																								stroke-width="2"
																							/>
																						</g>
																						<path id="Vector-5" data-name="Vector" d="M0,0H31.855V31.855H0Z" fill="none" opacity="0" />
																					</g>
																				</svg>
																			</Tooltip>
																		</a>
																	</PermissionAllowed>
																	{/* <Dropdown className="btnDel iconbtn">
                                                                                <Dropdown.Toggle
                                                                                    as="span"
                                                                                // id={`dropdown-toggle-${index}`}
                                                                                >
                                                                                    <i className="fas fa-ellipsis-h text-gray-900"></i>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <PermissionAllowed
                                                                                        permissions={['Delete Returns (Button)']}
                                                                                    >
                                                                                        <Dropdown.Item
                                                                                            onClick={() => {
                                                                                                // history(`/returnss/edit/${row?.id}`);
                                                                                                handleDeleteProduct(item)
                                                                                            }}
                                                                                        >
                                                                                            Delete
                                                                                        </Dropdown.Item>
                                                                                    </PermissionAllowed>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown> */}
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</Scroller>
									</>
								)}
								{/* <div className="table-responsive-xl tableData customersList"> */}
								{/* </div> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			{openPopup && (
				<ReplacementModal
					openPopup={openPopup}
					returnsDetail={returnsDetail}
					handleReplacementImeis={handleReplacementImeis}
					handleClose={closePopup}
					handleSave={handleSave}
					disableStatus={isDisable}
				/>
			)}

			{openProductReplacementPopup && (
				<>
					{console.log('asd')}
					<UpdateReplacementProductModal
						openProductReplacementPopup={openProductReplacementPopup}
						returnsDetail={returnsDetail}
						handleImeis={handleImeis}
						productDetail={productReplacementDetail}
						handleUpdateRecord={handleUpdateRecord}
						handleClose={closePopup}
						handleSave={handleSave}
						disableStatus={isDisable}
					/>
				</>
			)}

			{showDelete && (
				<DeleteConfirmation
					show={showDelete}
					handleClose={closePopup}
					handleDelete={handleDelete}
					title="Confirm"
					message={`Are you sure you want to delete ${showDelete?.imei}`}
				/>
			)}
			{showCreationDelete && (
				<DeleteConfirmation
					show={showCreationDelete}
					handleClose={closePopup}
					handleDelete={handleCreationDelete}
					title="Confirm"
					message={`Are you sure you want to delete ${showCreationDelete?.imei}`}
				/>
			)}
		</>
	);
};

export default ReturnsReplacementComponent;
