import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaFileInvoice } from 'react-icons/fa';
import moment from 'moment';
import { IoPerson } from 'react-icons/io5';
import CurrencyValue from 'ui-component/CurrencyValue';

function getStatusColorClass(paidStatus, masterStatus) {
	if (paidStatus === 'Paid' && masterStatus === 'Closed') {
		return 'status-Default';
	} else if (paidStatus === 'Paid') {
		return 'status-Success';
	} else if (paidStatus === 'Unpaid') {
		return 'status-Danger';
	} else if (paidStatus === 'Partial') {
		return 'status-Warning';
	} else {
		return '';
	}
}

const Estimate = ({ row, status, viewDetails }) => {
	return (
		<>
			{row &&
				row.length > 0 &&
				row.map((item, index) => {
					let currencySymbol = '';
					currencySymbol = item.currency == 'eur' ? '€' : item.currency == 'usd' ? '$' : 'AED';
					const [expiresAt, setExpiresAt] = useState('flase');

					useEffect(() => {
						const currentDate = new Date();
						const expireDate = new Date(item?.expires_at);

						if (expireDate < currentDate) {
							setExpiresAt('true');
						} else {
							setExpiresAt('false');
						}
					}, [item]);

					const totalWithSign = item.total < 0 ? `-${Math.abs(item?.total)}` : `+${item?.total}`;
					const estimatedContacts = expiresAt === 'false' ? 'estimated' : 'expired';
					const estimatedColumn = expiresAt === 'false' ? 'estimatedColumn' : 'expiredColumn';
					const estimatedIcon = expiresAt === 'false' ? 'estimatedIcon' : 'expiredIcon';
					const estimateTypeText = estimatedContacts === 'estimated' ? 'Estimated' : 'Expired';

					return (
						<>
							{status == 'Estimates' && item?.master_status?.name == 'Estimated' ? (
								<>
									<div
										key={index}
										className="estimate-option-item mt-2"
										onClick={() => {
											viewDetails(item?.id);
										}}
									>
										<div className={`estimate-option-item-body Estimates-Contacts ${estimatedContacts}`}>
											<div className={`column ${estimatedColumn}`}>
												<div class="colored-right-line"></div>
												<div className="IconDiv">
													<FaFileInvoice className={`Icon ${estimatedIcon}`} />
												</div>

												<div className="EstimateNumberType">
													<div className="EstimateNumber">{item?.estimation_number}</div>
													<p className="EstimateType">{estimateTypeText}</p>
												</div>
											</div>
											<div className="column-center">
												{item?.estimation_details.map((estimationDetail, index) => (
													<div key={index} className="centerText">
														<p className="">
															{index > 0 && <span>, &nbsp;</span>}
															{estimationDetail?.qty} {estimationDetail?.product?.model?.category?.name}
														</p>
													</div>
												))}
											</div>
											<div className="column">
												<div className="EstimateNumberDate">
													<div className="EstimateNumber">
														<CurrencyValue currencyObject={item?.converted_currency_total} />
													</div>
													<p className="EstimateType">{moment(item?.estimation_date).format('MMM DD')}</p>
												</div>
											</div>
										</div>
									</div>
								</>
							) : status === 'Invoices' && item?.master_status?.name != 'Estimated' ? (
								<>
									<div
										className="estimate-option-item mt-2"
										onClick={() => {
											viewDetails(item?.id);
										}}
									>
										<div
											className={`estimate-option-item-body Invoice-Contacts ${getStatusColorClass(
												item?.paid_status,
												item?.master_status.name
											)}`}
										>
											<div className={`column-1 `}>
												<div class="colored-right-line"></div>
												<div className="IconDiv">
													<FaFileInvoice className={`Icon ${estimatedIcon}`} />
												</div>
												<div className="EstimateNumberType">
													<div className="EstimateNumber">{item?.invoice_number}</div>
													<div className="invoicedetailsText">
														{item?.estimation_details.map((estimationDetail, index) => (
															<div key={index}>
																<p className="EstimateType">
																	{index > 0 && <span>, &nbsp;</span>}
																	{estimationDetail?.qty} {estimationDetail?.product?.model?.category?.name}
																</p>
															</div>
														))}
													</div>
												</div>
											</div>
											<div className="column-2">
												<p className="centerText">
													{' '}
													<IoPerson />
													&nbsp; {item?.sales_person?.first_name} {item?.sales_person?.last_name}{' '}
												</p>
											</div>
											<div className="column-2">
												<p className={`status`}> {item?.paid_status} </p>
											</div>
											<div className="column-2">
												<p className="centerText"> {item?.master_status.name} </p>
											</div>
											<div className="column-2">
												<div className="EstimateNumberDate">
													<div className="EstimateNumber">
														<CurrencyValue currencyObject={item?.converted_currency_total} />
													</div>
													<p className="EstimateType">{moment(item?.estimation_date).format('MMM DD')}</p>
												</div>
											</div>
										</div>
									</div>
								</>
							) : status === 'Credit' ? (
								<>
									<div className="estimate-option-item mt-2">
										<div className={`estimate-option-item-body Invoice-Contacts Invoice-Status`}>
											<div className={`column-1 status-Default`}>
												<div class="colored-right-line"></div>
												<div className="IconDiv">
													<FaFileInvoice className={`Icon ${estimatedIcon}`} />
												</div>

												<div className="EstimateNumberType">
													<div className="EstimateNumber">CR-23001</div>
													<p className="EstimateType">100 Accessories, 76 Parts</p>
												</div>
											</div>
											<div className="column-2">
												<p className="centerText">
													{' '}
													<IoPerson />
													&nbsp; Arian Adil{' '}
												</p>
											</div>
											<div className="column-2">
												<p className="centerText"> INV-23201 </p>
											</div>
											<div className="column-2">
												<p className="centerText"> Used: $2,300 </p>
											</div>
											<div className="column-2">
												<div className="EstimateNumberDate">
													<div className="EstimateNumber">
														<CurrencyValue currencyObject={item?.converted_currency_total} />
													</div>
													<p className="EstimateType">{moment(item?.estimation_date).format('MMM DD')}</p>
												</div>
											</div>
										</div>
									</div>

									{/* <div  className="estimate-option-item mt-2">
                                                                    <div className={`estimate-option-item-body Invoice-Contacts Invoice-Status`}
                                                                    >
                                                                    
                                                                        <div className={`column-1 status-Default`}>
                                                                        <div class="colored-right-line"></div>
                                                                            <div className='IconDiv'>
                                                                                    <FaFileInvoice className={`Icon ${estimatedIcon}`}/>
                                                                            </div>

                                                                            <div className="EstimateNumberType">
                                                                                    <div className="EstimateNumber">
                                                                                    CR-23002
                                                                                    </div>
                                                                                    <p className='EstimateType'>100 Accessories, 76 Parts</p>
                                                                            </div>

                                                                        </div>
                                                                        <div className="column-2">
                                                                        
                                                                            <p className='centerText'> <IoPerson />&nbsp; Arian Adil </p>
                                                                        </div>
                                                                        <div className="column-2">
                                                                        
                                                                            <p className='centerText'> INV-23201 </p>
                                                                        </div>
                                                                        <div className="column-2">
                                                                        
                                                                            <p className='centerText'> Used: $0 </p>
                                                                        </div>
                                                                        <div className="column-2">
                                                                            <div className="EstimateNumberDate">
                                                                                    <div className="EstimateNumber">
                                                                                    {currencySymbol} -{item?.total}
                                                                                    </div>
                                                                                    <p className='EstimateType'>{moment(item?.estimation_date).format('MMM DD')}</p>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                            </div> */}
								</>
							) : (
								<></>
							)}
						</>
					);
				})}
		</>
	);
};

export default Estimate;
