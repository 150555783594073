import React from 'react';
import { IconArrowDownRight, IconArrowUpRight } from '@tabler/icons'; // Import these if they are separate components
import { Fade } from 'react-awesome-reveal';

const ProductSoldAndReturnCard = ({ productSold, productGrowth, returns, returnsGrowth }) => {
	return (
		<div className="row" style={{ marginBottom: '20px' }}>
			<div className="col-12">
				<Fade>
					<div className="card" style={{ height: '160px' }}>
						<div className="card-body" style={{ padding: '20px', paddingTop: '30px' }}>
							<div className="row">
								<div className="col-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									{productGrowth <= 0 ? (
										<div className="icon-container red" style={{ marginBottom: '10px' }}>
											<IconArrowDownRight size={20} className="icon red" />
										</div>
									) : productGrowth > 0 ? (
										<div className="icon-container green" style={{ marginBottom: '10px' }}>
											<IconArrowUpRight size={20} className="icon green" />
										</div>
									) : null}
									<div style={{ fontWeight: 500 }}>Product sold</div>
									<div style={{ fontWeight: 700, fontSize: '17px' }}>{`${productSold}`}</div>
									<div style={{ color: productGrowth <= 0 ? '#f64e60' : productGrowth > 0 ? '#4acd8a' : '#000', fontSize: '10px' }}>
										{productGrowth > 0 ? `+${productGrowth}` : `${productGrowth}`}% last month
									</div>
								</div>
								<div className="col-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									{returnsGrowth > 0 ? (
										<div className="icon-container red" style={{ marginBottom: '10px' }}>
											<IconArrowDownRight size={20} className="icon red" />
										</div>
									) : returnsGrowth <= 0 ? (
										<div className="icon-container green" style={{ marginBottom: '10px' }}>
											<IconArrowUpRight size={20} className="icon green" />
										</div>
									) : null}
									<div style={{ fontWeight: 500 }}>Total return</div>
									<div style={{ fontWeight: 700, fontSize: '17px' }}>{`${returns}`}</div>
									<div style={{ color: returnsGrowth > 0 ? '#f64e60' : returnsGrowth <= 0 ? '#4acd8a' : '#000', fontSize: '10px' }}>
										{returnsGrowth > 0 ? `+${returnsGrowth}` : `${returnsGrowth}`}% last month
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fade>
			</div>
		</div>
	);
};

export default ProductSoldAndReturnCard;
