import React, { useEffect, useRef, useState } from 'react';
import assignbyIcon from 'assets/images/icons/assign-by.svg';
import calendarIcon from 'assets/images/icons/feather-calendar.svg';
import rejectIcon from 'assets/images/icons/reject.svg';
import inspactIcon from 'assets/images/icons/inspact.svg';
import checkIcon from 'assets/images/icons/check-green.svg';
import closetIcon from 'assets/images/icons/close-red.svg';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { storeInLocalStore, setstateCollectionList, setMessageData } from 'store/reducers/mainSlice';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Loader from 'ui-component/Loader';
import moment from 'moment';
import Scroller from 'ui-component/Scroller';
import { getStatusColor } from 'views/Operations/TaskGrid/Task';
import { Avatar, Tooltip } from '@mui/material';
import { CgPlayListCheck } from 'react-icons/cg';
import toast from 'react-hot-toast';

import '../processindicator.scss';
import { FaCheck, FaPrint } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im';
import Barcode from 'react-barcode';
import { BsClipboardCheck } from 'react-icons/bs';
import { GrTest } from 'react-icons/gr';
import { FaBoxesStacked } from 'react-icons/fa6';
import { GiAutoRepair, GiHandTruck, GiMagnifyingGlass } from 'react-icons/gi';
import { BiCategoryAlt } from 'react-icons/bi';
import { PiInfoFill, PiPackageBold, PiTestTubeFill } from 'react-icons/pi';
import ProcessIndicator from '../ProcessIndicator';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { useSearchParams } from 'react-router-dom';
import PrintPreviewDialog from 'ui-component/PrintPreviewDialog';
import { width } from '@mui/system';

import PrintContent from 'ui-component/PrintPreviewDialog/PrintContent';
import { useReactToPrint } from 'react-to-print';
import { IoMdBarcode } from 'react-icons/io';
import { taskboardProgresses } from 'assets/helperdata/taskboard-progrss';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const TestingDetails = () => {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const selectedId = useSelector((state) => state.main.user.selectedtaskid);
	const [isLoading, setLoading] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [inspectInfo, setInspectInfo] = useState();
	const [dueDate, setDueDate] = useState();
	const [startDate, setStartDate] = useState();
	const [isInProgress, setIsInProgress] = useState(false);
	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const showConfirmationDialog = (title, message = '', onConfirm = () => {}, onClose = () => {}) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	const getTestingDetails = (callback = () => {}) => {
		setLoading(true);
		setIsDisable(true);
		const data = {
			selectedId: selectedId,
			type: 'accept'
		};
		api.gettestingdetailsbyid(id).then((res) => {
			setLoading(false);
			setIsDisable(false);
			setInspectInfo(res.data);
			let duedate = moment(res.data.due_date).format('DD MMM HH:mm');
			let startdate = moment(res.data.created_at).format('DD MMM HH:mm');
			setDueDate(duedate);
			setStartDate(startdate);
			callback(res.data);
		});
	};

	useEffect(() => {
		if (id) {
			getTestingDetails((data) => {
				setIsInProgress(!!data?.start_at);
				if (!data?.start_at) {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Start Testing?',
							'Are you sure you want to start testing?',
							() => {
								handleStart();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				} else {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Complete Testing?',
							'Are you sure you want to complete testing without looking at its details?',
							() => {
								handleSubmit();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const onStartClick = () => {
		showConfirmationDialog(
			'Start Testing?',
			'Are you sure you want to start testing?',
			() => {
				handleStart();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleStart = () => {
		setIsDisable(true);
		toast.promise(
			api.startTesting(id).then(
				(res) => {
					if (res.status) {
						setIsInProgress(true);
					}

					setIsDisable(false);
					return res?.status ? res : null;
				},
				() => {
					setIsDisable(false);
				}
			),
			{
				loading: 'Starting Segregation...',
				success: (data) => `${data.message}`,
				error: <b>Failed to start Segregation</b>
			}
		);
	};

	const onDoneClicked = () => {
		showConfirmationDialog(
			'Complete Testing?',
			'Are you sure you want to complete testing?',
			() => {
				handleSubmit();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleSubmit = () => {
		setIsDisable(true);
		const data = { task_testing_id: id, action_type: 'submit' };
		toast.promise(
			api.saveTestingData(data).then((res) => {
				if (res.status) {
					setIsDisable(false);
					history('/operations/testing');
				}
				return res?.status ? res : null;
			}),
			{
				loading: 'Saving Collection...',
				success: (data) => `${data.message}`,
				error: <b>Failed to save collection</b>
			}
		);
	};
	const goback = () => {
		history('/operations/testing');
	};
	return (
		<>
			<ProcessIndicator isInProgress={isInProgress} processes={taskboardProgresses} currentIndex={3} />

			<div className={`TaskDetails`}>
				<div className="d-flex justify-content-between mb-0">
					<div className="topBtnSign" style={{ '--statusColor': getStatusColor(!inspectInfo?.end_at ? 'blue' : 'green') }}>
						<span>{inspectInfo?.start_at && inspectInfo?.end_at ? 'Completed' : isInProgress ? 'Inprogress' : 'Upcoming'}</span>
					</div>
				</div>
				<div style={{ position: 'absolute', right: '24px', top: '10px', zIndex: '2' }}>
					<Barcode
						value={inspectInfo?.task_segregation_detail_reference_no}
						height={30}
						width={1}
						fontSize={12}
						displayValue={false}
					/>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card p-4">
							<Scroller>
								<div className="row mb-5">
									<div className="col-12 d-flex justify-content-between mb-4">
										<h3 className="font-weight-bold m-0">{inspectInfo?.invoice_number}</h3>
										{/* <div
											className={`d-flex align-items-center justify-content-end datediv mb-4`}
											style={{ '--statusColor': getStatusColor(inspectInfo?.priority_status?.color) }}
										>
											<div className="boxDate">{moment(inspectInfo?.due_date).format('DD MMM HH:mm')}</div>
										</div> */}
									</div>
									<div className="col-12">
										<div className="row">
											<div className="col-md-20 mb-2 mb-md-0 detail-item">
												<p className="detail-label">Reference.</p>
												<div className="detail-value"> {inspectInfo?.task_segregation_detail_reference_no} </div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Collected On</p>
												<div className="detail-value">
													{inspectInfo?.collected_on
														? moment(inspectInfo?.collected_on).format('DD MMM YYYY hh:mm A')
														: '10 Jan 2023 07:55 AM'}
												</div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Customer</p>
												<div className="detail-value">
													<PrivacyModeContent>{inspectInfo?.customer}</PrivacyModeContent>
												</div>
											</div>

											<div className=" col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label">Supplier</p>
												<div className="detail-value">
													<PrivacyModeContent>{inspectInfo?.supplier}</PrivacyModeContent>
												</div>
											</div>

											<div className="col-md-20 mb-2 mb-md-0 detail-item" style={{ gap: '3px' }}>
												<p className="detail-label" style={{ fontSize: '11px' }}>
													Quantity
												</p>
												<div className="detail-value">{inspectInfo?.total_qty} Pcs</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-4">
										<h5 className="font-weight-bold mb-4">
											<CgPlayListCheck fontSize={30} /> To Test
										</h5>
										<div className="product-list-box">
											<table className="table-sm table-borderless" width="100%">
												<thead>
													<tr>
														<th>Product</th>
														<th>Quantity</th>
													</tr>
												</thead>
												<tbody>
													{inspectInfo?.items.length > 0 &&
														inspectInfo?.items.map((item) => {
															return (
																<>
																	<tr>
																		<td className="align-middle">{item?.product?.product_name}</td>
																		<td className="align-middle">{item?.qty} Pcs</td>
																	</tr>
																</>
															);
														})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</Scroller>
							<div className="btnarea">
								<span
									onClick={() => {
										goback();
									}}
									className={`btn btn-link ${isDisable ? 'disabled' : ''}`}
								>
									Back
								</span>
								<PermissionAllowed permissions={['Start / End Task Testing (Action)']}>
									{inspectInfo?.status != 'completed' &&
										(isInProgress ? (
											<span
												onClick={() => {
													onDoneClicked();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Done
											</span>
										) : (
											<span
												onClick={() => {
													onStartClick();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Start
											</span>
										))}
								</PermissionAllowed>
							</div>
						</div>
					</div>
				</div>
			</div>
			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}
		</>
	);
};

export default TestingDetails;
