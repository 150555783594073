import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Scroller from 'ui-component/Scroller';
import Loader from 'ui-component/Loader';
import AutosuggestInput from 'ui-component/AutoSuggestInput';
import refreshIcon from 'assets/images/icons/refresh.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import { useParams } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa';

const currencyData = ['aed', 'eur', 'usd'];

const ProductUpdate = () => {
	const history = useNavigate();
	const { id } = useParams();
	// const selectedProduct = useSelector((state) => state.main.user.selectedProduct);
	const [selectedProduct, setSelectedProduct] = useState([]);
	const selectModelRef = useRef();
	const imeiRef = useRef();
	const colorRef = useRef();
	const purchasePriceRef = useRef();
	const sellingPriceRef = useRef();
	const currencyRef = useRef();
	const supplierRef = useRef();
	const initialRenderRef = useRef(true);
	const [isLoading, setIsloading] = useState(false);
	const [modelvalue, setModelvalue] = useState('');
	const [modelsuggestions, setModelsuggestions] = useState([]);
	const [storage, setStorage] = useState([]);
	const [grade, setGrade] = useState([]);
	const [color, setColor] = useState([]);
	const [allstatus, setStatus] = useState([]);
	const [currentstorage, setCurrentstorage] = useState('');
	const [currentGrade, setCurrentgrade] = useState('');
	const [currentColor, setCurrentcolor] = useState('');
	const [currentSupplier, setCurrentSupplier] = useState('');
	const [currentCustomer, setCurrentCustomer] = useState('');
	const [currentStatus, setCurrentstatus] = useState('');
	const [currentSellingInvoice, setCurrentSellingInvoice] = useState('');
	const [sellingInvoice, setSellingInvoice] = useState([]);
	const [currencyRates, setCurrencyRates] = useState([]);
	const [currencyValue, setCurrencyValue] = useState('');
	const [supplierData, setSupplierData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const [imeiNumber, setImeiNumber] = useState('');
	const [alertMessage, setAlertMessage] = useState();
	const [productValue, setproductValue] = useState();
	const getAllattributes = () => {
		api.getAllstatus().then((res) => {
			let tempstatus = res.data.filter((item) => item.type == 'Product').map((item) => ({ id: item.id, name: item.name }));
			setStatus(tempstatus);
		});
		api.allAttributes().then((res) => {
			if (res?.status) {
				let storageTemp = res.data.filter((item) => item.type == 'storage').map((item) => ({ id: item.id, name: item.name }));
				let gradeTemp = res.data.filter((item) => item.type == 'grade').map((item) => ({ id: item.id, name: item.name }));
				let colorTemp = res.data.filter((item) => item.type == 'color').map((item) => ({ id: item.id, name: item.name }));
				setStorage(storageTemp);
				setGrade(gradeTemp);
				setColor(colorTemp);
			} else {
			}
		});
	};
	const validateLength = (e, maxLength) => {
		let inputValue = e.target.value;
		if (inputValue.length > maxLength) {
			inputValue = inputValue.slice(0, maxLength);
		}
		e.target.value = inputValue;
	};
	const getSupplierAPI = () => {
		api.selectAllSuppller()
			.then((res) => {
				if (res.status) {
					setSupplierData(res?.data);
				}
			})
			.catch((err) => {
				console.log('Err ', err);
			});
	};
	const getCustomerAPI = () => {
		api.selectAllCustomer(1)
			.then((res) => {
				if (res.status) {
					setCustomerData(res?.data);
				}
			})
			.catch((err) => {
				console.log('Err ', err);
			});
	};
	
	const handleCheckIMEI = () => {
		if (imeiNumber !== '') {
			setAlertMessage({
				verifyingImei: 'Please wait...'
			});
			api.imeiuniqvarification({
				models: [{ imei_numbers: [imeiNumber] }]
			})
				.then((res) => {
					if (res.status) {
						const data = res.data[0];
						console.log('data ', data);
						if (data.is_unique === 'yes') {
							handleChange({ ...productValue, imei: data.imei });
						}
						setAlertMessage({
							imeiVerify: 'Verified'
						});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
		setTimeout(() => {
			setAlertMessage({});
		}, 1500);
	};
	const getCurrencyRates = (data) => {
		api.getCurrencyRate(data)
			.then((res) => {
				if (res?.status) {
					const _data = res?.data;
					if (_data.price_eur === 1) {
						setCurrencyRates([`Dirham ${_data.price_aed}`, `USD ${_data.price_usd}`]);
					} else if (_data.price_aed === 1) {
						setCurrencyRates([`Euro ${_data.price_eur}`, `USD ${_data.price_usd}`]);
					} else if (_data.price_usd === 1) {
						setCurrencyRates([`Dirham ${_data.price_aed}`, `Euro ${_data.price_eur}`]);
					}
				}
			})
			.catch((err) => {
				console.log('Err ', err);
			});
	};
	const goback = () => {
		history('/inventory/inbound');
	};
	const searchCustomer = async (inputValue) => {
		const response = await api.searchModel(inputValue, 1);
		if (response.status) {
			setModelsuggestions(
				response.data.data.map((model) => {
					return { name: model.name, value: model.id };
				})
			);
		}
		return [];
	};
	const getModelsuggestion = (value) => {
		const inputValue = value.value?.trim()?.toLowerCase();
		const inputLength = inputValue.length;
		if (inputLength !== 0) {
			searchCustomer(inputValue);
			return ['searching...'];
		}
		return [];
	};
	const getModelsuggestValue = (sugg) => {
		api.getSinglemodel(sugg.value).then((res) => {
			setCurrentstorage(res?.data?.storage);
			setCurrentgrade(res?.data?.grade);
			handleChange({
				...productValue,
				modelName: sugg.value,
				storage: res.data.storage_id,
				grade: res.data.grade_id
			});
		});

		return sugg?.name;
	};
	const imeiValidation = (e) => {
		const numericValue = e.target.value.replace(/\D/g, '');
		if (numericValue.length < 15) {
			setAlertMessage({
				imei: 'IMEI should be exactly 15 digits.'
			});
		} else {
			setAlertMessage();
		}
		e.target.value = numericValue;
	};

	const handlePrice = (e) => {
		const input = e.target.value;
		const formattedInput = input.replace(/[^0-9.]/g, '');
		e.target.value = formattedInput;
	};
	const convertUsd = () => {
		const price = productValue.purchase_cost_usd;
		api.currentConverter({ price_usd: price }).then((res) => {
			handleChange({
				...productValue,
				purchase_cost_eur: res.data.price_eur,
				purchase_cost_aed: res.data.price_aed
			});
		});
	};
	const convertUsdsale = () => {
		const price = productValue.sold_price_usd;
		api.currentConverter({ price_usd: price }).then((res) => {
			handleChange({
				...productValue,
				sold_price_eur: res.data.price_eur,
				sold_price_aed: res.data.price_aed
			});
		});
	};
	const handleChange = (val) => {
		setproductValue(val);
	};

	const getmodelvalue = () => {
		setIsloading(true);
		api.getproductbyId(id).then((res) => {
			setIsloading(false);
			if (res?.status) {
				console.log('REs ', res?.data);
				setSelectedProduct(res?.data);
			}
		});
	};

	const getSellingInvoiceAPI = (id) => {
		api.getSellingInvoice(id)
			.then((res) => {
				if (res?.status) {
					setSellingInvoice(res?.data);
				}
			})
			.catch((err) => {
				console.log('Error ', err);
			});
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (productValue.modelName === '') {
			setAlertMessage({
				modelName: 'Model is Required.'
			});
			selectModelRef.current.scrollIntoView({ behavior: 'smooth' });
		} else if (productValue.color === '') {
			setAlertMessage({
				color: 'Color is Required.'
			});
			colorRef.current.focus();
		} else if (productValue.imei === '') {
			console.log('imeiNumber', imeiNumber);
			if (imeiNumber === '') {
				setAlertMessage({
					imei: 'IMEI Number is Required'
				});
			} else {
				setAlertMessage({
					imei: 'Please verify the IMEI Number'
				});
			}
			imeiRef.current.focus();
		} else if (productValue.purchase_price === '') {
			setAlertMessage({
				purchase_price: 'Purchase Price is Required.'
			});
			purchasePriceRef.current.focus();
		} else if (productValue.selling_price === '') {
			setAlertMessage({
				selling_price: 'Selling Price is Required.'
			});
			sellingPriceRef.current.focus();
		} else if (productValue.currency === '') {
			setAlertMessage({
				currency: 'Currency is Required.'
			});
			currencyRef.current.focus();
		} else if (productValue.supplier === '') {
			setAlertMessage({
				supplier: 'Supplier is Required.'
			});
			supplierRef.current.focus();
		} else {
			api.updateProduct(selectedProduct?.id, {
				model_id: productValue.modelName,
				color_id: productValue.color,
				supplier_id: productValue.supplier,
				customer_id: productValue.customer,
				imei: productValue.imei,
				currency: productValue.currency,
				purchase_invoice: productValue.purchase_invoice,
				selling_invoice: productValue.selling_invoice,
				purchase_price: productValue.purchase_price,
				selling_price: productValue.selling_price
			}).then((res) => {
				if (res.status) {
					setAlertMessage({
						success: 'Product Updated Successfully.'
					});
					setTimeout(() => {
						setAlertMessage({});
						history('/inventory/inbound');
					}, 1500);
				} else {
					setAlertMessage({
						failed: res.message
					});
					setTimeout(() => {
						setAlertMessage({});
					}, 2000);
				}
			});
		}

		setTimeout(() => {
			setAlertMessage({});
		}, 1500);
	};

	useEffect(() => {
		getSupplierAPI();
		getCustomerAPI();
		if (currencyValue) {
			const data = { currency: currencyValue };
			getCurrencyRates(data);
		}
	}, [currencyValue]);
	useEffect(() => {
		getAllattributes();
		if (selectedProduct?.id) {
			setModelvalue(selectedProduct?.name);
			setCurrentstorage(selectedProduct?.storage || '');
			setCurrentcolor(selectedProduct?.color || '');
			setImeiNumber(selectedProduct?.imei);
			setCurrencyValue(selectedProduct?.currency);
			// searchCustomer(selectedProduct?.master_model.name || '');
			setCurrentgrade(selectedProduct?.grade || '');
			// setCurrentstatus(selectedProduct?.master_status || '');
			setCurrentSupplier(selectedProduct?.supplier);
			setCurrentCustomer(selectedProduct?.customer);
			setCurrentSellingInvoice(selectedProduct?.selling_invoice);

			setproductValue({
				modelName: selectedProduct?.model_id || '',
				// model_id: selectedProduct?.model_id || '',
				storage: selectedProduct?.storage_id || '',
				grade: selectedProduct?.grade_id || '',
				color: selectedProduct?.color_id || '',
				imei: selectedProduct?.imei || '',
				storage: selectedProduct?.storage_id || '',
				grade: selectedProduct?.grade_id || '',
				currency: selectedProduct?.currency || '',
				supplier: selectedProduct?.supplier_id || '',
				customer: selectedProduct?.customer_id || '',
				purchase_invoice: selectedProduct?.purchase_invoice || '',
				selling_invoice: selectedProduct?.selling_invoice?.id || '',
				purchase_price: selectedProduct?.purchase_price_unit || '',
				selling_price: selectedProduct?.selling_price_unit || ''
			});
		}
	}, [selectedProduct]);

	useEffect(() => {
		if (imeiNumber === '' && modelvalue !== '') {
			handleChange({
				...productValue,
				imei: ''
			});
		}
	}, [imeiNumber]);

	useEffect(() => {
		if (initialRenderRef.current) {
			initialRenderRef.current = false;
			return;
		}
		if (modelvalue == '') {
			setCurrentstorage('');
			setCurrentgrade('');
			handleChange({
				...productValue,
				modelName: ''
			});
		}
		if (productValue?.customer) {
			getSellingInvoiceAPI(productValue?.customer);
		}
	}, [modelvalue, productValue?.customer]);

	useEffect(() => {
		if (id) {
			getmodelvalue();
		}
	}, [id]);

	console.log('productValue ', productValue);
	console.log('selectedProduct ', selectedProduct);

	return (
		<>
			{isLoading ? (
				<div style={{ position: 'relative', width: '100%', height: '70vh' }}>
					<Loader />
				</div>
			) : (
				<>
					<div className="row">
						<div className="col-xl-12 col-lg-12">
							<div className="card shadow-sm bg-white mb-4 p-2 p-xl-3">
								<div className="col-md-12 my-4">
									<h1 className="h4 mb-0  font-weight-bold">Update Product</h1>
								</div>
								<form className="userForm" onSubmit={handleSubmit}>
									<Scroller>
										<div className="row">
											<div className="col-xl-12 col-lg-12">
												<div className="card shadow-sm rounded bg-white mb-4 p-3 p-xl-4">
													<div className="m-sm-2">
														<form className="userForm" onSubmit={handleSubmit}>
															<div className="form-row">
																<div className="col-md-12 mt-0 p-3">
																	<h6 className="h5 ">Product Informations</h6>
																</div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label
																		ref={selectModelRef}
																		htmlFor="inputName"
																		className=""
																	>
																		Model*
																	</label>
																	<AutosuggestInput
																		placeholder={'Select'}
																		className={'form-control'}
																		value={modelvalue}
																		setValue={setModelvalue}
																		suggestions={modelsuggestions}
																		setSuggestions={setModelsuggestions}
																		getSuggestionValue={getModelsuggestValue}
																		getSuggestions={getModelsuggestion}
																	/>
																	{alertMessage?.modelName && (
																		<>
																			<span className="px-3 error-message text-danger">
																				{alertMessage.modelName}
																			</span>
																		</>
																	)}
																</div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Product Color
																	</label>
																	<select
																		className="form-control select-option"
																		onChange={(e) => {
																			handleChange({ ...productValue, color: e.target.value });
																		}}
																	>
																		{currentColor != '' ? (
																			<>
																				<option value={currentColor?.id}>
																					{' '}
																					{currentColor.name}{' '}
																				</option>
																			</>
																		) : (
																			<>
																				<option> Select </option>
																			</>
																		)}
																		{color &&
																			color
																				.filter((item) => item?.id != currentColor.id)
																				.map((item, index) => (
																					<option key={index} value={item.id}>
																						{item.name}
																					</option>
																				))}
																	</select>
																	{alertMessage?.color && (
																		<>
																			<span className="px-3 error-message text-danger">
																				{alertMessage.color}
																			</span>
																		</>
																	)}
																</div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		IMEI *
																	</label>
																	<div style={{ position: 'relative' }}>
																		<input
																			ref={imeiRef}
																			type="text"
																			className="form-control"
																			id="inputName"
																			placeholder="IMEI *"
																			maxLength={15}
																			pattern="[0-9]*"
																			value={imeiNumber}
																			onChange={(e) => {
																				imeiValidation(e);
																				setImeiNumber(e.target.value);
																			}}
																		/>
																		<img
																			style={{
																				position: 'absolute',
																				top: '20px',
																				right: '20px',
																				cursor: 'pointer'
																			}}
																			onClick={handleCheckIMEI}
																			width="20px"
																			weight="20px"
																			src={refreshIcon}
																			alt="error"
																		/>
																	</div>
																	{alertMessage?.imei && (
																		<>
																			<span className="px-3 error-message text-danger">
																				{alertMessage.imei}
																			</span>
																		</>
																	)}
																	{alertMessage?.verifyingImei && (
																		<>
																			<span className="px-3 error-message text-info">
																				{alertMessage.verifyingImei}
																			</span>
																		</>
																	)}
																	{alertMessage?.imeiVerify && (
																		<>
																			<span className="px-3 error-message text-success">
																				{alertMessage.imeiVerify}
																			</span>
																		</>
																	)}
																</div>

																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Storage
																	</label>
																	<select
																		className="form-control select-option"
																		onChange={(e) => {
																			handleChange({ ...productValue, storage: e.target.value });
																		}}
																		disabled={currentstorage ? 'disabled' : ''}
																	>
																		{currentstorage != '' ? (
																			<>
																				<option value={currentstorage.id}>
																					{' '}
																					{currentstorage.name}{' '}
																				</option>
																			</>
																		) : (
																			<>
																				<option> Select </option>
																			</>
																		)}

																		{storage &&
																			storage.map((item, index) => (
																				<option key={index} value={item.id}>
																					{item.name}
																				</option>
																			))}
																	</select>
																</div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Grade
																	</label>
																	<select
																		className="form-control select-option"
																		onChange={(e) => {
																			handleChange({ ...productValue, grade: e.target.value });
																		}}
																		disabled={currentGrade ? 'disabled' : ''}
																	>
																		{currentGrade != '' ? (
																			<>
																				<option value={currentGrade.id}>
																					{' '}
																					{currentGrade.name}{' '}
																				</option>
																			</>
																		) : (
																			<>
																				<option> Select </option>
																			</>
																		)}

																		{grade &&
																			grade.map((item, index) => (
																				<option key={index} value={item.id}>
																					{item.name}
																				</option>
																			))}
																	</select>
																</div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Purchase Price
																	</label>
																	<input
																		ref={purchasePriceRef}
																		type="text"
																		className="form-control"
																		id="inputName"
																		placeholder="Purchase Price"
																		value={productValue?.purchase_price}
																		onChange={(e) => {
																			validateLength(e, 6);
																			handleChange({
																				...productValue,
																				purchase_price: e.target.value
																			});
																		}}
																	/>
																	{alertMessage?.purchase_price && (
																		<>
																			<span className="px-3 error-message text-danger">
																				{alertMessage.purchase_price}
																			</span>
																		</>
																	)}
																</div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Selling Price
																	</label>
																	<input
																		ref={sellingPriceRef}
																		type="text"
																		className="form-control"
																		id="inputName"
																		placeholder="Selling Price"
																		value={productValue?.selling_price}
																		onChange={(e) => {
																			validateLength(e, 6);
																			handleChange({
																				...productValue,
																				selling_price: e.target.value
																			});
																		}}
																	/>
																	{alertMessage?.selling_price && (
																		<>
																			<span className="px-3 error-message text-danger">
																				{alertMessage.selling_price}
																			</span>
																		</>
																	)}
																</div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Currency
																	</label>
																	<select
																		ref={currencyRef}
																		className="form-control select-option"
																		value={productValue?.currency}
																		onChange={(e) => {
																			setCurrencyValue(e.target.value);
																			handleChange({ ...productValue, currency: e.target.value });
																		}}
																	>
																		<option value=""> Select </option>
																		{currencyData?.map((item, index) => (
																			<option key={index} value={item}>
																				{item}
																			</option>
																		))}
																	</select>
																	{alertMessage?.currency && (
																		<>
																			<span className="px-3 error-message text-danger">
																				{alertMessage.currency}
																			</span>
																		</>
																	)}
																</div>
																<div className="form-group col-md-2 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Currency Rate
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		id="inputName"
																		// placeholder="Euro"
																		value={currencyRates[0]}
																		disabled
																	/>
																</div>
																<div className="form-group col-md-2 px-3 mt-5">
																	<input
																		type="text"
																		className="form-control"
																		id="inputName"
																		// placeholder="Dirham"
																		value={currencyRates[1]}
																		// onChange={(e) => {
																		// 	handleChange({ ...productValue, selling_price: e.target.value });
																		// }}
																		disabled
																	/>
																</div>
																<div className="col-md-12 mt-0 p-3">
																	<h6 className="h5 ">Company Address</h6>
																</div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Supplier
																	</label>
																	<select
																		ref={supplierRef}
																		className="form-control select-option"
																		value={productValue?.supplier}
																		onChange={(e) => {
																			handleChange({ ...productValue, supplier: e.target.value });
																		}}
																	>
																		{currentSupplier != '' ? (
																			<>
																				<option value={currentSupplier.id}>
																					{' '}
																					{currentSupplier.name}{' '}
																				</option>
																			</>
																		) : (
																			<>
																				<option> Select </option>
																			</>
																		)}
																		{supplierData?.map((item, index) => (
																			<option key={index} value={item.id}>
																				{item.name}
																			</option>
																		))}
																	</select>
																	{alertMessage?.supplier && (
																		<>
																			<span className="px-3 error-message text-danger">
																				{alertMessage.supplier}
																			</span>
																		</>
																	)}
																</div>

																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Purchase INV
																	</label>
																	<select
																		className="form-control select-option"
																		// onChange={(e) => {
																		// 	handleChange({ ...productValue, purchase_invoice: e.target.value });
																		// }}
																	>
																		<option> Select </option>
																		{[]?.map((item, index) => (
																			<option key={index} value={item}>
																				{item}
																			</option>
																		))}
																	</select>
																</div>
																<div className="form-group col-md-4 px-3 py-1"></div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Customer
																	</label>
																	<select
																		className="form-control select-option"
																		value={productValue?.customer}
																		onChange={(e) => {
																			handleChange({ ...productValue, customer: e.target.value });
																		}}
																	>
																		{currentCustomer != '' ? (
																			<>
																				<option value={currentCustomer?.id}>
																					{' '}
																					{currentCustomer?.name}{' '}
																				</option>
																			</>
																		) : (
																			<>
																				<option> Select </option>
																			</>
																		)}
																		{customerData?.map((item, index) => (
																			<option key={index} value={item.id}>
																				{item.name}
																			</option>
																		))}
																	</select>
																</div>
																<div className="form-group col-md-4 px-3 py-1">
																	<label htmlFor="inputName" className="">
																		Selling INV
																	</label>
																	<select
																		className="form-control select-option"
																		onChange={(e) => {
																			handleChange({
																				...productValue,
																				selling_invoice: e.target.value
																			});
																		}}
																	>
																		{currentSellingInvoice != '' ? (
																			<>
																				<option value={currentSellingInvoice?.id}>
																					{' '}
																					{currentSellingInvoice?.invoice_number}{' '}
																				</option>
																			</>
																		) : (
																			<>
																				<option> Select </option>
																			</>
																		)}
																		{sellingInvoice?.map((item, index) => (
																			<option key={index} value={item.id}>
																				{item?.invoice_number}
																			</option>
																		))}
																	</select>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</Scroller>
									<div className="">
										<div className="btnarea">
											<button onClick={goback} className="btn btn-primary canBtn">
												<FaMinus /> Cancel
											</button>
											<button
												type="submit"
												className="btn btn-primary greenBtn "
											>
												<FaPlus /> Update
											</button>
										</div>
										{alertMessage?.success && (
											<span className="px-3 down-error text-success">{alertMessage?.success}</span>
										)}
										{alertMessage?.failed && (
											<span className="px-3 down-error text-danger">{alertMessage?.failed}</span>
										)}
									</div>
								</form>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default ProductUpdate;
