import React, { useState, useEffect, useContext } from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import { FaSearch, FaTimesCircle, FaPlus, FaSave, FaTimes, FaMinus, FaCheck } from 'react-icons/fa';
import Scroller from 'ui-component/Scroller';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { CiMobile3 } from "react-icons/ci";
import Pagination from 'ui-component/Pagination';
import { useSelection } from './SelectionContext';
import NoImage from 'assets/images/no-image.png';




const ProductList = ({
    selectedItems,
    close,
    // isLoading,
    currency,
    handleClose
}) => {
    const { selectedRows, addSelectedRow, removeSelectedRow, clearSelectedRows, updateSelectedRow } = useSelection();
    const [productList, setProductList] = useState([]);
    const [currentItems, setCurrentItems] = useState([]);
    const [isAddList, setIsAddList] = useState([]);
    const [rowCounts, setRowCounts] = useState([]);
    const [pagination, setpagination] = useState(null);
    const [searchField, setSearchField] = useState('');
    const [priceInputs, setPriceInputs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalProducts, setTotalProducts] = useState(0);
    const [isAddedItemsCaptured, setIsAddedItemsCaptured] = useState(true);

    useEffect(() => {
        setCurrentItems(selectedItems)
        if (currentItems?.length > 0) {
            setIsAddedItemsCaptured(false)
        }
    }, [isAddedItemsCaptured]);
    const totalQuantity = selectedRows.reduce((total, row) => total + row.quantity, 0);
    const totalPrice = selectedRows.reduce((total, row) => total + (row.quantity * row.price), 0);

    useEffect(() => {
        setIsAddList(Array(productList?.length).fill(false));
        setRowCounts(Array(productList?.length).fill(0));
    }, [productList]);

    useEffect(() => {
        if (selectedRows?.length > 0) {
            const updatedIsAddList = Array(productList?.length).fill(false);
            const updatedRowCounts = Array(productList?.length).fill(0);
            const updatedPriceInputs = Array(productList?.length).fill('');

            selectedRows.forEach((selectedRow) => {
                const { product_id, quantity, price } = selectedRow;
                const index = productList.findIndex((item) => item.id === product_id);
                if (index !== -1) {
                    updatedIsAddList[index] = true;
                    updatedRowCounts[index] = quantity;
                    updatedPriceInputs[index] = price;
                }
            });
            setIsAddList(updatedIsAddList);
            setRowCounts(updatedRowCounts);
            setPriceInputs(updatedPriceInputs);
        }
    }, [productList, selectedRows]);


    const handleIncrease = (productId) => {

        let productListCollection = [...productList];
        const itemIndex = productListCollection.findIndex(product => product.id === productId);
        if (itemIndex !== -1) {
            productListCollection[itemIndex].quantity += 1;
            setProductList(productListCollection);
        }

        let selectedItemCollection = null;

        if (currentItems.length === 0) {
            selectedItemCollection = [{

                product_id: productListCollection[itemIndex].id,
                product_name: productListCollection[itemIndex].product_name,
                quantity: productListCollection[itemIndex].quantity,
                price: productListCollection[itemIndex].price,
                purchased_quantity: productListCollection[itemIndex].purchased_quantity,
                sku: productListCollection[itemIndex].sku,
                index: index,
                thumbnail_url: productListCollection[itemIndex].thumbnail_url,
                is_selected: true
            }]
        } else {
            selectedItemCollection = currentItems.map((item) => {
                if (item.product_id === productId) {
                    return { ...item, quantity: item.quantity + 1 };
                }
                return item;
            });
        }

        setCurrentItems(selectedItemCollection);

        const total = selectedItemCollection.reduce((total, item) => total + (item.quantity || 0), 0)
        setTotalProducts(total)
    };

    const handleDecrease = (productId) => {

        let productListCollection = [...productList];
        const itemIndex = productListCollection.findIndex(product => product.id === productId);
        if (itemIndex) {
            if (productListCollection[itemIndex] && productListCollection[itemIndex].quantity > 0) {
                productListCollection[itemIndex].quantity -= 1;
            }
            if (productListCollection[itemIndex] && productListCollection[itemIndex].quantity == 0) {
                productListCollection[itemIndex].is_selected = false;
            }
            // Update the productList state
            setProductList(productListCollection);
        }

        // Optionally, update selectedItems as well
        let selectedItemsCollection = currentItems
            .filter(item => !(item.product_id === productId && item.quantity === 0))
            .map((item) => {
                if (item.product_id === productId && item.quantity > 0) {
                    return { ...item, quantity: item.quantity - 1 };
                }
                return item;
            });
        let setSelectedItemsCollection = selectedItemsCollection.filter(item => item.quantity > 0);

        setCurrentItems(setSelectedItemsCollection);
        const total = selectedItemsCollection.reduce((total, item) => total - (item.quantity || 0), 0)
        setTotalProducts(total)
    };

    const handleData = (data) => {

        let collection = data?.data;

        if (currentItems?.length > 0) {
            collection.map((item) => {
                item.is_selected = false;
                item.quantity = 0;
            });
            currentItems.map((selectedItem) => {
                const itemIndex = collection.findIndex(product => product.id === selectedItem?.product_id);
                if (itemIndex !== -1) {
                    collection[itemIndex].is_selected = true;
                    collection[itemIndex].quantity = selectedItem?.quantity;
                }
            });
        }

        setProductList(collection);
        setpagination(data);
    };

    useEffect(() => {
        api.getAllproducts(searchField).then((res) => {
            setpagination(res?.data);
            let collection = res?.data?.data;
            if (currentItems?.length > 0) {
                collection.map((item) => {
                    item.is_selected = false;
                    item.quantity = 0;
                });

                currentItems.map((selectedItem) => {
                    const itemIndex = collection.findIndex(product => product.id === selectedItem?.product_id);
                    if (itemIndex !== -1) {
                        collection[itemIndex].is_selected = true;
                        collection[itemIndex].quantity = selectedItem?.quantity;
                    }
                });
            } else {
                collection.map((item) => {
                    item.is_selected = false;
                    item.quantity = 0;
                });

                selectedItems.map((selectedItem) => {
                    const itemIndex = collection.findIndex(product => product.id === selectedItem?.product_id);
                    if (itemIndex !== -1) {
                        collection[itemIndex].is_selected = true;
                        collection[itemIndex].quantity = selectedItem?.quantity;
                    }
                });
            }
            setProductList(collection);
        });
    }, [searchField]);

    const searchData = (e) => {
        e.preventDefault();
        setSearchField(e.target.value);

    };
    const updaterowCounts = (productId, value) => {

        let productListCollection = [...productList];
        const itemIndex = productListCollection.findIndex(product => product.id === productId);
        if (itemIndex !== -1) {
            productListCollection[itemIndex].quantity = value;
            setProductList(productListCollection);
        }

        let selectedItemCollection = null;

        if (currentItems.length === 0) {
            selectedItemCollection = [{

                product_id: productListCollection[itemIndex].id,
                product_name: productListCollection[itemIndex].product_name,
                // quantity: productListCollection[itemIndex].quantity,
                quantity: parseInt(value),
                price: productListCollection[itemIndex].price,
                purchased_quantity: productListCollection[itemIndex].purchased_quantity,
                sku: productListCollection[itemIndex].sku,
                index: index,
                thumbnail_url: productListCollection[itemIndex].thumbnail_url,
                is_selected: true
            }]
        } else {
            selectedItemCollection = currentItems.map((item) => {
                if (item.product_id === productId) {
                    return { ...item, quantity: parseInt(value) };
                }
                return item;
            });
        }

        setCurrentItems(selectedItemCollection);

        const total = selectedItemCollection.reduce((total, item) => total + (item.quantity || 0), 0)
        setTotalProducts(total)


    }


    const handleAddProduct = (productId) => {

        let productListCollection = [...productList];
        const item = productListCollection.find(item => item.id === productId);
        
        if (item) {
            item.quantity += 1;
            item.is_selected = true;
        }
        setProductList(productListCollection);

        const itemIndex = productListCollection.findIndex(product => product.id === productId);
        let itemsCollection = null;
        if (currentItems.findIndex(product => product.id === productId) === -1) {

            let newItem = {

                product_id: productListCollection[itemIndex].id,
                product_name: productListCollection[itemIndex].product_name,
                quantity: productListCollection[itemIndex].quantity,
                price: productListCollection[itemIndex].price,
                sku: productListCollection[itemIndex].sku,
                free_stock: productListCollection[itemIndex].free_stock,
                reserved_stock: productListCollection[itemIndex].reserved_stock,
                purchased_quantity: productListCollection[itemIndex].purchased_quantity,
                currency: productListCollection[itemIndex].currency,
                thumbnail_url: productListCollection[itemIndex].thumbnail_url,
                converted_currency_price: productListCollection[itemIndex].converted_currency_price,
                is_selected: true
            }
            newItem.converted_currency_total = {
                'usd': parseFloat(parseFloat(productListCollection[itemIndex].converted_currency_price['usd']) * productListCollection[itemIndex].quantity),
                'eur': parseFloat(parseFloat(productListCollection[itemIndex].converted_currency_price['eur']) * productListCollection[itemIndex].quantity),
                'aed': parseFloat(parseFloat(productListCollection[itemIndex].converted_currency_price['aed']) * productListCollection[itemIndex].quantity),
            };
            itemsCollection = [...currentItems, newItem];
        } else {
            itemsCollection = currentItems.map((item) => {
                if (item.product_id === productId) {
                    return { ...item, quantity: item.quantity + 1, is_selected: true };
                }
                return item;
            });
        }
        const total = itemsCollection.reduce((total, item) => total + (item.quantity || 0), 0)
        setTotalProducts(total)
        setCurrentItems(itemsCollection);


    };

    const handleDone = () => {
        close(currentItems)
    }

    return (


        <div className="p-2">
            <div className="foundListPopup">

                <div className="mb-2 ml-0 pl-0" style={{ padding: '15px 0 10px 10px' }}>

                    <div className="row">
                        <div className="col-12 text-left">
                            <h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0" style={{ marginLeft: '14px' }}>Add Product </h5>
                        </div>
                    </div>
                    <button className="btn modal-close-icon" onClick={handleClose}>
                        {/* <FaTimes /> */}
                        <svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                            <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                        </svg>


                    </button>
                </div>
                <div className="row align-items-top">
                    <div className="col-12">
                        <div className=" bg-white mb-4">
                            {/* 
                            <div className="section-head cstm-section-head">
                                <h2 style={{ fontSize: '18px' }}>Add products</h2>
                            </div> */}
                            <div className="align-items-center justify-content-between TableSerachBar">

                                <div className="justify-content-between align-items-center" style={{ gap: '10px' }}>
                                    <form className="navbar-search">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control bg-white border"
                                                placeholder="Search"
                                                aria-label="Search"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => {
                                                    searchData(e);
                                                }}
                                                value={searchField}
                                                style={{ width: '100%' }}
                                            />
                                            <span className="searchIcon">
                                                <FaSearch />
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="table-responsive-xl  estimationList p-2">
                                <Scroller extraHeight={60}>
                                    <table className="table">
                                        <thead>
                                            <tr className="sticky-header">
                                                <th className="align-middle" style={{ width: '8%' }}>

                                                </th>
                                                <th scope="col" style={{ width: '47%' }}>
                                                    Name
                                                </th>
                                                <th scope="col" style={{ width: '15%' }}>
                                                    Free stock
                                                </th>
                                                {/* <th scope="col" >Sold Last 28 days</th> */}
                                                <th scope="col" style={{ width: '15%' }}>Price ($)</th>
                                                <th scope="col" style={{ width: '15%' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <>
                                                    {[...Array(5)].map((e, i) => (
                                                        <tr>
                                                            <>
                                                                {[...Array(9)].map((e, i) => (
                                                                    <td>
                                                                        <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                                    </td>
                                                                ))}
                                                            </>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <>

                                                    {
                                                        productList?.length > 0 && productList?.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr key={item?.id}>
                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                            {
                                                                                item?.thumbnail_url ? (
                                                                                    <img src={item?.thumbnail_url} style={{ width: '40px' }} alt="product" />
                                                                                ) : (
                                                                                    <img src={NoImage} style={{ width: '40px' }} alt="product" />
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                            <div className="font500">{item?.product_name}</div>
                                                                            <div className="textGray">{item?.sku}</div>
                                                                        </td>
                                                                        <td style={{ verticalAlign: 'middle' }} className="font500">
                                                                            {/* {
                                                                                (item?.free_stock > 0 && item?.free_stock <= 10) ? (
                                                                                    <><span className='text-warning'>{item?.free_stock}</span> {item?.free_stock > 1 ? `pcs` : `pc`}</>
                                                                                ) : (item?.free_stock > 10) ? (
                                                                                    <><span className='text-success'>{item?.free_stock}</span> {item?.free_stock > 1 ? `pcs` : `pc`}</>
                                                                                ) : (
                                                                                    <><span className='text-danger'>{item?.free_stock}</span> {item?.free_stock > 1 ? `pcs` : `pc`}</>
                                                                                )
                                                                            } */}
                                                                            {item?.free_stock} {item?.purchased_quantity > 0 && (<><Tooltip title={<span style={{ fontSize: "13px" }}>Already purchased</span>} arrow placement="top"><span className="textGray">+ <span>{item?.purchased_quantity}</span></span></Tooltip></>)}
                                                                        </td>
                                                                        {/* <td className='text-center'> {item?.Sold_last_28_days} </td> */}
                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                            <label htmlFor="" style={{ margin: 'auto 0 auto 0' }}>{currency === 'usd' ? '$ ' : (currency === 'eur' ? '€ ' : (currency === 'aed' ? 'AED ' : currency))}</label> {item?.converted_currency_price[currency]}

                                                                        </td>
                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                            {/* {
                                                                                isAddList[index] ? (
                                                                                <>
                                                                                    <div className='btn-group'>
                                                                                        <button className='btn btn-default btn-sm btn-left' onClick={() => handleDecrease(index)}>
                                                                                            <FaMinus />
                                                                                        </button>
                                                                                        <input type='text' value={rowCounts[index]} className='product-amount-field'
                                                                                            onChange={(e) => {
                                                                                                const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                                                updaterowCounts(index, cleanedValue);
                                                                                            }}
                                                                                        >
                                                                                        </input>
                                                                                        <button className='btn btn-default btn-sm btn-right' onClick={() => handleIncrease(index)}>
                                                                                            <FaPlus />
                                                                                        </button>

                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                    <>
                                                                                        <button className='btn btn-primary btn-outlined-main' onClick={() => handleAddProduct(index)}> <FaPlus /> Add</button>
                                                                                    </>

                                                                                )
                                                                            } */}
                                                                            {
                                                                                item?.is_selected ? (
                                                                                    <>
                                                                                        <div className='btn-group'>
                                                                                            <button className='btn btn-default btn-sm btn-left' onClick={() => handleDecrease(item?.id)}>
                                                                                                <FaMinus />
                                                                                            </button>
                                                                                            {/* <input type='text' value={rowCounts[index]} className='product-amount-field'
                                                                                                onChange={(e) => {
                                                                                                    const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                                                    updaterowCounts(index, cleanedValue);
                                                                                                }}
                                                                                            /> */}
                                                                                            <input
                                                                                                type='text'
                                                                                                value={item?.quantity}
                                                                                                className='product-amount-field'
                                                                                                onChange={(e) => {
                                                                                                    const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                                                    updaterowCounts(item?.id, cleanedValue);
                                                                                                }}
                                                                                            />
                                                                                            <button className='btn btn-default btn-sm btn-right' onClick={() => handleIncrease(item?.id)}>
                                                                                                <FaPlus />
                                                                                            </button>

                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <button className='btn btn-primary btn-outlined-main' onClick={() => handleAddProduct(item?.id)}> <FaPlus /> Add</button>
                                                                                    </>

                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            );
                                                        })
                                                    }
                                                    {isLoading && (
                                                        <tr className="no-hover">
                                                            <td colSpan={9}>
                                                                <div className="not-found-container">
                                                                    <div className="not-found-icon">
                                                                        <TbDatabaseExclamation />
                                                                    </div>
                                                                    <h6 className="not-found-text">No Data Available</h6>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </Scroller>
                            </div>
                            <div style={{ marginTop: '-20px' }}>

                                <Pagination pagination={pagination} data={handleData} onLoading={(e) => { setIsLoading(e) }} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="">
                    <div className="row">
                        <div className="col-12">

                            <div className="section-footer">
                                <div className="footer-left">
                                    <div className="produt-count">
                                        <label htmlFor=""> Products</label>
                                        <label>{totalProducts.toLocaleString()}</label>
                                    </div>
                                    {/* <div className="price">
                                        <label htmlFor=""> Price </label>
                                        <label>{currency === 'usd' ? ' $ ' : (currency === 'eur' ? ' € ' : (currency === 'aed' ? ' AED ' : ''))} {totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</label>
                                    </div> */}
                                </div>
                                <div className="footer-right">
                                    {/* <button className="btn btn-primary btn-outlined-main" onClick={() => close(selectedRows)}> */}
                                    <button className="btn btn-primary btn-outlined-main" onClick={() => handleDone(currentItems)}>
                                        <FaCheck /> Done
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        // <div>
        //     <div className="modal-body">
        //         <div className="card shadow-sm bg-white mb-4 table-card">

        //             <div className="section-head cstm-section-head">
        //                 <h2 style={{ fontSize: '18px' }}>Add products</h2>
        //                 {/* <IoIosClose className="close-Icon" onClick={closeFound} /> */}
        //             </div>
        //             <div className="align-items-center justify-content-between TableSerachBar">

        //                 <div className="justify-content-between align-items-center" style={{ gap: '10px' }}>
        //                     <form className="navbar-search">
        //                         <div className="input-group">
        //                             <input
        //                                 type="text"
        //                                 className="form-control bg-white border"
        //                                 placeholder="Search"
        //                                 aria-label="Search"
        //                                 aria-describedby="basic-addon2"
        //                                 onChange={(e) => {
        //                                     searchData(e);
        //                                 }}
        //                                 value={searchField}
        //                                 style={{ width: '100%' }}
        //                             />
        //                             <span className="searchIcon">
        //                                 <FaSearch />
        //                             </span>
        //                         </div>
        //                     </form>
        //                 </div>
        //             </div>
        //             <div className="table-responsive-xl tableData estimationList p-2">
        //                 <Scroller extraHeight={44}>
        //                     <table className="table">
        //                         <thead>
        //                             <tr className="sticky-header">
        //                                 <th className="align-middle" width={'5%'}>

        //                                 </th>
        //                                 <th scope="col" width={'40%'}>
        //                                     Name
        //                                 </th>
        //                                 <th scope="col" width={'15%'} className='text-center'>
        //                                     Free stock
        //                                 </th>
        //                                 {/* <th scope="col" width={'15%'} className='text-center'>Sold Last 28 days</th> */}
        //                                 <th scope="col" width={'15%'} className='text-center'>Price
        //                                     {currency === 'usd' ? ' ($)' : (currency === 'eur' ? ' (€)' : (currency === 'aed' ? ' (AED)' : ''))}</th>
        //                                 <th scope="col"></th>
        //                             </tr>
        //                         </thead>
        //                         <tbody>
        //                             {isLoading ? (
        //                                 <>
        //                                     {[...Array(5)].map((e, i) => (
        //                                         <tr>
        //                                             <>
        //                                                 {[...Array(9)].map((e, i) => (
        //                                                     <td>
        //                                                         <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
        //                                                     </td>
        //                                                 ))}
        //                                             </>
        //                                         </tr>
        //                                     ))}
        //                                 </>
        //                             ) : (
        //                                 <>

        //                                     {productList?.length > 0 &&
        //                                         productList?.map((item, index) => {
        //                                             return (
        //                                                 <>
        //                                                     <tr key={item?.id}>
        //                                                         <td> <CiMobile3 /> </td>
        //                                                         <td>{item?.product_name}</td>
        //                                                         <td className='text-center'>{item?.free_stocks}</td>
        //                                                         <td className='text-center cstm-flex'>
        //                                                             <label htmlFor="" style={{ margin: 'auto 0 auto 0' }}>{currency === 'usd' ? ' $' : (currency === 'eur' ? ' €' : (currency === 'aed' ? ' AED' : ''))}</label>
        //                                                             {item?.price}
        //                                                         </td>
        //                                                         <td>
        //                                                             {isAddList[index] ? (
        //                                                                 <>
        //                                                                     <div className='btn-group'>
        //                                                                         <button className='btn btn-default btn-sm btn-left' onClick={() => handleDecrease(index)}>
        //                                                                             <FaMinus />
        //                                                                         </button>
        //                                                                         <input type='text' value={rowCounts[index]} className='product-amount-field'
        //                                                                             onChange={(e) => {
        //                                                                                 const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
        //                                                                                 updaterowCounts(index, cleanedValue);
        //                                                                             }}
        //                                                                         >
        //                                                                         </input>
        //                                                                         <button className='btn btn-default btn-sm btn-right' onClick={() => handleIncrease(index)}>
        //                                                                             <FaPlus />
        //                                                                         </button>

        //                                                                     </div>
        //                                                                 </>
        //                                                             ) :
        //                                                                 (
        //                                                                     <>
        //                                                                         <button className='btn btn-primary btn-outlined-main' onClick={() => add(index)}> <FaPlus /> Add</button>
        //                                                                     </>

        //                                                                 )}

        //                                                         </td>
        //                                                     </tr>
        //                                                 </>
        //                                             );
        //                                         })}
        //                                     {isLoading && (
        //                                         <tr className="no-hover">
        //                                             <td colSpan={9}>
        //                                                 <div className="not-found-container">
        //                                                     <div className="not-found-icon">
        //                                                         <TbDatabaseExclamation />
        //                                                     </div>
        //                                                     <h6 className="not-found-text">No Data Available</h6>
        //                                                 </div>
        //                                             </td>
        //                                         </tr>
        //                                     )}
        //                                 </>
        //                             )}
        //                         </tbody>
        //                     </table>
        //                 </Scroller>
        //             </div>
        //             <Pagination pagination={pagination} data={handleData} onLoading={(e) => { }} />

        //         </div>
        //     </div>
        //     <div className="section-footer">
        //         <div className="footer-left">
        //             <div className="produt-count">
        //                 <label htmlFor=""> Products</label>
        //                 <label>{totalQuantity}</label>
        //             </div>
        //             <div className="price">
        //                 <label htmlFor=""> Price </label>
        //                 <label>{currency === 'usd' ? ' $ ' : (currency === 'eur' ? ' € ' : (currency === 'aed' ? ' AED ' : ''))} {totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</label>
        //             </div>
        //         </div>
        //         <div className="footer-right">
        //             <button className="btn btn-primary btn-outlined-main" onClick={() => close(selectedRows)}>
        //                 <FaCheck /> Done
        //             </button>
        //         </div>
        //     </div>
        // </div>
    );
};

export default ProductList;
