import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './PayPurchaseInvoiceModal.scss';

import { IoWarningOutline } from 'react-icons/io5';
import { PiWarningCircleFill, PiWarningFill } from 'react-icons/pi';
import { useEffect } from 'react';
import { BiBarcodeReader } from 'react-icons/bi';
// import BarcodeScannerComponent from 'react-qr-barcode-scanner-18';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { MdPayments } from 'react-icons/md';
import { getCurrencySymbol } from 'utils/currency';

function PayPurchaseInvoiceModal({ show, handleClose, onSubmit, title, message, customIcon, confirmDanger, isLoading }) {
	const confirmBtnRef = useRef(null);
	const barcodeInputFocus = useRef(null);
	const [scanBC, setScanBc] = useState('');
	const isMobile = useCheckMobileScreen();

	const [amount, setAmount] = useState('');

	useEffect(() => {
		if (!!scanBC) {
			setAmount(scanBC);
		}
	}, [scanBC]);

	useEffect(() => {
		setTimeout(() => {
			barcodeInputFocus?.current?.focus();
		}, 1);
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		onSubmit && onSubmit(amount);
	};

	const [payFull, setPayFull] = useState(true);

	useEffect(() => {
		if (payFull) {
			setAmount(show?.total_open);
		}
	}, [payFull]);

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className="pay-purchase-inv-modal-dialog"
		>
			<Modal.Body>
				<Modal.Title>
					<span> {customIcon ? customIcon : <MdPayments />}</span>
					{title || 'Pay Now'}
				</Modal.Title>

				<form onSubmit={handleSubmit}>
					<div>
						<div className="form-group" style={{ position: 'relative' }}>
							<span style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}>
								{getCurrencySymbol(show?.currency)}
							</span>
							<input
								ref={barcodeInputFocus}
								type="number"
								className={`form-control ${amount > show?.total_open ? 'error-input' : ''}`}
								value={amount}
								style={{ paddingLeft: '20px' }}
								onChange={(e) => {
									setAmount(e.target.value);
								}}
								required
								disabled={payFull}
							/>
						</div>
						<div className="form-group d-flex" style={{ position: 'relative', gap: '5px' }}>
							<input
								type="checkbox"
								id="pay-remains"
								onChange={() => {
									setPayFull(!payFull);
								}}
								checked={payFull}
							/>{' '}
							<label className="m-0" for="pay-remains">
								Pay remaining
							</label>
						</div>
					</div>

					<div className="modal-actions">
						<button
							ref={confirmBtnRef}
							type="submit"
							className={`btn btn-primary btn-outlined-main btn-confirm ${confirmDanger ? 'danger' : ''}`}
							disabled={isLoading || amount > show?.total_open}
						>
							Save Payment
						</button>

						<button
							className="btn btn-primary btn-outlined-main"
							type="button"
							onClick={(e) => {
								handleClose && handleClose(e);
							}}
						>
							Cancel
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}

export default PayPurchaseInvoiceModal;
