import React, { useState, useRef, useEffect } from 'react';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import { Modal } from 'react-bootstrap';
import { handleAddress } from 'utils/addressUtils';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { apiResponseErrorHandler } from 'helpers/common.helper';
import { FaUpload } from "react-icons/fa6";

import axios from 'axios';

const CreateDirectory = (
    { open, close,path,type }
    ) => {
   
    const fullNameRef = useRef();
    const selectedFile = useRef();
    const newNameRef = useRef();
    const [file, setFile] = useState(null);
    const [nameValue, setNameValue] = useState(null);
    const [NameAlert, setNameAlert] = useState('');
    const [folderinfo, setfolderinfo] = useState({
        directory_name: '',

    });
    const handleClose = (data) => {
        setNameValue(null);
        setAlertmessage('')
        console.log(data?.type)
        data?.type == 'click' ? close() : close(data);
        
    };
    const handleChange = (val) => {
      
        setfolderinfo(val);
    }
    const handleNameChange = (e) => {
      
        setNameValue(e.target.value);
    }
    const [alertMessage, setAlertmessage] = useState({});

    const onFileChange = (event) => {
        const selectedFile = event.target.files[0];
        
        setFile(selectedFile);
        console.log(selectedFile)
      };

      
    
    const onSubmit = () => {

        if(type == 'folder'){
            if(fullNameRef.current.value){
                
                let regex = /^[^\\/!@#$%^&*()_+=[\]{};':"\\|,.<>/?]*$/;
                if (!regex.test(fullNameRef.current.value)) {
                    setNameAlert(false);
                        setAlertmessage({
                            fullName: 'Please enter a valid folder name without special characters or forward slashes.'
                        });
                }else{
                    let fullPath =  `${open?.directory_path}/${folderinfo.directory_name}` ;
        
                    let replacedPath = fullPath.replace(/\.\.\//g, '');
                    replacedPath = replacedPath.replace(/\/\//g, '/');
                    console.log(replacedPath)
                    api.addDirectory({
                        directory_path: replacedPath,
                        directory_name: folderinfo.directory_name,
                        parent_id : open?.id,
                        contact_id: open?.contact_id,
                        isFile:0,
                    }).then((res) => {
                        console.log(res)
                        if (res.message) {
                            handleClose(res.message)
                            setAlertmessage('')

                        } else {
                                setAlertmessage({
                                    failed: res.error
                                });
                            setTimeout(() => {
                                setAlertmessage();
                            }, 2000);
                        }
                    });
                    
                }
                        
            } 
            else {
                setAlertmessage({
                    fullName: 'Folder Name is required.'
                });
                
            
        }
        
        
    
    setTimeout(() => {
        setAlertmessage('')
    }, 1500)

        }else if(type == 'rename'){
            
            if(newNameRef.current.value){
                if(newNameRef.current.value == open?.name){
                    handleClose()
                    return false
                }
                let regex = /^[^\\/!@#$%^&*()+=[\]{};':"\\|,.<>/?]*$/;
                if (!regex.test(newNameRef.current.value)) {
                    setNameAlert(false);
                        setAlertmessage({
                            fullName: 'Please enter a valid name without special characters or forward slashes.'
                        });
                }else{
                    api.renameDirectory(open?.id,{name: newNameRef.current.value}).then((res) => {
                    {console.log('renamed', res)}
                        if (res.message) {
                            handleClose(res.message);
                            setAlertmessage('')

                        } else {
                                setAlertmessage({
                                    failed: res.error
                                });
                            setTimeout(() => {
                                setAlertmessage();
                            }, 2000);
                        }
                     })

                }
            }
            else{
                setAlertmessage({
                    fullName: 'Name cannot be empty'
                });
            }
        }
        else {
            api.addDirectory({
                directory_path: open?.directory_path,
                directory_name: 'fileName',
                parent_id : open?.id,
                contact_id: open?.contact_id,
                isFile:1,
                file:file
            }).then((res) => {
                console.log(res)
                if (res.message) {
                    handleClose(res.message);
                    setAlertmessage('')

                } else {
                        setAlertmessage({
                            failed: res.error
                        });
                    setTimeout(() => {
                        setAlertmessage();
                    }, 2000);
                }
            });
        }
    }
    const namehandle = (e) => {
        let inputValue = e.target.value;
        const words = inputValue.split(' ');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
        inputValue = capitalizedWords.join(' ');
        e.target.value = inputValue;
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          onSubmit();
        }
      };

    return (
        <Modal show={open} onHide={handleClose} centered>
            <Modal.Body>
              

                    {alertMessage?.failed && (
                        <>
                            <span className="error-message text-danger">{alertMessage?.failed}</span>
                        </>
                     )}
                <div className="row py-3 px-2">
                    <div className="col-md-12">
                    
                        <h4 className="h6 text-white text-center">
                           {type == 'folder' ? 'Create Folder' : type == 'rename' ? 'Enter a new name' : 'Upload file'}
                        </h4>
                        
                        <div className="custom-control custom-checkbox inputchechbox py-2">
                            <form className="suppliecreatepop form-row">
                            
                                <div className="newrow">
                                {type == 'folder' ? (
                                      <div className="form-group col-md-12">
                                      <label className="">Folder Name</label>
                                      <input
                                          ref={fullNameRef}
                                          type='text'
                                          id="input-example"
                                          name="input-name"
                                          className="form-control"
                                          placeholder="Enter Folder Name"
                                          onKeyDown={handleKeyPress}
                                          onChange={(e) => {
                                              namehandle(e);
                                              handleChange({ ...folderinfo, directory_name: e.target.value });
                                          }}
                                      />
                                          {NameAlert == true && <i className="fas fa-check"></i>}
                                          {NameAlert == false && alertMessage?.fullName && (
                                              <i className="fas fa-times"></i>
                                          )}
                                      {alertMessage?.fullName && (
                                          <>
                                              <span className="error-message text-danger">{alertMessage?.fullName}</span>
                                          </>
                                      )}
                                  </div>
                                ) : type == 'rename' ? (
                                    <div className="form-group col-md-12">
                                      
                                      <input
                                          ref={newNameRef}
                                          type='text'
                                          id="input-example"
                                          name="input-name"
                                          className="form-control"
                                          onKeyDown={handleKeyPress}
                                          value={ nameValue == undefined ? open?.name : nameValue}
                                          onChange={handleNameChange}
                                      />
                                          {NameAlert == true && <i className="fas fa-check"></i>}
                                          {NameAlert == false && alertMessage?.fullName && (
                                              <i className="fas fa-times"></i>
                                          )}
                                      {alertMessage?.fullName && (
                                          <>
                                              <span className="error-message text-danger">{alertMessage?.fullName}</span>
                                          </>
                                      )}
                                  </div>
                                ) : (
                                    <div className="form-group col-md-12">
                                        <label className="">Select File</label>
                                        <input
                                            ref={selectedFile}
                                            type='file'
                                            id="input-example"
                                            name="input-name"
                                            className="form-control"
                                            onChange={onFileChange}
                                            onKeyDown={handleKeyPress}
                                        />
                                            {NameAlert == true && <i className="fas fa-check"></i>}
                                            {NameAlert == false && alertMessage?.fullName && (
                                                <i className="fas fa-times"></i>
                                            )}
                                        {alertMessage?.fullName && (
                                            <>
                                                <span className="error-message text-danger">{alertMessage?.fullName}</span>
                                            </>
                                        )}
                                    </div>
                                )}
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row w-100 ">
                    <div className="btnarea w-100 createDirectoryButtons">
                        <button
                            onClick={handleClose}
                            className="btn btn-link"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onSubmit}
                            className="btn btn-primary btn-outlined-main"
                        >   
                            {type == 'folder' ? (
                                <>
                                <FaPlus /> Create
                                </>
                            ) : type == 'rename' ?(
                                <>
                                Rename
                                </>

                            ): (
                                <>
                                 <FaUpload /> Upload
                                </>
                            )}
                            
                        </button>
                    </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    )
}

export default CreateDirectory
