import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { FaSave, FaTimes } from 'react-icons/fa';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa6';
import { toast } from 'react-hot-toast';
import NoImage from 'assets/images/no-image.png';

const ProductCommentModal = ({ name, image, comment, handleClose }) => {
    // const [data, setData] = useState([]);
    // const [awbNumber, setAwbNumber] = useState('');
    // const [isFileUploaded, setIsFileUploaded] = useState(false);

    // const onLogoDrop = useCallback((acceptedFiles) => {
    // console.log('onLogoDrop triggered', acceptedFiles);

    // if (validateFileExtension(acceptedFiles[0].name)) {
    // 	const formData = new FormData();
    // 	formData.append('awb', acceptedFiles[0]);
    // 	formData.append('originalFileName', acceptedFiles[0].name);
    // 	formData.append('estimate_id', selectedEstimate.id);
    // 	formData.append('awbNumber', awbNumber); // append awbNumber here
    // 	setIsFileUploaded(true);
    // 	setData(formData);
    // } else {
    // 	toast.error('Invalid file extension. Please select a PNG, JPEG, JPG, or SVG file.');
    // }
    // }, [awbNumber]); // add awbNumber as a dependency

    // console.log(awbNumber);
    // const validateFileExtension = (fileName) => {
    // 	const allowedExtensions = ['png', 'jpeg', 'jpg', 'svg', 'pdf'];
    // 	const fileExtension = fileName.split('.').pop().toLowerCase();
    // 	return allowedExtensions.includes(fileExtension);
    // };

    // const handleSubmit = () => {
    // 	api
    // 		.uploadAWB(data)
    // 		.then((res) => {
    // 			if (res.status == true) {
    // 				toast.success(res.message);
    // 				handleClose();
    // 			} else {
    // 				toast.error(res.message);
    // 			}
    // 		})
    // 		.catch((err) => {
    // 			toast.error('Something went wrong');
    // 		});
    // };

    // const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({ onDrop: onLogoDrop });

    return (
        <Modal show onHide={handleClose} centered size="md">
            <Modal.Body>
                <div className="p-2">
                    <div className="mb-2 ml-0 pl-0">
                        <div className="row" style={{ marginBottom: 20 }}>
                            <div className="col-12 d-flex align-items-center justify-content-between">
                                <h4 style={{ marginBottom: 0, fontWeight: 600 }}>Product Comment</h4>
                                <button className="btn modal-close-icon" onClick={handleClose} style={{ top: '-9px', right: 0 }}>
                                    <svg
                                        id="Component_127_1"
                                        height="16"
                                        width="16"
                                        data-name="Component 127 – 1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 19.295 19.289"
                                    >
                                        <path
                                            id="Icon_ionic-ios-close"
                                            data-name="Icon ionic-ios-close"
                                            d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
                                            transform="translate(-11.285 -11.289)"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 20 }}>
                            <div className="col-12" style={{ display: 'flex' }}>
                                <div>
                                    {
                                        image ? (
                                            <>
                                                <img src={image} alt="Product" style={{ width: '75px', height: 'auto' }} />
                                            </>
                                        ) : (
                                            <>
                                                <img src={NoImage} alt="Product" style={{ width: '52px', height: 'auto' }} />
                                            </>
                                        )
                                    }
                                </div>
                                <div>

                                    <div className="col-12 form-group font500" style={{ fontSize: '15px' }}>
                                        {name}
                                    </div>
                                    <div className="col-lg-12">
                                        <label className='textGray' style={{ fontStyle: 'italic' }}>
                                            <FaQuoteLeft style={{ marginTop: '-10px' }} />
                                            {' '}{comment}{' '}
                                            <FaQuoteRight style={{ marginTop: '-10px' }} />
                                        </label>
                                        {/* <input type="text" className="form-control" onChange={(e) => setAwbNumber(e.target.value)} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <button className="btn btn-main"onClick={handleSubmit}>
                                    <FaSave /> Save
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ProductCommentModal;
