import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './AddRepairsModal.scss';

import { IoWarningOutline } from 'react-icons/io5';
import { PiWarningCircleFill, PiWarningFill } from 'react-icons/pi';
import { useEffect } from 'react';
import { BiBarcodeReader } from 'react-icons/bi';
import Select from 'react-select';
import { GiAutoRepair } from 'react-icons/gi';

function AddRepairsModal({ show, handleClose, onSubmit, title, message, customIcon, confirmDanger, isLoading, repairOptions }) {
	const confirmBtnRef = useRef(null);
	// const barcodeInputFocus = useRef(null);

	const [selectedRepairs, setSelectedRepairs] = useState('');

	useEffect(() => {
		if (show?.repairs) {
			console.log(show?.repairs);
			setSelectedRepairs(show?.repairs);
		}
		console.log('ssrepair types')
		console.log(repairOptions)
		console.log('se repair types')
	}, [repairOptions]);

	const handleSubmit = (event) => {
		event.preventDefault();
		onSubmit && onSubmit({ ...show, repairs: selectedRepairs });
	};

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className="add-repairs-dialog"
		>
			<Modal.Body>
				<Modal.Title>
					<span> {customIcon ? customIcon : <GiAutoRepair />}</span>
					{title || 'Add Repairs'}
				</Modal.Title>

				<form onSubmit={handleSubmit}>
					<div>
						<p className="message">
							Select the repairs you would like to add to this <br /> {show?.product?.product_name} {show?.color}
						</p>
						<div className="form-group">
							<Select
								options={repairOptions?.map((r) => ({ ...r, value: r?.repair_type_id, label: r?.repair_type?.name }))}
								className="rs-container"
								classNamePrefix="rs"
								isMulti
								isClearable={false}
								onChange={(e) => {
									setSelectedRepairs(e);
								}}
								defaultValue={show?.repairs}
								closeMenuOnSelect={false}
							/>
						</div>
					</div>

					<div className="modal-actions">
						<button
							ref={confirmBtnRef}
							type="submit"
							className={`btn btn-primary btn-outlined-main btn-confirm ${confirmDanger ? 'danger' : ''}`}
							disabled={isLoading}
						>
							Continue
						</button>

						<button
							className="btn btn-primary btn-outlined-main"
							type="button"
							onClick={(e) => {
								handleClose && handleClose(e);
							}}
						>
							Cancel
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}

export default AddRepairsModal;
