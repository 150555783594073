import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaSave } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const PasswordForm = () => {
	const userId = useSelector((state) => state.main.user.id);
	const [oldPasswrd, setOldPassword] = useState('');
	const [newPass, setNewPass] = useState('');
	const [confirmNewpass, setConfirmnewpass] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordone, setShowPasswordone] = useState(false);
	const [showPasswordtwo, setShowPasswordtwo] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState();
	const [alertMsg, setAlertMsg] = useState({ color: '', msg: '' });

	const onSubmit = (e) => {
		e.preventDefault();
		if (oldPasswrd == '') {
			setErrors({
				password: true,
				oldPassword: 'Field is required.'
			});
		} else if (newPass == '') {
			setErrors({
				password: true,
				newPassword: 'Field is required.'
			});
		} else if (confirmNewpass == '') {
			setErrors({
				password: true,
				confPassword: 'Field is required.'
			});
		} else {
			setIsLoading(true);
			const data = {
				user_id: userId,
				current_password: oldPasswrd,
				new_password: newPass,
				new_password_confirmation: confirmNewpass
			};
			api.changePassword(data).then((res) => {
				if (res && res.status) {
					setIsLoading(false);
					toast.success(res.message);
				} else {
					if (res?.errors?.new_password?.length > 0) {
						setErrors({
							password: true,
							confPassword: res.errors.new_password[0]
						});
					} else {
						setErrors({
							password: true,
							oldPassword: res.message
						});
					}
				}
			});
		}
	};
	const changeOldpasswordview = () => {
		setShowPassword(!showPassword);
	};
	const changeOldpasswordtwoview = () => {
		setShowPasswordone(!showPasswordone);
	};
	const changeOldpasswordthreeview = () => {
		setShowPasswordtwo(!showPasswordtwo);
	};
	const passwordValidation = (e) => {
		const password = e.target.value;
		if (password.length < 7) {
			setErrors({
				password: true,
				newPassword: 'Password must be at least 7 characters'
			});
		} else {
			setErrors('');
		}
		setTimeout(() => {
			setErrors('');
		}, 2000);
	};
	const confirmpasswordhandle = (e) => {
		const contfirm_password = e.target.value;
		if (contfirm_password !== newPass) {
			setErrors({
				confirmPassword: true,
				confPassword: 'Confirm Password is not matched'
			});
		} else {
			setErrors('');
		}
		setTimeout(() => {
			setErrors('');
		}, 2000);
	};
	return (
		<>
			<div className="row passwordReset m-0 d-flex justify-content-center align-items-center">
				<form className="pt-4 col-md-12" onSubmit={onSubmit} style={{ maxWidth: '400px' }}>
					<div className="form-row d-flex- flex-column">
						<div className="form-group-inline mb-4">
							<label htmlFor="inputName" className="text-default" style={{ width: '220px' }}>
								Old Password
							</label>

							<input
								type={showPassword ? 'text' : 'password'}
								className="form-control"
								id="inputName"
								onChange={(e) => {
									setOldPassword(e.target.value);
								}}
							/>
							{/* <i
								onClick={changeOldpasswordview}
								className={`far passeye ${showPassword ? 'fa-eye-slash' : 'fa-eye'} text-default`}
							/> */}
						</div>

						<div className="form-group-inline mb-4">
							<label htmlFor="inputEmail4" className="text-default" style={{ width: '220px' }}>
								New Password
							</label>
							<input
								type={showPasswordone ? 'text' : 'password'}
								className="form-control"
								id="inputEmail4"
								onChange={(e) => {
									passwordValidation(e);
									setNewPass(e.target.value);
								}}
							/>
							{/* <i
								onClick={changeOldpasswordtwoview}
								className={`far passeye ${showPasswordone ? 'fa-eye-slash' : 'fa-eye'} text-default`}
							/> */}
						</div>

						<div className="form-group-inline mb-4">
							<label htmlFor="vatNumber" className="text-default" style={{ width: '220px' }}>
								Confirm New Password
							</label>
							<input
								type={showPasswordtwo ? 'text' : 'password'}
								className="form-control"
								id="vatNumber"
								onChange={(e) => {
									confirmpasswordhandle(e);
									setConfirmnewpass(e.target.value);
								}}
							/>
							{/* <i
								onClick={changeOldpasswordthreeview}
								className={`far passeye ${showPasswordtwo ? 'fa-eye-slash' : 'fa-eye'} `}
							/> */}
						</div>

						{errors?.oldPassword && <p className="mt-2 text-danger">{errors?.oldPassword}</p>}
						{errors?.newPassword && <p className="mt-2 text-danger">{errors?.newPassword}</p>}
						{errors?.confPassword && <p className="mt-2 text-danger">{errors?.confPassword}</p>}

						<div className="form-group col-md-12 mt-">
							<div className="btnarea">
								<button type="submit" className="btn btn-primary btn-outlined-main">
									<FaSave /> Update
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default PasswordForm;
