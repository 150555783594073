import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { FaSpinner, FaTimes, FaPlus, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { WarningIcon, CloseIcon, DeleteIcon2 } from 'ui-component/custom-icons/icons';
import NoImage from 'assets/images/no-image.png';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelect from 'react-select';
import Select from 'react-select';
import CurrencyValue from 'ui-component/CurrencyValue';
import { ConstructionOutlined } from '@mui/icons-material';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';


const ProcessOrderModal = ({ openPopup, collection, handleClose, handleReloadDetails, handleProcessPurchaseOrder, handleSendToOperation }) => {

    const [processingPurchaseOrderButton, setProcessingPurchaseOrderButton] = useState(false);
    const [collectionDetails, setCollectionDetails] = useState(null);
    const [disabledPurchaseOrderButton, setDisabledPurchaseOrderButton] = useState(true);
    const [disabledPicklistButton, setDisabledPicklistButton] = useState(true);
    const [disabledOperationsButton, setDisabledOperationsButton] = useState(true);
    const [processOrderStep, setProcessOrderStep] = useState(1);
    const [supplierList, setSupplierList] = useState([]);
    const [warehouseList, setWarehouseList] = useState([]);
    const currentDate = moment().format('YYYY-MM-DD');
    const [defaultCurrency, setDefaultCurrency] = useState('usd');
    const [availableProductLists, setAvailableProductLists] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [proceeedToCreatePurchaseOrder, setProceedToCreatePurchaseOrder] = useState(false);
    const [isRecordProcessing, setIsRecordProcessing] = useState(false);
    
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);

    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({
        salesOrderId: collection?.id,
        referenceNo: collection?.sales_order_number,
        supplierId: '',
        expectedDate: currentDate,
        warehouseId: '',
        currency: defaultCurrency,
        paymentMethod: '',
        notes: '',
        items: []
    })

    const currencies = [
        { label: 'EUR', value: 'eur' },
        { label: 'USD', value: 'usd' },
        { label: 'AED', value: 'aed' }
    ];

    const paymentMethods = [
        { label: 'In Credit', value: 'in_credit' },
        { label: 'Upfront Payment', value: 'upfront_payment' }
    ];

    useEffect(() => {
        api.loadDetailsForProcessOrder(collection?.id).then((res) => {
            if (res?.status) {
                setDisabledPurchaseOrderButton(res?.data?.has_purchase_order)
                setDisabledPicklistButton(res?.data?.has_picklist)
                setDisabledOperationsButton(res?.data?.has_operations)
                setCollectionDetails(res?.data);
                setDefaultCurrency(res?.data?.currency);
                const identifyToProceedInPurchaseOrder = res?.data?.sales_order_items?.filter(item => item.proceed_to_create_purchase_order === true)?.length > 0 ? true : false;
                setProceedToCreatePurchaseOrder(identifyToProceedInPurchaseOrder)


                


                setPurchaseOrderItems(res?.data?.sales_order_items?.filter(item => item.allowed_qty_to_purchase !== 0));


                const productLists = res?.data?.sales_order_items?.filter(item => item.allowed_qty_to_purchase !== 0)?.map((item) => ({
                    ...item,
                    // label: `${item.product_name}`,
                    label: `<div style="display: flex; justify-content: space-between; margin-left: 10px; margin-right: 10px;">
                            <span style="color: #252424;">${item?.product_name}</span>
                            <span style="color: #252424; position: absolute; left: 600px;">$ ${item?.price}</span>
                            <span style="color: ${item?.free_stock > 0 ? '#3ac87f' : '#9c9c9c' }">${item?.free_stock}</span>
                            </div>`,
                    value: item.product_id,
                }));
                setAvailableProductLists(productLists);

            }
        });
        api.getSelectOptionsList('supplier').then((res) => {
            const customerArray = res?.data?.map((item) => ({ id: item.id, label: item.name, value: item.id }));
            setSupplierList(customerArray);
           
        });
        api.getSelectOptionsList('warehouse').then((res) => {
            const customerArray = res?.data?.map((item) => ({ id: item.id, label: item.name, value: item.id }));
            setWarehouseList(customerArray);
        });
        setProcessOrderStep(1)
    }, []);

    const disablepopup = () => {
        handleClose();
    };


    const handlePurchaseOrderInfo = (val) => {
        setPurchaseOrderDetails(val);
    };

    const handeChangePurchaseQty = (index, value) => {
        const allowedQty = purchaseOrderItems[index].allowed_qty_to_purchase;
        let purchaseQuantity = parseInt(value) > 0 ? parseInt(value) : 0;
 
        // Ensure the purchase quantity does not exceed the allowed quantity
        if (purchaseQuantity > allowedQty) {
            purchaseQuantity = allowedQty;
        }
        
        const updatedItem = {
            ...purchaseOrderItems[index],
            purchase_qty: purchaseQuantity,
        };
        setPurchaseOrderItems(prevItems => 
            prevItems.map((item, i) => 
                i === index ? updatedItem : item
            )
        );
        handleComputeProfit(index, purchaseQuantity, purchaseOrderItems[index].purchase_price);

    }

    const handeChangePurchasePrice = (index, value) => {
        const purchasePrice = parseFloat(value) > 0 ? parseFloat(value) : 0;
        
        const updatedItem = {
            ...purchaseOrderItems[index],
            purchase_price: purchasePrice,
        };
        
        setPurchaseOrderItems(prevItems => 
            prevItems.map((item, i) => 
                i === index ? { ...item, ...updatedItem } : item
            )
        );
        handleComputeProfit(index, purchaseOrderItems[index].purchase_qty, purchasePrice);
        
    };
    
    const handleComputeProfit = (index, quantity, purchasePrice) => {
        const sellingPrice = purchaseOrderItems[index]?.converted_unit_price;
        let collections = purchaseOrderItems[index];
        let grossProfit = null;
        let profitPercentage = null;
        let totalProfit = null;
        if (!isNaN(purchasePrice) || purchasePrice > 0) { 

            if (purchasePrice < 1) {
                if (quantity < 1) {

                    grossProfit = {
                        'usd': parseFloat(0),
                        'eur': parseFloat(0),
                        'aed': parseFloat(0),
                    };
                    profitPercentage = {
                        'usd': parseFloat('0'),
                        'eur': parseFloat('0'),
                        'aed': parseFloat('0'),
                    };
                } else {
                    grossProfit = {
                        'usd': (parseFloat(sellingPrice['usd']) - parseFloat(purchasePrice)),
                        'eur': (parseFloat(sellingPrice['eur']) - parseFloat(purchasePrice)),
                        'aed': (parseFloat(sellingPrice['aed']) - parseFloat(purchasePrice)),
                    };
                    profitPercentage = {
                        'usd': parseFloat('100'),
                        'eur': parseFloat('100'),
                        'aed': parseFloat('100'),
                    };
                }
            } else if (quantity < 1) {
                if (purchasePrice < 1) {
                    grossProfit = {
                        'usd': parseFloat(0),
                        'eur': parseFloat(0),
                        'aed': parseFloat(0),
                    };
                    profitPercentage = {
                        'usd': parseFloat('0'),
                        'eur': parseFloat('0'),
                        'aed': parseFloat('0'),
                    };
                } else {
                    if (quantity < 1) {
                        grossProfit = {
                            'usd': parseFloat(0),
                            'eur': parseFloat(0),
                            'aed': parseFloat(0),
                        };
                        profitPercentage = {
                            'usd': parseFloat('0'),
                            'eur': parseFloat('0'),
                            'aed': parseFloat('0'),
                        };
                    } else {

                        grossProfit = {
                            'usd': (parseFloat(sellingPrice['usd']) - parseFloat(purchasePrice)),
                            'eur': (parseFloat(sellingPrice['eur']) - parseFloat(purchasePrice)),
                            'aed': (parseFloat(sellingPrice['aed']) - parseFloat(purchasePrice)),
                        };
                        profitPercentage = {
                            'usd': (((parseFloat(sellingPrice['usd']) - parseFloat(purchasePrice)) * 100) / parseFloat(purchasePrice)),
                            'eur': (((parseFloat(sellingPrice['eur']) - parseFloat(purchasePrice)) * 100) / parseFloat(purchasePrice)),
                            'aed': (((parseFloat(sellingPrice['aed']) - parseFloat(purchasePrice)) * 100) / parseFloat(purchasePrice)),
                        };
                    }
                }
            } else {
                grossProfit = {
                    'usd': (parseFloat(sellingPrice['usd']) - parseFloat(purchasePrice)),
                    'eur': (parseFloat(sellingPrice['eur']) - parseFloat(purchasePrice)),
                    'aed': (parseFloat(sellingPrice['aed']) - parseFloat(purchasePrice)),
                };
                
                profitPercentage = {
                    'usd': (((parseFloat(sellingPrice['usd']) - parseFloat(purchasePrice)) * 100) / parseFloat(purchasePrice)),
                    'eur': (((parseFloat(sellingPrice['eur']) - parseFloat(purchasePrice)) * 100) / parseFloat(purchasePrice)),
                    'aed': (((parseFloat(sellingPrice['aed']) - parseFloat(purchasePrice)) * 100) / parseFloat(purchasePrice)),
                };
            }
            totalProfit = {
                'usd': (parseFloat(sellingPrice['usd']) - parseFloat(purchasePrice)) * quantity,
                'eur': (parseFloat(sellingPrice['eur']) - parseFloat(purchasePrice)) * quantity,
                'aed': (parseFloat(sellingPrice['aed']) - parseFloat(purchasePrice)) * quantity,
            };
        } else {

            grossProfit = {
                'usd': parseFloat(sellingPrice['usd']),
                'eur': parseFloat(sellingPrice['eur']),
                'aed': parseFloat(sellingPrice['aed']),
            };
            profitPercentage = {
                'usd': parseFloat('100'),
                'eur': parseFloat('100'),
                'aed': parseFloat('100'),
            };
            totalProfit = {
                'usd': parseFloat(sellingPrice['usd']) * quantity,
                'eur': parseFloat(sellingPrice['eur']) * quantity,
                'aed': parseFloat(sellingPrice['eur']) * quantity,
            };
        }


        const updatedItem = {
            ...purchaseOrderItems[index],
            purchase_price: purchasePrice,
            purchase_qty: quantity,
            gross_profit: grossProfit,
            profit_percentage: profitPercentage,
            total_profit: totalProfit
        };

        setPurchaseOrderItems(prevItems => 
            prevItems.map((item, i) => 
                i === index ? { ...item, ...updatedItem } : item
            )
        );

    }

    const formValidation = () => {
        let isValid = true;
        if (typeof purchaseOrderDetails?.supplierId === 'undefined' || purchaseOrderDetails?.supplierId == '') {
            isValid = false;
            toast.error('Supplier is required');
        } else if (typeof purchaseOrderDetails?.expectedDate === 'undefined' || purchaseOrderDetails?.expectedDate == '') {
            isValid = false;
            toast.error('Expected delivery date is required');
        } else if (typeof purchaseOrderDetails?.warehouseId === 'undefined' || purchaseOrderDetails?.warehouseId == '') {
            isValid = false;
            toast.error(typeof purchaseOrderDetails?.warehouseId === 'undefined' || 'Warehouse is required');
        } else if (typeof purchaseOrderDetails?.currency === 'undefined' || purchaseOrderDetails?.currency == '') {
            isValid = false;
            toast.error('Currency is required');
        } else if (typeof purchaseOrderDetails?.paymentMethod === 'undefined' || purchaseOrderDetails?.paymentMethod == '') {
            isValid = false;
            toast.error('Payment method is required');
        } else if (purchaseOrderItems.length < 1) {
            isValid = false;
            toast.error('Please add at least one product');
        } else if (!validateRows()) {
            isValid = false;
            toast.error('Please fill in all fields for each product');
        }

        return isValid;
    };

    const validateRows = () => {
        for (let i = 0; i < purchaseOrderItems.length; i++) {
            console.log(purchaseOrderItems[i]);
            const { product_id, purchase_qty, purchase_price } = purchaseOrderItems[i];
            if (i != purchaseOrderItems.length - 1) {
                if (!product_id || !purchase_qty || !purchase_price) {
                    return false;
                }
            }
        }
        return true;
    };

    const submitPurchaseOrder = () => {
            
        if (formValidation()) {
            const data = {
                sales_order_id: purchaseOrderDetails?.salesOrderId,
                reference_no: purchaseOrderDetails?.referenceNo,
                supplier_id: purchaseOrderDetails?.supplierId,
                expected_date: purchaseOrderDetails?.expectedDate,
                warehouse_id: purchaseOrderDetails?.warehouseId,
                currency: purchaseOrderDetails?.currency,
                payment_method: purchaseOrderDetails?.paymentMethod,
                notes: purchaseOrderDetails?.notes,
                products: purchaseOrderItems
            }
            api.createSalesOrderPurchaseOrder(data).then(
                (res) => {
                    console.log(res);
                    if (res.status) {
                        toast.success(res.message);
                        handleReloadDetails();
                    } else {
                        toast.error(res.message);
                    }
                },
                () => { }
            );
        }
    }
    const getFilteredOptions = (index) => {
        return availableProductLists.filter(option => 
            !Object.values(selectedProducts).includes(option.value) || selectedProducts[index] === option.value
        );
    };

    const getSelectedOption = (productId) => {
        return availableProductLists.find(option => option.value === productId) || null;
    };
    
    const handleModelChange = (index, selectedOption) => {
        const updatedItem = {
            ...purchaseOrderItems[index],
            base_purchase_qty: selectedOption?.base_purchase_qty,
            converted_unit_price: selectedOption?.converted_unit_price,
            free_stock : selectedOption?.free_stock,
            gross_profit : selectedOption?.gross_profit,
            id: '',
            product_id: selectedOption?.product_id,
            product_name: selectedOption?.product_name,
            profit_percentage: selectedOption?.profit_percentage,
            purchase_price: selectedOption?.purchase_price,
            purchase_qty: selectedOption?.purchase_qty,
            purchased_stock: selectedOption?.purchased_stock,
            qty: selectedOption?.qty,
            sku: selectedOption?.sku,
            thumbnail_url: selectedOption?.thumbnail_url,
            total_profit: selectedOption?.total_profit,
            unit_price: selectedOption?.unit_price
        };
        // console.log('updatedItem',updatedItem)
        setPurchaseOrderItems(prevItems => 
            prevItems.map((item, i) => 
                i === index ? { ...item, ...updatedItem } : item
            )
        );
        // setSelectedProducts(prevState => ({
        //     ...prevState,
        //     [index]: selectedOption.value
        // }));
    };
    
    const removeProductFromList = (index) => {
        setPurchaseOrderItems(purchaseOrderItems.filter((_, i) => i !== index));
    };
    
    const addItemRow = () => {
        const newRow = {
            id: '',
            base_purchase_qty: 0,
            converted_unit_price: {usd: '0.00', aed: '0.00', eur: '0.00'},
            free_stock : 0,
            gross_profit : {usd: '0.00', eur: '0.00', aed: '0.00'},
            product_id: 0,
            product_name: '',
            profit_percentage: {usd: 0, eur: 0, aed: 0},
            purchase_price: 0,
            purchase_qty: 0,
            purchased_stock: 0,
            qty: 0,
            sku: '',
            thumbnail_url: '',
            total_profit: {usd: '0.00', eur: '0.00', aed: '0.00'},
            unit_price: '0.00'
        }
        // const newProduct = { id: purchaseOrderItems.length + 1, name: `Product ${purchaseOrderItems.length + 1}`, price: 0 };
        setPurchaseOrderItems([...purchaseOrderItems, newRow]);
    };

    const handleProcessPicklist = () => {

        const data = {
            sales_order_id: collectionDetails?.id
        }
        setIsRecordProcessing(true);
        api.createSalesOrderPicklist(data).then(
            (res) => {
                if (res.status) {
                    toast.success(res.message);
                    setIsRecordProcessing(false);
                    handleReloadDetails();
                } else {
                    toast.error(res.message);
                }
            },
            () => { }
        );
    }

        
    return (
        <>
            <Modal show={openPopup} onHide={disablepopup} centered size="xl">
                <Modal.Body>
                    {
                        processOrderStep === 1 ? (
                            <>
                                        
                                <div className="p-2">
                                    <div style={{ padding: '20px' }}>
                                        <div className="mb-2 ml-0 pl-0">

                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <WarningIcon height='50px' width='50px' />
                                                </div>
                                            </div>
                                            <button className="btn modal-close-icon" onClick={handleClose}>
                                                <CloseIcon height={'16px'} width={'16px'} />
                                            </button>
                                        </div>

                                        <div className="row align-items-top">
                                            <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                                <div style={{ fontSize: '24px' }}>
                                                    Order Process
                                                </div>
                                                <div>
                                                    To process this order, please select at least one of the following actions:
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-12 text-center " style={{ padding: '10px 0' }}>
                                                <div className="row">
                                                    <div className="col-lg-12 form-group">
                                                        <div className="card">
                                                            <div className="card-body">



                                                                {/* <div className="row">
                                                                    <div className="col-12 form-group font500">Items Ordered</div>
                                                                </div> */}
                                                                <div className="row" style={{ borderBottom: '2px solid #e0e0e0' }}>
                                                                    <div className="col-6 col-lg-6 text-left form-group font500">Product</div>
                                                                    <div className="col-1 col-lg-2 text-left form-group font500">Qty</div>
                                                                    <div className="col-3 col-lg-2 text-left form-group font500">Stocks</div>
                                                                    <div className="col-2 col-lg-2 text-left form-group font500">Remaining</div>
                                                                </div>
                                                                <div style={{ padding: '10px 0' }}>
                                                                    {
                                                                        collectionDetails?.sales_order_items?.length > 0 && (
                                                                            <>
                                                                                {
                                                                                    // collectionDetails?.sales_order_items?.filter(item => item.allowed_qty_to_purchase !== 0)?.map((item, index) => (
                                                                                    collectionDetails?.sales_order_items?.filter(item => item.purchased_all_product === false)?.map((item, index) => (
                                                                                        <>
                                                                                                        
                                                                                            <div className="row" style={{ lineHeight: '30px' }}>
                                                                                                <div className="col-6 col-lg-6 text-left d-flex">
                                                                                                    <div>
                                                                                                        {
                                                                                                            item?.thumbnail_url ? (
                                                                                                                <img src={item?.thumbnail_url} style={{ width: '25px' }} />
                                                                                                            ) : (
                                                                                                                <img src={NoImage} style={{ width: '25px' }} />
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div style={{ marginLeft: '5px' }}>
                                                                                                        {item?.product_name}
                                                                                                        
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-1 col-lg-2 text-left d-flex">
                                                                                                    <div>{item?.qty}</div>
                                                                                                    
                                                                                                    <div>
                                                                                                        {
                                                                                                            item?.total_purchased_qty > 0 && (
                                                                                                                <>
                                                                                                                    <Tooltip title={<span style={{ fontSize: "13px" }}>Quantity processed</span>} arrow placement="top"><span className="textGray" style={{ marginLeft: '5px' }}>- <span>{ item?.total_purchased_qty.toLocaleString() }</span></span></Tooltip>
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                    
                                                                                                </div>
                                                                                                <div className="col-3 col-lg-2 text-left d-flex">
                                                                                                    <div className="">{item?.free_stock.toLocaleString()}</div>
                                                                                                    {
                                                                                                        item?.purchased_stock > 0 && (
                                                                                                            <>
                                                                                                                <Tooltip title={<span style={{ fontSize: "13px" }}>Incoming Products</span>} arrow placement="top"><span className="textGray" style={{ marginLeft: '5px' }}>+ <span>{ item?.purchased_stock.toLocaleString() }</span></span></Tooltip>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="col-2 col-lg-2 text-left">
                                                                                                    {
                                                                                                        ((item?.total_purchased_qty + item?.free_stock) > item?.qty) ? (
                                                                                                            <>
                                                                                                                <span className="textGray">0</span>
                                                                                                            </>
                                                                                                        ) : (item?.qty - item?.total_purchased_qty - item?.free_stock) === 0 ? (
                                                                                                            <>
                                                                                                                <span className="textGray">0</span>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                    {/* <span className="text-danger">{item?.qty - item?.total_purchased_qty}</span> */}
                                                                                                                <span className="text-danger">{ item?.qty - (item?.total_purchased_qty + item?.free_stock) }</span>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                    {/* {
                                                                                                        (item?.base_purchase_qty === 0) ? (
                                                                                                            <>
                                                                                                                <span className="textGray">{item?.base_purchase_qty}</span>
                                                                                                            </>
                                                                                                        ) : (item?.base_purchase_qty > 0) ? (
                                                                                                            <>
                                                                                                                <span className="text-danger">{item?.base_purchase_qty}</span>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <span className="text-success">{item?.free_stock - item?.qty}</span>
                                                                                                            </>  
                                                                                                        )
                                                                                                    } */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row" style={{ padding: '15px 0 25px 0' }}>
                                            <div className="col-12 text-center" >
                                                <div className=''>

                                                    Move Sales Order to:
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="btnarea" style={{ justifyContent: 'center' }}>
                                            <div className="">

                                                <div className="row">
                                                    <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                                        <button
                                                            className="btn btn-main-primary btn-outlined-main"
                                                            style={{ marginRight: '12px' }}
                                                            disabled={isRecordProcessing || disabledPurchaseOrderButton}
                                                            onClick={() => {
                                                                if (proceeedToCreatePurchaseOrder) {
                                                                    setProcessOrderStep(2)
                                                                } else {
                                                                    handleProcessPicklist()
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                isRecordProcessing ? (
                                                                    <>
                                                                        <FaSpinner className="fa-spin" /> Processing Order...
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        Proceed to Process Order <FaChevronRight />
                                                                    </>
                                                                )
                                                            }
                                                            
                                                        </button>
                                                        {/* <button
                                                            className="btn btn-main-primary btn-outlined-main"
                                                            style={{ marginRight: '12px' }}
                                                            disabled={disabledPicklistButton}
                                                            onClick={() => sendEmail()}
                                                        >
                                                            Picklist
                                                        </button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '15px' }}>
                                            <div className="col-12 text-center" >
                                                <div className='text-danger'>

                                                    Note: the chosen action cannot be rolled back once it is processed.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                            
                                <div className="p-2 swipe-rtl-animation">
                                    <div style={{ padding: '20px' }}>
                                        <div className="mb-2 ml-0 pl-0">

                                            <div className="row">
                                                <div className="col-12 text-left">
                                                    <div
                                                        style={{ marginTop: '-22px', marginLeft: '-20px', cursor: 'pointer' }}
                                                        onClick={() => setProcessOrderStep(1)}
                                                    >
                                                        <FaChevronLeft style={{ height: '16px', width: '16px' }} /> Back

                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn modal-close-icon" onClick={handleClose}>
                                                <CloseIcon height={'16px'} width={'16px'} />
                                            </button>
                                        </div>

                                        <div className="row align-items-top">
                                            <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                                <div style={{ fontSize: '24px' }}>
                                                    Create Purchase Order
                                                </div>
                                                <div>
                                                    To process this order, please select at least one of the following actions:
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-12 text-center " style={{ padding: '10px 0' }}>
                                                <div className="row">
                                                    <div className="col-lg-12 form-group">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                                            
                                                                <div className="row align-items-top form-group">
                                                                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                                        <div className="textGray" style={{ width: '150px' }}>Reference Number</div>
                                                                        <div style={{ width: '275px', marginLeft: '10px', textAlign: 'left' }}>
                                                                            { collection?.sales_order_number }{ collectionDetails?.total_purchase_order > 0 ? `-${collectionDetails?.total_purchase_order}` : `` }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-top form-group">
                                                                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                                        <div className="textGray" style={{ width: '150px' }}>Supplier</div>
                                                                        <div style={{ width: '275px', marginLeft: '10px' }}>


                                                                            <ReactSelect
                                                                                className="rs-container"
                                                                                classNamePrefix="rs"
                                                                                name="customer"
                                                                                options={supplierList}
                                                                                value={supplierList?.find((c) => c?.value == purchaseOrderDetails?.supplierId)}
                                                                                // onChange={(selected) => handleCustomerinfo(selected)}
                                                                                onChange={(e) => {
                                                                                    handlePurchaseOrderInfo({
                                                                                        ...purchaseOrderDetails,
                                                                                        supplierId: e?.value
                                                                                    });
                                                                                }}
                                                                                menuPlacement="auto"
                                                                                menuPosition="fixed"
                                                                                placeholder="Select"
                                                                                style={{ borderRadius: '18px' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="row align-items-top form-group">
                                                                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                                        <div className="textGray" style={{ width: '150px' }}>Expected Delivery Date</div>
                                                                        <div style={{ width: '275px', marginLeft: '10px' }}>


                                                                            <input
                                                                                type="date"
                                                                                value={purchaseOrderDetails?.expectedDate}
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="form-control form-inp"
                                                                                onClick={(e) => e.target.focus()}
                                                                                onChange={(e) => {
                                                                                    // handleChangeCurrency(e?.target?.value)
                                                                                    handlePurchaseOrderInfo({
                                                                                        ...purchaseOrderDetails,
                                                                                        expectedDate: e?.target?.value
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-top form-group">
                                                                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                                        <div className="textGray" style={{ width: '150px' }}>Warehouse</div>
                                                                        <div style={{ width: '275px', marginLeft: '10px' }}>


                                                                            <ReactSelect
                                                                                className="rs-container"
                                                                                classNamePrefix="rs"
                                                                                name="transit-company"
                                                                                options={warehouseList}
                                                                                value={warehouseList?.find((c) => c?.value == purchaseOrderDetails?.warehouseId)}
                                                                                onChange={(e) => {
                                                                                    handlePurchaseOrderInfo({
                                                                                        ...purchaseOrderDetails,
                                                                                        warehouseId: e?.value
                                                                                    });
                                                                                }}
                                                                                menuPlacement="auto"
                                                                                menuPosition="fixed"
                                                                                placeholder="Select"
                                                                                style={{ borderRadius: '18px' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-top form-group">
                                                                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                                        <div className="textGray" style={{ width: '150px' }}>Currency</div>
                                                                        <div style={{ maxWidth: '275px', marginLeft: '10px' }}>

                                                                            <Select
                                                                                className="rs-container-est-white"
                                                                                classNamePrefix="rs"
                                                                                isSearchable={true}
                                                                                name="customer"
                                                                                options={currencies}
                                                                                value={currencies?.find((c) => c?.value == purchaseOrderDetails?.currency)}
                                                                                // onChange={(e) => {
                                                                                //     handlePurchaseOrderInfo({
                                                                                //         ...purchaseInvoice,
                                                                                //         currency: e.value
                                                                                //     });
                                                                                //     setCurrency(e);
                                                                                // }}
                                                                                onChange={(e) => {
                                                                                    handlePurchaseOrderInfo({
                                                                                        ...purchaseOrderDetails,
                                                                                        currency: e?.value
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-top form-group">
                                                                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                                        <div className="textGray" style={{ width: '150px' }}>Payment Method</div>
                                                                        <div style={{ width: '275px', marginLeft: '10px' }}>

                                                                            <Select
                                                                                className="rs-container-est-white"
                                                                                classNamePrefix="rs"
                                                                                isSearchable={true}
                                                                                name="customer"
                                                                                options={paymentMethods}
                                                                                value={paymentMethods?.find((c) => c?.value == purchaseOrderDetails?.paymentMethod)}
                                                                                onChange={(e) => {
                                                                                    handlePurchaseOrderInfo({
                                                                                        ...purchaseOrderDetails,
                                                                                        paymentMethod: e?.value
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-top form-group">
                                                                    <div className="col-12" style={{ display: 'flex', textAlign: 'right' }} >
                                                                        <div className="textGray" style={{ width: '178px' }}>Notes</div>
                                                                        <div style={{ width: '100%', marginLeft: '10px' }}>

                                                                            <textarea
                                                                                className='form-control'
                                                                                rows="5"
                                                                                value={purchaseOrderDetails?.notes}
                                                                                onChange={(e) => {
                                                                                    handlePurchaseOrderInfo({
                                                                                        ...purchaseOrderDetails,
                                                                                        notes: e?.target?.value
                                                                                    });
                                                                                }}
                                                                                style={{ resize: 'none' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    
                                                                <div className="row align-items-top form-group" style={{ marginTop: '35px' }}>
                                                                    <div className="col-6 text-left">
                                                                        <h2 className="font500 font18 font500">Products</h2>
                                                                    </div>
                                                                    {
                                                                        purchaseOrderItems?.length > 0 && (
                                                                            <>
                                                                                <div className="col-6 text-right" style={{ display: 'flex', justifyContent: 'right', marginTop: '-10px' }}>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary btn-outlined-main"
                                                                                        onClick={addItemRow}
                                                                                    >
                                                                                        <FaPlus />
                                                                                        Add Products
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                                    
                                                                {
                                                                    purchaseOrderItems?.length < 1 && (
                                                                        <>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="add-item-empty-div" style={{ padding: '30px 0' }}>
            
                                                                                        <button type="button" className="btn btn-primary btn-outlined-main-primary" onClick={addItemRow}>
                                                                                            <FaPlus />
                                                                                            Add Products
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                    
                                                                {
                                                                    purchaseOrderItems?.length > 0 && (
                                                                        <>
                                                                            <div className="row" style={{ borderBottom: '2px solid #e0e0e0' }}>
                                                                                <div className="col-6 col-lg-4 text-left form-group font500">Model</div>
                                                                                <div className="col-2 col-lg-1 text-left form-group font500">Qty</div>
                                                                                <div className="col-3 col-lg-2 text-left form-group font500">Purchase Price</div>
                                                                                <div className="col-3 col-lg-2 text-left form-group font500">Selling Price</div>
                                                                                <div className="col-2 col-lg-3 text-left form-group font500">Profit</div>
                                                                                {/* <div className="col-2 col-lg-2 text-left form-group">Total Profit</div> */}
                                                                            </div>
                                                                            {
                                                                                purchaseOrderItems?.map((item, index) => (
                                                                                    <>
                                                                                        <div className="row hover-row" style={{ lineHeight: '30px', paddingBottom: '10px', borderBottom: '1px solid #e0e0e0', paddingTop: '10px' }}>
                                                                                            <div className="col-4 col-lg-4 text-left d-flex align-items-center">
                                                                                                
                                                                                                <ReactSelect
                                                                                                    className="rs-container"
                                                                                                    classNamePrefix="rs"
                                                                                                    options={availableProductLists}
                                                                                                    value={availableProductLists.find(product => product.value === item?.product_id)}
                                                                                                    // options={getFilteredOptions(index)}
                                                                                                    // value={getSelectedOption(item.product_id)}
                                                                                                    // options={[...models]?.map((m) => ({
                                                                                                    //     ...m,
                                                                                                    //     optionAttribute: m.attribute,
                                                                                                    // })).filter((m) => invItems?.every((it) => it.model != m.value) || m.value == item.model)}
                                                                                                    // value={models?.find((v) => v?.value == item.model)}
                                                                                                    // item.product_id
                                                                                                    menuPlacement="auto"
                                                                                                    menuPosition="fixed"
                                                                                                    placeholder="Select or type..."
                                                                                                    formatOptionLabel={({ label, optionAttribute }) => (
                                                                                                        <div dangerouslySetInnerHTML={{ __html: label }}></div>
                                                                                                    )}
                                                                                                    onChange={(e) => {
                                                                                                        handleModelChange(index, e);
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-2 col-lg-1 text-left d-flex align-items-center">
                                                                                                
                                                                                                
                                                                                                <input
                                                                                                    className="numField"
                                                                                                    type="number"
                                                                                                    value={item?.purchase_qty}
                                                                                                    maxLength={10}
                                                                                                    onChange={(e) => {
                                                                                                        if (item.product_id) {
                                                                                                            const newVal = e.target.value;
                                                                                                            if (newVal.length <= 10) {
                                                                                                                handeChangePurchaseQty(index, newVal);
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    style={{ paddingInline: '3px', width: '100%' }}
                                                                                                    onFocus={(event) => {
                                                                                                        // event.target.select();
                                                                                                        if (event.target.value === '0') {
                                                                                                            event.target.value = '';
                                                                                                        }
                                                                                                    }}
                                                                                                />

                                                                                                {/* {
                                                                                                    (item?.base_purchase_qty === 0) ? (
                                                                                                        <>
                                                                                                            <span className="textGray">{item?.base_purchase_qty}</span>
                                                                                                        </>
                                                                                                    ) : (item?.base_purchase_qty > 0) ? (
                                                                                                        <>
                                                                                                            <Tooltip title={<span style={{ fontSize: "13px" }}>Free Stocks</span>} arrow placement="top"><span className="textGray" style={{ marginLeft: '5px' }}>+ <span>{ item?.free_stock.toLocaleString() }</span></span></Tooltip>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <span className="text-success">{item?.free_stock - item?.qty}</span>
                                                                                                        </>  
                                                                                                    )
                                                                                                } */}
                                                                                            </div>
                                                                                            <div className="col-2 col-lg-2 text-left font500 d-flex align-items-center">
                                                                                                    
                                                                                                    <input
                                                                                                        className="numField"
                                                                                                        type="number"
                                                                                                        value={item?.purchase_price}
                                                                                                        maxLength={10}
                                                                                                        onChange={(e) => {
                                                                                                            if (item.product_id) {
                                                                                                                const newVal = e.target.value;
                                                                                                                if (newVal.length <= 10) {
                                                                                                                    handeChangePurchasePrice(index, newVal);
                                                                                                                }
                                                                                                            }
                                                                                                        }}
                                                                                                        style={{ paddingInline: '3px', width: '90%' }}
                                                                                                        onFocus={(event) => {
                                                                                                            // event.target.select();
                                                                                                            if (event.target.value === '0') {
                                                                                                                event.target.value = '';
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                            </div>
                                                                                            <div className="col-3 col-lg-2 text-left  d-flex align-items-center">
                                                                                                {getCurrencySymbol(purchaseOrderDetails?.currency)}{' '}
                                                                                                {parseFloat(item?.converted_unit_price[purchaseOrderDetails?.currency])?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                                                            </div>
                                                                                            <div className="col-2 col-lg-3 text-left">
                                                                                                <div className="row" style={{ lineHeight: '17px' }}>
                                                                                                    <div className="col-12  d-flex" style={{ gap: '6px' }}>
                                                                                                        <div className="textGray">
                                                                                                            Gross
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {getCurrencySymbol(purchaseOrderDetails?.currency)}{' '}
                                                                                                            {parseFloat(item?.gross_profit[purchaseOrderDetails?.currency]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} {' '}
                                                                                                            ({parseFloat(item?.profit_percentage[purchaseOrderDetails?.currency]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%)
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row" style={{ lineHeight: '17px' }}>
                                                                                                    <div className="col-12  d-flex" style={{ gap: '10px' }}>
                                                                                                        <div className="textGray">
                                                                                                            Total
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {getCurrencySymbol(purchaseOrderDetails?.currency)}{' '}
                                                                                                            {parseFloat(item?.total_profit[purchaseOrderDetails?.currency]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="hover-button-container-lg">
                                                                                                
                                                                                                <div className="hover-button">
                                                                                                    
                                                                                                    <div
                                                                                                        className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
                                                                                                        onClick={() => { removeProductFromList(index) }}
                                                                                                    >
                                                                                                        <DeleteIcon2 height={'18px'} width={'18px'} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))   
                                                                            }
                                                                            <div className="row align-items-top form-group" style={{ marginTop: '10px' }}>
                                                                                <div className="col-6 text-left"></div>
                                                                                <div className="col-6 text-right" style={{ display: 'flex', justifyContent: 'right' }}>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary btn-outlined-main"
                                                                                        // onClick={handleSelectRow}
                                                                                    >
                                                                                        <FaPlus />
                                                                                        Add Products
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </>    
                                                                    )
                                                                }    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            
                                        {
                                            purchaseOrderItems?.length > 0 && (
                                                <>
                                                    <div className="btnarea" style={{ justifyContent: 'center' }}>
                                                        <div className="">

                                                            <div className="row">
                                                                <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                                                    <button
                                                                        className="btn btn-main-primary btn-outlined-main"
                                                                        style={{ marginRight: '12px' }}
                                                                        disabled={processingPurchaseOrderButton}
                                                                        onClick={() => { submitPurchaseOrder() }}
                                                                    >
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        <div className="row" style={{ marginTop: '15px' }}>
                                            <div className="col-12 text-center" >
                                                <div className='text-danger'>

                                                    Note: the chosen action cannot be rolled back once it is processed.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProcessOrderModal;
