import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import Scroller from 'ui-component/Scroller';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, storeInLocalStore, setStateModellist } from 'store/reducers/mainSlice';
import { FaPlus, FaSave, FaSpinner } from 'react-icons/fa';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import toast from 'react-hot-toast';
import ReactSelect from 'react-select';
import { TbRuler } from 'react-icons/tb';
import Switch from '@mui/material/Switch';
import { FormControl } from '@mui/material'

const ProductForm = ({ edit }) => {
	const { id } = useParams();
	const history = useNavigate();
	const dispatch = useDispatch();
	const nameRef = useRef();
	const skuRef = useRef();
	const warehouseRef = useRef();
	const modelTypeRef = useRef();
	const gradeRef = useRef();
	const [status, setStatus] = useState(false)
	const [publishInClientPortal, setPublishInClientPortal] = useState(false)
	const [hasStorage, setHasStorage] = useState(false);
	const [hasGrade, setHasGrade] = useState(false);
	const [hasColor, setHasColor] = useState(false);
	const [selectedValues, setselectedValues] = useState(false);
	const [productTypelist, setProductTypeList] = useState([]);
	const [vatValidationAlert, setVatvalidationlert] = useState('');
	const [alertMessage, setAlertMessage] = useState();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
	const selectedProduct = useSelector((state) => state.main.user.selectedUser);
	const [supplierList, setSupplierList] = useState([]);
	const [warehouseList, setWarehouseList] = useState([]);
	const [selectedWarehouses, setSelectedWarehouses] = useState([]);
	const [isProcessingForm, setIsProcessingForm] = useState([]);
	const [priceMargin, setPriceMargin] = useState(0);

	const [productDetail, setProductDetail] = useState({
		name: '',
		sku: '',
		modelCategory: '',
		storages: [],
		grades: [],
		colors: [],
		warehouses: [],
		status: 1,
		price: '',
		purchasePrice: '',
		supplierId: '',
		productCodeSupplier: '',
		deliveryTime: '',
		minimumOrder: '',
		orderQuantity: '',
		productType: '',
		gradeId: '',
		storageId: '',
		inPortal: 1
	});

	const productTypeOptions = [
		{ label: 'Normal', value: 1 },
		{ label: 'Unlimited Stock', value: 2 },
		{ label: 'Virtual Composition', value: 3 },
		{ label: 'Composition with Own Stock', value: 4 },
	];

	const statusOptions = [
		{ label: 'Publish', value: 1 },
		{ label: 'Unpublish', value: 0 }
	];

	const categoryWithourVariant = [
		'Accessories', 'Parts', 'Cables'
	];


	const [grades, setGrades] = useState([]);

	const [storages, setStorages] = useState([]);

	const [colors, setColors] = useState([]);

	const loadProductCategories = () => {
		api.getAllProductCategoriesLite().then((res) => {
			if (res.status) {
				// console.log(res.data);
				let typeTemp = res.data.map((item) => ({ ...item }));
				setProductTypeList(typeTemp);
			} else {
			}
		});
	};



	const loadProductColors = () => {
		api.getAllColors().then((res) => {
			if (res.status) {
				let typeTemp = res.data.map((item) => ({ value: item.id, label: item.name }));
				setColors(typeTemp);
			} else {
			}
		});
	};

	const loadProductGrades = () => {
		api.getAllGrades().then((res) => {
			if (res.status) {
				let typeTemp = res.data.map((item) => ({ value: item.id, label: item.name }));
				setGrades(typeTemp);
			} else {
			}
		});
	};

	const loadProductStorages = () => {
		api.getAllStorages().then((res) => {
			if (res.status) {
				let typeTemp = res.data.map((item) => ({ value: item.id, label: item.name }));
				setStorages(typeTemp);
			} else {
			}
		});
	};

	const loadSuppliers = () => {
		api.getSelectOptionsList('supplier').then((res) => {
			const defaultSelect = { id: 0, name: 'No Supplier' };
			const tempSelect = res.data.map((item) => ({ id: item.id, name: item.name }));
			const mergedSelect = [defaultSelect, ...tempSelect];
			setSupplierList(mergedSelect);
		});
	};
	const loadWarehouses = () => {
		api.getSelectOptionsList('warehouse').then((res) => {
			// const defaultSelect = { id: 0, name: 'No warehouse' };
			const tempSelect = res.data.map((item) => ({ id: item.id, name: item.name }));
			const mergedSelect = [...tempSelect];
			setWarehouseList(mergedSelect);
		});
	};

	const handleChange = (val) => {
		setProductDetail(val);
	};

	const handleCategoryChange = (val) => {
		setHasGrade(val?.hasGrade);
		setHasStorage(val?.hasStorage);
		setHasColor(val?.hasColor);
	};

	const handleWarehouseChange = (val) => {
		const newSelectedWarehouse = val.map(item => ({
			id: item.value,
			value: item.value,
			label: item.label,
		}));
		setProductDetail({ ...productDetail, warehouses: newSelectedWarehouse });
		setSelectedWarehouses(newSelectedWarehouse);
	};

	const handleStatus = (val) => {
		setProductDetail({ ...productDetail, status: (val === true) ? 1 : 0 });
		setStatus(val)
	}

	const handlePublishInClientPortal = (val) => {
		setProductDetail({ ...productDetail, inPortal: (val === true) ? 1 : 0 });
		setPublishInClientPortal(val)
	}

	const handleComputePriceMargin = (purchasePrice, sellingPrice) => {
		// const sellingPrice = productDetail?.price;
		// const purchasePrice = productDetail?.purchasePrice;
		let marginPercentage = 0;
		if (parseInt(sellingPrice) < parseInt(purchasePrice)) {

			console.log('mas mababa ang selling price')
			marginPercentage = ((parseFloat(sellingPrice) - parseFloat(purchasePrice)) / parseFloat(purchasePrice)) * 100;
		} else {
			console.log('mas mattas ang selling price')
			marginPercentage = ((parseFloat(sellingPrice) - parseFloat(purchasePrice)) / parseFloat(sellingPrice)) * 100;
		}

		if (isNaN(marginPercentage)) {
			setPriceMargin(0);
		} else {

			if (sellingPrice != '0.00' || purchasePrice != '0.00') {
				setPriceMargin(Math.round(marginPercentage));
				// setPriceMargin(100 - Math.round(marginPercentage));
			} else {
				setPriceMargin(0);
			}
		}
	}

	const handleSubmit = (e) => {
		if (edit && id) {
			e.preventDefault();
			if (productDetail.name == '') {
				setAlertMessage({
					name: 'Product Name is Required.'
				});
				nameRef.current.focus();
			} else if (productDetail.sku.trim() == '') {
				setAlertMessage({
					sku: 'SKU is Required.'
				});
				skuRef.current.focus();
			} else if (productDetail.warehouses.length < 1) {
				setAlertMessage({
					warehouse: 'Warehouse is Required.'
				});
				skuRef.current.focus();
			} else {
				setIsSubmitDisabled(true);
				// toast.promise(
				api
					.updateProduct(id, {
						sku: productDetail?.sku,
						name: productDetail?.name,
						product_category: productDetail?.modelCategory,
						storages: productDetail?.storages,
						grades: productDetail?.grades,
						warehouses: productDetail?.warehouses,
						// colors: productDetail?.colors,
						status: productDetail?.status,
						price: productDetail?.price,
						purchase_price: productDetail?.purchasePrice,
						supplier_id: productDetail?.supplierId,
						product_code_supplier: productDetail?.productCodeSupplier,
						delivery_time: productDetail?.deliveryTime,
						minimum_order: productDetail?.minimumOrder,
						order_quantity: productDetail?.orderQuantity,
						product_type: productDetail?.productType,
						grade_id: productDetail?.gradeId,
						storage_id: productDetail?.storageId,
						in_portal: publishInClientPortal,
					})
					.then(
						(res) => {
							if (res.status) {
								toast.success(res.message);
								history(`/products/${res.data.id}`);
							} else {
								if (res.errors?.name) {
									setAlertmessage({
										name: res.errors.name
									});
									nameRef.current.focus();
								} else {
									// 13256MIXDNA

									toast.error(res.message);
									// setAlertmessage({
									// 	failed: res.message
									// });
								}
							}

							setIsSubmitDisabled(false);
							return res?.status ? res : null;
						},
						() => {
							setIsSubmitDisabled(false);
						}
					);
				// 	{
				// 		loading: 'Updating Products...',
				// 		// success: (data) => `${data.message}`,
				// 		// error: <b>{data.message}</b>
				// 	}
				// );
			}
		} else {
			e.preventDefault();
			if (productDetail.name == '') {
				setAlertMessage({
					name: 'Model Name is Required.'
				});
				nameRef.current.focus();
			} else if (productDetail.modelCategory == '') {
				setAlertMessage({
					modelType: 'Please select Model Category.'
				});
			} else if (productDetail.sku.trim() == '') {
				setAlertMessage({
					sku: 'SKU is Required.'
				});
				skuRef.current.focus();
			} else if (productDetail.warehouses.length < 1) {
				setAlertMessage({
					warehouse: 'Warehouse is Required.'
				});
				// warehouseRef.current.focus();
			} else {
				setIsSubmitDisabled(true);
				api.createProduct({
					sku: productDetail?.sku,
					name: productDetail?.name,
					product_category_id: productDetail?.modelCategory,
					storages: productDetail?.storages,
					grades: productDetail?.grades,
					warehouses: productDetail?.warehouses,
					// colors: productDetail?.colors,
					status: productDetail?.status,
					price: productDetail?.price,
					purchase_price: productDetail?.purchasePrice,
					supplier_id: productDetail?.supplierId,
					product_code_supplier: productDetail?.productCodeSupplier,
					delivery_time: productDetail?.deliveryTime,
					minimum_order: productDetail?.minimumOrder,
					order_quantity: productDetail?.orderQuantity,
					product_type: productDetail?.productType,
					grade_id: productDetail?.gradeId,
					storage_id: productDetail?.storageId,
					in_portal: productDetail?.inPortal,
				}).then((res) => {
					console.log(res)
					// if (res.status) {
					// 	setTimeout(() => {
					// 		setIsSubmitDisabled(false);
					// 		setAlertMessage({});
					// 		dispatch(storeInLocalStore({ stateAlertmessage: 'Model Created Successfully.' }));
					// 		dispatch(setStateModellist(''));
					// 		history('/products');
					// 	}, 500);
					// } else {
					// 	setAlertMessage({
					// 		failed: res.message
					// 	});
					// 	setTimeout(() => {
					// 		setAlertMessage({});
					// 	}, 2000);
					// }
					setIsSubmitDisabled(false);
					if (res.status) {
						toast.success(res.message);
						history(`/products`);
					} else {
						if (res.errors?.name) {
							setAlertmessage({
								name: res.errors.name
							});
							nameRef.current.focus();
						} else {
							// 13256MIXDNA

							toast.error(res.message);
							// setAlertmessage({
							// 	failed: res.message
							// });
						}
					}

					setIsSubmitDisabled(false);
					// return res?.status ? res : null;

				});
			}
			// setTimeout(() => {
			// 	setAlertMessage({});
			// }, 1500);
		}
	};
	useEffect(() => {

		loadProductCategories();
		loadProductColors();
		loadProductGrades();
		loadProductStorages();
		loadSuppliers();
		loadWarehouses();
		if (edit) {
			getProductDetails();
		}
	}, []);

	const getProductDetails = () => {
		setLoading(true);
		api.getProductDetails(id).then((res) => {
			setLoading(false);
			if (!res.data) {
				setLoading(true);
				history('/products');
			} else {
				const loadData = res?.data;
				let tempdata = {
					sku: loadData?.sku || '',
					name: loadData?.product_name || '',
					modelCategory: loadData?.category || '',
					storages: loadData?.storage || '',
					grades: loadData?.grade || '',
					warehouses: loadData?.warehouses || [],
					colors: [],
					status: loadData?.status || 0,
					price: loadData?.price || '',
					purchasePrice: loadData?.purchase_price || '',
					supplierId: loadData?.supplier?.id || '',
					productCodeSupplier: loadData?.product_code_supplier || '',
					deliveryTime: loadData?.delivery_time || '',
					minimumOrder: loadData?.minimum_order || '',
					orderQuantity: loadData?.order_quantity || '',
					productType: loadData?.product_type || '',
					inPortal: loadData?.in_portal || 0,
					gradeId: loadData?.grade?.id || '',
					storageId: loadData?.storage?.id || '',
				};
				setProductDetail(tempdata);
				if (loadData?.status === 1) {
					setStatus(true);
				} else {
					setStatus(false);
				}
				if (loadData?.in_portal === 1) {
					setPublishInClientPortal(true)
				} else {
					setPublishInClientPortal(false);
				}
				if (categoryWithourVariant.includes(loadData?.category?.name)) {
					setHasGrade(false);
					setHasStorage(false);
				} else {
					setHasGrade(true);
					setHasStorage(true);
				}

				const sellingPrice = loadData?.price;
				const purchasePrice = loadData?.purchase_price;
				const marginPercentage = ((sellingPrice - purchasePrice) / sellingPrice) * 100;
				// setPriceMargin(Math.round(marginPercentage));

				if (sellingPrice != '0.00' || purchasePrice != '0.00') {
					setPriceMargin(100 - Math.round(marginPercentage));
				} else {
					setPriceMargin(0);
				}
				// handleComputePriceMargin()
				// if (loadData?.grade?.id) {
				// 	setHasGrade(true);
				// }
				// if (loadData?.storage?.id) {
				// 	setHasStorage(true);
				// }
				setSelectedWarehouses(loadData?.warehouses || []);
				setLoading(true);
			}
		});
	};
	const goback = () => {
		history('/products');
	};
	return (
		<>
			<div className="row m-0 create-contact-container">
				<div className="card w-100 h-100">
					<form action="" className="h-100" onSubmit={handleSubmit}>
						<div className="section-head m-0 p-0">
							<h5 className="m-0 p-0 pb-2" style={{ fontWeight: '500' }}>
								{edit ? 'Edit Product' : 'Create Product'}
							</h5>
						</div>
						<Scroller>
							<div className="form-section">
								<div className="form-section-head">
									<h4>General Information</h4>
								</div>
							</div>
							<div className="form-group">
								<div className="form-group-body">
									<label htmlFor="">Category</label>
									<div>

										{edit ?
											<Select
												className="rs-container disabled"
												classNamePrefix="rs"
												isDisabled={true}
												disabled={true}
												name="ca"
												menuPosition="fixed"
												options={productTypelist.map((item) => ({ label: item.name, value: item.id }))}
												value={productTypelist
													.map((item) => ({ label: item.name, value: item.id }))
													?.find((c) => c?.value == productDetail?.modelCategory?.id)}
												style={{ width: '270px' }}
												placeholder="Select or type..."
											/>

											:
											<Select
												options={productTypelist.map((e) => ({
													value: e.id,
													label: e.name,
													hasGrade: e.has_grade_variations,
													hasColor: e.has_color_variations,
													hasStorage: e.has_storage_variations
												}))}
												className="rs-container"
												classNamePrefix="rs"
												onChange={(e) => {
													handleChange({ ...productDetail, modelCategory: e.value });
													handleCategoryChange(e);
												}}
												placeholder="Select or type..."
											/>
										}



									</div>
								</div>
								<div className="form-group-error">
									{alertMessage?.modelType && (
										<>
											<span className="error-message text-danger">{alertMessage.modelType}</span>
										</>
									)}
								</div>
							</div>

							<div className="form-section">
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">{edit ? 'Product' : 'Model'} Name</label>
										<div>
											{edit ?
												<input
													ref={nameRef}
													type="text"
													className="form-control"
													placeholder="Enter Product Name"
													value={productDetail?.name}
													onChange={(e) => {
														handleChange({ ...productDetail, name: e.target.value });
													}}
												/>
												:
												<input
													ref={nameRef}
													type="text"
													className="form-control"
													placeholder="Enter Model Name"
													onChange={(e) => {
														handleChange({ ...productDetail, name: e.target.value });
													}}
												/>
											}

										</div>
									</div>
									<div className="form-group-error">
										{alertMessage?.name && (
											<>
												<span className="error-message text-danger">{alertMessage.name}</span>
											</>
										)}
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">SKU</label>
										<div>
											<input
												ref={skuRef}
												type="text"
												className="form-control"
												placeholder="Enter SKU"
												maxLength={50}
												value={productDetail?.sku}
												onChange={(e) => {
													handleChange({ ...productDetail, sku: e.target.value });
												}}
											/>

										</div>
									</div>
									<div className="form-group-error">
										{alertMessage?.sku && (
											<>
												<span className="error-message text-danger">{alertMessage.sku}</span>
											</>
										)}
									</div>
								</div>
								{hasStorage ? (
									<div className="form-group">
										<div className="form-group-body">
											<label htmlFor="">Storage</label>
											<div>
												<Select
													className="rs-container"
													classNamePrefix="rs"
													name="ca"
													menuPosition="fixed"
													options={storages.map((item) => ({ label: item.label, value: item.value }))}
													value={
														storages.map((item) => ({
															label: item.label, value: item.value
														}))?.find((c) => c?.value == productDetail?.storageId)
													}
													onChange={(e) => {
														handleChange({ ...productDetail, storageId: e.value });
													}}
												/>
											</div>
										</div>
										<div className="form-group-error">
											{alertMessage?.storage && (
												<>
													<span className="error-message text-danger">{alertMessage.storage}</span>
												</>
											)}
										</div>
									</div>
								) : (
									''
								)}

								{hasGrade ? (
									<div className="form-group">
										<div className="form-group-body">
											<label htmlFor="">Grade</label>
											<div>
												{
													edit ? (
														<Select
															className="rs-container"
															classNamePrefix="rs"
															name="ca"
															menuPosition="fixed"
															options={grades.map((item) => ({ label: item.label, value: item.value }))}
															value={
																grades.map((item) => ({
																	label: item.label, value: item.value
																}))?.find((c) => c?.value == productDetail?.gradeId)
															}
															onChange={(e) => {
																handleChange({ ...productDetail, gradeId: e.value });
															}}
														/>
													) : (

														<Select
															className="rs-container"
															classNamePrefix="rs"
															name="ca"
															menuPosition="fixed"
															options={grades.map((item) => ({ label: item.label, value: item.value }))}
															value={
																grades.map((item) => ({
																	label: item.label, value: item.value
																}))?.find((c) => c?.value == productDetail?.grades?.id)
															}
															onChange={(e) => {
																handleChange({ ...productDetail, gradeId: e.value });
															}}
														/>
														// <CreatableSelect
														// 	options={grades}
														// 	// isMulti
														// 	className="rs-container"
														// 	classNamePrefix="rs"
														// 	onChange={(e) =>
														// 		setProductDetail({
														// 			...productDetail,
														// 			grades: e.map((e) => ({ value: e.value, is_new: e.__isNew__ ? true : false }))
														// 		})
														// 	}
														// />
													)
												}
											</div>
										</div>
										<div className="form-group-error">
											{alertMessage?.grade && (
												<>
													<span className="error-message text-danger">{alertMessage.grade}</span>
												</>
											)}
										</div>
									</div>
								) : (
									''
								)}
								{/* {hasColor ? (
									<div className="form-group">
										<div className="form-group-body">
											<label htmlFor="">Colours</label>
											<div>
												<CreatableSelect
													options={colors}
													// isMulti
													className="rs-container"
													classNamePrefix="rs"
													onChange={(e) =>
														setProductDetail({
															...productDetail,
															colors: e.map((e) => ({ value: e.value, is_new: e.__isNew__ ? true : false }))
														})
													}
												/>
											</div>
										</div>
										<div className="form-group-error">
											{alertMessage?.grade && (
												<>
													<span className="error-message text-danger">{alertMessage.grade}</span>
												</>
											)}
										</div>
									</div>
								) : (
									''
								)} */}
								{/* <div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Product Type</label>
										<div>
											<Select
												menuPosition={'fixed'}
												options={productTypeOptions}
												value={productTypeOptions?.find((y) => y?.value == productDetail?.productType)}
												className="rs-container"
												classNamePrefix="rs"
												placeholder=""
												onChange={(e) => {
													handleChange({ ...productDetail, productType: e.value });
												}}
											/>
										</div>
									</div>
								</div> */}
							</div>
							{edit && (
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Status</label>
										<div>


											<FormControl sx={{ width: '100% ' }}>
												{/* <span id="demo-multiple-chip-label">Status</span> */}

												<Switch
													color="primary"
													checked={status}
													onChange={(event) => handleStatus(event.target.checked)}
												/>
											</FormControl>
										</div>
									</div>
								</div>
							)}
							<div className="form-group">
								<div className="form-group-body">
									<label htmlFor="">Publish in Client Portal</label>
									<div>


										<FormControl sx={{ width: '100% ' }}>
											{/* <span id="demo-multiple-chip-label">Status</span> */}

											<Switch
												color="primary"
												checked={publishInClientPortal}
												onChange={(event) => handlePublishInClientPortal(event.target.checked)}
											/>
										</FormControl>
									</div>
								</div>
							</div>


							<div className="form-section" style={{ marginTop: '20px' }}>
								<div className="form-section-head">
									<h4>Pricing</h4>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Sales Price ($)</label>
										<div>
											<input
												value={productDetail?.price}
												type="number"
												className="form-control"
												id="inputName"
												placeholder="0"
												onChange={(e) => {
													// handleChange({ ...productDetail, price: e.target.value });
													// handleComputePriceMargin()
													const val = e.target.value;
													if (/^\d*\.?\d*$/.test(val)) { // regex to allow numbers and a single dot
														handleChange({ ...productDetail, price: val });
														handleComputePriceMargin(productDetail?.purchasePrice, val);

													}
												}}
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Purchase Price ($)</label>
										<div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
											<input
												value={productDetail?.purchasePrice}
												type="text"
												placeholder="0"
												className="form-control"
												id="inputName"
												onChange={(e) => {
													// handleChange({ ...productDetail, purchasePrice: e.target.value });
													// handleComputePriceMargin()
													const val = e.target.value;
													if (/^\d*\.?\d*$/.test(val)) { // regex to allow numbers and a single dot
														handleChange({ ...productDetail, purchasePrice: val });
														handleComputePriceMargin(val, productDetail?.price);

													}
												}}
											/>
											<div className='textGray product-price-margin-label'>
												Margin: {priceMargin}%
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="form-section" style={{ marginTop: '20px' }}>
								<div className="form-section-head">
									<h4>Location</h4>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Warehouse</label>
										<div className="">

											<Select
												ref={warehouseRef}
												isMulti={true}
												options={warehouseList.map((e) => ({
													value: e.id,
													label: e.name,
												}))}
												value={selectedWarehouses?.map(warehouse => ({
													value: warehouse.id,
													label: warehouseList.find(w => w.id === warehouse?.id)?.name || 'empty'
												}))}
												className="rs-container"
												classNamePrefix="rs"
												onChange={(e) => {
													handleWarehouseChange(e);
												}}
												placeholder="Select or type..."
											/>
										</div>
									</div>
									<div className="form-group-error">
										{alertMessage?.warehouse && (
											<>
												<span className="error-message text-danger">{alertMessage.warehouse}</span>
											</>
										)}
									</div>
								</div>
							</div>


							<div className="form-section" style={{ marginTop: '20px' }}>
								<div className="form-section-head">
									<h4>Supplier</h4>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Supplier</label>
										<div>
											<Select
												className="rs-container"
												classNamePrefix="rs"
												isSearchable={true}
												name="supplier"
												menuPosition="fixed"
												options={supplierList.map((item) => ({ label: item.name, value: item.id }))}
												value={supplierList
													.map((item) => ({ label: item.name, value: item.id }))
													?.find((c) => c?.value == productDetail?.supplierId)}
												onChange={(e) => {
													handleChange({ ...productDetail, supplierId: e.value });

												}}
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Productcode supplier</label>
										<div>
											<input
												value={productDetail?.productCodeSupplier}
												type="text"
												className="form-control"
												id="inputName"
												onChange={(e) => {
													handleChange({ ...productDetail, productCodeSupplier: e.target.value });
												}}
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Delivery Time (days)</label>
										<div>
											<input
												value={productDetail?.deliveryTime}
												type="number"
												className="form-control"
												id="inputName"
												onChange={(e) => {
													handleChange({ ...productDetail, deliveryTime: e.target.value });
												}}
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Minimum amount to order</label>
										<div>
											<input
												value={productDetail?.minimumOrder}
												type="number"
												className="form-control"
												id="inputName"
												onChange={(e) => {
													handleChange({ ...productDetail, minimumOrder: e.target.value });
												}}
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-group-body">
										<label htmlFor="">Order in quantities of</label>
										<div>
											<input
												value={productDetail?.orderQuantity}
												type="number"
												className="form-control"
												id="inputName"
												onChange={(e) => {
													handleChange({ ...productDetail, orderQuantity: e.target.value });
												}}
											/>
										</div>
									</div>
								</div>
							</div>



						</Scroller>

						<div className="non-scroll-btn-section">
							<div className="btnarea">
								{/* <button onClick={goback} className="btn btn-link">
									Cancel
								</button> */}
								{edit ? (
									<PermissionAllowed permissions={['Save Contact Details (Action)']}>
										<button
											type="submit"
											className={isSubmitDisabled ? `btn btn-main disabled` : `btn btn-main`}
											disabled={isSubmitDisabled}
										>
											{
												isSubmitDisabled ? (
													<>
														<FaSpinner className="fa-spin" /> Saving Changes...
													</>
												) : (
													<>
														<FaSave />  Save Changes
													</>
												)
											}
										</button>
									</PermissionAllowed>
								) : (
									<PermissionAllowed permissions={['Create New Contact (Action)']}>
										<button
											type="submit"
											className={isSubmitDisabled ? `btn btn-main disabled` : `btn btn-main`}
											disabled={isSubmitDisabled}
											onClick={handleSubmit}
										>
											{
												isSubmitDisabled ? (
													<>
														<FaSpinner className="fa-spin" /> Creating Product...
													</>
												) : (
													<>
														<FaPlus />  Create Product
													</>
												)
											}
										</button>
									</PermissionAllowed>
								)}
							</div>
							{alertMessage?.success && <span className="px-3 down-error text-success">{alertMessage?.success}</span>}
							{alertMessage?.failed && <span className="px-3 down-error text-danger">{alertMessage?.failed}</span>}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ProductForm;
