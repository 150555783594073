import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setReturncomponent, setEstimatedata, storeInLocalStore } from 'store/reducers/mainSlice';
import 'react-datepicker/dist/react-datepicker.css';
import PurchaseInvoiceDetailInvoice from './PurchaseInvoiceDetailInvoice';
import { getCurrencySymbol } from 'utils/currency';
import CurrencyValue from 'ui-component/CurrencyValue';
import Notes from 'ui-component/notes';
import moment from 'moment';
import toast from 'react-hot-toast';
import PdfViewer from 'ui-component/PdfViewer';
import { Dropdown } from 'react-bootstrap';
import { LuChevronDown } from 'react-icons/lu';
import BarcodeScanModal from 'ui-component/confirmation-dialogs/barcodeScanModal/BarcodeScanModal';
import PayPurchaseInvoiceModal from 'ui-component/confirmation-dialogs/PayPurchaseInvoiceModal/PayPurchaseInvoiceModal';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PurchaseInvoiceEditPopup from 'views/purchaseList/comonents/PurchaseInvoiceEditPopup';

const PurchaseInvoiceDetails = () => {
	const { id } = useParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const [purchaseInvoice, setPurchaseInvoice] = useState(null);
	const [notes, setNotes] = useState([]);
	const [paymentModal, setPaymentModal] = useState(null);

	const [editModal, setEditModal] = useState();

	const addNote = (noteInputValue, isDeletable = 1) => {
		toast.promise(
			api
				.newPurchaseInvoiceNote(id, {
					details: noteInputValue,
					type: 'public'
				})
				.then(
					(res) => {
						if (res.status) {
							setNotes((oldData) => [
								{
									id: res?.data?.id,
									details: noteInputValue,
									isDeletable: isDeletable,
									date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
									time: moment(res?.data?.created_at).format('HH:mm'),
									user_name: userName,
									expand: false
								},
								...oldData
							]);
						}

						return res?.status ? res : null;
					},
					() => {}
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const toggleNoteExpand = (index) => {
		setNotes((oldData) =>
			oldData?.map((note, i) =>
				index == i
					? {
							...note,
							expand: !note?.expand
					  }
					: note
			)
		);
	};

	const removeNote = (index) => {
		toast.promise(
			api.removePurchaseInvoiceNote(id, notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setNotes((oldData) => oldData?.filter((note, i) => index != i));
					}

					return res?.status ? res : null;
				},
				() => {}
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	const addPurchaseInvoicePayment = (data) => {
		setPaymentModal(null);
		toast.promise(
			api
				.addPurchaseInvoicePayment(id, {
					amount: data
				})
				.then((res) => {
					if (res?.status) {
						setPurchaseInvoice((oldData) => ({
							...oldData,
							...res?.data
						}));
						setNotes((oldData) => [
							{
								id: res?.data?.newNote?.id,
								details: res?.data?.newNote?.details,
								isDeletable: 0,
								date: moment(res?.data?.newNote?.created_at).format('YYYY-MM-DD'),
								time: moment(res?.data?.newNote?.created_at).format('HH:mm'),
								user_name: userName,
								expand: false
							},
							...oldData
						]);
					}

					return res?.status ? res : abcd;
				}),
			{
				loading: 'Adding Payment...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add payment</b>
			}
		);
	};

	useEffect(() => {
		if (id) {
			api.getPurchaseInvoice(id).then((res) => {
				setPurchaseInvoice(res?.data);
				setNotes(
					res?.data?.notes?.map((note) => ({
						id: note?.id,
						details: note?.details,
						isDeletable: note?.is_deletable,
						date: moment(note?.created_at).format('YYYY-MM-DD'),
						time: moment(note?.created_at).format('HH:mm'),
						user_name: note?.user_created?.first_name + ' ' + note?.user_created?.last_name,
						expand: false
					}))
				);
			});
		}
	}, []);

	return (
		<>
			<div className="row m-0 w-100" style={{ paddingInline: '50px' }}>
				<div className="col-12 col-md-8">
					<div className="section-head">
						<div className="actions" style={{ gap: '5px' }}></div>
						<div className="actions" style={{ gap: '10px' }}>
							{/* <PermissionAllowed permissions={['Purchase Invoice Add Payment (Button)']}>
								{purchaseInvoice?.payment_status != 'Paid' && (
									<Link
										to={''}
										onClick={() => {
											setPaymentModal({
												total_open: purchaseInvoice?.total_open,
												total_paid: purchaseInvoice?.total_paid,
												currency: purchaseInvoice?.currency
											});
										}}
										className="btn btn-primary btn-outlined-main white-var"
										style={{ minWidth: 'unset' }}
									>
										Pay Now
									</Link>
								)}
							</PermissionAllowed> */}

							<PermissionAllowed
								permissions={['Purchase Invoice Add Payment (Button)', 'Delete Purchase Invoice (Button)']}
								hasSome
							>
								<Dropdown className="btnDel iconbtn" align={'end'}>
									<Dropdown.Toggle as="span">
										<Link to={''} className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: 'unset' }}>
											Extra <LuChevronDown />
										</Link>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<PermissionAllowed permissions={['Purchase Invoice Add Payment (Button)']}>
											<Dropdown.Item
												onClick={() => {
													setPaymentModal({
														total_open: purchaseInvoice?.total_open,
														total_paid: purchaseInvoice?.total_paid,
														currency: purchaseInvoice?.currency
													});
												}}
											>
												Pay Now
											</Dropdown.Item>
										</PermissionAllowed>
										<PermissionAllowed permissions={['Delete Purchase Invoice (Button)']}>
											<Dropdown.Item
												onClick={() => {
													setEditModal(purchaseInvoice);
												}}
											>
												Edit
											</Dropdown.Item>
										</PermissionAllowed>
									</Dropdown.Menu>
								</Dropdown>
							</PermissionAllowed>
							<div className={`payment-status-indicator ${purchaseInvoice?.payment_status?.toLowerCase()}`}>
								{purchaseInvoice?.payment_status}
							</div>
						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-3 p-3 p-xl-4 w-100">
							<PurchaseInvoiceDetailInvoice purchaseInvoice={purchaseInvoice} />
						</div>

						<div className="card shadow-sm rounded bg-white mb-3 p-1 w-100">
							{purchaseInvoice?.attachment_type == 'pdf' ? (
								<PdfViewer src={purchaseInvoice?.attachment_url + '#toolbar=0&navpanes=0&zoom=FitW'} />
							) : (
								<img src={purchaseInvoice?.attachment_url} style={{ width: '100%' }} />
							)}
						</div>
					</div>
				</div>
				<div className="col-12 col-md-3">
					<div style={{ marginTop: '55px' }}>
						{/* <div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Status
							</label>

							<p>{purchaseInvoice?.payment_status}</p>
						</div> */}
						<div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Currency
							</label>

							<p>{purchaseInvoice?.currency?.toUpperCase()}</p>
						</div>

						<div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Total Paid
							</label>

							<p>
								<CurrencyValue currencyObject={purchaseInvoice?.converted_currency_total_paid} />
							</p>
						</div>

						<div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								To Pay
							</label>

							<p>
								<CurrencyValue currencyObject={purchaseInvoice?.converted_currency_total_open} />
							</p>
						</div>

						<Notes
							notes={notes}
							addNote={addNote}
							deleteNotePermission={[]}
							removeNote={removeNote}
							toggleNoteExpand={toggleNoteExpand}
							parentId={1}
							type="purchase-invoice"
						/>
					</div>
				</div>
			</div>
			{paymentModal && (
				<PayPurchaseInvoiceModal
					show={paymentModal}
					handleClose={() => {
						setPaymentModal(null);
					}}
					onSubmit={addPurchaseInvoicePayment}
				/>
			)}

			{editModal && (
				<PurchaseInvoiceEditPopup
					edit={editModal}
					openpopup={true}
					handleClose={() => {
						setEditModal(null);
					}}
				/>
			)}
		</>
	);
};

export default PurchaseInvoiceDetails;
