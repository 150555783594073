import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Scroller from 'ui-component/Scroller';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import ProductVariations from './ProductVariations';
import { Skeleton, Tooltip } from '@mui/material';
import { AiOutlineDelete } from 'react-icons/ai';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import toast from 'react-hot-toast';
import { FaPlus, FaSave, FaPencilAlt, FaCloudDownloadAlt, FaSpinner } from 'react-icons/fa';
import { MdOutlineModeEdit, MdOutlineCloudDownload } from 'react-icons/md';
import Select from 'react-select';
import usePageTitle from 'hooks/usePageTitle';

const ExportProduct = () => {
	const [setPageTitle] = usePageTitle('Products - Export');
	const [isExportRunning, setIsExportRunning] = useState(false);
	const [selectedProductStatus, setSelectedProductStatus] = useState(1);
	const [selectedOption, setSelectedOption] = useState(0);
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const productStatusType = [
		{ label: 'Active products', value: 1 },
		{ label: 'Inactive products', value: 0 }
	];
	const options = [
		{ label: 'Yes', value: 1 },
		{ label: 'No', value: 0 }
	];

	const ExportProduct = () => {
		setIsExportRunning(true);
		const data = {
			startDate: '2021-01-01',
			endDate: '2021-12-31'
		};
		api.exportProducts(data, selectedProductStatus, selectedOption).then((res) => {
			console.log('sa blob', res);
			var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(newBlob, `Projectmix - Product List.xlsx`);
			setIsExportRunning(false);
		});
	};
	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="row pb10">
						<div className="col-12">
							<div className="section-head-new"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="content pad15">
							<div className="row m-0 p-0 mb-3">
								<div className="col-md-2 col-sm-3 col-12">
									<h4 className="font18">
										<b>Export</b>
									</h4>
								</div>
							</div>
							<div className="row">
								<div className="col-md-4 col-3 text-right form-group">Product type</div>
								<div className="col-md-4 col-9 form-group">
									{productStatusType?.map((status, index) => (
										<>
											<div key={index}>
												<input
													type="radio"
													name="act-product"
													onClick={() => {
														setSelectedProductStatus(status?.value);
													}}
													checked={status?.value === selectedProductStatus ? true : false}
												/>{' '}
												<span htmlFor="">{status.label}</span>
											</div>
										</>
									))}
								</div>
							</div>
							<div className="row">
								<div className="col-md-4 col-3 text-right form-group">Include warehouse and stock information</div>
								<div className="col-md-4 col-9 form-group">
									{options?.map((option, index) => (
										<>
											<div key={index}>
												<input
													type="radio"
													name="act-product-yes"
													onClick={() => {
														setSelectedOption(option?.value);
													}}
													checked={option?.value === selectedOption ? true : false}
												/>{' '}
												<span htmlFor="">{option.label}</span>
											</div>
										</>
									))}
								</div>
							</div>
							<div className="row m-0 p-0 mb-3">
								<div className="col-12">
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										{isExportRunning ? (
											<button className="btn btn-outlined-main disabled" disabled>
												<FaSpinner style={{ animation: 'spin 2s linear infinite' }} /> Exporting...
											</button>
										) : (
											<button className="btn btn-outlined-main" onClick={() => ExportProduct()}>
												<MdOutlineCloudDownload /> Export
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ExportProduct;