import React, { createContext,useContext, useState } from 'react';

 const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
  const [selectedRows, setSelectedItems] = useState([]);

  const addSelectedRow = (item) => {
    setSelectedItems((prevItems) => [...prevItems, item]);
  };

  const updateSelectedRow = (updatedItem) => {
  const { product_id } = updatedItem;
  const existingRowIndex = selectedRows.findIndex((row) => row.product_id === product_id);

  if (existingRowIndex !== -1) {
    const updatedRows = [...selectedRows];
    updatedRows[existingRowIndex] = updatedItem;
    setSelectedItems(updatedRows);
  }
};

  const removeSelectedRow = (product_id) => {
    setSelectedItems((prevItems) => {
    const updatedItems = prevItems.filter((prevItem) => prevItem.product_id !== product_id);
    return updatedItems;
  });
  };

  const clearSelectedRows = () => {
    setSelectedItems([]);
  };

  return (
    <SelectionContext.Provider
      value={{
        selectedRows,
        addSelectedRow,
        removeSelectedRow,
        clearSelectedRows,
        updateSelectedRow
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

export const useSelection = () => {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error('useSelection must be used within a SelectionProvider');
  }
  return context;
};
