import MainLayout from 'views/client/layout';
import Auth from 'ui-component/Authclient';
import DashboardDefault from 'views/client/dashboard';
import Orders from 'views/client/orders';
import Returns from 'views/client/returns';
import ReturnsDetails from 'views/client/returns/ReturnsDetails';
import Estimates from 'views/client/estimates';
import EstimationDetails from 'views/client/estimates/EstimationDetails';
import Invoices from 'views/client/Invoices';
import Products from 'views/client/products';
import CheckWarranty from 'views/client/check-warranty';
import CompanyDetails from 'views/client/company-details';
import Settings from 'views/client/settings';
import CompanyAddress from 'views/client/company-details/components/CompanyAddress';
import CompanyDocuments from 'views/client/company-details/components/Documents';
import CompanyBankDetails from 'views/client/company-details/components/BankDetails';
import CompanyEndDetails from 'views/client/company-details/components/End';
import VerificationCompleted from 'views/client/company-details/components/VerificationCompleted';
import CompanyInfo from 'views/client/settings/components/CompanyInfo';
import CompanyAddressSettings from 'views/client/settings/components/CompanyAddress';
import CompanyBankSettings from 'views/client/settings/components/CompanyBank';
import SurveyDetails from 'views/client/company-details/components/Survey';
import CompanyPreferenceSettings from 'views/client/settings/components/CompanyPreference';
import Cart from 'views/client/cart';

// ===========================|| Client ROUTING ||=========================== //
const ClientRoutes = {
	path: '/',
	element: <MainLayout />,
	children: [
		{
			path: 'client/portal',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<DashboardDefault />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/dashboard',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<DashboardDefault />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/orders',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<Orders />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/returns',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<Returns />
						// </Auth>
					)
				},
				{
					path: 'create',
					element: <ReturnsDetails />
				},
				{
					path: ':id',
					element: <ReturnsDetails edit />
				}
			]
		},
		{
			path: 'client/cart',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<Cart />
						// </Auth>
					)
				}
			]
		},

		{
			path: 'client/estimates',
			children: [
				{
					path: '',
					element: <Estimates />
				},
				{
					path: ':id',
					element: <EstimationDetails />
				}
			]
		},

		{
			path: 'client/invoices',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<Invoices />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/products',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<Products />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/check-warranty',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<CheckWarranty />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/company-details',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<CompanyDetails />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/settings',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<Settings />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/settings/company-info',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<CompanyInfo />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/settings/company-address',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<CompanyAddressSettings />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/settings/company-bank',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<CompanyBankSettings />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/settings/company-preference',
			children: [
				{
					path: '',
					element: (
						// <Auth>
						<CompanyPreferenceSettings />
						// </Auth>
					)
				}
			]
		},
		{
			path: 'client/company-address',
			element: (
				// <Auth>
				<CompanyAddress />
				// </Auth>
			)
		},
		{
			path: 'client/company-documents',
			element: (
				// <Auth>
				<CompanyDocuments />
				// </Auth>
			)
		},
		{
			path: 'client/company-bank-details',
			element: (
				// <Auth>
				<CompanyBankDetails />
				// </Auth>
			)
		},
		{
			path: 'client/company-survey-details',
			element: (
				// <Auth>
				<SurveyDetails />
				// </Auth>
			)
		},
		{
			path: 'client/company-end-details',
			element: (
				// <Auth>
				<CompanyEndDetails />
				// </Auth>
			)
		},
		{
			path: 'client/company-verified',
			element: (
				// <Auth>
				<VerificationCompleted />
				// </Auth>
			)
		}
	]
};

export default ClientRoutes;
