export function handleAddress(place) {
	const { address_components, formatted_address } = place;
	let country = '';
	let state = '';
	let city = '';
	let zipCode = '';
	let addressLine1 = '';
	let addressLine2 = '';
	for (const component of address_components) {
		const componentType = component.types[0];

		if (componentType === 'country') {
			country = component.long_name;
		} else if (componentType === 'administrative_area_level_1') {
			state = component.long_name;
		} else if (componentType === 'administrative_area_level_3' || componentType === 'locality') {
			city = component.long_name;
		} else if (componentType === 'postal_code') {
			zipCode = component.long_name;
		} else if (componentType === 'street_number') {
			addressLine1 = `${component.long_name} ${addressLine1}`;
		} else if (componentType === 'route') {
			addressLine1 += component.long_name;
		} else if (componentType === 'sublocality_level_1') {
			addressLine2 = component.long_name;
		}
	}

	const addressObj = {
		country,
		state,
		city,
		zipCode,
		addressLine1,
		addressLine2
	};
	return addressObj;
}

export function checkObjectMatch(obj1, obj2) {
	for (const key in obj1) {
		if (obj1.hasOwnProperty(key)) {
			if (obj1[key] !== obj2[key]) {
				return false;
			}
		}
	}
	return true

}