import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useEffect } from 'react';
import ScrollButton from 'ui-component/ScrollToTopButton';
import GlobalSearch from 'ui-component/global-search/GlobalSearch';
import { useHotkeys } from 'react-hotkeys-hook';
import toast, { Toaster } from 'react-hot-toast';
import { setDisplayTheme, setClientLogout, storeClientDetails } from 'store/reducers/mainSlice';
import { useDispatch, useSelector } from 'react-redux';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { AiOutlinePoweroff } from 'react-icons/ai';
import './client.scss';
import NavBar from './NavBar';
import Sidebar from './SideBar';
import SidebarMobile from './SideBarMobile';
import Footer from './Footer';
import SearchBar from './SearchBar';
import Chatbox from './Chatbox';

const ClientLayout = () => {
	const history = useNavigate();
	const [showSearch, setShowSearch] = useState(false);
	const [displayTheme] = useSelector((state) => [state.main.displayTheme]);
	const [clientlog, setClientLog] = useState(false);
	const dispatch = useDispatch();
	const clientUser = useSelector((state) => state.main.client);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [mobileNav, setMobileNav] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [isBlur, setIsBlur] = useState(false);
	const [searchActive, setSearchActive] = useState(false);
	const [burgerActive, setBurgerActive] = useState(false);
	const [profileActive, setProfileActive] = useState(false);
	const path = window.location.pathname;
	const pathSegments = path.split('/');
	const lastSegment = pathSegments[pathSegments.length - 1];
	const [chatBoxOpen, setChatBoxOpen] = useState(false);
	const [salesManager, setSalesManager] = useState([]);
	const [activeChatMenu, setActiveChatMenu] = useState('menu');
	  const INACTIVITY_TIME = 30 * 60 * 1000; // 15 minutes in milliseconds


	useHotkeys('/', () => handleSearchToggle());

	const LogOutHandler = () => {
		dispatch(setClientLogout(false));
		api.clientlogout().then();
		window.localStorage?.removeItem('client');
		window.sessionStorage?.removeItem('client');
		dispatch(storeClientDetails(null));
		history('/client');
	};

	 useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        // Step 3: Call the logout function after inactivity
        LogOutHandler();
      }, INACTIVITY_TIME);
    };

    // Set up event listeners for various user activities
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('scroll', resetTimer);

    // Set the initial timer
    resetTimer();

    // Step 4: Cleanup function to remove event listeners and clear the timeout
    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('scroll', resetTimer);
    };
  }, []);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (clientUser?.data) {
			api.getClientInfo(clientUser?.data?.id).then((res) => {
				setSalesManager(res?.companyDocs?.users);
				if (res?.data) {
					dispatch(storeClientDetails(res.data));
				}
			});
		}
	}, []);

	useEffect(() => {
		document.body.id = 'page-top';
		if (!displayTheme) {
			if (localStorage.getItem('displayTheme')) {
				dispatch(setDisplayTheme(localStorage.getItem('displayTheme')));
			} else {
				dispatch(setDisplayTheme('lightMode'));
			}
		}
		if (!clientUser?.token) {
			history('/client');
		}
	}, []);
	const handleClientFromSide = () => {
		setClientLog(true);
	};
	const handleNavOpen = () => {
		setMobileNav((prev) => !prev);
	};

	useEffect(() => {
		document.body.className = displayTheme;
	}, [displayTheme]);

	const handleSearchToggle = () => {
		setShowSearch(!showSearch);
	};

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);

		// Cleanup function to remove the event listener when the component unmounts
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

	const styles = {
		width: windowWidth > 991 ? '80%' : windowWidth > 768 ? '100%' : '100%',
		height: isMobile ? 'auto' : '100%'
	};

	return (
		<>
			<div id="parent-client-wrapper">
				<div id="wrapper">
					<NavBar onSearchToggle={handleSearchToggle} />

					<div id="new-wrapper" style={{ position: 'relative' }}>
						{!isMobile ? (
							<Sidebar clientLogout={handleClientFromSide} style={{ height: '93vh !important' }} />
						) : (
							<>
								<SidebarMobile
									clientLogout={handleClientFromSide}
									mobileNav={mobileNav}
									setMobileNav={setMobileNav}
									setIsBlur={setIsBlur}
									setBurgerActive={setBurgerActive}
								/>
							</>
						)}

						<div id="content-wrapper-2" className={`d-flex flex-column ${isBlur ? 'blur' : ''}`}>
							{/* Main Content */}
							<div
								id="content"
								style={{
									position: 'relative',
									height: lastSegment === 'company-verified' || lastSegment === 'check-warranty' ? '100%' : isMobile ? 'auto' : '100%'
								}}
							>
								{/* Topbar */}

								<div
									className={`container-fluid d-flex justify-content-center ${isMobile ? 'padding-mobile-client' : ''}`}
									style={{
										height: lastSegment === 'company-verified' || lastSegment === 'check-warranty' ? '100%' : isMobile ? 'auto' : '100%'
									}}
								>
									{/* Page Content */}
									<div style={styles}>
										<Outlet />
									</div>
								</div>
							</div>
							{/* <Chatbox
								chatBoxOpen={chatBoxOpen}
								setChatBoxOpen={setChatBoxOpen}
								activeChatMenu={activeChatMenu}
								setActiveChatMenu={setActiveChatMenu}
								isMobile={isMobile}
								salesManager={salesManager}
							/> */}
						</div>
					</div>
					{isMobile && (
						<div id="footer-mobile" style={{ zIndex: 12 }}>
							<Footer
								onnavopen={handleNavOpen}
								mobileNav={mobileNav}
								clientLogout={handleClientFromSide}
								isBlur={isBlur}
								setIsBlur={setIsBlur}
								setBurgerActive={setBurgerActive}
								setProfileActive={setProfileActive}
								burgerActive={burgerActive}
								profileActive={profileActive}
								setMobileNav={setMobileNav}
								setSearchActive={setSearchActive}
								searchActive={searchActive}
							/>
						</div>
					)}
					{/* <div style={{ height: '100%', width: '100%', backgroundColor: 'black', zIndex: 15, position: 'absolute', top: 0, left: 0 }}></div> */}
					<Chatbox
						chatBoxOpen={chatBoxOpen}
						setChatBoxOpen={setChatBoxOpen}
						activeChatMenu={activeChatMenu}
						setActiveChatMenu={setActiveChatMenu}
						isMobile={isMobile}
						salesManager={salesManager}
					/>
				</div>
			</div>

			{/* Scroll to Top Button */}
			<ScrollButton />

			{clientlog && (
				<ActionConfirmationModal
					show={clientlog}
					title={'Ready to Leave?'}
					customIcon={<AiOutlinePoweroff size={35} />}
					customConfirmText={'Logout'}
					handleClose={() => setClientLog(false)}
					handleConfirm={LogOutHandler}
					confirmDanger
				/>
			)}
			<GlobalSearch show={showSearch} onForceClose={() => setShowSearch(false)} />
			<Toaster
				toastOptions={{
					className: 'toaster-styles-2'
				}}
			/>
		</>
	);
};

export default ClientLayout;
