import React, { useEffect, useState } from 'react';
import ProcessIndicator from '../ProcessIndicator';
import { CompanyDetailsProgresses } from 'assets/helperdata/client/company-details-progress';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import Select from 'react-select';

const BankDetails = () => {
	const clientUser = useSelector((state) => state.main.client);
	const history = useNavigate();
	const [isInProgress, setIsInProgress] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [data, setData] = useState({
		bankName: '',
		bankBeneficiary: '',
		bankIBAN: '',
		bankSWIFT: '',
		address: '',
		id: clientUser?.data?.id
	});

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (clientUser?.data?.id) {
			api.getClientInformationBank(clientUser?.data?.id).then((res) => {
				if (res?.data?.companyBank) {
					console.log(res.data?.companyBank);
					setData({
						bankName: res.data?.companyBank?.bank_name,
						bankBeneficiary: res.data?.companyBank?.bank_beneficiary_name,
						bankIBAN: res.data?.companyBank?.bank_IBAN,
						bankSWIFT: res.data?.companyBank?.bank_SWIFT,
						address: res.data?.companyBank?.bank_address,
						id: res.data?.companyBank?.id
					});
				}
			});
		}
	}, [clientUser?.data?.id]);

	const moveToNext = () => {
		if (data.bankName === '' || data.bankBeneficiary === '' || data.bankIBAN === '' || data.bankSWIFT === '' || data.address === '') {
			// toast.error('Please fill all fields');
			// setIsInProgress(false);
			// return;
		} else {

			api
				.updateClientBankDetails(data.id, data)
				.then((res) => {
					toast.success('Bank details updated successfully');
				})
				.catch((err) => {
					console.log(err);
				});
		}
		setIsInProgress(false);
		history('../client/company-survey-details');
	};
	const moveToBack = () => {
		setIsInProgress(false);
		history('../client/company-documents');
	};

	const calculateCompletionPercentage = () => {
		const fields = ['bankName', 'bankBeneficiary', 'bankIBAN', 'bankSWIFT', 'address'];
		const filledFields = fields.filter((field) => data[field] !== '');
		return (filledFields.length / fields.length) * 100;
	};

	// Call this function whenever you want to get the completion percentage
	const completionPercentage = calculateCompletionPercentage();

	return (
		<div className={`main-page-client ${isMobile ? 'padding-mobile-client' : ''}`}>
			<div className="actions-company-details-client">
				<ProcessIndicator
					isInProgress={isInProgress}
					processes={CompanyDetailsProgresses}
					currentIndex={4}
					completionPercentage={completionPercentage}
				/>
			</div>
			<div className="company-details-client-content">
				<div className="" style={{ justifyContent: 'space-between' }}>
					<table className="projectmix-table-vertical-client">
						<tbody>
							<tr>
								<td className="fw-600" colSpan={2} style={{ textAlign: isMobile ? 'left' : 'center', fontSize: isMobile ? 16 : 14 }}>
									Bank details
								</td>
							</tr>
							<tr>
								{!isMobile && <th>Bank Name</th>}
								<td>
									{isMobile && <label className="onboard-label">Bank Name</label>}
									<input
										type="text"
										value={data.bankName}
										className="form-control"
										onChange={(e) => {
											setData({ ...data, bankName: e.target.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
									/>
								</td>
							</tr>
							<tr>
								{!isMobile && <th>Beneficiary</th>}
								<td>
									{isMobile && <label className="onboard-label">Beneficiary</label>}
									<input
										type="text"
										value={data.bankBeneficiary}
										className="form-control"
										onChange={(e) => {
											setData({ ...data, bankBeneficiary: e.target.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
									/>
								</td>
							</tr>
							<tr>
								{!isMobile && <th> IBAN</th>}
								<td>
									{isMobile && <label className="onboard-label">IBAN</label>}
									<input
										type="text"
										value={data.bankIBAN}
										className="form-control"
										onChange={(e) => {
											setData({ ...data, bankIBAN: e.target.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
									/>
								</td>
							</tr>
							<tr>
								{!isMobile && <th> SWIFT</th>}
								<td>
									{isMobile && <label className="onboard-label">SWIFT</label>}
									<input
										type="text"
										className="form-control"
										value={data.bankSWIFT}
										onChange={(e) => {
											setData({ ...data, bankSWIFT: e.target.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
									/>
								</td>
							</tr>
							<tr>
								{!isMobile && <th>Address</th>}
								<td>
									{isMobile && <label className="onboard-label">Address</label>}
									<input
										type="text"
										value={data.address}
										className="form-control"
										onChange={(e) => {
											setData({ ...data, address: e.target.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="action-company-dtls-adrs-btn" style={{ marginTop: 10 }}>
				<button
					className="btn btn-cmpny-dtls-action-back"
					onClick={() => {
						setIsInProgress(true);
						moveToBack();
					}}
				>
					<MdKeyboardArrowLeft /> Back
				</button>

				<button
					className="btn btn-cmpny-dtls-action"
					onClick={() => {
						setIsInProgress(true);
						moveToNext();
					}}
				>
					Next <MdKeyboardArrowRight />
				</button>
			</div>
		</div>
	);
};

export default BankDetails;
