import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPlus } from 'react-icons/fa';
import { color } from '@mui/system';
import { set } from 'draft-js/lib/EditorState';
// import errorSound from '../../assets/audio/error.mp3';
import errorSound from '../../../assets/audio/error.mp3';
import useSound from 'use-sound';
import { PiArrowLineDownRightThin } from 'react-icons/pi';


const InboundingForm = ({ operationDetails, handleErrorDialog, handleUpdateOperationDetailsProducts }) => {

    const proceedures = [
        { label: 'For Operation', value: 'operation' },
        { label: 'For Picklist', value: 'picklist' },
    ];
	const [colors, setColors] = useState([]);
    const [errorSoundPlay] = useSound(errorSound);
	const [isInProgress, setIsInProgress] = useState(false);
	const imeiInputRef = useRef(null);
    const [isInternalLoading, setInternalLoading] = useState(false);
    // const [defaultProceedure, setDefaultProceedure] = useState('operation');
    const [productList, setProductList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [productQuantity, setProductQuantity] = useState(1);
    
	const [errorDialog, setErrorDialog] = useState({
		show: false,
		message: '',
		title: ''
	});

    
    const [itemDetails, setItemDetails] = useState({
        id: '',
        categoryId: '',
        categoryName: '',
        product_id: '',
        product_name: '',
        quantity: 1,
        color_id: '',
        color_name: '',
        imei: '',
    });

    useEffect(() => { 
        
        api.getSelectOptionsList('product').then((res) => {
            if (res?.data) {
                const products = res?.data?.map((item) => { 
                    item.label = item.product_name;
                    item.value = item.id;
                    return item;
                });
                setProductList(products);
            }
        });
        api.getSelectOptionsList('color').then((res) => {
            if (res?.data) {
                const colors = res?.data?.map((item) => { 
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                setColors(colors);
            }
        });
    }, []);


	const showErrorDialog = (title, message = '') => {
		handleErrorDialog({
			show: true,
			message: message,
			title: title
		});
	};

	const handleIMEIInputKeydown = (event) => {
		if (event.key === 'Enter') {
			addModalImei();
		}
    };

    
    const handleInboundItem = () => {
        if (!itemDetails?.product_id) {
            showErrorDialog('Product is required', `Please select a product`);
            return;
        }

        if (!itemDetails?.quantity) {
            showErrorDialog('Quantity is required', `Please enter a quantity`);
            return;
        }

        if (!itemDetails?.color_name) {
            showErrorDialog('Color is required', `Please select a color`);
            return;
        }
        if (itemDetails?.imei) {

            const imeiExists = operationDetails?.products?.find(product => product.imei === itemDetails?.imei);
            if (imeiExists) {
                showErrorDialog(`IMEI already inbounded`);
                setItemDetails({ ...itemDetails, imei: '' });
                errorSoundPlay();
                return false;
            }
        }
        // if (itemDetails?.categoryName !== 'Parts' && itemDetails?.categoryName !== 'Accessories') {

            if (itemDetails?.imei?.trim() === '') {

                showErrorDialog('IMEI is required', `Invalid IMEI Entered`);
                setItemDetails({ ...itemDetails, imei: '' });
                return false;
            } 

            const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

            if (!alphanumericRegex.test(itemDetails?.imei)) {
                showErrorDialog(`Please enter a valid IMEI Number`);
                setItemDetails({ ...itemDetails, imei: '' });
                return false;
            }
        // }

        
        const newData = {
            id: itemDetails?.id,
            categoryName: itemDetails?.categoryName,
            product_id: itemDetails?.product_id,
            product_name: itemDetails?.product_name,
            quantity: itemDetails?.quantity,
            color_id: itemDetails?.color_id,
            color_name: itemDetails?.color_name,
            imei: itemDetails?.imei,
            status: 'Pending',
        }
        handleUpdateOperationDetailsProducts(newData)
        
        setItemDetails({
            ...itemDetails,
            quantity: 1,
            imei: ''
        })

    };

	const addModalImei = async () => {
		if (!itemDetails?.imei || !itemDetails?.imei?.trim()) {
            showErrorDialog('IMEI is required', `Invalid IMEI Entered`);
            setItemDetails({ ...itemDetails, imei: '' });
			return;
		}

		const alphanumericRegex = /^[0-9a-zA-Z]{10,16}$/;

		if (!alphanumericRegex.test(itemDetails?.imei)) {
			showErrorDialog(`Please enter a valid IMEI Number`);
            setItemDetails({ ...itemDetails, imei: '' });
			return;
        }

        const imeiExists = operationDetails?.products?.find(product => product.imei === itemDetails?.imei);
        
        if (imeiExists) {
			showErrorDialog(`IMEI already inbounded`);
            setItemDetails({ ...itemDetails, imei: '' });
            errorSoundPlay();
        }
        
        const newData = {
            id: itemDetails?.id,
            categoryId: itemDetails?.categoryId,
            categoryName: itemDetails?.categoryName,
            product_id: itemDetails?.product_id,
            product_name: itemDetails?.product_name,
            quantity: itemDetails?.quantity,
            color_id: itemDetails?.color_id,
            color_name: itemDetails?.color_name,
            imei: itemDetails?.imei,
            status: 'Pending',
        }

        handleUpdateOperationDetailsProducts(newData)
        
        setItemDetails({
            ...itemDetails,
            quantity: 1,
            imei: '',
        })

    };
    
    return (
        <>
            
            <div>
                
                <div className="form-group">
                    <h5>{ operationDetails?.referenceNo}</h5>
                </div>

                <div className="row align-items-top form-group">
                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                        <div className="textGray" style={{ width: '64px' }}>Product</div>
                        <div style={{ width: '350px', marginLeft: '10px', textAlign: 'left' }}>
                            <Select
                                placeholder="Select or type..."
                                className="rs-container"
                                classNamePrefix="rs"
                                options={productList}
                                value={itemDetails?.product_id ? categoryList?.find((c) => c?.value == itemDetails?.product_id) : null}
                                onChange={(e) => {
                                    // setSelectedProduct(e);
                                    setItemDetails({
                                        ...itemDetails,
                                        product_id: e.value,
                                        product_name: e.label
                                    });
                                    setProductQuantity(1)
                                    // setTimeout(() => {
                                    //     if (e.value && selectedColor) {
                                    //         imeiInputRef.current.focus();
                                    //     }
                                    // }, 1);
                                }}
                                // isDisabled={!isInProgress}
                            />
                       
                        </div>
                    </div>
                </div>

                <div className="row align-items-top form-group">
                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                        <div className="textGray" style={{ width: '64px' }}>Color</div>
                        <div style={{ width: '350px', marginLeft: '10px', textAlign: 'left' }}>
                            <CreatableSelect
                                placeholder="Select or type..."
                                className="rs-container"
                                classNamePrefix="rs"
                                options={colors}
                                value={itemDetails?.color_name ? categoryList?.find((c) => c?.label == itemDetails?.color_name) : null}
                                onChange={(e) => {
                                    setItemDetails({
                                        ...itemDetails,
                                        color_name: e.label,
                                    });
                                    // setSelectedColor(e.value);
                                    // setTimeout(() => {
                                    //     imeiInputRef.current.focus();
                                    // }, 1);
                                }}
                            />
                    
                        </div>
                    </div>
                </div>

                <div className="row align-items-top form-group">
                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                        <div className="textGray" style={{ width: '64px' }}>IMEI</div>
                        <div style={{ width: '350px', marginLeft: '10px', textAlign: 'left' }}>
                            <input
                                type="text"
                                id="input-example"
                                name="input-name"
                                className="form-control"
                                placeholder="IMEI"
                                minLength={15}
                                maxLength={16}
                                value={itemDetails?.imei}
                                onChange={(e) => {
                                    setItemDetails({
                                        ...itemDetails,
                                        imei: e.target.value
                                    });
                                }}
                                onKeyDown={(e) => {
                                    handleIMEIInputKeydown(e);
                                }}
                                autoComplete={'off'}
                                disabled={!itemDetails?.product_id || !itemDetails?.quantity || !itemDetails?.color_name}
                                autoFocus
                            />
                    
                        </div>
                    </div>
                </div>

                
                <div className="row align-items-top form-group">
                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                        <div className="textGray" style={{ width: '64px' }}></div>
                        <div style={{ width: '350px', marginLeft: '10px', textAlign: 'right', display: 'flex', justifyContent: 'right' }}>
                            {/* <PermissionAllowed permissions={['Create New Estimation (Action)']}> */}
                                <button
                                    type="button"
                                    onClick={() => handleInboundItem()}
                                    className="btn btn-primary btn-outlined-main"
                                    disabled={isInternalLoading}
                                >
                                    <FaPlus /> Add
                                </button>
                            {/* </PermissionAllowed> */}
                       
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

export default InboundingForm;
