import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaSave, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import FileUpload from 'ui-component/FileUpload';
import { getCurrencySymbol } from 'utils/currency';
import toast from 'react-hot-toast';
import { Abc } from '@mui/icons-material';

const PurchaseInvoiceEditPopup = ({ openpopup, handleClose, disableStatus, edit }) => {
	const [rows, setRows] = useState([]);
	const [alertMessage, setAlertMessage] = useState();
	const [userList, setUserList] = useState([]);
	// const [createType, setCreateType] = useState({ label: 'New', value: 'new' });
	const [supplierInvoices, setSupplierInvoices] = useState([]);
	const [file, setFile] = useState();
	const [formInfo, setFormInfo] = useState({
		supplier: '',
		currency: '',
		invoice_number: '',
		date: '',
		transferCharge: ''
	});

	const history = useNavigate();

	useEffect(() => {
		if (edit?.id) {
			setFormInfo({
				supplier: edit?.supplier,
				currency: edit?.currency,
				invoice_number: edit?.invoice_number,
				date: moment(edit?.date).format('YYYY-MM-DD'),
				transferCharge: edit?.additional_charges
			});
			setRows(edit?.purchase_invoice_items);
		} else {
			setFormInfo({
				supplier: '',
				currency: '',
				invoice_number: '',
				date: '',
				transferCharge: ''
			});
			setRows([]);
		}
	}, [edit]);

	const handleFileUpload = (e) => {
		const files = e.target.files;
		setFile(files?.length >= 1 ? files?.[0] : []);
		// setDisablestatus(true);
	};

	const removeRow = (id) => {
		if (rows.length === 1) {
			return;
		}
		const updatedRows = rows.filter((row) => row.id !== id);
		setRows(updatedRows);
	};

	const handleValue = (val) => {
		setFormInfo(val);
	};

	const getPurchaseInvoiceOptionsList = (sup_id) => {
		api.getPurchaseInvoiceOptionsList(sup_id).then((res) => {
			setSupplierInvoices(res?.data);
		});
	};

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		zIndex: 9
	};
	const onSubmit = () => {
		if (formInfo.invoice_number == '') {
			toast?.error('Please enter invoice number');
		} else if (formInfo.date == '') {
			toast?.error('Please select date');
		} else {
			let purchaseId = rows.map((item) => item.id);
			const data = {
				invoice_number: formInfo?.invoice_number,
				date: formInfo?.date,
				additional_charges: formInfo?.transferCharge,
				currency: formInfo?.currency,
				purchase_list_ids: purchaseId
				// attachment: file,
			};

			toast.promise(
				api.updatePurchaseInvoice(edit?.id, data).then((res) => {
					if (res.status) {
						handleClose();
						history('/purchase-invoice');
					}
					return res.status ? res : abc;
				}),
				{
					loading: 'Updating Purchase Invoice...',
					success: <b>Purchase Invoice Updated!</b>,
					error: <b>Could not update Purchase Invoice</b>
				}
			);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const disablepopup = () => {
		setRows('');
		handleClose();
	};
	return (
		<>
			<Modal show={openpopup} onHide={disablepopup} centered size="xl">
				<Modal.Body>
					<div className="p-4">
						<div className="foundListPopup">
							<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 ml-0 pl-0">
								<h3 className="h4 mb-0  font-weight-bold flex-grow-1">Edit Purchase Invoice</h3>
							</div>
							<div className="row align-items-top">
								<div className="col-md-5">
									<form className="assign found">
										<div className="row w-100">
											<div className="form-group col-md-6 mb-2">
												<label className="">Supplier</label>

												<p className="m-0">{formInfo?.supplier?.name}</p>
											</div>
											<div className="form-group col-md-6 mb-2">
												<label className="">Currency</label>

												<p className="m-0">{formInfo?.currency?.toUpperCase()}</p>
											</div>
											<div className="form-group col-md-12 mb-2">
												<label className="">Invoice Number</label>

												<input
													type="text"
													onChange={(e) => {
														handleValue({
															...formInfo,
															invoice_number: e.target.value
														});
													}}
													value={formInfo?.invoice_number}
													className="form-control"
													disabled
												/>
												{alertMessage?.user && (
													<>
														<span className="error-message text-danger">{alertMessage?.user}</span>
													</>
												)}
											</div>
											<div className="form-group col-md-6">
												<label className="">Date</label>
												<input
													type="date"
													onChange={(e) => {
														handleValue({
															...formInfo,
															date: e.target.value
														});
													}}
													disabled
													defaultValue={formInfo?.date}
													value={formInfo?.date}
													className="form-control"
												/>
												{alertMessage?.date && (
													<>
														<span className="error-message text-danger">{alertMessage?.date}</span>
													</>
												)}
											</div>
											<div className="form-group col-md-6">
												<label className="">Transfer Charge</label>
												<input
													type="number"
													onChange={(e) => {
														handleValue({
															...formInfo,
															transferCharge: e.target.value
														});
													}}
													value={formInfo?.transferCharge}
													className="form-control"
												/>
												{alertMessage?.time && (
													<>
														<span className="error-message text-danger">{alertMessage?.time}</span>
													</>
												)}
											</div>

											{/* <div className="form-group col-md-12">
												<label>Attachment</label>

												<FileUpload
													accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
													loading={false}
													onChange={(e) => {
														handleFileUpload(e);
													}}
													selectedValue={file?.[0]}
												/>

												{alertMessage?.files && (
													<span className="px-3 down-error text-danger">{alertMessage?.files}</span>
												)}
											</div> */}
										</div>
									</form>
								</div>
								<div className="col-md-7 m-0 p-0">
									<div className="collaps found table-responsive-xl tableData">
										<Scroller heightvalue={'100%'}>
											<table className="table table-md  formActBtns border-0">
												<thead style={tableHeaderStyles}>
													<tr>
														<th className="align-middle">Model</th>
														<th className="align-middle">Invoice</th>
														<th className="align-middle">Quantity</th>
														<th className="align-middle">Price</th>
														<th className="align-middle"></th>
													</tr>
												</thead>
												<tbody>
													{rows?.map((row) => (
														<tr key={row.id}>
															<td className="align-middle">{row?.product?.name}</td>
															<td className="align-middle">{row?.invoice?.invoice_number}</td>
															<td className="align-middle">{row?.quantity}</td>
															<td className="align-middle">
																{getCurrencySymbol(formInfo?.currency)} {row?.price?.[formInfo?.currency]}
															</td>
															<td className="align-middle text-right">
																<span
																	className={`btnEdit plain-iconbtn ${
																		rows?.length <= 1 ? 'disabled' : ''
																	}`}
																	onClick={() => removeRow(row.id)}
																>
																	<FaTimes />
																</span>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</Scroller>
									</div>
								</div>
							</div>
						</div>
						<div className="btnarea mt-3">
							<button disabled={disableStatus} onClick={disablepopup} className="btn btn-link">
								Cancel
							</button>
							<button disabled={disableStatus} onClick={onSubmit} className="btn btn-primary btn-outlined-main">
								<FaSave /> Save
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PurchaseInvoiceEditPopup;
