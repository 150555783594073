import React from 'react';
import { Modal } from 'react-bootstrap';

const AlertModel = ({ openStatus, handleclose }) => {
    return (
        <Modal show={openStatus} onHide={handleclose} centered>
            <Modal.Body>
                <div className='row p-3 '>
                    <div className='text-center m-auto'>
                        <p>{openStatus}</p>
                        <button className="btn rounded-pill btn-primary py-2 px-4 pl-3 pr-4 m-auto" onClick={(e) => {
                            handleclose();
                        }}>
                            OK
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AlertModel
