import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useEffect } from 'react';
import ScrollButton from 'ui-component/ScrollToTopButton';
import GlobalSearch from 'ui-component/global-search/GlobalSearch';
import { useHotkeys } from 'react-hotkeys-hook';
import toast, { Toaster } from 'react-hot-toast';
import { setDisplayTheme, storeClientDetails } from 'store/reducers/mainSlice';
import { useDispatch, useSelector } from 'react-redux';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { AiOutlinePoweroff } from 'react-icons/ai';
import '../client.scss';
import Select from 'react-select';
import NavBar from '../NavBar/NavbarLogin';
import Oval1 from 'assets/images/designs/Oval-1.png';
import Oval2 from 'assets/images/designs/Oval.png';

const ForgotPassword = () => {
	const history = useNavigate();
	const navigate = useNavigate();
	const [isClicked, setIsClicked] = useState(false);

	const dispatch = useDispatch();
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	let validToken = useSelector((state) => state.main?.user?.token);
	const [email, setEmail] = useState('');
	const [login, setLogin] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const onSubmit = () => {
		setIsClicked(true);
		const payload = {
			email: email
		};

		setIsSubmitting(true);

		toast.promise(api.forgotPasswordClient(payload), {
			loading: 'Sending OTP',
			success: (res) => {
				if (res.status) {
					const { email, token } = res.data; // Extract email and token from res.data
					navigate('/client/forgot-password-otp', { state: { email, token } });
					return 'OTP sent successfully';
				} else {
					setIsClicked(false);
					return 'Failed to process request';
				}
			},
			error: (err) => `Failed to process request: ${err.message}`
		});
	};

	return (
		<>
			<div id="parent-client-wrapper">
				<div id="wrapper">
					<NavBar />

					<div
						id="new-wrapper"
						style={{
							fontFamily: 'Poppins, sans-serif'
						}}
					>
						<div id="content-wrapper" className="d-flex flex-column" style={{ backgroundColor: 'transparent' }}>
							<div id="content" style={{ position: 'relative' }}>
								<div className="container-fluid d-flex justify-content-center">
									<div style={{ maxWidth: '2200px', width: '100%', height: '90vh' }}>
										<div className={login ? 'main-page-auth-3' : 'main-page-auth-2'}>
											<div className={login ? 'backdrop-image-purple' : 'backdrop-image-purple-sign'}>
												<img src={Oval1} alt="Oval1" style={{ opacity: '50%' }} />
											</div>
											<div className={login ? 'backdrop-image-pink' : 'backdrop-image-pink-sign'}>
												<img src={Oval2} alt="Oval2" style={{ opacity: '70%' }} />
											</div>
											<div className="invoice-client-content">
												<div
													className="form-signup"
													style={{ justifyContent: 'space-between' }}
													onKeyPress={(event) => {
														if (event.key === 'Enter') {
															event.preventDefault();
														}
													}}
												>
													<div className="signup-head" style={{ marginBottom: 20 }}>
														<h4 style={{ marginBottom: 5 }}>Password Reset</h4>
													</div>
													<div className="row" style={{ marginBottom: 20 }}>
														<div className={isMobile ? 'col-12' : 'col-12'}>
															<input
																type="text"
																className="form-control"
																placeholder="Email@gmail.com"
																style={{ color: 'black', borderColor: '#0066FF' }}
																autocomplete="nope"
																onChange={(e) => {
																	setEmail(e.target.value);
																}}
															/>
														</div>
													</div>
													<div className="action-signup">
														<button
															className="btn btn-cmpny-dtls-action"
															type="submit"
															onClick={() => onSubmit()}
															disabled={isSubmitting} // Disable the button when isSubmitting is true
														>
															Send OTP
														</button>
													</div>
												</div>
											</div>
										</div>
										<div
											style={{
												position: 'fixed',
												bottom: 20,
												left: '50%',
												transform: 'translate(-50%, 0)',
												textAlign: 'center'
											}}
										>
											© 2024 Projectmix Ai. <span style={{ display: 'inline-block' }}>All Rights Reserved</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Toaster
				toastOptions={{
					className: 'toaster-styles-2'
				}}
			/>
		</>
	);
};

export default ForgotPassword;
