import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router';

const Scroller = ({ children, heightvalue, resHeight = null, extraHeight = 0 }) => {
	const resViewRef = useRef(null);
	const { pathname } = useLocation();
	const [heightData, setHeightData] = useState();
	const { width: windowWidth, height: windowHeight } = useWindowDimensions();

	const scrollbarStyle = {
		width: '100%',
		height: '100%',
		overflowX: 'hidden'
	};
	const style = {
		width: '100%',
		maxHeight: heightvalue ? heightvalue : heightData ? heightData : '50vh',
		marginBottom: '10px'
	};
	const heightMeasure = () => {
		let wrapperData = document.getElementById('content-wrapper')?.clientHeight || 50;
		let navData = document.querySelector('nav')?.clientHeight || 50;
		let topbarheight = document.querySelector('.topListCom')?.clientHeight || 0;
		let secondrytop = document.querySelector('.CenterpageTitle')?.clientHeight || 0;
		let buttonare = document.querySelector('.btnarea')?.clientHeight || 0;
		let sectionHead = document.querySelector('.section-head')?.clientHeight || 0;
		let tablesearchbar = document.querySelector('.TableSerachBar')?.clientHeight || 0;
		let pagin = document.querySelector('.paginationcon')?.clientHeight || 0;
		let nonScrollBtnSection = document.querySelector('.non-scroll-btn-section')?.clientHeight || 0;
		let processStatusIndicator = document.querySelector('.process-status-indicator-container')?.clientHeight || 0;
		let currenthtml = secondrytop;

		let mainHeight = wrapperData;
		let usesHeight =
			currenthtml +
			buttonare +
			tablesearchbar +
			pagin +
			sectionHead +
			topbarheight +
			nonScrollBtnSection +
			processStatusIndicator +
			extraHeight;
		let ogih = mainHeight - usesHeight - 50;

		setHeightData(`${ogih}px`);
	};
	useEffect(() => {
		heightMeasure();
	}, []);

	useEffect(() => {
		heightMeasure();
	}, [extraHeight]);

	useEffect(() => {
		resHeight && resHeight(style?.height);
	}, [heightData]);

	return windowWidth < 650 ? (
		<>{children}</>
	) : (
		<Scrollbars
			autoHide
			autoHideTimeout={0}
			autoHideDuration={0}
			style={{ ...style, overflow: 'visible' }}
			renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
			renderView={(props) => <div className="responsive-view" {...props} style={{ ...style, ...scrollbarStyle }} />}
		>
			{children}
		</Scrollbars>
	);
};

export default Scroller;
