// TopSuppliers.js
import React from 'react';
import PrivacyModeContent from 'ui-component/PrivacyModeContent'; // Import this if it's in a different file
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

const TopSuppliers = ({ topSupplier }) => {
	return (
		topSupplier.length > 0 && (
			<div className="col-lg-3 col-md-6 col-sm-12">
				<Fade>
					<div className="card" style={{ height: '280px', marginBottom: '20px', padding: '10px', paddingTop: '5px' }}>
						<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: '10px' }}>
							<a style={{ fontWeight: '500' }}>Top Suppliers</a>
						</div>
						<div className="card-body" style={{ paddingTop: 5, paddingBottom: 0, paddingRight: 15, overflowY: 'auto', maxHeight: '200px' }}>
							{topSupplier.map((item, index) => (
								<Link to={`/contacts/${item.id}`} style={{ color: 'black', textDecoration: 'none' }}>
									<div className="row hover-effect" key={index} style={{ paddingBottom: '4px', paddingTop: '3px' }}>
										<div className="col-7 top-clients">
											<div
												style={{
													whiteSpace: 'nowrap',

													fontWeight: 100
												}}
											>
												<PrivacyModeContent>
												{item.supplier_name.split(' ').slice(0, 2).join(' ')}
												</PrivacyModeContent>
											</div>
										</div>
										<div className="col-5 top-clients" style={{ textAlign: 'right' }}>
											<a style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
												<PrivacyModeContent>$ {item.total_purchase.toLocaleString()}</PrivacyModeContent>
											</a>
										</div>
									</div>
								</Link>
							))}
						</div>
					</div>
				</Fade>
			</div>
		)
	);
};

export default TopSuppliers;
