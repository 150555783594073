import React, { useEffect, useState } from 'react';
import ProcessIndicator from './ProcessIndicator';
import { CompanyDetailsProgresses } from 'assets/helperdata/client/company-details-progress';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { set } from 'react-hook-form';

const CompanyDetails = () => {
	const history = useNavigate();
	const [isInProgress, setIsInProgress] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [turnOver, setTurnOver] = useState([]);
	const [activities, setActivities] = useState([]);
	const [countryList, setCountryList] = useState([]);
	const [interests, setInterests] = useState([]);
	const [specifications, setSpecifications] = useState([]);
	const [companyData, setCompanyData] = useState(null);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const clientUser = useSelector((state) => state.main.client);
	const [data, setData] = useState({
		companyName: clientUser?.data?.company_name,
		establishmentYear: '',
		companyActivites: '',
		companyTurnOver: '',
		id: clientUser?.data?.id,
		contactPerson: '',
		email: '',
		phone: '',
		whatsapp: '',
		website: '',
		phonecode: '',
		phonecountryiso2: '',
		whatsappcountryiso2: '',
		whatsappcode: '',
		interests: [],
		specifications: [],
		isDefaut: false
	});

	const [phoneNumber, setPhoneNumber] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [defaultCountry, setDefaultCountry] = useState(countryCode);

	const [whatsapp, setWhatsapp] = useState('');
	const [selectedCountry, setSelectedCountry] = useState('AE');
	const [selectedWhatsappCountry, setSelectedWhatsappCountry] = useState('AE');
	const [trigger, setTrigger] = useState(false);

	const currentYear = new Date().getFullYear();
	const establishmentYearOptions = [];

	const countryCodes = countryList.reduce((unique, country) => {
		const isDuplicate = unique.find((item) => item.label === `+${country.phonecode}`);

		if (!isDuplicate) {
			unique.push({
				label: `+${country.phonecode}`,
				value: `+${country.phonecode}`
			});
		}

		return unique;
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		setDefaultCountry(countryCode);
	}, [countryCode]);

	for (let year = currentYear; year >= currentYear - 100; year--) {
		establishmentYearOptions.push({
			value: year.toString(),
			label: year.toString()
		});
	}

	useEffect(() => {
		api
			.getClientInterest()
			.then((res) => {
				const interests = res.data.map((interest) => ({
					value: interest.id,
					label: interest.name
				}));

				setInterests(interests);
			})
			.catch((err) => {
				console.log(err);
			});

		api
			.getClientSpec()
			.then((res) => {
				const specification = res.data.map((spec) => ({
					value: spec.id,
					label: spec.name
				}));
				setSpecifications(specification);
			})
			.catch((err) => {
				console.log(err);
			});

		api.getTurnOvers().then((res) => {
			if (res.data) {
				setSelectedOption(res.data[0].id);
				setData({ ...data, companyTurnOver: res.data[0].id });
				setTurnOver(res.data);
			}
		});
		api.company_activities().then((res) => {
			if (res.data) {
				const activities = res.data.map((activity) => ({
					value: activity.id,
					label: activity.name
				}));
				setActivities(activities);
			}
		});
		if (clientUser?.data?.id) {
			api.getClientInformation(clientUser?.data?.id).then((res) => {
				if (res.data.companyInfo) {
					if (clientUser?.data?.is_approved != 2) {
						console.log(res.data);
						setData({
							establishmentYear: res.data.companyInfo.establishment_year,
							companyActivites: res.data.companyInfo.company_activity_id,
							companyTurnOver: res.data.companyInfo.turnover_id,
							id: res.data.client.id,
							contactPerson: res.data.companyContact.contact_person,
							email: res.data.companyContact.email,
							phone: res.data.companyContact.phone_number,
							whatsapp: res.data.companyContact.whatsapp,
							phonecode: res.data.companyContact.phone_code,
							whatsappcode: res.data.companyContact.whatsapp_code,
							phonecountryiso2: res.data.companyContact.phone_country_iso2,
							whatsappcountryiso2: res.data.companyContact.whatsapp_country_iso2,
							website: res.data.companyContact.website,
							interests: res.data.companyInterest,
							specifications: res.data.companySpec,
							isDefaut: res.data.companyContact.isDefault === 1 ? true : false
						});
					} else {
						setCompanyData(res.data);
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		api.getCountryList().then((res) => {
			if (res.status === true) {
				setCountryList(res.data);
			}
		});
		if (data.phonecountryiso2) {
			setSelectedCountry(data.phonecountryiso2);
		}
		if (data.whatsappcountryiso2) {
			setSelectedWhatsappCountry(data.whatsappcountryiso2);
		}
	}, []);

	// const updatePhone = () => {
	// 	if (phoneNumber) {
	// 		const phoneCode = phoneNumber.countryCallingCode;
	// 		const phone = phoneNumber.nationalNumber;
	// 		const phoneCountryIso2 = phoneNumber.country;
	// 		setData({ ...data, phone: phone, phonecode: phoneCode, phonecountryiso2: phoneCountryIso2 });
	// 	}
	// };

	// const updateWhatsapp = () => {
	// 	console.log(whatsapp);
	// 	if (whatsapp) {
	// 		const whatsappCode = whatsapp.countryCallingCode;
	// 		const whatsappNumber = whatsapp.nationalNumber;
	// 		const whatsappCountryIso2 = whatsapp.country;
	// 		setData({ ...data, whatsapp: whatsappNumber, whatsappcode: whatsappCode, whatsappcountryiso2: whatsappCountryIso2 });
	// 	}
	// 	console.log(data);
	// };

	useEffect(() => {
		if (data?.phonecode) {
			const country = countryList.find((country) => country.phonecode === data.phonecode);

			if (country) {
				setCountryCode(country.iso2);
				setSelectedCountry(country.iso2);
			}
		}
	}, [data.phonecode]);

	useEffect(() => {
		if (data?.whatsappcode) {
			const country = countryList.find((country) => country.phonecode === data.whatsappcode);
			if (country) {
				setCountryCode(country.iso2);
				setSelectedWhatsappCountry(country.iso2);
			}
		}
	}, [data.whatsappcode]);

	const changeSelectedCountry = (phoneCode) => {
		// console.log(phoneCode);
	};

	useEffect(() => {
		if (trigger === true) {
			if (
				data.contactPerson == '' ||
				data.email == '' ||
				data.phone == '' ||
				data.whatsapp == '' ||
				data.phonecode == '' ||
				data.whatsappcode == '' ||
				data.website == ''
			) {
				toast.error('Please fill all fields');
			} else {
				moveToNext();
			}
		}
	}, [trigger]); // Change dependency to trigger

	const savePhones = () => {
		if (phoneNumber) {
			const phoneCode = phoneNumber.countryCallingCode;
			const phone = phoneNumber.nationalNumber;
			const phoneCountryIso2 = phoneNumber.country;
			setData((prevData) => ({ ...prevData, phone: phone, phonecode: phoneCode, phonecountryiso2: phoneCountryIso2 }));
		}
		if (whatsapp) {
			const whatsappCode = whatsapp.countryCallingCode;
			const whatsappNumber = whatsapp.nationalNumber;
			const whatsappCountryIso2 = whatsapp.country;
			setData((prevData) => ({
				...prevData,
				whatsapp: whatsappNumber,
				whatsappcode: whatsappCode,
				whatsappcountryiso2: whatsappCountryIso2
			}));
		}
		setTrigger(!trigger); // Toggle trigger state here
	};

	const moveToNext = () => {
		// if (phoneNumber) {
		// 	console.log(phoneNumber);
		// 	const phoneCode = phoneNumber.countryCallingCode;
		// 	const phone = phoneNumber.nationalNumber;
		// 	console.log(phoneCode, phone);
		// 	setData({ ...data, phone: phone, phonecode: phoneCode });
		// }
		// if (whatsapp) {
		// 	const whatsappCode = whatsapp.countryCallingCode;
		// 	const whatsappNumber = whatsapp.nationalNumber;
		// 	setData({ ...data, whatsapp: whatsappNumber, whatsappcode: whatsappCode });
		// }
		if (!data.establishmentYear) {
			toast.error('Please select establishment year');
			return;
		}
		if (!data.companyActivites) {
			toast.error('Please select company activities');
			return;
		}
		if (!data.companyTurnOver) {
			toast.error('Please select company turnover');
			return;
		}
		if (
			data.contactPerson == '' ||
			data.email == '' ||
			data.phone == '' ||
			data.whatsapp == '' ||
			data.phonecode == '' ||
			data.whatsappcode == '' ||
			data.website == ''
		) {
			toast.error('Please fill all fields');
			setIsInProgress(false);
			setTrigger(false);
			return;
		}

		api
			.updateClientInfo(data.id, data)
			.then((res) => {
				history('/client/company-address');
				setIsInProgress(false);
			})
			.catch((err) => {
				setIsInProgress(false);
			});
	};

	const handleCheckboxChange = (value) => {
		setSelectedOption(value);
		setData({ ...data, companyTurnOver: value });
	};
	const calculateCompletionPercentage = () => {
		const fields = ['establishmentYear', 'companyActivites', 'companyTurnOver', 'contactPerson', 'email', 'phone', 'whatsapp', 'website'];
		const filledFields = fields.filter((field) => data[field] !== undefined && data[field] !== null && data[field] !== '');
		return (filledFields.length / fields.length) * 100;
	};

	// Call this function whenever you want to get the completion percentage
	const completionPercentage = calculateCompletionPercentage();

	const SaveCompanydetails = () => {};

	const customComponents = {
		ClearIndicator: () => null // Override the ClearIndicator with a null component
	};

	return (
		<div className={`main-page-client ${isMobile ? 'padding-mobile-client' : ''}`} style={{ paddingTop: 0 }}>
			<>
				<div className="actions-company-details-client">
					<ProcessIndicator
						isInProgress={isInProgress}
						processes={CompanyDetailsProgresses}
						currentIndex={1}
						completionPercentage={completionPercentage}
					/>
				</div>
				<div className="company-details-client-content">
					<div className="" style={{ justifyContent: 'space-between' }}>
						<table className="projectmix-table-vertical-client">
							<tbody>
								<tr>
									{!isMobile && <th></th>}

									<td className="fw-600" style={{ textAlign: isMobile ? 'left' : 'left', fontSize: isMobile ? 16 : 14 }}>
										Company Details
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Company Name</th>}
									<td>
										{isMobile && <label className="onboard-label">Company Name</label>}
										<input type="text" className="form-control" placeholder="company name" value={data.companyName} disabled />
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Establishment Year</th>}
									<td>
										{isMobile && <label className="onboard-label">Establishment Year</label>}
										<Select
											className="rs-container-est-white-client"
											classNamePrefix="rs"
											isSearchable={true}
											name="customer"
											options={establishmentYearOptions}
											value={establishmentYearOptions
												.map((y) => ({ label: y.label, value: y.value }))
												.find((y) => y?.value == data?.establishmentYear)}
											onChange={(e) => {
												setData({ ...data, establishmentYear: e.value });
												const completionPercentage = calculateCompletionPercentage();
											}}
										/>
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Company Activities</th>}
									<td>
										{isMobile && <label className="onboard-label">Company Activities</label>}
										<Select
											className="rs-container-est-white-client"
											classNamePrefix="rs"
											isSearchable={true}
											name="customer"
											options={activities}
											value={activities.find((option) => option.value === data.companyActivites)}
											onChange={(e) => {
												setData({ ...data, companyActivites: e.value });
												const completionPercentage = calculateCompletionPercentage();
											}}
										/>
									</td>
								</tr>
								{['Mobiles'].includes(activities.find((option) => option.value === data.companyActivites)?.label) && (
									<tr>
										{!isMobile && <th>Interests</th>}
										<td>
											{isMobile && <label className="onboard-label">Interests</label>}
											<Select
												className="rs-container-est-white-client"
												classNamePrefix="rs"
												isSearchable={true}
												name="customer"
												options={interests}
												isMulti={true}
												components={customComponents} // Apply the custom components
												value={
													interests
														.filter((interest) => data.interests.some((companyI) => companyI.interest_id === interest.value))
														.map((interest) => ({ value: interest.value, label: interest.label })) // Ensure the structure matches your component's expectation
												}
												onChange={(selectedOptions) => {
													const selectedInterestIds = selectedOptions.map((option) => ({ interest_id: option.value }));
													setData({ ...data, interests: selectedInterestIds });
												}}
											/>
										</td>
									</tr>
								)}
								{['Mobiles'].includes(activities.find((option) => option.value === data.companyActivites)?.label) && (
									<tr>
										{!isMobile && <th>Specification</th>}
										<td>
											{isMobile && <label className="onboard-label">Specification</label>}
											<Select
												className="rs-container-est-white-client"
												classNamePrefix="rs"
												isSearchable={true}
												name="customer"
												options={specifications}
												isMulti={true}
												components={customComponents}
												value={
													specifications
														.filter((spec) => data.specifications.some((companyS) => companyS.specification_id === spec.value))
														.map((spec) => ({ value: spec.value, label: spec.label })) // Ensure the structure matches your component's expectation
												}
												onChange={(selectedOptions) => {
													const selectedSpecIds = selectedOptions.map((option) => ({ specification_id: option.value }));
													setData({ ...data, specifications: selectedSpecIds });
												}}
											/>
										</td>
									</tr>
								)}

								<tr>
									{!isMobile && <th>Annual Turnover</th>}
									<td>
										{isMobile && <label className="onboard-label">Annual Turnover</label>}
										<Select
											className="rs-container-est-white-client"
											classNamePrefix="rs"
											isSearchable={true}
											name="turnOver"
											options={turnOver?.map((item) => ({
												label: item.name,
												value: item.id
											}))}
											value={turnOver
												?.map((item) => ({ label: item.name, value: item.id }))
												?.find((item) => item?.value == data?.companyTurnOver)}
											onChange={(e) => {
												setData({ ...data, companyTurnOver: e.value });
												const completionPercentage = calculateCompletionPercentage();
											}}
											menuPlacement="auto"
											menuPosition="fixed"
										/>
									</td>
								</tr>
							</tbody>
						</table>
						<table className="projectmix-table-vertical-client">
							<tbody>
								<tr>
									{!isMobile && <th></th>}

									<td className="fw-600" style={{ textAlign: isMobile ? 'left' : 'left', fontSize: isMobile ? 16 : 14 }}>
										Contact person
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Sales Contact</th>}
									<td>
										{isMobile && <label className="onboard-label">Sales Contact</label>}
										<input
											type="text"
											className="form-control"
											value={data?.contactPerson}
											onChange={(e) => {
												setData({ ...data, contactPerson: e.target.value });
												const completionPercentage = calculateCompletionPercentage();
											}}
										/>
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Company E-mail</th>}
									<td>
										{isMobile && <label className="onboard-label">Company E-mail</label>}
										<input
											type="text"
											className="form-control"
											value={data?.email}
											onChange={(e) => {
												setData({ ...data, email: e.target.value });
												const completionPercentage = calculateCompletionPercentage();
											}}
										/>
									</td>
								</tr>

								<tr>
									{!isMobile && <th>Phone Nr.</th>}
									<td>
										{isMobile && <label className="onboard-label">Phone Nr.</label>}
										{/* <div className="phn-cmpny-dtls">
												<Select
													className="rs-container-est-white-client slct-phn-cmpny-dtls"
													classNamePrefix="rs"
													isSearchable={true}
													name="customer"
													options={countryCodes}
													style={{ borderRadius: 10 }}
													value={countryCodes?.find((y) => y?.value == data?.phonecode)}
													onChange={(e) => {
														setData({ ...data, phonecode: e.value });
														const completionPercentage = calculateCompletionPercentage();
													}}
													menuPlacement="auto"
													menuPosition="fixed"
												/>
												<input
													type="text"
													value={data?.phone}
													className="form-control"
													onChange={(e) => {
														setData({ ...data, phone: e.target.value });
														const completionPercentage = calculateCompletionPercentage();
													}}
												/>
											</div> */}
										<div className="phn-cmpny-dtls">
											<PhoneInput
												international
												defaultCountry={selectedCountry}
												value={data?.phonecode ? `+${data.phonecode}${data?.phone}` : data?.phone}
												onCountryChange={(country) => {
													setSelectedCountry(country);
												}}
												onChange={(phone) => {
													if (typeof phone === 'string') {
														const phoneNumber = parsePhoneNumberFromString(phone);
														if (phoneNumber) {
															const phoneCode = phoneNumber.countryCallingCode;
															setPhoneNumber(phoneNumber);
															changeSelectedCountry(phoneCode);
														} else {
															setData({ ...data, phone: '', phonecode: '' });
														}
													} else {
														setData({ ...data, phone: '', phonecode: '' });
													}
												}}
												// onBlur={updatePhone}
												style={{ width: '100%' }}
												showCountrySelect
												inputClassName="borderRadiusStyle"
												selectClassName="borderRadiusStyle"
											/>
										</div>
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Whatsapp Nr.</th>}
									<td>
										{isMobile && <label className="onboard-label">Whatsapp Nr.</label>}
										<div className="phn-cmpny-dtls">
											{/* <Select
													className="rs-container-est-white-client slct-phn-cmpny-dtls"
													classNamePrefix="rs"
													isSearchable={true}
													name="customer"
													options={countryCodes}
													value={countryCodes?.find((y) => y?.value == data?.whatsappcode)}
													onChange={(e) => {
														setData({ ...data, whatsappcode: e.value });
														const completionPercentage = calculateCompletionPercentage();
													}}
													menuPlacement="auto"
													menuPosition="fixed"
												/>
												<input
													type="text"
													className="form-control"
													value={data?.whatsapp}
													onChange={(e) => {
														setData({ ...data, whatsapp: e.target.value });
														const completionPercentage = calculateCompletionPercentage();
													}}
												/> */}
											<PhoneInput
												international
												defaultCountry={selectedWhatsappCountry}
												value={data?.whatsappcode ? `+${data.whatsappcode}${data?.whatsapp}` : data?.whatsapp}
												// onChange={(phone) => {
												// 	if (typeof phone === 'string') {
												// 		const whatsapp = parsePhoneNumberFromString(phone);
												// 		if (whatsapp) {
												// 			setWhatsapp(whatsapp);
												// 		} else {
												// 			setData({ ...data, whatsappcode: '', whatsapp: '' });
												// 		}
												// 		setSelectedWhatsappCountry(whatsapp ? whatsapp.country : '');
												// 	} else {
												// 		setData({ ...data, whatsappcode: '', whatsappcode: '' });
												// 	}
												// }}
												// onCountryChange={(country) => {
												// 	setSelectedWhatsappCountry(country);
												// }}
												onCountryChange={(country) => {
													setSelectedWhatsappCountry(country);
												}}
												onChange={(phone) => {
													if (typeof phone === 'string') {
														const whatsapp = parsePhoneNumberFromString(phone);
														if (whatsapp) {
															const phoneCode = whatsapp.countryCallingCode;
															setWhatsapp(whatsapp);
															changeSelectedCountry(phoneCode);
														} else {
															setData({ ...data, whatsappcode: '', whatsapp: '' });
														}
													} else {
														setData({ ...data, whatsappcode: '', whatsapp: '' });
													}
												}}
												// onBlur={updateWhatsapp}
												style={{ width: '100%' }}
												showCountrySelect
											/>
										</div>
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Website</th>}

									<td>
										{isMobile && <label className="onboard-label">Website</label>}
										<input
											type="text"
											className="form-control"
											value={data?.website}
											onChange={(e) => {
												setData({ ...data, website: e.target.value });
												const completionPercentage = calculateCompletionPercentage();
											}}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="action-company-dtls-btn" style={{ marginTop: 10 }}>
					<button
						className="btn btn-cmpny-dtls-action"
						onClick={() => {
							setIsInProgress(true);
							savePhones();
						}}
					>
						Next <MdKeyboardArrowRight />
					</button>
				</div>
			</>
		</div>
	);
};

export default CompanyDetails;
