import React, { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';

function CurrencyValue({ currencyObject, onlyValue }) {
	const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);


	return <>{!onlyValue && `${getCurrencySymbol(selectedCurrency)} `}{currencyObject ? numberFormatter.format(currencyObject[selectedCurrency]) : 'N/A'}</>;
}

export default CurrencyValue;
