import React from 'react';
import { CSSTransition } from 'react-transition-group'; // Import this if it's a separate component
import { Modal, Button } from 'react-bootstrap'; // Import these if they are separate components
import { Fade } from 'react-awesome-reveal';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Link } from 'react-router-dom';

const OpenClientsCard = ({ handleShow, show, handleClose, openClients }) => {
	return (
		<div className="col-lg-3 col-md-6 col-sm-12">
			<Fade>
				<div className="card" style={{ height: '280px', marginBottom: '20px', padding: '10px', paddingTop: '5px' }}>
					<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: '10px' }}>
						<a style={{ fontWeight: '500' }}>Credit Notes</a>
						<a style={{ float: 'right', color: '#0166ff' }} onClick={handleShow}>
							Show More
						</a>
					</div>
					<CSSTransition
						in={show}
						timeout={500}
						classNames={{
							enter: 'sketch-enter',
							enterActive: 'sketch-enter-active',
							exit: 'sketch-exit',
							exitActive: 'sketch-exit-active'
						}}
						unmountOnExit
					>
						<Modal show={show} onHide={handleClose}>
							<Modal.Header closeButton>
								<Modal.Title>Modal heading</Modal.Title>
							</Modal.Header>
							<Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
							<Modal.Footer>
								<Button variant="secondary" onClick={handleClose}>
									Close
								</Button>
								<Button variant="primary" onClick={handleClose}>
									Save Changes
								</Button>
							</Modal.Footer>
						</Modal>
					</CSSTransition>
					<div
						className="card-body custom-no-scroll"
						style={{ paddingTop: 5, paddingBottom: 0, paddingRight: 15, overflowY: 'auto', maxHeight: '200px' }}
					>
						{Object.entries(openClients)
							.sort((a, b) => Number(a[1].total) - Number(b[1].total))
							.map(([key, value], index) => {
								const name = value.customer_name.split(' ').slice(0, 2).join(' ');
								return (
									<Link to={`/sales/invoices/${value.id}`} style={{ color: 'black', textDecoration: 'none' }}>
										<div className="row hover-effect" key={index} style={{ paddingBottom: '4px', paddingTop: '3px' }}>
											<div className="col-6 top-clients">
												<a style={{ whiteSpace: 'nowrap', fontWeight: 100 }}><PrivacyModeContent>{name}</PrivacyModeContent></a>
											</div>
											<div className="col-6 top-clients" style={{ textAlign: 'right' }}>
												<a style={{ fontWeight: 600 }}><PrivacyModeContent>$ {`${Math.floor(Number(value.total)).toLocaleString()}`}</PrivacyModeContent></a>
											</div>
										</div>
									</Link>
								);
							})}
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default OpenClientsCard;
