import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPaperclip, FaSave, FaSpinner, FaPlus } from 'react-icons/fa';
import { CloseIcon, SettingsLabelIcon } from 'ui-component/custom-icons/icons'
import toast from 'react-hot-toast';
import Select from 'react-select';

import { useDropzone } from 'react-dropzone';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

const AwbModal = ({ openPopup, details, handleClose, handleReloadList }) => {

    const [information, setInformation] = useState(null);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isFormProcessing, setIsFormProcessing] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);
    const [hasAttachment, setHasAttachment] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [uploadedFileSize, setUploadedFileSize] = useState('');
    const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
	const [vatValidationAlert, setVatvalidationlert] = useState();

    useEffect(() => {
        initializeData()
    }, []);
    
    const initializeData = () => {
        setInformation({
            ...information,
            id: details?.id,
            shipmentAttachmentId: '',
            referenceNo: '',
        });
        api.getSelectOptionsList('currency').then((res) => {
            const collection = res?.data?.map((item) => ({ id: item.id, label: item.currency, value: item.id }));
            setCurrencyList(collection);
		});
        setIsDataLoaded(true);
    }

    const handleProcessSubmit = () => {
        setIsFormProcessing(true);
        // handleSubmit(information);
        if (information?.referenceNo == null || information?.referenceNo == '') {
            toast.error('Airway bill is required');
            setIsFormProcessing(false);
            return false;
        }
        toast.promise(
            api
                .shipmentCreateAwb(details?.id, information)
                .then((res) => {
                if (res.status) {
                    handleReloadList()
                }

                return res?.status == true ? res : null;
            }),

            {
                loading: 'Creating bank details...',
                success: (data) => `${data.message}`,
                error: <b>Creating bank details Failed</b>
            }
        );
    }

    const handleProcessEnter = (event) => {
        if (event.key === 'Enter') {
            handleProcessSubmit();
        }
    }
    

	const onDrop = useCallback((acceptedFiles) => {
		console.log('onDrop triggered', acceptedFiles);
		// if (validateFileExtensionforLogo(acceptedFiles[0].name)) {
		// 	setData((prevData) => ({ ...prevData, companyLogo: acceptedFiles[0] }));
		// 	setLogo(acceptedFiles[0].name);
		// } else {
			toast.error('Invalid file extension. Please select a JPEG, JPG, PNG, or PDF file.');
		// }
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: '.pdf, .jpeg, .jpg, .png',
		multiple: false
    });
    

    const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png'];
    const validateFileExtension = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };


    const formData = new FormData();
    
    const uploadAttachment = async (attachment) => {
        formData.append('attachment', attachment);
        formData.append('shipment_id', details?.id);
        setUploadedFileName(attachment.name);
        
        
        let sizeStr = "";
        const sizeInKB = attachment.size / 1024;
        if (sizeInKB >= 1024) {
            const sizeInMB = sizeInKB / 1024;
            sizeStr = `${sizeInMB.toFixed(2)} MB`;
        } else {
            sizeStr = `${sizeInKB.toFixed(2)} KB`;
        }

        

        setUploadedFileSize(sizeStr);
        try {
            setHasAttachment(true)
            setIsAttachmentLoading(true)
            // setTimeout(() => {
            //     // setIsAttachmentLoading(false)
 
            // }, 2000);
                try {

                    toast.promise(
                        api
                            .uploadShipmentAttachment(details?.id, formData)
                            .then(
                                (res) => {
                                    if (res.status) {
                                        setIsAttachmentLoading(false)
                                        setInformation({
                                            ...information,
                                            shipmentAttachmentId: res.data.id
                                        });
                                        // initiate();
                                        // setUploadInvoice(false);
                                    }
        
                                    return res?.status ? res : null;
                                },
                                () => {}
                            ),
                        {
                            loading: 'uploading file...',
                            success: (data) => `${data.message}`,
                            error: <b>Failed to upload file</b>
                        }
                    );
                    
                } catch (error) {
                    toast.error('Error uploading file');
                }
            
            
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file');
        }
    };
    
	const onPaymentDrop = useCallback((acceptedFiles) => {
		console.log('onPaymentDrop triggered', acceptedFiles);

        if (validateFileExtension(acceptedFiles[0].name)) {
            uploadAttachment(acceptedFiles[0]);
			setPaymentData((prevData) => ({ ...prevData, attachment: acceptedFiles[0] }));
			setPaymentAttachment(acceptedFiles[0].name);
		} else {
			toast.error('Invalid file extension. Please select a PDF, JPEG, JPG, or PNG file.');
		}
	}, []);
	const { getRootProps: getPaymentRootProps, getInputProps: getPaymentInputProps } = useDropzone({ onDrop: onPaymentDrop });


    return (
        <>
            <Modal show={openPopup}  centered size="md">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center color-theme">
                                        <SettingsLabelIcon height={'60px'} width={'60px'} />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Airway Bill Information
                                    </div>
                                    <div className='textGray'>
                                        Manage shipment details for accurate <br />tracking and smooth delivery.
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    Airway bill code
                                </div>
                                <div className="col-12 form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                        value={information?.bankName}
                                        placeholder='Enter airway bill code'
                                        onChange={(e) => {
                                            setInformation({
                                                ...information,
                                                referenceNo: e.target.value
                                            })
                                        }}
                                        onKeyDown={(e) => {
                                            handleProcessEnter(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top" style={{ padding: '3px 0' }}>
                                <div className="col-12">
                                    
                                    <div>
                                        {
                                            hasAttachment ? (
                                                <>
                                                     <div className="payment-modal-attachment-container">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div className="col-12" style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div className="payment-modal-attachment-icon">
                                                                            <FaPaperclip />
                                                                        </div>
                                                                        <div className="payment-modal-attachment-name">
                                                                            <div>{uploadedFileName}</div>
                                                                            <div>{uploadedFileSize}</div>
                                                                            
                                                                        </div>
                                                                        <div className="payment-modal-attachment-delete">
                                                                            <svg id="Component_127_1" height="12" width="12" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                                                                                <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        isAttachmentLoading && (
                                                                            <div className="col-12">
                                                                                <div className="animated-progress-bar"></div>
                                                                            </div>

                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div {...getPaymentRootProps()}>
            
                                                        <div
                                                            className="add-item-empty-div"
                                                        >
                                                            <button type="button" className="btn btn-primary btn-outlined-main-primary dragText" >
                                                                <FaPlus />
                                                                Add file here
                                                            </button>
                                                        </div>
                                                        <input {...getPaymentInputProps({ disabled: vatValidationAlert !== true })} style={{ display: 'none' }} />{' '}
                                                    </div>
                                                </>
                                            )
                                        }
                                       
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-top mt10" style={{ padding: '3px 0' }}>
                                <div className="col-12 d-flex justify-content-right" style={{ gap: '10px' }}>
                                    <button
                                        type="button"
                                        className="btn-danger btn-outlined-danger  white-var"
                                        onClick={() => {
                                            handleClose()
                                        }}
                                        style={{ lineHeight: '25px' }}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn-primary btn-outlined-main-primary  white-var"
                                        disabled={!isDataLoaded || isFormProcessing}
                                        onClick={() => {
                                            if (!isFormProcessing) {
                                                handleProcessSubmit()
                                            }
                                        }}
                                        style={{ lineHeight: '25px' }}
                                    >
                                        {
                                            isFormProcessing ? (
                                                <>
                                                    <FaSpinner className="fa-spin" /> Saving...
                                                </>
                                            ) : (
                                                <>
                                                    <FaSave /> Save
                                                </>
                                            )
                                        }
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AwbModal;
