import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Scroller from 'ui-component/Scroller';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { Skeleton, Tooltip } from '@mui/material';
import { AiOutlineDelete } from 'react-icons/ai';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import toast from 'react-hot-toast';
import { FaPlus, FaSave, FaPencilAlt } from 'react-icons/fa';
import { MdOutlineModeEdit } from "react-icons/md";
import Select from 'react-select';
import { HiOutlineRefresh } from 'react-icons/hi';
import DetailsTab from './components/DetailsTab';
import OrderHistoryTab from './components/OrderHistoryTab';
import usePageTitle from 'hooks/usePageTitle';
import NoImage from 'assets/images/no-image.png';



const SerialsDetails = () => {
	const [setPageTitle] = usePageTitle('Serials Details');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const { id } = useParams();
	const [activeComponent, setActiveComponent] = useState('Details');
	const selectedUser = useSelector((state) => state.main.user.selectedUser);
	const [isDisable, setDisable] = useState(false);
	const [openWarehousePopup, setOpenWarehousePopup] = useState(false);
	const dispatch = useDispatch();
	const history = useNavigate();
	const [serialDetails, setSerialDetails] = useState();
	const [repairLists, setRepairLists] = useState();
	const [backorders, setBackorders] = useState();
	const [defaultThumbnail, setDefaultThumbnail] = useState('');
	const [isEdit, setisEdit] = useState(false);
	const [isChecked, setisChecked] = useState(null);
	const [associateproduct, setAssociateproduct] = useState([]);
	const [isLoading, setIsloading] = useState(false);
	const [disableStatus, setDisablestatus] = useState(false);
	const [imageId, setImageId] = useState(null);
	const [files, setFiles] = useState([]);

	const [show, setShow] = useState('');


	const getSerialsDetails = () => {
		setIsloading(true);
		api.getSerialsDetails(id).then((res) => {
			setIsloading(false);
			setSerialDetails(res.data?.stock);

			res?.data?.stock?.product?.thumbnails?.map((thumbnail) => {
				if (thumbnail?.is_default == 1) {
					setDefaultThumbnail(thumbnail?.thumbnail_url);
				}

				// res?.data?.thumbnails?.map((thumbnail) => {
				// 	if (thumbnail?.is_default == 1) {
				// 		setDefaultThumbnail(thumbnail?.thumbnail_url);
				// 		// } else {
				// 		// 	setDefaultThumbnail('https://t3.ftcdn.net/jpg/05/88/70/78/360_F_588707867_pjpsqF5zUNMV1I2g8a3tQAYqinAxFkQp.jpg');
				// 	}
				// });
			});
			setRepairLists(res.data?.repairs);
		});
	};

	const handleComponentChange = (component) => {
		setActiveComponent(component);
	};

	const openModalFormWarehouse = () => {
		setOpenWarehousePopup(true);
	}



	useEffect(() => {
		getSerialsDetails();
	}, []);

	return (
		<>

			<div className="row">
				<div className="col-12">
					<div className="row pb10">
						<div className="col-12">
							<div className="section-head">
								<h2
									style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}
								>
									{serialDetails?.imei}
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="content pad15">
							<div className="row m-0 p-0 mb-3">
								<div className="col-md-2 col-sm-3 col-12">
									{
										defaultThumbnail ? (
											<img src={defaultThumbnail} className='product-thumbnail' alt="product" />
										) : (
											<img src={NoImage} alt="product" style={{ width: '70px', height: '100px' }} />
										)
									}
								</div>
								<div className="col-md-8 col-sm-9 col-12 product-header-summary">
									<div className="row">
										<div className="col-12">


											<div className="row form-group">
												<div className="col-12">
													<div className="form-section">
														<div className="">
															<h4 style={{ fontSize: '18px !important' }}><b>{serialDetails?.product_name}</b></h4>
														</div>
													</div>
													<div className="row">
														<div className="col-12">

															<div className="row form-group product-preview-stock-labels">
																<div className="col-12">
																	<div className="row">
																		<div className="col-md-12">
																			<div className="product-section-head " style={{ paddingBottom: '0px' }}>
																				<span style={{ fontSize: '18px' }}>{serialDetails?.product?.name}</span>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-md-12">

																			<div
																				className={`badge2 badge-light-gray2`}
																				style={{ fontWeight: '500', color: '#000' }}
																			>
																				{serialDetails?.imei}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>


										</div>
									</div>


								</div>
							</div>



							<div className="row mt20">
								<div className="col-12">
									<ul className="topListCom">
										<li
											className={`${activeComponent === 'Details' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('Details');
											}}
										>
											Details
										</li>
										{/* <li
											className={`${activeComponent === 'History' ? 'active font14' : 'font14'} ${isLoading && 'not-allowed-pointer'}`}
											style={{ fontWeight: '500' }}
											onClick={() => {
												!isLoading && handleComponentChange('History');
											}}
										>
											History 
										</li> */}
									</ul>

									<hr style={{ marginTop: '-1px' }} />
								</div>
							</div>

							{activeComponent === 'Details' ? (
								<>
									<DetailsTab
										serialDetails={serialDetails}
										repairLists={repairLists}
									/>
								</>
							) : activeComponent == 'History' ? (
								<>
									<OrderHistoryTab
										serialDetails={serialDetails}
									// backorders={backorders}
									/>
								</>
							) : (
								<></>
							)}


						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SerialsDetails;
