import React from 'react';
import { Link } from 'react-router-dom';

const NotificationTextParser = ({ inputString, LinkComponent = Link }) => {
	const parseString = (str) => {
		const blockRegex = /<(\w+)([^>]*)>(.*?)<\/\1>/g;

		let match;
		let lastIndex = 0;
		const elements = [];

		while ((match = blockRegex.exec(str)) !== null) {
			const [fullMatch, tagName, attributes, innerText] = match;

			const parsedAttributes = {};
			const attributeRegex = /(\w+)=['"]([^'"]*)['"]/g;
			let attributeMatch;

			while ((attributeMatch = attributeRegex.exec(attributes)) !== null) {
				const [, attributeName, attributeValue] = attributeMatch;
				parsedAttributes[attributeName] = attributeValue;
			}

			let to = '#';

			switch (tagName?.toLowerCase()) {
				case 'estimation':
					to = `/sales/invoices/${parsedAttributes.value}`;
					break;
				case 'quotation':
					to = `/sales/estimates/${parsedAttributes.value}`;
					break;
				case 'contact':
					to = `/contacts/${parsedAttributes.value}`;
					break;
				case 'user':
					to = `/user/${parsedAttributes.value}`;
					break;
				case 'inspection':
					to = `/operations/inspection/${parsedAttributes.value}`;
					break;
				case 'collection':
					to = `/operations/collection/${parsedAttributes.value}`;
					break;
				case 'segregation':
					to = `/operations/segregation/${parsedAttributes.value}`;
					break;
				case 'testing':
					to = `/operations/testing/${parsedAttributes.value}`;
					break;
				case 'inbounding':
					to = `/operations/inbounding/${parsedAttributes.value}`;
					break;
				case 'quality-control':
					to = `/operations/quality-control/${parsedAttributes.value}`;
					break;
				case 'repairing':
					to = `/operations/repairing/${parsedAttributes.value}`;
					break;
				case 'retesting':
					to = `/operations/retesting/${parsedAttributes.value}`;
					break;
				case 'packing':
					to = `/operations/packing/${parsedAttributes.value}`;
					break;
				case 'shipments':
					to = `/shipments/${parsedAttributes.value}`;
					break;
				case 'stock':
					to = `/inventory/view/${parsedAttributes.value}`;
					break;
				default:
					break;
			}

			elements.push(str.slice(lastIndex, match.index));

			const CustomLinkComponent = React.createElement(LinkComponent, { to }, innerText);
			elements.push(CustomLinkComponent);

			lastIndex = match.index + fullMatch.length;
		}

		// Add the remaining text after the last match
		elements.push(str.slice(lastIndex));

		return elements;
	};

	const parsedElements = parseString(inputString);

	return <div>{parsedElements}</div>;
};

export default NotificationTextParser;
