import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import usePermissions from 'hooks/usePermissions';
import moment from 'moment';
import Scroller from 'ui-component/Scroller';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { TbDatabaseExclamation } from 'react-icons/tb';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const BackordersTab = ({ productDetails }) => {

	const history = useNavigate();
	const [backorders, setBackorders] = useState();
	const [allPermissions, hasEveryPermission] = usePermissions();

	const getProductBackorders = (id) => {
		api.getProductBackorders(id).then((res) => {
			if (res.status) {
				setBackorders(res?.data)
			}
		})
	};



	useEffect(() => {
		getProductBackorders(productDetails?.id);
	}, []);
	return (
		<>
			<div className="content mt15">
				<div className="row">
					<div className="col-12 ">
						{backorders?.length > 0 ? (
							<>
								<div className="table-responsive-xl">
									<Scroller>
										<table className="table">
											<thead>
												<tr className="sticky-header ">
													<th scope="col" className="font14" width={'150'}>Invoice Number</th>
													<th scope="col" className="font14" width="">Customer</th>
													<th scope="col" style={{ width: '100px' }} className="font14">Available</th>
													<th scope="col" style={{ width: '200px' }}>Expected</th>
													<th scope="col" style={{ width: '100px' }}>Ordered At</th>
												</tr>
											</thead>
											<tbody>
												{backorders?.map((row, index) => {
													return (
														<tr key={index} style={{ height: '52px' }}>
															<td className="align-middle font14">{row?.estimation?.invoice_number}</td>
															<td className="align-middle font14">
																<PrivacyModeContent>{row?.customer?.name}</PrivacyModeContent>
															</td>
															<td className="text-danger font14">0 / {row?.quantity}</td>
															<td className="font14">
																Not yet purchased
															</td>
															<td className="font14">
																{moment(row?.date_created).format(CommonDateOnlyFormat)}
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</Scroller>
								</div>
							</>
						) : (
							<>
								<div className="not-found-container">
									<div className="not-found-icon">
										<TbDatabaseExclamation />
									</div>
									<h6 className="not-found-text">No Data Available</h6>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default BackordersTab;
