import React, { useEffect, useState, useCallback } from 'react';
import { CompanyDetailsProgresses } from 'assets/helperdata/client/company-details-progress';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import { FaCircleCheck } from 'react-icons/fa6';

import { useDropzone } from 'react-dropzone';

const CompanyInfo = () => {
	const history = useNavigate();
	const [isInProgress, setIsInProgress] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [turnOver, setTurnOver] = useState([]);
	const [activities, setActivities] = useState([]);
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [companyData, setCompanyData] = useState(null);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const clientUser = useSelector((state) => state.main.client);
	const [isUploadLogo, setIsUploadLogo] = useState(false);
	const [logoBlobUrl, setLogoBlobUrl] = useState('');
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [data, setData] = useState({
		companyName: clientUser?.data?.company_name,
		establishmentYear: '',
		companyActivites: '',
		companyTurnOver: '',
		id: clientUser?.data?.id
	});
	const currentYear = new Date().getFullYear();
	const establishmentYearOptions = [];

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 768);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		api.getTurnOvers().then((res) => {
			if (res.data) {
				setSelectedOption(res.data[0].id);
				setData({ ...data, companyTurnOver: res.data[0].id });
				setTurnOver(res.data);
			}
		});
		api.company_activities().then((res) => {
			if (res.data) {
				setActivities(res.data);
			}
		});
		if (clientUser?.data?.id) {
			api.getClientInformation(clientUser?.data?.id).then((res) => {
				setCompanyData(res.data);
			});
		}
	}, []);

	console.log(companyData);
	console.log(clientUser);
	for (let year = currentYear; year >= currentYear - 100; year--) {
		establishmentYearOptions.push({
			value: year.toString(),
			label: year.toString()
		});
	}

	function handleInputChange(field, value) {
		setIsFormChanged(true);
		setCompanyData((prevState) => ({
			...prevState,
			client: {
				...prevState.client,
				[field]: value
			}
		}));
	}

	function handleInputChangeInfo(field, value) {
		setIsFormChanged(true);
		setCompanyData((prevState) => ({
			...prevState,
			companyInfo: {
				...prevState.companyInfo,
				[field]: value
			}
		}));
	}
	function handleInputChangeAddress(field, value) {
		console.log(field);
		if (field == 'email') {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(companyData?.companyAddress.email)) {
				console.error('Invalid email format');
				setIsEmailValid(false);
			} else {
				setIsEmailValid(true);
			}
		}
		setIsFormChanged(true);
		setCompanyData((prevState) => ({
			...prevState,
			companyAddress: {
				...prevState.companyAddress,
				[field]: value
			}
		}));
	}
	console.log(isEmailValid);
	function handleInputChangeDocs(field, value) {
		setIsFormChanged(true);
		setCompanyData((prevState) => ({
			...prevState,
			companyDoc: {
				...prevState.companyDoc,
				[field]: value
			}
		}));
	}
	const SaveCompanydetails = () => {
		const formData = new FormData();

		// Append all the properties of companyData to formData
		for (const key in companyData) {
			if (companyData[key] instanceof File) {
				formData.append(key, companyData[key], companyData[key].name);
			} else if (typeof companyData[key] === 'object' && companyData[key] !== null) {
				for (const subKey in companyData[key]) {
					formData.append(`${key}[${subKey}]`, companyData[key][subKey]);
				}
			} else {
				formData.append(key, companyData[key]);
			}
		}

		api.updateClientGeneralInformation(formData).then((res) => {
			if (res.status === true) {
				toast.success(res.message);
				setIsFormChanged(false);
			} else {
				toast.error(res.message);
			}
		});
	};

	const onLogoDrop = useCallback((acceptedFiles) => {
		console.log('onLogoDrop triggered', acceptedFiles);

		if (validateFileExtension(acceptedFiles[0].name)) {
			const reader = new FileReader();

			reader.onloadend = () => {
				const base64data = reader.result.split(',')[1];

				setCompanyData((prevData) => ({
					...prevData,
					companyLogo: base64data,
					originalFileName: acceptedFiles[0].name
				}));
				setLogoBlobUrl(URL.createObjectURL(acceptedFiles[0]));
				setIsUploadLogo(true);
				setIsFormChanged(true);
			};

			reader.readAsDataURL(acceptedFiles[0]);
		} else {
			toast.error('Invalid file extension. Please select a PNG, JPEG, JPG, or SVG file.');
		}
	}, []);

	const validateFileExtension = (fileName) => {
		const allowedExtensions = ['png', 'jpeg', 'jpg', 'svg'];
		const fileExtension = fileName.split('.').pop().toLowerCase();
		return allowedExtensions.includes(fileExtension);
	};
	const turnoverName = turnOver.find((item) => item.id === companyData?.companyInfo?.turnover_id)?.name;
	const companyActivityName = activities.find((item) => item.id === companyData?.companyInfo?.company_activity_id)?.name;

	const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({ onDrop: onLogoDrop });

	return (
		<>
			{isMobile && (
				<div className="approved-mobile">
					<div className="verified-mobile">
						<span className="menuIcon">
							<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
								<defs>
									<clipPath id="clip-path">
										<rect
											id="Rectangle_138"
											data-name="Rectangle 138"
											width="22"
											height="22"
											transform="translate(22 557)"
											stroke="#707070"
											stroke-width="1"
										/>
									</clipPath>
								</defs>
								<g id="Mask_Group_5" data-name="Mask Group 5" transform="translate(-22 -557)" clip-path="url(#clip-path)">
									<g id="settings" transform="translate(23.102 557)">
										<path
											id="Path_202"
											data-name="Path 202"
											d="M10.666,7a3.666,3.666,0,1,0,3.666,3.666A3.666,3.666,0,0,0,10.666,7Zm0,5.5A1.833,1.833,0,1,1,12.5,10.666,1.833,1.833,0,0,1,10.666,12.5Z"
											transform="translate(-0.771 0.332)"
											fill="currentColor"
										/>
										<path
											id="Path_203"
											data-name="Path 203"
											d="M19.465,12.739l-.407-.235a8.34,8.34,0,0,0,0-3.015l.407-.235a2.751,2.751,0,1,0-2.749-4.766l-.408.236A8.227,8.227,0,0,0,13.7,3.22v-.47a2.749,2.749,0,1,0-5.5,0v.47a8.227,8.227,0,0,0-2.61,1.508l-.41-.237A2.751,2.751,0,1,0,2.429,9.256l.407.235a8.34,8.34,0,0,0,0,3.015l-.407.235a2.751,2.751,0,0,0,2.749,4.766l.408-.236a8.227,8.227,0,0,0,2.612,1.5v.47a2.749,2.749,0,1,0,5.5,0v-.47a8.227,8.227,0,0,0,2.61-1.509l.41.236a2.751,2.751,0,0,0,2.749-4.766ZM17.13,9.278a6.459,6.459,0,0,1,0,3.437.916.916,0,0,0,.425,1.038l.993.574a.917.917,0,1,1-.916,1.588l-1-.576a.916.916,0,0,0-1.114.151,6.4,6.4,0,0,1-2.972,1.718.916.916,0,0,0-.688.888v1.148a.916.916,0,1,1-1.833,0V18.1a.916.916,0,0,0-.688-.888,6.4,6.4,0,0,1-2.971-1.722.916.916,0,0,0-1.114-.151l-.993.575a.916.916,0,1,1-.916-1.587l.993-.574a.916.916,0,0,0,.425-1.038,6.459,6.459,0,0,1,0-3.437A.916.916,0,0,0,4.34,8.241l-.993-.574a.917.917,0,1,1,.916-1.588l1,.576a.916.916,0,0,0,1.113-.148A6.4,6.4,0,0,1,9.343,4.789a.916.916,0,0,0,.688-.892V2.749a.916.916,0,1,1,1.833,0V3.9a.916.916,0,0,0,.688.888,6.4,6.4,0,0,1,2.972,1.722.916.916,0,0,0,1.114.151l.993-.575a.916.916,0,1,1,.916,1.587l-.993.574a.916.916,0,0,0-.424,1.035Z"
											transform="translate(-1.052 0)"
											fill="currentColor"
										/>
									</g>
								</g>
							</svg>
						</span>
						<span style={{ marginLeft: 5, fontSize: 16 }}>Settings</span>
					</div>
				</div>
			)}
			<div className="main-page-client">
				<div className="order-client-content">
					<div className="heading-order-client-tbl" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h3>Company Info</h3>
						<h3 style={{ color: '#36c880' }}>
							Submitted <FaCircleCheck />
						</h3>
					</div>
					<div className={`${isMobile ? 'settings-detail-mobile' : ''}`}>
						<div className="row" style={{ marginTop: 15, marginBottom: 30 }}>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>First Name</label>
								<input
									type="text"
									className="form-control"
									value={companyData?.client?.first_name}
									onChange={(e) => handleInputChange('first_name', e.target.value)}
									disabled
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Last Name</label>
								<input
									type="text"
									className="form-control"
									value={companyData?.client?.last_name}
									onChange={(e) => handleInputChange('last_name', e.target.value)}
									disabled
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Phone Number</label>
								<div className="phn-cmpny-dtls">
									<input
										type="text"
										className="form-control"
										value={companyData?.client?.number}
										onChange={(e) => handleInputChange('number', e.target.value)}
										disabled
									/>
								</div>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Company Name</label>
								<input
									type="text"
									className="form-control"
									value={companyData?.client?.company_name}
									onChange={(e) => handleInputChange('company_name', e.target.value)}
									disabled
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Establishment Year</label>
								{/* <Select
								className="rs-container-est-white"
								classNamePrefix="rs"
								isSearchable={true}
								name="customer"
								disabled={true}
								value={establishmentYearOptions
									.map((y) => ({ label: y.label, value: y.value }))
									.find((y) => y?.value == companyData?.companyInfo?.establishment_year)}
								options={establishmentYearOptions}
								// onChange={(e) => {
								// 	setData({...data,establishmentYear: e.value});
								// }}
								onChange={(selectedOption) => {
									handleInputChangeInfo('establishment_year', selectedOption.value);
								}}
							/> */}
								<input
									type="text"
									className="form-control"
									value={companyData?.companyInfo?.establishment_year}
									onChange={(e) => handleInputChangeAddress('email', e.target.value)}
									disabled
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Activities</label>
								{/* <Select
								className="rs-container-est-white"
								classNamePrefix="rs"
								isSearchable={true}
								name="customer"
								disabled={true}
								options={activities?.map((y) => ({
									label: y.name,
									value: y.id
								}))}
								value={activities
									?.map((y) => ({ label: y.name, value: y.id }))
									?.find((y) => y?.value == companyData?.companyInfo?.company_activity_id)}
								// onChange={(e) => {
								// 	setData({...data,companyActivites: e.value});
								// }}
								onChange={(selectedOption) => {
									handleInputChangeInfo('company_activity_id', selectedOption.value);
								}}
							/> */}
								<input
									type="text"
									className="form-control"
									value={companyActivityName}
									onChange={(e) => handleInputChangeAddress('email', e.target.value)}
									disabled
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Annual Turnover</label>
								{/* <Select
								className="rs-container-est-white"
								classNamePrefix="rs"
								isSearchable={true}
								name="customer"
								disabled={true}
								options={turnOver?.map((y) => ({
									label: y.name,
									value: y.id
								}))}
								value={turnOver
									?.map((y) => ({ label: y.name, value: y.id }))
									?.find((y) => y?.value == companyData?.companyInfo?.turnover_id)}
								// onChange={(e) => {
								// 	setData({...data,companyActivites: e.value});
								// }}
								onChange={(selectedOption) => {
									handleInputChangeInfo('turnover_id', selectedOption.value);
								}}
								styles={{
									control: (base, state) => ({
										...base,
										backgroundColor: state.isDisabled ? '#eee' : base.backgroundColor,
										cursor: state.isDisabled ? 'not-allowed' : base.cursor
									})
								}}
							/> */}
								<input
									type="text"
									className="form-control"
									value={turnoverName}
									onChange={(e) => handleInputChangeAddress('email', e.target.value)}
									disabled
								/>
							</div>
						</div>
						<div className="heading-order-client-tbl">
							<h3>Contacts</h3>
						</div>
						<div className="row" style={{ marginTop: 15, marginBottom: 30 }}>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Sales Contact</label>
								<input
									type="text"
									className="form-control"
									value={companyData?.companyContact?.contact_person}
									onChange={(e) => handleInputChangeAddress('contact_person', e.target.value)}
									disabled
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Company Email</label>
								<input
									type="email"
									className="form-control"
									value={companyData?.companyContact?.email}
									onChange={(e) => handleInputChangeAddress('email', e.target.value)}
									disabled
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Website</label>
								<input
									type="text"
									className="form-control"
									value={companyData?.companyContact?.website}
									onChange={(e) => handleInputChangeAddress('website', e.target.value)}
									disabled
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Phone Number</label>
								<input
									type="text"
									className="form-control"
									value={companyData?.companyContact?.phone_code + companyData?.companyContact?.phone_number}
									onChange={(e) => handleInputChangeAddress('phone_number', e.target.value)}
									disabled
								/>
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>WhatsApp Number</label>
								<input
									type="text"
									className="form-control"
									value={companyData?.companyContact?.whatsapp_code + companyData?.companyContact?.whatsapp}
									onChange={(e) => handleInputChangeAddress('whatsapp', e.target.value)}
									disabled
								/>
							</div>
						</div>
						<div className="heading-order-client-tbl">
							<h3>Documents</h3>
						</div>
						<div className="row" style={{ marginTop: 15, marginBottom: 30 }}>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>VAT code</label>
								<input type="text" className="form-control" value={companyData?.companyDoc?.vat_code} disabled />
							</div>
							<div className="col-lg-4">
								<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Registration Number</label>
								<input type="text" className="form-control" disabled value={companyData?.companyDoc?.registration_number} />
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<a>This information cannot be edited. If there are any updates you would like to request, kindly contact your provider.</a>
							</div>
						</div>
					</div>
					{/* <div className="heading-order-client-tbl">
						<h3>Company Logo</h3>
					</div>
					<div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
						<div className="col-4">
							<div {...getLogoRootProps()} className={`updateLogo `}>
								<input {...getLogoInputProps()} />
								Drag and drop files here
							</div>
							<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Upload Logo</label>
						</div>
						<div className="col-8">
							{!isUploadLogo ? (
								<img
									src={`http://127.0.0.1:8000/${companyData?.companyDoc?.company_logo}`}
									style={{
										width: '100%',
										height: '100px',
										objectFit: 'contain'
									}}
								/>
							) : (
								<img
									src={logoBlobUrl}
									style={{
										width: '100%',
										height: '100px',
										objectFit: 'contain'
									}}
								/>
							)}
							<label style={{ marginLeft: 2, marginTop: 5, color: '#888888' }}>Preview Logo: {`${logoBlobUrl}`.split('/').pop()}</label>
						</div>
					</div> */}
					{/* <div className="heading-order-client-tbl">
						<h3>Uploaded Files</h3>
					</div>
					<div className="row" style={{ marginTop: 15, marginBottom: 30 }}>
						<div className="col-12">
							<div className="uploaded-files">
								<a
									href={`http://127.0.0.1:8000/${companyData?.companyDoc?.vat_doc}`}
									target="_blank"
									rel="noopener noreferrer"
								>{`${companyData?.companyDoc?.vat_doc.split('/').pop()}`}</a>
							</div>
						</div>
						<div className="col-12">
							<div className="uploaded-files">
								<a href={`http://127.0.0.1:8000/${companyData?.companyDoc?.registration_doc}`} target="_blank" rel="noopener noreferrer">
									{`${companyData?.companyDoc?.registration_doc.split('/').pop()}`}
								</a>
							</div>
						</div>
					</div> */}
					{/* <div className="row">
						<div className="col-12 d-flex justify-content-end">
							<button
								className="btn btn-primary-main btn-confirm-agreement"
								disabled={!isFormChanged || !isEmailValid}
								onClick={() => SaveCompanydetails()}
							>
								Update
							</button>
						</div>
					</div> */}
				</div>
			</div>
		</>
	);
};

export default CompanyInfo;
