import { useState } from 'react'
import { Link } from 'react-router-dom'

const ScrollButton = () => {
	const [visible, setVisible] = useState(false)

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop
		if (scrolled > 100) {
			setVisible(true)
		} else if (scrolled <= 100) {
			setVisible(false)
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	window.addEventListener('scroll', toggleVisible)

	return (
		<Link className="scroll-to-top rounded" to="#page-top"
			style={{ display: visible ? 'inline' : 'none' }}
			onClick={scrollToTop}>
			<i className="fas fa-angle-up" />
		</Link>
	)
}

export default ScrollButton
