import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { CloseIcon, RepairIcon2 } from 'ui-component/custom-icons/icons'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { ConstructionOutlined } from '@mui/icons-material';

const RepairModal = ({ openPopup, selectedItem, index, handleClose, handleAddRepairs }) => {

    const currencies = [
        { label: 'USD($)', value: 'usd' },
        { label: 'EUR(€)', value: 'eur' },
        { label: 'AED(AED)', value: 'aed' }
    ];

    const [defaultCurrency, setDefaultCurrency] = useState(currencies?.[0]?.label);
    
    const [selectedRepairType, setSelectedRepairType] = useState(null);
    const [selectedAssginedPerson, setSelectedAssignedPerson] = useState(null);
    const [selectedInboundedItem, setSelectedInboundedItem] = useState({
        'id': selectedItem?.id,
        'index': index,
        'colorId': selectedItem?.colorId,
        'colorName': selectedItem?.colorName,
        'productId': selectedItem?.productId,
        'productName': selectedItem?.productName,
        'quantity': selectedItem?.quantity,
        'repair': null
    });
    const [selectedRepairs, setSelectedRepairs] = useState({
        'operationItemId': selectedItem?.id,
        'repairTypeId': '',
        'repairName': '',
        'repairCurrency': currencies?.[0]?.value,
        'repairCost': '',
        'assignedId': '',
        'assignedName': '',
    });
    const [repairTypes, setRepairTypes] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        api.getSelectOptionsList('standard_repair_types').then((res) => {
            if (res?.data) {
                const repairs = res?.data?.map((item) => { 
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                setRepairTypes(repairs);
            }
        });
        api.getSelectOptionsList('user').then((res) => {
            if (res?.data) {
                const users = res?.data?.map((item) => { 
                    item.label = `${item.first_name} ${item.last_name}`;
                    item.value = item.id;
                    return item;
                });
                setUsers(users);
            }
        });
    }, []);
    
    const handleChangeRepair = (e) => {
        setSelectedRepairs({
            ...selectedRepairs,
            'repairTypeId': e?.id,
            'repairName': e?.name
        })
        setSelectedRepairType(e);
    }
    const handleChangeCurrency = (currency) => {
        setSelectedRepairs({
            ...selectedRepairs,
            repairCurrency: currency.value
        })
        setDefaultCurrency(currency.label);
    }

    const handleChangeCost = (e) => {
        const cost = parseFloat(e.target.value);

        if (!isNaN(cost)) {
            setSelectedRepairs({
                ...selectedRepairs,
                repairCost: cost
            })
        } else {
            setSelectedRepairs({
                ...selectedRepairs,
                repairCost: parseFloat('0')
            })
        }
    };

    const handleChangeAssignedPerson = (e) => {
        setSelectedRepairs({
            ...selectedRepairs,
            'assignedId': e?.id,
            'assignedName': e?.label,
        })
        setSelectedAssignedPerson(e)
    }
    
    const submitForm = (type) => {

        if (selectedRepairs?.repairTypeId == '') {
            toast.error('Please select a repair type');
            return;
        }
        if (selectedRepairs?.repairCost === undefined ) {
            toast.error('Please enter the repair cost');
            return;
        }
        if (selectedRepairs?.assignedId == '') {
            toast.error('Please select an assigned person');
            return;
        }
        if (type == 'save') {
            handleAddRepairs(selectedRepairs);
            handleClose();
        } else {
            handleAddRepairs(selectedRepairs);
            
            setSelectedRepairType(null);
            setSelectedAssignedPerson(null);
            setSelectedRepairs({
                'operationItemId': selectedItem?.id,
                'repairTypeId': '',
                'repairName': '',
                'repairCurrency': currencies?.[0]?.value,
                'repairCost': '',
                'assignedId': '',
                'assignedName': '',
            })
        }
    }
    

    return (
        <>
            <Modal show={openPopup}  centered size="md">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center">
                                        <RepairIcon2 height={'80px'} width={'80px'} />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>

                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Add Repairs
                                    </div>
                                    <div>
                                        Select the repairs you would like to add to this <br />{selectedItem?.productName} {selectedItem?.colorName} {' '}
                                        <div className="text-primary">IMEI: {selectedItem?.imei}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0' }}>
                                    <label>Repairs Needed</label>
                                    <Select
                                        options={repairTypes}
                                        className="rs-container"
                                        classNamePrefix="rs"
                                        placeholder="Select or type..."
                                        isMulti={false}
                                        onChange={(e) => {
                                            handleChangeRepair(e)
                                        }}
                                        value={selectedRepairType}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0 0 0' }}>
                                    <label>Repair Cost</label>
                                    <div className="custom-input-group">

                                        <InputGroup className="mb-3 d-flex">
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title={defaultCurrency}
                                                id="input-group-dropdown-1"
                                            >
                                                {
                                                    currencies.map((currency) => { 
                                                        return (
                                                            <Dropdown.Item
                                                                href="#"
                                                                key={currency.value}
                                                                onClick={() => { 
                                                                    handleChangeCurrency(currency)
                                                                }}
                                                            >
                                                                {currency.label}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </DropdownButton>
                                            <input
                                                type="text"
                                                id="input-example"
                                                name="input-name"
                                                className="form-control"
                                                placeholder="0.00"
                                                onChange={(e) => {
                                                    handleChangeCost(e)
                                                }}
                                                value={selectedRepairs?.repairCost}
                                                autoComplete={'off'}
                                                autoFocus
                                            />
                                        </InputGroup>
                                    </div>

                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0' }}>
                                    <label>Assign to</label>
                                    <Select
                                        options={users}
                                        className="rs-container"
                                        placeholder="Select or type..."
                                        classNamePrefix="rs"
                                        isMulti={false}
                                        isClearable={false}
                                        onChange={(e) => {
                                            handleChangeAssignedPerson(e)
                                            
                                        }}
                                        value={selectedAssginedPerson}
                                    />
                                </div>
                            </div>
                            <div className="btnarea" style={{ justifyContent: 'center', marginTop: '20px' }}>

                                    <div className="row">
                                        <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                            <button
                                                className="btn btn-main-primary btn-outlined-main"
                                                style={{ marginRight: '8px' }}
                                                disabled={!selectedRepairs}
                                                onClick={() => {
                                                    submitForm('save');
                                                }}
                                            >
                                                Save 
                                            </button>
                                            <button
                                                className="btn btn-main-primary btn-outlined-main"
                                                // style={{ marginRight: '4px' }}
                                                disabled={!selectedRepairs}
                                                onClick={() => {
                                                    submitForm('add');
                                                }}
                                            >
                                                Save and Add 
                                            </button>
                                            {/* <button onClick={handleClose} className="btn btn-main-primary btn-outlined-main" style={{ marginLeft: '4px' }}>
                                                Save and Add
                                            </button> */}
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RepairModal;
