import { setIn } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import { FaSave, FaTimes } from 'react-icons/fa';
import { FaCheck, FaPen, FaPlus } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';

import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import index from '..';

const ProductsPopupMobile = ({ onClose, onSubmit, currency = 'eur', preLoadedData, orderType, setOrderType, customer, transitCompany }) => {
	const compRef = useRef(null);

	// ## To close the popup ## //
	const handleClose = () => {
		onClose && onClose();
	};

	// // ## To close the popup when clicked outside ## //
	// const handleClickOutside = (event) => {
	// 	if (compRef.current && !compRef.current.contains(event.target)) {
	// 		handleClose();
	// 	}
	// };

	// useEffect(() => {
	// 	document.addEventListener('click', handleClickOutside, true);
	// 	return () => {
	// 		document.removeEventListener('click', handleClickOutside, true);
	// 	};
	// }, []);

	// ## Dropdown Data ## //
	const [models, setModels] = useState([]);
	const [invoiceList, setInvoiceList] = useState([]);
	const vatTypes = [{ label: 'Including VAT', value: 1, percentage: 0 }];

	const getSelectOptions = () => {
		// Fetch Models
		api.getSelectOptionsList('product').then((res) => {
			const tempList = res.data.map((item) => ({
				...item,
				label: `${item.product_name}`,
				value: item.id
			}));
			setModels(tempList);
		});

		api.getSelectOptionsList('invoice').then((res) => {
			const tempList = res.data.map((item) => ({
				...item,
				label: item.invoice_number,
				value: item.id
			}));
			setInvoiceList(tempList);
		});
	};

	useEffect(() => {
		if (preLoadedData) {
			if (preLoadedData?.selections) {
				setModels(preLoadedData?.selections?.models);
				setInvoiceList(preLoadedData?.selections?.invoiceList);
			} else {
				getSelectOptions();
			}

			setInvItems(
				preLoadedData?.invItems?.map((item) => ({
					...item,
					model: item?.model?.value,
					invoice: item?.invoice?.id,
					vat: item?.vat?.value
				})) || []
			);
			setShippingFee(preLoadedData?.shippingFee || 0);
		} else {
			getSelectOptions();
		}
	}, []);

	// ## Invoice Data Handling ## //
	const [invItems, setInvItems] = useState([
		{
			seqId: 1,
			qty: orderType == 'credit-note' ? -1 : 1,
			model: '',
			vat: 1,
			unitPrice: 0,
			comment: '',
			commentToggle: false,
			invoice: '',
			modelOptions: [],
			modelOptionsLoading: false,
			customValue: ''
		}
	]);

	const [shippingFee, setShippingFee] = useState(0);
	const shippingQty = orderType == 'credit-note' ? -1 : 1;

	const newRowTemplate = {
		seqId: invItems?.length + 1,
		qty: orderType == 'credit-note' ? -1 : 1,
		model: '',
		vat: 1,
		unitPrice: 0,
		comment: '',
		commentToggle: false,
		invoice: '',
		modelOptions: [],
		modelOptionsLoading: false,
		customValue: ''
	};

	useEffect(() => {
		setInvItems((oldData) =>
			oldData.map((item) => ({
				...item,
				qty: orderType == 'credit-note' ? (item?.qty > -1 ? item.qty * -1 : item?.qty) : item?.qty < 0 ? item.qty * -1 : item.qty
			}))
		);
	}, [orderType]);

	useEffect(() => {
		if (orderType != 'estimate') {
			if (invItems?.[0]?.qty < 0 && orderType != 'credit-note') {
				setOrderType('credit-note');
			} else if (invItems?.[0]?.qty >= 0 && orderType == 'credit-note') {
				setOrderType('invoice');
			}
		}
	}, [invItems?.[0]?.qty]);

	useEffect(() => {
		if (invItems?.length < 1) {
			setInvItems((oldData) => [{ ...newRowTemplate, expand: true, expandData: newRowTemplate }]);
		}
	}, [invItems]);

	const [invalidData, setInvalidData] = useState(false);
	const [invalidDataMessage, setInvalidDataMessage] = useState('');
	useEffect(() => {
		const invItemsCopy = [...invItems];
		if (invItemsCopy?.length <= 0) {
			setInvalidData(true);
			setInvalidDataMessage('Please add at least one item');
		} else if (invItemsCopy?.some((it) => it.expand)) {
			setInvalidData(true);
			setInvalidDataMessage('Confirm Product details before saving');
		} else if (orderType == 'credit-note' && invItemsCopy?.some((it) => it.qty > -1)) {
			setInvalidData(true);
			setInvalidDataMessage('Credit Note items should have negative quantity');
		} else if (orderType != 'credit-note' && invItemsCopy?.some((it) => it.qty < 1)) {
			setInvalidData(true);
			setInvalidDataMessage('Invoice items should have positive quantity');
		} else if (invItemsCopy?.some((it) => !it.model)) {
			setInvalidData(true);
			setInvalidDataMessage('Please select a model for all items');
		} else if (invItemsCopy?.some((it) => !it.unitPrice)) {
			setInvalidData(true);
			setInvalidDataMessage('Please enter unit price for all items');
		} else if (shippingFee < 0) {
			setInvalidData(true);
			setInvalidDataMessage('Shipping fee should be a positive number');
		} else if (orderType == 'credit-note' && invItemsCopy?.some((it) => !it.invoice)) {
			setInvalidData(true);
			setInvalidDataMessage('Please select an invoice for all items');
		} else {
			setInvalidData(false);
			setInvalidDataMessage('');
		}
	}, [invItems, shippingFee]);

	// ## Handle Quantity Change ## //

	const handleQtyChange = (index, value) => {
		setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, qty: value } : item)));
	};

	// ## Handle Unit Price Change ## //

	const handleUnitPriceChange = (index, value) => {
		setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, unitPrice: value } : item)));
	};

	// ## Handle Comment Change ## //

	const handleCommentChange = (index, value) => {
		setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, comment: value } : item)));
	};

	// ## Handle Add Comment Toggle ## //
	const handleAddCommentToggle = (index, status) => {
		setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, commentToggle: status } : item)));
	};

	// ## Handle Model Change ## //
	const handleModelChange = (index, e, isCreditModal = false) => {
		console.log(e, 'hello');
		if (isCreditModal) {
			const unitPrice = e?.converted_currency_unit_price?.[currency] || 0;
			setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, model: e.value, unitPrice } : item)));
		} else {
			setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, model: e.value } : item)));
		}
	};

	// ## Handle Invoice Change ## //
	const handleInvoiceChange = (index, e) => {
		setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, invoice: e.value, modelOptionsLoading: true } : item)));

		api.getEstimateProducts(e.value).then((res) => {
			setInvItems((oldData) =>
				oldData.map((item, i) =>
					i === index
						? {
								...item,
								invoice: e.value,
								modelOptionsLoading: false,
								modelOptions: res?.data?.map((r) => ({ ...r, label: r?.product?.product_name, value: r?.product_id })) || []
						  }
						: item
				)
			);
		});
	};

	// ## Handle VAT Change ## //
	const handleVatChange = (index, e) => {
		setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, vat: e.value } : item)));
	};

	// ## Handle Remove Inv Item ## //
	const handleRemoveInvItem = (index) => {
		setInvItems((oldData) => oldData.filter((item, i) => i != index));
	};

	// ## Handle Shipping Change ## //
	const handleShippingChange = (e) => {
		setShippingFee(e.target.value);
	};

	// ## Handle Toggle Item Expand ## //
	const handleItemExpand = (index) => {
		setInvItems((oldData) =>
			oldData.map((item, i) => (i === index ? { ...item, expand: true, expandData: { ...item } } : { ...item, expand: false }))
		);
	};

	// ## Handle Product Detail Edit Ok ## //
	const handleProductDetailEditOk = (index) => {
		setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, expand: false, expandData: null } : item)));
	};

	// ## Handle Product Detail Edit Cancel ## //
	const handleProductDetailEditCancel = (index) => {
		setInvItems((oldData) => oldData.map((item, i) => (i === index ? { ...item?.expandData, expand: false, expandData: null } : item)));
	};

	// ## Handle Add Item ## //
	const handleAddItem = () => {
		setInvItems((oldData) => [...oldData, { ...newRowTemplate, expand: true, expandData: newRowTemplate }]);
	};

	// ## Handle Submit ## //
	const handleSubmit = () => {
		const preparedData = invItems.map((item) => ({
			...item,
			model:
				orderType == 'credit-note' ? item?.modelOptions?.find((m) => m.value == item.model) : models?.find((m) => m.value == item.model),
			invoice: invoiceList?.find((inv) => inv.value == item.invoice),
			vat: vatTypes?.find((v) => v.value == item.vat),
			expand: false
		}));

		onSubmit &&
			onSubmit({
				invItems: preparedData,
				shippingFee,
				selections: {
					invoiceList,
					models
				}
			});
	};

	const subtotal = invItems?.reduce((acc, item) => acc + Number(item?.unitPrice) * Number(item.qty), 0) || 0;
	const total = Number(subtotal) + Number(shippingFee) * Number(shippingQty);

	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{ width: '100dvw', height: '100dvh', position: 'fixed', left: '0', top: '0', zIndex: '1000' }}
		>
			<div
				className="popup product-popup-mobile d-md-none"
				ref={compRef}
				style={{ position: 'relative', transform: 'none', top: '0', left: '0', maxWidth: '98%' }}
			>
				<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 ml-0 pl-0">
					<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0"></h5>
					<button className="btn modal-close-icon" onClick={handleClose}>
						<FaTimes />
					</button>
				</div>

				<div className="row m-0 p-0">
					<table className="table inv-mobile-prods-table">
						<thead>
							<tr>
								<th className="qty-col">Qty</th>
								<th className="model-col" style={{ width: '100%' }}>
									Model
								</th>
								<th className="uprice-col">Price</th>
								<th className="remove-col" style={{ maxWidth: '60px', paddingInline: '0', verticalAlign: 'middle' }}></th>
							</tr>
						</thead>
						<tbody>
							{invItems.map((item, index) => (
								<>
									<tr
										key={item?.seqId}
										className="bordered-bottom"
										onClick={() => {
											handleItemExpand(index);
										}}
									>
										<td className="qty-col" style={{ verticalAlign: 'middle' }}>
											{(item?.expandData ? item?.expandData?.qty : item.qty) || 0}&nbsp;x
										</td>
										<td className="model-col" style={{ width: '100%', verticalAlign: 'middle' }}>
											{orderType == 'credit-note' ? (
												item?.expandData ? (
													<div>
														<p className="m-0 p-0">
															{item?.expandData?.modelOptions?.find((v) => v?.value == item?.expandData.model)?.label || 'None Selected'}
														</p>
														<span style={{ color: 'grey' }}>{invoiceList?.find((v) => v?.value == item?.expandData.invoice)?.label}</span>
													</div>
												) : (
													<div>
														<p className="m-0 p-0">{item?.modelOptions?.find((v) => v?.value == item.model)?.label || 'None Selected'}</p>
														<span style={{ color: 'grey' }}>{invoiceList?.find((v) => v?.value == item.invoice)?.label}</span>
													</div>
												)
											) : item?.expandData ? (
												<div className="d-flex flex-column">
													<p className="m-0 p-0">{models?.find((v) => v?.value == item?.expandData.model)?.label || 'None Selected'}</p>
													<span style={{ color: 'grey' }}>{item?.expandData?.comment}</span>
												</div>
											) : (
												<div className="d-flex flex-column">
													<p className="m-0 p-0">{models?.find((v) => v?.value == item.model)?.label || 'None Selected'}</p>
													<span style={{ color: 'grey' }}>{item?.comment}</span>
												</div>
											)}
										</td>
										<td className="uprice-col" style={{ verticalAlign: 'middle' }}>
											<div className="d-flex align-items-center" style={{ height: '32px' }}>
												{getCurrencySymbol(currency)}&nbsp;
												{numberFormatter.format(Number(item?.expandData ? item?.expandData?.unitPrice : item.unitPrice))}
											</div>
										</td>
										<td className="remove-col" style={{ maxWidth: '60px', paddingInline: '0', verticalAlign: 'middle' }}>
											<button
												className="btn btn-remove"
												onClick={() => {
													handleRemoveInvItem(index);
												}}
											>
												<FaTimes />
											</button>
										</td>
									</tr>
									{item.expand && (
										<tr className="expanded-row">
											<td colSpan={4}>
												<div className="w-100">
													<div className="form-group mb-2">
														<label htmlFor="">Quantity</label>
														<input
															type="number"
															className="form-control"
															placeholder="0"
															value={item.qty}
															onChange={(e) => {
																handleQtyChange(index, e.target.value);
															}}
															onFocus={(event) => {
																event.target.select();
															}}
														/>
													</div>

													{orderType == 'credit-note' && (
														<div className="form-group mb-2">
															<label htmlFor="">Invoice</label>

															<ReactSelect
																className="rs-container"
																classNamePrefix="rs"
																options={[...invoiceList]?.filter((inv) =>
																	customer && transitCompany
																		? inv?.customer?.id == customer.id && inv?.transit_company?.id == transitCompany.id
																		: inv?.customer
																		? inv?.customer?.id == customer.id
																		: inv?.transit_company
																		? inv?.transit_company?.id == transitCompany.id
																		: true
																)}
																onChange={(e) => {
																	handleInvoiceChange(index, e);
																}}
																value={invoiceList?.find((v) => v?.value == item.invoice)}
																menuPlacement="auto"
																menuPosition="fixed"
															/>
														</div>
													)}

													<div className="form-group mb-2">
														<label htmlFor="">Model</label>

														{orderType == 'credit-note' ? (
															<ReactSelect
																className="rs-container"
																classNamePrefix="rs"
																options={item.modelOptions}
																onChange={(e) => {
																	handleModelChange(index, e, true);
																}}
																value={item?.modelOptions?.find((v) => v?.value == item.model)}
																menuPlacement="auto"
																menuPosition="fixed"
															/>
														) : (
															<ReactSelect
																className="rs-container"
																classNamePrefix="rs"
																options={[...models]?.filter((m) => invItems?.every((it) => it.model != m.value) || m.value == item.model)}
																onChange={(e) => {
																	handleModelChange(index, e);
																}}
																value={models?.find((v) => v?.value == item.model)}
																menuPlacement="auto"
																menuPosition="fixed"
															/>
														)}
													</div>

													{orderType != 'credit-note' && (
														<div className="form-group mb-2">
															<label htmlFor="">Comment</label>
															<input
																type="text"
																className="form-control"
																placeholder=""
																value={item.comment}
																onChange={(e) => {
																	handleCommentChange(index, e.target.value);
																}}
															/>
														</div>
													)}
													<div className="form-group mb-2">
														<label htmlFor="">Price</label>
														<input
															type="number"
															className="form-control"
															value={item.unitPrice}
															onChange={(e) => {
																handleUnitPriceChange(index, e.target.value);
															}}
															onFocus={(event) => {
																event.target.select();
															}}
														/>
													</div>
													<div className="form-group mb-2">
														<label htmlFor="">VAT</label>

														<ReactSelect
															className="rs-container"
															classNamePrefix="rs"
															options={vatTypes}
															value={vatTypes?.find((v) => v?.value == item.vat)}
															onChange={(e) => {
																handleVatChange(index, e);
															}}
															menuPlacement="auto"
															menuPosition="fixed"
														/>
													</div>
												</div>

												<div className="d-flex" style={{ gap: '10px' }}>
													<button
														className="btn btn-outlined-main"
														style={{ flex: '1' }}
														onClick={() => {
															handleProductDetailEditOk(index);
														}}
													>
														OK
													</button>
													<button
														className="btn btn-outlined-main"
														style={{ flex: '1' }}
														onClick={() => {
															handleProductDetailEditCancel(index);
														}}
													>
														Cancel
													</button>
												</div>
											</td>
										</tr>
									)}
								</>
							))}

							{invItems?.every((it) => !it.expand) && (
								<tr>
									<td colSpan={4}>
										<button className="btn btn-outlined-main w-100" onClick={handleAddItem}>
											<FaPlus /> Add Item
										</button>
									</td>
								</tr>
							)}

							<tr className="shipping-row">
								<td colSpan={2} className="text-right" style={{ verticalAlign: 'middle' }}>
									Shipping
								</td>
								<td>
									<input
										type="number"
										className="form-control"
										style={{ verticalAlign: 'middle', paddingInline: '5px', minWidth: '50px' }}
										value={shippingFee}
										onChange={handleShippingChange}
										onFocus={(event) => {
											event.target.select();
										}}
									/>
								</td>
								{/* <td style={{ verticalAlign: 'middle' }}>
									{getCurrencySymbol(currency)} {numberFormatter.format(Number(shippingFee) * Number(shippingQty))}
								</td> */}
								<td></td>
							</tr>

							<tr>
								<td colSpan={2} className="text-right" style={{ verticalAlign: 'middle', fontWeight: 600 }}>
									Subtotal
								</td>
								<td style={{ verticalAlign: 'middle', fontWeight: 600 }}>
									{getCurrencySymbol(currency || 'eur')}&nbsp;{numberFormatter.format(subtotal || 0)}
								</td>
								<td></td>
							</tr>
							<tr>
								<th colSpan={2} className="text-right" style={{ verticalAlign: 'middle', fontWeight: 600 }}>
									Total
								</th>
								<td style={{ verticalAlign: 'middle', fontWeight: 600 }}>
									{getCurrencySymbol(currency || 'eur')} {numberFormatter.format(total || 0)}
								</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="btnarea mt-3">
					<Tooltip title={invalidData ? invalidDataMessage : null} arrow placement="top">
						<button className="btn btn-main" onClick={handleSubmit} disabled={invalidData}>
							<FaSave /> Save
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

export default ProductsPopupMobile;
