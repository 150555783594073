import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import usePageTitle from 'hooks/usePageTitle';
import { storePageTitle } from 'store/reducers/mainSlice';
import ClientPortalVideo from 'assets/videos/ClientPortalVid.mp4';

const Dashboard = () => {
	const clientUser = useSelector((state) => state.main.client);
	const dispatch = useDispatch();
	const history = useNavigate();
	const [setPageTitle] = usePageTitle('dashboard');
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			<div className="main-page-client" style={{ padding: 30 }}>
				<div className="row d-flex  justify-content-between" style={{ height: '100%' }}>
					<div className="col-12 d-flex flex-column justify-content-center align-items-center">
						<h4 style={{ fontWeight: 600, marginBottom: 10 }}>
							Welcome to the{' '}
							<span className="gradient-text" style={{ fontSize: '1.5rem' }}>
								Revolutionary Client Portal
							</span>
						</h4>
						<div className="d-flex flex-column justify-content-center align-items-center" style={{ marginBottom: 30 }}></div>
						<div
							style={{
								boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
								borderRadius: 16,
								backgroundColor: 'white',
								marginBottom: 30,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								overflow: 'hidden' // Hide overflow
								// Adjust padding as needed to hide the black borders
							}}
						>
							<video
								controls
								style={{
									maxWidth: '100%', // Adjusted to 100%
									maxHeight: isMobile ? 300 : 380,
									overflow: 'hidden',
									borderRadius: 16,
									border: 'none',
									objectFit: 'contain'
								}}
							>
								<source src={ClientPortalVideo} type="video/mp4" style={{ border: 'none', outline: 'none' }} />
								Your browser does not support the video tag.
							</video>
						</div>
						<button className="btn btn-main-primary btn-rounded d-sm-block d-md-block" style={{ padding: '10px 20px', marginBottom: 30 }}>
							Explore your client portal
						</button>
					</div>

					<div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column feature-list-dashboard" style={{ fontSize: 13 }}>
						<svg xmlns="http://www.w3.org/2000/svg" width="31.25" height="31.251" viewBox="0 0 31.25 31.251">
							<path
								id="time-quarter-past_1_"
								data-name="time-quarter-past (1)"
								d="M31.25,15.626a1.3,1.3,0,1,1-2.6,0A13.035,13.035,0,0,0,15.625,2.6a1.3,1.3,0,0,1,0-2.6A15.642,15.642,0,0,1,31.25,15.626Zm-10.417,1.3a1.3,1.3,0,0,0,0-2.6H17.868a2.6,2.6,0,0,0-.941-.941V9.115a1.3,1.3,0,1,0-2.6,0v4.267a2.6,2.6,0,1,0,3.546,3.546ZM2.379,8.834a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,2.379,8.834ZM2.6,15.626a1.3,1.3,0,1,0-1.3,1.3A1.3,1.3,0,0,0,2.6,15.626ZM15.625,28.647a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,15.625,28.647ZM5.5,4.176a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,5.5,4.176ZM10.129,1.1a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,10.129,1.1ZM2.379,19.813a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,2.379,19.813ZM5.5,24.471a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,5.5,24.471Zm4.633,3.081a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,10.129,27.552Zm18.742-7.739a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,28.871,19.813Zm-3.117,4.658a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,25.754,24.471Zm-4.633,3.081a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,21.121,27.552Z"
								fill="#06f"
							/>
						</svg>

						<a style={{ marginTop: 10 }}>Save up to 80% and streamline your orders with our revolutionary Client Portal!</a>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column feature-list-dashboard" style={{ fontSize: 13 }}>
						<svg xmlns="http://www.w3.org/2000/svg" width="31.25" height="31.256" viewBox="0 0 31.25 31.256">
							<path
								id="floor"
								d="M1.613,12.468,12.747,18.78a5.862,5.862,0,0,0,5.757,0l11.133-6.313a3.069,3.069,0,0,0,0-5.4L18.5.753a5.871,5.871,0,0,0-5.757,0L1.613,7.064a3.069,3.069,0,0,0,0,5.4ZM17.22,16.514a3.29,3.29,0,0,1-3.189,0l-3.921-2.223,7.152-4.03,5.509,3.1-5.553,3.149ZM28.646,9.766a.5.5,0,0,1-.294.435l-2.935,1.664-5.5-3.1,3.733-2.1,4.7,2.668a.5.5,0,0,1,.294.435ZM14.031,3.019h0a3.295,3.295,0,0,1,3.189,0L21,5.165l-7.23,4.074L8.4,6.211l5.632-3.193ZM2.9,9.331,5.757,7.71l5.365,3.024L7.467,12.792,2.9,10.2a.469.469,0,0,1,0-.87Zm28.174,6.505a1.3,1.3,0,0,1-.466,1.781L18.52,24.693a5.872,5.872,0,0,1-2.9.763,5.807,5.807,0,0,1-2.874-.754L.664,17.907a1.3,1.3,0,0,1,1.277-2.27l12.087,6.8a3.294,3.294,0,0,0,3.193,0l12.072-7.065a1.3,1.3,0,0,1,1.781.465Zm0,5.8a1.3,1.3,0,0,1-.466,1.781L18.52,30.492a5.872,5.872,0,0,1-2.9.763,5.794,5.794,0,0,1-2.874-.755L.664,23.706a1.3,1.3,0,0,1,1.277-2.27l12.087,6.8a3.294,3.294,0,0,0,3.193,0l12.072-7.065a1.3,1.3,0,0,1,1.781.466Z"
								transform="translate(0 0.001)"
								fill="#06f"
							/>
						</svg>

						<a style={{ marginTop: 10 }}>Easily accept new estimates and agreements, and seamlessly follow your order process.</a>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column feature-list-dashboard" style={{ fontSize: 13 }}>
						<svg xmlns="http://www.w3.org/2000/svg" width="31.25" height="31.251" viewBox="0 0 31.25 31.251">
							<path
								id="operation"
								d="M23.518,11.068a1.3,1.3,0,0,0-1.779-.477l-1.267.732a6.473,6.473,0,0,0-3.546-2.076V7.814a1.3,1.3,0,1,0-2.6,0V9.248a6.489,6.489,0,0,0-3.546,2.076L9.51,10.591a1.3,1.3,0,0,0-1.3,2.255l1.268.732a5.969,5.969,0,0,0,0,4.094L8.208,18.4a1.3,1.3,0,1,0,1.3,2.255l1.267-.732A6.473,6.473,0,0,0,14.323,22v1.434a1.3,1.3,0,1,0,2.6,0V22a6.489,6.489,0,0,0,3.546-2.076l1.267.732a1.3,1.3,0,0,0,1.3-2.255l-1.268-.732a5.969,5.969,0,0,0,0-4.094l1.268-.732a1.3,1.3,0,0,0,.477-1.779Zm-7.893,8.464a3.906,3.906,0,1,1,3.906-3.906A3.911,3.911,0,0,1,15.625,19.532ZM2.6,14.44a1.3,1.3,0,0,1-1.3,1.185c-.039,0-.078,0-.118-.005a1.3,1.3,0,0,1-1.18-1.414,15.643,15.643,0,0,1,26.036-10.2V1.3a1.3,1.3,0,1,1,2.6,0V6.511a2.607,2.607,0,0,1-2.6,2.6H20.833a1.3,1.3,0,0,1,0-2.6H24.9A13.047,13.047,0,0,0,2.6,14.44Zm28.645,2.6A15.643,15.643,0,0,1,5.208,27.24v2.708a1.3,1.3,0,1,1-2.6,0V24.74a2.607,2.607,0,0,1,2.6-2.6h5.208a1.3,1.3,0,0,1,0,2.6H6.35a13.047,13.047,0,0,0,22.3-7.93,1.3,1.3,0,1,1,2.594.234Z"
								transform="translate(0)"
								fill="#06f"
							/>
						</svg>

						<a style={{ marginTop: 10 }}>You get real-time access to all you business operations in one central workspace.</a>
					</div>
				</div>
			</div>
			{/* this is the first  */}
			{/* <div className="main-page-client" style={{ padding: 30 }}>
				<div className="row">
					<div className="col-12 d-flex justify-content-center align-items-center" style={{ marginBottom: 20 }}>
						<h4 style={{ fontWeight: 600 }}>Welcome to the revolutionary Client Portal</h4>
					</div>
					<div
						className="col-12 d-flex flex-column justify-content-center align-items-center"
						style={{ marginBottom: 30, fontWeight: 500, color: '#9f9f9f' }}
					>
						<a>Say "Goodbye" to messy spreadsheets, manual tracking,</a>
						<a> and wasted hours on various communications!</a>
					</div>

					<div className="col-12 d-flex justify-content-center align-items-center" style={{ marginBottom: 30 }}>
						<div
							style={{ boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)', borderRadius: 16, backgroundColor: 'white', height: 300, width: 550 }}
						></div>
					</div>
					<div className="col-12 d-flex justify-content-center align-items-center" style={{ marginBottom: 60 }}>
						<button className="btn btn-main-primary btn-rounded d-sm-block d-md-block" style={{ padding: '10px 20px' }}>
							Explore your client portal
						</button>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12" style={{ fontSize: 15 }}>
						<a>Save up to 80% and streamline your orders with our revolutionary Client Portal!</a>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12" style={{ fontSize: 15 }}>
						<a>Easily accept new estimates and agreements, and seamlessly follow your order process.</a>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12" style={{ fontSize: 15 }}>
						<a>You get real-time access to all you business operations in one central workspace.</a>
					</div>
				</div>
			</div> */}
		</>
	);
};

export default Dashboard;
