import { Box } from '@mui/system';
import usePermissions from 'hooks/usePermissions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

const Auth = ({ children, requiredPermissions }) => {
	const history = useNavigate();
	const currentUser = useSelector((state) => state.main.user);
	const currentUserRole = useSelector((state) => state.main.user?.role?.name);
	const [allPermissions, hasPermissions] = usePermissions();
	const { pathname } = useLocation();

	const renderChild = () => {
		let path = pathname.split('/');
		if (path.length > 2) {
			path = path[2];
		} else {
			path = path[1];
		}

		// return permission?.includes(path) || currentUserRole === 'Super Admin'
		return !requiredPermissions || (requiredPermissions && hasPermissions(requiredPermissions)) ? (
			children
		) : (
			<Box sx={{ margin: '25%' }}>
				<span className="h3 text-center text-danger">
					You have not permission access of <strong>{path}</strong>.
				</span>
			</Box>
		);
	};
	return currentUser && currentUserRole ? renderChild() : history('/');
};

export default Auth;
