import { React, useState, useEffect } from 'react';

function WarriorCard({
	warriorPermission,
	isFlipped,
	setIsFlipped,
	previousMonth,
	currentYear,
	wotm,
	wotmName,
	wotmData,
	wotmSold,
	wotmProfitMargin,
	wotmContact,
	currentMonth,
	wotmImage,
	wotmImageSmall,
	userImage,
	user,
	data,
	target,
	soldQuantity,
	profitMargin,
	contactCount,
	isAdmin
}) {
	const [opacity, setOpacity] = useState(1);
	const [prevImage, setPrevImage] = useState(wotmImage);

	useEffect(() => {
		setOpacity(0);
		const timer = setTimeout(() => {
			setPrevImage(wotmImage);
			setOpacity(1);
		}, 500);
		return () => clearTimeout(timer);
	}, [wotmImage]);

	return (
		<div className="card-inner">
			<div className="card-front">
				<div
					className="card-header card-special"
					style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'space-between', paddingBottom: '0' }}
				>
					<h5 style={{ fontSize: '13px', fontWeight: 500 }}>Warrior of the Month</h5>
					<a style={{ fontSize: '13px', fontWeight: 500 }}>{`${previousMonth} ${currentYear}`}</a>
				</div>
				<div className="card-body custom-card-body" style={{ height: '100%' }}>
					<div className="row custom-row" style={{ height: '100%' }}>
						{wotmImage && (
							<div
								className="col-6 custom-col"
								style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
							>
								<div
									className="winning-image"
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										paddingLeft: isAdmin === true ? '10px' : '20px'
									}}
								>
									<img
										key={`${prevImage}-prev`}
										src={prevImage}
										alt="Warrior of the Month"
										style={{
											position: 'absolute',
											textAlign: 'center',
											maxWidth: isAdmin === true ? '130px' : '150px',
											maxHeight: '100%',
											objectFit: 'contain',
											animation: `${wotmImage !== prevImage ? 'fade-out 1s' : ''}`
										}}
									/>
									<img
										key={`${wotmImage}-current`}
										src={wotmImage}
										alt="Warrior of the Month"
										style={{
											textAlign: 'center',
											maxWidth: isAdmin === true ? '130px' : '150px',
											maxHeight: '100%',
											objectFit: 'contain',
											animation: `${wotmImage !== prevImage ? 'fade-in 1s forwards' : ''}`
										}}
									/>
								</div>
								<h5
									style={{
										fontSize: '18px',
										textAlign: 'center',
										fontWeight: '400',
										padding: '10px',
										paddingLeft: isAdmin === true ? '20px' : '30px',
										color: 'black',
										position: 'relative',
										zIndex: 1,
										bottom: '12px'
									}}
								>
									{wotmName[0] && wotmName[1] ? `${wotmName[0].value} ${wotmName[1].value}` : 'Loading...'}
								</h5>
							</div>
						)}
						{wotmData && wotmSold && wotmProfitMargin && wotmContact && (
							<div className="col-6 custom-col">
								<div className="winningProfit">
									<h2>{wotmData[0] ? `$ ${wotmData[0].value.toLocaleString('en-US', { maximumFractionDigits: 0 })}` : 'Loading...'}</h2>
									<a>
										{wotmData[0] && wotmData[3] ? `${((wotmData[0].value / wotmData[3].value) * 100).toFixed(2)}% of target` : 'Loading...'}
									</a>
									<br />
									<a>{`${parseInt(wotmSold).toLocaleString('en-US')}`} Pcs Sold</a>
									<br />
									<a>{`${wotmProfitMargin}`}% margin</a>
									<br />
									<a>{`${wotmContact}`} New Customer</a>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="card-back">
				<div className="card-back-header card-back-special" style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '0' }}>
					<a style={{ fontSize: '10px' }}>{`${currentMonth} ${currentYear}`}</a>
				</div>
				<div className="card-body" style={{ paddingTop: '0', textAlign: 'center' }}>
					<div className="row">
						<div className="col-6">
							<div className="comparison-card">
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}>
									<div
										style={{
											width: '28px',
											height: '28px',
											borderRadius: '50%',
											backgroundImage: `url(${wotmImageSmall})`,
											backgroundSize: 'cover',
											marginRight: '10px'
										}}
									></div>
									<h3 style={{ margin: '0' }}>{wotmName[0] ? `${wotmName[0].value}` : 'No data'}</h3>
								</div>
								<h2>{wotmData[0] ? `$ ${wotmData[0].value.toLocaleString('en-US', { maximumFractionDigits: 0 })}` : '0% of target'}</h2>
								<a>
									{wotmData[0] && wotmData[3] ? `${((wotmData[0].value / wotmData[3].value) * 100).toFixed(2)}% of target` : 'Loading...'}
								</a>
								<br />
								<a>{`${parseInt(wotmSold).toLocaleString('en-US')}`} Pcs Sold</a>
								<br />
								<a>{`${wotmProfitMargin}`}%% margin</a>
								<br />
								<a>{`${wotmContact}`} New Customer</a>
							</div>
						</div>
						{user && user[0] && (
							<div className="col-6">
								<div className="comparison-card">
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}>
										<div
											style={{
												width: '28px',
												height: '28px',
												borderRadius: '50%',
												backgroundImage: `url(${userImage})`,
												backgroundSize: 'cover',
												marginRight: '10px'
											}}
										></div>
										<h3 style={{ margin: '0' }}>{user[0] ? `${user[0].value}` : 'Loading...'}</h3>
									</div>
									<h2>{data[2] ? `$ ${data[2].value.toLocaleString('en-US', { maximumFractionDigits: 0 })}` : 'Loading...'}</h2>
									<a>{data[2] && target ? `${((data[2].value / target) * 100).toFixed(2)}% of target` : 'Loading...'}</a>
									<br />
									<a>{`${parseInt(soldQuantity).toLocaleString('en-US')}`} Pcs Sold</a>
									<br />
									<a>{`${profitMargin}`}% margin</a>
									<br />
									<a>{`${contactCount}`} New Customer</a>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default WarriorCard;
