import React, { useRef, useState, useEffect } from 'react';
import ProcessIndicator from './ProcessIndicator';
import { taskboardProgresses } from 'assets/helperdata/client/order-client-progress';
import StatusBadge from 'ui-component/StatusBadge';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import { FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { set } from 'react-hook-form';
import moment from 'moment';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { IoCartOutline } from 'react-icons/io5';
import AgreementModel from '../estimates/EstimationDetails';
import InvoiceModal from './InvoiceModal';
import { HiOutlineDownload } from 'react-icons/hi';
import AWBModal from './AWBModal';
import { FaRegFileLines } from 'react-icons/fa6';
import SearchBar from '../layout/SearchBar';
import { setSearchBar } from 'store/reducers/mainSlice';

const Orders = () => {
	const inputRef = useRef(null);

	const [isInProgress, setIsInProgress] = useState(false);
	const [searchField, setSearchField] = useState();
	const clientUser = useSelector((state) => state.main.client);
	const [data, setData] = useState([]);
	const [autoSuggest, setAutoSuggest] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [id, setId] = useState();
	const [agreementPopup, setAgreemmentPopup] = useState(false);
	const [pending, setPending] = useState(false);
	const [showAWBModal, setShowAWBModal] = useState(false);
	const [showInvoiceItems, setShowInvoiceItems] = useState(false);
	const [selectedIdDetails, setSelectedIdDetails] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	const [pagination, setpagination] = useState();
	const dispatch = useDispatch();

	const searchBar = useSelector((state) => state.main.searchBar);
	const [showButtons, setShowButtons] = useState([]);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (searchBar && inputRef.current) {
			inputRef.current.focus();
		}
	}, [searchBar]);

	console.log(searchBar);
	useEffect(() => {
		console.log(searchBar);
		if (clientUser?.data?.is_approved === 2) {
			api
				.getClientOrders(clientUser?.data?.contact_id)
				.then((res) => {
					console.log(res);
					setData(res?.data?.data);
					setSelectedOrder(res?.data?.data[0]);
					setpagination(res.data);
					setIsLoading(false);
					setAutoSuggest(res?.data?.data.map((item) => item.invoice_number).slice(0, 10));
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, []);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	console.log(data);
	const toggleButtons = (id) => {
		const newShowButtons = [...showButtons];
		const index = newShowButtons.indexOf(id);
		if (index !== -1) {
			newShowButtons.splice(index, 1);
		} else {
			newShowButtons.push(id);
		}
		setShowButtons(newShowButtons);
	};

	const closeagreementPopup = () => {
		console.log('clicked');
		setAgreemmentPopup(false);
	};
	const EstimationDetails = (id, status) => {
		console.log(id);
		setAgreemmentPopup(true);
		setId(id);
		setPending(status === 'Pending Customer' ? true : false);
	};

	const handleData = (data) => {
		setData(data.data);
		setpagination(data);
	};

	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);
		if (text.length > 2) {
			api.getClientOrders(clientUser?.data?.contact_id, undefined, text).then((res) => {
				setData(res?.data?.data);
				setSelectedOrder(res?.data?.data[0]);
				setAutoSuggest(res?.data?.data.map((item) => item.invoice_number).slice(0, 10));
				setpagination(res.data);
			});
		} else if (text.length > 0 && text.length <= 2) {
		} else {
			api.getClientOrders(clientUser?.data?.contact_id).then((res) => {
				setData(res?.data?.data);
				setSelectedOrder(res?.data?.data[0]);
				setpagination(res.data);
				setAutoSuggest(res?.data?.data.map((item) => item.invoice_number).slice(0, 10));
			});
		}
	};

	const showInvoiceitemsModal = (id) => {
		setSelectedIdDetails(id);
		console.log(id);
		setShowInvoiceItems(true);
	};

	const downloadImei = (id) => {
		api.downloadImei(id).then((res) => {
			var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(newBlob, `Projectmix - IMEI.xlsx`);
		});
	};

	const downloadAWB = (id) => {
		setSelectedOrder(id);
		setShowAWBModal(true);
	};

	return (
		<>
			{isMobile && (
				<div className="approved-mobile" style={{ position: 'sticky', top: 0, zIndex: 10 }}>
					<div className="verified-mobile">
						<svg xmlns="http://www.w3.org/2000/svg" width="21.996" height="20.162" viewBox="0 0 21.996 20.162">
							<path
								id="rectangle-list"
								d="M17.414,1H4.583A4.588,4.588,0,0,0,0,5.582v11a4.588,4.588,0,0,0,4.583,4.582H17.414A4.588,4.588,0,0,0,22,16.58v-11A4.588,4.588,0,0,0,17.414,1Zm2.75,15.58a2.753,2.753,0,0,1-2.75,2.749H4.583a2.753,2.753,0,0,1-2.75-2.749v-11a2.753,2.753,0,0,1,2.75-2.749H17.414a2.753,2.753,0,0,1,2.75,2.749ZM17.414,6.5a.917.917,0,0,1-.917.916H10.082a.916.916,0,1,1,0-1.833H16.5A.917.917,0,0,1,17.414,6.5ZM7.332,6.5A1.375,1.375,0,1,1,5.957,5.124,1.375,1.375,0,0,1,7.332,6.5Zm10.082,4.582A.917.917,0,0,1,16.5,12H10.082a.916.916,0,1,1,0-1.833H16.5A.917.917,0,0,1,17.414,11.081Zm-10.082,0A1.375,1.375,0,1,1,5.957,9.707,1.375,1.375,0,0,1,7.332,11.081Zm10.082,4.582a.917.917,0,0,1-.917.916H10.082a.916.916,0,1,1,0-1.833H16.5A.917.917,0,0,1,17.414,15.664Zm-10.082,0a1.375,1.375,0,1,1-1.375-1.375A1.375,1.375,0,0,1,7.332,15.664Z"
								transform="translate(0 -1)"
								fill="currentColor"
							/>
						</svg>
						<span style={{ marginLeft: 5, fontSize: 16 }}>Orders</span>
					</div>
				</div>
			)}
			<div className="main-page-client">
				{!isLoading ? (
					<div className="order-client-content">
						{!isMobile && (
							<div
								className="d-flex align-items-center TableSerachBar"
								style={{
									justifyContent: isMobile ? 'flex-end' : 'space-between' // Add this line
								}}
							>
								{!isMobile && (
									<div className="heading-order-client-tbl">
										<h3>Orders</h3>
									</div>
								)}
								<form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
									<div className="input-group">
										<input
											ref={inputRef}
											type="text"
											className="form-control bg-white border"
											placeholder="Search"
											aria-label="Search"
											aria-describedby="basic-addon2"
											value={searchField}
											onChange={(e) => {
												handleSearch(e);
											}}
											onFocus={() => {
												dispatch(setSearchBar(true));
											}}
											onBlur={() => {
												dispatch(setSearchBar(!searchBar));
											}}
										/>
										<span className="searchIcon">
											<FaSearch />
										</span>
									</div>
								</form>
							</div>
						)}

						{!isMobile ? (
							<div className="estimation-table-area" style={{ height: isMobile ? 'auto' : '93%' }}>
								<div className="table-side">
									<div
										className="table-responsive-xl tableData estimationList"
										style={{ height: isMobile ? 'auto' : '100%', overflowY: isMobile ? 'none' : 'auto' }}
									>
										{data.length > 0 ? (
											<>
												<table className="order-client-tb min-width-unset pm-table d-sm-table d-md-table">
													{' '}
													<thead
														style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff', borderBottom: '1px solid #cacaca' }}
													>
														<tr>
															<th scope="col">Reference</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Products
															</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Total
															</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Date
															</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Status
															</th>

															<th scope="col" style={{ textAlign: 'center' }}>
																Details
															</th>
															<th scope="col" style={{ textAlign: 'center' }}>
																IMEI
															</th>
															<th scope="col" style={{ textAlign: 'center' }}>
																AWB
															</th>
														</tr>
													</thead>
													{clientUser?.data?.is_approved === 2 ? (
														<>
															<tbody>
																{data.map((item, index) => (
																	<tr
																		key={index}
																		// onClick={() => setSelectedOrder(item)}
																		onClick={() => EstimationDetails(item.qoutation_id, item?.master_status?.name)}
																	>
																		<td className="text-left ml-5">
																			<Link to={''} className="ml-1">
																				{item.invoice_number}
																			</Link>
																		</td>
																		<td style={{ textAlign: 'left' }}>
																			{item.estimation_details.reduce((total, detail) => total + detail.qty, 0)}
																		</td>
																		<td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
																			{item.currency === 'eur' ? '€' : item.currency === 'usd' ? '$' : 'AED'}{' '}
																			{Number(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																		</td>

																		<td style={{ textAlign: 'left' }}>
																			{' '}
																			{moment(item?.estimation_date, 'DD-MM-YYYY').format('DD MMMM YYYY')}
																		</td>
																		<td>
																			<div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
																				<div
																					style={{
																						width: windowWidth >= 650 && windowWidth < 1290 ? '100%' : '80%'
																					}}
																				>
																					{' '}
																					<StatusBadge itemStatus={item.master_status.name == 'Paid' ? 'Completed' : 'Processing'} />
																				</div>
																			</div>
																		</td>
																		<td style={{ textAlign: 'center' }}>
																			<div
																				onClick={(e) => {
																					if (item.purchase_list.length !== 0) {
																						e.stopPropagation();
																						showInvoiceitemsModal(item.id);
																					}
																				}}
																				style={{
																					cursor: item.purchase_list.length !== 0 ? 'pointer' : 'not-allowed'
																				}}
																			>
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					width="15"
																					height="15"
																					viewBox="0 0 21.985 21.985"
																					className={item.purchase_list.length !== 0 ? 'active-svg' : 'inactive-svg'}
																				>
																					<path
																						id="search_1_"
																						data-name="search (1)"
																						d="M10.649,15.458a.859.859,0,1,1,0,1.718h-1.2a.859.859,0,1,1,0-1.718Zm7.042-4.594A.859.859,0,0,0,16.832,10H9.447a.859.859,0,1,0,0,1.718h7.386A.859.859,0,0,0,17.691,10.864ZM9.447,6.57h7.386a.859.859,0,1,0,0-1.718H9.447a.859.859,0,1,0,0,1.718ZM6.011,15.458H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.453H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.153H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,0,0,0-1.718ZM21.733,20.519a.859.859,0,0,1-1.215,1.215l-1.453-1.453A3.869,3.869,0,1,1,20.31,19.1ZM17.09,19.108a2.147,2.147,0,1,0-2.147-2.147A2.149,2.149,0,0,0,17.09,19.108ZM18.55,0H3.435A3.439,3.439,0,0,0,0,3.435V18.55a3.439,3.439,0,0,0,3.435,3.435h8.845a.859.859,0,1,0,0-1.718H3.435A1.72,1.72,0,0,1,1.718,18.55V3.435A1.72,1.72,0,0,1,3.435,1.718H18.55a1.72,1.72,0,0,1,1.718,1.718v8.5a.859.859,0,0,0,1.718,0v-8.5A3.439,3.439,0,0,0,18.55,0Zm0,0"
																					/>
																				</svg>
																			</div>
																		</td>
																		<td style={{ textAlign: 'center' }}>
																			<div
																				onClick={(e) => {
																					if (
																						item.purchase_list &&
																						item.purchase_list[item.purchase_list.length - 1] &&
																						item.purchase_list[item.purchase_list.length - 1].stocks_count > 0
																					) {
																						e.stopPropagation();
																						downloadImei(item.id);
																					}
																				}}
																				style={{
																					cursor:
																						item.purchase_list &&
																						item.purchase_list[item.purchase_list.length - 1] &&
																						item.purchase_list[item.purchase_list.length - 1].stocks_count > 0
																							? 'pointer'
																							: 'not-allowed'
																				}}
																			>
																				<HiOutlineDownload
																					size={18}
																					color={
																						item.purchase_list &&
																						item.purchase_list[item.purchase_list.length - 1] &&
																						item.purchase_list[item.purchase_list.length - 1].stocks_count > 0
																							? 'black'
																							: '#b7b7b7'
																					}
																				/>
																			</div>
																		</td>
																		<td style={{ textAlign: 'center' }}>
																			<div
																				onClick={(e) => {
																					if (item.has_AWB == 1) {
																						e.stopPropagation();
																						downloadAWB(item.id);
																					}
																				}}
																				style={{
																					cursor: item.has_AWB == 1 ? 'pointer' : 'not-allowed'
																				}}
																			>
																				<FaRegFileLines size={16} color={item.has_AWB == 1 ? 'black' : '#b7b7b7'} />
																			</div>
																		</td>
																	</tr>
																))}
															</tbody>
														</>
													) : (
														<></>
													)}
												</table>
												<div
													style={{
														position: 'sticky',
														bottom: '-1px',
														width: '100%',
														backgroundColor: '#fff',
														textAlign: isMobile ? 'center' : 'right',
														marginTop: 10
													}}
												>
													<Pagination pagination={pagination} data={handleData} />
												</div>
											</>
										) : (
											<div style={{ width: '100%' }}>
												<div className="no-hover" style={{ width: '100%' }}>
													<div className="whitebg">
														<div className="not-found-container ">
															<IoCartOutline size={120} color="black" />

															<h6
																className="not-found-text"
																style={{ fontStyle: 'italic', color: '#000', fontSize: '18px', marginTop: 10, paddingBottom: 5 }}
															>
																Add your first order and enjoy the convenience!
															</h6>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						) : (
							<>
								{data.length > 0 ? (
									<>
										<div className="order-list-client">
											<div className="row">
												<div className="col-5" style={{ padding: 10, paddingLeft: 20 }}>
													<span style={{ fontWeight: 600 }}>Reference</span>
												</div>
												<div className="col-5" style={{ padding: 10, display: 'flex', justifyContent: 'start', paddingRight: 20 }}>
													<span style={{ fontWeight: 600 }}>Status</span>
												</div>
											</div>
										</div>
										<div className="order-container">
											{data.map((item) => (
												<div
													className={`row order-item-client ${showButtons.includes(item.id) ? 'active' : ''}`}
													key={item.id}
													onClick={() => toggleButtons(item.id)}
												>
													<div
														className="col-5"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingLeft: 20,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
													>
														<a>{item.invoice_number}</a>
													</div>
													<div
														className="col-5"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
													>
														<div style={{ width: 100 }}>
															<StatusBadge itemStatus={item.master_status.name == 'Paid' ? 'Completed' : 'Processing'} />
														</div>
													</div>
													<div
														className="col-2"
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
														onClick={(event) => {
															event.stopPropagation();
															EstimationDetails(item.qoutation_id, item?.master_status?.name);
														}}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21.985 21.985">
															<path
																id="search_1_"
																data-name="search (1)"
																d="M10.649,15.458a.859.859,0,1,1,0,1.718h-1.2a.859.859,0,1,1,0-1.718Zm7.042-4.594A.859.859,0,0,0,16.832,10H9.447a.859.859,0,1,0,0,1.718h7.386A.859.859,0,0,0,17.691,10.864ZM9.447,6.57h7.386a.859.859,0,1,0,0-1.718H9.447a.859.859,0,1,0,0,1.718ZM6.011,15.458H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.453H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.153H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,0,0,0-1.718ZM21.733,20.519a.859.859,0,0,1-1.215,1.215l-1.453-1.453A3.869,3.869,0,1,1,20.31,19.1ZM17.09,19.108a2.147,2.147,0,1,0-2.147-2.147A2.149,2.149,0,0,0,17.09,19.108ZM18.55,0H3.435A3.439,3.439,0,0,0,0,3.435V18.55a3.439,3.439,0,0,0,3.435,3.435h8.845a.859.859,0,1,0,0-1.718H3.435A1.72,1.72,0,0,1,1.718,18.55V3.435A1.72,1.72,0,0,1,3.435,1.718H18.55a1.72,1.72,0,0,1,1.718,1.718v8.5a.859.859,0,0,0,1.718,0v-8.5A3.439,3.439,0,0,0,18.55,0Zm0,0"
															/>
														</svg>
													</div>

													{showButtons.includes(item.id) && (
														<div
															className={`col-12 ${showButtons.includes(item.id) ? 'show-buttons' : 'hide-buttons'}`}
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																paddingLeft: 20,
																paddingRight: 20,
																paddingBottom: 10,
																zIndex: 0,
																overflowX: 'auto',
																transition: 'all 0.5s ease'
															}}
														>
															<>
																<button
																	className="btn btn-primary btn-outlined-main"
																	style={{ width: '100%', marginRight: 10 }}
																	disabled={item.purchase_list.length === 0}
																	onClick={(e) => {
																		if (item.purchase_list.length !== 0) {
																			e.stopPropagation();
																			showInvoiceitemsModal(item.id);
																		}
																	}}
																>
																	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21.985 21.985">
																		<path
																			id="search_1_"
																			data-name="search (1)"
																			d="M10.649,15.458a.859.859,0,1,1,0,1.718h-1.2a.859.859,0,1,1,0-1.718Zm7.042-4.594A.859.859,0,0,0,16.832,10H9.447a.859.859,0,1,0,0,1.718h7.386A.859.859,0,0,0,17.691,10.864ZM9.447,6.57h7.386a.859.859,0,1,0,0-1.718H9.447a.859.859,0,1,0,0,1.718ZM6.011,15.458H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.453H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.153H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,0,0,0-1.718ZM21.733,20.519a.859.859,0,0,1-1.215,1.215l-1.453-1.453A3.869,3.869,0,1,1,20.31,19.1ZM17.09,19.108a2.147,2.147,0,1,0-2.147-2.147A2.149,2.149,0,0,0,17.09,19.108ZM18.55,0H3.435A3.439,3.439,0,0,0,0,3.435V18.55a3.439,3.439,0,0,0,3.435,3.435h8.845a.859.859,0,1,0,0-1.718H3.435A1.72,1.72,0,0,1,1.718,18.55V3.435A1.72,1.72,0,0,1,3.435,1.718H18.55a1.72,1.72,0,0,1,1.718,1.718v8.5a.859.859,0,0,0,1.718,0v-8.5A3.439,3.439,0,0,0,18.55,0Zm0,0"
																			fill={item.purchase_list.length === 0 ? 'gray' : 'currentColor'}
																		/>
																	</svg>
																	<span style={{ marginLeft: 10 }}> Items</span>
																</button>
																<button
																	className="btn btn-primary btn-outlined-main"
																	style={{ width: '100%', marginRight: 10 }}
																	disabled={
																		item.purchase_list &&
																		item.purchase_list[item.purchase_list.length - 1] &&
																		item.purchase_list[item.purchase_list.length - 1].stocks_count > 0
																			? false
																			: true
																	}
																	onClick={(e) => {
																		if (
																			item.purchase_list &&
																			item.purchase_list[item.purchase_list.length - 1] &&
																			item.purchase_list[item.purchase_list.length - 1].stocks_count > 0
																		) {
																			e.stopPropagation();
																			downloadImei(item.id);
																		}
																	}}
																>
																	<HiOutlineDownload size={18} />
																	<span style={{ marginLeft: 10 }}> IMEI</span>
																</button>
																<button
																	className="btn btn-primary btn-outlined-main"
																	style={{ width: '100%' }}
																	disabled={item.has_AWB == 1 ? false : true}
																	onClick={(e) => {
																		if (item.has_AWB == 1) {
																			e.stopPropagation();
																			downloadAWB(item.id);
																		}
																	}}
																>
																	<FaRegFileLines size={16} />
																	<span style={{ marginLeft: 10 }}> AWB</span>
																</button>
															</>
														</div>
													)}
												</div>
											))}
										</div>
										<div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
											<Pagination pagination={pagination} data={handleData} />
										</div>
									</>
								) : (
									<>
										<div style={{ width: '100%' }}>
											<div className="no-hover" style={{ width: '100%' }}>
												<div className="whitebg">
													<div className="not-found-container ">
														<IoCartOutline size={120} color="black" />

														<h6
															className="not-found-text"
															style={{ fontStyle: 'italic', color: '#000', fontSize: '18px', marginTop: 10, paddingBottom: 5 }}
														>
															Add your first order and enjoy the convenience!
														</h6>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</>
						)}
					</div>
				) : (
					<></>
				)}
				{agreementPopup && (
					<AgreementModel id={id} openAgreementPopup={agreementPopup} pending={pending} handleClose={closeagreementPopup} />
				)}
				<InvoiceModal show={showInvoiceItems} handleClose={() => setShowInvoiceItems(false)} selectedIdDetails={selectedIdDetails} />
				<AWBModal show={showAWBModal} handleClose={() => setShowAWBModal(false)} selectedOrder={selectedOrder} />
				<SearchBar searchField={searchField} handleSearch={handleSearch} autoSuggest={autoSuggest} />
			</div>
		</>
	);
};

export default Orders;
