import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function PrivacyModeContent({ children, notUserRestricted }) {
	const hidden = useSelector((state) => state.main.privacyMode);
	const user = useSelector((state) => state.main.user);

	const replacedChildren = String(children).replaceAll(/[\s\S]/g, 'x');

	return (
		<>
			{hidden || (!notUserRestricted && user?.email == 'accounts@glpwireless.com') ? (
				<span style={{ filter: 'blur(3px)', ' -webkit-filter': 'blur(3px)' }}>
					{replacedChildren}
				</span>
			) : (
				children
			)}
		</>
	);
}

export default PrivacyModeContent;
