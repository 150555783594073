import { lazy } from 'react'

// project imports
// import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import Loadable from 'ui-component/Loadable'
import NewLogin from 'views/pages/authentication/NewLogin'
import ForgotPassword from 'views/pages/authentication/ForgotPassword'
import ForgotPasswordOTP from 'views/pages/authentication/ForgotPasswordOTP'
import NewPassword from 'views/pages/authentication/NewPassword'
import SetPassword from 'views/pages/authentication/SetPassword'

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login')))

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
	path: '/',
	element: (
		<NavMotion>
			{/* <GuestGuard> */}
			<MinimalLayout />
			{/* </GuestGuard> */}
		</NavMotion>
	),
	children: [
		{
			path: '/',
			element: <NewLogin />
		},
		{
			path: '/login',
			element: <NewLogin />
		},
		{
			path: '/forgot',
			element: <ForgotPassword />
		},
		{
			path: '/forgot-password-otp',
			element: <ForgotPasswordOTP />
		},
		{
			path: '/new-password',
			element: <NewPassword />
		},
		{
			path: '/setPassword',
			element: <SetPassword />
		}

		// {
		//     path: '/forgot',
		//     element: <AuthForgotPassword />
		// }
	]
}

export default LoginRoutes
