import React, { useState, useEffect, useRef } from 'react';
import 'react-phone-number-input/style.css';
import { useNavigate, useParams } from 'react-router';
import Scroller from 'ui-component/Scroller';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'store/reducers/mainSlice';
import { FaPlus, FaSave } from 'react-icons/fa';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import Select from 'react-select';

// import './contactsforms.scss';
import toast from 'react-hot-toast';

const contactTypes = [
	{ label: 'Customer', value: 'customer' },
	{ label: 'Lead', value: 'lead' },
	{ label: 'Supplier', value: 'supplier' },
	{ label: 'Transit Company', value: 'transit-company' }
];

const yesOrNoOptions = [
	{ label: 'Yes', value: 1 },
	{ label: 'No', value: 0 }
];
const statusOptions = [
	{ label: 'Active', value: 1 },
	{ label: 'Inactive', value: 0 }
];

const WarehouseForm = ({ edit }) => {
	const { id } = useParams();
	const history = useNavigate();
	const dispatch = useDispatch();
	const nameRef = useRef();
	const [alertMessage, setAlertmessage] = useState({});
	const [warehouseInfo, setWarehouseInfo] = useState({
		name: '',
		canAcceptOrders: 0,
		isDefault: 0,
		canIncludeStock: 0,
		priority: '',
		status: 0,
	});
	const namehandle = (e) => {
		let inputValue = e.target.value;
		const words = inputValue.split(' ');
		const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.substr(1));
		inputValue = capitalizedWords.join(' ');
		e.target.value = inputValue;
	};
	const handleChange = (val) => {
		setWarehouseInfo(val);
	};

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

	const handleSubmit = (e) => {
		if (edit && id) {
			e.preventDefault();
			let sameValue = false;
			if (warehouseInfo.name.trim() === '') {
				setAlertmessage({
					name: 'Warehouse Name is required.'
				});
				nameRef.current.focus();
			} else if (sameValue) {
				goback();
			} else {
				setIsSubmitDisabled(true);

				toast.promise(
					api
						.updateWarehouse(id, {
							name: warehouseInfo.name,
							can_accept_orders: warehouseInfo.canAcceptOrders,
							is_default: warehouseInfo.isDefault,
							can_include_stock: warehouseInfo.canIncludeStock,
							status: warehouseInfo.status,
						})
						.then(
							(res) => {
								if (res.status) {
									history(`/warehouses/${res.data.id}`);
								} else {
									if (res.errors?.name) {
										setAlertmessage({
											name: res.errors.name
										});
										nameRef.current.focus();
									} else {
										setAlertmessage({
											failed: res.message
										});
									}
								}

								setIsSubmitDisabled(false);
								return res?.status ? res : null;
							},
							() => {
								setIsSubmitDisabled(false);
							}
						),
					{
						loading: 'Updating Warehouse...',
						success: (data) => `${data.message}`,
						error: <b>Failed to update warehouse</b>
					}
				);
			}
		} else {
			e.preventDefault();
			if (warehouseInfo.name.trim() === '') {
				setAlertmessage({
					name: 'Warehouse Name is required.'
				});
				nameRef.current.focus();
			} else {
				setIsSubmitDisabled(true);

				toast.promise(
					api
						.addWarehouse({
							name: warehouseInfo.name,
							can_accept_orders: warehouseInfo.canAcceptOrders,
							is_default: warehouseInfo.isDefault,
							can_include_stock: warehouseInfo.canIncludeStock,
						})
						.then(
							(res) => {
								if (res.status) {
									history(`/warehouses/${res.data.id}`);
								} else {
									if (res.errors?.name) {
										setAlertmessage({
											name: res.errors?.name
										});
										nameRef.current.focus();
									} else {
										setAlertmessage({
											failed: res.message
										});
									}
								}
								setIsSubmitDisabled(false);
								return res?.status ? res : null;
							},
							() => {
								setIsSubmitDisabled(false);
							}
						),
					{
						loading: 'Creating Warehouse...',
						success: (data) => `${data.message}`,
						error: <b>Failed to create warehouse</b>
					}
				);
			}
		}
	};

	useEffect(() => {
		if (edit) {
			getSelectedWarehouse();
		}
	}, []);

	const getSelectedWarehouse = () => {
		setLoading(true);
		api.getWarehouseDetails(id).then((res) => {
			setLoading(false);
			if (!res.data) {
				setLoading(true);
				history('/warehouses');
			} else {
				const loadData = res?.data;
				let tempdata = {
					name: loadData?.name || '',
					canAcceptOrders: loadData?.can_accept_orders || 0,
					isDefault: loadData?.is_default || 0,
					canIncludeStock: loadData?.can_include_stock || 0,
					status: loadData?.status || 0,
				};
				setWarehouseInfo(tempdata);
				setLoading(true);
			}
		});
	};

	const goback = () => {
		history('/warehouses');
	};
	return (
		<>
			<div className="row m-0 create-warehouse-container">
				<div className="card w-100 h-100">
					<form action="" className="h-100" onSubmit={handleSubmit}>
						<div className="section-head m-0 p-0">
							<h5 className="m-0 p-0 pb-2" style={{ fontWeight: '500' }}>
								{edit ? 'Edit Warehouse' : 'Create Warehouse'}
							</h5>
						</div>
						<Scroller>
							<div className="form-section">
								<div className="form-section-head">
									<h4>General Information</h4>
								</div>

                                <div className="form-group-error">
                                    {alertMessage.contactType && (
                                        <>
                                            <span className="error-message text-danger">{alertMessage.contactType}</span>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="form-group-body">
                                    <label htmlFor="">
                                        Name <sup className="text-danger">*</sup>
                                    </label>
                                    <div>
                                        <input
                                            ref={nameRef}
                                            type="text"
                                            className="form-control"
                                            id="inputName"
                                            maxLength={50}
                                            value={warehouseInfo?.name}
                                            onChange={(e) => {
                                                namehandle(e);
                                                handleChange({ ...warehouseInfo, name: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group-error">
                                    {alertMessage.name && (
                                        <>
                                            <span className="error-message text-danger">{alertMessage.name}</span>
                                        </>
                                    )}
									{alertMessage.failed && <span className="px-3 down-error text-danger">{alertMessage.failed}</span>} 
                                </div>
                            </div>


                            <div className="form-group">
                                <div className="form-group-body">
                                    <label htmlFor="">Accept orders for this warehouse</label>
                                    <div>
                                        <Select
                                            menuPosition={'fixed'}
                                            options={yesOrNoOptions}
                                            value={yesOrNoOptions?.find((y) => y?.value == warehouseInfo?.canAcceptOrders)}
                                            className="rs-container"
                                            classNamePrefix="rs"
                                            placeholder=""
                                            onChange={(e) => {
                                                handleChange({ ...warehouseInfo, canAcceptOrders: e.value });
                                            }}
                                        />
                                        <div className="textGray font10">If no, you cannot create orders for this warehouse.</div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="form-group-body">
                                    <label htmlFor="">Selected by default</label>
                                    <div>
                                        <Select
                                            menuPosition={'fixed'}
                                            options={yesOrNoOptions}
                                            value={yesOrNoOptions?.find((y) => y?.value == warehouseInfo?.isDefault)}
                                            className="rs-container"
                                            classNamePrefix="rs"
                                            placeholder=""
                                            onChange={(e) => {
                                                handleChange({ ...warehouseInfo, isDefault: e.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="form-group-body">
                                    <label htmlFor="">Stock is included in general stock</label>
                                    <div>
                                        <Select
                                            menuPosition={'fixed'}
                                            options={yesOrNoOptions}
                                            value={yesOrNoOptions?.find((y) => y?.value == warehouseInfo?.canIncludeStock)}
                                            className="rs-container"
                                            classNamePrefix="rs"
                                            placeholder=""
                                            onChange={(e) => {
                                                handleChange({ ...warehouseInfo, canIncludeStock: e.value });
                                            }}
                                        />
                                        <div className="textGray font10">If the stock in the warehouse will count for the general (free) stock of the products. This is also what is pushed to webshops.</div>
                                    </div>
                                </div>
                            </div>
                            

                            {edit && (
                                <div className="form-group">
                                    <div className="form-group-body">
                                        <label htmlFor="">Status</label>
                                        <div>
                                            <Select
                                                menuPosition={'fixed'}
                                                options={statusOptions}
                                                value={statusOptions?.find((y) => y?.value == warehouseInfo?.status)}
                                                className="rs-container"
                                                classNamePrefix="rs"
                                                placeholder=""
                                                onChange={(e) => {
                                                    handleChange({ ...warehouseInfo, status: e.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group-error">
                                        {alertMessage.transitType && (
                                            <>
                                                <span className="error-message text-danger">{alertMessage.transitType}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}


						</Scroller>
						<div className="non-scroll-btn-section">
							<div className="btnarea">
								{/* <button onClick={goback} className="btn btn-link">
									Cancel
								</button> */}
								{edit ? (
									<PermissionAllowed permissions={['Save Warehouse Details (Action)']}>
										<button
											type="submit"
											className="btn btn-main"
											disabled={isSubmitDisabled}
										>
											<FaSave /> Save Changes
										</button>
									</PermissionAllowed>
								) : (
									<PermissionAllowed permissions={['Create New Warehouse (Action)']}>
										<button
											type="submit"
											className="btn btn-main"
											disabled={isSubmitDisabled}
										>
											<FaPlus /> Create
										</button>
									</PermissionAllowed>
								)}
							</div>
							{/* {alertMessage.success && <span className="px-3 down-error text-success">{alertMessage.success}</span>}
							{alertMessage.failed && <span className="px-3 down-error text-danger">{alertMessage.failed}</span>} */}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default WarehouseForm;
