// TopCustomers.js
import React from 'react';
import PrivacyModeContent from 'ui-component/PrivacyModeContent'; // Import this if it's in a different file
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

const TopCustomers = ({ topCustomer, isAdmin }) => {
	return (
		topCustomer.length > 0 && (
			<div className="col-lg-3 col-md-6 col-sm-12">
				<Fade>
					<div
						className="card"
						style={{ height: isAdmin == true ? '280px' : '270px', marginBottom: '20px', padding: '10px', paddingTop: '5px' }}
					>
						<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: '10px' }}>
							<a style={{ fontWeight: '500' }}>Top 10 Customers</a>
						</div>
						<div
							className="card-body custom-no-scroll"
							style={{ paddingTop: 5, paddingBottom: 0, paddingRight: 15, overflowY: 'auto', maxHeight: '200px' }}
						>
							{topCustomer.map((item, index) => (
								<Link to={`/contacts/${item.customer_id}`} style={{ color: 'black', textDecoration: 'none' }}>
									<div className="row hover-effect" key={index} style={{ paddingBottom: '4px', paddingTop: '3px' }}>
										<div className="col-6 top-clients">
											<div
												style={{
													whiteSpace: 'nowrap',
													fontWeight: 100
												}}
											>
												<PrivacyModeContent>{item.contact_name.split(' ').slice(0, 2).join(' ')}</PrivacyModeContent>
											</div>
										</div>
										<div className="col-6 top-clients" style={{ textAlign: 'right' }}>
											<a style={{ fontWeight: 600 }}>
												<PrivacyModeContent>$ {item.total_purchase.toLocaleString()}</PrivacyModeContent>
											</a>
										</div>
									</div>
								</Link>
							))}
						</div>
					</div>
				</Fade>
			</div>
		)
	);
};

export default TopCustomers;
