import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { FaPlus, FaSave } from 'react-icons/fa';
import Scroller from 'ui-component/Scroller';
import { Dropdown } from 'react-bootstrap';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import StockProductsModal from './modals/StockProductsModal';
import UpdateReturnProductModal from './modals/UpdateReturnProductModal';
import { TbDatabaseExclamation } from 'react-icons/tb';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import usePermissions from 'hooks/usePermissions';
import { Checkbox, Tooltip } from '@mui/material';

const ReturnsReturnedProductsComponent = ({
	returnsDetail,
	disableProductBtn,
	handleSetImeis,
	handleLoadRecord,
	newReturnedDetails,
	isMobile
	// openModalFormWarehouse
}) => {
	const topSection = useRef(null);
	const [allPermissions, hasEveryPermission] = usePermissions();
	const [openPopup, setOpenPopup] = useState(false);
	const [openProductPopup, setOpenProductPopup] = useState(false);
	const [isDisable, setDisable] = useState(false);
	const [imeiLists, setImeiLists] = useState([]);
	const [productDetail, setProductDetail] = useState([]);
	const [selectedImeis, setSelectedImeis] = useState([]);
	const [disableReceiveBtn, setDisableReceiveBtn] = useState(true);
	const [showDelete, setShowDelete] = useState('');
	const [showCreationDelete, setShowCreationDelete] = useState('');
	const [showCreationDeleteIndex, setShowCreationDeleteIndex] = useState(0);
	const [selectedReturnProduct, setSelectedReturnProduct] = useState('');
	const [isReturnedChecked, setIsReturnedChecked] = useState(false);
	const [isBackToStockEnabled, setIsBackToStockEnabled] = useState(false);

	const handleOpenModal = (estimationId) => {
		setOpenPopup(true);
	};

	const closePopup = () => {
		setOpenPopup(false);
		setOpenProductPopup(false);
		setShowDelete(false);
		setShowCreationDelete(false);
	};

	const handleSave = (data) => {
		console.log(data);
		api.getClientInvoiceNumberCustomer(data?.estimationId).then((res) => {
			setInvoiceDetails({
				estimationId: res.data?.id,
				invoiceNumber: res.data?.invoice_number,
				customerId: res.data?.customer_id,
				customerName: res.data?.customer_name
			});
			handleSetEstimationId(res.data?.id);

			setReturnsDetail({
				...returnsDetail,
				estimationId: res.data?.id,
				invoiceNumber: res.data?.invoice_number,
				customerId: res.data?.customer_id,
				customerName: res.data?.customer_name
			});
		});
		setOpenPopup(false);
	};

	const handleSelectedProduct = (data) => {
		setSelectedImeis(data);
		newReturnedDetails(data);
		setOpenProductPopup(false);
	};

	const handleEditReturnedProductPopup = (item) => {
		setOpenProductPopup(true);
		setProductDetail(item);
	};

	const handleDeleteProduct = (item, index) => {
		if (returnsDetail?.id) {
			setShowDelete(item);
			setSelectedReturnProduct(item?.id);
		} else {
			setShowCreationDelete(item);
			setShowCreationDeleteIndex(index);
		}
	};

	const handleCreationDelete = () => {
		const newSelectedImeis = [...selectedImeis]; // Create a copy of the array
		newSelectedImeis.splice(showCreationDeleteIndex, 1); // Remove the record at the specified index
		setSelectedImeis(newSelectedImeis); // Update the state
		setShowCreationDelete('');
		setShowCreationDeleteIndex(0);
	};

	const handleCheckAllReturned = () => {
		setIsReturnedChecked(!isReturnedChecked);
		setIsBackToStockEnabled(!isReturnedChecked);
	};

	const handleDelete = () => {
		toast.promise(
			api.deleteReturnedProductsClient(selectedReturnProduct).then((res) => {
				if (res.status) {
					setShowDelete('');
					setSelectedReturnProduct('');
					handleLoadRecord();
				}
				return res?.status ? res : null;
			}),
			{
				loading: 'Deleting Product Returns...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete product returns</b>
			}
		);
	};
	const handleImeis = (data, action) => {
		setDisableReceiveBtn(false);
		if (returnsDetail?.id) {
			toast.promise(
				api.addReturnedProductsClient(returnsDetail?.id, data).then((res) => {
					handleLoadRecord();
					return res?.status ? res : null;
				}),
				{
					loading: 'Adding return products...',
					success: (data) => `${data.message}`,
					error: <b>Failed to add return products</b>
				}
			);
		} else {
			setSelectedImeis((prevImeis = []) => {
				const newData = [...prevImeis, ...data];
				handleSetImeis(newData); // passes the new data
				return data;
			});
		}
		if (action == 'empty') {
			setOpenPopup(true);
		} else {
			setOpenPopup(false);
		}
	};

	const handleUpdateRecord = () => {
		handleLoadRecord();
		setOpenProductPopup(false);
	};

	const handleBackToStockPopup = () => {};

	useEffect(() => {
		if (returnsDetail?.estimationId) {
			setSelectedImeis(returnsDetail?.returns);
		}
	}, [returnsDetail]);

	return (
		<>
			<div className="card card-shadow p-2 card-box-shadow">
				<div className="row inbounding-imeis">
					<div className="col-xl-12 col-lg-12">
						<div className="row">
							<div className="col-12">
								<div className="d-flex align-items-center justify-content-between TableSerachBar">
									<div className={selectedImeis?.length > 0 ? 'returns-section-header' : 'returns-section-header gray'}>
										Returns
										{/* <Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} /> */}
									</div>
									{(!returnsDetail?.status || returnsDetail?.status == 'For Approval') && (
										<div className="form-inline navbar-search">
											<button
												className="bulk-action btn-outlined-main"
												onClick={() => {
													handleOpenModal(returnsDetail?.estimationId);
												}}
												disabled={disableProductBtn}
											>
												<FaPlus style={{ fontSize: '10px' }} /> Add Product
											</button>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								{selectedImeis?.length > 0 && (
									<>
										<Scroller extraHeight={topSection?.current?.clientHeight || 0}>
											<table className="table text-gray-800">
												<thead>
													<tr>
														{!isMobile && <th scope="col" width="8%"></th>}
														<th scope="col" width="35%" className="font14">
															Product
														</th>
														<th scope="col" width="15%" className="font14">
															Price
														</th>
														<th scope="col" width="42%" className="font14">
															Reason
														</th>
														{/* <th scope="col"></th> */}
													</tr>
												</thead>
												<tbody>
													{console.log(selectedImeis)}
													{selectedImeis?.map((item, index) => {
														return (
															<tr>
																{!isMobile && (
																	<td
																		className="text-center"
																		onClick={() => {
																			if (returnsDetail?.status === 'For approval') {
																				handleEditReturnedProductPopup(item);
																			}
																		}}
																		style={{
																			cursor: returnsDetail?.status === 'For approval' ? 'pointer' : 'not-allowed'
																		}}
																	>
																		<img
																			style={{ height: '50px' }}
																			src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwWIVyAdRMA4aS5YN8LJb3JE6OatJn71YZfpZM0ck55rJXBZluV0Fc0BC7QepmxY4RAQY&usqp=CAU"
																		/>
																	</td>
																)}
																<td
																	className="font14 v-align-middle"
																	onClick={() => {
																		if (returnsDetail?.status === 'For approval') {
																			handleEditReturnedProductPopup(item);
																		}
																	}}
																	style={{
																		cursor: returnsDetail?.status === 'For approval' ? 'pointer' : 'not-allowed'
																	}}
																>
																	<label>{item?.imei}</label>
																	<div style={{ marginTop: '-8px' }} className="textGray">
																		{item?.product?.product_name}
																	</div>
																</td>
																<td
																	className="font14 v-align-middle"
																	onClick={() => {
																		if (returnsDetail?.status === 'For approval') {
																			handleEditReturnedProductPopup(item);
																		}
																	}}
																	style={{
																		cursor: returnsDetail?.status === 'For approval' ? 'pointer' : 'not-allowed',
																		whiteSpace: 'nowrap'
																	}}
																>
																	{item?.purchase_list?.currency == 'eur'
																		? `€ ${item?.product?.price}`
																		: item?.purchase_list?.currency == 'usd'
																		? `$ ${item?.product?.price}`
																		: item?.purchase_list?.currency == 'aed'
																		? `د.إ ${item?.product?.price}`
																		: `${item?.purchase_list?.currency} ${item?.product?.price}`}
																</td>
																<td
																	className="font14 v-align-middle"
																	onClick={() => {
																		if (returnsDetail?.status === 'For approval') {
																			handleEditReturnedProductPopup(item);
																		}
																	}}
																	style={{
																		cursor: returnsDetail?.status === 'For approval' ? 'pointer' : 'not-allowed'
																	}}
																>
																	{item?.repair_reason}{' '}
																	{item?.repair_reason === 'Others'
																		? item?.incorrect && item?.incorrect.length > 0
																			? item?.incorrect.map((inc) => inc.label).join(', ')
																			: item?.repairs.map((rep) => rep.label).join(', ')
																		: `(${
																				item?.incorrect && item?.incorrect.length > 0
																					? item?.incorrect.map((inc) => inc.label).join(', ')
																					: item?.repairs.map((rep) => rep.label).join(', ')
																		  })`}
																</td>

																{returnsDetail?.status == 'For Approval' && (
																	<td className="font14 v-align-middle text-center">
																		<a
																			href="javascript:void(0);"
																			onClick={() => {
																				// history(`/returnss/edit/${row?.id}`);
																				handleDeleteProduct(item, index);
																			}}
																		>
																			<svg
																				id="vuesax_linear_trush-square"
																				data-name="vuesax/linear/trush-square"
																				xmlns="http://www.w3.org/2000/svg"
																				width="20"
																				height="20"
																				viewBox="0 0 31.855 31.855"
																			>
																				<g id="trush-square">
																					<path
																						id="Vector"
																						d="M0,17.255V9.291C0,2.655,2.655,0,9.291,0h7.964c6.637,0,9.291,2.655,9.291,9.291v7.964c0,6.637-2.655,9.291-9.291,9.291H9.291C2.655,26.546,0,23.891,0,17.255Z"
																						transform="translate(2.655 2.655)"
																						fill="none"
																						stroke="#ff342d"
																						stroke-linecap="round"
																						stroke-linejoin="round"
																						stroke-width="2"
																					/>
																					<g id="Group" transform="translate(8.097 8.548)">
																						<path
																							id="Vector-2"
																							data-name="Vector"
																							d="M0,.438,1.779.265A51.533,51.533,0,0,1,6.942,0a86.992,86.992,0,0,1,8.72.438"
																							transform="translate(0 3.026)"
																							fill="none"
																							stroke="#ff342d"
																							stroke-linecap="round"
																							stroke-linejoin="round"
																							stroke-width="2"
																						/>
																						<path
																							id="Vector-3"
																							data-name="Vector"
																							d="M0,2.588.186,1.447C.319.624.425,0,1.9,0H4.181C5.654,0,5.76.65,5.893,1.447l.186,1.128"
																							transform="translate(4.792)"
																							fill="none"
																							stroke="#ff342d"
																							stroke-linecap="round"
																							stroke-linejoin="round"
																							stroke-width="2"
																						/>
																						<path
																							id="Vector-4"
																							data-name="Vector"
																							d="M11.932,0l-.571,8.76c-.093,1.367-.173,2.429-2.6,2.429H3.172c-2.429,0-2.509-1.062-2.6-2.429L0,0"
																							transform="translate(1.858 3.57)"
																							fill="none"
																							stroke="#ff342d"
																							stroke-linecap="round"
																							stroke-linejoin="round"
																							stroke-width="2"
																						/>
																					</g>
																					<path id="Vector-5" data-name="Vector" d="M0,0H31.855V31.855H0Z" fill="none" opacity="0" />
																				</g>
																			</svg>
																		</a>
																	</td>
																)}
															</tr>
														);
													})}
												</tbody>
											</table>
										</Scroller>
									</>
								)}
								{/* <div className="table-responsive-xl tableData customersList"> */}
								{/* </div> */}
							</div>
						</div>
						{returnsDetail?.status == 'Received' && (
							<div className="row">
								<div className="col-12">
									<div className="d-flex align-items-center justify-content-between TableSerachBar">
										<div className="font18"></div>
										<div className="form-inline navbar-search">
											<button
												className="bulk-action btn-outlined-main"
												onClick={() => {
													handleBackToStockPopup();
												}}
												disabled={!isBackToStockEnabled}
											>
												Add back to stock
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{openPopup && (
				<StockProductsModal
					openPopup={openPopup}
					returnsDetail={returnsDetail}
					handleImeis={handleImeis}
					handleClose={closePopup}
					handleSave={handleSave}
					disableStatus={isDisable}
				/>
			)}
			{openProductPopup && (
				<UpdateReturnProductModal
					openProductPopup={openProductPopup}
					returnsDetail={returnsDetail}
					handleImeis={handleImeis}
					productDetail={productDetail}
					handleUpdateRecord={handleUpdateRecord}
					handleClose={closePopup}
					handleSelectedProduct={handleSelectedProduct}
					handleSave={handleSave}
					disableStatus={isDisable}
				/>
			)}

			{showDelete && (
				<DeleteConfirmation
					show={showDelete}
					handleClose={closePopup}
					handleDelete={handleDelete}
					title="Confirm Delete"
					message={`Are you sure you want to delete ${showDelete?.imei}`}
				/>
			)}
			{showCreationDelete && (
				<DeleteConfirmation
					show={showCreationDelete}
					handleClose={closePopup}
					handleDelete={handleCreationDelete}
					title="Confirm Delete"
					message={`Are you sure you want to delete ${showCreationDelete?.imei}`}
				/>
			)}
		</>
	);
};

export default ReturnsReturnedProductsComponent;
