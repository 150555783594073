import React from 'react';
import '../estimation/estimateInvoice.scss';
import mgLogo from 'assets/images/mglogo.svg';
import cmLogo from 'assets/images/cmlogo.svg';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import { Skeleton } from '@mui/material';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

function CreditNoteDetailInvoice({ creditnotes, privacyMode }) {
	return (
		<div className="est-inv-container">
			<div className="row m-0">
				{creditnotes ? (
					<>
						{creditnotes?.transit_company?.name?.toLowerCase() == 'coolmix' ? (
							<>
								<div className="col-6 p-0">
									<div className="est-detail-logo" style={{ height: '36px' }}>
										<img src={cmLogo} height={'36px'} />
									</div>

									<div className="customer-details" style={{ marginTop: '20px' }}>
										<p className="m-0">
											<b>
												<PrivacyModeContent>{creditnotes?.customer?.name}</PrivacyModeContent>
											</b>
											<br />
											<PrivacyModeContent>{creditnotes?.customer?.address}</PrivacyModeContent> <br />
											<PrivacyModeContent>{`${creditnotes?.customer?.city}, ${creditnotes?.customer?.zipcode}`}</PrivacyModeContent>{' '}
											<br />
											<PrivacyModeContent>{creditnotes?.customer?.country}</PrivacyModeContent>
										</p>
									</div>
								</div>
								<div className="col-6 p-0">
									<div className="company-details text-right">
										<p className="m-0">
											<b>Mix Parts B.V.</b> <br />
											Industrieweg 24 C <br />
											5262 GJ Vught <br />
											The Netherlands <br />
											info@coolmix.eu <br />
											www.coomix.eu
										</p>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="col-6 p-0">
									<div className="est-detail-logo" style={{ height: '36px' }}>
										<img src={mgLogo} height={'36px'} />
									</div>

									<div className="customer-details" style={{ marginTop: '20px' }}>
										<p className="m-0">
											<b>
												<PrivacyModeContent>{creditnotes?.customer?.name}</PrivacyModeContent>
											</b>
											<br />
											<PrivacyModeContent>{creditnotes?.customer?.address}</PrivacyModeContent> <br />
											<PrivacyModeContent>{`${creditnotes?.customer?.city}, ${creditnotes?.customer?.zipcode}`}</PrivacyModeContent>{' '}
											<br />
											<PrivacyModeContent>{creditnotes?.customer?.country}</PrivacyModeContent>
										</p>
									</div>
								</div>
								<div className="col-6 p-0">
									<div className="company-details text-right">
										{/* <p className="m-0">
											<b> Mix Global FZCO</b> <br />
											Dubai Airport Freezone <br />
											4W Blok B, Office 636, Dubai <br />
											finance@trademixglobal.com <br />
											www.trademixglobal.com <br />
										</p> */}
									</div>
								</div>
							</>
						)}
					</>
				) : (
					<>
						<div className="col-6 p-0">
							<div className="est-detail-logo" style={{ height: '36px' }}>
								<Skeleton sx={{ height: '36px', width: '100px' }} />
							</div>

							<div className="customer-details" style={{ marginTop: '20px' }}>
								<Skeleton variant="text" />
								<Skeleton variant="text" />
								<Skeleton variant="text" />
							</div>
						</div>
						<div className="col-6 p-0">
							<div className="company-details text-right">
								<p className="m-0">
									<Skeleton variant="text" />
									<Skeleton variant="text" />
									<Skeleton variant="text" />
									<Skeleton variant="text" />
									<Skeleton variant="text" />
								</p>
							</div>
						</div>
					</>
				)}
			</div>

			<div className="row mx-0" style={{ marginTop: '50px', alignItems: 'center' }}>
				<div className="col-8 p-0">
					{creditnotes ? <h3 className="est-ref m-0">{creditnotes?.credit_note_number}</h3> : <Skeleton variant="rounded" />}
				</div>
				<div className="col-4 p-0">
					<table className="w-100">
						<tr>
							<td>Date:</td>
							<td className="text-right">{moment(creditnotes?.date).format(CommonDateOnlyFormat)}</td>
						</tr>
					</table>
				</div>
			</div>

			<div className="est-inv-items" style={{ marginTop: '50px' }}>
				<table className="w-100">
					<thead>
						<tr>
							<th>Invoice</th>
							<th>Item</th>
							<th className="text-center">Quantity</th>
							<th className="text-right">Unit Price</th>
							<th className="text-right">Total</th>
						</tr>
					</thead>
					<tbody>
						{creditnotes?.credit_note_details?.map((item) => (
							<tr>
								<td>{item?.invoice_number}</td>
								<td>{item?.product_name}</td>
								<td className="text-center">{item?.qty}</td>
								<td className="text-right">
									<CurrencyValue currencyObject={item?.converted_currency_unit_price} />
								</td>
								<td className="text-right">
									<CurrencyValue currencyObject={item?.converted_currency_total} />
								</td>
							</tr>
						))}

						{/* <tr>
							<td colSpan={2}>Shipping</td>
							<td className="text-center"> {creditnotes?.shipping_qty}</td>
							<td className="text-right">
								<CurrencyValue currencyObject={creditnotes?.converted_currency_shipping_fee} />
							</td>
							<td className="text-right">
								<CurrencyValue currencyObject={creditnotes?.converted_currency_total_shipping_fee} />
							</td>
						</tr> */}
						{/* {creditnotes?.vat_type == 'exclusive' && (
							<tr>
								<td colSpan={3}></td>
								<td className="text-right">VAT ({creditnotes?.vat_percent}%)</td>
								<td className="text-right font-weight-bold">
									<CurrencyValue currencyObject={creditnotes?.converted_currency_vat_amount} />
								</td>
							</tr>
						)} */}
						{/* <tr>
							<td colSpan={3}></td>
							<td className="text-right">Subtotal</td>
							<td className="text-right font-weight-bold">
								<CurrencyValue currencyObject={creditnotes?.converted_currency_sub_total} />
							</td>
						</tr> */}
						<tr>
							<td colSpan={3}></td>
							<td className="text-right">Total</td>
							<td className="text-right font-weight-bold">
								<CurrencyValue currencyObject={creditnotes?.converted_currency_total} />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default CreditNoteDetailInvoice;
