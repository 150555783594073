import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'react-phone-number-input/style.css';
import { useNavigate, useParams } from 'react-router';
import Scroller from 'ui-component/Scroller';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'store/reducers/mainSlice';
import { FaPlus, FaSave, FaSpinner } from 'react-icons/fa';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import ReactSelect from 'react-select';
import usePageTitle from 'hooks/usePageTitle';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { set } from 'draft-js/lib/EditorState';
import { CloseIcon } from 'ui-component/custom-icons/icons';



const SettingsCompanyDetails = ({ edit }) => {
	const { id } = useParams();
    const [setPageTitle] = usePageTitle('Settings');
    useEffect(() => {
        return () => {
            setPageTitle('Settings');
        };
	}, []);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isFormProcessing, setIsFormProcessing] = useState(false);
	const history = useNavigate();
    const [countryList, setCountryList] = useState([]);

	const [companyDetails, setCompanyDetails] = useState({
		id: '',
		name: '',
		address: '',
		zipCode: '',
		city: '',
		countryId: '',
		countryName: '',
		email: '',
		emailInvoice: '',
		contactNumber: '',
		website: '',
		vatNumber: '',
		logoUrl: '',
	});

	useEffect(() => {
		initializeData()
	}, []);

	const initializeData = () => {
		// setLoading(true);

        api.getSelectOptionsList('country').then((res) => {
            const collection = res?.data?.map((item) => ({ id: item.id, label: item.name, value: item.id }));
            setCountryList(collection);
		});
		
		api.getCompanyDetails().then((res) => {
			setLoading(false);
			if (res.status) {
				setCompanyDetails({
					...companyDetails,
					id: res.data?.id,
					name: res.data?.name,
					address: res.data?.address,
					zipCode: res.data?.zip_code,
					city: res.data?.city,
					countryId: res.data?.country?.id,
					countryName: res.data?.country?.name,
					email: res.data?.email,
					emailInvoice: res.data?.email_invoice,
					contactNumber: res.data?.contact_number,
					website: res.data?.website,
					vatNumber: res.data?.vat_number,
					logoUrl: res.data?.logo_url,
				})
				setIsDataLoaded(true);
			}
		});
	}


	const dispatch = useDispatch();
	const nameRef = useRef();
	const [alertMessage, setAlertmessage] = useState({});
	const [warehouseInfo, setWarehouseInfo] = useState({
		name: '',
		canAcceptOrders: 0,
		isDefault: 0,
		canIncludeStock: 0,
		priority: '',
		status: 0,
	});
	const [vatValidationAlert, setVatvalidationlert] = useState();
	const namehandle = (e) => {
		let inputValue = e.target.value;
		const words = inputValue.split(' ');
		const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.substr(1));
		inputValue = capitalizedWords.join(' ');
		e.target.value = inputValue;
	};
	const handleChange = (val) => {
		setWarehouseInfo(val);
	};

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png', 'svg'];
    const validateFileExtension = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
	};
	
	const onDrop = useCallback((acceptedFiles) => {
		// if (validateFileExtensionforLogo(acceptedFiles[0].name)) {
		// 	setData((prevData) => ({ ...prevData, companyLogo: acceptedFiles[0] }));
		// 	setLogo(acceptedFiles[0].name);
		// } else {
			toast.error('Invalid file extension. Please select a JPEG, JPG, PNG, or PDF file.');
		// }
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: '.pdf, .jpeg, .jpg, .png',
		multiple: false
	});
	
    const uploadAttachment = async (attachment) => {
        const formData = new FormData();
        formData.append('attachment', attachment);
        // formData.append('purchase_invoice_id', purchaseInvoice?.id);
        // formData.append('type', 'payment');

        try {

			toast.promise(
				api
					.uploadCompanyDetailsLogo(id, formData)
					.then(
						(res) => {
							if (res.status) {
                                initializeData();
								// setEstimaterow((oldData) => ({
								// 	...oldData,
								// 	notes: [
								// 		{
								// 			id: res?.data?.id,
								// 			details: noteInputValue,
								// 			date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
								// 			time: moment(res?.data?.created_at).format('HH:mm'),
								// 			user_name: userName,
								// 			expand: false
								// 		},
								// 		...oldData?.notes
								// 	]
								// }));
							}

							return res?.status ? res : null;
						},
						() => {}
					),
				{
					loading: 'uploading file...',
					success: (data) => `${data.message}`,
					error: <b>Failed to upload file</b>
				}
            );
            
        } catch (error) {
            toast.error('Error uploading file');
        }
    };
    
	const onPaymentDrop = useCallback((acceptedFiles) => {
		

        if (validateFileExtension(acceptedFiles[0].name)) {
            uploadAttachment(acceptedFiles[0]);
			setPaymentData((prevData) => ({ ...prevData, attachment: acceptedFiles[0] }));
			setPaymentAttachment(acceptedFiles[0].name);
		} else {
			toast.error('Invalid file extension. Please select a PDF, JPEG, JPG, or PNG file.');
		}
	}, []);

	const { getRootProps: getPaymentRootProps, getInputProps: getPaymentInputProps } = useDropzone({ onDrop: onPaymentDrop });

	const contactTypes = [
		{ label: 'Customer', value: 'customer' },
		{ label: 'Lead', value: 'lead' },
		{ label: 'Supplier', value: 'supplier' },
		{ label: 'Transit Company', value: 'transit-company' }
	];


	const handleSubmit = (e) => {
		if (companyDetails?.name?.trim() === '' || companyDetails?.name === null) {
			toast.error('Company name is required');
			return false;
		} else if (companyDetails?.address?.trim() === '' || companyDetails?.address === null) {
			toast.error('Address is required');
			return false;
		} else if (companyDetails?.zipCode?.trim() === '' || companyDetails?.zipCode === null) {
			toast.error('Zip code is required');
			return false;
		} else if (companyDetails?.city?.trim() === '' || companyDetails?.city === null) {
			toast.error('City is required');
			return false;
		} else if (companyDetails?.countryId === '' || companyDetails?.countryId === undefined) {
			toast.error('Country is required');
			return false;
		} else if (companyDetails?.email?.trim() === '' || companyDetails?.email === null) {
			toast.error('Email is required');
			return false;
		} else if (companyDetails?.emailInvoice?.trim() === '' || companyDetails?.emailInvoice === null) {
			toast.error('Email for invoices is required');
			return false;
		} else if (companyDetails?.contactNumber?.trim() === '' || companyDetails?.contactNumber === null) {
			toast.error('Contact number is required');
			return false;
		} else {
			setIsFormProcessing(true)

			toast.promise(
				api
					.updateCompanyDetails(companyDetails)
					.then(
						(res) => {
							setIsFormProcessing(false)
							return res?.status ? res : null;
						},
						() => {
							setIsSubmitDisabled(false);
						}
					),
				{
					loading: 'Updating Company Details...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update company details</b>
				}
			);
		}

	};
	
	return (
		<>
			<div className="row">
				<div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 col-md-6 offset-md-3 col-12 mt30 mb20">
					<div className="card w-100 h-100">
						<div className="card-body">

							
							<div className="section-head m-0 p-0">
								<h5 className="m-0 p-0 pb-2" style={{ fontWeight: '500' }}>
									Company Details
								</h5>
							</div>
							<Scroller>
								<div className="form-section">
									<div className="form-section-head">
									</div>

									<div className="form-group-error">
										{alertMessage.contactType && (
											<>
												<span className="error-message text-danger">{alertMessage.contactType}</span>
											</>
										)}
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												Company name <sup className="text-danger">*</sup>
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<input
											type="text"
											className="form-control"
											id="inputName"
											value={companyDetails?.name}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, name: e.target.value });
											}}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												Address <sup className="text-danger">*</sup>
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<input
											type="text"
											className="form-control"
											id="inputName"
											value={companyDetails?.address}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, address: e.target.value });
											}}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												Zip code <sup className="text-danger">*</sup>
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<input
											ref={nameRef}
											type="text"
											className="form-control"
											id="inputName"
											maxLength={50}
											value={companyDetails?.zipCode}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, zipCode: e.target.value });
											}}
										/>
									</div>
								</div>
								
								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												City <sup className="text-danger">*</sup>
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<input
											ref={nameRef}
											type="text"
											className="form-control"
											id="inputName"
											value={companyDetails?.city}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, city: e.target.value });
											}}
										/>
									</div>
								</div>
								
								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												Country <sup className="text-danger">*</sup>
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<ReactSelect
											className="rs-container"
											classNamePrefix="rs"
											name="transit-company"
											options={countryList}
											value={countryList?.find((c) => c?.value == companyDetails?.countryId)}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, countryId: e?.value, countryName: e?.label });
											}}
											menuPlacement="auto"
											menuPosition="fixed"
											placeholder="Select or Type..."
											style={{ borderRadius: '18px' }}
										/>
									</div>
								</div>
								
								
								
								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												Email address <sup className="text-danger">*</sup>
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<input
											ref={nameRef}
											type="text"
											className="form-control"
											id="inputName"
											value={companyDetails?.email}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, email: e.target.value });
											}}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												Email address for invoices <sup className="text-danger">*</sup>
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<input
											ref={nameRef}
											type="text"
											className="form-control"
											id="inputName"
											value={companyDetails?.emailInvoice}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, emailInvoice: e.target.value });
											}}
										/>
									</div>
								</div>
								
								
								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												Contact number <sup className="text-danger">*</sup>
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<input
											ref={nameRef}
											type="text"
											className="form-control"
											id="inputName"
											value={companyDetails?.contactNumber}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, contactNumber: e.target.value });
											}}
										/>
									</div>
								</div>

								
								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												VAT number
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<input
											ref={nameRef}
											type="text"
											className="form-control"
											id="inputName"
											value={companyDetails?.vatNumber}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, vatNumber: e.target.value });
											}}
										/>
									</div>
								</div>
								

								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												Website
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">

										<input
											ref={nameRef}
											type="text"
											className="form-control"
											id="inputName"
											value={companyDetails?.website}
											onChange={(e) => {
												setCompanyDetails({ ...companyDetails, website: e.target.value });
											}}
										/>
									</div>
								</div>
								
								<div className="row">
									<div className="col-lg-4 col-md-4 col-12">

										<div className="text-right">
											<label htmlFor="">
												Logo
											</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12 form-group">
										{
											companyDetails?.logoUrl ? (	
												<>
													<div>
														<div className="card card-shadow">
															<div
																className="settings-logo-delete-container"
																onClick={() => { 
																	setCompanyDetails({ ...companyDetails, logoUrl: '' });
																}}
															>
																<CloseIcon height='13px' width='13px' />
															</div>
															<div className="card-body">
																<img src={companyDetails?.logoUrl} className='img-fluid' />
															</div>
														</div>
														
													</div>
												</>
											) : (
												<>
													<div {...getPaymentRootProps()}>
								
														<div
															className="add-item-empty-div"
															style={{ alignItems: 'center' }}
														>
															<button type="button" className="btn btn-primary btn-outlined-main-primary dragText" >
																<FaPlus />
																Add File
															</button>
															<span style={{ marginLeft: '10px' }}>or drag file...</span>
														</div>
														<input {...getPaymentInputProps({ disabled: vatValidationAlert !== true })} style={{ display: 'none' }} />{' '}
													</div>
												</>
											)										
										}
									</div>
								</div>
								
								<div className="row">
									<div className="col-12 text-center d-flex align-items-center justify-content-center">

										<PermissionAllowed permissions={['Save Warehouse Details (Action)']}>
											<button
												type="button"
												className="btn btn-main"
												disabled={!isDataLoaded || isFormProcessing}
												onClick={() => {
													if (!isFormProcessing) {
														handleSubmit()
													}
												}}
											>
												{
													isFormProcessing ? (
														<>
															<FaSpinner className="fa-spin" /> Saving...
														</>
													) : (
														<>
															<FaSave /> Save Details
														</>
													)
												}
												
											</button>
										</PermissionAllowed>
									</div>
								</div>



							</Scroller>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SettingsCompanyDetails;
