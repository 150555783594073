import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setSelectedItem, setStateUserList, storeInLocalStore, setStateUserRole } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import IconSearch from 'assets/images/magnifying-glass.svg';
import { Dropdown } from 'react-bootstrap';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import { FaEye, FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { LiaTimesSolid } from 'react-icons/lia';
import UserPermissions from 'views/settings/UserPermissions';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import { RiShieldUserLine } from 'react-icons/ri';
import { ImSpinner2 } from 'react-icons/im';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import toast from 'react-hot-toast';

const Parts = () => {
	const history = useNavigate();
	const [userList, setUserList] = useState([]);
	const [partsList, setPartsList] = useState([]);
	const [show, setShow] = useState('');
	const [roleslist, setRoleslist] = useState([]);
	const [pagination, setpagination] = useState([]);
	const [isLoading, setIsloading] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const [stateUserlist, setStateUserlist] = useState();
	const [statePartslist, setStatePartslist] = useState();
	const staterole = useSelector((state) => state.main.setstateuserrole);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([
		{
			label: 'Model',
			name: 'model_id',
			options: [
				{ label: 'iPhone 14', value: 1 },
				{ label: 'iPhone 13', value: 0 },
				{ label: 'iPhone 12', value: 0 }
			]
		}
	]);
	const [activeFilters, setActiveFilters] = useState({});

	const [userPermissions, setUserPermissions] = useState();

	// const getUserlist = async () => {
	// 	setIsloading(true);
	// 	const response = await api.getUserList();
	// 	if (response && response.status) {
	// 		setUserList(response.data.data);
	// 		setpagination(response.data);
	// 		setStateUserlist(response.data);
	// 		setIsloading(false);
	// 	} else {
	// 		setIsloading(false);
	// 	}
	// };
	const getPartslist = async () => {
		setIsloading(true);
		const response = await api.getPartsList();
		if (response && response.status) {
			setPartsList(response.data.data);
			setpagination(response.data);
			setStateUserlist(response.data);
			setIsloading(false);
		} else {
			setIsloading(false);
		}
	};
	const getAllrole = () => { };
	const deleteuserData = (data) => {
		setShow(data);
	};
	const handleSearch = (e) => {
		const name = e.target.value;
		if (name.length > 2) {
			setTimeout(() => {
				setIsloading(true);
				api.searchuser(name).then((res) => {
					setIsloading(false);
					setUserList(res.data.data);
					setpagination(res.data);
				});
			}, 500);
		}
		if (name.length == 0) {
			// getUserlist();
			getPartslist();
		}
	};
	const handleClose = () => setShow('');

	const [loadingPermissionId, setLoadingPermissionId] = useState();

	const openPermissions = (id) => {
		setLoadingPermissionId(id);
		api.getUserPermissionsList(id).then((res) => {
			if (res?.status) {
				setUserPermissions({
					user: userList?.find((u) => u?.id == id),
					permissions: res?.data
				});
			}
			setLoadingPermissionId(null);
		});
	};

	const handleDelete = (id) => {
		toast.promise(
			api.deleteUser(id).then((res) => {
				if (res.status) {
					// getUserlist();
					setShow(null);
					return res?.status ? res : abc;
				}
			}),
			{
				loading: 'Deleting User...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete user</b>
			}
		);
	};
	const viewPartsData = (id) => {
		const currentParts = userList.filter((item) => item.id === id);
		dispatch(setSelectedItem(currentParts[0]));
		history(`/inventory/parts/${id}`);
	};
	const updateUser = (id) => {
		const currentParts = userList.filter((item) => item.id === id);
		dispatch(setSelectedItem(currentParts[0]));
		history(`/settings/user/edit/${id}`);
	};
	useEffect(() => {
		console.log(staterole);
		if (!staterole) {
			getAllrole();
		} else {
			setRoleslist(staterole);
		}
	}, [staterole]);
	useEffect(() => {
		// getUserlist();
		getPartslist();
	}, []);
	const roleFilter = (e) => {
		setIsloading(true);
		api.filterbyRoles({
			role: e.target.value
		}).then((res) => {
			setIsloading(false);
			setUserList(res.data.data);
			setpagination(res.data);
		});
	};
	const handleData = (data) => {
		setUserList(data.data);
		setpagination(data);
	};
	return (
		<>
			<div className="row " style={{ height: '100%' }}>
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head">
						<h2>Parts</h2>
						<div className="actions">
							<Link to={'/inventory/parts/create'} className="btn btn-primary btn-outlined-main">
								<FaPlus /> New Parts
							</Link>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="estimation-table-area">
							<div className="table-side">
								<div className="table-responsive-xl tableData UserListing p-2">
									<Scroller>
										<table className="table  min-width-unset">
											<thead>
												<tr>
													<th scope="col" width="">
														Parts Name
													</th>
													<th scope="col" width="">
														Quantity
													</th>
													<th scope="col">For Model</th>
													<th scope="col">For Repair Type</th>
												</tr>
											</thead>
											<tbody>
												{isLoading ? (
													<>
														{[...Array(5)].map((e, i) => (
															<tr>
																{[...Array(7)].map((e, i) => (
																	<td>
																		<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																	</td>
																))}
															</tr>
														))}
													</>
												) : (
													<>
														{partsList?.map((row, index) => {
															const maxLength = 20;
															return (
																<tr
																	key={index}
																	className={userPermissions?.user?.id == row?.id ? 'selected-row' : ''}
																>
																	<td className="align-middle"> {row?.name}</td>
																	<td className="align-middle">{row?.quantity}</td>
																	<td className="align-middle">{row?.model?.name}</td>
																	<td className="align-middle">{row?.repair_type?.name}</td>

																	<td className="align-middle text-center action-col">
																		<span
																			className="btnEdit plain-iconbtn"
																			onClick={() => {
																				viewPartsData(row.id);
																			}}
																		>
																			<FaEye />
																		</span>
																	</td>
																</tr>
															);
														})}
														{partsList?.length < 1 && (
															<tr className="no-hover">
																<td colSpan={7}>
																	<div className="not-found-container">
																		<div className="not-found-icon">
																			<TbDatabaseExclamation />
																		</div>
																		<h6 className="not-found-text">No Data Available</h6>
																	</div>
																</td>
															</tr>
														)}
													</>
												)}
											</tbody>
										</table>
									</Scroller>
								</div>
								<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsloading(e)} />
							</div>

							{userPermissions && (
								<div className="estimate-detail-container">
									<div className="row m-0 p-2">
										<div className="col-12">
											<div className="d-flex justify-content-between align-items-center">
												<h6 className="est-title">
													Permissions - {userPermissions?.user?.first_name} {userPermissions?.user?.last_name}
												</h6>
												<LiaTimesSolid style={{ cursor: 'pointer' }} onClick={() => setUserPermissions(null)} />
											</div>
											<UserPermissions onClose={() => setUserPermissions(null)} data={userPermissions} />
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			{show && (
				<DeleteConfirmation
					show={show}
					handleClose={handleClose}
					handleDelete={handleDelete}
					title="Confirm"
					message={`Are you sure you want to delete ${show?.first_name} ${show?.last_name || ''}`}
				/>
			)}
		</>
	);
};

export default Parts;

function CustomToggle({ children, onClick }) {
	return (
		<>
			<a
				className="dropdown-toggle"
				href="#"
				role="button"
				id="dropdownMenuLink"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				onClick={onClick}
			>
				{children}
			</a>
		</>
	);
}
