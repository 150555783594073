import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
	// Add Customer
	addCustomer(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/customers`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	// Get Customer
	getCustomer() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/customers`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	getCustomerFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/customers/filter/get`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	updateCustomerStatus(id, status) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/customers/update-status/${id}`,
					{ status: status ? 'activate' : 'suspend' },
					{
						headers: {
							Authorization: `Bearer ${
								JSON.parse(localStorage.getItem('user'))?.token ||
								JSON.parse(sessionStorage.getItem('user'))?.token ||
								token
							}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	// search customer
	searchCustomer(query, status) {
		return this.handelAlert((res, rej, loadObj) => {
			const url = status ? `${base}/api/customers?search=${query}&status=${status}` : `${base}/api/customers?search=${query}`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	selectAllCustomer() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/customers?type=all&&status=1`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	filterCustomer(filters, search = null) {
		let url = `${base}/api/customers?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getCustomerbyId(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/customers/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getCustomerEstimates(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/customers/${id}/estimates`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getSellingInvoice(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/customer/invoice/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	deleteCustomer(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/customers/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	searchCustomer(name) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/customers?search=${name}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	updateCustomer(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/customers/${id}`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	assignCustomerSalesPerson(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/customers/${id}/assign-sales-person`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	// vatNumberValidate(vatNumber) {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.get(`https://api.vatcheckapi.com/v2/check?vat_number=${vatNumber}&apikey=MAR7rBhsQOF072UFWtjDYqBGfiQcU3NxARKqrHtJ`
	// 			)
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },

	// Supplier API
	// Add Customer
	addSupplier(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/suppliers`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	// Get Customer
	getSupplier() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/suppliers`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getSupplierEstimates(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/suppliers/${id}/estimates`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getSupplierFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/suppliers/filter/get`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	updateSupplierStatus(id, status) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/suppliers/update-status/${id}`,
					{ status: status ? 'activate' : 'suspend' },
					{
						headers: {
							Authorization: `Bearer ${
								JSON.parse(localStorage.getItem('user'))?.token ||
								JSON.parse(sessionStorage.getItem('user'))?.token ||
								token
							}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	selectAllSuppller() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/suppliers?type=all&&status=1`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	// selectSupplierStatus(query) {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.get(`${base}/api/suppliers?type=all&&status=1`, {
	// 				headers: {
	// 					Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user'))?.token) || token}`
	// 				}
	// 			})
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				res(rej.response.data);
	// 			});
	// 	}, false);
	// },

	getSupplierbyId(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/suppliers/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	deleteSupplier(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/suppliers/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	updateSupplier(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/suppliers/${id}`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	searchSupplier(name) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/suppliers?search=${name}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	filterSupplier(filters, search = null) {
		let url = `${base}/api/suppliers?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	vatValidation(vatNumber) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`https://api.vatcheckapi.com/v2/check?vat_number=${vatNumber}&apikey=g6Rjq3yM5FJ78PLUfbXHAeAQhnB18h0xYAkxG4e8`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	}
};
