import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
    // GET API

	getTransactionLists() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transactions`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},

	getTransactionFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			const url = `${base}/api/transactions/filter`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
    },
    
    filterTransactions(filters, search = null) {
        let url = `${base}/api/transactions?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
	getTransactionDetails(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transactions/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
    },
    
	exportTransaction(filters) {
		let url = `${base}/api/transactions/export?`;

		Object.keys(filters).forEach((key) => {
			filters[key]?.value && (url += `${key}=${filters[key]?.value}&`);
	
		});
        url = url.endsWith('&') ? url.slice(0, -1) : url;
        
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(url, status, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					},
					responseType: 'blob'
				})
				.then((response) => {
					console.log(response);
					res(response.data);
				})
				.catch((rej) => {
					res(rej?.response?.status);
				});
		}, false);
	},

    deleteTransaction(id) {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .delete(`${base}/api/transactions/${id}`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
	// updateOperationsData(id, data) {
	// 	return this.handelAlert((res, rej, loadObj) => {
	// 		axios
	// 			.put(`${base}/api/operations/${id}/inbound`, data, {
	// 				headers: {
	// 					Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
	// 						}`
	// 				}
	// 			})
	// 			.then((response) => {
	// 				res(response.data);
	// 			})
	// 			.catch((rej) => {
	// 				apiResponseErrorHandler(rej);
	// 				res(rej.response);
	// 			});
	// 	}, false);
	// },
}