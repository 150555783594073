import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

const PendingCreditNotesCard = () => {
	const [creditData, setCreditData] = useState(null);

	useEffect(() => {
		api.getPendingCreditNotes().then((res) => {
			if (!res || res === 0) {
				return;
			}
			setCreditData(res);
		});
	}, []);

	if (!creditData) {
		return <div></div>;
	}

	return (
		<div className="col-lg-3 col-md-6 col-sm-12" style={{ marginBottom: '20px' }}>
			<Fade>
				<div className="card" style={{ height: '270px', paddingLeft: '5px', paddingRight: '5px' }}>
					<div className="card-header" style={{ backgroundColor: 'white', marginTop: '10px' }}>
						<h5 style={{ fontWeight: 500, fontSize: '13px' }}>Pending Credit Notes</h5>
					</div>
					<div
						className="card-body custom-no-scroll"
						style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 15, overflowY: 'auto', maxHeight: '200px' }}
					>
						{Object.entries(creditData)
							.map(([key, value]) => ({
								...value,
								total: Math.abs(value.total)
							}))
							.sort((a, b) => b.total - a.total)
							.map((value, index) => (
								<Link to={`/sales/invoices/${value.id}`} style={{ color: 'black', textDecoration: 'none' }}>
									<div className="row hover-effect" key={index} style={{ paddingBottom: '4px', paddingTop: '3px' }}>
										<div className="col-6 top-clients">
											<a style={{ whiteSpace: 'nowrap', fontWeight: 100 }}>{value.customer_name}</a>
										</div>
										<div className="col-6 top-clients" style={{ textAlign: 'right' }}>
											<a style={{ fontWeight: 600 }}>
												$ -{value.total.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
											</a>
										</div>
									</div>
								</Link>
							))}
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default PendingCreditNotesCard;
