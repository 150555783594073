import React from 'react';
import { IconArrowDownRight, IconArrowUpRight } from '@tabler/icons'; // Import these if they are separate components
import { Fade } from 'react-awesome-reveal';

const GrossProfitCard = ({ revenueData, revenueGrowth, revenueGrowthBar }) => {
	return (
		<div className="custom-gross-profit col-md-6 col-sm-12">
			<Fade>
				<div className="card" style={{ position: 'relative', width: '100%', height: '230px', maxHeight: '230px', marginBottom: '20px' }}>
					<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: '10px' }}>
						<a style={{ fontWeight: '500' }}>Gross Profit</a>
					</div>
					<div className="card-body" style={{ paddingTop: '5px', padding: '20px' }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h3 style={{ fontSize: '20px', fontWeight: 600, marginTop: '15px' }}>$ {`${revenueData}`}</h3>
							{revenueGrowth < 0 ? (
								<>
									<IconArrowDownRight size={16} style={{ color: '#f64e60', marginTop: '10px', marginLeft: '10px' }} />
									<span style={{ color: '#f64e60', fontSize: '12px', fontWeight: 500, marginLeft: '10px', paddingTop: '13px' }}>
										{`${Math.abs(revenueGrowth)}%`}
									</span>
								</>
							) : (
								<>
									<IconArrowUpRight size={16} style={{ color: '#36c880', marginTop: '10px', marginLeft: '10px' }} />
									<span style={{ color: '#36c880', fontSize: '12px', fontWeight: 500, marginLeft: '10px', paddingTop: '13px' }}>
										{`${revenueGrowth}%`}
									</span>
								</>
							)}
						</div>
						<div
							style={{
								height: '15px',
								width: '100%',
								backgroundColor: '#36c880',
								borderRadius: '50px',
								overflow: 'hidden',
								marginTop: '20px'
							}}
						>
							{revenueGrowth !== 0 && (
								<div
									style={{
										height: '100%',
										width: `${Math.abs(revenueGrowthBar)}%`, // Set width to the percentage of the progress
										backgroundColor: '#f64e60',
										borderRadius: '50px',
										border: '1px solid white',
										transition: 'width 2s ease-in-out' // Add transition
									}}
								></div>
							)}
						</div>
						<div className="row" style={{ marginTop: '50px' }}>
							<div className="col-6" style={{ display: 'flex', alignItems: 'center' }}>
								<span
									style={{
										display: 'inline-block',
										width: '6px',
										height: '6px',
										borderRadius: '3px',
										backgroundColor: '#f64e60',
										marginRight: '10px'
									}}
								></span>
								<a style={{ display: 'block', fontSize: '12px', color: '#a5a5a5' }}>Last Month</a>
							</div>
							<div className="col-6" style={{ display: 'flex', alignItems: 'center' }}>
								<span
									style={{
										display: 'inline-block',
										width: '6px',
										height: '6px',
										borderRadius: '3px',
										backgroundColor: '#36c880',
										marginRight: '10px'
									}}
								></span>
								<a style={{ display: 'block', fontSize: '12px', color: '#a5a5a5' }}>This Month</a>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default GrossProfitCard;
