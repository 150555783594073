import React, { useState, useEffect, useRef } from 'react';
import Scroller from 'ui-component/Scroller';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPlus, FaSearch } from 'react-icons/fa';
import StatusBadge from 'ui-component/StatusBadge';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import { StopWatchIcon, RepairIcon, PauseIcon, CheckCircleIcon, PlayCircleIcon } from 'ui-component/custom-icons/icons';
import RepairModal from '../modals/RepairModal';
import { toast } from 'react-hot-toast';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { useDispatch, useSelector } from 'react-redux';


const RepairsList = ({ repairsLists, handleReloadData }) => {

    const topSection = useRef(null);
    const [modelImeis, setModelImeis] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [showRepairPopup, setShowRepairPopup] = useState(false);
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const [repairs, setRepairs] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredInboundList, setFilteredInboundList] = useState([]);
    const [productIndexRepair, setProductIndexRepair] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

	const [userId] = useSelector((state) => [
		state.main?.user?.id
    ]);
    
    useEffect(() => {
        updateRepairList();
    }, [repairsLists]);


    const updateRepairList = () => {
        console.log('repairsLists', repairsLists)
        // Construct a new summary details array from repairsLists.products
        const collection = repairsLists?.repairs?.map(repair => ({

                id: repair?.id,
                imei: repair?.stock?.imei,
                productId: repair?.product?.id,
                productName: repair?.product?.name,
                colorId: repair?.color?.id,
                colorName: repair?.color?.name,
                repairTypeId: repair?.repair_type?.id,
                repairTypeName: repair?.repair_type?.name,
                currency: repair?.currency,
                cost: repair?.cost,
                timeSpent: repair?.time_spent,
                status: repair?.status,
                assignedToId: repair?.assigned_to?.id,
                assignedToName: repair?.assigned_to?.name,

        })) || [];
    
        // Update the summaryDetails state once with the new array
        setRepairs(collection);
    }

    const handleClose = () => {
        setShowDelete(false);
        setShowRepairPopup(false);
    }
    const handleSearch = (value) => {
        if (value.length >= 3) {

            const filtered = repairs.filter(item => 
                (item.imei && item.imei.includes(value)) || 
                (item.productName && item.productName.toLowerCase().includes(value.toLowerCase()))
            );
            setRepairs(filtered);
        } else {
            updateRepairList();
        }
    }

    const handleAddRepairs = (repairs) => {
        toast.promise(
            api
                .createOperationItemRepair(operationDetails?.id, {
                    operation_item_id: repairs?.operationItemId,
                    assigned_id: repairs?.assignedId,
                    cost: repairs?.repairCost,
                    currency: repairs?.repairCurrency,
                    repair_type_id: repairs?.repairTypeId,
                })
                .then(
                    (res) => {
                        if (res.status) {
                            handleReloadData()
                            // setNotes((oldData) => [
                            //     {
                            //         id: res?.data?.id,
                            //         details: noteInputValue,
                            //         date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
                            //         time: moment(res?.data?.created_at).format('HH:mm'),
                            //         expand: false,
                            //         isDeletable: true
                            //     },
                            //     ...oldData
                            // ]);
                        }

                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Adding repair...',
                success: (data) => `${data.message}`,
                error: <b>Failed to add repair</b>
            }
        );
    }

    const handleRepairProgress = (action, item) => {
        if (action === 'start') {
            handleStartRepair(item)
        } else if (action === 'resume') {
            handleResumeRepair(item)
        } else if (action === 'pause') {
            handlePauseRepair(item)
        } else if (action === 'complete') {
            handleCompleteRepair(item)
        }
    }

    const handleStartRepair = (item) => {
        toast.promise(
            api
                .startOperationItemRepairs(item?.id)
                .then(
                    (res) => {
                        if (res.status) {
                            handleReloadData()
                        }

                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Adding repair...',
                success: (data) => `${data.message}`,
                error: <b>Failed to add repair</b>
            }
        );
    }
    const handleResumeRepair = (item) => {
        toast.promise(
            api
                .resumeOperationItemRepairs(item?.id)
                .then(
                    (res) => {
                        if (res.status) {
                            handleReloadData()
                        }

                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Adding repair...',
                success: (data) => `${data.message}`,
                error: <b>Failed to add repair</b>
            }
        );
    }
    const handlePauseRepair = (item) => {
        toast.promise(
            api
                .pauseOperationItemRepairs(item?.id)
                .then(
                    (res) => {
                        if (res.status) {
                            handleReloadData()
                        }

                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Adding repair...',
                success: (data) => `${data.message}`,
                error: <b>Failed to add repair</b>
            }
        );
    }
    const handleCompleteRepair = (item) => {
        toast.promise(
            api
                .completeOperationItemRepairs(item?.id)
                .then(
                    (res) => {
                        if (res.status) {
                            handleReloadData()
                        }

                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Adding repair...',
                success: (data) => `${data.message}`,
                error: <b>Failed to add repair</b>
            }
        );
    }

    return (
        <>
            
            
            {/* <div className="card shadow-sm bg-white mb-4 p-4 table-card"></div> */}
            <div >
                <div className="bg-white pt-0">
                    <div className="d-flex align-items-center justify-content-between TableSerachBar">
                        <div>
                            <h5>Repairs List</h5>
                        </div>

                        <form className="form-inline navbar-search">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control bg-white border"
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                        handleSearch(e.target.value)
                                    }}
                                    // value={searchField}
                                    style={{ width: '100%' }}
                                />
                                <span className="searchIcon">
                                    <FaSearch />
                                </span>
                            </div>
                        </form>
                    </div>

                    <Scroller extraHeight={topSection?.current?.clientHeight || 0}>
                        <table className="table pm-table">
                            <thead>
                                <tr>
                                    <th scope="col">IMEI</th>
                                    <th scope="col" style={{ width: '160px' }}>Repair</th>
                                    <th scope="col" style={{ width: '105px' }}>Status</th>
                                    <th scope="col" style={{ width: '130px' }}>Assigned To</th>
                                    <th scope="col" style={{ width: '85px' }}>Cost</th>
                                    <th scope="col" style={{ width: '100px' }}>Time Spent</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    repairs.length > 0 ? (
                                        <>
                                            {
                                                repairs?.map((item, index) => (
                                                    <>
                                                        <tr key={index} className={`${item?.status !== 'Completed' && 'hover-row' }`}>
                                                            <td className="align-middle">
                                                                <div className="">{item?.imei}</div>
                                                                <div className="font500 textGray" style={{ fontSize: '12px'}}>
                                                                    {item.productName} { item.colorName && ( <span>({item?.colorName})</span> ) }
                                                                </div>
                                                            </td>
                                                            <td className="align-middle">
                                                                {item?.repairTypeName}
                                                            </td>
                                                            <td className="align-middle"><StatusBadge itemStatus={`${item?.status}`} /> </td>
                                                            <td className="align-middle">
                                                                {item?.assignedToName}
                                                            </td>
                                                            {/* <td className="align-middle">{item?.proceedureName}</td> */}
                                                            <td className="align-middle">
                                                                {getCurrencySymbol(item?.currency)} {parseFloat(item?.cost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                            </td>
                                                            <td className="align-middle">
                                                                {item?.timeSpent}
                                                            </td>
                                                            <PermissionAllowed permissions={['Process Operations Repairs']}>
                                                                {
                                                                    userId === item?.assignedToId && (
                                                                        <>
                                                                            {
                                                                                item?.status !== 'Completed' && (
                                                                                    <>
                                                                                        <td className="hover-button-container" >
                                                                                            <div>
                                                                                                <div className="hover-button">
                                                                                                    <div
                                                                                                        className='btn btn-primary btn-outlined-main white-var btn-sm'
                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => {
                                                                                                            if (item?.status === 'Pending') {
                                                                                                                handleRepairProgress('start', item) 
                                                                                                            }
                                                                                                            if (item?.status === 'Paused') {
                                                                                                                handleRepairProgress('resume', item) 
                                                                                                            }
                                                                                                            if (item?.status === 'In Progress') {
                                                                                                                handleRepairProgress('pause', item) 
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            item?.status === 'Pending' && (
                                                                                                                <>
                                                                                                                    <PlayCircleIcon height={'20'} width={'20'} /> 
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            item?.status === 'Paused' && (
                                                                                                                <>
                                                                                                                    <PlayCircleIcon height={'20'} width={'20'} /> 
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            item?.status === 'In Progress' && (
                                                                                                                <>
                                                                                                                    <PauseIcon height={'20'} width={'20'} /> 
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        item?.status === 'In Progress' && (
                                                                                                            <>
                                                                                                                <div
                                                                                                                    className='btn btn-success btn-outlined-approve btn-sm'
                                                                                                                    style={{ cursor: 'pointer', marginLeft: '8px' }}
                                                                                                                    onClick={() => {
                                                                                                                        if (item?.status === 'In Progress') {
                                                                                                                            handleRepairProgress('complete', item) 
                                                                                                                        }
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <CheckCircleIcon height={'20'} width={'20'} />
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </PermissionAllowed>
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                            </>
                                        ) : (
                                            <>
                                                <tr>
                                                    <td colSpan={6}>
                                                        <div className="not-found-container cstm-not-found" style={{ height: '385px' }}>
                                                            <div className="not-found-icon">
                                                                <img src={NoRecordFoundIcon} style={{ height: '100px', width: '100px' }} />
                                                            </div>
                                                            <h6 className="not-found-text">No Repairs Available</h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                            </tbody>
                        </table>

                    </Scroller>
                </div>
            </div>



            {showRepairPopup && (
                <RepairModal
                    openPopup={showRepairPopup}
                    selectedItem={selectedItem}
                    index={productIndexRepair}
                    handleClose={handleClose}
                    handleAddRepairs={handleAddRepairs}
                />
            )}

        </>
    );
};

export default RepairsList;
