import React, { useRef, useState, useEffect } from 'react';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import { useDispatch, useSelector } from 'react-redux';

import { FaSearch, FaFilter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaMobileAlt } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import NoImage from 'assets/images/no-image.png';
import SearchBar from '../layout/SearchBar';
import { setSearchBar } from 'store/reducers/mainSlice';

import ProductDetailsModel from './components/product-details';
import { FiFilter, FiPlusCircle } from 'react-icons/fi';
import { MdOutlinePhoneIphone, MdCable } from 'react-icons/md';
import { IoSettingsOutline, IoTrashOutline } from 'react-icons/io5';
import { FaHeadphonesAlt } from 'react-icons/fa';
import { PiGearSixBold } from 'react-icons/pi';

import { HiOutlineCurrencyEuro } from 'react-icons/hi2';
import DirhamIcon from './components/DirhamsIcon';
import CurrencyIcon from './components/DollarsIcon';

const Products = () => {
	const inputRef = useRef(null);

	const [isInProgress, setIsInProgress] = useState(false);
	const [products, setProducts] = useState([]);
	const [openProductModal, setOpenProductModal] = useState(false);
	const [searchField, setSearchField] = useState();
	const [activeFilters, setActiveFilters] = useState({});
	const [clickActiveFilter, setClickActiveFilter] = useState(false);
	const [filters, setFilters] = useState([]);
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isProductPageInactive, setIsProductPageInactive] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [selectedID, setSelectedID] = useState('');
	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
	const [pagination, setpagination] = useState();
	const [autoSuggest, setAutoSuggest] = useState([]);
	const searchBar = useSelector((state) => state.main.searchBar);
	const [activeCurrency, setActiveCurrency] = useState('USD');
	const [activeOption, setActiveOption] = React.useState(0);
	const [showDropdown, setShowDropdown] = useState(false);
	const dispatch = useDispatch();
	const [activeOptions, setActiveOptions] = useState([]);
	const [activeGradeOptions, setActiveGradeOptions] = useState([]);
	const [isExpanded, setIsExpanded] = useState(false);
	const [isClicked, setIsClicked] = useState(false);
	const [selectedCurrency, setSelectedCurrency] = useState('USD');

	const handleClick = () => {
		setIsClicked(!isClicked);
		console.log('clik');
	};

	const getCurrencyIcon = (currency) => {
		console.log(currency);
		switch (currency) {
			case 'USD':
				return <CurrencyIcon style={{ marginLeft: 2 }} />;
			case 'EUR':
				return <HiOutlineCurrencyEuro size={20} />;
			case 'AED':
				return <DirhamIcon />;
			default:
				return <CurrencyIcon />;
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (searchBar && inputRef.current) {
			inputRef.current.focus();
		}
	}, [searchBar]);

	useEffect(() => {
		api
			.getAllClientProducts()
			.then((res) => {
				setProducts(res.data.data);
				setpagination(res.data);
				getFiltersList();
				setAutoSuggest(res?.data?.data.map((item) => item.name).slice(0, 10));
				isFirstLoad && setIsFirstLoad(false);
			})
			.catch((err) => {
				console.log(err);
			});
		console.log(products);
	}, []);

	const handleData = (data) => {
		setProducts(data.data);
		setpagination(data);
	};
	const openProduct = (id) => {
		setSelectedID(id);
		setOpenProductModal(true);
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getProductFiltersClient().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);

		const status = !isProductPageInactive ? 'active' : 'inactive';
		api.filterProductClient(activeFilters, searchField?.length > 2 ? searchField : null, status).then((res) => {
			setIsLoading(false);
			setProducts(res.data.data);
			setpagination(res.data);
			setAutoSuggest(res?.data?.data.map((item) => item.name).slice(0, 10));
		});
	};

	const modifyFilter = (option) => {
		const categoryFilter = filters.find((filter) => filter.label === 'Category');
		const optionObject = categoryFilter.options.find((opt) => opt.label === option);

		const filterObject = {
			...activeFilters,
			category_id: {
				label: optionObject.label,
				value: optionObject.value
			}
		};

		// If the selected category is different from the previous one, reset the options
		if (!activeFilters.category_id || activeFilters.category_id.value !== optionObject.value) {
			setActiveOptions([]);
			setActiveGradeOptions([]);
		}

		setActiveFilters(filterObject);
	};
	const modifyFilterStorage = (option) => {
		const storageFilter = filters.find((filter) => filter.label === 'Storage');
		const optionObject = storageFilter.options.find((opt) => opt.label === option);

		let existingStorageId;
		if (activeFilters.storage_id !== undefined) {
			existingStorageId = Array.isArray(activeFilters.storage_id) ? activeFilters.storage_id : [activeFilters.storage_id];
		}

		const filterObject = {
			...activeFilters,
			storage_id: existingStorageId
				? [...existingStorageId, { label: optionObject.label, value: optionObject.value }]
				: [{ label: optionObject.label, value: optionObject.value }]
		};

		setActiveFilters(filterObject);
		// rest of your code
	};

	const modifyFilterGrade = (option) => {
		const gradeFilter = filters.find((filter) => filter.label === 'Grade');
		const optionObject = gradeFilter.options.find((opt) => opt.label === option);

		let existingGradeId;
		if (activeFilters.grade_id !== undefined) {
			existingGradeId = Array.isArray(activeFilters.grade_id) ? activeFilters.grade_id : [activeFilters.grade_id];
		}

		const filterObject = {
			...activeFilters,
			grade_id: existingGradeId
				? [...existingGradeId, { label: optionObject.label, value: optionObject.value }]
				: [{ label: optionObject.label, value: optionObject.value }]
		};

		setActiveFilters(filterObject);
		// rest of your code
	};

	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsLoading(true);
			const status = !isProductPageInactive ? 'active' : 'inactive';
			if (text.length > 2) {
				api.filterProductClient(activeFilters, text, status).then((res) => {
					setIsLoading(false);
					setProducts(res.data.data);
					setpagination(res.data);
					setAutoSuggest(res?.data?.data.map((item) => item.name).slice(0, 10));
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterProductClient(activeFilters, null, status).then((res) => {
					setIsLoading(false);
					setProducts(res.data.data);
					setpagination(res.data);
					setAutoSuggest(res?.data?.data.map((item) => item.name).slice(0, 10));
				});
			}
		}, 1000);

		setSearchTimeout(timeout);
	};

	const optionIcons = {
		Filter: (
			<svg fill="none" height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg" id="fi_10609382">
				<path
					clip-rule="evenodd"
					d="m16 3c.5523 0 1 .44772 1 1v1h4c.5523 0 1 .44772 1 1 0 .55229-.4477 1-1 1h-4v1c0 .55228-.4477 1-1 1s-1-.44772-1-1v-4c0-.55228.4477-1 1-1zm-14 3c0-.55228.44772-1 1-1h9c.5523 0 1 .44772 1 1 0 .55229-.4477 1-1 1h-9c-.55228 0-1-.44772-1-1zm6 3c.55228 0 1 .44772 1 1v4c0 .5523-.44772 1-1 1s-1-.4477-1-1v-1h-4c-.55228 0-1-.4477-1-1s.44772-1 1-1h4v-1c0-.55228.44772-1 1-1zm3 3c0-.5523.4477-1 1-1h9c.5523 0 1 .4477 1 1s-.4477 1-1 1h-9c-.5523 0-1-.4477-1-1zm5 3c.5523 0 1 .4477 1 1v1h4c.5523 0 1 .4477 1 1s-.4477 1-1 1h-4v1c0 .5523-.4477 1-1 1s-1-.4477-1-1v-4c0-.5523.4477-1 1-1zm-14 3c0-.5523.44772-1 1-1h9c.5523 0 1 .4477 1 1s-.4477 1-1 1h-9c-.55228 0-1-.4477-1-1z"
					fill="currentColor"
					fill-rule="evenodd"
				></path>
			</svg>
		),
		iPhone: <MdOutlinePhoneIphone size={18} style={{ marginLeft: '-3px' }} color={'currentColor'} fill={'currentColor'} />,
		Parts: <PiGearSixBold size={18} style={{ marginLeft: '-3px' }} color={'currentColor'} fill={'currentColor'} />,
		Accessories: <MdCable size={18} style={{ marginLeft: '-3px' }} color={'currentColor'} fill={'currentColor'} />
	};

	const currencySymbols = {
		USD: '$',
		EUR: '€',
		AED: 'Dhs'
	};

	return (
		<>
			{isMobile && (
				<div className="approved-mobile" style={{ position: 'sticky', top: 0, zIndex: 10 }}>
					<div className="verified-mobile">
						<span className="menuIcon">
							<svg xmlns="http://www.w3.org/2000/svg" width="21.994" height="21.991" viewBox="0 0 21.994 21.991">
								<path
									id="box-open-full"
									d="M21.639,9.931,20.064,6.639a.916.916,0,0,0-1.117-.474L10.994,8.819,3.031,6.166a.918.918,0,0,0-1.117.475L.4,9.824A2.773,2.773,0,0,0,1.824,13.86l-.005,2.122a4.579,4.579,0,0,0,3.134,4.351l4,1.334a6.464,6.464,0,0,0,4.058,0l4-1.334a4.576,4.576,0,0,0,3.134-4.342l.006-2.128a2.729,2.729,0,0,0,1.667-1.639,2.682,2.682,0,0,0-.181-2.291Zm-19.628.761L3.219,8.16l6.475,2.157L8.209,13.3a.913.913,0,0,1-1.076.4L2.476,12.147a.936.936,0,0,1-.586-.566,1.006,1.006,0,0,1,.119-.889Zm3.521,7.9a2.747,2.747,0,0,1-1.881-2.61l0-1.512,2.9.967a2.753,2.753,0,0,0,3.262-1.257l.254-.509,0,6.4a4.663,4.663,0,0,1-.533-.141l-4-1.333Zm10.9,0-4,1.334a4.637,4.637,0,0,1-.532.142l0-6.411.292.587a2.732,2.732,0,0,0,2.348,1.338,2.777,2.777,0,0,0,.88-.144l2.9-.965,0,1.513a2.745,2.745,0,0,1-1.881,2.606ZM20.1,11.586a.912.912,0,0,1-.567.551L14.843,13.7a.916.916,0,0,1-1.041-.335l-1.516-3.045L18.76,8.16,20.027,10.8a.91.91,0,0,1,.074.787ZM4.779,5.4a.915.915,0,0,1,0-1.3L8.35.537a1.836,1.836,0,0,1,2.592,0L12.4,2a1.885,1.885,0,0,1,2.157.316l2.067,2.067a.916.916,0,0,1-1.3,1.3L13.266,3.612,10.378,6.5a.916.916,0,0,1-1.3-1.3l1.968-1.968-1.4-1.4L6.076,5.4a.916.916,0,0,1-1.3,0Z"
									transform="translate(0 -0.002)"
									fill="currentColor"
								/>
							</svg>
						</span>
						<span style={{ marginLeft: 5, fontSize: 16 }}>Products</span>
					</div>
				</div>
			)}
			<div
				className="main-page-client"
				style={{
					alignItems: !isMobile ? 'center' : ''
				}}
			>
				<div className="order-client-content" style={{ maxWidth: '850px', width: !isMobile ? '100%' : '' }}>
					<div className="d-flex align-items-center justify-content-between TableSerachBar" style={{ paddingTop: 0, paddingBottom: 0 }}>
						<div className="heading-order-client-tbl" style={{ display: 'flex', alignItems: 'center', width: isMobile ? '100%' : 'auto' }}>
							{!isMobile && <h3 style={{ marginBottom: 0, marginRight: 10 }}>Products</h3>}
							{/* <div style={{ width: isMobile ? '100%' : 'auto' }}>
								<Filter
									activeFilters={activeFilters}
									onSubmit={setActiveFilters}
									filters={filters}
									style={{ width: isMobile ? '100%' : 'auto' }}
								/>
							</div> */}
							<div className={`product-filter-mobile ${isClicked && isMobile ? 'fade' : ''}`} style={{ width: '100%' }}>
								{['Filter', 'iPhone', 'Parts', 'Accessories'].map((option, index) => (
									<div
										className={`product-filter-mobile-option ${activeOption === index ? 'active' : ''}`}
										style={{ ...(index === 0 ? { paddingLeft: 10 } : {}), display: 'flex', justifyContent: 'space-between' }}
										onClick={() => {
											setActiveOption(index);
											if (option !== 'Filter') {
												modifyFilter(option);
												handleFilter();
											} else {
												setActiveFilters({});
											}
											if (option === 'iPhone' && activeOption === index) {
												setShowDropdown(!showDropdown);
												console.log(filters);
											} else {
												setShowDropdown(false);

												// If the activeFilters object has grade_id or storage_id properties, remove them
												if (activeFilters.grade_id) {
													delete activeFilters.grade_id;
												}
												if (activeFilters.storage_id) {
													delete activeFilters.storage_id;
												}
												// Update the state with the modified activeFilters object
												if (option !== 'Filter') {
													modifyFilter(option);
												} else {
													setActiveFilters({});
												}
												setShowDropdown(false);
											}
										}}
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<span className="icon-color">{optionIcons[option]}</span>
											<a style={{ pointerEvents: 'none' }}>{option}</a>
										</div>
										{option === 'iPhone' && (
											<span className="hidden-option">
												<FiPlusCircle />
											</span>
										)}
										{showDropdown && option === 'iPhone' && (
											<div
												className="dropdown"
												style={{
													position: 'absolute',
													width: 250,
													height: 'fit-content',
													display: 'flex',
													borderRadius: 10,
													padding: 10,
													top: '85px',
													justifyContent: 'center',
													backgroundColor: 'white',
													boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
													zIndex: 11,
													marginLeft: '-10px',
													cursor: 'default'
												}}
												onClick={(e) => e.stopPropagation()}
											>
												<button
													className="btn modal-close-icon"
													style={{ top: '11px', zIndex: 12 }}
													onClick={(e) => {
														e.stopPropagation();
														setShowDropdown(false);
													}}
												>
													<svg
														id="Component_127_1"
														height="16"
														width="16"
														data-name="Component 127 – 1"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 19.295 19.289"
													>
														<path
															id="Icon_ionic-ios-close"
															data-name="Icon ionic-ios-close"
															d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
															transform="translate(-11.285 -11.289)"
														/>
													</svg>
												</button>
												<div className="row" style={{ marginTop: 10 }}>
													<div
														className="col-12 d-flex justify-content-start align-item-center"
														style={{ color: 'black', marginBottom: 10, marginLeft: 5 }}
													>
														<span style={{ fontWeight: 500 }}>Storage</span>
													</div>
													<div className="col-12 d-flex flex-wrap justify-content-left" style={{ marginBottom: 10 }}>
														{filters
															.find((filter) => filter.label === 'Storage')
															.options.map((option, index) => (
																<span
																	className={`tag ${activeOptions.includes(option.value) ? 'active' : ''}`}
																	key={option.value}
																	style={{ color: 'black', margin: '5px', cursor: 'pointer' }}
																	onClick={() => {
																		if (activeOptions.includes(option.value)) {
																			setActiveOptions(activeOptions.filter((value) => value !== option.value));

																			// Remove the clicked option from activeFilters
																			setActiveFilters((prevFilters) => {
																				const { storage_id, ...restFilters } = prevFilters;
																				const newStorageId = storage_id.filter((item) => item.value !== option.value);
																				return { ...restFilters, storage_id: newStorageId };
																			});
																		} else {
																			setActiveOptions([...activeOptions, option.value]);
																			modifyFilterStorage(option.label);
																		}
																	}}
																>
																	{option.label}
																</span>
															))}
													</div>
													<div
														className="col-12 d-flex justify-content-start align-item-center"
														style={{ color: 'black', marginBottom: 10, marginLeft: 5 }}
													>
														<span style={{ fontWeight: 500 }}>Grade</span>
													</div>
													<div className="col-12 d-flex flex-wrap justify-content-left" style={{ marginBottom: 10 }}>
														{filters
															.find((filter) => filter.label === 'Grade')
															.options.map((option, index) => (
																<span
																	className={`tag ${activeGradeOptions.includes(option.value) ? 'active' : ''}`}
																	key={option.value}
																	style={{ color: 'black', margin: '5px', cursor: 'pointer' }}
																	onClick={() => {
																		if (activeGradeOptions.includes(option.value)) {
																			setActiveGradeOptions(activeGradeOptions.filter((value) => value !== option.value));

																			// Remove the clicked option from activeFilters
																			setActiveFilters((prevFilters) => {
																				const { storage_id, ...restFilters } = prevFilters;
																				const newStorageId = storage_id.filter((item) => item.value !== option.value);
																				return { ...restFilters, storage_id: newStorageId };
																			});
																		} else {
																			setActiveGradeOptions([...activeGradeOptions, option.value]);
																			modifyFilterGrade(option.label);
																		}
																	}}
																>
																	{option.label}
																</span>
															))}
													</div>
													<div className="col-12">
														<div
															className="clear-filter d-flex justify-content-center align-item-center"
															style={{ borderRadius: 10, border: '1px solid #f2f2f2', padding: 5, cursor: 'pointer' }}
															onClick={() => {
																const { storage_id, grade_id, ...restFilters } = activeFilters;
																setActiveFilters(restFilters);
																setActiveOptions([]);
																setActiveGradeOptions([]);
															}}
														>
															<a style={{ color: 'black' }}>
																<IoTrashOutline /> Clear
															</a>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								))}
							</div>
						</div>

						<form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
							<div style={{ marginRight: 40, marginTop: '-33px', backgroundColor: 'white' }}>
								<div
									className="currency-option-1 product-filter-mobile-currency"
									style={{ position: 'absolute', zIndex: '6', paddingLeft: selectedCurrency == 'EUR' ? 8 : 10, width: 36, height: 35 }}
									onClick={handleClick}
								>
									{getCurrencyIcon(selectedCurrency)}
								</div>
								{/* <div
									className={`product-filter-mobile-currency ${selectedCurrency === 'AED' ? 'active' : ''}`}
									style={{
										zIndex: '5',
										position: 'absolute',
										paddingLeft: 9,
										transition: 'transform 0.3s ease-out',
										transform: isClicked ? 'translateX(-115%)' : 'translateX(0)'
									}}
									onClick={() => {
										setSelectedCurrency('AED');
										handleClick();
									}}
								>
									{getCurrencyIcon('AED')}
								</div> */}
								<div
									className={`product-filter-mobile-currency ${selectedCurrency === 'EUR' ? 'active' : ''}`}
									style={{
										zIndex: '4',
										position: 'absolute',
										paddingLeft: 7,
										transition: 'transform 0.3s ease-out',
										transform: isClicked ? 'translateX(-115%)' : 'translateX(0)'
									}}
									onClick={() => {
										setSelectedCurrency('EUR');
										handleClick();
									}}
								>
									{getCurrencyIcon('EUR')}
								</div>
								<div
									className={` product-filter-mobile-currency ${selectedCurrency === 'USD' ? 'active' : ''}`}
									style={{
										zIndex: '3',
										position: 'absolute',
										paddingLeft: 9,
										transition: 'transform 0.3s ease-out',
										transform: isClicked ? 'translateX(-230%)' : 'translateX(0)'
									}}
									onClick={() => {
										setSelectedCurrency('USD');
										handleClick();
									}}
								>
									{getCurrencyIcon('USD')}
								</div>
							</div>
							{!isMobile && (
								<div className="input-group" style={{ marginTop: 3 }}>
									<input
										ref={inputRef}
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										value={searchField}
										onChange={(e) => {
											handleSearch(e);
										}}
										onFocus={() => {
											dispatch(setSearchBar(true));
										}}
										onBlur={() => {
											dispatch(setSearchBar(!searchBar));
										}}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							)}
						</form>
					</div>

					{/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
						<div className="currency-container">
							<a className={`currency-select ${activeCurrency === 'USD' ? 'active' : ''}`} onClick={() => setActiveCurrency('USD')}>
								USD
							</a>
							<a className={`currency-select ${activeCurrency === 'EUR' ? 'active' : ''}`} onClick={() => setActiveCurrency('EUR')}>
								EUR
							</a>
							<a className={`currency-select ${activeCurrency === 'AED' ? 'active' : ''}`} onClick={() => setActiveCurrency('AED')}>
								AED
							</a>
						</div>
					</div> */}
					{!isMobile ? (
						<div className="estimation-table-area" style={{ height: isMobile ? 'auto' : '96%' }}>
							<div className="table-side">
								<div
									className="table-responsive-xl tableData estimationList"
									style={{ height: isMobile ? 'auto' : '100%', overflowY: isMobile ? 'none' : 'auto' }}
								>
									<table className="order-client-tb min-width-unset pm-table d-sm-table d-md-table" style={{ marginBottom: 10 }}>
										<thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff', borderBottom: '1px solid #cacaca' }}>
											<tr>
												<th scope="col" className="text-left" style={{ width: '10%' }}></th>
												{/* <th scope="col" style={{ textAlign: 'left', width: '20%' }}>
													SKU
												</th> */}
												<th scope="col" style={{ textAlign: 'left', width: '70%' }}>
													Product
												</th>
												<th scope="col" style={{ textAlign: 'left', width: '10%' }}>
													Price
												</th>
												<th style={{ width: '10%' }}></th>
											</tr>
										</thead>
										<tbody>
											{products.map((item, index) => (
												<tr key={index} onClick={() => openProduct(item.id)}>
													<td className="text-left ml-5">
														{item?.thumbnail ? (
															<img src={item?.thumbnail_url} style={{ width: '30px' }} alt="product" />
														) : (
															<img src={NoImage} style={{ width: '30px' }} alt="product" />
														)}
													</td>
													{/* <td className="" style={{ textAlign: 'left' }}>
														<span className="link-prdct-dtls ml-1">{item.sku}</span>
													</td> */}
													<td style={{ textAlign: 'left' }}>{item.name}</td>
													<td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
														{currencySymbols[selectedCurrency]}{' '}
														{parseFloat(item.converted_currency_price[selectedCurrency.toLowerCase()]).toFixed(2)}
													</td>

													<td style={{ textAlign: 'right' }}>
														<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
															<div className="cart-product-list-client">
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.258 20.37">
																	<path
																		id="Union_2"
																		data-name="Union 2"
																		d="M15.944,18.6a1.771,1.771,0,1,1,1.772,1.769A1.774,1.774,0,0,1,15.944,18.6ZM6.2,18.6A1.772,1.772,0,1,1,7.975,20.37,1.774,1.774,0,0,1,6.2,18.6Zm-.266-5.684L4.457,5.528a.461.461,0,0,1-.016-.078L3.705,1.772H.889A.886.886,0,1,1,.889,0H4.43A.886.886,0,0,1,5.3.713L6.042,4.43H20.374a.882.882,0,0,1,.868,1.052L19.824,12.91v.008a2.656,2.656,0,0,1-2.605,2.139H8.541A2.651,2.651,0,0,1,5.937,12.918Zm1.737-.347a.884.884,0,0,0,.885.717.03.03,0,0,1,.016,0h8.609c.012,0,.023,0,.035,0a.886.886,0,0,0,.868-.713L19.3,6.2H6.4Z"
																		fill="#fff"
																	/>
																</svg>
															</div>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<div
										style={{
											position: 'sticky',
											bottom: '-1px',
											width: '100%',
											backgroundColor: '#fff',
											textAlign: isMobile ? 'center' : 'right',
											marginTop: '-14px',
											paddingBottom: 10
										}}
									>
										<Pagination pagination={pagination} data={handleData} />
									</div>
								</div>
							</div>
						</div>
					) : (
						<>
							<div className="order-list-client" style={{ marginTop: 20, top: 0, position: 'sticky' }}>
								<div className="row">
									<div className="col-7" style={{ padding: 10, paddingLeft: 20 }}>
										<span style={{ fontWeight: 600 }}>Product</span>
									</div>
									<div className="col-3" style={{ padding: 10 }}>
										<span style={{ fontWeight: 600 }}>Price</span>
									</div>
									<div className="col-2"></div>
								</div>
							</div>
							<div className="order-container">
								{products.map((item) => (
									<div
										className="row order-item-client"
										key={item.id}
										style={{ paddingTop: 10, paddingBottom: 10 }}
										onClick={() => openProduct(item.id)}
									>
										{/* <div className="col-3" style={{ paddingLeft: 20 }}>
											<a className="link-prdct-dtls">{item.sku}</a>
										</div> */}
										<div className="col-7" style={{ display: 'flex', alignItems: 'center' }}>
											<a>{item.name}</a>
										</div>
										<div className="col-3" style={{ display: 'flex', alignItems: 'center' }}>
											<a>
												{currencySymbols[selectedCurrency]}{' '}
												{parseFloat(item.converted_currency_price[selectedCurrency.toLowerCase()]).toFixed(2)}
											</a>
										</div>
										<div className="col-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
											<div className="cart-product-list-client-mobile">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.258 20.37">
													<path
														id="Union_2"
														data-name="Union 2"
														d="M15.944,18.6a1.771,1.771,0,1,1,1.772,1.769A1.774,1.774,0,0,1,15.944,18.6ZM6.2,18.6A1.772,1.772,0,1,1,7.975,20.37,1.774,1.774,0,0,1,6.2,18.6Zm-.266-5.684L4.457,5.528a.461.461,0,0,1-.016-.078L3.705,1.772H.889A.886.886,0,1,1,.889,0H4.43A.886.886,0,0,1,5.3.713L6.042,4.43H20.374a.882.882,0,0,1,.868,1.052L19.824,12.91v.008a2.656,2.656,0,0,1-2.605,2.139H8.541A2.651,2.651,0,0,1,5.937,12.918Zm1.737-.347a.884.884,0,0,0,.885.717.03.03,0,0,1,.016,0h8.609c.012,0,.023,0,.035,0a.886.886,0,0,0,.868-.713L19.3,6.2H6.4Z"
														fill="#fff"
													/>
												</svg>
											</div>
										</div>
									</div>
								))}
							</div>
							<div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
								<Pagination pagination={pagination} data={handleData} />
							</div>
						</>
					)}
				</div>

				<ProductDetailsModel
					show={openProductModal}
					handleClose={() => setOpenProductModal(false)}
					handleConfirm={() => setOpenProductModal(false)}
					selectedId={selectedID}
					selectedCurrency={selectedCurrency}
				/>
				<SearchBar searchField={searchField} handleSearch={handleSearch} autoSuggest={autoSuggest} />
			</div>
		</>
	);
};

export default Products;
