import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './ItemRepairsModal.scss';

import { IoWarningOutline } from 'react-icons/io5';
import { PiWarningCircleFill, PiWarningFill } from 'react-icons/pi';
import { useEffect } from 'react';
import { BiBarcodeReader } from 'react-icons/bi';
import Select from 'react-select';
import { GiAutoRepair } from 'react-icons/gi';
import { Checkbox, Tooltip } from '@mui/material';
import LinearProgressWithLabel from 'ui-component/LinearProgressWithLabel';
import { FaCheck, FaPlus, FaTimes } from 'react-icons/fa';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';

function ItemRepairsModal({
	show,
	handleClose,
	onSubmit,
	title,
	message,
	customIcon,
	confirmDanger,
	isLoading,
	isInprogress,
	repairOptions,
	mainTaskCompleted,
	handleRequestNewRepair,
	handleStockRepairState,
	handleRepairItemCheck,
	handleCancelRepairRequest
}) {
	const confirmBtnRef = useRef(null);
	// const barcodeInputFocus = useRef(null);

	const [selectedRepairs, setSelectedRepairs] = useState('');
	const [newRepairInput, setNewRepairInput] = useState('');

	useEffect(() => {
		if (show?.repairs) {
			setSelectedRepairs(show?.repairs);
		}
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
	};

	const requestNewRepair = () => {
		handleRequestNewRepair &&
			handleRequestNewRepair(newRepairInput, () => {
				setNewRepairInput('');
			});
	};

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className="item-repairs-dialog"
		>
			<Modal.Body>
				<Modal.Title>
					{/* <span> {customIcon ? customIcon : <GiAutoRepair />}</span> */}
					<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">{title || 'Repair'}</h5>
					<button className="btn modal-close-icon" onClick={handleClose}>
						<FaTimes />
					</button>
				</Modal.Title>

				<form onSubmit={handleSubmit}>
					<div>
						<p className="message d-flex justify-content-between">
							<span>{show?.stock?.product?.name}</span> <span>{show?.stock?.imei}</span>
						</p>
					</div>

					<div className="d-flex align-items-center flex-column">
						<div className="repairs-checklist">
							{/* <div style={{ paddingInline: '10px', paddingTop: '5px' }}>
								<LinearProgressWithLabel value={50} />
							</div> */}
							<table className="w-100">
								{show?.stock?.repairs?.map((rep) => (
									<tr>
										<td className="text-left" style={{ width: '30px' }}>
											<Checkbox
												style={{ padding: '5px', paddingLeft: 0 }}
												disabled={!rep?.is_approved || show?.status != 'inprogress'}
												checked={rep?.status == 'completed'}
												onChange={(e) => handleRepairItemCheck && handleRepairItemCheck(rep?.id, rep?.status)}
											/>
										</td>
										<td className="text-left">
											<div className="d-flex justify-content-center align-items-center" style={{ gap: '2px' }}>
												<span className="waiting-for-approval">
													{rep?.repair_cost?.repair_type?.name} {!rep?.is_approved && <small>Waiting for approval</small>}
												</span>
												{!rep?.is_approved && (
													<Tooltip title="Cancel Request">
														<span
															className="btn plain-iconbtn"
															style={{ fontSize: '10px', width: '20px', height: '20px' }}
															onClick={() => handleCancelRepairRequest && handleCancelRepairRequest(rep?.id)}
														>
															<FaTimes />
														</span>
													</Tooltip>
												)}
											</div>
										</td>
									</tr>
								))}

								{show?.status == 'inprogress' && (
									<PermissionAllowed permissions={['Add Item Repair for Repairing']}>
										<tr>
											<td className="text-left">
												<Checkbox style={{ padding: '5px', paddingLeft: 0 }} disabled />
											</td>
											<td className="text-left">
												<div className="d-flex justify-content-center align-items-center" style={{ gap: '2px' }}>
													<Select
														className="rs-newentry-container"
														classNamePrefix="rs-newentry"
														options={repairOptions
															?.filter((r) => !show?.stock?.repairs?.some((r2) => r?.repair_type_id == r2?.repair_cost?.repair_type?.id))
															?.map((r) => ({
																...r,
																value: r?.repair_type_id,
																label: r?.repair_type?.name
															}))}
														placeholder="Add New..."
														value={newRepairInput}
														onChange={(e) => {
															setNewRepairInput(e);
														}}
													/>
													{newRepairInput && (
														<>
															<Tooltip title="Cancel">
																<span
																	className="btn plain-iconbtn"
																	style={{ fontSize: '10px', width: '20px', height: '20px' }}
																	onClick={() => {
																		setNewRepairInput('');
																	}}
																>
																	<FaTimes />
																</span>
															</Tooltip>
															<Tooltip title="Request Approval">
																<span
																	className="btn plain-iconbtn"
																	style={{ fontSize: '10px', width: '20px', height: '20px' }}
																	onClick={requestNewRepair}
																>
																	<FaCheck />
																</span>
															</Tooltip>
														</>
													)}
												</div>
											</td>
										</tr>
									</PermissionAllowed>
								)}
							</table>
						</div>
					</div>

					<div className="modal-actions" style={{justifyContent: 'center'}}>
						{isInprogress && !mainTaskCompleted && (
							<>
								{show?.status == 'upcoming' && (
									<button
										ref={confirmBtnRef}
										type="submit"
										className={`btn btn-primary btn-outlined-main plain-start`}
										disabled={isLoading}
										onClick={() => handleStockRepairState('start')}
									>
										Start
									</button>
								)}

								{show?.status == 'inprogress' && (
									<button
										ref={confirmBtnRef}
										type="submit"
										className={`btn btn-primary btn-outlined-main plain-stop`}
										disabled={isLoading}
										onClick={() => handleStockRepairState('pause')}
									>
										Pause
									</button>
								)}

								{show?.status == 'paused' && (
									<button
										ref={confirmBtnRef}
										type="submit"
										className={`btn btn-primary btn-outlined-main plain-continue`}
										disabled={isLoading}
										onClick={() => handleStockRepairState('resume')}
									>
										Resume
									</button>
								)}

								{(show?.status == 'inprogress' || show?.status == 'paused') && (
									<button
										ref={confirmBtnRef}
										type="submit"
										className={`btn btn-primary btn-outlined-main plain-finish`}
										disabled={isLoading}
										onClick={() => handleStockRepairState('end')}
									>
										End
									</button>
								)}
							</>
						)}
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}

export default ItemRepairsModal;
