import React from 'react';

const PrintContent = React.forwardRef((props, ref) => {
	return (
		<div className="print-content" style={{width: '100%'}} ref={ref}>
			{props?.children}
		</div>
	);
});

export default PrintContent;
