import apisetup from './apisetup';
import api1 from './api';
import apiDashboard from './apiDashboard';
import apiContact from './apiContact';
import apiCustomer from './apiCustomer';
import apiTransitCompany from './apiTransitCompany';
import apiProduct from './apiProduct';
import apiEstimation from './apiEstimation';
import apiTaskbox from './apiTaskbox';
import apiProductCreation from './apiProductsCreation';
import apiShipments from './apiShipments';
import apiCreditNote from './apiCreditNote';
import apiReport from './apiReport';
import apiWarehouse from './apiWarehouse';
import apiReturns from './apiReturns';
import apiPurchasing from './apiPurchasing';
import apiOperation from './apiOperation';
import apiTransaction from './apiTransaction';
import apiSalesOrders from './apiSalesOrders';
import apiPicklist from './apiPicklist';
import apiSettings from './apiSettings';
import apiTest from './apiTest';

import apiClient from './apiClient';
window.api = {
	...apisetup,
	...api1,
	...apiDashboard,
	...apiContact,
	...apiCustomer,
	...apiTransitCompany,
	...apiProduct,
	...apiEstimation,
	...apiTaskbox,
	...apiProductCreation,
	...apiShipments,
	...apiCreditNote,
	...apiReport,
	...apiWarehouse,
	...apiReturns,
	...apiPurchasing,
	...apiOperation,
	...apiTransaction,
	...apiSalesOrders,
	...apiPicklist,
	...apiSettings,
	...apiTest,
	...apiClient,
};
// export default api
