import { lazy } from 'react';
// import GuestGuard from 'utils/route-guard/GuestGuard';
import AuthenticationLayout from 'views/client/layout/AuthenticationLayout';
import MainLayout from 'views/client/layout/login.jsx';

import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import NewLogin from 'views/client/login';
import ForgotPassword from 'views/client/layout/AuthenticationLayout/ForgotPassword';
import ForgotPasswordOTP from 'views/client/layout/AuthenticationLayout/ForgotPasswordOTP';
import NewPassword from 'views/client/layout/AuthenticationLayout/NewPassword';
import SetPassword from 'views/pages/authentication/SetPassword';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const ClientLoginRoutes = {
	path: 'client/',
	element: <MainLayout />,
	children: [
		{
			path: '',
			element: <AuthenticationLayout />
		},
		{
			path: 'login',
			element: <AuthenticationLayout />
		},
		{
			path: 'forgot',
			element: <ForgotPassword />
		},
		{
			path: 'forgot-password-otp',
			element: <ForgotPasswordOTP />
		},
		{
			path: 'new-password',
			element: <NewPassword />
		},
		{
			path: 'setPassword',
			element: <SetPassword />
		}
	]
};

export default ClientLoginRoutes;
