import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RiShieldUserLine } from 'react-icons/ri';
import { FaPaperclip } from 'react-icons/fa';
import ImageViewer from './ImageViewer';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { CommonDateOnlyFormat } from 'helpers/common.helper';


function PaymentItem({ data, onEditClicked, onViewClicked, onDeleteClicked, currency, toggleExpand }) {
	const handleEditPayment = (e) => {
		onEditClicked && onEditClicked(data);
	};

	const handleViewPayment = (e) => {
		onViewClicked && onViewClicked(data);
	};

	const handleDeletePayment = (e) => {
		onDeleteClicked && onDeleteClicked(data.id);
	};
	const [selectedRow, setSelectedRow] = useState(null);

	const handleRowClick = (id) => {
		setSelectedRow(id === selectedRow ? null : id);
	};





	const pStyle = {
		color: '#45ad51',
		fontWeight: '600'
	};
	const altText = 'Image Could not be loaded';

	return (
		<tr onClick={() => toggleExpand && toggleExpand()} >
			<td> {moment(data?.payment_date).format(CommonDateOnlyFormat)} </td>
			<td>
				<p>
					{
						data?.pay_type == 'credit-note' ? (
							'Credit Note'
						) : data?.pay_type == 'bank-charges' ? (
							'Bank Charges'
						) : (
							'Wired'
						)
					}
				</p>
			</td>
			<td className="text-left">
				<span style={pStyle}>
					{
						data?.currency ? (
							<>
								{ getCurrencySymbol(data?.currency) } {' '}
							</>
						) : (
							<>
								{ getCurrencySymbol(currency) } {' '}
							</>
						)
					}
					{numberFormatter.format(data?.amount_paid)}
					
				</span>
			</td>

			<td> {data?.user_created?.first_name}</td>
			<td className="text-center">
				<center>
					{
						data?.payment_attachment?.payment_attachment_url && (

							<a href={data?.payment_attachment?.payment_attachment_url}
								target="_blank"
							>
								<span className="btnEdit plain-iconbtn" >
									<FaPaperclip />
								</span>
							</a>
						)
					}
				</center>
			</td>
		</tr>
	);
}

export default PaymentItem;
