import React, { useEffect, useState } from 'react';
import ProcessIndicator from '../ProcessIndicator';
import { CompanyDetailsProgresses } from 'assets/helperdata/client/company-details-progress';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { allCountriesList } from 'assets/helperdata/countries-list';

import Select from 'react-select';
import { set } from 'react-hook-form';
import toast from 'react-hot-toast';

const CompanyAddress = () => {
	const history = useNavigate();
	const [isInProgress, setIsInProgress] = useState(false);
	const clientUser = useSelector((state) => state.main.client);
	const [selectedOption, setSelectedOption] = useState(null);
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [shippingData, setShippingData] = useState({
		id: clientUser?.data?.id,
		companyAddress: '',
		city: '',
		country: '',
		zip: ''
	});
	const [billingData, setBillingData] = useState({
		id: clientUser?.data?.id,
		companyAddress: '',
		city: '',
		country: '',
		zip: ''
	});

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const countryCodes = [
		{ label: '+93', value: '+93' },
		{ label: '+358', value: '+358' },
		{ label: '+355', value: '+355' },
		{ label: '+213', value: '+213' },
		{ label: '+263', value: '+263' },
		{ label: '+43', value: '+43' },
		{ label: '+32', value: '+32' },
		{ label: '+359', value: '+359' },
		{ label: '+385', value: '+385' },
		{ label: '+357', value: '+357' },
		{ label: '+420', value: '+420' },
		{ label: '+45', value: '+45' },
		{ label: '+971', value: '+971' }
	];

	useEffect(() => {
		if (clientUser?.data?.id) {
			api.getClientInformation(clientUser?.data?.id).then((res) => {
				if (res.data.companyBillingAddress) {
					console.log(res.data.companyBillingAddress);
					setBillingData({
						companyAddress: res.data.companyBillingAddress.address,
						city: res.data.companyBillingAddress.city,
						country: res.data.companyBillingAddress.country,
						zip: res.data.companyBillingAddress.zip
					});
				}
				if (res.data.companyShippingAddress) {
					console.log(res.data.companyShippingAddress);
					setShippingData({
						companyAddress: res.data.companyShippingAddress.address,
						city: res.data.companyShippingAddress.city,
						country: res.data.companyShippingAddress.country,
						zip: res.data.companyShippingAddress.zip
					});
				}
			});
		}
	}, [clientUser?.data?.id]);

	const moveToNext = () => {
		if (billingData.companyAddress == '' || billingData.city == '' || billingData.country == '' || billingData.zip == '') {
			toast.error('Please fill all fields');
			setIsInProgress(false);
			return;
		}

		let data = {
			id: clientUser?.data?.id,
			billingData: { ...billingData }
		};

		if (isCheckboxChecked) {
			if (shippingData.companyAddress == '' || shippingData.city == '' || shippingData.country == '' || shippingData.zip == '') {
				toast.error('Please fill all fields');
				setIsInProgress(false);
				return;
			}
			data.shippingData = { ...shippingData };
		}

		console.log(data);
		api
			.updateClientAddress(data.id, data)
			.then((res) => {
				console.log(res.data);
				setIsInProgress(false);
				if (res.message === 'Client updated successfully') {
					toast.success('Company Address Updated Successfully');
					history('/client/company-documents');
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				setIsInProgress(false);
			});
	};
	const moveToBack = () => {
		setIsInProgress(false);
		history('/client/company-details');
	};
	const handleCheckboxChange = (e) => {
		setIsCheckboxChecked(e.target.checked);
	};

	const calculateCompletionPercentage = () => {
		const fields = ['companyAddress', 'city', 'country', 'zip'];
		const filledFields = fields.filter((field) => billingData[field] !== '');
		return (filledFields.length / fields.length) * 100;
	};

	// Call this function whenever you want to get the completion percentage
	const completionPercentage = calculateCompletionPercentage();

	return (
		<div className={`main-page-client ${isMobile ? 'padding-mobile-client' : ''}`}>
			<div className="actions-company-details-client">
				<ProcessIndicator
					isInProgress={isInProgress}
					processes={CompanyDetailsProgresses}
					currentIndex={2}
					completionPercentage={completionPercentage}
				/>
			</div>
			<div className="company-details-client-content">
				<div className="" style={{ justifyContent: 'space-between' }}>
					<table className="projectmix-table-vertical-client">
						<tbody>
							<tr>
								{!isMobile && <th></th>}
								<td className="fw-600" style={{ textAlign: isMobile ? 'left' : 'left', fontSize: isMobile ? 16 : 14 }}>
									Billing Address
								</td>
							</tr>
							<tr>
								{!isMobile && <th>Address</th>}
								<td>
									{isMobile && <label className="onboard-label">Address</label>}
									<input
										type="text"
										className="form-control"
										value={billingData?.companyAddress}
										onChange={(e) => {
											setBillingData({ ...billingData, companyAddress: e.target.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
									/>
								</td>
							</tr>
							<tr>
								{!isMobile && <th>City</th>}
								<td>
									{isMobile && <label className="onboard-label">City</label>}
									<input
										type="text"
										className="form-control"
										value={billingData?.city}
										onChange={(e) => {
											setBillingData({ ...billingData, city: e.target.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
									/>
								</td>
							</tr>
							<tr>
								{!isMobile && <th>Country</th>}
								<td>
									{isMobile && <label className="onboard-label">Country</label>}
									<Select
										className="rs-container-est-white-client"
										classNamePrefix="rs"
										isSearchable={true}
										name="customer"
										options={allCountriesList?.map((y) => ({ label: y.name, value: y.name }))}
										value={allCountriesList?.map((y) => ({ label: y.name, value: y.name }))?.find((y) => y?.value == billingData?.country)}
										onChange={(e) => {
											setBillingData({ ...billingData, country: e.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
										menuPlacement="auto"
										menuPosition="fixed"
									/>
								</td>
							</tr>
							<tr>
								{!isMobile && <th>Zip code</th>}
								<td>
									{isMobile && <label className="onboard-label">Zip Code</label>}
									<input
										type="text"
										className="form-control"
										value={billingData?.zip}
										onChange={(e) => {
											setBillingData({ ...billingData, zip: e.target.value });
											const completionPercentage = calculateCompletionPercentage();
										}}
									/>
								</td>
							</tr>
						</tbody>
					</table>
					<div className="group-chk-cmpny-dtls">
						<input type="checkbox" name="" id="" onChange={handleCheckboxChange} />
						<label htmlFor="">Different Shipping Address</label>
					</div>
					{isCheckboxChecked && (
						<table className="projectmix-table-vertical-client">
							<tbody>
								<tr>
									{!isMobile && <th></th>}
									<td className="fw-600" style={{ textAlign: isMobile ? 'left' : 'left', fontSize: isMobile ? 16 : 14 }}>
										Shipping Address
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Address</th>}
									<td>
										{isMobile && <label className="onboard-label">Address</label>}
										<input
											type="text"
											className="form-control"
											value={shippingData?.companyAddress}
											onChange={(e) => {
												setShippingData({ ...shippingData, companyAddress: e.target.value });
											}}
										/>
									</td>
								</tr>
								<tr>
									{!isMobile && <th>City</th>}
									<td>
										{isMobile && <label className="onboard-label">City</label>}
										<input
											type="text"
											className="form-control"
											value={shippingData?.city}
											onChange={(e) => {
												setShippingData({ ...shippingData, city: e.target.value });
											}}
										/>
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Country</th>}
									<td>
										{isMobile && <label className="onboard-label">Country</label>}
										<Select
											className="rs-container-est-white-client"
											classNamePrefix="rs"
											isSearchable={true}
											name="customer"
											options={allCountriesList?.map((y) => ({ label: y.name, value: y.name }))}
											value={allCountriesList
												?.map((y) => ({ label: y.name, value: y.name }))
												?.find((y) => y?.value == shippingData?.country)}
											onChange={(e) => {
												setShippingData({ ...shippingData, country: e.value });
											}}
											menuPlacement="auto"
											menuPosition="fixed"
										/>
									</td>
								</tr>
								<tr>
									{!isMobile && <th>Zip Code</th>}
									<td>
										{isMobile && <label className="onboard-label">Zip Code</label>}
										<input
											type="text"
											className="form-control"
											value={shippingData?.zip}
											onChange={(e) => {
												setShippingData({ ...shippingData, zip: e.target.value });
											}}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					)}
				</div>
			</div>
			<div className="action-company-dtls-adrs-btn" style={{ marginTop: 10 }}>
				<button
					className="btn btn-cmpny-dtls-action-back"
					onClick={() => {
						setIsInProgress(true);
						moveToBack();
					}}
				>
					<MdKeyboardArrowLeft /> Back
				</button>

				<button
					className="btn btn-cmpny-dtls-action"
					onClick={() => {
						setIsInProgress(true);
						moveToNext();
					}}
				>
					Next <MdKeyboardArrowRight />
				</button>
			</div>
		</div>
	);
};

export default CompanyAddress;
