import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { FaMinus, FaPlus, FaTimes } from 'react-icons/fa';
import { currencyObject, getCurrencySymbol } from 'utils/currency';
import toast from 'react-hot-toast';
import { ImSpinner2 } from 'react-icons/im';

const ColapsibleTable = ({ close, purchaseList }) => {
	console.log(purchaseList);

	const [alertMessage, setAlertMessage] = useState();
	const [purcahseItem, setPurchaseItem] = useState();
	const [rows, setRows] = useState([
		{
			id: 1,
			product: null,
			purchase_listid: '',
			editmodal: false,
			estimateId: '',
			estimatenumber: '',
			quantity: '',
			unitPrice: '',
			supplierId: '',
			sellingprice: '',
			profit: '',
			profitPercent: '',
			totalprofit: '',
			purchaseNum: ''
		}
	]);
	const [supplierList, setSupplierList] = useState([]);
	const [nextId, setNextId] = useState(2);
	const [submitLoading, setSubmitLoading] = useState(false);

	const [currentCurrency, setCurrentCurrency] = useState('usd');

	const updateCallSup = () => {
		api.getSelectOptionsList('supplier').then((res) => {
			const tempList = res.data.map((item) => ({
				id: item.id,
				label: `${item.name}`,
				value: item.id
			}));
			setSupplierList(tempList);
		});
	};

	const getpurchaseItem = () => {
		api.purchaseListById(purchaseList).then((res) => {
			console.log(res);
			setPurchaseItem(res.data);
			const selectedItem = res.data;
			if (selectedItem != null) {
				const tempDataForObject = {
					id: selectedItem.id,
					product: selectedItem.product,
					purchase_listid: selectedItem.id,
					editmodal: true,
					estimateId: selectedItem?.estimation_id,
					estimatenumber: selectedItem?.estimation_number,
					quantity: selectedItem.sold_qty,
					unitPrice: '',
					supplierId: '',
					sellingprice: selectedItem?.converted_currency_selling_price_unit,
					profit: '',
					profitPercent: '',
					totalprofit: '',
					purchaseNum: selectedItem.id
				};
				setRows([tempDataForObject]);
				setNextId(2);
			}
		});
	};
	const removeRow = (id) => {
		if (rows.length === 1) {
			return;
		}
		const updatedRows = rows?.filter((row) => row.id !== id);
		setRows(updatedRows);
	};

	const handleQuantityChange = (id, value, pl_id) => {
		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				const maxQuantity = purcahseItem?.sold_qty;
				const inputVal = parseInt(value) || 0;
				const quantity = inputVal > maxQuantity ? maxQuantity : inputVal < 0 ? 0 : inputVal;
				const unitPrice = row.unitPrice || 0;
				let sellingPrice = row.sellingprice?.[currentCurrency] || 0;
				const profit = unitPrice > 0 ? (sellingPrice - unitPrice).toFixed(2) : 0;
				const profitPercent = unitPrice > 0 ? ((profit * 100) / unitPrice).toFixed(2) : 0;
				const totalprofit = unitPrice > 0 ? (quantity * profit).toFixed(2) : 0;

				return { ...row, quantity, unitPrice, profit, totalprofit, profitPercent };
			}
			return row;
		});
		setRows(updatedRows);
	};

	const handleUnitPriceChange = (id, value, currency) => {
		const updatedRows = rows.map((row) => {
			if (row.id === id) {
				const unitPrice = parseFloat(value) || 0;
				let sellingPrice = row.sellingprice?.[currentCurrency] || 0;
				const profit = unitPrice > 0 ? (sellingPrice - unitPrice).toFixed(2) : 0;
				const profitPercent = unitPrice > 0 ? ((profit * 100) / unitPrice).toFixed(2) : 0;
				const totalprofit = unitPrice > 0 ? (row.quantity * profit).toFixed(2) : 0;

				return { ...row, unitPrice, profit, totalprofit, profitPercent };
			}
			return row;
		});
		setRows(updatedRows);
	};

	const [firstSupplierSelect, setFirstSupplierSelect] = useState(true);

	const handleSupplierList = (id, value) => {
		const updatedRows = rows.map((row) => {
			if (firstSupplierSelect) {
				setFirstSupplierSelect(false);
				const supplierId = value.id;
				return { ...row, supplierId };
			} else {
				if (row.id === id) {
					const supplierId = value.id;
					return { ...row, supplierId };
				}
				return row;
			}
		});
		setRows(updatedRows);
	};
	const valueValidation = (e, purchaseId) => {
		let inputValue = e.target.value;

		if (inputValue > maxQuantity) {
			inputValue = maxQuantity;
		}
	};
	const handleAmount = (e) => {
		let inputValue = e.target.value;
		const currentdata = purcahseItem?.currency_unit_price;
		let maxQuantity =
			currentCurrency == 'eur'
				? currentdata?.unit_price_eur
				: currentCurrency == 'usd'
				? currentdata?.unit_price_usd
				: currentdata?.unit_price_aed;
		if (inputValue > maxQuantity) {
			inputValue = maxQuantity;
		}
		e.target.value = inputValue;
	};

	useEffect(() => {
		updateCallSup();
		getpurchaseItem();
	}, []);

	const validateRows = () => {
		if (rows.filter((r) => !r?.quantity)?.length > 0) {
			toast.error('Quantity is required for all items');
			return false;
		}

		if (rows.filter((r) => !r?.unitPrice)?.length > 0) {
			toast.error('Unit Price is required for all items');
			return false;
		}

		return true;
	};

	const saveData = () => {
		if (!validateRows()) {
		} else {
			setSubmitLoading(true);
			const data = {
				currency: currentCurrency,
				details: rows.map((obj) => ({
					purchase_unit_price: obj.unitPrice,
					purchase_qty: obj.quantity,
					supplier_id: obj.supplierId,
					purchase_list_id: obj.purchase_listid
				}))
			};
			toast.promise(
				api.backOrderMovetoFound(data).then(
					(res) => {
						if (res.status) {
							close('reload');
						}

						setSubmitLoading(false);

						return res?.status ? res : null;
					},
					() => {
						setSubmitLoading(false);
					}
				),
				{
					loading: 'Moving to found...',
					success: (data) => `${data.message}`,
					error: <b>Failed to move to found</b>
				}
			);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const handlecurrency = (e) => {
		setCurrentCurrency(e);
		const updatedRows = rows.map((row) => {
			const unitPrice = row.unitPrice || 0;
			let sellingPrice = row.sellingprice?.[e] || 0;
			const profit = unitPrice > 0 ? (sellingPrice - unitPrice).toFixed(2) : 0;
			const profitPercent = unitPrice > 0 ? ((profit * 100) / unitPrice).toFixed(2) : 0;
			const totalprofit = unitPrice > 0 ? (row.quantity * profit).toFixed(2) : 0;

			return { ...row, unitPrice, profit, totalprofit, profitPercent };
		});
		setRows(updatedRows);
	};

	useEffect(() => {
		const modelId = rows?.map((item) => item.purchase_listid);
	}, [rows]);
	return (
		<>
			<div className="foundListPopup">
				<div className="d-flex TableSerachBar align-items-center justify-content-between mb-4">
					<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0">Sourced Products</h5>

					<div className="CurrencySel d-flex align-items-center">
						<Select
							className="rs-container"
							classNamePrefix="rs"
							value={currencyObject.find((item) => item.value == currentCurrency)}
							options={currencyObject}
							onChange={(e) => {
								handlecurrency(e.value);
							}}
						/>
					</div>
					<button className="btn modal-close-icon" onClick={close}>
						<FaTimes />
					</button>
				</div>
				<div className="table-responsive-xl tableData productsTable">
					<table className="table collaps table-md  formActBtns border-0">
						<thead>
							<tr>
								<th className="align-middle" width={'300'}>
									Model
								</th>
								<th className="align-middle">Estimate</th>
								<th className="align-middle">Quantity</th>
								<th className="align-middle">Unit Price</th>
								<th className="align-middle" width={'240'}>
									Supplier
								</th>
								<th className="align-middle">Selling Price</th>
								<th className="align-middle">Gross Profit</th>
								<th className="align-middle">Total Profit</th>
								<th className="align-middle text-center" width={'50'}></th>
							</tr>
						</thead>
						<tbody>
							{rows.map((row, index) => (
								<>
									<tr key={row?.id}>
										<td className="align-middle form-row estimateCName">{row?.product?.product_name}</td>
										<td className="align-middle">{row.estimatenumber}</td>
										<td className="align-middle">
											<input
												className="numField"
												type="number"
												value={row?.quantity}
												placeholder="00"
												onChange={(e) => {
													handleQuantityChange(row.id, e.target.value, row.purchase_listid);
												}}
												min={0}
												max={purcahseItem?.sold_qty}
											/>
										</td>

										<td className="align-middle">
											<input
												className="numField"
												type="number"
												defaultValue="{'00'}"
												placeholder="00"
												onChange={(e) => {
													handleAmount(e, row.purchaseNum);
													handleUnitPriceChange(row.id, e.target.value);
												}}
											/>
										</td>
										<td className="align-middle form-row estimateCName">
											<div className="form-group col-md-12 mb-0">
												<Select
													className="rs-filter-container"
													classNamePrefix="rs-filter"
													isSearchable={true}
													name="customer"
													options={supplierList}
													value={supplierList.find((sup) => sup.id == row.supplierId)}
													placeholder="Select"
													onChange={(selected) => handleSupplierList(row.id, selected)}
												/>
											</div>
										</td>
										<td className="align-middle">
											{getCurrencySymbol(currentCurrency)}
											{row?.sellingprice?.[currentCurrency]}
										</td>
										<td className="align-middle">
											{getCurrencySymbol(currentCurrency)}
											{row.profit || '00'} {`(${row.profitPercent || '00'}%)`}
										</td>
										<td className="align-middle">
											{getCurrencySymbol(currentCurrency)}
											{row.totalprofit || '00'}
										</td>
										<td className="align-middle text-center">
											<span className="btnEdit plain-iconbtn" onClick={() => removeRow(row.id)}>
												<FaTimes />
											</span>
										</td>
									</tr>
								</>
							))}
							{alertMessage?.modelrow && <span className="px-3 down-error text-danger">{alertMessage?.modelrow}</span>}
						</tbody>
					</table>
				</div>
			</div>
			<div className="btnarea mt-5">
				{/* <button onClick={close} className="btn btn-link">
					Cancel
				</button> */}
				<button onClick={() => saveData()} disabled={submitLoading} className="btn btn-main">
					<>
						&nbsp; <FaPlus /> Move to sourced &nbsp;
					</>
				</button>
			</div>
		</>
	);
};

export default ColapsibleTable;
