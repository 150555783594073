import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { storeInLocalStore } from 'store/reducers/mainSlice';

const CustomAlert = () => {
	const dispatch = useDispatch();
	const alertmessage = useSelector((state) => state?.main?.user.stateAlertmessage);
	const [message, setMessage] = useState();
	const disablePopup = () => {
		dispatch(storeInLocalStore({ stateAlertmessage: '' }));
	};
	useEffect(() => {
		setMessage(alertmessage);
		setTimeout(() => {
			setMessage('');
			dispatch(storeInLocalStore({ stateAlertmessage: '' }));
		}, 2500);
	}, [alertmessage]);
	return (
		<>
			{message && (
				<div
					className="alert alert-dismissible fade show"
					role="alert"
					style={{
						position: 'absolute',
						top: '150px',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						minWidth: '300px',
						zIndex: '99',
						background: '#292932d4',
						borderColor: ' #fff'
					}}
				>
					<span className={`text-success`}>{message}</span>
					<button onClick={disablePopup} type="button" className="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true" className={`text-success`}>
							&times;
						</span>
					</button>
				</div>
			)}
		</>
	);
};

export default CustomAlert;
