import React, { useEffect, useState } from 'react';
import usePermissions from 'hooks/usePermissions';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Scroller from 'ui-component/Scroller';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { TbDatabaseExclamation } from 'react-icons/tb';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const ProductHistory = ({
    productDetails,
}) => {

    const history = useNavigate();
    const [productHistory, setProductHistory] = useState();
    const [allPermissions, hasEveryPermission] = usePermissions();

    const getProductHistory = (id) => {
        api.getProductHistory(id).then((res) => {
            if (res.status) {
                setProductHistory(res?.data)
            }
        })
    };

    useEffect(() => {
        getProductHistory(productDetails?.id);
    }, []);
    return (
        <>
            <div className="content mt15">
                <div className="row">
                    <div className="col-12 ">
                        {productHistory?.length > 0 ? (
                            <>

                                <div className="table-responsive-xl">
                                    <Scroller>
                                        <table className="table pm-table">
                                            <thead>
                                                <tr className='sticky-header '>
                                                    <th scope="col" style={{ width: '170px' }}>Warehouse</th>
                                                    <th scope="col" style={{ width: '95px' }}>Old Stock</th>
                                                    <th scope="col" style={{ width: '95px' }}>New Stock</th>
                                                    <th scope="col" style={{ width: '95px' }}>Difference</th>
                                                    <th scope="col">Reason</th>
                                                    <th scope="col" style={{ width: '150px' }}>User</th>
                                                    <th scope="col" style={{ width: '140px' }}>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productHistory?.map((row, index) => {
                                                    return (
                                                        <tr key={index} style={{ height: '52px' }}>
                                                            <td className="align-middle font14">
                                                                <a onClick={() => {
                                                                    if (hasEveryPermission(['View Warehouse Details (Page)'])) {
                                                                        history(`/warehouses/${row?.warehouse?.id}`);
                                                                    }
                                                                }}
                                                                    target="_blank"
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="link-list-item"
                                                                >
                                                                    {row?.warehouse?.name}
                                                                </a>
                                                            </td>
                                                            <td className="align-middle text-left font14">{row?.old_stock}</td>
                                                            <td className="font14 text-left">{row?.new_stock}</td>
                                                            <td className={`text-left font14 ${row?.difference > 0 ? 'positive' : row?.difference < 0 ? 'negative' : ''}`}>{row?.difference}</td>
                                                            <td className='text-left'>{row?.action_detail}</td>
                                                            <td className='text=left'>{row?.created_by?.name}</td>
                                                            <td className="text-left font14">{moment(row?.created_at).fromNow()}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Scroller>
                                </div>
                            </>
                        ) : (
                            <>

                                <div className="not-found-container">
                                    <div className="not-found-icon">
                                        <TbDatabaseExclamation />
                                    </div>
                                    <h6 className="not-found-text">No Data Available</h6>
                                </div>
                            </>
                        )}



                    </div>

                </div>
            </div>
        </>
    );
};

export default ProductHistory;
