// ContactCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Fade } from 'react-awesome-reveal';

const ContactCard = ({ contact }) => {
	return (
		<div className="col-performance col-md-6 col-sm-12">
			<Fade>
				<div className="card" style={{ height: '280px', marginBottom: '20px' }}>
					<div className="card-header" style={{ backgroundColor: 'white' }}>
						<a style={{ fontWeight: '500' }}>New Contacts</a>
					</div>
					<div className="card-body" style={{ paddingTop: '10px', maxHeight: '300px', overflowY: 'auto' }}>
						{Object.values(contact).map((item, index) => {
							const initials = item.name
								.split(' ')
								.map((word) => word[0])
								.join('')
								.toUpperCase();
							return (
								<div className="row" style={{ marginBottom: '13px' }} key={index}>
									<div className="col-3 contact-col">
										<div className="contact-circle">
											<PrivacyModeContent>{initials}</PrivacyModeContent>
										</div>
									</div>
									<div className="col-9" style={{ marginTop: 2 }}>
										<Link
											to={`/contacts/${item.number}`}
											style={{ display: 'block', fontWeight: 600, color: 'black', textDecoration: 'none' }}
										>
											<PrivacyModeContent>{item.name}</PrivacyModeContent>
										</Link>
										<a style={{ display: 'block', color: '#a5a5a5' }}>
											<PrivacyModeContent>Created By: {item.sales_person_name}</PrivacyModeContent>
										</a>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default ContactCard;
