import { BiCategoryAlt } from 'react-icons/bi';
import { BsClipboardCheck } from 'react-icons/bs';
import { FaBoxesStacked } from 'react-icons/fa6';
import { GiAutoRepair, GiHandTruck, GiMagnifyingGlass } from 'react-icons/gi';
import { PiPackageBold, PiTestTubeFill } from 'react-icons/pi';
import { LuTestTubes } from 'react-icons/lu';

export const CompanyDetailsProgresses = [
	{ name: 'Login', icon: <PiTestTubeFill />, status: '', link: '' },
	{ name: 'Company', icon: <GiMagnifyingGlass />, status: '', link: '' },
	{ name: 'Address', icon: <GiAutoRepair />, status: '', link: '' },
	{ name: 'Documents', icon: <LuTestTubes />, status: '', link: '' },
	{ name: 'Bank', icon: <PiPackageBold />, status: '', link: '' },
	{ name: 'Survey', icon: <PiPackageBold />, status: '', link: '' }
];
