import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LuChevronDown, LuSend } from 'react-icons/lu';
import usePageTitle from 'hooks/usePageTitle';
import Scroller from 'ui-component/Scroller';
import { TbDatabaseExclamation } from 'react-icons/tb';
import toast from 'react-hot-toast';
import { FaCheck, FaPlus, FaPencilAlt, FaTimes, FaSpinner, FaTrashAlt } from 'react-icons/fa';
import { RiTruckLine } from 'react-icons/ri';
import moment from 'moment';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import FileUpload from 'ui-component/FileUpload';
import NoImage from 'assets/images/no-image.png';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { Tooltip } from '@mui/material';
import StatusBadge from 'ui-component/StatusBadge';
import { useDropzone } from 'react-dropzone';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import usePermissions from 'hooks/usePermissions';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import PaymentModal from './components/PaymentModal';
import EmailPurchaseOrderModal from './components/EmailPurchaseOrderModal';
import MarkAsPurchasedModal from './components/MarkAsPurchasedModal';
import { saveAs } from 'file-saver';
import { DeleteIcon2 } from 'ui-component/custom-icons/icons';
import { Skeleton } from '@mui/material';

import PdfViewer from 'ui-component/PdfViewer';

const PurchasingDetails = () => {
    const { id } = useParams();
    const history = useNavigate();
    const [purchaseInvoice, setPurchaseInvoice] = useState(null);
    const [uploadInvoice, setUploadInvoice] = useState(false);
    const [showMarginModal, setShowMarginModal] = useState(false);
    const [file, setFile] = useState();
    const [receipts, setReceipts] = useState([]);
    const [setPageTitle] = usePageTitle('Purchasing');
    const [currency, setCurrency] = useState('');
    const [items, setItems] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [selectedMarginIndex, setSelectedMarginIndex] = useState(null);
    const [subtotal, setSubtotal] = useState({ usd: "0.00", eur: "0.00", aed: "0.00" });
    const [total, setTotal] = useState({ usd: "0.00", eur: "0.00", aed: "0.00" });
    const [overallMargin, setOverallMargin] = useState({ usd: "0.00", eur: "0.00", aed: "0.00" });
    const [paymentAttachmentId, setPaymentAttachmentId] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
	const [allPermissions, hasEveryPermission] = usePermissions();
    const [showMarkAsPurchasedModal, setShowMarkAsPurchasedModal] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false)
    
    const [email, setEmail] = useState(purchaseInvoice?.supplier?.email || '');
    const [subject, setSubject] = useState(`Purchase Order - ${purchaseInvoice && purchaseInvoice.purchase_order_id}`);
    const [message, setMessage] = useState(
        `Dear ${purchaseInvoice?.supplier?.name},\n\nWe are hereby sending you an order, under our reference ${purchaseInvoice?.purchase_order_id}. The order is attached as a PDF and as an Excel file.\n\nKind regards,\nMix Global FZCO`
    );
    const [isSending, setIsSending] = useState(false);

    const [showDelete, setShowDelete] = useState(false);
    const [showDeletePurchaseInvoice, setShowDeletePurchaseInvoice] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPaymentDelete, setShowPaymentDelete] = useState(false);
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);

    useEffect(() => {
        initiate();
    }, [id]);

    const initiate = () => {
        api.getPurchaseOrderDetails(id).then((res) => {
            setPurchaseInvoice(res?.data);
            setReceipts(res?.data?.receipts);

            setCurrency(res?.data?.currency);

            const populate = res?.data?.purchase_invoice_items?.map(item => {

                const currency = res?.data?.currency;
                const purchasePrice = item?.price !== '0.00' ? item?.price : '0.00';
                return {
                    id: item?.id,
                    product_id: item?.product?.id,
                    name: item?.product?.name,
                    sku: item?.product?.sku,
                    base_qty: item?.quantity,
                    qty: item?.quantity,
                    qty_needed: item?.quantity,
                    received_quantity: item?.received_quantity,
                    available_quantity: item?.product?.available_quantity,
                    purchased_quantity: item?.product?.purchased_quantity,
                    thumbnail_url: item?.product?.thumbnail_url,
                    purchase_price: item?.converted_currency_price,
                    currency: currency,
                    // total_price: item?.total,
                    total_price: {
                        'usd': (parseFloat(item?.converted_currency_price['usd']) * item?.quantity).toFixed(2),
                        'aed': (parseFloat(item?.converted_currency_price['aed']) * item?.quantity).toFixed(2),
                        'eur': (parseFloat(item?.converted_currency_price['eur']) * item?.quantity).toFixed(2)
                    },
                    sales_price: item?.converted_currency_selling_price,
                    supplier_id: item?.supplier_id,
                    supplier: item?.supplier,
                    margin_price: {
                        'usd': (parseFloat(item?.converted_currency_selling_price['usd']) - parseFloat(purchasePrice)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                        'aed': (parseFloat(item?.converted_currency_selling_price['aed']) - parseFloat(purchasePrice)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                        'eur': (parseFloat(item?.converted_currency_selling_price['eur']) - parseFloat(purchasePrice)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    },
                    total_margin: {
                        'usd': ((parseFloat(item?.converted_currency_selling_price['usd']) - parseFloat(purchasePrice)) * item?.quantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                        'aed': ((parseFloat(item?.converted_currency_selling_price['aed']) - parseFloat(purchasePrice)) * item?.quantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                        'eur': ((parseFloat(item?.converted_currency_selling_price['eur']) - parseFloat(purchasePrice)) * item?.quantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    },
                };
            });
            
            // setPurchaseOrderItems(populate);
            setItems(populate);
            setTotalQuantity(populate?.reduce((acc, item) => acc + (parseInt(item.qty) || 0), 0));

            // setSubtotal({
            //     'usd': (populate?.reduce((acc, item) => acc + (parseFloat(item.total_price['usd'].replace(/,/g, '')) || 0), 0)),
            //     'aed': (populate?.reduce((acc, item) => acc + (parseFloat(item.total_price['aed'].replace(/,/g, '')) || 0), 0)),
            //     'eur': (populate?.reduce((acc, item) => acc + (parseFloat(item.total_price['eur'].replace(/,/g, '')) || 0), 0))
            // })
            setSubtotal({
                'usd': (populate?.reduce((acc, item) => {
                    return acc + (parseFloat(item.total_price['usd']) !== 0.00 ? parseFloat(item.total_price['usd'].replace(/,/g, '')) : 0);
                }, 0)),
                'aed': (populate?.reduce((acc, item) => {
                    return acc + (parseFloat(item.total_price['aed']) !== 0.00 ? parseFloat(item.total_price['aed'].replace(/,/g, '')) : 0);
                }, 0)),
                'eur': (populate?.reduce((acc, item) => {
                    return acc + (parseFloat(item.total_price['eur']) !== 0.00 ? parseFloat(item.total_price['eur'].replace(/,/g, '')) : 0);
                }, 0)),
            })
            setTotal({
                'usd': (populate?.reduce((acc, item) => {
                    return acc + (parseFloat(item.total_price['usd']) !== 0.00 ? parseFloat(item.total_price['usd'].replace(/,/g, '')) : 0);
                }, 0) + parseFloat(res?.data?.shipping_fee)),
                'aed': (populate?.reduce((acc, item) => {
                    return acc + (parseFloat(item.total_price['aed']) !== 0.00 ? parseFloat(item.total_price['aed'].replace(/,/g, '')) : 0);
                }, 0) + parseFloat(res?.data?.shipping_fee)),
                'eur': (populate?.reduce((acc, item) => {
                    return acc + (parseFloat(item.total_price['eur']) !== 0.00 ? parseFloat(item.total_price['eur'].replace(/,/g, '')) : 0);
                }, 0) + parseFloat(res?.data?.shipping_fee)),
            })

            setOverallMargin({
                'usd': (populate?.reduce((acc, item) => {
                    return parseFloat(acc) + parseFloat(item.total_margin['usd'].replace(/,/g, ''));
                }, 0)),
                'aed': (populate?.reduce((acc, item) => {
                    return parseFloat(acc) + parseFloat(item.total_margin['aed'].replace(/,/g, ''));
                }, 0)),
                'eur': (populate?.reduce((acc, item) => {
                    return parseFloat(acc) + parseFloat(item.total_margin['eur'].replace(/,/g, ''));
                }, 0)),
            })


            setDataLoaded(true)
            // Set initial values of email, subject, and message
            setEmail(res?.data?.supplier?.email || '');
            setSubject(`Purchase Order - ${res?.data?.purchase_order_id}`);
            setMessage(
                `Dear ${res?.data?.supplier?.name},\n\nWe are hereby sending you an order, under our reference ${res?.data?.purchase_order_id}. The order is attached as a PDF and as an Excel file.\n\nKind regards,\nMix Global FZCO`
            );
        });

    };
    const sumQuantity = purchaseInvoice?.purchase_invoice_items?.reduce((total, row) => total + row.quantity, 0);
    const totalrecieved = purchaseInvoice?.receipts?.reduce(
        (total, row) => total + row.receipt_items?.reduce((total, item) => total + (item.quantity || 0), 0),
        0
    );

    const handleDeletePurchaseInvoice = () => {
        setShowDeletePurchaseInvoice(true);
    };
    const deletePurchaseInvoice = () => {
        api.deletePurchaseInvoice(id).then((res) => {

            if (res.status) {
                toast.success(res.message);
                history('/purchasing');
            } else {
                toast.error(res.message);
            }
        });
    }

    const handleFileUpload = (e) => {
        const files = e.target.files;
        setFile(files?.length >= 1 ? files?.[0] : []);
    };

    const markPurchased = () => {

        if (!purchaseInvoice?.warehouse_id) {
            toast.error('Please update purchase invoice details');
            return false;
        } else if (items.length === 0) {
            toast.error('Please update purchase invoice details');
            return false;
        } else if (!validateItems()) {
            return false;
        }

        purchaseInvoice['items'] = items;


        setShowMarkAsPurchasedModal(true)

        // api.markAsPurchased(id, purchaseInvoice).then((res) => {

        //     if (res.status) {
        //         toast.success(res.message);
        //         initiate();
        //     } else {
        //         toast.error(res.message);
        //     }
        // });
    };

    // const handleMarkAsPurchased = () => {

    //     api.markAsPurchased(id, purchaseInvoice).then((res) => {

    //         if (res.status) {
    //             toast.success(res.message);
    //             initiate();
    //         } else {
    //             toast.error(res.message);
    //         }
    //     });
    // }

    const validateItems = () => {
        let isValid = true;
        const currency = purchaseInvoice?.currency;
        items.forEach((item, index) => {
            if (!item.name) {
                toast.error(`Please select product name for row ${index + 1}`);
                isValid = false;
                return;
            } else if (!item.qty || isNaN(item.qty) || item.qty <= 0) {
                toast.error(`Please provide a valid quantity for ${item.name}`);
                isValid = false;
                return;
            } else if (!item.purchase_price[currency] || isNaN(item.purchase_price[currency]) || item.purchase_price[currency] <= 0) {
                toast.error(`Please provide a valid price for ${item.name}`);
                isValid = false;
                return;
            }
        });
        return isValid
    }
    const emailSupplier = () => {
        setIsModalOpen(true);
        setIsSending(false);
    };

    const downloadPurchaseOrder = (type) => {
        if (type == 'pdf') {
            api.downloadPurchaseOrderPdf(id).then((res) => {

                var newBlob = new Blob([res], { type: 'application/pdf' });
                saveAs(
                    newBlob,
                    `Purchase Order.pdf`
                );
            });
        } else {

            api.downloadPurchaseOrderExcel(id).then((res) => {
                var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(newBlob, `Purchase Order - ${purchaseInvoice && purchaseInvoice.purchase_order_id}.xlsx`);
            });

        }
    }
    const registerReceipt = () => {
        history(`/purchasing/create/${id}/receipt`);
    };
    const registerPayment = () => {
        setShowPaymentModal(true)
    }
    const uplodInvoice = () => {
        setUploadInvoice(true);
    };
    const uploadFile = () => {
        const data = {
            id: id,
            attachment: file
        };

        api.uploadInvoiceAttachment(id, data).then((res) => {

            if (res.status) {
                toast.success(res.message);
                initiate();
                setUploadInvoice(false);
            } else {
                toast.error(res.message);
            }
        });
    };
    const handleClose = () => {
        setUploadInvoice(false);
        setShowMarginModal(false);
        setIsModalOpen(false)
        setShowDelete(false)
        setShowPaymentModal(false)
        setShowDeletePurchaseInvoice(false)
        setShowPaymentDelete(false)
        setShowMarkAsPurchasedModal(false)
    }
    // const handleClose2 = () => setIsModalOpen(false);
    const handleSaveAndClose = () => {
        initiate();
        setShowPaymentModal(false)
    }
    const handleMarkAsPurchased = () => {
        initiate();
        setShowMarkAsPurchasedModal(false)
    }


	const viewData = (type, id) => {
		if (type === 'products') {
			window.open(`/products/${id}`, '_blank');
			// history(`/products/${id}`);
		} else if (type === 'purchase_invoice') {
			window.open(`/purchasing/view/${id}`, '_blank');
		} else if (type === 'supplier') {
			window.open(`/contacts/${id}`, '_blank');
		} else if (type === 'warehouse') {
			window.open(`/warehouses/${id}`, '_blank');
		}
    };
    
    const showProductMargin = (index) => {
        setShowMarginModal(true)
        setSelectedMarginIndex(index)
    }


    const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png'];
    const validateFileExtension = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };

    const [paymentAttachment, setPaymentAttachment] = useState('');
	const [vatValidationAlert, setVatvalidationlert] = useState();
    
	const [paymentData, setPaymentData] = useState({
		purchase_invoice_id: purchaseInvoice?.id,
		attachment: '',
		filename: '',
    });
    const [attachment, setAttachment] = useState([]);
    
	const onDrop = useCallback((acceptedFiles) => {
		// if (validateFileExtensionforLogo(acceptedFiles[0].name)) {
		// 	setData((prevData) => ({ ...prevData, companyLogo: acceptedFiles[0] }));
		// 	setLogo(acceptedFiles[0].name);
		// } else {
			toast.error('Invalid file extension. Please select a JPEG, JPG, PNG, or PDF file.');
		// }
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: '.pdf, .jpeg, .jpg, .png',
		multiple: false
    });
    

    const uploadAttachment = async (attachment) => {
        const formData = new FormData();
        formData.append('attachment', attachment);
        formData.append('purchase_invoice_id', purchaseInvoice?.id);
        formData.append('type', 'payment');

        try {

			toast.promise(
				api
					.uploadPurchaseOrderAttachment(id, formData)
					.then(
						(res) => {
							if (res.status) {
                                initiate();
                                setUploadInvoice(false);
							}

							return res?.status ? res : null;
						},
						() => {}
					),
				{
					loading: 'uploading file...',
					success: (data) => `${data.message}`,
					error: <b>Failed to upload file</b>
				}
            );
            
        } catch (error) {
            toast.error('Error uploading file');
        }
    };
    
	const onPaymentDrop = useCallback((acceptedFiles) => {
		

        if (validateFileExtension(acceptedFiles[0].name)) {
            uploadAttachment(acceptedFiles[0]);
			setPaymentData((prevData) => ({ ...prevData, attachment: acceptedFiles[0] }));
			setPaymentAttachment(acceptedFiles[0].name);
		} else {
			toast.error('Invalid file extension. Please select a PDF, JPEG, JPG, or PNG file.');
		}
	}, []);
	const { getRootProps: getPaymentRootProps, getInputProps: getPaymentInputProps } = useDropzone({ onDrop: onPaymentDrop });

    const removeAttachment = (id) => {
        setShowDelete(true);
        setPaymentAttachmentId(id)
    }
    const handleDeletePaymentAttachment = () => {
        const id = paymentAttachmentId;
        api.deletePurchaseOrderAttachment(id).then((res) => {
            if (res.status) {
                setShowDelete(false);
                setPaymentAttachmentId(null)
                toast.success(res.message);
                initiate();
            } else {
                toast.error(res.message);
            }
        });
    }
    const deletePayment = (id) => {
        setShowPaymentDelete(true)
        setPaymentId(id)
    }

    const handleDeletePayment = () => {
        
        api.deletePurchaseOrderPayment(paymentId).then((res) => {
            if (res.status) {
                setShowPaymentDelete(false);
                setPaymentId(null)
                toast.success(res.message);
                initiate();
            } else {
                toast.error(res.message);
            }
        });
    }

    const downloadAttachment = (attachment) => {
        // api.downloadPurchaseOrderAttachment(attachment?.id).then((res) => {
        //     const mimeType = attachment?.extension === 'pdf' ? 'application/pdf' : 'image/jpeg'; // Adjust the MIME type as necessary
        //     const newBlob = new Blob([res], { type: mimeType });
        //     saveAs(newBlob, `${attachment?.attachment}`); // Use the filename from the attachment
        // });
        attachment?.attachment && window.open(attachment?.attachment_url, '_blank');
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-12">
                    <div className="section-head row">
                        <h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }} className="col-md-4">
                            {purchaseInvoice && purchaseInvoice.purchase_order_id}
                        </h2>
                        <div className="actions col-md-8">

                            <PermissionAllowed permissions={['Email Purchase Order']} hasSome>

                                <button type="button" onClick={() => emailSupplier()} className="btn btn-primary btn-outlined-main">

                                    <svg
                                        id="fi_2983788"
                                        enable-background="new 0 0 512.005 512.005"
                                        height="18"
                                        viewBox="0 0 512.005 512.005"
                                        width="18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g>
                                            <path
                                                d="m511.658 51.675c2.496-11.619-8.895-21.416-20.007-17.176l-482 184c-5.801 2.215-9.638 7.775-9.65 13.984-.012 6.21 3.803 11.785 9.596 14.022l135.403 52.295v164.713c0 6.948 4.771 12.986 11.531 14.593 6.715 1.597 13.717-1.598 16.865-7.843l56.001-111.128 136.664 101.423c8.313 6.17 20.262 2.246 23.287-7.669 127.599-418.357 122.083-400.163 122.31-401.214zm-118.981 52.718-234.803 167.219-101.028-39.018zm-217.677 191.852 204.668-145.757c-176.114 185.79-166.916 176.011-167.684 177.045-1.141 1.535 1.985-4.448-36.984 72.882zm191.858 127.546-120.296-89.276 217.511-229.462z"
                                                fill="currentColor"
                                            ></path>
                                        </g>
                                    </svg>
                                    Send to Supplier
                                </button>
                            </PermissionAllowed>

                            <PermissionAllowed permissions={['Export Purchase Order']} hasSome>

                                <Dropdown
                                    // className="btnDel btn-export-po iconbtn"
                                    className={ purchaseInvoice?.master_status?.name === 'Concept' ? 'btnDel btn-export-po iconbtn' : 'btnDel btn-export-po-2 iconbtn'}
                                    align={'end'}>
                                    <Dropdown.Toggle as="span">
                                        <Link to={''} className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: 'unset' }}>

                                            <svg
                                                id="fi_2767144"
                                                enable-background="new 0 0 128 128"
                                                height="18"
                                                viewBox="0 0 128 128"
                                                width="18"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    id="Download"
                                                    d="m128 65c0 15.439-12.563 28-28 28h-20c-2.211 0-4-1.791-4-4s1.789-4 4-4h20c11.027 0 20-8.973 20-20s-8.973-20-20-20h-4c-2.211 0-4-1.791-4-4 0-15.439-12.563-28-28-28s-28 12.561-28 28c0 2.209-1.789 4-4 4h-4c-11.027 0-20 8.973-20 20s8.973 20 20 20h20c2.211 0 4 1.791 4 4s-1.789 4-4 4h-20c-15.437 0-28-12.561-28-28s12.563-28 28-28h.223c1.996-17.975 17.277-32 35.777-32s33.781 14.025 35.777 32h.223c15.438 0 28 12.561 28 28zm-50.828 37.172-9.172 9.172v-50.344c0-2.209-1.789-4-4-4s-4 1.791-4 4v50.344l-9.172-9.172c-1.563-1.563-4.094-1.563-5.656 0s-1.563 4.094 0 5.656l16 16c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l16-16c1.563-1.563 1.563-4.094 0-5.656s-4.094-1.563-5.656 0z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                            Export <LuChevronDown />
                                        </Link>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                        <PermissionAllowed permissions={['Export Purchase Order']}>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    downloadPurchaseOrder('pdf');
                                                }}
                                            >
                                                Export to PDF
                                            </Dropdown.Item>
                                        </PermissionAllowed>
                                        <PermissionAllowed permissions={['Export Purchase Order']}>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    downloadPurchaseOrder('excel');
                                                }}
                                            >
                                                Export to Excel
                                            </Dropdown.Item>
                                        </PermissionAllowed>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </PermissionAllowed>

                            <PermissionAllowed permissions={['Edit Purchase Order']}>
                                
                                {/* {
                                    purchaseInvoice?.master_status?.name === 'Concept' && ( */}
                                        <button type="button" onClick={() => history(`/purchasing/edit/${id}`)} className="btn btn-primary btn-outlined-main">
                                            <FaPencilAlt /> Edit
                                        </button>
                                    {/* )
                                } */}
                            </PermissionAllowed>
                            <PermissionAllowed permissions={['Delete Purchase Order']}>
                                <button
                                    type="button"
                                    onClick={() => { 
                                        if (hasEveryPermission(['Delete Purchase Order'])) {
                                            handleDeletePurchaseInvoice()
                                        }
                                    } }
                                    className="btn btn-danger btn-outlined-danger"
                                >
                                    <FaTrashAlt /> Delete
                                </button>
                            </PermissionAllowed>
                            

                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="card shadow-sm rounded bg-white mb-4 p-3 p-xl-4 w-100">
                            <div className="parent-group">
                                <div className="btn-group">
                                    <button
                                        className={`btn btn-default btn-sm btn-left events-none ${purchaseInvoice?.master_status?.name === 'Concept' ? 'concept' : ''}`}
                                        style={{ minWidth: '100px'}}
                                    >
                                        Concept
                                    </button>
                                    <button
                                        className={`btn btn-default btn-sm btn-middle events-none ${purchaseInvoice?.master_status?.name === 'Purchased' ? 'purchased' : '' }`}
                                        style={{ minWidth: '100px'}}
                                    >
                                        Purchased
                                    </button>
                                    <button
                                        className={`btn btn-default btn-sm btn-right events-none ${purchaseInvoice?.master_status?.name === 'Received' ? 'received' : '' }`}
                                        style={{ minWidth: '100px'}}
                                    >
                                        Received
                                    </button>
                                    {/* {
                                        purchaseInvoice?.payment_method == 'in_credit' ? (
                                            <>
                                                <button
                                                    className={`btn btn-default btn-sm btn-right events-none ${purchaseInvoice?.master_status?.name === 'Paid' ? 'po_paid' : ''
                                                        }`}
                                                >
                                                    Payment
                                                </button>
                                                <button
                                                    className={`btn btn-default btn-sm btn-right events-none ${purchaseInvoice?.master_status?.name === 'Received' ? 'received' : ''
                                                        }`}
                                                >
                                                    Received
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    className={`btn btn-default btn-sm btn-right events-none ${purchaseInvoice?.master_status?.name === 'Received' ? 'received' : ''
                                                        }`}
                                                >
                                                    Received
                                                </button>
                                                <button
                                                    className={`btn btn-default btn-sm btn-right events-none ${purchaseInvoice?.master_status?.name === 'Paid' ? 'po_paid' : ''
                                                        }`}
                                                >
                                                    Paid
                                                </button>
                                            </>
                                       )
                                    } */}
                                </div>

                                <PermissionAllowed permissions={['Process Purchase Order']} hasSome>
                                    <div className="btn-group-actions">
                                        {
                                            purchaseInvoice?.master_status?.name === 'Concept' && (
                                                <>
                                                        <button className="btn btn-default btn-sm btn-purchase-main" onClick={() => markPurchased()}>
                                                            {' '}
                                                            <FaCheck /> Mark as purchased
                                                        </button>
                                                </>
                                            )
                                        }
                                        { 
                                            purchaseInvoice?.master_status?.name === 'Purchased' && (
                                                <>
                                                    {
                
                                                        (purchaseInvoice?.payment_method == 'in_credit') ? (
                                                            <>
                                                                {
                                                                    purchaseInvoice?.master_status?.name === 'Purchased' && (
                                                                        <>
                                                                            <button
                                                                                className="btn btn-default btn-sm btn-purchase-main"
                                                                                disabled={sumQuantity == totalrecieved}
                                                                                onClick={() => registerReceipt()}
                                                                            >
                                                                                {' '}
                                                                                <RiTruckLine /> Register receipt
                                                                            </button>
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    (purchaseInvoice?.payment_balance?.paid !== 0) && (
                                                                        <>
                                                                            <button
                                                                                className="btn btn-default btn-sm btn-purchase-main"
                                                                                disabled={sumQuantity == totalrecieved}
                                                                                onClick={() => registerReceipt()}
                                                                            >
                                                                                {' '}
                                                                                <RiTruckLine /> Register receipt
                                                                            </button>
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        {/* ) : (
                                            <>
                                                <button
                                                    className="btn btn-default btn-sm btn-purchase-main"
                                                    disabled={sumQuantity == totalrecieved}
                                                    onClick={() => registerReceipt()}
                                                >
                                                    {' '}
                                                    <RiTruckLine /> Register receipt
                                                </button>
                                            </>
                                        )} */}
                                    </div>
                                </PermissionAllowed>
                                
                                <PermissionAllowed permissions={['Add Purchase Order Payment']} hasSome>
                                {
                                    purchaseInvoice?.payment_method === 'upfront_payment' ? (
                                        <>
                                            {
                                                purchaseInvoice?.master_status?.name !== 'Concept' && (
                                                    <>
                                                        <div
                                                            className="text-right"
                                                            style={{position: 'absolute', right: '20px' }}
                                                        >
                                                            <div className="btn-group-actions" style={{ display: 'flex' }}>
                                                                <div
                                                                    style={{ marginRight: '10px', marginTop: '2px' }}
                                                                >
                                                                    <StatusBadge itemStatus={purchaseInvoice?.payment_status?.name} />
                                                                </div>
                                                                {
                                                                    purchaseInvoice?.payment_status?.name === 'Open' && (
                                                                        <>
                                                                            <button
                                                                                className="btn btn-default btn-sm btn-purchase-main"
                                                                                onClick={() => registerPayment()}
                                                                            >
                                                                                {' '}
                                                                                Add Payment
                                                                            </button>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    ) : purchaseInvoice?.payment_method === 'in_credit' ? (
                                        <>
                                            {
                                                purchaseInvoice?.master_status?.name === 'Received' && (
                                                    <>
                                                        <div
                                                            className="text-right"
                                                            style={{position: 'absolute', right: '20px' }}
                                                        >
                                                            <div className="btn-group-actions" style={{ display: 'flex' }}>
                                                                <div
                                                                    style={{ marginRight: '10px', marginTop: '2px' }}
                                                                >
                                                                    <StatusBadge itemStatus={purchaseInvoice?.payment_status?.name} />
                                                                </div>
                                                                {
                                                                    purchaseInvoice?.payment_status?.name === 'Open' && (
                                                                        <>

                                                                            <button
                                                                                className="btn btn-default btn-sm btn-purchase-main"
                                                                                onClick={() => registerPayment()}
                                                                            >
                                                                                {' '}
                                                                                Add Payment
                                                                            </button>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )
                                }
                                </PermissionAllowed>
                            </div>

                            <hr />
                            {/* <Scroller> */}
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">



                                    <div className="row align-items-top">
                                            <div className="col-md-4 col-12 form-group">
                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                    <div className="textGray" style={{ width: '151px' }}>

                                                        {
                                                            dataLoaded ? ( 
                                                                <>
                                                                    Purchase Order
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                                                        {
                                                            dataLoaded ? ( 
                                                                <>
                                                                    {purchaseInvoice && purchaseInvoice.purchase_order_id}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 form-group" >
                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                                                    <div className="textGray" style={{ width: '151px' }}>
                                                        {
                                                            dataLoaded ? ( 
                                                                <>
                                                                    Purchase Invoice
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                                                        {
                                                            dataLoaded ? ( 
                                                                <>
                                                                    {
                                                                        purchaseInvoice?.purchase_invoice_number ? (
                                                                            <>
                                                                                <div>
                                                                                    {purchaseInvoice?.purchase_invoice_number}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    purchaseInvoice?.master_status?.name === 'Concept' ? (
                                                                                        <>
                                                                                            -----
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div 
                                                                                                className="text-primary cursor-pointer"
                                                                                                onClick={() => {
                                                                                                    setShowMarkAsPurchasedModal(true)
                                                                                                }}
                                                                                            >
                                                                                                [Add Purchase Invoice Number]
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-top">
                                            <div className="col-md-4 col-12 form-group">
                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                    <div className="textGray" style={{ width: '151px' }}>
                                                        {
                                                            dataLoaded ? ( 
                                                                <>
                                                                    Reference No
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                                                        {
                                                            dataLoaded ? ( 
                                                                <>
                                                                    {purchaseInvoice && purchaseInvoice.reference_no}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 form-group" >
                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                                                    <div className="textGray" style={{ width: '151px' }}>
                                                        {
                                                            dataLoaded ? ( 
                                                                <>
                                                                    Warehuse
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                                                        {
                                                            dataLoaded ? ( 
                                                                <>
                                                                    {
                                                                        purchaseInvoice?.warehouse_id ? (
                                                                            <>
                                                                                <div
                                                                                    className={hasEveryPermission(['View Warehouse (Button)']) ? 'cursor-pointer text-primary' : ''}
                                                                                    onClick={() => {
                                                                                        if (hasEveryPermission(['View Warehouse (Button)'])) {
                                                                                            viewData('warehouse', purchaseInvoice?.warehouse?.id);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {purchaseInvoice?.warehouse?.name}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>---</>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-top">
                                            <div className="col-md-4 col-12 form-group" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                <div className="textGray" style={{ width: '151px' }}>
                                                    {
                                                        dataLoaded ? ( 
                                                            <>
                                                                Supplier
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                                                    {
                                                        dataLoaded ? ( 
                                                            <>
                                                                {
                                                                    purchaseInvoice?.supplier_id ? (
                                                                        <>
                                                                            <div
                                                                                className={hasEveryPermission(['View Contact (Button)']) ? 'cursor-pointer text-primary' : ''}
                                                                                onClick={() => {
                                                                                    if (hasEveryPermission(['View Contact (Button)'])) {
                                                                                        viewData('supplier', purchaseInvoice?.supplier?.id);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {purchaseInvoice?.supplier?.name}
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>---</>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 form-group" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                <div className="textGray" style={{ width: '151px' }}>
                                                    {
                                                        dataLoaded ? ( 
                                                            <>
                                                                Currency
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                                                    {
                                                        dataLoaded ? ( 
                                                            <>
                                                                {
                                                                    purchaseInvoice?.currency ? (
                                                                        <>
                                                                            {purchaseInvoice?.currency.toUpperCase()}
                                                                        </>
                                                                    ) : (
                                                                        <>---</>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-top">
                                            <div className="col-md-4 col-12 form-group" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                <div className="textGray" style={{ width: '151px' }}>
                                                    {
                                                        dataLoaded ? ( 
                                                            <>
                                                                Expected Delivery
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                                                    {
                                                        dataLoaded ? ( 
                                                            <>
                                                                {
                                                                    purchaseInvoice?.date ? (
                                                                        <>
                                                                            {moment(purchaseInvoice?.date).format('DD MMM YYYY')}
                                                                        </>
                                                                    ) : (
                                                                        <>---</>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 form-group" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                                <div className="textGray" style={{ width: '151px' }}>
                                                    {
                                                        dataLoaded ? ( 
                                                            <>
                                                                Payment Method
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                                                    {
                                                        dataLoaded ? ( 
                                                            <>
                                                                {
                                                                    purchaseInvoice?.payment_method == 'upfront_payment' ? (
                                                                        <>Upfront Payment</>
                                                                    ) : purchaseInvoice?.payment_method == 'in_credit' ? (
                                                                        <>In Credit</>
                                                                    ) : (
                                                                        <>---</>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                        {
                                            purchaseInvoice?.notes && (
                                                <div className="row align-items-top form-group">
                                                    <div className="col-12" style={{ display: 'flex', textAlign: 'right' }} >
                                                        <div className="textGray" style={{ width: '151px' }}>
                                                            {
                                                                dataLoaded ? ( 
                                                                    <>
                                                                        Notes
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                        {
                                                            dataLoaded ? ( 
                                                                <>
                                                                    <div style={{ maxWidth: '275px', marginLeft: '10px', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: purchaseInvoice?.notes?.replace(/\n/g, '<br>').replace(/ +(?= )/g,'') || '' }}></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                                                </>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            )
                                        }


                                        <div className="productsTable estlist" style={{ fontSize: '12px' }}>
                                            <div className="section-head" style={{ marginLeft: '-5px' }}>
                                                <h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>Products</h2>
                                            </div>

                                            <div className="estimation-table-area">
                                                <div className="table-side">
                                                    <div className="table-responsive-xl tableData estimationList">
                                                        {/* <Scroller extraHeight={80}> */}
                                                        {/* <table className="table table-md text-gray-900 formActBtns border-0"> */}
                                                        <table className="table table-md text-gray-900 formActBtns border-0">
                                                            <thead>
                                                                <tr className="sticky-header ">
                                                                    {/* {
                                                                        purchaseInvoice?.estimation_id && purchaseInvoice?.estimation_id !== 'invoice' ? (
                                                                            <>
                                                                                <th scope="col" className="align-middle" style={{ width: '55px' }}></th>
                                                                                <th scope="col" className="align-middle" style={{ paddingLeft: '0px', width: '24%' }}>Model</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '55px' }}>Qty</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '110px' }}>Received</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '110px' }}>Sales Price</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '24%' }}>Supplier</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '130px' }}>Purchase Price</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '110px' }}>Stocks</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '70px' }}>Margins</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '110px' }}>Total</th>
                                                                            </>
                                                                        ) : (
                                                                            <> */}
                                                                                <th scope="col" className="align-middle" style={{ width: '55px' }}></th>
                                                                                <th scope="col" className="align-middle" style={{ paddingLeft: '0px', width: '24%' }}>Model</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '55px' }}>Ordered</th>
                                                                                {
                                                                                    purchaseInvoice?.receipts.length > 0 && (
                                                                                        <>
                                                                                            <th scope="col" className="align-middle" style={{ width: '55px' }}>Received</th>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                <th scope="col" className="align-middle" style={{ width: '130px' }}>Purchase</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '110px' }}>Stocks</th>
                                                                                <th scope="col" className="align-middle" style={{ width: '110px' }}>Total</th>
                                                                            {/* </>
                                                                        )
                                                                    } */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items?.length > 0 ? (
                                                                    items.map((item, index) => (
                                                                        <>
                                                                            <tr key={item?.id}>
                                                                                <td>

                                                                                    {item?.thumbnail_url ? (
                                                                                        <img src={item?.thumbnail_url} style={{ width: '30px' }} alt="product" />
                                                                                    ) : (
                                                                                        <img src={NoImage} style={{ width: '30px' }} alt="product" />
                                                                                    )}
                                                                                </td>
                                                                                <td className="align-middle estimateCName" style={{ paddingLeft: '0px' }}>

                                                                                    <div
                                                                                        className={hasEveryPermission(['View Product (Button)']) ? 'cursor-pointer text-primary align-middle' : 'align-middle'}
                                                                                        onClick={() => {
                                                                                            if (hasEveryPermission(['View Product (Button)'])) {
                                                                                                viewData('products', item.product_id);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {item?.name}
                                                                                    </div>
                                                                                    <div className="textGray">{item?.sku}</div>
                                                                                </td>
                                                                                <td className="align-middle estimateCName">
                                                                                    {item?.qty}
                                                                                </td>
                                                                                {
                                                                                    purchaseInvoice?.receipts.length > 0 && (
                                                                                        <>
                                                                                            <td className="align-middle">{item?.received_quantity}</td>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {/* {
                                                                                    purchaseInvoice?.source && purchaseInvoice?.source == 'invoice' && (
                                                                                        <>
                                                                                            <td className="align-middle ">
                                                                                                {getCurrencySymbol(purchaseInvoice?.currency)}{' '}
                                                                                                {item?.sales_price[purchaseInvoice?.currency]}
                                                                                            </td>
                                                                                            <td className="align-middle ">
                                                                                                {
                                                                                                    item?.supplier_id ? (
                                                                                                        <>
                                                                                                            {item?.supplier?.name}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>---</>
                                                                                                    )
                                                                                                }
                                                                                            </td>
                                                                                        </>
                                                                                    )
                                                                                } */}
                                                                                <td className="align-middle">
                                                                                    {getCurrencySymbol(purchaseInvoice?.currency)}{' '} 
                                                                                    {parseFloat(item?.purchase_price[purchaseInvoice?.currency]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    <div className="">

                                                                                        {
                                                                                            (
                                                                                                item?.purchased_quantity > 0) ? (
                                                                                                <>
                                                                                                    {item?.available_quantity} { item?.purchased_quantity > 0 && (<><Tooltip title={<span style={{ fontSize: "13px" }}>Already purchased</span>} arrow placement="top"><span className="textGray">+ <span>{item?.purchased_quantity}</span></span></Tooltip></>) }
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <div><span className="text-danger font600">{item?.available_quantity}</span> pc</div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                {/* {
                                                                                    purchaseInvoice?.source && purchaseInvoice?.source == 'invoice' && (
                                                                                        <>
                                                                                            <td className="align-middle">
                                                                                                <div className="">
                                                                                                    {getCurrencySymbol(purchaseInvoice?.currency)}{' '}
                                                                                                    {
                                                                                                        item?.total_margin[purchaseInvoice?.currency] !== '0.00' ? (
                                                                                                            item?.total_margin[purchaseInvoice?.currency]
                                                                                                        ) : (
                                                                                                            '0.00'
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                        </>
                                                                                    )
                                                                                } */}
                                                                                <td className="align-middle">
                                                                                    <div className="">
                                                                                        {getCurrencySymbol(purchaseInvoice?.currency)}{' '}
                                                                                        {
                                                                                            item?.total_price[purchaseInvoice?.currency] !== '0.00' ? (
                                                                                                parseFloat(item?.total_price[purchaseInvoice?.currency]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                                                                            ) : (
                                                                                                '0.00'
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                ) : (
                                                                    <tr className="no-hover">
                                                                        <td colSpan={ (purchaseInvoice?.source && purchaseInvoice?.source == 'invoice') ? 8 : 6}>
                                                                            <div className="not-found-container cstm-not-found">
                                                                                <div className="not-found-icon">
                                                                                    <TbDatabaseExclamation />
                                                                                </div>
                                                                                <h6 className="not-found-text">Products not found</h6>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                            {purchaseInvoice?.purchase_invoice_items?.length > 0 ? (
                                                                <tfoot>
                                                                    <tr className="tr-nth">
                                                                        <td className="align-middle"></td>
                                                                        <td className="align-middle"></td>
                                                                        <td className="align-middle text-left">
                                                                            {totalQuantity} pcs
                                                                        </td>
                                                                        <td className={`align-middle`} colSpan={purchaseInvoice?.master_status?.name === 'Received' ? 2 : 1}></td>
                                                                        <td className={`align-middle textGray`}>Subtotal</td>
                                                                    
                                                                        <td className="align-middle">
                                                                            <label>
                                                                                {getCurrencySymbol(purchaseInvoice?.currency)}{' '}
                                                                                {
                                                                                    subtotal[purchaseInvoice?.currency] !== '0.00' ? (
                                                                                        <>{parseFloat(subtotal[purchaseInvoice?.currency]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</>
                                                                                    ) : (
                                                                                        <>0.00</>
                                                                                    )
                                                                                }
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="">
                                                                        <td className="align-middle" colSpan={3}></td>
                                                                        <td className={`align-middle`} colSpan={purchaseInvoice?.master_status?.name === 'Received' ? 2 : 1}></td>
                                                                    
                                                                        <td className="align-middle textGray">
                                                                            Shipping
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <label>
                                                                                {getCurrencySymbol(purchaseInvoice?.currency)}{' '}
                                                                                {parseFloat(purchaseInvoice?.shipping_fee ?? 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="">
                                                                        <td className="align-middle" colSpan={3}></td>
                                                                        <td className={`align-middle`} colSpan={purchaseInvoice?.master_status?.name === 'Received' ? 2 : 1}></td>
                                                                    
                                                                        <td className="align-middle textGray">
                                                                            Total
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <label>
                                                                                {getCurrencySymbol(purchaseInvoice?.currency)}{' '}
                                                                                {
                                                                                    !isNaN(parseFloat(total[purchaseInvoice?.currency])) ? (
                                                                                        <>
                                                                                            {
                                                                                                parseFloat(total[purchaseInvoice?.currency]) !== '0.00' ? (
                                                                                                    <>{parseFloat(total[purchaseInvoice?.currency] ?? 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</>
                                                                                                ) : (
                                                                                                    <>0.00</>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    ) : (
                                                                                        <>0.00</>
                                                                                    )
                                                                                }
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </table>
                                                        {/* </Scroller> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <br />
                                        <br />
                                        {
                                            purchaseInvoice?.receipts?.length > 0 && (
                                            <>
                                                <div className="productsTable estlist" style={{ fontSize: '12px' }}>
                                                    <div className="table-responsive-xl tableData">
                                                        <div>
                                                            <h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px', fontWeight: '600' }}>
                                                                Receipts
                                                            </h2>
                                                        </div>
                                                        <table className="table table-md text-gray-900 formActBtns border-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="align-middle">Reference No</th>
                                                                    <th className="align-middle">
                                                                        Received By
                                                                    </th>
                                                                    <th className="align-middle " style={{ width: '75px'}}>
                                                                        Status
                                                                    </th>
                                                                    <th className="align-middle">
                                                                        Products
                                                                    </th>
                                                                    <th className="align-middle">
                                                                        Value
                                                                    </th>
                                                                    <th className="align-middle">
                                                                        Date Received 
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {
                                                                    purchaseInvoice?.receipts.map((receipt, index) => (
                                                                        <>
                                                                            <tr key={receipt?.id}>
                                                                                <td className="align-middle estimateCName">
                                                                                    <div
                                                                                        className="text-primary"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => history(`/purchasing/view/${receipt?.id}/receipt`)}
                                                                                    >
                                                                                        {receipt?.receipt_number}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle">{receipt?.user?.name}</td>
                                                                                <td className="align-middle"><StatusBadge itemStatus={`Completed`} /></td>
                                                                                <td className="align-middle">{receipt?.receipt_items?.reduce((total, item) => total + (item.quantity || 0), 0)} </td>
                                                                                <td className="align-middle">
                                                                                    {getCurrencySymbol(purchaseInvoice?.currency)}{' '}
                                                                                    {parseFloat(receipt?.receipt_items?.reduce((total, item) => total + (parseFloat(item.price) * item.quantity || 0), 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    {moment(receipt?.created_at).format('DD MMM YYYY hh:mm A')}
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                            )
                                    }
                                    
                                        <br />
                                        <br />
                                        {
                                            purchaseInvoice?.purchase_invoice_payments?.length > 0 && (
                                            <>
                                                <div className="productsTable estlist" style={{ fontSize: '12px' }}>
                                                    <div className="table-responsive-xl tableData">
                                                        <div>
                                                            <h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px', fontWeight: '600' }}>
                                                                Payments
                                                            </h2>
                                                        </div>
                                                        <table className="table table-md text-gray-900 formActBtns border-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="align-middle">Payment Date</th>
                                                                    <th className="align-middle">Payment Type</th>
                                                                    <th className="align-middle">Amount</th>
                                                                    <th className="align-middle">Attachment</th>
                                                                    <th className="align-middle" style={{ width: '50px'}}></th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {
                                                                    purchaseInvoice?.purchase_invoice_payments.map((payment, index) => (
                                                                        <>
                                                                            <tr key={payment?.id}>
                                                                                <td className="align-middle estimateCName">
                                                                                    {moment(payment?.payment_date).format('DD MMM YYYY')}
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    {
                                                                                        payment?.payment_type == 'cash' ? (
                                                                                            'Cash'
                                                                                        ) : payment?.payment_type == 'bank_cheque' ? (
                                                                                            'Bank Cheque'
                                                                                        ) : payment?.payment_type == 'credit_card' ? (
                                                                                            'Credit Card'
                                                                                        ) : payment?.payment_type == 'bank_transfer' ? (
                                                                                            'Bank Transfer'
                                                                                        ) : (
                                                                                            ''
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    {getCurrencySymbol(payment?.currency)}{' '}
                                                                                    {parseFloat(payment?.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    {
                                                                                        payment?.attachment_url && (
                                                                                            <>
                                                                                                <div
                                                                                                    className='text-primary'
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    onClick={() => {
                                                                                                        window.open(payment?.attachment_url, '_blank');
                                                                                                    }}
                                                                                                >
                                                                                                    {payment?.filename}
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </td>

                                                                                <PermissionAllowed permissions={['Delete Purchase Order']} hasSome>
                                                                                    <td className="align-middle action-col font14" style={{ marginTop: '7px' }}>
                                                                                        <Tooltip title={'Delete'} arrow placement="top">
                                                                                            <a
                                                                                                href="javascript:void(0);"
                                                                                                onClick={() => {
                                                                                                    if (hasEveryPermission(['Delete Purchase Order'])) {
                                                                                                        deletePayment(payment?.id);
                                                                                                    }
                                                                                                }}
                                                                                                style={{ marginLeft: '3px' }}
                                                                                            >
                                                                                                <svg
                                                                                                    id="vuesax_linear_trush-square"
                                                                                                    data-name="vuesax/linear/trush-square"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="20"
                                                                                                    height="20"
                                                                                                    viewBox="0 0 31.855 31.855"
                                                                                                >
                                                                                                    <g id="trush-square">
                                                                                                        <path
                                                                                                            id="Vector"
                                                                                                            d="M0,17.255V9.291C0,2.655,2.655,0,9.291,0h7.964c6.637,0,9.291,2.655,9.291,9.291v7.964c0,6.637-2.655,9.291-9.291,9.291H9.291C2.655,26.546,0,23.891,0,17.255Z"
                                                                                                            transform="translate(2.655 2.655)"
                                                                                                            fill="none"
                                                                                                            stroke="#ff342d"
                                                                                                            stroke-linecap="round"
                                                                                                            stroke-linejoin="round"
                                                                                                            stroke-width="2"
                                                                                                        />
                                                                                                        <g id="Group" transform="translate(8.097 8.548)">
                                                                                                            <path
                                                                                                                id="Vector-2"
                                                                                                                data-name="Vector"
                                                                                                                d="M0,.438,1.779.265A51.533,51.533,0,0,1,6.942,0a86.992,86.992,0,0,1,8.72.438"
                                                                                                                transform="translate(0 3.026)"
                                                                                                                fill="none"
                                                                                                                stroke="#ff342d"
                                                                                                                stroke-linecap="round"
                                                                                                                stroke-linejoin="round"
                                                                                                                stroke-width="2"
                                                                                                            />
                                                                                                            <path
                                                                                                                id="Vector-3"
                                                                                                                data-name="Vector"
                                                                                                                d="M0,2.588.186,1.447C.319.624.425,0,1.9,0H4.181C5.654,0,5.76.65,5.893,1.447l.186,1.128"
                                                                                                                transform="translate(4.792)"
                                                                                                                fill="none"
                                                                                                                stroke="#ff342d"
                                                                                                                stroke-linecap="round"
                                                                                                                stroke-linejoin="round"
                                                                                                                stroke-width="2"
                                                                                                            />
                                                                                                            <path
                                                                                                                id="Vector-4"
                                                                                                                data-name="Vector"
                                                                                                                d="M11.932,0l-.571,8.76c-.093,1.367-.173,2.429-2.6,2.429H3.172c-2.429,0-2.509-1.062-2.6-2.429L0,0"
                                                                                                                transform="translate(1.858 3.57)"
                                                                                                                fill="none"
                                                                                                                stroke="#ff342d"
                                                                                                                stroke-linecap="round"
                                                                                                                stroke-linejoin="round"
                                                                                                                stroke-width="2"
                                                                                                            />
                                                                                                        </g>
                                                                                                        <path id="Vector-5" data-name="Vector" d="M0,0H31.855V31.855H0Z" fill="none" opacity="0" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </a>
                                                                                        </Tooltip>
                                                                                    </td>
                                                                                </PermissionAllowed>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                            )
                                        }
                                    </div>
                                </div>
                            {/* </Scroller> */}
                            {purchaseInvoice?.attachment_url && (
                                <>
                                    {purchaseInvoice.attachment_url.toLowerCase().endsWith('.pdf') ? (
                                        <embed src={purchaseInvoice.attachment_url} type="application/pdf" width="100%" height="600px" />
                                    ) : (
                                        <img src={purchaseInvoice.attachment_url} alt="" />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {
                        purchaseInvoice?.purchase_invoice_attachments?.length > 0 && (
                            <>
                                {
                                    purchaseInvoice?.purchase_invoice_attachments?.map((attachment, index) => (
                                        <div className="row m-0">
                                            <div className="col-12" style={{ padding: '0'}}>

                                                <div className="card shadow-sm bg-white mb-4 w-100 border card-border-gray">
                                                    <div className="card-header-radius-0" style={{ padding: '0px 10px'}}>
                                                        <div className="row">
                                                            <div className="col-12">

                                                                <div className="section-head row" style={{ padding: '0px 10px !important' }}>
                                                                    <div style={{ marginTop: '-4px', alignItems: 'center', fontSize: '16px' }} className="col-md-4">
                                                                        {attachment?.filename}
                                                                    </div>
                                                                    <div className="actions col-md-8">


                                                                        <button type="button" onClick={() => downloadAttachment(attachment)} className="btn btn-primary btn-outlined-main">

                                                                            
                                                                            <svg
                                                                                id="fi_2767144"
                                                                                enable-background="new 0 0 128 128"
                                                                                height="18"
                                                                                viewBox="0 0 128 128"
                                                                                width="18"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    id="Download"
                                                                                    d="m128 65c0 15.439-12.563 28-28 28h-20c-2.211 0-4-1.791-4-4s1.789-4 4-4h20c11.027 0 20-8.973 20-20s-8.973-20-20-20h-4c-2.211 0-4-1.791-4-4 0-15.439-12.563-28-28-28s-28 12.561-28 28c0 2.209-1.789 4-4 4h-4c-11.027 0-20 8.973-20 20s8.973 20 20 20h20c2.211 0 4 1.791 4 4s-1.789 4-4 4h-20c-15.437 0-28-12.561-28-28s12.563-28 28-28h.223c1.996-17.975 17.277-32 35.777-32s33.781 14.025 35.777 32h.223c15.438 0 28 12.561 28 28zm-50.828 37.172-9.172 9.172v-50.344c0-2.209-1.789-4-4-4s-4 1.791-4 4v50.344l-9.172-9.172c-1.563-1.563-4.094-1.563-5.656 0s-1.563 4.094 0 5.656l16 16c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l16-16c1.563-1.563 1.563-4.094 0-5.656s-4.094-1.563-5.656 0z"
                                                                                    fill="currentColor"
                                                                                ></path>
                                                                            </svg>
                                                                            Download
                                                                        </button>


                                                                        
                                                                        <button 
                                                                            type="button" 
                                                                            className="btn btn-danger btn-outlined-danger"
                                                                            onClick={() => removeAttachment(attachment?.id)}
                                                                        >

                                                                            
                                                                            <DeleteIcon2 height='20px' width='20px' />
                                                                            Delete 
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <div className="col-11">
                                                                {attachment?.filename}
                                                            </div>
                                                            <div className="col-1 text-right">
                                                                
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="card-body text-center">

                                                        {attachment?.extension == 'pdf' ? (
                                                            <PdfViewer src={attachment?.attachment_url + '#toolbar=0&navpanes=0&zoom=FitW'} />
                                                        ) : (
                                                            <img src={attachment?.attachment_url} className="img-fluid" />
                                                        )}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        purchaseInvoice?.payment_method == 'in_credit' ? (
                            <>
                                {
                                    purchaseInvoice?.master_status?.name !== 'Concept' && (
                                        <div className="row" style={{ marginBottom: '50px'}}>
                                            <div className="col-12">
                                                <div {...getPaymentRootProps()}>
                    
                                                    <div
                                                        className="add-item-empty-div"
                                                        style={{ alignItems: 'center' }}
                                                    >
                                                        <button type="button" className="btn btn-primary btn-outlined-main-primary dragText" >
                                                            <FaPlus />
                                                            Add File
                                                        </button>
                                                        <span style={{ marginLeft: '10px' }}>or drag file...</span>
                                                    </div>
                                                    <input {...getPaymentInputProps({ disabled: vatValidationAlert !== true })} style={{ display: 'none' }} />{' '}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        ) : purchaseInvoice?.payment_method == 'upfront_payment' ? (
                            <>
                                        
                                {
                                    (purchaseInvoice?.master_status?.name !== 'Concept') && (
                                        <div className="row" style={{ marginBottom: '50px'}}>
                                            <div className="col-12">
                                                <div {...getPaymentRootProps()}>
                    
                                                    <div
                                                        className="add-item-empty-div"
                                                        style={{ alignItems: 'center' }}
                                                    >
                                                        <button type="button" className="btn btn-primary btn-outlined-main-primary dragText" >
                                                            <FaPlus />
                                                            Add File
                                                        </button>
                                                        <span style={{ marginLeft: '10px' }}>or drag file...</span>
                                                    </div>
                                                    <input {...getPaymentInputProps({ disabled: vatValidationAlert !== true })} style={{ display: 'none' }} />{' '}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        ) : (
                            ''
                        )
                    }
                </div>
            </div>
            <Modal show={uploadInvoice} onHide={handleClose} centered>
                <Modal.Body>
                    <Modal.Title>
                        {' '}
                        <div className="text-center">Upload Invoice</div>
                    </Modal.Title>
                    <div className="form-group col-md-12">
                        <label>Attachment</label>
                        <FileUpload
                            accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
                            loading={false}
                            onChange={(e) => {
                                handleFileUpload(e);
                            }}
                            selectedValue={file?.[0]}
                        />
                    </div>
                    <div className="flex-attachment">
                        <Button variant="primary" className="m-2 " onClick={handleClose}>
                            Cancel
                        </Button>
                        <button className="btn btn-primary btn-outlined-main" onClick={() => uploadFile()}>
                            Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            {
                showPaymentModal && (
                    <>
                        <PaymentModal
                            showModal={showPaymentModal}
                            details={purchaseInvoice}
                            handleSaveAndClose={handleSaveAndClose}
                            handleClose={handleClose}
                        />
                    </>
                )
            }
            {
                isModalOpen && (
                    <>
                        <EmailPurchaseOrderModal
                            openPopup={isModalOpen}
                            details={purchaseInvoice}
                            items={items}
                            handleClose={handleClose}
                        />
                    </>
                )
            }
            {
                showMarkAsPurchasedModal && (
                    <>
                        <MarkAsPurchasedModal
                            openPopup={showMarkAsPurchasedModal}
                            details={purchaseInvoice}
                            onHandleReloadData={handleMarkAsPurchased}
                            handleClose={handleClose}
                        />
                    </>
                )
            }

			{showDelete && (
				<DeleteConfirmation
					show={showDelete}
					handleClose={handleClose}
					handleDelete={handleDeletePaymentAttachment}
					title="Confirm"
					message={`Are you sure you want to delete attachment?`}
				/>
			)}
			{showDeletePurchaseInvoice && (
				<DeleteConfirmation
					show={showDeletePurchaseInvoice}
					handleClose={handleClose}
					handleDelete={deletePurchaseInvoice}
					title="Confirm"
					message={`Are you sure you want to delete purchase order?`}
				/>
			)}
			{showPaymentDelete && (
				<DeleteConfirmation
					show={showPaymentDelete}
					handleClose={handleClose}
					handleDelete={handleDeletePayment}
					title="Confirm"
					message={`Are you sure you want to delete payment?`}
				/>
			)}
        </>
    );
};

export default PurchasingDetails;
