import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Scroller from 'ui-component/Scroller';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import ProductVariations from './ProductVariations'
import { Skeleton, Tooltip } from '@mui/material';
import { AiOutlineDelete } from 'react-icons/ai';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import toast from 'react-hot-toast';
import { FaPlus,FaSave, FaFileUpload, FaCloudDownloadAlt, FaSpinner  } from 'react-icons/fa';
import { MdOutlineModeEdit, MdOutlineUploadFile, MdOutlineCloudDownload } from "react-icons/md";
import Select from 'react-select';
import usePageTitle from 'hooks/usePageTitle';



const ImportProduct = () => {
	
	const [setPageTitle] = usePageTitle('Products - Import');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const [file, setFile] = useState(null);
	const [selectedStrategy, setSelectedStrategy] = useState(0);
	const [isImportRunning, setIsImportRunning] = useState(false);
	const strategyTypes = [
		{ label: 'Add new products and update existing products', value: 0 },
		{ label: 'Only add new products, ignore existing products', value: 1 },
		{ label: 'Only update existing products, ignore new products', value: 2 },
	];

	const exportSheet = () => {
		const data = {
			startDate: '2021-01-01',
			endDate: '2021-12-31'
		};
		api.importProductsFormat(data).then((res) => {
				var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				saveAs(newBlob, `Projectmix - Import Products Format.xlsx`);
			
		});
	};


	const importSheet = () => {
		setIsImportRunning(true)
		api.importProducts(
			file,
			selectedStrategy
		).then((res) => {
			if(res.success === true){
				toast.success('Products imported successfully');

			}else{
				if(res.status != 200){
					toast.error(res?.data?.message);
				}
			}
			setIsImportRunning(false)
		});
	//    setFile(null)
	}

	const handleFileInput = (e) => {
		const file = event.target.files[0];
		if (file && file.name) {
			const fileExtension = file.name.split('.').pop();
	
			if (fileExtension !== 'xlsx') {
				toast.error('Invalid file format. Please upload a .xlsx file.');
				setFile(null)
				return;
			} else {
				setFile(e.target.files[0])
			}
		}
	}
	return (
		<>


			<div className="row">
				<div className="col-12">
					<div className="row pb10">
						<div className="col-12">
							<div className="section-head-new">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="content pad15">
							<div className="row m-0 p-0 mb-3">
								<div className="col-md-2 col-sm-3 col-12">
									<h4 className='font18'><b>Import</b></h4>
								</div>
							</div>
							<div className="row">
								<div className="col-md-4 col-3 text-right form-group">
									File
								</div>
								<div className="col-md-4 col-9 form-group">

									<div className="prdct-inactiv-div-yes">
										<div className="yes">
											<input type="file" 
												name="act-product" 
												className="form-control" 
												onChange={(e)=> handleFileInput(e)}
												accept=".xlsx"
											/>
										</div> 
										<div className=""> <span>Choose an Excel (.xlsx) file to import.</span></div> 
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-4 col-3 text-right form-group">
									Strategy
								</div>
								<div className="col-md-4 col-9 form-group">
									<div className="row" style={{ marginTop: '5px' }}>
										<div className="col-12">
										{
											strategyTypes?.map((strategy, index) => (
												<>
													<div key={index}>
														<input type="radio" 
																name="act-product-yes" 
																onClick={ () => {
																	setSelectedStrategy(strategy?.value)
																}}
																checked={ strategy?.value === selectedStrategy ? true : false }
														/>{' '}
														<span htmlFor="">{strategy.label}</span>
													</div>
												</>
											))
										}
										</div>
									</div>
								</div>
							</div>
							{/* <div className="row m-0 p-0 mb-3">
								<div className="col-md-2 col-sm-3 col-12">
								</div>
								<div className="col-md-10 col-sm-5 col-12">
									<div className="" style={{ justifyContent: 'space-between' }}>
                                            <table className='projectmix-table-vertical  table-vertical-export'>
                                                <tbody>
                                                    <tr>
                                                        <th>File</th>
                                                        <td className=''>
															<div className="prdct-inactiv-div-yes">
																<div className="yes">
																	<input type="file" 
																		name="act-product" 
																		className="form-control" 
																		onChange={(e)=> handleFileInput(e)}
																		accept=".xlsx"
																	/>
																</div> 
																<div className=""> <span>Choose an Excel (.xlsx) file to import.</span></div> 
															</div>
														</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Strategy</th>
                                                        <td className=''>
															<div className="prdct-inactiv-div-yes">
																<div className="row" style={{ marginTop: '5px' }}>
																	<div className="col-12">
																	{
																		strategyTypes?.map((strategy, index) => (
																			<>
																				<div style={{ margin: '-2px 0'}} key={index}>
																					<input type="radio" 
																							name="act-product-yes" 
																							onClick={ () => {
																								setSelectedStrategy(strategy?.value)
																							}}
																							checked={ strategy?.value === selectedStrategy ? true : false }
																					/>{' '}
																					<label htmlFor="">{strategy.label}</label>
																				</div>
																			</>
																		))
																	}
																	</div>
																</div>
															</div>
														</td>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <td className='prdct-Inactive'>
															
														</td>
                                                    </tr>
                                                </tbody>
                                            </table>
										</div>
								</div>
							</div> */}
							<div className="row m-0 p-0 mb-3">
								<div className="col-12">
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button className='btn btn-outlined-main' style={{ marginRight: '4px' }} onClick={()=> exportSheet()}><MdOutlineCloudDownload /> Export format</button>
										{ 
											isImportRunning ? (
												<>
													<button className='btn btn-outlined-main disabled' disabled >
														<FaSpinner style={{ animation: 'spin 2s linear infinite' }} /> Importing...
													</button>
												</>
											) : (
												<>
													<button className='btn btn-outlined-main' style={{ marginLeft: '4px' }} onClick={()=> importSheet()}><MdOutlineUploadFile /> Import</button>
												</>
											)
										}
									    
                                    </div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ImportProduct;
