import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPlus } from 'react-icons/fa';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import CurrencyValue from 'ui-component/CurrencyValue';


const RepairSummary = ({ repairsLists }) => {
    const [summaryDetails, setSummaryDetails] = useState([]);

    // useEffect(() => {
    
    //     const summaryMap = {};
    
    //     repairsLists?.forEach(product => {
    //         const key = `${product.productName}-${product.colorName}`;
    //         if (summaryMap[key]) {
    //             summaryMap[key].quantity += product.quantity;
    //         } else {
    //             summaryMap[key] = {
    //                 name: product.productName,
    //                 color: product.colorName,
    //                 quantity: product.quantity
    //             };
    //         }
    //     });
        
    //     // console.log('summaryMap', summaryMap);
    //     const newSummaryDetails = Object.values(summaryMap);
    //     console.log('newSummaryDetails', newSummaryDetails);
    
    //     setSummaryDetails(newSummaryDetails);
    // }, [repairsLists]);
    
    return (
        <>
            
            
            <div className="">
                                                
                <div className="form-group">
                    <h5 style={{ marginLeft: '10px', marginTop: '17px' }}>Summary</h5>
                </div>
                <div className="row">
                    <div className="col-12 form-group">
                        <hr style={{ border: '1px solid #d4d4d4', margin: '0 10px 10px 10px' }} />
                        <div className='d-flex  justify-content-between' style={{ padding: '0 10px 10px 10px' }}>
                            <div>Pending Repairs</div>
                            <div> {repairsLists?.pending_repairs} </div>
                        </div>
                        <div className='d-flex  justify-content-between' style={{ padding: '0 10px 10px 10px' }}>
                            <div>Completed Repairs</div>
                            <div> {repairsLists?.completed_repairs} </div>
                        </div>
                        <div className='d-flex  justify-content-between' style={{ padding: '0 10px 10px 10px' }}>
                            <div>Total Repairs</div>
                            <div> {repairsLists?.total_repairs} </div>
                        </div>
                        <div className='d-flex  justify-content-between' style={{ padding: '0 10px 10px 10px' }}>
                            <div>
                                Time Spent
                            </div>
                            <div>
                                {repairsLists?.total_time_spent}
                            </div>
                        </div>
                        <div className='d-flex  justify-content-between' style={{ padding: '0 10px 10px 10px' }}>
                            <div>Total Cost</div>
                            <div><CurrencyValue currencyObject={repairsLists?.total_cost} /></div>
                        </div>
                    </div>
                </div>
                <hr style={{ border: '1px solid #d4d4d4', margin: '0 10px 25px 10px' }} />

                


            </div>


        </>
    );
};

export default RepairSummary;
