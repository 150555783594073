import { textToSentenceCase } from 'helpers/common.helper';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Pagination from 'ui-component/Pagination';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Scroller from 'ui-component/Scroller';
import Notes from 'ui-component/notes';
import Filter from 'ui-component/filter-menu/Filter';
import { FaUserTie } from 'react-icons/fa6';
import { TbFileInvoice } from 'react-icons/tb';
import { LuChevronDown } from 'react-icons/lu';
import { Dropdown } from 'react-bootstrap';
import AwbModal from 'ui-component/confirmation-dialogs/awbModal/AwbModal';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill } from 'react-icons/pi';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function TransitShipmentDetails() {
	const { id } = useParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const history = useNavigate();
	const [isLoading, setLoading] = useState(false);
	const [isItemLoading, setItemLoading] = useState(false);
	const [invoices, setInvoices] = useState([]);
	const [transitShipmentItemList, setTransitShipmentItemList] = useState([]);
	const [transitShipmentDetails, setTransitShipmentDetails] = useState();
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [pagination, setpagination] = useState([]);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [searchField, setSearchField] = useState();
	const [showTrackingNoModal, setShowTrackingNoModal] = useState(false);

	const [trackingNoDetail, setTrackingNoDetail] = useState(null);

	const topSection = useRef(null);
	const getTransitShipmentDetails = (callback = () => { }) => {
		setLoading(true);
		api.getTransitShipmentDetails(id).then((res) => {
			if (res?.status) {
				setLoading(false);
				setTransitShipmentDetails({
					...res.data,
					notes: res?.data?.notes?.map((note) => ({
						id: note?.id,
						details: note?.note,
						date: moment(note?.created_at || undefined).format('YYYY-MM-DD'),
						time: moment(note?.created_at || undefined).format('HH:mm'),
						user_name: note?.logged_by_user?.first_name,
						expand: false
					}))
				});
				setInvoices(res?.data?.invoices);
				callback(res.data);
			} else {
				history('/operations/transitshipments');
			}
		});

		api.getTransitShipmentItemList(id).then((res) => {
			setItemLoading(false);
			setTransitShipmentItemList(res.data.data);
			setpagination(res.data);
			isFirstLoad && setIsFirstLoad(false);
		});
		api.getTransitShipmentItemFilters(id).then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	const handleTrackingNoModalClose = () => {
		handlePaymentModal(false, null);
		setShowAddPayment && setShowAddPayment(false);
	};

	const handleFormSubmit = (e) => {
		console.log(e?.trackingNo);
		toast.promise(
			api
				.updateTransitShipmentAwb(id, {
					tracking_no: e?.trackingNo
				})
				.then((res) => {
					if (res.status) {
						getTransitShipmentDetails();
						handleCloseAwbModal();
					}
				}),
			{
				loading: 'Updating Tracking...',
				success: <b>Tracking Detail updated!</b>,
				error: <b>Could not Update Tracking Detail.</b>
			}
		);
	};

	const handleUpdateTransitShipmentStatus = (transit_shipment_invoice_id) => {
		let cnfrmTtl = 'Update Shipment Status';
		let cnfrmMsg = 'Are you sure this package has been received by transit company?';

		showConfirmationDialog(cnfrmTtl, cnfrmMsg, () => {
			toast.promise(
				api.updateTransitShipmentInvoiceStatus(id).then(
					(res) => {
						if (res.status) {
							getTransitShipmentDetails();
						}

						handleCloseConfirmationDialog();

						return res?.status ? res : null;
					},
					() => { }
				),
				{
					loading: 'Updating status...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update shipment status</b>
				}
			);
		});
	};

	const handleData = (data) => {
		setTransitShipmentItemList(data.data);
		setpagination(data);
	};

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => { },
		handleClose: () => { }
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => { },
			handleClose: () => { }
		});
	};

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => { },
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	useEffect(() => {
		if (id) {
			getTransitShipmentDetails();
		}
	}, []);

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const handleFilter = (activeF = null, searchF = null) => {
		setItemLoading(true);
		api.filterTransitShipmentItem(id, activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setItemLoading(false);
			setTransitShipmentItemList(res.data.data);
			setpagination(res.data);
		});
	};

	const s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	};

	const handleDownloadImei = (id) => {
		api.downloadTransitShipmentImei(id).then((res) => {
			// Create a workbook
			console.log(res);
			return false;
			const wb = XLSX.utils.book_new();
			// Create a worksheet
			const ws = XLSX.utils.json_to_sheet(res?.data);
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

			// Convert the workbook to a binary string
			const wbBinaryString = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

			// Convert the binary string to a Blob
			const blob = new Blob([s2ab(wbBinaryString)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			saveAs(blob, `${transitShipmentDetails?.shipment?.reference_no} imeis.xlsx`);
		});
	};
	const handleEditAwb = (id) => {
		setShowTrackingNoModal(id);
	};
	const handleCloseAwbModal = (data) => {
		setShowTrackingNoModal('');
	};
	const redirectFunc = (data) => {
		setTimeout(() => {
			history(`/operations/transitshipments/${id}`);
		}, 1000);
	};

	const addNote = (noteInputValue) => {
		toast.promise(
			api
				.newTransitShipmentNote(id, {
					note: noteInputValue
				})
				.then(
					(res) => {
						if (res.status) {
							setTransitShipmentDetails((oldData) => ({
								...oldData,
								notes: [
									{
										id: res?.data?.id,
										details: noteInputValue,
										date: moment(res?.data?.created_at || undefined).format('YYYY-MM-DD'),
										time: moment(res?.data?.created_at || undefined).format('HH:mm'),
										user_name: userName,
										expand: false
									},
									...(oldData?.notes || [])
								]
							}));
						}

						return res?.status ? res : null;
					},
					() => { }
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const removeNote = (index) => {
		toast.promise(
			api.removeTransitShipmentNote(id, transitShipmentDetails?.notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setTransitShipmentDetails((oldData) => ({
							...oldData,
							notes: oldData?.notes?.filter((note, i) => index != i)
						}));
					}
					return res?.status ? res : null;
				},
				() => { }
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	const toggleNoteExpand = (index) => {
		setTransitShipmentDetails((oldData) => ({
			...oldData,
			notes: oldData?.notes?.map((note, i) =>
				index == i
					? {
						...note,
						expand: !note?.expand
					}
					: note
			)
		}));
	};

	return (
		<>
			<div className={`TaskDetails`}>
				<div className="row">
					<div className="col-3">
						<h6 className="mb-3">Summary</h6>
						{invoices?.map((s) => (
							<div
								style={{ border: '0.5px transparent solid' }}
								className={`card p-2 mb-2 ship-summary-card ${selectedInvoice == s?.id && 'blue-border'}`}
								onClick={() => {
									setSelectedInvoice((oldData) => (oldData == s?.id ? null : s?.id));
								}}
							>
								<div className="d-flex justify-content-between ship-summary-card-head">
									<p className="m-0" style={{ fontWeight: '500' }}>
										<a href={'/sales/invoices/' + s?.id} target="_blank">
											<TbFileInvoice style={{ fontSize: '13px', marginTop: '-4px', marginRight: '5px' }} />
											{s?.invoice_number}
										</a>
										{/* <br />
										<span style={{ color: '#6e7681', fontSize: '10px' }}>{ s?.customer_name }</span> */}
									</p>
									<span>{s?.status}</span>
									{/* <button className="btn btn-primary btn-outlined-main filter-btn"
										style={{ minWidth: 'unset', paddingInline: '5px', paddingBlock: '0' }} 
										onClick={() => handleUpdateTransitShipmentStatus(s?.transit_shipment_invoice_id)}
									>
										{s?.status}
									</button> */}
								</div>
								<div className="d-flex mb-2" style={{ color: '#6e7681' }}>
									<FaUserTie style={{ marginTop: '2px' }} />{' '}
									<span style={{ marginLeft: '8px' }}>
										<PrivacyModeContent>{s?.customer_name}</PrivacyModeContent>
									</span>
								</div>
								{s?.details?.map((item) => (
									<div className="d-flex justify-content-between mb-1">
										<p className="m-0" style={{ fontSize: '12px', fontWeight: '400' }}>
											{item?.product}
										</p>
										<p className="m-0">{item?.qty} Pcs</p>
									</div>
								))}
							</div>
						))}
					</div>
					<div className="col-6">
						<div className="card p-2">
							<Scroller>
								<div className="row inbounding-imeis">
									<div className="col-xl-12 col-lg-12">
										<div className="row">
											<div className="col-12">
												<div className="d-flex align-items-center justify-content-between TableSerachBar">
													<div>
														<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
													</div>
													<div className="form-inline navbar-search" style={{ zIndex: 10 }}>
														<Dropdown className="btnDel iconbtn">
															<Dropdown.Toggle as="span">
																<Link to={''} className="btn btn-primary btn-outlined-main filter-btn" style={{ minWidth: 'unset' }}>
																	Actions <LuChevronDown />
																</Link>
															</Dropdown.Toggle>
															<Dropdown.Menu className="custom-dropdown-menu">
																<Dropdown.Item
																	onClick={() => {
																		handleDownloadImei(id);
																	}}
																>
																	Download IMEI
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={() => {
																		handleEditAwb(id);
																	}}
																>
																	Edit AWB
																</Dropdown.Item>
																{/* <Dropdown.Item
      onClick={() => {
        handleUpdateTransitShipmentStatus(id);
      }}
    >
      Update Shipment
    </Dropdown.Item> */}
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												{/* <div className="table-responsive-xl tableData customersList"> */}
												<Scroller extraHeight={topSection?.current?.clientHeight || 0}>
													<table className="table ">
														<thead>
															<tr>
																<th scope="col" width="20%">
																	IMEI
																</th>
																<th scope="col">Product</th>
																<th scope="col" width="20%">
																	Colour
																</th>
																<th scope="col">Package</th>
																{/* <th scope="col"></th> */}
															</tr>
														</thead>
														<tbody>
															{transitShipmentItemList?.map((item, index) => {
																return (
																	<>
																		<tr key={index}>
																			<td className="align-middle">
																				<span className="d-flex align-items-center" style={{ gap: '2px' }}>
																					<span>{item?.imei}</span>
																				</span>
																			</td>
																			<td className="align-middle">{item?.product}</td>
																			<td className="align-middle">{item?.color ? textToSentenceCase(item?.color) : ''}</td>
																			<td>{item?.package_reference_no}</td>
																		</tr>
																	</>
																);
															})}
															{transitShipmentItemList?.data
																?.filter((item) => (!!selectedInvoice ? item?.estimation_id == selectedInvoice : true))
																?.map((item, index) => (
																	<tr
																		key={index}
																	// id={item?.imei}
																	// className={item?.imei == searchImei ? 'found-row' : ''}
																	>
																		<td className="align-middle">
																			<span className="d-flex align-items-center" style={{ gap: '2px' }}>
																				<span>{item?.imei}</span>
																			</span>
																		</td>
																		<td className="align-middle">{item?.product}</td>
																		<td className="align-middle">{item?.color ? textToSentenceCase(item?.color) : ''}</td>
																		<td>{item?.package_reference_no}</td>
																	</tr>
																))}
														</tbody>
													</table>
												</Scroller>
												{/* </div> */}
											</div>
										</div>
										<Pagination pagination={pagination} data={handleData} onLoading={(e) => setItemLoading(e)} />
										{/* <div className="card shadow-sm bg-white mb-4">
							<div className="row">
								<div className="col-4"></div>
								<div className="col-8">
									
								</div>
							</div>
						</div> */}
									</div>
								</div>
							</Scroller>
						</div>
					</div>
					<div className="col-3">
						<div>
							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Reference
								</label>

								<p>{transitShipmentDetails?.shipment?.reference_no}</p>
							</div>
							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Status
								</label>

								<p className="text-success">Shipped to Transit</p>
							</div>

							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Tracking
								</label>

								<p>
									<a target="_blank" href={transitShipmentDetails?.shipment?.tracking_link}>
										{transitShipmentDetails?.shipment?.tracking_no}
									</a>
								</p>
							</div>
							{/* 
							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Quantity
								</label>

								<p>{transitShipmentDetails?.shipment?.quantity}</p>
							</div> */}

							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Ship To
								</label>

								<p className="text-right">
									<Link to={`/transit-company/${transitShipmentDetails?.shipment?.ship_to?.id}`}>
										<PrivacyModeContent notUserRestricted>{transitShipmentDetails?.shipment?.ship_to?.name}</PrivacyModeContent>
									</Link>
									<br />
									{transitShipmentDetails?.shipment?.ship_to?.address}, <br />
									{transitShipmentDetails?.shipment?.ship_to?.city}, {transitShipmentDetails?.shipment?.ship_to?.zipcode}, <br />
									{transitShipmentDetails?.shipment?.ship_to?.country}
								</p>
							</div>

							<Notes
								notes={transitShipmentDetails?.notes}
								addNote={addNote}
								deleteNotePermission={[]}
								removeNote={removeNote}
								toggleNoteExpand={toggleNoteExpand}
							/>
						</div>
					</div>
				</div>
			</div>
			{showTrackingNoModal && (
				<AwbModal
					transitShipmentId={showTrackingNoModal}
					redirectionData={redirectFunc}
					onFormSubmit={handleFormSubmit}
					handleCloseAwbModal={handleCloseAwbModal}
				/>
			)}
			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}
		</>
	);
}

export default TransitShipmentDetails;
