import { Avatar } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { BsPhone } from 'react-icons/bs';
import { FaCheck, FaEdit } from 'react-icons/fa';
import { FaCircleInfo } from 'react-icons/fa6';
import PropTypes from 'prop-types';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

export const getStatusColor = (statusColor) => {
	switch (statusColor) {
		case 'red':
			return '#ff7675';
			break;
		case 'yellow':
			return '#fbbf0d';
			break;
		case 'blue':
			return '#077bbb';
			break;
		case 'green':
			return 'grey';
			break;
		default:
			return 'grey';
			break;
	}
};

function Task({ task, onAccept, onDetails, onEdit, customDone, acceptPermission, viewPermission, privacyMode }) {
	const handleAcceptButton = () => {
		onAccept && onAccept(task?.id);
	};

	const handleEditButton = () => {
		onEdit && onEdit(task?.id);
	};

	const handleDetailsButton = () => {
		onDetails && onDetails(task?.id);
	};

	return (
		<div className="task-card" style={{ '--statusColor': getStatusColor(task?.priorityColor) }}>
			<div className="task-head">
				<h6>
					<PrivacyModeContent notUserRestricted={task?.taskNameNotPrivate}>
						{task?.taskName?.length > 20 ? task?.taskName.slice(0, 25) + '...' : task?.taskName}
					</PrivacyModeContent>
				</h6>
				<span className="task-due">{moment(task?.date).format(CommonDateOnlyFormat)}</span>
			</div>

			<div className="task-body">
				{task?.bodyContent}
				{/* <div className="task-assignee">
					<Avatar sx={{ width: 20, height: 20 }} style={{ fontSize: 10 }}>
						{task?.assigned_to_user?.first_name.slice(0, 1)}
						{task?.assigned_to_user?.last_name.slice(0, 1)}
					</Avatar>
					<span>
						{task?.assigned_to_user?.first_name} {task?.assigned_to_user?.last_name}
					</span>
				</div> */}
			</div>

			<div className="task-actions">
				<div className="task-qty">
					<BsPhone /> {task?.quantity}
				</div>
				<div className="d-flex justify-content-end" style={{ gap: '5px' }}>
					<PermissionAllowed permissions={acceptPermission || []}>
						{task?.priority == 'completed' ? (
							<>
								{/* <button className="btn btn-primary btn-outlined-main" onClick={handleEditButton}>
								<FaEdit /> Edit
							</button> */}
							</>
						) : (
							<>
								{task?.inprogress ? (
									<>
										<button className="btn btn-primary btn-outlined-main" onClick={handleAcceptButton}>
											{customDone ? (
												customDone
											) : (
												<>
													<FaCheck /> Done
												</>
											)}
										</button>
									</>
								) : (
									<>
										<button className="btn btn-primary btn-outlined-main" onClick={handleAcceptButton}>
											<FaCheck /> Start
										</button>
									</>
								)}
							</>
						)}
					</PermissionAllowed>

					<PermissionAllowed permissions={viewPermission || []}>
						<button className="btn btn-primary btn-outlined-main" onClick={handleDetailsButton}>
							<FaCircleInfo /> Details
						</button>
					</PermissionAllowed>
				</div>
			</div>
		</div>
	);
}

Task.propTypes = {
	task: PropTypes.shape({
		id: PropTypes.number,
		taskName: PropTypes.string,
		date: PropTypes.any,
		priority: PropTypes.string,
		priorityColor: PropTypes.string,
		quantity: PropTypes.number,
		inprogress: PropTypes.bool,
		bodyContent: PropTypes.any
	}),
	onAccept: PropTypes.func,
	onDetails: PropTypes.func,
	onEdit: PropTypes.func
};

export default Task;
