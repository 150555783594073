import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import Loader from 'ui-component/Loader'
import { useSelector, useDispatch } from 'react-redux'
import { setLoading } from 'store/reducers/mainSlice'

const ViewRoleDetails = () => {
	const { id } = useParams()
	// const isLoading = useSelector((state) => state.main.isLoading);
	const selectedUser = useSelector((state) => state.main.user.selectedUser)
	const dispatch = useDispatch()
	const history = useNavigate()
	const [customerInfo, setCustomerInfo] = useState()
	const [isLoading, setIsloading] = useState(false)
	const [show, setShow] = useState('')
	// const [roles, setRoles] = useState();

	const getcustomerInfo = () => {
		setIsloading(true)
		api.getCustomerbyId(id).then((res) => {
			setIsloading(false)
			
			setCustomerInfo(res.data)
			// console.log(res.data.roles.map((item) => item.name));
			// setRoles(res.data.roles);
		})
	}
	const deleteuserData = () => {
		setShow(customerInfo)
	}

	const handleClose = () => setShow('')

	const handleDelete = (item) => {
		api.deleteCustomer(item).then((res) => {
			if (res.status) {
				setShow('')
				history('/contacts/customer')
			}
		})
	}
	useEffect(() => {
		getcustomerInfo()
	}, [])

	return (
		<>
			{isLoading ? (
				<div style={{ position: 'relative', width: '100%', height: '70vh' }}>
					<Loader />
				</div>
			) : (
				<>
					<div className="d-sm-flex align-items-center justify-content-between mb-4">
						<h1 className="h2 mb-0  font-weight-bold">{`${customerInfo?.name}`}</h1>
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb bg-transparent m-0 p-1">
								<li className="breadcrumb-item">
									<Link to={'/contacts/customer'}>User</Link>
								</li>
								<li className="breadcrumb-item active" aria-current="page">
									{`${customerInfo?.name}`}
								</li>
							</ol>
						</nav>
					</div>
					<div className="row">
						{/* Area Chart */}
						<div className="col-xl-12 col-lg-12">
							<div className="card shadow-sm rounded-xl bg-white mb-4 p-3 p-md-4">
								<div className="d-flex flex-row align-items-center justify-content-end mb-3 mb-lg-0">
									<Link to={`/contacts/update/${id}`} className="btn rounded-xl btn-outline-primary px-4 mr-3 ">
										<span className="icon mr-1">
											<i className="fas fa-pencil-alt" />
										</span>
										<span className="text">Edit</span>
									</Link>
									<Link to={'#'} onClick={deleteuserData} className="btn rounded-xl btn-outline-danger px-4 mr-3 ">
										<span className="icon mr-1">
											<i className="fas fa-trash-alt" />
										</span>
										<span className="text">Delete</span>
									</Link>
								</div>
								<div className="row p-xl-3 ">
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
										<ul className="customersDataList  border-right  m-0 p-0">
											<li>
												<label htmlFor="">Full Name</label>
												<span>{`${customerInfo?.name}`}</span>
											</li>
											<li>
												<label htmlFor="">Contact Name</label>
												<span>{`${customerInfo?.contact_name}`}</span>
											</li>
											<li>
												<label htmlFor="">Email Address</label>
												<span>{`${customerInfo?.email}`}</span>
											</li>
											<li>
												<label htmlFor="">Contact phone</label>
												<span>{`${customerInfo?.contact_phone}`}</span>
											</li>
											<li>
												<label htmlFor="">Contact whatsapp</label>
												<span>{`${customerInfo?.contact_whatsapp}`}</span>
											</li>
										</ul>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
										<ul className="customersDataList  border-right m-0 pl-sm-0 ">
											<li>
												<label htmlFor="">VAT Number</label>
												<span>{`${customerInfo?.vat_number}`}</span>
											</li>
											<li>
												<label htmlFor="">Registration Number</label>
												<span>{`${customerInfo?.registration_number}`}</span>
											</li>
											<li>
												<label htmlFor="">Address</label>
												<span>{`${selectedUser?.address?.address}`}</span> <br />
												<span>{`${selectedUser?.address?.address_line2}`}</span>
												<br />
												<span>{`${selectedUser?.address?.city?.city}`}</span> <br />
												<span>{`${selectedUser?.address?.state?.name}`}</span> <br />
												<span>{`${selectedUser?.address?.country?.name}`}</span> <br />
												<span>{`${selectedUser?.address?.zipcode}`}</span> <br />
											</li>
										</ul>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
										<ul className="customersDataList  m-0 pl-lg-4 pl-sm-0">
											<li>
												<label htmlFor="">Status</label>
												<span>
													{customerInfo?.status === 1
														? (
															<span className="activeStatus bg-success">Active</span>
														)
														: (
															<span className="inactiveStatus bg-danger">Inactive</span>
														)}
												</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Modal show={show} onHide={handleClose} centered>
						<Modal.Body>
							<Modal.Title>Confirm Delete</Modal.Title>
							<p>Are you sure you want to delete {`${show.first_name}`}</p>
							<Button variant="primary" className="m-2 " onClick={handleClose}>
                                Cancel
							</Button>
							<Button
								variant="danger"
								className="m-2"
								onClick={(e) => {
									handleDelete(show.id)
								}}
							>
                                Delete
							</Button>
						</Modal.Body>
					</Modal>
				</>
			)}
		</>
	)
}

export default ViewRoleDetails
