import React, { useRef, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { CiSearch } from 'react-icons/ci';
import { IoCartOutline } from 'react-icons/io5';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoPersonOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineExclamationCircle } from 'react-icons/hi2';
import { GoVerified } from 'react-icons/go';
import { setSearchBar } from 'store/reducers/mainSlice';
import { FiSearch } from 'react-icons/fi';

const Index = ({
	onnavopen,
	mobileNav,
	clientLogout,
	isBlur,
	setIsBlur,
	setBurgerActive,
	setProfileActive,
	burgerActive,
	profileActive,
	setMobileNav,
	setSearchActive,
	searchActive
}) => {
	const searchBar = useSelector((state) => state.main.searchBar);
	const clientUser = useSelector((state) => state.main.client?.data);
	const [accountShow, setAccountShow] = useState(false);
	const test = useSelector((state) => state.main.client);

	const dispatch = useDispatch();

	const clientLogoutside = () => {
		clientLogout();
	};
	console.log(test);
	return (
		<>
			{accountShow && (
				<div className="row" style={{ position: 'fixed', bottom: 80, marginLeft: 10, marginRight: 10, width: '96%' }}>
					<div className="col-12">
						<div className="card">
							<div className="card-body" style={{ padding: '20px 30px' }}>
								<div className="row" style={{ marginBottom: 5 }}>
									<div className="col-12">
										<Link
											className={`${clientUser?.is_approved !== 2 ? 'disabled-link' : ''}`}
											to={'/client/settings'}
											style={{ textDecoration: 'none', color: 'black' }}
											onClick={() => {
												setAccountShow(!accountShow);
												setIsBlur((prevIsBlur) => !prevIsBlur);
												setProfileActive(!profileActive);
												setBurgerActive(!burgerActive);
												setMobileNav(false);
											}}
										>
											<div className="mobile-account-pop" style={{ display: 'flex', alignItems: 'center' }}>
												<span className="menuIcon">
													<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
														<defs>
															<clipPath id="clip-path">
																<rect
																	id="Rectangle_138"
																	data-name="Rectangle 138"
																	width="22"
																	height="22"
																	transform="translate(22 557)"
																	stroke="#707070"
																	stroke-width="1"
																/>
															</clipPath>
														</defs>
														<g id="Mask_Group_5" data-name="Mask Group 5" transform="translate(-22 -557)" clip-path="url(#clip-path)">
															<g id="settings" transform="translate(23.102 557)">
																<path
																	id="Path_202"
																	data-name="Path 202"
																	d="M10.666,7a3.666,3.666,0,1,0,3.666,3.666A3.666,3.666,0,0,0,10.666,7Zm0,5.5A1.833,1.833,0,1,1,12.5,10.666,1.833,1.833,0,0,1,10.666,12.5Z"
																	transform="translate(-0.771 0.332)"
																	fill="currentColor"
																/>
																<path
																	id="Path_203"
																	data-name="Path 203"
																	d="M19.465,12.739l-.407-.235a8.34,8.34,0,0,0,0-3.015l.407-.235a2.751,2.751,0,1,0-2.749-4.766l-.408.236A8.227,8.227,0,0,0,13.7,3.22v-.47a2.749,2.749,0,1,0-5.5,0v.47a8.227,8.227,0,0,0-2.61,1.508l-.41-.237A2.751,2.751,0,1,0,2.429,9.256l.407.235a8.34,8.34,0,0,0,0,3.015l-.407.235a2.751,2.751,0,0,0,2.749,4.766l.408-.236a8.227,8.227,0,0,0,2.612,1.5v.47a2.749,2.749,0,1,0,5.5,0v-.47a8.227,8.227,0,0,0,2.61-1.509l.41.236a2.751,2.751,0,0,0,2.749-4.766ZM17.13,9.278a6.459,6.459,0,0,1,0,3.437.916.916,0,0,0,.425,1.038l.993.574a.917.917,0,1,1-.916,1.588l-1-.576a.916.916,0,0,0-1.114.151,6.4,6.4,0,0,1-2.972,1.718.916.916,0,0,0-.688.888v1.148a.916.916,0,1,1-1.833,0V18.1a.916.916,0,0,0-.688-.888,6.4,6.4,0,0,1-2.971-1.722.916.916,0,0,0-1.114-.151l-.993.575a.916.916,0,1,1-.916-1.587l.993-.574a.916.916,0,0,0,.425-1.038,6.459,6.459,0,0,1,0-3.437A.916.916,0,0,0,4.34,8.241l-.993-.574a.917.917,0,1,1,.916-1.588l1,.576a.916.916,0,0,0,1.113-.148A6.4,6.4,0,0,1,9.343,4.789a.916.916,0,0,0,.688-.892V2.749a.916.916,0,1,1,1.833,0V3.9a.916.916,0,0,0,.688.888,6.4,6.4,0,0,1,2.972,1.722.916.916,0,0,0,1.114.151l.993-.575a.916.916,0,1,1,.916,1.587l-.993.574a.916.916,0,0,0-.424,1.035Z"
																	transform="translate(-1.052 0)"
																	fill="currentColor"
																/>
															</g>
														</g>
													</svg>
												</span>
												<h5 style={{ marginBottom: 0, marginLeft: 10 }}>Settings</h5>
											</div>
										</Link>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<Link
											to={'#'}
											style={{ textDecoration: 'none', color: 'black' }}
											onClick={() => {
												clientLogoutside();
												setProfileActive(!profileActive);
											}}
										>
											<div className="mobile-account-pop" style={{ display: 'flex', alignItems: 'center' }}>
												<span className="menuIcon" style={{ marginLeft: 3 }}>
													<svg
														id="logout_"
														data-name="logout "
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														viewBox="0 0 21.982 20.999"
														stroke="#707070"
														stroke-width="1"
													>
														<path
															id="logout"
															d="M4.746,21a4.708,4.708,0,0,1-4.74-4.437L0,16.342V4.657A4.7,4.7,0,0,1,4.522.005L4.746,0H9.964a4.707,4.707,0,0,1,4.741,4.437l.005.22v.98a.8.8,0,0,1-1.6.106L13.1,5.637v-.98A3.107,3.107,0,0,0,10.148,1.58l-.185-.005H4.746a3.116,3.116,0,0,0-3.135,2.9l-.005.181V16.342a3.108,3.108,0,0,0,2.956,3.076l.184,0H9.975A3.1,3.1,0,0,0,13.1,16.534l.005-.18v-.991a.8.8,0,0,1,1.6-.106l.007.106v.991a4.687,4.687,0,0,1-4.506,4.64L9.975,21Zm12.731-6.882a.776.776,0,0,1-.076-1.025L17.479,13l1.755-1.715H8.292a.787.787,0,0,1-.109-1.568l.109-.007H19.236L17.479,8a.775.775,0,0,1-.08-1.025l.077-.088A.815.815,0,0,1,18.521,6.8l.09.076,3.123,3.05a.777.777,0,0,1,0,1.141l-3.118,3.047a.814.814,0,0,1-1.135,0Z"
															fill="currentColor"
														/>
													</svg>
												</span>
												<h5 style={{ marginBottom: 0, marginLeft: 10 }}>Logout</h5>
											</div>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<div
				className="container-fluid d-flex justify-content-between align-items-center"
				style={{
					boxShadow: '0px 5px 20px 0px rgb(180 180 180)',
					paddingLeft: 30,
					paddingTop: 10,
					paddingBottom: 10,
					paddingRight: 30,
					zIndex: 10,
					background: 'white'
				}}
			>
				<div className={`footer-icon ${mobileNav ? 'active-navbar-mobile' : 'inactive-navbar-mobile'}`}>
					<div
						className={`hamburger-menu ${burgerActive ? 'active' : ''}`}
						onClick={() => {
							onnavopen();
							if (!profileActive) {
								setIsBlur((prevIsBlur) => !prevIsBlur);
							}
							setBurgerActive(!burgerActive);
						}}
					>
						<div className="line-burg"></div>
						<div className="line-burg"></div>
						<div className="line-burg"></div>
					</div>
				</div>
				<div className="footer-icon">
					<FiSearch
						size={22}
						style={{ color: searchBar ? '#0066FF' : 'inherit' }}
						onClick={() => {
							dispatch(setSearchBar(!searchBar));
						}}
					/>
				</div>
				<div className="footer-icon">
					<IoCartOutline />
				</div>
				<div className="footer-icon">
					<IoPersonOutline
						style={{ color: profileActive ? '#0066FF' : 'inherit' }}
						onClick={() => {
							setAccountShow(!accountShow);
							if (!burgerActive) {
								setIsBlur((prevIsBlur) => !prevIsBlur);
							}
							setProfileActive(!profileActive);
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default Index;
