import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import store from './store';

// style + assets
import 'assets/scss/style.scss';
import config from './config';

import { QueryClient, QueryClientProvider } from 'react-query';
import { localStorageCacheCleaner } from 'helpers/common.helper';

import { React } from 'react';

const queryClient = new QueryClient();

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
// eslint-disable-next-line jest/require-hook

localStorageCacheCleaner();

root.render(
	<Provider store={store}>
		<BrowserRouter basename={config.basename}>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</BrowserRouter>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// eslint-disable-next-line jest/require-hook
serviceWorker.unregister();
