import React, { useEffect, useState } from 'react';
import ProcessIndicator from '../ProcessIndicator';
import { CompanyDetailsProgresses } from 'assets/helperdata/client/company-details-progress';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import Select from 'react-select';

const SurveyDetails = () => {
	const clientUser = useSelector((state) => state.main.client);
	const history = useNavigate();
	const [isInProgress, setIsInProgress] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [data, setData] = useState({
		platform: '',
		jobTitle: '',
		employees: '',
		isAgreed: false,
		isAcknowledged: false,
		id: clientUser?.data?.id
	});
	const [appClient, setAppClient] = useState([]);
	const [platforms, setPlatforms] = useState([]);
	const [employees, setEmployees] = useState([]);

	const [platformOptions, setPlatformOptions] = useState([]);

	const [employeeOptions, setEmployeeOptions] = useState([]);
	const [addOns, setAddOns] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		api.getAppClient().then((res) => {
			console.log(res.data);
			setAppClient(res.data);
		});

		api.getPlatformList().then((res) => {
			setPlatforms(res.data);
			// Transforming the platform data for react-select
			const transformedPlatforms = res.data.map((plat) => ({
				value: plat.id,
				label: plat.name
			}));
			setPlatformOptions(transformedPlatforms);
		});

		api.getEmployeeList().then((res) => {
			setEmployees(res.data);
			// Transforming the employee data for react-select
			const transformedEmployees = res.data.map((emp) => ({
				value: emp.id,
				label: emp.name
			}));
			setEmployeeOptions(transformedEmployees);
		});
	}, []);

	useEffect(() => {
		if (clientUser?.data?.id) {
			api.getClientInformationSurvey(clientUser?.data?.id).then((res) => {
				console.log(res);
				if (res?.data?.companySurvey) {
					console.log(res.data?.companySurvey);
					setData({
						platform: res.data?.companySurvey?.platform_id,
						jobTitle: res.data?.companySurvey?.job_title,
						employees: res.data?.companySurvey?.employee_id,
						notes: res.data?.companySurvey?.platform_notes ?? '',
						isAgreed: res.data?.companyInfo?.is_agreed,
						isAcknowledged: res.data?.companyInfo?.is_acknowledged,
						id: clientUser?.data?.id
					});
				}
				// if (res?.data?.companyBank) {
				// 	console.log(res.data?.companyBank);
				// 	setData({
				// 		bankName: res.data?.companyBank?.bank_name,
				// 		bankBeneficiary: res.data?.companyBank?.bank_beneficiary_name,
				// 		bankIBAN: res.data?.companyBank?.bank_IBAN,
				// 		bankSWIFT: res.data?.companyBank?.bank_SWIFT,
				// 		address: res.data?.companyBank?.bank_address,
				// 		id: res.data?.companyBank?.id
				// 	});
				// }
			});
		}
	}, [clientUser?.data?.id]);

	console.log(data);
	const moveToNext = () => {
		if (data?.platform === '' || data?.jobTitle === '' || data?.employees === '' || (addOns && data?.notes === '')) {
			toast.error('Please fill all fields');
			setIsInProgress(false);
			return;
		}
		setIsInProgress(false);
		api
			.updateClientSurveyDetails(data.id, data)
			.then((res) => {
				toast.success('Survey updated successfully');
			})
			.catch((err) => {
				console.log(err);
			});
		history('../client/company-verified');
	};
	const moveToBack = () => {
		setIsInProgress(false);
		history('../client/company-bank-details');
	};

	const calculateCompletionPercentage = () => {
		let fields = [];
		if (addOns) {
			fields = ['platform', 'jobTitle', 'employees', 'notes', 'isAgreed', 'isAcknowledged'];
		} else {
			fields = ['platform', 'jobTitle', 'employees', 'isAgreed', 'isAcknowledged'];
		}
		const filledFields = fields.filter((field) => data[field] !== '');
		return (filledFields.length / fields.length) * 100;
	};

	// Call this function whenever you want to get the completion percentage
	const completionPercentage = calculateCompletionPercentage();

	return (
		<div className={`main-page-client ${isMobile ? 'padding-mobile-client' : ''}`}>
			<div className="actions-company-details-client">
				<ProcessIndicator
					isInProgress={isInProgress}
					processes={CompanyDetailsProgresses}
					currentIndex={5}
					completionPercentage={completionPercentage}
				/>
			</div>
			<div className="company-details-client-content">
				<div className="" style={{ justifyContent: 'space-between' }}>
					<table className="projectmix-table-vertical-client">
						<tbody>
							<tr>
								<td className="fw-600" colSpan={2} style={{ textAlign: isMobile ? 'left' : 'center', fontSize: isMobile ? 16 : 14 }}>
									Survey
								</td>
							</tr>
							<tr>
								<td>
									<label className="onboard-label">How did you discover {appClient?.client_name ?? ''}?</label>
									<Select
										options={platformOptions}
										onChange={(e) => {
											setData({ ...data, platform: e.value });
											if (e.label === 'Events' || e.label === 'Others') {
												setAddOns(true);
											} else {
												setAddOns(false);
											}
										}}
										className="rs-container-est-white-client"
										name="platform"
										classNamePrefix="rs"
										placeholder="Select"
										value={platformOptions.find((option) => option.value === data.platform)}
										isSearchable={true}
									/>
								</td>
							</tr>
							{['Events', 'Others'].includes(platformOptions.find((option) => option.value === data.platform)?.label) && (
								<tr>
									<td>
										<label className="onboard-label">
											{platformOptions.find((option) => option.value === data.platform)?.label === 'Events'
												? 'Which event?'
												: 'Kindly specify'}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={
												platformOptions.find((option) => option.value === data.platform)?.label === 'Events'
													? 'Enter event name'
													: 'Enter specific platform'
											}
											value={data.notes}
											onChange={(e) => setData({ ...data, notes: e.target.value })}
										/>
									</td>
								</tr>
							)}
							<tr>
								<td>
									<label className="onboard-label">What is your job title?</label>
									<input
										type="text"
										className="form-control"
										placeholder="Enter job title"
										value={data.jobTitle}
										onChange={(e) => setData({ ...data, jobTitle: e.target.value })}
									/>
								</td>
							</tr>
							<tr>
								<td>
									<label className="onboard-label">How many employees are in your company?</label>
									<Select
										options={employeeOptions}
										onChange={(e) => setData({ ...data, employees: e.value })}
										className="rs-container-est-white-client"
										name="employees"
										classNamePrefix="rs"
										placeholder="Select"
										value={employeeOptions.find((option) => option.value === data.employees)}
										isSearchable={true}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="group-chk-trms-dtls">
					<input
						type="checkbox"
						id="terms-checkbox"
						checked={data.isAgreed === 1} // Ensure checkbox is checked if data.isAgreed is 1
						onClick={() => setData({ ...data, isAgreed: data.isAgreed === 1 ? 0 : 1 })} // Toggle between 0 and 1
					/>
					<label htmlFor="terms-checkbox">
						I agree to the{' '}
						<a href="https://projectmix.ai/terms-of-use" target="_blank" rel="noopener noreferrer">
							Privacy Policy
						</a>
						{' and '}
						<a href="https://projectmix.ai/terms-of-use" target="_blank" rel="noopener noreferrer">
							Terms & Condition.
						</a>{' '}
					</label>
				</div>
				<div className="group-chk-trms-dtls">
					<input
						type="checkbox"
						name=""
						id="acknowledge-checkbox"
						checked={data.isAcknowledged === 1} // Ensure checkbox is checked if data.isAcknowledged is 1
						style={{ marginBottom: isMobile ? 20 : 0 }}
						onClick={() => setData({ ...data, isAcknowledged: data.isAcknowledged === 1 ? 0 : 1 })} // Toggle between 0 and 1
					/>
					<label htmlFor="acknowledge-checkbox">I acknowledge that the provided information is accurate.</label>
				</div>
			</div>
			<div className="action-company-dtls-adrs-btn" style={{ marginTop: 10 }}>
				<button
					className="btn btn-cmpny-dtls-action-back"
					onClick={() => {
						setIsInProgress(true);
						moveToBack();
					}}
				>
					<MdKeyboardArrowLeft /> Back
				</button>

				<button
					className="btn btn-cmpny-dtls-action"
					onClick={() => {
						setIsInProgress(true);
						moveToNext();
					}}
				>
					Next <MdKeyboardArrowRight />
				</button>
			</div>
		</div>
	);
};

export default SurveyDetails;
