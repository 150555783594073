import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import './UserPermissions.scss';
import './RolePermissions.scss';
import Scroller from 'ui-component/Scroller';
import toast from 'react-hot-toast';

const RolePermissions = ({ onClose, data }) => {
	const history = useNavigate();
	const [permissionsList, setPermissionsList] = useState([]);
	const dispatch = useDispatch();

	const [userPermissionsList, setUserPermissionsList] = useState([
		{
			id: 1,
			name: 'Customer',
			permissions: [
				{
					id: 1,
					name: 'Customer Create',
					page_events: [
						{
							id: 1,
							name: 'Create Customer (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 2,
							name: 'Create New Customer (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 2,
					name: 'Customer List',
					page_events: [
						{
							id: 3,
							name: 'List Customer (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 4,
							name: 'Create Customer (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 5,
							name: 'View Customer (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 6,
							name: 'Delete Customer (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						},
						{
							id: 7,
							name: 'Suspend/Activate Customer (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 8,
							name: 'Edit Customer (Button)',
							method: 'edit',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 3,
					name: 'Customer View',
					page_events: [
						{
							id: 9,
							name: 'View Customer Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 10,
							name: 'Edit Customer Details (Action)',
							method: 'read',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 4,
					name: 'Customer Update',
					page_events: [
						{
							id: 11,
							name: 'Update Customer (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 12,
							name: 'Save Customer Details (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 2,
			name: 'User',
			permissions: [
				{
					id: 5,
					name: 'User Create',
					page_events: [
						{
							id: 13,
							name: 'Create New User (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 14,
							name: 'Create New User (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 6,
					name: 'User List',
					page_events: [
						{
							id: 15,
							name: 'List User (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 16,
							name: 'Create User (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 17,
							name: 'View User (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 18,
							name: 'Delete User (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						},
						{
							id: 19,
							name: 'Change User Status (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 20,
							name: 'View User Permissions (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 21,
							name: 'Update User Permissions (Checkbox)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 7,
					name: 'User View',
					page_events: [
						{
							id: 22,
							name: 'View User Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 23,
							name: 'Save User Details (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 3,
			name: 'Role',
			permissions: [
				{
					id: 8,
					name: 'Role Create',
					page_events: [
						{
							id: 24,
							name: 'Create New Role  (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 25,
							name: 'Create New Role (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 9,
					name: 'Role List',
					page_events: [
						{
							id: 26,
							name: 'List Role (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 27,
							name: 'Create Role (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 28,
							name: 'View Role (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 29,
							name: 'Delete Role (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 10,
					name: 'Role View',
					page_events: [
						{
							id: 30,
							name: 'View Role Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 31,
							name: 'Save Role Details (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 4,
			name: 'Permission',
			permissions: []
		},
		{
			id: 5,
			name: 'Permission Group',
			permissions: []
		},
		{
			id: 6,
			name: 'Supplier',
			permissions: [
				{
					id: 11,
					name: 'Supplier Create',
					page_events: [
						{
							id: 32,
							name: 'Create Supplier (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 33,
							name: 'Create New Supplier (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 12,
					name: 'Supplier List',
					page_events: [
						{
							id: 34,
							name: 'List Supplier (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 35,
							name: 'Create Supplier (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 36,
							name: 'View Supplier (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 37,
							name: 'Delete Supplier (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						},
						{
							id: 38,
							name: 'Suspend/Activate Supplier (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 39,
							name: 'Edit Supplier (Button)',
							method: 'edit',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 13,
					name: 'Supplier View',
					page_events: [
						{
							id: 40,
							name: 'View Supplier Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 41,
							name: 'Edit Supplier Details (Action)',
							method: 'edit',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 14,
					name: 'Supplier Update',
					page_events: [
						{
							id: 42,
							name: 'Update Supplier (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 43,
							name: 'Save Supplier Details (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 7,
			name: 'Transit Company',
			permissions: [
				{
					id: 15,
					name: 'Transit Company Create',
					page_events: [
						{
							id: 44,
							name: 'Create Transit Company (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 45,
							name: 'Create New Transit Company (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 16,
					name: 'Transit Company List',
					page_events: [
						{
							id: 46,
							name: 'List Transit Company (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 47,
							name: 'Create Transit Company (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 48,
							name: 'View Transit Company (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 49,
							name: 'Delete Transit Company (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						},
						{
							id: 50,
							name: 'Suspend/Activate Transit Company (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 51,
							name: 'Edit Transit Company (Button)',
							method: 'edit',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 17,
					name: 'Transit Company View',
					page_events: [
						{
							id: 52,
							name: 'View Transit Company Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 53,
							name: 'Edit Transit Company Details (Action)',
							method: 'edit',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 18,
					name: 'Transit Company Update',
					page_events: [
						{
							id: 54,
							name: 'Update Transit Company (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 55,
							name: 'Save Transit Company Details (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 8,
			name: 'Model',
			permissions: [
				{
					id: 19,
					name: 'Model Create',
					page_events: [
						{
							id: 56,
							name: 'Create Model (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 57,
							name: 'Create New Model (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 20,
					name: 'Model List',
					page_events: [
						{
							id: 58,
							name: 'List Model (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 59,
							name: 'Create Model (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 60,
							name: 'View Model (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 61,
							name: 'Delete Model (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 21,
					name: 'Model View',
					page_events: [
						{
							id: 62,
							name: 'View Model Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 63,
							name: 'Save Model Details (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 9,
			name: 'Stock',
			permissions: [
				{
					id: 22,
					name: 'Stock Create',
					page_events: [
						{
							id: 64,
							name: 'Create Stock (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 65,
							name: 'Create New Stock (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 23,
					name: 'Stock List',
					page_events: [
						{
							id: 66,
							name: 'List Stock (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 67,
							name: 'Create Stock (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 68,
							name: 'View Stock (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 69,
							name: 'Delete Stock (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 24,
					name: 'Stock View',
					page_events: [
						{
							id: 70,
							name: 'View Stock Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 71,
							name: 'Save Stock Details (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 10,
			name: 'Estimation',
			permissions: [
				{
					id: 25,
					name: 'Estimation Create',
					page_events: [
						{
							id: 72,
							name: 'Create Estimation (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 73,
							name: 'Create New Estimation (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						},
						{
							id: 74,
							name: 'Draft Estimation Details (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 26,
					name: 'Estimation List',
					page_events: [
						{
							id: 75,
							name: 'List Estimation (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 76,
							name: 'Create Estimation (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 77,
							name: 'View Estimation (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 78,
							name: 'Delete Estimation (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						},
						{
							id: 79,
							name: 'Accept Estimation (Button)',
							method: 'accept',
							type: 'button',
							has_permission: false
						},
						{
							id: 80,
							name: 'Add RP Estimation File (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 81,
							name: 'Add Estimation TT Files (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 82,
							name: 'Add to Purchase List (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 27,
					name: 'Estimation View',
					page_events: [
						{
							id: 83,
							name: 'View Estimation Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 84,
							name: 'Save Estimation Details (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 85,
							name: 'Print Estimation Details (Button)',
							method: 'print',
							type: 'button',
							has_permission: false
						},
						{
							id: 86,
							name: 'Upload Estimation RP File (Action)',
							method: 'upload',
							type: 'button',
							has_permission: false
						},
						{
							id: 87,
							name: 'Upload Estimation TT File (Action)',
							method: 'upload',
							type: 'button',
							has_permission: false
						},
						{
							id: 88,
							name: 'Download Estimation TT File (Button)',
							method: 'download',
							type: 'button',
							has_permission: false
						},
						{
							id: 89,
							name: 'Delete Estimation TT File (Action)',
							method: 'delete',
							type: 'button',
							has_permission: false
						},
						{
							id: 90,
							name: 'Create Estimation Shipment (Button)',
							method: 'store',
							type: 'button',
							has_permission: false
						},
						{
							id: 91,
							name: 'Upload Estimation Shipment Files (Action)',
							method: 'upload',
							type: 'button',
							has_permission: false
						},
						{
							id: 92,
							name: 'Update Estimation Shipment (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						},
						{
							id: 93,
							name: 'Delete Estimation Shipment (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						},
						{
							id: 94,
							name: 'Create Estimation Note (Button)',
							method: 'store',
							type: 'button',
							has_permission: false
						},
						{
							id: 95,
							name: 'Edit Estimation Note (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 96,
							name: 'Delete Estimation Note (Button)',
							method: 'Delete',
							type: 'button',
							has_permission: false
						},
						{
							id: 97,
							name: 'Create Estimation Payment (Button)',
							method: 'store',
							type: 'button',
							has_permission: false
						},
						{
							id: 98,
							name: 'Update Estimation Payment (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 99,
							name: 'Delete Estimation Payment (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 11,
			name: 'Purchase List',
			permissions: [
				{
					id: 28,
					name: 'Purchase List All',
					page_events: [
						{
							id: 100,
							name: 'Display Purchase List (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 101,
							name: 'Move Purchase List to Found (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 102,
							name: 'Assign Inspection (Button)',
							method: 'store',
							type: 'button',
							has_permission: false
						},
						{
							id: 103,
							name: 'Assign Collection (Button)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 12,
			name: 'Purchase Order',
			permissions: []
		},
		{
			id: 13,
			name: 'Task Inspection',
			permissions: [
				{
					id: 29,
					name: 'Task Inspection List',
					page_events: [
						{
							id: 104,
							name: 'List Task Inspection (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 105,
							name: 'View Task Inspection Details (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 106,
							name: 'Start / End Task Inspection (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 30,
					name: 'Task Inspection View',
					page_events: [
						{
							id: 107,
							name: 'View Task Inspection Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 108,
							name: 'Start / End Task Inspection (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 14,
			name: 'Task Collection',
			permissions: [
				{
					id: 31,
					name: 'Task Collection List',
					page_events: [
						{
							id: 109,
							name: 'List Task Collection (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 110,
							name: 'View Task Collection Details (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 111,
							name: 'Start / End Task Collection (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 32,
					name: 'Task Collection View',
					page_events: [
						{
							id: 112,
							name: 'View Task Collection Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 113,
							name: 'Start / End Task Collection (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 15,
			name: 'Task Segregation',
			permissions: [
				{
					id: 33,
					name: 'Task Segregation List',
					page_events: [
						{
							id: 114,
							name: 'List Task Segregation (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 115,
							name: 'View Task Segregation Details (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 116,
							name: 'Start / End Task Segregation (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 34,
					name: 'Task Segregation View',
					page_events: [
						{
							id: 117,
							name: 'View Task Segregation Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 118,
							name: 'Start / End Task Segregation (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 16,
			name: 'Task Testing',
			permissions: [
				{
					id: 35,
					name: 'Task Testing List',
					page_events: [
						{
							id: 119,
							name: 'List Task Testing (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 120,
							name: 'View Task Testing Details (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 121,
							name: 'Start / End Task Testing (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 36,
					name: 'Task Testing View',
					page_events: [
						{
							id: 122,
							name: 'View Task Testing Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 123,
							name: 'Start / End Task Testing (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 17,
			name: 'Task Inbounding',
			permissions: [
				{
					id: 37,
					name: 'Task Inbounding List',
					page_events: [
						{
							id: 124,
							name: 'List Task Inbounding (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 125,
							name: 'View Task Inbounding Details (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 126,
							name: 'Start / End Task Inbounding (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 38,
					name: 'Task Inbounding View',
					page_events: [
						{
							id: 127,
							name: 'View Task Inbounding Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 128,
							name: 'Start / End Task Inbounding (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 129,
							name: 'Add Item Repair for Inbounding',
							method: 'create',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 18,
			name: 'Task Quality Control',
			permissions: [
				{
					id: 39,
					name: 'Task Quality Control List',
					page_events: [
						{
							id: 130,
							name: 'List Task Quality Control (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 131,
							name: 'View Task Quality Control Details (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 132,
							name: 'Start / End Task Quality Control (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 40,
					name: 'Task Quality Control View',
					page_events: [
						{
							id: 133,
							name: 'View Task Quality Control Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 134,
							name: 'Start / End Task Quality Control (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 135,
							name: 'Add Item Repair for Quality Control',
							method: 'create',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 19,
			name: 'Task Repairing',
			permissions: [
				{
					id: 41,
					name: 'Task Repairing List',
					page_events: [
						{
							id: 136,
							name: 'List Task Repairing (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 137,
							name: 'View Task Repairing Details (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 138,
							name: 'Start / End Task Repairing (Shortcut)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 42,
					name: 'Task Repairing View',
					page_events: [
						{
							id: 139,
							name: 'View Task Repairing Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 140,
							name: 'Start / End Task Repairing (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 141,
							name: 'Add Item Repair for Repairing',
							method: 'create',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 20,
			name: 'Task Retesting',
			permissions: [
				{
					id: 43,
					name: 'Task Retesting List',
					page_events: [
						{
							id: 142,
							name: 'List Task Retesting (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 143,
							name: 'View Task Retesting Details (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 144,
							name: 'Start / End Task Retesting (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 44,
					name: 'Task Retesting View',
					page_events: [
						{
							id: 145,
							name: 'View Task Retesting Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 146,
							name: 'Start / End Task Retesting (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 147,
							name: 'Add Item Repair for Retesting',
							method: 'create',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 21,
			name: 'Task Packing',
			permissions: [
				{
					id: 45,
					name: 'Task Packing List',
					page_events: [
						{
							id: 148,
							name: 'List Task Packing (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 149,
							name: 'View Task Packing Details (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 150,
							name: 'Start / End Task Packing (Button)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 46,
					name: 'Task Packing View',
					page_events: [
						{
							id: 151,
							name: 'View Task Packing Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 152,
							name: 'Start / End Task Packing (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						},
						{
							id: 153,
							name: 'Add Item for Packing',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		},
		{
			id: 22,
			name: 'Shipment',
			permissions: [
				{
					id: 47,
					name: 'Shipment Create',
					page_events: [
						{
							id: 154,
							name: 'Create Shipment (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 155,
							name: 'Create New Shipment (Action)',
							method: 'store',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 48,
					name: 'Shipment List',
					page_events: [
						{
							id: 156,
							name: 'List Shipment (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 157,
							name: 'Create Shipment (Button)',
							method: 'create',
							type: 'button',
							has_permission: false
						},
						{
							id: 158,
							name: 'View Shipment (Button)',
							method: 'read',
							type: 'button',
							has_permission: false
						},
						{
							id: 159,
							name: 'Delete Shipment (Button)',
							method: 'delete',
							type: 'button',
							has_permission: false
						}
					]
				},
				{
					id: 49,
					name: 'Shipment View',
					page_events: [
						{
							id: 160,
							name: 'View Shipment Details (Page)',
							method: 'read',
							type: 'display',
							has_permission: false
						},
						{
							id: 161,
							name: 'Save Shipment Details (Action)',
							method: 'update',
							type: 'button',
							has_permission: false
						}
					]
				}
			]
		}
	]);

	// const userPermissions = ;

	// useEffect(() => {
	// 	// const copy = [...userPermissions.modules];
	// 	// copy.sort((a, b) => b?.permissions?.length - a?.permissions?.length);
	// 	setUserPermissionsList(data?.permissions);
	// }, [data]);

	const handlePermissionEventCheck = (event_id, perm_id, group_id) => {
		// const copy = [...userPermissionsList];
		// const permEvent = copy
		// 	?.find((module) => module?.id == group_id)
		// 	?.permissions?.find((perm) => perm?.id == perm_id)
		// 	?.page_events?.find((e) => e?.id == event_id);

		const newValue = [...userPermissionsList]
			?.find((module) => module?.id == group_id)
			?.permissions?.find((perm) => perm?.id == perm_id)
			?.page_events?.find((e) => e?.id == event_id)?.has_permission
			? 0
			: 1;

		setUserPermissionsList((oldData) => {
			const copy = [...oldData];
			const permEvent = copy
				?.find((module) => module?.id == group_id)
				?.permissions?.find((perm) => perm?.id == perm_id)
				?.page_events?.find((e) => e?.id == event_id);
			permEvent.has_permission = newValue;
			return copy;
		});

		//Get API Response and repeat above 3 lines along with the last line only if success

		// toast.promise(
		// 	api
		// 		.updateUserPermissions(data?.user?.id, {
		// 			is_checked: newValue,
		// 			page_event_ids: [event_id]
		// 		})
		// 		.then((res) => {
		// 			if (res?.status) {
		// 			} else {
		// 				setUserPermissionsList((oldData) => {
		// 					const copy = [...oldData];
		// 					const permEvent = copy
		// 						?.find((module) => module?.id == group_id)
		// 						?.permissions?.find((perm) => perm?.id == perm_id)
		// 						?.page_events?.find((e) => e?.id == event_id);
		// 					permEvent.has_permission = newValue ? 0 : 1;
		// 					return copy;
		// 				});
		// 			}

		// 			return res?.status ? res : null;
		// 		})
		// 		.catch(() => {
		// 			setUserPermissionsList((oldData) => {
		// 				const copy = [...oldData];
		// 				const permEvent = copy
		// 					?.find((module) => module?.id == group_id)
		// 					?.permissions?.find((perm) => perm?.id == perm_id)
		// 					?.page_events?.find((e) => e?.id == event_id);
		// 				permEvent.has_permission = newValue ? 0 : 1;
		// 				return copy;
		// 			});
		// 		}),
		// 	{
		// 		loading: 'Updating Permission...',
		// 		success: (data) => `${data.message}`,
		// 		error: <b>Failed to update permission.</b>
		// 	}
		// );

		// setUserPermissionsList(copy);
	};

	return (
		<Scroller>
			<div className="role-permissions-box">
				{userPermissionsList?.map((module) => {
					return module?.permissions?.length > 0 ? (
						<>
							<div className="user-permission-group">
								<div>
									{/* <div className="myTest custom-control custom-checkbox">
										<input type="checkbox" className="custom-control-input" id="groupTest" indeterminate/>
										<label className="custom-control-label" for="groupTest" style={{ fontSize1: '12px' }}></label>
									</div> */}
									<h4>{module?.name}</h4>
								</div>

								{module?.permissions?.map((permission) => {
									return (
										<>
											<h6 className="permission-group-title">{permission?.name}</h6>
											<div className="permission-selectors">
												{permission?.page_events?.map((pEvent) => {
													return (
														<div className="pEvent-selector">
															{/* <div className="form-check">
													<input className="form-check-input" type="checkbox" value="" id={pEvent.name} checked={pEvent?.has_pEvent}/>
													<label
														className="form-check-label"
														for={pEvent.name}
														style={{ fontSize1: '12px' }}
													>
														{pEvent.name}
													</label>
												</div> */}

															<div className="myTest custom-control custom-checkbox">
																<input
																	type="checkbox"
																	className="custom-control-input"
																	id={pEvent.name}
																	checked={pEvent?.has_permission}
																	onChange={(e) =>
																		handlePermissionEventCheck(pEvent.id, permission?.id, module?.id)
																	}
																/>
																<label
																	className="custom-control-label"
																	for={pEvent.name}
																	style={{ fontSize1: '12px' }}
																>
																	{pEvent?.name}
																</label>
															</div>
														</div>
													);
												})}
											</div>
										</>
									);
								})}
							</div>
						</>
					) : (
						<></>
					);
				})}
			</div>
		</Scroller>
	);
};

export default RolePermissions;
