import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';

const StatusModal = ({ openPopup, returnsDetail, selectedCurrentStatus, handleClose, handleSave, disableStatus }) => {

	const [alertMessage, setAlertMessage] = useState();
	const [invoiceList, setInvoiceList] = useState([]);
	const [statusList, setStatusList] = useState([]);

	const [currentStatus, setCurrentStatus] = useState(selectedCurrentStatus);
	const [formInfo, setFormInfo] = useState({
		status_id: '',
		returns_id: returnsDetail?.id,
		message: ''
	});
	const handleValue = (val) => {
		// setFormInfo(prevDetail => ({ ...prevDetail, status_id: val?.label }));
		setCurrentStatus(val?.label)
		setFormInfo({
			...formInfo,
			status_id: val?.id
		});
		// setFormInfo({ status_id: val?.value });
	};

	useEffect(() => {
		api.getReturnsStatusList().then((res) => {
			const tempStatusList = res.data.map((item) => ({
				id: item.id,
				label: `${item.name}`,
				value: item.id
			}));
			setStatusList(tempStatusList);
		});
		// api.getUnassignedInvoiceNumberList().then((res) => {
		// 	const tempInvoiceList = res.data.map((item) => ({
		// 		id: item.id,
		// 		label: `${item.invoice_number} - ${item?.customer_name}`,
		// 		value: item.id
		// 	}));
		// 	setInvoiceList(tempInvoiceList);
		// });
		// setFormInfo({
		// 	...formInfo,
		// 	productId: returnsDetails?.id
		// });
	}, []);

	const onSubmit = () => {
		console.log(formInfo)
		if (formInfo.status_id == '') {
			setAlertMessage({
				status: 'Please select status.'
			});
		} else if (formInfo.message.trim() == '') {
			setAlertMessage({
				message: 'Please enter message.'
			});
		} else {
			const data = {
				...formInfo,
				status_id: formInfo.status_id,
				message: formInfo.message
			};
			handleSave(data);
		}
		setTimeout(() => {
			setAlertMessage('');
		}, 1500);
	};
	const disablepopup = () => {
		handleClose();
	};


	useEffect(() => {
		setCurrentStatus(selectedCurrentStatus)

		setFormInfo({
			...formInfo,
			status_id: returnsDetail?.statusId
		});
	}, []);

	return (
		<>
			<Modal show={openPopup} onHide={disablepopup} centered size="md">
				<Modal.Body>
					<div className="p-2">
						<div className="foundListPopup">
							<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 ml-0 pl-0">
								<h5 className="h5 mb-0 text-gray-900 font-weight-bold flex-grow-1 pt-0">
									Update Status
								</h5>
								<button className="btn modal-close-icon" onClick={handleClose}>
									<FaTimes />
								</button>
							</div>
							<div className="row align-items-top">
								<div className="col-12">
									<form className="assign found">
										<div className="row">
											<div className="form-group col-md-12">
												<label className="text-gray-900">{openPopup}</label>qwe
												{currentStatus}
												<Select
													className="rs-container"
													classNamePrefix="rs"
													isSearchable={true}
													name="status"
													options={statusList}
													placeholder="Select"
													value={statusList.find(option => option.label === currentStatus)}
													onChange={(selected) =>
														handleValue(selected)
													}
												/>
												{alertMessage?.status && (
													<>
														<span className="error-message text-danger">{alertMessage?.status}</span>
													</>
												)}
											</div>
										</div>
										<div className="row">
											<div className="col-12 form-group">

												<textarea
													className="form-control"
													id="rollbackComment"
													rows={8}
													onKeyUp={
														(e) => (
															setFormInfo({
																...formInfo,
																message: e.target?.value
															})
														)
													}
												/>
												{alertMessage?.message && (
													<>
														<span className="error-message text-danger">{alertMessage?.message}</span>
													</>
												)}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="btnarea">
							<button disabled={disableStatus} onClick={onSubmit} className="btn btn-main">
								<FaPlus /> Update status
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default StatusModal;
