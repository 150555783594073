// ModalComponent.jsx
import React from 'react';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaPlus, FaSave, FaPencilAlt, FaCloudDownloadAlt, FaSpinner } from 'react-icons/fa';
import { set } from 'react-hook-form';

const ModalComponent = ({ showModal, handleClose, modelType, modalOrders }) => {
	const [selectedEstimation, setSelectedEstimation] = useState('');
	const [isExportRunning, setIsExportRunning] = useState('a');

	const exportAllBackorders = () => {
		setIsExportRunning('b');
		api.exportAllBackorders().then((res) => {
			console.log(res);
			var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(newBlob, `Projectmix - Backorders.xlsx`);
			setIsExportRunning('a');
		});
	};

	const exportBackorder = (estimation) => {
		setIsExportRunning('c');
		api.exportBackorder(estimation).then((res) => {
			var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(newBlob, `Projectmix - Backorders.xlsx`);
			setIsExportRunning('a');
		});
	};

	const exportAllBackordersPDF = () => {
		setIsExportRunning('b');
		api.exportAllBackordersPDF().then((res) => {
			console.log(res);
			var newBlob = new Blob([res], { type: 'application/pdf' });
			const date = new Date();
			const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
			saveAs(newBlob, `Projectmix - Backorders - ${dateString}.pdf`);
			setIsExportRunning('a');
		});
	};

	const exportBackorderPDF = (estimation) => {
		setIsExportRunning('c');
		api.exportBackorderPDF(estimation).then((res) => {
			var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(newBlob, `Projectmix - Backorders.pdf`);
			setIsExportRunning('a');
		});
	};

	return (
		<Modal show={showModal} onHide={handleClose} centered>
			<Modal.Header>
				<Modal.Title>{modelType === 'pdf' ? 'Download Backorders (.pdf)' : 'Download Backorders (.xlsx)'}</Modal.Title>
				<Button variant="link" onClick={handleClose} style={{ marginLeft: 'auto' }}>
					X
				</Button>
			</Modal.Header>
			<Modal.Body>
				<label htmlFor="invoiceSelect">Select Invoice</label>
				<select
					className="form-control"
					id="invoiceSelect"
					name="invoiceSelect"
					onChange={(e) => setSelectedEstimation(e.target.value)}
					placeholder="Select or type...">
					<option>Choose an invoice</option>
					{modalOrders.map((order, index) => (
						<option key={index} value={order.estimation}>
							{`  ${order.estimation}`} {`${order.customer_name}`}
						</option>
					))}
				</select>
			</Modal.Body>
			<Modal.Footer>
				<Button className="btn btn-main" onClick={handleClose}>
					Close
				</Button>
				{isExportRunning === 'b' ? (
					<button className="btn btn-main-primary disabled" disabled>
						<FaSpinner style={{ animation: 'spin 2s linear infinite' }} /> Exporting...
					</button>
				) : isExportRunning === 'c' ? (
					<button className="btn btn-main-primary disabled" disabled>
						Export All
					</button>
				) : (
					<Button className="btn btn-main-primary" onClick={modelType === 'pdf' ? exportAllBackordersPDF : exportAllBackorders}>
						Export All
					</Button>
				)}
				{isExportRunning === 'c' ? (
					<button className="btn btn-main-primary disabled" disabled>
						<FaSpinner style={{ animation: 'spin 2s linear infinite' }} /> Exporting...
					</button>
				) : isExportRunning === 'b' ? (
					<button className="btn btn-main-primary disabled" disabled>
						Export
					</button>
				) : (
					<Button
						className="btn btn-main-primary"
						onClick={() => (modelType === 'pdf' ? exportBackorderPDF(selectedEstimation) : exportBackorder(selectedEstimation))}
					>
						Export
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default ModalComponent;
