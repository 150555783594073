import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import ClientRoutes from './ClientRoutes';
import ClientLoginRoutes from './ClientLoginRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
	return useRoutes([LoginRoutes, MainRoutes, ClientRoutes, ClientLoginRoutes]);
}
