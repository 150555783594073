import React, { useEffect, useState } from 'react';
import assignbyIcon from 'assets/images/icons/assign-by.svg';
import calendarIcon from 'assets/images/icons/feather-calendar.svg';
import rejectIcon from 'assets/images/icons/reject.svg';
import inspactIcon from 'assets/images/icons/inspact.svg';
import checkIcon from 'assets/images/icons/check-green.svg';
import closetIcon from 'assets/images/icons/close-red.svg';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { storeInLocalStore, setstateInspectionList, setMessageData } from 'store/reducers/mainSlice';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Loader from 'ui-component/Loader';
import moment from 'moment';
import Scroller from 'ui-component/Scroller';
import { PlusIcon, MinusIcon, SwitchNextIcon, SwitchprvIcon } from 'components/svg-icons/icons.js';
import { Avatar, Skeleton } from '@mui/material';
import { FaArrowLeftLong, FaArrowRightLong, FaBoxesStacked, FaMinus, FaPlus } from 'react-icons/fa6';
import { CgNotes, CgPlayListCheck, CgPlayListRemove } from 'react-icons/cg';
import { getStatusColor } from 'views/Operations/TaskGrid/Task';
import toast from 'react-hot-toast';
import ProcessIndicator from 'views/Operations/ProcessIndicator';
import { BsClipboardCheck } from 'react-icons/bs';
import { GiAutoRepair, GiHandTruck, GiMagnifyingGlass } from 'react-icons/gi';
import { BiCategoryAlt } from 'react-icons/bi';
import { PiInfoFill, PiPackageBold, PiTestTubeFill } from 'react-icons/pi';
import { Link, useSearchParams } from 'react-router-dom';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import ActionInspectionRollbackModal from 'ui-component/confirmation-dialogs/actionInspectionRollbackModal/ActionInspectionRollbackModal';
import { IoIosArrowDown, IoMdBarcode } from 'react-icons/io';
import { taskboardProgresses } from 'assets/helperdata/taskboard-progrss';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { AiFillMinusCircle, AiFillPlusCircle, AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import Notes from 'ui-component/notes';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

const InspectionDetails = () => {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const dispatch = useDispatch();
	const history = useNavigate();
	const [isLoading, setLoading] = useState(false);
	const [inspectionData, setInspectionData] = useState();
	const [isDisable, setIsDisable] = useState(false);
	const [privacyMode, setPrivacyMode] = useState(false);
	const [inspectInfo, setInspectInfo] = useState();
	const [dueDate, setDueDate] = useState();
	const [startDate, setStartDate] = useState();
	const [toInspectList, setToInspectList] = useState([]);
	const [isInProgress, setIsInProgress] = useState(false);
	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => { },
		handleClose: () => { }
	});
	const [showRollbackModal, setShowRollbackModal] = useState(false);
	const [rollbackDialog, setRollbackDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => { },
		handleClose: () => { }
	});
	const [notes, setNotes] = useState([]);

	const addNote = (noteInputValue) => {
		toast.promise(
			api
				.newInspectionNote({
					task_inspection_id: id,
					comment: noteInputValue
				})
				.then(
					(res) => {
						if (res.status) {
							setNotes((oldData) => [
								{
									id: res?.data?.id,
									details: noteInputValue,
									isDeletetable: 1,
									date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
									time: moment(res?.data?.created_at).format('HH:mm'),
									user_name: userName,
									expand: false,
									isDeletable: true
								},
								...oldData
							]);
						}

						return res?.status ? res : null;
					},
					() => { }
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const toggleNoteExpand = (index) => {
		setNotes((oldData) =>
			oldData?.map((note, i) =>
				index == i
					? {
						...note,
						expand: !note?.expand
					}
					: note
			)
		);
	};

	const removeNote = (index) => {
		toast.promise(
			api.removeInspectionNote(notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setNotes((oldData) => oldData?.filter((note, i) => index != i));
					}

					return res?.status ? res : null;
				},
				() => { }
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => { },
			handleClose: () => { }
		});
	};

	const showConfirmationDialog = (title, message = '', onConfirm = () => { }, onClose = () => { }) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	const getInspectionDetails = (callback = () => { }) => {
		setLoading(true);
		setIsDisable(true);
		api.getinspectiondetailsbyid(id).then((res) => {
			setIsDisable(false);
			setLoading(false);
			setInspectInfo(res.data);
			setNotes(
				res?.data?.notes?.map((note) => ({
					id: note?.id,
					details: note?.comment,
					isDeletetable: note?.is_deletetable,
					date: moment(note?.created_at).format('YYYY-MM-DD'),
					time: moment(note?.created_at).format('HH:mm'),
					user_name: note?.created_by_user?.first_name + ' ' + note?.created_by_user?.last_name,
					isDeletable: note?.is_deletetable,
					expand: false
				}))
			);
			let duedate = moment(res.data.due_date).format('DD MMM HH:mm');
			let startdate = moment(res.data.created_at).format('DD MMM HH:mm');
			setDueDate(duedate);
			setStartDate(startdate);
			let tempDetails = res.data.inspection_details.map((item) => {
				let approved_qty = item.approved_qty;
				return {
					...item,
					inspection_qty: approved_qty > 0 || item.rejected_qty == item.inspection_qty ? approved_qty : item.inspection_qty
				};
			});
			setToInspectList(tempDetails);
			callback(res.data);
		});
	};
	const handleInspectQuantityChange = (id, value) => {
		const maxQuantity = inspectInfo.inspection_details.filter((item) => item.id == id)[0]?.inspection_qty;
		const minQty = 0;

		setToInspectList((oldData) =>
			oldData?.map((item) => {
				const newInspectVal = value <= minQty ? minQty : value >= maxQuantity ? maxQuantity : value;
				const newRejVal = Number(inspectInfo.inspection_details.find((item) => item.id == id)?.inspection_qty || 0) - newInspectVal;

				if (item.id === id) {
					return {
						...item,
						inspection_qty: newInspectVal == 0 && value == '' ? value : newInspectVal,
						rejected_qty: newRejVal
					};
				}
				return item;
			})
		);
	};

	const handleRejectQuantityChange = (id, value) => {
		const maxQuantity = inspectInfo.inspection_details.filter((item) => item.id == id)[0]?.inspection_qty;
		const minQty = 0;

		setToInspectList((oldData) =>
			oldData?.map((item) => {
				const newRejVal = value <= minQty ? minQty : value >= maxQuantity ? maxQuantity : value;
				const newInspectVal = Number(inspectInfo.inspection_details.find((item) => item.id == id)?.inspection_qty || 0) - newRejVal;

				if (item.id === id) {
					return {
						...item,
						inspection_qty: newInspectVal,
						rejected_qty: newRejVal == 0 && value == '' ? value : newRejVal
					};
				}
				return item;
			})
		);
	};
	const handleswitchleft = () => {
		const updatedToInspectList = inspectInfo?.inspection_details.map((item) => {
			let rejqty = item.rejected_qty;
			let insqty = item.inspection_qty;
			return {
				...item,
				inspection_qty: 0,
				rejected_qty: insqty
			};
		});
		setToInspectList(updatedToInspectList);
	};
	const handleswitchright = () => {
		const updatedToInspectList = inspectInfo?.inspection_details.map((item) => {
			let rejqty = item.rejected_qty;
			let insqty = item.inspection_qty;
			return {
				...item,
				inspection_qty: insqty,
				rejected_qty: 0
			};
		});
		setToInspectList(updatedToInspectList);
	};

	useEffect(() => {
		if (id) {
			getInspectionDetails((data) => {
				setIsInProgress(!!data?.start_at);
				if (!data?.start_at) {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Start Inspection?',
							'Are you sure you want to start inspection without looking at its details?',
							() => {
								handleStart();
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				} else {
					if (searchParams.get('start')) {
						showConfirmationDialog(
							'Complete Inspection?',
							'Are you sure you want to complete inspection without looking at its details?',
							() => {
								let tempDetails = data.inspection_details.map((item) => {
									let approved_qty = item.approved_qty;
									return {
										...item,
										inspection_qty: approved_qty > 0 || item.rejected_qty == item.inspection_qty ? approved_qty : item.inspection_qty
									};
								});
								handleSubmit(tempDetails);
								handleCloseConfirmationDialog();
							},
							handleCloseConfirmationDialog
						);
						searchParams.delete('start');
						setSearchParams(searchParams);
					}
				}
			});
		}

		if (searchParams.get('start')) {
			showConfirmationDialog(
				'Start Inspection?',
				'Are you sure you want to start inspection without looking at its details?',
				() => {
					handleStart();
					handleCloseConfirmationDialog();
				},
				handleCloseConfirmationDialog
			);
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const redirectFunc = (data) => {
		setTimeout(() => {
			history('/sales/invoices');
		}, 1000);
	};

	const onStartClick = () => {
		showConfirmationDialog(
			'Start Inspection?',
			'Are you sure you want to start this inspection?',
			() => {
				handleStart();
				handleCloseConfirmationDialog();
			},
			handleCloseConfirmationDialog
		);
	};

	const handleStart = () => {
		setIsDisable(true);
		toast.promise(
			api.startInspection(id).then(
				(res) => {
					if (res.status) {
						setIsInProgress(true);
					}

					setIsDisable(false);
					return res?.status ? res : null;
				},
				() => {
					setIsDisable(false);
				}
			),
			{
				loading: 'Starting Inspection...',
				success: (data) => `${data.message}`,
				error: <b>Failed to start inspection</b>
			}
		);
	};

	const handleApprove = (approveAll) => {
		showConfirmationDialog(
			'Approve Inspection',
			approveAll
				? 'Are you sure you want to approve all products in this inspection?'
				: 'Are you sure you want to approve this inspection?',
			() => {
				const details = toInspectList.map((item) => ({
					task_inspection_detail_id: item.id,
					approved_qty: item.inspection_qty,
					rejected_qty: item.rejected_qty
				}));
				const data = {
					action_type: approveAll ? null : 'reject',
					items: details
				};

				toast.promise(
					api.approveInspection(id, data).then((res) => {
						setIsDisable(false);
						if (res.status) {
							history('/operations/inspection');
						}

						return res?.status ? res : null;
					}),
					{
						loading: 'Approving Inspection...',
						success: (data) => `${data.message}`,
						error: <b>Failed to approve inspection</b>
					}
				);
			},
			handleCloseConfirmationDialog
		);
	};

	const handleRollback = (id) => {
		setShowRollbackModal(id);
	};

	const handleCloseRollbackModal = (data) => {
		setShowRollbackModal('');
	};
	const handleSubmit = (tempDetails = null) => {
		setIsDisable(true);
		const details = tempDetails
			? tempDetails.map((item) => ({
				task_inspection_detail_id: item.id,
				approved_qty: item.inspection_qty,
				rejected_qty: item.rejected_qty
			}))
			: toInspectList.map((item) => ({
				task_inspection_detail_id: item.id,
				approved_qty: item.inspection_qty,
				rejected_qty: item.rejected_qty
			}));
		const data = {
			task_inspection_id: id,
			action_type: 'submit',
			details: details
		};

		if (
			(!!tempDetails && tempDetails?.some((item) => item?.rejected_qty > 0)) ||
			(!!!tempDetails && toInspectList?.some((item) => item?.rejected_qty > 0) && notes?.length <= 0)
		) {
			toast.error('You need to add a note to mark this as done');
			setIsDisable(false);
		} else {
			toast.promise(
				api.saveinspectionData(data).then((res) => {
					setIsDisable(false);
					if (res.status) {
						history('/operations/inspection');
					}

					return res?.status ? res : null;
				}),
				{
					loading: 'Saving Inspection...',
					success: (data) => `${data.message}`,
					error: <b>Failed to save inspection</b>
				}
			);
		}
	};
	const goback = () => {
		history('/operations/inspection');
	};
	return (
		<>
			{/* <ProcessIndicator isInProgress={isInProgress} processes={taskboardProgresses} currentIndex={0} /> */}

			<div className={`TaskDetails`}>
				<div className="d-flex justify-content-between mb-0">
					<div
						className="topBtnSign"
						style={{ '--statusColor': getStatusColor(inspectInfo?.priority_status?.color), marginInline: '50px' }}
					>
						<span>{inspectInfo?.status == 'forapproval' ? 'Needs Approval' : inspectInfo?.priority_status?.label}</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-8">
						<div className="card p-2 pt-4 p-md-4">
							<Scroller>
								<div className="row inspectRow">
									<div className="col-md-12">
										<h5 className="font-weight-bold mb-2">
											<CgPlayListCheck fontSize={30} /> Inspection Report
										</h5>
										<div className="product-list-box">
											<table className="table-sm table-borderless hide-on-mobile" width="100%">
												<thead>
													<tr>
														<th>Invoice</th>
														<th>Customer</th>
														<th>Product</th>
														{isInProgress ? (
															<>
																<th className="text-center">Approved</th>
																<th className="text-center">Rejected</th>
															</>
														) : (
															<>
																<th>Quantity</th>
															</>
														)}
													</tr>
												</thead>
												<tbody>
													{isLoading ? (
														<>
															{[...Array(3)].map((e, i) => (
																<tr>
																	{[...Array(5)].map((e, i) => (
																		<td>
																			<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																		</td>
																	))}
																</tr>
															))}
														</>
													) : (
														<>
															{toInspectList.length > 0 &&
																toInspectList.map((item, index) => {
																	// let model_name =
																	// 	item?.master_model?.name.length > 8
																	// 		? item?.master_model?.name.slice(0, 10) + '...'
																	// 		: item?.master_model?.name;
																	return (
																		<>
																			<tr key={index} className="product-row">
																				{/* <td width="30px" className="align-middle">
																					<img src={checkIcon} className="mr-2" alt="" />
																				</td> */}
																				<td className="align-middle">{item?.invoice_number || '-'}</td>
																				<td className="align-middle">
																					<PrivacyModeContent>{item?.customer_name || '-'}</PrivacyModeContent>
																				</td>
																				<td className="align-middle">{item?.product_name}</td>
																				{isInProgress ? (
																					<>
																						<td className="align-middle text-center">
																							{inspectInfo?.status != 'completed' ? (
																								<input
																									type="number"
																									className="numField"
																									value={item?.inspection_qty}
																									onChange={(e) => {
																										handleInspectQuantityChange(item?.id, e?.currentTarget?.value);
																									}}
																								/>
																							) : (
																								<>{item?.inspection_qty} Pcs</>
																							)}
																						</td>
																						<td className="align-middle text-center">
																							{inspectInfo?.status != 'completed' ? (
																								<input
																									type="number"
																									className="numField"
																									value={item?.rejected_qty}
																									onChange={(e) => {
																										handleRejectQuantityChange(item?.id, e?.currentTarget?.value);
																									}}
																								/>
																							) : (
																								<>{item?.rejected_qty} Pcs</>
																							)}
																						</td>
																					</>
																				) : (
																					<>
																						<td className="align-middle">
																							<>{item?.inspection_qty} Pcs</>
																						</td>
																					</>
																				)}
																			</tr>
																		</>
																	);
																})}
														</>
													)}
												</tbody>
											</table>
											<div className="inspection-details-mobile hide-on-non-mobile">
												{toInspectList.length > 0 &&
													toInspectList.map((item, index) => {
														return (
															<div className="inspection-detail-item">
																<div className="row m-0 p-0 inspection-detail-item-head">
																	<div className="col-6 m-0 p-0">
																		<h6>{item?.invoice_number || '-'}</h6>
																		<p className="m-0 p-0 cus-name">
																			<PrivacyModeContent>{item?.customer_name || '-'}</PrivacyModeContent>
																		</p>
																	</div>
																	<div className="col-6 m-0 p-0">
																		<p className="m-0 p-0 text-right" style={{ fontWeight: '500' }}>
																			{item?.product_name}
																		</p>
																		<p className="m-0 p-0 cus-name text-right">{item?.inspection_qty} Pcs</p>
																	</div>
																</div>
																{isInProgress && (
																	<>
																		<div className="row m-0 p-0 mt-2">
																			<div className="col-6 m-0 p-0">
																				<div className="form-group">
																					<label htmlFor="">Approved</label>
																					{inspectInfo?.status != 'completed' ? (
																						<input
																							type="number"
																							className="form-control"
																							value={item?.inspection_qty}
																							onChange={(e) => {
																								handleInspectQuantityChange(item?.id, e?.currentTarget?.value);
																							}}
																						/>
																					) : (
																						<>{item?.inspection_qty} Pcs</>
																					)}
																				</div>
																			</div>
																			<div className="col-6 m-0 p-0">
																				<div className="form-group">
																					<label htmlFor="">Rejected</label>
																					{inspectInfo?.status != 'completed' ? (
																						<input
																							type="number"
																							className="form-control"
																							value={item?.rejected_qty}
																							onChange={(e) => {
																								handleRejectQuantityChange(item?.id, e?.currentTarget?.value);
																							}}
																						/>
																					) : (
																						<>{item?.rejected_qty} Pcs</>
																					)}
																				</div>
																			</div>
																		</div>
																	</>
																)}

																{/* {!isInProgress && (
																	<>
																		<div>
																			<h4 className="text-center">
																				<span className="insp-qty">{item?.inspection_qty} Pcs</span>
																			</h4>
																		</div>
																	</>
																)} */}
															</div>
														);
													})}
											</div>
										</div>
									</div>
								</div>
							</Scroller>
							<div className="btnarea">
								<span
									onClick={() => {
										goback();
									}}
									className={`btn btn-link ${isDisable ? 'disabled' : ''}`}
								>
									Back
								</span>
								{inspectInfo?.status != 'completed' && (
									<PermissionAllowed permissions={['Rollback Inspection (Action)']}>
										<span
											onClick={() => {
												!!!isDisable && handleRollback(inspectInfo?.id);
											}}
											className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
										>
											Rollback to Sourcing
										</span>
									</PermissionAllowed>
								)}

								<PermissionAllowed permissions={['Start / End Task Inspection (Action)']}>
									{inspectInfo?.status != 'completed' &&
										(isInProgress ? (
											<>
												{inspectInfo?.status == 'forapproval' ? (
													<>
														{/* <span
															onClick={() => {
																!!!isDisable && handleApprove(true);
															}}
															className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
														>
															Approve All
														</span> */}
														<span
															onClick={() => {
																!!!isDisable && handleApprove();
															}}
															className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
														>
															Confirm
														</span>
													</>
												) : (
													<span
														onClick={() => {
															handleSubmit();
														}}
														className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
													>
														Done
													</span>
												)}
											</>
										) : (
											<span
												onClick={() => {
													onStartClick();
												}}
												className={`btn btn-primary btn-outlined-main  ${isDisable ? 'disabled' : ''}`}
											>
												Start
											</span>
										))}
								</PermissionAllowed>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-3">
						<div style={{ marginTop: '10px' }}>
							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Due
								</label>

								{isLoading ? (
									<Skeleton variant="text" width={'50px'}></Skeleton>
								) : (
									<p>{moment(inspectInfo?.due_date).format(CommonDateOnlyFormat)}</p>
								)}
							</div>
							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Supplier
								</label>

								{isLoading ? (
									<Skeleton variant="text" width={'50px'}></Skeleton>
								) : (
									<p>
										<Link to={`/contacts/${inspectInfo?.supplier_id}`}>
											<PrivacyModeContent>{inspectInfo?.supplier_name}</PrivacyModeContent>
										</Link>
									</p>
								)}
							</div>
							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Assigned To
								</label>

								{isLoading ? (
									<Skeleton variant="text" width={'50px'}></Skeleton>
								) : (
									<p>
										<Link to={`/settings/user/${inspectInfo?.assigned_to}`}>{inspectInfo?.assigned_to_user}</Link>
									</p>
								)}
							</div>
							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Assigned By
								</label>

								{isLoading ? (
									<Skeleton variant="text" width={'50px'}></Skeleton>
								) : (
									<p>
										<Link to={`/settings/user/${inspectInfo?.assigned_by}`}>{inspectInfo?.assigned_by_user}</Link>
									</p>
								)}
							</div>
							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Assigned On
								</label>

								{isLoading ? (
									<Skeleton variant="text" width={'50px'}></Skeleton>
								) : (
									<p>{moment(inspectInfo?.created_at).format(CommonDateOnlyFormat)}</p>
								)}
							</div>
							<div className="est-detail-item">
								<label htmlFor="inputName" className="est-labels">
									Quantity
								</label>

								{isLoading ? <Skeleton variant="text" width={'50px'}></Skeleton> : <p>{inspectInfo?.total_qty} Pcs</p>}
							</div>

							<Notes
								notes={notes}
								addNote={addNote}
								deleteNotePermission={[]}
								removeNote={removeNote}
								toggleNoteExpand={toggleNoteExpand}
							/>
						</div>
					</div>
				</div>
			</div>

			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}
			{showRollbackModal && (
				<ActionInspectionRollbackModal
					inspectionId={showRollbackModal}
					handleCloseRollbackModal={handleCloseRollbackModal}
					redirectionData={redirectFunc}
				/>
			)}
		</>
	);
};

export default InspectionDetails;
