import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { useState, useEffect, useRef } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, AreaChart, Area, XAxis, YAxis, CartesianGrid } from 'recharts';
import 'assets/scss/charts.scss';
import { Fade } from 'react-awesome-reveal';
import { IconArrowsLeftRight, IconCalendar, IconArrowUpRight, IconArrowDownRight, IconDots, IconChartBar } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ContactCard from './ContactCard';
import TopSuppliers from './TopSupplier';
import TopCustomers from './TopCustomerAdmin';
import TopProducts from './TopProductsAdmin';
import WarriorCard from './WarriorCard';
import GrossProfitCard from './GrossProfit';
import wotm from 'assets/images/dbDummy/afzaly.png';
import SalesTeamCard from './SalesTeam';
import SalesTargetCard from './SalesTarget';
import OpenClientsCard from './OpenClients';
import PerformanceRatingCard from './PerformanceAdmin';
import EstimatedCard from './Estimated';
import ReturnsPerSupplierCard from './SupplierReturns';
import CurrentInventoryCard from './CurrentInventory';
import ProductSoldAndReturnCard from './SoldAndReturn';

const currentYear = new Date().getFullYear();
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const scrollMonths = months.map((month) => `${month} ${currentYear}`);

const geoUrl = 'https://unpkg.com/world-atlas@2/countries-110m.json';

const CustomizedAxisTick = (props) => {
	const { x, y, payload } = props;

	const formatK = (num) => {
		return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k' : Math.sign(num) * Math.abs(num);
	};

	return (
		<g transform={`translate(${x - 50},${y - 10})`}>
			<text x={0} y={0} dy={16} textAnchor="start" fill="#838383">
				$ {formatK(payload.value)}
			</text>
		</g>
	);
};

const AdminDashboard = () => {
	const date = new Date();
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	const [currentDate, setDate] = useState(new Date());
	const [isLoading, setIsLoading] = useState(false);
	const [activeItem, setActiveItem] = useState('Month');
	const [currentMonth, setCurrentMonth] = useState(monthNames[date.getMonth()]);
	const [currentYear, setCurrentYear] = useState(date.getFullYear());
	const [activeMonth, setActiveMonth] = useState(`${currentMonth} ${currentYear}`);
	const [previousMonth, setPreviousMonth] = useState(monthNames[date.getMonth() - 1]);
	const [month, year] = activeMonth.split(' ');
	const [data, setData] = useState([{}]);
	const [totalRevenue, setTotalRevenue] = useState([]);
	const [amount, setAmount] = useState(1.0);
	const [fromCurrency, setFromCurrency] = useState('USD');
	const [toCurrency, setToCurrency] = useState('EUR');
	const [result, setResult] = useState(0);
	const [revenue, setRevenue] = useState([]);
	const [revenueGrowth, setRevenueGrowth] = useState([]);
	const [revenueGrowthBar, setRevenueGrowthBar] = useState([]);
	const [revenuePreviousGrowth, setRevenuePreviousGrowth] = useState([]);
	const [revenueData, setRevenueData] = useState([]);
	const [targetRevenue, setTargetRevenue] = useState([]);
	const [targetRevenuePercentage, setTargetRevenuePercentage] = useState([]);
	const [pendingOrder, setPendingOrder] = useState([]);
	const [productSold, setProductSold] = useState([]);
	const [productGrowth, setProductGrowth] = useState([]);
	const [salesPerson, setSalesPerson] = useState([]);
	const [contact, setContact] = useState([]);
	const [chartClicked, setChartClicked] = useState(false);
	const [topCustomer, setTopCustomer] = useState([]);
	const [topProducts, setTopProducts] = useState([]);
	const [topSupplier, setTopSupplier] = useState([]);
	const [salesSold, setSalesSold] = useState([]);
	const [salesTarget, setSalesTarget] = useState([]);
	const [salesPercentage, setSalesPercentage] = useState([]);
	const [displayMode, setDisplayMode] = useState('percentage');
	const [returnsSupplier, setReturnsSupplier] = useState([]);
	const [returns, setReturns] = useState([]);
	const [returnsGrowth, setReturnsGrowth] = useState([]);
	const [openClients, setOpenClients] = useState([]);

	const [wotmData, setWotm] = useState([]);
	const [wotmName, setWotmName] = useState([]);
	const [wotmImage, setWotmImage] = useState([]);
	const [wotmImageSmall, setWotmImageSmall] = useState([]);
	const [wotmSold, setWotmSold] = useState([]);
	const [wotmProfitMargin, setWotmProfitMargin] = useState([]);
	const [wotmContact, setWotmContact] = useState([]);

	const [dataKey, setDataKey] = useState('totalSales');

	const testData = [95, 90, 80, 80, 75, 70, 65, 40, 20];

	const activeDate = new Date(`${month} 1, ${year}`);
	const lastMonth = new Date(activeDate.setMonth(activeDate.getMonth() - 1));
	const lastLastMonth = new Date(activeDate.setMonth(activeDate.getMonth() - 1));

	const [prevMonth, setPrevMonth] = useState(`${lastMonth.toLocaleString('default', { month: 'long' })} ${lastMonth.getFullYear()}`);
	const [prevPrevMonth, setPrevPrevMonth] = useState(
		`${lastLastMonth.toLocaleString('default', { month: 'long' })} ${lastLastMonth.getFullYear()}`
	);

	const [scrollMonths, setScrollMonths] = useState([
		'Jan 2024',
		'Feb 2024',
		'Mar 2024',
		'Apr 2024',
		'May 2024',
		'Jun 2024',
		'Jul 2024',
		'Aug 2024',
		'Sep 2024',
		'Oct 2024',
		'Nov 2024',
		'Dec 2024'
	]);

	useEffect(() => {
		setIsLoading(true);

		const date = new Date();
		const currentMonthIndex = date.getMonth();
		const currentYear = date.getFullYear();

		const months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];
		const monthsToPreload = months.slice(0, currentMonthIndex + 1).reverse();

		const fetchData = async () => {
			for (const month of monthsToPreload) {
				const cachedData = localStorage.getItem(`salesData_${month}_${currentYear}`);
				const cachedTime = localStorage.getItem(`timestamp_${month}_${currentYear}`);
				const currentTime = new Date().getTime();
				const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds

				if (cachedData && cachedTime && currentTime - cachedTime < fiveMinutes) {
					if (month === months[currentMonthIndex]) {
						processData(JSON.parse(cachedData));
						setIsLoading(false);
					}
				} else {
					const res = await api.getSalesDataMonth(`${month} ${currentYear}`);
					localStorage.setItem(`salesData_${month}_${currentYear}`, JSON.stringify(res));
					localStorage.setItem(`timestamp_${month}_${currentYear}`, currentTime.toString());

					if (month === months[currentMonthIndex]) {
						processData(res);
						setIsLoading(false);
					}
				}
			}
		};

		fetchData();
	}, []);

	const scrollContainer = useRef(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (scrollContainer.current) {
				scrollContainer.current.scrollLeft = scrollContainer.current.scrollWidth;
			}
		}, 100); // adjust delay as needed

		// cleanup function
		return () => clearTimeout(timer);
	}, [months]);

	const handleClick = (item) => {
		setActiveItem(item);

		if (item === 'Quarter') {
			setScrollMonths(['Q1 2024', 'Q2 2024', 'Q3 2024', 'Q4 2024']);
		} else if (item === 'Month') {
			setScrollMonths([
				'Jan 2024',
				'Feb 2024',
				'Mar 2024',
				'Apr 2024',
				'May 2024',
				'Jun 2024',
				'Jul 2024',
				'Aug 2024',
				'Sep 2024',
				'Oct 2024',
				'Nov 2024',
				'Dec 2024'
			]);
		} else {
			setScrollMonths(['2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033']);
		}
		// handle other items...
	};

	const handleMonth = (month) => {
		setActiveMonth(month);

		if (month.includes('Q')) {
			api.getSalesDataQuarter(month).then((res) => {
				console.log(res);
			});
		} else if (month.length === 4) {
			// Call API for years
			const year = month;

			api.getSalesDataYear(year).then((res) => {
				console.log(res);
			});
		} else {
			// Call API for months
			const [monthName, year] = month.split(' ');

			const activeDate = new Date(`${monthName} 1, ${year}`);
			const fullMonthName = activeDate.toLocaleString('default', { month: 'long' });

			const lastMonth = new Date(activeDate.setMonth(activeDate.getMonth() - 1));
			const lastLastMonth = new Date(activeDate.setMonth(activeDate.getMonth() - 1));

			setCurrentMonth(monthNames[activeDate.getMonth()]);
			setCurrentYear(activeDate.getFullYear());
			setPreviousMonth(monthNames[lastMonth.getMonth()]);

			setPrevMonth(`${lastMonth.toLocaleString('default', { month: 'long' })} ${lastMonth.getFullYear()}`);
			setPrevPrevMonth(`${lastLastMonth.toLocaleString('default', { month: 'long' })} ${lastLastMonth.getFullYear()}`);

			const cachedData = localStorage.getItem(`salesData_${fullMonthName}_${year}`);
			if (cachedData) {
				const res = JSON.parse(cachedData);
				processData(res);
			} else {
				api.getSalesDataMonth(`${fullMonthName} ${year}`).then((res) => {
					localStorage.setItem(`salesData_${fullMonthName}_${year}`, JSON.stringify(res));
					processData(res);
				});
			}
		}
	};

	const processData = (res) => {
		setSalesSold(Math.floor(res.sales_sold));
		setSalesTarget(res.sales_target);
		setSalesPercentage(res.sales_percentage);
		setRevenueData(res.revenue_data);
		setRevenue(Number(res.revenue).toFixed(2));
		setTargetRevenue(Number(res.target_revenue).toFixed(2));
		setTargetRevenuePercentage(Number(res.revenue_percentage).toFixed(2));
		setRevenueGrowth(Number(res.revenue_growth).toFixed(2));
		setRevenueGrowthBar(Number(res.revenue_growth_bar).toFixed(2));
		setRevenuePreviousGrowth(Number(res.revenue_growth_previous).toFixed(2));
		setTotalRevenue(res.total_sales);
		setProductSold(res.product_sold);
		setReturns(res.returns_month);
		setReturnsGrowth(res.returns_growth);
		setProductGrowth(parseFloat(res.product_growth).toFixed(2));

		const newWotm = [
			{ name: 'wotm_sold', value: res.WOTM_Sold },
			{ name: 'wotm_credit', value: res.WOTM_Credit },
			{ name: 'wotm_remaining', value: res.WOTM_Remaining },
			{ name: 'wotm_target', value: res.sales_target / 3 }
		];
		setWotm(newWotm);
		setWotmSold(res.WOTM_sold_qty);
		setWotmProfitMargin(res.WOTM_profit_margin);
		const newWotmName = [
			{ name: 'wotm_first', value: res.wotm_first_name },
			{ name: 'wotm_last', value: res.wotm_last_name }
		];
		setWotmName(newWotmName);
		setWotmImage(res.wotm_image);
		setWotmImageSmall(res.wotm_image_small);
		setWotmContact(res.wotm_contact_count);
		const newSalesPerson = Object.values(res.top_sales_person).map((person) => ({
			name: person.first_name,
			photo: person.profile_photo,
			value: person.profit,
			amount: parseFloat(person.profit_amount).toFixed(0),
			turnover: parseFloat(person.turnover_amount).toFixed(0)
		}));
		setSalesPerson(newSalesPerson);

		setPendingOrder(res.pending_orders);

		const newContact = res.contacts;
		setContact(newContact);

		const newData = res.sales_stats;
		setData(newData);

		const newTopCustomer = res.top_customers;
		setTopCustomer(newTopCustomer);

		const newTopProduct = res.top_products;
		setTopProducts(newTopProduct);

		const newTopSupplier = res.top_supplier;
		setTopSupplier(newTopSupplier);

		const newReturnsSupplier = res.returns;
		setReturnsSupplier(newReturnsSupplier);

		const newOpenClients = res.open_clients;
		setOpenClients(newOpenClients);
	};

	const combinedData = Object.entries(data).map(([day, salesData]) => {
		const totalSales = Object.values(salesData).reduce((total, salespersonData) => {
			return total + (typeof salespersonData.sales === 'number' ? salespersonData.sales : 0);
		}, 0);

		const paid_balance = Object.values(salesData).reduce((total, salespersonData) => {
			return total + (typeof salespersonData.paid_balance === 'number' ? salespersonData.paid_balance : 0);
		}, 0);

		const sales = Object.entries(salesData).reduce((acc, [salesperson, salespersonData]) => {
			acc[salesperson] = {
				name: salespersonData.first_name,
				photo: salespersonData.profile_photo,
				sales: salespersonData.sales
			};
			return acc;
		}, {});

		return { day, sales, totalSales, paid_balance };
	});

	const dataCount = combinedData.length;

	const Avatar = ({ photo, name }) => {
		if (photo) {
			return <img src={photo} alt={name} style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '5px' }} />;
		} else {
			const initials = name
				.split(' ')
				.map((word) => word[0])
				.join('')
				.toUpperCase();
			return (
				<div
					style={{
						height: '30px',
						width: '30px',
						borderRadius: '50%',
						marginRight: '5px',
						backgroundColor: '#ccc',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					{initials}
				</div>
			);
		}
	};

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			const salesData = payload[0].payload;
			if (salesData) {
				if (!salesData.sales || (salesData.sales[0] && salesData.sales[0].name === undefined)) {
					return (
						<div className="custom-tooltip-chart" style={{ transform: 'translateY(-20px)' }}>
							<p className="date-label">{`${month} ${label}, ${year}`}</p>
							<div className="custom-label">
								<div
									className="row chart-row"
									style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '5px' }}
								>
									<div className="label-sales-value">No Sales</div>
								</div>
							</div>
						</div>
					);
				}

				return (
					<div className="custom-tooltip-chart" style={{ transform: 'translateY(-20px)' }}>
						<p className="date-label">{`${month} ${label}, ${year}`}</p>
						<div className="custom-label">
							{Object.entries(salesData.sales).map(([salesperson, data], index) => {
								if (data.name === 'Unknown') {
									return (
										<div
											className="row chart-row"
											style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '5px' }}
										>
											<div className="label-sales-value">No Sales</div>
										</div>
									);
								} else {
									return (
										<div
											className="row chart-row"
											style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}
											key={index}
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Avatar photo={data.photo} name={data.name} />
												<div className="label-sale1">{data.name}</div>
											</div>
											<div className="label-sales-value">
												$ {data.sales ? data.sales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
											</div>
										</div>
									);
								}
							})}
							<div className="total-chart">
								<p>
									TOTAL - ${' '}
									{salesData.totalSales
										? salesData.totalSales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
										: '0.00'}
								</p>
							</div>
						</div>
					</div>
				);
			}
		}

		return null;
	};

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		const exchangeRate = {
			USD: { USD: 1, EUR: 0.85, GBP: 0.76, JPY: 110.61 },
			EUR: { USD: 1.18, EUR: 1, GBP: 0.89, JPY: 130.14 },
			GBP: { USD: 1.32, EUR: 1.12, GBP: 1, JPY: 146.78 },
			JPY: { USD: 0.009, EUR: 0.0077, GBP: 0.0068, JPY: 1 }
		};

		if (exchangeRate[fromCurrency] && exchangeRate[fromCurrency][toCurrency]) {
			const convertedAmount = amount * exchangeRate[fromCurrency][toCurrency];
			setResult(convertedAmount);
		} else {
			throw new Error(`No exchange rate available from ${fromCurrency} to ${toCurrency}`);
		}
	}, [amount, fromCurrency, toCurrency]);

	const handleAmountChange = (event) => {
		setAmount(event.target.value);
	};

	const handleFromCurrencyChange = (event) => {
		setFromCurrency(event.target.value);
	};

	const handleToCurrencyChange = (event) => {
		setToCurrency(event.target.value);
	};

	const scroll = (scrollOffset) => {
		scrollContainer.current.scrollLeft += scrollOffset;
	};

	const donutData = [
		{ name: 'Group A', value: 100 - Number(salesPercentage) },
		{ name: 'Group B', value: Number(salesPercentage) }
	];

	const COLORS = ['#ebeaea', '#f64e60'];

	const contactColors = [
		{ background: '#bbf3ae', color: '#63b950' },
		{ background: '#caa4ec', color: '#8049b1' },
		{ background: '#f1e29b', color: '#ebb030' },
		{ background: '#df8d8d', color: '#b85252' }
	];

	const monthNames2 = {
		'01': 'January',
		'02': 'February',
		'03': 'March',
		'04': 'April',
		'05': 'May',
		'06': 'June',
		'07': 'July',
		'08': 'August',
		'09': 'September',
		10: 'October',
		11: 'November',
		12: 'December'
	};

	const CustomTick = ({ x, y, payload, hovered, index, totalCount, chartClicked }) => {
		const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		const monthNames2 = {
			Jan: 0,
			Feb: 1,
			Mar: 2,
			Apr: 3,
			May: 4,
			Jun: 5,
			Jul: 6,
			Aug: 7,
			Sept: 8,
			Oct: 9,
			Nov: 10,
			Dec: 11
		};
		const [activeMonthName, activeYear] = activeMonth.split(' ');
		const date = new Date(activeYear, monthNames2[activeMonthName], payload.value);
		const dayOfWeek = days[date.getDay()];

		const isEdgeTick = index === 0 || index === totalCount - 1;

		return (
			<g transform={isEdgeTick ? (index === 0 ? 'translate(30, 0)' : index === totalCount - 1 ? 'translate(-30, 0)' : '') : ''}>
				<text x={x} y={y + 15} textAnchor="middle" fill="#666" style={{ opacity: (isEdgeTick && !chartClicked) || hovered ? 1 : 0 }}>
					{dayOfWeek}, {payload.value} {activeMonthName}
				</text>
			</g>
		);
	};

	const CustomTooltipRevenue = ({ active, payload }) => {
		if (active && payload && payload.length) {
			const monthName = monthNames2[payload[0].payload.name];
			return (
				<div className="custom-tooltip-revenue" style={{ padding: '10px' }}>
					<p className="label" style={{ marginBottom: '5px', marginTop: '10px' }}>
						<strong>Month: </strong>
						{`${monthName}`}
					</p>
					<p className="intro">
						<strong>Profit: </strong>$ {new Intl.NumberFormat().format(payload[0].value)}
					</p>
				</div>
			);
		}

		return null;
	};

	const CustomDot = ({ cx, cy, payload, height, hoveredTick }) => {
		const rectWidth = 30;
		const extraHeight = 10; // Adjust this value to extend the height below
		if (payload.day === hoveredTick) {
			return <rect x={cx - rectWidth / 2} y={0} width={rectWidth} height={height + extraHeight} fill="#87cef7" fillOpacity={0.2} />;
		}
		return null;
	};

	// Inside your parent component
	const [hoveredTick, setHoveredTick] = useState(null);

	return (
		<>
			<div className="global-time row m-0 mt-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
				<a>
					{new Date().toLocaleString('en-US', {
						month: 'short',
						day: 'numeric',
						year: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						hour12: true
					})}
				</a>
				<div style={{ marginRight: '20px' }}>
					<a className="multiple-times">
						New York{' '}
						{currentDate.toLocaleString('en-US', { timeZone: 'America/New_York', hour: 'numeric', minute: 'numeric', hour12: false })}
					</a>
					<b className="multiple-times">|</b>
					<a className="multiple-times">
						London {currentDate.toLocaleString('en-US', { timeZone: 'Europe/London', hour: 'numeric', minute: 'numeric', hour12: false })}
					</a>
					<b className="multiple-times">|</b>
					<a className="multiple-times">
						Amsterdam{' '}
						{currentDate.toLocaleString('en-US', { timeZone: 'Europe/Amsterdam', hour: 'numeric', minute: 'numeric', hour12: false })}
					</a>
					<b className="multiple-times">|</b>
					<a className="multiple-times">
						Hong Kong{' '}
						{currentDate.toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong', hour: 'numeric', minute: 'numeric', hour12: false })}
					</a>
				</div>
			</div>

			{!isLoading && (
				<div className="row m-0 p-0 mt-4" style={{ marginBottom: '20px' }}>
					<div className="col-12">
						<Fade>
							<div
								className="card"
								style={{
									height: '450px',
									padding: '20px',
									zIndex: '1000',
									zIndex: '1'
								}}
							>
								<div className="card-header" style={{ backgroundColor: 'white', paddingBottom: 0, padding: 0, paddingLeft: '20px' }}>
									<h5 style={{ fontWeight: '500' }}>Sales Statistics</h5>
								</div>
								<div className="card-body" style={{ paddingTop: '5px' }}>
									<div className="d-flex justify-content-between align-items-center">
										<div className="stat-selector">
											<div className={`stat-selector__item ${activeItem === 'Month' ? 'active' : ''}`} onClick={() => handleClick('Month')}>
												Month
											</div>
											<div
												className={`stat-selector__item ${activeItem === 'Quarter' ? 'active' : ''}`}
												onClick={() => handleClick('Quarter')}
											>
												Quarter
											</div>
											<div className={`stat-selector__item ${activeItem === 'Year' ? 'active' : ''}`} onClick={() => handleClick('Year')}>
												Year
											</div>
										</div>
										<div className="sales-stats-button">
											<button
												className="btn btn-sm btn-primary"
												onClick={() => setDataKey(dataKey === 'totalSales' ? 'paid_balance' : 'totalSales')}
											>
												Switch to {dataKey === 'totalSales' ? 'Paid' : 'Total Sales'}
											</button>
										</div>
									</div>
									<div
										className="scroll-container"
										style={{ display: 'flex', justifyContent: 'flex-end', overflowX: 'auto', marginTop: '20px', width: '100%' }}
									>
										<div className="stats-date stats-date-custom">
											<button onClick={() => scroll(-100)}>&lt;</button>
											<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
												{scrollMonths.map((month, index) => {
													const currentMonth = new Date().getMonth();
													const isDisabled = index > currentMonth || (index === 0 && month.includes('Jan'));

													return (
														<div
															key={index}
															className={`stats-date__item ${month === activeMonth ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
															onClick={() => !isDisabled && handleMonth(month)}
															style={{ minWidth: '100px', opacity: isDisabled ? 0.5 : 1 }} // replace 100px with the desired width
														>
															{month}
														</div>
													);
												})}
											</div>
											<button onClick={() => scroll(100)}>&gt;</button>
										</div>
									</div>
									<div className="revenue-stats">
										<h5>
											Revenue{' '}
											<strong>
												$ {`${Number(totalRevenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
											</strong>
										</h5>
										<a>Period - {`${activeMonth}`}</a>
									</div>
									<div className="stat-chart" style={{ paddingTop: '10px' }}>
										<ResponsiveContainer width="100%" height={210}>
											<AreaChart
												data={combinedData}
												margin={{
													top: 10,
													right: 0,
													left: 0,
													bottom: 0
												}}
												onMouseMove={({ activeLabel }) => {
													setHoveredTick(activeLabel);
													setChartClicked(true);
												}}
												onMouseLeave={() => {
													setHoveredTick(null);
													setChartClicked(false);
												}}
											>
												<CartesianGrid strokeDasharray="5 3" stroke="#e2e2e2" vertical={false} />
												<XAxis
													dataKey="day"
													tick={(props) => (
														<CustomTick
															{...props}
															hovered={props.payload.value === hoveredTick}
															index={props.index}
															totalCount={dataCount}
															chartClicked={chartClicked}
														/>
													)}
													axisLine={false}
													tickLine={false}
												/>
												{window.innerWidth > 768 && (
													<YAxis orientation="left" axisLine={false} tickLine={false} tick={<CustomizedAxisTick />} />
												)}
												<Tooltip cursor={false} content={<CustomTooltip />} offset={20} />
												<Area
													type="linear"
													dataKey={dataKey}
													stroke={dataKey === 'totalSales' ? '#4891fd' : '#4af364'}
													fill={dataKey === 'totalSales' ? '#bcdaf1' : '#bbebc2'}
													dot={<CustomDot hoveredTick={hoveredTick} />}
												/>
											</AreaChart>
										</ResponsiveContainer>
									</div>
								</div>
							</div>
						</Fade>
					</div>
				</div>
			)}
			{!isLoading && (
				<div className="row m-0 p-0 mt-4">
					{wotmName[0] && wotmName[0].value !== 'No' && wotmName[1] && wotmName[1].value !== 'Data' && (
						<div className="col-lg-4 col-md-6 col-sm-12">
							<Fade>
								<div className={`card custom-card`} style={{ height: '230px', marginBottom: '20px' }}>
									<WarriorCard
										previousMonth={previousMonth}
										currentYear={currentYear}
										wotm={wotm}
										wotmName={wotmName}
										wotmData={wotmData}
										wotmSold={wotmSold}
										wotmProfitMargin={wotmProfitMargin}
										wotmContact={wotmContact}
										wotmImage={wotmImage}
										wotmImageSmall={wotmImageSmall}
										isAdmin={true}
									/>
								</div>
							</Fade>
						</div>
					)}

					<GrossProfitCard revenueData={revenueData} revenueGrowth={revenueGrowth} revenueGrowthBar={revenueGrowthBar} />

					<SalesTeamCard salesPerson={salesPerson} setDisplayMode={setDisplayMode} displayMode={displayMode} />

					<SalesTargetCard donutData={donutData} salesPercentage={salesPercentage} salesSold={salesSold} salesTarget={salesTarget} />

					<OpenClientsCard handleShow={handleShow} show={show} handleClose={handleClose} openClients={openClients} />

					<TopSuppliers topSupplier={topSupplier} />

					<TopCustomers topCustomer={topCustomer} isAdmin={true} />

					<TopProducts topProducts={topProducts} isAdmin={true} />

					<PerformanceRatingCard testData={testData} />

					<div className="col-returns col-md-6 col-sm-12">
						<EstimatedCard estimatedValue="$ 178,080" percentageChange="+10.08%" />

						<ReturnsPerSupplierCard returns={returnsSupplier} />
					</div>
					<div className="col-inventory col-md-6 col-sm-12">
						<CurrentInventoryCard inventoryValue="$ 376,080" productCount={354} />
						<ProductSoldAndReturnCard
							productSold={productSold}
							productGrowth={productGrowth}
							returns={returns}
							returnsGrowth={returnsGrowth}
						/>
					</div>
					{Object.keys(contact).length > 0 && <ContactCard contact={contact} />}
				</div>
			)}
		</>
	);
};

export default AdminDashboard;
