import React from 'react';
import { Modal } from 'react-bootstrap';
import './DeleteConfirmation.scss';

import { IoWarningOutline } from 'react-icons/io5';
import { PiWarningFill } from 'react-icons/pi';

function DeleteConfirmation({ show, handleClose, handleDelete, title, message, customDeleteButtonText }) {

	return (
		<Modal
			show={show}
			onHide={(e) => {
				handleClose && handleClose(e);
			}}
			centered
			className="delete-confirmation-dialog"
		>
			<Modal.Body style={{ marginTop: '-10px', marginBottom: '10px' }}>
				<Modal.Title>
					<span>
						
						<svg height="50px" width="50px" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 267 267" xmlns="http://www.w3.org/2000/svg" id="fi_15674336"><g transform="matrix(4.167 0 0 4.167 -3000 0)"><path d="m760.291 6.947c-1.704-3.06-4.866-4.947-8.291-4.947s-6.587 1.887-8.291 4.947c-6.619 11.889-15.845 28.459-22.442 40.309-1.697 3.047-1.689 6.794.02 9.833 1.71 3.04 4.861 4.911 8.271 4.911h44.884c3.41 0 6.561-1.871 8.271-4.911 1.709-3.039 1.717-6.786.02-9.833-6.597-11.85-15.823-28.42-22.442-40.309z" fill="#fff"></path><path d="m760.291 6.947c-1.704-3.06-4.866-4.947-8.291-4.947s-6.587 1.887-8.291 4.947c-6.619 11.889-15.845 28.459-22.442 40.309-1.697 3.047-1.689 6.794.02 9.833 1.71 3.04 4.861 4.911 8.271 4.911h44.884c3.41 0 6.561-1.871 8.271-4.911 1.709-3.039 1.717-6.786.02-9.833-6.597-11.85-15.823-28.42-22.442-40.309zm-5.242 2.919 22.442 40.308c.685 1.232.682 2.746-.008 3.974-.633 1.125-1.779 1.852-3.041 1.852h-44.884c-1.262 0-2.408-.727-3.041-1.852-.69-1.228-.693-2.742-.008-3.974 0 0 22.442-40.308 22.442-40.308.631-1.133 1.781-1.866 3.049-1.866s2.418.733 3.049 1.866z" fill="#f00"></path><path d="m752 46.5c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm2.87-24.003c.034-.783-.253-1.545-.795-2.111s-1.291-.886-2.075-.886c-.784 0-1.533.32-2.075.886s-.829 1.328-.795 2.111c.208 4.769.607 13.958.783 18.006.049 1.117.968 1.997 2.086 1.997h.002c1.118 0 2.037-.88 2.086-1.997.176-4.048.575-13.237.783-18.006z"></path></g></svg>
					</span>
					{title || 'Confirm'}
				</Modal.Title>


				{
					message ? (
						<div dangerouslySetInnerHTML={{ __html: message }} />
					) : (
						<p>Are you sure you want to delete this record?</p>
					)
				}

				<div className="modal-actions" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<button
						className="btn btn-primary btn-outlined-main btn-dlt"

						onClick={() => {
							handleDelete && handleDelete(show.id);
						}}
					>
						{customDeleteButtonText || 'Delete'}
					</button>
					<button
						className="btn btn-primary btn-outlined-main"
						onClick={(e) => {
							handleClose && handleClose(e);
						}}
					>
						Cancel
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default DeleteConfirmation;
