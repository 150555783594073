import React from 'react';

function CurrencyIcon() {
	return (
		<svg
			id="fi_2492300"
			enableBackground="new 0 0 30 30"
			fill="currentColor"
			height="16"
			viewBox="0 0 30 30"
			width="16"
			xmlns="http://www.w3.org/2000/svg"
		>
			<switch>
				<foreignObject height="1" requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" width="1"></foreignObject>
				<g>
					<g id="XMLID_356_" fill="currentColor">
						<path d="m15 24c-1.563 0-3.0405-.4941-4.1606-1.3916-1.1861-.9482-1.8394-2.2295-1.8394-3.6084h2c0 .7588.3867 1.4854 1.0889 2.0469 1.5352 1.2305 4.2876 1.2295 5.8218.001.7026-.5625 1.0893-1.2891 1.0893-2.0479s-.3867-1.4854-1.0889-2.0469c-.6997-.5596-1.6455-.8613-2.9771-.9492-1.1929-.0791-2.7368-.3086-4.0947-1.3955-1.186-.9482-1.8393-2.2295-1.8393-3.6084s.6533-2.6602 1.8398-3.6094c1.1197-.8965 2.5972-1.3906 4.1602-1.3906s3.0405.4941 4.1606 1.3916c1.1861.9482 1.8394 2.2295 1.8394 3.6084h-2c0-.7588-.3867-1.4854-1.0889-2.0469-1.5352-1.2305-4.2876-1.2295-5.8218-.001-.7026.5625-1.0893 1.2891-1.0893 2.0479s.3867 1.4854 1.0889 2.0469c.73.585 1.6201.8721 2.9771.9609 1.1069.0732 2.7358.2959 4.0942 1.3828 1.1865.9492 1.8398 2.2305 1.8398 3.6094s-.6533 2.6602-1.8398 3.6094c-1.1197.8965-2.5972 1.3906-4.1602 1.3906z"></path>
						<path d="m14 5h2v20h-2z"></path>
						<path d="m15 30c-8.271 0-15-6.7285-15-15s6.729-15 15-15 15 6.7285 15 15-6.729 15-15 15zm0-28c-7.1685 0-13 5.832-13 13s5.8315 13 13 13 13-5.832 13-13-5.8315-13-13-13z"></path>
					</g>
				</g>
			</switch>
		</svg>
	);
}

export default CurrencyIcon;
